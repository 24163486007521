angular.module('vcio-toolkit').config(function ($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.when('/clients', function (CurrentUser) {
    window.location.href = '/new-frontend?path=clients?token=' + CurrentUser.getToken();
  });

  $urlRouterProvider.when('/dashboard', function (CurrentUser) {
    window.location.href = '/new-frontend?path=home?token=' + CurrentUser.getToken();
  });

  $stateProvider
    .state('empty', {
      url: '/',
      controller: function ($location, $scope, $state, $rootScope, CurrentUser, HttpService) {
        var currentUser = CurrentUser.getUser();
        if (!CurrentUser.isAuthenticated()) {
          $state.go('login');
        } else if ($rootScope.connectWiseScreen) {
          // if ($rootScope.connectWiseScreen.screen === 'company') {
          $state.go('embedded.delivery');
          // } else {
          //     $state.go('msp.dashboard');
          // }
          // } else if (currentUser.modules.length === 0) {
          //     $state.go('msp.payment');
        } else if (!currentUser.firstName && !currentUser.lastName) {
          $state.go('user.profile');
        } else if (!currentUser.Company.name) {
          $state.go('msp.mycompany');

          // } else if (CurrentUser.isHtgGroup() && CurrentUser.hasModule('msp')) {
          //     $location.url('/clients?start=true' + (CurrentUser.isHtgGroup() ? '&group=true' : ''))
          //     // $state.go('clients.list');
          //
          //     // } else if (!CurrentUser.hasSubscription() && !CurrentUser.hasModule('partnermember')  && !CurrentUser.hasModule('unlimited') && !CurrentUser.hasModule('vendor')  && !CurrentUser.isHtgGroup()) {
          //     //     $state.go('msp.payment');
        } else if (CurrentUser.isPartnerMember() && !CurrentUser.hasModule('msp')) {
          $location.url('/academy/assignments?start=true');
        } else {
          window.location.href = '/new-frontend?path=home?token=' + CurrentUser.getToken();
        }

        // if (CurrentUser.hasModule('vendor')) {
        //     $state.go('msp.dashboard');
        // } else if (CurrentUser.hasModule('academy') && (CurrentUser.getUser() && CurrentUser.getUser().Company.name.indexOf('HTG') !== 0)) {
        //     $state.go('academy.assignments');
        // } else if (CurrentUser.hasModule('sales')) {
        //     $state.go('sales.home');
        // } else if (CurrentUser.hasModule('vcio')) {
        //     $state.go('vcio.dashboard');
        // } else {
        //     $state.go('msp.dashboard');
        // }
      },
    })
    .state('error', {
      url: '/error',
      templateUrl: '/templates/empty.html',
      params: { errors: [] },
      controller: function ($scope, $stateParams) {
        $scope.errors = $stateParams.errors;
      },
    })
    .state('uielements', {
      url: '/uielements',
      templateUrl: '/templates/uielements.html',
    })
    .state('login', {
      url: '/login?:message',
      templateUrl: '/templates/auth/login-panel.html',
      controller: 'LoginController',
    })
    .state('logout', {
      url: '/logout',
      templateUrl: '/templates/auth/login-panel.html',
      controller: function ($scope, $state, Auth) {
        Auth.logout();
      },
    });

  $stateProvider
    .state('msp', {
      abstract: true,
      template: '<ui-view/>',
      data: {
        // navbar: '/templates/msp/header.html'
      },
    })
    // .state('msp.dashboard', {
    //     url: '/dashboard',
    //     templateUrl: '/templates/dashboard/dashboard.html',
    //     controller: 'DashboardController',
    //     resolve: {
    //         HttpService: 'HttpService',
    //         dashboardWidgets: function (HttpService) {
    //             return HttpService.get('/api/dashboard')
    //         },
    //         widgetList: function (HttpService) {
    //             return HttpService.get('/api/lists/dashboardWidget')
    //         },
    //         users: function (HttpService, CurrentUser, InputSanitizerService) {
    //             return HttpService.get('/api/admin/users?companyId=' + InputSanitizerService.sanitize(CurrentUser.getUser().Company.id));
    //         },
    //         segments: function (HttpService) {
    //             return HttpService.get('/api/segments')
    //         },
    //         clients: function (HttpService) {
    //             return HttpService.get('/api/clients')
    //         }
    //     }

    // })
    .state('msp.mycompany', {
      url: '/mycompany/profile',
      templateUrl: '/templates/msp/my-company.html',
      controller: 'MyCompanyController',
      data: { access: 'modifyMyCompany', pageTitle: 'button.company' },
      resolve: {
        HttpService: 'HttpService',
        PermissionGroupModel: 'PermissionGroupModel',
        CompanyService: 'CompanyService',
        company: function (HttpService, CurrentUser, InputSanitizerService) {
          return HttpService.get(
            '/api/companies/' + InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
          );
        },
        users: function (HttpService, CurrentUser, InputSanitizerService) {
          return HttpService.get(
            '/api/admin/users?companyId=' +
              InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
          );
        },
        permissionGroups: function (PermissionGroupModel) {
          return PermissionGroupModel.query().$promise;
        },
      },
    })
    .state('msp.projectStatuses', {
      url: '/mycompany/projectstatuses',
      templateUrl: '/templates/msp/set-project-status.html',
      controller: 'CompanyStatusesController',
      data: { access: 'modifyMyCompany', pageTitle: 'button.company' },
      resolve: {
        HttpService: 'HttpService',
        PermissionGroupModel: 'PermissionGroupModel',
        CompanyService: 'CompanyService',
      },
    })
    .state('msp.variables', {
      url: '/mycompany/variables',
      templateUrl: '/templates/admin/client-variables.html',
      controller: 'ClientVariablesController',
      data: { pageTitle: 'company.variables' },
      resolve: {
        HttpService: 'HttpService',
        company: function ($stateParams, HttpService, CurrentUser, InputSanitizerService) {
          return HttpService.get(
            '/api/companies/' + InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
          );
        },
      },
    })
    .state('msp.cesInteractionTypes', {
      url: '/mycompany/ces/interactiontypes',
      templateUrl: '/templates/admin/ces-interaction-types.html',
      controller: 'CesInteractionTypesController',
      data: { pageTitle: 'label.client.ces.interactionTypes' },
      resolve: {
        HttpService: 'HttpService',
        interactionTypes: function (HttpService) {
          return HttpService.get('/api/admin/ces/interactiontypes');
        },
        engagementFactors: function (HttpService) {
          return HttpService.get('/api/admin/ces/engagementfactors');
        },
      },
    })
    .state('msp.email', {
      url: '/mycompany/email',
      templateUrl: '/templates/admin/email-settings.html',
      controller: 'EmailSettingsController',
      data: { pageTitle: 'company.email' },
      resolve: {
        HttpService: 'HttpService',
        company: function ($stateParams, HttpService, CurrentUser, InputSanitizerService) {
          return HttpService.get(
            '/api/companies/' + InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
          );
        },
      },
    })
    .state('msp.connectwise', {
      url: '/mycompany/connectwise',
      templateUrl: '/templates/msp/connectwise.html',
      controller: 'IntegrationConnectwiseController',
      data: {},
      resolve: {
        HttpService: 'HttpService',
        company: function ($stateParams, HttpService, CurrentUser, InputSanitizerService) {
          return HttpService.get(
            '/api/companies/' + InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
          );
        },
      },
    })
    .state('msp.integrations', {
      url: '/mycompany/integrations',
      templateUrl: '/templates/integration/integrations.html',
      controller: 'IntegrationsController',
      data: { access: 'useIntegrations' },
      resolve: {
        HttpService: 'HttpService',
        company: function ($stateParams, HttpService, CurrentUser, InputSanitizerService) {
          return HttpService.get(
            '/api/companies/' + InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
          );
        },
      },
    })
    .state('msp.payment', {
      url: '/mycompany/payment?:scope&:code',
      templateUrl: '/templates/admin/company-payment.html',
      controller: 'CompanyPaymentController',
      data: { access: 'managePayment', pageTitle: 'company.payment' },
      resolve: {
        HttpService: 'HttpService',
        company: function ($stateParams, HttpService, CurrentUser, InputSanitizerService) {
          return HttpService.get(
            '/api/companies/' + InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
          );
        },
        products: function (HttpService) {
          return HttpService.get('/api/payment/products');
        },
      },
    })
    .state('msp.colleagues', {
      url: '/mycompany/colleagues',
      templateUrl: '/templates/admin/colleagues.html',
      controller: 'ColleaguesController',
      data: { pageTitle: 'label.company.colleagues' },
      resolve: {
        HttpService: 'HttpService',
        company: function ($stateParams, HttpService, CurrentUser, InputSanitizerService) {
          return HttpService.get(
            '/api/companies/' + InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
          );
        },
      },
    })
    .state('msp.permissiongroups', {
      url: '/mycompany/permissiongroups',
      templateUrl: '/templates/vendor/marketplace.html',
      data: { pageTitle: 'label.marketplace.myPermissiongroups.title' },
      controller: 'MarketplaceController',
      resolve: {
        HttpService: 'HttpService',
        permissionGroups: function (HttpService) {
          return HttpService.get('/api/marketplace/permissiongroups?subscribed=true&hidden=true');
        },
        permissionGroupCategories: function (HttpService) {
          return HttpService.get('/api/vendor/permissiongroupcategories');
        },
      },
    })
    .state('msp.clientSegments', {
      url: '/mycompany/clientsegments',
      templateUrl: '/templates/msp/client-segments.html',
      data: { pageTitle: 'label.navbar.clientSegments' },
      controller: 'ClientSegmentsController',
      resolve: {
        HttpService: 'HttpService',
        clientSegments: function (HttpService) {
          return HttpService.get('/api/segments');
        },
      },
    })
    .state('msp.clientSegment', {
      url: '/mycompany/clientsegments/:segmentId',
      templateUrl: '/templates/msp/client-segment.html',
      controller: 'ClientSegmentController',
      data: { pageTitle: 'label.client.segment' },
      resolve: {
        HttpService: 'HttpService',
        interactionTypes: function (HttpService) {
          return HttpService.get('/api/admin/ces/interactiontypes');
        },
        clientSegment: function (HttpService, $stateParams) {
          return HttpService.get('/api/segments/' + $stateParams.segmentId + '?deep=true');
        },
        contactSeniorities: function (HttpService) {
          return HttpService.get('/api/admin/ces/contactseniorities');
        },
      },
    })
    .state('msp.clientAssetTypes', {
      url: '/mycompany/clientassettypes',
      templateUrl: '/templates/msp/client-asset-types.html',
      controller: 'ClientAssetTypesController',
      data: { pageTitle: 'label.client.asset.type' },
      resolve: {
        HttpService: 'HttpService',
      },
    })
    .state('msp.import', {
      url: '/import?:search',
      templateUrl: '/templates/integration/importpage.html',
      controller: 'ImportCompaniesWithContactsController',
      data: { pageTitle: 'label.import.contacts', access: 'useIntegrations' },
      resolve: {
        HttpService: 'HttpService',
        company: function (CurrentUser, HttpService, InputSanitizerService) {
          return HttpService.get(
            '/api/companies/' + InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
          );
        },
        industries: function (HttpService) {
          return HttpService.get('/api/lists/industry');
        },
        countries: function (HttpService) {
          return HttpService.get('/api/lists/country');
        },
        clients: function (HttpService) {
          return HttpService.get('/api/clients');
        },
        contactSeniorities: function (HttpService) {
          return HttpService.get('/api/admin/ces/contactseniorities');
        },
      },
    })
    .state('msp.importClient', {
      url: '/linkclient/:externalId',
      templateUrl: '/templates/integration/link-client.html',
      controller: 'IntegrationConnectwiseLinkClientController',
      resolve: {
        HttpService: 'HttpService',
        clients: function ($stateParams, HttpService) {
          return HttpService.get('/api/clients/');
        },
        contactSeniorities: function (HttpService) {
          return HttpService.get('/api/admin/ces/contactseniorities');
        },
      },
    })
    .state('msp.emailLogs', {
      url: '/email/log',
      templateUrl: '/templates/msp/email-log.html',
      data: { pageTitle: 'label.emailLog' },
      controller: 'EmailLogController',
    });

  $stateProvider
    .state('tasks', {
      abstract: true,
      template: '<ui-view/>',
      data: {},
    })

    .state('tasks.templates', {
      url: '/tasks/templates',
      templateUrl: '/templates/tasks/templates.html',
      controller: 'TaskTemplatesController',
      resolve: {
        HttpService: 'HttpService',
        CurrentUser: 'CurrentUser',
        taskTemplates: function (CurrentUser, HttpService) {
          return HttpService.get('/api/tasktemplates');
        },
      },
    });

  $stateProvider
    .state('meeting', {
      abstract: true,
      template: '<ui-view/>',
      data: {},
    })

    .state('meeting.overview', {
      url: '/meeting/meetings/:meetingId',
      templateUrl: '/templates/meeting/overview.html',
      controller: 'MeetingOverviewController',
      // data: {access: 'listMeetings'},
      resolve: {
        CurrentUser: 'CurrentUser',
        HttpService: 'HttpService',
        meeting: function ($stateParams, HttpService) {
          return HttpService.get('/api/meeting/meetings/' + $stateParams.meetingId);
        },
        services: function (HttpService) {
          return HttpService.get('/api/service/services?deleted=true');
        },
        taskTemplates: function (CurrentUser, HttpService) {
          return HttpService.get('/api/tasktemplates');
        },
        cesActivities: function ($stateParams, HttpService) {
          return HttpService.get(
            '/api/ces/activities?type=actual&interactionType=report&instanceId=' +
              $stateParams.meetingId,
          );
        },
        meetingWidgets: function (HttpService) {
          return HttpService.get('/api/lists/meetingWidget');
        },
      },
    })

    .state('meeting.snapshot', {
      url: '/meeting/meetings/:meetingId/snapshot/:snapshotId',
      templateUrl: '/templates/meeting/overview.html',
      controller: 'MeetingOverviewController',
      // data: {access: 'listMeetings'},
      resolve: {
        HttpService: 'HttpService',
        meeting: function ($stateParams, HttpService) {
          return HttpService.get(
            '/api/meeting/meetings/' +
              $stateParams.meetingId +
              '/snapshots/' +
              $stateParams.snapshotId,
          );
        },
        services: function (HttpService) {
          return HttpService.get('/api/service/services?deleted=true');
        },
        taskTemplates: function (CurrentUser, HttpService) {
          return HttpService.get('/api/tasktemplates');
        },
        cesActivities: function ($stateParams, HttpService) {
          return HttpService.get(
            '/api/ces/activities?type=actual&interactionType=report&instanceId=' +
              $stateParams.meetingId,
          );
        },
        meetingWidgets: function (HttpService) {
          return HttpService.get('/api/lists/meetingWidget');
        },
      },
    })

    // .state('meeting.print', {
    //     url: '/meeting/meetings/:meetingId/print',
    //     templateUrl: '/templates/meeting/print.html',
    //     controller: 'MeetingPrintController',
    //     // data: {access: 'printMeeting'},
    //     resolve: {
    //         HttpService: 'HttpService',
    //         meeting: function ($stateParams, HttpService) {
    //             return HttpService.get('/api/meetings/' + $stateParams.meetingId);
    //         },
    //         services: function (HttpService) {
    //             return HttpService.get('/api/service/services?deleted=true')
    //         }
    //     }
    // })

    // .state('meeting.vendortemplates', {
    //     url: '/meeting/vendortemplates',
    //     templateUrl: '/templates/meeting/templates.html',
    //     controller: 'MeetingTemplatesController',
    //     data: {access: 'createMeetingTemplate'},
    //     resolve: {
    //         HttpService: 'HttpService',
    //         meetingTemplates: function ($stateParams, HttpService) {
    //             return HttpService.get('/api/meeting/templates');
    //         }
    //     }
    // })
    .state('meeting.templates', {
      url: '/meeting/templates?:vendor:permissionGroupId',
      templateUrl: '/templates/meeting/templates.html',
      controller: 'MeetingTemplatesController',
      // data: {access: 'listMeetingTemplates'},
      resolve: {
        HttpService: 'HttpService',
        meetingTemplates: function ($stateParams, HttpService) {
          return HttpService.get(
            '/api/meeting/templates?vendor=' + ($stateParams.vendor == 'true'),
          );
        },
      },
    })

    .state('meeting.meetingTemplatesByPermissionGroup', {
      url: '/meeting/templates?:permissionGroupId',
      templateUrl: '/templates/meeting/templates.html',
      controller: 'MeetingTemplatesController',
      // data: {access: 'listMeetingTemplates'},
      resolve: {
        HttpService: 'HttpService',
        meetingTemplates: function ($stateParams, HttpService) {
          return HttpService.get(
            '/api/meeting/templates?vendor=' + ($stateParams.vendor == 'true'),
          );
        },
      },
    })

    .state('meeting.template', {
      url: '/meeting/templates/:meetingTemplateId?:copy:vendor',
      templateUrl: '/templates/meeting/template-edit.html',
      controller: 'MeetingTemplateEditController',
      // data: {access: 'listMeetingTemplates'},
      resolve: {
        CurrentUser: 'CurrentUser',
        HttpService: 'HttpService',
        meetingTemplate: function ($stateParams, HttpService) {
          return $stateParams.meetingTemplateId === 'new'
            ? null
            : HttpService.get('/api/meeting/templates/' + $stateParams.meetingTemplateId);
        },
        services: function ($stateParams, HttpService) {
          return $stateParams.template === 'true' ? null : HttpService.get('/api/service/services');
        },
        taskTemplates: function (CurrentUser, HttpService) {
          return HttpService.get('/api/tasktemplates');
        },
        serviceTemplates: function ($stateParams, HttpService) {
          return HttpService.get('/api/service/services/templates');
        },
        meetingWidgets: function (HttpService) {
          return HttpService.get('/api/lists/meetingWidget');
        },
      },
    });

  $stateProvider
    .state('clients', {
      abstract: true,
      template: '<ui-view/>',
      data: {
        pageTitle: 'Clients',
      },
    })
    // .state('clients.dashboard', {
    //     url: '/clients/dashboard',
    //     templateUrl: '/templates/client/ces-dashboard.html',
    //     controller: "CesDashboardController",
    //     resolve: {
    //         HttpService: 'HttpService',
    //         events: function ($stateParams, HttpService) {
    //             return HttpService.get('/api/ces/events');
    //         },
    //         clients: function (HttpService) {
    //             return HttpService.get('/api/clients');
    //         },
    //         clientSegments: function (HttpService) {
    //             return HttpService.get('/api/segments');
    //         },
    //         users: function (HttpService, CurrentUser) {
    //             return HttpService.get('/api/admin/users?companyId=' + CurrentUser.getUser().Company.id);
    //         }
    //     }
    // })
    .state('clients.list', {
      url: '/clientsces',
      templateUrl: '/templates/client/clients.html',
      resolve: {
        HttpService: 'HttpService',

        clients: function ($stateParams, HttpService) {
          return HttpService.get('/api/clients/');
        },
        clientSegments: function (HttpService) {
          return HttpService.get('/api/segments');
        },
        activities: function ($stateParams, HttpService) {
          return HttpService.get('/api/ces/activities');
        },
        users: function (HttpService, CurrentUser, InputSanitizerService) {
          return HttpService.get(
            '/api/admin/users?companyId=' +
              InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
          );
        },
      },
      controller: 'ClientsController',
    })
    .state('clients.view', {
      url: '/clients/:clientId',
      templateUrl: '/templates/client/client-overview.html',
      controller: function ($scope, $state, $stateParams, _, CurrentUser) {
        if (CurrentUser.can('listMeetings')) {
          $state.go('clients.meetings', { clientId: $stateParams.clientId });
          // } else if (CurrentUser.can('listSurveyResults')) {
          //     $state.go('clients.surveyresults', {
          //         clientId: $stateParams.clientId,
          //         type: _.get(CurrentUser.getUser(), 'surveyTypes[0]')
          //     });
        } else {
          $state.go('clients.contacts', { clientId: $stateParams.clientId });
        }
      },
      // resolve: {
      //     HttpService: 'HttpService',
      //     client: function ($stateParams, HttpService) {
      //         return HttpService.get('/api/clients/' + $stateParams.clientId);
      //     }
      // }
    })
    .state('clients.contacts', {
      url: '/clients/:clientId/contacts',
      templateUrl: '/templates/client/contacts.html',
      resolve: {
        HttpService: 'HttpService',
        client: function ($stateParams, HttpService) {
          return HttpService.get('/api/clients/' + $stateParams.clientId);
        },
        // clientSegments: function (HttpService) {
        //     return HttpService.get('/api/segments');
        // },
        contactSeniorities: function (HttpService) {
          return HttpService.get('/api/admin/ces/contactseniorities');
        },
      },
      controller: 'ClientContactsController',
    })
    .state('clients.emails', {
      url: '/clients/:clientId/emails',
      templateUrl: '/templates/client/emails.html',
      controller: 'ClientEmailsController',
      resolve: {
        HttpService: 'HttpService',
        client: function ($stateParams, HttpService) {
          return HttpService.get('/api/clients/' + $stateParams.clientId);
        },
        emailSequences: function ($stateParams, HttpService) {
          return HttpService.get('/api/clients/' + $stateParams.clientId + '/emailsequences');
          // },
          // clientSegments: function (HttpService) {
          //     return HttpService.get('/api/segments');
        },
      },
    })
    .state('clients.emailContacts', {
      url: '/clients/:clientId/emails/:sequenceId',
      templateUrl: '/templates/client/email-contacts.html',
      controller: 'ClientEmailContactsController',
      resolve: {
        HttpService: 'HttpService',
        client: function ($stateParams, HttpService) {
          return HttpService.get('/api/clients/' + $stateParams.clientId);
        },
        emailSequence: function ($stateParams, HttpService) {
          return HttpService.get(
            '/api/clients/' + $stateParams.clientId + '/emailsequences/' + $stateParams.sequenceId,
          );
          // },
          // clientSegments: function (HttpService) {
          //     return HttpService.get('/api/segments');
        },
      },
    })
    .state('clients.graders', {
      url: '/clients/:clientId/graders',
      templateUrl: '/templates/client/graders.html',
      resolve: {
        HttpService: 'HttpService',
        client: function ($stateParams, HttpService) {
          return HttpService.get('/api/clients/' + $stateParams.clientId);
        },
        graders: function ($stateParams, HttpService) {
          return HttpService.get('/api/graders/clientresponses/' + $stateParams.clientId);
          // },
          // clientSegments: function (HttpService) {
          //     return HttpService.get('/api/segments');
        },
      },
      controller: 'ClientGradersController',
    })
    .state('clients.leads', {
      url: '/leads',
      templateUrl: '/templates/client/leads.html',
      resolve: {
        HttpService: 'HttpService',
        contacts: function ($stateParams, HttpService) {
          return HttpService.get('/api/contacts');
        },
        leads: function ($stateParams, HttpService) {
          return HttpService.get('/api/leads');
          // },
          // clientSegments: function (HttpService) {
          //     return HttpService.get('/api/segments');
        },
      },
      controller: 'ClientLeadsController',
    })
    .state('clients.leadContacts', {
      url: '/leads/:emailDomain/contacts',
      templateUrl: '/templates/client/lead-contacts.html',
      resolve: {
        HttpService: 'HttpService',
        contacts: function ($stateParams, HttpService) {
          return HttpService.get('/api/contacts');
        },
        leads: function ($stateParams, HttpService) {
          return HttpService.get('/api/leads');
          // },
          // clientSegments: function (HttpService) {
          //     return HttpService.get('/api/segments');
        },
      },
      controller: 'ClientLeadContactsController',
    })
    .state('clients.projects', {
      url: '/clients/:clientId/projects',
      templateUrl: '/templates/client/client-projects.html',
      controller: 'ClientProjectsController',
      resolve: {
        HttpService: 'HttpService',
        CompanyService: 'CompanyService',
        clientProjects: function ($stateParams, HttpService, CurrentUser, InputSanitizerService) {
          return HttpService.get(
            '/api/clients/' +
              InputSanitizerService.sanitize($stateParams.clientId) +
              '/projects' +
              (CurrentUser.can('integrationProjects') ? '?integration=connectwise' : ''),
          );
        },
        client: function ($stateParams, HttpService) {
          return HttpService.get('/api/clients/' + $stateParams.clientId);
        },
        projects: function ($stateParams, HttpService) {
          return HttpService.get('/api/service/services?type=onetime');
        },
        users: function (CurrentUser, HttpService, InputSanitizerService) {
          return HttpService.get(
            '/api/companies/' +
              InputSanitizerService.sanitize(CurrentUser.getUser().Company.id) +
              '/users',
          );
        },
        company: function (CurrentUser, HttpService, InputSanitizerService) {
          return HttpService.get(
            '/api/companies/' + InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
          );
        },
        proposals: function ($stateParams, HttpService) {
          return HttpService.get('/api/clients/' + $stateParams.clientId + '/clientproposals');
        },
      },
    })
    .state('clients.assets', {
      url: '/clients/:clientId/assets',
      templateUrl: '/templates/client/client-assets.html',
      controller: 'ClientAssetsController',
      resolve: {
        HttpService: 'HttpService',
        CompanyService: 'CompanyService',
        clientProjects: function ($stateParams, HttpService, CurrentUser) {
          return HttpService.get(
            '/api/clients/' +
              $stateParams.clientId +
              '/projects' +
              (CurrentUser.can('integrationProjects') ? '?integration=connectwise' : ''),
          );
        },
        client: function ($stateParams, HttpService) {
          return HttpService.get('/api/clients/' + $stateParams.clientId);
        },
        projects: function ($stateParams, HttpService) {
          return HttpService.get('/api/service/services?type=onetime');
        },
        users: function (CurrentUser, HttpService, InputSanitizerService) {
          return HttpService.get(
            '/api/companies/' +
              InputSanitizerService.sanitize(CurrentUser.getUser().Company.id) +
              '/users',
          );
        },
        company: function (CurrentUser, HttpService, InputSanitizerService) {
          return HttpService.get(
            '/api/companies/' + InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
          );
        },
        assets: function ($stateParams, HttpService) {
          return HttpService.get('/api/clients/' + $stateParams.clientId + '/clientassets');
        },
      },
    })
    .state('clients.proposals', {
      url: '/clients/:clientId/proposals',
      templateUrl: '/templates/client/proposals.html',
      controller: 'ClientProposalsController',
      resolve: {
        HttpService: 'HttpService',
        CompanyService: 'CompanyService',
        clientProjects: function ($stateParams, HttpService, CurrentUser) {
          return HttpService.get(
            '/api/clients/' +
              $stateParams.clientId +
              '/projects' +
              (CurrentUser.can('integrationProjects') ? '?integration=connectwise' : ''),
          );
        },
        client: function ($stateParams, HttpService) {
          return HttpService.get('/api/clients/' + $stateParams.clientId);
        },
        projects: function ($stateParams, HttpService) {
          return HttpService.get('/api/service/services?type=onetime');
        },
        users: function (CurrentUser, HttpService, InputSanitizerService) {
          return HttpService.get(
            '/api/companies/' +
              InputSanitizerService.sanitize(CurrentUser.getUser().Company.id) +
              '/users',
          );
        },
        company: function (CurrentUser, HttpService, InputSanitizerService) {
          return HttpService.get(
            '/api/companies/' + InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
          );
        },
        proposals: function ($stateParams, HttpService) {
          return HttpService.get('/api/clients/' + $stateParams.clientId + '/clientproposals');
        },
      },
    })
    .state('clients.syncprojects', {
      url: '/clients/:clientId/projects/sync',
      templateUrl: '/templates/connectwise/sync-projects.html',
      controller: 'ClientSyncProjectsController',
      resolve: {
        HttpService: 'HttpService',
        CompanyService: 'CompanyService',
        projectStatuses: function (CompanyService) {
          return CompanyService.getProjectStatuses();
        },
        clientProjects: function ($stateParams, HttpService) {
          return HttpService.get('/api/clients/' + $stateParams.clientId + '/projects/integration');
        },
        connectwiseCompany: function ($stateParams, HttpService) {
          return HttpService.get('/api/clients/' + $stateParams.clientId + '/integration/company');
        },
        connectwiseUsers: function (HttpService) {
          return HttpService.get('/api/vcio/connectwise/users');
        },
        projectBoards: function (HttpService) {
          return HttpService.get('/api/vcio/connectwise/boards/project');
        },
        clientContacts: function ($stateParams, HttpService) {
          return HttpService.get('/api/clients/' + $stateParams.clientId);
        },
      },
    })
    .state('clients.delivery', {
      url: '/clients/:clientId/delivery/:system',
      templateUrl: '/templates/client/delivery.html',
      controller: 'ClientDeliveryController',
      resolve: {
        HttpService: 'HttpService',
        client: function ($stateParams, HttpService) {
          return HttpService.get('/api/clients/' + $stateParams.clientId);
        },
        clientProjects: function ($stateParams, HttpService) {
          return HttpService.get(
            '/api/clients/' + $stateParams.clientId + '/projects?integration=connectwise',
          );
        },
      },
    })
    .state('clients.surveyresults', {
      url: '/clients/:clientId/surveyresults/:type',
      templateUrl: '/templates/sales/survey-results.html',
      controller: 'SurveyResultsController',
      resolve: {
        HttpService: 'HttpService',
        surveyResultService: 'surveyResultService',
        surveys: function (HttpService, $stateParams) {
          return HttpService.get('/api/surveys?type=' + $stateParams.type);
        },
        surveyResults: function ($stateParams, surveyResultService) {
          return surveyResultService.getSurveyResultsWithScore(
            $stateParams.clientId,
            $stateParams.type,
          );
        },
        client: function ($stateParams, HttpService) {
          return HttpService.get('/api/clients/' + $stateParams.clientId);
          // },
          // clientSegments: function (HttpService) {
          //     return HttpService.get('/api/segments');
        },
      },
    })
    .state('clients.meetings', {
      url: '/clients/:clientId/meetings',
      templateUrl: '/templates/meeting/meetings.html',
      controller: 'MeetingsController',
      // data: {access: 'listMeetings'},
      resolve: {
        HttpService: 'HttpService',
        surveyResultService: 'surveyResultService',
        meetings: function ($stateParams, HttpService) {
          return HttpService.get('/api/meeting/meetings?clientId=' + $stateParams.clientId);
        },
        meetingTemplates: function (HttpService) {
          return HttpService.get('/api/meeting/templates?type=client');
        },
        client: function ($stateParams, HttpService) {
          return HttpService.get('/api/clients/' + $stateParams.clientId);
          // },
          // clientSegments: function (HttpService) {
          //     return HttpService.get('/api/segments');
        },
      },
    })
    .state('clients.contracts', {
      url: '/clients/:clientId/contracts',
      templateUrl: '/templates/client/contracts.html',
      controller: 'ClientContractsController',
      resolve: {
        HttpService: 'HttpService',
        serviceBundles: function ($stateParams, HttpService) {
          return HttpService.get('/api/service/servicebundles');
        },
        contracts: function (HttpService, $stateParams) {
          return HttpService.get('/api/vcio/contracts?clientId=' + $stateParams.clientId);
        },
        client: function ($stateParams, HttpService) {
          return HttpService.get('/api/clients/' + $stateParams.clientId);
          // },
          // clientSegments: function (HttpService) {
          //     return HttpService.get('/api/segments');
        },
      },
    })
    .state('clients.ces', {
      url: '/clients/:clientId/ces?:eventId',
      templateUrl: '/templates/client/ces.html',
      controller: 'ClientCesController',
      resolve: {
        HttpService: 'HttpService',
        client: function ($stateParams, HttpService) {
          return HttpService.get('/api/clients/' + $stateParams.clientId);
        },
        activities: function ($stateParams, HttpService) {
          return HttpService.get('/api/ces/activities?clientId=' + $stateParams.clientId);
        },
        interactionTypes: function ($stateParams, HttpService) {
          return HttpService.get('/api/admin/ces/interactiontypes');
        },
        contactSeniorities: function (HttpService) {
          return HttpService.get('/api/admin/ces/contactseniorities');
        },
        clientSegments: function (HttpService) {
          return HttpService.get('/api/segments');
        },
      },
    });

  $stateProvider
    .state('client', {
      abstract: true,
      template: '<ui-view/>',
      data: {
        pageTitle: 'Clients',
      },
    })
    .state('client.meetings', {
      url: '/client/meetings?:meetingTemplateId',
      templateUrl: '/templates/client/meetings.html',
      controller: 'ClientsMeetingsController',
      resolve: {
        HttpService: 'HttpService',
        meetingTemplates: function (HttpService) {
          return HttpService.get('/api/meeting/templates?type=client');
        },
        meetings: function (HttpService) {
          return HttpService.get('/api/meeting/meetings?type=client');
        },
        clients: function (HttpService) {
          return HttpService.get('/api/clients');
        },
        users: function (CurrentUser, HttpService, InputSanitizerService) {
          return HttpService.get(
            '/api/companies/' +
              InputSanitizerService.sanitize(CurrentUser.getUser().Company.id) +
              '/users',
          );
        },
      },
    })
    .state('client.projects', {
      url: '/client/projects',
      templateUrl: '/templates/client/projects.html',
      controller: 'ClientsProjectsController',
      resolve: {
        HttpService: 'HttpService',
        CompanyService: 'CompanyService',
        clientProjects: function ($stateParams, HttpService) {
          return HttpService.get('/api/client/projects');
        },
        projects: function ($stateParams, HttpService) {
          return HttpService.get('/api/service/services?type=onetime');
        },
        users: function (CurrentUser, HttpService, InputSanitizerService) {
          return HttpService.get(
            '/api/companies/' +
              InputSanitizerService.sanitize(CurrentUser.getUser().Company.id) +
              '/users',
          );
        },
        clients: function (HttpService) {
          return HttpService.get('/api/clients');
        },
        projectStatuses: function (HttpService) {
          return HttpService.get('/api/company/projectStatuses');
        },
      },
    });

  $stateProvider
    .state('ces', {
      abstract: true,
      template: '<ui-view/>',
      data: {
        pageTitle: 'Ces',
      },
    })
    .state('ces.activities', {
      url: '/ces/activities',
      templateUrl: '/templates/client/ces-activities.html',
      controller: 'CesActivitiesController',
      resolve: {
        HttpService: 'HttpService',
        activities: function ($stateParams, HttpService) {
          return HttpService.get('/api/ces/activities');
        },
        clients: function ($stateParams, HttpService) {
          return HttpService.get('/api/clients/');
        },
        clientSegments: function (HttpService) {
          return HttpService.get('/api/segments');
        },
        interactionTypes: function ($stateParams, HttpService) {
          return HttpService.get('/api/admin/ces/interactiontypes');
        },
        contactSeniorities: function (HttpService) {
          return HttpService.get('/api/admin/ces/contactseniorities');
        },
        users: function (HttpService, CurrentUser, InputSanitizerService) {
          return HttpService.get(
            '/api/admin/users?companyId=' +
              InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
          );
        },
      },
    });
  $stateProvider
    .state('tools', {
      abstract: true,
      template: '<ui-view/>',
      data: {
        pageTitle: 'Tools',
        // navbar: '/templates/tools/header.html'
      },
    })
    .state('tools.permissiongroups', {
      url: '/tools/permissiongroups',
      templateUrl: '/templates/academy/permission-groups.html',
      data: { pageTitle: 'academy.menu.permissiongroups' },
      controller: 'VendorPermissionGroupsController',
      resolve: {
        HttpService: 'HttpService',
        CurrentUser: 'CurrentUser',
        PermissionGroupModel: 'PermissionGroupModel',
        permissiongroups: function (PermissionGroupModel) {
          return PermissionGroupModel.query().$promise;
        },
        // roadmaps: function (AssetModel) {
        //     return AssetModel.query({type: 'roadmap'}).$promise;
        // },
        assetCategories: function (HttpService) {
          return HttpService.get('/api/academy/assetcategorytree');
        },
        services: function (HttpService) {
          return HttpService.get('/api/service/services/templates');
        },
        serviceBundles: function (HttpService) {
          return HttpService.get('/api/service/servicebundles/templates');
        },
        emailSequences: function (CurrentUser, HttpService) {
          if (CurrentUser.can('listEmailSequences')) {
            return HttpService.get('/api/emailsequences?template=true');
          } else {
            return [];
          }
        },
        graders: function (CurrentUser, HttpService) {
          if (CurrentUser.can('listGraders')) {
            return HttpService.get('/api/graders?type=template');
          } else {
            return [];
          }
        },
        surveysTemplates: function (CurrentUser, HttpService) {
          if (CurrentUser.can('listSurveys')) {
            return HttpService.get('/api/surveytemplates?type=needs');
          } else {
            return [];
          }
        },
        meetingTemplates: function (CurrentUser, HttpService) {
          if (CurrentUser.can('listMeetingTemplates')) {
            return HttpService.get('/api/meeting/templates?vendor=true');
          } else {
            return [];
          }
        },
      },
    })
    .state('tools.permissionGroupAdd', {
      url: '/tools/permissiongroups/new',
      templateUrl: '/templates/academy/permission-group-edit.html',
      controller: 'VendorPermissionGroupEditController',
      data: {
        pageTitle: 'academy.menu.editPermissionGroup',
        isNew: true,
      },
      resolve: {
        CurrentUser: 'CurrentUser',
        HttpService: 'HttpService',
        PermissionGroupCategoryModel: 'PermissionGroupCategoryModel',
        permissionGroup: function () {
          return null;
        },
        permissionGroupCategories: function (PermissionGroupCategoryModel) {
          return PermissionGroupCategoryModel.query().$promise;
        },
        courses: function (HttpService) {
          return HttpService.get('/api/academy/assets?type=course');
        },
        users: function (CurrentUser, HttpService, InputSanitizerService) {
          return HttpService.get(
            '/api/admin/users?companyId=' +
              InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
          );
        },
      },
    })
    .state('tools.permissionGroupEdit', {
      url: '/tools/permissiongroups/:permissionGroupId',
      templateUrl: '/templates/academy/permission-group-edit.html',
      controller: 'VendorPermissionGroupEditController',
      data: {
        pageTitle: 'academy.menu.editPermissionGroup',
        isNew: false,
      },
      resolve: {
        CurrentUser: 'CurrentUser',
        HttpService: 'HttpService',
        PermissionGroupCategoryModel: 'PermissionGroupCategoryModel',
        permissionGroup: function (HttpService, $stateParams) {
          return HttpService.get('/api/vendor/permissiongroups/' + $stateParams.permissionGroupId);
        },
        permissionGroupCategories: function (PermissionGroupCategoryModel) {
          return PermissionGroupCategoryModel.query().$promise;
        },
        courses: function (HttpService) {
          return HttpService.get('/api/academy/assets?type=course');
        },
        users: function (CurrentUser, HttpService, InputSanitizerService) {
          return HttpService.get(
            '/api/admin/users?companyId=' +
              InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
          );
        },
      },
    })
    .state('tools.members', {
      url: '/tools/members',
      templateUrl: '/templates/vendor/members.html',
      controller: 'VendorMembersController',
      data: { pageTitle: 'title.companies', access: 'manageAcademy' },
      resolve: {
        HttpService: 'HttpService',
        PermissionGroupModel: 'PermissionGroupModel',
        permissiongroups: function (PermissionGroupModel) {
          return PermissionGroupModel.query().$promise;
        },
        companies: function ($stateParams, HttpService) {
          return HttpService.get('/api/vendor/members?limit=50&permissiongroups=true');
          // },
          // groupClients: function ($stateParams, HttpService) {
          //     return HttpService.get('/api/vendor/groupclients');
        },
      },
    });
  $stateProvider
    .state('search', {
      abstract: true,
      template: '<ui-view/>',
      data: {
        pageTitle: 'Search',
        // navbar: '/templates/tools/header.html'
      },
    })
    .state('search.results', {
      url: '/search/result/:searchString?:type',
      templateUrl: '/templates/search/search-result.html',
      controller: 'SearchResultController',
      data: { pageTitle: 'label.search' },
      resolve: {
        results: function (HttpService, $stateParams) {
          return HttpService.get(
            '/api/search/result/' + $stateParams.searchString + '?type=' + $stateParams.type,
          );
        },
      },
    });

  $stateProvider
    .state('marketplace', {
      abstract: true,
      template: '<ui-view/>',
      data: {
        pageTitle: 'Marketplace',
      },
    })
    .state('marketplace.marketplace', {
      url: '/marketplace',
      templateUrl: '/templates/vendor/marketplace.html',
      data: { pageTitle: 'label.marketplace.title' },
      controller: 'MarketplaceController',
      resolve: {
        HttpService: 'HttpService',
        permissionGroups: function (HttpService) {
          return HttpService.get('/api/marketplace/permissiongroups');
        },
        permissionGroupCategories: function (HttpService) {
          return HttpService.get('/api/vendor/permissiongroupcategories');
        },
      },
    })
    .state('marketplace.category', {
      url: '/marketplace/categories/:categoryId',
      templateUrl: '/templates/vendor/marketplace.html',
      data: { pageTitle: 'label.marketplace.title' },
      controller: 'MarketplaceController',
      resolve: {
        HttpService: 'HttpService',
        permissionGroups: function (HttpService, $stateParams) {
          return HttpService.get(
            '/api/marketplace/permissiongroups?categoryId=' + $stateParams.categoryId,
          );
        },
        permissionGroupCategories: function (HttpService) {
          return HttpService.get('/api/vendor/permissiongroupcategories');
        },
      },
    })
    .state('marketplace.permissiongroup', {
      url: '/marketplace/permissiongroups/:permissionGroupId?:nextSteps',
      templateUrl: '/templates/vendor/marketplace-permission-group.html',
      controller: 'MarketplacePermissionGroupController',
      data: { pageTitle: 'label.academy' },
      resolve: {
        HttpService: 'HttpService',
        permissiongroup: function ($stateParams, HttpService) {
          return HttpService.get(
            '/api/marketplace/permissiongroups/' + $stateParams.permissionGroupId,
          );
        },
        permissionGroupCategories: function (HttpService) {
          return HttpService.get('/api/vendor/permissiongroupcategories');
        },
        services: function (HttpService) {
          return HttpService.get('/api/service/services');
        },
        serviceBundles: function (CurrentUser, HttpService) {
          if (CurrentUser.can('listServiceBundles')) {
            return HttpService.get('/api/service/servicebundles');
          } else {
            return [];
          }
        },
        emailSequences: function (CurrentUser, HttpService) {
          if (CurrentUser.can('listEmailSequences')) {
            return HttpService.get('/api/emailsequences');
          } else {
            return [];
          }
        },
        graders: function (CurrentUser, HttpService) {
          if (CurrentUser.can('listGraders')) {
            return HttpService.get('/api/graders');
          } else {
            return [];
          }
        },
        meetingTemplates: function (CurrentUser, HttpService) {
          if (CurrentUser.can('listMeetingTemplates')) {
            return HttpService.get('/api/meeting/templates');
          } else {
            return [];
          }
        },
        surveys: function (CurrentUser, HttpService) {
          if (CurrentUser.can('listSurveys')) {
            return HttpService.get('/api/surveys?type=needs');
          } else {
            return [];
          }
        },
      },
    })
    .state('marketplace.vendors', {
      url: '/marketplace/vendors',
      templateUrl: '/templates/academy/vendors.html',
      controller: 'VendorsController',
      data: { pageTitle: 'label.academy' },
      resolve: {
        HttpService: 'HttpService',
        vendors: function ($stateParams, HttpService) {
          return HttpService.get('/api/vendors');
        },
      },
    })
    .state('marketplace.vendor', {
      url: '/marketplace/vendors/:vendorId',
      templateUrl: '/templates/academy/vendor.html',
      controller: 'VendorController',
      data: { pageTitle: 'label.academy' },
      resolve: {
        HttpService: 'HttpService',
        company: function ($stateParams, HttpService) {
          return HttpService.get('/api/vendors/' + $stateParams.vendorId);
        },
      },
    })
    .state('marketplace.mypermissiongroups', {
      url: '/marketplace/mypermissiongroups',
      templateUrl: '/templates/vendor/vendor-permissiongroups.html',
      data: { pageTitle: 'label.marketplace.vendorPermissiongroups.title' },
      controller: 'MarketplaceMyPermissionGroupsController',
      resolve: {
        HttpService: 'HttpService',
        company: function (CurrentUser, HttpService, InputSanitizerService) {
          return HttpService.get(
            '/api/vendors/' + InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
          );
        },
        permissionGroups: function (HttpService) {
          return HttpService.get('/api/marketplace/permissiongroups?subscribed=true');
        },
        permissionGroupCategories: function (HttpService) {
          return HttpService.get('/api/vendor/permissiongroupcategories');
        },
      },
    });

  $stateProvider
    .state('sales', {
      abstract: true,
      template: '<ui-view/>',
      data: {
        pageTitle: 'Sales',
        access: 'useSales',
        module: 'sales',
      },
    })
    .state('sales.overview', {
      url: '/sales/overview/:surveyResultId?:type',
      templateUrl: '/templates/sales/overview.html',
      data: { pageTitle: 'sales.menu.companies', access: 'useSales' },
      resolve: {
        HttpService: 'HttpService',
        surveyResult: function ($stateParams, HttpService) {
          return HttpService.get('/api/surveyresults/' + $stateParams.surveyResultId + '/scores');
          //     return HttpService.get('/api/surveyresults/' + $stateParams.surveyResultId + '/dictionary');
        },
        services: function (HttpService, $q) {
          return HttpService.get('/api/service/services?deleted=true').then(function (services) {
            var result = {};
            services.forEach(function (service) {
              result[service.id] = service;
            });
            return result;
          });
        },
        projectStatuses: function (HttpService) {
          return HttpService.get('/api/lists/projectStatuses');
        },
      },
      controller: 'OverviewController',
    })
    .state('sales.reportPrint', {
      url: '/reports/:surveyResultId/print',
      templateUrl: '/templates/sales/overview-print.html',
      data: { pageTitle: 'sales.menu.companies', access: 'useSales' },
      resolve: {
        HttpService: 'HttpService',
        surveyResult: function ($stateParams, HttpService) {
          return HttpService.get('/api/surveyresults/' + $stateParams.surveyResultId + '/scores');
        },
        services: function (HttpService) {
          return HttpService.get('/api/service/services?deleted=true').then(function (services) {
            var result = {};
            services.forEach(function (service) {
              result[service.id] = service;
            });
            return result;
          });
        },
      },
      controller: 'OverviewPrintController',
    })
    // .state('sales.reports', {
    //     url: '/report/:contactId',
    //     templateUrl: '/templates/msp/survey-report.html',
    //     data: {pageTitle: 'Reports', access: 'useSales'},
    //     controller: 'ReportController'
    // })
    .state('sales.reportExport', {
      url: '/sales/reports/exports/:reportExportId',
      templateUrl: '/templates/report/export/export-edit.html',
      controller: 'ReportExportEditController',
      data: { access: 'manageSurveys', pageTitle: 'sales.menu.surveys' },
      resolve: {
        HttpService: 'HttpService',
        reportExport: function (HttpService, $stateParams) {
          return HttpService.get('/api/sales/reports/exports/' + $stateParams.reportExportId);
        },
      },
    })
    .state('sales.reportExports', {
      url: '/sales/reports/exports',
      templateUrl: '/templates/report/export/report-exports-list.html',
      controller: 'ReportExportsController',
      data: { pageTitle: 'ReportExports', access: 'useSales' },
      resolve: {
        HttpService: 'HttpService',
        surveys: function (HttpService) {
          return HttpService.get('/api/surveys');
        },
        exports: function (HttpService) {
          return HttpService.get('/api/sales/reports/exports');
        },
      },
    })
    .state('sales.pages', {
      url: '/sales/pages',
      templateUrl: '/templates/msp/company-pages.html',
      controller: 'CompanyPagesController',
      data: { pageTitle: 'sales.menu.pages' },
      resolve: {
        HttpService: 'HttpService',
        company: function ($stateParams, HttpService, CurrentUser, InputSanitizerService) {
          return HttpService.get(
            '/api/companies/' + InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
          );
        },
        pages: function ($stateParams, HttpService) {
          return HttpService.get('/api/sales/pages');
        },
      },
    })
    .state('sales.surveytemplates', {
      url: '/sales/surveytemplates?:type',
      templateUrl: '/templates/sales/surveys.html',
      controller: 'SurveysController',
      data: { access: 'useSales', pageTitle: 'sales.menu.surveytemplates' },
      resolve: {
        HttpService: 'HttpService',
        surveys: function ($stateParams, HttpService) {
          return HttpService.get('/api/surveytemplates?type=' + $stateParams.type);
        },
      },
    })
    .state('sales.surveytemplate', {
      url: '/sales/surveytemplates/:surveyId?:type&:serviceType',
      templateUrl: '/templates/report/edit.html',
      controller: 'ReportEditController',
      data: { access: 'manageSurveyTemplates', pageTitle: 'sales.menu.surveytemplates' },
      resolve: {
        HttpService: 'HttpService',
        survey: function ($stateParams, HttpService) {
          return $stateParams.surveyId === 'new'
            ? null
            : HttpService.get('/api/surveytemplates/' + $stateParams.surveyId);
        },
        services: function () {
          return undefined; //HttpService.get('/api/service/services/templates');
        },
        serviceTemplates: function ($stateParams, HttpService) {
          return HttpService.get('/api/service/services/templates');
        },
        copy: function () {
          return false;
        },
        template: function () {
          return true;
        },
      },
    })
    .state('sales.surveysByPermissionGroup', {
      url: '/sales/surveys?:type:permissionGroupId',
      templateUrl: '/templates/sales/surveys.html',
      controller: 'SurveysController',
      data: { access: 'useSales', pageTitle: 'sales.menu.surveys' },
      resolve: {
        HttpService: 'HttpService',
        surveys: function ($stateParams, HttpService) {
          return HttpService.get('/api/surveys?count=true&type=' + $stateParams.type);
        },
      },
    })
    .state('sales.surveys', {
      url: '/sales/surveys?:type',
      templateUrl: '/templates/sales/surveys.html',
      controller: 'SurveysController',
      data: { access: 'useSales', pageTitle: 'sales.menu.surveys' },
      resolve: {
        HttpService: 'HttpService',
        surveys: function ($stateParams, HttpService) {
          return HttpService.get('/api/surveys?count=true&type=' + $stateParams.type);
        },
      },
    })
    .state('sales.survey', {
      url: '/sales/surveys/:surveyId?:type',
      templateUrl: '/templates/report/edit.html',
      controller: 'ReportEditController',
      data: { access: 'manageSurveys', pageTitle: 'sales.menu.surveys' },
      resolve: {
        HttpService: 'HttpService',
        survey: function ($stateParams, HttpService) {
          return $stateParams.surveyId === 'new'
            ? null
            : HttpService.get('/api/surveys/' + $stateParams.surveyId);
        },
        services: function ($stateParams, HttpService) {
          return HttpService.get('/api/service/services');
        },
        serviceTemplates: function ($stateParams, HttpService) {
          return HttpService.get('/api/service/services/templates');
        },
        copy: function () {
          return false;
        },
        template: function () {
          return false;
        },
      },
    })
    .state('sales.surveycopy', {
      url: '/sales/surveys/:surveyId/copy?:type',
      templateUrl: '/templates/report/edit.html',
      controller: 'ReportEditController',
      data: { access: 'manageSurveys', pageTitle: 'sales.menu.surveys' },
      resolve: {
        HttpService: 'HttpService',
        survey: function ($stateParams, HttpService) {
          return $stateParams.surveyId === 'new'
            ? null
            : HttpService.get('/api/surveys/' + $stateParams.surveyId);
        },
        services: function ($stateParams, HttpService) {
          return HttpService.get('/api/service/services');
        },
        serviceTemplates: function ($stateParams, HttpService) {
          return HttpService.get('/api/service/services/templates?all=true');
        },
        copy: function () {
          return true;
        },
        template: function () {
          return false;
        },
      },
    })
    .state('sales.surveytemplatecopy', {
      url: '/sales/surveytemplates/:surveyId/copy',
      templateUrl: '/templates/report/edit.html',
      controller: 'ReportEditController',
      data: { access: 'manageSurveys', pageTitle: 'sales.menu.surveys' },
      resolve: {
        HttpService: 'HttpService',
        survey: function ($stateParams, HttpService) {
          return $stateParams.surveyId === 'new'
            ? null
            : HttpService.get('/api/surveytemplates/' + $stateParams.surveyId);
        },
        services: function ($stateParams, HttpService) {
          return HttpService.get('/api/service/services');
        },
        serviceTemplates: function ($stateParams, HttpService) {
          return HttpService.get('/api/service/services/templates');
        },
        copy: function () {
          return true;
        },
        template: function () {
          return true;
        },
      },
    });

  $stateProvider
    .state('marketing', {
      abstract: true,
      template: '<ui-view/>',
      data: {
        pageTitle: 'Demand Generation',
        access: 'useMarketing',
        module: 'marketing',
        // navbar: '/templates/marketing/header.html'
      },
    })
    .state('marketing.home', {
      url: '/marketing',
      templateUrl: '/templates/msp/marketing.html',
      controller: 'MarketingController',
      data: { pageTitle: 'My Marketing' },
    })
    // .state('marketing.contacts', {
    //     url: '/marketing/contacts',
    //     templateUrl: '/templates/msp/contacts.html',
    //     controller: 'ContactsController',
    //     data: {pageTitle: 'label.leads'}
    // })
    .state('marketing.visitors', {
      url: '/marketing/visitors',
      templateUrl: '/templates/marketing/visitors.html',
      controller: 'LeadVisitorsController',
      data: { pageTitle: 'label.visitors' },
      resolve: {
        HttpService: 'HttpService',
        visitors: function (HttpService) {
          return HttpService.get('/api/leadvisitors');
        },
      },
    })
    .state('marketing.gradersByPermissionGroup', {
      url: '/marketing/graders?:permissionGroupId',
      templateUrl: '/templates/marketing/graders.html',
      controller: 'GradersController',
      data: { pageTitle: 'graders.title' },
      resolve: {
        HttpService: 'HttpService',
        graders: function (HttpService) {
          return HttpService.get('/api/graders?type=grader');
        },
      },
    })
    .state('marketing.graders', {
      url: '/marketing/graders',
      templateUrl: '/templates/marketing/graders.html',
      controller: 'GradersController',
      data: { pageTitle: 'graders.title' },
      resolve: {
        HttpService: 'HttpService',
        graders: function (HttpService) {
          return HttpService.get('/api/graders?type=grader');
        },
      },
    })
    .state('marketing.graderTemplates', {
      url: '/marketing/gradertemplates',
      templateUrl: '/templates/marketing/graders.html',
      controller: 'GradersController',
      data: { pageTitle: 'graders.templateTitle' },
      resolve: {
        HttpService: 'HttpService',
        graders: function (HttpService) {
          return HttpService.get('/api/graders?type=template');
        },
      },
    })
    .state('marketing.grader', {
      url: '/marketing/graders/:graderId',
      templateUrl: '/templates/marketing/grader-edit.html',
      controller: 'GraderEditController',
      data: { pageTitle: 'Grader Edit' },
      resolve: {
        HttpService: 'HttpService',
        grader: function ($stateParams, HttpService) {
          return $stateParams.graderId === 'new'
            ? null
            : HttpService.get('/api/graders/' + $stateParams.graderId);
        },
        copy: function () {
          return false;
        },
      },
    })
    .state('marketing.graderCopy', {
      url: '/marketing/graders/:graderId/copy',
      templateUrl: '/templates/marketing/grader-edit.html',
      controller: 'GraderEditController',
      data: { pageTitle: 'Grader Edit', access: 'useMarketing' },
      resolve: {
        HttpService: 'HttpService',
        grader: function ($stateParams, HttpService) {
          return HttpService.get('/api/graders/' + $stateParams.graderId);
        },
        copy: function () {
          return true;
        },
      },
    })
    .state('marketing.emailsequencesByPermissionGroup', {
      url: '/marketing/emailsequences?:permissionGroupId',
      templateUrl: '/templates/msp/email-sequences.html',
      controller: 'EmailSequencesController',
      data: { pageTitle: 'emailSequences.title' },
      resolve: {
        HttpService: 'HttpService',
        emailsequences: function (HttpService) {
          return HttpService.get('/api/emailsequences?template=false');
        },
      },
    })
    .state('marketing.emailsequences', {
      url: '/marketing/emailsequences',
      templateUrl: '/templates/msp/email-sequences.html',
      controller: 'EmailSequencesController',
      data: { pageTitle: 'emailSequences.title' },
      resolve: {
        HttpService: 'HttpService',
        emailsequences: function (HttpService) {
          return HttpService.get('/api/emailsequences?template=false');
        },
      },
    })
    .state('marketing.emailsequenceTemplates', {
      url: '/marketing/emailsequencetemplates',
      templateUrl: '/templates/msp/email-sequences.html',
      controller: 'EmailSequencesController',
      data: { pageTitle: 'emailSequences.templateTitle' },
      resolve: {
        HttpService: 'HttpService',
        emailsequences: function (HttpService) {
          return HttpService.get('/api/emailsequences?template=true');
        },
      },
    })
    .state('marketing.emailsequence', {
      url: '/marketing/emailsequence/:emailsequenceId',
      templateUrl: '/templates/msp/email-sequence-edit.html',
      controller: 'EmailSequenceEditController',
      data: { pageTitle: 'emailSequenceEdit.title' },
      resolve: {
        HttpService: 'HttpService',
        emailsequence: function ($stateParams, HttpService) {
          return $stateParams.emailsequenceId === 'new'
            ? null
            : HttpService.get('/api/emailsequences/' + $stateParams.emailsequenceId);
        },
        copy: function () {
          return false;
        },
      },
    })
    .state('marketing.emailsequenceCopy', {
      url: '/marketing/emailsequence/:emailsequenceId/copy',
      templateUrl: '/templates/msp/email-sequence-edit.html',
      controller: 'EmailSequenceEditController',
      data: { pageTitle: 'emailSequenceEdit.title' },
      resolve: {
        HttpService: 'HttpService',
        emailsequence: function ($stateParams, HttpService) {
          return HttpService.get('/api/emailsequences/' + $stateParams.emailsequenceId);
        },
        copy: function () {
          return true;
        },
      },
    })
    .state('marketing.leadmagnets', {
      url: '/marketing/leadmagnets',
      templateUrl: '/templates/msp/leadmagnets.html',
      controller: 'LeadMagnetsController',
      data: { pageTitle: 'title.leadmagnets' },
      resolve: {
        HttpService: 'HttpService',
        leadmagnets: function (HttpService) {
          return HttpService.get('/api/leadmagnets');
        },
      },
    })
    .state('marketing.leadmagnet', {
      url: '/marketing/leadmagnets/:leadmagnetId',
      templateUrl: '/templates/msp/leadmagnet-edit.html',
      controller: 'LeadMagnetEditController',
      data: { pageTitle: 'title.leadmagnetEdit' },
      resolve: {
        HttpService: 'HttpService',
        leadmagnet: function ($stateParams, HttpService) {
          return $stateParams.leadmagnetId === 'new'
            ? null
            : HttpService.get('/api/leadmagnets/' + $stateParams.leadmagnetId);
        },
      },
    })
    .state('marketing.library', {
      url: '/marketing/library',
      templateUrl: '/templates/msp/library.html',
      controller: 'LibraryController',
      data: { pageTitle: 'title.library' },
    })
    .state('marketing.myfiles', {
      url: '/marketing/myfiles',
      templateUrl: '/templates/msp/myfiles.html',
      controller: 'MyFilesController',
      data: { pageTitle: 'title.myLibrary' },
    })
    .state('marketing.graderLeads', {
      url: '/graders/:graderId/leads',
      templateUrl: '/templates/marketing/grader-leads.html',
      controller: 'GraderLeadsController',
      data: { pageTitle: 'Grader Leads', access: 'useMarketing' },
    })
    .state('marketing.graderStatistics', {
      url: '/graders/:graderId/statistics',
      templateUrl: '/templates/marketing/grader-statistics.html',
      controller: 'GraderStatisticsController',
      data: { pageTitle: 'Grader Statistics', access: 'useMarketing' },
      resolve: {
        HttpService: 'HttpService',
        grader: function ($stateParams, HttpService) {
          return HttpService.get('/api/graders/' + $stateParams.graderId);
        },
      },
    })
    .state('marketing.leadmagnetStatistics', {
      url: '/leadmagnetstats',
      templateUrl: '/templates/marketing/leadmagnet-statistics.html',
      controller: 'LeadMagnetStatisticsController',
      data: { pageTitle: 'Lead Magnet Statistics', access: 'useMarketing' },
    });

  $stateProvider
    .state('academy', {
      abstract: true,
      template: '<ui-view/>',
      data: {
        pageTitle: 'label.academy',
        access: 'useAcademy',
        module: 'academy',
      },
    })
    .state('academy.images', {
      url: '/academy/images',
      templateUrl: '/templates/academy/images.html',
      controller: 'AcademyImagesController',
      data: { pageTitle: 'label.academy.images' },
      resolve: {},
    })
    .state('academy.assetAdd', {
      url: '/academy/assets/new/:assetType/:assetCategory',
      templateUrl: '/templates/academy/manage/asset-edit.html',
      controller: 'AcademyAssetEditController',
      data: {
        pageTitle: 'academy.menu.createAsset',
      },
      resolve: {
        HttpService: 'HttpService',
        asset: function () {
          return {};
        },
        assets: function (HttpService) {
          return HttpService.get('/api/academy/assets');
        },
        trainers: function (CurrentUser, HttpService, InputSanitizerService) {
          return HttpService.get(
            '/api/admin/users?companyId=' +
              InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
          );
        },
        isCopy: function () {
          return false;
        },
      },
    })
    .state('academy.assetcategory', {
      url: '/academy/assets/category/:categoryId',
      templateUrl: '/templates/academy/asset-category.html',
      controller: 'AcademyAssetCategoryController',
      data: { pageTitle: 'label.academy' },
      resolve: {
        HttpService: 'HttpService',
        category: function (HttpService, $stateParams) {
          return HttpService.get('/api/academy/assetcategories/' + $stateParams.categoryId);
        },
      },
    })
    .state('academy.assetcategoriesByType', {
      url: '/academy/assets/type/:type',
      templateUrl: '/templates/academy/assets-by-type.html',
      controller: 'AcademyAssetCategoriesController',
      data: { pageTitle: 'label.academy' },
      resolve: {
        HttpService: 'HttpService',
        categories: function (HttpService, $stateParams) {
          return HttpService.get('/api/academy/assetcategories?type=' + $stateParams.type);
        },
      },
    })
    .state('academy.searchResults', {
      url: '/academy/assets/search/:searchString',
      templateUrl: '/templates/academy/search-result.html',
      controller: 'AcademyAssetSearchController',
      data: { pageTitle: 'label.academy' },
      resolve: {
        results: function (HttpService, $stateParams) {
          return HttpService.get('/api/academy/search/' + $stateParams.searchString);
        },
      },
    })
    .state('academy.courseAssign', {
      url: '/academy/courses/:courseId/assign',
      controller: function ($state, $stateParams, CurrentUser, HttpService) {
        HttpService.post(
          '/api/academy/courses/' + $stateParams.courseId + '/assign/' + CurrentUser.getUser().id,
        )
          .then(function () {
            $state.go('academy.course', { courseId: $stateParams.courseId });
          })
          .catch(function (error) {
            $state.go('error', { error: error });
          });
      },
    })
    .state('academy.course', {
      url: '/academy/courses/:courseId',
      templateUrl: '/templates/academy/asset.html',
      controller: 'AcademyAssetController',
      data: { pageTitle: 'label.academy' },
      resolve: {
        HttpService: 'HttpService',
        asset: function ($stateParams, HttpService) {
          return HttpService.get('/api/academy/assets/' + $stateParams.courseId);
        },
        quizResults: function (CurrentUser, HttpService, InputSanitizerService) {
          return HttpService.get(
            '/api/academy/quizzes/results/' +
              InputSanitizerService.sanitize(CurrentUser.getUser().id),
          );
        },
      },
    })
    .state('academy.courseAsset', {
      url: '/academy/courses/:courseId/:sectionId/:assetId',
      templateUrl: '/templates/academy/asset.html',
      controller: 'AcademyAssetController',
      data: { pageTitle: 'label.academy' },
      resolve: {
        HttpService: 'HttpService',
        asset: function ($stateParams, HttpService) {
          return HttpService.get(
            '/api/academy/courses/' +
              $stateParams.courseId +
              '/' +
              $stateParams.sectionId +
              '/' +
              $stateParams.assetId,
          );
        },
        quizResults: function (CurrentUser, HttpService, InputSanitizerService) {
          return HttpService.get(
            '/api/academy/quizzes/results/' +
              InputSanitizerService.sanitize(CurrentUser.getUser().id),
          );
        },
      },
    })
    .state('academy.courseAssetEdit', {
      url: '/academy/courses/:courseId/:sectionId/:assetId/edit',
      templateUrl: '/templates/academy/manage/asset-edit.html',
      controller: 'AcademyAssetEditController',
      data: { pageTitle: 'label.academy', isNew: false },
      resolve: {
        HttpService: 'HttpService',
        asset: function ($stateParams, HttpService) {
          return HttpService.get(
            '/api/academy/courses/' +
              $stateParams.courseId +
              '/' +
              $stateParams.sectionId +
              '/' +
              $stateParams.assetId,
          );
        },
        assets: function (HttpService) {
          return HttpService.get('/api/academy/assets');
        },
        trainers: function (CurrentUser, HttpService, InputSanitizerService) {
          return HttpService.get(
            '/api/admin/users?companyId=' +
              InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
          );
        },
        isCopy: function () {
          return false;
        },
      },
    })
    .state('academy.roadmap', {
      url: '/academy/roadmaps/:roadmapId?:companyId',
      templateUrl: '/templates/academy/roadmap.html',
      controller: 'AcademyRoadmapController',
      data: { pageTitle: 'label.academy' },
      resolve: {
        HttpService: 'HttpService',
        roadmapView: function ($stateParams, HttpService) {
          if ($stateParams.companyId) {
            return HttpService.get(
              '/api/academy/roadmapviews/' +
                $stateParams.roadmapId +
                '?companyId=' +
                $stateParams.companyId,
            );
          } else {
            return HttpService.get('/api/academy/roadmapviews/' + $stateParams.roadmapId);
          }
        },
      },
    })
    .state('academy.roadmapCourse', {
      url: '/academy/roadmaps/:roadmapId/:sectionId/courses/:courseId',
      templateUrl: '/templates/academy/asset.html',
      controller: 'AcademyAssetController',
      data: { pageTitle: 'label.academy' },
      resolve: {
        HttpService: 'HttpService',
        asset: function ($stateParams, HttpService) {
          // return HttpService.get('/api/academy/assets/' + $stateParams.courseId);
          return HttpService.get(
            '/api/academy/roadmaps/' +
              $stateParams.roadmapId +
              '/' +
              $stateParams.sectionId +
              '/' +
              $stateParams.courseId,
          );
        },
        quizResults: function (CurrentUser, HttpService, InputSanitizerService) {
          return HttpService.get(
            '/api/academy/quizzes/results/' +
              InputSanitizerService.sanitize(CurrentUser.getUser().id),
          );
        },
      },
    })
    .state('academy.roadmapCourseEdit', {
      url: '/academy/roadmaps/:roadmapId/:sectionId/courses/:courseId/edit',
      templateUrl: '/templates/academy/manage/asset-edit.html',
      controller: 'AcademyAssetEditController',
      data: { pageTitle: 'label.academy', isNew: false },
      resolve: {
        HttpService: 'HttpService',
        asset: function ($stateParams, HttpService) {
          return HttpService.get(
            '/api/academy/courses/' +
              $stateParams.courseId +
              '/' +
              $stateParams.sectionId +
              '/' +
              $stateParams.assetId,
          );
        },
        assets: function (HttpService) {
          return HttpService.get('/api/academy/assets');
        },
        trainers: function (CurrentUser, HttpService, InputSanitizerService) {
          return HttpService.get(
            '/api/admin/users?companyId=' +
              InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
          );
        },
        isCopy: function () {
          return false;
        },
      },
    })
    .state('academy.roadmapCourseAsset', {
      url: '/academy/roadmaps/:roadmapId/:sectionId/courses/:courseId/:courseSectionId/assets/:assetId',
      templateUrl: '/templates/academy/asset.html',
      controller: 'AcademyAssetController',
      data: { pageTitle: 'label.academy' },
      resolve: {
        HttpService: 'HttpService',
        asset: function ($stateParams, HttpService) {
          return HttpService.get(
            '/api/academy/courses/' +
              $stateParams.courseId +
              '/' +
              $stateParams.courseSectionId +
              '/' +
              $stateParams.assetId +
              '?roadmapId=' +
              $stateParams.roadmapId,
          );
        },
        quizResults: function (CurrentUser, HttpService, InputSanitizerService) {
          return HttpService.get(
            '/api/academy/quizzes/results/' +
              InputSanitizerService.sanitize(CurrentUser.getUser().id),
          );
        },
      },
    })
    .state('academy.roadmapCourseAssetEdit', {
      url: '/academy/roadmaps/:roadmapId/:sectionId/courses/:courseId/:courseSectionId/assets/:assetId',
      templateUrl: '/templates/academy/manage/asset-edit.html',
      controller: 'AcademyAssetEditController',
      data: { pageTitle: 'label.academy', isNew: false },
      resolve: {
        HttpService: 'HttpService',
        asset: function ($stateParams, HttpService) {
          return HttpService.get(
            '/api/academy/courses/' +
              $stateParams.courseId +
              '/' +
              $stateParams.courseSectionId +
              '/' +
              $stateParams.assetId,
          );
        },
        assets: function (HttpService) {
          return HttpService.get('/api/academy/assets');
        },
        trainers: function (CurrentUser, HttpService, InputSanitizerService) {
          return HttpService.get(
            '/api/admin/users?companyId=' +
              InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
          );
        },
        isCopy: function () {
          return false;
        },
      },
    })
    .state('academy.asset', {
      url: '/academy/assets/:assetId',
      templateUrl: '/templates/academy/asset.html',
      controller: 'AcademyAssetController',
      data: { pageTitle: 'label.academy' },
      resolve: {
        HttpService: 'HttpService',
        asset: function ($stateParams, HttpService) {
          return HttpService.get('/api/academy/assets/' + $stateParams.assetId);
        },
        quizResults: function (CurrentUser, HttpService, InputSanitizerService) {
          return HttpService.get(
            '/api/academy/quizzes/results/' +
              InputSanitizerService.sanitize(CurrentUser.getUser().id),
          );
        },
      },
    })
    .state('academy.assetEdit', {
      url: '/academy/assets/:assetId/edit',
      templateUrl: '/templates/academy/manage/asset-edit.html',
      controller: 'AcademyAssetEditController',
      data: {
        pageTitle: 'academy.menu.editAsset',
        isNew: false,
      },
      resolve: {
        HttpService: 'HttpService',
        asset: function ($stateParams, HttpService) {
          return HttpService.get('/api/academy/assets/' + $stateParams.assetId);
        },
        assets: function (HttpService) {
          return HttpService.get('/api/academy/assets');
        },
        trainers: function (CurrentUser, HttpService, InputSanitizerService) {
          return HttpService.get(
            '/api/admin/users?companyId=' +
              InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
          );
        },
        isCopy: function () {
          return false;
        },
      },
    })
    .state('academy.courseQuizEdit', {
      url: '/academy/quizes/:courseId/:sectionId/:assetId/edit',
      templateUrl: '/templates/academy/manage/quiz-edit.html',
      controller: 'AcademyQuizEditController',
      data: { pageTitle: 'label.academy' },
      resolve: {
        HttpService: 'HttpService',
        quiz: function ($stateParams, HttpService) {
          return HttpService.get('/api/academy/assets/' + $stateParams.assetId);
        },
        trainers: function (CurrentUser, HttpService, InputSanitizerService) {
          return HttpService.get(
            '/api/admin/users?companyId=' +
              InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
          );
        },
      },
    })
    .state('academy.quizEdit', {
      url: '/academy/quizes/:assetId/edit',
      templateUrl: '/templates/academy/manage/quiz-edit.html',
      controller: 'AcademyQuizEditController',
      data: { pageTitle: 'label.academy' },
      resolve: {
        HttpService: 'HttpService',
        quiz: function ($stateParams, HttpService) {
          return HttpService.get('/api/academy/assets/' + $stateParams.assetId);
        },
        trainers: function (CurrentUser, HttpService, InputSanitizerService) {
          return HttpService.get(
            '/api/admin/users?companyId=' +
              InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
          );
        },
      },
    })
    .state('academy.users', {
      url: '/academy/users',
      templateUrl: '/templates/academy/users.html',
      controller: 'AcademyUsersController',
      data: { pageTitle: 'academy.menu.coaching' },
      resolve: {
        HttpService: 'HttpService',
        users: function ($stateParams, HttpService) {
          return HttpService.get('/api/academy/users');
        },
        courses: function (HttpService) {
          return HttpService.get('/api/academy/assets?type=course');
        },
      },
    })
    .state('academy.user', {
      url: '/academy/members/:companyId/users/:userId',
      templateUrl: '/templates/academy/user-general.html',
      controller: 'AcademyUserController',
      data: { pageTitle: 'academy.menu.coaching' },
      resolve: {
        HttpService: 'HttpService',
        user: function ($stateParams, HttpService) {
          return HttpService.get(
            '/api/academy/users/' + $stateParams.userId + '?companyId=' + $stateParams.companyId,
          );
        },
        courses: function (HttpService) {
          return HttpService.get('/api/academy/assets?type=course');
        },
      },
    })
    .state('academy.userTimeline', {
      url: '/academy/members/:companyId/users/:userId/timeline',
      templateUrl: '/templates/academy/user-timeline.html',
      controller: 'AcademyUserTimelineController',
      data: { pageTitle: 'academy.menu.coaching' },
      resolve: {
        HttpService: 'HttpService',
        user: function ($stateParams, HttpService) {
          return HttpService.get(
            '/api/academy/users/' + $stateParams.userId + '?companyId=' + $stateParams.companyId,
          );
        },
        courses: function (HttpService) {
          return HttpService.get('/api/academy/assets?type=course');
        },
      },
    })
    .state('academy.userMeetings', {
      url: '/academy/members/:companyId/users/:userId/meetings',
      templateUrl: '/templates/academy/user-meetings.html',
      controller: 'AcademyUserMeetingsController',
      data: { pageTitle: 'academy.menu.coaching' },
      resolve: {
        HttpService: 'HttpService',
        user: function ($stateParams, HttpService) {
          return HttpService.get(
            '/api/academy/users/' + $stateParams.userId + '?companyId=' + $stateParams.companyId,
          );
        },
        meetings: function (HttpService, $stateParams) {
          return HttpService.get('/api/meeting/meetings?userId=' + $stateParams.userId);
        },
        meetingTemplates: function (HttpService) {
          return HttpService.get('/api/meeting/templates?type=private');
        },
      },
    })
    .state('academy.userMeetingsRedirect', {
      url: '/academy/meetings',
      controller: function ($state, $stateParams, CurrentUser) {
        $state.transitionTo('academy.userMeetings', {
          companyId: CurrentUser.getUser().Company.id,
          userId: CurrentUser.getUser().id,
        });
      },
      data: { pageTitle: 'academy.menu.coaching' },
    })
    .state('academy.userCourse', {
      url: '/academy/users/:userId/courses/:courseId',
      templateUrl: '/templates/academy/user-general.html',
      controller: 'AcademyUserController',
      data: { pageTitle: 'academy.menu.coaching' },
      resolve: {
        HttpService: 'HttpService',
        user: function ($stateParams, HttpService) {
          return HttpService.get(
            '/api/academy/users/' + $stateParams.userId + '?companyId=' + $stateParams.companyId,
          );
        },
        courses: function (HttpService) {
          return HttpService.get('/api/academy/assets?type=course');
        },
      },
    })
    .state('academy.assignments', {
      url: '/academy/assignments',
      templateUrl: '/templates/academy/user-general.html',
      controller: 'AcademyUserController',
      data: { pageTitle: 'academy.menu.coaching' },
      resolve: {
        HttpService: 'HttpService',
        CurrentUser: 'CurrentUser',
        user: function (HttpService, CurrentUser, InputSanitizerService) {
          return HttpService.get(
            '/api/academy/users/' + InputSanitizerService.sanitize(CurrentUser.getUser().id),
          );
        },
        courses: function (HttpService) {
          return HttpService.get('/api/academy/assets?type=course');
        },
      },
    })
    .state('academy.members', {
      url: '/academy/members',
      templateUrl: '/templates/academy/members.html',
      controller: 'AacdemyMembersController',
      resolve: {
        HttpService: 'HttpService',
        companies: function ($stateParams, HttpService) {
          return HttpService.get('/api/vendor/members');
        },
      },
    })
    .state('academy.member', {
      url: '/academy/members/:companyId',
      templateUrl: '/templates/academy/member.html',
      controller: 'VendorMemberController',
      resolve: {
        HttpService: 'HttpService',
        company: function ($stateParams, HttpService) {
          return HttpService.get('/api/vendor/members/' + $stateParams.companyId);
        },
        courses: function (HttpService) {
          return HttpService.get('/api/academy/assets?type=course');
        },
        colleagues: function (CompanyService) {
          return CompanyService.getColleagues();
        },
      },
    });

  $stateProvider
    .state('service', {
      abstract: true,
      template: '<ui-view/>',
      data: {
        pageTitle: 'Services',
        module: 'academy',
        access: 'useAcademy',
        // navbar: '/templates/academy/header.html'
      },
    })
    .state('service.services', {
      url: '/vcio/services?:permissionGroupId&:type',
      templateUrl: '/templates/service/services.html',
      data: { pageTitle: 'services.menu.services' },
      controller: 'AcademyServicesController',
      resolve: {
        HttpService: 'HttpService',
        services: function (HttpService, $stateParams) {
          return HttpService.get('/api/service/services/?type=' + ($stateParams.type || ''));
        },
        serviceGroups: function (HttpService) {
          return HttpService.get('/api/service/servicegroups/');
        },
      },
    })
    .state('service.servicebundlesByPermissionGroup', {
      url: '/vcio/servicebundles?:permissionGroupId',
      templateUrl: '/templates/vcio/service-bundles.html',
      controller: 'VcioServiceBundlesController',
      data: { pageTitle: 'services.menu.servicebundles' },
      resolve: {
        HttpService: 'HttpService',
        serviceBundles: function (HttpService) {
          return HttpService.get('/api/service/servicebundles');
        },
        services: function (HttpService) {
          return HttpService.get('/api/service/services/');
        },
        serviceTemplates: function (HttpService) {
          return HttpService.get('/api/service/services/templates');
        },
      },
    })
    .state('service.servicebundles', {
      url: '/vcio/servicebundles',
      templateUrl: '/templates/vcio/service-bundles.html',
      controller: 'VcioServiceBundlesController',
      data: { pageTitle: 'services.menu.servicebundles' },
      resolve: {
        HttpService: 'HttpService',
        serviceBundles: function (HttpService) {
          return HttpService.get('/api/service/servicebundles');
        },
        services: function (HttpService) {
          return HttpService.get('/api/service/services/');
        },
        serviceTemplates: function (HttpService) {
          return HttpService.get('/api/service/services/templates');
        },
      },
    })
    .state('service.servicebundletemplates', {
      url: '/vcio/servicebundletemplates',
      templateUrl: '/templates/vcio/service-bundles.html',
      controller: 'VcioServiceBundlesController',
      data: { pageTitle: 'services.menu.servicebundletemplates', vendorSelector: true },
      resolve: {
        HttpService: 'HttpService',
        serviceBundles: function (HttpService) {
          return HttpService.get('/api/service/servicebundles/templates');
        },
        services: function (HttpService) {
          return HttpService.get('/api/service/services/');
        },
        serviceTemplates: function (HttpService) {
          return HttpService.get('/api/service/services/templates');
        },
      },
    })
    .state('service.servicebundlebuilder', {
      url: '/vcio/servicebundlebuilder/:serviceBundleIds',
      templateUrl: '/templates/vcio/service-bundle-builder.html',
      controller: 'VcioServiceBundleBuilderController',
      data: { pageTitle: 'services.menu.servicebundlebuilder' },
      resolve: {
        HttpService: 'HttpService',
        serviceGroups: function (HttpService) {
          return HttpService.get('/api/service/servicegroups?services=true&type=ongoing');
        },
        serviceBundles: function (HttpService, $stateParams) {
          return HttpService.get(
            '/api/service/servicebundles?owner=true' +
              ($stateParams.serviceBundleIds ? '&ids=' + $stateParams.serviceBundleIds : ''),
          );
          // return HttpService.get('/api/service/servicebundles' + ($stateParams.serviceBundleIds == 'all' ? '' : '?ids=' + $stateParams.serviceBundleIds));
        },
      },
    })
    .state('service.servicebundle', {
      url: '/vcio/servicebundles/:serviceBundleId',
      templateUrl: '/templates/vcio/service-bundle.html',
      controller: 'VcioServiceBundleController',
      data: { pageTitle: 'services.menu.servicebundles' },
      resolve: {
        HttpService: 'HttpService',
        bundle: function (HttpService, $stateParams) {
          return HttpService.get('/api/service/servicebundles/' + $stateParams.serviceBundleId);
        },
        services: function (HttpService) {
          return HttpService.get('/api/service/services');
        },
        serviceTemplates: function (HttpService) {
          return HttpService.get('/api/service/services/templates');
        },
        copy: function () {
          return false;
          // },
          // courses: function ($stateParams, HttpService) {
          //     return HttpService.get('/api/academy/courses/');
        },
      },
    })
    .state('service.servicebundleEdit', {
      url: '/vcio/servicebundles/:serviceBundleId/edit',
      templateUrl: '/templates/vcio/service-bundle-edit.html',
      controller: 'VcioServiceBundleEditController',
      data: { pageTitle: 'services.menu.servicebundles' },
      resolve: {
        HttpService: 'HttpService',
        bundle: function (HttpService, $stateParams) {
          return $stateParams.serviceBundleId === 'new'
            ? null
            : HttpService.get('/api/service/servicebundles/' + $stateParams.serviceBundleId);
        },
        services: function (HttpService) {
          return HttpService.get('/api/service/services');
        },
        serviceTemplates: function (HttpService) {
          return HttpService.get('/api/service/services/templates/');
        },
        copy: function () {
          return false;
        },
        template: function () {
          return false;
        },
      },
    })
    .state('service.servicebundletemplateEdit', {
      url: '/vcio/servicebundletemplates/:serviceBundleId/edit',
      templateUrl: '/templates/vcio/service-bundle-edit.html',
      controller: 'VcioServiceBundleEditController',
      data: { pageTitle: 'services.menu.servicebundles' },
      resolve: {
        HttpService: 'HttpService',
        bundle: function (HttpService, $stateParams) {
          return $stateParams.serviceBundleId === 'new'
            ? null
            : HttpService.get('/api/service/servicebundles/' + $stateParams.serviceBundleId);
        },
        services: function (HttpService) {
          return HttpService.get('/api/service/services');
        },
        serviceTemplates: function (HttpService) {
          return HttpService.get('/api/service/services/templates');
        },
        copy: function () {
          return false;
        },
        template: function () {
          return true;
        },
      },
    })
    .state('service.servicebundleCopy', {
      url: '/vcio/servicebundle/:serviceBundleId/copy',
      templateUrl: '/templates/vcio/service-bundle-edit.html',
      controller: 'VcioServiceBundleEditController',
      data: { pageTitle: 'services.menu.servicebundles' },
      resolve: {
        HttpService: 'HttpService',
        bundle: function (HttpService, $stateParams) {
          return HttpService.get('/api/service/servicebundles/' + $stateParams.serviceBundleId);
        },
        services: function (HttpService) {
          return HttpService.get('/api/service/services');
        },
        serviceTemplates: function (HttpService) {
          return HttpService.get('/api/service/services/templates');
        },
        copy: function () {
          return true;
        },
        template: function () {
          return false;
        },
      },
    })
    .state('service.servicebundletemplateCopy', {
      url: '/vcio/servicebundletemplates/:serviceBundleId/copy',
      templateUrl: '/templates/vcio/service-bundle-edit.html',
      controller: 'VcioServiceBundleEditController',
      data: { pageTitle: 'services.menu.servicebundles' },
      resolve: {
        HttpService: 'HttpService',
        bundle: function (HttpService, $stateParams) {
          return HttpService.get('/api/service/servicebundles/' + $stateParams.serviceBundleId);
        },
        services: function (HttpService) {
          return HttpService.get('/api/service/services');
        },
        serviceTemplates: function (HttpService) {
          return HttpService.get('/api/service/services/templates');
        },
        copy: function () {
          return true;
        },
        template: function () {
          return true;
        },
      },
    })
    .state('service.servicebundlePrint', {
      url: '/vcio/servicebundles/:serviceBundleId/print',
      templateUrl: '/templates/vcio/service-bundle-print.html',
      controller: 'VcioServiceBundlePrintController',
      resolve: {
        HttpService: 'HttpService',
        services: function (HttpService) {
          return HttpService.get('/api/service/services?body=true');
        },
        serviceTemplates: function (HttpService) {
          return HttpService.get('/api/service/services/templates?body=true');
        },
        bundle: function (HttpService, $stateParams) {
          return HttpService.get('/api/service/servicebundles/' + $stateParams.serviceBundleId);
        },
      },
    })
    .state('service.servicegroups', {
      url: '/academy/servicegroups',
      templateUrl: '/templates/service/servicegroups.html',
      data: { pageTitle: 'academy.menu.services' },
      controller: 'AcademyServiceGroupsController',
      resolve: {
        listview: function () {
          return false;
        },
      },
    })
    .state('service.servicetemplates', {
      url: '/academy/servicetemplates?:type',
      templateUrl: '/templates/service/services.html',
      data: { pageTitle: 'services.menu.servicetemplates', vendorSelector: true },
      controller: 'AcademyServicesController',
      resolve: {
        HttpService: 'HttpService',
        services: function (HttpService, $stateParams) {
          return HttpService.get(
            '/api/service/services/templates/?type=' + ($stateParams.type || ''),
          );
        },
        serviceGroups: function (HttpService) {
          return HttpService.get('/api/service/servicegroups/templates/');
        },
      },
    })
    .state('service.servicegroup', {
      url: '/academy/servicegroups/:serviceGroupId',
      templateUrl: '/templates/service/servicegroup.html',
      controller: 'AcademyServiceGroupController',
      data: { pageTitle: 'academy.menu.services' },
      resolve: {
        HttpService: 'HttpService',
        serviceGroup: function ($stateParams, HttpService) {
          return HttpService.get('/api/service/servicegroups/' + $stateParams.serviceGroupId);
        },
      },
    })
    .state('service.service', {
      url: '/academy/services/:serviceId',
      templateUrl: '/templates/service/service.html',
      controller: 'AcademyServiceController',
      data: { pageTitle: 'academy.menu.services' },
      resolve: {
        HttpService: 'HttpService',
        service: function ($stateParams, HttpService) {
          return HttpService.get('/api/service/services/' + $stateParams.serviceId);
        },
        surveys: function ($stateParams, HttpService) {
          return HttpService.get('/api/service/services/' + $stateParams.serviceId + '/surveys');
        },
      },
    })
    .state('service.serviceEdit', {
      url: '/academy/services/:serviceId/edit',
      templateUrl: '/templates/academy/manage/service-edit.html',
      controller: 'AcademyServiceEditController',
      data: { pageTitle: 'academy.menu.services' },
      resolve: {
        HttpService: 'HttpService',
        service: function ($stateParams, HttpService) {
          return $stateParams.serviceId === 'new'
            ? null
            : HttpService.get('/api/service/services/' + $stateParams.serviceId);
        },
        copy: function () {
          return false;
        },
        template: function () {
          return false;
        },
      },
    })
    .state('service.serviceCopy', {
      url: '/academy/services/:serviceId/copy',
      templateUrl: '/templates/academy/manage/service-edit.html',
      controller: 'AcademyServiceEditController',
      data: { pageTitle: 'academy.menu.services' },
      resolve: {
        HttpService: 'HttpService',
        service: function ($stateParams, HttpService) {
          return HttpService.get('/api/service/services/' + $stateParams.serviceId);
        },
        copy: function () {
          return true;
        },
        template: function () {
          return false;
        },
      },
    })
    .state('service.servicetemplateEdit', {
      url: '/academy/servicetemplates/:serviceId/edit',
      templateUrl: '/templates/academy/manage/service-edit.html',
      controller: 'AcademyServiceEditController',
      data: { pageTitle: 'academy.menu.services' },
      resolve: {
        HttpService: 'HttpService',
        service: function ($stateParams, HttpService) {
          return $stateParams.serviceId === 'new'
            ? null
            : HttpService.get('/api/service/services/' + $stateParams.serviceId);
        },
        copy: function () {
          return false;
        },
        template: function () {
          return true;
        },
      },
    })
    .state('service.servicetemplateCopy', {
      url: '/academy/servicetemplates/:serviceId/copy',
      templateUrl: '/templates/academy/manage/service-edit.html',
      controller: 'AcademyServiceEditController',
      data: { pageTitle: 'academy.menu.services' },
      resolve: {
        HttpService: 'HttpService',
        service: function ($stateParams, HttpService) {
          return HttpService.get('/api/service/services/' + $stateParams.serviceId);
        },
        copy: function () {
          return true;
        },
        template: function () {
          return true;
        },
      },
    });

  $stateProvider
    .state('vcio', {
      abstract: true,
      template: '<ui-view/>',
      data: {
        pageTitle: 'vCIO',
        module: 'vcio',
        access: 'useVcio',
        // navbar: '/templates/vcio/header.html'
      },
    })
    .state('vcio.contract', {
      url: '/vcio/contracts/:contractId',
      templateUrl: '/templates/vcio/contract.html',
      controller: 'VcioContractController',
      resolve: {
        HttpService: 'HttpService',
        //serviceGroups: function (HttpService) {
        //    return HttpService.get('/api/service/servicegroups?services=true');
        //},
        contract: function (HttpService, $stateParams) {
          return HttpService.get(
            '/api/vcio/contracts/' + $stateParams.contractId + '?services=true',
          );
          // },
          // client: function (HttpService, $stateParams) {
          //     return HttpService.get('/api/clients/' + $stateParams.clientId);
        },
      },
    })
    .state('vcio.contractEdit', {
      url: '/vcio/contracts/:contractId/edit?clientId',
      templateUrl: '/templates/vcio/contract-edit.html',
      controller: 'VcioContractEditController',
      resolve: {
        HttpService: 'HttpService',
        serviceGroups: function (HttpService) {
          return HttpService.get('/api/service/servicegroups?services=true');
        },
        contract: function (HttpService, $stateParams) {
          return $stateParams.contractId === 'new'
            ? undefined
            : HttpService.get('/api/vcio/contracts/' + $stateParams.contractId);
        },
        client: function (HttpService, $stateParams, $location) {
          return $stateParams.contractId === 'new'
            ? HttpService.get('/api/clients/' + $location.search().clientId)
            : null;
          // return HttpService.get('/api/clients/' + $stateParams.clientId);
          // return HttpService.get('/api/clients/' + $location.search().clientId);
        },
      },
    })
    .state('vcio.contractCreate', {
      url: '/vcio/contracts/new/edit/:clientId',
      templateUrl: '/templates/vcio/contract-edit.html',
      controller: 'VcioContractEditController',
      resolve: {
        HttpService: 'HttpService',
        serviceGroups: function (HttpService) {
          return HttpService.get('/api/service/servicegroups?services=true');
        },
        contract: function () {
          return undefined;
        },
        client: function (HttpService, $stateParams) {
          return HttpService.get('/api/clients/' + $stateParams.clientId);
        },
      },
    });

  $stateProvider
    .state('admin', {
      abstract: true,
      template: '<ui-view/>',
      data: {
        module: 'admin',
      },
    })
    .state('admin.help', {
      url: '/admin/help',
      templateUrl: '/templates/admin/help.html',
      controller: 'HelpManageController',
      data: { access: 'manageHelp' },
      resolve: {
        HttpService: 'HttpService',
        helps: function ($stateParams, HttpService) {
          return HttpService.get('/api/help');
        },
      },
    })
    .state('admin.apikeys', {
      url: '/apikeys',
      templateUrl: '/templates/admin/apikeys.html',
      controller: 'APIKeysController',
      data: { access: 'manageAPIKeys' },
    })
    .state('admin.translations', {
      url: '/translations',
      controller: 'TranslationsController',
      templateUrl: '/templates/admin/translations.html',
      data: { access: 'modifyTranslation' },
    })
    .state('admin.settings', {
      url: '/admin/settings',
      templateUrl: '/templates/admin/settings.html',
      data: { access: 'manageSettings' },
    })
    .state('admin.rights', {
      url: '/admin/rights',
      templateUrl: '/templates/auth/rights.html',
      controller: 'RightsController',
      data: { access: 'manageRoles' },
      resolve: {
        HttpService: 'HttpService',
        rights: function (HttpService) {
          return HttpService.get('/api/admin/rights');
        },
        roles: function (HttpService) {
          return HttpService.get('/api/admin/roles');
        },
        modules: function (HttpService) {
          return HttpService.get('/api/admin/modules');
        },
      },
    })
    .state('admin.users', {
      url: '/admin/users',
      templateUrl: '/templates/admin/users.html',
      controller: 'UserAdminController',
      data: { access: 'listAllUser' },
    })
    .state('admin.companyUsers', {
      url: '/admin/users/:companyId',
      templateUrl: '/templates/admin/users.html',
      controller: 'UserAdminController',
      data: { access: 'listAllUser' },
    })
    .state('admin.companies', {
      url: '/admin/companies',
      templateUrl: '/templates/admin/companies.html',
      controller: 'CompanyAdminController',
      data: { access: 'listAllCompany' },
    })
    .state('admin.company', {
      url: '/admin/companies/:companyId',
      templateUrl: '/templates/admin/company-edit.html',
      controller: 'CompanyEditController',
      data: { access: 'modifyCompany' },
      resolve: {
        HttpService: 'HttpService',
        company: function ($stateParams, HttpService) {
          //return adminService.getCompanyById($stateParams.companyId);
          return HttpService.get('/api/companies/' + $stateParams.companyId);
        },
      },
    })
    .state('admin.emailtemplates', {
      url: '/admin/emailtemplates',
      templateUrl: '/templates/admin/email-templates.html',
      controller: 'EmailTemplatesController',
      data: { access: 'manageEmailTemplates' },
    })
    .state('admin.events', {
      url: '/admin/events',
      template:
        '<br><br><div class="row" ng-repeat="event in events" title="{{event.args}}">' +
        '<div class="col-xs-1">{{event.createdAt | date:\'shortTime\'}}</div>' +
        '<div class="col-xs-3">{{event.event}}</div>' +
        '<div class="col-xs-4">{{event.User.email}}</div>' /* +
                 '<div class="col-xs-4">{{event.args}}</div></div>',*/,
      controller: function ($scope, events) {
        $scope.events = events;
      },
      data: { access: 'listUserEvents' },
      resolve: {
        HttpService: 'HttpService',
        events: function ($stateParams, HttpService) {
          return HttpService.get('/api/events/timeline');
        },
      },
    })
    .state('admin.eventReport', {
      url: '/admin/reports/events',
      templateUrl: '/templates/reports/events.html',
      controller: 'AdminEventReportController',
      data: { access: 'listUserEvents' },
      resolve: {
        HttpService: 'HttpService',
        events: function ($stateParams, HttpService) {
          return HttpService.get('/api/events/reports/events');
        },
      },
    })
    .state('admin.companiesEventsReport', {
      url: '/admin/reports/companyevents?:event',
      templateUrl: '/templates/reports/companies-events.html',
      controller: 'AdminCompaniesEventsReportController',
      data: { access: 'listUserEvents' },
      resolve: {
        HttpService: 'HttpService',
        companies: function ($stateParams, HttpService) {
          return HttpService.get('/api/events/reports/companies?event=' + $stateParams.event);
        },
        eventList: function (HttpService) {
          return HttpService.get('/api/lists/events');
        },
      },
    })
    .state('admin.companyEventsReport', {
      url: '/admin/reports/companyevents/:companyId',
      templateUrl: '/templates/reports/company-events.html',
      controller: 'AdminCompanyEventsReportController',
      data: { access: 'listUserEvents' },
      resolve: {
        HttpService: 'HttpService',
        company: function ($stateParams, HttpService) {
          return HttpService.get('/api/events/reports/companies/' + $stateParams.companyId);
        },
      },
    })
    .state('admin.sessions', {
      url: '/admin/sessions',
      template:
        '<br><br><div class="row" ng-repeat="session in sessions">' +
        '<div class="col-xs-1">{{session.lastActivityAt | date:\'shortTime\'}}</div>' +
        '<div class="col-xs-3">{{session.id}}</div>' +
        '<div class="col-xs-3">{{session.email}}</div>',
      controller: function ($scope, sessions) {
        $scope.sessions = sessions;
      },
      data: { access: 'listUserEvents' },
      resolve: {
        HttpService: 'HttpService',
        sessions: function ($stateParams, HttpService) {
          return HttpService.get('/api/auth/sessions');
        },
      },
    })
    .state('admin.permissionGroupCategories', {
      url: '/admin/permissiongroupcategories',
      templateUrl: '/templates/admin/permissiongroupcategories.html',
      controller: 'VendorPermissionGroupCategoryAdminController',
      resolve: {
        HttpService: 'HttpService',
        permissionGroupCategories: function (HttpService) {
          return HttpService.get('/api/vendor/permissiongroupcategorytree');
        },
      },
    })
    .state('admin.tasks', {
      url: '/admin/tasks',
      templateUrl: '/templates/admin/admin-tasks.html',
      controller: 'AdminTasksController',
    });

  $stateProvider
    .state('embedded', {
      abstract: true,
      template: '<ui-view/>',
    })
    .state('embedded.delivery', {
      url: '/embedded/delivery',
      controller: function ($rootScope, $scope, $state, _, clients) {
        if ($rootScope.connectWiseScreen) {
          var client = _.find(clients, function (client) {
            return (
              _.get(client, 'externalIds.connectwise') + '' == $rootScope.connectWiseScreen.id + ''
            );
          });
          if (client) {
            $state.go('clients.delivery', { clientId: client.id, system: 'connectwise' });
          } else {
            $state.go('msp.importClient', { externalId: $rootScope.connectWiseScreen.id });
          }
        }
      },
      data: {},
      resolve: {
        clients: function ($stateParams, HttpService) {
          return HttpService.get('/api/clients');
        },
      },
    })
    .state('embedded.surveyresults', {
      url: '/embedded/surveyresults',
      controller: function ($rootScope, $scope, $state, _, clients) {
        if ($rootScope.connectWiseScreen) {
          var client = _.find(clients, function (client) {
            return (
              _.get(client, 'externalIds.connectwise') + '' == $rootScope.connectWiseScreen.id + ''
            );
          });
          if (client) {
            $state.go('clients.view', { clientId: client.id });
          } else {
            $state.go('msp.importClient', { externalId: $rootScope.connectWiseScreen.id });
          }
        }
      },
      data: {},
      resolve: {
        clients: function ($stateParams, HttpService) {
          return HttpService.get('/api/clients');
        },
      },
    });

  $stateProvider
    .state('user', {
      abstract: true,
      template: '<ui-view/>',
    })
    // .state('user.noright', {
    //     url: '/noright',
    //     templateUrl: '/templates/client/noright.html'
    // })
    // .state('user.help', {
    //     url: '/help/:module',
    //     templateUrl: '/templates/help.html',
    //     controller: 'HelpController',
    //     data: {pageTitle: 'Help'},
    //     resolve: {
    //         HttpService: 'HttpService',
    //         help: function ($stateParams, HttpService) {
    //             return HttpService.get('/api/help/' + $stateParams.module);
    //         }
    //     }
    // })
    .state('user.profile', {
      url: '/profile',
      templateUrl: '/templates/admin/user-profile.html',
      controller: 'UserProfileController',
      data: { pageTitle: 'Profile' },
      resolve: {
        HttpService: 'HttpService',
        user: function (CurrentUser, HttpService, InputSanitizerService) {
          return HttpService.get(
            '/api/admin/users/' + InputSanitizerService.sanitize(CurrentUser.getUser().id),
          );
        },
        permissionGroupCategories: function (HttpService) {
          return HttpService.get('/api/vendor/permissiongroupcategories');
        },
      },
    });

  $stateProvider
    .state('404', {
      url: '/404',
      templateUrl: '/templates/404.html',
    })
    .state('403', {
      url: '/403',
      templateUrl: '/templates/403.html',
    });

  $stateProvider
    .state('sso', {
      abstract: true,
      template: '<ui-view/>',
    })
    .state('sso.community', {
      url: '/sso/community?:sso&:sig',
      template:
        '<p ng-if="!url" translate="label.sso.authenticating"></p>' +
        '<p ng-if="url" translate="label.sso.redirect" translate-values="{url: url"></p>',
      controller: function ($scope, $location, $window, DialogService, HttpService) {
        HttpService.post('/api/auth/sso/discourse', {
          sso: $location.search().sso,
          sig: $location.search().sig,
        })
          .then(function (result) {
            $scope.url = result;
            $window.location.href = result;
          })
          .catch(function (error) {
            DialogService.error(error);
          });
      },
    });

  $urlRouterProvider.when(
    '/clients/:clientId/projects/:system',
    '/clients/:clientId/delivery/:system',
  );
  $urlRouterProvider.when('/overview/:surveyResultId', '/sales/overview/:surveyResultId');
  $urlRouterProvider.when('/vcio/workspaces', '/embedded/delivery');
  $urlRouterProvider.when('/vcio', '/embedded/delivery');
  $urlRouterProvider.when('/sales', '/embedded/surveyresults');
  $urlRouterProvider.when('/sales/surveyresults', '/embedded/surveyresults');
  $urlRouterProvider.when('/vcio/servicebundlebuilder', '/vcio/servicebundlebuilder/');
  $urlRouterProvider.when('/academy/dashboard', '/academy/assignments');
  $urlRouterProvider.when('/academy/goals', '/academy/assets/type/course');
  $urlRouterProvider.when('/academy/services', '/vcio/services');
  $urlRouterProvider.when('/academy/services/:serviceId', '/vcio/services/:serviceId');
  $urlRouterProvider.when('/academy', '/academy/assignments');
  $urlRouterProvider.otherwise('/');
});
