'use strict';

angular
  .module('vcio-toolkit')

  .service(
    'AssetService',
    function (
      $q,
      $rootScope,
      $state,
      $translate,
      _,
      AssetModel,
      AssetCategoryModel,
      CurrentUser,
      HttpService,
      InputSanitizerService,
    ) {
      var mainCatsObj = {
        course: {
          icon: 'graduation-cap',
          fields: ['name', 'subtitle', 'trainer', 'overview', 'sections', 'prerequisites'],
          showInSidebar: true,
          showInMenu: true,
        },
        knowledgebase: {
          icon: 'book',
          type: {
            article: {
              icon: 'newspaper-o',
              fields: [
                'name',
                'subtitle',
                'trainer',
                'overview',
                'knowledgebaseType',
                'relatedAssets',
                'markAsCompleted',
                'published',
              ],
            },
            lecture: {
              icon: 'play-circle-o',
              fields: [
                'name',
                'subtitle',
                'trainer',
                'overview',
                'knowledgebaseType',
                'relatedAssets',
                'markAsCompleted',
                'video',
                'published',
              ],
            },
            process: {
              icon: 'tasks',
              fields: [
                'name',
                'subtitle',
                'trainer',
                'overview',
                'knowledgebaseType',
                'relatedAssets',
                'markAsCompleted',
                'video',
                'todoLists',
                'sendDelivery',
                'published',
              ],
            },
          },
          showInSidebar: true,
          showInMenu: true,
        },
        resource: {
          icon: 'book',
          fields: [
            'name',
            'subtitle',
            'trainer',
            'overview',
            'file',
            'published',
            'markAsCompleted',
          ],
          showInSidebar: true,
          showInMenu: true,
        },
        training: {
          icon: 'desktop',
          fields: [
            'name',
            'subtitle',
            'trainer',
            'video',
            'overview',
            'relatedAssets',
            'markAsCompleted',
            'published',
          ],
          showInSidebar: true,
          showInMenu: true,
        },
        roadmap: {
          icon: 'road',
          fields: ['name', 'subtitle', 'trainer', 'overview', 'sections', 'sendDelivery'],
          showInSidebar: true,
          showInMenu: true,
        },
        quiz: {
          icon: 'question',
          fields: ['name', 'overview', 'quiz'],
        },
      };
      var mainCats = _.keys(mainCatsObj);

      var getIconByFilename = function (filename) {
        if (filename) {
          var extension =
            filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;
          var icons = {
            'file-pdf-o': ['pdf'],
            'file-zip-o': ['zip', 'rar'],
            'file-excel-o': ['xls', 'xlsx', 'ods'],
            'file-word-o': ['doc', 'docx', 'odt'],
            'file-text-o': ['txt'],
            'file-image-o': ['jpg', 'png', 'gif'],
            'file-video-o': ['avi', 'mkv', 'wmv'],
          };
          var fileicon = _.findKey(icons, function (v) {
            return _.indexOf(v, extension) !== -1;
          });
          if (fileicon) {
            return fileicon;
          } else {
            return 'file-o';
          }
        } else {
          return 'file-o';
        }
      };

      return {
        mainCats: mainCats,
        mainCatsObj: mainCatsObj,
        knowledgebaseTypes: _.keys(mainCatsObj.knowledgebase.type),

        isFieldShown: function (item, field) {
          if (item && item.type) {
            if (
              item.type == 'knowledgebase' &&
              mainCatsObj[item.type].type[item.knowledgebaseType]
            ) {
              return (
                _.indexOf(mainCatsObj[item.type].type[item.knowledgebaseType].fields, field) !== -1
              );
            } else {
              return !!(
                mainCatsObj[item.type] && _.indexOf(mainCatsObj[item.type].fields, field) !== -1
              );
            }
          } else {
            return false;
          }
        },

        getIconByFilename: getIconByFilename,

        goState: function (asset) {
          if (asset.type == 'course') {
            $state.go('academy.course', { courseId: asset.id });
          } else if (asset.type === 'roadmap') {
            $state.go('academy.roadmap', { roadmapId: asset.id });
          } else {
            $state.go('academy.asset', { assetId: asset.id });
          }
        },

        getAssetLink: function (asset) {
          if (asset.type == 'course') {
            return 'academy.course({courseId: ' + asset.id + '})';
            // return asset.prerequisitesDone ? "academy.course({courseId: " + asset.id + "})" : "";
          } else if (asset.type === 'roadmap') {
            return 'academy.roadmap({roadmapId: ' + asset.id + '})';
          } else {
            return 'academy.asset({assetId: ' + asset.id + '})';
          }
        },

        getAssetTypeIcon: function (asset) {
          var result;

          if (typeof asset === 'undefined' || !asset.type) {
            return '';
          }
          if (asset.type == 'resource') {
            result = getIconByFilename(asset.filename);
          } else if (
            asset.type == 'knowledgebase' &&
            mainCatsObj[asset.type].type[asset.knowledgebaseType]
          ) {
            result = _.get(mainCatsObj[asset.type].type[asset.knowledgebaseType], 'icon');
          } else {
            result = _.get(mainCatsObj[asset.type], 'icon');
          }
          return result;
        },

        getAssetTypeIconTitle: function (asset) {
          var result;
          if (typeof asset === 'undefined' || !asset.type) {
            return '';
          }
          if (
            asset.type == 'knowledgebase' &&
            mainCatsObj[asset.type].type[asset.knowledgebaseType]
          ) {
            result = $translate.instant('label.academy.assetTypes.' + asset.knowledgebaseType);
          } else {
            result = $translate.instant('label.academy.assetTypes.' + asset.type);
          }
          return result;
        },

        finish: function (asset, user) {
          var deferred = $q.defer();
          asset.finishedAt = !asset.finishedAt;
          // var _asset = getAssetById(asset.id);
          // if (_asset) {
          //     _asset.finishedAt = !_asset.finishedAt;
          // }
          var url = '/api/academy/assets/' + InputSanitizerService.sanitize(asset.id) + '/finish';
          if (user && user.id) {
            url += '/' + InputSanitizerService.sanitize(user.id);
          }
          if (asset.finishedAt) {
            HttpService.put(url, { quiz: asset }).then(
              function () {
                deferred.resolve();
              },
              function (error) {
                deferred.reject(error);
              },
            );
          } else {
            HttpService.delete(url, { quiz: asset }).then(
              function () {
                deferred.resolve();
              },
              function (error) {
                deferred.reject(error);
              },
            );
          }
          return deferred.promise;
        },

        attachableAssetGroupBy: function (item, useTypePrefix) {
          if (item) {
            useTypePrefix = useTypePrefix !== false;
            var category;
            var subcategory;
            if (item.SubCategory) {
              subcategory = item.SubCategory;
              if (subcategory.Category) {
                category = subcategory.Category;
              }
            }
            if (category && subcategory) {
              if (useTypePrefix) {
                return (
                  $translate.instant('label.academy.assetTypes.' + item.type) +
                  ' / ' +
                  category.name +
                  ' / ' +
                  subcategory.name
                );
              } else {
                return category.name + ' / ' + subcategory.name;
              }
            } else if (useTypePrefix) {
              return (
                $translate.instant('label.academy.assetTypes.' + item.type) +
                (item.type == 'quiz'
                  ? ' / ' + $translate.instant('academy.asset.quizTypes.' + item.quizType)
                  : '')
              );
            } else {
              return '';
            }
          }
        },

        generatePrerequisitesTooltip: function (course) {
          var result = '';
          if (!_.isEmpty(course.Prerequisites)) {
            // result = $translate.instant('message.academy.unfinishedPrerequisites') + '<br>';
            _.forEach(course.Prerequisites, function (asset) {
              if (asset.SubCategory && asset.SubCategory.Category) {
                result +=
                  asset.SubCategory.Category.name +
                  ' > ' +
                  asset.SubCategory.name +
                  ' > ' +
                  asset.name +
                  '<br>';
              }
            });
          }
          return result;
        },
        save: function (asset, assetList) {
          var deferred = $q.defer();
          new AssetModel(asset).$save().then(
            function (saved) {
              if (assetList) {
                assetList.push(saved);
              }
              $rootScope.$broadcast('academyAssetSaved', { asset: saved });
              deferred.resolve(saved);
            },
            function (error) {
              deferred.reject(error);
            },
          );
          return deferred.promise;
        },
      };
    },
  )

  .factory(
    'AssetManager',
    function (
      $q,
      $rootScope,
      $state,
      $translate,
      _,
      AssetModel,
      AssetCategoryModel,
      AssetService,
      CurrentUser,
      HttpService,
    ) {
      // var currentUser = CurrentUser.getUser();
      // var list = [];

      // this.init = function (force) {
      //     var deferred = $q.defer();
      //     if (force || (currentUser && currentUser.Company && currentUser.Company.id != CurrentUser.getUser().Company.id) || tree.length === 0) {
      //         HttpService.get('/api/academy/assettree')
      //             .then(function (categories) {
      //                 this.list = categories;
      //                 currentUser = CurrentUser.getUser();
      //                 deferred.resolve();
      //             })
      //             .catch(function (error) {
      //                 console.error(error);
      //                 currentUser = CurrentUser.getUser();
      //                 deferred.reject();
      //             });
      //     } else {
      //         deferred.resolve();
      //     }
      //     return deferred.promise;
      // };
      //
      // $rootScope.$on('login-changed-event', function () {
      //     this.init();
      // });
      //
      // function isCourseFinished(courseId) {
      //     var course = _.find(assets, {id: courseId});
      //     var assetIds;
      //     if (course && course.sections) {
      //         assetIds = _(course.sections).flatten().map('attachedAssets').flatten().map('id').uniq().value();
      //     }
      //     return course && _.filter(assetIds, function (assetId) {
      //         var asset = _.find(assets, {id: assetId});
      //         return !asset || !asset.finishedAt;
      //     }).length === 0;
      // }

      // function isPrerequisiteDone(prerequisites) {
      //     return (_.filter(_.map(prerequisites, 'id'), function (courseId) {
      //         return !isCourseFinished(courseId);
      //     })).length === 0;
      // }
      //
      // function generatePrerequisitesTooltip(course) {
      //     var result = $translate.instant('message.academy.unfinishedPrerequisites') + '<br>';
      //     var unfinished = [];
      //     _.forEach(course.prerequisites, function (item) {
      //         if (!isCourseFinished(item.id)) {
      //             unfinished.push(item.id);
      //         }
      //     });
      //     if (unfinished.length === 0) {
      //         return '';
      //     }
      //     _.forEach(unfinished, function (item) {
      //         var asset = getAssetById(item);
      //         if (asset.SubCategory && asset.SubCategory.Category) {
      //             result += asset.SubCategory.Category.name + ' > ' + asset.SubCategory.name + ' > ' + asset.name + '<br>';
      //         }
      //     });
      //     /*
      //      if (course.overview) {
      //      result = result + '<br>' + course.overview;
      //      }
      //      */
      //     return result;
      // }

      // function getCategoryById(categoryId) {
      //     return _.find(categories, {id: parseInt(categoryId)});
      // }

      // function getSubcategoriesById(category, onlyPublished) {
      //     var subCategories = _.filter(categories, {CategoryId: parseInt(category.id)});
      //
      //     //TODO the below should be calculated after load and modified after update/delete/add
      //     category.subCategoriesCount = subCategories.length;
      //     category.trainers = [];
      //     _.forEach(subCategories, function (subCategory) {
      //         var catAssets = getAssetByCategory(subCategory.id, onlyPublished);
      //         //category.assetCount += catAssets.length;
      //         subCategory.assetCount = _.filter(catAssets, {published: true}).length;
      //         category.trainers = _.uniq(_.union(category.trainers, _.map(catAssets, 'Trainer')));
      //         _.forEach(_.filter(catAssets, {type: 'course'}), function (course) {
      //             course.prerequisiteDone = isPrerequisiteDone(course.prerequisites);
      //         });
      //         category.assetCount += catAssets.length;
      //     });
      // }

      // function getCategoriesByType(type) {
      //     return _.orderBy(_.filter(categories, {type: type, CategoryId: null}), ['orderId', 'name']);
      // }

      // function saveCategory(assetCategory) {
      //     var deferred = $q.defer();
      //     var _assetCategory = new AssetCategoryModel(assetCategory);
      //     if (_assetCategory.id) {
      //         _assetCategory.$update(function (result) {
      //             var assetCategoryKey = _.findIndex(categories, {id: result.id});
      //             if (assetCategoryKey !== -1) {
      //                 categories[assetCategoryKey] = result;
      //             }
      //             deferred.resolve(result);
      //         }, function (error) {
      //             deferred.reject(error);
      //         });
      //     } else {
      //         _assetCategory.$save(function (result) {
      //             categories.push(result);
      //             deferred.resolve(result);
      //         }, function (error) {
      //             deferred.reject(error);
      //         });
      //     }
      //     return deferred.promise;
      // }

      // function save(asset) {
      //     var deferred = $q.defer();
      //     var _asset = new AssetModel(asset);
      //     if (_asset.id) {
      //         _asset.$update(function (result) {
      //             init(true).then(function () {
      //                 deferred.resolve(result);
      //             });
      //         }, function (error) {
      //             deferred.reject(error);
      //         });
      //     } else {
      //         _asset.$save(function (result) {
      //             init(true).then(function () {
      //                 deferred.resolve(result);
      //             });
      //         }, function (error) {
      //             deferred.reject(error);
      //         });
      //     }
      //     return deferred.promise;
      // }

      // function copy(asset) {
      //     var deferred = $q.defer();
      //     var _asset = new AssetModel(asset);
      //     _asset.$copy(function (result) {
      //         init(true).then(function () {
      //             deferred.resolve(result);
      //         });
      //     }, function (error) {
      //         deferred.reject(error);
      //     });
      //     return deferred.promise;
      // }

      // function removeCategory(assetCategory) {
      //     var deferred = $q.defer();
      //     var _assetCategory = new AssetCategoryModel(assetCategory);
      //     if (_assetCategory.id) {
      //         _assetCategory.$delete(function (result) {
      //             categories = _.filter(categories, function (a) {
      //                 return a.id !== _assetCategory.id;
      //             });
      //             deferred.resolve(result);
      //         }, function (error) {
      //             deferred.reject(error);
      //         });
      //     } else {
      //         deferred.reject();
      //     }
      //     return deferred.promise;
      // }

      // function remove(asset) {
      //     var deferred = $q.defer();
      //     var _asset = new AssetModel(asset);
      //     if (_asset.id) {
      //         _asset.$delete(function (result) {
      //             assets = _.filter(assets, function (a) {
      //                 return a.id !== _asset.id;
      //             });
      //             deferred.resolve(result);
      //         }, function (error) {
      //             deferred.reject(error);
      //         });
      //     } else {
      //         deferred.reject();
      //     }
      //     return deferred.promise;
      // }

      // function finish(asset, user) {
      //     var deferred = $q.defer();
      //     asset.finishedAt = !asset.finishedAt;
      //     var _asset = getAssetById(asset.id);
      //     if (_asset) {
      //         _asset.finishedAt = !_asset.finishedAt;
      //     }
      //     var url = '/api/academy/assets/' + asset.id + '/finish';
      //     if (user && user.id) {
      //         url += '/' + user.id;
      //     }
      //     if (asset.finishedAt) {
      //         HttpService.put(url).then(function () {
      //             deferred.resolve();
      //         }, function (error) {
      //             deferred.reject(error);
      //         });
      //     } else {
      //         HttpService.delete(url).then(function () {
      //             deferred.resolve();
      //         }, function (error) {
      //             deferred.reject(error);
      //         });
      //     }
      //     return deferred.promise;
      // }

      return {
        // init: init,
        // resetAssets: resetAssets,
        // isFieldShown: isFieldShown,
        // isPrerequisiteDone: isPrerequisiteDone,
        // generatePrerequisitesTooltip: generatePrerequisitesTooltip,
        // getAssetById: getAssetById,
        // getAssetByCategory: getAssetByCategory,
        // getAssetsByType: getAssetsByType,
        // getCategoryById: getCategoryById,
        // getSubcategoriesById: getSubcategoriesById,
        // getCategoriesByType: getCategoriesByType,
        // saveCategory: saveCategory,
        // save: save,
        // copy: copy,
        // removeCategory: removeCategory,
        // remove: remove,
        // saveReorder: saveReorder,
        // finish: finish,
        // getAssetTypeIcon: getAssetTypeIcon,
        // getAssetTypeIconTitle: getAssetTypeIconTitle,
        // getItemCount: function (category) {
        //     return $translate.instant('label.academy.assetTypeList.number' + (category.assetCount > 1 ? 's' : '') + 'InCollection', {
        //         number: category.assetCount,
        //         type: $translate.instant('label.academy.assetTypes.' + category.type)
        //     });
        // },
        // getPublishedItemCount: function (category) {
        //     var publishedAssetCount = 0;
        //     _.forEach(category.children, function (subCategory) {
        //         publishedAssetCount += getAssetByCategory(subCategory.id, true).length;
        //     });
        //     return $translate.instant('label.academy.assetTypeList.number' + (publishedAssetCount > 1 ? 's' : '') + 'InCollection', {
        //         number: publishedAssetCount,
        //         type: $translate.instant('label.academy.assetTypes.' + category.type)
        //     });
        // },
        // getIconByFilename: getIconByFilename,
        // knowledgebaseTypes: _.keys(mainCatsObj.knowledgebase.type),
        // get mainCatsObj() {
        //     return mainCatsObj;
        // },
        // get mainCats() {
        //     return mainCats;
        // },
        // get categories() {
        //     return categories;
        // },
        // get list() {
        //     return assets;
        // }
      };
    },
  );
