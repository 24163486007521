angular
  .module('vcio-toolkit')
  .controller(
    'NavbarController',
    function (
      $http,
      $location,
      $modal,
      $moment,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $timeout,
      $translate,
      $window,
      _,
      AssetService,
      Auth,
      CurrentUser,
      LocalStorage,
      HttpService,
      UserEventService,
      InputSanitizerService,
    ) {
      //$scope.hideNavbar = $routeParams.print == 'true';
      $scope.isCollapsed = true;
      $scope.openSearch = false;
      $scope.showSolutionSets = false;
      $scope.showMarketPlace = false;
      $scope.CurrentUser = CurrentUser;
      $scope.user = CurrentUser.getUser();
      if ($scope.user) {
        $scope.user.logoUrl = '/images/users/' + $scope.user.id + '?' + new Date().getTime();
      }
      //$scope.unreadAnnouncementIds = [];
      $scope.search = {
        searchString: '',
        searchSuggest: [],
        isopen: false,
        ongoing: false,
        noresult: false,
      };
      $scope.unreadAnnouncements = [];
      $scope.isVendor = CurrentUser.isVendor;
      $scope.logout = function () {
        $state.go('logout');
      };

      $scope.isLoginPage = function () {
        return $window.location.hash === '#/login';
      };

      $scope.testBarText = function () {
        if (!(nodeenv === 'production')) {
          return nodeenv + ' - ' + appversion;
        } else {
          return undefined;
        }
      };

      $scope.helpItems = { global: [], globalCat: {}, local: [] };

      function refreshHelpItems() {
        var helpList = _.filter($scope.allHelpItems, function (item) {
          return (
            (!item.VendorId || _.find($scope.user.Company.Vendors, { id: item.VendorId })) &&
            (_.isEmpty(item.permissionGroupIds) ||
              _.intersection(item.permissionGroupIds, $scope.user.Company.PermissionGroups).length >
                0)
          );
        });
        $scope.helpItems.global = _(helpList)
          .filter(function (item) {
            return !item.url && !item.categoryName;
          })
          .value();
        $scope.helpItems.globalCat = _(helpList)
          .filter(function (item) {
            return !item.url && item.categoryName;
          })
          .groupBy('categoryName')
          .sortKeysBy()
          .value();
        $scope.helpItems.local = _(helpList)
          .filter(function (item) {
            return !item.categoryName;
          })
          .filter(function (item) {
            if (item.url && item.url.indexOf('*') == item.url.length - 1) {
              return $location.path().indexOf(item.url.substr(0, item.url.length - 1)) === 0;
            } else {
              return item.url == $location.path();
            }
          })
          .value();
        $scope.helpItems.localCat = _(helpList)
          .filter(function (item) {
            return item.categoryName;
          })
          .filter(function (item) {
            if (item.url && item.url.indexOf('*') == item.url.length - 1) {
              return $location.path().indexOf(item.url.substr(0, item.url.length - 1)) === 0;
            } else {
              return item.url == $location.path();
            }
          })
          .groupBy('categoryName')
          .sortKeysBy()
          .value();
      }

      $scope.isAuthenticated = function () {
        return CurrentUser.isAuthenticated();
      };

      $scope.can = CurrentUser.can;
      $scope.hasModule = CurrentUser.hasModule;

      $scope.generateNewFrontendUrls = function () {
        $scope.newClientAssetTypesUrl =
          '/new-frontend?path=clientassettypes?token=' + CurrentUser.getToken();
        $scope.newGlobalProjectRoadmapUrl =
          '/new-frontend?path=client/project-roadmap?token=' + CurrentUser.getToken();
        $scope.newHomeScreenUrl = '/new-frontend?path=/home?token=' + CurrentUser.getToken();
        $scope.newClientListUrl = '/new-frontend?path=clients?token=' + CurrentUser.getToken();
        $scope.newSmartAssessmentsTemplatesUrl = '/new-frontend?path=/smart-assessment/templates?token=' + CurrentUser.getToken();
        $scope.newSmartAssessmentsAssessmentsUrl = '/new-frontend?path=/smart-assessment/assessments?token=' + CurrentUser.getToken();
      };

      $scope.generateNewFrontendUrls();
      $scope.$on('companyChanged', $scope.generateNewFrontendUrls);

      $scope.getCompany = function () {
        return HttpService.get(
          '/api/companies/' + InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
        );
      };

      $scope.isFillSurvey = function () {
        return $location.path().indexOf('/fillsurvey') === 0;
      };

      $scope.showSetupItem = function (type, surveyType) {
        if ($scope.user) {
          if (CurrentUser.isVendor()) {
            return true;
          } else if (type === 'services') {
            return (
              CurrentUser.can('useService') &&
              $scope.user.templateTypes &&
              $scope.user.templateTypes.services > 0
            );
          } else if (type === 'serviceBundles') {
            return (
              CurrentUser.can('listServiceBundles') &&
              $scope.user.templateTypes &&
              $scope.user.templateTypes.serviceBundles > 0
            );
          } else if (type === 'graders') {
            return (
              CurrentUser.can('listGraders') &&
              $scope.user.templateTypes &&
              $scope.user.templateTypes.graders > 0
            );
          } else if (type === 'emailsequences') {
            return (
              CurrentUser.can('listEmailSequences') &&
              $scope.user.templateTypes &&
              $scope.user.templateTypes.emailsequences > 0
            );
          } else if (type === 'surveys') {
            if (!surveyType) {
              return $scope.user.templateTypes && $scope.user.templateTypes.surveys.length > 0;
            } else {
              return _.includes($scope.user.surveyTypes, surveyType);
            }
            // user.surveyTypes \| contains:'([a-z]*)'
          } else if (type === 'meetings') {
            return (
              CurrentUser.can('listMeetingTemplates') &&
              $scope.user.templateTypes &&
              $scope.user.templateTypes.meetings > 0
            );
          }
        } else {
          return false;
        }
      };

      $scope.showSetup = function () {
        return (
          $scope.user &&
          (CurrentUser.isVendor() ||
            (!CurrentUser.isHtgGroup() &&
              !CurrentUser.hasModule('basic') &&
              CurrentUser.hasModule('msp') &&
              $scope.user.templateTypes &&
              ($scope.user.templateTypes.services > 0 ||
                $scope.user.templateTypes.serviceBundles > 0 ||
                $scope.user.templateTypes.graders > 0 ||
                $scope.user.templateTypes.emailsequences > 0 ||
                $scope.user.templateTypes.surveys.length > 0 ||
                $scope.user.templateTypes.meetings > 0)))
        );
      };

      $scope.getTitle = function () {
        return $state.$current.data && $state.$current.data.pageTitle
          ? $translate.instant($state.$current.data.pageTitle)
          : '';
      };

      $rootScope.$on('$locationChangeSuccess', function () {
        refreshHelpItems();
      });

      $scope.$on('login-changed-event', function () {
        $scope.user = CurrentUser.getUser();
        $scope.user.logoUrl = '/images/users/' + $scope.user.id + '?' + new Date().getTime();
        if (!$scope.allHelpItems) {
          HttpService.get('/api/help').then(function (result) {
            $scope.allHelpItems = result;
            refreshHelpItems();
          });
        }
        if (!$scope.permissionGroupCategoryTree) {
          HttpService.get('/api/vendor/permissiongroupcategories').then(function (result) {
            _.forEach(result, function (category) {
              var counter = 0;
              if (category.SubCategories) {
                _.forEach(category.SubCategories, function (subcategory) {
                  var subcounter = subcategory.PermissionGroups.length;
                  subcategory.permissionGroupCounter = subcounter;
                  counter += subcounter;
                });
              }
              category.permissionGroupCounter = counter;
            });
            $scope.permissionGroupCategoryTree = result;
          });
        }
      });

      $scope.$on('announcement-read-event', function (event, announcementId) {
        _.remove($scope.unreadAnnouncements, function (item) {
          return item.id == announcementId;
        });
      });

      $scope.goHome = function () {
        $state.go('empty');
      };

      $scope.showGetHelp = function () {
        return !CurrentUser.isHtgGroup();
      };

      $scope.getHelp = function () {
        $http.get('/api/help').success(function (helps) {
          var help = _.find(helps, function (item) {
            if (item.url.indexOf('*') == item.url.length - 1) {
              return $location.path().indexOf(item.url.substr(0, item.url.length - 1)) === 0;
            } else {
              return item.url == $location.path();
            }
          });
          if (help) {
            HS.beacon.suggest(help.articleIds);
          } else {
            HS.beacon.reset();
          }
          HS.beacon.init();
          HS.beacon.toggle();
        });
      };

      $scope.openHelp = function (item) {
        UserEventService.event('helpClicked', {
          helpUrl: item.url,
          helpName: item.name,
        });
        if (item.type == 'appcue') {
          if ($window.Appcues) {
            $window.Appcues.show(item.value);
          }
        } else if (item.type == 'url') {
          $window.open(item.value, '_blank');
        }
      };

      $scope.academyMainCats = AssetService.mainCatsObj;

      // $scope.openChat = function () {
      //     if (typeof window.Intercom !== 'undefined') {
      //         window.Intercom('showNewMessage');
      //     }
      // };

      $scope.clickSearch = function (item, type) {
        HttpService.post('/api/admin/events/searchClick', {
          term: $scope.search.searchString,
          id: item.id,
          name: item.name,
          itemType: type,
          type: 'suggest',
        });
        if (type === 'permissionGroup') {
          $state.go('marketplace.permissiongroup', { permissionGroupId: item.id });
        } else if (type === 'asset') {
          $state.go('academy.asset', { assetId: item.id });
        } else if (type === 'myService') {
          $state.go('service.service', { serviceId: item.id });
        } else if (type === 'mySeviceBundle') {
          $state.go('service.servicebundle', { serviceBundleId: item.id });
        } else if (type === 'myMeetingTemplate') {
          $state.go('meeting.templates');
        } else if (type === 'myGrader') {
          $state.go('marketing.graders');
        } else if (type === 'myEmailSequence') {
          $state.go('marketing.emailsequences');
        } else if (type === 'contact') {
          $state.go('clients.contacts', { clientId: item.ClientId });
        } else if (type === 'client') {
          $state.go('clients.view', { clientId: item.id });
        } else if (type === 'meeting') {
          $state.go('meeting.overview', { meetingId: item.id });
        } else if (type === 'vendorService') {
          $state.go('marketplace.permissiongroup', {
            permissionGroupId: item.PermissionGroups[0].id,
          });
        } else if (type === 'vendorServiceBundle') {
          $state.go('marketplace.permissiongroup', { permissionGroupId: item.id });
        } else if (type === 'vendorMeeting') {
          $state.go('marketplace.permissiongroup', {
            permissionGroupId: item.PermissionGroups[0].id,
          });
        } else if (type === 'vendorGrader') {
          $state.go('marketplace.permissiongroup', {
            permissionGroupId: item.PermissionGroups[0].id,
          });
        } else if (type === 'vendorEmailSequence') {
          $state.go('marketplace.permissiongroup', {
            permissionGroupId: item.PermissionGroups[0].id,
          });
        }
      };

      $scope.goSearch = function (type) {
        $scope.search.type = type;
        if ($scope.search.searchString) {
          $state.go('search.results', $scope.search);
        }
      };

      $scope.$watch('search.searchString', function (newVal, oldVal) {
        if ($scope.search.searchString.length >= 3) {
          if ($scope.search.timeout) {
            $timeout.cancel($scope.search.timeout);
          }
          $scope.search.ongoing = true;
          $scope.search.isopen = true;
          $scope.search.timeout = $timeout(function () {
            HttpService.get(
              '/api/search/suggest/' + InputSanitizerService.sanitize($scope.search.searchString),
            ).then(function (result) {
              $scope.search.searchSuggest = result;
              if ($scope.showSetupItem('services')) $scope.search.searchSuggest.myService = [];
              if ($scope.showSetupItem('serviceBundles'))
                $scope.search.searchSuggest.myServiceBundle = [];
              if ($scope.showSetupItem('meetings'))
                $scope.search.searchSuggest.myMeetingTemplate = [];
              if ($scope.showSetupItem('graders')) $scope.search.searchSuggest.myGrader = [];
              if ($scope.showSetupItem('emailsequences'))
                $scope.search.searchSuggest.myEmailSequence = [];

              if ($scope.can('useMarketing', 'useSales', 'useVcio'))
                $scope.search.searchSuggest.contact = [];
              if ($scope.can('useMarketing', 'useSales', 'useVcio'))
                $scope.search.searchSuggest.client = [];

              if ($scope.showSetupItem('meetings')) $scope.search.searchSuggest.meeting = [];
              if ($scope.showSetupItem('services')) $scope.search.searchSuggest.vendorService = [];
              if ($scope.showSetupItem('serviceBundles'))
                $scope.search.searchSuggest.vendorServiceBundle = [];
              if ($scope.showSetupItem('meetings')) $scope.search.searchSuggest.vendorMeeting = [];
              if ($scope.showSetupItem('graders')) $scope.search.searchSuggest.vendorGrader = [];
              if ($scope.showSetupItem('emailsequences'))
                $scope.search.searchSuggest.vendorEmailSequence = [];

              $scope.search.searchSuggestCount = _.sum(_.map(result, 'length'));
              $scope.search.isopen = true;
              $scope.search.ongoing = false;
              if (_.flatten(_.values(result)).length === 0) {
                $scope.search.noresult = true;
              } else {
                $scope.search.noresult = false;
              }
            });
          }, 1000);
        }
      });

      $scope.openUserProfile = function () {
        $state.go('user.profile');
      };

      $scope.openCompanyProfile = function () {
        $state.go('msp.mycompany');
      };

      $scope.changeCompany = function (company) {
        if (company && CurrentUser.getUser().Company.id != company.id) {
          $rootScope.$broadcast('dataLoadingStarted');
          $http.get('/api/auth/changecompany/' + company.id).success(function (result) {
            Auth.setUser(result.user, result.token);
            $rootScope.previousState = undefined;
            $rootScope.$broadcast('dataLoadingFinished');
            $rootScope.$broadcast('companyChanged');
            window.location = '/new-frontend?path=/home?token=' + InputSanitizerService.sanitize(result.token);
          });
        }
      };

      $scope.openCompanySelector = function () {
        if ($scope.user && $scope.user.Companies.length >= 15 && !$scope.companySelectorModal) {
          $rootScope.$broadcast('dataLoadingStarted');
          $scope.companySelectorModal = $modal.open({
            template:
              '<modal-form form-title="title.selectCompany" onok="ok" oncancel="cancel">' +
              '<input-select model="selected.company" values="companies" keyname="id" valuename="name" search-min="2" allow-clear="false" placeholder="label.search" on-select="ok"></input-select>' +
              '</modal-form>',
            controller: function ($scope, $modalInstance, $http, companies) {
              $rootScope.$broadcast('dataLoadingFinished');
              $scope.companies = companies;
              // $http.get('/api/admin/users/' + CurrentUser.getUser().id + '/companies')
              //     .success(function (companies) {
              //         $scope.companies = companies;
              //     })
              $scope.selected = {};
              $scope.ok = function () {
                $modalInstance.close($scope.selected.company);
              };
              $scope.cancel = function () {
                $modalInstance.dismiss();
              };
            },
            backdrop: 'static',
            resolve: {
              companies: function (HttpService) {
                return HttpService.get(
                  '/api/admin/users/' +
                    InputSanitizerService.sanitize($scope.user.id + '/companies'),
                );
              },
            },
          });

          $scope.companySelectorModal.result.then(
            function (company) {
              $scope.companySelectorModal = undefined;
              $scope.changeCompany(company);
            },
            function () {
              $scope.companySelectorModal = undefined;
            },
          );
        }
      };

      // $scope.openChangePassword = function () {
      //     var user = CurrentUser.getUser();
      //     $scope.selectedUser = user;
      //
      //     var modalScope = $rootScope.$new();
      //     modalScope.user = user;
      //     modalScope.modalInstance = $modal.open({
      //         templateUrl: '/templates/admin/password-change.html',
      //         controller: 'ChangePasswordController',
      //         backdrop: 'static',
      //         scope: modalScope
      //     });
      //
      //     modalScope.result.then(function (message) {
      //     }, function () {
      //     });
      // };
      $scope.toggleShowPermissionGroupLevel2 = function (group) {
        const temp = group.showSubCategories;
        $scope.closeAllPermissionCategory();
        group.showSubCategories = !temp;
      };
      $scope.toggleShowMarketPlace = function () {
        $scope.showMarketPlace = !$scope.showMarketPlace;
      };
      $scope.toggleSolutionSets = function () {
        $scope.showSolutionSets = !$scope.showSolutionSets;
      };

      $scope.closeAllPermissionCategory = function () {
        if ($scope.permissionGroupCategoryTree) {
          _.forEach($scope.permissionGroupCategoryTree, function (permission) {
            permission.showSubCategories = false;
          });
        }
      };

      document.body.addEventListener('click', function (event) {
        const noClose = '.navbar *';
        const close =
          '#nav-company-selector *, ' +
          '#nav-main-search *, ' +
          '#nav-company-selector-modal *, ' +
          '#nav-company-selector-modal-1 *, ' +
          '#nav-main-search-icon *, ' +
          '#nav-main-search-icon-mobile *, ' +
          '#nav-profile *';

        if (!event.target.matches('#mobile-solution-sets *')) {
          $scope.showMarketPlace = false;
          $scope.showSolutionSets = false;
          $scope.closeAllPermissionCategory();
        }
        if (
          (!event.target.matches(noClose) && !$scope.isCollapsed) ||
          (event.target.matches(close) && !$scope.isCollapsed)
        ) {
          $scope.isCollapsed = true;
          $scope.openSearch = false;
          $scope.showMarketPlace = false;
          $scope.showSolutionSets = false;
          $scope.closeAllPermissionCategory();
        }
        $scope.$apply();
      });
      $rootScope.$on('$stateChangeSuccess', function () {
        $scope.isCollapsed = true;
        $scope.showMarketPlace = false;
        $scope.showSolutionSets = false;
        $scope.closeAllPermissionCategory();
      });
    },
  );
