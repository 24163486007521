'use strict';

/**
 * Parses all date like String to Date in the input obj.
 * @param obj
 * @returns {null}
 */
var regexIso8601 =
  /^(\d{4}|\+\d{6})(?:-(\d{2})(?:-(\d{2})(?:T(\d{2}):(\d{2}):(\d{2})\.(\d{1,})(Z|([\-+])(\d{2}):(\d{2}))?)?)?)?$/;
var fieldsToParse = ['updatedAt', 'createdAt', 'assignedAt', 'deadline'];
//var regexIso8601 = /(\d{4})-(\d{2})-(\d{2})T(\d{2})\:(\d{2})\:(\d{2})[+-](\d{2})\:(\d{2})/;
var parseAllStringToDate = function (obj) {
  if (typeof obj !== 'object') return obj;

  for (var key in obj) {
    if (!obj.hasOwnProperty(key)) continue;

    var value = obj[key];
    var match;
    // Check for string properties which look like dates.
    if (
      typeof value === 'string' &&
      fieldsToParse.indexOf(key) > -1 &&
      (match = value.match(regexIso8601))
    ) {
      var milliseconds = Date.parse(match[0]);
      if (!isNaN(milliseconds)) {
        obj[key] = new Date(milliseconds);
      }
    } else if (typeof value === 'object') {
      // Recurse into object
      parseAllStringToDate(value);
    }
  }
};

function compareObjects(s, t) {
  if (typeof s !== typeof t) {
    console.log('two objects not the same type');
    return;
  }
  if (typeof s !== 'object') {
    console.log('arguments are not typeof === "object"');
    return;
  }
  for (var prop in s) {
    if (s.hasOwnProperty(prop)) {
      if (t.hasOwnProperty(prop)) {
        if (!angular.equals(s[prop], t[prop])) {
          console.log('property ' + prop + ' does not match');
        }
      } else {
        console.log('second object does not have property ' + prop);
      }
    }
  }
  // now verify that t doesn't have any properties
  // that are missing from s
  for (prop in t) {
    if (t.hasOwnProperty(prop)) {
      if (!s.hasOwnProperty(prop)) {
        console.log('first object does not have property ' + prop);
      }
    }
  }
}

angular.module('vcio-toolkit').factory('Utils', function ($location, $translate, ngToast, _) {
  return {
    isVendorDomain: function () {
      return (
        $location.host() !== 'localhost' &&
        !_.includes(
          ['tool', 'adm', 'admin', 'test', 'prod', 'local'],
          $location.host().substr(0, $location.host().indexOf('.')),
        )
      );
    },
    copyToClipboard: function (text) {
      var textarea = document.createElement('textarea');
      textarea.value = text;
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px';
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
    },
    showToastMessage: function (message, options) {
      options = options || {};
      var content = $translate.instant(message);
      if (_.isObject(message) && message.code) {
        content = $translate.instant(message.code, message.translateValues);
      }
      ngToast.create({
        className: options.className || 'success',
        content: content,
        dismissButton: true,
        timeout: options.timeout || 10000,
      });
    },
  };
});
