angular.module('vcio-toolkit');
// .controller('NotificationsController', function ($scope, $state, $stateParams, $translate, $http, $modal, CurrentUser) {
//
//     $scope.notifications = []
//     $scope.filter = {type: 'all', message: ''};
//     $scope.availableTypes = ['all', 'surveyResultStarted', 'surveyIdle', 'surveyContinued', 'surveyResultFinished', 'leadMagnetHit']
//
//     $scope.refresh = function () {
//         $http.get('/api/notifications')
//             .success(function (notifications) {
//                 $scope.notifications = notifications;
//             })
//     }
//
//     $scope.refresh();
//
//     $scope.messageFilter = function (item) {
//         var text = item.message + (item.User ? item.User.firstName + item.User.lastName : '') + (item.Company ? item.Company.name : '');
//         return $scope.filter.message === '' ||
//             (text.toLowerCase().indexOf($scope.filter.message.toLowerCase()) > -1);
//     }
//
//     $scope.typeFilter = function (item) {
//         return $scope.filter.type == 'all' ||
//             (item.type == $scope.filter.type);
//     }
//
//     $scope.linkFilter = function (item) {
//         return item && item.args.link && item.message.indexOf('https://') === -1;
//     }
//
//     $scope.openShow = function (notification) {
//         var modalInstance = $modal.open({
//             template: '<modal-form form-title="title.notification" onok="ok" label-ok="button.ok"><p ng-bind-html="notification.message"></p></modal-form>',
//             controller: function($scope, $modalInstance, notification){
//                 $scope.notification = notification;
//                 $scope.ok = function () {
//                     $modalInstance.close();
//                 };
//             },
//             size: 'lg',
//             resolve: {
//                 notification: function () {
//                     return notification;
//                 }
//             }
//         });
//
//         modalInstance.result.then(function (announcement) {
//         }, function () {
//         });
//     };
//
// });
