angular
  .module('vcio-toolkit')

  .controller(
    'TaskTemplatesController',
    function ($scope, $translate, $modal, HttpService, CurrentUser) {
      $scope.editTemplate = null;
      $scope.newTemplateExpended = false;

      $scope.getTaskTemplates = function () {
        return HttpService.get('/api/tasktemplates').then(function (taskTemplates) {
          $scope.taskTemplates = taskTemplates;
        });
      };
      $scope.getTaskTemplates();

      $scope.updateTaskTemplate = function () {
        return HttpService.put('/api/tasktemplates');
      };

      $scope.editTaskTemplate = function (taskTemplate) {
        if (!$scope.editTemplate) {
          $scope.editTemplate = _.cloneDeep(taskTemplate);
          taskTemplate.$$editing = true;
        }
      };

      $scope.deleteTaskTemplate = function (taskTemplate) {
        $scope.confirm($translate('confirm.question'), function () {
          HttpService.delete('/api/tasktemplates/' + taskTemplate.id).then(
            setTimeout(function () {
              $scope.getTaskTemplates();
            }, 100),
          );
        });
      };

      $scope.newTaskTemplate = function () {
        if (!$scope.editTemplate) {
          $scope.newTemplateExpanded = true;
          $scope.editTemplate = {};
        }
      };

      $scope.cancelEdit = function (taskTemplate) {
        if (taskTemplate) {
          taskTemplate.$$editing = false;
        } else {
          $scope.newTemplateExpanded = false;
        }
        $scope.editTemplate = null;
      };

      $scope.saveEdit = function (taskTemplate) {
        if (!$scope.validForm()) {
          return;
        }

        if (taskTemplate) {
          HttpService.put('/api/tasktemplates/' + taskTemplate.id, $scope.editTemplate).then(
            function () {
              $scope.taskTemplates = $scope.getTaskTemplates();
              $scope.editTemplate = null;
              taskTemplate.$$editing = false;
            },
          );
        } else {
          $scope.editTemplate.CompanyId = CurrentUser.getUser().companyId;

          HttpService.post('/api/tasktemplates/', { taskTemplate: $scope.editTemplate }).then(
            function () {
              $scope.taskTemplates = $scope.getTaskTemplates();
              $scope.editTemplate = null;
              $scope.newTemplateExpanded = false;
            },
          );
        }
      };

      $scope.validForm = function () {
        if (
          $scope.editTemplate.name &&
          $scope.editTemplate.description &&
          $scope.editTemplate.hours
        ) {
          return true;
        }

        return false;
      };

      $scope.confirm = function (message, _ok, _cancel) {
        var modalInstance = $modal.open({
          templateUrl: '/templates/modal-confirm.html',
          controller: function ($scope, $modalInstance, message) {
            $scope.message = message;
            $scope.ok = function () {
              $modalInstance.close(true);
            };

            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
          resolve: {
            message: function () {
              return message;
            },
          },
        });

        modalInstance.result.then(function () {
          _ok();
        });
      };
    },
  );
