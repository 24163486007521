angular
  .module('vcio-toolkit')

  .controller(
    'CompanyPaymentController',
    function (
      $http,
      $location,
      $modal,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $translate,
      $window,
      _,
      Auth,
      CurrentUser,
      DialogService,
      HttpService,
      company,
      products,
    ) {
      $scope.company = company;
      $scope.customer = undefined;
      $scope.offers = [];
      $scope.invoices = [];
      $scope.products = _(products)
        .filter({ type: 'subscription' })
        .map(function (product, i, products) {
          var minClients = i > 0 ? products[i - 1].maxClients + 1 : 1;
          return _.extend(product, {
            name: $translate.instant('label.payment.planDropdownTitle', {
              name: product.metadata.name || '',
              monthlyFee: _.get(
                _.find(product.plans, { billingCycle: 'monthly' }) || product.plans[0],
                'monthlyFee',
              ),
            }),
          });
        })
        .value();
      $scope.addons = _(products)
        .filter(function (addon) {
          return addon.type == 'addon' && !_.isEmpty(addon.plans) && addon.metadata.module;
        })
        .map(function (addon) {
          return _.extend(addon, {
            monthlyFee: _.get(
              _.find(addon.plans, { billingCycle: 'monthly' }) || addon.plans[0],
              'monthlyFee',
            ),
            selected: CurrentUser.hasModule(addon.metadata.module),
          });
        })
        .value();
      $scope.selected = {};

      if ($stateParams.code) {
        HttpService.post('/api/payment/connect', { authCode: $stateParams.code })
          .then(function () {
            DialogService.message('label.payment.stripe.connected');
            //$state.go('msp.payment', {}, {reload: true})
            $location.url($location.path());
          })
          .catch(function (error) {
            DialogService.error(error);
          });
      }

      if (CurrentUser.can('managePaymentConnect')) {
        // if (!_.get($scope.company.keys.stripe, 'stripe_user_id')) {
        HttpService.get('/api/payment/connect/url').then(function (stripeConnectUrl) {
          $scope.stripeConnectUrl = stripeConnectUrl;
        });
        // }
      }

      $scope.disconnectStripe = function () {
        HttpService.delete('/api/payment/connect')
          .then(function () {
            delete $scope.company.keys.stripe;
            DialogService.message('label.payment.stripe.disconnected');
          })
          .catch(function (error) {
            DialogService.error(error);
          });
      };

      // $scope.getFee = function (cycle, product) {
      //     var plan = _.find(product.plans, {billingCycle: cycle});
      //     if (plan) {
      //         return plan.monthlyFee;
      //     } else {
      //         return undefined;
      //     }
      // };
      //
      // $scope.hasPremiumPlan = function (product) {
      //     return $scope.customer && $scope.customer.subscription && (!product || $scope.customer.subscription.plan.product == product.id);
      // };

      $scope.hasCustomPlan = function () {
        return (
          $scope.customer &&
          $scope.customer.subscription && //!_.includes(_.map($scope.products, 'id'), $scope.customer.subscription.plan.product);
          _.isEmpty(
            _.intersection(
              _($scope.products).flatMap('plans').map('id').value(),
              _.map($scope.customer.subscription.items.data, 'plan.id'),
            ),
          )
        );
      };

      $scope.hasSamePlan = function () {
        return _.get($scope.customer, 'subscription.plan.id') == _.get($scope.selected, 'plan.id');
      };

      // $scope.hasFreePlan = function () {
      //     return !$scope.customer || !$scope.customer.subscription;
      // };

      $scope.getFeatures = function (product) {
        return _.toArray(
          _.pickBy(product.metadata, function (value, key) {
            return _.startsWith(key, 'feature');
          }),
        );
      };

      // $scope.refreshOffers = function () {
      //     if ($scope.customer) {
      //         $http.get('/api/companies/' + company.id + '/payment/offers')
      //             .success(function (offers) {
      //                 $scope.offers = _.filter(offers, {status: 'created'});
      //                 if ($location.search().offer && _.find($scope.offers, {id: parseInt($location.search().offer)})) {
      //                     $scope.openOffer(_.find($scope.offers, {id: parseInt($location.search().offer)}));
      //                 }
      //
      //             })
      //     }
      // };

      $scope.refreshInvoices = function () {
        $http
          .get('/api/companies/' + company.id + '/payment/invoices')
          .success(function (invoices) {
            $scope.invoices = invoices;
          });
      };

      $scope.refreshCustomer = function () {
        $rootScope.$broadcast('dataLoadingStarted');
        $http.get('/api/companies/' + company.id).success(function (company) {
          $scope.company = company;
          if ($scope.company.stripeCustomerId) {
            $http
              .get('/api/companies/' + company.id + '/payment/customer')
              .success(function (customer) {
                $scope.customer = customer;
                if (!customer) {
                  $scope.company.stripeCustomerId = undefined;
                }
                if (
                  $location.search().edit ===
                  'card' /*|| !$scope.customer || !$scope.customer.card*/
                ) {
                  $scope.openEditCard();
                  // } else if ($scope.company.modules.length === 0) {
                  //     $scope.openUpgrade();
                }

                // $scope.refreshOffers();
                $scope.refreshInvoices();
                $rootScope.$broadcast('dataLoadingFinished');
              })
              .error(function (error) {
                $rootScope.$broadcast('dataLoadingFinished');
                DialogService.error(error);
              });
          } else {
            $rootScope.$broadcast('dataLoadingFinished');
            // $scope.openEditCard();
          }
        });
      };

      $scope.refreshCustomer();

      $scope.cancelPlan = function () {
        if ($scope.customer.subscription) {
          DialogService.confirm(
            $translate.instant('label.payment.cancelPlan.confirm'),
            'confirm.question',
            function () {
              $rootScope.$broadcast('dataLoadingStarted');
              HttpService.delete('/api/companies/' + $scope.company.id + '/payment/plan')
                .then(function () {
                  $rootScope.$broadcast('dataLoadingFinished');
                  DialogService.message('label.payment.cancelPlan.success');
                  Auth.refreshToken();
                  $scope.refreshCustomer();
                })
                .catch(function (error) {
                  $rootScope.$broadcast('dataLoadingFinished');
                  DialogService.error(error);
                });
            },
          );
        }
      };

      // $scope.openUpgrade = function (product) {
      //     // if (!$scope.hasPremiumPlan(product)) {
      //         if ($scope.customer) {
      //             upgrade(product);
      //         } else {
      //             $scope.openEditCard(function () {
      //                 upgrade(product);
      //             })
      //         }
      //     // }
      // };

      $scope.openEditCard = function (cb) {
        var customer = $scope.customer;
        if (!$scope.company.stripeCustomerId && !customer) {
          customer = { card: {} };
        }
        if (customer) {
          var modalInstance = $modal.open({
            templateUrl: '/templates/admin/company-payment-card.html',
            controller: 'CompanyPaymentCardEditController',
            backdrop: 'static',
            resolve: {
              customer: function () {
                return customer;
              },
              company: function () {
                return $scope.company;
              },
            },
          });

          modalInstance.result.then(
            function () {
              $scope.refreshCustomer();
              if (cb) {
                cb();
              }
            },
            function () {},
          );
        }
      };

      $scope.hasModule = function (module) {
        return _.includes($scope.company.modules, module);
      };

      $scope.openReceipts = function (invoice) {
        _.forEach(invoice.receipts, function (url) {
          $window.open(url, '_blank');
        });
      };

      // $scope.openInvoice = function (invoice) {
      //     var modalInstance = $modal.open({
      //         templateUrl: '/templates/admin/company-payment-invoice.html',
      //         controller: function ($scope, $modalInstance, invoice, company) {
      //             $scope.invoice = invoice;
      //             $scope.company = company;
      //
      //             $scope.close = function () {
      //                 $modalInstance.dismiss();
      //             }
      //         },
      //         backdrop: 'static',
      //         resolve: {
      //             invoice: function () {
      //                 return invoice;
      //             },
      //             company: function () {
      //                 return $scope.company;
      //             }
      //         }
      //     });
      //
      //     modalInstance.result.then(function (page) {
      //     }, function () {
      //     });
      // }
    },
  )

  .controller(
    'CompanyPaymentCardEditController',
    function ($scope, $rootScope, $modalInstance, $translate, _, HttpService, customer, company) {
      $scope.customer = customer || {};
      $scope.company = company;
      //$scope.card = customer ? customer.card : {};
      $scope.years = [];
      $scope.months = [];
      var y = new Date().getFullYear();
      for (var i = 0; i < 12; i++) {
        $scope.years[i] = y + i;
        $scope.months[i] = i + 1;
        //$scope.years[i] = {key: y + i, value: y + i};
        //$scope.months[i] = {key: i + 1, value: i + i};
      }

      if (
        $scope.customer &&
        $scope.customer.card &&
        !_.includes($scope.years, $scope.customer.card.expYear)
      ) {
        $scope.years.unshift($scope.customer.card.expYear);
      }

      // $scope.countries = [];
      // HttpService.get('/api/lists/country')
      //     .then(function (countryCodes) {
      //         countryCodes.forEach(function (code) {
      //             $scope.countries.push($translate.instant('country.' + code));
      //         })
      //     });

      $scope.save = function (cb) {
        HttpService.put('/api/companies/' + company.id + '/payment/customer', {
          customer: $scope.customer,
          card: $scope.customer.card,
        })
          .then(function (customerId) {
            $modalInstance.close(customerId);
          })
          .catch(function (error) {
            $scope.errors = [error];
            cb();
          });
      };

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };

      $scope.cardDataRequired = function () {
        return (
          $scope.customer &&
          $scope.customer.card &&
          (!$scope.customer.card.last4 || $scope.customer.card.number)
        );
      };
    },
  )

  .controller(
    'CompanyPaymentPlanUpgradeController',
    function (
      $http,
      $rootScope,
      $scope,
      $translate,
      _,
      Auth,
      DialogService,
      HttpService,
      InputSanitizerService,
    ) {
      $scope.billingCycles = [];
      $scope.subscription = {};
      $scope.selected.product = _.get($scope.products, 0);

      $scope.updatePrices = function () {
        if ($scope.selected.product && $scope.selected.plan) {
          var monthlyPrice =
            $scope.getMonthlyFee($scope.selected.product, $scope.subscription.billingCycle) +
            _($scope.addons)
              .filter({ selected: true })
              .map(function (addon) {
                return $scope.getMonthlyFee(addon, $scope.subscription.billingCycle);
              })
              .sum();
          var monthlyFeeByMonth =
            $scope.getMonthlyFee($scope.selected.product, 'monthly') +
            _($scope.addons)
              .filter({ selected: true })
              .map(function (addon) {
                return $scope.getMonthlyFee(addon, 'monthly');
              })
              .sum();
          var monthlyFeeByYear =
            $scope.getMonthlyFee($scope.selected.product, 'annual') +
            _($scope.addons)
              .filter({ selected: true })
              .map(function (addon) {
                return $scope.getMonthlyFee(addon, 'annual');
              })
              .sum();

          $scope.upgradeTranslateValues = {
            cycle: $scope.subscription.billingCycle,
            totalFee:
              $scope.getFee($scope.selected.product) +
              _($scope.addons)
                .filter({ selected: true })
                .map(function (addon) {
                  return $scope.getFee(addon);
                })
                .sum(),
            monthlyPrice: _.round(monthlyPrice, 2),
            monthlyFee: _.round(monthlyFeeByMonth, 2),
            difference:
              $scope.subscription.billingCycle == 'annual'
                ? _.round(12 * (monthlyFeeByMonth - monthlyPrice))
                : _.round(12 * (monthlyPrice - monthlyFeeByYear)),
          };
        } else {
          $scope.upgradeTranslateValues = {
            monthlyPrice: 0,
            monthlyFee: 0,
            difference: 0,
          };
        }
      };

      $scope.$watch('customer', function () {
        $scope.selected.product =
          _.find($scope.products, function (product) {
            return !_.isEmpty(
              _.intersection(
                _.map(product.plans, 'id'),
                _.map(_.get($scope.customer, 'subscription.items.data'), 'plan.id'),
              ),
            );
          }) ||
          $scope.selected.product ||
          _.get($scope.products, 0);
      });

      $scope.$watch('selected.product', function (product) {
        $scope.plans = $scope.selected.product ? $scope.selected.product.plans : [];
        $scope.billingCycles = _($scope.plans).map('billingCycle').uniq().value();
        $scope.subscription.billingCycle =
          _.get($scope.customer, 'subscription.items.data[0].plan.billingCycle') ||
          _.get($scope.billingCycles, 0);
        $scope.selected.plan = _.find($scope.plans, {
          billingCycle: $scope.subscription.billingCycle,
        });
        $scope.updatePrices();
      });

      $scope.$watch('subscription.billingCycle', function (billingCycle) {
        $scope.selected.plan = _.find($scope.plans, { billingCycle: billingCycle });
        // $scope.clientsCount = Math.max(clients.length, $scope.selected.plan.minClients);
        $scope.updatePrices();
      });

      $scope.getMonthlyFee = function (product, cycle) {
        var plan =
          _.find(product.plans, { billingCycle: cycle }) ||
          _.find(product.plans, { billingCycle: 'monthly' });
        if (plan) {
          return plan.monthlyFee;
        } else {
          return undefined;
        }
      };

      $scope.getFee = function (product, cycle) {
        var plan = _.find(product.plans, {
          billingCycle: cycle || $scope.subscription.billingCycle,
        });
        if (plan) {
          if (plan.billingCycle === 'monthly') {
            return plan.monthlyFee;
          } else {
            return plan.annualFee;
          }
        } else {
          return undefined;
        }
      };

      // $scope.cancel = function () {
      //     $modalInstance.dismiss();
      // };

      var doUpgrade = function () {
        if ($scope.selected.plan.id) {
          DialogService.confirm(
            $translate.instant(
              'label.payment.upgradePlan.confirm',
              {
                totalFee: $scope.upgradeTranslateValues.totalFee,
                cycle: $scope.subscription.billingCycle,
              },
              'messageformat',
            ),
            'label.payment.upgradePlan.confirm.title',
            function () {
              $rootScope.$broadcast('dataLoadingStarted');
              HttpService.put(
                '/api/companies/' +
                  InputSanitizerService.sanitize($scope.company.id) +
                  '/payment/plan',
                {
                  planId: $scope.selected.plan.id,
                  coupon: $scope.subscription.promoCode,
                  addonPlanIds: _($scope.addons)
                    .filter({ selected: true })
                    .map(function (addon) {
                      return (
                        _.find(addon.plans, { billingCycle: $scope.selected.plan.billingCycle }) ||
                        _.find(addon.plans, { billingCycle: 'monthly' })
                      ).id;
                    })
                    .value(),
                },
              )
                .then(function () {
                  $rootScope.$broadcast('dataLoadingFinished');
                  DialogService.message('label.payment.upgradePlan.success');
                  Auth.refreshToken();
                  $scope.refreshCustomer();
                })
                .catch(function (error) {
                  $rootScope.$broadcast('dataLoadingFinished');
                  DialogService.error(error);
                });
            },
          );
        }
      };

      $scope.upgrade = function () {
        if ($scope.customer) {
          doUpgrade();
        } else {
          $scope.openEditCard(function () {
            doUpgrade();
          });
        }
      };
    },
  )

  .controller(
    'CompanyPageEditController',
    function ($scope, $rootScope, $state, $http, $modalInstance, _, page, companyId, type) {
      $scope.page = page;
      $scope.companyId = companyId;
      $scope.type = type;

      $scope.sizes = ['S', 'M', 'L'];
      $scope.buttonStyle = {};
      $scope.page.design = $scope.page.design || {};
      if (!$scope.page.design.button) {
        $scope.page.design.button = {
          //width: '100%',
          fontSize: 'M',
          textColor: '#333333',
          backgroundColor: '#e7e7e7',
          borderColor: '#e7e7e7',
          text: 'Start survey...',
          fontFamily: 'Arial',
        };
      }
      $scope.$watch(
        'page.design',
        function () {
          $scope.buttonStyle.color = $scope.page.design.button.textColor;
          $scope.buttonStyle.backgroundColor = $scope.page.design.button.backgroundColor;
          $scope.buttonStyle.border = '1px solid ' + $scope.page.design.button.borderColor;
          $scope.buttonStyle.borderRadius = ($scope.page.design.button.borderRadius || '0') + 'px';
          $scope.buttonStyle.fontFamily = $scope.page.design.button.fontFamily;
          $scope.buttonStyle.fontSize = '15px';
          if ($scope.page.design.button.fontSize === 'S') {
            $scope.buttonStyle.fontSize = '10px';
          } else if ($scope.page.design.button.fontSize === 'L') {
            $scope.buttonStyle.fontSize = '24px';
          }
          if ($scope.page.design.button.fullWidth) {
            $scope.buttonStyle.width = '100%';
          } else {
            delete $scope.buttonStyle.width;
          }
        },
        true,
      );

      $scope.save = function (cb) {
        if ($scope.page.id) {
          $http
            .put('/api/sales/pages/' + $scope.page.id, { page: $scope.page })
            .success(function (result) {
              $modalInstance.close(result);
            })
            .error(function (error) {
              $scope.errors = [error];
              cb();
            });
        } else {
          $http
            .post('/api/sales/pages', { page: $scope.page })
            .success(function (result) {
              $modalInstance.close(result);
            })
            .error(function (error) {
              $scope.errors = [error];
              cb();
            });
        }
      };

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };
    },
  )

  .controller(
    'CompanyPagesController',
    function (
      $scope,
      $rootScope,
      $state,
      $modal,
      $http,
      _,
      CurrentUser,
      DialogService,
      company,
      pages,
    ) {
      $scope.filter = { name: '', type: '' };
      $scope.hasNoPages = false;
      $scope.currentUser = CurrentUser.getUser();
      $scope.company = company;
      $scope.pages = pages;
      $scope.newPage = {};
      $scope.showHint = false;

      $scope.newPage = { design: { button: {} } };
      $scope.sizes = ['S', 'M', 'L'];
      $scope.buttonStyle = {};

      function resetNewPage() {
        $scope.newPage.design = {};
        $scope.newPage.design.button = {
          //width: '100%',
          fontSize: 'M',
          textColor: '#333333',
          backgroundColor: '#e7e7e7',
          borderColor: '#e7e7e7',
          text: 'Start survey...',
          fontFamily: 'Arial',
        };
      }

      resetNewPage();

      if (pages.length === 0) {
        $scope.hasNoPages = true;
      }
      // $scope.defaultPage = _.find($scope.pages, {id: $scope.company.settings.defaultLandingPage || 0});
      //
      // $scope.saveDefaultPage = function () {
      //     var id = parseInt($scope.defaultPage || '0');
      //     $http.put('/api/companies/' + $scope.company.id + '/settings/defaultLandingPage', {value: id})
      //         .success(function () {
      //             $scope.company.settings.defaultLandingPage = id;
      //             var user = CurrentUser.getUser();
      //             user.Company.settings.defaultLandingPage = id;
      //             CurrentUser.setUser(user);
      //         })
      // };

      $scope.nameFilter = function (item) {
        return (
          $scope.filter.name === '' ||
          item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1 ||
          (item.Owner &&
            item.Owner.name &&
            item.Owner.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1)
        );
      };

      $scope.hint = function () {
        $scope.showHint = !$scope.showHint;
      };

      //$http.get('/api/companies/' + $scope.company.id + '/pages')
      //    .success(function (pages) {
      //        $scope.pages = pages;
      //    })

      $scope.canEdit = function (page) {
        return page.CompanyId === $scope.company.id;
      };

      $scope.copy = function (_page) {
        var page = angular.copy(_page);
        delete page.id;
        page.public = false;
        page.CompanyId = $scope.company.id;
        $scope.edit(page);
      };

      $scope.edit = function (_page) {
        if (!_page || $scope.canEdit(_page)) {
          $scope.selectedPage = _page || {};
          var modalInstance = $modal.open({
            templateUrl: '/templates/admin/company-page.html',
            controller: 'CompanyPageEditController',
            backdrop: 'static',
            size: 'lg',
            resolve: {
              page: function () {
                return angular.copy($scope.selectedPage);
              },
              companyId: function () {
                return $scope.company.id;
              },
              type: function () {
                return $scope.selectedPage.id ? 'edit' : 'add';
              },
            },
          });

          modalInstance.result.then(
            function (page) {
              if (!_page || !_page.id) {
                $scope.pages.push(page);
                $scope.hasNoPages = false;
              } else {
                angular.copy(page, $scope.selectedPage);
              }
            },
            function () {},
          );
        }
      };

      $scope.delete = function (page) {
        if ($scope.canEdit(page)) {
          $http
            .delete('/api/sales/pages/' + page.id)
            .success(function () {
              _.remove($scope.pages, function (item) {
                return item.id === page.id;
              });
              if ($scope.pages.length === 0) {
                $scope.hasNoPages = true;
              }
            })
            .error(function (error) {
              $scope.errors = [error];
            });
        }
      };

      $scope.save = function (page) {
        $rootScope.$broadcast('dataLoadingStarted');
        $http
          .post('/api/sales/pages', { page: $scope.newPage })
          .success(function (result) {
            $scope.pages.push(_.cloneDeep(result));
            $scope.newPage.expanded = false;
            resetNewPage();
            $rootScope.$broadcast('dataLoadingFinished');
          })
          .error(function (error) {
            $scope.errors = [error];
            $rootScope.$broadcast('dataLoadingFinished');
          });
      };

      $scope.cancel = function () {
        $scope.newPage.expanded = false;
        resetNewPage();
      };

      $scope.showScript = function (page) {
        var message =
          '<pre rows="4">&lt;script type="text/javascript" id="ryc-insert-script"<br> src="https://tool.managedservicesplatform.com/js/insertfill.js' +
          (page.id ? '?landingPageId=' + page.id : '') +
          '"&gt;&lt;/script&gt;</pre>';
        DialogService.message(message, 'surveyShare.shareScript', 'lg');
      };

      $scope.pageOperations = [
        { operation: $scope.copy, label: 'button.copy', icon: 'copy' },
        { operation: $scope.edit, label: 'button.edit', icon: 'edit', condition: $scope.canEdit },
        { operation: $scope.showScript, label: 'button.share', icon: 'share-alt' },
        {
          operation: $scope.delete,
          label: 'button.delete',
          icon: 'minus-square-o',
          condition: $scope.canEdit,
          confirmation: true,
        },
      ];
    },
  )

  // .controller('CompanyKeysController', function ($scope, $rootScope, $state, $modal, $http, _, CurrentUser, DialogService, company) {
  //     $scope.currentUser = CurrentUser.getUser();
  //     $scope.keys = company.keys || {};
  //     $scope.submitting = false;
  //
  //     $scope.delete = function (name) {
  //         $scope.submitting = true;
  //         $http.put('/api/companies/' + $scope.currentUser.Company.id + '/settings/keys.' + name, {value: undefined})
  //             .success(function () {
  //                 // $scope.messages = ['message.deleted'];
  //                 _.unset($scope.currentUser.Company.keys, name);
  //                 $scope.keys = $scope.currentUser.Company.keys || {};
  //                 CurrentUser.setUser($scope.currentUser);
  //                 $rootScope.$broadcast('login-changed-event');
  //                 $scope.submitting = false;
  //             })
  //             .error(function (error) {
  //                 DialogService.error(error, 'message.connectionFailed');
  //                 $scope.submitting = false;
  //             })
  //     };
  //
  //     $scope.save = function (name) {
  //         $scope.submitting = true;
  //         $scope.test(name, function (testResult) {
  //             if (testResult.success) {
  //                 $http.put('/api/companies/' + $scope.currentUser.Company.id + '/settings/keys.' + name, {value: $scope.keys[name]})
  //                     .success(function () {
  //                         // $scope.messages = ['message.saved'];
  //                         DialogService.message(testResult, 'message.connectionSuccess');
  //                         $scope.currentUser.Company.keys[name] = $scope.keys[name];
  //                         CurrentUser.setUser($scope.currentUser);
  //                         $rootScope.$broadcast('login-changed-event');
  //                         $scope.submitting = false;
  //                     })
  //                     .error(function (error) {
  //                         $scope.submitting = false;
  //                         DialogService.error(error, 'message.connectionFailed');
  //                         // $scope.errors = [error];
  //                     })
  //
  //             } else {
  //                 $scope.submitting = false;
  //                 DialogService.error(testResult, 'message.connectionFailed');
  //             }
  //         })
  //     };
  //
  //     $scope.test = function (name, cb) {
  //         $http.post('/api/companies/' + $scope.currentUser.Company.id + '/settings/keys/' + name, $scope.keys[name])
  //             .success(function (result) {
  //                 cb({code: 'message.companiesCount', translateValues: {count: result.count}, success: true});
  //             })
  //             .error(function (error) {
  //                 cb(error);
  //             })
  //     }
  // })

  .controller(
    'CompanyEditController',
    function (
      $scope,
      $rootScope,
      $state,
      $http,
      $location,
      $modal,
      _,
      CurrentUser,
      HttpService,
      UploadService,
      DialogService,
      company,
      InputSanitizerService,
    ) {
      $scope.currentUser = CurrentUser.getUser();
      $scope.company = company;
      $scope.coachUsers = [];
      if (company.id) {
        $scope.logoUrl = '/images/companies/' + company.id + '?' + new Date().getTime();
        $http
          .get('/api/admin/users?companyId=' + company.id + '&disabled=true')
          .success(function (users) {
            $scope.company.Users = users;
          });
      }

      HttpService.get(
        '/api/admin/users?companyId=' +
          InputSanitizerService.sanitize($scope.currentUser.Company.id),
      ).then(function (result) {
        $scope.coachUsers = result;
      });

      $scope.getCoachNameFunction = function (user) {
        return user.lastName + ', ' + user.firstName;
      };

      $scope.canSendSignupEmail = function (user) {
        return user.disabled;
      };

      // $scope.resendSignupEmail = function (user) {
      //     $http.get('/api/admin/users/' + user.id + '/resendsignup')
      //         .success(function () {
      //             $scope.messages = ['success'];
      //         }).error(function (error) {
      //         $scope.errors = [error];
      //     })
      // };

      $scope.showSignupLink = function (user) {
        var modalInstance = $modal.open({
          template:
            '<modal-form form-title="label.sendSignupLink" header-class="bg-success" onok="ok" oncancel="cancel"><p translate="label.sendSignupLinkText"></p><textarea class="form-control code" rows="4">{{_message}}</textarea></modal-form>',
          backdrop: 'static',
          size: 'lg',
          controller: function ($scope, $modalInstance) {
            $scope.ok = function () {
              $modalInstance.close(true);
            };
            $scope._message = 'https://' + $location.host() + '/signup/' + user.signupTicket;
          },
        });

        modalInstance.result.then(
          function () {},
          function () {},
        );
      };

      var modalInstance = $scope.modalInstance;

      $scope.openCompanyLogo = function (company) {
        company.uploadUrl = '/api/images/companies/' + company.id + '?companyid=' + company.id;
        UploadService.open('companies', company).then(function () {
          $scope.logoUrl = '/images/companies/' + company.id + '?' + new Date().getTime();
        });
      };

      $scope.validateDomain = function (cb) {
        HttpService.post('/api/validate/domain', {
          domain: $scope.company.domain,
          companyId: $scope.company.id,
        })
          .then(function (result) {
            if (cb) {
              cb();
            }
          })
          .catch(function (error) {
            if (cb) {
              cb(error);
            }
          });
      };

      $scope.save = function (cb) {
        $scope.validateDomain(function (error) {
          if (!error) {
            if ($scope.company.id) {
              $http
                .put('/api/companies/', { company: $scope.company })
                .success(function (result) {
                  $scope.company = result;
                  $scope.messages = ['message.saved'];
                  if ($scope.currentUser.Company.id === result.id) {
                    var currentUser = CurrentUser.getUser();
                    currentUser.Company.domain = result.domain;
                    CurrentUser.setUser(currentUser);
                  }
                  if (cb) {
                    cb();
                  }
                  if (modalInstance) {
                    modalInstance.close(result);
                  }
                })
                .error(function (error) {
                  if (cb) {
                    cb();
                  }
                  $scope.errors = [error];
                  console.error(error);
                });
            } else {
              $http
                .post('/api/companies', { company: $scope.company })
                .success(function (result) {
                  $scope.company = result;
                  cb();
                  if (modalInstance) {
                    modalInstance.close(result);
                  }
                })
                .error(function (error) {
                  cb();
                  $scope.errors = [error];
                  console.error(error);
                });
            }
          } else {
            cb();
            DialogService.error(error);
          }
        });
      };

      $scope.canEdit = function (company) {
        return (
          company &&
          (company.id === $scope.currentUser.Company.id || CurrentUser.can('modifyAnyCompany'))
        );
      };

      $scope.cancel = function () {
        if (modalInstance) {
          modalInstance.dismiss();
        }
      };

      $scope.revert = function () {
        $http.get('/api/companies/' + CurrentUser.getUser().Company.id).success(function (company) {
          $scope.company = company;
        });
      };

      $scope.getTitle = function () {
        if (!company.id) {
          return 'title.createCompany';
        } else if ($scope.canEdit(company)) {
          return 'title.editCompany';
        } else {
          return 'title.createCustomer';
        }
      };

      $scope.isSysadmin = function () {
        return CurrentUser.hasModule('system');
      };

      $scope.companyIsVendor = function (company) {
        return _.includes(company.modules, 'vendor');
      };
    },
  )

  .controller(
    'MyCompanyController',
    function (
      $filter,
      $location,
      $modal,
      $rootScope,
      $scope,
      $state,
      $translate,
      _,
      Auth,
      CurrentUser,
      HttpService,
      UploadService,
      DialogService,
      company,
      users,
      permissionGroups,
      InputSanitizerService,
    ) {
      $scope.logoUrl = null;
      $scope.currentUser = CurrentUser.getUser();
      $scope.company = company;
      $scope.company.Users = mapUsers(users);
      $scope.accessGrantor = _.find($scope.company.Users, { id: $scope.company.accessGrantor });
      $scope.accessGranted = $filter('date')($scope.company.accessGranted, 'medium');
      $scope.isVendor = function () {
        return CurrentUser.hasModule('vendor');
      };
      $scope.permissionGroups = permissionGroups;
      $scope.coachUsers = [];
      $scope.newMember = {};

      HttpService.get('/api/lists/currency').then(function (results) {
        $scope.currencies = results;
      });

      HttpService.get('/api/lists/role').then(function (roles) {
        $scope.roles = _.map(roles, function (role) {
          return { id: role, name: role };
        });
      });

      if (company.id) {
        $scope.logoUrl = '/images/companies/' + company.id + '?' + new Date().getTime();
      }

      $scope.openCompanyLogo = function (company) {
        UploadService.open('companies', company).then(function () {
          $scope.logoUrl = '/images/companies/' + company.id + '?' + new Date().getTime();
        });
      };

      $scope.validateDomain = function (cb) {
        HttpService.post('/api/validate/domain', {
          domain: $scope.company.domain,
          companyId: $scope.company.id,
        })
          .then(function (result) {
            if (cb) {
              cb();
            }
          })
          .catch(function (error) {
            if (cb) {
              cb(error);
            }
          });
      };

      $scope.save = function (cb) {
        $scope.validateDomain(function (error) {
          if (!error) {
            HttpService[$scope.company.id ? 'put' : 'post']('/api/companies/', {
              company: $scope.company,
            })
              .then(function (result) {
                _.extend($scope.company, result);
                Auth.refreshToken();
                DialogService.message('message.saved');
                cb();
              })
              .catch(function (error) {
                cb();
                DialogService.error(error);
              });
          } else {
            cb();
            DialogService.error(error);
          }
        });
      };

      $scope.revert = function () {
        HttpService.get(
          '/api/companies/' + InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
        ).then(function (company) {
          $scope.company = company;
        });
      };

      function mapUsers(users) {
        return _.map(users, function (user) {
          return _.extend(user, { $$role: user.Companies[0].UserCompanyRole.AuthRoleCode });
        });
      }

      $scope.changeUserRole = function (item, role, user) {
        if (role !== user.Companies[0].UserCompanyRole.AuthRoleCode) {
          HttpService.post(
            '/api/admin/users/' +
              InputSanitizerService.sanitize(user.id) +
              '/companies/' +
              InputSanitizerService.sanitize($scope.company.id),
            { role: role },
          )
            .then(function () {
              user.$$roleSelect = false;
              user.Companies[0].UserCompanyRole.AuthRoleCode = role;
            })
            .catch(function (error) {
              user.$$roleSelect = false;
              DialogService.error(error);
            });
        } else {
          user.$$roleSelect = false;
        }
      };

      $scope.refreshTeam = function () {
        HttpService.get(
          '/api/admin/users?disabled=true&companyId=' +
            InputSanitizerService.sanitize($scope.company.id),
        ).then(function (users) {
          $scope.company.Users = mapUsers(users);
        });
      };

      $scope.getUser = function () {
        if (
          !$scope.newMember.id &&
          $scope.newMember.email &&
          EMAIL_REGEX.test($scope.newMember.email)
        ) {
          HttpService.get(
            '/api/admin/users/' + InputSanitizerService.sanitize($scope.newMember.email),
          ).then(
            function (_user) {
              if (_user) {
                $scope.newMember.id = _user.id;
                $scope.newMember.firstName = _user.firstName;
                $scope.newMember.lastName = _user.lastName;
                $scope.messages = ['message.userExists'];
              }
            },
            function (error) {
              $scope.messages = [];
            },
          );
        }
      };

      $scope.$watch('user.email', function (o) {
        $scope.getUser();
      });

      $scope.okNewMember = function (cb) {
        var emailParts = $scope.newMember.email.split('@');
        if (emailParts.length !== 2) {
          var error = { name: 'InvalidEmail', code: 'login.invalidEmail' };
          cb(error);
        } else {
          HttpService.post('/api/teammembers', { user: $scope.newMember }).then(
            function () {
              $scope.refreshTeam();
              $scope.newMember = {};
              cb();
              DialogService.message('message.teamMemberCreated');
            },
            function (error) {
              cb(error);
              console.error(error);
            },
          );
        }
      };

      $scope.okNewLegalUrl = function (cb) {
        var emailParts = $scope.newMember.email.split('@');
        if (emailParts.length !== 2) {
          var error = { name: 'InvalidEmail', code: 'login.invalidEmail' };
          cb(error);
        } else {
          HttpService.post('/api/teammembers', { user: $scope.newMember }).then(
            function () {
              $scope.refreshTeam();
              $scope.newMember = {};
              cb();
              DialogService.message('message.teamMemberCreated');
            },
            function (error) {
              cb(error);
              console.error(error);
            },
          );
        }
      };

      $scope.deleteTeamMember = function (user) {
        HttpService.delete('/api/teammembers/' + user.id).then(
          function () {
            DialogService.message('message.teamMemberDeleted');
            $scope.refreshTeam();
          },
          function (error) {
            DialogService.message(error);
          },
        );
      };

      $scope.grantAccess = function () {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.post('/api/companies/accesscontrol')
          .then(function () {
            $scope.accessGrantor = $scope.currentUser;
            $scope.company.accessGranted = new Date();
            $rootScope.$broadcast('dataLoadingFinished');
          })
          .catch(function (error) {
            DialogService.error(error);
            $rootScope.$broadcast('dataLoadingFinished');
          });
      };

      $scope.revokeAccess = function () {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.delete('/api/companies/accesscontrol')
          .then(function () {
            $scope.accessGrantor = undefined;
            $scope.company.accessGranted = undefined;
            $rootScope.$broadcast('dataLoadingFinished');
          })
          .catch(function (error) {
            DialogService.error(error);
            $rootScope.$broadcast('dataLoadingFinished');
          });
      };
    },
  )
  .controller(
    'CompanyStatusesController',
    function ($scope, $rootScope, $modal, HttpService, CompanyService) {
      const findByOrderId = function (id) {
        return function (x) {
          return x.orderId === id;
        };
      };

      CompanyService.getProjectStatuses().then(function (status) {
        return ($scope.statuses = status.map(function (x) {
          return Object.assign(x, {
            changing: false,
            oldIcon: x.icon,
            oldName: x.name,
          });
        }));
      });

      $scope.changeName = function (orderId) {
        const status = $scope.statuses.find(findByOrderId(orderId));
        status.changing = true;
      };

      $scope.changeCancelled = function (orderId) {
        const status = $scope.statuses.find(findByOrderId(orderId));
        status.changing = false;
        status.name = status.oldName;
        status.icon = status.oldIcon;
      };

      $scope.changeAccepted = function (orderId) {
        const status = $scope.statuses.find(findByOrderId(orderId));
        const payload = $scope.statuses.map(function (x) {
          return x.orderId === orderId
            ? {
                name: x.name,
                icon: x.icon,
                orderId: x.orderId,
                code: x.code,
              }
            : {
                name: x.oldName,
                icon: x.oldIcon,
                orderId: x.orderId,
                code: x.code,
              };
        });

        HttpService.post('/api/company/projectStatuses', payload)
          .then(function () {
            status.changing = false;
            status.oldIcon = status.icon;
            status.oldName = status.name;
          })
          .catch(function (error) {
            console.log(error);
          });
      };

      $scope.iconClicked = function (orderId) {
        const status = $scope.statuses.find(findByOrderId(orderId));
        if (status.changing) {
          return $scope.openFontAwesomeModel(orderId);
        }

        status.changing = true;
      };

      $scope.deleteCustomData = function (orderId) {
        const status = $scope.statuses.find(findByOrderId(orderId));
        status.icon = null;
        status.changing = true;
        status.name = null;
      };

      $scope.openFontAwesomeModel = function (orderId) {
        var modalInstance = $modal.open({
          templateUrl: '/templates/msp/select-status-icon.html',
          controller: 'SelectStatusIconController',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            icons: function (HttpService) {
              return HttpService.get('/api/lists/fontAwesomeList');
            },
            id: function () {
              return orderId;
            },
          },
        });

        modalInstance.result.then(function (result) {
          if (!result) {
            return;
          }

          const status = $scope.statuses.find(findByOrderId(result.id));
          if (status.icon === result.icon) {
            return;
          }
          status.changing = true;
          status.icon = result.icon;
        });
      };
    },
  )
  .controller(
    'SelectStatusIconController',
    function ($modalInstance, $scope, $rootScope, icons, id) {
      $scope.originalList = icons;
      $scope.icons = icons;
      $scope.search = { searchText: '' };

      $scope.$watch('search.searchText', function () {
        $scope.icons = $scope.originalList.filter(function (x) {
          return x.includes($scope.search.searchText);
        });
      });

      $scope.selectIcon = function (icon) {
        $modalInstance.close({ id: id, icon: 'fa-fw fa-' + icon });
      };

      $scope.closeModal = function () {
        $modalInstance.close();
      };
    },
  )
  .controller(
    'ClientEditController',
    function (
      $scope,
      $rootScope,
      $state,
      $http,
      $location,
      $modal,
      _,
      CurrentUser,
      UploadService,
      DialogService,
      client,
      InputSanitizerService,
    ) {
      $scope.currentUser = CurrentUser.getUser();
      $scope.client = client;
      //if (client.id) {
      //    $http.get('/api/admin/users?clientId=' + client.id + '&disabled=true')
      //        .success(function (users) {
      //            $scope.client.Users = users;
      //        })
      //} else if (!$scope.client.OwnerId) {
      //    $scope.client.ownerId = $scope.currentUser.Client.id;
      //}

      var modalInstance = $scope.modalInstance;

      $scope.openClientLogo = function (client) {
        UploadService.open('clients', client).then(function () {
          client.logoUrl =
            '/images/clients/' +
            InputSanitizerService.sanitize(client.id) +
            '?' +
            new Date().getTime();
        });
      };

      $scope.save = function (cb) {
        if ($scope.client.id) {
          $http
            .put('/api/clients', { client: $scope.client })
            .success(function (result) {
              $scope.client = result;
              cb();
              if (modalInstance) {
                modalInstance.close(result);
              }
            })
            .error(function (error) {
              cb();
              $scope.errors = [error];
              console.error(error);
            });
        } else {
          $http
            .post('/api/clients', { client: $scope.client })
            .success(function (result) {
              $scope.client = result;
              cb();
              if (modalInstance) {
                modalInstance.close(result);
              }
            })
            .error(function (error) {
              cb();
              $scope.errors = [error];
              console.error(error);
            });
        }
      };

      $scope.removeVariable = function (key) {
        delete $scope.client.var[key];
      };

      $scope.cancel = function () {
        if (modalInstance) {
          modalInstance.dismiss();
        }
      };
    },
  )

  .controller(
    'CesInteractionTypesController',
    function (
      $scope,
      $rootScope,
      $state,
      $http,
      $modal,
      _,
      CurrentUser,
      DialogService,
      HttpService,
      UserEventService,
      interactionTypes,
      engagementFactors,
    ) {
      $scope.customInteractionTypes = _.filter(interactionTypes, function (interactionType) {
        return interactionType.type === 'custom';
      });
      $scope.automaticInteractionTypes = _.filter(interactionTypes, function (interactionType) {
        return interactionType.type !== 'custom';
      });
      $scope.engagementFactors = _.filter(engagementFactors, { type: 'manual' });
      $scope.newItem = { type: 'custom' };
      $scope.filter = { name: '' };

      $scope.edit = function (interactionType) {
        $scope.oldValues = _.cloneDeep(interactionType);
        interactionType.editName = interactionType.name;
        interactionType.$$isEdit = true;
      };

      $scope.delete = function (interactionType) {
        HttpService.delete('/api/admin/ces/interactiontypes/' + interactionType.id)
          .then(function () {
            _.remove($scope.customInteractionTypes, interactionType);
          })
          .catch(function (error) {
            DialogService.error(error);
          });
      };

      $scope.nameFilter = function (item) {
        return (
          !$scope.filter.name ||
          (item.name && item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1)
        );
      };

      $scope.save = function (interactionType, cb) {
        var req;
        interactionType.name = interactionType.editName;
        if (interactionType.id) {
          req = HttpService.put('/api/admin/ces/interactiontypes/' + interactionType.id, {
            interactionType: interactionType,
          });
          UserEventService.event('cesActivityTypeEdited');
        } else {
          req = HttpService.post('/api/admin/ces/interactiontypes', {
            interactionType: interactionType,
          });
          UserEventService.event('cesActivityTypeCreated');
        }
        $rootScope.$broadcast('dataLoadingStarted');
        req.then(
          function (result) {
            if (interactionType.id) {
              interactionType.$$isEdit = false;
            } else {
              $scope.customInteractionTypes.push(
                _.extend(result, {
                  EngagementFactor: _.find($scope.engagementFactors, {
                    id: interactionType.EngagementFactorId,
                  }),
                }),
              );
              $scope.newItem = { type: 'custom' };
            }
            $rootScope.$broadcast('dataLoadingFinished');
            if (cb) {
              cb();
            }
          },
          function (error) {
            if (cb) {
              cb();
            }
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
          },
        );
      };

      $scope.cancel = function (newItem) {
        if (newItem.id) {
          newItem.$$isEdit = false;
          _.forEach(newItem.oldValues, function (v, k) {
            newItem[k] = v;
          });
        } else {
          $scope.newItem = { type: 'custom' };
        }
      };
    },
  )

  .controller(
    'ClientVariablesController',
    function ($scope, $rootScope, $state, $http, $location, $modal, _, CurrentUser, company) {
      $scope.variableTypes = ['number', 'text', 'list'];
      $scope.company = company;
      $scope.company.settings = _.defaults(
        $scope.company.settings || {},
        { clientVariables: [] },
        { contactVariables: [] },
      );
      $scope.clientVariables = $scope.company.settings.clientVariables;
      $scope.contactVariables = $scope.company.settings.contactVariables;
      $scope.valid = true;

      $scope.addVariable = function (vars) {
        vars.push({ name: '', type: 'text' });
      };

      $scope.removeVariable = function (variable, vars) {
        _.remove(vars, variable);
      };

      $scope.editList = function (variable) {
        var modalInstance = $modal.open({
          template:
            '<modal-form onok="ok" oncancel="cancel"><div class="row" ng-repeat="value in variable.values track by $index">' +
            '<div class="col-xs-8"><input-text model="variable.values[$index]"></input-text></div><div class="col-xs-4"></div></div>' +
            '<div class="col-xs-12 text-right"><button type="button" class="btn btn-xs btn-success" ng-click="add()" translate="button.add"></button></div>' +
            '</modal-form>',
          controller: function ($modalInstance, $scope, _, variable) {
            $scope.variable = _.cloneDeep(variable);

            $scope.ok = function () {
              $modalInstance.close($scope.variable);
            };

            $scope.cancel = function () {
              $modalInstance.dismiss();
            };

            $scope.add = function () {
              if (!$scope.variable.values) {
                $scope.variable.values = [];
              }
              $scope.variable.values.push('');
            };

            $scope.remove = function (index) {
              $scope.variable.values.splice(index, 1);
            };
          },
          backdrop: 'static',
          resolve: {
            variable: function () {
              return variable;
            },
          },
        });

        modalInstance.result.then(
          function (result) {
            variable.values = result.values;
          },
          function () {},
        );
      };

      $scope.validateVariable = function (name) {
        return /^[a-zA-Z][a-zA-Z0-9-_]*$/.test(name);
      };

      $scope.validateVariablesInList = function (list) {
        var result = true;
        list.forEach(function (v1) {
          if (!/^[a-zA-Z][a-zA-Z0-9-_]*$/.test(v1.name)) {
            result = false;
          } else {
            var v2 = _.find(list, { name: v1.name });
            if (v2 && v2 !== v1) {
              result = false;
            }
          }
        });
        return result;
      };

      $scope.validate = function () {
        return (
          $scope.validateVariablesInList($scope.clientVariables) &&
          $scope.validateVariablesInList($scope.contactVariables)
        );
      };

      $scope.save = function (cb) {
        //{"clientVariables":[],"contactVariables":[]}

        $http
          .put('/api/companies/' + $scope.company.id + '/settings/clientVariables', {
            value: $scope.company.settings.clientVariables,
          })
          .success(function () {
            $http
              .put('/api/companies/' + $scope.company.id + '/settings/contactVariables', {
                value: $scope.company.settings.contactVariables,
              })
              .success(function () {
                $scope.messages = ['message.saved'];
                var user = CurrentUser.getUser();
                user.Company.settings = $scope.company.settings;
                CurrentUser.setUser(user);
                cb();
              })
              .error(function (error) {
                cb();
                $scope.errors = [error];
                console.error(error);
              });
          })
          .error(function (error) {
            cb();
            $scope.errors = [error];
            console.error(error);
          });

        //$http.put('/api/companies/' + CurrentUser.getUser().Company.id + '/settings', {settings: $scope.company.settings})
        //    //$http.put('/api/companies', {company: $scope.company})
        //    .success(function (result) {
        //        $scope.messages = ['message.saved'];
        //        var user = CurrentUser.getUser();
        //        user.Company.settings = $scope.company.settings;
        //        CurrentUser.setUser(user);
        //        cb();
        //    }).error(function (error) {
        //        cb();
        //        $scope.errors = [error];
        //        console.error(error);
        //    });
      };

      $scope.cancel = function () {
        $http.get('/api/companies/' + CurrentUser.getUser().Company.id).success(function (company) {
          $scope.company.settings = company.settings;

          $scope.company.settings = _.defaults(
            $scope.company.settings || {},
            { clientVariables: [] },
            { contactVariables: [] },
          );

          //if (!$scope.company.settings) {
          //    $scope.company.settings = {clientVariables: [], contactVariables: []};
          //} else {
          //    if (!)
          //}
        });
      };
    },
  )

  .controller(
    'ClientSegmentController',
    function (
      $scope,
      $stateParams,
      $rootScope,
      $moment,
      _,
      CesService,
      CurrentUser,
      DialogService,
      HttpService,
      UserEventService,
      interactionTypes,
      clientSegment,
      contactSeniorities,
      InputSanitizerService,
    ) {
      $scope.clientSegment = clientSegment;
      _.forEach($scope.clientSegment.CesRoadmap, function (activity) {
        activity.ContactSeniorities = activity.ContactSeniorities[0];
      });
      $scope.newCesActivity = { type: 'plan' };
      $scope.newClientSegment = {};

      $scope.interactionTypes = interactionTypes;
      $scope.planMonths = _.range(12).map(function (i) {
        return { id: i + 1, name: i + 1 };
      });

      $scope.chart = {};
      $scope.interactionTypes = interactionTypes;
      $scope.contactSeniorities = contactSeniorities;

      $scope.start = $moment().add(1, 'month');
      $scope.end = $moment().add(12 + 1, 'month');

      $scope.segmentFrequency = {};
      $scope.segmentFrequency.types = [
        { id: 1, name: 'Annual' },
        { id: 2, name: 'Quarterly' },
        { id: 3, name: 'Monthly' },
        { id: 4, name: 'None' },
      ];

      $scope.refreshChart = function () {
        $scope.chart = CesService.createChartData(
          _.map($scope.clientSegment.CesRoadmap, function (activity) {
            return _.extend(activity, {
              day: $moment().add(activity.month, 'month').format('YYYYMMDD'),
              type: 'plan',
            });
          }),
          $scope.clientSegment,
          { labelFormat: 'number', from: $scope.start, to: $scope.end },
        );
      };

      $scope.refreshChart();

      $scope.refresh = function () {
        HttpService.get(
          '/api/segments/' + InputSanitizerService.sanitize($stateParams.segmentId) + '?deep=true',
        )
          .then(function (clientSegment) {
            $scope.clientSegment = clientSegment;
            _.forEach($scope.clientSegment.CesRoadmap, function (activity) {
              activity.ContactSeniorities = activity.ContactSeniorities[0];
            });
            $scope.refreshChart();
            $rootScope.$broadcast('dataLoadingFinished');
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
          });
      };

      $scope.editCesActivity = function (cesActivity) {
        $scope.cancel(_.find($scope.cesActivities, { isEdit: true }));
        cesActivity.oldValues = _.cloneDeep(cesActivity);
        cesActivity.isEdit = true;
      };

      $scope.addNewCesActivity = function () {
        $scope.editCesActivity($scope.newCesActivity);
        $scope.newCesActivity.expanded = true;
      };

      $scope.save = function (newCesActivity) {
        $rootScope.$broadcast('dataLoadingStarted');
        if (!_.isArray(newCesActivity.ContactSeniorities)) {
          newCesActivity.ContactSeniorities = [newCesActivity.ContactSeniorities];
        }
        if (newCesActivity.id) {
          HttpService.post(
            '/api/segments/' +
              InputSanitizerService.sanitize($scope.clientSegment.id) +
              '/ces/activities/' +
              InputSanitizerService.sanitize(newCesActivity.id),
            { activity: newCesActivity },
          ).then(
            function (result) {
              delete newCesActivity.oldValues;
              newCesActivity.isEdit = false;
              newCesActivity.activity = _.cloneDeep(newCesActivity);
              $scope.refresh();
              $rootScope.$broadcast('dataLoadingFinished');
              UserEventService.event('clientSegmentActivityEdited');
            },
            function (error) {
              $rootScope.$broadcast('dataLoadingFinished');
              DialogService.error(error);
              console.error(error);
            },
          );
        } else {
          HttpService.put(
            '/api/segments/' +
              InputSanitizerService.sanitize($scope.clientSegment.id) +
              '/ces/activities',
            { activity: newCesActivity },
          ).then(
            function (result) {
              $scope.newCesActivity = { type: 'plan' };
              $scope.refresh();
              $rootScope.$broadcast('dataLoadingFinished');
              UserEventService.event('clientSegmentActivityCreated');
            },
            function (error) {
              $rootScope.$broadcast('dataLoadingFinished');
              DialogService.error(error);
              console.error(error);
            },
          );
        }
      };

      $scope.cancel = function (cesActivity) {
        if (cesActivity && cesActivity.oldValues) {
          _.forEach(cesActivity.oldValues, function (value, index) {
            cesActivity[index] = value;
          });
          delete cesActivity.oldValues;
          cesActivity.isEdit = false;
          $scope.newCesActivity = { type: 'plan' };
        }
      };

      $scope.delete = function (cesActivity) {
        $rootScope.$broadcast('dataLoadingStarted');

        if (cesActivity.id) {
          HttpService.delete(
            '/api/segments/' +
              InputSanitizerService.sanitize($scope.clientSegment.id) +
              '/ces/activities/' +
              InputSanitizerService.sanitize(cesActivity.id),
          ).then(
            function () {
              _.remove($scope.clientSegment.CesRoadmap, { id: cesActivity.id });
              $scope.refresh();
              $rootScope.$broadcast('dataLoadingFinished');
            },
            function (error) {
              $rootScope.$broadcast('dataLoadingFinished');
              DialogService.error(error);
              console.error(error);
            },
          );
        }
      };

      $scope.changeInteractionType = function (item) {
        $scope.newCesActivity.name = item.name;
      };

      $scope.cancelClientSegmentEdit = function (clientSegment) {
        $rootScope.$broadcast('dataLoadingStarted');
        if (clientSegment && clientSegment.oldValues) {
          _.forEach(clientSegment.oldValues, function (value, index) {
            clientSegment[index] = value;
          });
          delete clientSegment.oldValues;
          clientSegment.isEdit = false;
        } else {
          $scope.newClientSegment = { expanded: false };
        }
        $rootScope.$broadcast('dataLoadingFinished');
      };

      $scope.editClientSegment = function (clientSegment) {
        $rootScope.$broadcast('dataLoadingStarted');
        $scope.cancelClientSegmentEdit(clientSegment);

        clientSegment.isEdit = true;
        clientSegment.oldValues = _.cloneDeep(clientSegment);
        clientSegment.editName = clientSegment.name;
        clientSegment.editFrequency = $scope.segmentFrequency.types.find(function (type) {
          return type.name === clientSegment.frequency;
        });
        $rootScope.$broadcast('dataLoadingFinished');
      };

      $scope.saveClientSegment = function (clientSegment) {
        if (
          clientSegment.name === '' ||
          parseInt(clientSegment.complianceScore) < 0 ||
          isNaN(parseInt(clientSegment.complianceScore))
        ) {
          DialogService.error('error.clientSegments.formEmpty');
        } else {
          $rootScope.$broadcast('dataLoadingStarted');
          clientSegment.name = clientSegment.editName;
          clientSegment.frequency = clientSegment.editFrequency.name;
          if (clientSegment.id && clientSegment.id > 0) {
            HttpService.post('/api/segments/' + clientSegment.id, {
              clientSegment: clientSegment,
            }).then(
              function (result) {
                clientSegment.isEdit = false;
                delete clientSegment.oldValues;
                _.extend(clientSegment, _.pick(result, ['name', 'complianceScore', 'description']));
                $scope.newClientSegment = { expanded: false };
                $scope.refreshChart();
                $rootScope.$broadcast('dataLoadingFinished');
                UserEventService.event('clientSegmentEdited');
              },
              function (error) {
                $rootScope.$broadcast('dataLoadingFinished');
                DialogService.error(error);
                console.error(error);
              },
            );
          } else {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error('error.dataCorrupted');
          }
        }
      };
    },
  );
