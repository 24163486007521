angular.module('vcio-toolkit');

// .controller('CreateSurveyController', function ($scope, $rootScope, $window, $location, $http, $sce, $translate, _, CurrentUser, DialogService, EMAIL_REGEX) {
//
//     $scope.close = function () {
//         if (window.self !== window.top) {
//             window.parent.postMessage('close-ryc-fill-container', '*');
//         } else {
//             window.close();
//         }
//     }
//
//     var uuid = decodeURIComponent((new RegExp('[?|&]template=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [, ""])[1].replace(/\+/g, '%20')) || null;
//     $rootScope.$broadcast('dataLoadingStarted');
//     $http.get('/api/findsurvey/' + uuid)
//         .success(function (found) {
//             CurrentUser.setToken(found.token, true);
//             CurrentUser.setUser(found.user, true);
//             $scope.templateId = found.templateId;
//             $scope.welcome = $sce.trustAsHtml(found.welcome);
//
//             $scope.contact = {
//                 Client: {}
//             };
//
//             $scope.valid = false;
//             $scope.isShowContactDetails = false;
//             $rootScope.$broadcast('dataLoadingFinished');
//
//             $scope.showContactDetails = function () {
//                 $scope.isShowContactDetails = true;
//             }
//
//             $scope.checkEmail = function () {
//                 return $scope.contact.email && EMAIL_REGEX.test($scope.contact.email);
//             }
//
//             $scope.$watch('contact.email', function () {
//                 if ($scope.contact.email && EMAIL_REGEX.test($scope.contact.email)) {
//
//                     $http.get('/api/contacts/find/' + $scope.contact.email)
//                         .success(function (contact) {
//                             $scope.contact.id = contact.id;
//                             $scope.contact.firstName = contact.firstName;
//                             $scope.contact.lastName = contact.lastName;
//                             $scope.contact.phone = contact.phone;
//                             $scope.contact.Client = contact.Client;
//                             $scope.isShowContactDetails = true;
//                         })
//                         .error(function (error) {
//                             $scope.isShowContactDetails = false;
//                             DialogService.error(error);
//                         })
//
//                 } else {
//                     $scope.isShowContactDetails = false;
//                 }
//             });
//
//             $scope.$watch('contact', function () {
//                 $scope.valid = $scope.contact.email && EMAIL_REGEX.test($scope.contact.email) &&
//                     $scope.contact.firstName && $scope.contact.lastName && $scope.contact.Client && $scope.contact.Client.name;
//             }, true);
//
//             $scope.nextAction = function () {
//                 $rootScope.$broadcast('dataLoadingStarted');
//                 $http.post('/api/surveyresulttemplates/' + $scope.templateId + '/surveyresult', {contact: $scope.contact})
//                     .success(function (surveyResult) {
//                         $rootScope.$broadcast('dataLoadingFinished');
//                         // $window.location.path('/fillsurvey?survey=' + surveyResult.uuid);
//                         window.location.assign('/fillsurvey?survey=' + surveyResult.uuid);
//                     })
//             }
//         })
// })
