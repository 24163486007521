'use strict';

angular
  .module('vcio-toolkit')

  .service('SidebarFilterService', function (_, CurrentUser, LocalStorage) {
    var filters = [];

    /**
     *
     * @param name: name of the sidebar
     * @param scope: $scope of the sidebar controller
     * @constructor
     */
    function SidebarFilter(name, scope) {
      this.name = name;
      this.scope = scope;
      this.filter = {};
    }

    SidebarFilter.prototype.getSidebarKey = function () {
      var userId = CurrentUser.getUser().id + '';
      var companyId = CurrentUser.getUser().Company.id + '';
      return userId + '-' + companyId + '.' + this.name;
    };

    SidebarFilter.prototype.setSidebarWatcher = function () {
      // store the filter at any change in the sidebar filter
      var self = this;
      self.scope.$watch(
        'filter',
        function (newValue, oldValue) {
          if (oldValue !== undefined) {
            var sidebarStore = LocalStorage.getObject('sidebars', {});
            var config = _.get(sidebarStore, self.getSidebarKey());
            if (!config) {
              config = {};
              _.set(sidebarStore, self.getSidebarKey(), config);
            }
            _.forOwn(self.filter, function (value, key) {
              if (_.isArray(value)) {
                config[key] = _(value).filter({ selected: true }).map('id').value();
              } else if (key === 'name') {
                config[key] = value;
              }
            });
            LocalStorage.setObject('sidebars', sidebarStore);
          }
        },
        true,
      );
    };

    SidebarFilter.prototype.retrieveSidebarFilter = function () {
      var self = this;
      var sidebarStore = LocalStorage.getObject('sidebars', {});
      var config = _.get(sidebarStore, self.getSidebarKey());

      if (config && self.scope) {
        _.forOwn(self.filter, function (value, key) {
          if (_.isArray(value)) {
            _.forEach(value, function (item) {
              // set all items in the filter list selected where the id exists in the saved config
              item.selected = _.includes(config[key], item.id);
            });
          } else if (key === 'name') {
            self.filter[key] = config[key];
          }
        });
      }
      return self;
    };

    SidebarFilter.prototype.retrieveSidebarFilterSelectedIds = function (listName) {
      var self = this;
      var sidebarStore = LocalStorage.getObject('sidebars', {});
      return _.get(sidebarStore, self.getSidebarKey() + '.' + listName);
    };

    SidebarFilter.prototype.setLists = function (lists) {
      var self = this;
      _.forOwn(lists, function (newList, listName) {
        self.filter[listName] = _.map(newList, function (newValue, index) {
          newValue.id = newValue.id || index + 1;
          return _.extend(_.find(self.filter[listName], { id: newValue.id }), newValue);
        });
      });
      return self.retrieveSidebarFilter();
    };

    SidebarFilter.prototype.setList = function (name, values) {
      return this.setLists(_.set({}, name, values));
    };

    return {
      get: function (name, scope, lists) {
        var result = _.find(filters, { name: name });
        if (!result) {
          result = new SidebarFilter(name, scope);
          filters.push(result);
        } else if (!result.scope && scope) {
          result.scope = scope;
        }
        if (lists) {
          result.setLists(lists);
        }
        if (scope) {
          result.setSidebarWatcher();
        }

        return result;
      },
    };
  })

  .controller(
    'VendorSidebarCategoryModal',
    function (
      $modalInstance,
      $q,
      $rootScope,
      $scope,
      PermissionGroupModel,
      UploadService,
      _,
      item,
      permissiongroups,
    ) {
      $scope.item = item;
      $scope.item.$$hoverPermissionInput = [];
      $scope.logoUrl = item.ImageId
        ? '/images/assetcategories/' + $scope.item.id + '?' + new Date().getTime()
        : undefined;
      $scope.permissionGroups = permissiongroups;

      $scope.ok = function () {
        var promises = [];
        $rootScope.$broadcast('dataLoadingStarted');
        _.forEach($scope.permissionGroups, function (permissionGroup) {
          if (permissionGroup.$$toAdd === true) {
            promises.push(
              PermissionGroupModel.addAsset({
                permissionGroupId: permissionGroup.id,
                assetType: 'academy',
                assetId: $scope.item.id,
              }).$promise,
            );
          } else if (permissionGroup.$$toRemove === true) {
            promises.push(
              PermissionGroupModel.removeAsset({
                permissionGroupId: permissionGroup.id,
                assetType: 'academy',
                assetId: $scope.item.id,
              }).$promise,
            );
          }
        });
        $q.all(promises)
          .then(function () {
            $rootScope.$broadcast('dataLoadingFinished');
            $modalInstance.close($scope.item);
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
          });
      };

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };

      $scope.openLogo = function () {
        UploadService.openImage('assetcategories', $scope.item).then(function () {
          delete $scope.item.ImageId;
          $scope.logoUrl = '/images/assetcategories/' + $scope.item.id + '?' + new Date().getTime();
        });
      };

      $scope.groupHasCategory = function (group, category) {
        return !!_.find(group.AssetCategories, { id: category.id });
      };

      $scope.addRemoveCategoryToGroup = function (permissionGroup, category) {
        if (!$scope.groupHasCategory(permissionGroup, category)) {
          permissionGroup.$$toAdd = true;
          permissionGroup.$$toRemove = false;
          if (!permissionGroup.AssetCategories) {
            permissionGroup.AssetCategories = [];
          }
          permissionGroup.AssetCategories.push({ id: category.id });
        } else {
          permissionGroup.$$toAdd = false;
          permissionGroup.$$toRemove = true;
          _.remove(permissionGroup.AssetCategories, { id: category.id });
        }
      };
    },
  )

  .controller('VendorSidebarCopyModal', function ($modalInstance, $scope, item) {
    $scope.item = item;

    $scope.ok = function () {
      $modalInstance.close($scope.item);
    };

    $scope.cancel = function () {
      $modalInstance.dismiss();
    };
  })

  .controller('VendorSidebarDeleteModal', function ($modalInstance, $scope, item) {
    $scope.item = item;

    $scope.delete = function () {
      $modalInstance.close('delete');
    };

    $scope.cancel = function () {
      $modalInstance.dismiss();
    };

    $scope.buttons = [{ label: 'button.delete', click: $scope.delete, color: 'danger' }];
  })

  .directive('sidebarSingleFilter', function ($translate) {
    return {
      restrict: 'E',
      scope: {
        label: '@',
        model: '=',
      },
      controller: function ($scope, _) {
        $scope.clickValue = function (value) {
          value.selected = !value.selected;
          var other = _.find($scope.model, function (item) {
            return item.selected === true && item !== value;
          });
          if (other) {
            other.selected = false;
          }
        };
      },
      template:
        '<span class="subtitle margin-1x-left" translate="{{label}}"></span> ' +
        '<ul><li ng-repeat="value in model">' +
        '  <span ng-click="clickValue(value)"><i class="fa" ng-class="{\'fa-check-circle-o\': value.selected, \'fa-circle-o\': !value.selected}"></i> <span ng-bind-html="value.name"></span></span>' +
        '</li></ul>',
    };
  })

  .directive('sidebarSingleSelect', function () {
    return {
      restrict: 'E',
      scope: {
        label: '@',
        model: '=',
        placeholder: '@',
        onSelect: '=?',
        selected: '=?',
      },
      controller: function ($scope, _) {
        $scope.selected = $scope.selected || { item: _.find($scope.model, { selected: true }) };
        $scope.$watch('selected.item', function () {
          _.forEach($scope.model, function (item) {
            item.selected = $scope.selected.item && $scope.selected.item.id === item.id;
          });
        });
      },
      template:
        '<div><span class="subtitle margin-1x-left" translate="{{label}}"></span>' +
        '<abm-form-group class="abm-form"><abm-ui-select model="selected.item" type="single" placeholder="{{placeholder}}" values="model" allow-clear="true" on-select="onSelect"></abm-ui-select></abm-form-group>' +
        '</div>',
    };
  })

  .directive('sidebarMultiFilter', function ($translate) {
    return {
      restrict: 'E',
      scope: {
        label: '@',
        model: '=',
        orderBy: '@?',
        onChange: '=?',
      },
      controller: function ($scope) {
        $scope.orderBy = $scope.orderBy || 'id';
        $scope.onValueChange = function (value) {
          value.selected = !value.selected;
          if ($scope.onChange) $scope.onChange();
        };
      },
      template:
        '<span class="subtitle margin-1x-left" translate="{{label}}"></span> ' +
        '<ul><li ng-repeat="value in model | orderBy: orderBy track by $index">' +
        '  <span ng-click="onValueChange(value)"><i class="fa" ng-class="{\'fa-check-square-o\': value.selected, \'fa-square-o\': !value.selected}"></i><span  class="{{value.class}}"> {{value.name | translate}}</span></span>' +
        '</li></ul>',
    };
  })

  .directive('sidebarMultiSelect', function () {
    return {
      restrict: 'E',
      scope: {
        label: '@',
        model: '=',
        placeholder: '@',
        selected: '=?',
      },
      controller: function ($scope, _) {
        $scope.selected = $scope.selected || { items: _.filter($scope.model, { selected: true }) };
        $scope.$watch('selected.items', function () {
          _.forEach($scope.model, function (item) {
            item.selected = !!_.find($scope.selected.items, { id: item.id });
          });
        });
      },
      template:
        '<div><span class="subtitle margin-1x-left" translate="{{label}}"></span>' +
        '<abm-form-group class="abm-form"><abm-ui-select model="selected.items" type="multi" placeholder="{{placeholder}}" values="model"></abm-ui-select></abm-form-group>' +
        '</div>',
    };
  })

  .directive('sidebarDatePicker', function () {
    return {
      restrict: 'E',
      scope: {
        label: '@',
        model: '=',
        max: '=',
        min: '=',
        onChange: '&?',
        mode: '@',
        dateOptions: '=',
        format: '@',
      },
      controller: function ($scope) {
        $scope.opened = false;
        $scope.options = $scope.dateOptions || {};
        $scope.openDate = function ($event) {
          $event.preventDefault();
          $event.stopPropagation();
          $scope.opened = true;
        };
      },
      template:
        '<p class="input-group input-group-sm" style="position: relative">\n' +
        '    <input type="text" disabled datepicker-popup="{{format}}" class="form-control" ng-model="model"\n' +
        '           datepicker-options="options"\n' +
        '           is-open="false" min-date="min" max-date="max"\n' +
        '           datepicker-mode="mode" show-button-bar="false"\n' +
        '           close-text="{{\'button.close\' | translate}}" init-date="model"/>\n' +
        '    <input type="text" ng-show="false" datepicker-popup="{{format}}" class="form-control" ng-model="model"\n' +
        '           datepicker-options="options"\n' +
        '           is-open="opened" min-date="min" max-date="max"\n' +
        '           datepicker-mode="mode" show-button-bar="false"\n' +
        '           close-text="{{\'button.close\' | translate}}" init-date="model"\n' +
        '           datepicker-append-to-body="false" ng-change="changed"/>\n' +
        '    <span class="input-group-btn"><button type="button" class="btn btn-default" ng-click="openDate($event)"><i\n' +
        '            class="glyphicon glyphicon-calendar"></i></button></span>\n' +
        '</p>\n',
    };
  });
