angular
  .module('vcio-toolkit')

  .controller(
    'OverviewController',
    function (
      $filter,
      $http,
      $modal,
      $moment,
      $q,
      $scope,
      $state,
      $stateParams,
      $rootScope,
      $timeout,
      $translate,
      $window,
      _,
      CurrentUser,
      DialogService,
      HttpService,
      LocalStorage,
      ModalFormService,
      UserEventService,
      projectStatuses,
      surveyResult,
      services,
      InputSanitizerService,
    ) {
      $scope.currentUser = $scope.currentUser || CurrentUser.getUser();
      $scope.hasModule = CurrentUser.hasModule;
      $scope.options = {
        showComments: false,
        isEditing: false,
        compare: true,
      };
      $scope.surveyResult = surveyResult;
      $scope.projectStatuses = projectStatuses;
      $scope.services = services;
      if ($scope.surveyResult.commentsCount > 0) {
        $scope.options.showComments = true;
      }
      $scope.expanded = {
        dimensions: {},
      };
      $scope.showHide = {
        header: true,
        executive: true,
        category: true,
        actionPlan: true,
        projects: true,
        details: true,
        cover: true,
        appendix: true,
        back: true,
      };

      $scope.overviewType = {
        itcq: {
          template: '/templates/sales/overview/itcq/overview.html',
          controller: 'OverviewITCQController',
        },
        stack: {
          template: '/templates/sales/overview/itcq/overview.html',
          controller: 'OverviewITCQController',
        },
        stack2: {
          template: '/templates/report/stack2/overview.html',
          controller: 'ReportStack2OverviewController',
        },
        needs: {
          template: '/templates/sales/overview/itcq/overview.html',
          controller: 'OverviewITCQController',
        },
        qbr: {
          template: '/templates/sales/overview/qbr/overview.html',
          controller: 'OverviewQBRController',
        },
        mpd: {
          template: '/templates/sales/overview/qbr/overview.html',
          controller: 'OverviewQBRController',
        },
      };
      // maturity: {template:'', controller: ''} - ehhez nincs

      $scope.cesActivities = [];
      HttpService.get(
        '/api/ces/activities?type=actual&interactionType=' +
          (InputSanitizerService.sanitize($scope.surveyResult.type) == 'stack2'
            ? 'report'
            : 'survey') +
          '&instanceId=' +
          InputSanitizerService.sanitize($scope.surveyResult.id),
      ).then(function (result) {
        $scope.cesActivities = result;
      });

      $scope.getProjectStatusIcon = function (status) {
        if (status === 'planned') {
          return 'fa fa-calendar';
        } else if (status === 'approved') {
          return 'fa fa-calendar-check-o';
        } else if (status === 'inProgress') {
          return 'fa fa-clock-o';
        } else if (status === 'finished') {
          return 'fa fa-check';
        } else {
          return 'fa fa-exclamation-triangle';
        }
      };
      $scope.setProjectStatus = function (service) {
        //var service = _.find($scope.surveyResult.onetimeServices, '$$statusEdit');
        //service.$$statusEdit = false;
        if (service.oldStatus !== service.status) {
          $scope.saveEdit();
        }
        $scope.cancelProjectStatusEdit();
      };
      $scope.cancelProjectStatusEdit = function () {
        _.forEach($scope.surveyResult.onetimeServices, function (service) {
          service.$$statusEdit = false;
          service.oldStatus = service.status;
        });
      };
      $scope.showProjectStatuses = function (service) {
        $scope.cancelProjectStatusEdit();
        service.$$statusEdit = true;
        service.status = service.status || 'planned';
        service.oldStatus = service.status;
      };

      $scope.linkCesActivity = function () {
        $rootScope.$broadcast('dataLoadingStarted');
        $q.all([
          HttpService.get('/api/admin/ces/interactiontypes'),
          HttpService.get(
            '/api/clients/' + InputSanitizerService.sanitize($scope.surveyResult.ClientId),
          ),
          // HttpService.get('/api/surveys/' + $scope.surveyResult.Survey.TemplateId),
          HttpService.get(
            '/api/ces/activities?type=plan&interactionType=' +
              (InputSanitizerService.sanitize($scope.surveyResult.type) == 'stack2'
                ? 'report'
                : 'survey'),
          ),
        ])
          .then(function (results) {
            $rootScope.$broadcast('dataLoadingFinished');
            var interactionTypes = results[0];
            var activities = results[2];

            ModalFormService.open({
              template:
                '<input-select values="cesActivities" model="selected.activity" label="label.client.ces.selectActualActivity"></input-select>',
              controller: function ($scope) {
                $scope.save = function () {
                  $rootScope.$broadcast('dataLoadingStarted');
                  var newCesActivity = _.pick(data.asset, ['id']);
                  newCesActivity.ActualId = $scope.selected.activity.id;
                  HttpService.post(
                    '/api/ces/activities/' + InputSanitizerService.sanitize(newCesActivity.id),
                    { activity: { id: $scope.selected.activity.id } },
                  ).then(
                    function (result) {
                      $rootScope.$broadcast('dataLoadingFinished');
                      $modalInstance.close($scope.selected.activity.id);
                    },
                    function (error) {
                      $rootScope.$broadcast('dataLoadingFinished');
                      DialogService.error(error);
                      console.error(error);
                    },
                  );
                };
              },
              ok: 'save',
              cancel: 'cancel',
              close: function (result) {
                $scope.cesActivities.push(result);
              },
              title: 'label.clients.createEngagementActivity',
              scope: {
                newCesActivity: {
                  type: 'actual',
                  // assetType: 'surveys',
                  // assetId: $scope.surveyResult.id,
                  name: $scope.surveyResult.title,
                  InteractionTypeId: _.find(interactionTypes, {
                    EngagementFactorId: surveyTemplate.EngagementFactorId,
                  }).id,
                  InstanceId: $scope.surveyResult.id,
                  date: new Date(),
                  contactIds: [$scope.surveyResult.ContactId],
                  template: surveyTemplate,
                  // PlanId: data.planActivityId
                },
                client: results[1],
                hide: {
                  type: true,
                  interactionType: true,
                  instance: true,
                  contacts: true,
                },
              },
            });
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
          });
      };

      $scope.createCesActivity = function () {
        $rootScope.$broadcast('dataLoadingStarted');
        $q.all([
          HttpService.get('/api/admin/ces/interactiontypes'),
          HttpService.get(
            '/api/surveys/' + InputSanitizerService.sanitize($scope.surveyResult.Survey.TemplateId),
          ),
        ])
          .then(function (results) {
            $rootScope.$broadcast('dataLoadingFinished');
            var interactionTypes = results[0];
            var surveyTemplate = results[1];

            ModalFormService.open({
              templateUrl: "'/templates/client/ces-activity-edit.html'",
              controller: 'CesActivityEditController',
              ok: 'save',
              cancel: 'cancel',
              close: function (result) {
                $scope.cesActivities.push(result);
              },
              title: 'label.clients.createEngagementActivity',
              scope: {
                newCesActivity: {
                  type: 'actual',
                  // assetType: 'surveys',
                  // assetId: $scope.surveyResult.id,
                  name: $scope.surveyResult.title,
                  InteractionTypeId: _.find(interactionTypes, {
                    EngagementFactorId: surveyTemplate.EngagementFactorId,
                  }).id,
                  InstanceId: $scope.surveyResult.id,
                  date: new Date(),
                  contactIds: [$scope.surveyResult.ContactId],
                  template: surveyTemplate,
                  // PlanId: data.planActivityId
                },
                client: $scope.surveyResult.Client,
                hide: {
                  type: true,
                  interactionType: true,
                  instance: true,
                  contacts: true,
                },
              },
            });
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
          });
      };

      $scope.getOverviewTemplateName = function () {
        return _.get($scope.overviewType, $scope.surveyResult.type + '.template');
      };

      $scope.getOverviewControllerName = function () {
        return _.get($scope.overviewType, $scope.surveyResult.type + '.controller');
      };

      $scope.isEmbedded = params.embedded;
      $scope.isPrint = params.print;
      $scope.goEmbeddedBack = function () {
        $state.go('embedded.surveyresults');
      };

      $scope.removeEmptyResults = function (block) {
        // remove "empty" answers from view
        if (block.responses) {
          // remove undefined statement responses
          _.forEach(block.statements, function (statement) {
            statement.responses = _.filter(statement.responses, function (item) {
              return item.value || item.value === 0;
            });
          });

          // remove block responses where a statement response is missing
          block.responses = _.reject(block.responses, function (response) {
            return _.find(block.statements, function (statement) {
              return !_.find(statement.responses, { id: response.id });
            });
          });
        }
      };

      _.forEach($scope.surveyResult.roles, function (role) {
        var blocks = _.filter($scope.surveyResult.blocks, { role: role.code });
        blocks.forEach(function (block) {
          $scope.removeEmptyResults(block);

          block.responseComment =
            block.responses && block.responses.length > 0 ? '' : block.responseComment;
          _.forEach(block.responses, function (response) {
            if (response.comment) {
              block.responseComment += '<li>' + response.comment + '</li>';
            }
          });
          if (block.responseComment) {
            block.responseComment = '<ul>' + block.responseComment + '</ul>';
          }
        });
      });

      $scope.calculateMultiResponseBlock = function (surveyResult, block) {
        if (block.role) {
          var role = _.find(surveyResult.roles, { code: block.role });
          var multiResponse = (role || { multiresponse: false }).multiresponse;
          if (multiResponse) {
            var blocks = _.filter(surveyResult.blocks, { role: role.code });
            role.maxResponses = _.max(
              _.map(blocks, function (block) {
                return _.size(block.responses);
              }),
            );
            _.forEach(block.statements, function (statement) {
              statement.responses = _.map(statement.responses, function (response) {
                return _.extend(
                  { createdAt: (_.find(block.responses, { id: response.id }) || {}).createdAt },
                  response,
                );
              });
              if (statement.responses) {
                if (!_.includes(['text', 'textWithNumber'], statement.type)) {
                  statement.value = _.round(
                    _.sumBy(statement.responses, function (response) {
                      return response.value || 0;
                    }) / (role.maxResponses || 1),
                    2,
                  );
                } else if (statement.responses.length > 0) {
                  statement.value = statement.responses[0].value;
                }
                statement.history = _.keyBy(statement.responses, 'createdAt');
              }
            });
          }
        }
      };

      _.forEach($scope.surveyResult.blocks, function (block) {
        $scope.calculateMultiResponseBlock($scope.surveyResult, block);
      });

      // var compareQuarters = ['q2', 'q3', 'q4'];
      //
      // function cleanupBlockQuarters(block){
      //     if (block.quarters) {
      //         if (block.quarters[0].statements) {
      //             block.statements = block.quarters[0].statements;
      //         }
      //         delete block.quarters;
      //     }
      // }
      //
      // function refresh(reports){
      // reports = reports || {};
      // if (surveyResult.type == 'quarterly'){
      //     var oldDimensions = $scope.surveyResult.dimensions;
      //     $scope.surveyResult = _.cloneDeep(surveyResult);
      //     $scope.surveyResult.quarters = [];
      //     _.forEach($scope.surveyResult.blocks, cleanupBlockQuarters);
      //     if (reports.q2 || reports.q3 || reports.q4) {
      //         $scope.surveyResult.quarters = [
      //             { id: 'q1', name: reports.q1.name }
      //         ];
      //         _.forEach(compareQuarters, function(qId){
      //             if (reports[qId].survey) {
      //                 $scope.surveyResult.quarters.push(
      //                     { id: qId, name: reports[qId].name }
      //                 );
      //             }
      //         });
      //
      //         _.forEach($scope.surveyResult.blocks, function(block, key){
      //             block.quarters = [
      //                 { id: 'q1', statements: block.statements }
      //             ];
      //             delete block.statements;
      //             _.forEach(compareQuarters, function(qId){
      //                 if (reports[qId].survey) {
      //                     var qReport = reports[qId].survey;
      //                     cleanupBlockQuarters(qReport.blocks[key]);
      //                     block.quarters.push(
      //                         { id: qId, statements: qReport.blocks[key].statements }
      //                     );
      //                 }
      //             });
      //         });
      //     }
      //     $scope.surveyResult.dimensions = oldDimensions;
      // }
      // }
      // refresh();
      //
      // $http.get('/api/surveyresults/' + $scope.surveyResult.id + '/same')
      //     .then(function (response) {
      //         // $scope.sameSurveyResults = _.filter(response.data, function(s){
      //         //     return s.id != $scope.surveyResult.id;
      //         // });
      //         var startCount = Math.min($scope.sameSurveyResults.length, 3);
      //         for (var i = 0; i <= startCount; i++) {
      //             $scope.compareReports['q' + (i + 1)].name = 'Q' + (startCount + 1 - i);
      //         }
      //     });
      //
      $scope.$on('commentPosted', function () {
        $http
          .get('/api/surveyresults/' + $scope.surveyResult.id + '/comments/count')
          .then(function (response) {
            $scope.surveyResult.commentsCount = response.data.count;
          });
      });

      $scope.$watch('options.showComments', function () {
        // we have to manually resize all Chart.js charts
        window.setTimeout(function () {
          _.forOwn(Chart.instances, function (instance) {
            if (instance.options.responsive) {
              instance.resize(instance.render, true);
            }
          });
        }, 50);
      });

      $scope.$watch('options.compare', function (newValue, oldValue) {
        if ($scope.surveyResult.type === 'mpd') {
          $scope.showCompare();
        } else if ($scope.options.compare && oldValue === false) {
          if ($scope.surveyResult.compare) {
            $scope.showCompare();
          } else {
            $scope.openCompare();
          }
        }
      });

      // function saveStatement(block, statement, cb) {
      //     $http.put('/api/surveyresults/' + $scope.surveyResult.id + '/responses/' + block.orderIdQuestion + '/' + statement.id, {
      //         statement: {
      //             value: statement.value,
      //             checked: (statement.type == 'score' ? !!statement.checked : undefined)
      //         }
      //     }).success(function () {
      //         if (cb) {
      //             cb();
      //         }
      //     })
      // }

      $scope.saveEdit = function () {
        var invalid = _.some($scope.surveyResult.blocks, function (block) {
          return _.some(block.statements, function (statement) {
            if (statement.isEditing) {
              var val = statement.value;
              if (statement.type === 'textWithNumber' && statement.value) {
                val = statement.value.number;
              }
              var min =
                statement.type === 'percentage' || statement.type === 'textWithNumber'
                  ? 0
                  : statement.min;
              var max =
                statement.type === 'percentage' || statement.type === 'textWithNumber'
                  ? 100
                  : statement.max;
              return (min !== undefined && val < min) || (max !== undefined && val > max);
            } else {
              return false;
            }
          });
        });
        if (!invalid) {
          $rootScope.$broadcast('dataLoadingStarted');
          HttpService.put('/api/surveyresults/' + $scope.surveyResult.id + '/responses', {
            blocks: $scope.surveyResult.blocks,
          })
            .then(function (surveyResult) {
              $scope.surveyResult = surveyResult;
              $scope.options.isEditing = false;
              _.forEach($scope.surveyResult.blocks, function (block) {
                _.forEach(block.statements, function (statement) {
                  statement.isEditing = false;
                });
              });
              if ($scope.compareReports && $scope.compareReports[0]) {
                $scope.compareReports[0].data = $scope.surveyResult;
                $scope.showCompare();
              }
              $rootScope.$broadcast('dataLoadingFinished');
            })
            .catch(function (error) {
              $rootScope.$broadcast('dataLoadingFinished');
              DialogService.error(error);
            });
        } else {
          DialogService.error('error.overview.invalidInput');
        }
      };

      $scope.cancelEdit = function () {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.get(
          '/api/surveyresults/' +
            InputSanitizerService.sanitize($stateParams.surveyResultId) +
            '/scores',
        ).then(function (surveyResult) {
          $scope.options.isEditing = false;
          $scope.surveyResult = surveyResult;
          _.forEach($scope.surveyResult.blocks, function (block) {
            _.forEach(block.statements, function (statement) {
              statement.isEditing = false;
            });
          });
          if ($scope.compareReports && $scope.compareReports[0]) {
            $scope.compareReports[0].data = $scope.surveyResult;
            $scope.showCompare();
          }
          $rootScope.$broadcast('dataLoadingFinished');
        });
      };

      $scope.canEdit = function () {
        if ($scope.surveyResult.type === 'mpd') {
          return (
            $scope.surveyResult.CreatedById == CurrentUser.getUser().id && !$scope.options.isEditing
          );
        } else if ($scope.surveyResult.type === 'stack2') {
          return false;
        } else {
          return !$scope.options.isEditing;
        }
      };

      $scope.edit = function () {
        $scope.options.isEditing = true;
        // $scope.options.compare = false;

        _.forEach($scope.surveyResult.blocks, function (block) {
          var role = _.find($scope.surveyResult.roles, { code: block.role });
          if (!role || !role.multiresponse) {
            _.forEach(block.statements, function (statement) {
              statement.isEditing = true;
              statement.oldValue = _.clone(statement.value);
            });
          }
        });
      };

      $scope.startTopic = function (topicId) {
        if (typeof inline_manual_player !== 'undefined') {
          inline_manual_player.activateTopic(topicId);
        }
      };

      $scope.downloadCSV = function () {
        $window.open(
          '/api/surveyresults/' +
            InputSanitizerService.sanitize($scope.surveyResult.id) +
            '/results.csv?token=' +
            CurrentUser.getToken(),
          '_blank',
        );
      };

      $scope.downloadCsvExport = function () {
        var modalInstance = $modal.open({
          templateUrl: '/templates/sales/survey-export-modal.html',
          controller: 'SurveyExportModalController',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            selected: function () {
              return [];
            },
            surveyResults: function () {
              return HttpService.get(
                '/api/surveyresults?surveyId=' +
                  InputSanitizerService.sanitize($scope.surveyResult.SurveyId),
              );
            },
            group: function () {
              return 'clientName';
            },
            filterValue: function () {
              return $scope.surveyResult.Client.name;
            },
            type: function () {
              return $scope.surveyResult.Survey.type;
            },
          },
        });
      };

      $scope.generateReport = function () {
        var templateSuffix;
        if ($scope.surveyResult.Survey.type === 'stack2') {
          templateSuffix = 'stack2';
          UserEventService.event('auditPrinted', { surveyResultId: $scope.surveyResult.id });
          // $timeout($window.print, 20);
          // return;
        } else {
          templateSuffix = 'general';
        }
        var modalInstance = $modal.open({
          templateUrl: '/templates/sales/survey-report-generate-' + templateSuffix + '.html',
          controller: 'ReportGenerateController',
          backdrop: 'static',
          resolve: {
            surveyResult: function () {
              var surveyResult = _.cloneDeep($scope.surveyResult);
              if ((surveyResult.type === 'mpd' || $scope.options.compare) && surveyResult.compare) {
                _.forEach(surveyResult.blocks, function (block) {
                  if (block.compare) {
                    block.quarters = _.map(block.compare, function (item, i) {
                      return _.extend(item, { id: 'q' + (surveyResult.compare.length - i) });
                    });
                    delete block.compare;
                  }
                });
                surveyResult.quarter = 'q' + surveyResult.compare.length;
              } else {
                _.forEach(surveyResult.blocks, function (block) {
                  block.quarters = [{ id: 'q1', statements: block.statements }];
                });
                surveyResult.quarter = 'q1';
              }

              return surveyResult;
            },
            showHide: function () {
              return $scope.showHide;
            },
          },
        });

        modalInstance.result.then(
          function () {
            if ($scope.surveyResult.type === 'stack2') {
              UserEventService.event('auditPrinted', { surveyResultId: $scope.surveyResult.id });
              // $timeout($window.print, 20);
            }
          },
          function () {},
        );
      };

      $scope.shareReport = function () {
        var modalInstance = $modal.open({
          template:
            '<modal-form form-title="label.surveyResults.share" label_ok=\'button.ok\' onok="ok">\n' +
            '    <div class="row">\n' +
            '        <div class="col-md-12">\n' +
            '            <a ng-href="{{url}}" target="_blank">{{url}}</a>\n' +
            '        </div>\n' +
            '    </div>\n' +
            '</modal-form>\n',
          controller: function ($scope, $modalInstance, surveyResult) {
            $scope.surveyResult = surveyResult;
            $scope.url = '';
            $scope.generateUrl = function () {
              var url = 'https://' + apphostname + '/reports/' + $scope.surveyResult.uuid;
              if ($scope.surveyResult.type === 'stack2') {
                var widgets = [];
                _.forEach($scope.surveyResult.widgetsData, function (widgetData) {
                  if (!widgetData.showHide) {
                    widgets.push(widgetData.uuid);
                  }
                });
                if (widgets.length > 0) {
                  url += '?w=' + _.join(widgets, ',');
                }
              }
              $scope.url = url;
            };
            $scope.generateUrl();
            $scope.ok = function () {
              $modalInstance.close();
            };
          },
          backdrop: 'static',
          size: 'lg',
          resolve: {
            surveyResult: function () {
              return $scope.surveyResult;
            },
          },
        });

        modalInstance.result.then(function (result) {});
      };

      $scope.showHistoryPopover = function (data, type) {
        //if(data.name=="Servers") console.log(data, type);
        var returnText = '';
        $scope.historyValues = $scope.historyValues || {};
        _.forEach(data.history, function (item, timestamp) {
          returnText +=
            '<i>' +
            $filter('date')(timestamp, 'short') +
            ': </i>' +
            (_.isObject(item.value) && item.value[type] ? item.value[type] : item.value) +
            '<br>';
        });
        return returnText;
      };
      //
      // $scope.sendToVcio = function (system) {
      //     UserEventService.event('auditTicketExportButton', {surveyResultId: $scope.surveyResult.id});
      //     if (system === 'connectwise' && !_.get($scope.surveyResult.Client, 'externalIds.connectwise')) {
      //         DialogService.error($translate.instant('message.externalSystems.connectwise.noClientLinked', {clientId: $scope.surveyResult.Client.id}));
      //     } else {
      //         var modalInstance = $modal.open({
      //             // templateUrl: '/templates/vcio/service-to-vcio.html',
      //             template: '<modal-form form-title="label.overview.exportTasks" title-values="{system: systemName}" onok="ok" oncancel="cancel" label-cancel="button.close">' +
      //                 '<ul><li ng-repeat="ticket in tickets|limitTo:5">{{ticket.title}}</li><li ng-if="tickets.length > 5">...</li></ul>' +
      //                 '<input-select ng-if="boards" values="boards" model="selected.board" label="label.integration.serviceBoard"></input-select>' +
      //                 '</modal-form>',
      //             controller: 'OverviewTasksToVcioController',
      //             backdrop: 'static',
      //             //size: 'lg',
      //             resolve: {
      //                 surveyResult: function () {
      //                     return $scope.surveyResult;
      //                 },
      //                 system: function () {
      //                     return system;
      //                 }
      //             }
      //         });
      //
      //         modalInstance.result.then(function () {
      //         }, function () {
      //         });
      //     }
      // };

      $scope.getSameSurveyResults = function (cb) {
        if ($scope.sameSurveyResults) {
          cb($scope.sameSurveyResults);
        } else {
          $rootScope.$broadcast('dataLoadingStarted');
          HttpService.get('/api/surveyresults/' + $scope.surveyResult.id + '/same')
            .then(function (result) {
              $rootScope.$broadcast('dataLoadingFinished');
              $scope.sameSurveyResults = result;
              cb($scope.sameSurveyResults);
            })
            .catch(function () {
              $rootScope.$broadcast('dataLoadingFinished');
            });
        }
      };

      $scope.openCompare = function () {
        // if ($scope.options.compare) {
        //     $scope.options.compare = false;
        // } else {
        $scope.getSameSurveyResults(function (sameSurveyResults) {
          var modalInstance = $modal.open({
            templateUrl: '/templates/sales/compare-dialog.html',
            controller: 'OverviewCompareDialogController',
            backdrop: 'static',
            size: 'lg',
            resolve: {
              surveyResult: function () {
                return $scope.surveyResult;
              },
              sameSurveyResults: function () {
                return sameSurveyResults;
              },
              compareReports: function () {
                return $scope.compareReports;
              },
            },
          });

          modalInstance.result.then(
            function (result) {
              $scope.compareReports = result;
              if ($scope.compareReports.length > 1) {
                $scope.showCompare();
              } else {
                $scope.options.compare = false;
              }
            },
            function () {
              if (!$scope.surveyResult.compare) {
                $scope.options.compare = false;
              }
            },
          );
        });
        // }
      };

      $scope.hasServices = function () {
        return _.includes(['itcq', 'needs', 'stack'], $scope.surveyResult.type);
      };

      $scope.canComment = function () {
        return _.includes(['qbr', 'mpd'], $scope.surveyResult.type) && !$scope.options.isEditing;
      };

      $scope.canCompare = function () {
        return _.includes(['qbr', 'mpd'], $scope.surveyResult.type) && !$scope.options.isEditing;
      };

      $scope.showCompare = function () {
        if ($scope.options.compare) {
          $scope.getSameSurveyResults(function (sameSurveyResults) {
            if ($scope.surveyResult.type === 'mpd') {
              $scope.compareReports = _([0, 1, 2, 3])
                .map(function (i) {
                  var quarter = $moment($scope.surveyResult.mpdDataQuarter, 'YYYYQ')
                    .subtract(i, 'quarter')
                    .format('YYYY[Q]Q');
                  return {
                    orderId: i,
                    name: quarter,
                    data:
                      i === 0
                        ? $scope.surveyResult
                        : _.find(sameSurveyResults, { mpdDataQuarter: quarter }),
                  };
                })
                .reject(['data', undefined])
                .value();
            } else {
              $scope.compareReports = _.reject(
                _.map($scope.surveyResult.compare, function (item, i) {
                  return {
                    orderId: i,
                    name: item.name,
                    data:
                      i === 0 ? $scope.surveyResult : _.find(sameSurveyResults, { id: item.id }),
                  };
                }),
                ['data', undefined],
              );
              // if ($scope.compareReports.length > 1) {
              //     $scope.showCompare();
              // } else {
              //     $scope.options.compare = false;
              // }
            }

            if ($scope.compareReports.length > 1) {
              $scope.options.compare = true;
              _.forEach($scope.surveyResult.blocks, function (block) {
                block.compare = _.map($scope.compareReports, function (item) {
                  var _block = _.find(item.data.blocks, { id: block.id });
                  $scope.removeEmptyResults(_block);
                  $scope.calculateMultiResponseBlock(item.data, _block);

                  return {
                    orderId: item.orderId,
                    name: item.name,
                    title: item.data.title,
                    statements: _block.statements,
                  };
                });
              });
            } else if ($scope.surveyResult.type === 'mpd') {
              $scope.options.compare = false;
              $scope.options.compareDisabled = true;
              // } else {
              //     $scope.openCompare();
            }

            HttpService.put(
              '/api/surveyresults/' + $scope.surveyResult.id + '/compare',
              _.map($scope.compareReports, function (item) {
                return { name: item.name, id: item.data.id };
              }),
            ).then(function (result) {
              $scope.surveyResult.compare = result.compare;
            });

            // } else {
            //     $scope.options.compare = true;
            //     _.forEach($scope.surveyResult.blocks, function (block) {
            //         block.compare = _.map($scope.compareReports, function (item) {
            //             var _block = _.find(item.data.blocks, {id: block.id});
            //             $scope.removeEmptyResults(_block);
            //             $scope.calculateMultiResponseBlock(item.data, _block);
            //
            //             return {
            //                 orderId: item.orderId,
            //                 name: item.name,
            //                 statements: _block.statements
            //             }
            //         });
            //     })
            // }
          });
        }
      };

      $scope.createContractActionPlan = function () {
        var contract = {
          type: 'onetime',
          name: $scope.surveyResult.Survey.name,
          defaults: { hourlyRate: 0, monthlyPrice: 0, pricing: 'labour' },
          Services: {},
          SurveyResultId: $scope.surveyResult.id,
        };

        $http.get('/api/service/services?body=true').success(function (services) {
          var _services = {};
          services.forEach(function (service) {
            _services[service.id] = service;
          });
          $scope.surveyResult.onetimeServices.forEach(function (service) {
            var _service = _services[service.id];
            if (_service) {
              contract.Services[_service.id] = {
                hours: _.sumBy(_service.todoLists, function (todoList) {
                  return _.sumBy(todoList.todo, function (task) {
                    return task.hours || 0;
                  });
                }),
                hourlyRate: contract.defaults.hourlyRate,
                todoLists: _service.todoLists,
              };
            }
          });

          $http
            .post('/api/vcio/contracts' + '?clientId=' + $scope.surveyResult.ClientId, {
              contract: contract,
            })
            .success(function (result) {
              $state.go('vcio.contractEdit', { contractId: result.id });
            })
            .error(function (error) {
              $scope.errors = [error];
            });
        });
      };

      // if ($scope.surveyResult.compare && $scope.surveyResult.compare.length > 1) {
      //     $scope.getSameSurveyResults(function (sameSurveyResults) {
      //         $scope.compareReports = _.reject(_.map($scope.surveyResult.compare, function (item, i) {
      //             return {
      //                 orderId: i,
      //                 name: item.name,
      //                 data: i === 0 ? surveyResult : _.find(sameSurveyResults, {id: item.id})
      //             };
      //         }), ['data', undefined]);
      //         if ($scope.compareReports.length > 1) {
      //             $scope.showCompare();
      //         } else {
      //             $scope.options.compare = false;
      //         }
      //     })
      // } else {
      //     $scope.options.compare = false;
      // }
      if ($scope.surveyResult.compare || $scope.surveyResult.Survey.type == 'mpd') {
        $scope.showCompare();
      } else {
        $scope.options.compare = false;
      }
    },
  )

  .controller(
    'OverviewCompareDialogController',
    function (
      $modalInstance,
      $moment,
      $scope,
      _,
      DialogService,
      HttpService,
      compareReports,
      surveyResult,
      sameSurveyResults,
    ) {
      $scope.surveyResult = surveyResult;
      $scope.compareReports = compareReports;
      $scope.sameSurveyResults = sameSurveyResults;

      if (!$scope.surveyResult.compare || $scope.surveyResult.compare.length < 2) {
        var maxResults = Math.min($scope.sameSurveyResults.length, 3);
        $scope.compareReports = [
          { name: 'Q' + $moment(surveyResult.createdAt).quarter(), data: surveyResult },
        ];
        for (var i = maxResults; i > 0; i--) {
          $scope.compareReports[i] = {
            name: 'Q' + $moment($scope.sameSurveyResults[i - 1].createdAt).quarter(),
            data: $scope.sameSurveyResults[i - 1],
          };
        }
      }

      $scope.add = function () {
        if ($scope.compareReports.length < 4) {
          $scope.compareReports.push({});
        }
      };

      $scope.remove = function (index) {
        $scope.compareReports.splice(index, 1);
      };

      $scope.ok = function (cb) {
        $scope.compareReports = _.filter($scope.compareReports, function (item) {
          return item.data;
        });
        var compare = _.map($scope.compareReports, function (item) {
          return { name: item.name, id: item.data.id };
        });
        HttpService.put('/api/surveyresults/' + $scope.surveyResult.id + '/compare', compare)
          .then(function (result) {
            $scope.surveyResult.compare = result.compare;
            $modalInstance.close($scope.compareReports);
          })
          .catch(function (error) {
            DialogService.error(error);
            cb();
          });
      };

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };
    },
  )

  // .controller('OverviewTasksToVcioController', function ($scope, $rootScope, $stateParams, $http, $modalInstance, $translate, _, HttpService, UserEventService, VcioService, surveyResult, system) {
  //     $scope.surveyResult = surveyResult;
  //     $scope.system = system;
  //     $scope.systemName = $translate.instant('label.externalSystem.' + system);
  //     $scope.selected = {};
  //
  //     VcioService.setSystem(system);
  //
  //     if ($scope.system === 'connectwise') {
  //         // $scope.billingMethods = ['ActualRates', 'FixedFee', 'NotToExceed', 'OverrideRate'];
  //         VcioService.get('/boards/service').then(function (result) {
  //             $scope.boards = result;
  //         })
  //     } else if ($scope.system === 'autotask') {
  //         $scope.selected.board = {id: 0};
  //     }
  //
  //     $scope.tickets = [];
  //     _.forEach($scope.surveyResult.blocks, function (block) {
  //         var dimension = _.find($scope.surveyResult.dimensions, {code: block.dimension}) || {};
  //         $scope.tickets = _.concat($scope.tickets, _.map(block.tickets, function (ticket) {
  //             return _.extend(_.clone(ticket), {title: dimension.name + ' - ' + block.name + ' - ' + ticket.title});
  //         }));
  //     });
  //
  //     $scope.ok = function (cb) {
  //         if ($scope.selected.board) {
  //             $rootScope.$broadcast('dataLoadingStarted');
  //             VcioService.post('/clients/' + $scope.surveyResult.ClientId + '/serviceboards/' + $scope.selected.board.id + '/tickets', $scope.tickets)
  //                 .then(function (result) {
  //                     $scope.messages = [{
  //                         code: 'message.overview.ticketsExported',
  //                         translateValues: {url: result, board: $scope.selected.board.name}
  //                     }];
  //                     $scope.ok = undefined;
  //                     $rootScope.$broadcast('dataLoadingFinished');
  //                     cb();
  //                     UserEventService.event('auditTicketExported', {surveyResultId: $scope.surveyResult.id});
  //                 })
  //                 .catch(function (error) {
  //                     if(error.code == "error.notFound" && error.entity == "client") {
  //                         error = $translate.instant('error.systemIntegrationNotSet', {clientName: $scope.surveyResult.Client.name, systemName: $scope.systemName});
  //                     }
  //                     $scope.errors = [error];
  //                     $rootScope.$broadcast('dataLoadingFinished');
  //                     cb();
  //                 });
  //         } else {
  //             cb();
  //         }
  //     };
  //
  //     $scope.cancel = function () {
  //         $modalInstance.dismiss();
  //     };
  //
  // })

  .controller(
    'OverviewPrintController',
    function (
      $filter,
      $http,
      $modal,
      $moment,
      $q,
      $scope,
      $state,
      $stateParams,
      $rootScope,
      $timeout,
      $translate,
      $window,
      _,
      CurrentUser,
      DialogService,
      HttpService,
      LocalStorage,
      UserEventService,
      surveyResult,
      services,
    ) {
      $scope.currentUser = $scope.currentUser || CurrentUser.getUser();
      $scope.hasModule = CurrentUser.hasModule;
      $scope.options = {
        showComments: false,
        isEditing: false,
        compare: true,
      };
      $scope.surveyResult = surveyResult;
      $scope.services = services;
      $scope.expanded = {
        dimensions: {},
      };
      $scope.showHide = {
        header: true,
        executive: true,
        category: true,
        actionPlan: true,
        projects: true,
        details: true,
        cover: true,
        appendix: true,
        back: true,
      };

      $scope.overviewType = {
        stack2: {
          template: '/templates/report/stack2/print.html',
          controller: 'ReportStack2OverviewPrintController',
        },
      };

      $scope.getOverviewTemplateName = function () {
        return _.get($scope.overviewType, $scope.surveyResult.type + '.template');
      };

      $scope.getOverviewControllerName = function () {
        return _.get($scope.overviewType, $scope.surveyResult.type + '.controller');
      };

      $scope.removeEmptyResults = function (block) {
        // remove "empty" answers from view
        if (block.responses) {
          // remove undefined statement responses
          _.forEach(block.statements, function (statement) {
            statement.responses = _.filter(statement.responses, function (item) {
              return item.value || item.value === 0;
            });
          });

          // remove block responses where a statement response is missing
          block.responses = _.reject(block.responses, function (response) {
            return _.find(block.statements, function (statement) {
              return !_.find(statement.responses, { id: response.id });
            });
          });
        }
      };

      _.forEach($scope.surveyResult.roles, function (role) {
        var blocks = _.filter($scope.surveyResult.blocks, { role: role.code });
        blocks.forEach(function (block) {
          $scope.removeEmptyResults(block);

          block.responseComment =
            block.responses && block.responses.length > 0 ? '' : block.responseComment;
          _.forEach(block.responses, function (response) {
            if (response.comment) {
              block.responseComment += '<li>' + response.comment + '</li>';
            }
          });
          if (block.responseComment) {
            block.responseComment = '<ul>' + block.responseComment + '</ul>';
          }
        });
      });

      $scope.calculateMultiResponseBlock = function (surveyResult, block) {
        if (block.role) {
          var role = _.find(surveyResult.roles, { code: block.role });
          var multiResponse = (role || { multiresponse: false }).multiresponse;
          if (multiResponse) {
            var blocks = _.filter(surveyResult.blocks, { role: role.code });
            role.maxResponses = _.max(
              _.map(blocks, function (block) {
                return _.size(block.responses);
              }),
            );
            _.forEach(block.statements, function (statement) {
              statement.responses = _.map(statement.responses, function (response) {
                return _.extend(
                  { createdAt: (_.find(block.responses, { id: response.id }) || {}).createdAt },
                  response,
                );
              });
              if (statement.responses) {
                if (!_.includes(['text', 'textWithNumber'], statement.type)) {
                  statement.value = _.round(
                    _.sumBy(statement.responses, function (response) {
                      return response.value || 0;
                    }) / (role.maxResponses || 1),
                    2,
                  );
                } else if (statement.responses.length > 0) {
                  statement.value = statement.responses[0].value;
                }
                statement.history = _.keyBy(statement.responses, 'createdAt');
              }
            });
          }
        }
      };

      _.forEach($scope.surveyResult.blocks, function (block) {
        $scope.calculateMultiResponseBlock($scope.surveyResult, block);
      });
    },
  );
