angular
  .module('vcio-toolkit')

  /**
   * user structure from rest: services/auth.js
   */
  .factory(
    'CurrentUser',
    function (
      $window,
      $location,
      HttpService,
      LocalStorage,
      SessionStorage,
      _,
      InputSanitizerService,
    ) {
      var currentUser = null;
      var currentToken = null;

      // if ($window.currentUser && $window.currentToken) {
      //     currentUser = $window.currentUser;
      //     currentToken = $window.currentToken;
      //     LocalStorage.set('auth_user', angular.toJson(currentUser))
      //     LocalStorage.set('auth_token', currentToken)
      // }

      var can = function () {
        var args = arguments;
        if (args.length === 0) {
          return true;
        }
        if (args.length == 1 && _.isArray(args[0])) {
          args = args[0];
        }
        var result = false;
        if (currentUser) {
          for (var i = 0; i < arguments.length; i++) {
            if (_.includes(currentUser.rights, arguments[i])) {
              result = true;
              break;
            }
          }
        }
        return result;
      };

      var hasModule = function (module) {
        if (!module) {
          return true;
        }
        return currentUser && _.includes(currentUser.modules, module);
      };

      var setUser = function (user, temporary) {
        currentUser = user;
        if (!temporary) {
          LocalStorage.set('auth_user', angular.toJson(user));
        }
        if (typeof profitwell != 'undefined' && currentUser) {
          profitwell('user_email', currentUser.email);
          profitwell('user_id', currentUser.Company.stripeCustomerId);
        }
      };

      return {
        setUser: setUser,
        getUser: function () {
          if (!currentUser) {
            if (LocalStorage.get('auth_user') && LocalStorage.get('auth_user') != 'undefined') {
              var user = angular.fromJson(LocalStorage.get('auth_user'));
              if (user && user.version === $window.appversion) {
                currentUser = user;
                currentUser.CompanyId = currentUser.Company.id;
                currentUser.can = can;
                currentUser.hasModule = hasModule;
              } else {
                currentUser = undefined;
              }
            }
          }
          return currentUser;
        },

        setToken: function (token, temporary) {
          currentToken = token;
          if (!temporary) {
            LocalStorage.set('auth_token', token);
          } else {
            SessionStorage.set('auth_token', token);
          }
        },

        getToken: function () {
          if (!currentToken) {
            currentToken = LocalStorage.get('auth_token');
          }
          return currentToken;
        },

        clear: function () {
          LocalStorage.delete('auth_token');
          LocalStorage.delete('auth_user');
          currentUser = null;
          currentToken = null;
        },

        can: can,

        isAuthenticated: function () {
          return this.getUser() && currentUser.version === $window.appversion;
        },

        hasModule: hasModule,
        isVendor: function () {
          return hasModule('vendor');
        },

        hasSubscription: function () {
          return !!currentUser.Company.subscription;
        },

        isSystem: function () {
          return currentUser && hasModule('system');
        },

        isHtgGroup: function () {
          return currentUser && hasModule('group'); //&& !_.find(currentUser.Company.Vendors, {id: 2});//currentUser && _.startsWith(currentUser.Company.name, 'HTG') && currentUser.Company.name !== 'HTG Peer Groups';
        },

        isPartnerMember: function () {
          return currentUser && hasModule('partnermember') && !this.hasSubscription(); //&& !_.find(currentUser.Company.Vendors, {id: 2});//currentUser && _.startsWith(currentUser.Company.name, 'HTG') && currentUser.Company.name !== 'HTG Peer Groups';
        },

        hasHtgVendor: function () {
          return currentUser && _.find(currentUser.Company.Vendors, { id: 1469 });
        },

        hasPermissionGroup: function (permissionGroupId) {
          return _.includes(currentUser.Company.PermissionGroups, permissionGroupId);
        },

        getConfigValue: function (name) {
          return _.get(currentUser.config, name);
        },

        setConfigValue: function (name, value) {
          if (_.get(currentUser.config, name) !== value) {
            currentUser.config = currentUser.config || {};
            _.set(currentUser.config, name, value);
            setUser(currentUser);
            HttpService.put(
              '/api/admin/users/' +
                InputSanitizerService.sanitize(currentUser.id) +
                '/config/' +
                InputSanitizerService.sanitize(name),
              { value: value },
            );
          }
        },
      };
    },
  );
