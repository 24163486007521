angular
  .module('vcio-toolkit')

  .filter('unsafe', [
    '$sce',
    function ($sce) {
      return function (val) {
        return $sce.trustAsHtml(val);
      };
    },
  ])

  .filter('unsafeUrl', [
    '$sce',
    function ($sce) {
      return function (val) {
        return $sce.trustAsResourceUrl(val);
      };
    },
  ])

  .filter('iif', function () {
    return function (input, trueValue, falseValue) {
      return input ? trueValue : falseValue;
    };
  })

  .filter('translateList', function ($translate) {
    return function (value, list) {
      return $translate.instant(list + '.' + value);
    };
  })

  .filter('shorten', function () {
    return function (input, length) {
      if (!length) {
        length = 75;
      }
      if (input) {
        input = input.replace(/<[^>]+>/gm, ' ').replace(/&[^;]+;/gm, ' ');
        return (
          input.substr(0, Math.min(input.length, length)) + (input.length > length ? '...' : '')
        );
      } else {
        return '';
      }
    };
  })

  .filter('htmlToPlaintext', function () {
    return function (text) {
      return String(text)
        .replace(/ ?<br *\/?> ?/gm, '\n')
        .replace(/<[^>]+>/gm, '');
      // return String(text).replace(/<[^>]+>/gm, '');
    };
  })

  .filter('offset', function () {
    return function (input, start) {
      start = parseInt(start);
      return input.slice(start);
    };
  })

  .filter('contains', function (_) {
    return function (array, value) {
      if (_.isArray(value)) {
        return _.some(value, function (value) {
          return _.includes(array, value);
        });
      } else {
        return _.includes(array, value);
      }
    };
  })

  .filter('unique', function (_) {
    return function (arr, field) {
      return _.uniq(arr, function (a) {
        return a[field];
      });
    };
  })

  .filter('propsFilter', function () {
    return function (items, props) {
      var out = [];

      if (angular.isArray(items)) {
        items.forEach(function (item) {
          var itemMatches = false;

          var keys = Object.keys(props);
          for (var i = 0; i < keys.length; i++) {
            var prop = keys[i];
            var text = props[prop].toLowerCase();
            if (item[prop] && item[prop].toString().toLowerCase().indexOf(text) !== -1) {
              itemMatches = true;
              break;
            }
          }

          if (itemMatches) {
            out.push(item);
          }
        });
      } else {
        // Let the output be the input untouched
        out = items;
      }

      return out;
    };
  })

  .filter('orderObjectBy', function () {
    return function (items, field, reverse) {
      var filtered = [];
      angular.forEach(items, function (item) {
        filtered.push(item);
      });
      filtered.sort(function (a, b) {
        return a[field] > b[field] ? 1 : -1;
      });
      if (reverse) {
        filtered.reverse();
      }
      return filtered;
    };
  })

  .filter('capitalize', function () {
    return function (token) {
      return token.charAt(0).toUpperCase() + token.slice(1);
    };
  })

  .filter('formatPrice', function (numberFilter) {
    return function (number, decimals) {
      number = number || 0;
      decimals = decimals || 0;
      var suffix = '';
      if (number > 999999) {
        number = Math.floor(number / 10000) / 100;
        suffix = ' M';
        if (number > 999) {
          number = Math.floor(number / 10) / 100;
          suffix = ' B';
          if (number > 999) {
            number = Math.floor(number / 10) / 100;
            suffix = ' T';
          }
        }
      } else if (number >= 100000) {
        number = Math.floor(number / 10) / 100;
        suffix = ' k';
      }
      number = numberFilter(number, decimals);
      return number + suffix;
    };
  })

  .filter('percentage', function ($filter) {
    return function (input, decimals) {
      return $filter('number')(input * 100, decimals || 2);
    };
  })

  .filter('updatedAgo', function ($moment, $translate, _) {
    return function (input) {
      var date = $moment(input);
      if (date.isValid()) {
        var reference = $moment();
        var today = reference.clone().startOf('day');
        var yesterday = reference.clone().subtract(1, 'days').startOf('day');
        var days = $moment().diff(date, 'days');
        if (date.isSame(today, 'd')) {
          return $translate.instant('label.updatedAgo.today');
        } else if (date.isSame(yesterday, 'd')) {
          return $translate.instant('label.updatedAgo.yesterday');
        } else if (days > 6) {
          return $translate.instant('label.updatedAgo.week');
        } else {
          return $translate.instant('label.updatedAgo.days', { number: days });
        }
      } else {
        return '';
      }
    };
  })

  .filter('parseDate', function ($moment) {
    return function (input, inputFormat, outputFormat) {
      if (outputFormat) {
        return $moment(input, inputFormat);
      } else {
        return $moment(input, inputFormat).format(outputFormat);
      }
    };
  })

  .filter('formatTime', function () {
    var conversions = {
      ss: angular.identity,
      mm: function (value) {
        return value * 60;
      },
      hh: function (value) {
        return value * 3600;
      },
    };

    var padding = function (value, length) {
      var zeroes = length - ('' + value).length,
        pad = '';
      while (zeroes-- > 0) {
        pad += '0';
      }
      return pad + value;
    };

    return function (value, unit, format, isPadded) {
      var totalSeconds = conversions[unit || 'mm'](value),
        hh = Math.floor(totalSeconds / 3600),
        hh_h = Math.round((totalSeconds / 3600) * 10) / 10,
        hh_hh = Math.round((totalSeconds / 3600) * 100) / 100,
        mm = Math.floor((totalSeconds % 3600) / 60),
        mm_m = Math.floor(((totalSeconds % 3600) / 60) * 10) / 10,
        mm_mm = Math.floor(((totalSeconds % 3600) / 60) * 100) / 100,
        ss = totalSeconds % 60;

      format = format || 'hh:mm';
      isPadded = angular.isDefined(isPadded) ? isPadded : false;
      hh = isPadded ? padding(hh, 2) : hh;
      mm = isPadded ? padding(mm, 2) : mm;

      format = format.replace(/hh\.hh/, hh_hh).replace(/mm\.mm/, mm_mm);
      format = format.replace(/hh\.h/, hh_h).replace(/mm\.m/, mm_m);
      format = format.replace(/hh/, hh).replace(/mm/, mm).replace(/ss/, ss);
      return format;
    };
  })

  .filter('invertColor', function () {
    return function (hex, bw) {
      function padZero(str, len) {
        len = len || 2;
        var zeros = new Array(len).join('0');
        return (zeros + str).slice(-len);
      }

      if (!hex) {
        return 'fff';
      }
      if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
      }
      // convert 3-digit hex to 6-digits.
      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
      }
      var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
      if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
      }
      // invert color components
      r = (255 - r).toString(16);
      g = (255 - g).toString(16);
      b = (255 - b).toString(16);
      // pad each with zeros and return
      return '#' + padZero(r) + padZero(g) + padZero(b);
    };
  })

  .filter('groupBy', function ($timeout) {
    return function (data, key) {
      if (!key) {
        return data;
      }
      var outputPropertyName = '__groupBy__' + key;
      if (!data[outputPropertyName]) {
        var result = {};
        for (var i = 0; i < data.length; i++) {
          if (!result[data[i][key]]) {
            result[data[i][key]] = [];
          }
          result[data[i][key]].push(data[i]);
        }
        Object.defineProperty(data, outputPropertyName, {
          enumerable: false,
          configurable: true,
          writable: false,
          value: result,
        });
        $timeout(
          function () {
            delete data[outputPropertyName];
          },
          0,
          false,
        );
      }
      return data[outputPropertyName];
    };
  });
