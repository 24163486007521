angular
  .module('vcio-toolkit')
  .controller(
    'MyFilesController',
    function ($scope, $stateParams, $http, $modal, _, CurrentUser, UploadService) {
      $scope.folder = '';

      $scope.refresh = function () {
        $http
          .get('/api/companies/' + CurrentUser.getUser().Company.id + '/files')
          .success(function (result) {
            $scope.docs = result;
          })
          .error(function (error) {
            console.error(error);
          });
      };

      $scope.$watch('root', function () {
        $scope.refresh();
      });

      $scope.canAdd = function () {
        return CurrentUser.can('uploadFile');
      };

      $scope.delete = function (doc) {
        $http
          .delete('/api/companies/' + CurrentUser.getUser().Company.id + '/files/' + doc.id)
          .success(function () {
            _.remove($scope.docs, doc);
          });
      };

      $scope.isDownloadable = function (doc) {
        return doc.downloadable;
      };

      $scope.isPrivate = function (doc) {
        return !doc.downloadable;
      };

      $scope.changeDownloadable = function (doc) {
        $http
          .put('/api/companies/' + CurrentUser.getUser().Company.id + '/files/' + doc.id, {
            downloadable: !doc.downloadable,
          })
          .success(function () {
            doc.downloadable = !doc.downloadable;
          });
      };

      $scope.docOperations = [
        {
          operation: $scope.delete,
          label: 'button.delete',
          icon: 'minus-square-o',
          confirmation: true,
          //}, {
          //    operation: $scope.changeDownloadable,
          //    label: 'button.makeFileDownloadable',
          //    icon: 'unlock-alt',
          //    condition: $scope.isPrivate
          //}, {
          //    operation: $scope.changeDownloadable,
          //    label: 'button.makeFilePrivate',
          //    icon: 'lock',
          //    condition: $scope.isDownloadable
        },
      ];

      $scope.openUpload = function () {
        UploadService.openFile('companies', CurrentUser.getUser()).then(
          function () {
            $scope.refresh();
          },
          function () {},
        );
      };

      $scope.getClass = function (doc) {
        if (_.endsWith(doc.name, '.pdf')) {
          return 'fa-file-pdf-o';
        } else if (
          _.endsWith(doc.name, '.doc') ||
          _.endsWith(doc.name, '.docx') ||
          _.endsWith(doc.name, '.odt')
        ) {
          return 'fa-file-word-o';
        } else if (
          _.endsWith(doc.name, '.xls') ||
          _.endsWith(doc.name, '.xlsx') ||
          _.endsWith(doc.name, '.ods')
        ) {
          return 'fa-file-excel-o';
        } else if (
          _.endsWith(doc.name, '.ppt') ||
          _.endsWith(doc.name, '.pptx') ||
          _.endsWith(doc.name, '.odp')
        ) {
          return 'fa-file-powerpoint-o';
        } else if (
          _.endsWith(doc.name, '.jpg') ||
          _.endsWith(doc.name, '.jpeg') ||
          _.endsWith(doc.name, '.png') ||
          _.endsWith(doc.name, '.gif')
        ) {
          return 'fa-file-picture-o';
        } else if (doc.name.indexOf('.') > 0) {
          return 'fa-file-o';
        } else {
          return 'fa-folder-o';
        }
      };
    },
  )

  .controller(
    'FileSelectController',
    function (
      $scope,
      $stateParams,
      $http,
      $modalInstance,
      $modal,
      $timeout,
      _,
      CurrentUser,
      UploadService,
    ) {
      $scope.refresh = function () {
        $http
          .get('/api/companies/' + CurrentUser.getUser().Company.id + '/files')
          .success(function (result) {
            $scope.docs = result;
          })
          .error(function (error) {
            console.error(error);
          });
      };

      $scope.canAdd = function () {
        return CurrentUser.can('uploadFile');
      };

      $scope.openUpload = function () {
        UploadService.openFile('companies', CurrentUser.getUser()).then(
          function () {
            $scope.refresh();
          },
          function () {},
        );
      };

      $scope.getClass = function (doc) {
        if (_.endsWith(doc.name, '.pdf')) {
          return 'fa-file-pdf-o';
        } else if (
          _.endsWith(doc.name, '.doc') ||
          _.endsWith(doc.name, '.docx') ||
          _.endsWith(doc.name, '.odt')
        ) {
          return 'fa-file-word-o';
        } else if (
          _.endsWith(doc.name, '.xls') ||
          _.endsWith(doc.name, '.xlsx') ||
          _.endsWith(doc.name, '.ods')
        ) {
          return 'fa-file-excel-o';
        } else if (
          _.endsWith(doc.name, '.ppt') ||
          _.endsWith(doc.name, '.pptx') ||
          _.endsWith(doc.name, '.odp')
        ) {
          return 'fa-file-powerpoint-o';
        } else if (
          _.endsWith(doc.name, '.jpg') ||
          _.endsWith(doc.name, '.jpeg') ||
          _.endsWith(doc.name, '.png') ||
          _.endsWith(doc.name, '.gif')
        ) {
          return 'fa-file-picture-o';
        } else if (doc.name.indexOf('.') > 0) {
          return 'fa-file-o';
        } else {
          return 'fa-folder-o';
        }
      };

      $scope.selectDoc = function (doc) {
        $modalInstance.close(doc);
      };

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };

      $scope.refresh();
    },
  );
