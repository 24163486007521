angular
  .module('vcio-toolkit')

  .service(
    'IntegrationService',
    function ($q, $rootScope, $stateParams, _, HttpService, CurrentUser) {
      function getSystem() {
        return $stateParams.system;
      }

      function callWithCredentials(method, url, data) {
        var deferred = $q.defer();
        data = data || {};
        if (url && url.indexOf('/') !== 0) {
          url = '/' + url;
        }
        if (url) {
          $rootScope.$broadcast('dataLoadingStarted');
          HttpService[method]('/api/vcio/' + getSystem() + url, data)
            .then(function (result) {
              $rootScope.$broadcast('dataLoadingFinished');
              deferred.resolve(result);
            })
            .catch(function (error) {
              $rootScope.$broadcast('dataLoadingFinished');
              handleAutotaskError(error);
              deferred.reject(error);
            });
        } else {
          deferred.resolve(true);
        }
        return deferred.promise;
      }

      function handleAutotaskError(error) {
        if (_.isObject(error)) {
          if (error.code && _.isObject(error.code)) {
            error = error.code;
          }
        }
      }

      return {
        isBasecamp: function () {
          return getSystem() === 'basecamp';
        },

        isConnectwise: function () {
          return getSystem() === 'connectwise';
        },

        isAutotask: function () {
          return getSystem() === 'autotask';
        },

        get: function (url) {
          return callWithCredentials('get', url);
        },

        post: function (url, data) {
          return callWithCredentials('post', url, data);
        },

        put: function (url, data) {
          return callWithCredentials('put', url, data);
        },

        delete: function (url) {
          return callWithCredentials('delete', url);
        },

        changeUser: function () {
          $rootScope.$broadcast('vcioSystemChanged');
        },
      };
    },
  )

  .controller(
    'ImportCompaniesWithContactsController',
    function (
      $modal,
      $q,
      $rootScope,
      $scope,
      $stateParams,
      _,
      CurrentUser,
      HttpService,
      company,
      industries,
      countries,
      clients,
      contactSeniorities,
      InputSanitizerService,
    ) {
      $scope.clients = [];
      $scope.existingClients = clients;
      $scope.industries = industries;
      $scope.countries = countries;
      $scope.savedClients = [];
      $scope.company = company;
      $scope.externalsystems = ['connectwise', 'autotask', 'halo'];
      $scope.contactSeniorities = contactSeniorities;
      // $scope.system = 'connectwise';
      $scope.data = { clientName: $stateParams.search || '' };

      if (
        !_.isEmpty($scope.company.keys.autotask) &&
        _.isEmpty($scope.company.keys.connectwise) &&
        _.isEmpty($scope.company.keys.halo)
      ) {
        $scope.data.system = 'autotask';
      } else if (
        _.isEmpty($scope.company.keys.autotask) &&
        !_.isEmpty($scope.company.keys.connectwise) &&
        _.isEmpty($scope.company.keys.halo)
      ) {
        $scope.data.system = 'connectwise';
      } else if (
        _.isEmpty($scope.company.keys.autotask) &&
        _.isEmpty($scope.company.keys.connectwise) &&
        !_.isEmpty($scope.company.keys.halo)
      ) {
        $scope.data.system = 'halo';
      } else if (
        _.isEmpty($scope.company.keys.autotask) &&
        _.isEmpty($scope.company.keys.connectwise) &&
        _.isEmpty($scope.company.keys.halo)
      ) {
        $scope.data.setup = true;
      }

      $scope.itemsPerPage = 10;
      $scope.currentPage = 1;
      $scope.numPages = 1;
      $scope.totalItems = 0;

      $scope.$watch('data.clientName', function (newValue, oldValue) {
        if (newValue !== oldValue) {
          $scope.searchChanged = true;
          $scope.currentPage = 1;
        }
      });

      $scope.$watch('data.system', function (newValue, oldValue) {
        if (newValue !== oldValue) {
          $scope.searchChanged = true;
          $scope.currentPage = 1;
        }
        if ($scope.searchChanged) {
          $scope.search();
        }
      });

      $scope.search = function () {
        $scope.errors = [];
        $scope.savedClients = [];
        if ($scope.data.system && ($scope.searchChanged || $scope.currentPage == $scope.numPages)) {
          $rootScope.$broadcast('dataLoadingStarted');
          HttpService.get(
            '/api/vcio/' +
              InputSanitizerService.sanitize($scope.data.system) +
              '/companies?contacts=true&pageSize=' +
              InputSanitizerService.sanitize($scope.itemsPerPage) +
              '&page=' +
              InputSanitizerService.sanitize($scope.currentPage) +
              ($scope.data.clientName
                ? '&name=' + InputSanitizerService.sanitize($scope.data.clientName)
                : ''),
          )
            .then(function (result) {
              $rootScope.$broadcast('dataLoadingFinished');
              $scope.clients = _.map(
                $scope.searchChanged ? result : _.union($scope.clients, result),
                function (client) {
                  var existingClient = _.find($scope.existingClients, function (existingClient) {
                    return existingClient.externalIds[$scope.data.system] == client.externalId;
                  });
                  if (existingClient) {
                    client.existing = existingClient;
                  }
                  return client;
                },
              );
              $scope.totalItems = $scope.clients.length;
              if (result.length) {
                $scope.numPages++;
              }
              if (result.length === $scope.itemsPerPage) {
                $scope.totalItems++;
              }
              $scope.errors = [];
              $scope.searchChanged = false;
            })
            .catch(function (error) {
              $rootScope.$broadcast('dataLoadingFinished');
              $scope.errors = [error];
            });
        }
      };

      $scope.search();

      $scope.pagingFilter = function (index) {
        return (
          index >= ($scope.currentPage - 1) * $scope.itemsPerPage &&
          index < $scope.currentPage * $scope.itemsPerPage
        );
      };

      $scope.selectAllContacts = function (client) {
        client.selectAllContacts = !client.selectAllContacts;
        client.contacts.forEach(function (contact) {
          contact.selected = client.selectAllContacts;
        });
      };

      $scope.selectChanged = function (item) {
        item.selected = !item.selected;
      };

      $scope.validate = function () {
        var result = true;
        $scope.clients.forEach(function (client) {
          if (client.selected) {
            if (!client.address) {
              client.address = '';
              result = false;
            }
            if (!client.city) {
              client.city = '';
              result = false;
            }
            if (!client.zip) {
              client.zip = '';
              result = false;
            }
            if (!client.industry) {
              client.industry = '';
              result = false;
            }

            client.contacts.forEach(function (contact) {
              if (contact.selected) {
                if (!contact.firstName) {
                  contact.firstName = '';
                  result = false;
                }
                if (!contact.lastName) {
                  contact.lastName = '';
                  result = false;
                }
                if (!contact.email) {
                  contact.email = '';
                  result = false;
                }
              }
            });
          }
        });
        return result;
      };

      var saveClient = function (client) {
        return HttpService.post('/api/clients', {
          client: {
            name: client.name,
            domain: client.domain,
            description: client.description || 'imported',
            industry: client.industry,
            country: client.country,
            zip: client.zip,
            address: client.address,
            city: client.city,
            state: client.state,
            salesStatus: 'lead',
            externalId: client.externalId,
            source: $scope.data.system,
          },
        });
      };

      var saveContact = function (contact, clientId) {
        return HttpService.post('/api/contacts', {
          contact: {
            firstName: contact.firstName,
            lastName: contact.lastName,
            email: contact.email.toLowerCase(),
            phone: contact.phone,
            seniorityIds: contact.seniorityIds,
            ClientId: clientId,
            status: 'customer',
          },
        });
      };

      $scope.save = function () {
        if ($scope.validate()) {
          $rootScope.$broadcast('dataLoadingStarted');
          HttpService.post(
            '/api/admin/users/' +
              InputSanitizerService.sanitize(CurrentUser.getUser().id) +
              '/timeline/companyPSAImported',
            { system: $scope.data.system },
          );

          $q.all(
            _($scope.clients)
              .filter({ selected: true })
              .map(function (client) {
                return saveClient(client)
                  .then(function (createdClient) {
                    var saved = {
                      id: createdClient.id,
                      name: createdClient.name,
                      domain: createdClient.domain,
                      contacts: [],
                    };
                    $scope.savedClients.push(saved);
                    client.contacts.forEach(function (contact) {
                      if (contact.selected) {
                        saveContact(contact, createdClient.id)
                          .then(function (createdContact) {
                            saved.contacts.push(createdContact);
                          })
                          .catch(function (error) {
                            saved.contacts.push(_.extend(contact, { error: error }));
                          });
                      }
                    });
                    return createdClient;
                  })
                  .catch(function (error) {
                    $scope.savedClients.push(_.extend(client, { error: error }));
                    return;
                  });
              }),
          ).finally(function () {
            $rootScope.$broadcast('dataLoadingFinished');
            $scope.searchChanged = true;
          });
        } else {
          $scope.errors = ['error.validation'];
        }
      };
    },
  )

  .controller('IntegrationsController', function ($scope, company, $rootScope) {
    $scope.company = company;
    $scope.systemTab = 'connectwise';
  })

  .controller(
    'IntegrationAutotaskController',
    function ($scope, $rootScope, $http, $modal, _, CurrentUser) {
      $scope.currentUser = CurrentUser.getUser();
      $scope.keys = $scope.company.keys || {};

      $scope.editAPIKey = function () {
        var modalInstance = $modal.open({
          template:
            '<modal-form onok="save" oncancel="cancel" buttons="buttons" form-title="label.externalSystem.autotask.apiKey">' +
            '<input-text label="label.keys.autotask.username" model="keys.autotask.username" required="true"></input-text>' +
            '<input-text type="password" label="label.keys.autotask.password" model="keys.autotask.password" required="true"></input-text>' +
            '<input-text label="label.keys.autotask.webUrl" model="keys.autotask.webUrl"></input-text>' +
            '</modal-form>',
          controller: 'IntegrationAutotaskAPIKeyController',
          backdrop: 'static',
          resolve: {
            companyKeys: function () {
              return angular.copy($scope.keys);
            },
            company: function () {
              return $scope.company;
            },
          },
        });

        modalInstance.result.then(
          function (keys) {
            $scope.keys = keys;
          },
          function () {},
        );
      };
    },
  )

  .controller(
    'IntegrationComplianceScorecardController',
    function ($scope, $rootScope, $http, $modal, _, CurrentUser) {
      $scope.currentUser = CurrentUser.getUser();
      $scope.keys = $scope.company.keys || {};

      $scope.editAPIKey = function () {
        var modalInstance = $modal.open({
          template:
            '<modal-form onok="save" oncancel="cancel" buttons="buttons" form-title="label.externalSystem.complianceScore.apiKey">' +
            '<div class="margin-2x-bottom">' +
            '<div class="col-xs-3 control-label" ' +
            '<label translate="label.keys.complianceScorecard.environment"></label> ' +
            '</div> ' +
            '<div class="abm-form col-xs-9"> ' +
            '<abm-form-group class="no-margin-top"> ' +
            '<abm-ui-select ' +
            'model="selectedEnvironment" ' +
            'values="environments" ' +
            'on-select="setIntegrationComplianceScorecardEnvironment" ' +
            'placeholder="label.externalSystem.connectwise.placeHolder" ' +
            'allow-clear="false"> ' +
            '</abm-ui-select> ' +
            '</abm-form-group> ' +
            '</div> ' +
            '</div> ' +
            '<div class="margin-2x-bottom">' +
            '<input-text label="label.keys.complianceScorecard.apiToken" model="keys.complianceScorecard.apiToken"></input-text> ' +
            '<abm-form-group class="no-margin-top"> ' +
            '<div class="col-xs-3 control-label"" ' +
            '<h3 translate="label.keys.complianceScorecard.apiExpirationDate"></h3> ' +
            '</div> ' +
            '<div class="abm-form col-xs-9"> ' +
            '<abm-date model="keys.complianceScorecard.expirationDate" required="true" min-date="today" format="MMM dd. yyyy" placeholder="label.keys.complianceScorecard.apiExpirationDatePlaceholder"></abm-date> ' +
            '</div> ' +
            '</abm-form-group> ' +
            '</div> ' +
            '</modal-form>',
          controller: 'IntegrationComplianceScorecardAPIKeyController',

          backdrop: 'static',
          resolve: {
            companyKeys: function () {
              return angular.copy($scope.keys);
            },
            company: function () {
              return $scope.company;
            },
          },
        });

        modalInstance.result.then(
          function (keys) {
            $scope.keys = keys;
          },
          function () {},
        );
      };
    },
  )

  .controller(
    'IntegrationComplianceScorecardAPIKeyController',
    function (
      $scope,
      $rootScope,
      HttpService,
      $modalInstance,
      _,
      CurrentUser,
      DialogService,
      companyKeys,
      company,
      InputSanitizerService,
    ) {
      $scope.company = company;
      $scope.keys = companyKeys;
      $scope.submitting = false;
      $scope.cancelDisabled = false;

      $scope.environments = [
        { id: 'staging', name: 'Staging' },
        { id: 'production', name: 'Production' },
      ];

      const environmentUrls = {
        staging: 'https://staging-api.compliancerisk.io/api/v4',
        production: 'https://go-api.compliancerisk.io/api/v4',
      };

      $scope.selectedEnvironment = $scope.environments.find(
        ({ id }) => id === $scope.keys.complianceScorecard?.environment,
      );

      if ($scope.keys.complianceScorecard?.expirationDate) {
        $scope.keys.complianceScorecard.expirationDate = new Date(
          $scope.keys.complianceScorecard.expirationDate,
        );
      }

      $scope.setIntegrationComplianceScorecardEnvironment = function (environment) {
        $scope.selectedEnvironment = environment;
        $scope.keys.complianceScorecard = $scope.keys.complianceScorecard
          ? {
              ...$scope.keys.complianceScorecard,
              environment: environment.id,
              url: environmentUrls[environment.id],
            }
          : { environment: environment.id, url: environmentUrls[environment.id] };
      };

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };

      $scope.deleteKey = function () {
        $scope.submitting = true;
        $scope.keys.complianceScorecard = {};
        HttpService.put(
          '/api/companies/' +
            InputSanitizerService.sanitize($scope.company.id) +
            '/keys/complianceScorecard',
          { value: undefined },
        )
          .then(function (result) {
            $scope.cancelDisabled = true;
            _.unset($scope.company.keys, 'complianceScorecard');
            $scope.keys = $scope.company.keys || {};
            $scope.submitting = false;
            $modalInstance.close();
            window.location.reload();
          })
          .catch(function (error) {
            DialogService.error(error, 'message.connectionFailed');
            $scope.submitting = false;
          });
      };

      $scope.save = function (cb) {
        HttpService.put(
          '/api/companies/' +
            InputSanitizerService.sanitize($scope.company.id) +
            '/keys/complianceScorecard',
          $scope.keys.complianceScorecard,
        )
          .then(function () {
            _.set($scope.company.keys, 'complianceScorecard', $scope.keys.complianceScorecard);
            $modalInstance.close($scope.keys);
          })
          .catch(function (error) {
            DialogService.error(error, 'message.connectionFailed');
            cb();
          });
      };

      $scope.testKey = function (cb) {
        if (
          $scope.keys.complianceScorecard &&
          $scope.keys.complianceScorecard.apiToken &&
          $scope.keys.complianceScorecard.url
        ) {
          HttpService.post(
            '/api/companies/' +
              InputSanitizerService.sanitize($scope.company.id) +
              '/keys/complianceScorecard',
            $scope.keys.complianceScorecard,
          )
            .then(function (result) {
              var testResult = {
                code: 'message.companiesCount',
                translateValues: { count: result.count },
                success: true,
              };
              if (cb) {
                cb(testResult);
              } else {
                DialogService.message(testResult, 'message.connectionSuccess');
              }
            })
            .catch(function (error) {
              if (cb) {
                cb(error);
              } else {
                DialogService.error(error, 'message.connectionFailed');
              }
            });
        } else {
          if (cb) {
            cb();
          }
        }
      };

      $scope.buttons = [
        { label: 'button.test', click: $scope.testKey, color: 'primary' },
        { label: 'button.delete', click: $scope.deleteKey, color: 'warning' },
      ];
    },
  )

  .controller(
    'IntegrationHaloController',
    function ($scope, $rootScope, $http, $modal, _, CurrentUser) {
      $scope.currentUser = CurrentUser.getUser();
      $scope.keys = $scope.company.keys || {};

      $scope.editAPIKey = function () {
        var modalInstance = $modal.open({
          template:
            '<modal-form onok="save" oncancel="cancel" buttons="buttons" form-title="label.externalSystem.halo.apiKey">' +
            '<input-text label="label.keys.halo.tenantUrl" model="keys.halo.url" required="true"></input-text>' +
            '<input-text label="label.keys.halo.clientId" model="keys.halo.public"></input-text>' +
            '<input-text type="password" label="label.keys.halo.clientSecret" model="keys.halo.private" required="true"></input-text>' +
            '<input-text label="label.keys.halo.authorizationServer" model="keys.halo.authorizationServer"></input-text>' +
            '<input-text label="label.keys.halo.tenant" model="keys.halo.tenant"></input-text>' +
            '</modal-form>',
          controller: 'IntegrationHaloAPIKeyController',
          backdrop: 'static',
          resolve: {
            companyKeys: function () {
              return angular.copy($scope.keys);
            },
            company: function () {
              return $scope.company;
            },
          },
        });

        modalInstance.result.then(
          function (keys) {
            $scope.keys = keys;
          },
          function () {},
        );
      };
    },
  )

  .controller(
    'IntegrationHaloAPIKeyController',
    function (
      $scope,
      $rootScope,
      HttpService,
      $modalInstance,
      _,
      CurrentUser,
      DialogService,
      companyKeys,
      company,
      InputSanitizerService,
    ) {
      $scope.company = company;
      $scope.keys = companyKeys;
      $scope.submitting = false;
      $scope.cancelDisabled = false;

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };

      const transformUrl = (halo) => {
        const { url, authorizationServer, ...rest } = halo;
        const trimmedUrl = _.trim(url);
        const trimmedAuthorizationUrl = _.trim(authorizationServer);

        return {
          ...rest,
          url: _.startsWith(trimmedUrl, 'https://') ? url : `https://${trimmedUrl}`,
          authorizationServer: trimmedAuthorizationUrl
            ? _.startsWith(trimmedAuthorizationUrl, 'https://')
              ? trimmedAuthorizationUrl
              : `https://${trimmedAuthorizationUrl}`
            : trimmedAuthorizationUrl,
        };
      };

      $scope.deleteKey = function () {
        $scope.submitting = true;
        $scope.keys.halo = {};
        HttpService.put(
          '/api/companies/' + InputSanitizerService.sanitize($scope.company.id) + '/keys/halo',
          { value: undefined },
        )
          .then(function (result) {
            $scope.cancelDisabled = true;
            _.unset($scope.company.keys, 'halo');
            $scope.keys = $scope.company.keys || {};
            $scope.submitting = false;
            $modalInstance.close();
            window.location.reload();
          })
          .catch(function (error) {
            DialogService.error(error, 'message.connectionFailed');
            $scope.submitting = false;
          });
      };

      $scope.save = function (cb) {
        HttpService.put(
          '/api/companies/' + InputSanitizerService.sanitize($scope.company.id) + '/keys/halo',
          transformUrl($scope.keys.halo),
        )
          .then(function () {
            _.set($scope.company.keys, 'halo', transformUrl($scope.keys.halo));
            $modalInstance.close($scope.keys);
          })
          .catch(function (error) {
            DialogService.error(error, 'message.connectionFailed');
            cb();
          });
      };

      $scope.testKey = function (cb) {
        if (
          $scope.keys &&
          $scope.keys.halo &&
          $scope.keys.halo.url &&
          $scope.keys.halo.public &&
          $scope.keys.halo.private
        ) {
          HttpService.post(
            '/api/companies/' + InputSanitizerService.sanitize($scope.company.id) + '/keys/halo',
            transformUrl($scope.keys.halo),
          )
            .then(function (result) {
              var testResult = {
                code: 'message.companiesCount',
                translateValues: { count: result.count },
                success: true,
              };
              if (cb) {
                cb(testResult);
              } else {
                DialogService.message(testResult, 'message.connectionSuccess');
              }
            })
            .catch(function (error) {
              if (cb) {
                cb(error);
              } else {
                DialogService.error(error, 'message.connectionFailed');
              }
            });
        } else {
          if (cb) {
            cb();
          }
        }
      };

      $scope.buttons = [
        { label: 'button.test', click: $scope.testKey, color: 'primary' },
        { label: 'button.delete', click: $scope.deleteKey, color: 'warning' },
      ];
    },
  )

  .controller(
    'IntegrationAutotaskAPIKeyController',
    function (
      $scope,
      $rootScope,
      HttpService,
      $modalInstance,
      _,
      CurrentUser,
      DialogService,
      companyKeys,
      company,
      InputSanitizerService,
    ) {
      $scope.company = company;
      $scope.keys = companyKeys;
      $scope.submitting = false;
      $scope.cancelDisabled = false;

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };

      $scope.deleteKey = function () {
        $scope.submitting = true;
        $scope.keys.autotask = {};
        HttpService.put(
          '/api/companies/' + InputSanitizerService.sanitize($scope.company.id) + '/keys/autotask',
          { value: undefined },
        )
          .then(function (result) {
            $scope.cancelDisabled = true;
            _.unset($scope.company.keys, 'autotask');
            $scope.keys = $scope.company.keys || {};
            $scope.submitting = false;
            $modalInstance.close();
            window.location.reload();
          })
          .catch(function (error) {
            DialogService.error(error, 'message.connectionFailed');
            $scope.submitting = false;
          });
      };

      $scope.save = function (cb) {
        HttpService.put(
          '/api/companies/' + InputSanitizerService.sanitize($scope.company.id) + '/keys/autotask',
          $scope.keys.autotask,
        )
          .then(function () {
            _.set($scope.company.keys, 'autotask', $scope.keys.autotask);
            $modalInstance.close($scope.keys);
          })
          .catch(function (error) {
            DialogService.error(error, 'message.connectionFailed');
            cb();
          });
      };

      $scope.testKey = function (cb) {
        if (
          $scope.keys.autotask &&
          $scope.keys.autotask.username &&
          $scope.keys.autotask.password
        ) {
          HttpService.post(
            '/api/companies/' +
              InputSanitizerService.sanitize($scope.company.id) +
              '/keys/autotask',
            $scope.keys.autotask,
          )
            .then(function (result) {
              var testResult = {
                code: 'message.companiesCount',
                translateValues: { count: result.count },
                success: true,
              };
              if (cb) {
                cb(testResult);
              } else {
                DialogService.message(testResult, 'message.connectionSuccess');
              }
            })
            .catch(function (error) {
              if (cb) {
                cb(error);
              } else {
                DialogService.error(error, 'message.connectionFailed');
              }
            });
        } else {
          if (cb) {
            cb();
          }
        }
      };

      $scope.buttons = [
        { label: 'button.test', click: $scope.testKey, color: 'primary' },
        { label: 'button.delete', click: $scope.deleteKey, color: 'warning' },
      ];
    },
  )

  .controller(
    'IntegrationConnectwiseController',
    function (
      $scope,
      $translate,
      $rootScope,
      $http,
      $modal,
      $q,
      _,
      CurrentUser,
      DialogService,
      VcioService,
      HttpService,
      InputSanitizerService,
    ) {
      $scope.currentUser = CurrentUser.getUser();
      $scope.keys = $scope.company.keys || {};
      $scope.submitting = false;
      $scope.hostedScreens = [];
      $scope.companySettings = $scope.currentUser.Company.settings || null;
      $scope.boards = null;
      $scope.assignees = null;
      $scope.ticketSyncState = {
        touchpoint:
          $scope.companySettings &&
          $scope.companySettings.ConnectWise &&
          $scope.companySettings.ConnectWise.defaults.isTouchpointTicketSyncOn,
        activity:
          $scope.companySettings &&
          $scope.companySettings.ConnectWise &&
          $scope.companySettings.ConnectWise.defaults.isActivityTicketSyncOn,
      };
      $scope.defaultBoardName =
        $scope.companySettings && $scope.companySettings.ConnectWise
          ? $scope.companySettings.ConnectWise.defaults.defaultBoardName
          : null;
      $scope.defaultAssigneeName =
        $scope.companySettings && $scope.companySettings.ConnectWise
          ? $scope.companySettings.ConnectWise.defaults.defaultAssigneeName
          : null;
      $scope.defaultBoardId =
        $scope.companySettings && $scope.companySettings.ConnectWise
          ? $scope.companySettings.ConnectWise.defaults.defaultBoardId
          : null;
      $scope.defaultAssigneeId =
        $scope.companySettings && $scope.companySettings.ConnectWise
          ? $scope.companySettings.ConnectWise.defaults.defaultAssigneeId
          : null;
      $scope.canExport =
        $scope.companySettings &&
        $scope.companySettings.emailIntegration &&
        $scope.companySettings.emailIntegration.isCwEmailSyncOn &&
        $scope.companySettings.emailIntegration.touchPointExportEmail;
      $scope.cwEmailSyncState =
        $scope.companySettings &&
        $scope.companySettings.emailIntegration &&
        $scope.companySettings.emailIntegration.isCwEmailSyncOn;
      $scope.touchPointExportEmail =
        $scope.companySettings && $scope.companySettings.emailIntegration
          ? $scope.companySettings.emailIntegration.touchPointExportEmail
          : null;
      $scope.projectExportEmail =
        $scope.companySettings && $scope.companySettings.emailIntegration
          ? $scope.companySettings.emailIntegration.projectExportEmail
          : null;
      $scope.ticketExportEmail =
        $scope.companySettings && $scope.companySettings.emailIntegration
          ? $scope.companySettings.emailIntegration.ticketExportEmail
          : null;

      var getConnectWiseData = function () {
        HttpService.get('/api/vcio/connectwise/boards').then(function (boards) {
          $scope.boards = boards;
        });
        if ($scope.defaultBoardId) {
          HttpService.get(
            'api/vcio/connectwise/assignees/' +
              InputSanitizerService.sanitize($scope.defaultBoardId),
          ).then(function (assignees) {
            $scope.assignees = assignees;
          });
        }
      };

      $scope.$on('emailIntegrationSaved', function () {
        $scope.cwEmailSyncState = $scope.companySettings.emailIntegration.isCwEmailSyncOn;
        $scope.touchPointExportEmail =
          $scope.companySettings.emailIntegration.touchPointExportEmail;
        $scope.canExport = $scope.cwEmailSyncState && $scope.touchPointExportEmail;
        $scope.projectExportEmail = $scope.companySettings.emailIntegration.projectExportEmail;
        $scope.ticketExportEmail = $scope.companySettings.emailIntegration.ticketExportEmail;
      });

      $scope.$on('connectWiseSettingsSaved', function () {
        $scope.defaultBoardName = $scope.companySettings.ConnectWise.defaults.defaultBoardName;
        $scope.defaultAssigneeName =
          $scope.companySettings.ConnectWise.defaults.defaultAssigneeName;
        $scope.defaultBoardId = $scope.companySettings.ConnectWise.defaults.defaultBoardId;
        $scope.defaultAssigneeId = $scope.companySettings.ConnectWise.defaults.defaultAssigneeId;
        $scope.ticketSyncState = {
          touchpoint: $scope.companySettings.ConnectWise.defaults.isTouchpointTicketSyncOn,
          activity: $scope.companySettings.ConnectWise.defaults.isActivityTicketSyncOn,
        };
      });

      if ($scope.keys.connectwise && !$scope.companySettings.ConnectWise) {
        getConnectWiseData();
      }

      if (!_.get($scope.currentUser.Company.settings, 'connectwise')) {
        _.set($scope.currentUser.Company.settings, 'connectwise.academyAssignments', {});
      }
      $scope.myCompany = _.get($scope.currentUser.Company.settings, 'myCompany', {});

      $scope.editAPIKey = function () {
        var modalInstance = $modal.open({
          template:
            '<modal-form onok="save" oncancel="cancel" buttons="buttons" form-title="label.externalSystem.connectwise.apiKey">' +
            '<input-text label="label.keys.connectwise.url" model="keys.connectwise.url" required="true"></input-text>' +
            '<input-text label="label.keys.connectwise.company" model="keys.connectwise.company"></input-text>' +
            '<input-text label="label.keys.connectwise.public" model="keys.connectwise.public"></input-text>' +
            '<input-text type="password" label="label.keys.connectwise.private" model="keys.connectwise.private"></input-text>' +
            '<div class="alert alert-info" translate="label.keys.connectwise.help"></div>' +
            '</modal-form>',
          controller: 'IntegrationConnectwiseAPIKeyController',
          backdrop: 'static',
          resolve: {
            companyKeys: function () {
              return angular.copy($scope.keys);
            },
            company: function () {
              return $scope.company;
            },
          },
        });

        modalInstance.result.then(
          function (keys) {
            $scope.keys = keys;
          },
          function () {},
        );
      };

      $scope.editMyCompany = function () {
        var modalInstance = $modal.open({
          templateUrl: '/templates/msp/create-my-company.html',
          controller: 'IntegrationConnectwiseMyCompanyController',
          backdrop: 'static',
        });
        //
        modalInstance.result.then(
          function (myCompany) {
            $scope.myCompany.connectwise = myCompany;
          },
          function () {},
        );
      };

      $scope.isDefaultScreenGranted = function () {
        return $scope.keys.connectwise && $scope.keys.connectwise.url;
      };

      $scope.editTicketDefaults = function () {
        var modalInstance = $modal.open({
          templateUrl: '/templates/msp/edit-ticket-defaults.html',
          controller: 'IntegrationConnectwiseTicketDefaultController',
          backdrop: 'static',
          resolve: {
            boards: function () {
              return $scope.boards;
            },
            assignees: function () {
              return $scope.assignees;
            },
            defaultBoardId: function () {
              return $scope.defaultBoardId;
            },
            defaultAssigneeId: function () {
              return $scope.defaultAssigneeId;
            },
            ticketSyncState: function () {
              return {
                touchpoint:
                  $scope.companySettings &&
                  $scope.companySettings.ConnectWise &&
                  $scope.companySettings.ConnectWise.defaults.isTouchpointTicketSyncOn,
                activity:
                  $scope.companySettings &&
                  $scope.companySettings.ConnectWise &&
                  $scope.companySettings.ConnectWise.defaults.isActivityTicketSyncOn,
              };
            },
            company: function () {
              return $scope.company;
            },
          },
        });
      };

      $scope.editEmailIntegration = function () {
        var modalInstance = $modal.open({
          templateUrl: '/templates/msp/email-integration.html',
          controller: 'EmailIntegrationController',
          backdrop: 'static',
          resolve: {
            touchPointExportEmail: function () {
              return $scope.touchPointExportEmail;
            },
            projectExportEmail: function () {
              return $scope.projectExportEmail;
            },
            ticketExportEmail: function () {
              return $scope.ticketExportEmail;
            },
            cwEmailSyncState: function () {
              return (
                $scope.companySettings &&
                $scope.companySettings.emailIntegration &&
                $scope.companySettings.emailIntegration.isCwEmailSyncOn
              );
            },
            company: function () {
              return $scope.company;
            },
          },
        });
      };

      $scope.refresh = function () {
        if ($scope.keys.connectwise && $scope.keys.connectwise.url) {
          $rootScope.$broadcast('dataLoadingStarted');

          $http
            .get('/api/integration/connectwise/hostedsetups')
            .success(function (result) {
              $scope.hostedScreens = result;
              $rootScope.$broadcast('dataLoadingFinished');
            })
            .error(function (error) {
              $rootScope.$broadcast('dataLoadingFinished');
              DialogService.error(error, 'error.title');
              console.error(error);
            });
        }
        if ($scope.myCompany.connectwise && $scope.myCompany.connectwise.id) {
          $http
            .get('/api/vcio/connectwise/companies/?id=' + $scope.myCompany.connectwise.id)
            .success(function (result) {
              if (result.length) {
                $scope.myCompany.connectwise = result[0];
              }
            })
            .error(function (error) {
              DialogService.error(error, 'error.title');
              console.error(error);
            });
        }
      };

      $scope.showCreate = function (screen) {
        return screen.status == 'missing';
      };

      $scope.showDelete = function (screen) {
        return screen.status != 'missing';
      };

      $scope.showUpdate = function (screen) {
        return screen.status == 'differs';
      };

      $scope.createSetup = function (screen) {
        $rootScope.$broadcast('dataLoadingStarted');
        $http
          .post('/api/integration/connectwise/hostedsetups', { setup: screen })
          .success(function () {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.message({
              code: 'message.externalSystems.connectwise.screenCreated',
              translateValues: { name: screen.description },
            });
            $scope.refresh();
          })
          .error(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error, {
              code: 'error.externalSystems.connectwise.screenCreated',
              translateValues: { name: screen.description },
            });
            console.error(error);
          });
      };

      $scope.deleteSetup = function (screen) {
        $rootScope.$broadcast('dataLoadingStarted');
        $http
          .delete('/api/integration/connectwise/hostedsetups/' + screen.setup.id)
          .success(function () {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.message({
              code: 'message.externalSystems.connectwise.screenDeleted',
              translateValues: { name: screen.description },
            });
            $scope.refresh();
          })
          .error(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error, {
              code: 'error.externalSystems.connectwise.screenDeleted',
              translateValues: { name: screen.description },
            });
            console.error(error);
          });
      };

      $scope.updateSetup = function (screen) {
        $rootScope.$broadcast('dataLoadingStarted');
        $http
          .put('/api/integration/connectwise/hostedsetups/' + screen.setup.id, { setup: screen })
          .success(function () {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.message({
              code: 'message.externalSystems.connectwise.screenUpdated',
              translateValues: { name: screen.description },
            });
            $scope.refresh();
          })
          .error(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error, {
              code: 'error.externalSystems.connectwise.screenUpdated',
              translateValues: { name: screen.description },
            });
            console.error(error);
          });
      };

      $scope.createIntegration = function () {
        $rootScope.$broadcast('dataLoadingStarted');

        var promises = [];
        $scope.hostedScreens.forEach(function (screen) {
          if (screen.status == 'missing') {
            promises.push(
              $http.post('/api/integration/connectwise/hostedsetups', { setup: screen }),
            );
          }
        });

        $q.all(promises).then(
          function () {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.message('message.externalSystems.connectwise.screensCreated');
            $scope.refresh();
          },
          function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error, {
              code: 'error.externalSystems.connectwise.screenCreated',
              translateValues: { name: screen.description },
            });
            console.error(error);
          },
        );
      };

      $scope.exportTouchpointsToCW = function () {
        HttpService.post('/api/vcio/connectwise/exportTouchpointTickets')
          .then(function (message) {
            DialogService.message(
              {
                code: 'succes.externalSystems.connectwise.exportTicket',
                translateValues: { message: message.message },
              },
              $translate.instant('succes.externalSystems.connectwise.exportTicket.title'),
            );
            console.log(message);
          })
          .catch(function (error) {
            DialogService.error(
              {
                code: 'error.externalSystems.connectwise.exportTicket',
                translateValues: {
                  clientName: error.clientName,
                  errorMessage: error.translateValues.message,
                },
              },
              {
                code: 'error.externalSystems.connectwise.exportTicket.title',
              },
            );
          });
      };

      $scope.exportActivitiesToCW = function () {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.post('/api/vcio/connectwise/exportActivityTickets')
          .then(function (message) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.message(
              {
                code: 'succes.externalSystems.connectwise.exportTicket',
                translateValues: { message: message.message },
              },
              $translate.instant('succes.externalSystems.connectwise.exportActivityTicket.title'),
            );
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(
              {
                code: 'error.externalSystems.connectwise.exportTicket',
                translateValues: {
                  clientName: error.clientName,
                  errorMessage: error.translateValues.message,
                },
              },
              {
                code: 'error.externalSystems.connectwise.exportActivityTicket.title',
              },
            );
          });
      };

      $scope.syncTouchpointEmail = function () {
        HttpService.post('/api/vcio/email/syncTouchpointEmail')
          .then(function (message) {
            DialogService.message(
              {
                code: 'succes.externalSystems.email.exportTouchpoint',
                translateValues: { message: message.message },
              },
              $translate.instant('succes.externalSystems.email.exportTouchpoint.title'),
            );
            console.log(message);
          })
          .catch(function (error) {
            DialogService.error(
              {
                code: 'error.externalSystems.email.exportTouchpoint',
                translateValues: {
                  clientName: error.clientName,
                  errorMessage: error.translateValues ? error.translateValues.message : error,
                },
              },
              {
                code: 'error.externalSystems.email.exportTouchpoint.title',
              },
            );
          });
      };

      $scope.refresh();
    },
  )

  .controller(
    'IntegrationConnectwiseAPIKeyController',
    function (
      $scope,
      $rootScope,
      HttpService,
      $modalInstance,
      $q,
      _,
      CurrentUser,
      DialogService,
      companyKeys,
      company,
      InputSanitizerService,
    ) {
      $scope.company = company;
      $scope.keys = companyKeys;
      $scope.submitting = false;
      $scope.cancelDisabled = false;

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };

      $scope.deleteKey = function () {
        $scope.submitting = true;
        $scope.keys.connectwise = {};
        HttpService.put('/api/companies/' + $scope.company.id + '/keys/connectwise')
          .then(function (result) {
            $scope.cancelDisabled = true;
            _.unset($scope.company.keys, 'connectwise');
            $scope.keys = $scope.company.keys || {};
            $scope.submitting = false;
            $modalInstance.close();
            window.location.reload();
          })
          .catch(function (error) {
            DialogService.error(error, 'message.connectionFailed');
            $scope.submitting = false;
          });
      };

      $scope.save = function (cb) {
        $scope.testKey(function (testResult) {
          if (!testResult || testResult.success) {
            HttpService.put(
              '/api/companies/' +
                InputSanitizerService.sanitize($scope.company.id) +
                '/keys/connectwise',
              $scope.keys.connectwise,
            )
              .then(function () {
                if (testResult) {
                  DialogService.message(testResult, 'message.connectionSuccess');
                }
                _.set($scope.company.keys, 'connectwise', $scope.keys.connectwise);

                $modalInstance.close($scope.keys);
              })
              .catch(function (error) {
                DialogService.error(error, 'message.connectionFailed');
                cb();
              });
          } else {
            DialogService.error(testResult, 'message.connectionFailed');
            cb();
          }
        });
      };

      $scope.testKey = function (cb) {
        if (
          $scope.keys.connectwise &&
          ($scope.keys.connectwise.url ||
            $scope.keys.connectwise.company ||
            $scope.keys.connectwise.private ||
            $scope.keys.connectwise.public)
        ) {
          HttpService.post(
            '/api/companies/' +
              InputSanitizerService.sanitize($scope.company.id) +
              '/keys/connectwise',
            $scope.keys.connectwise,
          )
            .then(function (result) {
              var testResult = {
                code: 'message.companiesCount',
                translateValues: { count: result.count },
                success: true,
              };
              if (cb) {
                cb(testResult);
              } else {
                DialogService.message(testResult, 'message.connectionSuccess');
              }
            })
            .catch(function (error) {
              if (cb) {
                cb(error);
              } else {
                DialogService.error(error, 'message.connectionFailed');
              }
            });
        } else {
          if (cb) {
            cb();
          }
        }
      };

      $scope.buttons = [
        { label: 'button.test', click: $scope.testKey, color: 'primary' },
        { label: 'button.delete', click: $scope.deleteKey, color: 'warning' },
      ];
    },
  )

  .controller(
    'IntegrationConnectwiseMyCompanyController',
    function (
      $scope,
      $rootScope,
      $http,
      $modalInstance,
      $q,
      _,
      Auth,
      CurrentUser,
      DialogService,
      HttpService,
    ) {
      $scope.values = [];
      $scope.filter = {};
      $scope.selected = undefined;
      $scope.currentUser = CurrentUser.getUser();

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };

      $scope.deleteKey = function () {
        $scope.selected = undefined;
        Auth.setCompanySettingsValue('myCompany.connectwise')
          .then(function (result) {
            $modalInstance.close($scope.selected);
          })
          .catch(function (error) {
            DialogService.error(error);
            cb();
          });
      };

      $scope.save = function (cb) {
        if ($scope.selected && $scope.selected.id) {
          Auth.setCompanySettingsValue('myCompany.connectwise', _.pick($scope.selected, ['id']))
            .then(function (result) {
              $modalInstance.close($scope.selected);
            })
            .catch(function (error) {
              DialogService.error(error);
              cb();
            });
        } else {
          cb();
        }
      };

      $scope.search = function () {
        $scope.errors = [];
        $scope.messages = [];
        $scope.values = [];
        $rootScope.$broadcast('dataLoadingStarted');
        var url = '/api/vcio/connectwise/companies';
        if ($scope.filter.name) {
          url += '?name=' + $scope.filter.name;
        }
        HttpService.get(url).then(
          function (response) {
            $rootScope.$broadcast('dataLoadingFinished');
            $scope.values = response;
            if (!$scope.values || $scope.values.length === 0) {
              $scope.messages = ['message.noItems'];
            }
          },
          function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            if (error) {
              $scope.errors = [error];
              console.error(error);
            } else {
              $modalInstance.dismiss();
            }
          },
        );
      };

      $scope.select = function (item) {
        if ($scope.selected == item) {
          $scope.selected = undefined;
        } else {
          $scope.selected = item;
        }
      };

      $scope.buttons = [
        {
          label: 'button.delete',
          click: $scope.deleteKey,
          color: 'warning',
        },
      ];
    },
  )

  .controller(
    'IntegrationConnectwiseTicketDefaultController',
    function (
      $scope,
      $rootScope,
      HttpService,
      $modalInstance,
      Auth,
      $q,
      _,
      CurrentUser,
      DialogService,
      company,
      defaultBoardId,
      defaultAssigneeId,
      boards,
      assignees,
      ticketSyncState,
      InputSanitizerService,
    ) {
      $scope.company = company;
      $scope.cw = {};
      $scope.cw.boards = boards;
      $scope.cw.assignees = assignees;
      $scope.cw.defaultBoardId = defaultBoardId;
      $scope.cw.defaultAssigneeId = defaultAssigneeId;
      $scope.cw.isTouchpointTicketSyncOn = ticketSyncState.touchpoint;
      $scope.cw.isActivityTicketSyncOn = ticketSyncState.activity;

      var getConnectWiseBoardsData = function () {
        HttpService.get('/api/vcio/connectwise/boards').then(function (fetchedBoards) {
          $scope.cw.boards = fetchedBoards;
        });
      };

      var getConnectWiseAssigneesData = function () {
        HttpService.get(
          'api/vcio/connectwise/assignees/' +
            InputSanitizerService.sanitize($scope.cw.defaultBoardId),
        ).then(function (fetchedAssignees) {
          $scope.cw.assignees = fetchedAssignees.map(function (assignee) {
            return Object.assign(assignee, {
              fullName: assignee.firstName + ' ' + assignee.lastName,
            });
          });
        });
      };

      if (!$scope.cw.boards) {
        getConnectWiseBoardsData();
      }

      if (!$scope.cw.assignees && $scope.cw.defaultBoardId) {
        getConnectWiseAssigneesData();
      }

      var getConnectWiseDefault = function (arr, defaultId) {
        const defaultValue = arr.filter(function (item) {
          return item.id === defaultId;
        });
        return defaultValue[0] || null;
      };

      $scope.$watch('cw.defaultBoardId', function (newValue, oldValue) {
        if (newValue !== oldValue && newValue) {
          getConnectWiseAssigneesData();
        } else if (!newValue) {
          $scope.cw.assignee = null;
        }
      });

      $scope.$watch('cw.boards', function (newValue, oldValue) {
        if (newValue !== oldValue) {
          if (defaultBoardId) {
            $scope.cw.board = getConnectWiseDefault($scope.cw.boards, defaultBoardId);
          }
        }
      });

      $scope.$watch('cw.assignees', function (newValue, oldValue) {
        if (newValue !== oldValue) {
          if (defaultAssigneeId) {
            $scope.cw.assignee = getConnectWiseDefault($scope.cw.assignees, defaultAssigneeId);
          }
        }
      });

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };

      $scope.setTouchpointTicketSync = function () {
        $scope.cw.isTouchpointTicketSyncOn = !$scope.cw.isTouchpointTicketSyncOn;
      };

      $scope.setActivityTicketSync = function () {
        $scope.cw.isActivityTicketSyncOn = !$scope.cw.isActivityTicketSyncOn;
      };

      $scope.setBoardSelection = function (board) {
        $scope.cw.defaultBoardId = board ? board.id : null;
      };

      $scope.save = function () {
        Auth.setCompanySettingsValue('ConnectWise.defaults', {
          defaultAssigneeId: $scope.cw.assignee ? $scope.cw.assignee.id : null,
          defaultBoardId: $scope.cw.board ? $scope.cw.board.id : null,
          isTouchpointTicketSyncOn: $scope.cw.isTouchpointTicketSyncOn,
          isActivityTicketSyncOn: $scope.cw.isActivityTicketSyncOn,
          defaultAssigneeName: $scope.cw.assignee ? $scope.cw.assignee.fullName : null,
          defaultBoardName: $scope.cw.board ? $scope.cw.board.name : null,
        })
          .then(function (result) {
            $modalInstance.close($scope.selected);
            $rootScope.$broadcast('connectWiseSettingsSaved');
          })
          .catch(function (error) {
            DialogService.error(error);
          });
      };
    },
  )

  .controller(
    'EmailIntegrationController',
    function (
      $scope,
      $rootScope,
      HttpService,
      $modalInstance,
      Auth,
      $q,
      _,
      CurrentUser,
      DialogService,
      company,
      touchPointExportEmail,
      projectExportEmail,
      ticketExportEmail,
      cwEmailSyncState,
    ) {
      // $scope.company = company;
      $scope.cw = {};
      $scope.cw.isCwEmailSyncOn = cwEmailSyncState;
      $scope.cw.touchPointExportEmail = touchPointExportEmail;
      $scope.cw.ticketExportEmail = ticketExportEmail;
      $scope.cw.projectExportEmail = projectExportEmail;

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };

      $scope.setCwEmailSync = function () {
        $scope.cw.isCwEmailSyncOn = !$scope.cw.isCwEmailSyncOn;
      };

      $scope.save = function () {
        Auth.setCompanySettingsValue('emailIntegration', {
          touchPointExportEmail: $scope.cw.touchPointExportEmail || null,
          ticketExportEmail: $scope.cw.ticketExportEmail,
          projectExportEmail: $scope.cw.projectExportEmail,
          isCwEmailSyncOn: $scope.cw.isCwEmailSyncOn,
        })
          .then(function (result) {
            $modalInstance.close($scope.selected);
            $rootScope.$broadcast('emailIntegrationSaved');
          })
          .catch(function (error) {
            DialogService.error(error);
          });
      };
    },
  )

  .controller(
    'IntegrationConnectwiseLinkClientController',
    function (
      $http,
      $modal,
      $q,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      _,
      DialogService,
      HttpService,
      clients,
      contactSeniorities,
    ) {
      $scope.filter = {};
      $scope.clients = _.filter(clients, function (client) {
        return !client.externalIds.connectwise;
      });
      $scope.externalId = $stateParams.externalId;
      $scope.externalClient = null;
      $scope.contactSeniorities = contactSeniorities;

      var saveClient = function (client) {
        return HttpService.post('/api/clients', {
          client: {
            name: client.name,
            domain: client.domain,
            description: client.description || 'imported',
            industry: client.industry,
            country: client.country,
            zip: client.zip,
            address: client.address,
            city: client.city,
            state: client.state,
            salesStatus: 'lead',
            externalId: client.externalId,
            source: client.source,
          },
        });
      };

      var saveContact = function (contact, clientId) {
        return HttpService.post('/api/contacts', {
          contact: {
            firstName: contact.firstName,
            lastName: contact.lastName,
            email: contact.email.toLowerCase(),
            phone: contact.phone,
            seniorityIds: contact.seniorityIds,
            ClientId: clientId,
            status: 'customer',
          },
        });
      };

      $scope.ok = function (cb) {
        var client = $scope.externalClient;
        saveClient(client).then(
          function (createdClient) {
            var promises = [];
            client.contacts.forEach(function (contact) {
              if (contact.selected) {
                promises.push(saveContact(contact, createdClient.id));
              }
            });
            $q.all(promises).then(
              function () {
                if ($rootScope.previousState) {
                  $state.go($rootScope.previousState.name);
                }
              },
              function (error) {
                DialogService.error(error);
                cb();
              },
            );
          },
          function (error) {
            DialogService.error(error);
            cb();
          },
        );
      };

      $scope.cancel = function () {
        if ($rootScope.previousState) {
          $state.go($rootScope.previousState.name);
        }
        $scope.externalClient = null;
      };

      $scope.getCheckbox = function (contact) {
        return contact.selected ? 'fa-check-square-o' : 'fa-square-o';
      };

      $scope.selectContact = function (contact) {
        contact.selected = !contact.selected;
        if ($scope.externalClient.selected) {
          $scope.externalClient.selected = false;
        }
      };

      $scope.selectAllContacts = function (deselect) {
        $scope.externalClient.selected = deselect ? false : !$scope.externalClient.selected;
        $scope.externalClient.contacts.forEach(function (contact) {
          contact.selected = $scope.externalClient.selected;
        });
      };

      $scope.linkClient = function () {
        var client = {};
        var modalInstance = $modal.open({
          templateUrl: '/templates/modal-select.html',
          controller: 'ModalSelectController',
          backdrop: 'static',
          resolve: {
            model: function () {
              return client.id;
            },
            values: function () {
              return $scope.clients;
            },
            required: function () {
              return true;
            },
          },
        });

        modalInstance.result.then(
          function (result) {
            var client = _.find($scope.clients, { id: result.id });
            if (client) {
              client.externalIds.connectwise = $scope.externalId;
              $http
                .put('/api/vcio/connectwise/companies/' + $scope.externalId + '/link/' + result.id)
                .success(function () {
                  if ($rootScope.previousState) {
                    $state.go($rootScope.previousState.name);
                  }
                });
            }
          },
          function () {},
        );
      };

      $scope.importClient = function () {
        $rootScope.$broadcast('dataLoadingStarted');

        $http
          .get(
            '/api/vcio/connectwise/companies?contacts=true&id=' +
              InputSanitizerService.sanitize($scope.externalId),
          )
          .success(function (externalClients) {
            $scope.externalClient = externalClients[0];
            $rootScope.$broadcast('dataLoadingFinished');
          })
          .error(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error, 'error.error');
          });
      };
    },
  );
