angular
  .module('vcio-toolkit')
  .controller(
    'AcademyManageServiceGroupsController',
    function ($scope, $state, $rootScope, $http, $modal, _, CurrentUser, servicegroups) {
      $scope.serviceGroups = _.filter(servicegroups, {
        VendorId: CurrentUser.getUser().Company.id,
      });
      $scope.filter = { name: '' };

      $scope.nameFilter = function (item) {
        return (
          !$scope.filter.name ||
          (item.name && item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1)
        );
      };

      $scope.canEdit = function (item) {
        return (
          CurrentUser.can('createServiceGroup') &&
          (!item || !item.VendorId || item.VendorId === CurrentUser.getUser().Company.id)
        );
      };

      $scope.edit = function (serviceGroup) {
        if (serviceGroup) {
          if ($scope.canEdit(serviceGroup)) {
            $state.go('service.manageservicegrouptemplate', { id: serviceGroup.id });
          }
        } else {
          $state.go('service.manageservicegrouptemplate', { id: 'new', template: true });
        }
      };

      $scope.create = function () {
        $scope.edit();
      };

      $scope.delete = function (serviceGroup) {
        $http.delete('/api/service/servicegroups/' + serviceGroup.id).then(function () {
          _.remove($scope.serviceGroups, function (item) {
            return item.id == serviceGroup.id;
          });
        });
      };

      $scope.operations = [
        { operation: $scope.edit, label: 'button.edit', icon: 'edit', condition: $scope.canEdit },
        {
          operation: $scope.delete,
          label: 'button.delete',
          icon: 'minus-square-o',
          condition: $scope.canEdit,
          confirmation: true,
        },
      ];

      // $scope.refresh();
    },
  )

  .controller(
    'AcademyServiceGroupEditController',
    function (
      $scope,
      $rootScope,
      $state,
      $modal,
      $http,
      _,
      servicegroup,
      CurrentUser,
      AcademyService,
      HttpService,
    ) {
      $scope.serviceGroup = servicegroup;
      $scope.isTemplate = $scope.serviceGroup.template;
      $scope.hasModule = CurrentUser.hasModule;

      if (!$scope.isTemplate) {
        $http.get('/api/service/servicegroups/templates').then(function (response) {
          $scope.templates = response.data;
        });
      }

      if ($scope.serviceGroup.VendorId != CurrentUser.getUser().Company.id) {
        AcademyService.goBack('servicegroups');
      }

      if ($scope.serviceGroup && $scope.serviceGroup.Services) {
        $scope.serviceGroup.Services = _.sortBy($scope.serviceGroup.Services, 'orderId');
        for (var i = 0; i < $scope.serviceGroup.Services.length; i++) {
          $scope.serviceGroup.Services[i].orderId = i + 1;
        }
      }

      $scope.errors = [];
      $scope.currentUser = CurrentUser.getUser();

      $scope.ok = function (cb) {
        var req;
        if ($scope.serviceGroup.id) {
          // modify
          req = HttpService.put('/api/service/servicegroups', {
            serviceGroup: $scope.serviceGroup,
          });
        } else {
          //create
          req = HttpService.post('/api/service/servicegroups', {
            serviceGroup: $scope.serviceGroup,
          });
        }
        req.then(
          function (response) {
            cb();
            if ($scope.modalInstance) {
              $scope.modalInstance.close(response);
            } else {
              if ($scope.serviceGroup.id) {
                $scope.messages = ['message.saved'];
              } else {
                $state.go('service.manageservicegrouptemplates');
              }
            }
          },
          function (error) {
            $scope.errors.push(error);
            console.error(error);
            cb();
          },
        );
      };

      $scope.cancel = function () {
        if ($scope.modalInstance) {
          $scope.modalInstance.dismiss();
        } else {
          AcademyService.goBack('servicegrouptemplates');
        }
      };

      $scope.moveUp = function (item, list) {
        if (item.orderId > 1) {
          var prev = _.find(list, { orderId: item.orderId - 1 });
          item.orderId -= 1.5;
          prev.orderId++;
          item.orderId += 0.5;
        }
      };

      $scope.moveDown = function (item, list) {
        if (item.orderId < list.length) {
          var next = _.find(list, { orderId: item.orderId + 1 });
          item.orderId += 1.5;
          next.orderId--;
          item.orderId -= 0.5;
        }
      };
    },
  )

  // .controller('AcademyManageServicesController', function ($scope, $rootScope, $state, $http, $modal, _, CurrentUser, DialogService) {
  //     $scope.services = [];
  //     $scope.serviceGroups = [];
  //     //$scope.selectedServiceGroups = [];
  //     $scope.filter = {name: ''};
  //
  //     $scope.refresh = function () {
  //         $http.get('/api/service/services/templates?all=true')
  //             .success(function (result) {
  //                 $scope.services = _.filter(result, {VendorId: CurrentUser.getUser().Company.id});
  //             }).error(function (error) {
  //             console.error(error);
  //         });
  //         $http.get('/api/service/servicegroups/templates?all=true')
  //             .success(function (result) {
  //                 $scope.serviceGroups = _.filter(result, {VendorId: CurrentUser.getUser().Company.id});
  //                 $scope.serviceGroups.unshift({name: '-- select serviceGroup --'});
  //                 $scope.filter.serviceGroup = $scope.serviceGroups[0];
  //             }).error(function (error) {
  //             console.error(error);
  //         })
  //     };
  //
  //     $scope.canEdit = function (item) {
  //         return CurrentUser.can('createService') && (!item || !item.VendorId || item.VendorId === CurrentUser.getUser().Company.id);
  //     };
  //
  //     $scope.$on('openEdit', function (event, args) {
  //         if (args && args.viewTab == 'services' && args.itemId) {
  //             $scope.edit(_.find($scope.services, {id: args.itemId}));
  //         }
  //     });
  //
  //     $scope.edit = function (service) {
  //         if ($scope.canEdit(service)) {
  //             $state.go('service.manageservicetemplate', {id: service ? service.id : 'new'});
  //         }
  //     };
  //
  //     $scope.create = function () {
  //         $scope.edit();
  //     };
  //
  //     $scope.delete = function (service) {
  //
  //         $http.delete('/api/service/services/' + service.id)
  //             .success(function () {
  //                 _.remove($scope.services, function (item) {
  //                     return item.id == service.id
  //                 });
  //             }).error(function (error) {
  //             $rootScope.$broadcast('dataLoadingFinished');
  //             DialogService.error(error);
  //             console.error(error);
  //         });
  //     };
  //
  //     $scope.operations = [
  //         {operation: $scope.edit, label: 'button.edit', icon: 'edit', condition: $scope.canEdit},
  //         {
  //             operation: $scope.delete,
  //             label: 'button.delete',
  //             icon: 'minus-square-o',
  //             condition: $scope.canEdit,
  //             confirmation: true
  //         }
  //     ];
  //
  //     $scope.goService = function (service) {
  //         $state.go('service.service', {serviceId: service.id})
  //     };
  //
  //     $scope.publishedFilter = function (item) {
  //         return !!item.published;
  //     };
  //
  //     $scope.serviceGroupFilter = function (item) {
  //         return !$scope.filter.serviceGroup || !$scope.filter.serviceGroup.id || $scope.filter.serviceGroup.id === item.ServiceGroup.id;
  //         //return $scope.selectedServiceGroups.length == 0 || _.find($scope.selectedServiceGroups, {id: item.ServiceGroup.id});
  //     };
  //
  //     $scope.nameFilter = function (item) {
  //         return !$scope.filter.name || (item.name && item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1);
  //     };
  //
  //     //$scope.isServiceGroupSelected = function (serviceGroup) {
  //     //    return _.find($scope.selectedServiceGroups, {id: serviceGroup.id}) ? true : false;
  //     //}
  //     //$scope.selectServiceGroup = function (serviceGroup) {
  //     //    if ($scope.isServiceGroupSelected(serviceGroup)) {
  //     //        _.remove($scope.selectedServiceGroups, {id: serviceGroup.id});
  //     //    } else {
  //     //        $scope.selectedServiceGroups.push(serviceGroup);
  //     //    }
  //     //}
  //
  //     $scope.refresh();
  // })

  .controller(
    'AcademyServicesController',
    function (
      $scope,
      $state,
      $stateParams,
      $rootScope,
      $http,
      $modal,
      $translate,
      _,
      CurrentUser,
      AcademyService,
      DialogService,
      HttpService,
      LocalStorage,
      PermissionGroupModel,
      services,
      serviceGroups,
      InputSanitizerService,
    ) {
      $scope.serviceGroups = serviceGroups;
      $scope.filter = { type: 'any', expanded: true, permissionGroups: [] };
      if ($stateParams.permissionGroupId) {
        $scope.filter.permissionGroups = _.map(
          $stateParams.permissionGroupId.split(','),
          function (val) {
            return parseInt(val);
          },
        );
      }
      $scope.selectedDiscipline = '';
      $scope.serviceType = $stateParams.type;
      $scope.serviceTypes = ['any'];
      if ($scope.serviceType && _.includes(['onetime', 'ongoing'], $scope.serviceType)) {
        $scope.serviceTypes.push($scope.serviceType);
      } else {
        $scope.serviceTypes = ['any', 'onetime', 'ongoing'];
      }
      $scope.serviceCycles = ['annual', 'monthly', 'na', 'quarterly', 'weekly'];
      $scope.user = CurrentUser.getUser();
      $scope.isVendor = CurrentUser.hasModule('vendor');
      $scope.isTemplates = _.endsWith($state.$current.name, 'servicetemplates');
      $scope.userHasNoServices = false;
      $scope.newService = {};
      $scope.serviceGroupsStatus = LocalStorage.getObject('serviceGroupsStatus', {});
      $scope.myPermissionGroups = [];
      PermissionGroupModel.querySubscribed().$promise.then(function (result) {
        var listPermissionGroups = _(services)
          .flatMap(function (item) {
            return _.get(item, 'Template.PermissionGroups') || _.get(item, 'PermissionGroups', []);
          })
          .map('id')
          .uniq()
          .value();
        $scope.myPermissionGroups = _.filter(result, function (item) {
          return _.includes(listPermissionGroups, item.id);
        });
      });

      function serviceToServiceGroupAssociate(service) {
        var serviceGroup = _.find($scope.serviceGroups, { id: service.ServiceGroupId || 0 });
        if (!serviceGroup) {
          serviceGroup = service.ServiceGroup || { id: 0 };
          serviceGroup.Services = [];
          $scope.serviceGroups.push(serviceGroup);
        }
        if (!serviceGroup.Services) {
          serviceGroup.Services = [];
        }
        serviceGroup.expanded = $scope.serviceGroupsStatus[serviceGroup.id] !== false;
        serviceGroup.selected = false;
        serviceGroup.Services.push(service);
      }

      _.forOwn($scope.serviceGroups, function (serviceGroup) {
        serviceGroup.expanded = $scope.serviceGroupsStatus[serviceGroup.id] !== false;
        serviceGroup.Services = [];
      });
      services.forEach(function (service) {
        serviceToServiceGroupAssociate(service);
      });
      if ($scope.isTemplates) {
        $http.get('/api/service/services/').then(function (response) {
          var myservices = response.data;
          myservices.forEach(function (myservice) {
            var service = _.find(services, { id: myservice.TemplateId });
            if (service) {
              service.inCatalogue = true;
            }
          });
        });
      } else {
        if (services.length === 0) {
          $scope.userHasNoServices = true;
        }
      }

      function saveService(service, cb) {
        var req;
        $rootScope.$broadcast('dataLoadingStarted');
        if (service.id) {
          req = HttpService.put('/api/service/services', { service: service });
        } else {
          req = HttpService.post('/api/service/services', { service: service });
        }
        req.then(
          function (result) {
            $rootScope.$broadcast('dataLoadingFinished');
            if (!service.id) {
              service.id = result.id;
            }
            if (cb) {
              cb(null);
            }
          },
          function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            console.error(error);
            DialogService.error(error);
            if (cb) {
              cb(error);
            }
          },
        );
      }

      // function saveServiceGroup(serviceGroup, cb) {
      //     var req;
      //     if (serviceGroup.id) {
      //         req = HttpService.put('/api/service/servicegroups', {serviceGroup: serviceGroup})
      //     } else {
      //         req = HttpService.post('/api/service/servicegroups', {serviceGroup: serviceGroup})
      //     }
      //     req.then(function (result) {
      //         if (cb) {
      //             cb(null);
      //         }
      //     }, function (error) {
      //         console.error(error);
      //         DialogService.error(error);
      //         if (cb) {
      //             cb(error);
      //         }
      //     });
      // }

      function addServices(selectedTemplates, cb) {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.post('/api/service/services/templates', { templateIds: selectedTemplates })
          .then(function (result) {
            if ($scope.isTemplates) {
              services.forEach(function (template) {
                if (_.includes(selectedTemplates, template.id)) {
                  template.inCatalogue = true;
                  template.selected = false;
                }
              });
            } else {
              _.forEach(result, function (service) {
                serviceToServiceGroupAssociate(service);
              });
            }
            $rootScope.$broadcast('dataLoadingFinished');
            if (cb) {
              cb();
            }
          })
          .catch(function (error) {
            DialogService.error(error);
            console.error(error);
            $rootScope.$broadcast('dataLoadingFinished');
            if (cb) {
              cb(error);
            }
          });
      }

      $scope.clickPermissionGroupFilter = function (permissionGroupId) {
        // $scope.expandAllServiceGroups(true);
        // $state.go('service.services', {permissionGroupId: permissionGroupId.join()});
        $state.transitionTo(
          'service.services',
          { permissionGroupId: permissionGroupId.join() },
          { notify: false },
        );
      };

      $scope.serviceClick = function (service) {
        if (serviceDragged) {
          serviceDragged = false;
        } else {
          $state.go('service.service', { serviceId: service.id });
        }
      };

      $scope.serviceInlineEditClick = function (service) {
        service.history = {
          name: service.name,
          type: service.type,
          cycle: service.cycle,
          vendorName: null,
        };
        if (service.Vendor) {
          service.history.vendorName = service.Vendor.name;
        }
        service.editName = service.name;
        service.isEdit = true;
      };
      $scope.serviceInlineSaveClick = function (service, serviceGroup) {
        service.name = service.editName;
        saveService(service, function (error) {
          if (error !== null) {
            console.error(error);
            $scope.serviceInlineCancelClick(service);
          } else {
            serviceGroup.$$inlineAddOpened = false;
            service.isEdit = false;
          }
        });
      };
      $scope.serviceInlineCancelClick = function (service, serviceGroup) {
        serviceGroup.$$inlineAddOpened = false;
        if (service.history) {
          service.name = service.history.name;
          service.type = service.history.type;
          service.cycle = service.history.cycle;
          if (service.Vendor && service.history.vendorName !== null) {
            service.Vendor.name = service.history.vendorName;
          }
        } else {
          serviceGroup = _.find($scope.serviceGroups, { id: service.ServiceGroupId });
          _.remove(serviceGroup.Services, service);
        }
        service.isEdit = false;
      };

      $scope.inlineAddService = function (serviceGroup) {
        serviceGroup.$$inlineAddOpened = true;
        serviceGroup.Services = serviceGroup.Services || [];
        serviceGroup.Services.unshift({
          name: '',
          orderId: 0,
          steps: [],
          published: false,
          CreatedBy: $scope.user.id,
          UpdatedById: $scope.user.id,
          VendorId: $scope.user.Company.id,
          isEdit: true,
          template: $scope.isTemplates,
          type: 'any',
          ServiceGroupId: serviceGroup.id,
        });
      };

      $scope.checkUncheckServiceGroup = function (serviceGroup) {
        serviceGroup.selected = !serviceGroup.selected;
        _.forEach(serviceGroup.Services, function (service) {
          if (!service.inCatalogue) {
            service.selected = serviceGroup.selected;
          }
        });
      };

      $scope.validateCheckedServices = function () {
        var unchecked = true;
        services.forEach(function (service) {
          if (service.selected) {
            unchecked = false;
            return false;
          }
        });
        return unchecked;
      };

      $scope.getServicePopover = AcademyService.getServicePopover;
      $scope.hasModule = CurrentUser.hasModule;

      $scope.vendorFilter = AcademyService.vendorFilter;
      $scope.nameFilter = function (item) {
        return (
          !$scope.filter.name ||
          (item.name && item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1) ||
          (item.description &&
            item.description.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1) ||
          (item.Services &&
            _.find(item.Services, function (service) {
              return (
                (service.name &&
                  service.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1) ||
                (service.description &&
                  service.description.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1)
              );
            }))
        );
      };
      $scope.permissionGroupFilter = function (item) {
        return (
          _.isEmpty($scope.filter.permissionGroups) ||
          (item.Template &&
            _.find(item.Template.PermissionGroups, function (permissionGroup) {
              return _.includes($scope.filter.permissionGroups, permissionGroup.id);
            })) ||
          _.find(item.PermissionGroups, function (permissionGroup) {
            return _.includes($scope.filter.permissionGroups, permissionGroup.id);
          })
        );
      };
      $scope.serviceSort = function (item) {
        return item.id ? item.name : '';
      };

      $scope.canEditService = function (service) {
        return (
          !service ||
          !service.VendorId ||
          (CurrentUser.can('editService') &&
            CurrentUser.getUser().Company.id == service.VendorId &&
            !service.template)
        );
      };
      $scope.canDeleteService = function (service) {
        return (
          !service || !service.VendorId || CurrentUser.getUser().Company.id == service.VendorId
        );
      };
      $scope.canCopyServiceTemplate = function (service) {
        return (
          !service ||
          !service.VendorId ||
          (CurrentUser.getUser().Company.id !== service.VendorId && service.template)
        );
      };
      $scope.canEditServiceTemplate = function (service) {
        return (
          !service ||
          !service.VendorId ||
          (CurrentUser.getUser().Company.id == service.VendorId && service.template)
        );
      };
      $scope.canResetService = function (service) {
        return !$scope.isTemplates && service.TemplateId;
      };

      $scope.canEditServiceGroup = function (serviceGroup) {
        return (
          !serviceGroup ||
          !serviceGroup.VendorId ||
          CurrentUser.getUser().Company.id == serviceGroup.VendorId
        );
      };
      $scope.canDeleteServiceGroup = function (serviceGroup) {
        return (
          !_.find(_.flatten(_.map($scope.serviceGroups, 'Services')), {
            ServiceGroupId: serviceGroup.id,
          }) &&
          (!serviceGroup ||
            !serviceGroup.VendorId ||
            CurrentUser.getUser().Company.id == serviceGroup.VendorId)
        );
      };
      $scope.canAddInlineService = function (serviceGroup) {
        return (
          serviceGroup.Services &&
          (serviceGroup.Services[serviceGroup.Services.length - 1].id || false)
        );
      };

      $scope.createServiceFromTemplate = function () {
        if (!$scope.isTemplates) {
          $rootScope.$broadcast('dataLoadingStarted');
          HttpService.get(
            '/api/service/services/templates/?type=' +
              InputSanitizerService.sanitize($scope.serviceType),
          ).then(
            function (serviceTemplates) {
              $scope.serviceTemplates = _.filter(serviceTemplates, function (o) {
                return !_.find(_.flatMap($scope.serviceGroups, 'Services'), { TemplateId: o.id });
              });
              //$scope.serviceTemplates = serviceTemplates;
              $scope.newService = { expanded: true };
              $rootScope.$broadcast('dataLoadingFinished');
            },
            function (error) {
              $rootScope.$broadcast('dataLoadingFinished');
              DialogService.error(error);
            },
          );
        }
      };
      $scope.save = function (newService, cb) {
        if (newService.template) {
          $state.go('service.serviceCopy', { serviceId: newService.template });
        }
      };
      $scope.cancel = function (newService) {
        $scope.newService = {};
      };
      $scope.copyService = function (service) {
        if ($scope.canEditService(service)) {
          $state.go('service.serviceCopy', { serviceId: service.id });
        }
      };
      $scope.editService = function (service) {
        if ($scope.canEditService(service)) {
          $state.go('service.serviceEdit', { serviceId: service ? service.id : 'new' });
        }
      };
      $scope.copyServiceTemplate = function (service) {
        if ($scope.canCopyServiceTemplate(service)) {
          $state.go('service.servicetemplateCopy', { serviceId: service.id });
        }
      };
      $scope.editServiceTemplate = function (service) {
        if ($scope.canEditServiceTemplate(service)) {
          $state.go('service.servicetemplateEdit', { serviceId: service ? service.id : 'new' });
        }
      };
      $scope.deleteService = function (service) {
        if ($scope.canDeleteService(service)) {
          $rootScope.$broadcast('dataLoadingStarted');
          HttpService.delete('/api/service/services/' + service.id)
            .then(function (result) {
              $rootScope.$broadcast('dataLoadingFinished');
              var serviceGroup = _.find($scope.serviceGroups, { id: service.ServiceGroupId });
              if (serviceGroup) {
                _.remove(serviceGroup.Services, { id: service.id });
              }
            })
            .catch(function (error) {
              $rootScope.$broadcast('dataLoadingFinished');
              DialogService.error(error);
            });
        }
      };

      $scope.resetService = function (service) {
        DialogService.confirm(
          'message.academy.confirmResetService',
          function () {
            $rootScope.$broadcast('dataLoadingStarted');
            if (service.TemplateId) {
              HttpService.post('/api/service/services/reset', { serviceIds: [service.id] })
                .then(function () {
                  $rootScope.$broadcast('dataLoadingFinished');
                  DialogService.message('message.academy.serviceReseted', function () {
                    $state.transitionTo($state.current, $stateParams, {
                      reload: true,
                      inherit: false,
                      notify: true,
                    });
                  });
                })
                .catch(function (error) {
                  $rootScope.$broadcast('dataLoadingFinished');
                  DialogService.message(error);
                });
            }
          },
          function () {},
        );
      };
      $scope.resetAllServices = function () {
        DialogService.confirm(
          'message.academy.confirmResetCatalogue',
          function () {
            $rootScope.$broadcast('dataLoadingStarted');

            var serviceIds = [];
            _.forEach($scope.serviceGroups, function (serviceGroup) {
              _.forEach(serviceGroup.Services, function (service) {
                serviceIds.push(service.id);
              });
            });

            HttpService.get('/api/service/services/templates').then(function (templates) {
              var templateIds = _.map(templates, 'id');

              HttpService.post('/api/service/services/reset', {
                serviceIds: serviceIds,
                templateIds: templateIds,
              })
                .then(function () {
                  $rootScope.$broadcast('dataLoadingFinished');
                  DialogService.message('message.academy.servicesReseted', function () {
                    $state.transitionTo($state.current, $stateParams, {
                      reload: true,
                      inherit: false,
                      notify: true,
                    });
                  });
                })
                .catch(function (error) {
                  $rootScope.$broadcast('dataLoadingFinished');
                  DialogService.message(error);
                });
            });

            // var cntr = 0;
            //
            // function next() {
            //     if (cntr < resetList.length) {
            //         resetService(resetList[cntr++], function () {
            //             next();
            //         });
            //     } else {
            //         $http.get('/api/service/services/templates').then(function (response) {
            //             addServices(_(response.data).filter({published: true}).map('id'));
            //         });
            //     }
            // }
            //
            // next();
          },
          function () {},
        );
      };

      $scope.expandAllServiceGroups = function (status) {
        $scope.filter.expanded = typeof status === 'undefined' ? !$scope.filter.expanded : status;
        $scope.serviceGroups.forEach(function (serviceGroup) {
          serviceGroup.expanded = $scope.filter.expanded;
          $scope.serviceGroupsStatus[serviceGroup.id] = serviceGroup.expanded;
        });
        LocalStorage.setObject('serviceGroupsStatus', $scope.serviceGroupsStatus);
      };

      $scope.changeExpandedServiceGroup = function (serviceGroup) {
        serviceGroup.expanded = !serviceGroup.expanded;
        $scope.serviceGroupsStatus[serviceGroup.id] = serviceGroup.expanded;
        LocalStorage.setObject('serviceGroupsStatus', $scope.serviceGroupsStatus);
      };

      $scope.editServiceGroup = function (serviceGroup) {
        if (!serviceGroup) {
          serviceGroup = {
            VendorId: CurrentUser.getUser().Company.id,
            template: $scope.isTemplates,
          };
        }
        if ($scope.canEditServiceGroup(serviceGroup)) {
          var modalScope = $rootScope.$new();
          $scope.selectedGroup = serviceGroup;
          modalScope.modalInstance = $modal.open({
            templateUrl: '/templates/academy/manage/servicegroup-edit.html',
            controller: 'AcademyServiceGroupEditController',
            scope: modalScope,
            backdrop: 'static',
            size: 'lg',
            resolve: {
              servicegroup: function () {
                return angular.copy(serviceGroup);
              },
            },
          });

          modalScope.modalInstance.result.then(
            function (serviceGroup) {
              if ($scope.selectedGroup && $scope.selectedGroup.id) {
                _.extend($scope.selectedGroup, serviceGroup);
              } else {
                serviceGroup.expanded = true;
                $scope.serviceGroups.unshift(serviceGroup);
                $scope.serviceGroupsStatus[serviceGroup.id] = serviceGroup.expanded;
                LocalStorage.setObject('serviceGroupsStatus', $scope.serviceGroupsStatus);
              }
            },
            function () {},
          );
        }
      };
      $scope.deleteServiceGroup = function (serviceGroup) {
        if ($scope.canDeleteServiceGroup(serviceGroup)) {
          $rootScope.$broadcast('dataLoadingStarted');
          $http.delete('/api/service/servicegroups/' + serviceGroup.id).then(function () {
            _.remove($scope.serviceGroups, { id: serviceGroup.id });
            $rootScope.$broadcast('dataLoadingFinished');
          });
        }
      };

      $scope.addServiceModal = function (serviceGroup) {
        var modalInstance = $modal.open({
          templateUrl: '/templates/academy/add-service-dialog.html',
          controller: 'AcademyServiceGroupAddServiceController',
          backdrop: 'static',
          size: 'md',
          resolve: {
            serviceTemplates: function ($stateParams, HttpService) {
              return HttpService.get('/api/service/services/templates');
            },
            services: function () {
              return services;
            },
            serviceGroup: function () {
              return serviceGroup;
            },
          },
        });

        modalInstance.result.then(
          function (selectedService) {
            if (selectedService) {
              addServices([selectedService.id]);
            }
          },
          function () {},
        );
      };
      $scope.addAllSelected = function () {
        var selectedTemplates = _.filter(_.flatten(_.map($scope.serviceGroups, 'Services')), {
          selected: true,
        });
        addServices(_.map(selectedTemplates, 'id'), function () {
          DialogService.message($translate.instant('message.academy.serviceAdded'));
        });
      };
      $scope.addCatService = function (service) {
        addServices([service.id], function () {
          DialogService.message($translate.instant('message.academy.serviceAdded'));
        });
      };

      // $scope.serviceOperations = [{
      //     operation: $scope.editService,
      //     label: 'button.edit',
      //     icon: 'edit',
      //     condition: $scope.canEditService
      // }, {
      //     operation: $scope.editServiceTemplate,
      //     label: 'button.edit',
      //     icon: 'edit',
      //     condition: $scope.canEditServiceTemplate
      // }, {
      //     operation: $scope.deleteService,
      //     label: 'button.delete',
      //     icon: 'minus-square-o',
      //     condition: $scope.canDeleteService,
      //     confirmation: true
      // }, {
      //     operation: $scope.copyService,
      //     label: 'button.copy',
      //     icon: 'copy',
      //     condition: $scope.canEditService
      // }, {
      //     operation: $scope.copyServiceTemplate,
      //     label: 'button.copy',
      //     icon: 'copy',
      //     condition: $scope.canEditServiceTemplate
      // }, {
      //     operation: $scope.addCatService,
      //     label: 'button.services.addToCatalogue',
      //     icon: 'copy',
      //     condition: $scope.canAddCatService
      // }, {
      //     operation: $scope.resetService,
      //     label: 'button.update',
      //     icon: 'retweet',
      //     condition: $scope.canResetService
      // }];
      // $scope.serviceGroupOperations = [{
      //     operation: $scope.editServiceGroup,
      //     label: 'button.edit',
      //     icon: 'edit',
      //     condition: $scope.canEditServiceGroup
      // }, {
      //     operation: $scope.deleteServiceGroup,
      //     label: 'button.delete',
      //     icon: 'minus-square-o',
      //     condition: $scope.canDeleteServiceGroup,
      //     confirmation: true
      // }, {
      //     operation: $scope.addServiceModal,
      //     label: 'label.services.addFromLibrary',
      //     icon: 'copy',
      //     condition: $scope.canEditServiceGroup
      // }, {
      //     operation: $scope.inlineAddService,
      //     label: 'button.addService',
      //     icon: 'plus',
      //     condition: $scope.canAddInlineService
      // }];
      // function deleteService(service, cb) {
      //     $http.delete('/api/service/services/' + service.id)
      //         .then(function () {
      //             var group = _.find($scope.serviceGroups, {id: service.ServiceGroupId});
      //             if (group) {
      //                 _.remove(group.Services, {id: service.id});
      //             }
      //             if (cb) {
      //                 cb();
      //             }
      //         }, function (error) {
      //             DialogService.error(error);
      //             console.error(error);
      //             if (cb) {
      //                 cb(error);
      //             }
      //         });
      // }
      //
      // function resetService(service, cb) {
      //
      //     if (service.TemplateId) {
      //         $http.get('/api/service/services/' + service.TemplateId)
      //             .then(function (response) {
      //                 var serviceTemplate = _.pick(response.data, ['name', 'goal', 'hoursToComplete', 'description',
      //                     'blueprint', 'valueProposition', 'explanation', 'deliverable', 'type', 'cycle', 'participants',
      //                     'price', 'unitType', 'unitPrice', 'skills', 'usedSystems', 'hintsSelling', 'hintsMarket',
      //                     'graders', 'ebooks', 'emailSequences', 'steps', 'resources', 'notes', 'todoLists', 'attachments',
      //                     'video', 'playlist', 'marketingAssets']);
      //                 // if (!response.data.published) {
      //                 //     deleteService(service, function (error) {
      //                 //         if (cb) {
      //                 //             cb(error);
      //                 //         }
      //                 //     });
      //                 // } else {
      //                 service = _.extend(service, serviceTemplate);
      //                 service.force = true;
      //                 saveService(service, function (error) {
      //                     delete service.force;
      //                     if (cb) {
      //                         cb(error);
      //                     }
      //                 });
      //                 // }
      //             }, function (error) {
      //                 DialogService.error(error);
      //                 console.error(error);
      //                 if (cb) {
      //                     cb();
      //                 }
      //             });
      //     } else {
      //         if (cb) {
      //             cb();
      //         }
      //     }
      // }
      // $scope.serviceGroupInlineEditClick = function (serviceGroup) {
      //     serviceGroup.history = {name: serviceGroup.name};
      //     serviceGroup.isEdit = true;
      // };
      // $scope.serviceGroupInlineSaveClick = function (serviceGroup) {
      //     saveServiceGroup(serviceGroup, function (error) {
      //         if (error !== null) {
      //             console.error(error);
      //             $scope.serviceGroupInlineCancelClick(serviceGroup);
      //         } else {
      //             serviceGroup.isEdit = false;
      //         }
      //     });
      // };
      // $scope.serviceGroupInlineCancelClick = function (serviceGroup) {
      //     serviceGroup.name = serviceGroup.history.name;
      //     serviceGroup.isEdit = false;
      // };
      // $scope.canAddCatService = function (service) {
      //     return $scope.type == 'templates' && !service.inCatalogue;
      // };
    },
  )

  .controller(
    'AcademyServiceGroupAddServiceController',
    function ($scope, $modalInstance, _, AcademyService, serviceTemplates, services, serviceGroup) {
      $scope.filter = {};
      $scope.values = _.filter(serviceTemplates, function (serviceTemplate) {
        return (
          !_.find(services, { TemplateId: serviceTemplate.id }) &&
          serviceTemplate.ServiceGroupId == serviceGroup.TemplateId
        );
      });

      $scope.ok = function (cb) {
        if ($scope.selected) {
          $modalInstance.close($scope.selected);
        } else {
          cb();
        }
      };

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };

      $scope.select = function (item) {
        if ($scope.selected == item) {
          $scope.selected = undefined;
        } else {
          $scope.selected = item;
        }
      };

      $scope.nameFilter = function (item) {
        return (
          !$scope.filter.name ||
          item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1
        );
      };
      $scope.vendorFilter = AcademyService.vendorFilter;
      $scope.publishedFilter = function (item) {
        return !!item.published;
      };
    },
  )

  .controller(
    'AcademyServiceGroupsController',
    function (
      $scope,
      $state,
      $rootScope,
      $http,
      $modal,
      $translate,
      _,
      CurrentUser,
      AcademyService,
      DialogService,
    ) {
      $scope.serviceGroups = [];
      $scope.filter = { type: 'any' };
      $scope.selectedDiscipline = '';
      //$scope.disciplines = [];
      $scope.serviceTypes = ['any', 'onetime', 'ongoing'];
      $scope.user = CurrentUser.getUser();

      // $scope.$watch('filter.onlyMyServices', function (newValue) {
      //     if (newValue) {
      //         AcademyService.setVendor($scope.user.Company);
      //     } else if (newValue === false) {
      //         AcademyService.setVendor(undefined);
      //     }
      // });

      //$rootScope.$broadcast('vendorChanged', vendor);
      $rootScope.$on('vendorChanged', function ($event, data) {
        if (!data || data.id != $scope.user.Company.id) {
          $scope.filter.onlyMyServices = false;
        }
      });

      $scope.getServicePopover = AcademyService.getServicePopover;

      $scope.refresh = function () {
        //$http.get('/api/lists/discipline')
        //    .success(function (disciplines) {
        $http.get('/api/service/servicegroups').then(function (response) {
          $scope.serviceGroups = response.data;
          $scope.serviceGroups.forEach(function (serviceGroup) {
            serviceGroup.expanded = true;
          });
          //disciplines.forEach(function (discipline) {
          //var disciplineServiceGroups = [];
          //serviceGroups.forEach(function (serviceGroup) {
          //    if (serviceGroup.discipline === discipline) {
          //        disciplineServiceGroups.push(serviceGroup);
          //    }
          //})

          //$scope.disciplines.push({
          //    code: discipline,
          //    serviceGroups: disciplineServiceGroups //_.filter(serviceGroups, {discipline: discipline})
          //});
          //})
        });
        //})
      };

      //$scope.getColor = AcademyService.getColor;
      // $scope.publishedFilter = AcademyService.publishedFilter;
      $scope.vendorFilter = AcademyService.vendorFilter;

      $scope.nameFilter = function (item) {
        return (
          !$scope.filter.name ||
          (item.name && item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1) ||
          (item.description &&
            item.description.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1) ||
          (item.Services &&
            _.find(item.Services, function (service) {
              return (
                service.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1 ||
                (service.description &&
                  service.description.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1)
              );
            }))
        );
      };

      $scope.typeFilter = function (item) {
        return (
          $scope.filter.type === 'any' || item.type === $scope.filter.type || item.type === 'any'
        );
      };
      //$scope.disciplineFilter = function (item) {
      //    return !$scope.selectedDiscipline || item.discipline === $scope.selectedDiscipline || item.code === $scope.selectedDiscipline;
      //}

      $scope.canEditService = function (service) {
        return (
          !service ||
          !service.VendorId ||
          (CurrentUser.can('editService') && CurrentUser.getUser().Company.id == service.VendorId)
        );
      };

      $scope.editService = function (service) {
        if ($scope.canEditService(service)) {
          $state.go('service.manageservice', { id: service ? service.id : 'new' });
          // var modalScope = $rootScope.$new();
          // $scope.selected = service;
          // modalScope.modalInstance = $modal.open({
          //     templateUrl: '/templates/academy/manage/service-edit.html',
          //     controller: 'AcademyServiceEditController',
          //     scope: modalScope,
          //     backdrop: 'static',
          //     size: 'lg',
          //     resolve: {
          //         service: function () {
          //             return angular.copy(service)
          //         }
          //     }
          // });
          //
          // modalScope.modalInstance.result.then(function (service) {
          //     if ($scope.selected && $scope.selected.id) {
          //         angular.copy(service, $scope.selected);
          //         if ($scope.selected.VendorId == $scope.user.Company.id) {
          //             $scope.selected.Vendor = $scope.user.Company;
          //         }
          //     } else {
          //         // $scope.services.push(service);
          //         var group = _.find($scope.serviceGroups, {id: service.ServiceGroupId});
          //         if (group) {
          //             group.Services.push(service);
          //         }
          //
          //     }
          // }, function () {
          // });
        }
      };

      $scope.createService = function () {
        $scope.editService();
      };

      $scope.copyService = function (_service) {
        $state.go('service.serviCecopy', { serviceId: _service.id });
        // var service = angular.copy(_service);
        // delete service.id;
        // delete service.CreatedById;
        // delete service.UpdatedById;
        // delete service.VendorId;
        // service.published = false;
        // $scope.editService(service);
      };

      $scope.deleteService = function (service) {
        if ($scope.canEditService(service)) {
          $rootScope.$broadcast('dataLoadingStarted');
          $http
            .delete('/api/service/services/' + service.id)
            .success(function () {
              var group = _.find($scope.serviceGroups, { id: service.ServiceGroupId });
              if (group) {
                _.remove(group.Services, { id: service.id });
              }
            })
            .error(function (error) {
              $rootScope.$broadcast('dataLoadingFinished');
              DialogService.error(error);
              console.error(error);
            });
        }
      };

      $scope.refresh();
    },
  )

  .controller(
    'AcademyServiceGroupController',
    function ($scope, $stateParams, $modal, _, CurrentUser, AcademyService, serviceGroup) {
      $scope.serviceGroup = serviceGroup;

      // $scope.getColor = AcademyService.getColor;
      $scope.publishedFilter = AcademyService.publishedFilter;

      $scope.canEdit = function () {
        return (
          CurrentUser.can('createServiceGroup') &&
          $scope.serviceGroup.VendorId === CurrentUser.getUser().Company.id
        );
      };

      $scope.edit = function () {
        AcademyService.edit('serviceGroup', $scope.serviceGroup, $modal, function (serviceGroup) {
          angular.copy(serviceGroup, $scope.serviceGroup);
        });
      };
    },
  )

  .controller(
    'AcademyServiceEditController',
    function (
      $scope,
      $rootScope,
      $state,
      $modal,
      $http,
      _,
      AcademyService,
      /*CourseModel, ProjectModel, */ CurrentUser,
      HttpService,
      service,
      copy,
      template,
    ) {
      $scope.service = service || {
        steps: [],
        published: false,
        VendorId: CurrentUser.getUser().Company.id,
        template: template,
      };
      if (copy) {
        if (!template) {
          $scope.service.TemplateId = $scope.service.id;
        }
        $scope.copy = true;
        delete $scope.service.id;
        delete $scope.service.CreatedById;
        delete $scope.service.UpdatedById;
        $scope.service.VendorId = CurrentUser.getUser().Company.id;
        $scope.service.Vendor = CurrentUser.getUser().Company;
        $scope.service.published = true;
        $scope.service.name = service.name;
        $scope.service.template = template;
      }

      $scope.isTemplate = $scope.service.template;
      $scope.currentUser = CurrentUser.getUser();
      var modalInstance = $scope.modalInstance;

      if ($state.$current.name === 'service.serviceEdit') {
        $scope.serviceGroup = service.ServiceGroup;
        //console.log(service);
      }

      if (!$scope.isTemplate) {
        $http.get('/api/service/services/templates').then(
          function (response) {
            $scope.templates = (response.data || []).sort(function (a, b) {
              return a.name.localeCompare(b.name);
            });
          },
          function (response) {
            console.error(response);
          },
        );
        $http.get('/api/service/servicegroups').then(
          function (response) {
            $scope.serviceGroups = response.data;
            if ($scope.service.ServiceGroup) {
              var UserServiceGroup = _.find($scope.serviceGroups, {
                TemplateId: $scope.service.ServiceGroup.id,
              });
              if (UserServiceGroup) {
                $scope.service.ServiceGroupId = UserServiceGroup.id;
              }
            }
          },
          function (response) {
            console.error(response);
          },
        );
      } else {
        $http.get('/api/service/servicegroups/templates').then(
          function (response) {
            $scope.serviceGroups = _.filter(response.data, {
              VendorId: $scope.currentUser.Company.id,
            });
          },
          function (response) {
            console.error(response);
          },
        );
      }

      if ($scope.service.VendorId != CurrentUser.getUser().Company.id) {
        AcademyService.goBack('services');
        return false;
      }

      $scope.errors = [];
      $scope.currentUser = CurrentUser.getUser();
      $scope.serviceGroups = [];
      $scope.marketingAssets = { graders: [], emailSequences: [], ebooks: [] };
      $scope.stepExampleUrls = [];
      $scope.serviceTypes = $scope.service.id
        ? $scope.service.type !== 'any'
          ? ['any', $scope.service.type]
          : [$scope.service.type]
        : ['any', 'onetime', 'ongoing'];
      $scope.serviceCycles = ['', 'na', 'annual', 'quarterly', 'monthly', 'weekly'];
      _.forEach($scope.service.steps, function (step) {
        step.exampleUrl =
          '/images/academy/services/' +
          $scope.service.id +
          '/examples/' +
          step.orderId +
          '?' +
          new Date().getTime();
      });
      $scope.newAsset = {
        graders: {},
        emailSequences: {},
        ebooks: {},
      };

      $scope.expanded = {
        notes: true,
        todoLists: true,
        attachments: true,
      };
      if (!$scope.service.id) {
        $scope.expanded.sales = true;
      }

      // $scope.service.marketingAssets = $scope.service.marketingAssets || {};
      // $scope.service.marketingAssets.graders = $scope.service.marketingAssets.graders || [];
      // $scope.service.marketingAssets.emailSequences = $scope.service.marketingAssets.emailSequences || [];
      // $scope.service.marketingAssets.ebooks = $scope.service.marketingAssets.ebooks || [];

      $scope.courses = []; //CourseModel.query();
      $scope.projects = []; //ProjectModel.query();
      $http.get('/api/graders' + ($scope.isTemplate ? '?type=template' : '')).then(
        function (response) {
          $scope.marketingAssets.graders = _.map(response.data, function (item) {
            return { id: item.id, name: item.name };
          });
        },
        function (response) {
          console.error(response);
        },
      );
      $http.get('/api/emailsequences' + ($scope.isTemplate ? '?template=true' : '')).then(
        function (response) {
          $scope.marketingAssets.emailSequences = _.map(response.data, function (item) {
            return { id: item.id, name: item.name };
          });
        },
        function (response) {
          console.error(response);
        },
      );
      $http.get('/api/companies/' + $scope.currentUser.Company.id + '/files').then(
        function (response) {
          $scope.marketingAssets.ebooks = _.map(response.data, function (item) {
            return { id: item.id, name: item.name };
          });
        },
        function (response) {
          console.error(response);
        },
      );

      $scope.hasModule = CurrentUser.hasModule;

      $scope.addAsset = function (asset, type) {
        if (!$scope.service.marketingAssets) {
          $scope.service.marketingAssets = {};
        }
        if (!$scope.service.marketingAssets[type]) {
          $scope.service.marketingAssets[type] = [];
        }
        if (asset && asset.id && !_.find($scope.service.marketingAssets[type], { id: asset.id })) {
          $scope.service.marketingAssets[type].push(asset);
        }
        $scope.newAsset[type] = undefined;
      };

      function saveService(cb) {
        var req;
        if ($scope.service.id) {
          req = HttpService.put('/api/service/services', { service: $scope.service });
        } else {
          req = HttpService.post('/api/service/services', { service: $scope.service });
        }
        req
          .then(function (result) {
            if (modalInstance) {
              modalInstance.close(_.extend($scope.service, { id: result.id }));
            } else {
              if (!$scope.service.id) {
                if ($scope.service.template) {
                  delete $scope.service.TemplateId;
                }
                cb();
                $state.go('service.serviceEdit', { serviceId: result.id });
              } else {
                $scope.messages = ['message.saved'];
                cb();
              }
              $scope.serviceTypes = $scope.service.id
                ? $scope.service.type !== 'any'
                  ? ['any', $scope.service.type]
                  : [$scope.service.type]
                : ['any', 'onetime', 'ongoing'];
            }
          })
          .catch(function (error) {
            console.error(error);
            $scope.errors = [error];
            cb();
          });
      }

      $scope.save = function (cb) {
        _.forEach($scope.service.steps, function (step) {
          delete step.expanded;
          delete step.exampleUrl;
          delete step.course;
          if (step.CourseId === '') {
            step.CourseId = null;
          }
        });
        if ($scope.service.CourseId === '') {
          $scope.service.CourseId = null;
        }
        if ($scope.service.ProjectId === '') {
          $scope.service.ProjectId = null;
        }
        saveService(cb);
      };

      $scope.cancel = function () {
        if (modalInstance) {
          modalInstance.dismiss();
        } else if ($state.$current.name === 'service.serviceCopy') {
          AcademyService.goBack('serviceCopy');
        } else {
          AcademyService.goBack($scope.isTemplate ? 'servicetemplates' : 'services');
        }
      };

      $scope.addStep = function () {
        if (!$scope.service.steps) $scope.service.steps = [];
        $scope.service.steps.push({
          orderId: $scope.service.steps.length + 1,
          todo: [],
        });
      };

      $scope.addTodoList = function () {
        if (!$scope.service.todoLists) {
          $scope.service.todoLists = [];
        }
        $scope.service.todoLists.push({
          orderId: $scope.service.todoLists.length + 1,
          todo: [],
        });
      };

      $scope.addTask = function (step) {
        if (!step.todo) {
          step.todo = [];
        }
        step.todo.push({
          text: '',
          orderId: step.todo.length + 1,
        });
      };

      $scope.moveUp = AcademyService.moveUp;

      $scope.moveDown = AcademyService.moveDown;

      $scope.remove = AcademyService.remove;

      $scope.addNote = function () {
        if (!$scope.service.notes) {
          $scope.service.notes = [];
        }
        $scope.service.notes.push({});
      };

      $scope.removeFile = function (doc) {
        _.remove($scope.service.attachments, { id: doc.id });
      };

      $scope.openFileSelect = function () {
        var _modalInstance = $modal.open({
          templateUrl: '/templates/msp/file-select.html',
          controller: 'FileSelectController',
          backdrop: 'static',
          size: 'lg',
        });

        _modalInstance.result.then(
          function (selectedFile) {
            if (!$scope.service.attachments) {
              $scope.service.attachments = [];
            }
            if (!_.find($scope.service.attachments, { id: selectedFile.id })) {
              $scope.service.attachments.push(selectedFile);
            }
          },
          function () {},
        );
      };

      $scope.openPreview = function () {
        if ($scope.service.id) {
          window.open('/#/academy/services/' + $scope.service.id);
        }
      };
    },
  )

  .controller(
    'AcademyServiceController',
    function (
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $modal,
      $http,
      $sce,
      $translate,
      $q,
      _,
      AcademyService,
      CurrentUser,
      DialogService,
      HttpService,
      service,
      /*courses, */ surveys,
    ) {
      $scope.currentUser = $scope.currentUser || CurrentUser.getUser();
      $scope.hasModule = CurrentUser.hasModule;
      $scope.service = service;
      $scope.surveys = surveys;
      $scope.courses = []; //courses;

      $scope.serviceQuality = AcademyService.serviceQuality;

      if ($scope.service.steps && $scope.courses.length > 0) {
        if ($scope.service && $scope.service.steps) {
          $scope.service.courses = [];
          $scope.service.steps.forEach(function (step) {
            if (step.ExampleImageId) {
              step.exampleUrl =
                '/images/academy/services/' + $scope.service.id + '/examples/' + step.orderId;
            }
            if (step.CourseId && !_.find($scope.service.courses, { id: parseInt(step.CourseId) })) {
              step.course = _.find($scope.courses, { id: parseInt(step.CourseId) });
              $scope.service.courses.push(step.course);
            }
          });
        }
      }

      if ($scope.service.blueprint) {
        $scope.service.blueprintUrl = $sce.trustAsResourceUrl(
          'https://www.lucidchart.com/documents/embeddedchart/' + $scope.service.blueprint,
        );
      }
      if ($scope.service.valueProposition) {
        $scope.service.valuePropositionUrl = $sce.trustAsResourceUrl(
          'https://www.lucidchart.com/documents/embeddedchart/' + $scope.service.valueProposition,
        );
      }

      if ($scope.service.description) {
        $scope.service.$$descriptionTrustedHtml = $sce.trustAsHtml($scope.service.description);
      }

      $scope.canEdit = function () {
        return (
          CurrentUser.can('editService') &&
          $scope.service.VendorId === CurrentUser.getUser().Company.id
        );
      };

      $scope.edit = function () {
        $state.go('service.serviceEdit', { serviceId: $scope.service.id });
        // AcademyService.edit('serviceEdit', $scope.service, $modal, function (service) {
        //     angular.copy(service, $scope.service);
        // })
      };

      // $scope.suggestImprovement = function () {
      //     if (typeof window.Intercom != 'undefined') {
      //         window.Intercom('showNewMessage', $translate.instant('message.suggestImprovementService', {serviceName: $scope.service.name}));
      //     }
      // };

      AcademyService.embedWistia($scope.service.video, $scope.service.playlist);

      $scope.can = function (right) {
        return CurrentUser.can(right);
      };

      $scope.getLabel = function (d) {
        return '<span translate="label.' + d + '"></span>';
      };

      $scope.sendToVcio = function (system) {
        var modalInstance = $modal.open({
          templateUrl: '/templates/vcio/service-to-vcio.html',
          controller: 'VcioServiceToVcioController',
          backdrop: 'static',
          //size: 'lg',
          resolve: {
            service: function () {
              return $scope.service;
            },
            system: function () {
              return system;
            },
          },
        });

        modalInstance.result.then(
          function () {},
          function () {},
        );
      };

      $scope.showDocument = function (note) {
        var modalInstance = $modal.open({
          template:
            '<modal-form form-title="{{note.name}}" onok="ok" label-ok="button.close"><div class="row"><div class="col-md-12"><span ng-bind-html="note.text"></span></div></div></modal-form>',
          controller: function ($scope, $modalInstance, note) {
            $scope.note = note;
            $scope.ok = function () {
              $modalInstance.close();
            };
          },
          backdrop: 'static',
          size: 'lg',
          resolve: {
            note: function () {
              return note;
            },
          },
        });

        modalInstance.result.then(
          function (consultation) {},
          function () {},
        );
      };
    },
  );
