angular
  .module('vcio-toolkit')

  .controller('HelpController', function ($scope, $rootScope, help) {
    $scope.help = help;

    //$rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams, $modal) {
    //    $modalInstance.close();
    //})
    //$scope.close = function(){
    //    $modalInstance.close();
    //}
    //$scope.cancel = function(){
    //    $modalInstance.close();
    //}
  })

  .controller(
    'HelpManageController',
    function ($rootScope, $scope, $state, $stateParams, _, DialogService, HttpService, helps) {
      $scope.helpList = helps;
      $scope.filter = {};
      $scope.newHelp = {};

      HttpService.get('/api/vendors?all=true').then(function (vendors) {
        $scope.vendors = vendors;
      });

      HttpService.get('/api/marketplace/permissiongroups?hidden=true').then(function (
        permissionGroups,
      ) {
        $scope.permissionGroups = permissionGroups;
      });

      $scope.permissionGroupFilter = function (item) {
        return !$scope.newHelp.VendorId || item.VendorId == $scope.newHelp.VendorId;
      };

      // $scope.$watch('newHelp.VendorId', function () {
      //
      // })

      $scope.createHelp = function () {
        _.forEach($scope.helpList, function (help) {
          help.isEdit = false;
        });
        $scope.newHelp = { expanded: true };
      };

      $scope.save = function (help, cb) {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.post('/api/help', { help: help }).then(
          function (result) {
            if (help.id) {
              help.isEdit = false;
            } else {
              $scope.helpList.push(result);
              $scope.newHelp = {};
            }
            $rootScope.$broadcast('dataLoadingFinished');
            if (cb) cb();
          },
          function (error) {
            if (cb) cb();
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
            console.error(error);
          },
        );
      };

      $scope.cancel = function (help) {
        if (help.id) {
          help.isEdit = false;
          _.forEach(help.oldValues, function (v, k) {
            help[k] = v;
          });
          delete help.oldValues;
        } else {
          $scope.help = {};
        }
      };

      $scope.nameFilter = function (item) {
        return (
          !$scope.filter.name ||
          (item.name && item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1) ||
          (item.url && item.url.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1) ||
          (item.value && item.value.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1)
        );
      };

      $scope.editHelp = function (help) {
        $scope.newHelp = help;
        help.oldValues = _.cloneDeep(help);
        help.isEdit = true;
      };

      $scope.deleteHelp = function (help) {
        HttpService.delete('/api/help/' + help.id).then(
          function () {
            _.remove($scope.helpList, function (item) {
              return item.id == help.id;
            });
          },
          function (error) {
            console.error(error);
          },
        );
      };
    },
  );
