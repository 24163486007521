angular.module('vcio-toolkit');

// .controller('SalesNavController', function ($scope, $rootScope, $state, $stateParams, $location, $http, $translate, _, CurrentUser) {
//     $scope.isCollapsed = true;
//     $scope.user = CurrentUser.getUser();
//     $scope.isConnectwise = !!$rootScope.connectWiseClientApi;
//
//     $scope.can = function (right) {
//         return CurrentUser.can(right);
//     };
//
//     $scope.getTitle = function () {
//         return $state.$current.data && $state.$current.data.pageTitle ? $translate.instant($state.$current.data.pageTitle) : '';
//     }
//
// })
//
//     .controller('SalesController', function ($scope, $rootScope, $state, $stateParams, $translate, $http, $modal, _, UploadService, LocalStorage, surveyResultService, clients, surveyResults) {
//         $scope.filter = {clientName: '', surveyStatus: ''};
//         $scope.clients = clients;
//         if ($stateParams.clientId) {
//             var client = _.find($scope.clients, {id: parseInt($stateParams.clientId)});
//             if (client) {
//                 LocalStorage.set('clientIdFilter', client.id);
//                 $state.go('sales.home');
//             }
//         }
//         //$scope.contacts = contacts;
//         $scope.surveyResults = surveyResults;
//         $scope.emptyStatistics = {
//             surveyResults: [{key: 'total', value: 0}, {key: 'draft', value: 0}, {
//                 key: 'sent',
//                 value: 0
//             }, {key: 'started', value: 0}, {key: 'finished', value: 0}, {key: 'evaluated', value: 0}, {
//                 key: 'archived',
//                 value: 0
//             }],
//             clients: {total: 0}
//         };
//         $scope.statistics = angular.copy($scope.emptyStatistics);
//
//         $scope.launchSurvey = function (client) {
//             $state.go('sales.launchsurvey', {clientId: client.id});
//             // $http.get('/api/surveys').success(function (surveys) {
//             //     $http.get('/api/surveydictionaries').success(function (dictionaries) {
//             //         if (!$scope.selectedClient){
//             //             $scope.selectClient(client);
//             //         }
//             //         var modalInstance = $modal.open({
//             //             templateUrl: '/templates/msp/survey-launch.html',
//             //             controller: 'LaunchSurveyController',
//             //             backdrop: 'static',
//             //             size: 'lg',
//             //             resolve: {
//             //                 client: function () {
//             //                     return $scope.selectedClient;
//             //                 },
//             //                 surveys: function () {
//             //                     return surveys;
//             //                 },
//             //                 dictionaries: function () {
//             //                     return dictionaries;
//             //                 }
//             //             }
//             //         });
//             //
//             //         modalInstance.result.then(function (survey) {
//             //             $scope.sendSurveyData = null;
//             //             $scope.$broadcast('surveyresult-added', survey)
//             //             $scope.messages = ['launch.sent']
//             //
//             //             $scope.refresh();
//             //         });
//             //     })
//             // })
//
//             // var modalInstance;
//             // launchSurveyService.getData(client.id).then(function (data) {
//             //     $scope.sendSurveyData = data;
//             //
//             //     modalInstance = $modal.open({
//             //         templateUrl: '/templates/msp/survey-launch.html',
//             //         controller: 'LaunchSurveyController',
//             //         backdrop: 'static',
//             //         size: 'lg',
//             //         resolve: {
//             //             client: function () {
//             //                 return $scope.sendSurveyData
//             //             }
//             //         }
//             //     });
//             //
//             //     modalInstance.result.then(function (survey) {
//             //         $scope.sendSurveyData = null;
//             //         $scope.$broadcast('surveyresult-added', survey)
//             //         $scope.messages = ['launch.sent']
//             //
//             //         $scope.refresh();
//             //     });
//             // })
//         };
//
//         $scope.addClientStat = function (client, surveyResult) {
//             client.statistics.surveyResults.total++;
//             if (client.statistics.surveyResults.hasOwnProperty(surveyResult.status)) {
//                 client.statistics.surveyResults[surveyResult.status] = client.statistics.surveyResults[surveyResult.status] + 1;
//             } else {
//                 client.statistics.surveyResults[surveyResult.status] = 1;
//             }
//             $scope.statistics.clients.total++;
//             $scope.addStat(surveyResult)
//         };
//
//         $scope.addStat = function (surveyResult) {
//             _.find($scope.statistics.surveyResults, {key: 'total'}).value++;
//             _.find($scope.statistics.surveyResults, {key: surveyResult.status}).value++;
//         };
//
//         $scope.statuses = surveyResultService.statuses;
//         $scope.getClassByStatus = surveyResultService.getClassByStatus;
//         $scope.getIconByStatus = surveyResultService.getIconByStatus;
//
//         //$scope.$watch('clients', function (clients) {
//         //    clients.forEach(function (client) {
//         //        $http.get('/api/clients/' + client.id + '/image')
//         //            .success(function (image) {
//         //                client.logo = image;
//         //            })
//         //    })
//         //})
//
//         $scope.refreshData = function (cb) {
//             $http.get('/api/clients/')
//                 .success(function (clients) {
//                     $scope.clients = clients;
//                     surveyResultService.getSurveyResults().then(function (surveyResults) {
//                         $scope.surveyResults = surveyResults;
//                         cb();
//                         //$http.get('/api/contacts/')
//                         //    .success(function (contacts) {
//                         //        $scope.contacts = contacts;
//                         //        cb();
//                         //    })
//                     })
//                 })
//         };
//
//         $scope.refreshScreen = function () {
//             $scope.statistics = angular.copy($scope.emptyStatistics);
//
//             //TODO filter on the server side
//             $scope.clients.forEach(function (client) {
//                 client.statistics = {
//                     surveyResults: {total: 0, draft: 0, sent: 0, started: 0, finished: 0, evaluated: 0, archived: 0}
//                 };
//                 $scope.surveyResults.forEach(function (surveyResult) {
//                     if (client.id == surveyResult.ClientId) {
//                         $scope.addClientStat(client, surveyResult);
//                         surveyResult.updatedAt = Date.parse(surveyResult.updatedAt);
//
//                         if (client.latestResult) {
//                             if (surveyResult.updatedAt > client.latestResult.updatedAt) {
//                                 client.latestResult = surveyResult;
//                             }
//                         } else {
//                             client.latestResult = surveyResult;
//                         }
//                     }
//                 });
//                 client.logoUrl = '/images/clients/' + client.id;
//             });
//
//             if (LocalStorage.get('clientNameFilter')) {
//                 $scope.filter.clientName = LocalStorage.get('clientNameFilter');
//                 LocalStorage.delete('clientIdFilter');
//             } else if (LocalStorage.get('clientIdFilter')) {
//                 var id = parseInt(LocalStorage.get('clientIdFilter'));
//                 var client = _.find($scope.clients, {id: id});
//                 if (client) {
//                     $scope.filter.clientId = id;
//                     $scope.selectClient(_.find($scope.clients, {id: id}));
//                 } else {
//                     LocalStorage.delete('clientIdFilter');
//                 }
//             }
//         };
//
//         $scope.$watch('filter.clientName', function (value) {
//             if (value) $scope.removeClientSelection();
//         });
//
//         $scope.filterBySurveyStatus = function (status) {
//             $scope.removeClientSelection();
//             $scope.filter.clientName = '';
//             if (status == 'total') {
//                 $scope.filter.surveyStatus = '';
//             } else {
//                 $scope.filter.surveyStatus = status;
//             }
//         };
//
//         $scope.clientIdFilter = function (item) {
//             return !LocalStorage.get('clientIdFilter') || item.id == LocalStorage.get('clientIdFilter');
//         };
//         $scope.nameFilter = function (item) {
//             if ($scope.filter.clientName === '') {
//                 return true;
//             }
//             if (item.name.toLowerCase().indexOf($scope.filter.clientName.toLowerCase()) > -1) {
//                 return true;
//             }
//
//             if (_.find(item.Contacts, function (contact) {
//                     return (contact.firstName + contact.lastName + contact.email).toLowerCase().indexOf($scope.filter.clientName.toLowerCase()) > -1;
//                 })) {
//                 return true;
//             }
//
//             //var filteredContacts = _.filter($scope.contacts, function (contact) {
//             //    return (contact.firstName + contact.lastName + contact.email).toLowerCase().indexOf($scope.filter.clientName.toLowerCase()) > -1;
//             //})
//             //var found = false;
//             //filteredContacts.forEach(function (contact) {
//             //    contact.Clients.forEach(function (client) {
//             //        if (client.id == item.id) found = true;
//             //    })
//             //})
//             //return found;
//             return false;
//         };
//
//         $scope.contactListFilter = function (item) {
//             return $scope.selectedClient && item.ClientId == $scope.selectedClient.id;
//         };
//
//         $scope.clientSurveyStatusFilter = function (item) {
//             return $scope.filter.surveyStatus === '' ||
//                 (item.latestResult && item.latestResult.status == $scope.filter.surveyStatus)
//         };
//
//         $scope.removeClientSelection = function () {
//             //LocalStorage.delete('clientNameFilter');
//             LocalStorage.delete('clientIdFilter');
//             $scope.selectedClient = null;
//             $scope.$broadcast('client-list-selection-removed');
//         };
//
//         $scope.selectClient = function (client) {
//             if (!client) {
//                 var filtered = _.filter($scope.clients, function (item) {
//                     return $scope.nameFilter(item)
//                 });
//                 if (filtered.length == 1) {
//                     $scope.selectClient(filtered[0]);
//                 }
//             } else {
//                 if ($scope.selectedClient == client) {
//                     $scope.filter.clientName = '';
//                     $scope.removeClientSelection();
//                 } else {
//                     $scope.selectedClient = client;
//                     $scope.$broadcast('client-list-selected', $scope.selectedClient);
//                     //$scope.filter.clientName = client.name;
//                     LocalStorage.set('clientIdFilter', client.id)
//                 }
//             }
//         };
//
//         $scope.deleteClient = function (client) {
//             $http.delete('/api/clients/' + client.id)
//                 .success(function () {
//                     _.remove($scope.clients, function (item) {
//                         return item.id == client.id
//                     });
//                 })
//         };
//
//         $scope.openEditClient = function (client) {
//             if (!client) {
//                 client = {}
//             }
//             var modalScope = $rootScope.$new();
//             modalScope.modalInstance = $modal.open({
//                 templateUrl: '/templates/admin/client-edit.html',
//                 controller: 'ClientEditController',
//                 scope: modalScope,
//                 backdrop: 'static',
//                 //size: 'lg',
//                 resolve: {
//                     client: function () {
//                         return angular.copy(client)
//                     }
//                 }
//             });
//
//             modalScope.modalInstance.result.then(function (client) {
//                 //refresh modified data
// //                angular.copy(client, $scope.selectedClient);
//                 $scope.refreshData(function () {
//                     $scope.refreshScreen();
//                     $scope.selectClient(_.find($scope.clients, {id: client.id}))
//                 });
//
//             }, function () {
//
//             });
//
//             if (LocalStorage.get('clientNameFilter')) {
//                 $scope.filter.clientName = LocalStorage.get('clientNameFilter');
//                 $scope.selectClient(_.find($scope.clients, {name: $scope.filter.clientName}))
//             }
//
//         };
//
//         $scope.openClientLogo = function (client) {
//             UploadService.open('clients', client).then(function(){
//                 client.logoUrl = '/images/clients/' + client.id + '?' + new Date().getTime();
//             });
//         };
//
//         $scope.clientOperations = [
//             {operation: $scope.openEditClient, label: 'button.edit', icon: 'edit'},
//             {operation: $scope.openClientLogo, label: 'button.logo', icon: 'image'},
//             {operation: $scope.deleteClient, label: 'button.delete', icon: 'times-circle', confirmation: true}
//         ];
//
//         $scope.createContact = function () {
//             $scope.openEditContact({status: 'customer'});
//         };
//
//         $scope.openEditContact = function (contact) {
//             $scope.selectedContact = contact;
//
//             var modalInstance = $modal.open({
//                 templateUrl: '/templates/admin/contact-edit.html',
//                 controller: 'ContactEditController',
//                 backdrop: 'static',
//                 resolve: {
//                     contact: function () {
//                         return angular.copy(contact);
//                     },
//                     client: function () {
//                         return $scope.selectedClient;
//                     }
//                 }
//             });
//
//             modalInstance.result.then(function () {
//                 $scope.refresh();
//                 //refresh modified data
//                 //if ($scope.selectedContact.id == contact.id) {
//                 //    angular.copy(contact, $scope.selectedContact);
//                 //} else {
//                 //    var _client = _.find($scope.clients, {id: contact.ClientId});
//                 //    var _contact = _.find(_client.Contacts, {id: contact.id});
//                 //    if (_contact) {
//                 //        angular.copy(contact, _contact);
//                 //    } else {
//                 //        _client.Contacts.push(contact);
//                 //    }
//                 //}
//
//             }, function () {
//
//             });
//         };
//
//         $scope.deleteContact = function (contact) {
//             $http.delete('/api/contacts/' + contact.id)
//                 .success(function () {
//                     var client = _.find($scope.clients, {id: contact.ClientId});
//                     _.remove(client.Contacts, {id: contact.id});
//                 })
//         };
//
//         $scope.contactOperations = [
//             {operation: $scope.openEditContact, label: 'button.edit', icon: 'edit'},
//             {operation: $scope.deleteContact, label: 'button.delete', icon: 'times-circle', confirmation: true}
//         ];
//
//         $scope.refresh = function () {
//             $scope.refreshData(function () {
//                 $scope.refreshScreen()
//             });
//         };
//
//         if ($scope.clients) {
//             $scope.refreshScreen();
//         }
//     });
