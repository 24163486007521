angular
  .module('vcio-toolkit')

  .controller(
    'CommentsController',
    function ($scope, $state, $http, $translate, _, CurrentUser, DialogService) {
      $scope.messages = [];
      $scope.newMessage = '';

      $scope.refresh = function () {
        $scope.newMessage = '';
        $http.get($scope.apiUrl).success(function (messages) {
          $scope.messages = messages;
        });
      };

      $scope.canEdit = function (message) {
        return message && message.CreatedById === CurrentUser.getUser().id;
      };

      $scope.submitMessage = function () {
        if ($scope.newMessage) {
          $http
            .post($scope.apiUrl, { message: $scope.newMessage })
            .success(function (result) {
              // $scope.messages.unshift(result);
              $scope.$emit('commentPosted');
              $scope.refresh();
            })
            .error(function (error) {
              DialogService.error(error);
            });
        }
      };

      $scope.removeMessage = function (message) {
        if (message) {
          $http
            .delete($scope.apiUrl + (!_.endsWith($scope.apiUrl, '/') ? '/' : '') + message.id)
            .success(function (result) {
              _.remove($scope.messages, { id: message.id });
            })
            .error(function (error) {
              DialogService.error(error);
            });
        }
      };

      $scope.editMessage = function (message) {
        message.oldMessage = message.message;
        message.isEditing = true;
      };

      $scope.cancelEditMessage = function (message) {
        message.message = message.oldMessage;
        message.isEditing = false;
      };

      $scope.submitEditMessage = function (message) {
        $http
          .put($scope.apiUrl + (!_.endsWith($scope.apiUrl, '/') ? '/' : '') + message.id, {
            message: message.message,
          })
          .success(function (result) {
            message.oldMessage = undefined;
            message.isEditing = false;
          })
          .error(function (error) {
            DialogService.error(error);
          });
      };

      $scope.refresh();
    },
  );
