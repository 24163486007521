angular
  .module('vcio-toolkit', [
    'ngSanitize',
    'ngCookies',
    'ui.bootstrap',
    'ui.router',
    'ngMessages',
    'pascalprecht.translate', //'ngLodash',
    'tc.chartjs',
    'ngFileUpload',
    'colorpicker.module',
    'angular-momentjs',
    'monospaced.elastic',
    'mwl.calendar',
    'isteven-multi-select',
    'ui.select',
    'clipboardcopy',
    'angular-uuid',
    'ngResource',
    'dndLists',
    'rzModule',
    'ngCsv',
    'froala',
    'ngToast',
    'ui.codemirror',
  ])

  .constant('EMAIL_REGEX', /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)+$/i)

  .run(function (
    $filter,
    $http,
    $interval,
    $locale,
    $location,
    $modal,
    $moment,
    $rootScope,
    $state,
    $templateCache,
    $timeout,
    $translate,
    $urlMatcherFactory,
    $window,
    _,
    AUTH_EVENTS,
    AcademyService,
    Auth,
    CurrentUser,
    ConnectWiseService,
    DialogService,
    LocalStorage,
  ) {
    var currentUser = CurrentUser.getUser();
    $rootScope.CurrentUser = CurrentUser;
    var hubspotShowInterval;
    if (CurrentUser.isAuthenticated()) {
      Auth.refreshToken();
    } else {
      hubspotShowInterval = $interval(function () {
        var HSElement = document.getElementById('hubspot-messages-iframe-container');
        if (HSElement) {
          if (CurrentUser.isAuthenticated()) {
            HSElement.classList.remove('invisible');
            $interval.cancel(hubspotShowInterval);
          } else {
            HSElement.classList.add('invisible');
          }
        }
      }, 3000);
    }

    $rootScope.isEmbedded = params.embedded;
    $rootScope._ = _;
    _.mixin({
      sortKeysBy: function (obj, comparator) {
        var keys = _.sortBy(_.keys(obj), function (key) {
          return comparator ? comparator(obj[key], key) : key;
        });

        return _.zipObject(
          keys,
          _.map(keys, function (key) {
            return obj[key];
          }),
        );
      },
    });

    $rootScope.replaceNewLineCharacters = function (input) {
      return String(input).replace(/[\n]/g, '\\n').replace(/[\r]/g, '\\r');
    };

    $translate.onReady(function () {
      $rootScope.translateReady = true;
    });
    // Auth.startTokenRefresher();

    ConnectWiseService.checkMember();
    var currentParams = null;
    var currentState = _.find($state.get(), function (state) {
      if (!state.url) {
        return false;
      } else {
        currentParams = $urlMatcherFactory.compile(state.url).exec($location.path());
        return currentParams;
      }
    });

    if (currentState) {
      $rootScope.previousState = {
        name: currentState.name,
        params: currentParams,
        search: $location.search(),
      };
    }

    if (typeof delighted != 'undefined' && currentUser && currentUser.Company) {
      delighted.survey({
        email: currentUser.email,
        name: currentUser.firstName + ' ' + currentUser.lastName,
        createdAt: currentUser.createdAt,
        properties: {
          // extra context (optional)
          // plan: "Medium",
          company: currentUser.Company.name,
        },
      });
    }

    // if (typeof inline_manual_player != 'undefined') {
    //     inline_manual_player.setCallbacks({
    //         //onStepShow: function (player, topic_id, step_id) {
    //         //    console.log('Step was displayed.');
    //         //},
    //         onTopicStart: function (player, topicId) {
    //             $http.post('/api/admin/users/' + currentUser.id + '/timeline/tutorialStarted', {
    //                 topicId: topicId,
    //                 steps: player.topics[topicId].steps.length
    //             });
    //         },
    //         onTopicEnd: function (player, topicId, stepId) {
    //             if (player.topics[topicId].steps.length == stepId && player.metadata.topics.end) {
    //                 $http.post('/api/admin/users/' + currentUser.id + '/timeline/tutorialCompleted', {
    //                     topicId: topicId
    //                 });
    //             } else {
    //                 $http.post('/api/admin/users/' + currentUser.id + '/timeline/tutorialEnded', {
    //                     topicId: topicId,
    //                     lastStep: stepId
    //                 });
    //             }
    //         },
    //         onRedirect: function (player, url, target, done) {
    //             var states = $state.get();
    //             for (var i = 0; i < states.length; i++) {
    //                 var state = states[i];
    //                 var rurl = (state.url ? new RegExp('^' + state.url.replace(/\/:[a-zA-Z0-9]*/ig, '\/[a-zA-Z0-9_%]*') + '$') : '');
    //
    //                 if (rurl && rurl.test(url.substr(2))) {
    //                     if (state.data && state.data.access) {
    //                         if (CurrentUser.can(state.data.access)) {
    //                             done(url, target);
    //                         } else {
    //                             DialogService.message('label.unauthorized');
    //                             inline_manual_player.deactivate();
    //                         }
    //                     } else {
    //                         done(url, target);
    //                     }
    //                 }
    //             }
    //         }
    //     });
    // }

    if (typeof profitwell != 'undefined' && currentUser) {
      profitwell('user_email', currentUser.email);
      profitwell('user_id', currentUser.Company.stripeCustomerId);
    }

    // if (typeof smartlook != 'undefined' && currentUser && currentUser.Company) {
    //     smartlook('tag', 'email', currentUser.email);
    //     smartlook('tag', 'name', currentUser.firstName + ' ' + currentUser.lastName);
    //     smartlook('tag', 'company', currentUser.Company.name);
    // }

    //var lang = LocalStorage.get('language') || $window.navigator.userLanguage || $window.navigator.language;
    $translate.use('en');
    if (currentUser && currentUser.createdAt && currentUser.firstName) {
      // if (typeof window.Intercom != 'undefined') {
      //     window.Intercom('boot', {
      //         app_id: params.intercomId,
      //         user_id: currentUser.id,
      //         user_hash: currentUser.intercomHMAC,
      //         name: currentUser.firstName + ' ' + currentUser.lastName,
      //         email: currentUser.email,
      //         created_at: Math.round($moment(currentUser.createdAt).valueOf() / 1000)
      //     });
      // }
      if (typeof window.Appcues != 'undefined') {
        Appcues.identify(currentUser.id, {
          name: currentUser.name,
          email: currentUser.email,
          created_at: currentUser.createdAt,
        });
      }
      // if (typeof analytics != 'undefined') {
      //     analytics.identify(currentUser.id, {
      //         name: currentUser.firstName + ' ' + currentUser.lastName,
      //         email: currentUser.email,
      //         traits: currentUser
      //     }, {
      //         integrations: {
      //             'All': false,
      //             'Appcues': true
      //         }
      //     });
      // }

      $http.post('/api/admin/users/' + currentUser.id + '/timeline/login');
    }

    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
      if (
        fromState.url !== '^' &&
        fromState.url !== '/logout' &&
        !_.startsWith(fromState.url, '/login')
      ) {
        $rootScope.previousState = {
          name: fromState.name,
          params: fromParams,
          search: $location.search(),
        };
      }
      var currentUser = CurrentUser.getUser();
      if (
        !CurrentUser.isAuthenticated() ||
        (currentUser.authType == 'print' &&
          currentUser.enabledUrl != $state.href(toState, toParams))
      ) {
        if (toState.url !== '/logout' && !_.startsWith(toState.url, '/login')) {
          $rootScope.previousState = {
            name: toState.name,
            params: toParams,
            search: $location.search(),
          };
          event.preventDefault();
          $state.go('login');
        }
      } else {
        if (toState.url == '/login') {
          // $state.go('empty');
        } else if (toState.data && toState.data.access && !CurrentUser.can(toState.data.access)) {
          event.preventDefault();
          $state.go('error', {
            errors: [
              {
                name: 'NoModuleAccess',
                code: 'error.noModuleAccess',
                values: [toState.data.access],
              },
            ],
          });
        } else {
          if (
            currentUser.modules.length === 0 &&
            toState.name != 'msp.payment' &&
            toState.name != 'logout' &&
            toState.name != 'error'
          ) {
            event.preventDefault();
            $state.go('msp.payment');
          }
        }
      }
    });

    $rootScope.$on('$stateChangeSuccess', function ($event, $toState, $toParams) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      AcademyService.setOwnerVendor(null);
      // if (typeof window.Intercom != 'undefined') {
      //     window.Intercom('update');
      // }
      if (typeof window.Appcues != 'undefined') {
        window.Appcues.page();
      }
      if (window._hsq) {
        window._hsq.push(['trackPageView']);
      }
      if (CurrentUser && CurrentUser.isAuthenticated()) {
        $http.post('/api/admin/users/' + CurrentUser.getUser().id + '/timeline/pageView', {
          page: $state.href($toState.name, $toParams),
          module: $toState.data ? $toState.data.module : undefined,
          name: $toState.data ? $toState.data.name : undefined,
        });
      }
      // if (typeof analytics != 'undefined') {
      //     analytics.page();
      // }
      // if (typeof Appcues != 'undefined') {
      //     Appcues.start();
      // }
    });

    $rootScope.$on('$locationChangeSuccess', function () {
      if ($window.Appcues) {
        $window.Appcues.start();
      }
    });

    $rootScope.$on(
      '$stateChangeError',
      function ($event, $toState, $toParams, $fromState, $fromParams, error) {
        var errorCode = _.isObject(error)
          ? $translate.instant(error.code, error.translateValues)
          : $translate.instant(error);
        var errorStatus = _.isObject(error) ? error.status : undefined;
        var errorType = _.isObject(error) ? error.name : undefined;
        $event.preventDefault();
        if ((errorCode && errorCode.indexOf('Not found') !== -1) || errorStatus == 404) {
          $event.preventDefault();
          $state.go('404', null, { location: false });
        } else if (errorStatus == 403) {
          $event.preventDefault();
          $state.go('403', null, { location: false });
        } else if (errorType == 'UserError') {
          $event.preventDefault();
          DialogService.error(error);
        } else {
          DialogService.error('error.somethingWentWrong');
        }
      },
    );

    $rootScope.$on(AUTH_EVENTS.notAuthenticated, function () {
      LocalStorage.delete('auth_token');
      LocalStorage.delete('auth_user');
      if (CurrentUser) {
        CurrentUser.clear();
      }
      $state.go('login');
    });

    // create a $scope.$timeout function which terminates on $scope's $destroy event.
    var Scope = Object.getPrototypeOf($rootScope);
    Scope.$timeout = function (func, time) {
      var timer = $timeout(func, time);
      this.$on('$destroy', function () {
        $timeout.cancel(timer);
      });
      return timer;
    };
  })

  .config([
    '$translateProvider',
    function ($translateProvider) {
      //$translateProvider.useLoaderCache(true);
      // $translateProvider.useMessageFormatInterpolation();
      $translateProvider.useUrlLoader('/api/translations');
      $translateProvider.addInterpolation('$translateMessageFormatInterpolation');
      $translateProvider.preferredLanguage('en');
      $translateProvider.fallbackLanguage('en');
    },
  ])

  .config([
    '$httpProvider',
    function ($httpProvider) {
      $httpProvider.defaults.cache = false;
      if (!$httpProvider.defaults.headers.get) {
        $httpProvider.defaults.headers.get = {};
      }
      // disable IE ajax request caching
      // $httpProvider.defaults.headers.get['If-Modified-Since'] = '0';
      $httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
      $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
      $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';

      $httpProvider.defaults.transformResponse.push(function (responseData) {
        parseAllStringToDate(responseData);
        return responseData;
      });
    },
  ])

  .config(function (uiSelectConfig) {
    uiSelectConfig.theme = 'bootstrap';
  })
  .config([
    'ngToastProvider',
    function (ngToast) {
      ngToast.configure({
        verticalPosition: 'top',
        horizontalPosition: 'center',
        maxNumber: 1,
      });
    },
  ])

  .factory('_', function () {
    return window._;
  })

  .factory('$libphonenumber', function () {
    return window.libphonenumber.parsePhoneNumber;
  })

  // .constant('_', window._)

  .factory(
    'UserEventService',
    function ($rootScope, HttpService, CurrentUser, InputSanitizerService) {
      return {
        event: function (name, args) {
          return HttpService.post(
            '/api/admin/users/' +
              InputSanitizerService.sanitize(CurrentUser.getUser().id) +
              '/timeline/' +
              InputSanitizerService.sanitize(name),
            args,
          );
        },
      };
    },
  )

  .factory(
    'ConnectWiseService',
    function ($state, $rootScope, $http, $timeout, _, DialogService, CurrentUser) {
      if (!$rootScope.connectWiseClientApi && params.embedded && params.origin) {
        $rootScope.connectWiseClientApi = new ConnectWiseHostedAPI(params.origin, {
          eventHandlers: [
            {
              event: 'onLoad',
              callback: function (data) {
                $rootScope.connectWiseScreen = data.screenObject;
                $rootScope.$broadcast('ConnectWiseScreenChanged', data.screenObject);
                // console.log(data);
                checkMember();
              },
            },
          ],
        });
      }

      var checkMember = function () {
        if ($rootScope.connectWiseClientApi) {
          var currentUser = CurrentUser.getUser();
          $rootScope.connectWiseClientApi.post(
            { request: 'getMemberAuthentication' },
            function (data) {
              // {"site":"https://staging.connectwisedev.com/", "companyid":"reframe_f", "memberid":"Admin1", "memberHash":"050c7216-483b-41db-9f87-5c1fdb1dfdb7"}
              if (data.memberEmail && data.memberEmail.toLowerCase() != currentUser.email) {
                $timeout(function () {
                  DialogService.error(
                    {
                      code: 'error.externalSystem.connectwise.notSameUser',
                      translateValues: {
                        emailRYC: currentUser.email,
                        emailCW: data.memberEmail,
                      },
                    },
                    'warning.title',
                  );
                }, 2000);
              }
            },
          );
        }
      };

      return {
        checkMember: checkMember,
      };
    },
  );
