angular
  .module('vcio-toolkit')
  .controller(
    'FillScoreCardController',
    function (
      $scope,
      $interpolate,
      $rootScope,
      $location,
      $http,
      $sce,
      $timeout,
      $translate,
      uuid,
      _,
      $window,
    ) {
      $scope.loadingFinished = false;
      $scope.questionsAnswered = false;
      $scope.stage = '';
      $scope.widget = {};
      $scope.arrow = { visible: true };

      $scope.$on('$destroy', function () {
        delete window.onbeforeunload;
      });

      window.onbeforeunload = function () {
        if ($scope.stage === 'questions') {
          return 'Are you sure you want to live this page? Your data is not sent as there are missing answers.';
        }
      };

      $window.onscroll = function () {
        $scope.arrow.visible = !(
          window.innerHeight + window.scrollY + 60 >=
          document.body.offsetHeight
        );
        $scope.$apply();
      };

      const surveyUUID =
        decodeURIComponent(
          (new RegExp('[?|&]survey=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [
            ,
            '',
          ])[1].replace(/\+/g, '%20'),
        ) || null;
      $http
        .get('/api/scorecard/' + surveyUUID)
        .success(function (response) {
          $scope.loadingFinished = true;
          $scope.widget = response;

          if ($scope.widget) {
            if (
              $scope.widget.data.questions[0].values &&
              $scope.widget.data.questions[0].values.length >= $scope.widget.data.maxResponses
            ) {
              return ($scope.stage = 'error');
            }
            _.forEach($scope.widget.data.questions, function (q) {
              q.value = 0;
            });

            $scope.stage = 'welcome';

            setTimeout(function () {
              $scope.arrow.visible = document.body.offsetHeight > window.innerHeight;
            }, 0);
          } else {
            $scope.stage = 'error';
          }
        })
        .error(function (error) {
          $scope.loadingFinished = true;
          $scope.stage = 'error';
        });

      $scope.updateStatus = function () {
        $scope.questionsAnswered = true;
        _.forEach($scope.widget.data.questions, function (q) {
          if (!q.value) {
            $scope.questionsAnswered = false;
          }
        });
      };

      $scope.rzSliderTranslate = function (value, sliderId, label) {
        if (label == 'floor' || (label == 'model' && value == 0)) {
          return 'n/a';
        } else {
          return value;
        }
      };

      $scope.gotoNextStage = function () {
        switch ($scope.stage) {
          case 'welcome':
            $scope.stage = 'questions';
            window.scrollTo(0, 0);
            break;
          case 'questions':
            $scope.save();
            break;
        }
      };

      $scope.save = function () {
        const emailInput = document.getElementById('scoreCardEmail').value;
        const emailFormat = new RegExp(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,5}$/);

        if (emailInput && !emailFormat.test(emailInput)) {
          return;
        }

        if ($scope.questionsAnswered) {
          var data = { questions: $scope.widget.data.questions };

          if ($scope.widget.data.fillerData) {
            data.fillerData = {
              fillerName: $scope.widget.data.fillerData.fillerName || 'n/a',
              fillerEmail: $scope.widget.data.fillerData.fillerEmail || 'n/a',
              fillerNote: $scope.widget.data.fillerData.fillerNote || 'n/a',
            };
          }

          $http
            .post('/api/scorecard/' + surveyUUID + '/external', data)
            .success(function () {
              $scope.stage = 'success';
            })
            .error(function () {
              $scope.stage = 'error';
            });
        }
      };
    },
  );
