angular
  .module('vcio-toolkit')

  .controller(
    'SearchResultController',
    function (
      $rootScope,
      $scope,
      $state,
      $stateParams,
      _,
      CurrentUser,
      HttpService,
      results,
      InputSanitizerService,
    ) {
      // $scope.disableFilter = CurrentUser.hasModule('vendor');
      $scope.currentUser = CurrentUser.getUser();
      $scope.searchString = $stateParams.searchString;
      $scope.results = results;
      $scope.limit = 5;
      $scope.searchList = {};
      if ($stateParams.type) {
        $scope.searchList[$stateParams.type] = {
          id: $stateParams.type,
          type: $stateParams.type,
          name: 'search.menu.' + $stateParams.type,
          limit: $scope.limit,
          children: [],
          count: 0,
        };
      }

      $scope.reload = function () {
        $state.go(
          $state.current,
          {
            searchString: $scope.searchString,
            type: $stateParams.type,
          },
          { reload: true },
        );
      };

      function assetIsBought(asset) {
        var permissionGroupIds = [];
        if (asset.SubCategory && asset.SubCategory.PermissionGroups) {
          permissionGroupIds = _.flatMap(asset.SubCategory.PermissionGroups, 'id');
        }
        var availablePermissionGroupIds = _.intersection(
          $scope.currentUser.Company.PermissionGroups,
          permissionGroupIds,
        );
        return availablePermissionGroupIds.length > 0;
      }

      $scope.clickSearch = function (item, type) {
        HttpService.post('/api/admin/events/searchClick', {
          term: $scope.searchString,
          id: item.id,
          name: item.name,
          itemType: $stateParams.type,
          type: 'result',
        });
      };

      $scope.getAssetLink = function (asset) {
        if (asset.VendorId == $scope.currentUser.Company.id) {
          return '';
        } else {
          var permissionGroupIds = [];
          if (asset.SubCategory && asset.SubCategory.PermissionGroups) {
            permissionGroupIds = _.flatMap(asset.SubCategory.PermissionGroups, 'id');
          }
          if (assetIsBought(asset)) {
            return '';
          } else if (permissionGroupIds[0]) {
            return (
              'marketplace.permissiongroup({permissionGroupId: ' + permissionGroupIds[0] + '})'
            );
          } else {
            return 'marketplace.marketplace';
          }
        }
      };

      $scope.generateSearchType = function (item, type) {
        if (!$scope.searchList[type]) {
          $scope.searchList[type] = {
            id: type,
            type: type,
            name: 'search.menu.' + type,
            limit: $scope.limit,
            children: [],
            count: 0,
          };
        }
        if (type === 'asset') {
          item.assetNeedToBuy = !assetIsBought(item);
        }
        $scope.searchList[type].children.push(item);
      };

      $scope.generateSearchList = function () {
        _.forEach($scope.searchList, function (type) {
          if (type.children.length) {
            type.children = [];
          }
          if (type.count) {
            type.count = 0;
          }
        });
        _.forEach($scope.results, function (typeList, type) {
          _.forEach(typeList.rows, function (item) {
            $scope.generateSearchType(item, type);
          });
          if ($scope.searchList[type]) {
            $scope.searchList[type].count = typeList.count;
          }
        });
      };

      $scope.showMore = function (type) {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.get(
          '/api/search/result/' +
            InputSanitizerService.sanitize($stateParams.searchString) +
            '?type=' +
            InputSanitizerService.sanitize($stateParams.type) +
            '&offset=' +
            InputSanitizerService.sanitize($scope.searchList[type].limit) +
            '&limit=' +
            InputSanitizerService.sanitize($scope.limit),
        ).then(function (result) {
          $scope.searchList[type].limit += $scope.limit;
          _.forEach(result, function (typeList, type) {
            _.forEach(typeList.rows, function (item) {
              $scope.generateSearchType(item, type);
            });
          });
          $rootScope.$broadcast('dataLoadingFinished');
        });
      };

      $scope.generateSearchList();
    },
  );
