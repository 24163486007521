angular
  .module('vcio-toolkit')
  .service('EmailSequenceService', function (_, CurrentUser) {
    return {
      copyTemplate: function (emailSequence) {
        emailSequence.TemplateId = emailSequence.id;
        delete emailSequence.id;
        emailSequence.Owner = CurrentUser.getUser().Company;
        emailSequence.SenderId = CurrentUser.getUser().id;
        emailSequence.template = false;
        emailSequence.name = 'Copy of ' + emailSequence.name;
      },
    };
  })

  .controller(
    'EmailSequencesController',
    function (
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $translate,
      $modal,
      _,
      CurrentUser,
      DialogService,
      HttpService,
      PermissionGroupModel,
      emailsequences,
    ) {
      $scope.emailSequences = emailsequences;
      $scope.filter = { name: '', permissionGroups: [] };
      if ($stateParams.permissionGroupId) {
        $scope.filter.permissionGroups = _.map(
          $stateParams.permissionGroupId.split(','),
          function (val) {
            return parseInt(val);
          },
        );
      }
      $scope.type =
        $state.$current.name === 'marketing.emailsequenceTemplates' ? 'templates' : 'sequences';
      //console.log($scope.type);
      $scope.userHasNoSequences = emailsequences.length === 0 && $scope.type === 'sequences';
      $scope.newEmailSequence = {};
      $scope.myPermissionGroups = [];
      PermissionGroupModel.querySubscribed().$promise.then(function (result) {
        var listPermissionGroups = _(emailsequences)
          .flatMap(function (item) {
            return _.get(item, 'Template.PermissionGroups') || _.get(item, 'PermissionGroups', []);
          })
          .map('id')
          .uniq()
          .value();
        $scope.myPermissionGroups = _.filter(result, function (item) {
          return _.includes(listPermissionGroups, item.id);
        });
      });

      $scope.clickPermissionGroupFilter = function (permissionGroupId) {
        $state.transitionTo(
          'marketing.emailsequencesByPermissionGroup',
          { permissionGroupId: permissionGroupId.join() },
          { notify: false },
        );
      };

      $scope.canEdit = function (emailSequence) {
        return (
          (!emailSequence && CurrentUser.can('createEmailSequence')) ||
          emailSequence.Owner.id == CurrentUser.getUser().Company.id ||
          CurrentUser.can('editAnyEmailSequence')
        );
      };

      $scope.edit = function (emailsequence) {
        if ($scope.canEdit(emailsequence)) {
          $state.go('marketing.emailsequence', {
            emailsequenceId: emailsequence ? emailsequence.id : 'new',
          });
        }
      };

      $scope.copy = function (emailsequence) {
        $state.go('marketing.emailsequenceCopy', { emailsequenceId: emailsequence.id });
      };

      $scope.save = function (newEmailSequence, cb) {
        if (newEmailSequence.template) {
          $state.go('marketing.emailsequenceCopy', { emailsequenceId: newEmailSequence.template });
        }
      };

      $scope.cancel = function () {
        $scope.newEmailSequence = {};
      };

      $scope.createEmailSequence = function () {
        if ($scope.type === 'templates') {
          $state.go('marketing.emailsequence', { emailsequenceId: 'new' });
        } else {
          $rootScope.$broadcast('dataLoadingStarted');
          HttpService.get('/api/emailsequences?template=true').then(
            function (emailSequenceTemplates) {
              $scope.emailSequenceTemplates = emailSequenceTemplates;
              $scope.newEmailSequence = { expanded: true };
              $rootScope.$broadcast('dataLoadingFinished');
            },
            function (error) {
              $rootScope.$broadcast('dataLoadingFinished');
              DialogService.error(error);
            },
          );
        }
      };

      $scope.delete = function (emailSequence) {
        HttpService.delete('/api/emailsequences/' + emailSequence.id).then(
          function (deleted) {
            _.remove($scope.emailSequences, function (item) {
              return item.id == deleted.emailSequenceId;
            });
          },
          function (error) {
            console.error(error);
          },
        );
      };

      $scope.nameFilter = function (item) {
        return (
          $scope.filter.name === '' ||
          item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1 ||
          (item.Owner &&
            item.Owner.name &&
            item.Owner.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1)
        );
      };
      // $scope.typeFilter = function (item) {
      //     return !$scope.permissionGroupFilter || (item.Template && _.find(item.Template.PermissionGroups, {id: parseInt($scope.permissionGroupFilter)}));
      // };
      $scope.permissionGroupFilter = function (item) {
        return (
          _.isEmpty($scope.filter.permissionGroups) ||
          (item.Template &&
            _.find(item.Template.PermissionGroups, function (permissionGroup) {
              return _.includes($scope.filter.permissionGroups, permissionGroup.id);
            })) ||
          _.find(item.PermissionGroups, function (permissionGroup) {
            return _.includes($scope.filter.permissionGroups, permissionGroup.id);
          })
        );
      };
    },
  )

  .controller(
    'EmailSequenceAddController',
    function (
      $scope,
      $modalInstance,
      _,
      CurrentUser,
      HttpService,
      EmailSequenceService,
      DialogService,
      emailsequences,
    ) {
      $scope.emailsequences = _.sortBy(emailsequences, 'name');
      $scope.selected = {};

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };

      $scope.ok = function (cb) {
        EmailSequenceService.copyTemplate($scope.selected.emailSequence);
        HttpService.post('/api/emailsequences', {
          emailSequence: $scope.selected.emailSequence,
        }).then(
          function (result) {
            DialogService.message('label.emailSequence.addToCatalogue.success', function () {
              $modalInstance.close(result);
            });
            cb();
          },
          function (error) {
            $scope.errors = [error];
            cb();
          },
        );
      };
    },
  )

  .controller(
    'EmailSequenceEditController',
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $translate,
      $http,
      $modal,
      _,
      CurrentUser,
      EmailSequenceService,
      emailsequence,
      copy,
    ) {
      $scope.currentUser = CurrentUser.getUser();

      $scope.emailSequence = emailsequence || { emails: [], Owner: CurrentUser.getUser().Company };

      if (copy) {
        EmailSequenceService.copyTemplate($scope.emailSequence);
      }

      if (!$scope.emailSequence.emails || $scope.emailSequence.emails.length === 0) {
        $scope.emailSequence.emails = [
          {
            subject: '',
            body: '',
            delay: 0,
            orderId: 0,
          },
        ];
      }

      $scope.senders = [];
      $http
        .get('/api/admin/users')
        .success(function (users) {
          users.forEach(function (user) {
            user.Companies.forEach(function (company) {
              if (
                company.id == CurrentUser.getUser().Company.id &&
                !_.find($scope.senders, { id: user.id })
              ) {
                user.Company = company;
                $scope.senders.push(user);
              }
            });
          });
        })
        .error(function (error) {
          console.error(error);
        });

      $scope.email = function (item) {
        return item ? item.firstName + ' ' + item.lastName + ' <' + item.email + '>' : '';
      };

      $scope.goBack = function () {
        if ($scope.emailSequence.template) {
          $state.go('marketing.emailsequenceTemplates');
        } else {
          $state.go('marketing.emailsequences');
        }
      };

      $scope.save = function (cb) {
        if ($scope.emailSequence.id) {
          $http
            .put('/api/emailsequences', { emailSequence: $scope.emailSequence })
            .success(function (emailSequence) {
              $scope.goBack();
            })
            .error(function (error) {
              $scope.errors = [error];
              console.error(error);
              cb();
            });
        } else {
          $http
            .post('/api/emailsequences', { emailSequence: $scope.emailSequence })
            .success(function (emailSequence) {
              $scope.goBack();
            })
            .error(function (error) {
              $scope.errors = [error];
              console.error(error);
              cb();
            });
        }
      };

      $scope.add = function () {
        $scope.emailSequence.emails.push({
          subject: '',
          body: '',
          delay: 1,
          orderId: $scope.emailSequence.emails.length,
        });
      };

      $scope.remove = function (item2remove, list) {
        _.remove(list, function (item) {
          return item.orderId == item2remove.orderId;
        });

        list.forEach(function (item) {
          if (item.orderId > item2remove.orderId) {
            item.orderId--;
          }
        });
      };

      //$scope.checkDays = function (input) {
      //    return _.filter($scope.emailSequence.emails, {delay: input}).length <= 1;
      //}

      $scope.cancel = function () {
        $scope.goBack();
      };

      $scope.sendTestEmail = function (orderId) {
        var modalInstance = $modal.open({
          templateUrl: '/templates/marketing/email-sequence-test.html',
          controller: function ($scope, $http, $modalInstance, emailSequenceId, orderId) {
            $scope.emailSequenceId = emailSequenceId;
            $scope.orderId = orderId;
            $scope.contacts = [];
            $scope.selected = {};
            $http.get('/api/contacts').success(function (contacts) {
              $scope.contacts = contacts;
            });

            $scope.ok = function (cb) {
              if ($scope.selected.contact) {
                $http
                  .post('/api/emailsequences/' + $scope.emailSequenceId + '/test', {
                    orderId: $scope.orderId,
                    contactId: $scope.selected.contact.id,
                  })
                  .success(function () {
                    $scope.messages = ['emailSequenceEdit.testEmailSent'];
                    cb();
                  })
                  .error(function (error) {
                    $scope.errors = [error];
                    cb();
                  });
              } else {
                $scope.errors = [{ code: 'error.noContactSelected' }];
              }
            };

            $scope.close = function (cb) {
              $modalInstance.dismiss();
            };
          },
          backdrop: 'static',
          //size: 'lg',
          resolve: {
            orderId: function () {
              return orderId;
            },
            emailSequenceId: function () {
              return $scope.emailSequence.id;
            },
          },
        });

        //modalInstance.result.then(function (campaign) {
        //    $scope.emailCampaigns.push(campaign);
        //});
      };
    },
  )

  .controller(
    'EmailCampaignsController',
    function (
      $scope,
      $state,
      $stateParams,
      $translate,
      $http,
      $modal,
      _,
      CurrentUser,
      emailsequences,
      emailcampaigns,
    ) {
      $scope.emailCampaigns = emailcampaigns;
      $scope.emailSequences = emailsequences;

      $scope.filter = { name: '', type: '' };

      $scope.nameFilter = function (item) {
        return (
          $scope.filter.name === '' ||
          item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1 ||
          (item.Owner &&
            item.Owner.name &&
            item.Owner.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1) ||
          (item.EmailSequence &&
            item.EmailSequence.name &&
            item.EmailSequence.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1)
        );
      };

      $scope.create = function () {
        var modalInstance = $modal.open({
          templateUrl: '/templates/msp/email-campaign-create.html',
          controller: 'EmailCampaignCreateController',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            emailSequences: function () {
              return $scope.emailSequences;
            },
          },
        });

        modalInstance.result.then(function (campaign) {
          $scope.emailCampaigns.push(campaign);
        });
      };

      $scope.start = function (campaign) {
        $http
          .put('/api/emailcampaigns/' + campaign.id + '/start')
          .success(function (emailCampaign) {
            campaign.status = 'started';
          })
          .error(function (error) {
            $scope.errors = [error];
            console.error(error);
          });
      };

      $scope.close = function (campaign) {
        $http
          .put('/api/emailcampaigns/' + campaign.id + '/closed')
          .success(function () {
            campaign.status = 'closed';
          })
          .error(function (error) {
            $scope.errors = [error];
            console.error(error);
          });
      };

      $scope.archive = function (campaign) {
        $http
          .put('/api/emailcampaigns/' + campaign.id + '/archived')
          .success(function () {
            _.remove($scope.emailCampaigns, { id: campaign.id });
          })
          .error(function (error) {
            $scope.errors = [error];
            console.error(error);
          });
      };

      $scope.delete = function (campaign) {
        $http
          .delete('/api/emailcampaigns/' + campaign.id)
          .success(function (emailCampaign) {
            _.remove($scope.emailCampaigns, { id: campaign.id });
          })
          .error(function (error) {
            $scope.errors = [error];
            console.error(error);
          });
      };

      // $scope.contacts = function (campaign) {
      //     $state.go('marketing.emailcampaign', {emailcampaignId: campaign.id});
      //     // var modalInstance = $modal.open({
      //     //     templateUrl: '/templates/msp/email-campaign-contacts.html',
      //     //     controller: 'EmailCampaignContactsController',
      //     //     backdrop: 'static',
      //     //     size: 'lg',
      //     //     resolve: {
      //     //         campaign: function () {
      //     //             return campaign;
      //     //         }
      //     //     }
      //     // });
      //     //
      //     // modalInstance.result.then(function (campaign) {
      //     //     $scope.emailCampaigns.push(campaign);
      //     // });
      //
      //     }
    },
  )

  .controller(
    'EmailCampaignCreateController',
    function (
      $scope,
      $state,
      $stateParams,
      $translate,
      $http,
      $modalInstance,
      _,
      CurrentUser,
      emailSequences,
    ) {
      $scope.emailSequences = _.filter(emailSequences, function (item) {
        return !item.template;
      });
      $scope.emailCampaign = {};

      $scope.name = function (item) {
        return item.name;
      };

      $scope.create = function (cb) {
        $http
          .post('/api/emailcampaigns', { emailCampaign: $scope.emailCampaign })
          .success(function (emailCampaign) {
            $scope.emailCampaign.id = emailCampaign.id;
            $scope.messages = ['message.saved'];
            $modalInstance.close(emailCampaign);
            cb();
          })
          .error(function (error) {
            $scope.errors = [error];
            console.error(error);
            cb();
          });
      };

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };
    },
  )

  .controller(
    'EmailCampaignContactsController',
    function ($scope, $state, $translate, $http, _, $modal, campaign) {
      $scope.emailCampaign = campaign;
      $scope.filter = { name: '', clientId: '0' };
      $scope.selectedFilter = { name: '', clientId: '0' };
      // $http.get('/api/emailcampaigns/' + campaign.id + '/contacts')
      //     .success(function (result) {
      //         $scope.emailCampaign.Contacts = result;
      //     }).error(function (error) {
      //     console.error(error);
      // })

      $http
        .get('/api/contacts/')
        .success(function (result) {
          $scope.allContacts = result;
          $scope.clients = [{ id: '0', name: 'select a company' }];
          result.forEach(function (contact) {
            if (!_.find($scope.clients, { id: contact.Client.id })) {
              $scope.clients.push(contact.Client);
            }
          });
        })
        .error(function (error) {
          console.error(error);
        });

      $scope.select = function (contact) {
        contact.selected = !contact.selected;
      };

      $scope.add = function (contact) {
        if (!_.find($scope.emailCampaign.Contacts, { id: contact.id })) {
          $scope.emailCampaign.Contacts.push(contact);
          contact.selected = false;
        }
      };

      $scope.remove = function (contact) {
        _.remove($scope.emailCampaign.Contacts, function (item) {
          return item.id == contact.id;
        });
        contact.selected = false;
      };

      $scope.addSelected = function () {
        $scope.allContacts.forEach(function (item) {
          if (item.selected && !_.find($scope.emailCampaign.Contacts, { id: item.id })) {
            $scope.emailCampaign.Contacts.push(item);
            item.selected = false;
          }
        });
      };

      $scope.contactFilter = function (item) {
        var name = (item.firstName + item.lastName + item.email).toLowerCase();

        return (
          $scope.emailCampaign &&
          !_.find($scope.emailCampaign.Contacts, { id: item.id }) &&
          (!$scope.filter.name || name.indexOf($scope.filter.name.toLowerCase()) > -1) &&
          (!$scope.filter.clientId ||
            $scope.filter.clientId === '0' ||
            item.ClientId == parseInt($scope.filter.clientId))
        );
      };

      $scope.selectedContactFilter = function (item) {
        var name = (item.firstName + item.lastName + item.email).toLowerCase();

        return (
          (!$scope.selectedFilter.name ||
            name.indexOf($scope.selectedFilter.name.toLowerCase()) > -1) &&
          (!$scope.selectedFilter.clientId ||
            $scope.selectedFilter.clientId === '0' ||
            item.ClientId == parseInt($scope.selectedFilter.clientId))
        );
      };

      $scope.addAll = function () {
        $scope.allContacts.forEach(function (item) {
          if ($scope.contactFilter(item)) {
            //if (!_.find($scope.emailCampaign.Contacts, {id: item.id})) {
            $scope.emailCampaign.Contacts.push(item);
            item.selected = false;
          }
        });
      };

      $scope.removeSelected = function () {
        _.remove($scope.emailCampaign.Contacts, function (item) {
          return item.selected;
        });
        $scope.allContacts.forEach(function (item) {
          item.selected = false;
        });
      };

      $scope.removeAll = function () {
        //$scope.emailCampaign.Contacts = [];
        $scope.allContacts.forEach(function (item) {
          if ($scope.selectedContactFilter(item)) {
            item.selected = false;
            _.remove($scope.emailCampaign.Contacts, { id: item.id });
          }
        });
      };

      $scope.createContact = function () {
        var contact = { status: 'customer' };
        var client = _.find($scope.clients, { id: parseInt($scope.selectedFilter.clientId) });

        var modalInstance = $modal.open({
          templateUrl: '/templates/client/contact-edit.html',
          controller: 'ContactEditController',
          backdrop: 'static',
          resolve: {
            contact: function () {
              return angular.copy(contact);
            },
            client: function () {
              return client;
            },
            contactSeniorities: function (HttpService) {
              return HttpService.get('/api/admin/ces/contactseniorities');
            },
          },
        });

        modalInstance.result.then(
          function (contact) {
            $scope.allContacts.push(contact);
          },
          function () {},
        );
      };

      $scope.save = function (cb) {
        $http
          .put('/api/emailcampaigns/' + $scope.emailCampaign.id + '/contacts', {
            contacts: $scope.emailCampaign.Contacts,
          })
          .success(function (result) {
            $state.go('marketing.emailcampaigns');
            // $scope.messages = ['message.saved']
            // cb();
          })
          .error(function (error) {
            $scope.errors = [error];
            cb();
          });
      };

      $scope.cancel = function () {
        $state.go('marketing.emailcampaigns');
        // $modalInstance.dismiss();
      };
    },
  );
