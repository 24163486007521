angular
  .module('vcio-toolkit')
  .controller('OverviewQBRQuarterlytableController', function ($scope, _) {
    var block = _.find($scope.surveyResult.Survey.blocks, { id: $scope.block.id });
    $scope.statements = _.map(block.statements, function (statement) {
      return {
        id: statement.id,
        data: [],
        name: statement.name,
        hint: statement.hint,
        chart: {},
      };
    });

    function countChartData(block) {
      if (block.quarters) {
        _.forEachRight(block.quarters, function (quarter) {
          _.forEach($scope.statements, function (statement) {
            var _statement = _.find(quarter.statements, { id: statement.id });
            if (_statement) {
              statement.data.push(Math.round(_statement.value * 100) / 100 || 0);
            }
          });
        });
      } else {
        $scope.statements.forEach(function (statement) {
          var _statement = _.find(block.statements, { id: statement.id });
          if (_statement) {
            statement.data.push(Math.round(_statement.value * 100) / 100 || 0);
          }
        });
      }
    }

    function generateLabels() {
      var labels = [];
      if ($scope.block.quarters) {
        _.forEachRight($scope.surveyResult.quarters, function (quarter) {
          labels.push($scope.compareReports[quarter.id].name);
        });
      } else {
        labels = [''];
      }
      return labels;
    }

    countChartData($scope.block);

    $scope.charts = {};
    $scope.statements.forEach(function (statement) {
      statement.chart = {
        labels: generateLabels(),
        series: [statement.name],
        data: [statement.data],
        options: {
          legend: { display: false },
          responsive: true,
          animation: true,
          bezierCurve: false,
          pointDot: false,
          colours: [$scope.getTrendColor(statement.data)],
          scaleOverride: true,
          scaleStartValue: 0,
          scaleSteps: 10,
          scaleStepWidth: 10,
        },
      };
    });
  })

  .controller('OverviewQBRScoreController', function ($http, $scope, _) {
    var block = _.find($scope.surveyResult.Survey.blocks, { id: $scope.block.id });
    $scope.statements = block.statements;

    var refreshChart = function () {
      var quarters = $scope.options.compare
        ? _.sortBy($scope.block.compare, 'orderId').reverse()
        : [{ name: '', statements: $scope.block.statements }];

      var labels = _.map(quarters, function (quarter) {
        return quarter.name;
      });
      var data = _.map(quarters, function (quarter) {
        return _.sumBy(quarter.statements, function (statement) {
          return statement.checked ? statement.score : 0;
        });
      });

      $scope.chart = {
        labels: labels,
        series: ['Score'],
        datasets: [
          _.extend(
            {
              // label: labels,
              data: data,
            },
            $scope.getTrendColor(data),
          ),
        ],
        options: {
          legend: { display: false },
          responsive: true,
          animation: false,
          // colours: [$scope.getTrendColor(data)],
          scaleOverride: true,
          scaleStartValue: 0,
          scaleSteps: 10,
          scaleStepWidth: 10,
        },
      };
    };

    $scope.isChecked = function (quarter, _statement) {
      var statement = _.find(quarter.statements, { id: _statement.id });
      return statement && statement.checked;
    };

    $scope.changeChecked = function (_quarter, _statement) {
      var statement = _.find(_quarter.statements, { id: _statement.id });
      if (statement.isEditing) {
        statement.oldValue = statement.checked;
        statement.checked = !statement.checked;
        if (!statement.checkedByUser) {
          statement.checkedByUser = false;
        }
        refreshChart();
      }
    };

    $scope.statementCheckboxStyle = function (quarter, _statement) {
      var statement = _.find(quarter.statements, { id: _statement.id });
      var style = 'fa ';
      style += statement && statement.checked ? 'fa-check-square' : 'fa-square';
      style += statement && statement.checked == statement.checkedByUser ? '-o' : '';
      return style;
    };

    refreshChart();
    $scope.$watch('options.compare', function () {
      refreshChart();
    });
  })

  .controller('OverviewQBRScorecardController', function ($scope, _) {
    var refreshChart = function () {
      var data = $scope.options.compare
        ? _.map($scope.block.compare, function (quarter) {
            var sum = _.sumBy(quarter.statements, 'value') || 0;
            var max = _.sumBy(quarter.statements, 'max') || 1;
            return Math.round((sum / max) * 100);
          }).reverse()
        : [
            Math.round(
              ((_.sumBy($scope.block.statements, 'value') || 0) /
                (_.sumBy($scope.block.statements, 'max') || 1)) *
                100,
            ),
          ];

      $scope.chart = {
        labels: $scope.options.compare ? _.map($scope.block.compare, 'name').reverse() : ['Score'],
        series: ['%'],
        data: [data],
        options: {
          legend: { display: false },
          responsive: true,
          animation: true,
          bezierCurve: false,
          pointDot: false,
          colours: [$scope.getTrendColor(data)],
          scaleOverride: true,
          scaleStartValue: 0,
          scaleSteps: 10,
          scaleStepWidth: 10,
        },
      };
    };
    $scope.$watch('options.compare', function () {
      refreshChart();
    });
    refreshChart();
  })

  // .controller('OverviewQBRReportGenerateController', function ($scope, $state, $stateParams, $http, $modalInstance, $filter, $window, $translate, _, DialogService, HttpService, surveyResult) {
  //     $scope.surveyResult = _.cloneDeep(surveyResult);
  //     $scope.titleData = {
  //         surveyTitle: surveyResult.title,
  //         evaluatedDate: $filter('date')(surveyResult.updatedAt, 'shortDate'),
  //         contactName: surveyResult.Contact.lastName + ', ' + surveyResult.Contact.firstName
  //     };
  //
  //     $scope.download = function () {
  //         $window.open('https://' + $window.location.hostname + ($window.location.port ? ':' + $window.location.port : '') + '/reports/' + $scope.downloadButton.filename)
  //     };
  //
  //     $scope.downloadButton = {
  //         label: 'button.download',
  //         function: $scope.download,
  //         enabled: false
  //     };
  //
  //     $scope.createReport = function (cb) {
  //         HttpService.post('/api/reports/qbr', {report: $scope.surveyResult})
  //             .then(function (result) {
  //                 $scope.downloadButton.filename = 'https://' + $window.location.hostname + ($window.location.port ? ':' + $window.location.port : '') + '/reports/' + result.id;
  //                 $scope.downloadButton.enabled = true;
  //             })
  //             .catch(function (error) {
  //                 console.error(error)
  //             })
  //     };
  //
  //     $scope.cancel = function () {
  //         $modalInstance.dismiss();
  //     }
  // })

  .controller('OverviewQBRController', function ($scope, $modal, _) {
    $scope.getBlockTemplate = function (block) {
      return '/templates/sales/overview/qbr/block-' + block.type + '.html';
    };

    $scope.getStatementTemplate = function (statement) {
      if (statement.type) {
        return '/templates/sales/overview/qbr/statement-' + statement.type + '.html';
      } else {
        return '/templates/sales/overview/qbr/statement-text.html';
      }
    };

    $scope.getTrendColor = function (data) {
      if (!_.isArray(data)) {
        return {
          fillColor: '#63BBBB',
          strokeColor: '#308888',
        };
      }
      var lastData = data[3] || data[2] || data[1] || data[0];

      if (lastData > data[0] * 1.1) {
        return {
          fillColor: '#7CD194',
          strokeColor: '#62B77A',
        };
      } else if (lastData < data[0] * 0.9) {
        return {
          fillColor: '#E76565',
          strokeColor: '#CD4B4B',
        };
      } else {
        return {
          fillColor: '#63BBBB',
          strokeColor: '#308888',
        };
      }
    };
  });
