angular
  .module('vcio-toolkit')

  .factory('AssetCategoryModel', function ($resource, CurrentUser, _) {
    var resource = $resource(
      '/api/academy/assetcategories/:assetCategoryId',
      { assetCategoryId: '@id' },
      {
        create: {
          method: 'POST',
          transformRequest: function (data) {
            return angular.toJson({ assetCategory: data });
          },
        },
        delete: {
          method: 'DELETE',
          transformRequest: function (data) {
            return angular.toJson({ assetCategory: data });
          },
        },
        update: {
          url: '/api/academy/assetcategories',
          method: 'PUT',
          transformRequest: function (data) {
            return angular.toJson({ assetCategory: data });
          },
        },
        reorder: {
          url: '/api/academy/assetcategories/reorder',
          isArray: true,
          method: 'POST',
          transformRequest: function (data) {
            var assetCategoryIds = [];
            _.forEach(data, function (item) {
              assetCategoryIds.push({
                id: item.id,
                orderId: item.orderId,
                CategoryId: item.CategoryId,
              });
            });
            return angular.toJson({ assetCategoryIds: assetCategoryIds });
          },
        },
        queryByVendor: {
          method: 'GET',
          isArray: true,
          transformResponse: function (data) {
            return _.filter(angular.fromJson(data), { VendorId: CurrentUser.getUser().Company.id });
          },
        },
      },
    );

    resource.prototype.$save = function () {
      if (!this.id) {
        return this.$create();
      } else {
        return this.$update();
      }
    };

    return resource;
  });
