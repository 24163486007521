angular
  .module('vcio-toolkit')
  .controller(
    'FillReportController',
    function ($sce, $scope, $http, _, $window, LocalStorage, $translate) {
      $scope.loadingFinished = false;
      $scope.questionsAnswered = false;
      $scope.stage = '';
      $scope.widget = {};
      $scope.arrow = { visible: true };
      $scope.buttonText = $translate.instant('button.next');

      $scope.$on('$destroy', function () {
        delete window.onbeforeunload;
      });

      window.onbeforeunload = function () {
        if ($scope.stage === 'questions') {
          return $translate.instant('confirm.leavePage');
        }
      };

      window.onscroll = function () {
        $scope.arrow.visible = !(
          window.innerHeight + window.scrollY + 60 >=
          document.body.offsetHeight
        );
        $scope.$apply();
      };

      const resetWindow = function () {
        setTimeout(function () {
          $scope.arrow.visible = !(
            window.innerHeight + window.scrollY + 60 >=
            document.body.offsetHeight
          );
          $scope.$apply();
          window.scrollTo(0, 0);
        }, 0);
      };

      const setCurrentBlock = function () {
        $scope.currentBlock =
          $scope.widget.data.dimensions[$scope.currentDimensionId].blocks[$scope.currentBlockId];
        if ($scope.currentBlock) {
          $scope.currentBlock.$$trustedHtmlComment = $sce.trustAsHtml(
            $scope.currentBlock.analyzeComment,
          );
          $scope.currentBlock.$$isEditComment = false;
        }
      };

      $scope.currentStep = 0;
      const surveyUUID =
        decodeURIComponent(
          (new RegExp('[?|&]survey=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [
            ,
            '',
          ])[1].replace(/\+/g, '%20'),
        ) || null;
      $http
        .get('/api/reports/' + surveyUUID)
        .success(function (response) {
          $scope.widget = response;
          if ($scope.widget) {
            $scope.steps = $scope.widget.data.dimensions.reduce(function (prev, dimension) {
              return prev + dimension.blocks.length;
            }, 0);

            $scope.stage = 'welcome';

            const data = LocalStorage.getObject(surveyUUID);
            if (data) {
              $scope.widget.data.dimensions = data;
            }

            $scope.currentDimension = $scope.widget.data.dimensions[0];
            // $scope.currentBlock = $scope.widget.data.dimensions[0].blocks[0];
            $scope.currentDimensionLength = $scope.widget.data.dimensions[0].blocks.length;
            $scope.currentDimensionId = 0;
            $scope.currentStep = 0;
            $scope.currentBlockId = 0;
            setCurrentBlock();
          } else {
            $scope.stage = 'error';
          }
          $scope.loadingFinished = true;
          resetWindow();
        })
        .error(function (error) {
          $scope.loadingFinished = true;
          $scope.stage = 'error';
          resetWindow();
        });

      $scope.nextAction = function () {
        switch ($scope.stage) {
          case 'welcome':
            $scope.stage = 'questions';
            $scope.currentDimension = $scope.widget.data.dimensions[0];
            // $scope.currentBlock = $scope.widget.data.dimensions[0].blocks[0];
            $scope.currentDimensionId = 0;
            $scope.currentDimensionLength = $scope.widget.data.dimensions[0].blocks.length;
            $scope.currentStep = 0;
            $scope.currentBlockId = 0;
            setCurrentBlock();
            break;
          case 'questions':
            $scope.currentStep++;
            $scope.currentBlockId++;

            // Last question, next button should submit
            if (
              $scope.currentBlockId + 1 >= $scope.currentDimensionLength &&
              $scope.currentDimensionId + 1 >= $scope.widget.data.dimensions.length
            ) {
              $scope.buttonText = $translate.instant('button.submit');
              $scope.lastQuestion = true;
            }

            if ($scope.currentBlockId >= $scope.currentDimensionLength) {
              if ($scope.currentDimensionId + 1 >= $scope.widget.data.dimensions.length) {
                $scope.stage = 'finish';
                $scope.save();
              } else {
                $scope.currentBlockId = 0;
                $scope.currentDimensionId++;
                $scope.currentDimensionLength =
                  $scope.widget.data.dimensions[$scope.currentDimensionId].blocks.length;
              }
            }

            $scope.currentDimension = $scope.widget.data.dimensions[$scope.currentDimensionId];
            // $scope.currentBlock = $scope.widget.data.dimensions[$scope.currentDimensionId].blocks[$scope.currentBlockId];
            setCurrentBlock();

            break;
          default:
            break;
        }

        resetWindow();
      };

      $scope.prevAction = function () {
        switch ($scope.stage) {
          case 'questions':
            $scope.currentStep--;
            $scope.currentBlockId--;
            $scope.lastQuestion = false;

            if ($scope.currentBlockId < 0) {
              $scope.currentDimensionId--;

              if ($scope.currentDimensionId < 0) {
                ($scope.currentDimensionId = 0), ($scope.stage = 'welcome');
              }

              $scope.currentDimensionLength =
                $scope.widget.data.dimensions[$scope.currentDimensionId].blocks.length;
              $scope.currentBlockId = $scope.currentDimensionLength - 1;
            }

            $scope.currentDimension = $scope.widget.data.dimensions[$scope.currentDimensionId];
            // $scope.currentBlock = $scope.widget.data.dimensions[$scope.currentDimensionId].blocks[$scope.currentBlockId]
            setCurrentBlock();

            break;
          case 'finish':
            $scope.currentDimension = $scope.widget.data.dimensions[$scope.currentDimensionId];
            $scope.stage = 'questions';
            break;
        }

        resetWindow();
      };

      $scope.getNextButtonText = function () {
        return $scope.lastQuestion
          ? $translate.instant('button.submit')
          : $translate.instant('button.next');
      };

      $scope.save = function () {
        LocalStorage.delete(surveyUUID);

        $http
          .post('/api/reports/' + surveyUUID + '/external', { data: $scope.widget.data })
          .success(function () {
            $scope.stage = 'finish';
          })
          .error(function () {
            $scope.stage = 'error';
          });
      };

      $scope.statementCheckboxChange = function (statement, block, type) {
        statement[type] = !statement[type];
        var _statement = _.find(block.statements, { id: statement.id });
        if (block.answerType === 'single') {
          if (_statement.target) {
            block.statements.forEach(function (other) {
              if (_statement.id != other.id && other.target) {
                other.target = false;
              }
            });
          }
          if (_statement.current) {
            block.statements.forEach(function (other) {
              if (_statement.id != other.id && other.current) {
                other.current = false;
              }
            });
          }
        }

        LocalStorage.setObject(surveyUUID, $scope.widget.data.dimensions);
      };

      $scope.getCheckboxClass = function (statement, type, block) {
        if (block.answerType === 'single') {
          return !!statement[type] ? 'fa-check-circle text-primary' : 'fa-circle text-disabled';
        } else {
          return !!statement[type] ? 'fa-check-square text-primary' : 'fa-square-o';
        }
      };

      $scope.keydown = function ($event) {
        if (!$scope.commentFocus) {
          if ($event.key == 'ArrowRight' && !$scope.lastQuestion && $scope.nextAction) {
            $scope.nextAction();
          } else if ($event.key == 'ArrowLeft' && $scope.prevAction) {
            $scope.prevAction();
          }
        }
      };

      $scope.saveBlockComment = function () {
        if ($scope.currentBlock) {
          $scope.currentBlock.$$isEditComment = false;
          $scope.currentBlock.$$trustedHtmlComment = $sce.trustAsHtml(
            $scope.currentBlock.analyzeComment,
          );
        }
      };
    },
  );
