angular
  .module('vcio-toolkit')

  .directive('treeViewItem', function ($compile) {
    return {
      restrict: 'E',
      replace: true,
      template:
        '<ul style="list-style-type: none;">' +
        '  <li>' +
        '      <i ng-class="{\'fa-minus\': branch.expanded && branch.todo, \'fa-plus\': !branch.expanded && branch[childrenName]}" ng-click="branch.expanded = !branch.expanded" class="fa"></i>' +
        '      <span ng-click="_click(branch)"> {{ _exp(branch)}}</span>' +
        '  </li>' +
        '</ul>',
      scope: {
        branch: '=',
        //labelName: "@",
        labelNames: '=',
        //childrenName: "@",
        childrenNames: '=',
        exp: '=',
        level: '@',
        //click:'=',
        clicks: '=',
      },
      link: function ($scope, $element, $attrs, $parentCtrl) {
        if ($scope.branch && $scope.childrenName && $scope.branch[$scope.childrenName]) {
          $element.append(
            $compile(
              '<tree-view-item ng-show="branch.expanded" ng-repeat="child in branch[childrenName] track by $index" branch="child" label-names="labelNames" children-names="childrenNames" clicks="clicks" level="{{level + 1}}"></tree-view-item>',
            )($scope),
          );
        }
      },
      controller: function ($scope, $attrs, $http) {
        //$scope._click = $scope.click;
        $scope.level = $scope.level || 0;
        if ($scope.labelNames) {
          $scope.labelName = $scope.labelNames[$scope.level];
        }
        if ($scope.childrenNames) {
          $scope.childrenName = $scope.childrenNames[$scope.level];
        }
        if ($scope.clicks) {
          $scope._click = $scope.clicks[$scope.level];
        }
        if (!$scope.exp) {
          if ($scope.labelName) {
            $scope._exp = function (item) {
              return item ? item[$scope.labelName] : '';
            };
          } else {
            $scope._exp = function (item) {
              return item ? JSON.stringify(item) : '';
            };
          }
        } else {
          $scope._exp = $scope.exp;
        }

        $scope.moveUp = function (item, list) {
          if (item.orderId > 1) {
            var prev = _.find(list, { orderId: item.orderId - 1 });
            item.orderId -= 1.5;
            prev.orderId++;
            item.orderId += 0.5;
          }
        };

        $scope.moveDown = function (item, list) {
          if (item.orderId < list.length) {
            var next = _.find(list, { orderId: item.orderId + 1 });
            item.orderId += 1.5;
            next.orderId--;
            item.orderId -= 0.5;
          }
        };
      },
    };
  });
