angular
  .module('vcio-toolkit')
  .controller('APIKeysController', function ($scope, $state, $stateParams, $http, _, $translate) {
    $scope.apiKeys = [];
    $scope.new = { name: '' };

    $scope.refresh = function () {
      $http.get('/api/keys').success(function (keys) {
        $scope.apiKeys = keys;
      });
    };

    $scope.generateNew = function (key) {
      if (key) {
        $http.post('/api/keys', { name: key.name }).success(function () {
          $scope.refresh();
        });
      } else {
        if ($scope.new.name && !_.find($scope.apiKeys, { name: $scope.new.name })) {
          $http.post('/api/keys', { name: $scope.new.name }).success(function () {
            $scope.refresh();
          });
        }
      }
    };

    $scope.delete = function (key) {
      $http.delete('/api/keys/' + key.id).success(function () {
        $scope.refresh();
      });
    };

    $scope.refresh();
  });
