//ReportStack2EditController

//ReportStack2OverviewController
angular
  .module('vcio-toolkit')
  .controller(
    'ReportEditController',
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $http,
      $q,
      $modal,
      $translate,
      $timeout,
      _,
      CurrentUser,
      DialogService,
      HttpService,
      SurveyService,
      UploadService,
      uuid,
      survey,
      services,
      serviceTemplates,
      copy,
      template,
      InputSanitizerService,
    ) {
      $scope.survey = survey || {
        template: template,
        blocks: [],
        LandingPageId: 0,
        type: $stateParams.type,
      };
      $scope.survey.dimensions = $scope.survey.dimensions || [];
      _.forEach($scope.survey.widgets, function (widget, index) {
        if (index > 0) {
          if (widget.onNewPage === undefined) {
            widget.onNewPage = false;
          }
        }
      });

      $scope.copy = copy;
      $scope.isTemplate = template;
      $scope.survey.multiRole = $scope.survey.roles && $scope.survey.roles.length > 0;
      $scope.wasTemplate = $scope.survey.template;
      $scope.expanded = { dimensions: true };
      $scope.dimensionTypes = ['execution', 'management'];
      $scope.answerTypes = ['single', 'multiple'];
      $scope.types = ['score', 'custom', 'scorecard'];
      $scope.widgetTypes = ['raw', 'quarterlytable'];
      $scope.item = {};

      $scope.surveyType = {
        qbr: {
          template: '/templates/report/general/edit.html',
          controller: 'ReportGeneralEditController',
        },
        stack2: {
          template: '/templates/report/stack2/edit.html',
          controller: 'ReportStack2EditController',
        },
        itcq: {
          template: '/templates/report/general/edit.html',
          controller: 'ReportGeneralEditController',
        },
        mpd: {
          template: '/templates/report/general/edit.html',
          controller: 'ReportGeneralEditController',
        },
        stack: {
          template: '/templates/report/general/edit.html',
          controller: 'ReportGeneralEditController',
        },
        needs: {
          template: '/templates/report/general/edit.html',
          controller: 'ReportGeneralEditController',
        },
      };
      $scope.getSurveyTemplateName = function () {
        return _.get($scope.surveyType, $scope.survey.type + '.template');
      };
      $scope.getSurveyControllerName = function () {
        return _.get($scope.surveyType, $scope.survey.type + '.controller');
      };

      var servicesObj = {
        onetimeServiceTemplates: [],
        ongoingServiceTemplates: [],
        serviceTemplates: [],
        onetimeServices: [],
        ongoingServices: [],
        services: [],
      };
      servicesObj.onetimeServiceTemplates = _.filter(serviceTemplates, function (s) {
        return /*s.published && */ s.type !== 'ongoing';
      });
      servicesObj.ongoingServiceTemplates = _.filter(serviceTemplates, function (s) {
        return /*s.published && */ s.type !== 'onetime';
      });
      servicesObj.serviceTemplates = serviceTemplates;
      servicesObj.onetimeServices = $scope.isTemplate
        ? servicesObj.onetimeServiceTemplates
        : _.filter(services, function (s) {
            return s.type !== 'ongoing';
          });
      servicesObj.ongoingServices = $scope.isTemplate
        ? servicesObj.ongoingServiceTemplates
        : _.filter(services, function (s) {
            return s.type !== 'onetime';
          });
      servicesObj.services = $scope.isTemplate ? servicesObj.serviceTemplates : services;

      $scope.onetimeServices = _.orderBy(servicesObj.onetimeServices, 'name');
      $scope.ongoingServices = _.orderBy(servicesObj.ongoingServices, 'name');

      $scope.services = _.orderBy(servicesObj.services, 'name');

      if ($scope.survey.id) {
        if ($scope.survey.PreviewImageId) {
          $scope.previewUrl =
            '/images/surveypreviews/' +
            InputSanitizerService.sanitize($scope.survey.id) +
            '?' +
            new Date().getTime();
        }
      }
      $scope.openPreview = function (survey) {
        UploadService.open('surveypreviews', $scope.survey).then(function () {
          $scope.previewUrl =
            '/images/surveypreviews/' +
            InputSanitizerService.sanitize($scope.survey.id) +
            '?' +
            new Date().getTime();
        });
      };

      if (copy) {
        SurveyService.copyTemplate($scope.survey, $scope.isTemplate);
      }

      $scope.survey.dimensions = _.orderBy($scope.survey.dimensions, 'orderId');
      $scope.survey.dimensions.forEach(function (dimension) {
        dimension.expandedBlocks = true;
        dimension.$$expanded = true;

        dimension.blocks = _($scope.survey.blocks)
          .filter({ dimension: dimension.code })
          .orderBy('orderIdAnalyze')
          .map(function (block, index) {
            block.orderId = index + 1;
            block.statements = _.orderBy(block.statements, 'orderId');
            block.$$expanded = true;
            block.statements.forEach(function (statement) {
              getService('onetimeService', statement);
              getService('ongoingService', statement);
              if (statement.onetimeService) {
                statement.service = statement.onetimeService;
              } else if (statement.ongoingService) {
                statement.service = statement.ongoingService;
              }
              statement.type = statement.type || 'number';
            });
            return block;
          })
          .value();
        if (dimension.imageId) {
          dimension.imageUrl =
            '/images/surveydimensions/' + dimension.imageId + '?' + new Date().getTime();
        }
      });

      function getService(type, statement) {
        if (statement[type]) {
          statement[type] = _.find(
            _.concat(servicesObj[type + 's'], servicesObj[type + 'Templates']),
            { id: _.isObject(statement[type]) ? statement[type].id : statement[type] },
          );
          if (statement[type] && statement[type].template) {
            var service = _.find(servicesObj[type + 's'], { TemplateId: statement[type].id });
            if (service) {
              statement[type] = service;
            }
          }
        }
      }

      $scope.close = function () {
        if ($scope.wasTemplate && !copy) {
          $state.go('sales.surveytemplates', { type: $scope.survey.type });
        } else {
          $state.go('sales.surveys', { type: $scope.survey.type });
        }
      };

      $scope.addServices = function (selectedTemplates, saveFunction) {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.post('/api/service/services/templates', { templateIds: selectedTemplates })
          .then(function (services) {
            saveFunction(function () {
              $rootScope.$broadcast('dataLoadingFinished');

              servicesObj.onetimeServices = servicesObj.onetimeServices.concat(
                _.filter(services, function (s) {
                  return /*s.published &&*/ s.type !== 'ongoing';
                }),
              );
              servicesObj.ongoingServices = servicesObj.ongoingServices.concat(
                _.filter(services, function (s) {
                  return /*s.published &&*/ s.type !== 'onetime';
                }),
              );
              servicesObj.services = servicesObj.services.concat(services);

              $scope.onetimeServices = _.orderBy(servicesObj.onetimeServices, 'name');
              $scope.ongoingServices = _.orderBy(servicesObj.ongoingServices, 'name');
              $scope.services = _.orderBy(servicesObj.services, 'name');

              _($scope.survey.dimensions)
                .flatMap('blocks')
                .value()
                .forEach(function (block) {
                  block.statements.forEach(function (statement) {
                    getService('onetimeService', statement);
                    getService('ongoingService', statement);
                    getService('service', statement);
                  });
                });

              DialogService.message($translate.instant('message.survey.serviceAdded'));
            });
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
          });
      };

      // $scope.addAllServicesToCatalogue = function () {
      //     var ids = [];
      //     _($scope.survey.dimensions).flatMap('blocks').value().forEach(function (block) {
      //         block.statements.forEach(function (statement) {
      //             if (statement.onetimeService && statement.onetimeService.template && ids.indexOf(statement.onetimeService.id) === -1) {
      //                 ids.push(statement.onetimeService.id);
      //             }
      //             if (statement.ongoingService && statement.ongoingService.template && ids.indexOf(statement.ongoingService.id) === -1) {
      //                 ids.push(statement.ongoingService.id);
      //             }
      //         });
      //     });
      //     if (!ids.length) {
      //         DialogService.message($translate.instant('message.survey.noServiceAdded'));
      //     } else {
      //         addServices(ids);
      //     }
      // };
    },
  )

  .controller('ReportGeneralEditBlockController', function ($scope, _, block) {
    $scope.block = block;
    $scope.statementTypes = ['text', 'number', 'currency', 'percentage', 'textWithNumber'];

    $scope.changeNoneOfTheAbove = function (block, statement) {
      block.statements.forEach(function (_statement) {
        if (_statement.id == statement.id) {
          _statement.noneOfTheAbove = !_statement.noneOfTheAbove;
        } else if (_statement.noneOfTheAbove) {
          _statement.noneOfTheAbove = false;
        }
      });
    };

    $scope.hideStatement = function (block, statement) {
      statement.hidden = true;
    };
    $scope.unhideStatement = function (block, statement) {
      statement.hidden = false;
    };

    $scope.calcSums = function () {
      if (!$scope.block.type || $scope.block.type === 'score') {
        $scope.block.$$sum = _.sumBy($scope.block.statements, 'score');
      } else {
        delete $scope.block.$$sum;
      }
    };
  })

  .controller(
    'ReportGeneralEditController',
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $http,
      $q,
      $modal,
      $translate,
      $timeout,
      _,
      CurrentUser,
      DialogService,
      SurveyService,
      UploadService,
      uuid,
    ) {
      $http.get('/api/sales/pages').success(function (pages) {
        $scope.landingPages = pages;
        $scope.survey.LandingPageId = $scope.survey.LandingPageId || 0;
      });
      if ($scope.survey.multiRole) {
        _.forEach($scope.survey.roles, function (role) {
          if (!role.uuid) {
            role.uuid = uuid.v4();
          }
        });
      }

      var oldSurvey = _.cloneDeep($scope.survey);

      var storeDimensionExpanded = {};
      var restoreDimensionExpanded = function () {
        $scope.survey.dimensions.forEach(function (dimension) {
          dimension.expanded = storeDimensionExpanded[dimension.code];
        });
      };

      $scope.addServiceToCatalogue = function (service) {
        $scope.addServices([service.id], $scope.save);
      };

      var blockDragged = false;
      $scope.blockClick = function (block) {
        if (blockDragged) {
          blockDragged = false;
        } else {
          block.expanded = !block.expanded;
        }
      };
      $scope.onDropBlockSuccess = function (data, target) {
        if (data.dimension === target.dimension) {
          var newBlockOrderId = target.orderIdAnalyze;
          target.orderIdAnalyze = data.orderIdAnalyze;
          data.orderIdAnalyze = newBlockOrderId;
        }
      };
      $scope.onDropDimensionSuccess = function (key, block) {
        if (block) {
          var newDimension = $scope.survey.dimensions[key];
          block.dimension = newDimension.code;
        }
      };
      $scope.onDragStart = function () {
        blockDragged = true;
        $scope.survey.dimensions.forEach(function (dimension) {
          storeDimensionExpanded[dimension.code] = dimension.expanded;
          //dimension.expanded = true;
          dimension.expandedBlocks = true;
        });
        // $scope.survey.blocks.forEach(function (block) {
        //     block.expanded = false;
        // });
      };
      $scope.onDragStop = function () {
        restoreDimensionExpanded();
      };
      var hoverTimeout;
      var lastHoverDimensionKey = null;
      $scope.onDragHover = function (dimension) {
        if (lastHoverDimensionKey !== dimension.code) {
          $timeout.cancel(hoverTimeout);
          hoverTimeout = $timeout(function () {
            dimension.expanded = true;
          }, 500);
        }
        lastHoverDimensionKey = dimension.code;
      };

      $scope.dropCallback = function (srcList, srcIndex, targetList, targetIndex) {
        // Copy the item from source to target.
        targetList.splice(targetIndex, 0, srcList[srcIndex]);
        // Remove the item from the source, possibly correcting the index first.
        // We must do this immediately, otherwise ng-repeat complains about duplicates.
        if (srcList == targetList && targetIndex <= srcIndex) {
          srcIndex++;
        }
        srcList.splice(srcIndex, 1);
        // By returning true from dnd-drop we signalize we already inserted the item.
        return true;
      };

      $scope.getDimension = function (dimensionCode) {
        return _.find($scope.survey.dimensions, { code: dimensionCode });
      };

      $scope.getTemplateDimension = function (dimension) {
        var templateDimension = dimension;
        if ($scope.survey.Template) {
          templateDimension =
            _.find($scope.survey.Template.dimensions, { code: dimension.code }) || dimension;
        }
        return templateDimension;
      };

      $scope.getTemplateRole = function (role) {
        var templateRole = role;
        if ($scope.survey.Template) {
          templateRole = _.find($scope.survey.Template.roles, { code: role.code }) || role;
        }
        return templateRole;
      };

      $scope.getTemplateBlock = function (block) {
        var templateBlock = block;
        if ($scope.survey.Template && $scope.survey.Template.blocks) {
          templateBlock = _.find($scope.survey.Template.blocks, { id: block.id });
          // if (!templateBlock) {
          //     templateBlock = _.cloneDeep(block);
          //     $scope.survey.blocks.push(templateBlock);
          // } olyan nem lehetne, hogy a template-ben nincs, itt meg van...
        }
        return templateBlock;
      };

      $scope.getTemplateStatement = function (block, statement) {
        var templateStatement = statement;
        var _block = $scope.getTemplateBlock(block);
        if ($scope.survey.Template && _block) {
          var _templateStatement = _.find($scope.getTemplateBlock(block).statements, {
            id: statement.id,
          });
          if (_templateStatement) {
            templateStatement = _templateStatement;
          }
        }
        return templateStatement;
      };

      $scope.save = function (cb) {
        // _.forEach($scope.survey.dimensions, function (dimension, index) {
        //     dimension.orderId = index + 1;
        //     _.forEach(dimension.blocks, function (block, index) {
        //         block.dimension = dimension.code;
        //         block.orderIdAnalyze = index + 1;
        //     });
        // });
        $scope.survey.blocks = _($scope.survey.dimensions)
          .flatMap('blocks')
          .orderBy('orderIdAnalyze')
          .value();
        $scope.survey.blocks.forEach(function (block) {
          _.forEach(block.statements, function (statement, index) {
            statement.orderId = index + 1;
            delete statement.service;
            if (_.isObject(statement.ongoingService)) {
              statement.ongoingService = statement.ongoingService.id;
            }
            if (_.isObject(statement.onetimeService)) {
              statement.onetimeService = statement.onetimeService.id;
            }
          });
        });
        if ($scope.survey.id) {
          $http
            .put('/api/surveys/' + $scope.survey.id, { survey: $scope.survey })
            .success(function (result) {
              // _.extend($scope.survey, result);
              oldSurvey = _.cloneDeep($scope.survey);
              $scope.messages = ['message.saved'];
              cb();
            })
            .error(function (error) {
              $scope.errors = [error];
              cb();
            });
        } else {
          $http
            .post('/api/surveys', { survey: $scope.survey })
            .success(function (result) {
              if (result) {
                oldSurvey = _.cloneDeep($scope.survey);
                if ($scope.survey.template) {
                  $state.go('sales.surveytemplate', {
                    surveyId: result.id,
                    type: $scope.survey.type,
                  });
                } else {
                  $state.go('sales.survey', { surveyId: result.id, type: $scope.survey.type });
                }
                // } else {
                //     // _.extend($scope.survey, result);
                //     oldSurvey = _.cloneDeep($scope.survey);
                //     $scope.messages = ['message.saved'];
                //     cb();
              }
            })
            .error(function (error) {
              $scope.errors = [error];
              cb();
            });
        }
      };

      $scope.deleteBlock = function (dimension, block) {
        if (dimension.blocks[block.code]) {
          delete dimension.blocks[block.code];
        }
        // _.remove($scope.survey.blocks, {id: block.id});
        _.remove(dimension.blocks, { id: block.id });
      };

      $scope.changeBlockAnswerType = function (block) {
        if (block.answerType === 'single') {
          block.answerType = 'multiple';
        } else {
          block.answerType = 'single';
        }
      };

      $scope.addBlock = function (dimension, newBlock) {
        if ($scope.survey.type !== 'stack2') {
          newBlock = { name: 'New Subcategory' };
        }
        if (newBlock && newBlock.name) {
          var blocks = _($scope.survey.dimensions)
            .flatMap('blocks')
            .orderBy('orderIdAnalyze')
            .value();
          var newBlockObj = {
            name: newBlock.name,
            id: uuid.v4(),
            type: 'score',
            answerType: 'multiple',
            orderIdAnalyze: (_.max(_.map(blocks, 'orderIdAnalyze')) || 0) + 1,
            orderIdQuestion: (_.max(_.map(blocks, 'orderIdQuestion')) || 0) + 1,
            dimension: dimension.code,
            statements: [],
            $$expanded: true,
          };
          // $scope.survey.blocks.push(newBlockObj);
          dimension.blocks.push(newBlockObj);
          newBlock.name = '';
        }
      };

      $scope.addStatement = function (block, newStatement) {
        if ((newStatement && newStatement.name) || $scope.survey.type !== 'stack2') {
          var statements = _.orderBy(block.statements, 'orderId');
          var max = _.maxBy(block.statements, 'id');
          block.statements.push({
            name: newStatement && newStatement.name ? newStatement.name : '',
            id: max ? max.id + 1 : 1,
            orderId: statements.length > 0 ? statements[statements.length - 1].orderId + 1 : 1,
            type: 'number',
          });
          if (newStatement) {
            newStatement.name = '';
          }
        }
      };
      $scope.deleteStatement = function (block, statement) {
        if ($scope.isTemplate || $scope.survey.type === 'stack2') {
          _.remove(block.statements, { id: statement.id });
        }
      };

      $scope.addRole = function () {
        // var modalInstance = $modal.open({
        //     template: '<modal-form onok="ok" oncancel="cancel" form-title="label.survey.addRole">' +
        //     '<input-text model="role.code" label="label.survey.role.code" required="true" type="code"></input-text>' +
        //     '</modal-form>',
        //     controller: function ($scope, $modalInstance) {
        //         $scope.role = {};
        //
        //         $scope.ok = function (cb) {
        //             $modalInstance.close($scope.role)
        //         }
        //         $scope.cancel = function () {
        //             $modalInstance.dismiss();
        //         }
        //     },
        //     backdrop: 'static'
        // });
        //
        // modalInstance.result.then(function (role) {
        if (!$scope.survey.roles) {
          $scope.survey.roles = [];
        }
        var uuidCode = uuid.v4();
        $scope.survey.roles.push({ code: uuidCode, uuid: uuidCode });
        $scope.survey.multiRole = $scope.survey.roles.length > 0;
        // }, function () {
        //
        // });
      };

      $scope.canDeleteRole = function (role) {
        return !_($scope.survey.dimensions).flatMap('blocks').find({ role: role.code });
      };

      $scope.deleteRole = function (role) {
        _.remove($scope.survey.roles, { code: role.code });
      };

      $scope.addDimension = function (newDimension) {
        if ($scope.survey.type === 'stack2') {
          $scope.survey.dimensions.push({
            code: uuid.v4(),
            name: newDimension && newDimension.name ? newDimension.name : '',
            blocks: [],
          });
          newDimension.name = '';
        } else {
          $scope.survey.dimensions.push({ code: uuid.v4(), name: 'New Category', blocks: [] });
        }
      };

      $scope.canDeleteDimension = function (dimension) {
        return (
          $scope.survey.type === 'stack2' ||
          !_($scope.survey.dimensions).flatMap('blocks').find({ dimension: dimension.code })
        );
      };

      $scope.deleteDimension = function (dimension) {
        if ($scope.canDeleteDimension(dimension)) {
          _.remove($scope.survey.dimensions, { code: dimension.code });
        }
      };

      $scope.hideDimension = function (dimension) {
        dimension.hidden = true;
      };
      $scope.unhideDimension = function (dimension) {
        dimension.hidden = false;
      };

      $scope.hideBlock = function (block) {
        block.hidden = true;
      };
      $scope.unhideBlock = function (block) {
        block.hidden = false;
      };

      $scope.getBlockTemplate = function (block) {
        return '/templates/sales/survey/block-' + block.type + '-edit.html';
      };

      $scope.getStatement = function (statements, id) {
        return _.find(statements, { id: id });
      };

      $scope.deleteOnetimeService = function (statement) {
        delete statement.onetimeService;
      };

      $scope.deleteOngoingService = function (statement) {
        delete statement.ongoingService;
      };

      $scope.changeBlockType = function (block) {
        if (block.type === 'score') {
          block.higherBetter = true;
        }
      };

      $scope.openDimensionLogo = function (dimension) {
        var data = {
          dimension: dimension,
          id: dimension.imageId ? dimension.imageId : 0,
          maxWidth: 2048,
          maxHeight: 2048,
          // maxSize: 1024
        };
        UploadService.openImage('surveydimensions', data).then(function (res) {
          //console.log(res);
          dimension.imageId = res.id;
          dimension.imageUrl = '/images/surveydimensions/' + res.id + '?' + new Date().getTime();
          $scope.save(function () {});
        });
      };

      $scope.deleteDimensionLogo = function (dimension) {
        dimension.imageId = '';
        delete dimension.imageUrl;
      };

      $scope.dimensionExpandToggle = function (dimension) {
        dimension.$$expanded = !dimension.$$expanded;
      };
      $scope.blockExpandToggle = function (block) {
        block.$$expanded = !block.$$expanded;
      };
      $scope.blockHiddenToggle = function (block) {
        block.hidden = !block.hidden;
      };

      $scope.checkFormIsDirty = function () {
        return !angular.equals(oldSurvey, $scope.survey);
      };

      $scope.moveDimensionUp = function (dimension) {
        if (dimension.orderId > 1) {
          var prevDimension = _.find($scope.survey.dimensions, { orderId: dimension.orderId - 1 });
          dimension.orderId--;
          prevDimension.orderId++;
        }
      };

      $scope.moveDimensionDown = function (dimension) {
        if (dimension.orderId < $scope.survey.dimensions.length) {
          var nextDimension = _.find($scope.survey.dimensions, { orderId: dimension.orderId + 1 });
          dimension.orderId++;
          nextDimension.orderId--;
        }
      };

      $scope.moveBlockUp = function (block, dimension) {
        if (block.orderId > 1) {
          var prevBlock = _.find(dimension.blocks, { orderId: block.orderId - 1 });
          block.orderId--;
          prevBlock.orderId++;
        }
      };

      $scope.moveBlockDown = function (block, dimension) {
        if (block.orderId < dimension.blocks.length) {
          var nextBlock = _.find(dimension.blocks, { orderId: block.orderId + 1 });
          block.orderId++;
          nextBlock.orderId--;
        }
      };
    },
  )

  .controller(
    'ReportStack2EditController',
    function ($scope, $state, $translate, _, HttpService, uuid) {
      if (!$scope.survey.ranges) {
        $scope.survey.ranges = [
          { name: 'Chaotic', color: '#ea413d' },
          { name: 'Ad-Hoc', color: '#ed742f' },
          { name: 'Standard', color: '#f5be41' },
          { name: 'Optimized', color: '#a8be4b' },
          { name: 'Agile', color: '#59bf90' },
        ];
      }
      if (!$scope.survey.widgets) {
        $scope.survey.widgets = [
          { type: 'header', data: {} },
          { type: 'categories', data: {} },
          { type: 'details', data: {} },
        ];
      }
      _.forEach($scope.survey.widgets, function (widget) {
        if (!widget.uuid) {
          widget.uuid = uuid.v4();
        }
      });
      $scope.widgetSelector = {};
      $scope.addWidgetSelectors = [];
      $scope.widgetList = [
        'header',
        'categories',
        'details',
        'bundlereview',
        'pricecalculator',
        'actionplan',
        'projects',
        'freetext',
      ];

      $scope.addServiceToCatalogue = function (service) {
        $scope.addServices([service.id], $scope.save);
      };

      $scope.filterWidgetList = function (item) {
        var unlimitedWidgets = ['categories', 'freetext'];
        if (_.includes(unlimitedWidgets, item)) {
          return true;
        } else {
          var widgetCount = _.filter($scope.survey.widgets, { type: item }).length;
          return widgetCount <= 0;
        }
      };

      var oldSurvey = _.cloneDeep($scope.survey);

      $scope.getSurveyWidgetControllerName = function (widget) {
        return 'ReportStack2EditWidget' + _.capitalize(_.get(widget, 'type')) + 'Controller';
      };
      $scope.addWidget = function (type, index) {
        if (!$scope.survey.widgets) {
          $scope.survey.widgets = [];
        }
        $scope.survey.widgets.splice(index, 0, {
          uuid: uuid.v4(),
          name: $translate.instant('surveyWidget.' + type),
          type: type,
          data: {},
        });
      };
      $scope.removeWidget = function (widget) {
        _.remove($scope.survey.widgets, { uuid: widget.uuid });
      };
      $scope.moveWidgetUp = function (widget) {
        var index = _.indexOf($scope.survey.widgets, widget);
        if (index === 0) {
          return;
        }
        var temp = $scope.survey.widgets[index];
        $scope.survey.widgets[index] = $scope.survey.widgets[index - 1];
        $scope.survey.widgets[index - 1] = temp;

        // [$scope.survey.widgets[index], $scope.survey.widgets[index-1]] = [$scope.survey.widgets[index-1], $scope.survey.widgets[index]];
      };
      $scope.moveWidgetDown = function (widget) {
        var index = _.indexOf($scope.survey.widgets, widget);
        if (index === $scope.survey.widgets.length - 1) {
          return;
        }
        var temp = $scope.survey.widgets[index];
        $scope.survey.widgets[index] = $scope.survey.widgets[index + 1];
        $scope.survey.widgets[index + 1] = temp;
        // [$scope.survey.widgets[index], $scope.survey.widgets[index+1]] = [$scope.survey.widgets[index+1], $scope.survey.widgets[index]];
      };

      $scope.save = function (cb) {
        var newSurvey = _.cloneDeep($scope.survey);
        newSurvey.blocks = _(newSurvey.dimensions)
          .flatMap('blocks')
          .orderBy('orderIdAnalyze')
          .value();
        newSurvey.blocks.forEach(function (block) {
          _.forEach(block.statements, function (statement, index) {
            statement.orderId = index + 1;
            delete statement.onetimeService;
            delete statement.ongoingService;
            if (statement.service && statement.service.type) {
              if (statement.service.type === 'ongoing') {
                statement.ongoingService = statement.service.id;
              }
              if (statement.service.type === 'onetime') {
                statement.onetimeService = statement.service.id;
              }
              if (statement.service.type === 'any') {
                statement.ongoingService = statement.service.id;
                statement.onetimeService = statement.service.id;
              }
            }
            delete statement.service;
          });
        });
        if (newSurvey.id) {
          HttpService.put('/api/surveys/' + newSurvey.id, { survey: newSurvey }).then(
            function (result) {
              // _.extend(newSurvey, result);
              oldSurvey = _.cloneDeep($scope.survey);
              $scope.messages = ['message.saved'];
              cb();
            },
            function (error) {
              $scope.errors = [error];
              cb();
            },
          );
        } else {
          HttpService.post('/api/surveys', { survey: newSurvey }).then(
            function (result) {
              if (result) {
                oldSurvey = _.cloneDeep($scope.survey);
                if (newSurvey.template) {
                  $state.go('sales.surveytemplate', { surveyId: result.id, type: newSurvey.type });
                } else {
                  $state.go('sales.survey', { surveyId: result.id, type: newSurvey.type });
                }
                // } else {
                //     // _.extend(newSurvey, result);
                //     oldSurvey = _.cloneDeep(newSurvey);
                //     $scope.messages = ['message.saved'];
                //     cb();
              }
            },
            function (error) {
              $scope.errors = [error];
              cb();
            },
          );
        }
      };

      $scope.checkFormIsDirty = function () {
        return !angular.equals(oldSurvey, $scope.survey);
      };
    },
  )

  .controller(
    'ReportStack2OverviewController',
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $http,
      $modal,
      $q,
      $window,
      _,
      $translate,
      CurrentUser,
      HttpService,
      LocalStorage,
      OverviewStack2ChartService,
      VcioService,
      InputSanitizerService,
    ) {
      $scope.loading = true;
      $scope.actionPlan = [];
      $scope.showBenchmark = false;
      $scope.postits = {};
      $scope.selected = {};
      $scope.blocks = [];
      $scope.new = { dimension: {} };
      $scope.actionPlanTab = 'schedule';
      $scope.userLogo = '/images/companies/' + CurrentUser.getUser().companyId;

      $scope.ranges = OverviewStack2ChartService.ranges;

      $scope.getSurveyWidgetControllerName = function (widget) {
        return 'ReportStack2OverviewWidget' + _.capitalize(_.get(widget, 'type')) + 'Controller';
      };

      //FIXME this only needs for backward compatibility
      _.forEach($scope.surveyResult.widgetsData, function (widgetData) {
        var widget = _.find($scope.surveyResult.widgets, { uuid: widgetData.uuid });
        if (widget) {
          widgetData.name = widget.name;
          widgetData.type = widget.type;
          widgetData.hidden = widget.hidden;
          widgetData.onNewPage = widget.onNewPage;
        }
      });

      var updateServices = function (dest, source) {
        _.remove(dest, function (item) {
          return !_.find(source, { id: item.id });
        });
        _.forEach(source, function (item) {
          if (!_.find(dest, { id: item.id })) {
            dest.push(item);
          }
        });
      };

      $scope.refresh = function (surveyResult) {
        updateServices($scope.surveyResult.onetimeServices, surveyResult.onetimeServices);
        updateServices($scope.surveyResult.ongoingServices, surveyResult.ongoingServices);
        $scope.surveyResult.score = surveyResult.score;
        $scope.surveyResult.targetScore = surveyResult.targetScore;
        $scope.surveyResult.dimensions.forEach(function (dimension) {
          var _dimension = _.find(surveyResult.dimensions, { code: dimension.code });
          if (_dimension) {
            dimension.score = _dimension.score;
            dimension.targetScore = _dimension.targetScore;
          }
        });

        if (!$scope.surveyResult.$$initialized) {
          $scope.surveyResult.blocks = surveyResult.blocks;
          $scope.blocks = _.orderBy($scope.surveyResult.blocks, 'orderIdAnalyze');
        }

        _.forEach($scope.blocks, function (block) {
          if (!block.tickets) {
            block.tickets = [];
          }
          _.forEach(block.tickets, function (ticket) {
            //TODO this can be removed after 2018. january , just for testing backward compatibilty
            if (ticket.text && !ticket.title) {
              ticket.title = ticket.text;
              delete ticket.text;
            }
          });
          var _block = _.find(surveyResult.blocks, { id: block.id });
          if (_block) {
            block.score = _block.score;
            block.targetScore = _block.targetScore;
            _.forEach(block.statements, function (statement) {
              var _statement = _.find(_block.statements, { id: statement.id });
              if (_statement) {
                statement.current = _statement.checkedByUser;
                statement.target = _statement.checked;
              }
            });
          }
        });

        $scope.getService = function (id) {
          return $scope.services[id];
        };

        $scope.service = function (id) {
          return $scope.services[id];
        };

        _.forEach($scope.surveyResult.onetimeServices, function (service) {
          service.$$hoverQuarterInput = {};
        });
        $scope.surveyResult.$$initialized = true;
        OverviewStack2ChartService.setRangesData($scope.surveyResult.ranges);
        $scope.$broadcast('overviewRefreshed');
      };

      $scope.changeWidgetShowHide = function (widget) {
        widget.showHide = !widget.showHide;
        $scope.save();
      };

      $scope.save = function () {
        var attributes = _.pick($scope.surveyResult, ['widgetsData']);
        if (!$scope.savePromise) {
          $scope.savePromise = HttpService.put(
            '/api/surveyresults/' + InputSanitizerService.sanitize($scope.surveyResult.id),
            { attributes: attributes },
          );
        } else {
          $scope.savePromise = $scope.savePromise.finally(function () {
            return HttpService.put(
              '/api/surveyresults/' + InputSanitizerService.sanitize($scope.surveyResult.id),
              { attributes: attributes },
            );
          });
        }
      };

      $scope.saveBlock = function (block) {
        if (!$scope.saveBlockPromise) {
          $scope.saveBlockPromise = HttpService.put(
            '/api/surveyresults/' +
              InputSanitizerService.sanitize($scope.surveyResult.id) +
              '/responses/' +
              InputSanitizerService.sanitize(block.id),
            block,
          ).then(function (result) {
            if (result) {
              $scope.refresh(result);
            }
          });
        } else {
          $scope.saveBlockPromise = $scope.saveBlockPromise.finally(function (result) {
            return HttpService.put(
              '/api/surveyresults/' +
                InputSanitizerService.sanitize($scope.surveyResult.id) +
                '/responses/' +
                InputSanitizerService.sanitize(block.id),
              block,
            ).then(function (result) {
              if (result) {
                $scope.refresh(result);
              }
            });
          });
        }
      };

      $scope.getProgressStyleColor = function (value) {
        var ranges = OverviewStack2ChartService.ranges;
        var color = ranges[0].color;
        _.forEach(ranges, function (range) {
          if (value > range.min && value <= range.max) {
            color = range.color;
            return false;
          }
        });
        return color;
      };

      $scope.progressStyleLength = function (length) {
        return { width: length + '%' };
      };

      $scope.getPopoverContent = function (block) {
        var result = '<ul class="fa-ul">';
        _.forEach(_.sortBy(block.statements, 'orderId'), function (statement) {
          var icon =
            'fa-' +
            (statement.checked ? 'check-' : '') +
            'square' +
            (!!statement.checked == !!statement.checkedByUser ? '-o' : '');
          var style = statement.highlighted
            ? statement.checked
              ? 'checked-highlighted'
              : 'highlighted'
            : statement.checked
            ? 'checked'
            : '';
          result +=
            '<li class="' +
            style +
            '"><i class="fa-li fa ' +
            icon +
            '"></i>' +
            statement.name +
            '</li>';
        });
        result += '</ul>';
        if (block.hint && block.hint != 'null') {
          result += '<div class="popover-footer">' + block.hint + '</div>';
        }
        return result;
      };

      $scope.getServicePopover = function (service) {
        if (service) {
          var d = { academy: 'success', marketing: 'danger', sales: 'primary', vcio: 'warning' };
          var result = service.description + '<div class="popover-footer"><div class="row"> ';
          _.forOwn(d, function (color, key) {
            result += '<div class="col-xs-3"><small>';
            service.serviceQuality[key].forEach(function () {
              result += '<i class="fa fa-star text-' + color + '"></i>';
            });
            result += '</small></div>';
          });
          return result + '</div></div>';
        } else {
          return '';
        }
      };

      $scope.openContractToProject = function (contract, system) {
        VcioService.setSystem(system);
        VcioService.get(
          '/clients/' + InputSanitizerService.sanitize($scope.client.id) + '/projects',
        )
          .then(function (projects) {
            $scope.client.Projects = projects;
            $rootScope.$broadcast('dataLoadingFinished');
            var modalInstance = $modal.open({
              templateUrl: '/templates/vcio/contract-to-project.html',
              controller: 'VcioContractToProjectController',
              backdrop: 'static',
              resolve: {
                contract: function () {
                  return angular.copy(contract);
                },
                client: function () {
                  return $scope.client;
                },
              },
            });

            modalInstance.result.then(
              function () {},
              function () {},
            );
          })
          .catch(function () {
            $rootScope.$broadcast('dataLoadingFinished');
          });
      };

      $scope.refresh($scope.surveyResult);

      $scope.scrollToElement = function (elementId) {
        var offset = document.getElementById(elementId).offsetTop;
        window.scrollTo(0, offset);
      };
    },
  )
  .controller(
    'ReportStack2OverviewPrintController',
    function ($scope, $rootScope, _, CurrentUser, OverviewStack2ChartService) {
      $scope.loading = true;
      $scope.actionPlan = [];
      $scope.showBenchmark = false;
      $scope.postits = {};
      $scope.selected = {};
      $scope.blocks = [];
      $scope.new = { dimension: {} };
      $scope.actionPlanTab = 'schedule';
      //$scope.userLogo = "/images/companies/" + CurrentUser.getUser().companyId;

      $scope.ranges = OverviewStack2ChartService.ranges;

      $scope.getSurveyWidgetControllerName = function (widget) {
        return 'ReportStack2OverviewWidget' + _.capitalize(_.get(widget, 'type')) + 'Controller';
      };

      var updateServices = function (dest, source) {
        _.remove(dest, function (item) {
          return !_.find(source, { id: item.id });
        });
        _.forEach(source, function (item) {
          if (!_.find(dest, { id: item.id })) {
            dest.push(item);
          }
        });
      };

      $scope.refresh = function (surveyResult) {
        updateServices($scope.surveyResult.onetimeServices, surveyResult.onetimeServices);
        updateServices($scope.surveyResult.ongoingServices, surveyResult.ongoingServices);
        $scope.surveyResult.score = surveyResult.score;
        $scope.surveyResult.targetScore = surveyResult.targetScore;
        $scope.surveyResult.dimensions.forEach(function (dimension) {
          var _dimension = _.find(surveyResult.dimensions, { code: dimension.code });
          if (_dimension) {
            dimension.score = _dimension.score;
            dimension.targetScore = _dimension.targetScore;
          }
        });

        if (!$scope.surveyResult.$$initialized) {
          $scope.surveyResult.blocks = surveyResult.blocks;
          $scope.blocks = _.orderBy($scope.surveyResult.blocks, 'orderIdAnalyze');
        }

        _.forEach($scope.blocks, function (block) {
          if (!block.tickets) {
            block.tickets = [];
          }
          _.forEach(block.tickets, function (ticket) {
            //TODO this can be removed after 2018. january , just for testing backward compatibilty
            if (ticket.text && !ticket.title) {
              ticket.title = ticket.text;
              delete ticket.text;
            }
          });
          var _block = _.find(surveyResult.blocks, { id: block.id });
          if (_block) {
            block.score = _block.score;
            block.targetScore = _block.targetScore;
            _.forEach(block.statements, function (statement) {
              var _statement = _.find(_block.statements, { id: statement.id });
              if (_statement) {
                statement.current = _statement.checkedByUser;
                statement.target = _statement.checked;
              }
            });
          }
        });

        $scope.getService = function (id) {
          return $scope.services[id];
        };

        $scope.service = function (id) {
          return $scope.services[id];
        };

        _.forEach($scope.surveyResult.onetimeServices, function (service) {
          service.$$hoverQuarterInput = {};
        });
        $scope.surveyResult.$$initialized = true;
        OverviewStack2ChartService.setRangesData($scope.surveyResult.ranges);
        $scope.$broadcast('overviewRefreshed');
      };

      $scope.getProgressStyleColor = function (value) {
        var ranges = OverviewStack2ChartService.ranges;
        var color = ranges[0].color;
        _.forEach(ranges, function (range) {
          if (value > range.min && value <= range.max) {
            color = range.color;
            return false;
          }
        });
        return color;
      };

      $scope.progressStyleLength = function (length) {
        return { width: length + '%' };
      };

      $scope.getPopoverContent = function (block) {
        var result = '<ul class="fa-ul">';
        _.forEach(_.sortBy(block.statements, 'orderId'), function (statement) {
          var icon =
            'fa-' +
            (statement.checked ? 'check-' : '') +
            'square' +
            (!!statement.checked == !!statement.checkedByUser ? '-o' : '');
          var style = statement.highlighted
            ? statement.checked
              ? 'checked-highlighted'
              : 'highlighted'
            : statement.checked
            ? 'checked'
            : '';
          result +=
            '<li class="' +
            style +
            '"><i class="fa-li fa ' +
            icon +
            '"></i>' +
            statement.name +
            '</li>';
        });
        result += '</ul>';
        if (block.hint && block.hint != 'null') {
          result += '<div class="popover-footer">' + block.hint + '</div>';
        }
        return result;
      };

      $scope.getServicePopover = function (service) {
        if (service) {
          var d = { academy: 'success', marketing: 'danger', sales: 'primary', vcio: 'warning' };
          var result = service.description + '<div class="popover-footer"><div class="row"> ';
          _.forOwn(d, function (color, key) {
            result += '<div class="col-xs-3"><small>';
            service.serviceQuality[key].forEach(function () {
              result += '<i class="fa fa-star text-' + color + '"></i>';
            });
            result += '</small></div>';
          });
          return result + '</div></div>';
        } else {
          return '';
        }
      };

      $scope.refresh($scope.surveyResult);

      $scope.scrollToElement = function (elementId) {
        var offset = document.getElementById(elementId).offsetTop;
        window.scrollTo(0, offset);
      };
    },
  )

  .controller('ReportStack2EditWidgetCategoriesController', function ($scope, widget) {})
  .controller('ReportStack2EditWidgetActionplanController', function ($scope, widget) {})
  .controller('ReportStack2EditWidgetProjectsController', function ($scope, widget) {})
  .controller('ReportStack2OverviewWidgetCategoriesController', function ($scope, _, widgetData) {
    $scope.widgetData = widgetData;
    if (!$scope.widgetData.data) {
      $scope.widgetData.data = {};
    }
  })
  .controller(
    'ReportStack2OverviewWidgetActionplanController',
    function (
      $http,
      $modal,
      $moment,
      $rootScope,
      $scope,
      $state,
      $translate,
      _,
      DialogService,
      HttpService,
      ModalSelector,
      widgetData,
      ProjectPriceService,
    ) {
      $scope.widgetData = widgetData;
      if (!$scope.widgetData.data) {
        $scope.widgetData.data = { services: [] };
      }
      $scope.selected = {};

      $scope.isSurveyProject = function (id) {
        return _($scope.surveyResult.blocks)
          .flatMap('statements')
          .flatMap('onetimeService')
          .includes(id);
      };

      $scope._services = _($scope.services)
        .toArray()
        .filter({
          type: 'onetime',
          deletedAt: null,
        })
        .map(function (service) {
          service.disabled =
            _.find($scope.surveyResult.onetimeServices, { id: service.id }) ||
            $scope.isSurveyProject(service.id);
          return service;
        })
        .value();
      $scope._services.unshift({
        name: $translate.instant('label.report.actionplan.createProject'),
      });

      var now = $moment();
      var timelist = {
        M: { id: 'YYYYMM', name: 'YYYY-M', sum: 1 },
        Q: { id: 'YYYYQ', name: 'YYYY[Q]Q', sum: 3 },
        y: { id: 'YYYY', name: 'YYYY', sum: 12 },
      };
      $scope.timeScale = 'Q';
      $scope.surveyResult.timeColumns = [];

      function generateTimeColumns() {
        $scope.surveyResult.timeColumns = [];
        _.forOwn($scope.surveyResult.onetimeServices, function (service) {
          service.sum = 0;
        });
        var startTime = now.clone();
        for (var i = 0; i < 5; i++) {
          var priceObj = {};
          var priceSum = 0;
          var activeObj = {};
          _.forEach($scope.surveyResult.onetimeServices, function (service) {
            var price = 0;
            var priceTime = startTime.clone().startOf($scope.timeScale);
            activeObj[service.id] = false;
            if (!service.prices) {
              service.prices = {};
            }
            for (var j = 0; j < timelist[$scope.timeScale].sum; j++) {
              if (_.indexOf(service.months, priceTime.format('YYYYMM')) !== -1) {
                activeObj[service.id] = true;
                const formattedPrices = ProjectPriceService.formatClientProjectPrices(
                  service.prices,
                );
                if (
                  formattedPrices[priceTime.format('YYYYMM')] &&
                  formattedPrices[priceTime.format('YYYYMM')] > 0
                ) {
                  price += formattedPrices[priceTime.format('YYYYMM')];
                }
              }
              priceTime.add(1, 'M');
            }
            if (price > 0) {
              price = _.round(price, 2);
              priceObj[service.id] = price;
              priceSum += price;
            }
            service.sum = 0;
            _.forEach(service.prices, function (o, oKey) {
              if (_.indexOf(service.months, oKey) !== -1) {
                service.sum += o;
              }
            });
          });
          $scope.surveyResult.timeColumns[i] = {
            id: parseInt(startTime.format(timelist[$scope.timeScale].id)),
            name: startTime.format(timelist[$scope.timeScale].name),
            active: activeObj,
            price: priceObj,
            sum: priceSum,
          };
          startTime.add(1, $scope.timeScale);
        }
      }

      generateTimeColumns();

      $scope.changeTimescale = function (t) {
        $scope.timeScale = t;
        generateTimeColumns();
      };
      $scope.prevTime = function () {
        now.subtract(1, $scope.timeScale);
        generateTimeColumns();
      };
      $scope.nextTime = function () {
        now.add(1, $scope.timeScale);
        generateTimeColumns();
      };

      $scope.serviceOrder = function (item) {
        var dimension = _.find($scope.surveyResult.dimensions, { code: item.dimension });
        return (
          (dimension ? dimension.orderId : 0) +
          ($scope.services[item.id] ? $scope.services[item.id].name : item.id)
        );
      };

      var saveOnetimeService = function (item) {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.put(
          '/api/surveyresults/' + $scope.surveyResult.id + '/services/onetime/' + item.id,
          { service: item },
        ).then(
          function () {
            $rootScope.$broadcast('dataLoadingFinished');
          },
          function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            console.error(error);
          },
        );
      };

      $scope.budgetInputBlur = function (item, c) {
        var months = timelist[$scope.timeScale].sum;
        var price = _.round(c.price[item.id] / months);
        var priceTime = $moment(c.id, timelist[$scope.timeScale].id);
        for (var j = 0; j < months; j++) {
          if (_.indexOf(item.months, priceTime.format('YYYYMM')) === -1) {
            item.months.push(priceTime.format('YYYYMM'));
          }
          item.pricess[priceTime.format('YYYYMM')] = price;
          if (j == months - 1) {
            item.prices[priceTime.format('YYYYMM')] += c.price[item.id] - price * months;
          }
          priceTime.add(1, 'M');
        }
        saveOnetimeService(item);
        generateTimeColumns();
      };

      $scope.addRemoveTimeColumn = function (item, c) {
        if (!item.months) {
          item.months = [];
        }
        c.active[item.id] = !c.active[item.id];
        var priceTime = $moment(c.id, timelist[$scope.timeScale].id);
        for (var j = 0; j < timelist[$scope.timeScale].sum; j++) {
          if (c.active[item.id] && _.indexOf(item.months, priceTime.format('YYYYMM')) === -1) {
            item.months.push(priceTime.format('YYYYMM'));
          } else if (!c.active[item.id]) {
            _.remove(item.months, function (v) {
              return v === priceTime.format('YYYYMM');
            });
          }
          priceTime.add(1, 'M');
        }
        saveOnetimeService(item);
        generateTimeColumns();
      };

      $scope.createContract = function () {
        var contract = {
          type: 'onetime',
          name: $scope.surveyResult.Survey.name,
          defaults: { hourlyRate: 0, monthlyPrice: 0, pricing: 'labour' },
          Services: {},
          SurveyResultId: $scope.surveyResult.id,
        };

        $http.get('/api/service/services?body=true').success(function (services) {
          var _services = {};
          services.forEach(function (service) {
            _services[service.id] = service;
          });
          $scope.surveyResult.onetimeServices.forEach(function (service) {
            var _service = _services[service.id];
            if (_service) {
              contract.Services[_service.id] = {
                hours: _.sumBy(_service.todoLists, function (todoList) {
                  return _.sumBy(todoList.todo, function (task) {
                    return task.hours || 0;
                  });
                }),
                hourlyRate: contract.defaults.hourlyRate,
                todoLists: _service.todoLists,
              };
            }
          });

          $http
            .post('/api/vcio/contracts' + '?clientId=' + $scope.surveyResult.ClientId, {
              contract: contract,
            })
            .success(function (result) {
              $state.go('vcio.contractEdit', { contractId: result.id });
            })
            .error(function (error) {
              $scope.errors = [error];
            });
        });
      };
      $scope.addProject = function (service) {
        var addService = function (serviceId) {
          $rootScope.$broadcast('DataLoadingStarted');
          HttpService.post(
            '/api/surveyresults/' + $scope.surveyResult.id + '/services/onetime/' + serviceId,
          )
            .then(function (result) {
              $scope.refresh(result);
              generateTimeColumns();
              service.disabled = true;
            })
            .catch(function (error) {
              DialogService.error(error);
            });
        };
        if (service && service.id) {
          addService(service.id);
          $scope.selected.service = undefined;
        } else {
          var modalInstance = $modal.open({
            template:
              '<modal-form  form-title="label.report.actionplan.createProject" onok="ok" oncancel="cancel">' +
              '<div class="abm-form">' +
              '<abm-form-group class="no-margin-top h2"><input type="text" class="ryc-h2" ng-model="newService.name" abm-form-control label="label.serviceEdit.name" required></abm-form-group>' +
              '<abm-form-group class="no-margin-top h2"><abm-ui-select model="newService.ServiceGroupId" type="id" placeholder="label.report.actionplan.selectServiceGroup" values="serviceGroups"></abm-ui-select></abm-form-group></modal-form>\n' +
              '</div>' +
              '</modal-form>',
            controller: function ($scope, $modalInstance, HttpService) {
              $scope.newService = { type: 'onetime' };
              HttpService.get('/api/service/servicegroups').then(function (servicegroups) {
                $scope.serviceGroups = _.sortBy(servicegroups, 'name');
              });
              $scope.ok = function (cb) {
                if (service) {
                  HttpService.post('/api/service/services', { service: $scope.newService }).then(
                    function (newService) {
                      $modalInstance.close(newService);
                    },
                  );
                }
              };
              $scope.cancel = function () {
                $modalInstance.dismiss();
              };
            },
            backdrop: 'static',
          });

          modalInstance.result.then(function (service) {
            $scope.selected.service = undefined;
            $scope.services[service.id] = service;
            $scope._services.push(service);
            addService(service.id);
          });
        }
      };

      $scope.removeProject = function (service) {
        $rootScope.$broadcast('DataLoadingStarted');
        HttpService.delete(
          '/api/surveyresults/' + $scope.surveyResult.id + '/services/onetime/' + service.id,
        )
          .then(function (result) {
            $scope.refresh(result);
            generateTimeColumns();
            $rootScope.$broadcast('DataLoadingFinished');
            _.find($scope._services, { id: service.id }).disabled = false;
          })
          .catch(function (error) {
            $rootScope.$broadcast('DataLoadingFinished');
            DialogService.error(error);
          });
      };

      $scope.createProject = function () {
        $scope.messages = [];
        $scope.errors = [];
        var modalInstance = $modal.open({
          templateUrl: '/templates/service/service-modal-create.html',
          controller: '',
          backdrop: 'static',
          size: 'lg',
          resolve: {},
        });

        modalInstance.result.then(
          function (data) {
            /*
                                var _permissionGroup = new PermissionGroupModel(data);
                                _permissionGroup.$save(function (result) {
                                    $scope.permissionGroups.push(result);
                                    $rootScope.$broadcast('dataLoadingFinished');
                                }, function (error) {
                                    $scope.errors = [error];
                                    $rootScope.$broadcast('dataLoadingFinished');
                                });
                */
          },
          function () {},
        );
      };
    },
  )
  .controller('ReportStack2OverviewWidgetProjectsController', function ($scope, _, widgetData) {
    $scope.widgetData = widgetData;
    if (!$scope.widgetData.data) {
      $scope.widgetData.data = {};
    }
    $scope.budgetSum = function () {
      var sum = 0;
      _.forEach($scope.surveyResult.onetimeServices, function (service) {
        _.forEach(service.prices, function (item, c) {
          if (_.indexOf(service.months, c) > -1) {
            sum += item;
          }
        });
      });
      return sum;
    };
  })

  .controller(
    'ReportStack2EditWidgetDetailsController',
    function ($scope, uuid, _, UploadService, widget) {
      $scope.widget = widget;
      if (!$scope.widget.data) {
        $scope.widget.data = {};
      }

      $scope.deleteBlock = function (dimension, block) {
        if (dimension.blocks[block.code]) {
          delete dimension.blocks[block.code];
        }
        _.remove(dimension.blocks, { id: block.id });
      };

      $scope.changeBlockAnswerType = function (block) {
        if (block.answerType === 'single') {
          block.answerType = 'multiple';
        } else {
          block.answerType = 'single';
        }
      };

      $scope.changeService = function (service) {};

      $scope.addBlock = function (dimension, newBlock) {
        if ($scope.survey.type !== 'stack2') {
          newBlock = { name: 'New Subcategory' };
        }
        if (newBlock && newBlock.name) {
          var blocks = _($scope.survey.dimensions)
            .flatMap('blocks')
            .orderBy('orderIdAnalyze')
            .value();
          var newBlockObj = {
            name: newBlock.name,
            id: uuid.v4(),
            type: 'score',
            answerType: 'multiple',
            orderId: _.size(dimension.blocks) + 1,
            orderIdAnalyze: (_.max(_.map(blocks, 'orderIdAnalyze')) || 0) + 1,
            orderIdQuestion: (_.max(_.map(blocks, 'orderIdQuestion')) || 0) + 1,
            dimension: dimension.code,
            statements: [],
            $$expanded: true,
          };
          dimension.blocks.push(newBlockObj);
          newBlock.name = '';
        }
      };

      $scope.addStatement = function (block, newStatement) {
        if ((newStatement && newStatement.name) || $scope.survey.type !== 'stack2') {
          var statements = _.orderBy(block.statements, 'orderId');
          var max = _.maxBy(block.statements, 'id');
          block.statements.push({
            name: newStatement && newStatement.name ? newStatement.name : '',
            id: max ? max.id + 1 : 1,
            orderId: statements.length > 0 ? statements[statements.length - 1].orderId + 1 : 1,
            type: 'number',
          });
          if (newStatement) {
            newStatement.name = '';
          }
        }
      };
      $scope.deleteStatement = function (block, statement) {
        if ($scope.isTemplate || $scope.survey.type === 'stack2') {
          _.remove(block.statements, { id: statement.id });
        }
      };

      $scope.addDimension = function (newDimension) {
        $scope.survey.dimensions.push({
          code: uuid.v4(),
          name: newDimension && newDimension.name ? newDimension.name : '',
          blocks: [],
          orderId: _.size($scope.survey.dimensions) + 1,
        });
        newDimension.name = '';
      };

      $scope.deleteDimension = function (dimension) {
        _.remove($scope.survey.dimensions, { code: dimension.code });
      };

      $scope.deleteService = function (statement) {
        delete statement.service;
      };

      $scope.openDimensionLogo = function (dimension) {
        var data = {
          dimension: dimension,
          id: dimension.imageId ? dimension.imageId : 0,
          maxWidth: 2048,
          maxHeight: 2048,
          // maxSize: 1024
        };
        UploadService.openImage('surveydimensions', data).then(function (res) {
          //console.log(res);
          dimension.imageId = res.id;
          dimension.imageUrl = '/images/surveydimensions/' + res.id + '?' + new Date().getTime();
          $scope.save(function () {});
        });
      };

      $scope.deleteDimensionLogo = function (dimension) {
        dimension.imageId = '';
        delete dimension.imageUrl;
      };

      $scope.dimensionExpandToggle = function (dimension) {
        dimension.$$expanded = !dimension.$$expanded;
      };
      $scope.blockExpandToggle = function (block) {
        block.$$expanded = !block.$$expanded;
      };
      $scope.blockHiddenToggle = function (block) {
        block.hidden = !block.hidden;
      };

      $scope.moveDimensionUp = function (dimension) {
        if (dimension.orderId > 1) {
          var prevDimension = _.find($scope.survey.dimensions, { orderId: dimension.orderId - 1 });
          dimension.orderId--;
          prevDimension.orderId++;
        }
      };
      $scope.moveDimensionDown = function (dimension) {
        if (dimension.orderId < $scope.survey.dimensions.length) {
          var nextDimension = _.find($scope.survey.dimensions, { orderId: dimension.orderId + 1 });
          dimension.orderId++;
          nextDimension.orderId--;
        }
      };
      $scope.moveBlockUp = function (block, dimension) {
        if (block.orderId > 1) {
          var prevBlock = _.find(dimension.blocks, { orderId: block.orderId - 1 });
          block.orderId--;
          prevBlock.orderId++;
          block.orderIdAnalyze = block.orderIdQuestion = block.orderId;
          prevBlock.orderIdAnalyze = prevBlock.orderIdQuestion = prevBlock.orderId;
        }
      };
      $scope.moveBlockDown = function (block, dimension) {
        if (block.orderId < dimension.blocks.length) {
          var nextBlock = _.find(dimension.blocks, { orderId: block.orderId + 1 });
          block.orderId++;
          nextBlock.orderId--;
          block.orderIdAnalyze = block.orderIdQuestion = block.orderId;
          nextBlock.orderIdAnalyze = nextBlock.orderIdQuestion = nextBlock.orderId;
        }
      };
    },
  )
  .controller('ReportStack2OverviewWidgetDetailsController', function ($scope, widgetData) {
    $scope.widget = widgetData;
    if (!$scope.widget.data) {
      $scope.widget.data = { text: '' };
    }
  })

  .controller('ReportStack2EditWidgetHeaderController', function ($scope, widget) {
    $scope.widget = widget;
    if (!$scope.widget.data) {
      $scope.widget.data = { text: '' };
    }
  })
  .controller('ReportStack2OverviewWidgetHeaderController', function ($scope, _, widgetData) {
    $scope.widgetData = widgetData;
    if (!$scope.widgetData.data) {
      $scope.widgetData.data = {};
    }
  })

  .controller('ReportStack2EditWidgetFreetextController', function ($scope, widget) {
    $scope.widget = widget;
    if (!$scope.widget.data) {
      $scope.widget.data = { text: '' };
    }
  })
  .controller(
    'ReportStack2OverviewWidgetFreetextController',
    function ($sce, $scope, _, widgetData) {
      $scope.widgetData = widgetData;
      if (!$scope.widgetData.data) {
        $scope.widgetData.data = { text: '' };
      }
      $scope.widgetData.data.$$trustedHtml = $sce.trustAsHtml($scope.widgetData.data.text);
      $scope.widgetData.$$isEditing = false;
      $scope.save = function (widgetData) {
        $scope.widgetData.$$isEditing = false;
        $scope.$parent.save();
        $scope.widgetData.data.$$trustedHtml = $sce.trustAsHtml($scope.widgetData.data.text);
      };
      $scope.clickEditor = function () {
        $scope.widgetData.$$isEditing = true;
      };
    },
  )

  .controller('ReportStack2EditWidgetBundlereviewController', function ($scope, widget) {})
  .controller(
    'ReportStack2OverviewWidgetBundlereviewController',
    function ($scope, _, AcademyService, HttpService, ModalSelector, widgetData) {
      $scope.widgetData = widgetData;
      if (!$scope.widgetData.data) {
        $scope.widgetData.data = { serviceBundles: [] };
      }
      if (!$scope.widgetData.data.serviceBundles) {
        $scope.widgetData.data.serviceBundles = [];
      } else {
        _.forOwn($scope.widgetData.data.serviceBundles, function (bundle) {
          bundle.Services = _.sortBy(bundle.Services, ['name', 'id']);
        });
      }
      $scope.selectToAddTicket = {};
      $scope.allServiceBundles = [];

      if (!$scope.serviceBundles) {
        HttpService.get('/api/service/servicebundles?owner=true').then(function (bundles) {
          $scope.allServiceBundles = _.map(bundles, function (bundle) {
            bundle.Services = _(bundle.Services)
              .map(function (service, id) {
                return _.extend(_.find($scope.services, { id: parseInt(id) }), service);
                // }).filter(function (service) {
                //     var s = _.find($scope.services, {id: service.id});
                //     return s && !s.deletedAt
              })
              .keyBy('id')
              .value();
            bundle.Services = _.sortBy(bundle.Services, ['name', 'id']);

            return bundle;
          });
        });
      } else {
        $scope.allServiceBundles = _.map($scope.serviceBundles, function (bundle) {
          bundle.Services = _(bundle.Services)
            .map(function (service, id) {
              return _.extend(_.find($scope.services, { id: parseInt(id) }), service);
              // }).filter(function (service) {
              //     var s = _.find($scope.services, {id: service.id});
              //     return s && !s.deletedAt
            })
            .keyBy('id')
            .value();
          bundle.Services = _.sortBy(bundle.Services, ['name', 'id']);

          return bundle;
        });
      }
      $scope.rates = [
        { color: '#fc2125', name: 'Red' },
        //{color: '#fd8208', name: 'Orange'},
        { color: '#fec309', name: 'Yellow' },
        { color: '#56d72b', name: 'Green' },
        //{color: '#1d9bf6', name: 'Blue'},
        //{color: '#7b7b7e', name: 'Grey'}
      ];

      $scope.selectServiceRate = function (service, rate) {
        if (service.rate && service.rate == rate) {
          service.rate = null;
        } else {
          service.rate = rate;
        }
        $scope.save();
      };

      $scope.addBundleToWidget = function () {
        ModalSelector.show(
          null,
          _.filter($scope.allServiceBundles, function (bundle) {
            return !_.find($scope.widgetData.data.serviceBundles, { id: bundle.id });
          }),
          function (bundle) {
            if (bundle) {
              $scope.widgetData.data.serviceBundles.push(bundle);
              $scope.save();
            }
          },
          true,
        );
      };
      $scope.removeBundleFromWidget = function (bundle) {
        _.remove($scope.widgetData.data.serviceBundles, function (item) {
          return item.id == bundle.id;
        });
        $scope.save();
      };

      $scope.ticketExpandToggle = function (ticket) {
        ticket.$$expanded = !ticket.$$expanded;
      };

      $scope.addTicket = function (ticketTitle, bundle) {
        if (ticketTitle) {
          bundle.tickets = bundle.tickets || [];
          bundle.tickets.push({ title: ticketTitle, $$expanded: true });
          $scope.selectToAddTicket = {};
          $scope.save();
        }
      };

      $scope.remove = function (ticket, bundle) {
        AcademyService.remove(ticket, bundle.tickets);
        $scope.save();
      };
    },
  )

  .controller('ReportStack2EditWidgetPricecalculatorController', function ($scope, widget) {})
  .controller(
    'ReportStack2OverviewWidgetPricecalculatorController',
    function (
      $scope,
      $rootScope,
      $state,
      _,
      AcademyService,
      CurrentUser,
      HttpService,
      ModalSelector,
      widgetData,
      InputSanitizerService,
    ) {
      $scope.currentUser = CurrentUser.getUser();
      $scope.widgetData = widgetData;
      $scope.widgetData.data = $scope.widgetData.data || { serviceBundles: [] };
      $scope.widgetData.data.serviceBundles =
        _($scope.widgetData.data.serviceBundles)
          .sortBy('orderId')
          .map(function (item, index) {
            return _.extend(item, { orderId: index });
          })
          .value() || [];
      $scope.widgetData.expanded = {
        servicesNotSelected: { show: true },
        servicesCurrent: { show: true },
        servicesFuture: { show: true },
      };
      $scope.recommended = {
        currentScore: $scope.surveyResult.score,
        recommendedScore: $scope.surveyResult.targetScore,
      };

      $scope.selectToAddTicket = {};
      $scope.allServiceBundles = [];
      if (!$scope.serviceBundles) {
        HttpService.get('/api/service/servicebundles?owner=true').then(function (bundles) {
          $scope.allServiceBundles = _.map(bundles, function (bundle) {
            bundle.Services = _(bundle.Services)
              .map(function (service, id) {
                return _.extend(_.find($scope.services, { id: parseInt(id) }), service);
              })
              .keyBy('id')
              .value();
            return bundle;
          });
          calculateRecommended();
        });
      } else {
        $scope.allServiceBundles = _.map($scope.serviceBundles, function (bundle) {
          bundle.Services = _(bundle.Services)
            .map(function (service, id) {
              return _.extend(_.find($scope.services, { id: parseInt(id) }), service);
            })
            .keyBy('id')
            .value();

          return bundle;
        });
        calculateRecommended();
      }

      var isServiceIncluded = function (service) {
        return !!_($scope.surveyResult.blocks)
          .flatMap('statements')
          .flatMap('ongoingService')
          .includes(service.id);
      };

      var isFutureService = function (service) {
        return _.find($scope.surveyResult.ongoingServices, { id: service.id });
      };

      var isCurrentService = function (service) {
        return !!_($scope.surveyResult.blocks)
          .map('statements')
          .flatten()
          .filter(function (statement) {
            return !statement.target && statement.current;
          })
          .map('ongoingService')
          .filter(undefined)
          .uniq()
          .includes(service.id);
      };

      var isNotSelectedService = function (service) {
        return (
          (_($scope.widgetData.data.serviceBundles)
            .flatMap('Services')
            .flatMap(function (services) {
              return _.keys(services);
            })
            .includes(service.id + '') ||
            isServiceIncluded(service)) &&
          !isCurrentService(service) &&
          !isFutureService(service)
        );
      };

      $scope._services = _($scope.services)
        .toArray()
        .filter(function (service) {
          return (
            isServiceIncluded(service) ||
            _($scope.widgetData.data.serviceBundles)
              .flatMap('Services')
              .flatMap(function (services) {
                return _.keys(services);
              })
              .includes(service.id + '')
          );
        })
        .value();

      $scope.isServiceIncluded = isServiceIncluded;

      $scope.reindex = function (array) {
        _.forEach(array, function (item, index) {
          item.orderId = index + 1;
        });
      };

      $scope.onServiceMove = function (index, list) {
        $scope[list].splice(index, 1);
        $scope.reindex($scope[list]);
        $scope.widgetData.data[list] = _.map(
          $scope[list],
          _.partialRight(_.pick, ['id', 'orderId']),
        );
        $scope.save();
      };

      function calculateServices() {
        $scope.futureServices = _($scope._services)
          .filter(isFutureService)
          .sortBy('orderId')
          .value();
        $scope.currentServices = _($scope._services)
          .filter(isCurrentService)
          .sortBy('orderId')
          .value();
        $scope.notSelectedServices = _($scope._services)
          .filter(isNotSelectedService)
          .sortBy('orderId')
          .value();
        $scope.reindex($scope.futureServices);
        $scope.reindex($scope.currentServices);
        $scope.reindex($scope.notSelectedServices);
        $scope.widgetData.data.futureServices = _.map(
          $scope.futureServices,
          _.partialRight(_.pick, ['id', 'orderId']),
        );
        $scope.widgetData.data.currentServices = _.map(
          $scope.currentServices,
          _.partialRight(_.pick, ['id', 'orderId']),
        );
        $scope.widgetData.data.notSelectedServices = _.map(
          $scope.notSelectedServices,
          _.partialRight(_.pick, ['id', 'orderId']),
        );
        $scope.save();
      }

      if ($scope.widgetData.data.futureServices) {
        var f = function (list) {
          return _($scope._services)
            .map(function (_service) {
              var service = _.find(list, { id: _service.id });
              if (service) {
                return _.extend(_service, { orderId: service.orderId });
              } else {
                return null;
              }
            })
            .filter(null)
            .orderBy('orderId')
            .value();
        };
        $scope.futureServices = f($scope.widgetData.data.futureServices);
        $scope.currentServices = f($scope.widgetData.data.currentServices);
        $scope.notSelectedServices = f($scope.widgetData.data.notSelectedServices);
      } else {
        calculateServices();
      }

      $scope.calculatePrice = function (bundle) {
        if (bundle) {
          if (bundle.defaults.pricing === 'unit') {
            return bundle.defaults.unitPrice * bundle.units;
          } else {
            return bundle.defaults.monthlyPrice;
          }
        } else {
          return undefined;
        }
      };

      $scope.addBundleToWidget = function () {
        ModalSelector.show(
          null,
          _.filter($scope.allServiceBundles, function (bundle) {
            return !_.find($scope.widgetData.data.serviceBundles, { id: bundle.id });
          }),
          function (bundle) {
            if (bundle) {
              $scope.widgetData.data.serviceBundles.push(
                _.extend(bundle, { orderId: $scope.widgetData.data.serviceBundles.length }),
              );
              calculateServices();
              $scope.save();
            }
          },
          true,
        );
      };

      $scope.removeBundleFromWidget = function (bundle) {
        _.remove($scope.widgetData.data.serviceBundles, { id: bundle.id });
        calculateServices();
        $scope.save();
      };

      function calculateRecommended() {
        var recommendedSurveyResult = _.cloneDeep($scope.surveyResult);

        recommendedSurveyResult.ongoingServices = _.union(
          recommendedSurveyResult.ongoingServices,
          _($scope.widgetData.data.serviceBundles)
            .filter({ recommended: true })
            .flatMap('Services')
            .flatMap(function (services) {
              return _.values(services);
            })
            .map('id')
            .filter(undefined)
            .map(function (serviceId) {
              return { id: serviceId };
            })
            .value(),
        );

        _.forEach(recommendedSurveyResult.blocks, function (block) {
          _.forEach(block.statements, function (statement) {
            if (_.find(recommendedSurveyResult.ongoingServices, { id: statement.ongoingService })) {
              statement.checked = true;
            }
          });
        });

        _.forEach($scope.surveyResult.blocks, function (block) {
          _.forEach(block.statements, function (statement) {
            statement.$$recommended = false;
            // if (_.find(recommendedSurveyResult.ongoingServices, {id: statement.ongoingService}) /*&&
            //     !_.find($scope.surveyResult.ongoingServices, {id: statement.ongoingService})*/) {
            if (
              _($scope.widgetData.data.serviceBundles)
                .filter({ recommended: true })
                .flatMap('Services')
                .flatMap(function (services) {
                  return _.values(services);
                })
                .map('id')
                .filter(undefined)
                .includes(statement.ongoingService)
            ) {
              statement.$$recommended = true;
            }
          });
        });

        HttpService.post('/api/surveyresults/calculate', {
          surveyResult: recommendedSurveyResult,
        }).then(function (result) {
          $scope.widgetData.data.recommended = {
            currentScore: result.score,
            recommendedScore: result.targetScore,
          };
        });
      }

      $scope.recommendBundle = function (bundle) {
        bundle.recommended = !bundle.recommended;
        calculateRecommended();
      };

      // calculateServices();

      $scope.$on('overviewRefreshed', function () {
        calculateServices();
        calculateRecommended();
      });

      $scope.shiftBundleLeft = function (bundle) {
        if (bundle.orderId > 0) {
          _.forEach($scope.widgetData.data.serviceBundles, function (_bundle) {
            if (_bundle.orderId == bundle.orderId - 1) {
              _bundle.orderId = bundle.orderId;
            }
          });
          bundle.orderId = bundle.orderId - 1;
        }
        $scope.save();
      };

      $scope.shiftBundleRight = function (bundle) {
        if (bundle.orderId < $scope.widgetData.data.serviceBundles.length - 1) {
          _.forEach($scope.widgetData.data.serviceBundles, function (_bundle) {
            if (_bundle.orderId == bundle.orderId + 1) {
              _bundle.orderId = bundle.orderId;
            }
          });
          bundle.orderId = bundle.orderId + 1;
        }
        $scope.save();
      };

      $scope.createContract = function () {
        if (!_.isEmpty($scope.widgetData.data.serviceBundles)) {
          var services = {};
          _.forEach($scope.widgetData.data.serviceBundles, function (bundle) {
            services = _.extend(services, $scope.allServiceBundles[bundle.id].Services);
          });

          var contract = {
            type: 'ongoing',
            name: $scope.surveyResult.Survey.name,
            // comment: bundle.comment,
            defaults: $scope.widgetData.data.serviceBundles[0].defaults,
            Services: services,
            SurveyResultId: $scope.surveyResult.id,
          };

          HttpService.post(
            '/api/vcio/contracts' +
              '?clientId=' +
              InputSanitizerService.sanitize($scope.surveyResult.ClientId),
            {
              contract: contract,
            },
          )
            .then(function (result) {
              $state.go('vcio.contractEdit', { contractId: result.id });
            })
            .catch(function (error) {
              $scope.errors = [error];
            });
        }
      };
    },
  )

  .service('OverviewStack2ChartService', function (_) {
    var ranges = [
      { min: 0, max: 20, name: '0-20', length: 20, color: '#ea413d', class: 'red' },
      { min: 20, max: 40, name: '20-40', length: 20, color: '#ed742f', class: 'orange' },
      { min: 40, max: 60, name: '40-60', length: 20, color: '#f5be41', class: 'yellow' },
      { min: 60, max: 80, name: '60-80', length: 20, color: '#a8be4b', class: 'green' },
      { min: 80, max: 100, name: '80-100', length: 20, color: '#59bf90', class: 'darkgreen' },
    ];

    // var round = function (num, decimals) {
    //     return Math.round(num * (10 * decimals)) / (10 * decimals)
    // };

    var createHorizontalChartData = function (targetScore, score) {
      return {
        data: {
          //TODO check if targeted score is less than the user score
          labels: [''],
          datasets: [
            {
              label: 'Current',
              data: [score],
              backgroundColor: '#62A6DB',
              hoverBackgroundColor: '#62A6DB',
            },
            {
              label: 'Targeted',
              data: [targetScore - score],
              backgroundColor: '#59BF90',
              hoverBackgroundColor: '#59BF90',
            },
            {
              data: [100 - targetScore],
              backgroundColor: '#EEEEEE',
              hoverBackgroundColor: 'white',
            },
          ],
        },
        options: {
          scales: {
            xAxes: [{ stacked: true }],
            yAxes: [{ stacked: true }],
          },
          responsive: true,
          animation: false,
          showTooltips: false,
        },
      };
    };

    var createDimensionData = function (dimension) {
      dimension.horizontalChartData = createHorizontalChartData(
        dimension.targetScore,
        dimension.score,
      );
      dimension.hint = 'dimension.' + dimension.code + '.hint';
      // if (dimension.blocks) {
      //     _.forOwn(dimension.blocks, function (block) {
      //         block.horizontalChartData = createHorizontalChartData(block.targetScore, block.score);
      //     })
      // }
    };

    var setRangesData = function (surveyRanges) {
      if (surveyRanges) {
        _.forEach(ranges, function (range, index) {
          if (surveyRanges[index] && surveyRanges[index].color) {
            range.color = surveyRanges[index].color;
          }
        });
      }
    };

    var createChartData = function (scores) {
      scores.horizontalChartData = createHorizontalChartData(scores.targetScore, scores.score);
      // scores.execution.horizontalChartData = createHorizontalChartData(scores.execution.targetScore, scores.execution.score);
      // scores.management.horizontalChartData = createHorizontalChartData(scores.management.targetScore, scores.management.score);

      scores.dimensions.forEach(function (dimension) {
        createDimensionData(dimension);
      });
      scores.blocks.forEach(function (block) {
        block.horizontalChartData = createHorizontalChartData(block.targetScore, block.score);
      });
    };
    return {
      ranges: ranges,
      setRangesData: setRangesData,
      createChartData: createChartData,
    };
  })

  .controller('OverviewStack2BlocksController', function ($rootScope, $scope, _, AcademyService) {
    $scope.selectToAddTicket = {};

    $scope.statementCheckboxStyle = function (statement, block, type) {
      if (!_.has(statement, type)) {
        statement[type] = false;
      }
      var result = '';
      if (block.answerType === 'single') {
        if (statement[type]) {
          result = 'fa-dot-circle-o';
        } else {
          result = 'fa-circle-o';
        }
      } else {
        if (statement[type]) {
          result = 'fa-check-square-o';
        } else {
          result = 'fa-square-o';
        }
      }
      if (type == 'target' && statement.current && block.answerType === 'multiple') {
        result = 'fa-check-square-o disabled';
      }

      return result;
    };

    $scope.statementCheckboxChange = function (statement, block, type) {
      statement[type] = !statement[type];
      var _statement = _.find(block.statements, { id: statement.id });
      _statement.checked = statement.target;
      _statement.checkedByUser = statement.current;

      if (block.answerType === 'single') {
        if (_statement.checked) {
          block.statements.forEach(function (other) {
            if (_statement.id != other.id && other.checked) {
              other.checked = false;
              other.target = false;
            }
          });
        }
        if (_statement.checkedByUser) {
          block.statements.forEach(function (other) {
            if (_statement.id != other.id && other.checkedByUser) {
              other.checkedByUser = false;
              other.current = false;
            }
          });
        }
      }
      $scope.saveBlock(block);
    };

    $scope.remove = function (ticket, block) {
      AcademyService.remove(ticket, block.tickets);
      $scope.saveBlock(block);
    };

    $scope.ticketExpandToggle = function (ticket) {
      ticket.$$expanded = !ticket.$$expanded;
    };

    $scope.addTicket = function (ticketTitle, block) {
      if (ticketTitle) {
        block.tickets = block.tickets || [];
        block.tickets.push({ title: ticketTitle, $$expanded: true });
        $scope.selectToAddTicket = {};
        $scope.saveBlock(block);
      }
    };

    $scope.blockHiddenToggle = function (block) {
      block.hidden = !block.hidden;
      $scope.saveBlock(block);
    };
  })

  .controller(
    'ReportExportsController',
    function ($rootScope, $scope, $state, $window, DialogService, HttpService, exports, surveys) {
      $scope.reportExports = exports;
      $scope.surveys = surveys;
      $scope.newReportExport = {};
      $scope.filter = { name: '' };

      $scope.download = function (reportExport) {
        $window.open(
          'https://' +
            $window.location.hostname +
            ($window.location.port ? ':' + $window.location.port : '') +
            '/api/sales/reports/exports/' +
            reportExport.id +
            '/compare.csv?token=' +
            reportExport.csvToken,
        );
      };

      $scope.save = function (newReportExport, cb) {
        var req;
        if (newReportExport.id) {
          req = HttpService.put('/api/sales/reports/exports/' + newReportExport.id, {
            exportSetup: newReportExport,
          });
        } else {
          req = HttpService.post('/api/sales/reports/exports', { exportSetup: newReportExport });
        }
        $rootScope.$broadcast('dataLoadingStarted');
        req.then(
          function (result) {
            if (newReportExport.id) {
              newReportExport.isEdit = false;
            } else {
              $scope.reportExports.push(result);
              $scope.newReportExport = {};
            }
            $rootScope.$broadcast('dataLoadingFinished');
            if (cb) {
              cb();
            }
          },
          function (error) {
            if (cb) {
              cb();
            }
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
            console.error(error);
          },
        );
      };

      $scope.cancel = function (newReportExport) {
        if (newReportExport.id) {
          newReportExport.isEdit = false;
          _.forEach(newReportExport.oldValues, function (v, k) {
            newReportExport[k] = v;
          });
        } else {
          $scope.newReportExport = {};
        }
      };

      $scope.nameFilter = function (item) {
        return (
          !$scope.filter.name ||
          (item.name && item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1) ||
          (item.Survey &&
            item.Survey.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1)
        );
      };

      $scope.delete = function (reportExport) {
        HttpService.delete('/api/sales/reports/exports/' + reportExport.id)
          .then(function (result) {
            _.remove($scope.reportExports, reportExport);
          })
          .catch(function (error) {
            DialogService.error(error);
          });
      };

      $scope.editReportExport = function (reportExport) {
        reportExport.oldValues = _.cloneDeep(reportExport);
        reportExport.isEdit = true;
      };
    },
  )

  .controller(
    'ReportExportEditController',
    function (
      $location,
      $rootScope,
      $scope,
      $state,
      $translate,
      $window,
      _,
      AcademyService,
      DialogService,
      HttpService,
      uuid,
      reportExport,
      InputSanitizerService,
    ) {
      $scope.reportExport = reportExport;
      $scope.survey = reportExport.Survey;
      $scope.surveyResultList = [];
      $scope.dimensionList = $scope.survey.dimensions;
      $scope.blockList = $scope.survey.blocks;
      $scope.statementList = _.flatMap($scope.survey.blocks, function (b) {
        return _.forOwn(b.statements, function (s) {
          s.blockId = b.id;
          s.uuid = uuid.v4();
        });
      });
      $scope.scoreTypes = [
        { id: 'current', name: $translate.instant('reports.currentScore') },
        {
          id: 'target',
          name: $translate.instant('reports.targetScore'),
        },
      ];
      $scope.reportExport.scoreType = $scope.reportExport.scoreType || 'current';
      $scope.currentView = 'surveyresults';
      $scope.select = {};
      $scope.changed = false;

      HttpService.get('/api/surveyresults?surveyId=' + reportExport.Survey.id).then(function (
        surveyResults,
      ) {
        $scope.surveyResultList = _.map(surveyResults, function (surveyResult) {
          return _.extend(surveyResult, {
            name:
              surveyResult.title +
              (surveyResult.Contact
                ? ' (' + surveyResult.Contact.firstName + ' ' + surveyResult.Contact.lastName + ')'
                : ''),
          });
        });
      });

      $scope.buttons = [
        {
          color: 'default',
          label: 'Download',
          click: function () {
            if ($scope.changed) {
              DialogService.warning('label.sales.reportExports.saveBefore');
            } else {
              $window.open(
                'https://' +
                  $window.location.hostname +
                  ($window.location.port ? ':' + $window.location.port : '') +
                  '/api/sales/reports/exports/' +
                  InputSanitizerService.sanitize($scope.reportExport.id) +
                  '/compare.csv?token=' +
                  InputSanitizerService.sanitize($scope.reportExport.csvToken),
              );
            }
          },
        },
      ];

      $scope.$watch('reportExport.SurveyResults', function (oldSurveys, newSurveys) {
        if (_.differenceBy(oldSurveys, newSurveys, 'id').length > 0) {
          $scope.changed = true;
        }
      });

      $scope.showAdd = function (type) {
        if ($scope.reportExport.Survey.type == 'mpd') {
          return type == 'statement';
        } else {
          return true;
        }
      };

      $scope.save = function (cb) {
        HttpService.put(
          '/api/sales/reports/exports/' + InputSanitizerService.sanitize($scope.reportExport.id),
          { exportSetup: $scope.reportExport },
        ).then(
          function (result) {
            $scope.messages = ['message.saved'];
            $scope.changed = false;
            cb();
          },
          function (error) {
            $scope.errors = [error];
            cb();
          },
        );
      };

      $scope.cancel = function () {
        $state.go('sales.reportExports');
      };

      $scope.surveyResultSearch = function (item, searchText) {
        return (
          item &&
          item.Client &&
          (_.includes(_.toLower(item.Client.name), _.toLower(searchText)) ||
            _.includes(_.toLower(item.name), _.toLower(searchText)))
        );
      };
      $scope.surveyResultFilter = function (item) {
        return $scope.reportExport && !_.find($scope.reportExport.SurveyResults, { id: item.id });
      };
      $scope.surveyResultGroupBy = function (item) {
        return item && item.Client ? item.Client.name : '';
      };
      $scope.surveyResultGroupOrder = function (groups) {
        return _.sortBy(groups, 'name');
      };

      // $scope.addSurveyResult = function (surveyResult) {
      //     if (!$scope.reportExport.SurveyResults) {
      //         $scope.reportExport.surveyResults = [];
      //     }
      //     if (surveyResult) {
      //         $scope.reportExport.SurveyResults.push(surveyResult);
      //     }
      //     $scope.select.surveyResult = {};
      // };
      // $scope.removeSurveyResult = function (surveyResult, list) {
      //     AcademyService.remove(surveyResult, list);
      // };

      $scope.fieldName = function (field) {
        if (field.type == 'dimension') {
          return _.get(_.find($scope.dimensionList, { code: field.id }), 'name');
        } else if (field.type == 'block') {
          var block = _.find($scope.blockList, { id: field.id });
          var dimension = _.find($scope.dimensionList, { code: block.dimension });
          return _.get(dimension, 'name') + '/' + _.get(block, 'name');
        } else if (field.type == 'statement') {
          var statement = _.find($scope.statementList, { blockId: field.blockId, id: field.id });
          var block = _.find($scope.blockList, { id: field.blockId });
          var dimension = _.find($scope.dimensionList, { code: block.dimension });
          return (
            _.get(dimension, 'name') + '/' + _.get(block, 'name') + '/' + _.get(statement, 'name')
          );
        }
      };
      $scope.removeField = function (field, list) {
        AcademyService.remove(field, list);
        $scope.changed = true;
      };

      $scope.dimensionFilter = function (item) {
        return $scope.reportExport && !_.find($scope.reportExport.dimensions, { id: item.code });
      };
      $scope.addDimension = function (dimension) {
        if (!$scope.reportExport.fields) {
          $scope.reportExport.fields = [];
        }
        if (dimension && !_.find($scope.reportExport.fields, { id: dimension.code })) {
          $scope.reportExport.fields.push({
            type: 'dimension',
            name: dimension.name,
            id: dimension.code,
          });
        }
        $scope.select.dimension = undefined;
        $scope.changed = true;
      };

      $scope.blockFilter = function (item) {
        return (
          $scope.reportExport &&
          !_.find($scope.reportExport.blocks, { id: item.id }) &&
          (!$scope.select.dimension ||
            ($scope.select.dimension && item.dimension == $scope.select.dimension.code))
        );
      };
      $scope.addBlock = function (block) {
        if (!$scope.reportExport.fields) {
          $scope.reportExport.fields = [];
        }
        if (block && !_.find($scope.reportExport.fields, { id: block.id })) {
          $scope.reportExport.fields.push({
            type: 'block',
            name: _.find($scope.dimensionList, { code: block.dimension }).name + ' - ' + block.name,
            id: block.id,
          });
        }
        $scope.select.block = undefined;
        $scope.changed = true;
      };

      $scope.statementFilter = function (item) {
        return (
          $scope.reportExport &&
          !_.find($scope.reportExport.statements, {
            id: item.id,
            blockId: item.blockId,
          }) &&
          (!$scope.select.block || ($scope.select.block && item.blockId == $scope.select.block.id))
        );
      };
      $scope.addStatement = function (statement) {
        if (!$scope.reportExport.fields) {
          $scope.reportExport.fields = [];
        }
        if (
          statement &&
          !_.find($scope.reportExport.fields, { blockId: statement.blockId, id: statement.id })
        ) {
          var block = _.find($scope.blockList, { id: statement.blockId });
          var dimension = _.find($scope.dimensionList, { code: block.dimension });
          $scope.reportExport.fields.push({
            type: 'statement',
            name: dimension.name + ' - ' + block.name + ' - ' + statement.name,
            id: statement.id,
            blockId: statement.blockId,
          });
        }
        $scope.select.statement = undefined;
        $scope.changed = true;
      };
    },
  );
