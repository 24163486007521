angular
  .module('vcio-toolkit')
  .controller('LibraryController', function ($scope, $stateParams, $http, $modal, CurrentUser) {
    $scope.root = 'eBooks';

    $scope.refresh = function () {
      $http
        .get('/api/library/' + $scope.root)
        .success(function (result) {
          $scope.docs = result;
        })
        .error(function (error) {
          console.error(error);
        });
    };

    $scope.$watch('root', function (value) {
      $scope.refresh();
    });

    $scope.canAdd = function () {
      return CurrentUser.can('uploadLibrary');
    };

    $scope.openUpload = function () {
      var modalInstance = $modal.open({
        templateUrl: '/templates/admin/library-upload.html',
        controller: 'LibraryUploadController',
        backdrop: 'static',
        resolve: {
          root: function () {
            return $scope.root;
          },
        },
      });

      modalInstance.result.then(
        function (company) {
          $scope.refresh();
        },
        function () {},
      );
    };

    $scope.getClass = function (doc) {
      if (doc.name.indexOf('.pdf', doc.name.length - '.pdf'.length) !== -1) {
        return 'fa-file-pdf-o';
      } else {
        return 'fa-file-word-o';
      }
    };
  })
  .controller(
    'LibraryUploadController',
    function ($scope, $rootScope, $stateParams, $http, $modalInstance, Upload, $timeout, root) {
      $scope.root = root;
      $scope.myFiles = [];
      $scope.ok = function () {
        if ($scope.file2upload) {
          $scope.upload($scope.file2upload, function (error) {
            if (!error) {
              $modalInstance.close();
            }
          });
        }
      };

      $scope.cancel = function () {
        $modalInstance.close();
      };

      $scope.preview = function (files) {
        var file = _.get(files, 0);
        if (file) {
          $timeout(function () {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
              $timeout(function () {
                file.dataUrl = e.target.result;
                $scope.file2upload = file;
              });
            };
          });
        }
      };

      $scope.upload = function (file, cb) {
        Upload.upload({
          url: '/api/library/' + $scope.root,
          method: 'POST',
          withCredentials: true,
          file: file,
        })
          .progress(function (evt) {
            //console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '% file :' + evt.config.file.name);
            $scope.uploadPercentage = parseInt((100.0 * evt.loaded) / evt.total);
          })
          .success(function (data, status, headers, config) {
            console.log(
              'file ' +
                $rootScope.replaceNewLineCharacters(config.data.file.name) +
                'is uploaded successfully. Response: ' +
                $rootScope.replaceNewLineCharacters(JSON.stringify(data)),
            );
            cb();
          })
          .error(function (error) {
            cb(error);
          });
      };
    },
  );
