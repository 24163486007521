angular
  .module('vcio-toolkit')

  .controller(
    'ClientCesController',
    function (
      $modal,
      $moment,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $timeout,
      $translate,
      _,
      CesClientPlaybookService,
      CesService,
      CurrentUser,
      DialogService,
      HttpService,
      SidebarFilterService,
      UserEventService,
      client,
      clientSegments,
      contactSeniorities,
      activities,
      interactionTypes,
    ) {
      $scope.client = client;
      $scope.clientSegments = clientSegments;
      $scope.cesActivities = activities;
      $scope.currentUser = $scope.currentUser || CurrentUser.getUser();
      $scope.newCesActivity = { expanded: false, type: 'plan', contactIds: [] };
      $scope.chart = {};
      $scope.interactionTypes = interactionTypes;
      $scope.contactSeniorities = contactSeniorities;
      $scope.getActivityType = CesService.getActivityType;
      $scope.getActivityClass = CesService.getActivityClass;
      $scope.hasContactSeniority = false;

      _.forEach($scope.client.Contacts, function (contact) {
        if (contact.Seniorities && contact.Seniorities.length) {
          $scope.hasContactSeniority = true;
        }
      });

      $scope.orderByFilter = function () {
        var selected = _.find($scope.filter.order, { selected: true });
        if (selected) {
          return selected;
        } else {
          return {
            orderField: 'day',
            reverse: true,
          };
        }
      };

      $scope.filter = SidebarFilterService.get('cesclientactivities').setLists({
        interactionType: _.filter($scope.interactionTypes, function (interactionType) {
          return !(interactionType.OwnerId === null && interactionType.type === 'custom');
        }),
      }).filter;

      $scope.activityFilter = function (activity) {
        var types = _($scope.filter.type).filter({ selected: true }).map('code').value();
        var interactionTypes = _($scope.filter.interactionType)
          .filter({ selected: true })
          .map('id')
          .value();
        return (
          (_.isEmpty(types) || _.includes(types, $scope.getActivityType(activity))) &&
          (_.isEmpty(interactionTypes) ||
            _.includes(interactionTypes, activity.InteractionTypeId)) &&
          (activity.type === 'actual' || !activity.ActualId)
        );
      };

      function refreshChart() {
        $scope.chart = CesService.createChartData(
          _.filter($scope.cesActivities, $scope.activityFilter),
          $scope.client.ClientSegment,
        );
      }

      $scope.$watch(
        'filter',
        function (newVal, oldVal) {
          // $scope.fromDay = $moment($scope.filter.fromDay.value);
          // $scope.toDay = $moment($scope.filter.toDay.value);
          // if ($scope.fromDay.format('YYYYMMDD') != $moment(oldVal.fromDay.value).format('YYYYMMDD') || $scope.toDay.format('YYYYMMDD') != $moment(oldVal.toDay.value).format('YYYYMMDD')) {
          //     $rootScope.$broadcast('dataLoadingStarted');
          //     HttpService.get('/api/ces/activities?fromDay=' + $scope.fromDay.format('YYYYMMDD') + '&toDay=' + $scope.toDay.format('YYYYMMDD'))
          //         .then(function (result) {
          //             $scope.cesActivities = result;
          //             refreshChart();
          //             $rootScope.$broadcast('dataLoadingFinished');
          //         });
          // } else {
          refreshChart();
          // }
        },
        true,
      );

      $scope.$watch('client.ClientSegment', function (newVal, oldVal) {
        refreshChart();
      });

      $scope.saveCesActivity = function (error) {
        if (!error) {
          if (!$scope.newCesActivity.oldValues || !$scope.newCesActivity.oldValues.id) {
            $scope.cesActivities.push($scope.newCesActivity);
          }
          delete $scope.newCesActivity.oldValues;
          $scope.newCesActivity.isEdit = false;
          $scope.newCesActivity = { type: 'plan' };
          refreshChart();
        }
      };

      $scope.cancelEditCesActivity = function () {
        var newCesActivity =
          _.find($scope.cesActivities, { isEdit: true }) || $scope.newCesActivity;
        if (newCesActivity) {
          if (newCesActivity.oldValues) {
            _.forEach(newCesActivity.oldValues, function (value, index) {
              newCesActivity[index] = value;
            });
            delete newCesActivity.oldValues;
          }
          newCesActivity.isEdit = false;
          $scope.newCesActivity = {
            date: $moment().add(1, 'month').toDate(),
            type: 'plan',
            contactIds: [],
          };
        }
      };

      $scope.editCesActivity = function (cesActivity) {
        $scope.cancelEditCesActivity();
        $scope.newCesActivity = cesActivity;
        cesActivity.date = cesActivity.day
          ? $moment(cesActivity.day, 'YYYYMMDD').toDate()
          : new Date();

        cesActivity.contactIds = [];
        _.forEach(cesActivity.Contacts, function (contact) {
          cesActivity.contactIds.push(contact.id);
        });

        cesActivity.oldValues = _.cloneDeep(cesActivity);
        cesActivity.isEdit = true;
      };

      $scope.addNewCesActivity = function () {
        // $scope.editCesActivity($scope.newCesActivity);
        $scope.cancelEditCesActivity();

        const contact = _.maxBy($scope.client.Contacts, function (contact) {
          const maxSeniority = _.maxBy(contact.Seniorities, 'factor');
          return _.get(
            _.find($scope.contactSeniorities, { id: _.get(maxSeniority, 'id') }),
            'factor',
          );
        });
        if (contact) {
          $scope.newCesActivity.contactIds.push(contact.id);
        }

        $scope.newCesActivity.isEdit = true;
        $scope.newCesActivity.expanded = true;
      };

      $scope.realizeCesActivity = function (cesActivity) {
        $scope.cancelEditCesActivity();
        cesActivity.oldValues = _.cloneDeep(cesActivity);
        cesActivity.type = 'actual';
        cesActivity.PlanId = cesActivity.id;
        cesActivity.externalTicketUrl = cesActivity.externalTicketUrl;
        cesActivity.externalTicketId = cesActivity.externalTicketId;
        cesActivity.PlanId = cesActivity.id;
        cesActivity.id = undefined;
        cesActivity.date = $moment().startOf('day');
        $scope.newCesActivity = cesActivity;
        cesActivity.contactIds = [];
        _.forEach(cesActivity.Contacts, function (contact) {
          cesActivity.contactIds.push(contact.id);
        });

        cesActivity.isEdit = true;
      };

      $scope.delete = function (cesActivity) {
        HttpService.delete('/api/ces/activities/' + cesActivity.id).then(
          function (result) {
            _.remove($scope.cesActivities, cesActivity);
            refreshChart();
          },
          function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
            console.error(error);
          },
        );
      };

      $scope.assignToSegment = function () {
        var itemToday = $moment().endOf('month').format('YYYYMMDD');
        $scope.addTemplate(itemToday);
      };

      $scope.applySegmentRoadmap = function () {
        CesClientPlaybookService.applySegmentRoadmap(client, contactSeniorities, function (result) {
          UserEventService.event('clientCesPlaybookApplied');
          if (result === 'error' || result !== null) {
            $state.reload();
          }
        });
      };

      $scope.editSegmentRoadmap = function () {
        $state.go('msp.clientSegment', { segmentId: $scope.client.ClientSegment.id });
      };

      $scope.scrollToElement = function (elementId) {
        var el = document.getElementById(elementId);
        if (el) {
          window.scrollTo(0, el.offsetTop);
        }
      };
      if ($stateParams.eventId) {
        $timeout(function () {
          $scope.scrollToElement('eventId-' + $stateParams.eventId);
        }, 500);
      }

      UserEventService.event('clientCesOpened');
    },
  )

  // .controller('CesActivityEditModalController', function ($rootScope, $modalInstance, $moment, $scope, _, DialogService, HttpService, data) {
  //     $scope.title = data.title || 'label.clients.createEngagementActivity';
  //     $scope.modalInstance = $modalInstance;
  //     $scope.interactionTypes = data.interactionTypes;
  //     $scope.contactSeniorities = data.contactSeniorities;
  //     $scope.client = data.client;
  //     $scope.hide = {
  //         type: (data.hideType ? data.hideType : false),
  //         interactionType: (data.hideInteractionType ? data.hideInteractionType : false),
  //         instance: (data.hideInstance ? data.hideInstance : false),
  //         date: (data.hideDate ? data.hideDate : false)
  //     };
  //     $scope.disable = {
  //         interactionType: (data.disableInteractionType ? data.disableInteractionType : false)
  //     };
  //     $scope.newCesActivity = {
  //         type: data.type,
  //         assetType: data.assetType,
  //         assetId: (data.assetType ? data.asset.id : null),
  //         name: (data.asset.title ? data.asset.title : data.asset.name),
  //         InteractionTypeId: (data.InteractionTypeId ? data.InteractionTypeId : data.asset.InteractionTypeId),
  //         InstanceId: data.asset.InstanceId,
  //         date: $moment().toDate(),
  //         contactIds: (data.asset.Contacts) ? _.map(data.asset.Contacts, 'id') : [data.asset.ContactId],
  //         template: data.assetTemplate,
  //         PlanId: data.planActivityId
  //     };
  //     $scope.selected = {};
  //     if (data.clients) {
  //         $scope.clientList = data.clients;
  //     }
  //     if (data.cesActivities) {
  //         $scope.cesActivities = _.filter(data.cesActivities, function (o) {
  //             return o.type === 'actual' && !_.find(data.cesActivities, {ActualId: o.id});
  //         });
  //     }
  //     $scope.setOption = function (option) {
  //         $scope.sendOption = option;
  //     };
  //     $scope.save = function () {
  //         $modalInstance.close($scope.newCesActivity);
  //     };
  //     $scope.saveLinked = function () {
  //         $rootScope.$broadcast('dataLoadingStarted');
  //         var newCesActivity = _.pick(data.asset, ['id']);
  //         newCesActivity.ActualId = $scope.selected.activity.id;
  //         HttpService.post('/api/ces/activities/' + newCesActivity.id, {activity: newCesActivity}).then(function (result) {
  //             $rootScope.$broadcast('dataLoadingFinished');
  //             $modalInstance.close($scope.selected.activity.id);
  //         }, function (error) {
  //             $rootScope.$broadcast('dataLoadingFinished');
  //             DialogService.error(error);
  //             console.error(error);
  //         });
  //     };
  //     $scope.cancel = function () {
  //         $modalInstance.dismiss();
  //     };
  // })
  .controller(
    'CesActivityEditController',
    function (
      $filter,
      $modal,
      $moment,
      $q,
      $rootScope,
      $scope,
      $translate,
      _,
      HttpService,
      DialogService,
      UserEventService,
      InputSanitizerService,
    ) {
      $scope.planActivityList = [];
      $scope.assetList = [];
      $scope.instanceList = [];
      $scope.interactionType = null;
      $scope.today = $moment().startOf('day');
      $scope.hide = $scope.hide || {};

      function verifyActivity() {
        var result = true;
        /*if ($scope.newCesActivity.type === 'plan' && $moment($scope.newCesActivity.date).startOf('day').isBefore($scope.today)) {
                $scope.errorMessge = $translate.instant('userError.client.ces.planInPast');
                result = false;
            } else*/
        if (
          $scope.newCesActivity.type === 'actual' &&
          $moment($scope.newCesActivity.date).startOf('day').isAfter($scope.today)
        ) {
          $scope.errorMessge = $translate.instant('userError.client.ces.actualInFuture');
          result = false;
        }
        return result;
      }

      $scope.$watch('newCesActivity.type', function (val) {
        if (!$scope.newCesActivity.id) {
          if (val === 'plan') {
            $scope.newCesActivity.date = $moment().add(1, 'month').toDate();
          } else if (val === 'actual') {
            $scope.newCesActivity.date = $moment().toDate();
          }
        }
      });

      $scope.$watch(
        'newCesActivity.InteractionType',
        function (interactionType, oldInteractionType) {
          if (!$scope.hide.instance) {
            if (interactionType && interactionType !== oldInteractionType) {
              $scope.newCesActivity.InteractionTypeId = interactionType.id;
              if (
                !$scope.newCesActivity.name ||
                (oldInteractionType && $scope.newCesActivity.name === oldInteractionType.name)
              ) {
                // if the user has chaged the name, we don't change it
                $scope.newCesActivity.name = interactionType.name;
              }
              // $scope.instanceList = [];
              // if (interactionType.type !== 'custom') {
              //     $scope.instanceList = _.filter($scope.assetList, {interactionTypeId: interactionType.id});
              if (
                $scope.newCesActivity.Instance &&
                $scope.newCesActivity.Instance.InteractionTypeId !=
                  $scope.newCesActivity.InteractionTypeId
              ) {
                $scope.newCesActivity.InstanceId = undefined;
              }
              // }
              // if ($scope.cesActivities) {
              //     $scope.planActivityList = _.filter($scope.cesActivities, function (o) {
              //         return o.type === 'plan' && o.InteractionTypeId == interactionType.id;
              //     });
              // }
            } else if (!interactionType) {
              $scope.newCesActivity.InteractionTypeId = undefined;
            }
          }
        },
      );

      $scope.assetListFilter = function (asset) {
        return asset && asset.interactionTypeId === $scope.newCesActivity.InteractionTypeId;
      };
      $scope.interactionTypeFilter = function (item) {
        return ($scope.disable && $scope.disable.interactionType) || $scope.newCesActivity.type; // === 'plan' || item.type === 'custom';
      };
      $scope.interactionTypeGroupBy = function (item) {
        if (item.type === 'custom') {
          return $translate.instant('label.client.ces.interactionTypes.manual');
        } else {
          return $translate.instant('label.client.ces.interactionTypes.automatic');
        }
      };

      var refreshInstance = function (instanceId) {
        if (!$scope.hide.instance) {
          if (instanceId) {
            $scope.newCesActivity.Instance = _.find($scope.assetList, {
              id: instanceId,
              interactionTypeId: $scope.newCesActivity.InteractionTypeId,
            });
            if (
              $scope.newCesActivity.Instance &&
              (!$scope.newCesActivity.name ||
                $scope.newCesActivity.name === $scope.newCesActivity.InteractionType.name)
            ) {
              $scope.newCesActivity.name = $scope.newCesActivity.Instance.title;
            }
          } else {
            $scope.newCesActivity.Instance = undefined;
          }
        }
      };

      $scope.$watch('newCesActivity.InstanceId', function (instanceId, oldInstanceId) {
        refreshInstance(instanceId);
      });

      $scope.changeClient = function (client) {
        $scope.client = client;
        HttpService.get('/api/contacts?clientId=' + InputSanitizerService.sanitize(client.id)).then(
          function (contacts) {
            $scope.clientContacts = _.map(contacts, function (contact) {
              return { id: contact.id, name: contact.firstName + ' ' + contact.lastName };
            });
            $scope.newCesActivity.contactIds = _.filter(
              $scope.newCesActivity.contactIds,
              function (o) {
                return _.find($scope.clientContacts, { id: o });
              },
            );
            if ($scope.newCesActivity.contactIds.length === 0) {
              var contact = _.maxBy(client.Contacts, function (contact) {
                const maxSeniorityId = _.maxBy(contact.Seniorities, function (s) {
                  return _.get(s, 'factor');
                });
                return _.get(_.find($scope.contactSeniorities, { id: maxSeniorityId }), 'factor');
              });
              if (contact) {
                $scope.newCesActivity.contactIds.push(contact.id);
              }
            }
          },
        );

        if (!$scope.hide.instance) {
          $scope.assetList = [];
          var sanitizedClientId = InputSanitizerService.sanitize(client.id);
          $q.all([
            HttpService.get('/api/graders/clientresponses/' + sanitizedClientId),
            HttpService.get('/api/clients/' + sanitizedClientId + '/emailsequences'),
            // HttpService.get('/api/surveyresults/?companyId=' + client.id),
            HttpService.get('/api/meeting/meetings/?clientId=' + sanitizedClientId),
          ]).then(function (results) {
            var graderInteractionType = _.find($scope.interactionTypes, { type: 'grader' });
            var emailInteractionType = _.find($scope.interactionTypes, { type: 'email' });
            var reportInteractionType = _.find($scope.interactionTypes, { type: 'report' });
            // var surveyInteractionType = _.find($scope.interactionTypes, {type: 'survey'});

            $scope.assetList = _.orderBy(
              _.union(
                _.map(results[0], function (o) {
                  if (graderInteractionType) {
                    return {
                      id: o.id,
                      name: o.name,
                      title: o.name,
                      createdAt: o.createdAt,
                      interactionTypeId: graderInteractionType.id,
                      interactionTypeName: graderInteractionType.name,
                    };
                  }
                }),
                _.map(results[1], function (o) {
                  if (emailInteractionType) {
                    return {
                      id: o.id,
                      name: o.name,
                      title: o.name,
                      createdAt: o.createdAt,
                      interactionTypeId: emailInteractionType.id,
                      interactionTypeName: emailInteractionType.name,
                    };
                  }
                }),
                // _.map(results[2], function (o) {
                //     if (o.Survey.type === 'needs' && surveyInteractionType) {
                //         return {
                //             id: o.id,
                //             name: o.title + ' [' + $filter('date')(o.createdAt, "shortDate") + ']',
                //             title: o.title,
                //             createdAt: o.createdAt,
                //             interactionTypeId: surveyInteractionType.id,
                //             interactionTypeName: surveyInteractionType.name
                //         };
                //     }
                // }),
                _.map(results[2], function (o) {
                  if (reportInteractionType) {
                    return {
                      id: o.id,
                      name: o.name + ' [' + $filter('date')(o.createdAt, 'shortDate') + ']',
                      title: o.name,
                      createdAt: o.createdAt,
                      interactionTypeId: reportInteractionType.id,
                      interactionTypeName: reportInteractionType.name,
                    };
                  }
                }),
              ),
              'createdAt',
              'desc',
            );

            if ($scope.newCesActivity.InteractionTypeId && $scope.newCesActivity.InstanceId) {
              $scope.instanceList = _.filter($scope.assetList, {
                interactionTypeId: $scope.newCesActivity.InteractionTypeId,
              });
              refreshInstance($scope.newCesActivity.InstanceId);
            }
          });
        }
      };
      if ($scope.client) {
        $scope.changeClient($scope.client);
      }

      $scope.save = function (cb) {
        // if ($scope.selected && $scope.selected.activity && $scope.sendOption === 'link') {
        //     if ($scope.$parent.saveLinked && typeof $scope.$parent.saveLinked === 'function') {
        //         $scope.$parent.saveLinked();
        //         return false;
        //     }
        // } else {
        $scope.newCesActivity.ClientId = $scope.newCesActivity.ClientId || $scope.client.id;
        $scope.newCesActivity.day = parseInt(
          $moment($scope.newCesActivity.date).format('YYYYMMDD'),
        );
        if (verifyActivity($scope.newCesActivity)) {
          $rootScope.$broadcast('dataLoadingStarted');
          var req;
          if ($scope.newCesActivity.assetType && $scope.newCesActivity.assetId) {
            req = HttpService.put(
              '/api/clients/' +
                InputSanitizerService.sanitize($scope.newCesActivity.ClientId) +
                '/activities/' +
                InputSanitizerService.sanitize($scope.newCesActivity.assetType) +
                '/' +
                InputSanitizerService.sanitize($scope.newCesActivity.assetId),
              { activity: $scope.newCesActivity },
            );
          } else if (!$scope.newCesActivity.id) {
            req = HttpService.put('/api/ces/activities', { activity: $scope.newCesActivity });
          } else {
            req = HttpService.post(
              '/api/ces/activities/' + InputSanitizerService.sanitize($scope.newCesActivity.id),
              { activity: $scope.newCesActivity },
            );
          }
          req
            .then(function (result) {
              $rootScope.$broadcast('dataLoadingFinished');
              if (!$scope.newCesActivity.id) {
                if ($scope.newCesActivity.PlanId) {
                  UserEventService.event('clientCesActivityCompleted');
                } else {
                  UserEventService.event('clientCesActivityCreated');
                }
              }

              _.extend($scope.newCesActivity, result);
              if (cb) {
                cb();
              }
              if ($scope.modalInstance) {
                $scope.modalInstance.close(result);
              }
            })
            .catch(function (error) {
              if (cb) {
                cb(error);
              }
              $rootScope.$broadcast('dataLoadingFinished');
              DialogService.error(error);
              console.error(error);
            });
        } else {
          var error = $translate.instant('userError.client.ces.dateMismatchTitle');
          if (cb) {
            cb(error);
          }
          DialogService.error(error);
          return false;
        }
        // }
      };

      $scope.cancel = function (cb) {
        if (cb) {
          cb();
        }
        if ($scope.modalInstance) {
          $scope.modalInstance.dismiss();
        }
      };

      $scope.openAddContact = function () {
        var modalInstance = $modal.open({
          templateUrl: '/templates/client/contact-edit.html',
          controller: 'ContactEditController',
          backdrop: 'static',
          resolve: {
            contact: function () {
              return { status: 'customer' };
            },
            client: function () {
              return $scope.client;
            },
            contactSeniorities: function (HttpService) {
              return HttpService.get('/api/admin/ces/contactseniorities');
            },
          },
        });

        modalInstance.result.then(
          function (contact) {
            contact.name = contact.firstName + ' ' + contact.lastName;
            $scope.clientContacts.push(contact);
            $scope.clientContacts = $scope.clientContacts.sort(function (a, b) {
              return ('' + a.name).localeCompare(b.name);
            });
            $scope.newCesActivity.contactIds = $scope.newCesActivity.contactIds || [];
            $scope.newCesActivity.contactIds.push(contact.id);
          },
          function () {},
        );
      };
    },
  )

  .controller(
    'ClientSegmentsController',
    function (
      $rootScope,
      $scope,
      $state,
      $moment,
      $translate,
      _,
      CurrentUser,
      DialogService,
      HttpService,
      UserEventService,
      clientSegments,
    ) {
      $scope.clientSegments = clientSegments;
      $scope.newClientSegment = {};
      $scope.isEdit = false;

      $scope.currentUser = CurrentUser.getUser();
      $scope.can = CurrentUser.can;

      $scope.segmentFrequency = {};
      $scope.segmentFrequency.types = [
        { id: 1, name: 'Annual' },
        { id: 2, name: 'Quarterly' },
        { id: 3, name: 'Monthly' },
        { id: 4, name: 'None' },
      ];

      $scope.save = function (clientSegment) {
        if (clientSegment.name === '') {
          DialogService.error('error.clientSegments.nameEmpty');
        } else {
          if (
            parseInt(clientSegment.complianceScore) < 0 ||
            isNaN(parseInt(clientSegment.complianceScore))
          ) {
            clientSegment.complianceScore = 0;
          }
          $rootScope.$broadcast('dataLoadingStarted');
          clientSegment.name = clientSegment.editName;
          clientSegment.frequency = clientSegment.editFrequency.name;
          if (clientSegment.id && clientSegment.id > 0) {
            HttpService.post('/api/segments/' + clientSegment.id, {
              clientSegment: clientSegment,
            }).then(
              function (result) {
                clientSegment.isEdit = false;
                delete clientSegment.oldValues;
                _.extend(clientSegment, _.pick(result, ['name', 'complianceScore']));
                $scope.newClientSegment = { expanded: false };
                $rootScope.$broadcast('dataLoadingFinished');
                UserEventService.event('clientSegmentEdited');
              },
              function (error) {
                $rootScope.$broadcast('dataLoadingFinished');
                DialogService.error(error);
                console.error(error);
              },
            );
          } else {
            HttpService.put('/api/segments/', { clientSegment: clientSegment }).then(
              function (result) {
                $scope.clientSegments.push(result);
                $scope.newClientSegment = { expanded: false };
                $rootScope.$broadcast('dataLoadingFinished');
                UserEventService.event('clientSegmentCreated');
              },
              function (error) {
                $rootScope.$broadcast('dataLoadingFinished');
                DialogService.error(error);
                console.error(error);
              },
            );
          }
        }
      };

      $scope.cancel = function (clientSegment) {
        $rootScope.$broadcast('dataLoadingStarted');
        if (clientSegment && clientSegment.oldValues) {
          _.forEach(clientSegment.oldValues, function (value, index) {
            clientSegment[index] = value;
          });
          delete clientSegment.oldValues;
          clientSegment.isEdit = false;
        } else {
          $scope.newClientSegment = { expanded: false };
        }
        $rootScope.$broadcast('dataLoadingFinished');
      };

      $scope.editClientSegment = function (clientSegment) {
        $rootScope.$broadcast('dataLoadingStarted');
        $scope.cancel(_.find($scope.clientSegments, { isEdit: true }));

        clientSegment.isEdit = true;
        clientSegment.oldValues = _.cloneDeep(clientSegment);
        clientSegment.editName = clientSegment.name;
        clientSegment.editFrequency = $scope.segmentFrequency.types.find(function (type) {
          return type.name === clientSegment.frequency;
        });
        $rootScope.$broadcast('dataLoadingFinished');
      };

      $scope.removeClientSegment = function (clientSegment) {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.delete('/api/segments/' + clientSegment.id).then(
          function (result) {
            _.remove($scope.clientSegments, clientSegment);
            $rootScope.$broadcast('dataLoadingFinished');
          },
          function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
            console.error(error);
          },
        );
      };

      $scope.editClientSegmentRoadmapTemplate = function (clientSegment) {
        $state.go('msp.clientSegment', { segmentId: clientSegment.id });
      };
    },
  )

  .controller(
    'CesActivitiesController',
    function (
      $rootScope,
      $scope,
      $modal,
      $moment,
      $translate,
      _,
      DialogService,
      HttpService,
      CesService,
      SidebarFilterService,
      UserEventService,
      activities,
      clients,
      clientSegments,
      users,
      interactionTypes,
      contactSeniorities,
    ) {
      $scope.clientSegments = clientSegments;
      $scope.clients = _.map(clients, function (client) {
        var score = CesService.calculate(
          _.filter(activities, function (activity) {
            return (
              activity.ClientId == client.id &&
              activity.day >= $moment().subtract(6, 'month').format('YYYYMMDD') &&
              activity.day <= $moment().format('YYYYMMDD')
            );
          }),
        ).actualAvg[12];
        if (client.ClientSegment && client.ClientSegment.complianceScore) {
          var d = score - client.ClientSegment.complianceScore;
          return _.extend(client, { compliant: _.clamp(d, -1, 1), score: score });
        } else {
          return client;
        }
      });
      $scope.cesActivities = _.map(activities, function (activity) {
        var client = _.find($scope.clients, { id: activity.ClientId });
        // activity.clientLogoUrl = '/images/clients/' + activity.ClientId + '?' + new Date().getTime();
        activity.$$complianceRating = client.compliant;
        return activity;
      });
      $scope.newCesActivity = { expanded: false, type: 'plan', contactIds: [] };
      $scope.interactionTypes = _.sortBy(interactionTypes, 'name');
      $scope.contactSeniorities = contactSeniorities;
      $scope.getActivityType = CesService.getActivityType;
      $scope.getActivityClass = CesService.getActivityClass;

      $scope.filter = SidebarFilterService.get('cesactivities', null, {
        segment: _.concat($scope.clientSegments, [
          {
            id: -1,
            name: $translate.instant('label.clientSegment.without'),
          },
        ]),
        user: _.concat(
          _.map(users, function (user) {
            return {
              id: user.id,
              name: user.firstName + ' ' + user.lastName,
            };
          }),
          [{ id: -1, name: 'label.client.ces.sidebar.withoutAccountManager' }],
        ),
        interactionType: _.filter($scope.interactionTypes, function (interactionType) {
          return !(interactionType.OwnerId === null && interactionType.type === 'custom');
        }),
      }).filter;

      $scope.activityFilter = function (activity) {
        var types = _($scope.filter.type).filter({ selected: true }).map('code').value();
        var users = _($scope.filter.user).filter({ selected: true }).map('id').value();
        var segments = _($scope.filter.segment).filter({ selected: true }).map('id').value();
        var compliance = _($scope.filter.compliance).filter({ selected: true }).map('id').value();
        var interactionType = _($scope.filter.interactionType)
          .filter({ selected: true })
          .map('id')
          .value();
        return (
          (_.isEmpty(types) || _.includes(types, $scope.getActivityType(activity))) &&
          (_.isEmpty(users) || _.includes(users, activity.Client.AccountManagerId)) &&
          (_.isEmpty(segments) || _.includes(segments, activity.Client.ClientSegmentId)) &&
          (compliance.length < 1 || _.includes(compliance, activity.$$complianceRating)) &&
          (interactionType.length < 1 || _.includes(interactionType, activity.InteractionTypeId)) &&
          (activity.type === 'actual' || !activity.ActualId)
        );
      };

      function refreshChart() {
        $scope.chart = CesService.createChartData(
          _.filter($scope.cesActivities, $scope.activityFilter),
          $scope.clientSegments,
        );
      }

      $scope.$watch(
        'filter',
        function () {
          refreshChart();
        },
        true,
      );

      $scope.getActivityClass = CesService.getActivityClass;

      $scope.saveCesActivity = function (error) {
        if (!error) {
          if (!$scope.newCesActivity.oldValues || !$scope.newCesActivity.oldValues.id) {
            $scope.cesActivities.push($scope.newCesActivity);
          }
          delete $scope.newCesActivity.oldValues;
          $scope.newCesActivity.isEdit = false;
          $scope.newCesActivity = { type: 'plan' };
          refreshChart();
        }
      };

      $scope.cancelEditCesActivity = function () {
        var newCesActivity =
          _.find($scope.cesActivities, { isEdit: true }) || $scope.newCesActivity;
        if (newCesActivity) {
          if (newCesActivity.oldValues) {
            _.forEach(newCesActivity.oldValues, function (value, index) {
              newCesActivity[index] = value;
            });
            delete newCesActivity.oldValues;
          }
          newCesActivity.isEdit = false;
          $scope.newCesActivity = {
            date: $moment().add(1, 'month').toDate(),
            type: 'plan',
            contactIds: [],
          };
        }
      };

      $scope.editCesActivity = function (cesActivity) {
        $scope.cancelEditCesActivity();
        $scope.newCesActivity = cesActivity;
        cesActivity.date = cesActivity.day
          ? $moment(cesActivity.day, 'YYYYMMDD').toDate()
          : new Date();

        cesActivity.contactIds = [];
        _.forEach(cesActivity.Contacts, function (contact) {
          cesActivity.contactIds.push(contact.id);
        });

        cesActivity.oldValues = _.cloneDeep(cesActivity);
        cesActivity.isEdit = true;
      };

      $scope.realizeCesActivity = function (cesActivity) {
        $scope.cancelEditCesActivity();
        cesActivity.oldValues = _.cloneDeep(cesActivity);
        cesActivity.type = 'actual';
        cesActivity.PlanId = cesActivity.id;
        cesActivity.id = undefined;
        cesActivity.date = $moment().startOf('day');
        $scope.newCesActivity = cesActivity;

        cesActivity.contactIds = [];
        _.forEach(cesActivity.Contacts, function (contact) {
          cesActivity.contactIds.push(contact.id);
        });

        cesActivity.isEdit = true;
      };

      $scope.delete = function (cesActivity) {
        HttpService.delete('/api/ces/activities/' + cesActivity.id).then(
          function (result) {
            _.remove($scope.cesActivities, cesActivity);
            refreshChart();
          },
          function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
            console.error(error);
          },
        );
      };
      UserEventService.event('clientCesActivitiesOpened');
    },
  )

  .controller(
    'CesActivitiesShareController',
    function (
      $modalInstance,
      $rootScope,
      $scope,
      $translate,
      CurrentUser,
      DialogService,
      HttpService,
      UserEventService,
      Utils,
      calendarShares,
    ) {
      $rootScope.$broadcast('dataLoadingFinished');
      $scope.calendarShares = calendarShares || [];

      $scope.copyUrl = function (calendarShare) {
        Utils.copyToClipboard(calendarShare.$$url);
        Utils.showToastMessage($translate.instant('label.client.ces.share.linkCopied'));
      };

      $scope.add = function (team) {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.post('/api/calendars', {
          name: $translate.instant('label.client.ces.share.defaultName', {
            company: team
              ? CurrentUser.getUser().Company.name
              : CurrentUser.getUser().firstName + ' ' + CurrentUser.getUser().lastName,
          }),
          settings: { team: team },
        })
          .then(function (calendarShare) {
            $rootScope.$broadcast('dataLoadingFinished');
            $scope.calendarShares.push(calendarShare);
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
          });
      };

      $scope.edit = function (calendarShare) {
        calendarShare.$$edit = true;
        calendarShare.$$name = calendarShare.name;
      };

      $scope.cancelEdit = function (calendarShare) {
        calendarShare.$$edit = false;
      };

      $scope.save = function (calendarShare) {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.put('/api/calendars/' + calendarShare.id, {
          name: calendarShare.$$name,
        })
          .then(function (result) {
            $rootScope.$broadcast('dataLoadingFinished');
            calendarShare.name = result.name;
            calendarShare.$$edit = false;
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
          });
      };

      $scope.delete = function (calendarShare) {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.delete('/api/calendars/' + calendarShare.id)
          .then(function () {
            $rootScope.$broadcast('dataLoadingFinished');
            _.remove($scope.calendarShares, { id: calendarShare.id });
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
          });
      };

      $scope.close = function () {
        $modalInstance.dismiss();
      };

      UserEventService.event('clientCesActivitiesShared');
    },
  )

  .directive('cesClientGauge', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        client: '=',
      },
      controller: function ($scope) {
        if ($scope.client.ClientSegment) {
          var colors = { 1: '#59BF90', 0: '#F7BE08', '-1': '#d9534f' };

          $scope.doughnutChartData = {
            data: {
              labels: ['Score', 'Compliance Score'],
              datasets: [
                {
                  data:
                    $scope.client.score >= $scope.client.ClientSegment.complianceScore
                      ? [$scope.client.ClientSegment.complianceScore]
                      : [
                          $scope.client.score,
                          $scope.client.ClientSegment.complianceScore - $scope.client.score,
                        ],
                  backgroundColor: [colors[$scope.client.compliant], '#efefef'],
                  hoverBackgroundColor: [colors[$scope.client.compliant], '#efefef'],
                },
              ],
            },
            options: {
              width: 120,
              height: 120,
              responsive: true,
              animation: false,
              tooltips: false,
              legend: false,
              cutoutPercentage: 70,
            },
          };
        }
      },
      template:
        '<div><div style="width: 100%; height:100%; max-height: 100px; max-width: 100px" ng-if="doughnutChartData">' +
        '<canvas tc-chartjs-doughnut chart="" chart-legend="" chart-options="doughnutChartData.options" chart-data="doughnutChartData.data" width="200"></canvas>' +
        "<h6 class=\"small\"><span ng-class=\"{'chart-green': client.compliant > 0, 'chart-yellow': client.compliant == 0, 'chart-red': client.compliant < 0}\">{{client.score}} / {{client.ClientSegment.complianceScore}}</span><br></h6>" +
        '</div>' +
        '<span class="fa fa-2x fa-minus text-muted " ng-if="!doughnutChartData" translate-title = "label.client.ces.sidebar.segment.undefined"> </span>' +
        '</div>',
    };
  })

  .service('CesService', function ($moment, $translate, _) {
    // Chart.plugins.register({
    //     // need to manipulate tooltip visibility before its drawn (but after update)
    //     beforeDraw: function (chartInstance, easing) {
    //         // check and see if the plugin is active (its active if the option exists)
    //         if (chartInstance.config.options.tooltips.onlyShowForDatasetIndex) {
    //             // get the plugin configuration
    //             var tooltipsToDisplay = chartInstance.config.options.tooltips.onlyShowForDatasetIndex;
    //
    //             // get the active tooltip (if there is one)
    //             var active = chartInstance.tooltip._active || [];
    //
    //             // only manipulate the tooltip if its just about to be drawn
    //             if (active.length > 0) {
    //                 // first check if the tooltip relates to a dataset index we don't want to show
    //                 if (tooltipsToDisplay.indexOf(active[0]._datasetIndex) === -1) {
    //                     // we don't want to show this tooltip so set it's opacity back to 0
    //                     // which causes the tooltip draw method to do nothing
    //                     chartInstance.tooltip._model.opacity = 0;
    //                 }
    //             }
    //         }
    //     }
    // });

    var calculate = function (activities, options) {
      function engagementScore(a) {
        var val = _.round(
          _(a)
            .takeRight(9)
            .reverse()
            .map(function (x, i) {
              return x * (1 - i * 0.1);
            })
            .sum() / 3,
        );
        return val;
      }

      options = options || {};
      var clientCount = options.clientCount || 1;
      var from = options.from || $moment().endOf('month').subtract(12, 'months');
      var to = options.to || $moment().endOf('month').add(12, 'months');

      var data = { actual: [], plan: [], actualAvg: [], planAvg: [], labels: [] };

      var thisMonth = $moment().endOf('month');

      var monthCounter = 0;
      for (
        var month = $moment(from).endOf('month');
        month.isSameOrBefore(to);
        month.add(1, 'month').endOf('month')
      ) {
        monthCounter++;
        if (options.labelFormat === 'number') {
          data.labels.push(
            $translate.instant('label.client.ces.chart.month', { number: monthCounter }),
          );
        } else {
          data.labels.push(month.format('YYYY-MM'));
        }

        var monthActivities = _.filter(activities, function (activity) {
          return activity.day.toString().substring(0, 6) == month.format('YYYYMM');
        });

        if (month.isSameOrBefore(thisMonth)) {
          data.actual.push(
            _.round(
              _(monthActivities).filter({ type: 'actual' }).sumBy('score') / (clientCount || 1),
            ),
          );
          data.actualAvg.push(engagementScore(_.mergeWith(_.clone(data.actual), data.plan, _.add)));
          data.plan.push(0);
          if (month.isSame(thisMonth)) {
            data.planAvg.push(engagementScore(_.mergeWith(_.clone(data.actual), data.plan, _.add)));
          } else {
            data.planAvg.push(NaN);
          }
        } else {
          data.actual.push(0);
          data.actualAvg.push(NaN);
          data.plan.push(
            _.round(
              _(monthActivities).filter({ type: 'plan' }).sumBy('score') / (clientCount || 1),
            ),
          );
          data.planAvg.push(engagementScore(_.mergeWith(_.clone(data.actual), data.plan, _.add)));
        }
      }

      data.getActivitiesByIndex = function (index, datasetIndex) {
        return _.filter(activities, function (activity) {
          return (
            ((datasetIndex === 0 && activity.type === 'actual') ||
              (datasetIndex === 1 && activity.type === 'plan')) &&
            $moment(activity.day, 'YYYYMMDD').isBetween(
              $moment(from).add(index, 'month').startOf('month'),
              $moment(from).add(index, 'month'),
            )
          );
        });
      };
      return data;
    };

    var getActivityType = function (item) {
      if (item.type === 'actual') {
        return 'actual';
      } else if (item.type === 'plan') {
        var day = $moment(item.day, 'YYYYMMDD');
        if (!item.ActualId && $moment().diff(day) > 0) {
          return 'missed';
        } else {
          return 'plan';
        }
      } else {
        return 'error';
      }
    };

    var getActivityClass = function (item) {
      if (item.type === 'actual') {
        return 'text-success';
      } else if (item.type === 'plan') {
        var day = $moment(item.day, 'YYYYMMDD');
        if (!item.ActualId && $moment().diff(day) > 0) {
          return 'text-danger';
        } else {
          return 'text-success-light';
        }
      }
    };

    return {
      calculate: calculate,
      createChartData: function (activities, segment, options) {
        var colors = {
          actual: '#59BF90',
          plan: '#c7eada',
          trend: '#62A6DB',
          segment: '#F6303D',
        };
        var data = calculate(activities, options);
        var maxValue = _.round(
          _.max(
            _.union(data.actual, data.plan, data.actualAvg, data.planAvg, [
              segment ? segment.complianceScore : 0,
            ]),
          ) * 1.1,
        );
        var result = {
          data: {
            labels: data.labels,
            datasets: [
              {
                label: $translate.instant('label.client.ces.chart.completed'),
                borderColor: colors.actual,
                backgroundColor: colors.actual,
                data: data.actual,
                type: 'bar',
                yAxisID: 'bar-y-axis',
              },
              {
                label: $translate.instant('label.client.ces.chart.planned'),
                borderColor: colors.plan,
                backgroundColor: colors.plan,
                data: data.plan,
                type: 'bar',
                yAxisID: 'bar-y-axis',
              },
              {
                label: $translate.instant('label.client.ces.chart.completedAvg'),
                borderColor: colors.trend,
                pointBackgroundColor: '#FFF',
                pointRadius: 2,
                pointBorderWidth: 1,
                fill: 'none',
                data: data.actualAvg,
                type: 'line',
                borderWidth: 2,
              },
              {
                label: $translate.instant('label.client.ces.chart.plannedAvg'),
                borderColor: colors.trend,
                pointBackgroundColor: '#FFF',
                pointRadius: 2,
                pointBorderWidth: 1,
                fill: 'none',
                data: data.planAvg,
                type: 'line',
                borderDash: [5, 5],
                borderWidth: 2,
              },
            ],
          },
          options: {
            // onClick: function (c, i) {
            //     if (!_.isEmpty(i) && options.filter){
            //         options.filter(i[0]._index)
            //     }
            //     // e = i[0];
            //     // console.log(e._index)
            //     // var x_value = this.data.labels[e._index];
            //     // var y_value = this.data.datasets[0].data[e._index];
            //     // console.log(x_value);
            //     // console.log(y_value);
            // },
            responsive: true,
            animation: false,
            bezierCurve: false,
            pointDot: false,
            legend: { display: false },
            tooltips: {
              // intersect: false,
              // mode: 'index'
              // onlyShowForDatasetIndex: [0, 1],
              enabled: false,

              custom: function (tooltipModel) {
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjs-tooltip');

                // Create element on first render
                if (!tooltipEl) {
                  tooltipEl = document.createElement('div');
                  tooltipEl.id = 'chartjs-tooltip';
                  tooltipEl.innerHTML = '<table></table>';
                  document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (
                  tooltipModel.opacity === 0 ||
                  (!_.isEmpty(this._active) &&
                    !_.includes([0, 1, 2, 3], this._active[0]._datasetIndex))
                ) {
                  tooltipEl.style.opacity = 0;
                  return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                  tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                  tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                  return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                  var innerHtml =
                    '<thead><b>' +
                    tooltipModel.dataPoints[0].xLabel +
                    ': <br>' +
                    (tooltipModel.dataPoints[0].datasetIndex === 0 ||
                    tooltipModel.dataPoints[0].datasetIndex === 2
                      ? data.actualAvg[tooltipModel.dataPoints[0].index] +
                        ': ' +
                        $translate.instant('label.client.ces.chart.completedAvg')
                      : data.planAvg[tooltipModel.dataPoints[0].index] +
                        ': ' +
                        $translate.instant('label.client.ces.chart.plannedAvg')) +
                    '</b></thead>' +
                    '<tbody><b>' +
                    (tooltipModel.dataPoints[0].datasetIndex === 0 ||
                    tooltipModel.dataPoints[0].datasetIndex === 2
                      ? data.actual[tooltipModel.dataPoints[0].index]
                      : data.plan[tooltipModel.dataPoints[0].index]) +
                    ': ' +
                    (tooltipModel.dataPoints[0].datasetIndex === 0
                      ? $translate.instant('label.client.ces.chart.completed')
                      : $translate.instant('label.client.ces.chart.planned')) +
                    '</b><br>';
                  _.forEach(
                    data.getActivitiesByIndex(
                      tooltipModel.dataPoints[0].index,
                      tooltipModel.dataPoints[0].datasetIndex,
                    ),
                    function (activity) {
                      innerHtml += activity.score + ': ' + activity.name + '<br>';
                    },
                  );

                  innerHtml += '</tbody>';

                  var tableRoot = tooltipEl.querySelector('table');
                  tableRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                tooltipEl.style.opacity = 1;
                tooltipEl.style.backgroundColor = '#fff';
                tooltipEl.style.borderColor = '#000';
                tooltipEl.style.cornerRadius = 25;
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.left =
                  position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                tooltipEl.style.top =
                  position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                tooltipEl.style.padding =
                  tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                tooltipEl.style.pointerEvents = 'none';
              },
            },
            scales: {
              xAxes: [
                {
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: $translate.instant('label.client.ces.chart.xAxes'),
                  },
                },
              ],
              yAxes: [
                {
                  stacked: false,
                  scaleLabel: {
                    display: true,
                    labelString: $translate.instant('label.client.ces.chart.yAxes'),
                  },
                  ticks: {
                    beginAtZero: true,
                    min: 0,
                    max: maxValue,
                  },
                },
                {
                  id: 'bar-y-axis',
                  stacked: true,
                  type: 'linear',
                  display: false,
                  ticks: {
                    beginAtZero: true,
                    min: 0,
                    max: maxValue,
                  },
                },
              ],
            },
          },
        };

        if (segment && segment.complianceScore > 0) {
          result.data.datasets.push({
            label: $translate.instant('label.client.ces.chart.compliance'),
            borderColor: colors.segment,
            pointRadius: 0,
            fill: 'none',
            data: _.times(data.labels.length, _.constant(segment.complianceScore)),
            type: 'line',
            borderWidth: 1,
          });
        }
        return result;
      },
      getActivityClass: getActivityClass,
      getActivityType: getActivityType,
    };
  })

  .service(
    'CesClientPlaybookService',
    function ($modal, $rootScope, _, DialogService, HttpService) {
      var applySegmentRoadmap = function (client, contactSeniorities, cb) {
        var modalInstance = $modal.open({
          templateUrl: '/templates/client/ces-apply-segment-roadmap.html',
          controller: function (
            $modalInstance,
            $moment,
            $scope,
            $rootScope,
            client,
            contactSeniorities,
          ) {
            $scope.client = client;
            $scope.contactSeniorityData = [];
            $scope.contactSeniorities = contactSeniorities;
            $scope.options = { startMonth: $moment().format('YYYYMM') };
            $scope.months = _(24)
              .range()
              .map(function (i) {
                return {
                  id: $moment()
                    .add(i - 12, 'month')
                    .format('YYYYMM'),
                  name: $moment()
                    .add(i - 12, 'month')
                    .format('MM-YYYY'),
                };
              })
              .value();
            const seniorityHelper = {};
            _.forEach($scope.contactSeniorities, function (s) {
              seniorityHelper[s.id] = {
                data: _.cloneDeep(s),
                contacts: [],
                contactIds: [],
              };
            });
            $scope.client.Contacts = $scope.client.Contacts.map(function (c) {
              c.name = c.firstName + ' ' + c.lastName;
              return c;
            });
            _.forEach($scope.client.Contacts || [], function (c) {
              _.forEach(c.Seniorities || [], function (s) {
                const seniorityData = seniorityHelper[s.id];
                if (seniorityData) {
                  seniorityData.contacts.push(Object.assign({}, c));
                  seniorityData.contactIds.push(c.id);
                }
              });
            });

            $scope.modalSeniorityData = Object.values(seniorityHelper).sort(function (a, b) {
              return a.contacts.length - b.contacts.length;
            });

            $scope.showSeniorityNotPresentInfo = false;
            _.forEach($scope.modalSeniorityData, function (d) {
              if (!d.contacts.length) {
                $scope.showSeniorityNotPresentInfo = true;
              }
            });

            $scope.getContactsString = function (contacts) {
              return contacts
                .map(function (c) {
                  return c.name;
                })
                .join(', ');
            };

            $scope.okEnabled = function () {
              var error = false;
              _.forEach($scope.modalSeniorityData, function (d) {
                if (!d.contactIds || !d.contactIds.length) {
                  error = true;
                }
              });
              return error;
            };

            $scope.ok = function () {
              const final = {};
              _.forEach($scope.modalSeniorityData, function (d) {
                _.forEach(d.contactIds, function (contactId) {
                  if (final[contactId]) {
                    final[contactId].seniorityIds.push(d.data.id);
                  } else {
                    final[contactId] = {
                      contactId: contactId,
                      seniorityIds: [d.data.id],
                    };
                  }
                });
              });
              $scope.contactSeniorityData = Object.values(final);
              $modalInstance.close({
                startMonth: $scope.options.startMonth,
                contactSeniorityData: $scope.contactSeniorityData,
              });
            };

            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
          backdrop: 'static',
          resolve: {
            client: function () {
              return client;
            },
            contactSeniorities: function () {
              return contactSeniorities;
            },
          },
        });

        modalInstance.result.then(
          function (modalResult) {
            $rootScope.$broadcast('dataLoadingStarted');
            HttpService.post(
              '/api/segments/' + client.ClientSegment.id + '/ces/clients/' + client.id,
              {
                startMonth: modalResult.startMonth,
                contactSeniorityData: modalResult.contactSeniorityData,
              },
            ).then(
              function (result) {
                $rootScope.$broadcast('dataLoadingFinished');
                if (result.message === 'userError.client.ces.playbookSeniorityMismatch') {
                  DialogService.warning(result.message);
                }
                cb(result);
              },
              function (error) {
                $rootScope.$broadcast('dataLoadingFinished');
                console.error(error);
                DialogService.error(error);
                cb(null);
              },
            );
          },
          function () {
            cb('error');
          },
        );
      };

      return {
        applySegmentRoadmap: applySegmentRoadmap,
      };
    },
  );
