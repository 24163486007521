angular
  .module('vcio-toolkit')

  .factory('surveyResultService', function ($http, $q, _) {
    return {
      availableStatuses: ['draft', 'sent', 'started', 'finished', 'evaluated', 'archived'],
      statuses: {
        draft: { label: 'label.surveyResultsDraft', type: 'info', icon: 'fa-pause' },
        sent: { label: 'label.surveyResultsSent', type: 'warning', icon: 'fa-paper-plane' },
        started: { label: 'label.surveyResultsStarted', type: 'info', icon: 'fa-play' },
        filled: {
          label: 'label.surveyResultsFinished',
          type: 'primary',
          icon: 'fa-flag-checkered',
        },
        evaluated: { label: 'label.surveyResultsEvaluated', type: 'success', icon: 'fa-check' },
        archived: { label: 'label.surveyResultsArchived', type: 'primary', icon: 'fa-archive' },
        total: { label: 'label.surveyResultsTotal', type: 'default', icon: 'fa-sum' },
      },

      getClassByStatus: function (prefix, status) {
        return (
          (prefix ? prefix : '') + (this.statuses[status] ? this.statuses[status].type : 'default')
        );
      },

      getIconByStatus: function (prefix, status) {
        return (prefix ? prefix : '') + (this.statuses[status] ? this.statuses[status].icon : '');
      },

      // getSurveyResults: function (companyId, type) {
      //     var deferred = $q.defer();
      //
      //     $http.get('/api/surveyresults/' +
      //         (companyId ? '?companyId=' + companyId : '') +
      //         (type ? '&type=' + type : ''))
      //         .success(function (results) {
      //             deferred.resolve(_.sortBy(results, function (item) {
      //                 return -1 * item.updatedAt.getTime()
      //             }));
      //         })
      //         .error(function (error) {
      //             console.error(error);
      //         });
      //
      //     return deferred.promise;
      // },

      getSurveyResultsWithScore: function (companyId, type) {
        var deferred = $q.defer();

        $http
          .get(
            '/api/surveyresults/' +
              (companyId ? '?companyId=' + companyId : '') +
              (type ? '&type=' + type : '') +
              '&calcScore=true',
          )
          .success(function (results) {
            deferred.resolve(
              _.sortBy(results, function (item) {
                return -1 * item.updatedAt.getTime();
              }),
            );
          })
          .error(function (error) {
            console.error(error);
          });

        return deferred.promise;
      },

      getSurveyResultById: function (id) {
        var deferred = $q.defer();

        $http
          .get('/api/surveyresults/' + id)
          .success(function (result) {
            deferred.resolve(result);
          })
          .error(function (error) {
            console.error(error);
          });

        return deferred.promise;
      },
    };
  });
