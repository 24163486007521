angular.module('vcio-toolkit').factory('InputSanitizerService', function (_) {
  return {
    sanitize: function (input) {
      if (
        typeof input === 'number' ||
        (typeof input === 'string' &&
          input.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i)) // validate if UUID
      ) {
        return input;
      }

      return _.escape(String(input).replace(/[\n]/g, '\\n').replace(/[\r]/g, '\\r').replace(/\.\.\//g, ''));
    },
  };
});
