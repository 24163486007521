angular
  .module('vcio-toolkit')
  .controller(
    'ShareProposalController',
    function (
      $scope,
      $interpolate,
      $rootScope,
      $location,
      $http,
      $translate,
      _,
      $window,
      ProjectPriceService,
    ) {
      /**
       * We need this because on reload the application had a flickering effect,
       * (some of the elements are rendered when they should not)
       * therefore we only show the 'shareproposal.ejs' when the controller loaded.
       */
      document.body.style.display = '';

      $scope.stage = '';
      $scope.loadingFinished = false;
      $scope.proposal = {};

      var queryString = window.location.search;
      var urlParams = new URLSearchParams(queryString);
      var token = urlParams.get('token');
      $scope.currency = urlParams.get('currency');

      $http
        .get('/api/proposals/' + token)
        .success(function (response) {
          $scope.loadingFinished = true;
          $scope.proposal = response;
          $scope.proposalStatus = $scope.proposal.status;
          $scope.stage = 'rendered';
          $scope.proposal.actorEmail = response.actorEmail;
          $scope.proposal.actorInitials = response.actorInitials;
          $scope.proposal.initialsError = true;
          $scope.proposal.emailError = true;
          ($scope.proposalOwnerName =
            response.CreatedBy.firstName + ' ' + response.CreatedBy.lastName),
            ($scope.proposal.url = window.location.href);
          $scope.sumPrice = $scope.getTotalWithoutDiscount();
          $scope.companyLogoUrl = '/images/companies/' + $scope.proposal.Client.OwnerId;
          $scope.clientLogoUrl = '/images/clients/' + $scope.proposal.ClientId;
        })
        .error(function (error) {
          $scope.loadingFinished = true;
          $scope.stage = 'error';
        });

      $scope.showButtons = function () {
        return $scope.proposalStatus === 'draft' || $scope.proposalStatus === 'pending';
      };

      $scope.isButtonEnabled = function () {
        return $scope.proposal.actorEmail && $scope.proposal.actorInitials;
      };

      $scope.onInitialsChange = function (errorVisualsType) {
        $scope.proposal[errorVisualsType] = false;
      };

      $scope.getProjectCost = function (project) {
        const prices = ProjectPriceService.formatClientProjectPrices(project.prices);

        return project.prices
          ? Object.values(prices).reduce(function (acc, price) {
              return Number(acc) + Number(price);
            }, 0)
          : 0;
      };

      $scope.getTotalWithoutDiscount = function () {
        var sumPrice = 0;
        $scope.proposal.ClientProjects.forEach(function (project) {
          sumPrice = sumPrice + Number($scope.getProjectCost(project));
        });

        return sumPrice;
      };

      $scope.capitalizeStatus = function (status) {
        return status.charAt(0).toUpperCase() + status.slice(1);
      };

      $scope.setApproval = function (status) {
        $http
          .put('/api/proposals/' + token + '/approval', {
            actorEmail: $scope.proposal.actorEmail,
            actorInitials: $scope.proposal.actorInitials,
            status: status,
          })
          .success(function (response) {
            $scope.stage = response.status;
            $scope.sendEmail(response.status);
          })
          .error(function (error) {
            $scope.stage = 'error';
          });
      };

      $scope.sendEmail = function (status) {
        const templateData = {
          actorInitials: $scope.proposal.actorInitials,
          proposalUrl: $scope.proposal.url,
          proposalOwnerName: $scope.proposalOwnerName,
          proposalOwnerCompany: $scope.proposal.companyName,
          proposalNameRow:
            status === 'approved'
              ? 'Thanks for Approving ' + $scope.proposal.name
              : 'The Proposal: ' + $scope.proposal.name + ' has been rejected.',
        };

        const mailData = {
          subject:
            status === 'approved'
              ? $scope.proposal.name + ' Approved'
              : $scope.proposal.name + ' Rejected',
          to: $scope.proposal.actorEmail,
          cc: $scope.proposal.CreatedBy.email,
          bcc: $scope.proposal.accountManagerEmail,
        };

        $http
          .post('/api/email/sendTemplatedMail', {
            mailData: Object.assign(mailData, { templateData: templateData }),
            templateName: 'proposalAcceptReject',
            status: status,
            userId: $scope.proposal.CreatedById,
            companyId: $scope.proposal.Client.OwnerId,
          })
          .catch(function (error) {
            console.log(error);
          });
      };
    },
  );
