angular
  .module('vcio-toolkit')

  .directive('updateTitle', function ($rootScope, $timeout, $translate) {
    return {
      link: function (scope, element) {
        $rootScope.$on(
          '$stateChangeStart',
          function (event, toState, toParams, fromState, fromParams) {
            var title = '';
            if (toState.data && toState.data.pageTitle) {
              title = $translate.instant(toState.data.pageTitle);
            }
            // Set asynchronously so page changes before title does
            $timeout(function () {
              element.text(title && title.length > 0 ? title : '');
            });
          },
        );
      },
    };
  })

  .directive('pageTitle', function () {
    return {
      restrict: 'E',
      replace: true,
      template: '<h1 translate="{{currentState.data.pageTitle}}"></h1>',
      controller: function ($scope, $state) {
        $scope.currentState = $state.current;
      },
    };
  })
  .directive('authCan', function () {
    return {
      restrict: 'A',
      replace: true,
      transclude: 'element',
      template: '<div ng-show="can()"><div ng-transclude></div></div>',
      controller: function ($scope, $attrs, CurrentUser) {
        $scope.can = function () {
          return CurrentUser.can($attrs.authCan);
        };
      },
    };
  })

  .directive('it', function () {
    return {
      restrict: 'E',
      replace: true,
      template:
        '<span>{{labelBefore | translate}} <i class="{{iconClass}}"></i> {{labelAfter | translate}}</span>',
      scope: {
        iconClass: '@',
        labelBefore: '@',
        labelAfter: '@',
      },
    };
  })

  .directive('translateTitle', function ($translate) {
    return {
      restrict: 'A',
      replace: true,
      scope: {
        translateTitle: '@',
      },
      controller: function ($scope, $element) {
        $element.attr('title', $translate.instant($scope.translateTitle));
      },
    };
  })

  .directive('translateList', function ($translate) {
    return {
      restrict: 'E',
      replace: true,
      template: "<span>{{value ? (list + '.' + value | translate) : ''}}</span>",
      scope: {
        list: '@',
        value: '=',
      },
    };
  })

  .directive('titleExpandable', function () {
    return {
      restrict: 'E',
      replace: true,
      template:
        '<div ng-click="model = !model"><span translate="{{labelBefore}}"></span> <i class="fa" ng-class="{\'fa-angle-down\': model, \'fa-angle-right\': !mdodel}"></i> <span translate="{{labelAfter}}"></span></div>',
      scope: {
        labelBefore: '@',
        labelAfter: '@',
        model: '=',
      },
    };
  })

  .directive('accordion2', function () {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      template:
        '<div class="panel panel-{{panelClass}}" style="margin-bottom: 0; margin-top: 5px;">' +
        '<div class="panel-heading panel-title" ng-click="open()"><span title="{{labelTitle}}">{{label|translate}}' +
        '<i ng-if="useCaret" class="fa pull-right" ng-class="{\'fa-angle-up\': expanded, \'fa-angle-down\': !expanded}"></i>' +
        '</span></div>' +
        '<div ng-if="!useShow"><div ng-if="expanded" class="panel-body"><ng-transclude></ng-transclude></div></div>' +
        '<div ng-if="useShow"><div ng-show="expanded" class="panel-body"><ng-transclude></ng-transclude></div></div>' +
        '</div>',
      scope: {
        label: '@',
        labelTitle: '@',
        panelClass: '@',
        useShow: '@',
        useCaret: '@',
        _expanded: '=expanded',
      },
      controller: function ($scope) {
        if ($scope._expanded !== undefined) {
          $scope.expanded = $scope._expanded;
        }
        if (!$scope.panelClass) {
          $scope.panelClass = 'default';
        }
        $scope.open = function () {
          $scope.expanded = !$scope.expanded;
        };
      },
    };
  })

  .directive('confirmedClick', function ($modal) {
    // var ModalInstanceCtrl = function ($scope, $modalInstance) {
    //     $scope.ok = function () {
    //         $modalInstance.close();
    //     };
    //
    //     $scope.cancel = function () {
    //         $modalInstance.dismiss('cancel');
    //     };
    // };

    return {
      restrict: 'A',
      scope: {
        confirmedClick: '&',
      },
      controller: function ($scope, $element, $attrs) {
        $scope.message = $attrs.confirmedClickMessage || 'confirm.question';
        //$scope.messageValues = $attrs.confirmedClickMessageValues;
      },
      link: function ($scope, $element) {
        $element.bind('click', function ($event) {
          $event.preventDefault();
          $event.stopPropagation();

          var modalHtml = '<div class="modal-body" translate="' + $scope.message + '"></div>';
          modalHtml +=
            '<div class="modal-footer"><button class="btn btn-success" ng-click="ok()">{{"button.ok" | translate}}</button><button class="btn btn-primary" ng-click="cancel()">{{"button.cancel" | translate}}</button></div>';

          var modalInstance = $modal.open({
            template: modalHtml,
            controller: function ($scope, $modalInstance) {
              $scope.ok = function () {
                $modalInstance.close(true);
              };

              $scope.cancel = function () {
                $modalInstance.dismiss();
              };
            },
          });

          modalInstance.result.then(
            function () {
              $scope.confirmedClick();
            },
            function () {
              //Modal dismissed
            },
          );
        });
      },
    };
  })

  .directive('ngEnter', function () {
    return function ($scope, $element, $attrs) {
      $element.bind('keydown keypress', function ($event) {
        if ($event.which === 13) {
          $scope.$apply(function () {
            $scope.$eval($attrs.ngEnter);
          });

          $event.preventDefault();
        }
      });
    };
  })

  .directive('ngEscape', function () {
    return function ($scope, $element, $attrs) {
      $element.bind('keydown keypress', function ($event) {
        if ($event.which === 27) {
          $scope.$apply(function () {
            $scope.$eval($attrs.ngEscape);
          });

          $event.preventDefault();
        }
      });
    };
  })

  .directive('languageSelector', function ($translate) {
    return {
      restrict: 'E',
      replace: true,
      template:
        '<div class="languageSelector text-center">' +
        //'<div style="padding: 2px;">' +
        '  <img ng-src="/images/flags/{{currentLanguage()}}.png" >' +
        //'</div>' +
        //'<div style="padding: 2px;" ng-repeat="language in availableLanguages()">' +
        '  <img ng-src="/images/flags/{{language}}.png" ng-click="changeLanguage(language)" ng-repeat="language in availableLanguages()">' +
        //'</div>' +
        '</div>',
      controller: function ($scope, $http) {
        $scope.languages = ['en'];

        $http.get('/api/translations/languages').success(function (result) {
          $scope.languages = result;
        });

        $scope.currentLanguage = function () {
          return $translate.use().substr(0, 2);
        };

        $scope.availableLanguages = function () {
          var result = angular.copy($scope.languages);
          result.splice(result.indexOf($scope.currentLanguage()), 1);
          return result;
        };

        $scope.changeLanguage = function (language) {
          $translate.use(language);
        };
      },
      link: function ($scope, $element) {
        $element.bind('mouseenter', function () {
          $element.css({
            height: $scope.languages.length * 34 + 20 + 'px',
            //"width": "55px",
            //"padding": "10px",
            'text-align': 'left',
            border: 'solid lightgray 1px',
          });
        });
        $element.bind('mouseleave', function () {
          $element.css({
            height: '16px',
            width: '22px',
            padding: '0px',
            'text-align': 'center',
            border: '0px',
          });
        });
      },
    };
  })

  .directive('iframeClose', function () {
    return {
      restrict: 'E',
      replace: true,
      template:
        '<div class="iframeClose text-center"><i class="fa fa-times" ng-click="close()"></i></div>',
    };
  })

  .directive('pdfDownload', function () {
    return {
      restrict: 'E',
      template:
        '<a class="btn btn-primary btn-xs" ng-click="downloadPdf()" title="{{\'button.download\' | translate}}" ng-hide="downloaded">' +
        '<i class="fa fa-download" ng-hide="downloaded || downloading"></i>' +
        '<i class="fa fa-spinner fa-spin" ng-show="downloading"></i></a>' +
        '<a ng-download="report.pdf" content="pdfdata" download="report.pdf" class="btn btn-primary btn-xs"><i class="fa fa-save" ng-show="downloaded" download="report.pdf"></i></a>',
      scope: {
        url: '@',
      },
      link: function ($scope, $element) {
        $scope.anchor = $element.children()[0];
      },
      controller: function ($scope, $attrs, $http) {
        $scope.downloadPdf = function () {
          $scope.downloading = true;
          $http
            .get($scope.url)
            .success(function () {
              $scope.downloading = false;
              $scope.downloaded = true;
              //$scope.pdfdata = data;
              //$scope.anchor.setAttribute('href', 'data:application/pdf;base64,' + data);
              //$scope.anchor.setAttribute('download', 'report.pdf');
              $scope.downloadPdf = function () {};
            })
            .error(function (error) {
              console.error(error);
            });
        };
      },
    };
  })

  .directive('resolveController', [
    '$controller',
    function ($controller) {
      return {
        scope: true,
        link: function ($scope, $elem, $attrs) {
          var resolve = $scope.$eval($attrs.resolve);
          if (!resolve) {
            resolve = {};
          }
          angular.extend(resolve, { $scope: $scope });
          $controller($attrs.resolveController, resolve);
        },
      };
    },
  ])

  .directive('leftMenu', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        menuItems: '=',
        tabHolder: '=',
        onChangeTab: '=',
      },
      controller: function ($scope) {
        //_.filter($state.get(), function(item){return item.name.indexOf('academy.') == 0;})
        $scope.changeTab = $scope.onChangeTab;
        if (!$scope.changeTab) {
          $scope.changeTab = function (tab) {
            $scope.tabHolder = tab;
          };
        }
        $scope.nameFilter = function (item) {
          return item.name && item.label;
        };
      },
      template:
        '<div class="list-group"><a ng-repeat="item in menuItems | filter:nameFilter" class="list-group-item" ng-class="{\'active\': tabHolder == item.name}" ng-click="changeTab(item.name)" id="left-menu-{{item.name}}">' +
        '<div class="row"><div class="col-md-12"><i ng-show="item.icon" class="fa fa-{{item.icon}} pull-left" style="padding-top: 4px;"></i><div style="margin-left: 10px;overflow: hidden;">{{item.label | translate}}</div></div></div>' +
        '</a></div>',
      //'<table><tr><td><i ng-show="item.icon" class="fa fa-{{item.icon}}"></i></td><td>{{item.label | translate}}<//td></tr></table>' +
    };
  })

  .directive('tools', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        model: '=',
        actions: '=',
        align: '@',
        color: '@',
      },
      controller: function ($scope) {
        if (!$scope.align) {
          $scope.align = 'right';
        }
        if (!$scope.color) {
          $scope.color = '#555555';
        }
        if ($scope.actions) {
          $scope.actions.forEach(function (action) {
            if (!action.condition) {
              action.condition = function () {
                return true;
              };
            }
          });
        }
        $scope.hasAction = function (model) {
          var result = false;
          $scope.actions.forEach(function (action) {
            if (action.condition(model)) {
              result = true;
            }
          });
          return result;
        };
      },
      template:
        '<div class="btn-group" dropdown>' +
        '<span dropdown-toggle style="color:{{color}}; font-size: 120%"><i class="fa fa-cog" ng-if="hasAction(model)"></i></span>' +
        '<ul class="dropdown-menu" ng-class="{\'pull-right\': align == \'right\'}" role="menu">' +
        '  <li ng-repeat="action in actions" ng-show="action.condition(model)">' +
        '    <a ng-if="!action.confirmation" ng-click="action.operation(model)"><i class="fa fa-{{action.icon}}"></i> <label translate="{{action.label}}"></label></a>' +
        '    <a ng-if="action.confirmation" confirmed-click="action.operation(model)"><i class="fa fa-{{action.icon}}"></i> <label translate="{{action.label}}"></label></a>' +
        '  </li>' +
        '</ul></div></div> ',
    };
  })

  .directive('bindHtmlCompile', [
    '$compile',
    function ($compile) {
      return {
        restrict: 'A',
        link: function (scope, element, attrs) {
          scope.$watch(
            function () {
              return scope.$eval(attrs.bindHtmlCompile);
            },
            function (value) {
              // In case value is a TrustedValueHolderType, sometimes it
              // needs to be explicitly called into a string in order to
              // get the HTML string.
              element.html(value && value.toString());
              // If scope is provided use it, otherwise use parent scope
              var compileScope = scope;
              if (attrs.bindHtmlScope) {
                compileScope = scope.$eval(attrs.bindHtmlScope);
              }
              $compile(element.contents())(compileScope);
            },
          );
        },
      };
    },
  ])

  .directive('imagePopup', function ($modal) {
    return {
      restrict: 'EA',
      replace: true,
      template: '<img ng-src="{{imgSrc}}" style="max-width: 100%;" ng-click="open()">',
      scope: {
        imgSrc: '@',
        imgTitle: '@',
      },
      link: function (scope, element, attrs) {
        if (!scope.imgSrc && attrs.src) {
          scope.imgSrc = attrs.src;
        }
        if (!scope.imgTitle && attrs.title) {
          scope.imgTitle = attrs.title;
        }
        scope.open = function () {
          var modalInstance = $modal.open({
            template:
              '<div class="modal-header" ng-hide="!imgTitle"><h3 class="modal-title">{{imgTitle}}</h3></div><div class="modal-body text-center"><img ng-src="{{imgSrc}}" style="max-width: 100%;" ng-click="close()"></div>',
            //backdrop: 'static',
            size: 'lg',
            controller: function ($scope, $modalInstance, imgSrc, imgTitle) {
              $scope.imgSrc = imgSrc;
              $scope.imgTitle = imgTitle;
              $scope.close = function () {
                $modalInstance.dismiss();
              };
            },
            resolve: {
              imgSrc: function () {
                return scope.imgSrc;
              },
              imgTitle: function () {
                return scope.imgTitle;
              },
            },
          });
          modalInstance.result.then(
            function (result) {},
            function () {},
          );
        };
      },
    };
  })

  .directive('comments', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        apiUrl: '@',
      },
      controller: 'CommentsController',
      templateUrl: '/templates/comments.html',
    };
  })

  .directive('messages', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {},
      controller: function ($scope) {
        $scope.getMessage = function (message) {
          if (typeof message === 'object') {
            return message.code;
          } else {
            return message;
          }
        };
        $scope.getMessageValues = function (message) {
          if (typeof message === 'object') {
            return message.translateValues;
          } else {
            return null;
          }
        };
      },
      template:
        '<div class="row"><div class="col-md-12"><div class="alert alert-danger" ng-repeat="error in $parent.errors">' +
        '<strong translate="{{getMessage(error)}}" translate-values="{{getMessageValues(error)}}"></strong><span ng-if="error.fields" ng-repeat="field in error.fields track by $index">: <strong>{{field | translate}}</strong> </span>' +
        '</div></div><div class="row"><div class="col-md-12"><div class="alert alert-success text-left b" ng-repeat="message in $parent.messages" translate="{{getMessage(message)}}" translate-values="{{getMessageValues(message)}}"></div></div></div></div>',
    };
  })

  .directive('stateLoadingIndicator', function ($rootScope, AUTH_EVENTS) {
    return {
      restrict: 'E',
      template:
        '<div ng-show="isStateLoading"><div class="modal-spinner-background">' +
        '  <div class="spinner"> ' +
        '    <div class="rect1"></div> ' +
        '    <div class="rect2"></div> ' +
        '    <div class="rect3"></div> ' +
        '    <div class="rect4"></div> ' +
        '    <div class="rect5"></div> ' +
        '  </div>' +
        '</div></div>',
      replace: true,
      link: function ($scope) {
        $scope.isStateLoading = false;

        $rootScope.$on('dataLoadingStarted', function () {
          $scope.isStateLoading = true;
        });
        $rootScope.$on('dataLoadingFinished', function () {
          $scope.isStateLoading = false;
        });
        $rootScope.$on('$stateChangeStart', function () {
          $scope.isStateLoading = true;
        });
        $rootScope.$on('$stateChangeSuccess', function () {
          $scope.isStateLoading = false;
        });
        $rootScope.$on('$stateChangeError', function () {
          $scope.isStateLoading = false;
        });
        $rootScope.$on(AUTH_EVENTS.notAuthenticated, function () {
          $scope.isStateLoading = false;
        });
        $rootScope.$on(AUTH_EVENTS.unauthorized, function () {
          $scope.isStateLoading = false;
        });
      },
    };
  })

  .directive('iframePopup', function ($modal) {
    return {
      restrict: 'E',
      transclude: true,
      template: '<div ng-click="open()"><ng-transclude></ng-transclude></div>',
      scope: {
        iframeSrc: '@',
      },
      replace: true,
      link: function ($scope) {
        $scope.open = function () {
          var modalInstance = $modal.open({
            //templateUrl: '/templates/help/academy-sidebar.html',
            template:
              '<div class="modal-body text-center"><iframe ng-src="{{iframeSrc}}" width="100%" height="600"></div>',
            //backdrop: 'static',
            size: 'lg',
            controller: function ($scope, $modalInstance, $sce, iframeSrc) {
              $scope.iframeSrc = $sce.trustAsResourceUrl(iframeSrc);
              $scope.close = function () {
                $modalInstance.dismiss();
              };
            },
            resolve: {
              iframeSrc: function () {
                return $scope.iframeSrc;
              },
            },
          });
          modalInstance.result.then(
            function (result) {},
            function () {},
          );

          //function autoResize(id){
          //    var newheight;
          //    var newwidth;
          //
          //    if(document.getElementById){
          //        newheight=document.getElementById(id).contentWindow.document .body.scrollHeight;
          //        newwidth=document.getElementById(id).contentWindow.document .body.scrollWidth;
          //    }
          //
          //    document.getElementById(id).height= (newheight) + "px";
          //    document.getElementById(id).width= (newwidth) + "px";
          //}
        };
      },
    };
  })

  .directive('wistiaPopup', function ($modal, $timeout, CurrentUser) {
    return {
      restrict: 'A',
      scope: {
        wistiaPopup: '@',
        code: '@',
        playlist: '=',
      },
      //controller: function ($scope, $element, $attrs) {
      //$scope.code = $attrs.wistiaPopup.replace(/'/g, "");
      //},
      link: function ($scope, $element) {
        $element.bind('click', function () {
          var modalInstance = $modal.open({
            template:
              '<div class="modal-body" style="height: 440px"><div class="pull-right"><i class="fa fa-times-circle" ng-click="close()"></i></div><div id="{{ \'wistia_\' + wistiaCode}}" class="wistia_embed" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="550" height="344"></div></div>',
            controller: function ($scope, $modalInstance, wistiaCode, playlist) {
              $scope.wistiaCode = wistiaCode;
              if (playlist) {
                $timeout(function () {
                  var wistiaPlaylist = Wistia.playlist($scope.wistiaCode, {
                    videoFoam: true,
                    theme: 'tab',
                  });
                  wistiaPlaylist.setEmail(CurrentUser.getUser().email);
                }, 100);
              } else {
                $timeout(function () {
                  var wistiaEmbed = Wistia.embed($scope.wistiaCode, {
                    videoFoam: true,
                    trackEmail: CurrentUser.getUser().email,
                  });
                }, 100);
              }
              $scope.close = function () {
                $modalInstance.dismiss();
              };
            },
            resolve: {
              wistiaCode: function () {
                return $scope.code || $scope.wistiaPopup;
              },
              playlist: function () {
                return $scope.playlist;
              },
            },
          });

          modalInstance.result.then(
            function () {},
            function () {},
          );
        });
      },
    };
  })

  .directive('imageSelector', function ($modal, $http, $timeout, Upload, _) {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: '/templates/image-selector-preview.html',
      scope: {
        model: '=',
        listUrl: '@',
        uploadUrl: '@',
        type: '@',
        height: '=?',
      },
      controller: function ($scope) {
        $scope.editor = true;
        if (!$scope.type) {
          $scope.type = 'single';
        }
        if (!$scope.height) {
          $scope.height = 100;
        }
        $scope.images = [];
        if ($scope.type === 'multi') {
          $scope.images = _.map($scope.model, function (id) {
            return { id: id, url: '/images/' + id + '?' + Date.now() };
          });
        } else if ($scope.model) {
          $scope.images = [{ id: $scope.model, url: '/images/' + $scope.model + '?' + Date.now() }];
        }
      },
      link: function ($scope) {
        $scope.open = function () {
          var modalInstance = $modal.open({
            backdrop: 'static',
            size: 'lg',
            templateUrl: '/templates/image-selector.html',
            resolve: {
              data: function () {
                return {
                  selectedImages: angular.copy($scope.images),
                  storage: $scope.storage,
                  listUrl: $scope.listUrl,
                  uploadUrl: $scope.uploadUrl,
                  type: $scope.type,
                };
              },
            },
            controller: function ($scope, $modalInstance, data) {
              _.extend($scope, data);
              $scope.images = [];
              // $scope.selectedImages = [];
              $http.get($scope.listUrl).success(function (images) {
                images.forEach(function (image) {
                  $scope.images.push(
                    _.extend(image, { url: '/images/' + image.id + '?' + Date.now() }),
                  );
                });
              });
              // $scope.ids.forEach(function (id) {
              //     $scope.selectedImages.push({id: id, url: '/images/' + id + '?' + Date.now()})
              // })

              $scope.removeSelected = function () {
                $scope.selectedImages = [];
              };

              $scope.preview = function (files) {
                var file = _.get(files, 0);
                if (file) {
                  $timeout(function () {
                    var fileReader = new FileReader();
                    fileReader.readAsDataURL(file);
                    fileReader.onload = function (e) {
                      $timeout(function () {
                        file.dataUrl = e.target.result;
                        $scope.file2upload = file;
                      });
                    };
                  });
                }
              };

              $scope.uploadImage = function () {
                if ($scope.file2upload.size > 512 * 1024) {
                  $scope.errors = [{ code: 'error.fileTooLarge' }];
                } else {
                  Upload.upload({
                    url: $scope.uploadUrl,
                    method: 'POST',
                    withCredentials: true,
                    file: $scope.file2upload,
                  })
                    .progress(function (evt) {})
                    .success(function (data) {
                      $scope.messages = ['message.fileUploaded'];
                      if (_.find($scope.images, { id: data.imageId })) {
                        _.remove($scope.images, { id: data.imageId });
                      }
                      $scope.images.unshift({
                        id: data.imageId,
                        filename: $scope.file2upload.filename,
                        url: '/images/' + data.imageId + '?' + Date.now(),
                      });
                    })
                    .error(function (error) {
                      if (error && error.status == 400) {
                        $scope.errors = [{ code: 'error.invalidFileType', fields: ['jpg, png'] }];
                      } else {
                        $scope.errors = [error];
                      }
                    });
                }
              };

              $scope.isSelected = function (image) {
                return _.find($scope.selectedImages, { id: image.id });
              };

              $scope.removeAll = function () {
                $scope.selectedImages = [];
              };

              $scope.selectImage = function (image) {
                if ($scope.type === 'multi') {
                  if (!_.find($scope.selectedImages, { id: image.id })) {
                    $scope.selectedImages.push({
                      id: image.id,
                      url: '/images/' + image.id + '?' + Date.now(),
                    });
                  } else {
                    $scope.removeImage(image.id);
                  }
                } else {
                  if ($scope.selectedImages.length > 0 && $scope.selectedImages[0].id == image.id) {
                    $scope.selectedImages = [];
                  } else {
                    $scope.selectedImages = [
                      {
                        id: image.id,
                        url: '/images/' + image.id + '?' + Date.now(),
                      },
                    ];
                  }
                }
              };

              $scope.removeImage = function (id) {
                _.remove($scope.selectedImages, { id: id });
              };

              $scope.ok = function () {
                $modalInstance.close($scope.selectedImages);
              };

              $scope.cancel = function () {
                $modalInstance.dismiss();
              };
            },
          });
          modalInstance.result.then(
            function (selectedImages) {
              $scope.images = angular.copy(selectedImages);
              if ($scope.type === 'multi') {
                $scope.model = _.map(selectedImages, 'id');
              } else if (selectedImages.length > 0) {
                $scope.model = selectedImages[0].id;
              } else {
                $scope.model = [];
              }
            },
            function () {},
          );
        };
      },
    };
  })

  .directive('imageViewer', function ($modal, _) {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: '/templates/image-selector-preview.html',
      scope: {
        model: '=',
        type: '@',
        height: '=',
      },
      controller: function ($scope) {
        if (!$scope.type) {
          $scope.type = 'single';
        }
        $scope.images = [];
        if ($scope.type === 'multi') {
          $scope.images = _.map($scope.model, function (id) {
            return { id: id, url: '/images/' + id + '?' + Date.now() };
          });
        } else if ($scope.model) {
          $scope.images = [{ id: $scope.model, url: '/images/' + $scope.model + '?' + Date.now() }];
        }
      },
      link: function ($scope) {
        $scope.open = function (src) {
          var modalInstance = $modal.open({
            template:
              '<div class="modal-body text-center"><img ng-src="{{imgSrc}}" style="max-width: 100%;" ng-click="close()"></div>',
            //backdrop: 'static',
            size: 'lg',
            controller: function ($scope, $modalInstance, imgSrc) {
              $scope.imgSrc = imgSrc;
              $scope.close = function () {
                $modalInstance.dismiss();
              };
            },
            resolve: {
              imgSrc: function () {
                return src;
              },
            },
          });
          modalInstance.result.then(
            function (result) {},
            function () {},
          );
        };
      },
    };
  })

  .directive('limitMax', function () {
    return {
      link: function (scope, element, attributes) {
        element.on('keydown keyup', function (e) {
          if (
            Number(element.val()) > Number(attributes.limitMax) &&
            e.keyCode != 46 && // delete
            e.keyCode != 8 // backspace
          ) {
            e.preventDefault();
            element.val(attributes.limitMax);
          }
        });
      },
    };
  })

  .directive('servicebundleDetailsPopover', function () {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      template:
        '<span class="{{size}}" uib-popover="{{getBundlePopover(bundle)}}" popover-title="{{bundle.name}}" ' +
        'popover-trigger="mouseenter" popover-placement="{{placement}}" style="display: inline-block;">' +
        '<ng-transclude></ng-transclude></span>',
      scope: {
        size: '@',
        bundle: '=',
        placement: '@',
        projectType: '@',
        services: '=',
      },
      controller: function ($scope, $translate, _) {
        $scope.placement = $scope.placement || 'right';
        $scope.getBundlePopover = function (bundle) {
          var popoverText;
          if (bundle) {
            popoverText = bundle.description || ' ';
            if (bundle.Services && $scope.services && _.size(bundle.Services)) {
              var sortedServices = [];
              _.forEach(bundle.Services, function (value, key) {
                if ($scope.services[key] && $scope.services[key].name) {
                  sortedServices.push({ name: $scope.services[key].name, index: key });
                }
              });
              sortedServices = _.sortBy(sortedServices, ['name']);
              popoverText +=
                '<br><b>' + $translate.instant('label.vcio.servicesIncluded') + ':</b>';
              /**
                             * The old version, without the sort by name function is commented out.
                             _.forEach(bundle.Services, function(value, key){
                            if ($scope.services[key] && $scope.services[key].name) popoverText += '<br>'+$scope.services[key].name;
                        });
                             */
              _.forEach(sortedServices, function (value) {
                popoverText += '<br>' + value.name;
              });
            }
          }
          return popoverText;
        };
      },
      link: function (scope) {},
    };
  })

  .directive('serviceDetailsPopover', function ($translate, $filter, _) {
    return {
      restrict: 'EA',
      replace: true,
      transclude: true,
      template:
        '<span class="{{size}}" uib-popover="{{getServicePopover(service)}}" popover-title="{{service.name}}" ' +
        'popover-trigger="mouseenter" popover-placement="{{placement}}">' +
        '<ng-transclude></ng-transclude></span>',
      scope: {
        size: '@',
        service: '=',
        placement: '@',
        projectType: '@',
      },
      controller: function ($scope) {
        //$scope.d = {academy: 'success', marketing: 'danger', sales: 'primary', vcio: 'warning'};
        /*
                 <div ng-if=\'service\'>{{service.description}}'+
                 '<div class=\'row\'><div class=\'col-xs-6\'>{{\'label.academy.serviceType.\'+service.type|translate}}</div>'+
                 '<div class=\'col-xs-6\'>{{service.hoursToComplete}} {{(service.hoursToComplete == 1 ? \'hour\' : \'hours\')}}</div></div>'+
                 '<div class=\'popover-footer\'><div class=\'row\'><div class=\'col-xs-3\' ng-repeat=\'(key, color) in d\'>'+
                 '{{key}}'+ //<small>{{color}}'+
                 //'<i class=\'fa fa-star text-{{color}}\' ng-repeat=\'(k, v) in service.serviceQuality[key]\'></i></small>'+
                 '</div>'+
                 '</div></div></div>
                 */
        $scope.getServicePopover = function (service) {
          var result = '';
          if (service && !service.deletedAt) {
            var description = service.description || service.details;
            var d = { academy: 'success', marketing: 'danger', sales: 'primary', vcio: 'warning' };
            result = $filter('shorten')(description, 500);
            if (_.includes(['onetime', 'any', 'ongoing'], service.type)) {
              result +=
                '<div class="row"><div class="col-xs-6">' +
                $translate.instant('label.academy.serviceType.' + service.type) +
                '</div>';
            }
            if (service.hoursToComplete) {
              result +=
                '<div class="col-xs-6">' +
                service.hoursToComplete +
                ' ' +
                (service.hoursToComplete == 1 ? 'hour' : 'hours') +
                '</div>';
            }
            result +=
              '<div class="popover-footer">' +
              (service.ClientAssets
                ? service.ClientAssets.length + '  assets belongs to this project'
                : '') +
              '</div>';
            return result + '</div>';
            /*</div>';*/
          } else if (service && service.deletedAt) {
            result = $translate.instant('message.survey.missingServicePopover', {
              serviceId: service.id,
            });
            return result;
          } else {
            return '';
          }
        };
      },

      link: function (scope) {},
    };
  })

  .directive('generalPopover', function ($translate, $filter, _) {
    return {
      restrict: 'EA',
      replace: true,
      transclude: true,
      template:
        '<span class="{{size}}" uib-popover="{{getGeneralPopover(body,footer,isBodyList,itemProperty,disabled)}}" popover-title="{{header}}" ' +
        'popover-trigger="mouseenter" popover-placement="{{placement}}">' +
        '<ng-transclude></ng-transclude></span>',

      scope: {
        size: '@',
        header: '@',
        disabled: '=',
        body: '@',
        bodyList: '=',
        footer: '@',
        placement: '@',
        isBodyList: '=',
        itemProperty: '@',
      },
      controller: function ($scope) {
        $scope.getGeneralPopover = function (bodyList, body, footer, itemProperty, disabled) {
          if ($scope.disabled) {
            return '';
          }
          var result = '';
          if ($scope.bodyList) {
            $scope.list = [];

            $scope.bodyList.forEach(function (item) {
              $scope.list.push('<li>' + item[$scope.itemProperty] + '</li>');
            });
            result = $scope.list.toString().replaceAll(',', '');
          } else {
            result += '<div>' + $filter('shorten')($scope.body, 500) + '</div>';
          }
          result += '<div class="popover-footer">' + $scope.footer + '</div>';
          return result + '</div>';
        };
      },

      link: function (scope) {},
    };
  })

  .directive('assetDetailsPopover', function () {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      template:
        '<span class="{{size}}" uib-popover="{{getPopover(asset)}}" popover-title="{{asset.name}}" ' +
        'popover-trigger="mouseenter" popover-placement="{{placement}}">' +
        '<ng-transclude></ng-transclude></span>',
      scope: {
        size: '@',
        asset: '=',
        placement: '@?',
      },
      controller: function ($scope, $filter, _) {
        if (!$scope.placement) {
          $scope.placement = 'right';
        }
        $scope.getPopover = function (item) {
          var popoverText = '';
          if ((item.type === 'roadmap' || item.type === 'course') && item.sections) {
            _.forEach(item.sections, function (section) {
              popoverText += '<br><b>' + section.name + '</b>';
              _.forEach(section.attachedAssets, function (attachedAsset) {
                if (attachedAsset.name) {
                  popoverText += '<br>&nbsp;&nbsp;' + attachedAsset.name;
                }
              });
            });
          } else {
            popoverText = $filter('shorten')(item.overview, 250) || ' ';
            if (item.type === 'resource' && item.filename) {
              popoverText += '<br><br>' + item.filename;
            }
          }
          return popoverText;
        };
      },
      link: function (scope) {},
    };
  })

  .directive('wistiaEmbed', function ($timeout, CurrentUser) {
    return {
      scope: {
        code: '@wistiaEmbed',
        wistiaPlaylist: '@?',
      },
      link: function (scope) {
        var currentUser = CurrentUser.getUser();
        if (scope.code) {
          if (scope.wistiaPlaylist == 'true') {
            $timeout(function () {
              var wistiaPlaylist = Wistia.playlist(scope.code, {
                videoFoam: true,
                theme: 'tab',
              });
              if (currentUser) {
                wistiaPlaylist.setEmail(currentUser.email);
              }
            }, 100);
          } else {
            $timeout(function () {
              var wistiaEmbed = Wistia.embed(scope.code, {
                videoFoam: true,
                trackEmail: currentUser ? currentUser.email : '',
              });
            }, 100);
          }
        }
      },
    };
  })

  /**
   * @name confirmOnExit
   *
   * @description
   * Prompts user while he navigating away from the current route (or, as long as this directive
   * is not destroyed) if any unsaved form changes present.
   *
   * @element Attribute
   * @scope
   * @param confirmOnExit Scope function which will be called on window refresh/close or AngularS $route change to
   *                          decide whether to display the prompt or not.
   * @param confirmMessageWindow Custom message to display before browser refresh or closed.
   * @param confirmMessageRoute Custom message to display before navigating to other route.
   * @param confirmMessage Custom message to display when above specific message is not set.
   *
   * @example
   * Usage:
   * Example Controller: (using controllerAs syntax in this example)
   *
   *      angular.module('AppModule', []).controller('pageCtrl', [function () {
   *          this.isDirty = function () {
   *              // do your logic and return 'true' to display the prompt, or 'false' otherwise.
   *              return true;
   *          };
   *      }]);
   *
   * Template:
   *
   *      <div confirm-on-exit="pageCtrl.isDirty()"
   *          confirm-message-window="All your changes will be lost."
   *          confirm-message-route="All your changes will be lost. Are you sure you want to do this?">
   *
   * @see
   * http://stackoverflow.com/a/28905954/340290
   *
   * @author Manikanta G
   */
  .directive('confirmOnExit', function ($rootScope) {
    return {
      scope: {
        confirmOnExit: '&',
        confirmMessageWindow: '@',
        confirmMessageRoute: '@',
        confirmMessage: '@',
      },
      link: function ($scope) {
        window.onbeforeunload = function () {
          if ($scope.confirmOnExit()) {
            return $scope.confirmMessageWindow || $scope.confirmMessage;
          }
        };
        var $stateChangeStartUnbind = $scope.$on('$stateChangeStart', function (event) {
          if ($scope.confirmOnExit()) {
            if (!confirm($scope.confirmMessageRoute || $scope.confirmMessage)) {
              event.preventDefault();
              $rootScope.$broadcast('dataLoadingFinished');
            }
          }
        });

        $scope.$on('$destroy', function () {
          window.onbeforeunload = null;
          $stateChangeStartUnbind();
        });
      },
    };
  })
  .directive('search', function ($translate) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        model: '=',
        placeholder: '@',
        refresh: '=?',
        _hint: '@hint',
      },
      controller: function ($scope) {
        if (!$scope.refresh) {
          $scope.refresh = function () {};
        }
        $scope.hint = $translate.instant($scope._hint);
        if ($scope.hint == $scope._hint) {
          $scope.hint = undefined;
        }
      },
      template:
        '<div class="abm-form"><div class="input-group">' +
        '    <input type="text" ng-model="model" class="form-control padding-1x-left" placeholder="{{placeholder | translate}}" ng-enter="refresh()" ' +
        '        uib-popover="{{hint}}" popover-trigger="mouseenter" popover-placement="top">' +
        '    <span class="input-group-addon">' +
        '       <i class="fa fa-search" ng-click="refresh()"></i>' +
        '   </span>' +
        '</div></div>',
    };
  })

  .directive('listFilter', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        label: '@',
        list: '=',
      },
      controller: function ($scope, _) {
        $scope.select = function (item, $event) {
          _.forEach($scope.list, function (o) {
            o.selected = o.id === item.id;
          });
          $event.preventDefault();
          $event.stopPropagation();
          return false;
        };
      },
      template:
        '<span class="margin-2x-right" dropdown dropdown-append-to-body="true" auto-close="outsideClick">' +
        '    <span dropdown-toggle text-align: right;">' +
        '        <span translate="{{label}}"></span> <i class="fa fa-filter"></i><span class="caret"></span>' +
        '    </span>' +
        '    <ul class="dropdown-menu padding-1x" role="menu">' +
        '        <li ng-repeat="item in list" ng-click="select(item, $event)">' +
        '            <i class="fa fa-lg fa-fw" ng-class="{\'fa-check-circle-o\': item.selected, \'fa-circle-o\': !item.selected}"></i>' +
        '            <span translate="{{item.name}}"></span>' +
        '</li></ul></span>',
    };
  })

  // .directive('rycFilter', function ($translate) {
  //     return {
  //         restrict: 'E',
  //         replace: true,
  //         scope: {
  //             model: '=',
  //             label: '@',
  //             labelList: '@'
  //         },
  //         controller: function ($scope) {
  //             $scope.clickFilter = function (type, $event) {
  //                 $scope.model[type] = !$scope.model[type];
  //                 $event.preventDefault();
  //                 $event.stopPropagation();
  //                 return false;
  //             };
  //         },
  //         template: '<div class="margin-2x-right" dropdown dropdown-append-to-body="true" auto-close="outsideClick">' +
  //             '    <span dropdown-toggle style="color:#555555; text-align: right;">' +
  //             '        <span translate="{{label}}"></span> <i class="fa fa-filter"></i><span class="caret"></span>' +
  //             '    </span>' +
  //             '    <ul class="dropdown-menu padding-1x" role="menu">' +
  //             '        <li ng-repeat="(item, value) in model" ng-click="clickFilter(item, $event)">' +
  //             '            <i class="fa fa-lg fa-fw" ng-class="{\'fa-check-square-o\': value, \'fa-square-o\': !value}"></i>' +
  //             '            <span translate="{{(labelList ? labelList + \'.\' : \'\') + item}}"></span>' +
  //             '</li></ul></div>'
  //     };
  // })
  //
  // .directive('rycFilterSingle', function ($translate) {
  //     return {
  //         restrict: 'E',
  //         replace: true,
  //         scope: {
  //             model: '=?',
  //             label: '@',
  //             list: '=',
  //             click: '=?'
  //         },
  //         controller: function ($scope, _) {
  //             $scope.clickFilter = function (item, $event) {
  //                 if ($scope.model == item.id) {
  //                     $scope.model = '';
  //                 } else {
  //                     $scope.model = item.id;
  //                 }
  //                 if ($scope.click) {
  //                     $scope.click($scope.model);
  //                 }
  //                 $event.preventDefault();
  //                 $event.stopPropagation();
  //                 return false;
  //             };
  //         },
  //         template: '<span class="margin-2x-right" dropdown dropdown-append-to-body="true" auto-close="outsideClick">' +
  //             '    <span dropdown-toggle style="color:#555555; text-align: right;">' +
  //             '        <span translate="{{label}}"></span> <i class="fa fa-filter"></i><span class="caret"></span>' +
  //             '    </span>' +
  //             '    <ul class="dropdown-menu padding-1x" role="menu">' +
  //             '        <li ng-repeat="item in list" ng-click="clickFilter(item, $event)">' +
  //             '            <i class="fa fa-lg fa-fw" ng-class="{\'fa-check-circle-o\': model == item.id, \'fa-circle-o\': model != item.id}"></i>' +
  //             '            <span translate="{{item.name}}"></span>' +
  //             '</li></ul></span>'
  //     };
  // })

  .directive('rycFilterMulti', function ($translate) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        model: '=?',
        label: '@',
        list: '=',
        click: '=?',
      },
      controller: function ($scope, _) {
        $scope.clickFilter = function (item, $event) {
          if ($scope.model) {
            if (
              _.findIndex($scope.model, function (o) {
                return o == item.id;
              }) > -1
            ) {
              _.remove($scope.model, function (n) {
                return n == item.id;
              });
            } else {
              $scope.model[$scope.model.length] = item.id;
              $scope.model = _.uniq($scope.model);
            }
            if ($scope.click) {
              $scope.click($scope.model);
            }
            $event.preventDefault();
            $event.stopPropagation();
            return false;
          } else {
            item.selected = true;
          }
        };

        $scope.isChecked = function (item) {
          if ($scope.model) {
            return (
              _.findIndex($scope.model, function (o) {
                return o == item;
              }) > -1
            );
          } else {
            return _.get(_.find($scope.list, { id: item }), 'selected');
          }
        };
      },
      template:
        '<span class="margin-2x-right" dropdown dropdown-append-to-body="true" auto-close="outsideClick">' +
        '    <span dropdown-toggle style="color:#555555; text-align: right;">' +
        '        <span translate="{{label}}"></span> <i class="fa fa-filter"></i><span class="caret"></span>' +
        '    </span>' +
        '    <ul class="dropdown-menu padding-1x" role="menu">' +
        '        <li ng-repeat="item in list" ng-click="clickFilter(item, $event)">' +
        '            <i class="fa fa-lg fa-fw" ng-class="{\'fa-check-circle-o\': isChecked(item.id), \'fa-circle-o\': !isChecked(item.id)}"></i>' +
        '            <span translate="{{item.name}}"></span>' +
        '</li></ul></span>',
    };
  })

  .directive('rycTransclude', function () {
    return {
      compile: function (tElement, tAttrs, transclude) {
        return function (scope, iElement, iAttrs) {
          transclude(scope.$new(), function (clone) {
            iElement.append(clone);
          });
        };
      },
    };
  })

  .directive('rycRepeat', function () {
    return {
      restrict: 'EA',
      // replace: true,
      transclude: true,
      template:
        '<div ng-repeat="(title, group) in groups track by $index">' +
        '  <h3>' +
        '    <span translate="{{title}}"></span>' +
        '    <i ng-if="collapsible" class="fa fa-fw pull-right" ng-click="group.expanded = !group.expanded" ng-class="{\'fa-angle-down\': !group.expanded, \'fa-angle-up\': group.expanded}"></i>' +
        '  </h3>' +
        '  <div ng-show="group.expanded">' +
        '    <div ng-repeat="item in group.list | limitTo:group.limit" ryc-transclude=""></div>' +
        '    <div class="text-center margin-4x-bottom"><span class="btn btn-default btn-outline" translate="button.more" ng-show="group.limit < group.length" ng-click="showMore(group)"></span></div>' +
        '  </div>' +
        '</div>',
      scope: {
        list: '=',
        groupTitle: '@',
        groupProperty: '@',
        collapsible: '=?',
      },
      controller: function ($scope, _) {
        // $scope.groupProperty = $scope.groupProperty || 'type'; don't group if no groupProperty
        $scope.groups = _($scope.list)
          .groupBy(function (item) {
            if ($scope.groupProperty) {
              return (
                ($scope.groupTitle || '') +
                (!$scope.groupTitle || _.endsWith($scope.groupTitle, '.') ? '' : '.') +
                _.get(item, $scope.groupProperty)
              );
            } else {
              return $scope.groupTitle || '';
            }
          })
          .mapValues(function (group) {
            return { list: group, length: group.length, limit: 3, expanded: true };
          })
          .value();

        $scope.showMore = function (group) {
          group.limit += 3;
        };
      },
    };
  })
  .directive('priceWithCurrency', function () {
    return {
      restrict: 'A',
      replace: true,
      transclude: 'element',
      template: function ($scope) {
        var template =
          '<span title="{{currencyLabel | translate}} {{price | number:2}}"' +
          '>{{currencyLabel | translate}}{{price | ';
        if (!$scope.showTitle) {
          template = '<span' + '>{{currencyLabel | translate}}{{price | ';
        }
        if ($scope.formatting === 'price') {
          template += 'formatPrice';
        } else {
          template += 'number';
        }
        template += ':digits}}</span>';
        return template;
      },
      scope: {
        currency: '@',
        price: '@',
        digits: '=?',
        formatting: '=',
        showTitle: '=?',
      },
      controller: function ($scope) {
        $scope.formatting = $scope.formatting || 'number';
        $scope.digits = $scope.digits || 0;
        $scope.currencyLabel = 'currency.' + $scope.currency;
      },
    };
  })

  .directive('lazyLoad', function (InputSanitizerService) {
    return {
      restrict: 'A',
      scope: {
        lazyLoad: '@',
      },
      link: function ($scope, $rootScope, $element, $attrs) {
        var observer = new IntersectionObserver(loadImg);
        var img = angular.element($element)[0];
        observer.observe(img);

        function loadImg(changes) {
          changes.forEach(function (change) {
            if (change.intersectionRatio > 0) {
              change.target.src = InputSanitizerService.sanitize($scope.lazyLoad);
            }
          });
        }
      },
    };
  })

  .directive('titleShowHide', function () {
    return {
      restrict: 'E',
      template:
        '<h2 style="{{style}}"\n' +
        '    ng-mouseenter="model.$$hoverInput = true"\n' +
        '    ng-mouseleave="model.$$hoverInput = false"\n' +
        '    ng-click="model.show = !model.show">\n' +
        '    <span translate="{{label}}"></span>\n' +
        '    <i class="fa"\n' +
        "       ng-class=\"{ 'fa-eye': model.show, 'fa-eye-slash': !model.show}\"\n" +
        '       ng-show="model.$$hoverInput"></i>\n' +
        '</h2>',
      scope: {
        model: '=',
        label: '@',
        style: '@',
      },
      controller: function ($scope, _) {},
    };
  })

  .directive('userImage', function () {
    return {
      restrict: 'E',
      template:
        '<img src="/images/empty-image.png" class="img-responsive img-circle" title="{{user.firstName}} {{user.lastName}} &lt;{{user.email}}&gt;" ' +
        'ng-style="style(user)"/>',
      scope: {
        user: '=user',
      },
      controller: function ($scope, _, HttpService) {
        $scope.style = function (user) {
          return {
            width: '25px',
            height: '25px',
            display: 'inline-block',
            'background-image': user ? "url('/images/users/" + user.id + "')" : '',
            'background-position': 'center center',
            'background-size': 'cover',
          };
        };
      },
    };
  })

  .directive('scrollToTop', function ($window) {
    return {
      restrict: 'E',
      template:
        '<div style="position: fixed;bottom: 10px; display: inline-block;"><i style="margin-left: -30px" class="fa fa-2x fa-chevron-circle-up text-muted" ng-if="show" ng-click="scrollToTop()"></i></div>',
      scope: {
        offset: '@?',
      },
      link: function ($scope, $element) {
        $scope.show = false;

        angular.element($window).bind('scroll', function () {
          if ($window.pageYOffset >= 100) {
            $scope.show = true;
          } else {
            $scope.show = false;
          }
        });
      },
      controller: function ($scope) {
        $scope.scrollToTop = function () {
          window.scrollTo(0, 0);
        };
      },
    };
  })

  // .directive('permissionGroupPrice', function () {
  //     return {
  //         restrict: 'E',
  //         replace: true,
  //         template: '<div>\n' +
  //             '    <span ng-if="permissionGroup.price > 0 && permissionGroup.discountPrice !== 0 && !permissionGroup.discountPrice">\n' +
  //             '        <h3 style="display: inline-block;">\n' +
  //             '        <span price-with-currency formatting="price" digits="2"\n' +
  //             '              currency="{{currency}}" price="{{permissionGroup.price}}"></span>\n' +
  //             '        </h3>\n' +
  //             '    </span>\n' +
  //             '    <span ng-if="permissionGroup.price > 0 && (permissionGroup.discountPrice > 0 || permissionGroup.discountPrice === 0)">\n' +
  //             '        <h4 style="display: inline-block; text-decoration: line-through;color:#a3a3a3">\n' +
  //             '        <span price-with-currency formatting="price" digits="2"\n' +
  //             '              currency="{{currency}}" price="{{permissionGroup.price}}"></span>\n' +
  //             '        </h4>\n' +
  //             '        <h3 style="display: inline-block;" ng-if="permissionGroup.discountPrice > 0">\n' +
  //             '        <span price-with-currency formatting="price" digits="2"\n' +
  //             '              currency="{{currency}}" price="{{permissionGroup.discountPrice}}"></span>\n' +
  //             '        </h3>\n' +
  //             '        <h3 style="display: inline-block;" ng-if="permissionGroup.discountPrice === 0"' +
  //             '            translate="label.vendor.permissionGroup.free"></h3>\n' +
  //             '    </span>\n' +
  //             '    <span ng-if="!permissionGroup.price || permissionGroup.price == 0">\n' +
  //             '        <h3 style="display: inline-block;" translate="label.vendor.permissionGroup.free"></h3>\n' +
  //             '    </span>\n' +
  //             '</div>\n',
  //         scope: {
  //             permissionGroup: "=",
  //             currency:"@?"
  //         },
  //         controller: function ($scope) {
  //             $scope.currency = $scope.currency || 'usd';
  //
  //         }
  //     };
  // })
  //
  .directive('csvReader', function (_) {
    return {
      restrict: 'A',
      scope: {
        csvReader: '=',
      },
      link: function ($scope, $element) {
        var csv2json = function (csv) {
          // var result = [];
          // var lines = csv.split('\n');
          // var header = lines[0].split(',');
          //
          // _.forEach(lines, function (line, lineNumber) {
          //     if (lineNumber > 0){
          //         var row = line.split(',');
          //         var record = {};
          //         _.forEach(header, function (name, j) {
          //             record[name] = row[j];
          //         });
          //         result.push(record);
          //     }
          // });
          //
          // return result;

          function parseLine(text) {
            var regex =
              /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
            var arr = [];
            text.replace(regex, function (m0, m1, m2, m3) {
              if (m1 !== undefined) {
                arr.push(m1.replace(/\\'/g, "'"));
              } else if (m2 !== undefined) {
                arr.push(m2.replace(/\\"/g, '"'));
              } else if (m3 !== undefined) {
                arr.push(m3);
              }
              return '';
            });
            if (/,\s*$/.test(text)) {
              arr.push('');
            }
            return arr;
          }

          function zipObject(props, values) {
            return props.reduce(function (prev, prop, i) {
              prev[prop] = values[i];
              return prev;
            }, {});
          }

          var lines = csv.split('\n');
          var header = parseLine(lines[0]);
          lines.shift();
          return lines.map(function (line) {
            return zipObject(header, parseLine(line));
          });
        };

        $($element).on('change', function (changeEvent) {
          var files = changeEvent.target.files;
          if (files.length) {
            var r = new FileReader();
            r.onload = function (e) {
              var contents = e.target.result;
              $scope.$apply(function () {
                $scope.csvReader = csv2json(contents);
              });
            };
            r.readAsText(files[0]);
          }
        });
      },
    };
  })
  .directive('autofocus', function ($timeout) {
    return {
      restrict: 'A',
      link: function ($scope, $element) {
        $timeout(function () {
          $element[0].focus();
        });
      },
    };
  });
