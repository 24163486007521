angular
  .module('vcio-toolkit')

  .controller(
    'SidebarPermissionGroupController',
    function (
      $modal,
      $q,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $translate,
      _,
      AssetCategoryModel,
      AssetModel,
      AssetService,
      CurrentUser,
      DialogService,
      HttpService,
      SidebarFilterService,
    ) {
      $scope.currentUser = CurrentUser.getUser();
      $scope.categories = [];

      $q.all([
        HttpService.get('/api/lists/solutionsetTypes'),
        HttpService.get('/api/lists/userRoles'),
      ]).then(function (results) {
        $scope.filter = SidebarFilterService.get('permissiongroups', $scope, {
          price: [
            {
              name: 'Free',
              min: 0,
              max: 0,
            },
            {
              name: '$1 - $500',
              min: 0,
              max: 500,
            },
            {
              name: '$500 - $1000',
              min: 500,
              max: 1000,
            },
            {
              name: '$1000 - $2000',
              min: 1000,
              max: 2000,
            },
            {
              name: '$2000 & Above',
              min: 2000,
              max: Number.MAX_SAFE_INTEGER,
            },
          ],
          assetType: [
            {
              code: 'meetingTemplates',
              name: 'label.sidebar.permissionGroupFilter.assetType.report',
            },
            {
              code: 'onetimeServices',
              name: 'label.sidebar.permissionGroupFilter.assetType.onetimeService',
            },
            { code: 'courses', name: 'label.sidebar.permissionGroupFilter.assetType.course' },
            {
              code: 'knowledgebases',
              name: 'label.sidebar.permissionGroupFilter.assetType.knowledgebase',
            },
            { code: 'trainings', name: 'label.sidebar.permissionGroupFilter.assetType.training' },
            { code: 'resources', name: 'label.sidebar.permissionGroupFilter.assetType.resource' },
            { code: 'roadmaps', name: 'label.sidebar.permissionGroupFilter.assetType.roadmap' },
            {
              code: 'ongoingServices',
              name: 'label.sidebar.permissionGroupFilter.assetType.ongoingService',
            },
            {
              code: 'serviceBundles',
              name: 'label.sidebar.permissionGroupFilter.assetType.serviceBundle',
            },
            { code: 'graders', name: 'label.sidebar.permissionGroupFilter.assetType.grader' },
            {
              code: 'emailsequences',
              name: 'label.sidebar.permissionGroupFilter.assetType.emailsequence',
            },
          ],
          createdAt: [
            {
              name: 'Last 30 days',
              days: 30,
            },
            {
              name: 'Last 90 days',
              days: 90,
            },
          ],
          type: _(results[0])
            .map(function (item) {
              return {
                code: item,
                name: $translate.instant('solutionsetTypes.' + item),
              };
            })
            .sortBy('name')
            .value(),
          userRoles: _(results[1])
            .map(function (item) {
              return {
                code: item,
                name: $translate.instant('userRoles.' + item),
                // selected: _.includes($scope.currentUser.roles, item)
              };
            })
            .union([
              {
                code: '',
                name: $translate.instant('userRoles.none'),
              },
            ])
            .value(),
        }).filter;
      });

      function refreshCategoryTree() {
        HttpService.get('/api/vendor/permissiongroupcategorytree')
          .then(function (categories) {
            $scope.categories = _.map(categories, function (category) {
              category.size = _(category.SubCategories)
                .flatMap('PermissionGroups')
                .uniqBy('id')
                .size();
              category.SubCategories = _.map(category.SubCategories, function (subcategory) {
                subcategory.size = subcategory.PermissionGroups.length;
                return subcategory;
              });
              return category;
            });

            // $scope.filter.vendors = _($scope.categories).flatMap('SubCategories').flatMap('PermissionGroups').map('Vendor').uniqBy('id').sortBy('name').value();
            SidebarFilterService.get('permissiongroups').setList(
              'vendors',
              _($scope.categories)
                .flatMap('SubCategories')
                .flatMap('PermissionGroups')
                .map('Vendor')
                .uniqBy('id')
                .sortBy('name')
                .value(),
            );
            expandActiveCategoryTree();
          })
          .catch(function (error) {
            console.error(error);
          });
      }

      function resetActiveCategoryTree(items) {
        _.forEach(items, function (item) {
          if (item.$$active) {
            item.$$active = false;
          }
          if (item.SubCategories && item.SubCategories.length) {
            resetActiveCategoryTree(item.SubCategories);
          }
        });
      }

      function expandActiveCategoryTree() {
        if ($scope.categories.length) {
          resetActiveCategoryTree($scope.categories);
          var category;
          var subcategory;

          if ($stateParams.categoryId) {
            category = _.find($scope.categories, { id: parseInt($stateParams.categoryId) });
            if (!category) {
              _.some($scope.categories, function (_category) {
                subcategory = _.find(_category.SubCategories, {
                  id: parseInt($stateParams.categoryId),
                });
                category = _category;
                return !!subcategory;
              });
            }
            if (subcategory) {
              subcategory.$$active = true;
              category.$$expanded = true;
            } else if (category) {
              category.$$active = true;
            }
          }
        }
      }

      refreshCategoryTree();

      $scope.$on('login-changed-event', function () {
        refreshCategoryTree();
      });

      // $scope.setSubscriptionFiltering = function () {
      //     if (!$scope.filter.currentUserProductId) {
      //         $scope.filter.currentUserProductId = _.get($scope.currentUser, 'Company.subscription.product', "");
      //     } else {
      //         $scope.filter.currentUserProductId = "";
      //     }
      // };
    },
  );
