angular
  .module('vcio-toolkit')

  .factory(
    'Auth',
    function (
      $interval,
      $modal,
      $rootScope,
      $window,
      _,
      CurrentUser,
      HttpService,
      LocalStorage,
      InputSanitizerService,
      $state,
    ) {
      var tokenRefresher;

      var setUser = function (user, token) {
        CurrentUser.setToken(token);
        user.surveyTypes = [];
        CurrentUser.setUser(user);

        HttpService.get('/api/admin/templatetypes').then(function (templates) {
          user.templateTypes = templates;
          user.surveyTypes = templates.surveys;

          CurrentUser.setUser(user);
          $rootScope.$broadcast('login-changed-event');
        });

        // if (typeof inline_manual_player !== 'undefined') {
        //     window.inlineManualTracking = {
        //         uid: user.id,
        //         email: user.email,
        //         name: user.firstName + ' ' + user.lastName,
        //         group: user.Company ? user.Company.name : undefined
        //     };
        //     inline_manual_player.setMetadata({
        //         uid: user.id,
        //         email: user.email,
        //         name: user.firstName + ' ' + user.lastName,
        //         group: user.Company ? user.Company.name : undefined
        //     });
        // }
        // if (HS) {
        //     HS.beacon.ready(function () {
        //         HS.beacon.identify({
        //             name: user.firstName + ' ' + user.lastName,
        //             email: user.email,
        //             company: user.Company ? user.Company.name : undefined,
        //             companyId: user.Company ? user.Company.id : undefined
        //         });
        //     });
        // }
        // if (typeof analytics != 'undefined') {
        //     analytics.identify(user.id, {
        //         name: user.firstName + ' ' + user.lastName,
        //         email: user.email,
        //         traits: user
        //     });
        // }
        if (window._hsq) {
          // document.getElementById('hubspot-messages-iframe-container').classList.remove('invisible');

          window._hsq.push([
            'identify',
            {
              email: user.email,
              id: user.id,
            },
          ]);
          window._hsq.push([
            'trackEvent',
            {
              id: 'login',
            },
          ]);
          window._hsq.push(['trackPageView']);
          window.HubSpotConversations.widget.refresh();
        }
        // if (typeof window.Intercom !== 'undefined') {
        //     window.Intercom('boot', {
        //         app_id: params.intercomId,
        //         user_id: user.id,
        //         user_hash: user.intercomHMAC,
        //         name: user.firstName + ' ' + user.lastName,
        //         email: user.email,
        //         created_at: Math.round($moment(user.createdAt).valueOf() / 1000)
        //     });
        // }
        if (typeof window.Appcues !== 'undefined') {
          Appcues.identify(user.id, {
            name: user.name,
            email: user.email,
            created_at: user.createdAt,
          });
        }
        if (window.smartlook) {
          window.smartlook('identify', user.id, {
            name: user.firstName + ' ' + user.lastName,
            email: user.email,
            permissionGroups: _.isEmpty(user.Company.PermissionGroups)
              ? ''
              : ',' + _.join(user.Company.PermissionGroups, ',') + ',',
          });
        }

        if (window.Canny) {
          window.Canny('identify', {
            appID: '5bb79f7aff988b4d59882b9e',
            user: {
              email: user.email,
              name: user.firstName + ' ' + user.lastName,
              id: user.id,
              avatarURL: 'https://' + apphostname + '/images/users/' + user.id,
              created: new Date(user.createdAt).toISOString(),
            },
          });
        }
        HttpService.post('/api/admin/users/' + user.id + '/timeline/login');
        if (_.isEmpty(user.roles)) {
          var modalInstance = $modal.open({
            templateUrl: '/templates/admin/user-recommendations.html',
            controller: 'UserRecommendationsController',
            // size: 'lg',
            backdrop: 'static',
            keyboard: false,
            resolve: {
              userRoles: function (HttpService) {
                return HttpService.get('/api/lists/userRoles');
              },
            },
          });
        }
      };

      var refreshToken = function (cb) {
        if (
          !(document.hidden || document.msHidden || document.webkitHidden) &&
          CurrentUser &&
          CurrentUser.isAuthenticated() &&
          CurrentUser.getUser()
        ) {
          HttpService.get('/api/auth/token').then(function (result) {
            setUser(result.user, result.token);
            if (cb) {
              cb();
            }
            var hsContainer = document.getElementById('hubspot-messages-iframe-container');
            if (hsContainer) {
              hsContainer.classList.remove('invisible');
            }
          });
        }
      };

      var setCompanySettingsValue = function (name, value, cb) {
        if (name) {
          return HttpService.put(
            '/api/companies/' +
              InputSanitizerService.sanitize(CurrentUser.getUser().Company.id) +
              '/settings/' +
              InputSanitizerService.sanitize(name),
            { value: value },
          )
            .then(function () {
              var user = CurrentUser.getUser();
              _.set(user.Company.settings, name, value);
              CurrentUser.setUser(user);
              if (cb) {
                cb();
              }
            })
            .catch(function (error) {
              if (cb) {
                cb(error);
              }
            });
        }
      };

      var getCompanySettingsValue = function (name, defaultValue) {
        return _.get(CurrentUser.getUser().Company.settings, name, defaultValue);
      };

      // /**
      //  * refresh the user info and the token in 5 minutes interval
      //  */
      // var startTokenRefresher = function () {
      //     if (!tokenRefresher) {
      //         tokenRefresher = $interval(refreshToken, 5 * 60 * 1000);
      //     }
      // };

      return {
        setUser: setUser,
        setCompanySettingsValue: setCompanySettingsValue,
        getCompanySettingsValue: getCompanySettingsValue,
        // startTokenRefresher: startTokenRefresher,
        refreshToken: refreshToken,

        logout: function () {
          // The backend doesn't care about logouts, delete the token and the user
          CurrentUser.clear();
          LocalStorage.delete('companyNameFilter');
          LocalStorage.delete('companyIdFilter');
          $rootScope.previousState = undefined;
          //delete $window.sessionStorage.token;
          //delete $window.sessionStorage.userCompanyId;
          $state.go('login');
        },
      };
    },
  )

  .config(function ($httpProvider) {
    $httpProvider.interceptors.push('authInterceptor');
  });
