angular
  .module('vcio-toolkit')
  .service(
    'CompanyService',
    function ($modal, $rootScope, $translate, CurrentUser, HttpService, InputSanitizerService) {
      const defaultIcons = {
        planned: 'fa-fw fa-calendar',
        approved: 'fa-fw fa-calendar-check-o',
        inProgress: 'fa-fw fa-clock-o',
        finished: 'fa-fw fa-check',
        recommendation: 'fa-fw fa-lightbulb-o',
        issue: 'fa-fw fa-exclamation-triangle',
        none: 'fa-fw',
      };
      return {
        getProjectStatuses: function () {
          if (CurrentUser.isAuthenticated()) {
            return HttpService.get('/api/company/projectStatuses').then(function (result) {
              const mapStatuses = function (status) {
                const defaultIcon = defaultIcons[status.code || 'recommendation'];

                return {
                  orderId: status.orderId,
                  code: status.code,
                  name: status.name,
                  icon: status.icon,
                  defaultIcon: defaultIcon,
                  languageKey: 'projectStatuses.' + status.code,
                };
              };

              return result.map(mapStatuses);
            });
          } else {
            return Promise.resolve();
          }
        },

        openEditCompany: function (company, ok, cancel) {
          var modalScope = $rootScope.$new();
          modalScope.modalInstance = $modal.open({
            templateUrl: '/templates/admin/company-edit.html',
            controller: 'CompanyEditController',
            scope: modalScope,
            backdrop: 'static',
            size: 'lg',
            resolve: {
              company: function () {
                return angular.copy(company);
              },
            },
          });

          modalScope.modalInstance.result.then(ok, cancel);
        },

        getColleagues: function () {
          return HttpService.get(
            '/api/admin/users?companyId=' +
              InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
          ).then(function (users) {
            if (!_.find(users, { id: CurrentUser.getUser().id })) {
              users.unshift(CurrentUser.getUser());
            }
            return _.map(users, function (user) {
              return _.extend({ name: user.firstName + ' ' + user.lastName }, user);
            });
          });
        },
      };
    },
  );
