angular.module('vcio-toolkit');

// .controller('LaunchSurveyController', function ($scope, $state, $stateParams, $http, $translate, $compile, $modal, _, CurrentUser, client, surveys) {
//     $scope.labelOK = 'button.save';
//     $scope.currentUser = CurrentUser.getUser();
//     $scope.surveys = _.sortBy(surveys, 'name');
//     $scope.errors = [];
//
//     $scope.client = client;
//     $scope.contacts = $scope.client.Contacts;
//
//     $scope.emailBodyPreview = '';
//     $scope.templates = [];
//     $scope.surveyChanged = false;
//
//     $scope.surveyResult = {
//         ClientId: $scope.client.id,
//         sendLater: true
//     };
//
//     $scope.$watch('surveyResult.Survey', function (value, old) {
//         if (!old) {
//             $scope.setTitle();
//         } else if (old != value) {
//             $scope.surveyChanged = true;
//         }
//     });
//
//     if ($scope.surveys.length > 0) {
//         $scope.surveyResult.title = $scope.surveys[0].name;
//     }
//
//     $scope.getEmailFunction = function (item) {
//         return item ? (item.firstName + " " + item.lastName + " <" + item.email + ">") : '';
//     };
//
//     $scope.getContactName = function (item) {
//         return item.firstName + ' ' + item.lastName + ' <' + item.email + '>';
//     };
//
//     $scope.getSurveyName = function (item) {
//         return item.name ? item.name : item.code;
//     };
//
//     if (!$scope.surveyResult.ContactId && $scope.contacts.length > 0) {
//         $scope.surveyResult.ContactId = $scope.contacts[0].id
//     }
//
//     $scope.setTitle = function () {
//         if ($scope.surveyResult.Survey) {
//             $scope.surveyResult.title = $scope.surveyResult.Survey.name;
//         }
//     };
//
//     $scope.updatePreview = function () {
//         $scope.contact = _.find($scope.contacts, {id: parseInt($scope.surveyResult.ContactId)});
//         $scope.emailBodyPreview = angular.copy($scope.surveyResult.emailBody);
//     };
//
//     $scope.updateEmail = function () {
//         var selected = _.find($scope.templates, 'en');
//         $scope.surveyResult.emailBody = selected.body;
//         $scope.surveyResult.subject = selected.subject;
//         $scope.updatePreview();
//     };
//
//     $scope.save = function (cb) {
//         var data = $scope.surveyResult;
//         data.contact = $scope.contact;
//         if (!$scope.surveyResult.id) {
//             $http.post('/api/surveyresults', {surveyResult: _.omit(data, ['survey'])})
//                 .success(function (result) {
//                     $scope.surveyResult.id = result.id;
//                     $scope.surveyResult.uuid = result.uuid;
//                     $scope.surveyResult.url = result.url;
//                     $scope.messages = ["launch.created"];
//                     /*$scope.labelOK = 'button.invite';*/
//                     $scope.save = false;
//                     cb();
//                 })
//                 .error(function (error) {
//                     $scope.errors.push(error);
//                     console.error(error);
//                     cb();
//                 });
//
//         } else {
//             //$scope.invite();
//             $scope.save = false;
//             cb();
//         }
//     };
//
//     $scope.cancel = function () {
//         if ($scope.modalInstance) {
//             $scope.modalInstance.close();
//         } else {
//             if ($scope.save === false){
//                 $state.go('sales.dashboard', { clientId: $scope.client.id });
//             } else {
//                 $state.go('sales.home')
//             }
//         }
//     };
//
//     $scope.openAddContact = function () {
//         var modalInstance = $modal.open({
//             templateUrl: '/templates/admin/contact-edit.html',
//             controller: 'ContactEditController',
//             backdrop: 'static',
//             resolve: {
//                 contact: function () {
//                     return {status: 'customer'};
//                 },
//                 client: function () {
//                     return $scope.client;
//                 }
//             }
//         });
//
//         modalInstance.result.then(function (user) {
//             //refresh modified data
//             $scope.contacts.push(user);
//         }, function () {
//         });
//     };
//
//     $scope.openAddSurvey = function(){
//         var modalInstance = $modal.open({
//             templateUrl: '/templates/sales/survey-add-directly.html',
//             controller: 'SurveyAddController',
//             backdrop: 'static',
//             resolve: {
//                 surveys: function ($stateParams, $http) {
//                     return $http.get('/api/surveytemplates');
//                 }
//             }
//         });
//
//         modalInstance.result.then(function (survey) {
//             //refresh modified data
//             $scope.surveys.push(survey);
//             $scope.surveys = _.sortBy($scope.surveys, 'name');
//             $scope.surveyResult.Survey = survey;
//         }, function () {
//         });
//     };
//
//
//     $http.get('/api/templates/mail/launch').success(function (templates) {
//         $scope.templates = templates;
//         var selected = _.find($scope.templates, {language: 'en'});
//         $scope.surveyResult.emailBody = selected.body;
//         $scope.surveyResult.subject = selected.subject;
//         $scope.surveyResult.language = selected.language;
//         $scope.updatePreview()
//     });
//
//     $scope.invite = function () {
//         $state.go('sales.invite', {surveyResultId: $scope.surveyResult.id});
//         // var modalInstance;
//         // // launchSurveyService.getData($scope.client.id, $scope.surveyResult.id).then(function (data) {
//         // //     $scope.sendSurveyData = data;
//         //
//         // modalInstance = $modal.open({
//         //     templateUrl: '/templates/msp/survey-invite.html',
//         //     controller: 'InviteSurveyController',
//         //     backdrop: 'static',
//         //     size: 'lg',
//         //     resolve: {
//         //         surveyResult: function () {
//         //             return $scope.surveyResult;
//         //         },
//         //         senders: function (HttpService) {
//         //             return HttpService.get('/api/admin/users');
//         //         }
//         //     }
//         // });
//         //
//         // modalInstance.result.then(function (survey) {
//         //     $scope.sendSurveyData = null;
//         //     $scope.$broadcast('surveyresult-added', survey)
//         // });
//         // // })
//     }
//
// })
//
// .controller('InviteSurveyController', function ($scope, $state, $stateParams, $http, $translate, $compile, $modal, _, CurrentUser, surveyResult, senders) {
//     $scope.surveys = [];
//     $scope.errors = [];
//     $scope.currentUser = CurrentUser.getUser();
//
//     $scope.surveyResult = surveyResult;
//     $scope.senders = _.filter(senders, function (user) {
//         return _.find(user.Companies, {id: $scope.currentUser.Company.id});
//     });
//     $scope.client = surveyResult.Client;
//     $scope.contact = surveyResult.Contact;
//     $scope.url = $scope.surveyResult.url || $scope.surveyResult.Survey.LandingPage.url + ($scope.surveyResult.Survey.LandingPage.url.indexOf('?') > -1 ? '&' : '?') + 'survey=' + $scope.surveyResult.uuid;
//     $scope.emailBodyPreview = '';
//     $scope.sender = CurrentUser.getUser();
//     $scope.templates = [];
//
//     $scope.surveyResult.language = $translate.use();
//
//     if (surveyResult.roles.length) {
//         $scope.$watch('surveyResult.Role', function () {
//             var role = _.find($scope.surveyResult.roles, {code: $scope.surveyResult.Role});
//             if (role) {
//                 $scope.url = $scope.surveyResult.Survey.LandingPage.url +
//                     ($scope.surveyResult.Survey.LandingPage.url.indexOf('?') > -1 ? '&' : '?') + 'survey=' +
//                     $scope.surveyResult.uuid + '_' + role.uuid;
//             }
//             $scope.updatePreview();
//         });
//     }
//     $scope.$watch('surveyResult.SenderId', function () {
//         $scope.updatePreview();
//     });
//
//     $scope.getEmailFunction = function (item) {
//         return item ? (item.firstName + " " + item.lastName + " <" + item.email + ">") : '';
//     };
//
//     $scope.updatePreview = function () {
//         // $scope.contact = _.find($scope.contacts, {id: parseInt($scope.surveyResult.ContactId)})
//         $scope.sender = _.find($scope.senders, {id: parseInt($scope.surveyResult.SenderId)});
//         $scope.emailBodyPreview = angular.copy($scope.surveyResult.emailBody);
//     };
//
//     $scope.updateEmail = function () {
//         var selected = _.find($scope.templates, {language: $scope.surveyResult.language});
//         $scope.surveyResult.emailBody = selected.body;
//         $scope.surveyResult.subject = selected.subject;
//         $scope.updatePreview();
//     };
//
//     $scope.cancel = function () {
//         if ($scope.modalInstance) {
//             $scope.modalInstance.close();
//         } else {
//             $state.go('sales.home');
//         }
//     };
//
//     $http.get('/api/templates/mail/launch').success(function (templates) {
//         $scope.templates = templates;
//         var selected = _.find($scope.templates, {language: $scope.surveyResult.language});
//         $scope.surveyResult.emailBody = selected.body;
//         $scope.surveyResult.subject = selected.subject;
//         $scope.surveyResult.language = selected.language;
//         $scope.updatePreview()
//     });
//
//     $scope.send = function (cb) {
//         $http.put('/api/surveyresults/' + $scope.surveyResult.id + '/invite', {
//             sender: $scope.sender,
//             subject: $scope.surveyResult.subject,
//             language: $scope.surveyResult.language,
//             emailBody: $scope.surveyResult.emailBody
//         }).then( function() {
//                 $scope.messages = ['launch.sent'];
//                 cb();
//                 //$modalInstance.close();
//             }).catch(function (response) {
//             $scope.errors = [response.data];
//             cb();
//         });
//     }
// });
