angular
  .module('vcio-toolkit')

  .directive('academyBreadcrumbs', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        model: '=',
      },
      template:
        '<h6>' +
        '    <span ng-repeat="item in breadcrumbs">' +
        '        <span ng-if="item.href"><a ng-href="{{item.href}}" class="text-default">{{item.name}}</a> &gt;</span>' +
        '        <span ng-if="!item.href" class="text-muted">{{item.name}}</span></span>' +
        '</h6>',
      controller: function ($scope, $state, $stateParams, $translate, _, AssetModel) {
        //$state.href('academy.asset', {assetId: 951})
        $scope.breadcrumbs = [];
        // if($state.current.name === 'academy.roadmapCourseAsset'){
        //     $scope.roadmap = $scope.AssetManager.getAssetById(parseInt($stateParams.roadmapId));
        // }

        function fillCourseBreadcrumbs() {
          // var course = AssetManager.getAssetById(parseInt($stateParams.courseId));
          // course.SubCategory = AssetManager.getCategoryById(course.SubCategoryId);
          // course.Category = AssetManager.getCategoryById(course.SubCategory.CategoryId);

          $scope.breadcrumbs.push({
            name: $translate.instant('label.academy.assetTypes.course'),
            href: $state.href('academy.assetcategoriesByType', {
              type: 'course',
            }),
          });
          if ($scope.model.Course.SubCategory) {
            $scope.breadcrumbs.push({
              name: $scope.model.Course.SubCategory.Category.name,
              href: $state.href('academy.assetcategory', {
                categoryId: $scope.model.Course.SubCategory.Category.id,
              }),
            });
            $scope.breadcrumbs.push({
              name: $scope.model.Course.SubCategory.name,
              href: $state.href('academy.assetcategory', {
                categoryId: $scope.model.Course.SubCategory.Category.id,
                '#': $scope.model.Course.SubCategory.id,
              }),
            });
          }
          $scope.breadcrumbs.push({
            name: $scope.model.Course.name,
            href: $state.href('academy.course', {
              courseId: $scope.model.Course.id,
            }),
          });
          $scope.breadcrumbs.push({ name: $scope.model.name });
        }

        function fillRoadmapBreadcrumbs(state_name) {
          // var course = AssetManager.getAssetById(parseInt($stateParams.courseId));
          // course.SubCategory = AssetManager.getCategoryById(course.SubCategoryId);
          // course.Category = AssetManager.getCategoryById(course.SubCategory.CategoryId);
          $scope.breadcrumbs.push({
            name: $translate.instant('label.academy.assetTypes.roadmap'),
            href: $state.href('academy.assetcategoriesByType', {
              type: 'roadmap',
            }),
          });
          if (state_name === 'academy.roadmapCourseAsset') {
            $scope.roadmap = $scope.model.Roadmap;
            if ($scope.roadmap.SubCategory) {
              $scope.breadcrumbs.push({
                name: $scope.roadmap.SubCategory.Category.name,
                href: $state.href('academy.assetcategory', {
                  categoryId: $scope.roadmap.SubCategory.Category.id,
                }),
              });
              $scope.breadcrumbs.push({
                name: $scope.roadmap.SubCategory.name,
                href: $state.href('academy.assetcategory', {
                  categoryId: $scope.roadmap.SubCategory.Category.id,
                  '#': $scope.roadmap.SubCategory.id,
                }),
              });
            }
            $scope.breadcrumbs.push({
              name: $scope.roadmap.name,
              href: $state.href('academy.roadmap', {
                roadmapId: $scope.roadmap.id,
              }),
            });
            $scope.breadcrumbs.push({
              name: $scope.model.Course.name,
              href: $state.href('academy.roadmapCourse', {
                roadmapId: $scope.roadmap.id,
                sectionId: $stateParams.sectionId,
                courseId: $scope.model.Course.id,
              }),
            });
          } else {
            if ($scope.model.Roadmap.SubCategory) {
              $scope.breadcrumbs.push({
                name: $scope.model.Roadmap.SubCategory.Category.name,
                href: $state.href('academy.assetcategory', {
                  categoryId: $scope.model.Roadmap.SubCategory.Category.id,
                }),
              });
              $scope.breadcrumbs.push({
                name: $scope.model.Roadmap.SubCategory.name,
                href: $state.href('academy.assetcategory', {
                  categoryId: $scope.model.Roadmap.SubCategory.Category.id,
                  '#': $scope.model.Roadmap.SubCategory.id,
                }),
              });
            }
            $scope.breadcrumbs.push({
              name: $scope.model.Roadmap.name,
              href: $state.href('academy.roadmap', {
                roadmapId: $scope.model.Roadmap.id,
              }),
            });
          }
          $scope.breadcrumbs.push({ name: $scope.model.name });
        }

        function fillBreadcrumbs() {
          // academy.asset or academy.course
          $scope.breadcrumbs.push({
            name: $translate.instant('label.academy.assetTypes.' + $scope.model.type),
            href: $state.href('academy.assetcategoriesByType', {
              type: $scope.model.type,
            }),
          });
          if ($scope.model.Category) {
            $scope.breadcrumbs.push({
              name: $scope.model.Category.name,
              href: $state.href('academy.assetcategory', {
                categoryId: $scope.model.Category.id,
              }),
            });
          }
          if ($scope.model.SubCategory) {
            $scope.breadcrumbs.push({
              name: $scope.model.SubCategory.name,
              href: $scope.model.Category
                ? $state.href('academy.assetcategory', {
                    categoryId: $scope.model.Category.id,
                    '#': $scope.model.SubCategory.id,
                  })
                : undefined,
            });
          }
          $scope.breadcrumbs.push({ name: $scope.model.name });
        }

        if (
          $state.current.name ===
          'academy.courseAsset' /* || $state.current.name === 'academy.courseQuiz'*/
        ) {
          fillCourseBreadcrumbs();
        } else if (
          $state.current.name === 'academy.roadmapCourse' ||
          $state.current.name === 'academy.roadmapCourseAsset'
        ) {
          fillRoadmapBreadcrumbs($state.current.name);
        } else {
          fillBreadcrumbs();
        }

        $scope.$watch('model.SubCategoryId', function () {
          if (
            $state.current.name !==
              'academy.courseAsset' /* && $state.current.name !== 'academy.courseQuiz'*/ &&
            $state.current.name !== 'academy.roadmapCourse' &&
            $state.current.name !== 'academy.roadmapCourseAsset'
          ) {
            $scope.breadcrumbs = [];
            fillBreadcrumbs();
          }
        });
      },
    };
  })

  .directive('academyAssetRow', function () {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: '/templates/academy/asset-row.html',
      scope: {
        asset: '=',
        assetHref: '@?',
        assetMarkAsCompleted: '=?',
        assetPopover: '=?',
      },
      controller: function ($rootScope, $scope, $element, _, AssetService, HttpService) {
        if (!$scope.assetHref) {
          $scope.assetHref = AssetService.getAssetLink($scope.asset);
        }
        if (!_.isEmpty($scope.asset.Users)) {
          $scope.asset.finishedAt = $scope.asset.Users[0].UserAcademyAsset.finishedAt;
        }
        $scope.markAsCompleted = function (asset) {
          if (asset.type !== 'quiz') {
            $rootScope.$broadcast('dataLoadingStarted');
            AssetService.finish(asset).finally(function () {
              $rootScope.$broadcast('dataLoadingFinished');
            });
          }
        };
        $scope.getAssetTypeIcon = AssetService.getAssetTypeIcon;
        $scope.getAssetTypeIconTitle = AssetService.getAssetTypeIconTitle;
        //console.log($scope.asset);
      },
    };
  })

  .directive('academyTrainers', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        trainers: '=',
      },
      template:
        '<h6 ng-show="trainersList && trainersList.length > 0"><span translate="label.academy.assetTypeList.writtenBy"></span>' +
        "<span ng-repeat=\"trainer in trainersList track by $index\">{{$index > 0 ? ',': ''}} {{trainer.firstName}} {{trainer.lastName}}</span><br>" +
        '<span ng-repeat="trainer in trainersList track by $index">' +
        '   <div class="img-circle margin-1x-top margin-1x-right" back-img="/images/users/{{trainer.id}}" style="display: inline-block; width: 40px; height: 40px;">' +
        '</span>' +
        '</h6>',

      controller: function ($scope, _) {
        $scope.trainersList = _.uniqBy(
          _.without(
            _.isArray($scope.trainers) ? $scope.trainers : [$scope.trainers],
            undefined,
            null,
          ),
          function (o) {
            return o.email;
          },
        );
      },
    };
  });
