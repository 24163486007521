angular
  .module('vcio-toolkit')
  .controller(
    'LoginController',
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $location,
      $window,
      $cookies,
      _,
      CurrentUser,
      Auth,
      HttpService,
    ) {
      if (CurrentUser.isAuthenticated()) {
        $state.go('empty');
      }
      $scope.user = {};
      $scope.error = '';
      $scope.companies = null;
      $scope.clientPortalUrl = '/new-frontend?path=client-portal';
      $scope.rememberme = $cookies.rememberme ? $cookies.rememberme : false;
      // $scope.isVendorDomain = Utils.isVendorDomain();
      $scope.loginVideo = {
        id: serverConfigSettings.loginVideo,
        title: serverConfigSettings.loginVideoTitle,
      };

      $scope.showResetSuccess = function () {
        return $stateParams.message && $stateParams.message === 'resetPasswordSuccess';
      };

      $scope.getLoginBoxStyle = function () {
        return $scope.loginVideo.id ? 'with-video' : 'without-video';
      };

      if (/*$scope.rememberme && */ $cookies.user) {
        var _user = JSON.parse($cookies.user);
        if (_user.version != $window.appversion) {
          delete $cookies.user;
        } else {
          $scope.user = _user;
          //$scope.companies = $scope.user.companies
        }
      }

      $scope.sendTFA = function () {
        HttpService.post('/api/auth/tfa/send/' + $scope.user.email)
          .then(function () {
            $rootScope.$broadcast('dataLoadingFinished');
            $scope.message = 'login.tfa.smsSent';
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            console.error(error);
          });
      };

      $scope.login = function () {
        $rootScope.$broadcast('dataLoadingStarted');
        $scope.error = '';

        // var user = CurrentUser.getUser();
        // if (user && user.Company && user.Company.id &&
        //     credentials && credentials.company && credentials.company.id === user.Company.id) {
        //     // if we have a previously selected company id, use that one
        //     credentials.company = user.Company;
        // }

        HttpService.post('/api/auth/login', $scope.user)
          .then(function (userAndToken) {
            if (userAndToken && userAndToken.redirect) {
              window.location = userAndToken.redirect;
              return;
            }
            $rootScope.$broadcast('dataLoadingFinished');
            if (userAndToken && userAndToken.token && userAndToken.user) {
              Auth.setUser(userAndToken.user, userAndToken.token);
              // $rootScope.loginOpened = false;
              // var currentUser = CurrentUser.getUser();
              if (
                $rootScope.previousState &&
                $rootScope.previousState.name &&
                $rootScope.previousState.name != 'empty' &&
                $rootScope.previousState.name != 'login'
              ) {
                $state.go(
                  $rootScope.previousState.name,
                  _.extend($rootScope.previousState.search, $rootScope.previousState.params),
                );
              } else {
                window.location = '/new-frontend?path=/home?token=' + userAndToken.token;
              }
              HttpService.post('/api/logger/info', {
                url: $window.location.href,
                email: userAndToken.user.email,
                companyId: userAndToken.user.Company.id,
                companyName: userAndToken.user.Company.name,
                version: appversion,
              });
            } else {
              $scope.showTFA = true;
            }
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            CurrentUser.clear();
            $scope.error = error;
          });
      };
      $scope.showSignup = function () {
        return true;
        // return $location.host() && ($location.host().substr(0, 3) == 'ryc' || $location.host().substr(0, 3) == 'tool');
      };
    },
  );
