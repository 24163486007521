var http = function () {
  this.bindFunction = function (caller, object) {
    return function () {
      return caller.apply(object, [object]);
    };
  };

  this.stateChange = function (object) {
    if (this.request.readyState == 4) {
      if (this.request.status == 200 && this.callbackFunction) {
        this.callbackFunction(this.request.responseText);
      } else if (this.errorFunction) {
        this.errorFunction(this.request);
      }
    }
  };

  this.getRequest = function () {
    if (window.ActiveXObject) return new ActiveXObject('Microsoft.XMLHTTP');
    else if (window.XMLHttpRequest) return new XMLHttpRequest();
    return false;
  };

  this.init = function (method) {
    this.request = this.getRequest();
    if (this.request) {
      var req = this.request;
      req.onreadystatechange = this.bindFunction(this.stateChange, this);
      method(req);
    }
  };

  return this;
};

http.prototype.get = function (url) {
  this.init(function (req) {
    req.open('GET', url, true);
    req.send('');
  });
  return this;
};

http.prototype.post = function (url, data) {
  this.init(function (req) {
    req.open('POST', url, true);
    req.setRequestHeader('Content-type', 'application/json');
    req.send(JSON.stringify(data));
  });
  return this;
};

http.prototype.put = function (url, data) {
  this.init(function (req) {
    req.open('PUT', url, true);
    req.setRequestHeader('Content-type', 'application/json');
    req.send(JSON.stringify(data));
  });
  return this;
};

http.prototype.success = function (callbackFunction) {
  this.callbackFunction = callbackFunction;
  return this;
};

http.prototype.error = function (errorFunction) {
  this.errorFunction = errorFunction;
  return this;
};

var ryclm_saveVisit = function (uuid) {
  new http().post(ryclm_apphostname + '/leadvisitors/' + uuid);
};

var ryclm_createCSSLink = function (document) {
  var c = document.createElement('link');
  c.setAttribute('rel', 'stylesheet');
  c.setAttribute('src', '/styles/ryclm.css');
  document.head.appendChild(c);
};

var ryclm_setBodyTopMargin = function (document, lm, options) {
  if (options.type == 'smartbar') {
    if (lm.style.display == 'none') {
      document.body.style.marginTop = '0px';
    } else {
      document.body.style.marginTop = lm.style._height + 3 + 'px';
    }
  }
};

var ryclm_checkScreen = function (options) {
  return options.showOnSmallScreen || window.innerWidth > 800;
};

var ryclm_checkPath = function (rules) {
  if (!rules || rules.length === 0) {
    return true;
  }

  var matches = true;
  for (var i = 0; i < rules.length; i++) {
    if (rules[i].type == 'show' || rules[i].type == 'showPartial') {
      matches = false;
    }
  }
  for (var i = 0; i < rules.length; i++) {
    if (
      (rules[i].type == 'show' && rules[i].url == window.location.href) ||
      (rules[i].type == 'showPartial' && window.location.href.indexOf(rules[i].url) > -1)
    ) {
      matches = true;
    }
    if (
      (rules[i].type == 'hide' && rules[i].url == window.location.href) ||
      (rules[i].type == 'hidePartial' && window.location.href.indexOf(rules[i].url) > -1)
    ) {
      matches = false;
    }
  }

  return matches;
};

var ryclm_hide = function (document, lm, options) {
  lm.style.display = 'none';
  ryclm_setBodyTopMargin(document, lm, options);
};

var ryclm_createClose = function (document, lm, options) {
  var close = document.createElement('div');
  close.setAttribute('class', 'ryclm-close');
  close.innerHTML = 'X';
  close.onclick = function () {
    ryclm_hide(document, lm, options);
    sessionStorage.setItem(options.uuid + '-closed', true);
  };
  lm.appendChild(close);
};

var ryclm_createTitle = function (document, lm, options) {
  if (options.design.title && options.type != 'smartbar') {
    var title = document.createElement('div');
    title.setAttribute('class', 'ryclm-' + options.type + '-title');
    title.style.color = options.design.textColor;
    title.innerHTML = options.design.title;
    lm.appendChild(title);
  }
};

var ryclm_createText = function (document, lm, options) {
  var text = document.createElement('div');
  text.setAttribute('id', options.uuid + '-text');
  text.setAttribute('class', 'ryclm-' + options.type + '-text');
  text.style.color = options.design.textColor;
  text.innerHTML = options.design.text;
  lm.appendChild(text);
};

var ryclm_createInputField = function (document, lm, options) {
  if (options.mode == 'email' || options.mode == 'document') {
    var input = document.createElement('input');
    input.setAttribute('type', 'text');
    input.setAttribute('id', options.uuid + '-input');
    input.setAttribute('class', 'ryclm-' + options.type + '-input');
    input.setAttribute('placeholder', options.design.inputPlaceholder);
    lm.appendChild(input);
  }
};

var ryclm_createButton = function (document, lm, options) {
  var btn = document.createElement('span');
  btn.setAttribute('type', 'button');
  btn.setAttribute('id', options.uuid + '-button');
  btn.setAttribute('class', 'ryclm-' + options.type + '-btn');
  btn.textContent = options.design.buttonText;
  btn.style.color = options.design.buttonTextColor;
  btn.style.backgroundColor = options.design.buttonBackgroundColor;
  btn.style.border = '1px solid ' + options.design.buttonBorderColor;
  btn.setAttribute('data-options', JSON.stringify(options));
  btn.onclick = function (evt) {
    ryclm_hit(JSON.parse(evt.target.getAttribute('data-options')));
  };
  lm.appendChild(btn);
};

var rycFillContainer;
var isMobile = function (agent) {
  return (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      agent,
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      agent.toLowerCase().substr(0, 4),
    )
  );
};

var ryclm_hit = function (options) {
  var input = document.getElementById(options.uuid + '-input');
  var text = document.getElementById(options.uuid + '-text');
  var button = document.getElementById(options.uuid + '-button');

  function checkEmail(cb) {
    if (
      input.value &&
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(input.value)
    ) {
      input.style.borderColor = '#cccccc';
      cb();
    } else {
      input.focus();
      input.style.borderColor = 'red';
    }
  }

  new http()
    .post(ryclm_apphostname + '/leadmagnets/' + options.id, {
      EmailSequenceId: options.EmailSequenceId,
      GraderId: options.GraderId,
      type: 'hit',
      email: input ? input.value : '',
    })
    .success(function (response) {
      if (options.mode == 'link') {
        if (options.successRedirect) {
          window.location.href = options.successRedirect;
        }
      } else if (options.mode == 'grader') {
        if (!rycFillContainer) {
          var c = document.createElement('link');
          c.setAttribute('rel', 'stylesheet');
          c.setAttribute('href', ryclm_apphostname + '/styles/fill-panel.css');
          c.setAttribute('type', 'text/css');
          document.head.appendChild(c);

          var width = Math.min(window.innerWidth, 810);
          var height = Math.min(window.window.innerHeight, 550);
          if (isMobile(navigator.userAgent || navigator.vendor || window.opera)) {
            width = window.innerWidth;
            height = window.window.innerHeight;
          }

          rycFillContainer = document.createElement('div');
          rycFillContainer.setAttribute('id', 'ryc-fill-container');
          rycFillContainer.setAttribute('class', 'rycfill-container');

          var i = document.createElement('iframe');
          i.setAttribute('id', 'ryc-fill-frame');
          i.setAttribute(
            'style',
            'width: ' + width + 'px;height: ' + height + 'px;pointer-events: "all"',
          );
          i.setAttribute('class', 'rycfill-iframe rycfill-box-shadow');
          i.setAttribute('scrolling', 'no');
          i.setAttribute('frameborder', '0');
          i.setAttribute('src', ryclm_apphostname + '/fillgrader?survey=' + options.GraderUUID);
          rycFillContainer.appendChild(i);
          document.body.appendChild(rycFillContainer);

          window.onresize = function () {
            if (isMobile(navigator.userAgent || navigator.vendor || window.opera)) {
              width = window.innerWidth;
              height = window.window.innerHeight;
            } else {
              width = Math.min(window.innerWidth, 810);
              height = Math.min(window.window.innerHeight, 550);
            }
            i.setAttribute(
              'style',
              'width: ' + width + 'px;height: ' + height + 'px;pointer-events: "all"',
            );
          };

          window.addEventListener(
            'message',
            function (e) {
              if (e.data == 'close-ryc-fill-container') {
                //rycFillContainer.remove();
                if (rycFillContainer && rycFillContainer.parentNode) {
                  rycFillContainer.parentNode.removeChild(rycFillContainer);
                  rycFillContainer = undefined;
                }
              }
            },
            false,
          );
        }
      } else if (options.mode == 'email') {
        if (input) {
          checkEmail(function () {
            if (options.successRedirect) {
              window.location.href = options.successRedirect;
            } else {
              input.parentNode.removeChild(input);
              button.parentNode.removeChild(button);
              text.innerHTML = options.design.successText || '';
            }
          });
        }
      } else if (options.mode == 'document') {
        if (input) {
          checkEmail(function () {
            window.location.href = ryclm_apphostname + '/download/' + options.FileId;
          });
        }
      }
    })
    .error(function (response) {
      input.style.borderColor = 'red';
      input.focus();
      alert('Please give your company email address');
    });
};

Date.prototype.getWeek = function () {
  // Returns the ISO week of the date.
  var date = new Date(this.getTime());
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return (
    1 +
    Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7)
  );
};

var ryclm_dateAsNum = function (date, type) {
  function pad(num, size) {
    var s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
  }

  var result = '' + date.getFullYear();
  if (type == 'month' || type == 'week' || type == 'day' || type == 'hour' || type == 'minute') {
    result += pad(date.getMonth() + 1, 2);
  }
  if (type == 'week' || type == 'day' || type == 'hour' || type == 'minute') {
    result += pad(date.getWeek(), 2);
  }
  if (type == 'day' || type == 'hour' || type == 'minute') {
    result += pad(date.getDay(), 2);
  }
  if (type == 'hour' || type == 'minute') {
    result += pad(date.getHours(), 2);
  }
  if (type == 'minute') {
    result += pad(date.getMinutes(), 2);
  }
  return parseInt(result);
};

var ryclm_checkShow = function (options) {
  var lastShown = localStorage.getItem(options.uuid + '-lastshown')
    ? new Date(localStorage.getItem(options.uuid + '-lastshown'))
    : undefined;
  var closed = !!sessionStorage.getItem(options.uuid + '-closed');
  if (!lastShown) {
    return true;
  }
  if (closed) {
    return false;
  }
  if (options.frequencyType == 'always') {
    return true;
  }

  var now = new Date();

  // if we are still in the same interval, do not display
  if (
    ryclm_dateAsNum(now, options.frequencyType) == ryclm_dateAsNum(lastShown, options.frequencyType)
  ) {
    return false;
  } else {
    return true;
  }
};

var ryclm_show = function (document, lm, options) {
  lm.style.display = 'block';
  ryclm_setBodyTopMargin(document, lm, options);
  localStorage.setItem(options.uuid + '-lastshown', new Date());
  new http().post(ryclm_apphostname + '/leadmagnets/' + options.id, {});
};

var ryclm_createShowEvents = function (document, lm, options) {
  if (options.show == 'ontime') {
    window.setTimeout(function () {
      if (ryclm_checkShow(options)) {
        ryclm_show(document, lm, options);
      }
    }, options.when * 1000);
  } else if (options.show == 'onscroll') {
    window.addEventListener('scroll', function () {
      if (lm.style.display == 'none' && ryclm_checkShow(options)) {
        ryclm_show(document, lm, options);
      }
    });
  }
};

var ryclm_createFunctions = {
  smartbar: function (document, lm, options) {
    lm.style._height = 24;
    if (options.design.size == 'M') {
      lm.style._height = 36;
    } else if (options.design.size == 'L') {
      lm.style._height = 60;
    }
    //lm.setAttribute("class", lm.getAttribute("class") + " ryclm-smartbar-small");

    lm.style.height = lm.style._height + 'px';
    lm.style.top = '0';
    lm.style.left = '0';
    if (options.behaviour == 'sticky') {
      lm.style.position = 'fixed';
    } else {
      lm.style.position = 'absolute';
    }
  },
  scrollbox: function (document, lm, options) {
    if (options.design.size == 'S') {
      lm.style.width = '200px';
    } else if (options.design.size == 'L') {
      lm.style.width = '800px';
    } else {
      lm.style.width = '400px';
    }
    lm.style.height = 'auto';

    if (options.placement == 'bottom-right') {
      lm.style.bottom = '0px';
      lm.style.right = '0px';
      lm.style.borderTopLeftRadius = '10px';
    } else if (options.placement == 'bottom-left') {
      lm.style.bottom = '0px';
      lm.style.left = '0px';
      lm.style.borderTopRightRadius = '10px';
    } else if (options.placement == 'top-left') {
      lm.style.top = '0px';
      lm.style.left = '0px';
      lm.style.borderBottomRightRadius = '10px';
    } else if (options.placement == 'top-right') {
      lm.style.top = '0px';
      lm.style.right = '0px';
      lm.style.borderBottomLeftRadius = '10px';
    }

    lm.style.position = 'fixed';
  },

  popup: function (document, lm, options) {
    if (options.design.size == 'S') {
      lm.style.width = '200px';
      lm.style.marginLeft = '-100px';
    } else if (options.design.size == 'L') {
      lm.style.width = '800px';
      lm.style.marginLeft = '-400px';
    } else {
      lm.style.width = '400px';
      lm.style.marginLeft = '-200px';
    }
    lm.style.height = 'auto';

    lm.style.top = '50%';
    lm.style.left = '50%';
    lm.style.marginTop = '-120px';
    lm.style.borderRadius = '10px';

    lm.style.position = 'fixed';
  },
};

var ryclm_createContainer = function (document, lm, options) {
  var importFont = true;
  availableCommonFonts.forEach(function (item) {
    if (options.design.fontFamily == item) {
      importFont = false;
    }
  });
  if (importFont) {
    var styleNode = document.createElement('style');
    styleNode.type = 'text/css';
    styleNode.textContent =
      "@font-face { font-family: '" +
      options.design.fontFamily +
      "'; src: url('" +
      ryclm_apphostname +
      '/fonts/' +
      options.design.fontFamily.substr(0, options.design.fontFamily.indexOf('-')) +
      '/' +
      options.design.fontFamily +
      ".ttf'); }";
    document.head.appendChild(styleNode);
  }

  lm.setAttribute('class', 'ryclm-' + options.type);
  lm.style.backgroundColor = options.design.backgroundColor;
  //lm.style.color = options.design.textColor;
  lm.style.fontFamily = options.design.fontFamily;
  lm.style.fontSize = '15px';
  if (options.design.fontSize == 'S') {
    lm.style.fontSize = '10px';
  } else if (options.design.fontSize == 'L') {
    lm.style.fontSize = '24px';
  }
};
