angular
  .module('vcio-toolkit')

  .controller(
    'SidebarClientsController',
    function (
      $moment,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $translate,
      _,
      CurrentUser,
      SidebarFilterService,
    ) {
      $scope.currentUser = CurrentUser.getUser();

      SidebarFilterService.get('clients', $scope, {
        touchPointStatuses: [
          {
            id: 'Missed',
            name: 'label.touchPointStatus.Missed',
            class: 'text-danger',
            orderId: '0',
          },
          { id: 'Done', name: 'label.touchPointStatus.Done', class: 'text-success', orderId: '2' },
          {
            id: 'Upcoming',
            name: 'label.touchPointStatus.Upcoming',
            class: 'text-warning',
            orderId: '1',
          },
        ],
      });

      $scope.filter = SidebarFilterService.get('clients', $scope, {
        compliance: [
          {
            id: 1,
            name: 'label.client.ces.sidebar.segment.compliant',
            class: 'text-success',
            orderId: '0',
          },
          {
            id: 0,
            name: 'label.client.ces.sidebar.segment.semiCompliant',
            class: 'text-warning',
            orderId: '1',
          },
          {
            id: -1,
            name: 'label.client.ces.sidebar.segment.notCompliant',
            class: 'text-danger',
            orderId: '2',
          },
          { id: undefined, name: 'label.client.ces.sidebar.segment.undefined', orderId: '3' },
        ],
      }).filter;
    },
  )

  .controller(
    'SidebarClientProjectsController',
    function (
      $moment,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $translate,
      _,
      CurrentUser,
      SidebarFilterService,
    ) {
      $scope.currentUser = CurrentUser.getUser();
      $scope.filter = SidebarFilterService.get('clientprojects', $scope, {
        order: [
          {
            name: $translate.instant('label.clients.projects.order.project'),
            orderField: 'Service.name',
            selected: true,
          },
          {
            name: $translate.instant('label.clients.projects.order.client'),
            orderField: 'Client.name',
          },
        ],
      }).filter;
    },
  )

  .controller(
    'SidebarClientMeetingsController',
    function (
      $moment,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $translate,
      _,
      CurrentUser,
      SidebarFilterService,
      InputSanitizerService,
    ) {
      $scope.currentUser = CurrentUser.getUser();
      $scope.templateFilterSelected = {};
      var sidebarFilter = SidebarFilterService.get('clientmeetings', $scope, {
        order: [
          {
            id: 1,
            name: "Score <i class='fa fa-chevron-down'>",
            orderField: 'score',
            reverse: false,
          },
          {
            id: 2,
            name: "Score <i class='fa fa-chevron-up'>",
            orderField: 'score',
            reverse: true,
          },
          {
            id: 3,
            name: "Target Score <i class='fa fa-chevron-down'>",
            orderField: 'targetScore',
            reverse: false,
          },
          {
            id: 4,
            name: "Target Score <i class='fa fa-chevron-up'>",
            orderField: 'targetScore',
            reverse: true,
          },
        ],
      });
      $scope.filter = sidebarFilter.filter;

      if ($stateParams.meetingTemplateId) {
        $scope.templateFilterSelected.item = _.find($scope.filter.meetingTemplates, {
          id: parseInt($stateParams.meetingTemplateId),
        });
      } else {
        $scope.templateFilterSelected.item = _.find($scope.filter.meetingTemplates, {
          id: InputSanitizerService.sanitize(
            _.get(sidebarFilter.retrieveSidebarFilterSelectedIds('meetingTemplates'), 0),
          ),
        });
      }
    },
  );
