angular
  .module('vcio-toolkit')

  .controller(
    'DashboardController',
    function (
      $scope,
      $rootScope,
      $translate,
      $modal,
      CurrentUser,
      HttpService,
      dashboardWidgets,
      widgetList,
      users,
      segments,
      clients,
      InputSanitizerService,
    ) {
      $scope.dashboardWidgets =
        _.map(dashboardWidgets, function (widget) {
          return _.extend(widget, { deleted: !!widget.deleted });
        }) || [];
      $scope.assignmentsDeleted = !!_.find(dashboardWidgets, {
        type: 'assignments',
        deleted: true,
      });
      $scope.privatemeetingsDeleted = !!_.find(dashboardWidgets, {
        type: 'privatemeetings',
        deleted: true,
      });
      $scope.growthDeleted = !!_.find(dashboardWidgets, { type: 'growth', deleted: true });
      $scope.widgetList = widgetList;
      $scope.users = _.map(users, function (user) {
        user.name = user.firstName + ' ' + user.lastName;
        return user;
      });
      $scope.segments = segments;
      $scope.clients = _(clients).filter('ClientSegment').sortBy('name').value();

      $scope.getWidgetControllerName = function (widget) {
        return 'Dashboard' + _.capitalize(_.get(widget, 'type')) + 'Controller';
      };

      $scope.editWidgetName = function (widget) {
        widget.$$name = widget.name;
        widget.$$isEdit = true;

        setTimeout(function () {
          document.getElementById('dashboardWidgetNameInput').focus();
        }, 0);
      };

      $scope.saveWidgetName = function (widget) {
        widget.name = widget.$$name;
        widget.$$isEdit = false;
        $scope.saveWidgets();
      };

      $scope.cancelWidgetName = function (widget) {
        widget.$$isEdit = false;
      };

      $scope.moveWidgetUp = function (widget) {
        const index = _.indexOf($scope.dashboardWidgets, widget);
        if (index === 0) {
          return;
        }

        const temp = $scope.dashboardWidgets[index];
        $scope.dashboardWidgets[index] = $scope.dashboardWidgets[index - 1];
        $scope.dashboardWidgets[index - 1] = temp;

        $scope.saveWidgets();
      };

      $scope.moveWidgetDown = function (widget) {
        const index = _.indexOf($scope.dashboardWidgets, widget);
        if (index === $scope.dashboardWidgets.length - 1) {
          return;
        }
        const temp = $scope.dashboardWidgets[index];
        $scope.dashboardWidgets[index] = $scope.dashboardWidgets[index + 1];
        $scope.dashboardWidgets[index + 1] = temp;

        $scope.saveWidgets();
      };

      $scope.filterWidgetList = function (item) {
        const unlimitedWidgets = ['sas', 'ces', 'growth'];
        const widgetCount = _.filter($scope.dashboardWidgets, {
          type: item,
          deleted: false,
        }).length;
        return _.includes(unlimitedWidgets, item) || widgetCount <= 0;
      };

      $scope.saveWidgets = function () {
        $scope.dashboardWidgets.forEach(function (widget) {
          const filter = widget.filter;

          if (widget.$$clientSegmentList && widget.$$clientSegmentList.length) {
            const clientSegmentIds = widget.$$clientSegmentList
              .filter(function (segment) {
                return segment.selected;
              })
              .map(function (segment) {
                return segment.id;
              });

            filter.segments = clientSegmentIds;
          }

          if (widget.$$userList && widget.$$userList.length) {
            const accountManagerIds = widget.$$userList
              .filter(function (user) {
                return user.selected;
              })
              .map(function (user) {
                return user.id;
              });

            filter.user = accountManagerIds;
          }
        });

        HttpService.put('/api/dashboard', { dashboardWidgets: $scope.dashboardWidgets }).then(
          function () {
            HttpService.get('/api/dashboard').then(function (result) {
              $scope.dashboardWidgets = result;
            });
          },
        );
      };

      $scope.removeWidget = function (widget) {
        if (_.includes(['growth', 'assignments', 'privatemeetings'], widget.type)) {
          widget.deleted = true;
        } else {
          _.remove($scope.dashboardWidgets, { uuid: widget.uuid });
        }

        $scope.saveWidgets();
      };

      $scope.addWidget = function (type, index) {
        if (!index || index < 0) {
          index = 0;
        }

        if (type === 'sas') {
          const modalInstance = $modal.open({
            templateUrl: 'templates/dashboard/modals/select-meeting-template.html',
            controller: function ($scope, $modalInstance, meetingTemplates) {
              $scope.meetingTemplates = meetingTemplates;
              $scope.selected = {};

              $scope.ok = function () {
                $modalInstance.close($scope.selected.template);
              };

              $scope.cancel = function () {
                $modalInstance.dismiss();
              };
            },
            resolve: {
              meetingTemplates: function (HttpService) {
                return HttpService.get('/api/meeting/templates');
              },
            },
          });

          modalInstance.result.then(function (meetingTemplate) {
            if (meetingTemplate) {
              $scope.dashboardWidgets.splice(index, 0, {
                type: type,
                deleted: false,
                name: $translate.instant(type ? 'label.dashboard.widgets.' + type : ''),
                filter: { meetingTemplateId: meetingTemplate.id },
              });
              $scope.saveWidgets();
            }
          });
        } else {
          if (_.includes(['growth', 'assignments', 'privatemeetings'], type)) {
            _.remove($scope.dashboardWidgets, { type: type, deleted: true });
          }
          $scope.dashboardWidgets.splice(index, 0, {
            type: type,
            name: $translate.instant(type ? 'label.dashboard.widgets.' + type : ''),
            filter: {},
            deleted: false,
          });
          $scope.saveWidgets();
        }
      };

      if (
        !_.find($scope.dashboardWidgets, { type: 'assignments' }) &&
        !_.find($scope.dashboardWidgets, {
          type: 'assignments',
          deleted: true,
        })
      ) {
        HttpService.get(
          '/api/academy/users/' +
            InputSanitizerService.sanitize(CurrentUser.getUser().id) +
            '/courses',
        ).then(function (assignedCourses) {
          if (!_.isEmpty(assignedCourses)) {
            $scope.addWidget('assignments', $scope.dashboardWidgets.length);
          }
        });
      }

      if (
        !_.find($scope.dashboardWidgets, { type: 'privatemeetings' }) &&
        !_.find($scope.dashboardWidgets, {
          type: 'privatemeetings',
          deleted: true,
        })
      ) {
        HttpService.get(
          '/api/meeting/meetings?userId=' +
            InputSanitizerService.sanitize(CurrentUser.getUser().id),
        ).then(function (privateMeetings) {
          if (!_.isEmpty(privateMeetings)) {
            $scope.addWidget('privatemeetings', $scope.dashboardWidgets.length);
          }
        });
      }

      if (
        !_.find($scope.dashboardWidgets, { type: 'growth' }) &&
        !_.find($scope.dashboardWidgets, {
          type: 'growth',
          deleted: true,
        })
      ) {
        $scope.addWidget('growth', 0);
      }
    },
  )

  .controller(
    'DashboardCesController',
    function (
      $scope,
      _,
      $rootScope,
      CesService,
      CurrentUser,
      HttpService,
      widget,
      InputSanitizerService,
    ) {
      $scope.filter = widget.filter || {};
      $scope.cesActivities = null;
      $scope.filter.$$openFilter = false;
      $scope.chart = null;
      $scope.widget = widget;

      const currentUser = CurrentUser.getUser();
      $scope.widget.$$userList = _($scope.users)
        .cloneDeep()
        .map(function (user) {
          user.selected = _.includes($scope.filter.user, user.id);
          return user;
        });

      $scope.refreshChart = function () {
        if (!widget.uuid) {
          return;
        }

        var queryParams = '?widgetId=' + widget.uuid;
        if ($scope.widget.$$clientSegmentList && $scope.widget.$$clientSegmentList.length) {
          const clientSegmentIds = $scope.widget.$$clientSegmentList
            .filter(function (segment) {
              return segment.selected;
            })
            .map(function (segment) {
              return segment.id;
            });

          queryParams += '&clientSegmentIds=' + clientSegmentIds.join(',');
        }
        if ($scope.widget.$$userList && $scope.widget.$$userList.length) {
          const accountManagerIds = $scope.widget.$$userList
            .filter(function (user) {
              return user.selected;
            })
            .map(function (user) {
              return user.id;
            });
          queryParams += '&accountManagerIds=' + accountManagerIds.join(',');
        }

        HttpService.get('/api/dashboard/widgets/ces' + InputSanitizerService.sanitize(queryParams))
          .then(function (response) {
            $scope.cesActivities = response;
          })
          .then(function () {
            $scope.chart = CesService.createChartData(
              $scope.cesActivities,
              $scope.widget.$$clientSegmentList,
            );
          });
      };

      $scope.getClientSegments = function () {
        return HttpService.get('/api/segments').then(function (result) {
          $scope.widget.$$clientSegmentList = result;
        });
      };
      $scope.getClientSegments().then(function () {
        $scope.widget.$$clientSegmentList.forEach(function (segment) {
          segment.selected = $scope.filter.segments
            ? $scope.filter.segments.includes(segment.id)
            : false;
        });
        $scope.refreshChart();
      });

      $scope.openCesWidgetFilter = function () {
        $scope.filter.$$openFilter = true;
      };

      $scope.closeCesWidgetFilter = function () {
        $scope.filter.$$openFilter = false;
      };

      $scope.resetFilter = function () {
        _.forEach($scope.widget.$$userList, function (user) {
          user.selected = false;
        });

        _.forEach($scope.widget.$$clientSegmentList, function (segment) {
          segment.selected = false;
        });

        $scope.refreshChart();
      };
    },
  )

  .controller(
    'DashboardSasController',
    function (
      $scope,
      $rootScope,
      $modal,
      $translate,
      widget,
      HttpService,
      MeetingService,
      InputSanitizerService,
    ) {
      $scope.filter = widget.filter || {};
      $scope.ranges = [];
      $scope.meetingTemplate = {};
      $scope.clients = [];
      $scope.clientsCollapsed = true;
      $scope.MeetingService = MeetingService;

      $scope.getMeetingTemplate = function () {
        HttpService.get(
          '/api/meeting/templates/' +
            InputSanitizerService.sanitize($scope.filter.meetingTemplateId),
        ).then(function (meetingTemplate) {
          $scope.meetingTemplate = meetingTemplate;
          $scope.ranges = _.get(
            _.find($scope.meetingTemplate.widgets, { type: 'header' }),
            'data.ranges',
          );
        });
      };
      $scope.getMeetingTemplate();

      $scope.getSasScore = function () {
        HttpService.get(
          '/api/meeting/templates/' +
            InputSanitizerService.sanitize($scope.filter.meetingTemplateId) +
            '/average',
        ).then(function (result) {
          $scope.avgScore = result.avgScore;
          $scope.avgTargetScore = result.avgTargetScore;
          $scope.meetingsCount = result.count;
          $scope.clients = result.clients;
        });
      };
      $scope.getSasScore();

      // $scope.openClientsModal = function () {
      //     const clientNames = $scope.clients.map(function (client) {
      //         return '<p>' + client + '</p>'
      //     })
      //     clientNames.join('')
      //
      //     const header = '<div class="modal-header" style="background-color: lightgray">' +
      //         '<h1 class="modal-title">' + $translate.instant('label.dashboard.clientsModal.header') + '</h1>' +
      //         '</div>'
      //     const body = '<div class="modal-body">' + clientNames.join('') + '</div>'
      //     const footer = '<div class="modal-footer">' +
      //         '<button class="btn btn-primary" ng-click="cancel()">{{"button.cancel" | translate}}</button>' +
      //         '</div>'
      //
      //     $modal.open({
      //         template: header + body + footer,
      //         controller: function ($scope, $modalInstance) {
      //             $scope.cancel = function () {
      //                 $modalInstance.dismiss()
      //             }
      //         },
      //         size: 'sm'
      //     })
      // }
    },
  )

  .controller(
    'DashboardPrivatemeetingsController',
    function (
      $modal,
      $moment,
      $scope,
      $rootScope,
      $translate,
      CurrentUser,
      HttpService,
      widget,
      InputSanitizerService,
    ) {
      $scope.currentUser = CurrentUser.getUser();

      HttpService.get(
        '/api/meeting/meetings?userId=' + InputSanitizerService.sanitize(CurrentUser.getUser().id),
      ).then(function (meetings) {
        $scope.hasMore = meetings.length > 5;
        $scope.meetings = _.take(meetings, 5);
      });

      $scope.meetingOrder = function (meeting) {
        return $moment(meeting.createdAt).diff($moment(), 'hours');
      };
    },
  )

  .controller(
    'DashboardCesupcomingController',
    function (
      $modal,
      $moment,
      $scope,
      $rootScope,
      $translate,
      CesService,
      CurrentUser,
      HttpService,
      widget,
      InputSanitizerService,
    ) {
      $scope.currentUser = CurrentUser.getUser();
      $scope.getActivityClass = CesService.getActivityClass;

      if (CurrentUser.can('setAccountManager')) {
        var apiUrlSuffix = '&accountManagerIds=' + CurrentUser.getUser().id;
      } else {
        var apiUrlSuffix = '';
      }

      HttpService.get(
        '/api/ces/activities?fromDay=' +
          $moment().format('YYYYMMDD') +
          InputSanitizerService.sanitize(apiUrlSuffix),
      ).then(function (activities) {
        $scope.hasMore = activities.length > 5;
        $scope.activities = _(activities).orderBy('day').take(5).value();
      });
    },
  )

  .controller(
    'DashboardCesmissedController',
    function (
      $modal,
      $moment,
      $scope,
      $rootScope,
      $translate,
      CesService,
      CurrentUser,
      HttpService,
      widget,
      InputSanitizerService,
    ) {
      $scope.currentUser = CurrentUser.getUser();
      $scope.getActivityClass = CesService.getActivityClass;

      if (CurrentUser.can('setAccountManager')) {
        var apiUrlSuffix = '&accountManagerIds=' + CurrentUser.getUser().id + '&type=plan';
      } else {
        var apiUrlSuffix = '&type=plan';
      }

      HttpService.get(
        '/api/ces/activities?toDay=' +
          $moment().format('YYYYMMDD') +
          InputSanitizerService.sanitize(apiUrlSuffix),
      ).then(function (activities) {
        $scope.hasMore = activities.length > 5;
        $scope.activities = _(activities).orderBy('day', 'desc').take(5).value();
      });
    },
  )

  .controller(
    'DashboardAssignmentsController',
    function (
      $modal,
      $moment,
      $scope,
      $rootScope,
      $translate,
      AcademyService,
      CurrentUser,
      HttpService,
      widget,
      InputSanitizerService,
    ) {
      // $scope.filter = widget.filter;
      // $scope.vendors = _.map(CurrentUser.getUser().Company.Vendors, function (vendor) {
      //     vendor.selected = _.includes(widget.filter.vendor, vendor.id);
      //     return _.cloneDeep(vendor)
      // });
      //
      // $scope.refresh = function () {
      // widget.filter.vendor = _($scope.vendors).filter({selected: true}).map('id').value();
      HttpService.get(
        '/api/academy/users/' + InputSanitizerService.sanitize(CurrentUser.getUser().id),
      ).then(function (user) {
        var courses = _(user.Courses).filter(function (course) {
          return course.progress < 100; //&& (_.isEmpty($scope.filter.vendors) || _.find($scope.filter.vendors, {id: course.VendorId}));
        });
        $scope.hasMore = courses.size() > 5;
        $scope.courses = courses
          .take(5)
          .orderBy(['orderId', 'name'])
          .map(function (course) {
            course.chart = AcademyService.chartData(course.progress, 'overall', '#62A6DB');
            return course;
          })
          .value();
        // HttpService.put('/api/dashboard/widgets', {widgetId: widget.uuid, vendor: _.map($scope.filter.vendors,'id')})
      });
      // };

      // $scope.$watch('filter.vendors', function () {
      //     // if ($scope.filter.vendors != _($scope.vendors).filter({selected: true}).map('id').value()) {
      //     $scope.refresh();
      //     // }
      // }, true)
      //
      // $scope.resetFilter = function () {
      //     _.remove($scope.filter.vendors, true);
      // }

      $scope.generateCoursePopover = AcademyService.generateCoursePopover;
    },
  )

  .controller(
    'DashboardGrowthController',
    function ($scope, $rootScope, $translate, _, HttpService, CompanyService, CurrentUser, widget) {
      const user = CurrentUser.getUser();
      $scope.currency = user.Company.currency || 'usd';
      $scope.filter = widget.filter || {};
      $scope.widget.$$userList = _($scope.users)
        .cloneDeep()
        .map(function (user) {
          user.selected = _.includes($scope.filter.user, user.id);
          return user;
        });

      $scope.getProjectStatuses = function () {
        CompanyService.getProjectStatuses(user.Company.id).then(function (projectStatuses) {
          $scope.statuses = _.filter(projectStatuses, function (project) {
            return project.code !== 'issue' && project.code !== 'none';
          });
        });
      };
      $scope.getProjectStatuses();

      $scope.refreshChart = function () {
        if (!widget.uuid) {
          return;
        }

        var queryParams = '?widgetId=' + widget.uuid;
        if ($scope.widget.$$clientSegmentList && $scope.widget.$$clientSegmentList.length) {
          const clientSegmentIds = $scope.widget.$$clientSegmentList
            .filter(function (segment) {
              return segment.selected;
            })
            .map(function (segment) {
              return segment.id;
            });

          queryParams += '&clientSegmentIds=' + clientSegmentIds.join(',');
        }
        if ($scope.widget.$$userList && $scope.widget.$$userList.length) {
          const accountManagerIds = $scope.widget.$$userList
            .filter(function (user) {
              return user.selected;
            })
            .map(function (user) {
              return user.id;
            });
          queryParams += '&accountManagerIds=' + accountManagerIds.join(',');
        }

        HttpService.get('/api/dashboard/widgets/growth' + queryParams).then(function (result) {
          $scope.scores = result;
        });
      };

      $scope.getClientSegments = function () {
        return HttpService.get('/api/segments').then(function (result) {
          $scope.widget.$$clientSegmentList = result;
        });
      };
      $scope.getClientSegments().then(function () {
        $scope.widget.$$clientSegmentList.forEach(function (segment) {
          segment.selected = $scope.filter.segments
            ? $scope.filter.segments.includes(segment.id)
            : false;
        });
        $scope.refreshChart();
      });

      $scope.openFilter = function () {
        $scope.filter.$$openFilter = true;
      };

      $scope.closeFilter = function () {
        $scope.filter.$$openFilter = false;
      };

      $scope.resetFilter = function () {
        _.forEach($scope.widget.$$userList, function (user) {
          user.selected = false;
        });

        _.forEach($scope.widget.$$clientSegmentList, function (segment) {
          segment.selected = false;
        });

        $scope.refreshChart();
      };
    },
  )

  .controller(
    'DashboardTouchpointsupcomingController',
    function ($scope, $rootScope, $moment, $translate, CurrentUser, HttpService, widget) {
      $scope.getclientNameColor = function (client) {
        return { color: client.touchpointType === 'Missed' ? 'red' : '#59BF90' };
      };

      $scope.sumOfClients = $scope.clients.reduce(
        function (acc, client) {
          acc[client.ClientSegment.frequency] += 1;
          return acc;
        },
        { Monthly: 0, Quarterly: 0, Annual: 0 },
      );

      $scope.upcomingClientsByFrequency = _($scope.clients)
        .filter(function (client) {
          return ['Upcoming', 'Missed'].includes(client.touchpointType);
        })
        .sortBy('ClientSegment.id')
        .groupBy('ClientSegment.frequency')
        .value();

      $scope.countClientsByFrequency = function (frequency) {
        return $scope.sumOfClients[frequency];
      };

      $scope.hasUpcoming = Object.values($scope.upcomingClientsByFrequency).length;
    },
  )

  .controller(
    'DashboardTouchpointsmissedController',
    function ($scope, $rootScope, $moment, $translate, CurrentUser, HttpService, widget) {
      $scope.sumOfClients = $scope.clients.reduce(
        function (acc, client) {
          acc[client.ClientSegment.frequency] += 1;
          return acc;
        },
        { Monthly: 0, Quarterly: 0, Annual: 0 },
      );

      $scope.missedClientsByFrequency = _($scope.clients)
        .filter(function (client) {
          return client.touchpointType === 'Missed';
        })
        .sortBy('ClientSegment.id')
        .groupBy('ClientSegment.frequency')
        .value();

      $scope.countClientsByFrequency = function (frequency) {
        return $scope.sumOfClients[frequency];
      };

      $scope.hasMissed = Object.values($scope.missedClientsByFrequency).length;
    },
  );
