angular.module('vcio-toolkit').factory('ProjectPriceService', function (_) {
  return {
    formatClientProjectPrices: function (clientProjectPrices) {
      if (!clientProjectPrices) return;

      const flattenedPrices = _.concat(
        clientProjectPrices.assets,
        clientProjectPrices.others,
      ).filter(function (a) {
        return a;
      });

      const aggregatedAssetPrices = flattenedPrices.reduce(function (acc, price) {
        if (_.isNil(price) || _.isNil(price.months)) {
          return acc;
        }

        _.forEach(price.months, function (value, month) {
          acc[month] = acc[month] || 0;
          acc[month] += value;
        });

        return acc;
      }, {});

      return aggregatedAssetPrices;
    },
  };
});
