angular
  .module('vcio-toolkit')
  .controller(
    'MarketingController',
    function ($scope, $stateParams, $state, $window, $location, _) {
      //$scope.viewTab = 'graders';
      //if ($stateParams && $stateParams.viewTab) {
      //    $scope.viewTab = $stateParams.viewTab;
      //}
      //
      //$scope.changeTab = function (tab) {
      //    $state.go('marketing.viewTab', {viewTab: tab}, {reload: true});
      //}
      //
      //$scope.menuItems = [
      //    {name: 'leads', label: 'label.leads', icon: 'users', template: '/templates/msp/contacts.html', controller: 'ContactsController', init: 'lead'},
      //    {name: 'contacts', label: 'label.contacts', icon: 'users', template: '/templates/msp/contacts.html', controller: 'ContactsController'},
      //    {name: 'graders', label: 'graders.title', icon: 'list-ol', template: '/templates/marketing/graders.html', controller: 'GradersController', init: 'grader'},
      //    {name: 'graderTemplates', label: 'graders.templateTitle', icon: 'file-o', template: '/templates/marketing/graders.html', controller: 'GradersController', init: 'template'},
      //    {name: 'emailSequences', label: 'emailSequences.title', icon: 'envelope-o', template: '/templates/msp/email-sequences.html', controller: 'EmailSequencesController'},
      //    {name: 'emailCampaigns', label: 'emailCampaigns.title', icon: 'envelope-square', template: '/templates/msp/email-campaigns.html', controller: 'EmailCampaignsController'},
      //    {name: 'leadmagnets', label: 'title.leadmagnets', icon: 'magnet', template: '/templates/msp/leadmagnets.html', controller: 'LeadMagnetsController'},
      //    {name: 'library', label: 'title.library', icon: 'book', template: '/templates/msp/library.html', controller: 'LibraryController', init:'eBooks'},
      //    {name: 'mylibrary', label: 'title.myLibrary', icon: 'folder-o', template: '/templates/msp/myfiles.html', controller: 'MyFilesController'}
      //]
      //
      //$scope.template = _.find($scope.menuItems, {name: $scope.viewTab}).template;
      //$scope.controller = _.find($scope.menuItems, {name: $scope.viewTab}).controller;
      //$scope.controllerInit = _.find($scope.menuItems, {name: $scope.viewTab}).init;
    },
  );
