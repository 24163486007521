angular
  .module('vcio-toolkit')

  .factory('DialogService', function ($modal, $translate, _) {
    var dialogController = function ($scope, $modalInstance, $sce, data) {
      $scope.type = data.type || 'success';
      $scope.ok = function () {
        $modalInstance.close(true);
      };
      if (data.confirm) {
        $scope.cancel = function () {
          $modalInstance.dismiss();
        };
      }

      if (_.isObject(data.message)) {
        if (data.message.code == 'error.invalidData') {
          data.title = data.message.code;
          var _message = '';
          _.forEach(data.message.fields, function (field, i) {
            var val = _.nth(data.message.values, i);
            _message += '<br>' + $translate.instant(field) + (val ? ': ' + val : '');
          });
          $scope._message = $sce.trustAsHtml(_message);
        } else {
          $scope._message = $sce.trustAsHtml(
            $translate.instant(data.message.code, data.message.translateValues),
          );
        }
      } else {
        $scope._message = $sce.trustAsHtml($translate.instant(data.message));
      }

      $scope._title = _.isObject(data.title)
        ? $translate.instant(data.title.code, data.title.translateValues)
        : $translate.instant(data.title);
    };

    var show = function (message, title, size, cb, cbCancel, type) {
      if (typeof title === 'function') {
        cb = title;
        title = undefined;
        if (typeof size === 'function') {
          cbCancel = size;
          size = undefined;
        }
      } else if (typeof size === 'function') {
        cbCancel = cb;
        cb = size;
        size = undefined;
      }
      var modalInstance = $modal.open({
        templateUrl: '/templates/dialog.html',
        backdrop: 'static',
        size: size,
        controller: dialogController,
        resolve: {
          data: function () {
            return {
              message: message,
              title: title,
              type: type,
              confirm: cbCancel ? true : false,
            };
          },
        },
      });

      modalInstance.result.then(
        function () {
          if (cb) {
            cb();
          }
        },
        function () {
          if (cbCancel) {
            cbCancel();
          }
        },
      );
    };

    return {
      /**
       * @deprecated use message instead
       */
      show: function (message, title, size, cb) {
        show(message, title, size, cb, null, 'success');
      },
      message: function (message, title, size, cb) {
        show(message, title, size, cb, cb, 'success');
      },
      error: function (message, title, size, cb) {
        show(message, title || 'error.title', size, cb, null, 'danger');
      },
      warning: function (message, title, size, cb) {
        show(message, title, size, cb, null, 'warning');
      },
      confirm: function (message, title, size, cb, cbCancel) {
        show(message, title, size, cb, function () {}, 'success');
      },
    };
  })

  .factory('ModalFormService', function ($modal, $rootScope, _) {
    return {
      open: function (options) {
        var modalOptions = {
          backdrop: options.backdrop || 'static',
          size: options.size,
          controller: options.controller,
        };

        var t =
          '<modal-form form-title="' +
          (options.title || '') +
          '" title-values="' +
          (options.titleValues || '') +
          '" ' +
          'onok="' +
          (options.ok || 'ok') +
          '" oncancel="' +
          (options.cancel || 'cancel') +
          '">\n';
        if (options.template) {
          modalOptions.template = t + options.template + '\n</modal-form>';
        } else if (options.templateUrl) {
          modalOptions.template =
            t + '<ng-include src="' + options.templateUrl + '"></ng-include>' + '\n</modal-form>';
        }

        if (options.scope) {
          modalOptions.scope = _.extend($rootScope.$new(), options.scope);
        }

        if (options.resolve) {
          modalOptions.resolve = options.resolve;
        }

        modalOptions.scope.modalInstance = $modal.open(modalOptions);
        modalOptions.scope.modalInstance.result.then(options.close, options.dismiss);
      },
    };
  });
