angular
  .module('vcio-toolkit')

  .service('SurveyService', function (_, CurrentUser) {
    return {
      copyTemplate: function (survey, isTemplate) {
        if (survey.template && !isTemplate && !survey.TemplateId) {
          survey.Template = _.cloneDeep(survey);
          survey.TemplateId = survey.id;
        } else if (isTemplate) {
          delete survey.TemplateId;
        }
        delete survey.id;
        survey.Owner = CurrentUser.getUser().Company;
        survey.public = false;
        survey.template = isTemplate;
      },
    };
  })

  .controller(
    'SurveyExportModalController',
    function (
      $modalInstance,
      $rootScope,
      $translate,
      $scope,
      $window,
      _,
      CurrentUser,
      selected,
      surveyResults,
      group,
      filterValue,
      type,
      InputSanitizerService,
    ) {
      $scope.selected = {
        surveyResults: selected,
        scoreType: 'current',
      };
      var values = _(surveyResults)
        .map(function (surveyResult) {
          return {
            id: surveyResult.id,
            clientName: surveyResult.Client.name,
            name:
              surveyResult.title +
              (surveyResult.Contact
                ? ' (' + surveyResult.Contact.firstName + ' ' + surveyResult.Contact.lastName + ')'
                : ''),
          };
        })
        .sortBy('name')
        .value();

      $scope.group = group;
      $scope.type = type;
      $scope.values = $scope.group ? _.groupBy(values, $scope.group) : values;
      $scope.filter = { name: filterValue };
      // $scope.scoreType = 'current';
      $scope.scoreTypes = [
        { id: 'current', name: $translate.instant('reports.currentScore') },
        {
          id: 'target',
          name: $translate.instant('reports.targetScore'),
        },
      ];
      $scope.okEnabled = function () {
        return $scope.selected.scoreType && !_.isEmpty($scope.selected.surveyResults);
      };

      $scope.ok =
        $scope.funcOk ||
        function () {
          $window.open(
            'https://' +
              InputSanitizerService.sanitize($window.location.hostname) +
              ($window.location.port
                ? ':' + InputSanitizerService.sanitize($window.location.port)
                : '') +
              '/api/sales/reports/exports/compare.csv?scoreType=' +
              InputSanitizerService.sanitize($scope.selected.scoreType) +
              '&surveyResultIds=' +
              InputSanitizerService.sanitize(
                _.join(_.map($scope.selected.surveyResults, 'id'), ','),
              ) +
              '&token=' +
              CurrentUser.getToken(),
          );
          $modalInstance.close();
        };

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };

      $scope.groupFilter = function (item, values) {
        return (
          !$scope.filter.name ||
          _.includes(_.toLower(item), _.toLower($scope.filter.name)) ||
          _.filter(values, function (o) {
            return _.includes(_.toLower(o.name), _.toLower($scope.filter.name));
          }).length > 0
        );
      };
      $scope.itemFilter = function (item) {
        return (
          !$scope.filter.name ||
          _.includes(_.toLower(item.name), _.toLower($scope.filter.name)) ||
          _.includes(_.toLower(item.clientName), _.toLower($scope.filter.name))
        );
      };

      $scope.isSelected = function (item) {
        return !!_.find($scope.selected.surveyResults, { id: item.id });
      };

      $scope.select = function (item) {
        if (!$scope.isSelected(item)) {
          $scope.selected.surveyResults.push(item);
        } else {
          _.remove($scope.selected.surveyResults, item);
        }
      };
    },
  )

  .controller(
    'SurveysController',
    function (
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $translate,
      $q,
      $modal,
      $window,
      _,
      CurrentUser,
      HttpService,
      PermissionGroupModel,
      surveys,
      InputSanitizerService,
    ) {
      $scope.currentUser = CurrentUser.getUser();
      $scope.filter = { name: '', all: false, permissionGroups: [] };
      if ($stateParams.permissionGroupId) {
        $scope.filter.permissionGroups = _.map(
          $stateParams.permissionGroupId.split(','),
          function (val) {
            return parseInt(val);
          },
        );
      }
      $scope.surveys = _(surveys)
        .sortBy('name')
        .map(function (survey) {
          survey.dimensionCount = _(survey.dimensions)
            .filter(function (dimension) {
              return !dimension.hidden;
            })
            .size();
          survey.blockCount = _(survey.blocks)
            .filter(function (block) {
              return !block.hidden;
            })
            .size();
          return survey;
        })
        .value();
      $scope.isTemplates = $state.$current.name === 'sales.surveytemplates';
      $scope.type = $stateParams.type;
      $scope.surveyType = $translate.instant('surveyType.' + $stateParams.type);
      $scope.newSurvey = {};
      $scope.myPermissionGroups = [];
      PermissionGroupModel.querySubscribed().$promise.then(function (result) {
        var listPermissionGroups = _(surveys)
          .flatMap(function (item) {
            return _.get(item, 'Template.PermissionGroups') || _.get(item, 'PermissionGroups', []);
          })
          .map('id')
          .uniq()
          .value();
        $scope.myPermissionGroups = _.filter(result, function (item) {
          return _.includes(listPermissionGroups, item.id);
        });
      });

      $scope.clickPermissionGroupFilter = function (permissionGroupId) {
        $state.transitionTo(
          'sales.surveysByPermissionGroup',
          { permissionGroupId: permissionGroupId.join(), type: $stateParams.type },
          { notify: false },
        );
      };

      $scope.save = function (newSurvey) {
        if (newSurvey.template) {
          $state.go('sales.surveycopy', { surveyId: newSurvey.template, type: $scope.surveyType });
        }
      };

      $scope.cancel = function () {
        $scope.newSurvey.expanded = false;
      };

      $scope.canEdit = function (item) {
        return item.OwnerId == CurrentUser.getUser().Company.id;
      };

      $scope.canDelete = function (item) {
        return item.OwnerId == CurrentUser.getUser().Company.id;
      };

      $scope.editSurvey = function (survey) {
        if ($scope.canEdit(survey)) {
          if (survey.template) {
            $state.go('sales.surveytemplate', { surveyId: survey.id, type: survey.type });
          } else {
            $state.go('sales.survey', { surveyId: survey.id, type: survey.type });
          }
        }
      };

      $scope.addCatSurvey = function (survey) {
        $state.go('sales.surveycopy', { surveyId: survey.id, type: $scope.type });
      };
      $scope.copySurvey = function (survey) {
        if ($scope.isTemplates) {
          $state.go('sales.surveytemplatecopy', { surveyId: survey.id });
        } else {
          $state.go('sales.surveycopy', { surveyId: survey.id, type: $scope.type });
        }
      };

      $scope.createSurvey = function (survey) {
        if ($scope.isTemplates) {
          $state.go('sales.surveytemplate', { surveyId: 'new', type: $scope.type });
        } else {
          $rootScope.$broadcast('dataLoadingStarted');
          HttpService.get(
            '/api/surveytemplates?type=' + InputSanitizerService.sanitize($scope.type),
          ).then(
            function (surveyTemplates) {
              $scope.surveyTemplates = surveyTemplates;
              $scope.newSurvey = { expanded: true };
              $rootScope.$broadcast('dataLoadingFinished');
            },
            function (error) {
              $rootScope.$broadcast('dataLoadingFinished');
              DialogService.error(error);
            },
          );
        }
      };

      $scope.deleteSurvey = function (survey) {
        HttpService.delete('/api/surveys/' + survey.id).then(function () {
          _.remove($scope.surveys, { id: survey.id });
        });
      };

      $scope.nameFilter = function (item) {
        return (
          $scope.filter.name === '' ||
          item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1 ||
          (item.Owner &&
            item.Owner.name &&
            item.Owner.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1)
        );
      };
      $scope.typeFilter = function (item) {
        return (
          !$scope.permissionGroupFilter ||
          (item.Template &&
            _.find(item.Template.PermissionGroups, { id: parseInt($scope.permissionGroupFilter) }))
        );
      };
      $scope.permissionGroupFilter = function (item) {
        return (
          _.isEmpty($scope.filter.permissionGroups) ||
          (item.Template &&
            _.find(item.Template.PermissionGroups, function (permissionGroup) {
              return _.includes($scope.filter.permissionGroups, permissionGroup.id);
            })) ||
          _.find(item.PermissionGroups, function (permissionGroup) {
            return _.includes($scope.filter.permissionGroups, permissionGroup.id);
          })
        );
      };

      $scope.downloadCsvExport = function (item) {
        var modalInstance = $modal.open({
          templateUrl: '/templates/sales/survey-export-modal.html',
          controller: 'SurveyExportModalController',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            selected: function () {
              return [];
            },
            surveyResults: function () {
              return HttpService.get('/api/surveyresults?surveyId=' + item.id);
            },
            group: function () {
              return 'clientName';
            },
            filterValue: function () {
              return '';
            },
            type: function () {
              return $scope.type;
            },
          },
        });
      };

      // $scope.surveyOperations = [{
      //     operation: $scope.editSurvey,
      //     label: 'button.edit',
      //     icon: 'edit',
      //     condition: $scope.canEdit
      // }, {
      //     operation: $scope.deleteSurvey,
      //     confirmation: true,
      //     label: 'button.delete',
      //     icon: 'times',
      //     condition: $scope.canDelete
      // }, {
      //     operation: $scope.copySurvey,
      //     label: (($scope.isTemplates) ? 'button.surveys.addToCatalogue' : 'button.copy'),
      //     icon: 'copy'
      // }]
    },
  )

  .controller(
    'SurveyAddController',
    function (
      $scope,
      $modalInstance,
      $http,
      _,
      CurrentUser,
      SurveyService,
      DialogService,
      surveys,
    ) {
      $scope.surveys = _.sortBy(surveys.data, 'name');
      $scope.selected = {};
      $scope.cancel = function () {
        $modalInstance.dismiss();
      };

      $scope.ok = function (cb) {
        SurveyService.copyTemplate($scope.selected.survey, false);
        $http
          .post('/api/surveys', { survey: $scope.selected.survey })
          .success(function (result) {
            DialogService.message('label.survey.addToCatalogue.success', function () {
              $modalInstance.close(result);
            });
            cb();
          })
          .error(function (error) {
            $scope.errors = [error];
            cb();
          });
      };
    },
  );

// .controller('SurveyEditBlockController', function ($scope, _, block) {
//     $scope.block = block;
//     $scope.statementTypes = ['text', 'number', 'currency', 'percentage', 'textWithNumber'];
//
//     $scope.changeNoneOfTheAbove = function (block, statement) {
//         block.statements.forEach(function (_statement) {
//             if (_statement.id == statement.id) {
//                 _statement.noneOfTheAbove = !_statement.noneOfTheAbove;
//                 // if (_statement.noneOfTheAbove) {
//                 //     _statement.score = 0;
//                 // }
//             } else if (_statement.noneOfTheAbove) {
//                 _statement.noneOfTheAbove = false;
//             }
//         })
//     };
//
//     $scope.hideStatement = function (block, statement) {
//         statement.hidden = true;
//     };
//     $scope.unhideStatement = function (block, statement) {
//         statement.hidden = false;
//     };
//
//     $scope.calcSums = function () {
//         if (!$scope.block.type || $scope.block.type === 'score') {
//             $scope.block.$$sum = _.sumBy($scope.block.statements, 'score');
//         } else {
//             delete $scope.block.$$sum;
//         }
//     };
//
//     // $scope.$watch('block.statement.score', function () {
//     //     $scope.calcSums();
//     // })
// })

// .controller('SurveyEditController', function ($scope, $rootScope, $state, $stateParams, $http, $q, $modal, $translate, $timeout, _, CurrentUser, DialogService, SurveyService, UploadService, uuid, survey, services, serviceTemplates, copy, template) {
//     $scope.survey = survey || {
//         template: template,
//         blocks: [],
//         LandingPageId: 0
//     };
//     $scope.survey.dimensions = $scope.survey.dimensions || [];
//
//     $scope.copy = copy;
//     $scope.isTemplate = template;
//     $scope.survey.multiRole = $scope.survey.roles && $scope.survey.roles.length > 0;
//     $scope.wasTemplate = survey.template;
//     $scope.expanded = {dimensions: true};
//     $scope.dimensionTypes = ['execution', 'management'];
//     $scope.answerTypes = ['single', 'multiple'];
//     $scope.types = ['score', 'custom', 'scorecard'];
//     $scope.widgetTypes = ['raw', 'quarterlytable'];
//     $scope.item = {};
//     //$scope.quarters = [{"id": "q1", name: 'Q1'}, {"id": "q2", name: 'Q2'}, {"id": "q3", name: 'Q3'}, {"id": "q4", name: 'Q4'}];
//     // if (!$scope.isTemplate) {
//     $http.get('/api/sales/pages').success(function (pages) {
//         $scope.landingPages = pages;
//         $scope.survey.LandingPageId = $scope.survey.LandingPageId || 0;
//     });
//     if (survey.type === 'stack2') {
//         //console.log(survey);
//     }
//     // }
//     var servicesObj = {
//         onetimeServiceTemplates: [],
//         ongoingServiceTemplates: [],
//         onetimeServices: [],
//         ongoingServices: []
//     };
//     servicesObj.onetimeServiceTemplates = _.filter(serviceTemplates, function (s) {
//         return /*s.published && */s.type !== 'ongoing'
//     });
//     servicesObj.ongoingServiceTemplates = _.filter(serviceTemplates, function (s) {
//         return /*s.published && */s.type !== 'onetime'
//     });
//     servicesObj.onetimeServices = $scope.isTemplate ? servicesObj.onetimeServiceTemplates : _.filter(services, function (s) {
//         return s.type !== 'ongoing'
//     });
//     servicesObj.ongoingServices = $scope.isTemplate ? servicesObj.ongoingServiceTemplates : _.filter(services, function (s) {
//         return s.type !== 'onetime'
//     });
//
//     $scope.onetimeServices = _.orderBy(servicesObj.onetimeServices, 'name');
//     $scope.ongoingServices = _.orderBy(servicesObj.ongoingServices, 'name');
//
//     $scope.close = function () {
//         if ($scope.wasTemplate && !copy) {
//             $state.go('sales.surveytemplates', {type: $scope.survey.type});
//         } else {
//             $state.go('sales.surveys', {type: $scope.survey.type});
//         }
//     };
//
//     if (survey.id) {
//         if (survey.PreviewImageId) {
//             $scope.previewUrl = '/images/surveypreviews/' + survey.id + '?' + new Date().getTime();
//         }
//     }
//     $scope.openPreview = function (survey) {
//         UploadService.open('surveypreviews', survey).then(function () {
//             $scope.previewUrl = '/images/surveypreviews/' + survey.id + '?' + new Date().getTime();
//         });
//     };
//
//     if (copy) {
//         SurveyService.copyTemplate($scope.survey, $scope.isTemplate);
//     }
//
//     if ($scope.survey.multiRole) {
//         _.forEach($scope.survey.roles, function (role) {
//             if (!role.uuid) {
//                 role.uuid = uuid.v4();
//             }
//         });
//     }
//
//     if (!$scope.survey.ranges) {
//         $scope.survey.ranges = [
//             {name: 'Chaotic', color: '#ea413d'},
//             {name: 'Ad-Hoc', color: '#ed742f'},
//             {name: 'Standard', color: '#f5be41'},
//             {name: 'Optimized', color: '#a8be4b'},
//             {name: 'Agile', color: '#59bf90'}
//         ];
//     }
//
//     $scope.survey.dimensions = _.orderBy($scope.survey.dimensions, 'orderId');
//     $scope.survey.dimensions.forEach(function (dimension) {
//         dimension.expandedBlocks = true;
//         dimension.$$expanded = true;
//
//         dimension.blocks = _($scope.survey.blocks).filter({'dimension': dimension.code}).orderBy('orderIdAnalyze').map(function (block, index) {
//             block.orderId = index + 1;
//             block.statements = _.orderBy(block.statements, 'orderId');
//             block.$$expanded = true;
//             block.statements.forEach(function (statement) {
//                 getService('onetimeService', statement);
//                 getService('ongoingService', statement);
//                 statement.type = statement.type || 'number';
//             });
//             return block;
//         }).value();
//         if (dimension.imageId) {
//             dimension.imageUrl = '/images/surveydimensions/' + dimension.imageId + '?' + new Date().getTime();
//         }
//     });
//
//     function getService(type, statement) {
//         if (statement[type]) {
//             statement[type] = _.find(_.concat(servicesObj[type + 's'], servicesObj[type + 'Templates']), {id: (_.isObject(statement[type]) ? statement[type].id : statement[type])});
//             if (statement[type] && statement[type].template) {
//                 var service = _.find(servicesObj[type + 's'], {TemplateId: statement[type].id});
//                 if (service) {
//                     statement[type] = service;
//                 }
//             }
//         }
//     }
//
//     // $scope.survey.blocks = _.orderBy($scope.survey.blocks, 'orderIdAnalyze');
//     // $scope.survey.blocks.forEach(function (block) {
//     //     block.$$expanded = true;
//     //     block.statements = _.orderBy(_.reject(block.statements, {hidden: true}), 'orderId');
//     //     if ($scope.survey.type == 'stack2') {
//     //         block.type = 'score'; //TODO can be removed after 2018 febr.
//     //     }
//     //     if (!block.id) {
//     //         block.id = uuid.v4(); //TODO can be removed after 2018 febr.
//     //     }
//     //
//     //     block.statements.forEach(function (statement) {
//     //         getService('onetimeService', statement);
//     //         getService('ongoingService', statement);
//     //         statement.type = statement.type || 'number';
//     //     })
//     // });
//     var oldSurvey = _.cloneDeep($scope.survey);
//
//     var storeDimensionExpanded = {};
//     var restoreDimensionExpanded = function () {
//         $scope.survey.dimensions.forEach(function (dimension) {
//             dimension.expanded = storeDimensionExpanded[dimension.code];
//         });
//     };
//     var blockDragged = false;
//     $scope.blockClick = function (block) {
//         if (blockDragged) {
//             blockDragged = false;
//         } else {
//             block.expanded = !block.expanded;
//         }
//     };
//     $scope.onDropBlockSuccess = function (data, target) {
//         if (data.dimension === target.dimension) {
//             var newBlockOrderId = target.orderIdAnalyze;
//             target.orderIdAnalyze = data.orderIdAnalyze;
//             data.orderIdAnalyze = newBlockOrderId;
//         }
//     };
//     $scope.onDropDimensionSuccess = function (key, block) {
//         if (block) {
//             var newDimension = $scope.survey.dimensions[key];
//             block.dimension = newDimension.code;
//         }
//     };
//     $scope.onDragStart = function () {
//         blockDragged = true;
//         $scope.survey.dimensions.forEach(function (dimension) {
//             storeDimensionExpanded[dimension.code] = dimension.expanded;
//             //dimension.expanded = true;
//             dimension.expandedBlocks = true;
//         });
//         // $scope.survey.blocks.forEach(function (block) {
//         //     block.expanded = false;
//         // });
//     };
//     $scope.onDragStop = function () {
//         restoreDimensionExpanded();
//     };
//     var hoverTimeout;
//     var lastHoverDimensionKey = null;
//     $scope.onDragHover = function (dimension) {
//         if (lastHoverDimensionKey !== dimension.code) {
//             $timeout.cancel(hoverTimeout);
//             hoverTimeout = $timeout(function () {
//                 dimension.expanded = true;
//             }, 500);
//         }
//         lastHoverDimensionKey = dimension.code;
//     };
//
//     $scope.dropCallback = function (srcList, srcIndex, targetList, targetIndex) {
//         // Copy the item from source to target.
//         targetList.splice(targetIndex, 0, srcList[srcIndex]);
//         // Remove the item from the source, possibly correcting the index first.
//         // We must do this immediately, otherwise ng-repeat complains about duplicates.
//         if (srcList == targetList && targetIndex <= srcIndex) {
//             srcIndex++;
//         }
//         srcList.splice(srcIndex, 1);
//         // By returning true from dnd-drop we signalize we already inserted the item.
//         return true;
//     };
//
//     $scope.getDimension = function (dimensionCode) {
//         return _.find($scope.survey.dimensions, {code: dimensionCode});
//     };
//
//     $scope.getTemplateDimension = function (dimension) {
//         var templateDimension = dimension;
//         if ($scope.survey.Template) {
//             templateDimension = _.find($scope.survey.Template.dimensions, {code: dimension.code}) || dimension;
//         }
//         return templateDimension;
//     };
//
//     $scope.getTemplateRole = function (role) {
//         var templateRole = role;
//         if ($scope.survey.Template) {
//             templateRole = _.find($scope.survey.Template.roles, {code: role.code}) || role;
//         }
//         return templateRole;
//     };
//
//     $scope.getTemplateBlock = function (block) {
//         var templateBlock = block;
//         if ($scope.survey.Template && $scope.survey.Template.blocks) {
//             templateBlock = _.find($scope.survey.Template.blocks, {id: block.id});
//             // if (!templateBlock) {
//             //     templateBlock = _.cloneDeep(block);
//             //     $scope.survey.blocks.push(templateBlock);
//             // } olyan nem lehetne, hogy a template-ben nincs, itt meg van...
//         }
//         return templateBlock;
//     };
//
//     $scope.getTemplateStatement = function (block, statement) {
//         var templateStatement = statement;
//         var _block = $scope.getTemplateBlock(block);
//         if ($scope.survey.Template && _block) {
//             var _templateStatement = _.find($scope.getTemplateBlock(block).statements, {id: statement.id});
//             if (_templateStatement) {
//                 templateStatement = _templateStatement;
//             }
//         }
//         return templateStatement;
//     };
//
//     $scope.save = function (cb) {
//         // _.forEach($scope.survey.dimensions, function (dimension, index) {
//         //     dimension.orderId = index + 1;
//         //     _.forEach(dimension.blocks, function (block, index) {
//         //         block.dimension = dimension.code;
//         //         block.orderIdAnalyze = index + 1;
//         //     });
//         // });
//         $scope.survey.blocks = _($scope.survey.dimensions).flatMap('blocks').orderBy('orderIdAnalyze').value();
//         $scope.survey.blocks.forEach(function (block) {
//             _.forEach(block.statements, function (statement, index) {
//                 statement.orderId = index + 1;
//                 if (_.isObject(statement.ongoingService)) {
//                     statement.ongoingService = statement.ongoingService.id;
//                 }
//                 if (_.isObject(statement.onetimeService)) {
//                     statement.onetimeService = statement.onetimeService.id;
//                 }
//             });
//         });
//         if ($scope.survey.id) {
//             $http.put('/api/surveys/' + $scope.survey.id, {survey: $scope.survey})
//                 .success(function (result) {
//                     // _.extend($scope.survey, result);
//                     oldSurvey = _.cloneDeep($scope.survey);
//                     $scope.messages = ['message.saved'];
//                     cb();
//                 })
//                 .error(function (error) {
//                     $scope.errors = [error];
//                     cb();
//                 })
//         } else {
//             $http.post('/api/surveys', {survey: $scope.survey})
//                 .success(function (result) {
//                     if ($scope.copy && result) {
//                         oldSurvey = _.cloneDeep($scope.survey);
//                         if ($scope.survey.template) {
//                             $state.go('sales.surveytemplate', {surveyId: result.id, type: $scope.survey.type});
//                         } else {
//                             $state.go('sales.survey', {surveyId: result.id, type: $scope.survey.type});
//                         }
//                     } else {
//                         // _.extend($scope.survey, result);
//                         oldSurvey = _.cloneDeep($scope.survey);
//                         $scope.messages = ['message.saved'];
//                         cb();
//                     }
//                 })
//                 .error(function (error) {
//                     $scope.errors = [error];
//                     cb();
//                 })
//         }
//     };
//
//     $scope.deleteBlock = function (dimension, block) {
//         if (dimension.blocks[block.code]) {
//             delete dimension.blocks[block.code];
//         }
//         // _.remove($scope.survey.blocks, {id: block.id});
//         _.remove(dimension.blocks, {id: block.id});
//     };
//
//     $scope.changeBlockAnswerType = function (block) {
//         if (block.answerType === 'single') {
//             block.answerType = 'multiple';
//         } else {
//             block.answerType = 'single';
//         }
//     };
//
//     $scope.addBlock = function (dimension, newBlock) {
//         if ($scope.survey.type !== 'stack2') {
//             newBlock = {name: 'New Subcategory'};
//         }
//         if (newBlock && newBlock.name) {
//             var blocks = _($scope.survey.dimensions).flatMap('blocks').orderBy('orderIdAnalyze').value();
//             var newBlockObj = {
//                 name: newBlock.name,
//                 id: uuid.v4(),
//                 type: 'score',
//                 answerType: 'multiple',
//                 orderIdAnalyze: (_.max(_.map(blocks, "orderIdAnalyze")) || 0) + 1,
//                 orderIdQuestion: (_.max(_.map(blocks, "orderIdQuestion")) || 0) + 1,
//                 dimension: dimension.code,
//                 statements: [],
//                 $$expanded: true
//             };
//             // $scope.survey.blocks.push(newBlockObj);
//             dimension.blocks.push(newBlockObj);
//             newBlock.name = '';
//         }
//     };
//
//     $scope.addStatement = function (block, newStatement) {
//         if ((newStatement && newStatement.name) || $scope.survey.type !== 'stack2') {
//             var statements = _.orderBy(block.statements, 'orderId');
//             var max = _.maxBy(block.statements, 'id');
//             block.statements.push({
//                 name: (newStatement && newStatement.name) ? newStatement.name : '',
//                 id: (max ? (max.id + 1) : 1),
//                 orderId: statements.length > 0 ? statements[statements.length - 1].orderId + 1 : 1,
//                 type: 'number'
//             });
//             if (newStatement) {
//                 newStatement.name = '';
//             }
//         }
//     };
//     $scope.deleteStatement = function (block, statement) {
//         if ($scope.isTemplate || $scope.survey.type === 'stack2') {
//             _.remove(block.statements, {id: statement.id});
//         }
//     };
//
//     $scope.addRole = function () {
//         // var modalInstance = $modal.open({
//         //     template: '<modal-form onok="ok" oncancel="cancel" form-title="label.survey.addRole">' +
//         //     '<input-text model="role.code" label="label.survey.role.code" required="true" type="code"></input-text>' +
//         //     '</modal-form>',
//         //     controller: function ($scope, $modalInstance) {
//         //         $scope.role = {};
//         //
//         //         $scope.ok = function (cb) {
//         //             $modalInstance.close($scope.role)
//         //         }
//         //         $scope.cancel = function () {
//         //             $modalInstance.dismiss();
//         //         }
//         //     },
//         //     backdrop: 'static'
//         // });
//         //
//         // modalInstance.result.then(function (role) {
//         if (!$scope.survey.roles) {
//             $scope.survey.roles = [];
//         }
//         var uuidCode = uuid.v4();
//         $scope.survey.roles.push({code: uuidCode, uuid: uuidCode});
//         $scope.survey.multiRole = $scope.survey.roles.length > 0;
//         // }, function () {
//         //
//         // });
//     };
//
//     $scope.canDeleteRole = function (role) {
//         return !_($scope.survey.dimensions).flatMap('blocks').find({role: role.code});
//     };
//
//     $scope.deleteRole = function (role) {
//         _.remove($scope.survey.roles, {code: role.code});
//     };
//
//     $scope.addDimension = function (newDimension) {
//         if ($scope.survey.type === 'stack2') {
//             $scope.survey.dimensions.push({
//                 code: uuid.v4(),
//                 name: (newDimension && newDimension.name ? newDimension.name : ''),
//                 blocks: []
//             });
//             newDimension.name = '';
//
//         } else {
//             $scope.survey.dimensions.push({code: uuid.v4(), name: 'New Category', blocks: []});
//         }
//     };
//
//     $scope.canDeleteDimension = function (dimension) {
//         return $scope.survey.type === 'stack2' || !_($scope.survey.dimensions).flatMap('blocks').find({dimension: dimension.code});
//     };
//
//     $scope.deleteDimension = function (dimension) {
//         if ($scope.canDeleteDimension(dimension)) {
//             _.remove($scope.survey.dimensions, {code: dimension.code});
//         }
//     };
//
//     $scope.hideDimension = function (dimension) {
//         dimension.hidden = true;
//     };
//     $scope.unhideDimension = function (dimension) {
//         dimension.hidden = false;
//     };
//
//     $scope.getBlockTemplate = function (block) {
//         return '/templates/sales/survey/block-' + block.type + '-edit.html';
//     };
//
//     $scope.getStatement = function (statements, id) {
//         return _.find(statements, {id: id});
//     };
//
//     $scope.deleteOnetimeService = function (statement) {
//         delete statement.onetimeService;
//     };
//
//     $scope.deleteOngoingService = function (statement) {
//         delete statement.ongoingService;
//     };
//
//     function addServices(selectedTemplates) {
//         $rootScope.$broadcast('dataLoadingStarted');
//         $scope.save(function () {
//             $http.post('/api/service/services/templates', {templateIds: selectedTemplates})
//                 .success(function (services) {
//                     $rootScope.$broadcast('dataLoadingFinished');
//
//                     servicesObj.onetimeServices = servicesObj.onetimeServices.concat(_.filter(services, function (s) {
//                         return s.published && s.type !== 'ongoing'
//                     }));
//                     servicesObj.ongoingServices = servicesObj.ongoingServices.concat(_.filter(services, function (s) {
//                         return s.published && s.type !== 'onetime'
//                     }));
//
//                     $scope.onetimeServices = _.orderBy(servicesObj.onetimeServices, 'name');
//                     $scope.ongoingServices = _.orderBy(servicesObj.ongoingServices, 'name');
//
//                     _($scope.survey.dimensions).flatMap('blocks').value().forEach(function (block) {
//                         block.statements.forEach(function (statement) {
//                             getService('onetimeService', statement);
//                             getService('ongoingService', statement);
//                         })
//                     });
//
//                     DialogService.message($translate.instant('message.survey.serviceAdded'));
//                 })
//                 .error(function (error) {
//                     $rootScope.$broadcast('dataLoadingFinished');
//                     DialogService.error(error);
//                 });
//         })
//     }
//
//     $scope.addServiceToCatalogue = function (service) {
//         addServices([service.id]);
//     };
//
//     $scope.addAllServicesToCatalogue = function () {
//         var ids = [];
//         _($scope.survey.dimensions).flatMap('blocks').value().forEach(function (block) {
//             block.statements.forEach(function (statement) {
//                 if (statement.onetimeService && statement.onetimeService.template && ids.indexOf(statement.onetimeService.id) === -1) {
//                     ids.push(statement.onetimeService.id);
//                 }
//                 if (statement.ongoingService && statement.ongoingService.template && ids.indexOf(statement.ongoingService.id) === -1) {
//                     ids.push(statement.ongoingService.id);
//                 }
//             });
//         });
//         if (!ids.length) {
//             DialogService.message($translate.instant('message.survey.noServiceAdded'));
//         } else {
//             addServices(ids);
//         }
//     };
//
//     $scope.changeBlockType = function (block) {
//         if (block.type === 'score') {
//             block.higherBetter = true;
//         }
//     };
//
//     $scope.openDimensionLogo = function (dimension) {
//         var data = {
//             dimension: dimension,
//             id: (dimension.imageId) ? dimension.imageId : 0,
//             maxWidth: 2048,
//             maxHeight: 2048,
//             maxSize: 1024
//         };
//         UploadService.openImage('surveydimensions', data).then(function (res) {
//             //console.log(res);
//             dimension.imageId = res.id;
//             dimension.imageUrl = '/images/surveydimensions/' + res.id + '?' + new Date().getTime();
//             $scope.save(function () {
//
//             });
//         });
//     };
//
//     $scope.dimensionExpandToggle = function (dimension) {
//         dimension.$$expanded = !dimension.$$expanded;
//     };
//     $scope.blockExpandToggle = function (block) {
//         block.$$expanded = !block.$$expanded;
//     };
//     $scope.blockHiddenToggle = function (block) {
//         block.hidden = !block.hidden;
//     };
//
//     $scope.checkFormIsDirty = function () {
//         return !angular.equals(oldSurvey, $scope.survey);
//     };
//
//     $scope.moveDimensionUp = function (dimension) {
//         if (dimension.orderId > 1) {
//             var prevDimension = _.find($scope.survey.dimensions, {orderId: dimension.orderId - 1});
//             dimension.orderId--;
//             prevDimension.orderId++;
//         }
//     };
//
//     $scope.moveDimensionDown = function (dimension) {
//         if (dimension.orderId < $scope.survey.dimensions.length) {
//             var nextDimension = _.find($scope.survey.dimensions, {orderId: dimension.orderId + 1});
//             dimension.orderId++;
//             nextDimension.orderId--;
//         }
//     };
//
//     $scope.moveBlockUp = function (block, dimension) {
//         if (block.orderId > 1) {
//             var prevBlock = _.find(dimension.blocks, {orderId: block.orderId - 1});
//             block.orderId--;
//             prevBlock.orderId++;
//         }
//     };
//
//     $scope.moveBlockDown = function (block, dimension) {
//         if (block.orderId < dimension.blocks.length) {
//             var nextBlock = _.find(dimension.blocks, {orderId: block.orderId + 1});
//             block.orderId++;
//             nextBlock.orderId--;
//         }
//     }
// });

// .controller('SurveyDictionaryEditBlockController', function ($scope, _, block) {
//     $scope.block = block;
// })
//
// .controller('SurveyDictionaryEditController', function ($scope, $state, $stateParams, $http, $q, _, survey, defaultDictionary, surveyDictionary, dictionaries, copy) {
//     $scope.survey = survey;
//     $scope.defaultDictionary = defaultDictionary;
//     $scope.dictionary = surveyDictionary || {
//             public: false,
//             SurveyCode: $scope.survey.code,
//             report: {},
//             roles: [],
//             blocks: [],
//             dimensions: []
//         };
//     $scope.dictionaries = dictionaries;
//     $scope.copy = copy;
//     $scope.expanded = {blocks: true}
//
//     if (copy) {
//         delete $scope.dictionary.id;
//         $scope.dictionary.public = false;
//         $scope.dictionary.default = false;
//         $scope.dictionary.name = 'Copy of ' + $scope.dictionary.name;
//
//         // $scope.dictionary.code = $scope.dictionary.code + '-2';
//     }
//
//     $scope.validateDictionaryCode = function (value) {
//         return ($scope.dictionary.id || !_.find($scope.dictionaries, {code: value}));
//     }
//
//     $scope.survey.roles.forEach(function (role) {
//         if (!_.find($scope.dictionary.roles, {code: role.code})) {
//             $scope.dictionary.roles.push(role);
//         }
//     })
//     _.remove($scope.dictionary.roles, function (item) {
//         return !_.find($scope.survey.roles, {code: item.code})
//     })
//
//     $scope.survey.dimensions.forEach(function (dimension) {
//         var dDimension = _.find($scope.dictionary.dimensions, {code: dimension.code});
//         if (!dDimension) {
//             dDimension = {
//                 code: dimension.code
//             };
//             $scope.dictionary.dimensions[dimension.code] = dDimension;
//         }
//     })
//     _.remove($scope.dictionary.dimensions, function (item) {
//         return !_.find($scope.survey.dimensions, {code: item.code})
//     })
//
//     $scope.survey.blocks.forEach(function (block) {
//         var dBlock = _.find($scope.dictionary.blocks, {id: block.id});
//         if (!dBlock) {
//             dBlock = {
//                 id: block.id,
//                 statements: []
//             };
//             $scope.dictionary.blocks.push(dBlock);
//         }
//
//         block.statements.forEach(function (statement) {
//             if (!_.find(dBlock.statements, {id: statement.id})) {
//                 if (!dBlock.statements) {
//                     dBlock.statements = [];
//                 }
//                 dBlock.statements.push({id: statement.id});
//             }
//         })
//     })
//     _.remove($scope.dictionary.blocks, function (item) {
//         return !_.find($scope.survey.blocks, {id: item.id})
//     })
//
//
//     $scope.getDictDimension = function (dimension) {
//         var dictDimension = _.find($scope.dictionary.dimensions, {code: dimension.code});
//         if (!dictDimension) {
//             dictDimension = {code: dimension.code};
//             $scope.dictionary.dimensions.push(dictDimension);
//         }
//         return dictDimension;
//     }
//
//     $scope.getDictBlock = function (block) {
//         var dictBlock = _.find($scope.dictionary.blocks, {id: block.id});
//         if (!dictBlock) {
//             dictBlock = {id: block.id, statements: []};
//             $scope.dictionary.blocks.push(dictBlock);
//         }
//         return dictBlock;
//     }
//
//     $scope.getDictStatement = function (block, statement) {
//         var dictBlock = $scope.getDictBlock(block);
//         var dictStatement = _.find(dictBlock.statements, {id: statement.id});
//         if (!dictStatement) {
//             dictStatement = {id: statement.id};
//             dictBlock.statements.push(dictStatement);
//         }
//         return dictStatement;
//     }
//
//     $scope.save = function (cb) {
//         if (!$scope.copy && $scope.dictionary.id) {
//             $http.put('/api/surveys/' + $scope.survey.code + '/dictionaries/' + $scope.dictionary.id, {dictionary: $scope.dictionary})
//                 .success(function (result) {
//                     $scope.dictionary = result;
//                     $scope.messages = ['message.saved']
//                     cb();
//                 }).error(function (error) {
//                 $scope.errors = [error];
//                 cb();
//             })
//         } else {
//             $http.post('/api/surveys/' + $scope.survey.code + '/dictionaries', {dictionary: $scope.dictionary})
//                 .success(function (result) {
//                     $scope.dictionary = result;
//                     $scope.messages = ['message.saved']
//                     $state.go('sales.surveydictionary', {
//                         surveyCode: $scope.dictionary.SurveyCode,
//                         dictionaryId: $scope.dictionary.id
//                     });
//                     cb();
//                 }).error(function (error) {
//                 $scope.errors = [error];
//                 cb();
//             })
//         }
//     }
//
//     $scope.revert = function () {
//         if ($scope.dictionary.id) {
//             $http.get('/api/surveys/' + $scope.survey.code + '/dictionaries/' + $scope.dictionary.id)
//                 .success(function (result) {
//                     $scope.dictionary = result;
//                 }).error(function (error) {
//                 $scope.errors = [error];
//             })
//         }
//     }
//
// })
