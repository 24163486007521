angular
  .module('vcio-toolkit')

  .constant('AUTH_EVENTS', {
    loginSuccess: 'auth-login-success',
    loginFailed: 'auth-login-failed',
    logoutSuccess: 'auth-logout-success',
    sessionTimeout: 'auth-session-timeout',
    notAuthenticated: 'auth-not-authenticated',
    unauthorized: 'auth-unauthorized',
  })

  .factory(
    'authInterceptor',
    function ($rootScope, $q, $window, AUTH_EVENTS, LocalStorage, SessionStorage) {
      return {
        request: function (config) {
          config.headers = config.headers || {};
          var token = SessionStorage.get('auth_token') || LocalStorage.get('auth_token');
          if (token) {
            config.headers.Authorization = 'Bearer ' + token;
          }
          return config;
        },
        response: function (response) {
          return response || $q.when(response);
        },
        responseError: function (rejection) {
          if (rejection.status === 401 || rejection.status === 419 || rejection.status === 440) {
            $rootScope.$broadcast(AUTH_EVENTS.notAuthenticated);
          }
          return $q.reject(rejection);
        },
      };
    },
  );
