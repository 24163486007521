angular
  .module('vcio-toolkit')

  .factory('AssetModel', function ($resource, CurrentUser, _) {
    var resource = $resource(
      '/api/academy/assets/:assetId',
      { assetId: '@id' },
      {
        create: {
          method: 'POST',
          transformRequest: function (data) {
            return angular.toJson({ asset: data });
          },
        },
        delete: {
          method: 'DELETE',
          transformRequest: function (data) {
            return angular.toJson({ asset: data });
          },
        },
        update: {
          url: '/api/academy/assets',
          method: 'PUT',
          transformRequest: function (data) {
            return angular.toJson({ asset: data });
          },
        },
        copy: {
          url: '/api/academy/assets/copy',
          method: 'POST',
          transformRequest: function (data) {
            return angular.toJson({ asset: _.pick(data, ['id', 'name', 'subtitle']) });
          },
        },
        reorder: {
          url: '/api/academy/assets/reorder',
          isArray: true,
          method: 'POST',
          transformRequest: function (datas) {
            var assetIds = [];
            _.forEach(datas, function (data) {
              assetIds.push({
                id: data.id,
                orderId: data.orderId,
                SubCategoryId: data.SubCategoryId,
              });
            });
            return angular.toJson({ assetIds: assetIds });
          },
        },
        queryByVendor: {
          method: 'GET',
          isArray: true,
          transformResponse: function (data) {
            return _.filter(angular.fromJson(data), { VendorId: CurrentUser.getUser().Company.id });
          },
        },
      },
    );

    resource.prototype.$save = function () {
      if (!this.id) {
        return this.$create();
      } else {
        return this.$update();
      }
    };

    return resource;
  });
