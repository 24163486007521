angular
  .module('vcio-toolkit')
  .controller(
    'SidebarCesActivitiesController',
    function (
      $modal,
      $moment,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $translate,
      _,
      CurrentUser,
      DialogService,
      HttpService,
      SidebarFilterService,
    ) {
      $scope.currentUser = CurrentUser.getUser();
      $scope.filter = SidebarFilterService.get('cesactivities', $scope, {
        type: [
          {
            name: $translate.instant('label.client.ces.activityTypes.plan'),
            code: 'plan',
            id: 1,
          },
          {
            name: $translate.instant('label.client.ces.activityTypes.actual'),
            code: 'actual',
            id: 2,
          },
          {
            name: $translate.instant('label.client.ces.activityTypes.missed'),
            code: 'missed',
            id: 3,
          },
        ],
        compliance: [
          { id: 1, name: 'label.client.ces.sidebar.segment.compliant', class: 'text-success' },
          { id: 0, name: 'label.client.ces.sidebar.segment.semiCompliant', class: 'text-warning' },
          { id: -1, name: 'label.client.ces.sidebar.segment.notCompliant', class: 'text-danger' },
          { id: undefined, name: 'label.client.ces.sidebar.segment.undefined' },
        ],
      }).filter;

      $scope.openSharing = function () {
        $modal.open({
          templateUrl: '/templates/sidebar/cesactivities-share.html',
          size: 'lg',
          controller: 'CesActivitiesShareController',
          // size: 'lg',
          backdrop: 'static',
          resolve: {
            calendarShares: function () {
              $rootScope.$broadcast('dataLoadingStarted');
              return HttpService.get('/api/calendars');
            },
          },
        });
      };
    },
  );
