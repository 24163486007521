'use strict';
angular
  .module('vcio-toolkit')

  .factory('froalaConfig', function ($timeout, _, CurrentUser) {
    var templates = [
      {
        id: 't1',
        name: 'Project Scope Exercise',
        html: '<p><strong>Ask:</strong> As you think about success on this project, tell me, what kinds of things are important to you?</p><ul><li>&nbsp;</li><li>&nbsp;</li><li>&nbsp;</li><li><br></li></ul><p><strong>Add:</strong> Anything else?</p><ul><li>&nbsp;</li><li>&nbsp;</li><li>&nbsp;</li><li><br></li></ul><p><strong>Prioritize:</strong> What is your priority for these things?</p><ul><li>&nbsp;</li><li>&nbsp;</li><li>&nbsp;</li><li><br></li></ul><p><strong>Desired results / Priorities</strong></p><ol><li><br></li><li><br></li><li><br></li><li><br></li><li><br></li><li><br></li></ol>',
      },
      {
        id: 't2',
        name: 'Set Project Performance Priorities',
        html: '<p><em>[Please indicate what performance factors matters most during the project - Distribute 10 Points Across the 3 factors]</em></p><table style="margin-right: calc(70%); width: 30%;"><thead><tr><th>Performance Factors<br></th><th style="text-align: center;">Points<br></th></tr></thead><tbody><tr><td style="width: 75%; vertical-align: middle;">Quality / Scope<br></td><td style="width: 25%; vertical-align: middle; text-align: center;"><br></td></tr><tr><td style="width: 75%; vertical-align: middle;">Time / Deadline<br></td><td style="width: 25%; vertical-align: middle; text-align: center;"><br></td></tr><tr><td style="width: 75%; vertical-align: middle;">Cost<br></td><td style="width: 25%; vertical-align: middle; text-align: center;"><br></td></tr><tr><td style="width: 75%; text-align: left; vertical-align: bottom;"><br>Overall<br></td><td style="width: 25%; text-align: center; vertical-align: bottom;">10<br></td></tr></tbody></table><p><br></p>',
      },
      {
        id: 't3',
        name: 'Make a Project Change Request',
        html: '<p><strong>Complete this tool if the change meets one or more of the following criteria:</strong></p><ul><li>The change is estimated to cost more than (fill in).</li><li>The change moves the schedule by more than % (fill in).</li><li>The change alters the original project vision statement.</li></ul><p><strong><br></strong></p><p><strong>Describe the proposed project change:&nbsp;</strong></p><p><br></p><p><strong>List the reasons for the proposed change:&nbsp;</strong></p><p><br></p><p><br></p><p><br></p><p><strong>Identify how the change will affect the following project elements:</strong></p><p><br></p><ul><li>Project Schedule:&nbsp;</li><li>Cost:</li><li>Resources:&nbsp;</li></ul><p><br></p><p><strong>Does the change affect the original project vision statement? If yes, how?&nbsp;</strong></p><p><br></p><p><br></p><p><strong>Describe the effect on the project if this change is not made:&nbsp;</strong></p><p><br></p><p><br></p><p><strong>Accountability&mdash;Person Making the Change Request</strong></p><p><br></p><p>Name:</p><p>Date:</p><p><br></p><p><br></p>',
      },
      {
        id: 't4',
        name: 'Problem solving exercise',
        html: '<p><strong>What is the Problem</strong> - symptoms, issues, gaps</p><ul><li>&nbsp;</li><li>&nbsp;</li><li>&nbsp;</li></ul><p><strong>Analysis of current situation</strong> - what is happening</p><ul><li>&nbsp;</li><li>&nbsp;</li><li>&nbsp;</li></ul><p><strong>Set Goals</strong> - this is what the ideal looks like</p><ul><li>&nbsp;</li><li>&nbsp;</li><li>&nbsp;</li></ul><p><strong>Find out the reasons</strong> - why the problem exists</p><ul><li>&nbsp;</li><li>&nbsp;</li><li><br></li></ul><p><strong>Solutions</strong> - what will fix the problems</p><ul><li>&nbsp;</li><li>&nbsp;</li><li>&nbsp;</li></ul><p><strong>Implement</strong> - what are the next steps</p><ul><li>&nbsp;</li><li>&nbsp;</li><li><br></li></ul>',
      },
    ];

    function toggleCustomQuote(froala, val, type) {
      function findParentNode(o) {
        for (; o.parentNode && o.parentNode != froala.el; ) {
          o = o.parentNode;
        }
        return o;
      }

      if (val === 'decrease') {
        var blocks = froala.selection.blocks();
        for (var i = 0; i < blocks.length; i++) {
          'BLOCKQUOTE' != blocks[i].tagName &&
            (blocks[i] = jQuery(blocks[i]).parentsUntil(froala.$el, 'BLOCKQUOTE').get(0));
        }
        froala.selection.save();
        for (var i = 0; i < blocks.length; i++) {
          blocks[i] && jQuery(blocks[i]).replaceWith(blocks[i].innerHTML);
        }
        froala.html.unwrap();
        froala.selection.restore();
      } else if (val === 'increase') {
        var blocks = froala.selection.blocks();
        for (var i = 0; i < blocks.length; i++) {
          blocks[i] = findParentNode(blocks[i]);
        }
        froala.selection.save();
        var element = jQuery('<blockquote class="blockquote-' + type + '">');
        element.insertBefore(blocks[0]);
        for (var i = 0; i < blocks.length; i++) {
          element.append(blocks[i]);
        }
        froala.html.unwrap();
        froala.selection.restore();
      }
    }

    FroalaEditor.DefineIconTemplate(
      'quote',
      '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path style="fill: [COLOR]" d="M10.31788,5l.93817,1.3226A12.88271,12.88271,0,0,0,8.1653,9.40125a5.54242,5.54242,0,0,0-.998,3.07866v.33733q.36089-.04773.66067-.084a4.75723,4.75723,0,0,1,.56519-.03691,2.87044,2.87044,0,0,1,2.11693.8427,2.8416,2.8416,0,0,1,.8427,2.09274,3.37183,3.37183,0,0,1-.8898,2.453A3.143,3.143,0,0,1,8.10547,19,3.40532,3.40532,0,0,1,5.375,17.7245,4.91156,4.91156,0,0,1,4.30442,14.453,9.3672,9.3672,0,0,1,5.82051,9.32933,14.75716,14.75716,0,0,1,10.31788,5Zm8.39243,0,.9369,1.3226a12.88289,12.88289,0,0,0-3.09075,3.07865,5.54241,5.54241,0,0,0-.998,3.07866v.33733q.33606-.04773.63775-.084a4.91773,4.91773,0,0,1,.58938-.03691,2.8043,2.8043,0,0,1,2.1042.83,2.89952,2.89952,0,0,1,.80578,2.10547,3.42336,3.42336,0,0,1-.86561,2.453A3.06291,3.06291,0,0,1,16.49664,19,3.47924,3.47924,0,0,1,13.742,17.7245,4.846,4.846,0,0,1,12.64721,14.453,9.25867,9.25867,0,0,1,14.17476,9.3898,15.26076,15.26076,0,0,1,18.71031,5Z"></path></svg>',
    );
    FroalaEditor.DefineIcon('quotePrimary', { NAME: 'quote', COLOR: '#62A6DB', template: 'quote' });
    FroalaEditor.RegisterCommand('quotePrimary', {
      title: 'Quote Blue',
      type: 'dropdown',
      options: { increase: 'Increase', decrease: 'Decrease' },
      callback: function (cmd, val) {
        toggleCustomQuote(this, val, 'primary');
      },
    });
    FroalaEditor.DefineIcon('quoteSuccess', {
      NAME: 'quote-left',
      COLOR: '#59BF90',
      template: 'quote',
    });
    FroalaEditor.RegisterCommand('quoteSuccess', {
      title: 'Quote Green',
      type: 'dropdown',
      options: { increase: 'Increase', decrease: 'Decrease' },
      callback: function (cmd, val) {
        toggleCustomQuote(this, val, 'success');
      },
    });
    FroalaEditor.DefineIcon('quoteWarning', {
      NAME: 'quote-left',
      COLOR: '#F7BE08',
      template: 'quote',
    });
    FroalaEditor.RegisterCommand('quoteWarning', {
      title: 'Quote Yellow',
      type: 'dropdown',
      options: { increase: 'Increase', decrease: 'Decrease' },
      callback: function (cmd, val) {
        toggleCustomQuote(this, val, 'warning');
      },
    });
    FroalaEditor.DefineIcon('quoteDanger', {
      NAME: 'quote-left',
      COLOR: '#F6303D',
      template: 'quote',
    });
    FroalaEditor.RegisterCommand('quoteDanger', {
      title: 'Quote Red',
      type: 'dropdown',
      options: { increase: 'Increase', decrease: 'Decrease' },
      callback: function (cmd, val) {
        toggleCustomQuote(this, val, 'danger');
      },
    });

    FroalaEditor.DefineIcon('templateDropdown', { NAME: 'cogs', SVG_KEY: 'cogs' });
    FroalaEditor.RegisterCommand('templateDropdown', {
      title: 'Insert template',
      type: 'dropdown',
      focus: false,
      undo: true,
      refreshAfterCallback: true,
      options: _.mapValues(_.keyBy(templates, 'id'), 'name'),
      callback: function (cmd, val) {
        var text = _.mapValues(_.keyBy(templates, 'id'), 'html')[val];
        if (text) {
          this.html.insert(text);
        }
      },
    });
    // <blockquote class="embedly-card" data-card-key="aec553413cc2468bbb28887c06b9a66d" data-card-image="https://www.cognitoforms.com/content/images/blog/trello-feature-3-e1d370ed-6b04-4f90-b947-ebb8c455cc22.png" data-card-type="article-full"><h4><a href="https://www.cognitoforms.com/blog/52/how-we-use-trello-to-manage-releases">How We Use Trello to Manage Releases</a></h4><p>Learn how our team works collaboratively to build Cognito Forms every day.</p></blockquote>
    //     <script async src="//cdn.embedly.com/widgets/platform.js" charset="UTF-8"></script>
    return {
      paragraphStyles: {
        'blockquote-success': 'Blockquote Green',
        'blockquote-warning': 'Blockquote Yellow',
        'blockquote-danger': 'Blockquote Red',
        'blockquote-primary': 'Blockquote Blue',
      },
      key: 'DUA2yE2A2H1A8B2B5C2qYFd1UQRFQIVb1MSMc2IWPNe1IFg1yD4C3D2C1C4C1F2H4B2C2==',
      attribution: false,
      embedlyDefaults: {
        cards: {
          override: true,
          controls: 0,
          recommend: 0,
        },
      },
      embedlyOptions: {
        controls: 0,
        recommend: 0,
      },
      embedlyKey: 'aec553413cc2468bbb28887c06b9a66d',
      embedlyScriptPath: 'https://cdn.embedly.com/widgets/platform.js',
      toolbarInline: false,
      fontFamily: {
        'Arial,Helvetica,sans-serif': 'Arial',
        'Georgia,serif': 'Georgia',
        'Impact,Charcoal,sans-serif': 'Impact',
        'Tahoma,Geneva,sans-serif': 'Tahoma',
        "'Times New Roman',Times,serif": 'Times New Roman',
        'Verdana,Geneva,sans-serif': 'Verdana',
        Roboto: 'Roboto',
      },
      fontSize: ['8', '10', '12', '14', '16', '18', '20', '30', '60', '96'],
      colorsText: [
        '#61BD6D',
        '#1ABC9C',
        '#54ACD2',
        '#2C82C9',
        '#9365B8',
        '#475577',
        '#000000',
        '#41A85F',
        '#00A885',
        '#3D8EB9',
        '#2969B0',
        '#553982',
        '#28324E',
        '#404040',
        '#F7DA64',
        '#FBA026',
        '#EB6B56',
        '#E25041',
        '#A38F84',
        '#EFEFEF',
        '#7D7F80',
        '#FAC51C',
        '#F37934',
        '#D14841',
        '#B8312F',
        '#7C706B',
        '#D1D5D8',
        '#CBCBCB',
        '#74BC93',
        '#72A4D6',
        '#EEC042',
        '#E24545',
        '#251C72',
        '#FFFFFF',
        'REMOVE',
      ],
      colorsBackground: [
        '#61BD6D',
        '#1ABC9C',
        '#54ACD2',
        '#2C82C9',
        '#9365B8',
        '#475577',
        '#000000',
        '#41A85F',
        '#00A885',
        '#3D8EB9',
        '#2969B0',
        '#553982',
        '#28324E',
        '#404040',
        '#F7DA64',
        '#FBA026',
        '#EB6B56',
        '#E25041',
        '#A38F84',
        '#EFEFEF',
        '#7D7F80',
        '#FAC51C',
        '#F37934',
        '#D14841',
        '#B8312F',
        '#7C706B',
        '#D1D5D8',
        '#CBCBCB',
        '#74BC93',
        '#72A4D6',
        '#EEC042',
        '#E24545',
        '#251C72',
        '#FFFFFF',
        'REMOVE',
      ],
      toolbarButtons: [
        'paragraphFormat',
        'fontFamily',
        'fontSize',
        'textColor',
        'backgroundColor',
        'quote',
        'quoteSuccess',
        'quoteWarning',
        'quoteDanger',
        'quotePrimary',
        '|',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'insertLink',
        'formatUL',
        'formatOL',
        'clearFormatting',
        '|',
        'align',
        'indent',
        'outdent',
        'insertTable',
        'templateDropdown',
        '-',
        'html',
        'insertImage',
        'embedly',
        '|',
        'undo',
        'redo',
        'help',
      ],
      fontSizeSelection: true,
      heightMin: 100,
      // heightMax: 500,
      imageDefaultAlign: null,
      imageDefaultDisplay: null,
      // imageOutputSize: true,
      imageUploadURL: '/api/academy/images',
      imageUploadMethod: 'POST',
      imageUploadParam: 'file',
      imageAllowedTypes: ['jpeg', 'jpg', 'png'],
      imageManagerLoadURL: '/api/academy/images',
      imageUploadRemoteUrls: false,
      pastePlain: false,
      requestHeaders: {
        get Authorization() {
          var token = '';
          if (CurrentUser.getToken()) {
            token = 'Bearer ' + CurrentUser.getToken();
          }
          return token;
        },
      },
      events: {
        // 'froalaEditor.initialized': function (e, editor) {
        //     editor.events.focus();
        // }
        // 'froalaEditor.image.removed': function (e, editor, $img) {
        //     if ($img.data('imageid')) {
        //         HttpService.delete('/api/academy/images/' + $img.data('imageid'))
        //             .then(function (data) {
        //                 // console.log('image was deleted');
        //             }, function (error) {
        //                 console.error(error);
        //                 DialogService.error(error);
        //             })
        //     }
        // },
        // 'froalaEditor.blur': function (e, editor) {
        //done in the angular-froala.js
        //     // e.target.id should start with froala-
        //     var tag = angular.element(editor.el).parent().parent().next();
        //     if (tag && tag.attr('id') === 'blur-save-' + e.target.id) {
        //         tag.click();
        //     } else if (document.getElementById('blur-save-' + e.target.id)) {
        //         document.getElementById('blur-save-' + e.target.id).click();
        //     }
        // }
      },
    };
  });
