angular
  .module('vcio-toolkit')
  .controller(
    'TranslationsController',
    function ($scope, $state, $stateParams, $http, $sce, $modal, Upload, _, $translate) {
      $scope.translations = [];

      $scope.new = { code: '', language: 'en', value: '' };
      $scope.download = {};
      $scope.filter = { code: '', language: 'en', value: '', availableLanguages: ['en'] };
      $http
        .get('/api/lists/language')
        .success(function (languages) {
          $scope.filter.availableLanguages = languages;
        })
        .error(function (error) {
          console.error(error);
        });

      $scope.save = function () {
        $http
          .post('/api/translations', { translation: $scope.new })
          .success(function (result) {
            //if (!_.find($scope.translations, {code: $scope.new.code, language: $scope.new.language})) {
            //    $scope.translations.push(result)
            //}
            $scope.new = { code: '', language: $scope.filter.language, value: '' };
            $scope.refresh();
          })
          .error(function (error) {
            console.error(error);
          });
      };

      $scope.edit = function (translation) {
        $scope.new = {
          code: translation.code,
          language: translation.language,
          value: translation.value,
        };
      };

      $scope.filterByField = function (field, value) {
        if (field && value.toLowerCase().indexOf(field.toLowerCase()) > -1) {
          return true;
        } else {
          return false;
        }
      };

      $scope.filterTranslations = function () {
        return _.filter($scope.translations, function (item) {
          return (
            (($scope.filter.code && $scope.filterByField($scope.filter.code, item.code)) ||
              !$scope.filter.code) &&
            (($scope.filter.value && $scope.filterByField($scope.filter.value, item.value)) ||
              !$scope.filter.value) &&
            (($scope.filter.language &&
              $scope.filterByField($scope.filter.language, item.language)) ||
              !$scope.filter.language)
          );
        });
      };

      $scope.refresh = function () {
        $http.get('/api/translations').success(function (data) {
          $scope.translations = data;
        });
      };

      $scope.doUpload = function () {
        // if ($scope.file2upload.size > 512 * 1024) {
        //     $scope.errors = [{code: 'error.fileTooLarge'}]
        // } else {
        var upload = Upload.upload({
          url: '/api/translations',
          method: 'PUT',
          withCredentials: true,
          file: $scope.upload.files[0],
        })
          .progress(function (evt) {})
          .success(function (data, status, headers, config) {
            $scope.messages = ['message.fileUploaded'];
          })
          .error(function (error) {
            if (error && error.status == 400) {
              $scope.errors = [{ code: 'error.invalidFileType', fields: ['jpg, png'] }];
            } else {
              $scope.errors = [error];
            }
          });
        // }
        // };
      };

      $scope.refresh();
    },
  );
