angular.module('vcio-toolkit').factory('HttpService', function ($http, $q) {
  return {
    get: function (url, headers) {
      var deferred = $q.defer();
      $http.get(url, { headers: headers }).then(
        function (response) {
          deferred.resolve(response.data);
        },
        function (response) {
          deferred.reject(response.data);
        },
      );
      return deferred.promise;
    },
    post: function (url, data, headers) {
      var deferred = $q.defer();
      $http.post(url, data, { headers: headers }).then(
        function (response) {
          deferred.resolve(response.data);
        },
        function (response) {
          deferred.reject(response.data);
        },
      );
      return deferred.promise;
    },
    put: function (url, data, headers) {
      var deferred = $q.defer();
      $http.put(url, data, { headers: headers }).then(
        function (response) {
          deferred.resolve(response.data);
        },
        function (response) {
          deferred.reject(response.data);
        },
      );
      return deferred.promise;
    },
    delete: function (url, headers) {
      var deferred = $q.defer();
      $http.delete(url, { headers: headers }).then(
        function (response) {
          deferred.resolve(response.data);
        },
        function (response) {
          deferred.reject(response.data);
        },
      );
      return deferred.promise;
    },
  };
});
