angular.module('vcio-toolkit').factory('overviewService', function ($http, $q) {
  //        var getSurveyResult = function (id) {
  //            var deferred = $q.defer();
  //
  //            $http.get('/api/surveyresults/' + id)
  //                .success(function (result) {
  //                    deferred.resolve(result);
  //                })
  //                .error(function (error) {
  //                    console.error(error);
  //                });
  //
  //            return deferred.promise;
  //        };
  //
  //        var getScores = function (id) {
  //            var deferred = $q.defer();
  //
  //            $http.get('/api/surveyresults/' + id + '/scores')
  //                .success(function (result) {
  //                    createChartData(result)
  //                    deferred.resolve(result);
  //                })
  //                .error(function (error) {
  //                    console.error(error);
  //                });
  //
  //            return deferred.promise;
  //        };
  //
  //        var createDoughnutChartData = function (targetScore, score) {
  //            return {data: [
  //                {
  //                    value: score,
  //                    color: "#59BF90",
  //                    highlight: "#258CBC",
  //                    label: "Current"
  //                },
  //                {
  //                    value: targetScore - score,
  //                    color: "#59BF90",
  //                    highlight: "#7BAE3A",
  //                    label: "Targeted"
  //                },
  //                {
  //                    value: 100 - targetScore, //TODO check if targeted score is less than the user score
  //                    color: "#EEEEEE",
  //                    highlight: "white"
  //                }
  //            ],
  //                options: {
  //                    width: 120,
  //                    height: 120,
  //                    responsive: true,
  //                    animation: false,
  //                    showTooltips: false/*,
  //                     onAnimationComplete: function () {
  //                     this.chart.ctx.font = "bold 9px Helvetica";
  //                     this.chart.ctx.fillStyle = "#59BF90";
  //                     this.chart.ctx.textAlign = "center";
  //                     this.chart.ctx.fillText(score + '%', this.chart.canvas.width / 2, this.chart.canvas.height / 2 + 10);
  //                     }*/
  //                }
  //            }
  //        }
  //
  //        var createDimensionData = function (dimension) {
  //            dimension.doughnutChartData = createDoughnutChartData(dimension.targetScore, dimension.score)
  //            dimension.name = "dimension." + dimension.code + ".name";
  //            dimension.hint = "dimension." + dimension.code + ".hint";
  //            if (dimension.blocks) {
  //                dimension.blocks.forEach(function (block) {
  //                    block.doughnutChartData = createDoughnutChartData(block.targetScore, block.score)
  //                })
  //            }
  //        }
  //
  //        var createChartData = function (scores) {
  //            scores.doughnutChartData = createDoughnutChartData(scores.targetScore, scores.score)
  //            scores.execution.doughnutChartData = createDoughnutChartData(scores.execution.targetScore, scores.execution.score)
  //            scores.management.doughnutChartData = createDoughnutChartData(scores.management.targetScore, scores.management.score)
  //
  //            scores.execution.name = "dimension.type.execution";
  //            scores.execution.dimensions.forEach(function (dimension) {
  //                createDimensionData(dimension)
  //            })
  //
  //            scores.management.name = "dimension.type.management";
  //            scores.management.dimensions.forEach(function (dimension) {
  //                createDimensionData(dimension)
  //            })
  ////            scores.targetScore = 72
  ////            scores.score = 65
  //
  //            scores.doughnutChartData = {data: [
  //                {
  //                    value: scores.score,
  //                    color: "#62A6DB",
  //                    highlight: "#258CBC",
  //                    label: "Current"
  //                },
  //                {
  //                    value: scores.targetScore - scores.score,
  //                    color: "#59BF90",
  //                    highlight: "#7BAE3A",
  //                    label: "Targeted"
  //                }
  //            ],
  //                options: {
  //                    width: 120,
  //                    height: 120,
  //                    responsive: true,
  //                    animation: false/*,
  //                     onAnimationComplete: function () {
  //                     this.chart.ctx.font = "bold 24px Helvetica";
  //                     this.chart.ctx.fillStyle = "#62A6DB";
  //                     this.chart.ctx.textAlign = "center";
  //                     this.chart.ctx.fillText(scores.score + '%', this.chart.canvas.width / 2, this.chart.canvas.height / 2 + 10);
  //                     }*/
  //                }
  //            }
  //
  //            var ranges = [
  //                {min: 0, max: 40, name: '0-40'},
  //                {min: 40, max: 60, name: '40-60'},
  //                {min: 60, max: 80, name: '60-80'},
  //                {min: 80, max: 100, name: '80-100'}
  //            ]
  //
  //            ranges.forEach(function (range) {
  //                if (scores.targetScore < range.max) {
  //                    // we have to show the range
  //                    if (scores.targetScore >= range.min && scores.targetScore < range.max) {
  //                        // the score is in this range
  //                        scores.doughnutChartData.data.push({
  //                            value: range.max - scores.targetScore,
  //                            color: "#EEEEEE",
  //                            highlight: "#EEEEEE",
  //                            label: range.name
  //                        })
  //                    } else {
  //                        // show the entire range
  //                        scores.doughnutChartData.data.push({
  //                            value: range.max - range.min,
  //                            color: "#EEEEEE",
  //                            highlight: "#EEEEEE",
  //                            label: range.name
  //                        })
  //                    }
  //                }
  //            })
  //        }

  //        var getActionPlan = function (resultId, cb) {
  //            $http.get('/api/actionplans/' + resultId)
  //                .success(function (result) {
  //                    cb(result)
  //                })
  //                .error(function (error) {
  //                    console.log(error.err);
  //                });
  //        }

  return {
    //getSurveyResult: getSurveyResult,
    //getScores: getScores,
    //createChartData: createChartData
  };
});
