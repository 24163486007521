angular
  .module('vcio-toolkit')

  .controller(
    'SidebarAcademyController',
    function (
      $modal,
      $q,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $translate,
      _,
      AssetCategoryModel,
      AssetModel,
      AssetService,
      CurrentUser,
      DialogService,
      HttpService,
      LocalStorage,
      PermissionGroupModel,
      LocalStorage,
      SessionStorage,
    ) {
      $scope.itemTree = [];
      $scope.dragging = false;
      $scope.dragItem = null;
      $scope.currentUser = CurrentUser.getUser();

      function addChild(itemTree, item, parent) {
        var result;
        if (item.SubCategoryId) {
          //asset
          parent.children = parent.children || [];
          result = _.extend(
            _.pick(item, ['id', 'name', 'type', 'orderId', 'subtitle', 'VendorId', 'CategoryId']),
            {
              typeName:
                item.type === 'knowledgebase'
                  ? 'academy.asset.knowledgebaseTypes.' + item.knowledgebaseType
                  : 'label.academy.assetTypes.' + item.type,
              level: 3,
            },
          );
          parent.children.push(result);
        } else if (item.CategoryId) {
          // subcategory
          parent.children = parent.children || [];
          result = _.extend(
            _.pick(item, ['id', 'name', 'type', 'orderId', 'subtitle', 'VendorId', 'CategoryId']),
            {
              typeName: 'label.academy.assetTypes.' + item.type,
              children: [],
              level: 2,
            },
          );
          parent.children.push(result);
        } else {
          //category
          result = _.extend(
            _.pick(item, ['id', 'name', 'type', 'orderId', 'subtitle', 'VendorId', 'ImageId']),
            {
              typeName: 'label.academy.assetTypes.' + item.type,
              imageUrl: '/images/assetcategories/' + item.id,
              children: [],
              level: 1,
            },
          );
          _.find(itemTree, { type: item.type }).children.push(result);
        }
        return result;
      }

      function refreshCategoryTree() {
        var oldItemtree = $scope.itemTree;
        var newItemTree = [];
        var deferred = $q.defer();

        HttpService.get('/api/academy/assetcategorytree')
          .then(function (_categories) {
            _.forEach(AssetService.mainCats, function (mainCatId, index) {
              if (AssetService.mainCatsObj[mainCatId].showInSidebar) {
                var mainCatItem = {
                  type: mainCatId,
                  level: 0,
                  $$expanded: oldItemtree[index] ? oldItemtree[index].$$expanded : true,
                  name: 'label.academy.assetTypes.' + mainCatId,
                  children: [], //_.filter(_.orderBy(categories, ['orderId', 'name']), {type: mainCatId})
                };
                if ($scope.sidebarAcademyStatus[mainCatId] === false) {
                  mainCatItem.$$expanded = false;
                }

                // processCategory(mainCatItem, 1);
                newItemTree.push(mainCatItem);
              }
            });

            _.forEach(_categories, function (_category) {
              var level1 = addChild(newItemTree, _category);
              _.forEach(_category.SubCategories, function (_subcategory) {
                var level2 = addChild(newItemTree, _subcategory, level1);
                _.forEach(_subcategory.Assets, function (_asset) {
                  addChild(newItemTree, _asset, level2);
                });
              });
            });

            $scope.itemTree = newItemTree;
            $scope.currentUser = CurrentUser.getUser();
            deferred.resolve();
          })
          .catch(function (error) {
            console.error(error);
            $scope.currentUser = CurrentUser.getUser();
            deferred.reject();
          });
        deferred.resolve();
      }

      function resetActiveCategoryTree(items) {
        _.forEach(items, function (item) {
          if (item.$$active) {
            item.$$active = false;
          }
          if (item.children && item.children.length) {
            resetActiveCategoryTree(item.children);
          }
        });
      }

      function expandActiveCategoryTree() {
        if ($scope.itemTree.length) {
          resetActiveCategoryTree($scope.itemTree);
          var category;
          var subcategory;

          if ($stateParams.assetId) {
            var asset; // = $scope.itemManager.getAssetById(parseInt($stateParams.assetId));
            _.some(_.flatMap($scope.itemTree, 'children'), function (_category) {
              if (
                _.some(_category.children, function (_subcategory) {
                  asset = _.find(_subcategory.children, { id: parseInt($stateParams.assetId) });
                  subcategory = _subcategory;
                  return !!asset;
                })
              ) {
                category = _category;
              }
              return !!asset;
            });
            if (asset) {
              asset.$$active = true;
              subcategory.$$expanded = true;
              category.$$expanded = true;
            }
          } else if ($stateParams.categoryId) {
            _.some(_.flatMap($scope.itemTree, 'children'), function (_category) {
              subcategory = _.find(_category.children, { id: parseInt($stateParams.categoryId) });
              category = _category;
              return !!subcategory;
            });
            if (subcategory) {
              subcategory.$$active = true;
              category.$$expanded = true;
            } else if (category) {
              category.$$active = true;
            }
          }
          // if (category) {
          //     if (category.CategoryId) {
          //         subcategory = category;
          //         category = $scope.itemManager.getCategoryById(category.CategoryId);
          //     }
          //     var mainCat = _.find($scope.itemTree, {type: category.type});
          //     if (mainCat) {
          //         mainCat.$$expanded = true;
          //         var cat1 = _.find(mainCat.children, {id: category.id});
          //         if (cat1) {
          //             cat1.$$expanded = true;
          //             if (subcategory && cat1.children && cat1.children.length) {
          //                 var cat2 = _.find(cat1.children, {id: subcategory.id});
          //                 if (cat2) {
          //                     cat2.$$expanded = true;
          //                 }
          //             }
          //         }
          //     }
          // }
        }
      }

      function reorderCategory(item) {
        if (item.children) {
          _.forEach(item.children, function (child, index) {
            if (child.orderId !== index + 1) {
              child.orderChanged = true;
              child.orderId = index + 1;
            }
            if (child.children) {
              var newCategoryId = item.id ? item.id : null;
              if (child.CategoryId !== newCategoryId) {
                child.orderChanged = true;
                child.CategoryId = newCategoryId;
              }
              reorderCategory(child);
            } else {
              if (child.SubCategoryId !== item.id) {
                child.orderChanged = true;
                child.SubCategoryId = item.id;
              }
            }
          });
        }
      }

      function reorderCategoryTree() {
        _.forEach($scope.itemTree, function (item) {
          reorderCategory(item);
        });
      }

      function saveReorder() {
        var deferred = $q.defer();
        var categories = _.flatMap($scope.itemTree, 'children');
        var assets = _($scope.itemTree)
          .flatMap('children')
          .flatMap('children')
          .flatMap('children')
          .value();
        $q.all([
          // AssetCategoryModel.reorder(_.filter(categories, {orderChanged: true})).$promise,
          AssetCategoryModel.reorder(
            _(categories)
              .flatMap('children')
              .union(categories)
              .filter({ orderChanged: true })
              .value(),
          ).$promise,
          AssetModel.reorder(_.filter(assets, { orderChanged: true })).$promise,
        ]).then(
          function () {
            _.forEach(categories, function (category) {
              category.orderChanged = false;
            });
            _.forEach(assets, function (asset) {
              asset.orderChanged = false;
            });
            deferred.resolve();
          },
          function () {
            deferred.reject();
          },
        );
        return deferred.promise;
      }

      refreshCategoryTree();
      expandActiveCategoryTree();

      $scope.$on('login-changed-event', function () {
        refreshCategoryTree();
      });

      $scope.$on('academyAssetSaved', function (event, data, c, d) {
        var asset;
        _.some(_.flatMap($scope.itemTree, 'children'), function (_category) {
          if (
            _.some(_category.children, function (_subcategory) {
              asset = _.find(_subcategory.children, { id: parseInt(data.asset.id) });
              return !!asset;
            })
          ) {
          }
          return !!asset;
        });
        if (asset) {
          asset.name = data.asset.name;
        } else {
          var subcategory;
          _.some(_.flatMap($scope.itemTree, 'children'), function (_category) {
            subcategory = _.find(_category.children, { id: parseInt(data.asset.SubCategoryId) });
            return !!subcategory;
          });
          if (subcategory) {
            addChild($scope.itemTree, data.asset, subcategory);
          }
        }
      });

      // ----------------------------------------------------------- DND

      $scope.dragstartCallback = function (item, parentItem) {
        $scope.dragging = true;
        $scope.dragItem = item;
        $scope.dragItem.dragType = item.type + parentItem.level;
      };
      $scope.dragendCallback = function () {
        $scope.dragging = false;
        $scope.dragItem = null;
      };
      $scope.dropCallback = function (srcList, srcIndex, targetList, targetIndex) {
        // Copy the item from source to target.
        targetList.splice(targetIndex, 0, srcList[srcIndex]);
        // Remove the item from the source, possibly correcting the index first.
        // We must do this immediately, otherwise ng-repeat complains about duplicates.
        if (srcList == targetList && targetIndex <= srcIndex) {
          srcIndex++;
        }
        srcList.splice(srcIndex, 1);
        reorderCategoryTree();
        $rootScope.$broadcast('dataLoadingStarted');
        saveReorder().then(
          function () {
            $rootScope.$broadcast('dataLoadingFinished');
          },
          function (error) {
            DialogService.error(error);
            $rootScope.$broadcast('dataLoadingFinished');
          },
        );
        expandActiveCategoryTree();
        // By returning true from dnd-drop we signalize we already inserted the item.
        return true;
      };

      $scope.clickItem = function (item) {
        if (!item.children) {
          AssetService.goState(item);
        } else if (item.CategoryId) {
          $state.go('academy.assetcategory', { categoryId: item.CategoryId, '#': item.id });
        } else {
          $state.go('academy.assetcategory', { categoryId: item.id });
        }
      };

      $scope.addItem = function (parentItem) {
        if (parentItem.CategoryId) {
          $state.go('academy.assetAdd', {
            assetCategory: parentItem.id,
            assetType: parentItem.type,
          });
        } else {
          var modalInstance = $modal.open({
            templateUrl: '/templates/sidebar/academy-sidebar-category-modal.html',
            controller: 'VendorSidebarCategoryModal',
            backdrop: 'static',
            resolve: {
              parentItem: function () {
                return parentItem;
              },
              item: function () {
                return {
                  name: '',
                  type: parentItem.type,
                  CategoryId: parentItem.id,
                };
              },
              permissiongroups: function () {
                return null; //PermissionGroupModel.query().$promise;
              },
            },
          });

          modalInstance.result.then(
            function (newItem) {
              new AssetCategoryModel(newItem).$save().then(function (saved) {
                if (newItem.id) {
                  newItem.name = saved.name;
                  newItem.description = saved.description;
                  newItem.ImageId = saved.ImageId;
                } else {
                  addChild($scope.itemTree, saved, parentItem);
                }
                // refreshCategoryTree()
              });
            },
            function () {},
          );
        }
      };

      $scope.copyItem = function (item, parentItem) {
        if (!_.has(item, 'children')) {
          var modalInstance = $modal.open({
            templateUrl: '/templates/sidebar/academy-sidebar-copy-modal.html',
            controller: 'VendorSidebarCopyModal',
            backdrop: 'static',
            resolve: {
              item: function () {
                var copyAsset = _.cloneDeep(item);
                copyAsset.name = 'Copy - ' + copyAsset.name;
                return copyAsset;
              },
            },
          });

          modalInstance.result.then(
            function (newItem) {
              new AssetModel(newItem).$copy(
                function (saved) {
                  addChild($scope.itemTree, saved, parentItem);
                  // refreshCategoryTree();
                  $state.go('academy.assetEdit', { assetId: saved.id });
                },
                function (error) {
                  DialogService.error(error);
                },
              );
            },
            function () {},
          );
        }
      };

      $scope.canEdit = function (item) {
        if (!$scope.currentUser) {
          $scope.currentUser = CurrentUser.getUser();
        }
        return item.VendorId === $scope.currentUser.Company.id;
      };

      $scope.editItem = function (item) {
        if (!_.has(item, 'children')) {
          $state.go('academy.assetEdit', { assetId: item.id });
        } else {
          var modalInstance = $modal.open({
            templateUrl: '/templates/sidebar/academy-sidebar-category-modal.html',
            controller: 'VendorSidebarCategoryModal',
            backdrop: 'static',
            resolve: {
              item: function () {
                return angular.copy(item);
              },
              permissiongroups: function () {
                return PermissionGroupModel.query().$promise;
              },
            },
          });

          modalInstance.result.then(
            function (editItem) {
              new AssetCategoryModel(editItem).$save();
              item.name = editItem.name;
              item.VendorId = editItem.VendorId;
              item.subtitle = editItem.subtitle;
              item.imageUrl = '/images/assetcategories/' + editItem.id + '?' + new Date().getTime();
            },
            function () {},
          );
        }
      };

      $scope.deleteItem = function (item, parent) {
        DialogService.confirm('message.academy.confirmDelete', function () {
          if (_.has(item, 'children')) {
            new AssetCategoryModel(item).$delete(function () {
              if (parent) {
                _.remove(parent.children, item);
              } else {
                refreshCategoryTree();
              }
            });
          } else {
            new AssetModel(item).$delete(function () {
              if (parent) {
                _.remove(parent.children, item);
              } else {
                refreshCategoryTree();
              }
            });
          }
        });
      };

      $scope.changeExpandedStatus = function (entity) {
        entity.$$expanded = !entity.$$expanded;
        if (!entity.id) {
          $scope.sidebarAcademyStatus[entity.type] = entity.$$expanded;
        } else {
          $scope.sidebarAcademyStatus[entity.id] = entity.$$expanded;
        }
        SessionStorage.setObject('sidebarAcademyStatus', $scope.sidebarAcademyStatus);
      };
    },
  );
