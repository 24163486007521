angular
  .module('vcio-toolkit')
  .factory('LocalStorage', function (_, InputSanitizerService) {
    return {
      get: function (key, def) {
        var val = localStorage.getItem(key);
        return val || def;
      },
      getBoolean: function (key, def) {
        var val = localStorage.getItem(key);
        return val === undefined ? def : val === 'true';
      },
      getObject: function (key, def) {
        var val = localStorage.getItem(key);
        try {
          val = JSON.parse(val);
        } catch (err) {
          val = undefined;
        }
        if (_.isObject(val)) {
          return val;
        } else {
          return def;
        }
      },
      set: function (key, val) {
        return localStorage.setItem(key, val);
      },
      setObject: function (key, val) {
        val = InputSanitizerService.sanitize(JSON.stringify(val));
        return localStorage.setItem(key, val);
      },
      delete: function (key) {
        return localStorage.removeItem(key);
      },
    };
  })
  .factory('SessionStorage', function (InputSanitizerService) {
    return {
      get: function (key, def) {
        var val = sessionStorage.getItem(key);
        return val || def;
      },
      getBoolean: function (key, def) {
        var val = sessionStorage.getItem(key);
        return val === undefined ? def : val === 'true';
      },
      getObject: function (key, def) {
        var val = sessionStorage.getItem(key);
        try {
          val = JSON.parse(val);
        } catch (err) {
          val = false;
        }
        return val || def;
      },
      set: function (key, val) {
        return sessionStorage.setItem(key, val);
      },
      setObject: function (key, val) {
        val = InputSanitizerService.sanitize(JSON.stringify(val));
        return sessionStorage.setItem(key, val);
      },
      delete: function (key) {
        return sessionStorage.removeItem(key);
      },
    };
  });
