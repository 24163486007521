'use strict';

angular
  .module('vcio-toolkit')

  .controller('MeetingNotes', function ($scope, meeting, HttpService) {
    $scope.meeting = meeting;

    $scope.sideNav = {
      open: false,
      pin: false,
    };

    $scope.pinSideNav = function () {
      $scope.sideNav.pin = !$scope.sideNav.pin;
    };

    $scope.openSideNav = function () {
      $scope.sideNav.open = true;
    };

    $scope.closeSideNav = function () {
      $scope.sideNav.open = $scope.sideNav.pin && $scope.sideNav.open;
    };

    $scope.saveNotes = function (notes) {
      HttpService.put('/api/meeting/meetings/' + meeting.id + '/notes', { notes: notes });
    };
  });
