angular
  .module('vcio-toolkit')

  .directive('showErrors', function () {
    return {
      restrict: 'A',
      link: function (scope, el) {
        el.bind('blur', function () {
          var valid = false; // is valid logic
          el.toggleClass('has-error', valid);
        });
      },
    };
  })

  .directive('buttonIcon', function ($translate) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        labelAfter: '@',
        labelBefore: '@',
        icon: '@',
        action: '=?',
        classType: '@',
        buttonType: '@',
      },
      controller: function ($scope) {
        $scope.classType = $scope.classType || 'default';
        $scope.getClass = function () {
          return (
            'btn btn-' + $scope.classType + ($scope.buttonType ? ' btn-' + $scope.buttonType : '')
          );
        };
        if (!$scope.action) {
          $scope.action = function () {};
        }
      },
      template:
        '<span ng-class="getClass()" ng-click="action()">{{labelBefore | translate}} <i class="fa fa-{{icon}}"></i> {{labelAfter | translate}}</span>',
    };
  })

  .directive('buttonSwitch', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        labelAfter: '@',
        labelBefore: '@',
        model: '=',
        icon: '@',
        disabled: '=',
        classType: '@',
        buttonType: '@',
      },
      controller: function ($scope, _) {
        $scope.classType = $scope.classType || 'default';
        $scope.getClass = function () {
          return (
            'btn btn-' +
            $scope.classType +
            ($scope.buttonType ? ' btn-' + $scope.buttonType : '') +
            ($scope.model ? ' active' : '')
          );
        };
        if ($scope.icon) {
          $scope.iconOn = (_.startsWith($scope.icon, 'fa-') ? '' : 'fa-') + $scope.icon;
        } else {
          $scope.iconOn = 'fa-toggle-on';
        }
        if ($scope.icon) {
          $scope.iconOff = (_.startsWith($scope.icon, 'fa-') ? '' : 'fa-') + $scope.icon;
        } else {
          $scope.iconOff = 'fa-toggle-off';
        }
        $scope.iconClass = function () {
          return $scope.model ? $scope.iconOn : $scope.iconOff;
        };
        $scope.click = function () {
          if (!$scope.disabled) {
            $scope.model = !$scope.model;
          }
        };
      },
      // template: '<span ng-class="getClass()" ng-click="action()">{{labelBefore | translate}} <i class="fa fa-{{icon}}"></i> {{labelAfter | translate}}</span>',
      template:
        '<span ng-class="getClass()" ng-click="click()">{{labelBefore | translate}} <i class="fa" ng-class="iconClass()"></i> {{labelAfter | translate}}</span>',
    };
  })

  .directive('buttonSref', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        labelAfter: '@',
        labelBefore: '@',
        icon: '@',
        classType: '@',
        buttonType: '@',
        sref: '@',
        active: '=?',
      },
      controller: function ($scope) {
        $scope.classType = $scope.classType || 'default';
        $scope.getClass = function () {
          return (
            'btn btn-' +
            $scope.classType +
            ($scope.buttonType ? ' btn-' + $scope.buttonType : '') +
            ($scope.model ? ' active' : '')
          );
        };
      },
      template:
        '<span ng-class="getClass()" ui-sref="{{sref}}">{{labelBefore | translate}} <i class="fa fa-{{icon}}"></i> {{labelAfter | translate}}</span>',
    };
  })

  .directive('linkIcon', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        labelAfter: '@labelAfter',
        labelBefore: '@labelBefore',
        icon: '@icon',
        link: '@link',
        clazz: '@clazz',
      },
      template:
        '<a ng-href="{{link}}" class="{{clazz}}">{{labelBefore | translate}} <i class="fa fa-{{icon}}"></i> {{labelAfter | translate}}</a>',
    };
  })

  // .directive('inputHint', function ($translate) {
  //     return {
  //         restrict: "A",
  //         transclude: true,
  //         template: '<div popover-placement="top" uib-popover="{{hint}}" popover-trigger="mouseenter"><ng-transclude/></div>',
  //         scope: {
  //             inputHint: "@"
  //         },
  //         link: function ($scope, $element, $attrs) {
  //             if ($scope.inputHint) {
  //                 $scope.hint = $translate.instant($scope.inputHint + '.hint');
  //                 if ($scope.hint == $scope.inputHint + '.hint') {
  //                     $scope.hint = undefined;
  //                 }
  //             }
  //         }
  //     };
  // })

  .directive('focusMe', function ($timeout) {
    return {
      scope: { trigger: '@focusMe' },
      link: function (scope, element) {
        scope.$watch('trigger', function (value) {
          if (value === 'true') {
            $timeout(function () {
              element[0].focus();
            });
          }
        });
      },
    };
  })

  .directive('inputLabel', function ($translate) {
    return {
      restrict: 'E',
      replace: true,
      template:
        '<label ng-show="label" class="{{!skipControlClass ? \'control-label\' : \'\'}} {{left ? \'\' : \'pull-right\'}}" popover-placement="top" uib-popover="{{hint}}" popover-trigger="mouseenter">{{label | translate:values}} <i class="fa fa-question-circle text-success" ng-show="hint"></i></label>',
      scope: {
        label: '@',
        labelValues: '=?',
        left: '@',
        skipControlClass: '=?',
      },
      controller: function ($scope, $rootScope) {
        $scope.hint = $translate.instant($scope.label + '.hint');
        if ($scope.hint == $scope.label + '.hint') {
          $scope.hint = undefined;
        }
        if ($scope.labelValues) {
          $scope.values = JSON.stringify($scope.labelValues);
        } else {
          $scope.values = '{}';
        }
      },
    };
  })

  // .directive("inputLine", function(){
  //     return {
  //         restrict: 'E',
  //         replace: true,
  //         template: '<div class="form-group form-group-sm"><div class="col-xs-12">' +
  //             '<label class="{{lineHeight}}" ng-click="changeState()" ng-show="!isEdited">{{labelText}}</label>' +
  //             '<input type="text" class="{{lineHeight}}" ng-show="isEdited" ng-model="model" placeholder="placeholder" style="width: 100%;" ng-enter="blur()">' +
  //             '</div></div>',
  //         scope: {
  //             lineHeight: '@',
  //             placeholder: '@',
  //             model: '='
  //         },
  //         controller: function ($scope, _) {
  //             $scope.model = $scope.model || '';
  //             $scope.updateLabel = function () {
  //                 if($scope.model != '') {
  //                     $scope.labelText = $scope.model;
  //                 } else {
  //                     $scope.labelText = $scope.placeholder;
  //                 }
  //             };
  //
  //             $scope.changeState = function () {
  //                 $scope.updateLabel();
  //                 $scope.isEdited = !$scope.isEdited;
  //             };
  //
  //             $scope.blur = function(model){
  //                 $scope.changeState();
  //             };
  //
  //             $scope.updateLabel();
  //         },
  //         link: function ($scope, $element, $attrs, ctrl) {
  //         }
  //     }
  // })

  .directive('inputSwitch', function ($translate) {
    return {
      restrict: 'E',
      replace: true,
      template:
        '<div class="row form-group" >' +
        // '<label ng-if="label" class="col-xs-3 control-label" popover-placement="top" uib-popover="{{hint}}" popover-trigger="mouseenter">{{label | translate}} <i class="fa fa-question-circle text-success" ng-show="hint"></i></label>' +
        '<div class="col-md-3 col-xs-9"><input-label label="{{label}}"></input-label></div>' +
        "<div ng-class=\"{'col-md-12 col-xs-12': !label, 'col-md-9 col-xs-3': label, 'text-success': model, 'text-muted': !model }\">" +
        '    <i ng-click="change()" ng-if="!disabled" class="fa " ng-class="{\'fa-2x\': size != \'small\', \'fa-toggle-on\': model, \'fa-toggle-off\': !model}"></i>' +
        "    <i ng-if=\"disabled\" class=\"fa \" ng-class=\"{'fa-2x': size != 'small', 'fa-toggle-on': model, 'fa-toggle-off': !model}\"></i>" +
        '</div></div>',
      scope: {
        model: '=',
        label: '@',
        disabled: '=',
        readonly: '=',
        size: '@',
      },

      controller: function ($scope, $http) {
        $scope.hint = $translate.instant($scope.label + '.hint');
        if ($scope.hint == $scope.label + '.hint') {
          $scope.hint = undefined;
        }
        $scope.change = function () {
          if (!$scope.disabled && !$scope.readonly) {
            $scope.model = !$scope.model;
          }
        };
      },

      link: function ($scope, $element, $attrs, ctrl) {},
    };
  })

  .directive('inputRow', function ($translate) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      template:
        '<div class="form-group form-group-sm">' +
        '<div class="col-xs-3" ng-class="{\'disabled\': disabled}"><input-label label="{{label}}"></input-label></div>' +
        "<div ng-class=\"{'col-xs-12': !label, 'col-xs-9': label }\">" +
        '    <ng-transclude></ng-transclude>' +
        '</div></div>',
      scope: {
        label: '@',
        disabled: '=?',
      },
    };
  })

  .directive('inputText', function () {
    return {
      restrict: 'E',
      replace: true,
      template:
        '<div class="form-group form-group-sm">' +
        // '<label ng-show="label" class="col-xs-3 control-label">{{label | translate}} <i class="fa fa-question-circle text-success" ng-show="hint"></i></label>' +
        '<div class="col-xs-3" ng-show="label" ><input-label label="{{label}}"></input-label></div>' +
        "<div ng-class=\"{'has-error': hasError && touched, 'col-xs-12': !label, 'col-xs-9': label }\">" +
        '    <label ng-show="readonly" class="form-control no-border" style="overflow:hidden">{{model}}</label>' +
        '    <div class="input-group" style="width: 100%;">' +
        '        <span ng-if="icon" class="input-group-addon"><i class="fa fa-{{icon}}"></i></span>' +
        '        <input ng-hide="readonly" type="{{type}}" size="{{size}}" width="{{width}}" maxlength="{{maxLength}}" ng-model="model" class="form-control" ng-trim="trim" ng-required="required" ng-disabled="disabled" placeholder="{{placeholder | translate}}" ng-blur="blur(model)">' +
        '    </div>' +
        '</div></div>',
      scope: {
        form: '=',
        model: '=',
        label: '@',
        required: '=',
        trim: '=',
        disabled: '=',
        readonly: '=',
        minValue: '=?',
        maxValue: '=?',
        maxLength: '=?',
        size: '@',
        type: '@',
        validation: '=?',
        width: '=',
        placeholder: '@',
        change: '=',
        blur: '=',
        icon: '@',
      },

      controller: function ($scope, _) {
        if (!$scope.type) {
          $scope.type = 'text';
        } else if ($scope.type == 'code') {
          $scope.type = 'text';
          if (!$scope.validation) {
            $scope.validation = function (value) {
              return /^[a-zA-Z][a-zA-Z0-9-_]*$/.test(value);
            };
          }
        } else if ($scope.type == 'email') {
          $scope.type = 'text';
          if (!$scope.validation) {
            $scope.validation = function (value) {
              return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,5}$/.test(value);
            };
          }
        }
        $scope.maxLength = $scope.maxLength || Number.MAX_SAFE_INTEGER;
        // $scope.hint = $translate.instant($scope.label + '.hint');
        // if ($scope.hint == $scope.label + '.hint') $scope.hint = undefined;
      },

      link: function ($scope, $element, $attrs, ctrl) {
        $scope.$watch('model', function (input, old) {
          $scope.hasError = false;
          $scope.touched = false;
          if (input != old) {
            $scope.touched = true;
            if ($scope.change) {
              $scope.change(input);
            }
          }
          if ($scope.required && (input === undefined || input === '') && $scope.touched) {
            $scope.hasError = true;
          } else if (
            $scope.type == 'number' &&
            ($scope.minValue > input || $scope.maxValue < input) &&
            $scope.touched
          ) {
            $scope.hasError = true;
          } else if ($scope.validation && !$scope.validation(input) && $scope.touched) {
            $scope.hasError = true;
          }
          if (input != old) {
            $scope.touched = true;
            if ($scope.change) {
              $scope.change(input);
            }
          }
        });
        $scope.$watch('required', function (required) {
          $scope.hasError = false;
          if ($scope.required && ($scope.model === undefined || $scope.model === '')) {
            $scope.hasError = true;
          } else if (
            $scope.type == 'number' &&
            ($scope.minValue > $scope.model || $scope.maxValue < $scope.model)
          ) {
            $scope.hasError = true;
          } else if ($scope.validation && !$scope.validation($scope.model)) {
            $scope.hasError = true;
          }
        });
      },
    };
  })

  .directive('inputProgress', function ($translate) {
    return {
      restrict: 'E',
      replace: true,
      template:
        '<div class="row form-group-sm">' +
        '<div class="col-xs-3"><input-label label="{{label}}"></input-label></div>' +
        "<div ng-class=\"{'has-error': hasError, 'col-xs-12': !label, 'col-xs-9': label }\">" +
        '    <progressbar class="progress" ng-show="readonly" type="{{model <= 99 ? \'danger\': \'success\'}}"' +
        '       value="model || 0">{{model}}</progressbar>' +
        //'    <label ng-show="readonly" class="form-control no-border" style="overflow:hidden">{{model}}</label>' +
        '    <input ng-hide="readonly" type="{{type}}" size="{{size}}" min="{{minValue}}" max="{{maxValue}}" width="{{width}}" ng-model="model" class="form-control" ng-required="required" ng-disabled="disabled" placeholder="{{placeholder | translate}}" ng-blur="blur(model)">' +
        '</div></div>',
      scope: {
        form: '=',
        model: '=',
        label: '@',
        required: '=',
        disabled: '=',
        readonly: '=',
        minValue: '@',
        maxValue: '@',
        size: '@',
        type: '@',
        validation: '=?',
        width: '=',
        placeholder: '@',
        change: '=',
        blur: '=',
      },

      controller: function ($scope, $http) {
        if (!$scope.type) {
          $scope.type = 'text';
        } else if ($scope.type == 'code') {
          $scope.type = 'text';
          if (!$scope.validation) {
            $scope.validation = function (value) {
              return /^[a-zA-Z][a-zA-Z0-9-_]*$/.test(value);
            };
          }
        }
        // $scope.hint = $translate.instant($scope.label + '.hint');
        // if ($scope.hint == $scope.label + '.hint') $scope.hint = undefined;
      },

      link: function ($scope, $element, $attrs, ctrl) {
        $scope.$watch('model', function (input, old) {
          $scope.hasError = false;
          if ($scope.required && (input === undefined || input === '')) {
            $scope.hasError = true;
          } else if (
            $scope.type == 'number' &&
            ($scope.minValue > input || $scope.maxValue < input)
          ) {
            $scope.hasError = true;
          } else if ($scope.validation && !$scope.validation(input)) {
            $scope.hasError = true;
          }
          if ($scope.change && input != old) {
            $scope.change(input);
          }
        });
        $scope.$watch('required', function (required) {
          $scope.hasError = false;
          if ($scope.required && ($scope.model === undefined || $scope.model === '')) {
            $scope.hasError = true;
          } else if (
            $scope.type == 'number' &&
            ($scope.minValue > $scope.model || $scope.maxValue < $scope.model)
          ) {
            $scope.hasError = true;
          } else if ($scope.validation && !$scope.validation($scope.model)) {
            $scope.hasError = true;
          }
        });
      },
    };
  })

  .directive('inputSearch', function ($translate) {
    return {
      restrict: 'E',
      replace: true,
      template:
        '<p class="input-group">' +
        '  <input type="text" class="form-control" ng-model="model" placeholder="{{placeholder | translate}}">' +
        '  <span class="fa fa-2x fa-search form-control-feedback" style="top: 5px;" ng-style="getSearchStyle()"></span>' +
        '  <span class="input-group-btn">' +
        // '    <span class="btn btn-default disabled"><i class="fa fa-search"></i></span>' +
        '    <button type="button" class="btn btn-default" ng-if="clear" ng-click="clear()"><i class="fa fa-times"></i></button>' +
        '  </span>' +
        '</p>',
      scope: {
        model: '=',
        placeholder: '@',
        clear: '=?',
        allowClear: '=?',
      },

      controller: function ($scope, $http) {
        $scope.getSearchStyle = function () {
          return $scope.clear ? { right: '35px' } : { right: '5px' };
        };
        if ($scope.allowClear && !$scope.clear) {
          $scope.clear = function () {
            $scope.model = '';
          };
        }
      },
    };
  })

  .directive('inputTextField', function ($compile) {
    return {
      restrict: 'E',
      template:
        '<div class="col-xs-12"  ng-class="{\'has-error\': hasError}">' +
        '    <p ng-show="readonly">{{model}}</p>' +
        '    <input ng-hide="readonly" type="{{type}}" ng-model="model" class="form-control" ng-required="required">' +
        '</div>',
      scope: {
        model: '=model',
        required: '=required',
        readonly: '=readonly',
        type: '@',
      },

      controller: function ($scope, $http) {
        if (!$scope.type) $scope.type = 'text';
      },

      link: function ($scope, $element, $attrs, ctrl) {
        $scope.$watch('model', function (input) {
          $scope.hasError = /*$scope.form.$dirty &&*/ $scope.required && !input;
        });
      },
    };
  })

  .directive('inputTextarea', function ($translate) {
    return {
      restrict: 'E',
      template:
        '<div class="row form-group-sm" >' +
        // '<label ng-show="label" class="col-xs-3 control-label" popover-placement="top" uib-popover="{{hint}}" popover-trigger="mouseenter">{{label | translate}} <i class="fa fa-question-circle text-success" ng-show="hint"></i></label>' +
        '<div class="col-xs-3"><input-label label="{{label}}"></input-label></div>' +
        "<div ng-class=\"{'has-error': hasError, 'col-xs-12': !label, 'col-xs-9': label }\">" +
        '    <textarea type="{{type}}" ng-model="model" class="form-control" ng-required="required" style="{{style}}" />' +
        '</div></div>',
      scope: {
        form: '=form',
        model: '=model',
        label: '@label',
        required: '=required',
        readonly: '=readonly',
        type: '@',
        style: '@',
      },

      controller: function ($scope, $http) {
        if (!$scope.type) $scope.type = 'text';
        // $scope.hint = $translate.instant($scope.label + '.hint');
        // if ($scope.hint == $scope.label + '.hint') $scope.hint = undefined;
      },

      link: function ($scope, element, attrs, ctrl) {
        $scope.$watch('model', function (input) {
          $scope.hasError = /*$scope.form.$dirty &&*/ $scope.required && !input;
        });
      },
    };
  })

  .directive('inputRichtext', function ($sce, uuid) {
    return {
      restrict: 'E',
      template:
        '<div ng-mouseenter="options.hover = true" ng-mouseleave="options.hover = false">' +
        '  <div ng-if="options.isEdit">' +
        '    <textarea ng-model="model[field]" froala id="froala-{{id}}" froala-save="save"></textarea>' +
        '  </div>' +
        '  <i class="fa fa-edit fa-2x text-muted no-print pull-right" translate-title="button.edit" ng-if="options.hover && options.showEdit && !options.isEdit" ' +
        '    ng-click="clickEdit()" style="position: absolute;right: 30px;z-index: 1034;"></i>' +
        '  <p ng-bind-html="trustedHtml || \'<h5>\' + placeholder + \'</h5>\'" ng-if="!options.isEdit" ng-click="clickEdit()" style="min-height: 30px"' +
        '    popover-placement="top" uib-popover="{{placeholder}}" popover-trigger="mouseenter"></p>' +
        '</div>',
      scope: {
        model: '=',
        field: '@',
        trustedHtml: '=?',
        _placeholder: '@placeholder',
        id: '@?',
        onSave: '=?',
        showEdit: '=?',
      },
      link: function ($scope) {
        $scope.options = { isEdit: false, showEdit: !!$scope.showEdit };
        if (!$scope.id) {
          $scope.id = uuid.v4();
        }
        $scope.trustedHtml = $sce.trustAsHtml($scope.model[$scope.field]);
      },
      controller: function ($scope, $translate) {
        $scope.options = $scope.options || { isEdit: false };
        $scope.placeholder = $translate.instant($scope._placeholder);
        if ($scope.placeholder === $scope._placeholder) {
          $scope.placeholder = '';
        }

        $scope.clickEdit = function () {
          $scope.options.isEdit = true;
        };

        // $scope.$watch($scope.model[$scope.field], function (newValue, oldValue) {
        //     if (newValue !== oldValue){
        //         $scope.trustedHtml = $sce.trustAsHtml($scope.model[$scope.field]);
        //     }
        // });

        $scope.save = function () {
          $scope.options.isEdit = false;
          var u1 = $scope.trustedHtml ? $scope.trustedHtml.$$unwrapTrustedValue() : '';
          var u2 = $sce.trustAsHtml($scope.model[$scope.field])
            ? $sce.trustAsHtml($scope.model[$scope.field]).$$unwrapTrustedValue()
            : '';
          if (u1 !== u2) {
            if ($scope.onSave) {
              $scope.onSave();
            }
            $scope.trustedHtml = $sce.trustAsHtml($scope.model[$scope.field]);
          }
        };
      },
    };
  })

  .directive('inputRichtextMinified', function ($sce, froalaConfig) {
    return {
      restrict: 'E',
      template: '<textarea id="froala-notes"></textarea>',
      scope: {
        model: '=',
        field: '@',
        notes: '=?',
        onSave: '=?',
      },
      link: function ($scope) {
        var initialNotesValue = $sce.trustAsHtml($scope.model[$scope.field]);
        $scope.notes = initialNotesValue ? initialNotesValue.$$unwrapTrustedValue() : '';
      },
      controller: function ($scope) {
        $scope.froalaEditor = new FroalaEditor(
          'textarea#froala-notes',
          {
            key: froalaConfig.key,
            attribution: false,
            charCounterCount: false,
            embedlyKey: froalaConfig.embedlyKey,
            embedlyScriptPath: froalaConfig.embedlyScriptPath,
            toolbarInline: false,
            listAdvancedTypes: true,
            quickInsertButtons: [],
            toolbarButtons: [
              'bold',
              'italic',
              'underline',
              'strikeThrough',
              'clearFormatting',
              '|',
              'align',
              'indent',
              'outdent',
              'formatUL',
              'formatOL',
            ],
            events: {
              blur: function () {
                $scope.saveNotes();
              },
            },
          },
          function () {
            // Load notes
            $scope.froalaEditor.html.set($scope.notes);
          },
        );

        $scope.saveNotes = function () {
          var newNotes = $scope.froalaEditor.html.get();
          if (newNotes !== $scope.notes) {
            if ($scope.onSave) {
              $scope.onSave(newNotes);
            }
            $scope.notes = newNotes;
          }
        };
      },
    };
  })

  .directive('inputRichtextPrint', function ($sce) {
    return {
      restrict: 'E',
      template: '<p ng-bind-html="trustedHtml || \'\'" style="min-height: 30px"></p>',
      scope: {
        model: '=',
        field: '@',
      },
      link: function ($scope) {
        $scope.trustedHtml = $sce.trustAsHtml($scope.model[$scope.field]);
      },
    };
  })

  .directive('inputList', function ($translate) {
    return {
      restrict: 'E',
      template:
        '<div class="form-group form-group-sm" >' +
        // '<label ng-show="label" class="col-xs-3 control-label" popover-placement="top" uib-popover="{{hint}}" popover-trigger="mouseenter">{{label | translate}} <i class="fa fa-question-circle text-success" ng-show="hint"></i></label>' +
        '<div class="col-xs-3"><input-label label="{{label}}"></input-label></div>' +
        "<div ng-class=\"{'has-error': hasError, 'col-xs-12': !label, 'col-xs-9': label }\">" +
        '    <select class="form-control" ng-model="model" name="{{name}}" ng-required="required" ng-change="onChange({ value: model })"' +
        '            ng-options="item as (_exp(item)|translate) for item in listValues">' +
        '        <option ng-if="empty" value="">{{empty | translate}}</option>' +
        '    </select>' +
        '</div></div>',
      scope: {
        form: '=form',
        model: '=model',
        label: '@label',
        required: '=required',
        list: '@list',
        empty: '@',
        allowEmpty: '@',
        onChange: '&?',
        change: '=',
        modelParent: '=',
      },
      controller: function ($scope, $http) {
        // $scope.hint = $translate.instant($scope.label + '.hint');
        // if ($scope.hint == $scope.label + '.hint') $scope.hint = undefined;
        $http
          .get('/api/lists/' + $scope.list)
          .success(function (result) {
            $scope.listValues = result;
            if ($scope.allowEmpty) {
              $scope.listValues.unshift(null);
            }
          })
          .error(function (error) {
            console.error(error);
          });
        $scope._exp = function (item) {
          return item ? $scope.list + '.' + item : '';
        };
      },
      link: function ($scope, $element, $attr) {
        $scope.$watch('model', function (input, old) {
          $scope.hasError = /*$scope.form.$dirty &&*/ $scope.required && !input;
          if ($scope.change && input != old) {
            $scope.change($scope.parent || input);
          }
        });
      },
    };
  })

  //.directive("inputHttpList", function ($translate) {
  //    return {
  //        restrict: 'E',
  //        template: '<div class="row form-group" >' +
  //        '<label ng-show="label" class="col-xs-3 control-label" popover-placement="top" uib-popover="{{hint}}" popover-trigger="mouseenter">{{label | translate}} <i class="fa fa-question-circle text-success" ng-show="hint"></i></label>' +
  //        '<div ng-class="{\'has-error\': hasError, \'col-xs-12\': !label, \'col-xs-9\': label }">' +
  //        '    <select class="form-control" ng-model="model" name="{{name}}" ng-required="required">' +
  //        '        <option ng-repeat="item in listValues" value="{{item[valuename]}}" ng-selected="{{item == model}}">{{item[labelname]}}</option> ' +
  //        '    </select>' +
  //        '</div></div>',
  //        scope: {
  //            model: "=model",
  //            label: "@label",
  //            required: "=required",
  //            url: "@url",
  //            labelname: "@labelname",
  //            valuename: "@valuename"
  //        },
  //        controller: function ($scope, $http) {
  //            $scope.hint = $translate.instant($scope.label + '.hint');
  //            if ($scope.hint == $scope.label + '.hint') $scope.hint = undefined;
  //            $http.get(url)
  //                .success(function (result) {
  //                    $scope.listValues = result;
  //                })
  //                .error(function (error) {
  //                    console.error(error);
  //                });
  //        },
  //        link: function ($scope, $element, $attr) {
  //            $scope.$watch('model', function (input) {
  //                $scope.hasError = /*$scope.form.$dirty &&*/ $scope.required && !input;
  //            });
  //        }
  //    }
  //})

  .directive('inputValues', function ($translate) {
    return {
      restrict: 'E',
      template:
        '<div class="form-group form-group-sm" >' +
        // '<label ng-show="label" class="col-xs-3 control-label" popover-placement="top" uib-popover="{{hint}}" popover-trigger="mouseenter">{{label | translate}} <i class="fa fa-question-circle text-success" ng-show="hint"></i></label>' +
        '<div class="col-xs-3"><input-label label="{{label}}"></input-label></div>' +
        "<div ng-class=\"{'has-error': hasError, 'col-xs-12': !label, 'col-xs-9': label }\">" +
        '    <select class="form-control" ng-model="model" name="{{name}}" ng-required="required" ng-disabled="disabled">' +
        '        <option ng-if="empty" value="" selected="selected" disabled="disabled">{{empty | translate}}</option>' +
        '        <option ng-repeat="item in values" value="{{item}}" translate="{{(listcode && item ? listcode + \'.\' : \'\') + item}}" ng-selected="{{item == model}}"> ' +
        '    </select>' +
        '</div></div>',
      scope: {
        form: '=form',
        model: '=model',
        label: '@label',
        required: '=required',
        disabled: '=disabled',
        values: '=values',
        listcode: '@listcode',
        change: '=',
        changeModel: '=',
        empty: '@',
      },
      controller: function ($scope) {
        // $scope.hint = $translate.instant($scope.label + '.hint');
        // if ($scope.hint == $scope.label + '.hint') $scope.hint = undefined;
        if (!$scope.values) {
          $scope.values = [];
        }
      },
      link: function ($scope, $element, $attr) {
        $scope.$watch('model', function (input, old) {
          $scope.hasError = /*$scope.form.$dirty &&*/ $scope.required && !input;
          if ($scope.change && input != old) {
            $scope.change($scope.changeModel || input);
          }
        });
        $scope.$watch('required', function (required) {
          $scope.hasError = false;
          if ($scope.required && ($scope.model === undefined || $scope.model === '')) {
            $scope.hasError = true;
          } else if (
            $scope.type == 'number' &&
            ($scope.minValue > $scope.model || $scope.maxValue < $scope.model)
          ) {
            $scope.hasError = true;
          } else if ($scope.validation && !$scope.validation($scope.model)) {
            $scope.hasError = true;
          }
        });
      },
    };
  })

  .directive('inputListvalues', function () {
    return {
      restrict: 'E',
      template:
        '<div class="form-group form-group-sm" >' +
        // '<label ng-show="label" class="col-xs-3 control-label">{{label | translate}} <i class="fa fa-question-circle text-success" ng-show="hint"></i></label>' +
        '<div class="col-xs-3"><input-label label="{{label}}"></input-label></div>' +
        '<div ng-class="{\'has-error\': hasError, \'col-xs-12\': !label, \'col-xs-9\': label }"  popover-placement="top" uib-popover="{{hint}}" popover-trigger="mouseenter">' +
        '    <select class="form-control" ng-model="model" name="{{name}}" ng-required="required"' +
        '            ng-options="item[keyname] as (_exp(item)|translate) for item in (values | filter:itemFilter)">' +
        '        <option ng-if="empty" value="">{{empty | translate}}</option>' +
        '    </select>' +
        '</div></div>',
      scope: {
        form: '=form',
        model: '=model',
        label: '@label',
        required: '=required',
        values: '=values',
        keyname: '@keyname',
        valuename: '@valuename',
        exp: '=exp',
        empty: '@',
        allowEmpty: '@',
        filter: '=?',
      },
      controller: function ($scope) {
        // $scope.hint = $translate.instant($scope.label + '.hint');
        // if ($scope.hint == $scope.label + '.hint') {
        //     $scope.hint = undefined;
        // }
        $scope.keyname = $scope.keyname || 'id';
        $scope.valuename = $scope.valuename || 'name';
        $scope.itemFilter =
          $scope.filter ||
          function (o) {
            return true;
          };

        if (!$scope.values) {
          $scope.values = [];
        }
        if ($scope.allowEmpty) {
          $scope.values.unshift({ id: null, name: '' });
        }
        if (!$scope.exp) {
          if ($scope.valuename) {
            $scope._exp = function (item) {
              return item ? item[$scope.valuename] : '';
            };
          } else {
            $scope._exp = function (item) {
              return item ? JSON.stringify(item) : '';
            };
          }
        } else {
          $scope._exp = $scope.exp;
        }
      },
      link: function (scope) {
        scope.$watch('model', function (input) {
          scope.hasError = /*$scope.form.$dirty &&*/ scope.required && !input;
        });
      },
    };
  })

  .directive('inputNumbers', function ($translate) {
    return {
      restrict: 'E',
      template:
        '<div class="row form-group-sm" >' +
        // '<label ng-show="label" class="col-xs-3 control-label" popover-placement="top" uib-popover="{{hint}}" popover-trigger="mouseenter">{{label | translate}} <i class="fa fa-question-circle text-success" ng-show="hint"></i></label>' +
        '<div class="col-xs-3"><input-label label="{{label}}"></input-label></div>' +
        "<div ng-class=\"{'has-error': hasError, 'col-xs-12': !label, 'col-xs-9': label }\">" +
        '    <select class="form-control" ng-model="model" name="{{name}}" ng-required="required" ng-disabled="disabled" convert-to-number>' +
        '        <option ng-repeat="item in values" value="{{item}}" ng-selected="{{model == item}}">{{item}}</option> ' +
        '    </select>' +
        '</div></div>',
      scope: {
        model: '=model',
        label: '@label',
        required: '=required',
        disabled: '=disabled',
        values: '=values',
      },
      controller: function ($scope, $http) {
        // $scope.hint = $translate.instant($scope.label + '.hint');
        // if ($scope.hint == $scope.label + '.hint') $scope.hint = undefined;
        if (!$scope.values) {
          $scope.values = [];
        }
      },
      link: function ($scope, $element, $attr) {
        $scope.$watch('model', function (input) {
          $scope.hasError = /*$scope.form.$dirty &&*/ $scope.required && !input;
        });
      },
    };
  })

  // .directive("inputMultiselect", function ($translate) {
  //     return {
  //         restrict: 'E',
  //         template: '<div class="row form-group-sm" >' +
  //         // '<label ng-show="label" class="col-xs-3 control-label" popover-placement="top" uib-popover="{{hint}}" popover-trigger="mouseenter">{{label | translate}} <i class="fa fa-question-circle text-success" ng-show="hint"></i></label>' +
  //         '<div class="col-xs-3"><input-label label="{{label}}"></input-label></div>' +
  //         '<div ng-class="{\'has-error\': hasError, \'col-xs-12\': !label, \'col-xs-9\': label }">' +
  //         '    <div isteven-multi-select input-model="list" output-model="model" button-label="{{labels}}" item-label="{{labels}}" tick-property="ticked" width="100%" selection-mode="selectionMode" translation="multiselectLabels"></div>' +
  //         '</div></div>',
  //         scope: {
  //             model: "=",
  //             label: "@",
  //             required: "=",
  //             list: "=",
  //             empty: "@",
  //             labels: "@",
  //             selectionMode: "@"
  //         },
  //         controller: function ($scope, $http) {
  //             // $scope.hint = $translate.instant($scope.label + '.hint');
  //             // if ($scope.hint == $scope.label + '.hint') {
  //             //     $scope.hint = undefined;
  //             // }
  //             $scope.labels = $scope.labels || 'name';
  //             $scope.selectionMode = $scope.selectionMode || 'multi';
  //             $scope.multiselectLabels = {
  //                 selectAll: $translate.instant('label.select.all'),
  //                 selectNone: $translate.instant('label.select.none'),
  //                 reset: $translate.instant('label.select.undoAll'),
  //                 search: $translate.instant('label.select.search'),
  //                 nothingSelected: $translate.instant('label.select.select')
  //             }
  //         },
  //         link: function ($scope, $element, $attr) {
  //             $scope.$watch('model', function (input) {
  //                 $scope.hasError = /*$scope.form.$dirty &&*/ $scope.required && !input;
  //             });
  //         }
  //     }
  // })
  //
  .directive('inputSelect', function () {
    return {
      restrict: 'E',
      template:
        '<div class="row form-group form-group-sm" style="padding-top: 14px;">' +
        '<div class="col-xs-3"><input-label label="{{label}}"></input-label></div>' +
        "<div ng-class=\"{'has-error': hasError, 'col-xs-12': !label, 'col-xs-9': label }\">" +
        '  <ui-select ng-model="$parent.model" on-select="onSelect($item, $model)" ng-disabled="disabled">' +
        '    <ui-select-match allow-clear="{{allowClear}}">' +
        '      {{$select.selected[nameKey]}}' +
        '    </ui-select-match>' +
        '    <ui-select-choices repeat="item in (values | filter:itemFilter | filter: {\'{{nameKey}}\': $select.search}) track by item[idKey]" ng-class="{hide: $select.search.length < searchMin }"' +
        '        group-by="groupBy" group-filter="groupFilter" >' +
        '      <span ng-bind="item[nameKey]"></span>' +
        '    </ui-select-choices>' +
        '  </ui-select>' +
        '</div>' +
        '</div>',
      scope: {
        label: '@',
        model: '=',
        values: '=?',
        required: '=',
        searchMin: '=?',
        onSelect: '=?',
        allowClear: '@?',
        type: '@',
        disabled: '=?',
        idKey: '@',
        nameKey: '@',
        groupBy: '=?',
        groupFilter: '=?',
        filter: '=?',
      },
      controller: function ($scope, _) {
        if (!$scope.searchMin) {
          $scope.searchMin = 0;
        }
        if (!$scope.allowClear) {
          $scope.allowClear = 'true';
        }
        if (!$scope.onSelect) {
          $scope.onSelect = function () {};
        }
        $scope.idKey = $scope.idKey || 'id';
        $scope.nameKey = $scope.nameKey || 'name';
        $scope.itemFilter =
          $scope.filter ||
          function (o) {
            return true;
          };
        $scope.validate = function (input) {
          $scope.hasError = /*$scope.form.$dirty &&*/ $scope.required && !input;
          if (
            $scope.required &&
            ($scope.model === undefined ||
              $scope.model === '' ||
              (_.isArray($scope.model) && $scope.model.length === 0))
          ) {
            $scope.hasError = true;
          } else if (
            $scope.type === 'number' &&
            ($scope.minValue > $scope.model || $scope.maxValue < $scope.model)
          ) {
            $scope.hasError = true;
          } else if ($scope.validation && !$scope.validation($scope.model)) {
            $scope.hasError = true;
          }
        };
      },
      link: function ($scope, $element, $attr) {
        $scope.$watch('model', function (input) {
          $scope.validate(input);
        });
        $scope.$watch('required', function (required) {
          $scope.validate($scope.model);
        });
      },
    };
  })

  .directive('inputSelectRemote', function ($http, _, DialogService) {
    return {
      restrict: 'E',
      template:
        '<div class="row form-group-sm" >' +
        '<div class="col-xs-3"><input-label label="{{label}}"></input-label></div>' +
        "<div ng-class=\"{'has-error': hasError, 'col-xs-12': !label, 'col-xs-9': label }\">" +
        '  <ui-select ng-model="$parent.model" on-select="onSelect($item, $model)" ng-disabled="disabled">' +
        '    <ui-select-match allow-clear="{{allowClear}}">' +
        '      {{$select.selected.name}}' +
        '    </ui-select-match>' +
        '    <ui-select-choices refresh="searchMedia($select)" refresh-delay="300" repeat="item in values track by item.id" ng-class="{hide: $select.search.length < searchMin }">' +
        '      <span ng-bind="item.name"></span>' +
        '    </ui-select-choices>' +
        '  </ui-select>' +
        '</div>' +
        '</div>',
      scope: {
        label: '@',
        model: '=',
        required: '=',
        searchUrl: '@',
        searchMin: '=?',
        onSelect: '=?',
        allowClear: '@?',
        type: '@',
        disabled: '=?',
      },
      controller: function ($scope, _) {
        $scope.values = [];
        if (!$scope.searchMin) {
          $scope.searchMin = 2;
        }
        if (!$scope.allowClear) {
          $scope.allowClear = 'true';
        }
        if (!$scope.onSelect) {
          $scope.onSelect = function () {};
        }
        $scope.searchMedia = function ($select) {
          if ($select.search && $select.search.length >= $scope.searchMin) {
            return $http
              .get($scope.searchUrl.replace(/\[\[search\]\]/, $select.search))
              .success(function (result) {
                $scope.values = result;
              })
              .error(function (error) {
                DialogService.error(error, 'title.error');
              });
          }
        };
        $scope.validate = function (input) {
          $scope.hasError = /*$scope.form.$dirty &&*/ $scope.required && !input;
          if (
            $scope.required &&
            ($scope.model === undefined ||
              $scope.model === '' ||
              (_.isArray($scope.model) && $scope.model.length === 0))
          ) {
            $scope.hasError = true;
          } else if (
            $scope.type == 'number' &&
            ($scope.minValue > $scope.model || $scope.maxValue < $scope.model)
          ) {
            $scope.hasError = true;
          } else if ($scope.validation && !$scope.validation($scope.model)) {
            $scope.hasError = true;
          }
        };
      },
      link: function ($scope, $element, $attr) {
        $scope.$watch('model', function (input) {
          $scope.validate(input);
        });
        $scope.$watch('required', function (required) {
          $scope.validate($scope.model);
        });
      },
    };
  })

  .directive('inputSelectId', function () {
    return {
      restrict: 'E',
      replace: true,
      template:
        '<div class="form-group form-group-sm" >' +
        '<div class="col-xs-3"><input-label label="{{label}}"></input-label></div>' +
        "<div ng-class=\"{'col-xs-12': !label, 'col-xs-9': label }\">" +
        '  <ui-select ng-model="$parent.model" on-select="onSelect($item, $model)" ng-disabled="disabled" ng-class="{\'has-error\': $parent.hasError}">' +
        '    <ui-select-match allow-clear="{{allowClear}}" placeholder="{{placeholder| translate}}">' +
        '      {{$select.selected[nameKey]}}' +
        '    </ui-select-match>' +
        '    <ui-select-choices repeat="item[idKey] as item in (values | filter:itemFilter | filter: {\'{{nameKey}}\': $select.search}) track by item[idKey]" ng-class="{hide: $select.search.length < searchMin }">' +
        '      <div ng-if="imageKey" back-img="{{item[imageKey]}}" style="display: inline-block; width: 25px; height: 25px; vertical-align: middle;" class="img-circle margin-1x-right"></div>' +
        '      <span ng-bind="item[nameKey]"></span>' +
        '    </ui-select-choices>' +
        '  </ui-select>' +
        '</div>' +
        '</div>',
      scope: {
        label: '@',
        model: '=',
        values: '=',
        required: '=',
        searchMin: '=?',
        onSelect: '=?',
        allowClear: '@?',
        type: '@',
        disabled: '=?',
        idKey: '@',
        nameKey: '@',
        imageKey: '@',
        filter: '=?',
        placeholder: '@?',
      },
      controller: function ($scope, _) {
        if (!$scope.searchMin) {
          $scope.searchMin = 0;
        }
        if (!$scope.allowClear) {
          $scope.allowClear = 'true';
        }
        if (!$scope.onSelect) {
          $scope.onSelect = function () {};
        }
        $scope.idKey = $scope.idKey || 'id';
        $scope.nameKey = $scope.nameKey || 'name';
        $scope.itemFilter =
          $scope.filter ||
          function (o) {
            return true;
          };
        $scope.validate = function (input) {
          $scope.hasError = false; ///*$scope.form.$dirty &&*/ $scope.required && !input;
          if (
            $scope.required &&
            ($scope.model === undefined ||
              $scope.model === '' ||
              $scope.model === null ||
              (_.isArray($scope.model) && $scope.model.length === 0))
          ) {
            $scope.hasError = true;
          } else if (
            $scope.type === 'number' &&
            ($scope.minValue > $scope.model || $scope.maxValue < $scope.model)
          ) {
            $scope.hasError = true;
          } else if ($scope.validation && !$scope.validation($scope.model)) {
            $scope.hasError = true;
          }
        };
      },
      link: function ($scope, $element, $attr) {
        $scope.$watch('model', function (input) {
          $scope.validate(input);
        });
        $scope.$watch('required', function (required) {
          $scope.validate($scope.model);
        });
      },
    };
  })

  .directive('inputMultiselect', function () {
    return {
      restrict: 'E',
      template:
        '<div class="row form-group-sm" >' +
        '<div class="col-xs-3"><input-label label="{{label}}"></input-label></div>' +
        "<div ng-class=\"{'has-error': hasError, 'col-xs-12': !label, 'col-xs-9': label }\" >" +
        '  <ui-select multiple ng-model="$parent.model" on-select="onSelect($item, $model)" style="min-height:36px !important;" ng-disabled="disabled">' +
        '    <ui-select-match>' +
        '      {{$item.name}}' +
        '    </ui-select-match>' +
        '    <ui-select-choices repeat="item in (values | filter: $select.search) track by item.id" ng-class="{hide: $select.search.length < searchMin }">' +
        '      <span ng-bind="item.name"></span>' +
        '    </ui-select-choices>' +
        '  </ui-select>' +
        '</div>' +
        '</div>',
      scope: {
        label: '@',
        model: '=',
        values: '=',
        required: '=',
        searchMin: '=?',
        onSelect: '=?',
        allowClear: '@?',
        type: '@',
        disabled: '=?',
      },
      controller: function ($scope, _) {
        if (!$scope.searchMin) {
          $scope.searchMin = 0;
        }
        if (!$scope.allowClear) {
          $scope.allowClear = 'true';
        }
        if (!$scope.onSelect) {
          $scope.onSelect = function () {};
        }
        $scope.validate = function (input) {
          $scope.hasError = /*$scope.form.$dirty &&*/ $scope.required && !input;
          if (
            $scope.required &&
            ($scope.model === undefined ||
              $scope.model === '' ||
              (_.isArray($scope.model) && $scope.model.length === 0))
          ) {
            $scope.hasError = true;
          } else if (
            $scope.type === 'number' &&
            ($scope.minValue > $scope.model || $scope.maxValue < $scope.model)
          ) {
            $scope.hasError = true;
          } else if ($scope.validation && !$scope.validation($scope.model)) {
            $scope.hasError = true;
          }
        };
      },
      link: function ($scope, $element, $attr) {
        $scope.$watch('model', function (input) {
          $scope.validate(input);
        });
        $scope.$watch('required', function (required) {
          $scope.validate($scope.model);
        });
      },
    };
  })

  .directive('inputMultiselectId', function () {
    return {
      restrict: 'E',
      template:
        '<div class="row form-group-sm" >' +
        '<div class="col-xs-3"><input-label label="{{label}}"></input-label></div>' +
        "<div ng-class=\"{'has-error': hasError, 'col-xs-12': !label, 'col-xs-9': label }\" >" +
        '  <ui-select multiple ng-model="$parent.model" on-select="onSelect($item, $model)" style="min-height:36px !important;" ng-disabled="disabled">' +
        '    <ui-select-match placeholder="{{placeholder | translate}}">' +
        '      {{$item[nameKey]}}' +
        '    </ui-select-match>' +
        '    <ui-select-choices repeat="item[idKey] as item in (values | filter: {\'{{nameKey}}\': $select.search}) track by item[idKey]" ng-class="{hide: $select.search.length < searchMin }">' +
        '      <span ng-bind="item[nameKey]"></span>' +
        '    </ui-select-choices>' +
        '  </ui-select>' +
        '</div>' +
        '</div>',
      scope: {
        label: '@',
        model: '=',
        values: '=',
        required: '=',
        searchMin: '=?',
        onSelect: '=?',
        allowClear: '@?',
        type: '@',
        disabled: '=?',
        idKey: '@',
        nameKey: '@',
        placeholder: '@?',
      },
      controller: function ($scope, _) {
        if (!$scope.searchMin) {
          $scope.searchMin = 0;
        }
        if (!$scope.allowClear) {
          $scope.allowClear = 'true';
        }
        if (!$scope.onSelect) {
          $scope.onSelect = function () {};
        }
        $scope.idKey = $scope.idKey || 'id';
        $scope.nameKey = $scope.nameKey || 'name';
        $scope.validate = function (input) {
          $scope.hasError = /*$scope.form.$dirty &&*/ $scope.required && !input;
          if (
            $scope.required &&
            ($scope.model === undefined ||
              $scope.model === '' ||
              (_.isArray($scope.model) && $scope.model.length === 0))
          ) {
            $scope.hasError = true;
          } else if (
            $scope.type === 'number' &&
            ($scope.minValue > $scope.model || $scope.maxValue < $scope.model)
          ) {
            $scope.hasError = true;
          } else if ($scope.validation && !$scope.validation($scope.model)) {
            $scope.hasError = true;
          }
        };
      },
      link: function ($scope, $element, $attr) {
        $scope.$watch('model', function (input) {
          $scope.validate(input);
        });
        $scope.$watch('required', function (required) {
          $scope.validate($scope.model);
        });
      },
    };
  })

  .controller(
    'ModalSelectController',
    function ($scope, $modalInstance, _, model, values, required) {
      $scope.filter = {};
      $scope.values = values;
      $scope.selected = _.find(values, { id: model });
      $scope.editedService = { name: '', type: 'onetime' };
      $scope.ok = function () {
        if (required || $scope.selected) {
          $modalInstance.close($scope.selected);
        }
      };
      $scope.cancel = function () {
        $modalInstance.dismiss();
      };
      $scope.select = function (item) {
        if ($scope.selected == item) {
          $scope.selected = undefined;
        } else {
          $scope.selected = item;
          $scope.ok();
        }
      };
      $scope.nameFilter = function (item) {
        return (
          !$scope.filter.name ||
          item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1
        );
      };
      $scope.addNew = function (item) {
        if (item.name != '') {
          $scope.selected = item;
          $scope.ok();
        }
      };
    },
  )

  .factory('ModalSelector', function ($modal) {
    return {
      show: function (model, values, cb, required) {
        var modalInstance = $modal.open({
          templateUrl: '/templates/modal-select.html',
          controller: 'ModalSelectController',
          backdrop: 'static',
          resolve: {
            model: function () {
              return model;
            },
            values: function () {
              return values;
            },
            required: function () {
              return required;
            },
          },
        });

        modalInstance.result.then(
          function (result) {
            cb(result);
          },
          function () {},
        );
      },
    };
  })

  .directive('modalSelect', function ($modal, $translate, _, ModalSelector) {
    return {
      restrict: 'E',
      template:
        '<div class="row form-group-sm" >' +
        '<div class="col-xs-3"><input-label label="{{label}}"></input-label></div>' +
        '<div ng-class="{\'col-xs-12\': !label, \'col-xs-9\': label }" ng-click="open()">' +
        '<div class="form-control" ng-class="{\'has-error\': hasError}" style="padding-right: 30px">' +
        '    <label style="white-space: nowrap; overflow: hidden;">{{getText()}}</label>' +
        '    <span class="fa fa-2x fa-file-text-o form-control-feedback text-muted" style="top: 5px; right: 10px;"></span>' +
        '</div></div></div>',
      scope: {
        label: '@',
        model: '=',
        values: '=',
        required: '=',
        empty: '@',
        labels: '@',
        key: '@',
        placeholder: '@',
      },
      link: function ($scope) {
        $scope.getText = function () {
          var selected = _.find($scope.values, { id: $scope.model });
          return selected ? selected.name : $translate.instant($scope.placeholder);
        };

        $scope.open = function () {
          ModalSelector.show(
            $scope.model,
            $scope.values,
            function (result) {
              $scope.model = result ? result.id : undefined;
            },
            $scope.required,
          );
        };
      },
    };
  })

  .directive('inputDate', function ($translate) {
    return {
      restrict: 'E',
      scope: {
        label: '@label',
        required: '=',
        model: '=',
        dateOptions: '=',
        minDate: '=',
        maxDate: '=',
        format: '@',
        mode: '@',
        placeholder: '@?',
      },
      controller: function ($scope, $http) {
        $scope.opened = false;
        // $scope.hint = $translate.instant($scope.label + '.hint');
        // if ($scope.hint == $scope.label + '.hint') {
        //     $scope.hint = undefined;
        // }
        $scope.options = $scope.dateOptions || {};
        $scope.maxWidthStyle = { 'max-width': '189px' };
        if (!$scope.label && $scope.placeholder) {
          $scope.maxWidthStyle = { 'max-width': '50%' };
        }
      },
      link: function ($scope, $element, $attrs, $controller) {
        $scope.open = function (event) {
          event.preventDefault();
          event.stopPropagation();
          $scope.opened = true;
        };

        $scope.clear = function () {
          $scope.ngModel = null;
        };

        $scope.$watch('model', function (input) {
          $scope.hasError = /*$scope.form.$dirty &&*/ $scope.required && !input;
        });
        $scope.$watch('required', function (required) {
          $scope.hasError = false;
          if ($scope.required && ($scope.model === undefined || $scope.model === '')) {
            $scope.hasError = true;
          } else if ($scope.validation && !$scope.validation($scope.model)) {
            $scope.hasError = true;
          }
        });
      },
      template:
        '<div class="form-group form-group-sm margin-1x-top" >' +
        // '<label ng-show="label" class="col-xs-3 control-label" popover-placement="top" uib-popover="{{hint}}" popover-trigger="mouseenter">{{label | translate}} <i class="fa fa-question-circle text-success" ng-show="hint"></i></label>' +
        '<div class="col-xs-3" ng-show="label"><input-label label="{{label}}"></input-label></div>' +
        "<div ng-class=\"{'has-error': hasError, 'col-xs-12': !label, 'col-xs-9': label }\" ng-style=\"maxWidthStyle\">" +
        '<div class="input-group"><input type="text" class="form-control" placeholder="{{placeholder| translate}}" datepicker-popup="{{format}}" ng-model="model" datepicker-mode="mode" is-open="opened" min-date="minDate" max-date="maxDate" datepicker-options="options" date-disabled="disabled(date, mode)" ng-required="required" close-text="{{\'button.close\' | translate}}" show-button-bar="false" init-date="model"/>' +
        '<span class="input-group-btn">' +
        '<button class="btn btn-sm btn-default" ng-click="open($event)"><i class="fa fa-calendar"></i></button></span></div>' +
        '</div></div>',
    };
  })

  .directive('inputColorpicker', function ($translate) {
    return {
      restrict: 'E',
      scope: {
        label: '@',
        required: '=',
        model: '=',
        type: '@',
      },
      controller: function ($scope, $http) {
        // $scope.hint = $translate.instant($scope.label + '.hint');
        // if ($scope.hint == $scope.label + '.hint') $scope.hint = undefined;
        if (!$scope.type) {
          $scope.type = 'hex';
        }
      },
      link: function ($scope, element, attrs) {
        $scope.$watch('model', function (input) {
          $scope.hasError = /*$scope.form.$dirty &&*/ $scope.required && !input;
        });
      },
      template:
        '<div class="row form-group-sm" >' +
        // '<label ng-show="label" class="col-xs-3 control-label" popover-placement="top" uib-popover="{{hint}}" popover-trigger="mouseenter">{{label | translate}} <i class="fa fa-question-circle text-success" ng-show="hint"></i></label>' +
        '<div class="col-xs-3" ng-if="label"><input-label label="{{label}}"></input-label></div>' +
        "<div ng-class=\"{'has-error': hasError, 'col-xs-9': !label, 'col-xs-6': label }\">" +
        '<input colorpicker="{{type}}" colorpicker-parent="true" type="text" class="form-control" ng-model="model" />' +
        '</div><div class="col-xs-3">' +
        '<span class="form-control" style="background-image: none; background-color: {{model}}">&nbsp;</span>' +
        '</div></div>',
    };
  })

  .directive('inputCheckbox', function ($translate) {
    return {
      restrict: 'E',
      scope: {
        label: '@label',
        labelValues: '=?',
        model: '=',
        disabled: '=',
        fullSize: '=',
      },
      controller: function ($scope) {
        $scope.change = function () {
          if (!$scope.disabled) {
            $scope.model = !$scope.model;
          }
        };
        $scope.hint = $translate.instant($scope.label + '.hint');
        if ($scope.hint == $scope.label + '.hint') {
          $scope.hint = undefined;
        }
      },
      link: function ($scope, element, attrs) {},
      template:
        '<div class="row form-group-sm">' +
        '<div class="col-xs-3" ng-hide="fullSize"></div>' +
        "<div ng-class=\"{'col-xs-9': !fullSize, 'col-xs-12': fullSize}\">" +
        '  <div class="input-checkbox"  ng-click="change()" ng-class="{\'disabled\': disabled}">' +
        '    <i class="fa" ng-class="{\'fa-check-square-o\': model, \'fa-square-o\': !model}"></i>' +
        // '    <label translate="{{label}}"></label> <i class="fa fa-question-circle text-success" ng-show="hint"></i>' +
        '<input-label left="true" label="{{label}}" label-values="labelValues"></input-label>' +
        // '<label class="col-xs-3" popover-placement="top" uib-popover="{{hint}}" popover-trigger="mouseenter">{{label | translate}} <i class="fa fa-question-circle text-success" ng-show="hint"></i></label>' +
        '</div>' +
        '</div></div>',
    };
  })

  .directive('modalForm', function ($translate) {
    return {
      restrict: 'E',
      transclude: true,
      replace: true,
      scope: {
        _title: '@formTitle',
        hideTitle: '=?hideTitle',
        hideButtons: '=?hideButtons',
        headerClass: '@',
        bodyClass: '@',
        _title_values: '=?titleValues',
        _ok: '=?onok',
        _cancel: '=?oncancel',
        labelOk: '@labelOk',
        labelCancel: '@labelCancel',
        disableOk: '=?disableok',
        disableCancel: '=?',
        validate: '=?',
        buttons: '=?',
        buttonsOnTop: '=?',
      },
      controller: function ($scope, $element, _) {
        $scope.submitting = false;
        $scope.valid = true;
        $scope._buttons = [];
        if ($scope.buttons) {
          $scope._buttons = _.filter($scope.buttons, function (button) {
            return button.name != 'cancel' && button.name != 'ok';
          });
        }
        var btnok = _.find($scope.buttons, { name: 'ok' });
        if (!$scope._ok && btnok) {
          $scope._ok = btnok.click;
          $scope.labelOk = btnok.label;
        }
        var btncancel = _.find($scope.buttons, { name: 'cancel' });
        if (!$scope._cancel && btncancel) {
          $scope._cancel = btncancel.click;
          $scope.labelCancel = btncancel.label;
        }

        $scope.ok = function () {
          if ($scope._ok) {
            $scope.$parent.errors = [];
            $scope.$parent.messages = [];
            $scope.submitting = true;
            $scope._ok(function (error) {
              $scope.submitting = false;
              if (error) {
                $scope.$parent.errors = [error];
              }
            });
          }
        };
        $scope.cancel = function () {
          $scope._cancel();
        };
        if (!$scope.labelOk) $scope.labelOk = 'button.ok';
        if (!$scope.labelCancel) $scope.labelCancel = 'button.cancel';
        $scope.title = $translate.instant($scope._title, $scope._title_values);

        $scope.getValues = function () {
          var result = '{';
          return result + '}';
        };

        $scope.getMessage = function (message) {
          if (message && typeof message === 'object') {
            return message.code;
          } else {
            return message;
          }
        };
        $scope.getMessageValues = function (message) {
          if (message && typeof message === 'object') {
            return message.translateValues;
          } else {
            return null;
          }
        };
      },
      link: function ($scope, $element, $attr, $ctrl) {
        $scope.$watch(
          function () {
            return $element.controller('form').$valid;
          },
          function (validity) {
            if (!(undefined === validity)) {
              $scope.valid = validity;
            }
          },
        );
      },
      templateUrl: '/templates/modal-form.html',
    };
  })
  .directive('editForm', function ($translate) {
    return {
      restrict: 'E',
      transclude: true,
      replace: true,
      templateUrl: '/templates/edit-form.html',
      scope: {
        _title: '@formTitle',
        hideTitle: '=?hideTitle',
        hideButtons: '=?hideButtons',
        headerClass: '@',
        bodyClass: '@',
        _title_values: '=?titleValues',
        _ok: '=?onok',
        _cancel: '=?oncancel',
        labelOk: '@labelOk',
        labelCancel: '@labelCancel',
        disableOk: '=?',
        disableCancel: '=?',
        validate: '=?',
        buttons: '=?',
        messages: '=?',
        errors: '=?',
        buttonsOnTop: '=?',
      },
      controller: function ($scope, $element, _) {
        $scope.submitting = false;
        $scope.valid = true;
        $scope._buttons = [];
        if ($scope.buttons) {
          $scope._buttons = _.filter($scope.buttons, function (button) {
            return button.name != 'cancel' && button.name != 'ok';
          });
        }
        var btnok = _.find($scope.buttons, { name: 'ok' });
        if (!$scope._ok && btnok) {
          $scope._ok = btnok.click;
          $scope.labelOk = btnok.label;
        }
        var btncancel = _.find($scope.buttons, { name: 'cancel' });
        if (!$scope._cancel && btncancel) {
          $scope._cancel = btncancel.click;
          $scope.labelCancel = btncancel.label;
        }

        $scope.ok = function () {
          $scope.errors = [];
          $scope.messages = [];
          $scope.submitting = true;
          $scope._ok(function (error) {
            $scope.submitting = false;
            if (error) {
              $scope.errors = [error];
            }
          });
        };
        $scope.cancel = function () {
          $scope._cancel();
        };
        if (!$scope.labelOk) $scope.labelOk = 'button.ok';
        if (!$scope.labelCancel) $scope.labelCancel = 'button.cancel';
        $scope.title = $translate.instant($scope._title, $scope._title_values);

        if (!$scope.messages) $scope.messages = $scope.$parent.messages;
        if (!$scope.errors) $scope.errors = $scope.$parent.errors;
        $scope.getValues = function () {
          var result = '{';
          return result + '}';
        };

        $scope.getMessage = function (message) {
          if (message && typeof message === 'object') {
            return message.code;
          } else {
            return message;
          }
        };
        $scope.getMessageValues = function (message) {
          if (message && typeof message === 'object') {
            return message.translateValues;
          } else {
            return null;
          }
        };
      },
      link: function ($scope, $element, $attr, $ctrl) {
        $scope.$watch(
          function () {
            return $element.controller('form').$valid;
          },
          function (validity) {
            if (!(undefined === validity)) {
              $scope.valid = validity;
            }
          },
        );
      },
    };
  })

  .directive('modalRichtext', function ($modal, $translate) {
    return {
      restrict: 'E',
      scope: {
        label: '@',
        model: '=',
        labelOk: '@',
        labelCancel: '@',
        unsafeSanitizer: '=',
      },
      replace: true,
      template:
        '<div class="form-group form-group-sm" title="{{(model ? model : \'\') | htmlToPlaintext}}">' +
        // '<label ng-show="label" class="col-xs-3 control-label" popover-placement="top" uib-popover="{{hint}}" popover-trigger="mouseenter">{{label | translate}} <i class="fa fa-question-circle text-success" ng-show="hint"></i></label>' +
        '<div class="col-xs-3"><input-label label="{{label}}"></input-label></div>' +
        "<div ng-class=\"{'has-error': hasError, 'col-xs-12': !label, 'col-xs-9': label }\">" +
        '    <span class="form-control" ng-click="open()">{{(model ? model : "Click here to add text...")| shorten:35}}</span>' +
        '    <span class="fa fa-file-text-o form-control-feedback text-muted" style="top: 3px; right: 13px;"></span>' +
        '</div></div>',
      link: function ($scope, $element, $attrs) {
        $scope.open = function () {
          var modalInstance = $modal.open({
            templateUrl: '/templates/modal-richtext.html',
            controller: function (
              $scope,
              $modalInstance,
              model,
              label,
              labelOk,
              labelCancel,
              unsafeSanitizer,
            ) {
              $scope.model = model;
              $scope.label = label;
              $scope.labelOk = labelOk ? labelOk : 'button.ok';
              $scope.labelCancel = labelCancel ? labelCancel : 'button.cancel';
              $scope.unsafeSanitizer = unsafeSanitizer ? true : false;
              $scope.ok = function () {
                $modalInstance.close($scope.model);
              };

              $scope.cancel = function () {
                $modalInstance.dismiss();
              };
            },
            backdrop: 'static',
            size: 'lg',
            resolve: {
              model: function () {
                return $scope.model;
              },
              label: function () {
                return $scope.label;
              },
              labelOk: function () {
                return $scope.labelOk;
              },
              labelCancel: function () {
                return $scope.labelCancel;
              },
              unsafeSanitizer: function () {
                return $scope.unsafeSanitizer;
              },
            },
          });
          modalInstance.result.then(
            function (result) {
              $scope.model = result;
            },
            function () {},
          );
        };
      },
      controller: function ($scope, $http) {
        // $scope.hint = $translate.instant($scope.label + '.hint');
        // if ($scope.hint == $scope.label + '.hint') $scope.hint = undefined;
      },
    };
  })

  .directive('inputFont', function () {
    return {
      restrict: 'E',
      replace: true,
      template:
        '<div class="row form-group-sm" >' +
        // '<label ng-show="label" class="col-xs-3 control-label" popover-placement="top" uib-popover="{{hint}}" popover-trigger="mouseenter">{{label | translate}} <i class="fa fa-question-circle text-success" ng-show="hint"></i></label>' +
        '<div class="col-xs-3"><input-label label="{{label}}"></input-label></div>' +
        "<div ng-class=\"{'has-error': hasError, 'col-xs-12': !label, 'col-xs-9': label }\">" +
        //'    <select class="form-control" ng-model="selectedFont" style="font-family: {{model}}" ng-options="font for font in fontList" ng-required="required"></select>' +
        '<select class="form-control" ng-model="selectedFont" ng-required="required">' +
        '<optgroup style="font-family: {{font}}" ng-repeat="font in fontList track by $index"><option>{{font}}</option></optgroup> ' +
        '</select>' +
        '</div></div>',
      //template: '<select ng-model="selectedFont"  ng-options="font for font in fontList"></select>',
      scope: {
        model: '=',
        label: '@',
      },

      controller: function ($scope, $translate) {
        // $scope.hint = $translate.instant($scope.label + '.hint');
        // if ($scope.hint == $scope.label + '.hint') $scope.hint = undefined;

        $scope.fontList = [];
        $scope.selectedFont = $scope.model;

        $scope.addFontFace = function (fontFamily) {
          var styleNode = document.createElement('style');
          styleNode.type = 'text/css';
          styleNode.textContent =
            "@font-face { font-family: '" +
            fontFamily +
            "'; src: url('//" +
            apphostname +
            '/fonts/' +
            fontFamily.substr(0, fontFamily.indexOf('-')) +
            '/' +
            fontFamily +
            ".ttf'); }";
          document.head.appendChild(styleNode);
        };

        availableFonts.forEach(function (fontFamily) {
          $scope.addFontFace(fontFamily);
          $scope.fontList.push(fontFamily);
        });

        availableCommonFonts.forEach(function (fontFamily) {
          $scope.fontList.push(fontFamily);
        });
      },

      link: function ($scope, $element, $attrs, ctrl) {
        $scope.$watch('selectedFont', function (font) {
          $scope.model = $scope.selectedFont;
        });
      },
    };
  })

  .directive('inputTimezone', function ($moment, _) {
    return {
      restrict: 'E',
      replace: true,
      template:
        '<div class="row form-group-sm" >' +
        // '<label ng-show="label" class="col-xs-3 control-label" popover-placement="top" uib-popover="{{hint}}" popover-trigger="mouseenter">{{label | translate}} <i class="fa fa-question-circle text-success" ng-show="hint"></i></label>' +
        '<div class="col-xs-3"><input-label label="{{label}}"></input-label></div>' +
        "<div ng-class=\"{'has-error': hasError, 'col-xs-12': !label, 'col-xs-9': label }\">" +
        '    <select class="form-control" ng-model="model" ng-required="required">' +
        '        <option ng-repeat="zone in zones" value="{{zone}}" ng-selected="{{zone == model}}">{{zone}}</option> ' +
        '    </select>' +
        '</div></div>',
      scope: {
        model: '=',
        label: '@',
      },

      controller: function ($scope, $translate) {
        // $scope.hint = $translate.instant($scope.label + '.hint');
        // if ($scope.hint == $scope.label + '.hint') $scope.hint = undefined;

        $scope.zones = [
          '-12:00',
          '-11:00',
          '-10:00',
          '-09:00',
          '-08:00',
          '-07:00',
          '-06:00',
          '-05:00',
          '-04:00',
          '-03:00',
          '-02:00',
          '-01:00',
          '+00:00',
          '+01:00',
          '+02:00',
          '+03:00',
          '+04:00',
          '+05:00',
          '+06:00',
          '+07:00',
          '+08:00',
          '+09:00',
          '+10:00',
          '+11:00',
          '+12:00',
        ];
      },

      link: function ($scope, $element, $attrs, ctrl) {},
    };
  });
