angular
  .module('vcio-toolkit')
  .controller(
    'SidebarCesClientActivitiesController',
    function (
      $moment,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $translate,
      _,
      CurrentUser,
      SidebarFilterService,
    ) {
      $scope.currentUser = CurrentUser.getUser();
      $scope.filter = SidebarFilterService.get('cesclientactivities', $scope, {
        type: [
          {
            name: 'label.client.ces.activityTypes.plan',
            code: 'plan',
            id: 1,
          },
          {
            name: 'label.client.ces.activityTypes.actual',
            code: 'actual',
            id: 2,
          },
          {
            name: 'label.client.ces.activityTypes.missed',
            code: 'missed',
            id: 3,
          },
        ],
        order: [
          {
            id: 1,
            name: "Date <i class='fa fa-chevron-down'></i>",
            orderField: 'day',
            selected: true,
            reverse: false,
          },
          {
            id: 2,
            name: "Date <i class='fa fa-chevron-up'></i>",
            orderField: 'day',
            reverse: true,
          },
          {
            id: 3,
            name: "Score <i class='fa fa-chevron-down'>",
            orderField: 'score',
            reverse: false,
          },
          {
            id: 4,
            name: "Score <i class='fa fa-chevron-up'>",
            orderField: 'score',
            reverse: true,
          },
        ],
      }).filter;

      // $scope.filter.fromDay = {
      //     opened: false,
      //     value: $moment().subtract(12, 'month').toDate(),
      //     maxDate: $moment()
      // };
      // $scope.filter.toDay = {
      //     opened: false,
      //     value: $moment().add(12, 'month').toDate(),
      //     minDate: $moment()
      // };
    },
  );
