angular
  .module('vcio-toolkit')

  .controller('AdminEventReportController', function ($moment, $scope, $state, _, events) {
    $scope.rows = [];
    var columns = {};
    for (var i = 6; i >= 0; i--) {
      var month = $moment().subtract(i, 'months').format('YYYYMM');
      columns[month] = 0;
    }
    columns.Total = 0;
    $scope.total = { name: 'Total', columns: _.clone(columns), Total: 0 };

    _.forEach(events, function (event) {
      var row = _.find($scope.rows, { name: event.event });
      if (!row) {
        row = { name: event.event, columns: _.clone(columns), Total: 0 };
        $scope.rows.push(row);
      }
      var month = $moment(event.month, 'YYYYMM').format('YYYYMM');
      if (_.has(row.columns, month)) {
        row.columns[month] += parseInt(event.count);
        row.columns.Total += parseInt(event.count);
        $scope.total.columns[month] += parseInt(event.count);
        $scope.total.columns.Total += parseInt(event.count);
      }
    });
  })

  .controller(
    'AdminCompaniesEventsReportController',
    function ($scope, $state, $stateParams, $moment, _, companies, eventList) {
      $scope.eventList = eventList;
      $scope.filter = { event: $stateParams.event };
      var columns = {};
      for (var i = 6; i >= 0; i--) {
        var month = $moment().subtract(i, 'months').format('YYYYMM');
        columns[month] = 0;
      }
      $scope.total = { name: 'Total', columns: _.clone(columns), Total: 0 };

      $scope.companies = _.map(companies, function (company) {
        company.Total = 0;
        company.columns = _.clone(columns);
        _.forOwn(company.columns, function (value, key) {
          var month = _.find(company.months, { month: key });
          if (month) {
            company.columns[key] = parseInt(month.count);
            company.Total += parseInt(month.count);
            $scope.total.columns[key] += parseInt(month.count);
          } else {
            company.columns[key] = 0;
          }
        });
        return company;
      });

      _.forOwn($scope.total.columns, function (value, key) {
        $scope.total.Total += value;
      });

      $scope.$watch('filter.event', function (newValue, oldValue) {
        if (newValue !== oldValue) {
          if (newValue) {
            $state.go('admin.companiesEventsReport', { event: newValue });
          } else {
            $state.go('admin.companiesEventsReport');
          }
        }
      });

      $scope.notEmptyFilter = function (company) {
        return !$scope.filter.notEmpty || _(company.columns).values().sum() > 0;
      };
    },
  )

  .controller('AdminCompanyEventsReportController', function ($scope, $moment, _, company) {
    $scope.company = company;

    $scope.rows = [];
    var columns = {};
    for (var i = 6; i >= 0; i--) {
      var month = $moment().subtract(i, 'months').format('YYYYMM');
      columns[month] = 0;
    }
    columns.Total = 0;
    $scope.total = { name: 'Total', columns: _.clone(columns), Total: 0 };

    _.forEach($scope.company.events, function (event) {
      var row = _.find($scope.rows, { name: event.event });
      if (!row) {
        row = { name: event.event, columns: _.clone(columns), Total: 0 };
        $scope.rows.push(row);
      }
      var month = $moment(event.month, 'YYYYMM').format('YYYYMM');
      if (_.has(row.columns, month)) {
        row.columns[month] += parseInt(event.count);
        row.columns.Total += parseInt(event.count);
        $scope.total.columns[month] += parseInt(event.count);
        $scope.total.columns.Total += parseInt(event.count);
      }
    });
  })

  // .controller('ReportController', function ($scope, $stateParams, $http, _) {
  //
  //     $http.get('/api/clients/' + $stateParams.clientId)
  //         .success(function (client) {
  //             $scope.client = client;
  //         });
  //
  //     $scope.refresh = function () {
  //         $http.get('/api/reports/' + $stateParams.clientId)
  //             .success(function (reports) {
  //
  //                 $http.get('/api/surveyresults/?clientId=' + $stateParams.clientId)
  //                     .success(function (results) {
  //                         reports.forEach(function (report) {
  //                             var result = _.find(results, {id: parseInt(report.SurveyResult.id)});
  //                             if (result) {
  //                                 report.surveyTitle = result.title;
  //                                 report.surveyLastUpdated = result.updatedAt;
  //                                 report.surveyContact = result.Contact.firstName + ' ' + result.Contact.lastName;
  //                             }
  //                         });
  //
  //                         $scope.reports = reports;
  //                     })
  //             })
  //     };
  //
  //     $scope.refresh();
  // })

  .controller(
    'ReportGenerateController',
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $http,
      $modalInstance,
      $filter,
      $window,
      $translate,
      _,
      CurrentUser,
      HttpService,
      surveyResult,
      showHide,
      InputSanitizerService,
    ) {
      $scope.surveyResult = _.cloneDeep(surveyResult);
      $scope.titleData = {
        surveyTitle: surveyResult.title,
        evaluatedDate: $filter('date')($scope.surveyResult.updatedAt, 'shortDate'),
        contactName:
          $scope.surveyResult.Contact.lastName + ', ' + $scope.surveyResult.Contact.firstName,
      };
      $scope.showHide = showHide;

      $scope.showOptions = function () {
        return _.includes(['itcq', 'needs', 'stack'], $scope.surveyResult.type);
      };

      $scope.createForm = $scope.showOptions()
        ? {
            surveyResultId: $scope.surveyResult.id,
            clientId: $scope.surveyResult.ClientId,
            score: 'current',
            highlights: true,
            language: $translate.use(),
          }
        : undefined;

      $scope.download = function () {
        $window.open(
          'https://' +
            $window.location.hostname +
            ($window.location.port ? ':' + $window.location.port : '') +
            '/reports/' +
            InputSanitizerService.sanitize($scope.downloadButton.filename),
        );
      };

      $scope.downloadButton = {
        label: 'button.download',
        function: $scope.download,
        enabled: false,
      };

      $scope.createReport = function () {
        // if ($scope.surveyResult.type === 'stack2') {
        //     $modalInstance.close();
        // } else {
        var call = $scope.showOptions()
          ? HttpService.post('/api/reports', $scope.createForm)
          : HttpService.post('/api/reports/qbr', { report: $scope.surveyResult });
        // var call = _.includes(['qbr', 'mpd'], $scope.surveyResult.type) ? HttpService.post('/api/reports/qbr', {report: $scope.surveyResult}) : HttpService.post('/api/reports', $scope.createForm);
        call
          .then(function (result) {
            $scope.downloadButton.filename =
              'https://' +
              $window.location.hostname +
              ($window.location.port ? ':' + $window.location.port : '') +
              '/reports/' +
              result.id;
            $scope.downloadButton.enabled = true;
          })
          .catch(function (error) {
            console.error(error);
          });
        // }
      };

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };
    },
  );
