angular
  .module('vcio-toolkit')

  .factory('CallToActionModel', function ($resource, _) {
    return $resource(
      '/api/marketing/calltoaction/:callToActionId',
      { callToActionId: '@id', objectId: '@objectId', type: '@type' },
      {
        save: {
          method: 'POST',
          transformRequest: function (data) {
            return angular.toJson({ callToAction: data });
          },
        },
        update: {
          url: '/api/marketing/calltoaction',
          method: 'PUT',
          transformRequest: function (data) {
            return angular.toJson({ callToAction: data });
          },
        },
        getByType: {
          url: '/api/marketing/calltoaction/:type/:objectId',
          method: 'GET',
        },
      },
    );
  });
