angular
  .module('vcio-toolkit')
  .controller(
    'MainController',
    function ($rootScope, $scope, $state, $location, _, CurrentUser, LocalStorage, SessionStorage) {
      $scope.container = function () {
        // return $location.path().indexOf('/overview') === -1 &&  $location.path().indexOf('servicebundlebuilder') === -1;
        return true; //$state.current.name !== 'service.servicebundlebuilder' //||
        // $state.current.name === 'sales.survey' ||
        // $state.current.name === 'sales.surveytemplate' ||
        // $state.current.name === 'sales.surveycopy' ||
        // $state.current.name === 'sales.surveytemplatecopy'
      };

      $scope.isAuthenticated = CurrentUser.isAuthenticated;
      $scope.getBodyClass = function () {
        if ($state.current.name === 'login') {
          return 'login-body';
          // } else if ($state.current.name === 'sales.overview') {
          //     return 'overview-body';
        } else {
          return '';
        }
      };

      $scope.sidebarAcademyStatus = SessionStorage.getObject('sidebarAcademyStatus', {});
      $scope.opt = {
        showSidebar: LocalStorage.getObject('showSidebar', {}),
        // showacademySidebar: !LocalStorage.getBoolean('showAcademySidebar', false),
        // showpermissiongroupsSidebar: !LocalStorage.getBoolean('showPermissiongroupsSidebar', false),
        // showcessSidebar: false,
        // showdashboardSidebar: false,
        sidebar: '',
      };

      $scope.isVendor = function () {
        return CurrentUser.hasModule('vendor');
      };

      $rootScope.$on('$stateChangeSuccess', function ($event, $toState) {
        if (_.startsWith($toState.name, 'academy') && $scope.isVendor()) {
          $scope.opt.sidebar = 'academy';
        } else if (
          _.includes(['marketplace.marketplace', 'marketplace.category'], $toState.name) &&
          !$scope.isVendor()
        ) {
          $scope.opt.sidebar = 'permissiongroups';
        } else if ('clients.ces' == $toState.name) {
          $scope.opt.sidebar = 'cesclientactivities';
        } else if ('ces.activities' == $toState.name) {
          $scope.opt.sidebar = 'cesactivities';
        } else if ('clients.list' == $toState.name && CurrentUser.can('listCesActivities')) {
          $scope.opt.sidebar = 'clients';
        } else if ('client.projects' == $toState.name) {
          $scope.opt.sidebar = 'clientprojects';
        } else if ('client.meetings' == $toState.name) {
          $scope.opt.sidebar = 'clientmeetings';
        } else {
          $scope.opt.sidebar = '';
        }
      });

      $scope.changeExpandedStatus = function (entity) {
        entity.$$expanded = !entity.$$expanded;
      };

      $scope.changeSidebarState = function () {
        $scope.opt.showSidebar[$scope.opt.sidebar] = !$scope.opt.showSidebar[$scope.opt.sidebar];
        LocalStorage.setObject('showSidebar', $scope.opt.showSidebar);
      };

      $scope.isShowSidebar = function () {
        return $scope.opt.showSidebar[$scope.opt.sidebar];
      };
    },
  );
