angular
  .module('vcio-toolkit')
  .controller(
    'LeadMagnetsController',
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $translate,
      $http,
      $modal,
      _,
      CurrentUser,
      leadmagnets,
    ) {
      $scope.hasNoLeadMagnets = false;
      $scope.apphostname = apphostname;
      $scope.companyUUID = CurrentUser.getUser().Company.uuid;
      $scope.leadmagnets = leadmagnets;
      $scope.filter = { name: '', type: '' };
      $scope.init = function (type) {
        $scope.filter.type = type;
      };

      function resetNewMagnet() {
        return {
          CompanyId: CurrentUser.getUser().Company.id,
          enabled: true,
          type: 'smartbar',
          mode: 'email',
          frequencyType: 'always',
          frequency: null,
          show: 'ontime',
          rules: [],
          design: {
            size: 'M',
            textColor: '#636363',
            backgroundColor: '#eeeeee',
            text: 'Sign me up for free!',
            fontFamily: 'Arial',
            fontSize: 'M',
            inputPlaceholder: 'enter your email address',
            buttonText: 'Subscribe now',
            buttonBackgroundColor: '#008cba',
            buttonBorderColor: '#0079a1',
            buttonTextColor: '#ffffff',
          },
          when: 3,
          behaviour: 'sticky',
        };
      }

      $scope.leadmagnetTypes = ['smartbar', 'scrollbox', 'popup'];
      $scope.sizes = ['S', 'M', 'L'];
      $scope.leadmagnetModes = ['email', 'grader', 'link', 'document'];
      $scope.frequencies = ['always', 'minute', 'hour', 'day', 'week', 'month', 'year'];
      $scope.newMagnet = resetNewMagnet($scope.newMagnet);
      $scope.emailSequences = [];
      $scope.graders = [];
      $scope.myFiles = [];

      $http
        .get('/api/emailsequences')
        .success(function (emailSequences) {
          $scope.emailSequences = _.filter(emailSequences, function (item) {
            return !item.template;
          });
        })
        .error(function (error) {
          console.error(error);
        });

      $http
        .get('/api/graders')
        .success(function (graders) {
          $scope.graders = _.filter(graders, function (item) {
            return !item.template;
          });
        })
        .error(function (error) {
          console.error(error);
        });

      $http
        .get('/api/companies/' + CurrentUser.getUser().Company.id + '/files')
        .success(function (files) {
          $scope.myFiles = _.filter(files, function (item) {
            return item.downloadable;
          });
        })
        .error(function (error) {
          console.error(error);
        });

      $scope.paging = {
        limit: 3,
        current: 1,
      };
      if ($scope.leadmagnets.length === 0) {
        $scope.hasNoLeadMagnets = true;
      }
      // $scope.refresh = function () {
      //     $http.get('/api/leadmagnets')
      //         .success(function (leadmagnets) {
      //             $scope.leadmagnets = leadmagnets;

      // if (inline_manual_player && $scope.leadmagnets.length == 0){
      //     $http.get('/api/settings/leadmagnetsTutorialId').success(function(leadmagnetsTutorialId){
      //         if (leadmagnetsTutorialId) {
      //             inline_manual_player.activateTopic(parseInt(leadmagnetsTutorialId.value));
      //         }
      //     })
      // }
      // }).error(function (error) {
      // console.error(error);
      // })
      // }
      $scope.canEdit = function (leadmagnet) {
        return (
          (!leadmagnet && CurrentUser.can('createLeadMagnet')) ||
          leadmagnet.CompanyId == CurrentUser.getUser().Company.id ||
          CurrentUser.can('editAnyLeadMagnet')
        );
      };

      $scope.edit = function (leadmagnet) {
        if ($scope.canEdit(leadmagnet)) {
          $state.go('marketing.leadmagnet', { leadmagnetId: leadmagnet ? leadmagnet.id : 'new' });
          // var modalScope = $rootScope.$new();
          // $scope.selectedLeadmagnet = leadmagnet;
          // modalScope.modalInstance = $modal.open({
          //     templateUrl: '/templates/msp/leadmagnet-edit.html',
          //     controller: 'LeadMagnetEditController',
          //     scope: modalScope,
          //     backdrop: 'static',
          //     size: 'lg',
          //     resolve: {
          //         leadmagnet: function () {
          //             return angular.copy(leadmagnet)
          //         }
          //     }
          // });
          //
          // modalScope.modalInstance.result.then(function (leadmagnet) {
          //     if ($scope.selectedLeadmagnet && $scope.selectedLeadmagnet.id) {
          //         angular.copy(leadmagnet, $scope.selectedLeadmagnet);
          //     } else {
          //         $scope.leadmagnets.push(leadmagnet);
          //     }
          //
          // }, function () {
          // });
        }
      };

      $scope.create = function () {
        $scope.edit();
      };

      $scope.delete = function (leadmagnet) {
        $http
          .delete('/api/leadmagnets/' + leadmagnet.id)
          .success(function () {
            _.remove($scope.leadmagnets, function (item) {
              return item.id == leadmagnet.id;
            });
            if ($scope.leadmagnets.length === 0) {
              $scope.hasNoLeadMagnets = true;
            }
          })
          .error(function (error) {
            console.error(error);
          });
      };

      $scope.leadmagnetOperations = [
        { operation: $scope.edit, label: 'button.edit', icon: 'edit', condition: $scope.canEdit },
        {
          operation: $scope.delete,
          label: 'button.delete',
          icon: 'minus-square-o',
          condition: $scope.canEdit,
          confirmation: true,
        },
      ];

      $scope.nameFilter = function (item) {
        return (
          $scope.filter.name === '' ||
          item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1 ||
          (item.Owner &&
            item.Owner.name &&
            item.Owner.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1)
        );
      };

      $scope.cancel = function (newMagnet) {
        $scope.newMagnet = resetNewMagnet();
        $scope.newMagnet.expanded = false;
      };

      $scope.save = function (newMagnet) {
        $rootScope.$broadcast('dataLoadingStarted');
        $http
          .post('/api/leadmagnets/', { leadmagnet: $scope.newMagnet })
          .success(function (leadmagnet) {
            newMagnet.id = leadmagnet.id;
            $scope.leadmagnets.push(_.cloneDeep(newMagnet));
            $scope.newMagnet = resetNewMagnet();
            $scope.newMagnet.expanded = false;
            $scope.messages = ['message.saved'];
            $scope.saved = true;
            $rootScope.$broadcast('dataLoadingFinished');
          })
          .error(function (error) {
            $scope.errors = [error];
            console.error(error);
            $rootScope.$broadcast('dataLoadingFinished');
          });
      };

      // $scope.refresh();
    },
  )

  //.controller('LeadMagnetShareController', function ($scope, $modalInstance, leadmagnet) {
  //    $scope.leadmagnet = leadmagnet;
  //    $scope.showHint = false;
  //    $scope.hint = function () {
  //        $scope.showHint = !$scope.showHint;
  //    }
  //    $scope.ok = function () {
  //        $modalInstance.dismiss();
  //    };
  //
  //
  //})

  .controller(
    'LeadMagnetEditController',
    function (
      $scope,
      $state,
      $stateParams,
      $translate,
      $http,
      /*$modalInstance,*/ _,
      CurrentUser,
      leadmagnet,
    ) {
      $scope.leadmagnet = leadmagnet || {
        CompanyId: CurrentUser.getUser().Company.id,
        enabled: true,
        type: 'smartbar',
        mode: 'email',
        frequencyType: 'always',
        frequency: null,
        show: 'ontime',
        rules: [],
        design: {
          size: 'M',
          textColor: '#636363',
          backgroundColor: '#eeeeee',
          text: 'Sign me up for free!',
          fontFamily: 'Arial',
          fontSize: 'M',
          inputPlaceholder: 'enter your email address',
          buttonText: 'Subscribe now',
          buttonBackgroundColor: '#008cba',
          buttonBorderColor: '#0079a1',
          buttonTextColor: '#ffffff',
        },
        when: 3,
        behaviour: 'sticky',
      };

      $scope.leadmagnet.rules = $scope.leadmagnet.rules || [];
      // var modalInstance = $scope.modalInstance;
      // $scope.saved = false;

      $scope.leadmagnetTypes = ['smartbar', 'scrollbox', 'popup'];
      $scope.sizes = ['S', 'M', 'L'];
      $scope.leadmagnetModes = ['email', 'grader', 'link', 'document'];
      $scope.frequencies = ['always', 'minute', 'hour', 'day', 'week', 'month', 'year'];
      $scope.showOn = ['ontime', 'onscroll'];
      $scope.behaviours = ['static', 'sticky'];
      $scope.placements = ['bottom-right', 'bottom-left', 'top-left', 'top-right'];
      $scope.ruleTypes = ['show', 'hide', 'showPartial', 'hidePartial'];
      $scope.emailSequences = [];
      $scope.graders = [];
      $scope.myFiles = [];

      //$scope.selectedFontFamily = $scope.leadmagnet.design.fontFamily;
      $scope.containerStyle = {
        backgroundColor: $scope.leadmagnet.design.backgroundColor,
        'font-family': $scope.leadmagnet.design.fontFamily,
      };
      $scope.titleStyle = { color: $scope.leadmagnet.design.textColor };
      $scope.textStyle = { color: $scope.leadmagnet.design.textColor };
      $scope.buttonStyle = {
        color: $scope.leadmagnet.design.buttonTextColor,
        backgroundColor: $scope.leadmagnet.design.buttonBackgroundColor,
        borderColor: $scope.leadmagnet.design.buttonBorderColor,
      };

      $http
        .get('/api/emailsequences')
        .success(function (emailSequences) {
          $scope.emailSequences = _.filter(emailSequences, function (item) {
            return !item.template;
          });
        })
        .error(function (error) {
          console.error(error);
        });

      $http
        .get('/api/graders')
        .success(function (graders) {
          $scope.graders = _.filter(graders, function (item) {
            return !item.template;
          });
        })
        .error(function (error) {
          console.error(error);
        });

      $http
        .get('/api/companies/' + CurrentUser.getUser().Company.id + '/files')
        .success(function (files) {
          $scope.myFiles = _.filter(files, function (item) {
            return item.downloadable;
          });
        })
        .error(function (error) {
          console.error(error);
        });

      $scope.name = function (item) {
        return item.name || item.filename;
      };

      $scope.$watch('leadmagnet.frequencyType', function (input) {
        if (input == 'always') $scope.leadmagnet.frequency = '';
      });

      $scope.setContainerSize = function () {
        if ($scope.leadmagnet.type == 'smartbar') {
          $scope.containerStyle.width = '100%';
          $scope.containerStyle.height = '42px';
          if ($scope.leadmagnet.design.size == 'S') {
            $scope.containerStyle.height = '27px';
          } else if ($scope.leadmagnet.design.size == 'L') {
            $scope.containerStyle.height = '90px';
          }
        } else {
          $scope.containerStyle.height = undefined;
          $scope.containerStyle.width = '400px';
          if ($scope.leadmagnet.design.size == 'S') {
            $scope.containerStyle.width = '200px';
          } else if ($scope.leadmagnet.design.size == 'L') {
            $scope.containerStyle.width = '800px';
          }
        }
        $scope.containerStyle.fontSize = '15px';
        if ($scope.leadmagnet.design.fontSize == 'S') {
          $scope.containerStyle.fontSize = '10px';
        } else if ($scope.leadmagnet.design.fontSize == 'L') {
          $scope.containerStyle.fontSize = '24px';
        }
      };

      $scope.$watch('leadmagnet.type', function (input) {
        if (input == 'smartbar') {
          $scope.leadmagnet.placement = '';
          $scope.containerStyle.borderRadius = '0px';
          $scope.setContainerSize();
        } else if (input == 'scrollbox') {
          $scope.leadmagnet.behaviour = '';
          $scope.containerStyle.borderRadius = '10px';
          $scope.setContainerSize();
        } else if (input == 'popup') {
          $scope.containerStyle.borderRadius = '10px';
          $scope.setContainerSize();
        }
      });

      $scope.$watch('leadmagnet.mode', function (input) {
        if (input != 'email' && input != 'link') {
          $scope.leadmagnet.successRedirect = '';
        }
        if (input != 'email') {
          $scope.leadmagnet.EmailSequenceId = '';
        }
        if (input != 'grader') {
          $scope.leadmagnet.GraderId = '';
        }
      });

      $scope.getSampleClass = function (elem) {
        if ($scope.leadmagnet.type == 'smartbar') {
          return 'ryclm-smartbar' + (elem ? '-' + elem : '');
        } else {
          return 'ryclm-popup' + (elem ? '-' + elem : '');
        }
      };

      $scope.$watch(
        'leadmagnet.design',
        function () {
          $scope.textStyle.color = $scope.leadmagnet.design.textColor;
          $scope.containerStyle.backgroundColor = $scope.leadmagnet.design.backgroundColor;
          $scope.containerStyle.fontFamily = $scope.leadmagnet.design.fontFamily;
          $scope.setContainerSize();

          $scope.titleStyle.color = $scope.leadmagnet.design.textColor;
          $scope.textStyle.color = $scope.leadmagnet.design.textColor;
          $scope.buttonStyle.color = $scope.leadmagnet.design.buttonTextColor;
          $scope.buttonStyle.backgroundColor = $scope.leadmagnet.design.buttonBackgroundColor;
          $scope.buttonStyle.border = '1px solid ' + $scope.leadmagnet.design.buttonBorderColor;
        },
        true,
      );

      $scope.addRule = function () {
        $scope.leadmagnet.rules.push({ type: 'show', url: '' });
      };

      $scope.removeRule = function (index) {
        $scope.leadmagnet.rules.splice(index, 1);
      };

      $scope.save = function (cb) {
        if ($scope.leadmagnet.id) {
          $http
            .put('/api/leadmagnets/', { leadmagnet: $scope.leadmagnet })
            .success(function () {
              // $scope.messages = ['message.saved'];
              // $scope.saved = true;
              // cb();
              if ($scope.modalInstance) {
                $scope.modalInstance.close($scope.leadmagnet);
              } else {
                $state.go('marketing.leadmagnets');
              }
            })
            .error(function (error) {
              $scope.errors = [error];
              console.error(error);
              cb();
            });
        } else {
          $http
            .post('/api/leadmagnets/', { leadmagnet: $scope.leadmagnet })
            .success(function (leadmagnet) {
              if ($scope.modalInstance) {
                // $scope.leadmagnet.id = leadmagnet.id;
                // $scope.messages = ['message.saved'];
                // $scope.saved = true;
                $scope.modalInstance.close(leadmagnet);
              } else {
                $state.go('marketing.leadmagnets');
              }

              cb();
            })
            .error(function (error) {
              $scope.errors = [error];
              console.error(error);
              cb();
            });
        }
      };

      $scope.close = function () {
        if ($scope.modalInstance) {
          // if ($scope.saved) {
          //     $scope.modalInstance.close($scope.leadmagnet);
          // } else {
          $scope.modalInstance.dismiss();
          // }
        } else {
          $state.go('marketing.leadmagnets');
        }
      };
    },
  )

  .factory('leadmagnetService', function ($http, $q) {
    var getLeadmagnet = function (id) {
      var deferred = $q.defer();
      if (id) {
        $http
          .get('/api/leadmagnets/' + id)
          .success(function (result) {
            deferred.resolve(result);
          })
          .error(function (error) {
            console.error(error);
          });
      } else {
        deferred.resolve(null);
      }
      return deferred.promise;
    };

    return {
      getLeadmagnet: getLeadmagnet,
    };
  })

  .controller(
    'LeadMagnetStatisticsController',
    function (
      $scope,
      $stateParams,
      HttpService,
      $modal,
      $moment,
      $q,
      _,
      CurrentUser,
      DialogService,
    ) {
      $scope.options = {
        from: $moment().subtract(7, 'day').hour(0).minute(0).second(0).toDate(),
        to: $moment().add(1, 'day').hour(0).minute(0).second(0).toDate(),
        type: 'rate',
        selectedLeadmagnets: [],
        leadmagnets: [],
      };
      $scope.dataTypes = ['open', 'hit', 'rate'];
      $scope.refreshing = false;
      HttpService.post(
        '/api/admin/users/' + CurrentUser.getUser().id + '/timeline/leadmagnetStatsViewed',
      );

      var iterateDays = function (itf) {
        var from = $moment($scope.options.from);
        var to = $moment($scope.options.to);
        var item = from;
        var days = to.diff(from, 'days');
        for (var i = 0; i < days; i++) {
          item.add(1, 'day');
          var key = item.format('YYYY-MM-DD');
          itf(i, key, item, days);
        }
      };

      var addSeries = function (stat) {
        // $scope.data.push(stat);
        // $scope.chart.data.labels.push(stat.name);
        var dataset = {
          label: stat.name,
          // borderColor: colors.actual,
          // backgroundColor: colors.actual,
          data: [],
          // yAxisID: "bar-y-axis"
        };
        $scope.chart.data.datasets.push(dataset);
        iterateDays(function (index, key, day, days) {
          if (stat.times.hasOwnProperty(key)) {
            if ($scope.options.type == 'open') {
              dataset.data.push(stat.times[key].open || 0);
            } else if ($scope.options.type == 'hit') {
              dataset.data.push(stat.times[key].hit || 0);
            } else {
              if (stat.times[key].open > 0 && stat.times[key].hit > 0) {
                dataset.data.push(Math.round((stat.times[key].hit / stat.times[key].open) * 100));
              } else {
                dataset.data.push(0);
              }
            }
          } else {
            dataset.data.push(0);
          }
        });
      };

      var createChartData = function () {
        $scope.chart = {
          data: {
            labels: [], // days
            datasets: [],
            // options: {
            //     responsive: true,
            //     animation: false,
            //     bezierCurve: false,
            //     pointDot: false,
            //     legend: {display: false},
            //     tooltips: {
            //         enabled: false
            //     }
            // }
          },
        };

        iterateDays(function (index, key, day, days) {
          if (days < 10 || index % Math.round(days / 10) === 0) {
            $scope.chart.data.labels.push(day.format('MM-DD'));
          } else {
            $scope.chart.data.labels.push('');
          }
        });
      };

      $scope.refresh = function () {
        if (!$scope.refreshing) {
          $scope.refreshing = true;
          createChartData();
          $q.all(
            _($scope.options.leadmagnets)
              .filter({ selected: true })
              .map(function (leadmagnet) {
                return HttpService.post('/api/leadmagnets/' + leadmagnet.id + '/times', {
                  from: $scope.options.from,
                  to: $scope.options.to,
                });
              })
              .value(),
          )
            .then(function (results) {
              results.forEach(function (result) {
                addSeries(result);
              });
              $scope.refreshing = false;
            })
            .catch(function (error) {
              DialogService.error(error);
              $scope.refreshing = false;
            });
        }
      };

      HttpService.get('/api/leadmagnets').then(function (leadmagnets) {
        $scope.options.leadmagnets = leadmagnets;
        $scope.options.leadmagnets.forEach(function (lm) {
          lm.ticked = true;
        });
      });

      $scope.$watch('selectedLeadmagnets', function () {
        $scope.refresh();
      });
      $scope.$watch('from', function () {
        $scope.refresh();
      });
      $scope.$watch('to', function () {
        $scope.refresh();
      });
      $scope.$watch('type', function () {
        $scope.refresh();
      });
    },
  )

  .controller('LeadVisitorsController', function ($scope, $http, _, visitors) {
    $scope.visitors = visitors;
  });
