angular
  .module('vcio-toolkit')

  .controller('SettingsController', function ($scope, $state, HttpService, $modal, _, CurrentUser) {
    $scope.showDetails = true;
    HttpService.get('/api/settings/').then(function (settings) {
      $scope.settings = settings;
    });

    $scope.add = function (param) {
      var modalInstance = $modal.open({
        template:
          '<modal-form onok="ok" oncancel="cancel"><input-text model="param.name" label="label.name" required="true"></input-text></modal-form>',
        backdrop: 'static',
        controller: function ($scope, $modalInstance, param, settings) {
          $scope.param = param;
          $scope.settings = settings;

          $scope.ok = function (cb) {
            if (!_.find($scope.settings, { name: param.name })) {
              $scope.settings.push(param);
              $modalInstance.close(param);
            } else {
              $scope.errors = [{ code: 'alreadyExists' }];
              cb();
            }
          };
          $scope.cancel = function () {
            $modalInstance.dismiss();
          };
        },
        resolve: {
          param: function () {
            return param;
          },
          settings: function () {
            return $scope.settings;
          },
        },
      });

      modalInstance.result.then(
        function (param) {},
        function () {},
      );
    };

    $scope.addNumber = function () {
      $scope.add({ type: 'number', isArray: false });
    };

    $scope.addText = function () {
      $scope.add({ type: 'text', isArray: false });
    };

    $scope.addNumberList = function () {
      $scope.add({ type: 'number', isArray: true });
    };

    $scope.addTextList = function () {
      $scope.add({ type: 'text', isArray: true });
    };

    $scope.openEdit = function (param) {
      $scope.selectedParam = param;
      var modalInstance = $modal.open({
        templateUrl: '/templates/admin/settings-edit.html',
        controller: 'SettingsEditController',
        backdrop: 'static',
        resolve: {
          parameter: function () {
            return angular.copy($scope.selectedParam);
          },
        },
      });

      modalInstance.result.then(
        function (param) {
          _.find($scope.settings, { name: param.name }).value = param.value;
        },
        function () {},
      );
    };
  })

  .controller(
    'SettingsEditController',
    function (
      $scope,
      $rootScope,
      $state,
      HttpService,
      $modalInstance,
      _,
      CurrentUser,
      parameter,
      InputSanitizerService,
    ) {
      $scope.parameter = parameter;

      $scope.save = function (cb) {
        HttpService.post('/api/settings/' + InputSanitizerService.sanitize($scope.parameter.name), {
          value: $scope.parameter.value,
        })
          .then(function (result) {
            $modalInstance.close(parameter);
          })
          .catch(function (error) {
            $scope.errors = [error];
            cb();
          });
      };

      $scope.cancel = function (cb) {
        $modalInstance.dismiss();
      };

      $scope.addValue = function () {
        if (!$scope.parameter.value) {
          $scope.parameter.value = [];
        }
        if ($scope.parameter.type == 'number') {
          $scope.parameter.value.push(0);
        } else {
          $scope.parameter.value.push('');
        }
      };

      $scope.removeValue = function (index) {
        if ($scope.parameter.value) {
          $scope.parameter.value.splice(index, 1);
        }
      };
    },
  )

  .controller('SchedulerController', function ($modal, $scope, DialogService, HttpService, _) {
    HttpService.get('/api/scheduler/jobs').then(function (jobs) {
      $scope.jobs = jobs;
    });

    $scope.startJob = function (job) {
      var modalInstance = $modal.open({
        template:
          '<modal-form onok="ok" label-ok="button.start" oncancel="cancel" form-title="label.scheduler.startJob" title-values="{service: job.service, function: job.function}">' +
          '<input-text model="job.parameters" label="label.scheduler.parameters"></input-text>' +
          '</modal-form>',
        controller: function ($modalInstance, $scope, job) {
          $scope.job = job;
          $scope.ok = function (cb) {
            var args = JSON.parse(job.parameters);
            if (!_.isArray(args)) {
              args = [args];
            }
            HttpService.post('/api/scheduler/' + job.service + '/' + job.function + '/start', args)
              .then(function (result) {
                $modalInstance.close();
                DialogService.message('label.sysadmin.startJob.success');
              })
              .catch(function (error) {
                cb();
                DialogService.error(error);
              });
          };

          $scope.cancel = function () {
            $modalInstance.dismiss();
          };
        },
        backdrop: 'static',
        size: 'lg',
        resolve: {
          job: function () {
            return angular.copy(job);
          },
        },
      });
    };

    $scope.edit = function (job) {
      var modalInstance = $modal.open({
        template:
          '<modal-form onok="ok" oncancel="cancel">' +
          '<input-text model="job.description" label="label.scheduler.description"></input-text>' +
          '<input-text model="job.service" label="label.scheduler.service" disabled="job.id" required="true"></input-text>' +
          '<input-text model="job.function" label="label.scheduler.function" disabled="job.id" required="true"></input-text>' +
          '<input-text model="job.cronTime" label="label.scheduler.cron" required="true"></input-text>' +
          '<input-checkbox model="job.enabled" label="enabled"></input-checkbox>' +
          '</modal-form>',
        controller: function ($modalInstance, $scope, job) {
          $scope.job = job;
          $scope.ok = function (cb) {
            HttpService.post('/api/scheduler/jobs', job)
              .then(function (result) {
                $modalInstance.close(result);
              })
              .catch(function (error) {
                cb();
                $scope.errors.push(error);
              });
          };

          $scope.cancel = function () {
            $modalInstance.dismiss();
          };
        },
        backdrop: 'static',
        size: 'lg',
        resolve: {
          job: function () {
            return angular.copy(job);
          },
        },
      });

      modalInstance.result.then(
        function (result) {
          var found = _.find($scope.jobs, { id: result.id });
          if (!found) {
            $scope.jobs.push(result);
          } else {
            _.extend(found, result);
          }
        },
        function () {},
      );
    };
  });
