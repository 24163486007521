angular
  .module('vcio-toolkit')

  .controller(
    'AutotaskCreditentialsController',
    function ($scope, $rootScope, $http, $modalInstance, $q, _, CurrentUser) {
      $scope.externalsystems = ['autotask', 'connectwise', 'halo'];
      $scope.data = $rootScope.loginData || {
        user: '',
        pass: '',
        company: '',
        site: '',
        system: 'autotask',
      };

      $scope.showLogin = function () {
        return $scope.data.system === 'autotask';
      };

      $scope.ok = function () {
        $modalInstance.close({ loginData: $scope.data });
      };

      $scope.cancel = function () {
        $rootScope.loginData = false;
        $modalInstance.dismiss();
      };
    },
  );
