angular
  .module('vcio-toolkit')

  .controller(
    'AcademyNavController',
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $location,
      $http,
      $translate,
      _,
      CurrentUser,
      AcademyService,
    ) {
      $scope.isCollapsed = true;
      $scope.user = CurrentUser.getUser();

      if (
        CurrentUser.hasModule('vendor') &&
        !_.find($scope.user.Company.Vendors, { id: $scope.user.Company.id })
      ) {
        $scope.user.Company.Vendors.unshift({
          id: $scope.user.Company.id,
          name: $scope.user.Company.name,
        });
      }

      if ($location.search().vendorId) {
        AcademyService.setVendor(
          _.find($scope.user.Company.Vendors, { id: parseInt($location.search().vendorId) }),
        );
      } else if (CurrentUser.hasModule('vendor')) {
        AcademyService.setVendor($scope.user.Company);
      } else {
        AcademyService.setVendor();
      }

      $scope.can = function (right) {
        return CurrentUser.can(right);
      };

      $scope.isManage = function () {
        return $location.path().indexOf('/academy/manage') === 0;
      };

      $scope.showVendorSelector = function () {
        return $state.$current && $state.$current.data && $state.$current.data.vendorSelector;
      };

      $scope.showOwnerVendor = function () {
        return AcademyService.getOwnerVendor();
      };

      $scope.getOwnerVendorName = function () {
        return AcademyService.getOwnerVendor() ? AcademyService.getOwnerVendor().name : undefined;
      };

      $scope.getOwnerVendorId = function () {
        return AcademyService.getOwnerVendor() ? AcademyService.getOwnerVendor().id : undefined;
      };

      // $scope.manage = function () {
      //     var path = $location.path().split('/');
      //     if (path.length == 2) {
      //         $location.path('/academy/manage');
      //     } else if (path.length > 2) {
      //         $location.path('/academy/manage/' + path[2]);
      //     }
      // }

      $scope.getVendorName = function () {
        return AcademyService.getVendor()
          ? AcademyService.getVendor().name
          : $translate.instant('label.vendorAll');
      };

      $scope.getVendorId = function () {
        return AcademyService.getVendor() ? AcademyService.getVendor().id : undefined;
      };

      $scope.changeVendor = function (vendor) {
        AcademyService.setVendor(vendor);
        //$rootScope.$broadcast('vendorChanged', vendor);
      };

      $scope.getTitle = function () {
        return $state.$current.data && $state.$current.data.pageTitle
          ? $translate.instant($state.$current.data.pageTitle)
          : '';
      };
    },
  )

  .service(
    'AcademyService',
    function ($rootScope, $state, $timeout, $translate, $http, CurrentUser, _, DialogService) {
      var vendor = null;
      var ownerVendor = null;
      var filter;
      return {
        setFilter: function (_filter) {
          filter = _filter;
        },

        setVendor: function (_vendor) {
          vendor = _vendor;
          $rootScope.$broadcast('vendorChanged', vendor);
          // if (_vendor) {
          //     LocalStorage.set('selectedVendorId', _vendor.id);
          // } else {
          //     LocalStorage.delete('selectedVendorId');
          // }
        },

        getVendor: function () {
          return vendor;
        },

        setOwnerVendor: function (_ownerVendor) {
          ownerVendor = _ownerVendor;
        },

        getOwnerVendor: function () {
          return ownerVendor;
        },

        vendorFilter: function (item) {
          return (
            !vendor ||
            (item.VendorId && item.VendorId === vendor.id) ||
            (!item.VendorId && item.OwnerId === vendor.id)
          );
        },

        goBack: function (listname) {
          if (
            listname === 'serviceCopy' ||
            $rootScope.previousState.name === 'service.serviceCopy'
          ) {
            $state.go('service.services');
          } else if ($rootScope.previousState.name === 'service.servicetemplateCopy') {
            $state.go('service.servicetemplates');
          } else if (
            $rootScope.previousState &&
            $rootScope.previousState.name &&
            $rootScope.previousState.name != $state.$current.name &&
            $rootScope.previousState.name !== 'service.serviceCopy' &&
            $rootScope.previousState.name !== 'service.servicetemplateCopy'
          ) {
            $state.go($rootScope.previousState.name, $rootScope.previousState.params);
          } else if (listname === 'services') {
            $state.go('service.' + listname);
          } else if (CurrentUser.can('manageAcademy')) {
            $state.go('academy.manage' + listname);
          } else {
            $state.go('academy.' + listname);
          }
        },

        publishedFilter: function (item) {
          return item.published;
        },

        moveUp: function (item, list) {
          if (item.orderId > 1) {
            var prev = _.find(list, { orderId: item.orderId - 1 });
            item.orderId -= 1.5;
            prev.orderId++;
            item.orderId += 0.5;
          }
        },

        moveDown: function (item, list) {
          if (item.orderId < list.length) {
            var next = _.find(list, { orderId: item.orderId + 1 });
            item.orderId += 1.5;
            next.orderId--;
            item.orderId -= 0.5;
          }
        },

        remove: function (item2remove, list) {
          if (item2remove.orderId) {
            _.remove(list, function (item) {
              return item.orderId == item2remove.orderId;
            });

            list.forEach(function (item) {
              if (item.orderId > item2remove.orderId) {
                item.orderId--;
              }
            });
          } else {
            _.remove(list, function (item) {
              return item == item2remove;
            });
          }
        },

        embedWistia: function (code, playlist) {
          if (code) {
            if (playlist) {
              $timeout(function () {
                var wistiaPlaylist = Wistia.playlist(code, {
                  videoFoam: true,
                  theme: 'tab',
                });
                wistiaPlaylist.setEmail(CurrentUser.getUser().email);
              }, 100);
            } else {
              $timeout(function () {
                var wistiaEmbed = Wistia.embed(code, {
                  videoFoam: true,
                  trackEmail: CurrentUser.getUser().email,
                });
              }, 100);
            }
          }
        },

        getServicePopover: function (service) {
          var d = { academy: 'success', marketing: 'danger', sales: 'primary', vcio: 'warning' };
          var result = service.description + '<div class="popover-footer"><div class="row"> ';
          _.forOwn(d, function (color, key) {
            result += '<div class="col-xs-3"><small>';
            service.serviceQuality[key].forEach(function () {
              result += '<i class="fa fa-star text-' + color + '"></i>';
            });
            result += '</small></div>';
          });

          return result + '</div></div>';
        },

        chartData: function (value, label, color, size) {
          return {
            data: {
              labels: [$translate.instant(label)],
              datasets: [
                {
                  data: [value, 100 - value],
                  backgroundColor: [color || '#e7e7e7', '#EEEEEE'],
                },
              ],
            },
            options: {
              legend: false,
              tooltips: false,
              width: size || 120,
              height: size || 120,
              responsive: true,
              animation: false,
              cutoutPercentage: 85,
            },
          };
        },

        generateCoursePopover: function (course) {
          var popoverText = '';
          if (course) {
            if (course.subtitle) {
              popoverText += '<p><b>' + course.subtitle + '</b></p>';
            }
            if (course.overview) {
              popoverText += '<br>' + course.overview;
            }
            _.forEach(course.sections, function (section) {
              popoverText += '<br><b>' + section.name + '</b>';
              _.forEach(section.attachedAssets, function (attachedAsset) {
                if (attachedAsset.name) {
                  popoverText += '<br>&nbsp;&nbsp;' + attachedAsset.name;
                }
              });
            });
          }
          return popoverText;
        },
      };
    },
  )

  // .controller('AcademyNotesController', function ($scope, $state, $stateParams, $sce, $http, $translate, $location, $modal, $timeout, $moment, _, AcademyService, CurrentUser, user) {
  //     $scope.user = user;
  //     $scope.newNote = {};
  //
  //     $scope.canEditNote = function () {
  //         return CurrentUser.can("coach") && $scope.user.id != CurrentUser.getUser().id;
  //     };
  //
  //     $scope.editNote = function (note) {
  //         $scope.newNote = note;
  //     };
  //
  //     $scope.saveNote = function () {
  //         if ($scope.canEditNote()) {
  //             if ($scope.newNote.id) {
  //                 $http.put('/api/academy/notes/' + $scope.selectedUser.id, {note: $scope.newNote})
  //                     .success(function () {
  //                         $scope.newNote = {};
  //                     })
  //             } else {
  //                 $http.post('/api/academy/notes/' + $scope.selectedUser.id, {note: $scope.newNote})
  //                     .success(function (result) {
  //                         $scope.selectedUser.Notes.push(result);
  //                         $scope.newNote = {};
  //                     })
  //             }
  //         }
  //     };
  //
  //     $scope.deleteNote = function (note) {
  //         if ($scope.canEditNote()) {
  //             $http.delete('/api/academy/notes/' + $scope.selectedUser.id + '/' + note.id)
  //                 .success(function () {
  //                     _.remove($scope.selectedUser.Notes, {id: note.id});
  //                 })
  //         }
  //     }
  //
  // })

  .controller(
    'AcademyCourseToUsersController',
    function (
      $http,
      $modalInstance,
      $q,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $sce,
      _,
      CurrentUser,
      DialogService,
      HttpService,
      users,
      course,
    ) {
      $scope.users = users;
      $scope.course = course;
      $scope.companies = [];
      $scope.filter = {};
      $scope.isVendor = function () {
        return CurrentUser.hasModule('vendor');
      };

      _.forEach($scope.users, function (user) {
        var company = _.find($scope.companies, { id: user.Company.id });
        if (!_.find($scope.companies, { id: user.Company.id })) {
          company = { id: user.Company.id, name: user.Company.name, users: [] };
          $scope.companies.push(company);
        }
        company.users.push(user);
      });

      $scope.getUserClass = function (user) {
        return _.find(user.Courses, { id: $scope.course.id }) ? 'fa-check-square-o' : 'fa-square-o';
      };

      var assign = function (user, selected) {
        var deferred = $q.defer();
        if (selected && !_.find(user.Courses, { id: $scope.course.id })) {
          HttpService.post('/api/academy/courses/' + $scope.course.id + '/assign/' + user.id)
            .then(function () {
              user.Courses.push($scope.course);
              deferred.resolve();
            })
            .catch(function (error) {
              console.error(error);
              deferred.reject(error);
            });
        } else if (!selected && _.find(user.Courses, { id: $scope.course.id })) {
          HttpService.delete('/api/academy/courses/' + $scope.course.id + '/assign/' + user.id)
            .then(function () {
              _.remove(user.Courses, { id: $scope.course.id });
              deferred.resolve();
            })
            .catch(function (error) {
              console.error(error);
              deferred.reject(error);
            });
        } else {
          deferred.resolve();
        }
        return deferred.promise;
      };

      $scope.assign = function (user) {
        assign(user, !_.find(user.Courses, { id: $scope.course.id })).catch(function (error) {
          DialogService.error(error);
        });
      };

      $scope.close = function () {
        $modalInstance.dismiss();
      };

      $scope.companyFilter = function (company) {
        return (
          !$scope.filter.name ||
          (company &&
            company.name &&
            company.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1)
        );
      };

      $scope.selectCompany = function (company) {
        $rootScope.$broadcast('dataLoadingStarted');
        company.$$selected = !company.$$selected;
        var promises = [];
        company.users.forEach(function (user) {
          promises.push(assign(user, company.$$selected));
        });
        $q.all(promises)
          .then(function () {
            $rootScope.$broadcast('dataLoadingFinished');
          })
          .catch(function (error) {
            DialogService.error(error);
          });
      };
    },
  )

  .controller(
    'AcademyUsersController',
    function (
      $http,
      $location,
      $modal,
      $moment,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $sce,
      $translate,
      $timeout,
      _,
      AcademyService,
      DialogService,
      CurrentUser,
      HttpService,
      users,
      courses,
    ) {
      $scope.currentUser = CurrentUser.getUser();
      $scope.myUsers = _.filter(users, function (user) {
        return user.Coach && user.Coach.id == $scope.currentUser.id;
      });
      $scope.users = _.filter(users, function (user) {
        return !user.Coach || user.Coach.id != $scope.currentUser.id;
      });
      $scope.courses = courses;
      $scope.filter = { filter: { hasCheckpoint: false } };

      $scope.refresh = function () {
        _.forEach($scope.myUsers, function (user) {
          user.unfinishedCourses = _.orderBy(
            _.filter(user.Courses, function (course) {
              return course.progress < 100;
            }),
            ['UserAcademyAsset.orderId', 'name'],
          );
          user.finishedCourses = _.orderBy(
            _.filter(user.Courses, function (course) {
              return course.progress >= 100;
            }),
            ['UserAcademyAsset.orderId', 'name'],
          );

          user.progress = {
            overall:
              $scope.courses.length > 0
                ? Math.round((user.finishedCourses.length / $scope.courses.length) * 100)
                : 100,
            roadmap:
              user.Courses.length > 0
                ? Math.round((user.finishedCourses.length / user.Courses.length) * 100)
                : 100,
          };

          user.charts = {
            overall: AcademyService.chartData(user.progress.overall, 'overall', '#62A6DB'),
            roadmap: AcademyService.chartData(user.progress.roadmap, 'roadmap', '#62A6DB'),
          };

          user.checkpointCourses = _.filter(user.Courses, { hasCheckpoint: true });
        });
      };

      $scope.refresh(users);

      $scope.userFilter = function (user) {
        return (
          (!$scope.filter.name ||
            (user.email &&
              user.email.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1) ||
            (user.firstName &&
              user.firstName.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1) ||
            (user.lastName &&
              user.lastName.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1)) &&
          (!$scope.filter.filter.hasCheckpoint || !_.isEmpty(user.checkpointCourses))
        );
      };

      $scope.openUserCourse = function (user, course) {
        $scope.selectedUser = user;
        $scope.selectedCourse = course;
        var modalInstance = $modal.open({
          templateUrl: '/templates/academy/coaching-course-assets.html',
          controller: 'AcademyUserCourseAssetsModalController',
          // size: 'lg',
          backdrop: 'static',
          resolve: {
            course: function () {
              return course;
            },
            user: function () {
              return user;
            },
            quizResults: function () {
              return HttpService.get('/api/academy/quizzes/results/' + user.id);
            },
          },
        });

        modalInstance.result.then(
          function () {},
          function () {
            var courseAssets = _($scope.selectedCourse.sections)
              .flatten()
              .map('attachedAssets')
              .flatten()
              .uniq()
              .value();
            $scope.selectedCourse.progress =
              courseAssets.length > 0
                ? Math.round(
                    (_.filter(courseAssets, 'finishedAt').length / courseAssets.length) * 100,
                  )
                : 100;
            course.hasCheckpoint = !!_.find(courseAssets, function (asset) {
              return (
                !asset.finishedAt && asset.UserAcademyAsset && asset.UserAcademyAsset.checkpoint
              );
            });

            $scope.refresh();
          },
        );
      };
    },
  )

  .controller(
    'AcademyPrivateMeetingsController',
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $http,
      $modal,
      $moment,
      $window,
      $translate,
      _,
      LocalStorage,
      CurrentUser,
      DialogService,
      HttpService,
      surveyResultService,
      company,
      user,
      meetings,
    ) {
      $scope.meetings = meetings;
      $scope.company = company;
      $scope.user = user;
    },
  )

  .controller(
    'AcademyUserHeaderController',
    function (
      $q,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $sce,
      $http,
      $translate,
      $location,
      $modal,
      $timeout,
      $moment,
      _,
      AcademyService,
      AssetService,
      DialogService,
      CurrentUser,
      HttpService,
      InputSanitizerService,
    ) {
      $scope.options = {};
      $scope.vendors = CurrentUser.getUser().Company.Vendors;

      var getCoaches = function () {
        if ($scope.user.Coach) {
          $scope.user.Coach.name = $scope.user.Coach.firstName + ' ' + $scope.user.Coach.lastName;
        }
        return HttpService.get(
          '/api/admin/users?companyId=' +
            InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
        ).then(function (users) {
          if (!_.find(users, { id: CurrentUser.getUser().id })) {
            users.unshift(CurrentUser.getUser());
          }
          var coaches = _.map(users, function (user) {
            return _.extend({ name: user.firstName + ' ' + user.lastName }, user);
          });
          return coaches;
        });
      };

      $scope.chooseCoach = function () {
        if (CurrentUser.can('setCoach')) {
          getCoaches().then(function (coaches) {
            $scope.options.coaches = coaches;
            $scope.options.coachSelect = true;
          });
        }
      };

      $scope.openChooseCoach = function () {
        if (CurrentUser.can('setCoach')) {
          var modalInstance = $modal.open({
            template:
              '<modal-form onok="ok" oncancel="cancel" form-title="label.academy.coaching.chooseCoach"> ' +
              '  <div class="abm-form"><abm-form-group class="no-margin h6">\n' +
              '    <abm-ui-select model="user.Coach" values="coaches" allowClear="true"></abm-ui-select>\n' +
              '  </abm-form-group></div>\n' +
              '</modal-form>',
            controller: function ($modalInstance, $scope, user, changeCoach, coaches) {
              $scope.user = user;
              $scope.coaches = coaches;
              $scope.ok = function () {
                changeCoach();
                $modalInstance.close();
              };
              $scope.cancel = function () {
                $modalInstance.dismiss();
              };
            },
            backdrop: 'static',
            resolve: {
              changeCoach: function () {
                return $scope.changeCoach;
              },
              user: function () {
                return $scope.user;
              },
              coaches: function () {
                return getCoaches();
              },
            },
          });
        }
      };

      $scope.changeCoach = function () {
        if ($scope.user.Coach) {
          HttpService.post(
            '/api/academy/users/' + $scope.user.id + '/coaches/' + $scope.user.Coach.id,
          )
            .then(function () {
              $scope.options.coachSelect = false;
            })
            .catch(function (error) {
              DialogService.error(error);
              $scope.options.coachSelect = false;
            });
        } else {
          HttpService.delete('/api/academy/users/' + $scope.user.id + '/coaches')
            .then(function () {
              $scope.options.coachSelect = false;
            })
            .catch(function (error) {
              DialogService.error(error);
              $scope.options.coachSelect = false;
            });
        }
      };
    },
  )

  .controller(
    'AcademyUserController',
    function (
      $q,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $sce,
      $http,
      $translate,
      $location,
      $modal,
      $timeout,
      $moment,
      _,
      AcademyService,
      AssetService,
      DialogService,
      CurrentUser,
      HttpService,
      user,
      courses,
      InputSanitizerService,
    ) {
      $scope.user = user;
      $scope.courses = courses;
      $scope.unfinishedCourses = [];
      $scope.finishedCourses = [];
      $scope.options = {};

      $scope.isCoachingScreen = function () {
        return $state.current.name === 'academy.user';
      };

      $scope.reorderCourses = function () {
        _.forEach($scope.unfinishedCourses, function (course, index) {
          course.oldOrderId = course.orderId;
          course.orderId = index + 1;
        });
      };
      var saveReorderCourses = function () {
        $scope.reorderCourses();
        var coursesToSave = _.filter($scope.unfinishedCourses, function (course) {
          return course.oldOrderId !== course.orderId;
        });
        if (coursesToSave.length) {
          HttpService.post(
            '/api/academy/users/' + InputSanitizerService.sanitize($scope.user.id) + '/courses',
            _.map(coursesToSave, function (course) {
              return _.pick(course, ['id', 'orderId']);
            }),
          );
        }
      };

      $scope.refresh = function () {
        _.forEach($scope.user.Courses, function (course) {
          course.chart = AcademyService.chartData(course.progress, 'overall', '#62A6DB');
        });
        $scope.unfinishedCourses = _.orderBy(
          _.filter($scope.user.Courses, function (course) {
            return course.progress < 100;
          }),
          [
            function (o) {
              return o.orderId || '';
            },
            'name',
          ],
        );
        $scope.finishedCourses = _.orderBy(
          _.filter($scope.user.Courses, function (course) {
            return course.progress >= 100;
          }),
          ['orderId', 'name'],
        );

        $scope.user.Company =
          $scope.user.Company ||
          _.find(user.Companies, function (company) {
            return company.UserCompanyRole.default;
          });

        $scope.user.progress = {
          overall:
            $scope.courses.length > 0
              ? Math.round(($scope.finishedCourses.length / $scope.courses.length) * 100)
              : 100,
          roadmap:
            $scope.user.Courses.length > 0
              ? Math.round(_.meanBy($scope.user.Courses, 'progress'))
              : 100,
        };

        $scope.charts = {
          overall: AcademyService.chartData($scope.user.progress.overall, 'overall', '#62A6DB'),
          roadmap: AcademyService.chartData($scope.user.progress.roadmap, 'roadmap', '#62A6DB'),
        };

        saveReorderCourses();
      };

      $scope.openCourseAssets = function (course) {
        var modalInstance = $modal.open({
          templateUrl: '/templates/academy/coaching-course-assets.html',
          controller: 'AcademyUserCourseAssetsModalController',
          // size: 'lg',
          backdrop: 'static',
          resolve: {
            course: function () {
              return course;
            },
            user: function () {
              return $scope.user;
            },
            quizResults: function () {
              return HttpService.get(
                '/api/academy/quizzes/results/' + InputSanitizerService.sanitize($scope.user.id),
              );
            },
          },
        });

        modalInstance.result.then(
          function () {},
          function () {
            var courseAssets = _(course.sections)
              .flatten()
              .map('attachedAssets')
              .flatten()
              .uniq()
              .value();
            course.progress =
              courseAssets.length > 0
                ? Math.round(
                    (_.filter(courseAssets, 'finishedAt').length / courseAssets.length) * 100,
                  )
                : 100;
            course.finishedAt = course.progress === 100;

            $scope.refresh();
          },
        );
      };

      if ($stateParams.courseId) {
        var course = _.find($scope.user.Courses, { id: parseInt($stateParams.courseId) });
        if (course) {
          $scope.openCourseAssets(course);
        }
      }

      $scope.assignCourse = function () {
        var modalInstance = $modal.open({
          template:
            '<modal-form form-title="" onok="ok" oncancel="cancel">' +
            '<input-select model="select.course" values="availableCourses" group-by="attachableAssetGroupBy" group-filter="attachableAssetGroupOrder"></input-select>' +
            '</modal-form>',
          controller: function (
            $scope,
            $rootScope,
            $modalInstance,
            AssetService,
            HttpService,
            courses,
            user,
          ) {
            $scope.select = {};
            $scope.user = user;
            $scope.availableCourses = _(courses)
              .filter(function (course) {
                return (
                  !_.find(user.Courses, { id: course.id }) &&
                  course.SubCategory &&
                  _.difference(
                    course.prerequisites,
                    _($scope.user.Courses).filter('finishedAt').map('id').value(),
                  ).length === 0
                );
              })
              .sortBy(['name', 'id'])
              .value();
            $scope.ok = function (cb) {
              HttpService.post(
                '/api/academy/courses/' +
                  InputSanitizerService.sanitize($scope.select.course.id) +
                  '/assign/' +
                  $scope.user.id,
              )
                .then(function (result) {
                  cb();
                  $modalInstance.close($scope.select.course);
                })
                .catch(function (error) {
                  $scope.errors = [error];
                  cb();
                });
            };
            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
            $scope.attachableAssetGroupBy = function (item) {
              return AssetService.attachableAssetGroupBy(item, false);
            };
            $scope.attachableAssetGroupOrder = function (groups) {
              return _.sortBy(groups, function (group) {
                if (group.items.length > 0) {
                  var item = group.items[0];
                  return (
                    item.SubCategory.Category.orderId * 100 +
                    item.SubCategory.orderId * 10 +
                    item.orderId
                  );
                } else {
                  return 0;
                }
              });
            };
          },
          backdrop: 'static',
          resolve: {
            user: function () {
              return $scope.user;
            },
            courses: function () {
              return $scope.courses;
            },
          },
        });

        modalInstance.result.then(
          function () {
            $rootScope.$broadcast('dataLoadingStarted');
            HttpService.get(
              '/api/academy/users/' + InputSanitizerService.sanitize($scope.user.id),
            ).then(function (user) {
              $scope.user = user;
              $scope.refresh();
              $rootScope.$broadcast('dataLoadingFinished');
            });
            $scope.reorderCourses();
          },
          function () {},
        );
      };

      $scope.removeCourse = function (course) {
        HttpService.delete(
          '/api/academy/courses/' +
            InputSanitizerService.sanitize(course.id) +
            '/assign/' +
            InputSanitizerService.sanitize($scope.user.id),
        )
          .then(function () {
            $rootScope.$broadcast('dataLoadingStarted');
            HttpService.get(
              '/api/academy/users/' + InputSanitizerService.sanitize($scope.user.id),
            ).then(function (user) {
              $scope.user = user;
              $scope.refresh();
              $rootScope.$broadcast('dataLoadingFinished');
            });
          })
          .catch(function (error) {
            DialogService.error(error);
          });
      };

      $scope.movedCallback = function (srcList, srcIndex, targetList, targetIndex) {
        // Copy the item from source to target.
        targetList.splice(targetIndex, 0, srcList[srcIndex]);
        // Remove the item from the source, possibly correcting the index first.
        // We must do this immediately, otherwise ng-repeat complains about duplicates.
        if (srcList == targetList && targetIndex <= srcIndex) {
          srcIndex++;
        }
        srcList.splice(srcIndex, 1);
        saveReorderCourses();
        return true;
      };

      $scope.refresh();
    },
  )

  .controller(
    'AcademyUserTimelineController',
    function (
      $q,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $sce,
      $http,
      $translate,
      $location,
      $modal,
      $timeout,
      $moment,
      _,
      AcademyService,
      AssetService,
      DialogService,
      CurrentUser,
      HttpService,
      user,
      courses,
      InputSanitizerService,
    ) {
      $scope.user = user;
      $scope.courses = courses;
      $scope.userEvents = [];
      $scope.wistiaEvents = [];
      $scope.eventTypes = {
        academyAssetFinished: true,
        academyAssetOpened: true,
        // 'login': true,
        academyCourseAssigned: true,
        academyCourseFinished: true,
        wistiaVideoWatched: true,
      };

      $scope.getAssetTypeIcon = AssetService.getAssetTypeIcon;
      $scope.getAssetTypeIconTitle = AssetService.getAssetTypeIconTitle;

      function parseAssetEvent(event) {
        var eventData = {};
        var asset = event.args.asset; //_.find($scope.user.Assets, {id: parseInt(event.args.id)});
        if (asset) {
          asset.typeName = $translate.instant('label.academy.assetTypes.' + asset.type);
          eventData.type = event.event;
          eventData.date = event.createdAt;
          eventData.assetId = asset.id;
          eventData.asset = {
            type: asset.type,
            typeName: asset.typeName,
            knowledgebaseType: asset.knowledgebaseType,
            name: asset.name,
            category: asset.category,
          };
          if (event.event === 'academyAssetOpened') {
            eventData.eventName =
              asset.typeName + ' ' + $translate.instant('academy.coaching.userView.opened');
          } else {
            eventData.eventName =
              asset.typeName + ' ' + $translate.instant('academy.coaching.userView.completed');
          }
          if (asset.SubCategory) {
            eventData.subTitle =
              asset.typeName +
              ' > ' +
              asset.SubCategory.Category.name +
              ' > ' +
              asset.SubCategory.name +
              ' > ' +
              asset.name;
          } else {
            eventData.subTitle = asset.typeName + ' > ' + asset.name;
          }
        }
        return eventData;
      }

      function parseLoginEvent(event) {
        var eventData = {};
        eventData.eventName = $translate.instant('academy.coaching.userView.login');
        eventData.type = event.event;
        eventData.date = event.createdAt;
        eventData.subTitle = event.args.page;
        //console.log(event);
        return eventData;
      }

      function parsePageViewEvent(event) {
        var eventData = {};
        eventData.eventName = $translate.instant('academy.coaching.userView.pageView');
        eventData.type = event.event;
        eventData.date = event.createdAt;
        eventData.subTitle = event.args.page;
        //console.log(event);
        return eventData;
      }

      function parseWistiaEvent(event) {
        var asset;
        var assetId = event.embed_url.split('/');
        assetId = parseInt(assetId[assetId.length - 1]);
        if (assetId) {
          var eventData = _.cloneDeep(_.find($scope.userEvents, { assetId: assetId }));
          if (eventData) {
            if (event.percent_viewed > 0) {
              eventData.percent_viewed = event.percent_viewed;
              eventData.type = 'wistiaVideoWatched';
              eventData.date = $moment(event.received_at).toDate();
              eventData.eventName = $translate.instant('academy.coaching.userView.videoWatched');
              eventData.videoTitle = event.media_name;
              eventData.videoProgress = Math.floor(event.percent_viewed * 100);
              eventData.chart = AcademyService.chartData(
                eventData.videoProgress,
                'overall',
                '#59BF90',
              );
            }
            return eventData;
          }
        }
      }

      $scope.clickEventFilter = function (type, $event) {
        $scope.eventTypes[type] = !$scope.eventTypes[type];
        $event.preventDefault();
        $event.stopPropagation();
        return false;
      };

      $scope.eventFilter = function (event) {
        return event && $scope.eventTypes[event.type];
      };

      $scope.refreshTimeline = function () {
        $rootScope.$broadcast('dataLoadingStarted');

        $q.all([
          HttpService.get(
            '/api/admin/users/' +
              InputSanitizerService.sanitize($stateParams.userId) +
              '/timeline/?limit=100&event=' +
              InputSanitizerService.sanitize(_.keys($scope.eventTypes)),
          ),
          HttpService.get(
            '/api/academy/wistia/heatmaps/' +
              InputSanitizerService.sanitize($scope.user.email) /*+ '?page=' + $scope.page*/,
          ),
        ])
          .then(function (_results) {
            var userEvents = _results[0];
            var wistiaEvents = _results[1];

            $scope.userEvents = [];
            _.forEach(userEvents, function (event) {
              if (_.startsWith(event.event, 'academy')) {
                $scope.userEvents.push(parseAssetEvent(event));
              } else if (event.event === 'login') {
                $scope.userEvents.push(parseLoginEvent(event));
              } else if (event.event === 'pageView') {
                $scope.userEvents.push(parsePageViewEvent(event));
              }
            });

            _.forEach(wistiaEvents, function (wistiaEvent) {
              var eventData = parseWistiaEvent(wistiaEvent);
              if (eventData) {
                $scope.userEvents.push(eventData);
              }
            });

            $rootScope.$broadcast('dataLoadingFinished');
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            console.error(error);
            DialogService.error(error);
          });
      };

      $scope.refreshTimeline();
    },
  )

  .controller(
    'AcademyUserMeetingsController',
    function (
      $q,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $sce,
      $http,
      $translate,
      $location,
      $modal,
      $timeout,
      $moment,
      _,
      AcademyService,
      AssetService,
      DialogService,
      CurrentUser,
      HttpService,
      user,
      meetings,
      meetingTemplates,
    ) {
      $scope.user = user;
      $scope.meetings = meetings;
      $scope.meetingTemplates = meetingTemplates;

      $scope.canCreateMeeting = function () {
        return (
          CurrentUser.can('listAllPrivateMeeting') ||
          ($scope.user.Coach && $scope.user.Coach.id === CurrentUser.getUser().id)
        );
      };
    },
  )

  .controller(
    'AcademyUserCourseAssetsModalController',
    function (
      $modal,
      $modalInstance,
      $rootScope,
      $scope,
      $stateParams,
      AssetService,
      CurrentUser,
      DialogService,
      HttpService,
      _,
      course,
      quizResults,
      user,
      InputSanitizerService,
    ) {
      $scope.course = course;
      $scope.user = user;
      $scope.quizResults = quizResults;

      _.forEach($scope.course.sections, function (section) {
        _.forEach(section.attachedAssets, function (asset) {
          if ($scope.quizResults[asset.id]) {
            asset.finishedAt = $scope.quizResults[asset.id].finishedAt;
          }
        });
      });

      $scope.isVendor = function () {
        return CurrentUser.hasModule('vendor');
      };
      // _.forEach($scope.course.sections, function (section) {
      //     if (section.quiz && !$scope.quizResults[section.quiz.id]) {
      //         $rootScope.$broadcast('dataLoadingStarted');
      //         HttpService.get('/api/academy/assets/' + section.quiz.id).then(function (quiz) {
      //             $scope.quizResults[section.quiz.id] = {
      //                 assetId: quiz.id,
      //                 name: quiz.name,
      //                 finishedAt: null,
      //                 quizResult: quiz
      //             };
      //             $rootScope.$broadcast('dataLoadingFinished');
      //         });
      //     }
      // });
      $scope.close = function () {
        $modalInstance.dismiss();
      };
      $scope.markAsCompleted = function (asset) {
        // if (asset.type != 'quiz' || asset.VendorId === CurrentUser.getUser().Company.id) {
        AssetService.finish(asset, $scope.user);
        // }
      };
      // $scope.isQuizSuccessful = function (quiz) {
      //     if (quiz) {
      //         return (quiz.quiz.score >= quiz.quiz.passingScore);
      //     } else {
      //         return false;
      //     }
      // };
      $scope.showQuizResultsModal = function (asset) {
        var modalInstance = $modal.open({
          templateUrl: '/templates/academy/coaching-quiz-overview-modal.html',
          controller: function ($modalInstance, $rootScope, $scope, asset, userId, quizResult) {
            $scope.quiz = asset;
            $scope.quizResult = quizResult;
            $scope.setAnswer = function (question, answer) {
              if (question.type === 'singleSelect') {
                _.forEach(question.answers, function (o) {
                  o.checked = o === answer;
                });
              } else {
                answer.checked = !answer.checked;
              }
            };
            $scope.ok = function () {
              $scope.quiz.quiz = $scope.quizResult;
              HttpService.put(
                '/api/academy/assets/' +
                  InputSanitizerService.sanitize(asset.id) +
                  '/finish/' +
                  InputSanitizerService.sanitize(userId),
                { quiz: $scope.quiz },
              ).then(
                function (userAcademyAssetResult) {
                  $modalInstance.close(userAcademyAssetResult);
                },
                function (error) {
                  DialogService.error(error);
                },
              );
            };
            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
          backdrop: 'static',
          resolve: {
            asset: function () {
              return asset;
            },
            userId: function () {
              return $scope.user.id;
            },
            quizResult: function () {
              return $scope.quizResults[asset.id].quizResult;
            },
          },
        });
        modalInstance.result.then(
          function (quizResult) {
            $scope.quizResults[asset.id] = quizResult;
          },
          function () {},
        );
      };
      $scope.makeQuizResult = function (asset) {
        HttpService.put(
          '/api/academy/assets/' +
            InputSanitizerService.sanitize(asset.id) +
            '/finish/' +
            InputSanitizerService.sanitize($scope.user.id),
          { quiz: asset },
        ).then(
          function (userAcademyAssetResult) {
            $scope.quizResults[asset.id] = userAcademyAssetResult;
          },
          function (error) {
            DialogService.error(error);
          },
        );
      };
    },
  )

  .controller(
    'AcademyController',
    function (
      $scope,
      $rootScope,
      $state,
      $window,
      $location,
      $http,
      $moment,
      $translate,
      _,
      CurrentUser,
      AcademyService,
    ) {
      $scope.currentUser = CurrentUser.getUser();
    },
  )

  // .controller('AcademyRoadmapController', function ($scope, $http, $moment, _, CurrentUser, AcademyService, roadmap) {
  //     $scope.currentUser = CurrentUser.getUser();
  //     $scope.roadmap = roadmap;
  //
  //     $scope.roadmap.Goals.forEach(function (goal) {
  //         goal.Projects.forEach(function (project) {
  //             var _project = _.find($scope.roadmap.Projects, {id: project.id});
  //             if (_project) {
  //                 project.finishedAt = _project.UserAcademyProject.finishedAt;
  //             }
  //         })
  //     });
  //
  //     // $scope.getColor = AcademyService.getColor;
  //     $scope.deadlineClass = AcademyService.deadlineClass;
  //     $scope.goalPercentage = AcademyService.goalPercentage;
  //
  // })

  // .controller('AcademyMembersController', function ($scope, _, AcademyService, CurrentUser, members) {
  //     $scope.filter = {name: ''};
  //     $scope.members = members;
  //     $scope.nameFilter = function (item) {
  //         return !$scope.filter.name ||
  //             (item.name && item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1) ||
  //             (item.description && item.description.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1) ||
  //             (item.Services && _.find(item.Services, function (service) {
  //                 return service.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1 ||
  //                     (service.description && service.description.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1)
  //             }))
  //     };
  // })

  .controller(
    'AacdemyMembersController',
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $http,
      $modal,
      $timeout,
      $translate,
      _,
      HttpService,
      CurrentUser,
      companies,
    ) {
      $scope.currentUser = CurrentUser.getUser();
      $scope.companies = companies;
      $scope.filter = {
        name: '',
      };

      $scope.companyFilter = function (item) {
        return (
          !$scope.filter.name ||
          item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1 ||
          (item.domain && item.domain.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1)
        );
      };

      $scope.myCompanyFilter = function (item) {
        return item.CompanyVendor.CoachId === $scope.currentUser.id;
      };

      // $scope.$watch('filter.name', function (newVal, oldVal) {
      //     if ($scope.filter.name.length >= 3) {
      //         if ($scope.filter.timeout) {
      //             $timeout.cancel($scope.filter.timeout);
      //         }
      //         $scope.filter.timeout = $timeout(function () {
      //             $scope.refresh();
      //         }, 1000);
      //     }
      // });

      // $scope.create = function () {
      //     var modalInstance = $modal.open({
      //         templateUrl: '/templates/vendor/member-invite.html',
      //         controller: 'MemberInviteController',
      //         backdrop: 'static',
      //         resolve: {
      //             permissiongroups: function () {
      //                 return $scope.permissionGroups;
      //             },
      //             users: function (CurrentUser, HttpService) {
      //                 return HttpService.get('/api/admin/users?companyId=' + CurrentUser.getUser().Company.id);
      //             }
      //         }
      //         //size: 'lg',
      //     });
      //
      //     modalInstance.result.then(function () {
      //         $state.transitionTo('tools.members', {}, {
      //             reload: true,
      //             inherit: false,
      //             notify: true
      //         });
      //     }, function () {
      //     });
      // };
    },
  )

  .controller(
    'AcademyRoadmapAddRemoveUserModalController',
    function ($scope, $modalInstance, _, users, name, type) {
      $scope.item = { name: name, userIds: [], formTitle: 'label.academy.roadmapView.addUser' };
      $scope.users = users;
      $scope.type = type;
      $scope.ok = function () {
        if ($scope.item.userId) {
          $scope.item.userIds.push($scope.item.userId);
        } else {
          _.forEach($scope.users, function (user) {
            if (user.checked) {
              $scope.item.userIds.push(user.id);
            }
          });
        }
        $modalInstance.close($scope.item.userIds);
      };
      $scope.cancel = function () {
        $modalInstance.dismiss();
      };
      $scope.getContactName = function (item) {
        return item
          ? (item.firstName || item.name || '') +
              ' ' +
              (item.lastName || '') +
              (item.email ? ' <' + item.email + '>' : '')
          : '';
      };
    },
  )

  .controller(
    'AcademyRoadmapController',
    function (
      $filter,
      $modal,
      $moment,
      $q,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $translate,
      $http,
      _,
      AcademyService,
      AssetService,
      DialogService,
      CurrentUser,
      HttpService,
      roadmapView,
      InputSanitizerService,
    ) {
      $scope.currentUser = $scope.currentUser || CurrentUser.getUser();
      $scope.hasModule = CurrentUser.hasModule;
      $scope.roadmapView = roadmapView;
      $scope.companies = [];
      $scope.options = { progress: false, tab: 'general' };
      $scope.currentUser = CurrentUser.getUser();

      if (CurrentUser.hasModule('vendor')) {
        HttpService.get('/api/vendor/members').then(function (members) {
          var companies = members;
          if (!_.find(companies, { id: $scope.currentUser.Company.id })) {
            companies.push($scope.currentUser.Company);
          }
          $scope.companies = _.orderBy(companies, 'name');
        });
      }

      $scope.getAssetTypeIconTitle = AssetService.getAssetTypeIconTitle;
      $scope.getAssetTypeIcon = AssetService.getAssetTypeIcon;

      $scope.$watch('options.tab', function (newValue, oldValue) {
        if (newValue !== oldValue) {
          $scope.refresh();
        }
      });

      $scope.update = function () {
        _.forEach($scope.roadmapView.sections, function (section) {
          section.attachedCourses = _.filter($scope.roadmapView.AttachedAssets, function (course) {
            return course.AcademyAssetAttachedAsset.SectionId == section.id;
          });
        });
        _.remove($scope.roadmapView.sections, function (section) {
          return section.attachedCourses.length === 0;
        });

        if ($scope.options.progress) {
          $scope._assets = _($scope.roadmapView.AttachedAssets).map('AttachedAssets').flatten();

          $scope.team = {
            progress: {
              overall: $scope._assets.size()
                ? _.round((getFinishedAssets().size() / $scope._assets.size()) * 100)
                : 100,
              last30days: $scope._assets.size()
                ? _.round((getFinishedAssets(null, 30).size() / $scope._assets.size()) * 100)
                : 100,
              last90days: $scope._assets.size()
                ? _.round((getFinishedAssets(null, 90).size() / $scope._assets.size()) * 100)
                : 100,
            },
          };
          $scope.team.charts = calculateChartData($scope.team);

          _.forEach($scope.roadmapView.Users, function (user) {
            user.progress = {
              overall: $scope._assets.size()
                ? _.round((getUserFinishedAssets(user.id).size() / $scope._assets.size()) * 100)
                : 100,
              last30days: $scope._assets.size()
                ? _.round((getUserFinishedAssets(user.id, 30).size() / $scope._assets.size()) * 100)
                : 100,
              last90days: $scope._assets.size()
                ? _.round((getUserFinishedAssets(user.id, 90).size() / $scope._assets.size()) * 100)
                : 100,
            };
            user.charts = calculateChartData(user);
          });

          _.forEach($scope.roadmapView.AttachedAssets, function (course) {
            course.progress = {
              overall: course.AttachedAssets.length
                ? _.round(
                    (getCourseFinishedAssets(course).size() / course.AttachedAssets.length) * 100,
                  )
                : 100,
              last30days: course.AttachedAssets.length
                ? _.round(
                    (getCourseFinishedAssets(course, 30).size() / course.AttachedAssets.length) *
                      100,
                  )
                : 100,
              last90days: course.AttachedAssets.length
                ? _.round(
                    (getCourseFinishedAssets(course, 90).size() / course.AttachedAssets.length) *
                      100,
                  )
                : 100,
            };
            course.charts = calculateChartData(course);

            $scope.timelineItems = [];

            _.forEach($scope.roadmapView.Users, function (user) {
              _.forEach(user.Assets, function (asset) {
                $scope.timelineItems.push({
                  time: $moment(asset.UserAcademyAsset.finishedAt).format('HH:mm'),
                  day: $filter('date')(asset.UserAcademyAsset.finishedAt, 'shortDate'), //$moment(asset.finishedAt).format('D/M/YY'),
                  name: user.firstName + ' ' + user.lastName,
                  asset: asset,
                  userId: user.id,
                });
              });
            });
          });
        }
      };

      $scope.update();

      $scope.userAssignedFilter = function (user) {
        return user.UserAcademyAsset.assignedAt;
      };

      $scope.refresh = function () {
        $scope.options.progress = _.includes(['timeline', 'progress'], $scope.options.tab);
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.get(
          '/api/academy/roadmapviews/' +
            InputSanitizerService.sanitize($stateParams.roadmapId) +
            '?progress=' +
            InputSanitizerService.sanitize($scope.options.progress) +
            ($stateParams.companyId
              ? '&companyId=' + InputSanitizerService.sanitize($stateParams.companyId)
              : ''),
        )
          .then(function (roadmapView) {
            $scope.roadmapView = roadmapView;
            $scope.update();
            $rootScope.$broadcast('dataLoadingFinished');
          })
          .catch(function (error) {
            DialogService.error(error);
          });
      };

      // $scope.companySelect = function(item, model){
      //     if (item.id + '' != model + ''){
      //         $state.go('academy.roadmap', {roadmapId: $stateParams.roadmapId, companyId: item.id});
      //     }
      // };

      $scope.$watch('roadmapView.Company.id', function (newValue, oldValue) {
        if (newValue != oldValue) {
          $state.go('academy.roadmap', { roadmapId: $stateParams.roadmapId, companyId: newValue });
        }
      });

      $scope.canEdit = function () {
        return roadmapView.VendorId == $scope.currentUser.Company.id;
      };

      $scope.starRoadmap = function () {
        $scope.roadmapView.starred = !$scope.roadmapView.starred;
        HttpService.put(
          '/api/academy/roadmapviews/' +
            InputSanitizerService.sanitize($stateParams.roadmapId) +
            ($stateParams.companyId
              ? '?companyId=' + InputSanitizerService.sanitize($stateParams.companyId)
              : ''),
          { starred: roadmapView.starred },
        ).then(function () {});
      };

      $scope.addRemoveUsers = function (courseAsset) {
        var modalInstance = $modal.open({
          template:
            '<modal-form form-title="{{item.formTitle}}" title-values="{name: item.name}" onok="ok" oncancel="cancel">' +
            '    <input-checkbox ng-repeat="user in users" model="user.selected" label="{{user.name}}" full-size="true"></input-checkbox>' +
            // '    <input-multiselect model="item.users" values="users" allow-clear="true"></input-multiselect>' +
            '</modal-form>',
          controller: function ($scope, $rootScope, $modalInstance, HttpService, users) {
            $scope.users = _.map(users, function (user) {
              var result = _.extend(
                {
                  name: user.firstName + ' ' + user.lastName + ' <' + user.email + '>',
                  selected: !!_.find(user.Assets, function (asset) {
                    return asset.id == courseAsset.id && asset.UserAcademyAsset.assignedAt;
                  }),
                },
                user,
              );
              result._selected = result.selected;
              return result;
            });
            $scope.name = '"' + courseAsset.name + '"';

            $scope.ok = function (cb) {
              $rootScope.$broadcast('dataLoadingStarted');
              var arr = [];
              _.forEach($scope.users, function (user) {
                if (user.selected != user._selected) {
                  var func = user.selected ? 'post' : 'delete';
                  arr.push(
                    HttpService[func](
                      '/api/academy/courses/' +
                        InputSanitizerService.sanitize(courseAsset.id) +
                        '/assign/' +
                        InputSanitizerService.sanitize(user.id),
                    ),
                  );
                }
              });
              $q.all(arr)
                .then(function () {
                  $modalInstance.close();
                })
                .catch(function (error) {
                  $scope.error = error;
                  cb();
                });
            };
            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
          backdrop: 'static',
          resolve: {
            users: function () {
              return $scope.roadmapView.Users;
            },
          },
        });
        modalInstance.result.then(
          function () {
            $scope.refresh();
          },
          function () {},
        );
      };

      $scope.openCourseAssets = function (course, user) {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.get(
          '/api/academy/users/' +
            InputSanitizerService.sanitize(user.id) +
            '?assetIds=' +
            InputSanitizerService.sanitize(course.id) +
            ',' +
            InputSanitizerService.sanitize(_(course.AttachedAssets).map('id').join(',')),
        ).then(function (userResult) {
          var _course = _.find(userResult.Courses, { id: course.id });
          var modalInstance = $modal.open({
            templateUrl: '/templates/academy/coaching-course-assets.html',
            controller: 'AcademyUserCourseAssetsModalController',
            // size: 'lg',
            backdrop: 'static',
            resolve: {
              course: function () {
                return _course;
              },
              user: function () {
                return userResult;
              },
              quizResults: function () {
                return HttpService.get(
                  '/api/academy/quizzes/results/' + InputSanitizerService.sanitize(user.id),
                );
              },
            },
          });
          $rootScope.$broadcast('dataLoadingFinished');

          modalInstance.result.then(
            function () {},
            function () {
              $scope.refresh();
            },
          );
        });
      };

      $scope.openBulkAssign = function () {
        var modalInstance = $modal.open({
          templateUrl: '/templates/academy/roadmap-bulk-assign.html',
          controller: function ($scope, $modalInstance, courses, users) {
            $scope.courses = _.map(courses, function (course) {
              course.checkpoints = _(course.AttachedAssets)
                .filter({ quizType: 'checkpoint' })
                .map(function (checkpoint) {
                  checkpoint.orderId = _.indexOf(
                    course.sections,
                    _.find(course.sections, { id: checkpoint.AcademyAssetAttachedAsset.SectionId }),
                  );
                  return checkpoint;
                })
                .value();
              return course;
            });
            $scope.users = _.map(users, function (user) {
              user.name = user.firstName + ' ' + user.lastName;
              return user;
            });
            $scope.result = {};

            $scope.ok = function () {
              HttpService.post('/api/academy/courses/assign', {
                userIds: $scope.result.selectedUserIds,
                courseIds: _($scope.courses).filter({ $$selected: true }).map('id').value(),
                quizIds: _($scope.courses)
                  .filter({ $$selected: true })
                  .flatMap('checkpoints')
                  .filter({ $$selected: true })
                  .map('id')
                  .value(),
              }).then(function (result) {
                DialogService.message(
                  $translate.instant('label.academy.roadmapView.bulkAssign.success', result),
                );
              });
              $modalInstance.close();
            };

            $scope.cancel = function () {
              $modalInstance.dismiss();
            };

            $scope.selectCourse = function (course) {
              course.$$selected = !course.$$selected;
              if (!course.$$selected) {
                _.forEach(course.checkpoints, function (checkpoint) {
                  checkpoint.$$selected = false;
                });
              }
            };

            $scope.selectCheckpoint = function (course, checkpoint) {
              checkpoint.$$selected = !checkpoint.$$selected;
              if (checkpoint.$$selected) {
                course.$$selected = true;
                _.forEach(course.checkpoints, function (other) {
                  if (other.orderId < checkpoint.orderId) {
                    other.$$selected = true;
                  }
                });
              }
            };
          },
          // size: 'lg',
          backdrop: 'static',
          resolve: {
            courses: function () {
              return _.flatMap($scope.roadmapView.sections, 'attachedCourses');
            },
            users: function () {
              return $scope.roadmapView.Users;
            },
          },
        });

        modalInstance.result.then(
          function () {
            $scope.refresh();
          },
          function () {},
        );
      };

      // function getAssets(userId, course) {
      //     return $scope._assets.filter(function (asset) {
      //         return (!course || _.find(course.AttachedAssets, {id: asset.id})) && (!userId || _.find(asset.Users, function (user) {
      //             return userId === user.id;
      //         }));
      //     });
      // }

      // function getFinishedAssets(userId, days, course) {
      //     if (_.isObject(days)) {
      //         course = days;
      //         days = undefined;
      //     }
      //     var fromDay = $moment().subtract(days, 'day').startOf('day');
      //     return $scope._finishedAssets.filter(function (asset) {
      //         return (!course || _.find(course.AttachedAssets, {id: asset.id})) && _.find(asset.Users, function (user) {
      //             return (!userId || userId === user.id) && (!days || $moment(user.UserAcademyAsset.finishedAt).isAfter(fromDay));
      //         });
      //     });
      // }

      $scope.getCourseUsers = function (course) {
        return _.filter($scope.roadmapView.Users, function (user) {
          return !!_.find(user.Assets, function (asset) {
            return asset.id == course.id && asset.UserAcademyAsset.assignedAt;
          });
        });
      };

      function getFinishedAssets(days) {
        var fromDay = $moment().subtract(days, 'day').startOf('day');

        return _($scope.roadmapView.Users)
          .flatMap('Assets')
          .filter(function (asset) {
            return (
              asset &&
              asset.UserAcademyAsset.finishedAt &&
              (!days || $moment(asset.UserAcademyAsset.finishedAt).isAfter(fromDay))
            );
          });
      }

      function getUserFinishedAssets(userId, days) {
        var fromDay = $moment().subtract(days, 'day').startOf('day');

        return _(_.get(_.find($scope.roadmapView.Users, { id: userId }), 'Assets')).filter(
          function (asset) {
            return (
              asset.UserAcademyAsset.finishedAt &&
              (!days || $moment(asset.UserAcademyAsset.finishedAt).isAfter(fromDay))
            );
          },
        );
      }

      function getCourseFinishedAssets(course, days) {
        var fromDay = $moment().subtract(days, 'day').startOf('day');

        return _(course.AttachedAssets)
          .filter(function (asset) {
            return !!_($scope.roadmapView.Users)
              .flatMap('Assets')
              .find(function (userAsset) {
                return (
                  userAsset &&
                  asset &&
                  userAsset.id == asset.id &&
                  userAsset.UserAcademyAsset.finishedAt &&
                  (!days || $moment(userAsset.UserAcademyAsset.finishedAt).isAfter(fromDay))
                );
              });
          })
          .uniqBy('id');

        // return _($scope.roadmapView.Users).flatMap('Assets').filter(function (asset) {
        //     var _asset = _.find($scope._assets, {id: asset.id});
        //     return _asset && _asset.AcademyAssetAttachedAsset.CourseId == course.id &&
        //         asset.UserAcademyAsset.finishedAt && (!days || $moment(asset.UserAcademyAsset.finishedAt).isAfter(fromDay));
        // }).uniqBy('id');
      }

      function calculateChartData(item) {
        return {
          overall: AcademyService.chartData(item.progress.overall, 'overall', '#62A6DB'),
          last90days: AcademyService.chartData(item.progress.last90days, 'last90days', '#62A6DB'),
          last30days: AcademyService.chartData(item.progress.last30days, 'last30days', '#62A6DB'),
        };
      }

      $scope.generateCoursePopover = function (course) {
        var popoverBody = '';
        if (course.subtitle) {
          popoverBody = '<p><b>' + course.subtitle + '</b></p>';
        }
        if (course.overview) {
          popoverBody = popoverBody + '<br>' + course.overview;
        }
        if (course.AttachedAssets && course.AttachedAssets.length) {
          popoverBody = popoverBody + '<p><b>Attached Assets:</b></p>';
          _.forEach(course.AttachedAssets, function (asset) {
            popoverBody += '<br>' + asset.name;
            if (asset.timeToFinish !== null) {
              popoverBody += ' - ' + asset.timeToFinish;
            }
          });
        }
        return popoverBody;
      };

      $scope.sendToVcio = function (system) {
        if (
          system === 'connectwise' &&
          !_.get($scope.currentUser.Company.settings, 'myCompany.connectwise.id')
        ) {
          DialogService.error(
            $translate.instant('message.externalSystems.connectwise.noDefaultClient'),
          );
        } else {
          var modalInstance = $modal.open({
            templateUrl: '/templates/vcio/asset-to-vcio.html',
            controller: 'VcioAssetToVcioController',
            backdrop: 'static',
            //size: 'lg',
            resolve: {
              asset: function () {
                return $scope.roadmapView;
              },
              system: function () {
                return system;
              },
            },
          });

          modalInstance.result.then(
            function () {},
            function () {},
          );
        }
      };
    },
  )

  // .controller('AcademyRoadmapViewController', function ($filter, $modal, $moment, $q, $rootScope, $scope, $state, $stateParams, $translate, $http, _, AcademyAssetService, AcademyService, AssetManager, DialogService, CurrentUser, HttpService, VcioService, company, roadmapview) {
  //     $scope.roadmapView = roadmapview;
  //     $scope.currentUser = CurrentUser.getUser();
  //     $scope.teamFinishedAssets = {};
  //     $scope.teamFinishedAssets90 = {};
  //     $scope.teamFinishedAssets30 = {};
  //     $scope.teamAssignedAssets = {};
  //     $scope.userCourses = [];
  //     $scope.currentView = 'general';
  //     $scope.timelineItems = [];
  //     $scope.team = {};
  //     $scope.company = company;
  //
  //     // 0. roadmap overall
  //     // 1. user overall
  //     // 2. course overall
  //     // $scope.overall = $scope._finishedAssets.size() / $scope._assets.size();
  //
  //     function getAssets(userId, course) {
  //         return $scope._assets.filter(function (asset) {
  //             return (!course || _.find(course.AttachedAssets, {id: asset.id})) && (!userId || _.find(asset.Users, function (user) {
  //                     return userId === user.id;
  //                 }));
  //         });
  //     }
  //
  //     function getFinishedAssets(userId, days, course) {
  //         if (_.isObject(days)) {
  //             course = days;
  //             days = undefined;
  //         }
  //         var fromDay = $moment().subtract(days, 'day').startOf('day');
  //         return $scope._finishedAssets.filter(function (asset) {
  //             return (!course || _.find(course.AttachedAssets, {id: asset.id})) && _.find(asset.Users, function (user) {
  //                     return (!userId || userId === user.id) && (!days || $moment(user.UserAcademyAsset.finishedAt).isAfter(fromDay));
  //                 });
  //         });
  //     }
  //
  //     $scope.isVendor = function () {
  //         return CurrentUser.hasModule('vendor');
  //     };
  //
  //     $scope.isDisabled = function (asset) {
  //         return asset && asset.type == 'course' && !asset.prerequisiteDone;
  //     };
  //
  //     $scope.goCourse = function (course) {
  //         if (!$scope.isDisabled(course)) {
  //             $state.go('academy.course', {courseId: course.id});
  //         }
  //     };
  //
  //     $scope.update = function () {
  //         _.forEach($scope.roadmapView.Roadmap.sections, function (section) {
  //             section.attachedCourses = _.filter($scope.roadmapView.Roadmap.AttachedAssets, function (course) {
  //                 return course.AcademyAssetAttachedAsset.SectionId == section.id
  //             });
  //         });
  //         _.remove($scope.roadmapView.Roadmap.sections, function (section) {
  //             return section.attachedCourses.length == 0;
  //         });
  //
  //         $scope._assets = _($scope.roadmapView.Roadmap.AttachedAssets).map('AttachedAssets').flatten();
  //         $scope._finishedAssets = $scope._assets.filter(function (asset) {
  //             return _(asset.Users).filter(function (user) {
  //                     return user.UserAcademyAsset && user.UserAcademyAsset.finishedAt
  //                 }).size() > 0
  //         });
  //
  //         $scope.team = {
  //             progress: {
  //                 overall: _.round(getFinishedAssets().size() / getAssets().size() * 100),
  //                 last30days: _.round(getFinishedAssets(null, 30).size() / getAssets().size() * 100),
  //                 last90days: _.round(getFinishedAssets(null, 90).size() / getAssets().size() * 100)
  //             }
  //         }
  //         $scope.team.charts = calculateChartData($scope.team);
  //
  //         _.forEach($scope.roadmapView.Users, function (user) {
  //             user.progress = {
  //                 overall: _.round(getFinishedAssets(user.id).size() / getAssets().size() * 100),
  //                 last30days: _.round(getFinishedAssets(user.id, 30).size() / getAssets().size() * 100),
  //                 last90days: _.round(getFinishedAssets(user.id, 90).size() / getAssets().size() * 100)
  //             };
  //             user.charts = calculateChartData(user);
  //         });
  //
  //         _.forEach($scope.roadmapView.Roadmap.AttachedAssets, function (course) {
  //             course.progress = {
  //                 overall: _.round(getFinishedAssets(null, course).size() / getAssets(null, course).size() * 100),
  //                 last30days: _.round(getFinishedAssets(null, course, 30).size() / getAssets(null, course).size() * 100),
  //                 last90days: _.round(getFinishedAssets(null, course, 90).size() / getAssets(null, course).size() * 100)
  //             };
  //             course.charts = calculateChartData(course);
  //         });
  //
  //     };
  //
  //     $scope.refresh = function () {
  //         $rootScope.$broadcast('dataLoadingStarted');
  //         HttpService.get('/api/academy/roadmapviews/' + $stateParams.roadmapviewId)
  //             .then(function (roadmapView) {
  //                 $scope.roadmapView = roadmapView;
  //
  //                 $scope.update();
  //                 // calculateUserFinishedCourses();
  //                 // _.forEach($scope.roadmapView.Roadmap.AttachedAssets, function (item) {
  //                 //     $scope.userCourses.push(item.id);
  //                 //     item.users = getAssignedUsersForCourse(item);
  //                 //     item.progress = {};
  //                 //     item.charts = {};
  //                 //     item.progress = calculateCourseProgress(item);
  //                 //     item.charts = calculateChartData(item);
  //                 //     item.prerequisiteDone = true;//FIXME: AssetManager.isPrerequisiteDone(item.prerequisites);
  //                 // });
  //                 // _.forEach($scope.roadmapView.Users, function (user) {
  //                 //     user.progress = calculateUserProgress(user);
  //                 //     user.charts = calculateChartData(user);
  //                 // });
  //                 // $scope.team.progress = calculateTeamProgress();
  //                 // $scope.team.charts = calculateChartData($scope.team);
  //                 $rootScope.$broadcast('dataLoadingFinished');
  //             })
  //             .catch(function (error) {
  //                 DialogService.error(error);
  //             })
  //     };
  //
  //     $scope.update();
  //
  //     $scope.openCourseAssets = function (course, user) {
  //         $rootScope.$broadcast('dataLoadingStarted');
  //         HttpService.get('/api/academy/users/' + user.id)
  //             .then(function (userResult) {
  //                 $rootScope.$broadcast('dataLoadingFinished');
  //                 var _course = _.find(userResult.Courses, {id: course.id});
  //                 var modalInstance = $modal.open({
  //                     templateUrl: '/templates/academy/coaching-course-assets.html',
  //                     controller: 'AcademyUserCourseAssetsModalController',
  //                     // size: 'lg',
  //                     backdrop: 'static',
  //                     resolve: {
  //                         course: function () {
  //                             return _course;
  //                         },
  //                         user: function () {
  //                             return userResult;
  //                         },
  //                         quizzes: function () {
  //                             return HttpService.get('/api/academy/quizzes/results/' + user.id);
  //                         }
  //                     }
  //                 });
  //
  //                 modalInstance.result.then(function () {
  //                 }, function () {
  //                     $scope.refresh();
  //                 });
  //             });
  //     };
  //
  //     $scope.addUser = function () {
  //         $rootScope.$broadcast('dataLoadingStarted');
  //         HttpService.get('/api/companies/' + $stateParams.companyId + '/users')
  //             .then(function (companyUsers) {
  //                 $rootScope.$broadcast('dataLoadingFinished');
  //
  //                 var modalInstance = $modal.open({
  //                     templateUrl: '/templates/academy/manage/roadmap-view-add-user-modal.html',
  //                     controller: 'AcademyRoadmapAddUserModalController',
  //                     backdrop: 'static',
  //                     //size: 'lg',
  //                     resolve: {
  //                         name: function () {
  //                             return '"' + $scope.roadmapView.name + '"';
  //                         },
  //                         users: function () {
  //                             return _.filter(companyUsers, function (o) {
  //                                 return !_.find($scope.roadmapView.Users, {id: o.id});
  //                             });
  //                         }
  //                     }
  //                 });
  //
  //                 modalInstance.result.then(function (userIds) {
  //                     $rootScope.$broadcast('dataLoadingStarted');
  //                     var arr = [];
  //                     _.forEach(userIds, function (userId) {
  //                         arr.push(HttpService.post('/api/academy/roadmapviews/' + $stateParams.roadmapviewId + '/users/' + userId));
  //                     });
  //                     $q.all(arr).then(function () {
  //                         $scope.refresh();
  //                     });
  //                 }, function () {
  //                 });
  //             })
  //     };
  //     $scope.assignUser = function (courseAsset) {
  //         var modalInstance = $modal.open({
  //             templateUrl: '/templates/academy/manage/roadmap-view-add-user-modal.html',
  //             controller: 'AcademyRoadmapAddUserModalController',
  //             backdrop: 'static',
  //             resolve: {
  //                 name: function () {
  //                     return '"' + courseAsset.name + '"';
  //                 },
  //                 users: function () {
  //                     return _.filter($scope.roadmapView.Users, function (o) {
  //                         return !_.find(courseAsset.users, {id: o.id});
  //                     });
  //                 }
  //             }
  //         });
  //         modalInstance.result.then(function (userIds) {
  //             $rootScope.$broadcast('dataLoadingStarted');
  //             var arr = [];
  //             _.forEach(userIds, function (userId) {
  //                 arr.push(HttpService.post('/api/academy/courses/' + courseAsset.id + '/assign/' + userId));
  //             });
  //             $q.all(arr).then(function () {
  //                 $scope.refresh();
  //             });
  //         }, function () {
  //         });
  //     };
  //     $scope.removeUser = function (user) {
  //         if (user) {
  //             $rootScope.$broadcast('dataLoadingStarted');
  //             HttpService.delete('/api/academy/roadmapviews/' + $stateParams.roadmapviewId + '/users/' + user.id)
  //                 .then(function () {
  //                     $scope.refresh();
  //                 });
  //         }
  //     };
  //     $scope.removeUserFromCourse = function (courseAsset) {
  //         var modalInstance = $modal.open({
  //             templateUrl: '/templates/academy/manage/roadmap-view-add-user-modal.html',
  //             controller: 'AcademyRoadmapRemoveUserModalController',
  //             backdrop: 'static',
  //             resolve: {
  //                 name: function () {
  //                     return '"' + courseAsset.name + '"';
  //                 },
  //                 users: function () {
  //                     return _.filter($scope.roadmapView.Users, function (o) {
  //                         return _.find(courseAsset.users, {id: o.id});
  //                     });
  //                 }
  //             }
  //         });
  //
  //         modalInstance.result.then(function (userIds) {
  //             $rootScope.$broadcast('dataLoadingStarted');
  //             var arr = [];
  //             _.forEach(userIds, function (userId) {
  //                 arr.push(HttpService.delete('/api/academy/courses/' + courseAsset.id + '/assign/' + userId));
  //             });
  //             $q.all(arr).then(function () {
  //                 $scope.refresh();
  //             });
  //         }, function () {
  //         });
  //     };
  //
  //     $scope.setView = function (view) {
  //         if (view === 'timeline') {
  //             $scope.currentView = 'timeline';
  //         } else {
  //             $scope.currentView = 'general';
  //         }
  //     }
  //
  //     function chartData(value, label, color) {
  //         return {
  //             data: [{
  //                 value: value,
  //                 color: color || '#e7e7e7',
  //                 label: $translate.instant(label)
  //             }, {
  //                 value: (100 - value),
  //                 color: "#EEEEEE",
  //                 highlight: "#FFFFFF"
  //             }],
  //             options: {
  //                 width: 120,
  //                 height: 120,
  //                 responsive: true,
  //                 animation: false,
  //                 showTooltips: false,
  //                 percentageInnerCutout: 85
  //             }
  //         };
  //     }
  //
  //     function calculateChartData(item) {
  //         return {
  //             overall: chartData(item.progress.overall, 'overall', '#AA2B80'),
  //             last90days: chartData(item.progress.last90days, 'last90days', '#AA2B80'),
  //             last30days: chartData(item.progress.last30days, 'last30days', '#AA2B80')
  //         }
  //     }
  //
  // })

  .controller(
    'AcademyImagesController',
    function (
      $controller,
      $location,
      $rootScope,
      $scope,
      _,
      DialogService,
      HttpService,
      UploadService,
    ) {
      $scope.images = [];
      $scope.selectedImages = [];
      $scope.messages = [];
      $scope.errors = [];

      $scope.uploadImageController = function () {
        return $controller(UploadService.uploadImageController, {
          $scope: $scope,
          $modalInstance: null,
          data: {
            obj: {
              uploadUrl: '/api/academy/images',
            },
          },
        }).constructor;
      };

      $scope.refresh = function () {
        HttpService.get('/api/academy/images').then(function (images) {
          $scope.images = _.map(images, function (image) {
            return _.extend(image, { url: '/images/' + image.id });
          });
        });
      };
      $scope.refresh();

      $scope.uploadImage = function (cb) {
        $scope.upload(function (error, data) {
          if (data) {
            if (_.find($scope.images, { id: data.imageId })) {
              _.remove($scope.images, { id: data.imageId });
            }
            $scope.images.unshift({
              id: data.imageId,
              filename: $scope.file2upload.filename,
              url: '/images/' + data.imageId + '?' + Date.now(),
            });
            cb();
          } else {
            cb();
          }
        });
      };

      $scope.isSelected = function (image) {
        return _.find($scope.selectedImages, { id: image.id });
      };

      $scope.selectImage = function (image) {
        if (!_.find($scope.selectedImages, { id: image.id })) {
          $scope.selectedImages.push(
            _.extend(image, { url: 'https://' + $location.host() + image.url }),
          );
        } else {
          _.remove($scope.selectedImages, { id: image.id });
        }
      };

      $scope.deleteImages = function () {
        if (!_.isEmpty($scope.selectedImages)) {
          $rootScope.$broadcast('dataLoadingStarted');
          HttpService.delete(
            '/api/academy/images/?imageIds=' + _($scope.selectedImages).map('id').join(','),
          )
            .then(function (result) {
              $scope.selectedImages = [];
              $scope.refresh();
              $rootScope.$broadcast('dataLoadingFinished');
            })
            .catch(function (error) {
              $rootScope.$broadcast('dataLoadingFinished');
              DialogService.error(error);
            });
        }
      };
    },
  );

// .controller('AcademyCoachingController', function ($scope, $state, $stateParams, $sce, $http, $translate, $location, $modal, $timeout, $moment, _, AcademyService, DialogService, CurrentUser, /*goals, trainings, groupCoachings, */users, teamMembers) {
//     $scope.currentUser = CurrentUser.getUser();
//     $scope.selectedUser = null;
//
//     $scope.goals = [];// goals;
//     $scope.trainings = [];//trainings;
//     $scope.groupCoachings = [];//groupCoachings;
//     $scope.expanded = {};
//     $scope.filter = {};
//
//     users = _.uniqBy(_.concat(users, teamMembers), 'id');
//
//     $scope.coachFilterTypes = [
//         {
//             label: $translate.instant('label.academy.myUsers'),
//             filter: function (item) {
//                 return item.CoachId == $scope.currentUser.id;
//             }
//         }, {
//             label: $translate.instant('label.academy.allUsers'),
//             filter: function () {
//                 return true;
//             }
//         }, {
//             label: $translate.instant('label.academy.withoutCoach'),
//             filter: function (item) {
//                 return !item.CoachId;
//             }
//         }];
//
//     $scope.filter = {coach: $scope.coachFilterTypes[0]};
//     $scope.coachTypeFilter = $scope.filter.coach.filter;
//     $scope.users = users;
//
//     $scope.can = function (right) {
//         return CurrentUser.can(right);
//     };
//
//     $scope.calculateRoadmap = function () {
//
//         $scope.selectedUser.Goals.forEach(function (goal) {
//             goal.Projects.forEach(function (project) {
//                 var _project = _.find($scope.selectedUser.Projects, {id: project.id});
//                 if (_project) {
//                     project.finishedAt = _project.UserAcademyProject.finishedAt;
//                     project.recommendedAt = _project.UserAcademyProject.recommendedAt;
//                     if (project.finishedAt && project.published) {
//                         $scope.selectedUser.projectRate.roadmapFinished++;
//                     }
//                 }
//                 if (project.published) {
//                     $scope.selectedUser.projectRate.roadmap++;
//                 }
//             })
//         });
//
//         //All
//         $scope.goals.forEach(function (goal) {
//             _.forEach(goal.Projects, function (project) {
//                 var _project = _.find($scope.selectedUser.Projects, {id: project.id});
//                 if (_project) {
//                     project.finishedAt = (_project.UserAcademyProject ? _project.UserAcademyProject.finishedAt : undefined);
//                     project.recommendedAt = (_project.UserAcademyProject ? _project.UserAcademyProject.recommendedAt : undefined);
//                 }
//                 if (project.published) {
//                     $scope.selectedUser.projectRate.all++;
//                 }
//                 if (project.finishedAt && project.published) {
//                     $scope.selectedUser.projectRate.finished++;
//                 }
//             })
//         });
//
//         $scope.trainings.forEach(function (training) {
//             _.forEach(training.Courses, function (course) {
//                 var _course = _.find($scope.selectedUser.Courses, {id: course.id});
//                 if (_course) {
//                     course.finishedAt = _course.UserAcademyCourse.finishedAt;
//                     course.recommendedAt = _course.UserAcademyCourse.recommendedAt;
//                 }
//             })
//         });
//
//         _.forEach($scope.groupCoachings, function (groupCoaching) {
//             var gc = _.find($scope.selectedUser.GroupCoachings, {id: groupCoaching.id});
//             if (gc) {
//                 groupCoaching.finishedAt = gc.UserAcademyGroupCoaching.finishedAt;
//                 groupCoaching.recommendedAt = gc.UserAcademyGroupCoaching.recommendedAt;
//             }
//         })
//     };
//
//     $scope.getRoadmap = function () {
//         if (!$scope.selectedUser.Goals) {
//             $http.get('/api/academy/roadmaps/' + $scope.selectedUser.id)
//                 .success(function (roadmap) {
//                     $scope.selectedUser.Courses = roadmap.Courses;
//                     $scope.selectedUser.Projects = roadmap.Projects;
//                     $scope.selectedUser.Goals = roadmap.Goals;
//                     $scope.selectedUser.GroupCoachings = roadmap.GroupCoachings;
//
//                     $scope.calculateRoadmap();
//                 })
//         } else {
//             $scope.calculateRoadmap();
//         }
//     };
//
//     $scope.getHeatMaps = function () {
//         if (!$scope.selectedUser.wistiaEvents) {
//             $scope.selectedUser.wistiaEvents = [];
//         }
//         $scope.page = ($scope.page > 0) ? $scope.page + 1 : 1;
//
//         $http.get('/api/academy/wistia/heatmaps/' + $scope.selectedUser.email + '?page=' + $scope.page)
//             .success(function (events) {
//                 if (events.length === 0) $scope.selectedUser.wistiaEventsFinished = true;
//                 else {
//                     events.forEach(function (event) {
//                         $scope.selectedUser.wistiaEvents.push({
//                             heatMapURL: $sce.trustAsResourceUrl(event.iframe_heatmap_url),
//                             mediaName: event.media_name,
//                             mediaUrl: $sce.trustAsResourceUrl(event.media_url),
//                             receivedAt: $moment(event.received_at).toDate()
//                         });
//                     });
//                 }
//             })
//     };
//
//     $scope.openHeatMaps = function () {
//         $scope.expanded.heatmaps = !$scope.expanded.heatmaps;
//         if ($scope.expanded.heatmaps) {
//             $scope.getHeatMaps();
//         }
//     };
//
//     $scope.selectUser = function (user) {
//         $scope.expanded = {};
//         if ($scope.selectedUser && $scope.selectedUser.id == user.id) {
//             $scope.selectedUser = null;
//             $scope.expanded = {};
//         } else {
//             $scope.selectedUser = user;
//             $scope.$broadcast('userSelected', {userId: user.id});
//             $scope.selectedUser.projectRate = {all: 0, finished: 0, roadmap: 0, roadmapFinished: 0};
//
//             $http.get('/api/academy/notes/' + user.id)
//                 .success(function (notes) {
//                     $scope.selectedUser.Notes = notes;
//                 });
//
//             $scope.getRoadmap();
//             //$timeout(function () {
//             //    $scope.getHeatMaps();
//             //}, 1000);
//
//         }
//     };
//
//     // $scope.getColor = AcademyService.getColor;
//     $scope.deadlineClass = AcademyService.deadlineClass;
//     $scope.goalPercentage = AcademyService.goalPercentage;
//
//     // $scope.finishUnfinishCourse = function (course) {
//     //     AcademyService.finishUnfinishItem('courses', course, $scope.selectedUser.id);
//     // };
//     //
//     // $scope.finishUnfinishProject = function (project) {
//     //     AcademyService.finishUnfinishItem('projects', project, $scope.selectedUser.id);
//     // };
//     //
//     // $scope.finishUnfinishGroupCoaching = function (groupCoaching) {
//     //     AcademyService.finishUnfinishItem('groupcoachings', groupCoaching, $scope.selectedUser.id);
//     // };
//     //
//     // $scope.recommendCourse = function (course) {
//     //     $http.post('/api/academy/courses/' + course.id + '/recommend/' + $scope.selectedUser.id)
//     //         .success(function (result) {
//     //             course.UserAcademyCourse = result;
//     //             course.recommendedAt = result.recommendedAt;
//     //             //if (!_.find($scope.selectedUser.Courses, {id: course.id})) {
//     //             //    $scope.selectedUser.Courses.push(course);
//     //             //}
//     //             //_.find(_.find($scope.trainings, {id: course.TrainingId}).Courses, {id: course.id}).recommendedAt = result.recommendedAt;
//     //         })
//     // };
//     //
//     // $scope.recommendProject = function (project) {
//     //     $http.post('/api/academy/projects/' + project.id + '/recommend/' + $scope.selectedUser.id)
//     //         .success(function (result) {
//     //             project.UserAcademyProject = result;
//     //             project.recommendedAt = result.recommendedAt;
//     //             //if (!_.find($scope.selectedUser.Projects, {id: project.id})) {
//     //             //    $scope.selectedUser.Projects.push(project);
//     //             //}
//     //             //_.find(_.find($scope.goals, {id: project.GoalId}).Projects, {id: project.id}).recommendedAt = result.recommendedAt;
//     //         })
//     // };
//     //
//     // $scope.recommendGroupCoaching = function (groupCoaching) {
//     //     $http.post('/api/academy/groupcoachings/' + groupCoaching.id + '/recommend/' + $scope.selectedUser.id)
//     //         .success(function (result) {
//     //             groupCoaching.UserAcademyGroupCoaching = result;
//     //             groupCoaching.recommendedAt = result.recommendedAt;
//     //             //$scope.selectedUser.GroupCoachings.push(groupCoaching);
//     //             //_.find($scope.groupCoachings, {id: groupCoaching.id}).recommendedAt = result.recommendedAt;
//     //         })
//     // };
//     //
//     // $scope.removeCourseRecommendation = function (course) {
//     //     $http.delete('/api/academy/courses/' + course.id + '/recommend/' + $scope.selectedUser.id)
//     //         .success(function () {
//     //             course.recommendedAt = null;
//     //         })
//     // };
//     //
//     // $scope.removeProjectRecommendation = function (project) {
//     //     $http.delete('/api/academy/projects/' + project.id + '/recommend/' + $scope.selectedUser.id)
//     //         .success(function () {
//     //             project.recommendedAt = null;
//     //         })
//     // };
//     //
//     // $scope.removeGroupCoachingRecommendation = function (groupCoaching) {
//     //     $http.delete('/api/academy/groupcoachings/' + groupCoaching.id + '/recommend/' + $scope.selectedUser.id)
//     //         .success(function () {
//     //             groupCoaching.recommendedAt = null;
//     //         })
//     // };
//     //
//     // $scope.assignGoal = function (goal) {
//     //     if ($scope.can('coach')) {
//     //         $scope.selectedGoal = goal;
//     //         var modalInstance = $modal.open({
//     //             templateUrl: '/templates/academy/goal-assign.html',
//     //             controller: 'AcademyGoalAssign',
//     //             backdrop: 'static',
//     //             //size: 'lg',
//     //             resolve: {
//     //                 goal: function () {
//     //                     return $scope.selectedGoal;
//     //                 }
//     //             }
//     //         });
//     //
//     //         modalInstance.result.then(function (goal) {
//     //             $http.post('/api/academy/goals/' + goal.id + '/assign/' + $scope.selectedUser.id, {deadline: goal.deadline})
//     //                 .success(function (result) {
//     //                     goal.UserAcademyGoal = result;
//     //                     var user = $scope.selectedUser;
//     //                     $scope.selectedUser = undefined;
//     //                     user.Goals.push(goal);
//     //                     $scope.selectUser(user);
//     //                 })
//     //         }, function () {
//     //         });
//     //     }
//     // };
//     //
//     // $scope.unassignGoal = function (goal) {
//     //     $http.delete('/api/academy/goals/' + goal.id + '/assign/' + $scope.selectedUser.id)
//     //         .success(function () {
//     //             delete goal.UserAcademyGoal;
//     //             var user = $scope.selectedUser;
//     //             $scope.selectedUser = undefined;
//     //             _.remove(user.Goals, {id: goal.id});
//     //             $scope.selectUser(user);
//     //         })
//     // };
//     //
//     // $scope.finishGoal = function (goal) {
//     //     $http.put('/api/academy/goals/' + goal.id + '/finish/' + $scope.selectedUser.id)
//     //         .success(function () {
//     //             goal.UserAcademyGoal.finishedAt = new Date();
//     //         })
//     // };
//
//     $scope.takeUser = function (user) {
//         $http.post('/api/academy/users/' + user.id + '/coach')
//             .success(function (result) {
//                 user.CoachId = result.CoachId;
//             })
//     };
//
//     $scope.returnUser = function (user) {
//         $http.delete('/api/academy/users/' + user.id + '/coach')
//             .success(function (result) {
//                 user.CoachId = result.CoachId;
//             })
//     };
//
//     $scope.canTake = function (user) {
//         return !user.CoachId;
//     };
//
//     $scope.canReturn = function (user) {
//         return user.CoachId && CurrentUser.getUser().id == user.CoachId;
//     };
//
//     $scope.userOperations = [
//         {
//             operation: $scope.takeUser,
//             label: 'button.academy.takeUser',
//             icon: 'user-plus',
//             condition: $scope.canTake
//         },
//         {
//             operation: $scope.returnUser,
//             label: 'button.academy.returnUser',
//             icon: 'user-times',
//             condition: $scope.canReturn
//         }
//     ];
//
//     $scope.nameFilter = function (item) {
//         if (!$scope.filter.name) {
//             return true;
//         }
//         if (item) {
//             var name = item.firstName + item.lastName + item.email;
//             if (name && $scope.filter.name) {
//                 return name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1;
//             } else {
//                 return false;
//             }
//         }
//         return false;
//     };
//
//     $scope.userProjectFilter = function (item) {
//         if (!$scope.selectedUser || !$scope.selectedUser.Goals) {
//             return true;
//         }
//         return !_.find($scope.selectedUser.Goals, {id: item.id});
//     };
//
//     $scope.publishedFilter = function (item) {
//         return !!item.published;
//     };
//
//     if ($stateParams.userId) {
//         $scope.selectUser(_.find(users, {id: parseInt($stateParams.userId)}));
//     }
// })
//
// .controller('AcademyGoalAssign', function ($scope, $filter, $modalInstance, goal) {
//
//     $scope.goal = goal;
//     $scope.goal.deadline = new Date().getMonth();
//     $scope.months = [];
//     for (var i = 0; i < 6; i++) {
//         $scope.months.push({
//             id: $scope.goal.deadline + i,
//             name: $filter('date')(new Date(new Date().getFullYear(), $scope.goal.deadline + i), 'MMMM')
//         })
//     }
//
//     $scope.ok = function () {
//         $scope.goal.deadline = new Date(new Date().getFullYear(), parseInt($scope.goal.deadline) + 1, 0);
//         $modalInstance.close($scope.goal);
//     };
//
//     $scope.cancel = function () {
//         $modalInstance.dismiss();
//     };
//
// })
//
// .controller('AcademyManageController', function ($scope, $state, _, AssetManager, DialogService, HttpService, assets) {
//     $scope.AssetManager = AssetManager;
// })
//
// .controller('AcademyRecommendController', function ($scope, $state, $http, $modalInstance, _, CurrentUser, AcademyService, instance, type) {
//     $scope.instance = instance;
//     $scope.type = type;
//     $scope.users = [];
//     $scope.selected = {users: []};
//     $scope.originalSelection = [];
//
//     $http.get('/api/academy/users')
//         .then(function (response) {
//             $scope.users = response.data;
//         });
//
//     $http.get('/api/academy/' + type + 's/' + instance.id + '/recommend')
//         .then(function (response) {
//             var results = response.data;
//             $scope.users.forEach(function (user) {
//                 if (_.find(results, {UserId: user.id})) {
//                     user.ticked = true;
//                     $scope.originalSelection.push(user.id);
//                 }
//             })
//         });
//
//
//     $scope.ok = function (cb) {
//         var userIds = _.map($scope.selected.users, 'id');
//         $http.post('/api/academy/' + type + 's/' + instance.id + '/recommend', {userIds: userIds})
//             .then(function () {
//                 var usersToRemove = _.difference($scope.originalSelection, userIds);
//                 usersToRemove.forEach(function (user) {
//                     $http.delete('/api/academy/' + type + 's/' + instance.id + '/recommend/' + user);
//                 });
//                 $scope.originalSelection = userIds;
//                 $scope.errors = [];
//                 $scope.messages = ['message.saved'];
//                 cb();
//             }, function (error) {
//                 $scope.messages = [];
//                 $scope.errors = [error.data];
//                 cb();
//             });
//     };
//
//     $scope.close = function () {
//         $modalInstance.dismiss();
//     }
//
// })
//
// .controller('AcademyManageDisciplinesController', function ($scope, $state, $http, _, CurrentUser, AcademyService, disciplines) {
//     var currentUser = CurrentUser.getUser();
//     $scope.disciplines = _.filter(disciplines, {VendorId: currentUser.Company.id});
//
//     $scope.addDiscipline = function () {
//         $scope.disciplines.push({orderId: $scope.disciplines.length});
//     };
//
//     $scope.deleteDiscipline = function (discipline) {
//         _.remove($scope.disciplines, discipline);
//     };
//
//     $scope.save = function (cb) {
//         $scope.messages = [];
//         $scope.errors = [];
//         $http.put('/api/academy/disciplines', {disciplines: $scope.disciplines})
//             .then(function () {
//                 $scope.messages = ['message.saved'];
//                 cb();
//             }, function (error) {
//                 $scope.errors = [error.data];
//                 cb();
//             });
//     };
//
//     $scope.revert = function () {
//         $scope.messages = [];
//         $scope.errors = [];
//         $http.get('/api/academy/disciplines?vendor=true')
//             .then(function (response) {
//                 var disciplines = response.data;
//                 $scope.disciplines = _.filter(disciplines, {VendorId: currentUser.Company.id});
//             })
//     }
// });
