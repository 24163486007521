angular
  .module('vcio-toolkit')
  .controller(
    'SignupController',
    function (
      $scope,
      $rootScope,
      $window,
      $timeout,
      $translate,
      _,
      Auth,
      DialogService,
      HttpService,
      MarketPlacePaymentService,
      SessionStorage,
      InputSanitizerService,
    ) {
      $scope.permissionGroup = $rootScope.permissionGroup;
      $scope.translationLoaded = false;
      $rootScope.$on('$translateChangeEnd', function () {
        $scope.translationLoaded = true;
        $rootScope.$broadcast('dataLoadingFinished');
      });

      $rootScope.info = $rootScope.info || {};
      $scope.user = {
        firstName: $rootScope.info.firstName,
        lastName: $rootScope.info.lastName,
        email: $rootScope.info.email,
      };
      $scope.company = { name: $rootScope.info.companyName };
      if ($scope.permissionGroup && $scope.permissionGroup.realPrice > 0) {
        $scope.card = {};
        $scope.order = {
          permissionGroupId: $scope.permissionGroup.id,
          couponCode: $rootScope.info.coupon,
        };
        $scope.$watch('order.couponCode', function () {
          if (!$scope.order.couponCode) {
            $scope.order.discount = undefined;
            $scope.order.invalidCoupon = false;
          }
        });
      }

      HttpService.get('/api/lists/userRoles').then(function (userRoles) {
        $scope.userRoles = userRoles;
      });

      $scope.validateForm = function () {
        // expMonth: , expYear
        if ($scope.card) {
          if (
            !/^\d{2}\/\d{2}$/.test($scope.card.expiration) ||
            $scope.card.expiration.split('/')[0] > 12
          ) {
            DialogService.error('error.signup.cardExpiration');
            return false;
          }
          $scope.card.expMonth = $scope.card.expiration.split('/')[0];
          $scope.card.expYear = $scope.card.expiration.split('/')[1];

          if (!/^\d{3}$/.test($scope.card.cvc)) {
            DialogService.error('error.signup.cardCode');
            return false;
          }

          if (!$scope.card.name) {
            DialogService.error('error.signup.cardName');
            return false;
          }

          // if (!Stripe.card.validateCardNumber($scope.card.number)) {
          //     DialogService.error('error.signup.cardNumber')
          //     return false;
          // }
        }
        return true;
      };

      $scope.save = function () {
        $rootScope.$broadcast('dataLoadingStarted');
        $scope.applyCoupon(function (error) {
          if (!error && $scope.validateForm()) {
            HttpService.post('/api/signup', {
              company: $scope.company,
              user: _.extend($scope.user, { roles: [$scope.user.role] }),
              // https://trello.com/c/ApkfYsIO/38-turn-off-adding-any-ss-to-a-new-account-if-they-sign-up-on-our-site
              // vendorId: 3,
              // permissionGroupId: serverConfigSettings.templatesDefaultPermissionGroupId
            })
              .then(function (userAndToken) {
                Auth.setUser(userAndToken.user, userAndToken.token);
                var currentUser = userAndToken.user;

                if ($scope.permissionGroup) {
                  if (!$scope.permissionGroup.realPrice) {
                    MarketPlacePaymentService.subscribe(
                      $scope.permissionGroup.id,
                      $scope.permissionGroup.nextSteps,
                      'message.marketplace.permissionGroupSubscriptionSuccess',
                    )
                      .then(function () {
                        $rootScope.$broadcast('dataLoadingFinished');
                        $window.location.href =
                          '/#/marketplace/permissiongroups/' +
                          InputSanitizerService.sanitize($scope.permissionGroup.id) +
                          '?nextSteps=true';
                      })
                      .catch(function (error) {
                        DialogService.message('error.signup.payment', function () {
                          $window.location.href =
                            '/#/marketplace/permissiongroups/' +
                            InputSanitizerService.sanitize($scope.permissionGroup.id);
                        });
                      });
                  } else {
                    HttpService.put(
                      '/api/companies/' + currentUser.Company.id + '/payment/customer',
                      {
                        customer: { email: $scope.user.email },
                        card: $scope.card,
                      },
                    )
                      .then(function (customerId) {
                        $timeout(function () {
                          MarketPlacePaymentService.subscribe(
                            $scope.permissionGroup.id,
                            '',
                            'message.marketplace.permissionGroupSubscriptionSuccess',
                            $scope.order.discount ? $scope.order.couponCode : undefined,
                          )
                            .then(function () {
                              $rootScope.$broadcast('dataLoadingFinished');
                              SessionStorage.set(
                                'last4',
                                $scope.card.number.substring($scope.card.number.length - 4),
                              );
                              $window.location.href =
                                '/#/marketplace/permissiongroups/' +
                                InputSanitizerService.sanitize($scope.permissionGroup.id) +
                                '?nextSteps=true';
                            })
                            .catch(function (error) {
                              DialogService.message('error.signup.payment', function () {
                                $window.location.href =
                                  '/#/marketplace/permissiongroups/' +
                                  InputSanitizerService.sanitize($scope.permissionGroup.id);
                              });
                            });
                        }, 2000);
                      })
                      .catch(function (error) {
                        $rootScope.$broadcast('dataLoadingFinished');
                        DialogService.message('error.signup.payment', function () {
                          $window.location.href =
                            '/#/marketplace/permissiongroups/' +
                            InputSanitizerService.sanitize($scope.permissionGroup.id);
                        });
                      });
                  }
                } else {
                  $rootScope.$broadcast('dataLoadingFinished');
                  $window.location.href = '/#/';
                }
              })
              .catch(function (error) {
                $rootScope.$broadcast('dataLoadingFinished');
                if (error && error.code === 'error.signup.payment') {
                  DialogService.message(
                    $translate.instant(error.code, error.translateValues),
                    function () {
                      $window.location.href = '/login';
                    },
                  );
                } else {
                  DialogService.error(error);
                }
              });
          } else {
            $rootScope.$broadcast('dataLoadingFinished');
          }
        });
      };

      $scope.applyCoupon = function (cb) {
        // TODO send marketplacePermissionGroupBuyCoupon event from server side ???
        if ($scope.order && $scope.order.couponCode) {
          HttpService.get(
            '/api/payment/coupons/' +
              $scope.order.couponCode +
              '?vendorId=' +
              $scope.permissionGroup.VendorId,
          )
            .then(function (coupon) {
              if (coupon && (coupon.duration === 'once' || $scope.permissionGroup.stripePlan)) {
                if (coupon.amount) {
                  $scope.order.discount = Math.min(
                    $scope.permissionGroup.realPrice,
                    _.round(coupon.amount / 100, 2),
                  );
                } else {
                  $scope.order.discount = Math.min(
                    $scope.permissionGroup.realPrice,
                    _.round(($scope.permissionGroup.realPrice * coupon.percent) / 100, 2),
                  );
                }
                $scope.order.coupon = coupon;
                $scope.order.invalidCoupon = false;
                if (cb) {
                  cb();
                }
              } else {
                var error = $translate.instant('error.payment.coupon', {
                  couponCode: $scope.order.couponCode,
                });
                $scope.order.invalidCoupon = true;
                DialogService.error(error);
                if (cb) {
                  cb(error);
                }
              }
            })
            .catch(function (error) {
              $scope.order.invalidCoupon = true;
              DialogService.error(error);
              if (cb) {
                cb(error);
              }
            });
        } else {
          if (cb) {
            cb();
          }
        }
      };
    },
  );
