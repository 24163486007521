angular.module('template/popover/popover.html', []).run([
  '$templateCache',
  function ($templateCache) {
    $templateCache.put(
      'template/popover/popover.html',
      '<div class="popover {{placement}}" style="max-width: 200px" ng-class="{ in: isOpen(), fade: animation() }">\n' +
        '  <div class="arrow"></div>\n' +
        '\n' +
        '  <div class="popover-inner">\n' +
        '      <h3 class="popover-title" ng-bind-html="title | unsafe" ng-show="title"></h3>\n' +
        '      <div class="popover-content" ng-bind-html="content | unsafe"></div>\n' +
        '  </div>\n' +
        '</div>\n' +
        '',
    );
  },
]);
