angular
  .module('vcio-toolkit')
  .controller(
    'GradersController',
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $translate,
      $http,
      $modal,
      _,
      CurrentUser,
      DialogService,
      HttpService,
      PermissionGroupModel,
      graders,
    ) {
      $scope.currentUser = CurrentUser.getUser();
      $scope.graders = graders;
      $scope.graderTemplates = [];
      $scope.filter = { name: '', all: false, permissionGroups: [] };
      if ($stateParams.permissionGroupId) {
        $scope.filter.permissionGroups = _.map(
          $stateParams.permissionGroupId.split(','),
          function (val) {
            return parseInt(val);
          },
        );
      }
      $scope.type = $state.$current.name === 'marketing.graderTemplates' ? 'templates' : 'graders';
      $scope.isTemplates = $scope.type === 'templates';
      $scope.newGrader = {};
      $scope.myPermissionGroups = [];
      PermissionGroupModel.querySubscribed().$promise.then(function (result) {
        var listPermissionGroups = _(graders)
          .flatMap(function (item) {
            return _.get(item, 'Template.PermissionGroups') || _.get(item, 'PermissionGroups', []);
          })
          .map('id')
          .uniq()
          .value();
        $scope.myPermissionGroups = _.filter(result, function (item) {
          return _.includes(listPermissionGroups, item.id);
        });
      });

      $scope.clickPermissionGroupFilter = function (permissionGroupId) {
        // $scope.expandAllServiceGroups(true);
        // $state.go('service.services', {permissionGroupId: permissionGroupId.join()});
        $state.transitionTo(
          'marketing.gradersByPermissionGroup',
          { permissionGroupId: permissionGroupId.join() },
          { notify: false },
        );
      };

      $scope.save = function (newGrader, cb) {
        if (newGrader.template) {
          $state.go('marketing.graderCopy', { graderId: newGrader.template });
        }
      };

      $scope.cancel = function (newGrader) {
        $scope.newGrader = {};
      };

      $scope.addCatGrader = function (grader) {
        var newGrader = _.cloneDeep(grader);
        newGrader.TemplateId = newGrader.id;
        delete newGrader.id;
        newGrader.Owner = $scope.currentUser.Company;
        if (newGrader.email) {
          newGrader.email.from = $scope.currentUser.email;
        } else {
          newGrader.email = { from: $scope.currentUser.email };
        }
        newGrader.template = false;
        newGrader.name = 'Copy of ' + newGrader.name;

        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.post('/api/graders', { grader: newGrader }).then(
          function () {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.message($translate.instant('message.graders.graderAdded'));
          },
          function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            console.error(error);
            DialogService.error(error);
          },
        );
      };

      $scope.paging = {
        limit: 3,
        current: 1,
      };

      $scope.canCreate = function () {
        return CurrentUser.can('createGrader');
      };

      $scope.canEdit = function (grader) {
        return (
          grader.Owner.id == CurrentUser.getUser().Company.id && CurrentUser.can('createGrader')
        );
      };

      $scope.editGrader = function (grader) {
        if ($scope.canEdit(grader)) {
          $state.go('marketing.grader', { graderId: grader.id });
        }
      };

      $scope.copyGrader = function (_grader) {
        if ($scope.canCreate()) {
          $state.go('marketing.graderCopy', { graderId: _grader.id });
        }
      };

      $scope.createGrader = function () {
        if ($scope.canCreate()) {
          if ($scope.type === 'templates') {
            $state.go('marketing.grader', { graderId: 'new' });
          } else {
            $rootScope.$broadcast('dataLoadingStarted');
            HttpService.get('/api/graders?type=template').then(
              function (graderTemplates) {
                $scope.graderTemplates = graderTemplates;
                $scope.newGrader = { expanded: true };
                $rootScope.$broadcast('dataLoadingFinished');
              },
              function (error) {
                $rootScope.$broadcast('dataLoadingFinished');
                DialogService.error(error);
              },
            );
          }
        }
      };

      $scope.listLeads = function (grader) {
        $state.go('marketing.graderLeads', { graderId: grader.id });
      };

      $scope.statistics = function (grader) {
        $state.go('marketing.graderStatistics', { graderId: grader.id });
      };

      $scope.shareGrader = function (grader) {
        $modal.open({
          templateUrl: '/templates/marketing/grader-share.html',
          controller: 'GraderShareController',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            grader: function () {
              return grader;
            },
          },
        });
      };

      $scope.deleteGrader = function (grader) {
        HttpService.delete('/api/graders/' + grader.id).then(
          function (deleted) {
            _.remove($scope.graders, { id: grader.id });
          },
          function (error) {
            console.error(error);
          },
        );
      };

      $scope.isTemplate = function (grader) {
        return !grader.template;
      };

      $scope.isVendor = CurrentUser.hasModule('vendor');

      // $scope.graderOperations = [
      //     {operation: $scope.copyGrader, label: 'button.copy', icon: 'copy'},
      //     {operation: $scope.editGrader, label: 'button.edit', icon: 'edit', condition: $scope.canEdit},
      //     {operation: $scope.deleteGrader, label: 'button.delete', icon: 'minus-square-o', condition: $scope.canEdit, confirmation: true},
      //     {operation: $scope.shareGrader, label: 'button.share', icon: 'share-alt', condition: $scope.isTemplate},
      //     {operation: $scope.listLeads, label: 'button.leads', icon: 'group', condition: $scope.isTemplate},
      //     {operation: $scope.statistics, label: 'button.statistics', icon: 'bar-chart', condition: $scope.isTemplate}
      // ];

      $scope.companyFilter = function (item) {
        return (
          $scope.filter.all ||
          item.template ||
          (item.Owner && item.Owner.id == $scope.currentUser.Company.id)
        );
      };
      $scope.nameFilter = function (item) {
        return (
          $scope.filter.name === '' ||
          item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1 ||
          (item.Owner &&
            item.Owner.name &&
            item.Owner.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1)
        );
      };
      $scope.permissionGroupFilter = function (item) {
        return (
          _.isEmpty($scope.filter.permissionGroups) ||
          (item.Template &&
            _.find(item.Template.PermissionGroups, function (permissionGroup) {
              return _.includes($scope.filter.permissionGroups, permissionGroup.id);
            })) ||
          _.find(item.PermissionGroups, function (permissionGroup) {
            return _.includes($scope.filter.permissionGroups, permissionGroup.id);
          })
        );
      };
    },
  )

  .controller('GraderShareController', function ($scope, $modalInstance, grader) {
    $scope.grader = grader;
    $scope.settings = {
      autostart: true,
    };
    $scope.showHint = false;
    $scope.url = '';
    $scope.hint = function () {
      $scope.showHint = !$scope.showHint;
    };
    $scope.generateUrl = function () {
      var url = 'insertfill-' + $scope.grader.uuid + '.js';
      if ($scope.settings.autostart) {
        url += '?autostart=1';
      }
      $scope.url = url;
    };
    $scope.generateUrl();
    $scope.$watch('settings.autostart', function () {
      $scope.generateUrl();
    });
    $scope.ok = function () {
      $modalInstance.dismiss();
    };
  })

  .controller(
    'GraderEditController',
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $translate,
      $http,
      $modal,
      _,
      UploadService,
      CurrentUser,
      grader,
      copy,
      InputSanitizerService,
    ) {
      var currentUser = CurrentUser.getUser();
      $scope.isVendor = CurrentUser.hasModule('vendor');

      $scope.grader = grader || {
        dimensions: [],
        ranges: [],
        Owner: currentUser.Company,
        OwnerId: currentUser.Company.id,
        email: {
          from: currentUser.email,
          attachReportPDF: true,
        },
        scoreTotal: 'Your Score is {{score}} out of {{totalScore}}',
        design: {
          // button: {}
        },
      };

      if (copy) {
        $scope.grader.TemplateId = $scope.grader.id;
        delete $scope.grader.id;
        $scope.grader.Owner = currentUser.Company;
        if ($scope.grader.email) {
          $scope.grader.email.from = currentUser.email;
        } else {
          $scope.grader.email = { from: currentUser.email };
        }
        $scope.grader.template = false;
        $scope.grader.name = 'Copy of ' + $scope.grader.name;
      } else if ($scope.grader.OwnerId != currentUser.Company.id) {
        $state.go('marketing.graderTemplates');
      }

      if ($scope.grader.id) {
        if ($scope.grader.PreviewImageId) {
          $scope.previewUrl =
            '/images/graderpreviews/' + $scope.grader.id + '?' + new Date().getTime();
        }
      }
      $scope.openPreview = function (grader) {
        UploadService.open('graderpreviews', grader).then(function () {
          $scope.previewUrl = '/images/graderpreviews/' + grader.id + '?' + new Date().getTime();
        });
      };

      $scope.emailSequences = [];
      // var modalInstance = $scope.modalInstance;
      $scope.saved = false;
      $scope.attachTypes = ['html', 'pdf'];
      $scope.grader.email.attachType = $scope.grader.email.attachType || 'html';

      $http
        .get('/api/emailsequences')
        .success(function (emailSequences) {
          $scope.emailSequences = _.filter(emailSequences, function (item) {
            return !item.template;
          });
          $scope.emailSequences.unshift(undefined);
        })
        .error(function (error) {
          console.error(error);
        });

      $scope.name = function (item) {
        return item ? item.name : undefined;
      };

      $scope.grader.dimensions.forEach(function (dimension) {
        dimension.opened = false;
        dimension.orderId = parseInt(dimension.orderId);
        dimension.blocks.forEach(function (block) {
          block.answerType = 'single';
        });
      });
      if (!$scope.grader.ranges) {
        $scope.grader.ranges = [];
      }
      if (!$scope.grader.email) {
        $scope.grader.email = {};
      }

      $scope.sizes = ['S', 'M', 'L'];
      $scope.buttonStyle = {};
      $scope.grader.design = $scope.grader.design || {};
      if (!$scope.grader.design.button) {
        $scope.grader.design.button = {
          //width: '100%',
          fontSize: 'M',
          textColor: '#333333',
          backgroundColor: '#e7e7e7',
          borderColor: '#e7e7e7',
          text: 'Start survey...',
          fontFamily: 'Arial',
        };
      }
      if (!$scope.grader.design.report) {
        $scope.grader.design.report = {
          fontSize: 'M',
          backgroundColor: '#ffffff',
          fontFamily: 'Arial',
          textColor: '#000000',
          h1Color: '#ff4013',
          h2Color: '#ff8126',
          h3Color: '#00a2d7',
          h4Color: '#60b335',
          h5Color: '#ec3c9e',
          h6Color: '#73719d',
          chartScoreColor: '#59BF90',
          chartCircleColor: '#EFEFEF',
        };
      }
      $scope.$watch(
        'grader.design',
        function () {
          $scope.buttonStyle.color = $scope.grader.design.button.textColor;
          $scope.buttonStyle.backgroundColor = $scope.grader.design.button.backgroundColor;
          $scope.buttonStyle.border = '1px solid ' + $scope.grader.design.button.borderColor;
          $scope.buttonStyle.borderRadius =
            ($scope.grader.design.button.borderRadius || '0') + 'px';
          $scope.buttonStyle.fontFamily = $scope.grader.design.button.fontFamily;
          $scope.buttonStyle.fontSize = '15px';
          if ($scope.grader.design.button.fontSize === 'S') {
            $scope.buttonStyle.fontSize = '10px';
          } else if ($scope.grader.design.button.fontSize === 'L') {
            $scope.buttonStyle.fontSize = '24px';
          }
          if ($scope.grader.design.button.fullWidth) {
            $scope.buttonStyle.width = '100%';
          } else {
            delete $scope.buttonStyle.width;
          }
        },
        true,
      );

      $scope.goBack = function () {
        if ($scope.grader.template) {
          $state.go('marketing.graderTemplates');
        } else {
          $state.go('marketing.graders');
        }
      };

      $scope.save = function (cb) {
        if ($scope.grader) {
          $scope.errors = [];
          $scope.messages = [];
          $scope.grader.dimensions.forEach(function (dimension) {
            delete dimension.opened;
          });

          if ($scope.grader.id) {
            $http
              .put('/api/graders', { grader: $scope.grader })
              .success(function () {
                // $scope.messages = ['message.saved'];
                // $scope.saved = true;
                // cb();
                // $state.go('marketing.graders');
                $scope.goBack();
              })
              .error(function (error) {
                if (error.errors) {
                  $scope.errors = error.errors.concat(error.warnings);
                } else {
                  $scope.errors = [error];
                }
                console.error(error);
                cb();
              });
          } else {
            $http
              .post('/api/graders', { grader: $scope.grader })
              .success(function () {
                // $scope.grader.id = grader.id;
                // $scope.messages = ['message.saved']
                // $scope.saved = true;
                // cb();
                // $state.go('marketing.graders');
                $scope.goBack();
              })
              .error(function (error) {
                if (error.errors) {
                  $scope.errors = error.errors.concat(error.warnings);
                } else {
                  $scope.errors = [error];
                }
                console.error(error);
                cb();
              });
          }
        }
      };

      $scope.cancel = function () {
        // $state.go('marketing.graders');
        $scope.goBack();
      };

      $scope.changeScore = function () {
        $scope.selectedBlock.statements[0].score =
          $scope.selectedBlock.statements[0].score == $scope.selectedBlock.statements[1].score
            ? 1
            : $scope.selectedBlock.statements[1].score;
        $scope.selectedBlock.statements[1].score = 1 - $scope.selectedBlock.statements[0].score;
      };

      $scope.getScore = function (index) {
        return $scope.selectedBlock.statements[index].score > 0
          ? 'fa-check-square-o'
          : 'fa-square-o';
      };

      $scope.selectDimension = function (dimension) {
        if (dimension.opened) {
          $scope.selectedDimension = undefined;
          $scope.selectedBlock = undefined;
          dimension.opened = false;
        } else {
          $scope.grader.dimensions.forEach(function (item) {
            item.opened = false;
          });
          $scope.selectedDimension = dimension;
          dimension.opened = true;
          if ($scope.selectedBlock) {
            $scope.selectedBlock = undefined;
          }
        }
      };

      $scope.selectBlock = function (block) {
        if (block == $scope.selectedBlock) {
          $scope.selectedBlock = undefined;
        } else {
          $scope.selectedBlock = block;
        }
      };

      $scope.moveUp = function (item, list) {
        if (item.orderId > 1) {
          var prev = _.find(list, { orderId: item.orderId - 1 });
          item.orderId -= 1.5;
          prev.orderId++;
          item.orderId += 0.5;
        }
      };

      $scope.moveDown = function (item, list) {
        if (item.orderId < list.length) {
          var next = _.find(list, { orderId: item.orderId + 1 });
          item.orderId += 1.5;
          next.orderId--;
          item.orderId -= 0.5;
        }
      };

      $scope.addBlock = function (dimension) {
        if (!dimension.blocks) {
          dimension.blocks = [];
        }
        dimension.blocks.push({
          question: 'question ' + dimension.blocks.length + 1,
          type: 'multiple',
          orderId: dimension.blocks.length + 1,
          statements: [
            { name: '', score: 0, orderId: 1 },
            { name: '', score: 1, orderId: 2 },
          ],
        });
      };

      $scope.addDimension = function () {
        $scope.grader.dimensions.push({
          name: 'dimension ' + $scope.grader.dimensions.length + 1,
          description: '',
          orderId: $scope.grader.dimensions.length + 1,
          blocks: [],
        });
      };

      $scope.addRange = function () {
        var max = _.maxBy($scope.grader.ranges, 'scoreMax');
        var scoreMin = 0;
        var scoreMax = 1;
        if (max && max !== -Infinity) {
          scoreMin = max.scoreMax + 1;
          scoreMax = max.scoreMax + 2;
        }
        $scope.grader.ranges.push({
          scoreMin: scoreMin,
          scoreMax: scoreMax,
          description: '',
        });
      };

      $scope.remove = function (item2remove, list) {
        if (item2remove.orderId) {
          _.remove(list, function (item) {
            return item.orderId == item2remove.orderId;
          });

          list.forEach(function (item) {
            if (item.orderId > item2remove.orderId) {
              item.orderId--;
            }
          });
        } else {
          _.remove(list, function (item) {
            return item.scoreMin == item2remove.scoreMin;
          });
        }
      };

      $scope.openCompanyLogo = function () {
        UploadService.open('companies', currentUser.Company).then(function () {
          currentUser.Company.logoUrl =
            '/images/companies/' +
            InputSanitizerService.sanitize(currentUser.Company.id) +
            '?' +
            new Date().getTime();
        });
      };
    },
  )

  .controller('GraderSendReportController', function ($scope, $modalInstance) {
    $scope.sendReportForm = {
      toLead: true,
      toCreator: true,
    };

    $scope.ok = function () {
      $modalInstance.close($scope.sendReportForm);
    };

    $scope.cancel = function () {
      $modalInstance.dismiss();
    };
  })

  .controller(
    'GraderLeadsController',
    function (
      $scope,
      $rootScope,
      $stateParams,
      $http,
      $modal,
      DialogService,
      InputSanitizerService,
    ) {
      $http
        .get('/api/graders/' + InputSanitizerService.sanitize($stateParams.graderId) + '/leads')
        .success(function (grader) {
          $scope.grader = grader;

          grader.responses.forEach(function (lead) {
            var score = 0;
            if (lead.responses && lead.responses.constructor === Array) {
              lead.responses.forEach(function (response) {
                if (typeof response === 'number') {
                  score += response;
                } else if (response && typeof response === 'object') {
                  score += response.score;
                }
              });
            }
            lead.score = score;
          });
        })
        .error(function (error) {
          console.error(error);
        });

      //$scope.download = function(response){
      //    $http.get('/api/graders/results/'+response.id)
      //        .success(function(data){
      //            console.log('downloaded')
      //            //saveAs(new Blob([data], {type : 'application/pdf'}), "report.pdf");
      //            $scope.pdffile = new Blob([data], {type : 'application/pdf'});
      //            $scope.pdfurl = URL.createObjectURL($scope.pdffile);
      //
      //        }).error(function(error){
      //            console.error(error)
      //        })
      //
      //
      //}

      $scope.openSendGraderReport = function (graderResponse) {
        var modalInstance = $modal.open({
          templateUrl: '/templates/marketing/grader-report-modal.html',
          controller: 'GraderSendReportController',
          backdrop: 'static',
          resolve: {
            graderResponse: function () {
              return graderResponse;
            },
          },
        });

        modalInstance.result.then(
          function (result) {
            $rootScope.$broadcast('dataLoadingStarted');
            $http.post('/api/grader-report/' + graderResponse.uuid + '/resend', result).then(
              function () {
                $rootScope.$broadcast('dataLoadingFinished');
                DialogService.message('message.grader.graderReportSent');
              },
              function (response) {
                $rootScope.$broadcast('dataLoadingFinished');
                DialogService.error(response.data);
              },
            );
          },
          function () {},
        );
      };
    },
  )

  .controller(
    'GraderStatisticsController',
    function (
      $scope,
      $rootScope,
      $stateParams,
      $modal,
      $moment,
      CurrentUser,
      HttpService,
      grader,
      InputSanitizerService,
    ) {
      $scope.grader = grader;
      $scope.from = $moment().subtract(7, 'day').hour(0).minute(0).second(0).toDate();
      $scope.to = $moment().add(1, 'day').hour(0).minute(0).second(0).toDate();
      $scope.chart = {};

      HttpService.post(
        '/api/admin/users/' +
          InputSanitizerService.sanitize(CurrentUser.getUser().id) +
          '/timeline/graderStatsViewed',
        { graderId: $stateParams.graderId },
      );

      var createChartData = function (times) {
        $scope.chart = {
          data: {
            labels: [],
            datasets: [],
          },
          showData: {
            label: 'show',
            borderColor: '#b4b4b4',
            data: [],
          },
          openData: {
            label: 'open',
            borderColor: '#ebd332',
            data: [],
          },
          startData: {
            label: 'start',
            borderColor: '#488ecf',
            data: [],
          },
          finishData: {
            label: 'finish',
            borderColor: '#55be3b',
            data: [],
          },
          options: {
            responsive: true,
            animation: false,
            bezierCurve: false,
            pointDot: false,
          },
        };

        $scope.chart.data.datasets.push($scope.chart.showData);
        $scope.chart.data.datasets.push($scope.chart.openData);
        $scope.chart.data.datasets.push($scope.chart.startData);
        $scope.chart.data.datasets.push($scope.chart.finishData);

        var from = $moment($scope.from);
        var to = $moment($scope.to);
        var item = from;
        var days = to.diff(from, 'days');
        for (var i = 0; i < days; i++) {
          item.add(1, 'day');
          var key = item.format('YYYY-MM-DD');
          if (days < 10 || i % Math.round(days / 10) === 0) {
            $scope.chart.data.labels.push(item.format('MM-DD'));
          } else {
            $scope.chart.data.labels.push('');
          }
          if (times.hasOwnProperty(key)) {
            $scope.chart.showData.data.push(times[key].showButton || 0);
            $scope.chart.openData.data.push(times[key].open || 0);
            $scope.chart.startData.data.push(times[key].start || 0);
            $scope.chart.finishData.data.push(times[key].finish || 0);
          } else {
            $scope.chart.showData.data.push(0);
            $scope.chart.openData.data.push(0);
            $scope.chart.startData.data.push(0);
            $scope.chart.finishData.data.push(0);
          }
        }
      };

      $scope.sendTime = function () {
        HttpService.post(
          '/api/graders/' + InputSanitizerService.sanitize($stateParams.graderId) + '/times',
          {
            from: $scope.from,
            to: $scope.to,
          },
        ).then(function (result) {
          $scope.chartData = createChartData(result.times);
        });
      };

      //HttpService.get('/api/graders/' + $stateParams.graderId + '/times')
      //    .success(function (result) {
      //
      //    })

      $scope.sendTime(7);
    },
  );
