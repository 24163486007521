angular
  .module('vcio-toolkit')

  .factory('PermissionGroupModel', function ($resource, CurrentUser, _) {
    var resource = $resource(
      '/api/vendor/permissiongroups/:permissionGroupId',
      {
        permissionGroupId: '@permissionGroupId',
        companyId: '@companyId',
        assetId: '@assetId',
        assetType: '@assetType',
      },
      {
        create: {
          method: 'POST',
          transformRequest: function (data) {
            return angular.toJson({ permissionGroup: data });
          },
        },
        delete: {
          method: 'DELETE',
          transformRequest: function (data) {
            return angular.toJson({ permissionGroup: data });
          },
        },
        update: {
          url: '/api/vendor/permissiongroups',
          method: 'PUT',
          transformRequest: function (data) {
            return angular.toJson({ permissionGroup: data });
          },
        },
        addCompany: {
          url: '/api/vendor/permissiongroups/:permissionGroupId/company/:companyId',
          method: 'PUT',
        },
        removeCompany: {
          url: '/api/vendor/permissiongroups/:permissionGroupId/company/:companyId',
          method: 'DELETE',
        },
        addAsset: {
          url: '/api/vendor/permissiongroups/:permissionGroupId/:assetType/:assetId',
          method: 'PUT',
        },
        removeAsset: {
          url: '/api/vendor/permissiongroups/:permissionGroupId/:assetType/:assetId',
          method: 'DELETE',
        },
        queryByVendor: {
          method: 'GET',
          isArray: true,
          transformResponse: function (data) {
            return _.filter(angular.fromJson(data), { VendorId: CurrentUser.getUser().Company.id });
          },
        },
        querySubscribed: {
          url: '/api/marketplace/permissiongroups?subscribed=true&hidden=true',
          method: 'GET',
          isArray: true,
        },
      },
    );

    resource.prototype.$save = function (cb, cbError) {
      if (!this.id) {
        return this.$create(cb, cbError);
      } else {
        return this.$update(cb, cbError);
      }
    };

    return resource;
  });
