angular
  .module('vcio-toolkit')

  .directive('marketplacePermissionGroupSubscribe', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        permissionGroup: '=',
        buttons: '=?',
      },
      controller: 'MarketplacePermissionGroupSubscribeController',
      template:
        '<div>' +
        '  <button ng-if="isButton(\'get\') && isFree() && !isPurchased() && !isSubscribed() && canBuy()" class="btn btn-success btn-xs"\n' +
        '     translate-title="button.marketplace.free.hint"' +
        '     ng-click="subscribeToPermissionGroup();$event.preventDefault();$event.stopPropagation();"><span translate="button.marketplace.free"></span>\n' +
        '  </button>\n' +
        '  <button ng-if="isButton(\'getBack\') && isPurchased() && !isSubscribed()" class="btn btn-success btn-xs"\n' +
        '     translate-title="button.marketplace.getBack.hint"' +
        '     ng-click="subscribeToPermissionGroup();$event.preventDefault();$event.stopPropagation();"><span translate="button.marketplace.getBack"></span>\n' +
        '  </button>\n' +
        '  <button ng-if="isButton(\'buy\') && !isFree() && !isPurchased() && !isSubscribed() && canBuy()" class="btn btn-success btn-xs"\n' +
        '    translate-title="button.marketplace.buy.hint"' +
        '    ng-click="subscribeToPermissionGroup();$event.preventDefault();$event.stopPropagation();"><span translate="button.marketplace.buy"></span>\n' +
        '  </button>\n' +
        '  <button ng-if="isButton(\'remove\') && isSubscribed()" class="btn btn-success btn-xs"\n' +
        '    ng-click="unsubscribeToPermissionGroup();$event.preventDefault();$event.stopPropagation();">\n' +
        '   <span translate="button.marketplace.remove"></span>\n' +
        '  </button>\n' +
        '</div> ',
    };
  })

  .directive('marketplacePermissionGroupIncludesDirective', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        permissionGroup: '=',
        user: '=',
      },
      controller: function ($scope, $translate, _, CurrentUser) {
        $scope.user = $scope.user || CurrentUser.getUser();
        $scope.mailTo = function (type) {
          return $scope.permissionGroup.supportEmail
            ? 'mailto:' +
                $scope.permissionGroup.supportEmail +
                '?subject=' +
                $translate.instant('label.permissionGroup.supportEmail.subject', {
                  type: $translate.instant('label.permissionGroup.support.' + type),
                  name: $scope.permissionGroup.name,
                  firstName: $scope.user.firstName,
                  lastName: $scope.user.lastName,
                })
            : '';
        };

        $scope.permissionGroup.includes = [];
        if ($scope.permissionGroup.fullLifetime) {
          $scope.permissionGroup.includes.push(
            $translate.instant('label.permissionGroup.fullLifetime'),
          );
        }
        if ($scope.permissionGroup.certificate) {
          $scope.permissionGroup.includes.push(
            $translate.instant('label.permissionGroup.certificate'),
          );
        }
        if ($scope.permissionGroup.DefaultCourseIds.length > 0) {
          $scope.permissionGroup.includes.push(
            $translate.instant('label.permissionGroup.assignments'),
          );
        }
        // $scope.includes = includes.join(', ');
      },
      template:
        '<div>' +
        '<h5 ng-if="permissionGroup.includes"><span translate="label.permissionGroup.includes" class="text-success"></span>: {{permissionGroup.includes.join(",")}}</h5>' +
        '<h5 ng-if="permissionGroup.support && permissionGroup.support.length > 0">' +
        '    <span translate="label.permissionGroup.support" class="text-success"></span>:' +
        '    <span ng-repeat="type in permissionGroup.support">' +
        '        <a href="{{mailTo(type)}}" style="color: inherit!important;" ng-if="mailTo(type)" title="{{\'button.sendEmail\'|translate}}">{{"label.permissionGroup.support." + type | translate}}</a>' +
        '        <span ng-if="!mailTo(type)">{{"label.permissionGroup.support." + type | translate}}</span><span ng-if="!$last">, </span>' +
        '    </span>' +
        '</h5>' +
        '</div>',
    };
  })
  // .directive('marketplacePermissionGroupUserRolesDirective', function () {
  //     return {
  //         restrict: 'E',
  //         replace: true,
  //         scope: {
  //             permissionGroup: '='
  //         },
  //         template: '<div><h5 ng-if="permissionGroup.userRoles.length > 0">' +
  //             '<span translate="label.permissionGroup.userRoles" class="text-success"></span>:\n' +
  //             '    <span ng-repeat="role in permissionGroup.userRoles">\n' +
  //             '        <span>{{"userRoles." + role | translate}}</span><span ng-if="!$last">, </span>\n' +
  //             '    </span>\n' +
  //             '</h5></div>'
  //     };
  // })
  // .directive('marketplacePermissionGroupCategoriesDirective', function () {
  //     return {
  //         restrict: 'E',
  //         replace: true,
  //         scope: {
  //             permissionGroup: '=',
  //             permissionGroupCategories: '='
  //         },
  //         controller: function ($scope, _) {
  //             $scope.getCategoryName = function (subCategory) {
  //                 var categoryName;
  //                 _.forEach($scope.permissionGroupCategories, function (category) {
  //                     var result = _.find(category.SubCategories, {id: subCategory.id});
  //                     if (result) {
  //                         categoryName = result.name;
  //                         return false;
  //                     }
  //                 });
  //                 return categoryName;
  //             };
  //         },
  //         template: '<div><h5 ng-if="permissionGroup.SubCategories.length > 0">' +
  //             '<span translate="label.permissionGroup.categoriesLabel" class="text-success"></span>:\n' +
  //             '    <span ng-repeat="subCategory in permissionGroup.SubCategories">\n' +
  //             '        <span popover-placement="top" uib-popover="{{subcategory.description || \'\'}}" popover-trigger="mouseenter">{{getCategoryName(subCategory)}}</span><span ng-if="!$last">, </span>\n' +
  //             '    </span>\n' +
  //             '</h5></div>'
  //     };
  // })

  .controller(
    'VendorPermissionGroupHeaderController',
    function ($rootScope, $scope, $state, $stateParams, _, CurrentUser) {
      $scope.CurrentUser = CurrentUser.getUser();
      $scope.logoUrl = $scope.permissionGroup.ImageId
        ? '/images/permissiongroups/' + $scope.permissionGroup.id + '?' + new Date().getTime()
        : undefined;

      $scope.editPermissionGroup = function (permissiongroup) {
        //$scope.permissionGroup.isEdit = true;
      };
      $scope.canEdit = function () {
        return $scope.permissionGroup.VendorId === $scope.CurrentUser.Company.id;
      };

      // $scope.isFree = function () {
      //     var charges = $scope.CurrentUser.Company.charges.permissiongroups || [];
      //     return !!(!$scope.permissionGroup.price || $scope.permissionGroup.price == 0 || _.find(charges, {PermissionGroupId: $scope.permissionGroup.id}));
      // };
      //
      // $scope.isSubscribed = function () {
      //     return _.includes($scope.CurrentUser.Company.PermissionGroups, $scope.permissionGroup.id);
      //     // return !!_.find($scope.permissionGroup.Companies, {id: $scope.CurrentUser.CompanyId});
      // };
      //
      // $scope.subscribeToPermissionGroup = function () {
      //     $rootScope.$broadcast('dataLoadingStarted');
      //     HttpService.put('/api/vendor/permissiongroups/' + $scope.permissionGroup.id + '/subscribe').then(function () {
      //         $rootScope.$broadcast('dataLoadingFinished');
      //         if (!$scope.isFree()) {
      //             DialogService.message('message.vendor.permissionGroupSubscriptionSent');
      //         } else {
      //             $scope.permissionGroup.Companies.push({id: $scope.CurrentUser.Company.id});
      //         }
      //     }, function (error) {
      //         $rootScope.$broadcast('dataLoadingFinished');
      //         console.error(error);
      //         $scope.errors = [error];
      //     });
      // };
    },
  )
  .controller(
    'MarketplacePermissionGroupController',
    function (
      $rootScope,
      $scope,
      $state,
      $stateParams,
      InputSanitizerService,
      $timeout,
      $translate,
      $modal,
      _,
      AssetService,
      CurrentUser,
      DialogService,
      HttpService,
      SessionStorage,
      UploadService,
      permissiongroup,
      permissionGroupCategories,
      services,
      serviceBundles,
      emailSequences,
      graders,
      meetingTemplates,
      surveys,
    ) {
      $scope.isVendor = CurrentUser.isVendor;
      $scope.permissionGroup = permissiongroup;
      //console.log(permissiongroup);
      $scope.currentView = 'overview';
      $scope.permissionGroup.Currency = 'CA$';
      $scope.permissionGroupCategories = permissionGroupCategories;
      $scope.user = CurrentUser.getUser();
      $scope.getAssetLink = AssetService.getAssetLink;
      $scope.academyCounts = { roadmap: 0, resource: 0, knowledgebase: 0, course: 0, training: 0 };
      $scope.academyTypes = ['roadmap', 'resource', 'knowledgebase', 'course', 'training'];
      $scope.serviceCounts = { onetime: 0, ongoing: 0 };
      $scope.serviceTypes = ['onetime', 'ongoing'];
      $scope.breadCrumbSubCategories = [];
      $scope.breadCrumbCategories = [];
      _.forEach($scope.permissionGroup.SubCategories, function (subCategory) {
        _.forEach($scope.permissionGroupCategories, function (category) {
          var result = _.find(category.SubCategories, { id: subCategory.id });
          if (result) {
            $scope.breadCrumbSubCategories.push({ id: result.id, name: result.name });
            $scope.breadCrumbCategories.push({ id: category.id, name: category.name });
          }
        });
      });
      _.forEach($scope.permissionGroup.academy, function (asset) {
        $scope.academyCounts[asset.type]++;
      });
      $scope.permissionGroup.services = _.filter($scope.permissionGroup.services, {
        published: true,
      });
      _.forEach($scope.permissionGroup.services, function (service) {
        if (service.type === 'any') {
          $scope.serviceCounts['onetime']++;
          $scope.serviceCounts['ongoing']++;
        } else {
          $scope.serviceCounts[service.type]++;
        }
      });
      $scope.isSubscribed = function () {
        return _.includes($scope.user.Company.PermissionGroups, $scope.permissionGroup.id);
      };
      $scope.isPayed = function (permissionGroup) {
        var charge = _.find($scope.user.Company.Charges, { PermissionGroupId: permissionGroup.id });
        return charge && (!charge.paymentIntent || charge.paymentIntent.status == 'succeeded');
      };
      $scope.isTemplates = true;
      $scope.canCopyToCatalogue = function (item) {
        return item.inCatalogue === false && $scope.isSubscribed();
      };

      $scope.edit = function () {
        $state.go('tools.permissionGroupEdit', { permissionGroupId: $scope.permissionGroup.id });
      };
      $scope.share = function () {
        var modalInstance = $modal.open({
          template:
            '<modal-form form-title="label.permissionGroup.share" label_ok=\'button.ok\' onok="ok">\n' +
            '    <div class="row">\n' +
            '        <div class="col-md-8">\n' +
            '            <textarea style="width: 100%;" rows="5"><iframe src="{{url}}" width="1140" height="277"></iframe></textarea>\n' +
            //'            <button class="btn btn-default" ng-click="copy()">{{\'button.copy\'|translate}}</button>\n' +
            '        </div>\n' +
            '    </div>\n' +
            '</modal-form>\n',
          controller: function ($scope, $modalInstance, permissionGroup) {
            $scope.permissionGroup = permissionGroup;
            $scope.url = '';
            $scope.generateUrl = function () {
              var url =
                'https://' + apphostname + '/solutionsets/' + $scope.permissionGroup.uuid + '/card';
              $scope.url = url;
            };
            $scope.generateUrl();
            $scope.ok = function () {
              $modalInstance.close();
            };
          },
          backdrop: 'static',
          size: 'lg',
          resolve: {
            permissionGroup: function () {
              return $scope.permissionGroup;
            },
          },
        });

        modalInstance.result.then(function (result) {});
      };

      $scope.serviceList = { onetime: [], ongoing: [] };
      $scope.serviceList.onetime = _($scope.permissionGroup.services)
        .filter(function (item) {
          return item.type === 'onetime' || item.type === 'any';
        })
        .groupBy(function (item) {
          return _.get(item, 'ServiceGroup.name');
        })
        .mapValues(function (group) {
          group.forEach(function (item) {
            item.inCatalogue = !!_.find(services, { TemplateId: item.id });
          });
          return { list: group, length: group.length, limit: 3, expanded: true };
        })
        .value();
      $scope.serviceList.ongoing = _($scope.permissionGroup.services)
        .filter(function (item) {
          return item.type === 'ongoing' || item.type === 'any';
        })
        .groupBy(function (item) {
          return _.get(item, 'ServiceGroup.name');
        })
        .mapValues(function (group) {
          group.forEach(function (item) {
            item.inCatalogue = !!_.find(services, { TemplateId: item.id });
          });
          return { list: group, length: group.length, limit: 3, expanded: true };
        })
        .value();
      // $scope.surveyList = _($scope.permissionGroup.surveys).groupBy(function (item) {
      //     return _.get(item, 'type');
      // }).mapValues(function (group) {
      //     group.forEach(function (item) {
      //         item.inCatalogue = !!_.find(surveys, {TemplateId: item.id});
      //     });
      //     return {list: group, length: group.length, limit: 3, expanded: true};
      // }).value();
      _.forOwn($scope.permissionGroup.serviceBundles, function (item) {
        item.inCatalogue = !!_.find(serviceBundles, { TemplateId: item.id });
      });
      _.forOwn($scope.permissionGroup.emailSequences, function (item) {
        item.inCatalogue = !!_.find(emailSequences, { TemplateId: item.id });
      });
      _.forOwn($scope.permissionGroup.graders, function (item) {
        item.inCatalogue = !!_.find(graders, { TemplateId: item.id });
      });
      _.forOwn($scope.permissionGroup.meetingTemplates, function (item) {
        item.inCatalogue = !!_.find(meetingTemplates, { TemplateId: item.id });
      });
      _.forOwn($scope.permissionGroup.surveys, function (item) {
        item.inCatalogue = !!_.find(surveys, { TemplateId: item.id });
      });

      $scope.changeView = function (viewName) {
        $scope.currentView = viewName;
      };
      $scope.getGraderPopover = function (grader) {
        if (grader && grader.PreviewImageId) {
          return '<img src="/images/graderpreviews/' + grader.id + '" style="max-width: 300px;">';
        } else {
          return '';
        }
      };
      $scope.getSurveyPopover = function (survey) {
        if (survey && survey.PreviewImageId) {
          return (
            '<img src="/images/surveypreviews/' +
            survey.id +
            '" style="max-width: 300px;max-height:300px;float: right;margin:15px">' +
            (survey.description ? survey.description : '')
          );
        } else {
          return survey.description ? survey.description : '';
        }
      };

      $scope.addCatService = function (service) {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.post('/api/service/services/templates', { templateIds: [service.id] })
          .then(function () {
            service.inCatalogue = true;
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.message($translate.instant('message.academy.serviceAdded'));
          })
          .catch(function (error) {
            DialogService.error(error);
            console.error(error);
            $rootScope.$broadcast('dataLoadingFinished');
          });
      };
      $scope.addCatBundle = function (bundle) {
        $state.go('service.servicebundleCopy', { serviceBundleId: bundle.id });
      };
      $scope.addCatEmailSequence = function (emailsequence) {
        $state.go('marketing.emailsequenceCopy', { emailsequenceId: emailsequence.id });
      };
      $scope.addCatGrader = function (grader) {
        $state.go('marketing.graderCopy', { graderId: grader.id });
      };
      $scope.addCatSurvey = function (survey) {
        $state.go('sales.surveycopy', { surveyId: survey.id, type: survey.type });
      };

      $scope.addCatMeetingTemplate = function (meetingTemplate) {
        $state.go('meeting.template', {
          meetingTemplateId: meetingTemplate.id,
          copy: true,
          vendor: false,
        });
      };

      // $scope.getAssetTypeIconTitle = AssetService.getAssetTypeIconTitle;
      // $scope.getAssetTypeIcon = AssetService.getAssetTypeIcon;
      //
      // var generateAssetType = function (asset) {
      //     if (!$scope.assetList[asset.type]) {
      //         $scope.assetList[asset.type] = {
      //             id: asset.type,
      //             type: asset.type,
      //             name: 'academy.menu.' + asset.type,
      //             children: [],
      //             count: 0,
      //             expanded: true
      //         };
      //     }
      //     asset.typeIcon = AssetService.getAssetTypeIcon(asset);
      //     asset.prerequisitesDone = true;
      //     $scope.assetList[asset.type].children.push(asset);
      // };
      //
      // var generateAssetList = function () {
      //     _.forEach($scope.assetList, function (type) {
      //         if (type.children.length) {
      //             type.children = [];
      //         }
      //         if (type.count) {
      //             type.count = 0;
      //         }
      //     });
      //     _.forEach($scope.permissionGroup.academy, function (asset) {
      //         generateAssetType(asset);
      //         $scope.assetList[asset.type].count++;
      //     });
      // };
      // generateAssetList();

      if ($stateParams.nextSteps === 'true') {
        var last4 = SessionStorage.get('last4');
        var message = '';
        if (last4) {
          message = $translate.instant('label.marketplace.buy.confirmation', {
            permissionGroup: $scope.permissionGroup,
            customer: {
              email: $scope.user.email,
              card: { last4: last4 },
            },
            statementDescriptor:
              InputSanitizerService.sanitize(
                _.get($scope.permissionGroup.stripePlan, 'statementDescriptor'),
              ) || $translate.instant('label.marketplace.buy.confirmation.statementDescriptor'),
          });
        }

        if ($scope.permissionGroup.nextSteps) {
          message += (last4 ? '<hr/>' : '') + $scope.permissionGroup.nextSteps;
        }

        if (message) {
          DialogService.message(message);
        }
      }
    },
  )

  .controller('VendorMyPermissionGroupsController', function ($scope, _, permissiongroups) {
    $scope.filter = { name: '' };
    $scope.permissionGroups = permissiongroups;

    $scope.nameFilter = function (item) {
      return (
        $scope.filter.name === '' ||
        item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1 ||
        (item.description &&
          item.description.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1)
      );
    };
  })
  .controller(
    'VendorPermissionGroupsController',
    function (
      $scope,
      $rootScope,
      $state,
      $modal,
      $translate,
      $timeout,
      $window,
      _,
      AssetService,
      DialogService,
      CurrentUser,
      HttpService,
      LocalStorage,
      PermissionGroupModel,
      /*roadmaps, */ assetCategories,
      permissiongroups,
      services,
      serviceBundles,
      emailSequences,
      graders,
      meetingTemplates,
      surveysTemplates,
    ) {
      $scope.permissionGroups = permissiongroups;
      $scope.currentUser = CurrentUser.getUser();
      $scope.itemTree = [];
      $scope.permissionGroupStatus = LocalStorage.getObject('permissionGroupStatus', {});
      // $scope.roadmaps = roadmaps;
      $scope.assetCategories = assetCategories;
      // type - category - object

      _.forEach(AssetService.mainCatsObj, function (type, key) {
        if (type.showInSidebar) {
          $scope.itemTree.push({
            id: key,
            name: $translate.instant('label.academy.assetTypes.' + key),
            type: 'academy',
            children: _($scope.assetCategories)
              .filter({ type: key })
              .map(function (category) {
                return _.extend(_.pick(category, ['name', 'type', 'id']), {
                  children: category.SubCategories,
                });
              })
              .value(),
          });
        }
      });

      $scope.itemTree.push({
        name: 'Services',
        type: 'service',
        children: [
          {
            name: 'Templates',
            type: 'service',
            id: 'templates',
            children: _.sortBy(
              _.filter(services, { VendorId: $scope.currentUser.Company.id }),
              'name',
            ),
          },
        ],
      });
      $scope.itemTree.push({
        name: 'Service Bundles',
        type: 'serviceBundle',
        children: [
          {
            name: 'Templates',
            type: 'serviceBundle',
            id: 'templates',
            children: _.sortBy(
              _.filter(serviceBundles, { OwnerId: $scope.currentUser.Company.id }),
              'name',
            ),
          },
        ],
      });
      if (CurrentUser.can('listEmailSequences')) {
        $scope.itemTree.push({
          name: 'Email Workflows',
          type: 'emailSequence',
          children: [
            {
              name: 'Templates',
              type: 'emailSequence',
              id: 'templates',
              children: _.sortBy(
                _.filter(emailSequences, { OwnerId: $scope.currentUser.Company.id }),
                'name',
              ),
            },
          ],
        });
      }
      if (CurrentUser.can('listGraders')) {
        $scope.itemTree.push({
          name: 'Graders',
          type: 'grader',
          children: [
            {
              name: 'Templates',
              type: 'grader',
              id: 'templates',
              children: _.sortBy(
                _.filter(graders, { OwnerId: $scope.currentUser.Company.id }),
                'name',
              ),
            },
          ],
        });
      }
      if (CurrentUser.can('listMeetingTemplates')) {
        $scope.itemTree.push({
          name: 'Reports',
          type: 'meetingTemplate',
          children: [
            {
              name: 'Templates',
              type: 'meetingTemplate',
              id: 'templates',
              children: _.sortBy(
                _.filter(meetingTemplates, { OwnerId: $scope.currentUser.Company.id }),
                'name',
              ),
            },
          ],
        });
      }
      if (CurrentUser.can('listSurveys')) {
        $scope.itemTree.push({
          name: 'Needs Assesments',
          type: 'survey',
          children: [
            {
              name: 'Templates',
              type: 'survey',
              id: 'templates',
              children: _.sortBy(
                _.filter(surveysTemplates, { OwnerId: $scope.currentUser.Company.id }),
                'name',
              ),
            },
          ],
        });
      }

      $scope.create = function () {
        $state.go('tools.permissionGroupAdd');
        /*$scope.messages = [];
            $scope.errors = [];
            var modalInstance = $modal.open({
                templateUrl: '/templates/academy/edit-permission-group-modal.html',
                controller: 'VendorPermissionGroupsEditController',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    permissiongroup: function () {
                        return {'name': '', 'description': ''};
                    },
                    users: function (CurrentUser, HttpService) {
                        return HttpService.get('/api/admin/users?companyId=' + CurrentUser.getUser().Company.id);
                    },
                    roadmaps: function () {
                        return $scope.roadmaps;
                    }
                }
            });

            modalInstance.result.then(function (data) {
                var _permissionGroup = new PermissionGroupModel(data);
                _permissionGroup.$save(function (result) {
                    $scope.permissionGroups.push(result);
                    $rootScope.$broadcast('dataLoadingFinished');
                }, function (error) {
                    $scope.errors = [error];
                    $rootScope.$broadcast('dataLoadingFinished');
                });
            }, function () {
            });*/
      };

      $scope.groupHasCategory = function (group, asset) {
        return (
          !_.isEmpty(asset.PermissionGroups) && _.find(asset.PermissionGroups, { id: group.id })
        );
      };

      $scope.addRemoveCategoryToGroup = function (permissionGroup, asset, type) {
        if (!$scope.groupHasCategory(permissionGroup, asset)) {
          PermissionGroupModel.addAsset({
            permissionGroupId: permissionGroup.id,
            assetId: asset.id,
            assetType: type,
          }).$promise.then(function () {
            if (!asset.PermissionGroups) {
              asset.PermissionGroups = [];
            }
            asset.PermissionGroups.push({ id: permissionGroup.id });
          });
        } else {
          PermissionGroupModel.removeAsset({
            permissionGroupId: permissionGroup.id,
            assetId: asset.id,
            assetType: type,
          }).$promise.then(function () {
            _.remove(asset.PermissionGroups, { id: permissionGroup.id });
          });
        }
      };

      $scope.deletePermissionGroup = function (permissionGroup) {
        PermissionGroupModel.delete({
          permissionGroupId: permissionGroup.id,
        }).$promise.then(function () {
          _.remove($scope.permissionGroups, { id: permissionGroup.id });
        });
      };
      $scope.editPermissionGroup = function (permissionGroup) {
        $state.go('tools.permissionGroupEdit', { permissionGroupId: permissionGroup.id });
        /*
            $scope.messages = [];
            $scope.errors = [];
            var modalInstance = $modal.open({
                templateUrl: '/templates/academy/edit-permission-group-modal.html',
                controller: 'VendorPermissionGroupsEditController',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    permissiongroup: function () {
                        return permissionGroup;
                    },
                    users: function (CurrentUser, HttpService) {
                        return HttpService.get('/api/admin/users?companyId=' + CurrentUser.getUser().Company.id);
                    },
                    roadmaps: function () {
                        return $scope.roadmaps;
                    }
                }
            });

            modalInstance.result.then(function (data) {
                permissionGroup = data;
                PermissionGroupModel.update(data).$promise.then(function () {
                    $rootScope.$broadcast('dataLoadingFinished');
                }, function (error) {
                    $scope.errors = [error];
                    $rootScope.$broadcast('dataLoadingFinished');
                });
            }, function () {
            });*/
      };

      $scope.getExpandedStatus = function (entity) {
        var expanded = $scope.permissionGroupStatus[entity.type + '_' + entity.id];
        return expanded !== false;
      };
      $scope.changeExpandedStatus = function (entity) {
        $scope.permissionGroupStatus[entity.type + '_' + entity.id] =
          !$scope.getExpandedStatus(entity);
        LocalStorage.setObject('permissionGroupStatus', $scope.permissionGroupStatus);
      };

      $timeout(function () {
        if (document.getElementById('permission-group-tbody')) {
          document.getElementById('permission-group-tbody').style.height =
            window.innerHeight - 250 + 'px';
          window.onresize = function () {
            document.getElementById('permission-group-tbody').style.height =
              window.innerHeight - 250 + 'px';
          };
        }
      }, 500);
    },
  )

  .controller(
    'VendorPermissionGroupViewController',
    function ($scope, $state, _, CurrentUser, DialogService, HttpService, permissiongroup) {
      $scope.user = CurrentUser.getUser();
      $scope.permissionGroup = permissiongroup;
      $scope.logoUrl = permissiongroup.ImageId
        ? '/images/permissiongroups/' + $scope.permissionGroup.id + '?' + new Date().getTime()
        : undefined;
      $scope.view = 'overview';

      // $scope.isSubscribed = function () {
      //     return _.includes($scope.CurrentUser.Company.PermissionGroups, $scope.permissionGroup.id);
      //     // return !!_.find(permissiongroup.Companies, {id: $scope.user.Company.id});
      // };
      //
      // $scope.subscribeToPermissionGroup = function () {
      //     HttpService.put('/api/vendor/permissiongroups/' + permissiongroup.id + '/subscribe').then(function () {
      //         if (permissiongroup.price) {
      //             DialogService.message('message.vendor.permissionGroupSubscriptionSent');
      //         } else {
      //             $scope.permissionGroup.Companies.push({id: $scope.user.Company.id});
      //         }
      //     }, function (error) {
      //         console.error(error);
      //         $scope.errors = [error];
      //     });
      // };
    },
  )

  .controller(
    'VendorPermissionGroupEditController',
    function (
      $modal,
      $rootScope,
      $scope,
      $state,
      $translate,
      _,
      AssetService,
      InputSanitizerService,
      AcademyService,
      CurrentUser,
      DialogService,
      HttpService,
      PermissionGroupModel,
      UploadService,
      permissionGroup,
      permissionGroupCategories,
      courses,
      users,
    ) {
      $scope.currentUser = CurrentUser.getUser();
      $scope.permissionGroup = permissionGroup || {
        SubCategories: [],
        AttachedAssets: [],
        VendorId: $scope.currentUser.companyId,
      };
      if ($scope.permissionGroup.stripePlan) {
        $scope.readOnlyStripePlan = true;
        $scope.permissionGroup.stripePlanId = $scope.permissionGroup.stripePlan.id;
        $scope.permissionGroup.price = $scope.permissionGroup.stripePlan.amount / 100;
        $scope.permissionGroup.discountPrice = null;
      }

      $scope.$watch('permissionGroup.stripePlanId', function (newValue, oldValue) {
        if (newValue && !$scope.readOnlyStripePlan) {
          HttpService.get(
            '/api/payment/plans/' +
              InputSanitizerService.sanitize($scope.permissionGroup.stripePlanId),
          )
            .then(function (stripePlan) {
              $scope.permissionGroup.stripePlan = stripePlan;
              $scope.permissionGroup.price = stripePlan.amount / 100;
              $scope.permissionGroup.discountPrice = null;
              DialogService.message({
                code: 'label.permissionGroup.subscription.connectPlan',
                translateValues: {
                  name: stripePlan.nickname,
                  amount: $scope.permissionGroup.price,
                  product: stripePlan.productName,
                },
              });
            })
            .catch(function (error) {
              DialogService.error(error);
            });
        }
      });

      if ($scope.currentUser.companyId !== $scope.permissionGroup.VendorId) {
        $state.go('marketplace.permissiongroup', { permissionGroupId: permissionGroup.id });
      }
      $scope.logoUrl = $scope.permissionGroup.ImageId
        ? '/images/permissiongroups/' + $scope.permissionGroup.id + '?' + new Date().getTime()
        : undefined;
      $scope.users = [];
      _.forEach(users, function (user) {
        $scope.users.push({
          id: user.id,
          name: user.firstName + ' ' + user.lastName,
          logoUrl: '/images/users/' + user.id + '?' + new Date().getTime(),
        });
      });
      $scope.permissionGroup.support = $scope.permissionGroup.support || [];
      $scope.supportList = [
        'sales',
        'marketing',
        'technical',
        'migration',
        'onboarding',
        'consulting',
        'training',
        'customization',
      ].map(function (value) {
        return { id: value, name: $translate.instant('label.permissionGroup.support.' + value) };
      });

      $scope.selectDefaultCourses = {};
      $scope.courseList = _.filter(courses, function (course) {
        return (
          course.SubCategory &&
          _(course.SubCategory.PermissionGroups).map('id').includes($scope.permissionGroup.id)
        );
      });

      $scope.selectCategory = {};
      $scope.categoryList = [];
      _.forEach(permissionGroupCategories, function (category) {
        if (category.SubCategories) {
          _.forEach(category.SubCategories, function (item) {
            item.selectName = category.name + ' / ' + item.name;
            item.categoryOrderId = category.orderId;
            $scope.categoryList.push(item);
          });
        }
      });
      $scope.categoryList = _.orderBy($scope.categoryList, function (c) {
        return [c.categoryOrderId, c.orderId];
      });
      $scope.permissionGroup.subcategories = _.flatMap($scope.permissionGroup.SubCategories, 'id');

      $scope.addCategory = function (category, list) {
        if (!list) {
          list = [];
        }
        if (category) {
          list.push(category);
        }
        $scope.selectCategory = {};
      };
      $scope.removeCategory = function (category, list) {
        AcademyService.remove(category, list);
      };
      $scope.categoryFilter = function (o) {
        return _.indexOf($scope.permissionGroup.subcategories, o.id) === -1;
      };

      $scope.defaultCourseFilter = function (o) {
        return !_.find($scope.permissionGroup.DefaultCourseIds, { id: o.id });
      };
      $scope.addDefaultCourse = function (course, list) {
        if (!list) {
          list = [];
        }
        if (course) {
          list.push({ id: course.id });
        }
        $scope.selectDefaultCourses = {};
      };
      $scope.removeDefaultCourse = function (item2remove, list) {
        _.remove(list, function (item) {
          return item.id === item2remove.id;
        });
      };
      $scope.$watchCollection('permissionGroup.DefaultCourseIds', function (val) {
        if (!val) {
          $scope.permissionGroup.DefaultCourseIds = [];
        }
        $scope.defaultCourses = [];
        _.forEach($scope.permissionGroup.DefaultCourseIds, function (item) {
          var data = _.find($scope.courseList, { id: item.id });
          if (data) {
            $scope.defaultCourses.push(data);
          }
        });
      });

      $scope.openLogo = function () {
        UploadService.openImage('permissiongroups', $scope.permissionGroup).then(function () {
          delete $scope.permissionGroup.ImageId;
          $scope.logoUrl =
            '/images/permissiongroups/' + $scope.permissionGroup.id + '?' + new Date().getTime();
        });
      };

      function youtube_parser(url) {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return match && match[7].length === 11 ? match[7] : false;
      }

      $scope.changeVideo = function (event) {
        var val = event.clipboardData.getData('Text');
        if ($scope.permissionGroup.videoSource === 'youtube') {
          var parseUrlCode = youtube_parser(val);
          if (parseUrlCode) {
            $timeout(function () {
              $scope.permissionGroup.video = parseUrlCode;
            }, 100);
          }
        }
      };

      $scope.openSendTestEmail = function () {
        var modalInstance = $modal.open({
          template:
            '<modal-form onok="ok" oncancel="cancel" label-cancel="button.close" label-ok="button.send" form-title="label.permissionGroup.sendTestEmail">' +
            '<input-text model="options.emails" label="label.permissionGroup.sendTestEmail.emails"></input-text>' +
            '<iframe id="sample-iframe" width="100%" height="600px"></iframe>' +
            '</modal-form>',
          controller: function (
            $modalInstance,
            $scope,
            $state,
            $timeout,
            $translate,
            _,
            HttpService,
            permissionGroup,
          ) {
            $scope.options = {};
            $scope.permissionGroup = permissionGroup;

            $timeout(function () {
              HttpService.post('/email/sample', { body: $scope.permissionGroup.nextSteps }).then(
                function (result) {
                  var doc = document.getElementById('sample-iframe').contentWindow.document;
                  doc.open();
                  doc.write(result);
                  doc.close();
                },
              );
            }, 1000);

            $scope.ok = function (cb) {
              HttpService.post('/api/email/send', {
                email: {
                  addresses: _($scope.options.emails).split(',').map(_.trim).value(),
                  subject: $scope.permissionGroup.name,
                  body: $scope.permissionGroup.nextSteps,
                },
              })
                .then(function (result) {
                  cb();
                  $scope.messages = ['label.permissionGroup.sendTestEmail.success'];
                })
                .catch(function (error) {
                  cb();
                  $scope.errors = [error];
                });
            };

            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
          backdrop: 'static',
          resolve: {
            permissionGroup: function () {
              return $scope.permissionGroup;
            },
          },
        });
      };

      $scope.ok = function (cb) {
        $rootScope.$broadcast('dataLoadingStarted');
        var _permissionGroup = new PermissionGroupModel($scope.permissionGroup);
        _permissionGroup.$save(
          function () {
            $rootScope.$broadcast('dataLoadingFinished');
            if (cb) {
              cb();
            }
            $scope.readOnlyStripePlan = !!$scope.permissionGroup.stripePlan;
            $state.go('marketplace.permissiongroup', { permissionGroupId: _permissionGroup.id });
          },
          function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            console.error(error);
            DialogService.error(error.data);
            if (cb) {
              cb();
            }
          },
        );
      };

      $scope.cancel = function () {
        $state.go('marketplace.permissiongroup', { permissionGroupId: $scope.permissionGroup.id });
      };
    },
  )

  .controller('MarketplaceAcademyItemController', function ($scope, $translate, DialogService) {
    $scope.openBuyMessage = function (asset) {
      DialogService.message(
        $translate.instant('message.marketplace.buy', {
          type: $translate.instant('label.academy.assetTypes.' + asset.type),
        }),
      );
    };
  })

  .controller(
    'MarketplaceController',
    function (
      $http,
      $moment,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $translate,
      _,
      CurrentUser,
      DialogService,
      HttpService,
      PermissionGroupCategoryModel,
      SidebarFilterService,
      permissionGroups,
      permissionGroupCategories,
      InputSanitizerService,
    ) {
      $scope.filter = SidebarFilterService.get('permissiongroups').filter;
      $scope.permissionGroups = permissionGroups;
      $scope.currentUser = CurrentUser.getUser();
      $scope.isVendor = CurrentUser.isVendor;

      $scope.isSubscribed = function (permissionGroup) {
        return _.includes($scope.currentUser.Company.PermissionGroups, permissionGroup.id);
      };

      $scope.isPayed = function (permissionGroup) {
        var charge = _.find($scope.currentUser.Company.Charges, {
          PermissionGroupId: permissionGroup.id,
        });
        return charge && (!charge.paymentIntent || charge.paymentIntent.status == 'succeeded');
      };

      function parseBreadcrumbCategories() {
        var isSubcategory = false;
        $scope.BreadCrumbSubCategories = [];
        $scope.BreadCrumbCategories = [];
        if ($stateParams.categoryId) {
          _.forEach(permissionGroupCategories, function (category) {
            var counter = 0;
            _.forEach(category.SubCategories, function (subcategory) {
              var subcounter = subcategory.PermissionGroups.length;
              $scope.BreadCrumbSubCategories.push({
                id: subcategory.id,
                name: subcategory.name,
                permissionGroupCounter: subcounter,
              });
              counter += subcounter;
              if ($stateParams.categoryId == subcategory.id) {
                isSubcategory = true;
                $scope.BreadCrumbCategory = { id: category.id, name: category.name };
                $scope.BreadCrumbSubCategory = { id: subcategory.id, name: subcategory.name };
              }
            });
            $scope.BreadCrumbCategories.push({
              id: category.id,
              name: category.name,
              permissionGroupCounter: counter,
            });
            if ($stateParams.categoryId == category.id) {
              $scope.BreadCrumbCategory = { id: category.id, name: category.name };
            }
          });
          if (!isSubcategory) {
            $scope.BreadCrumbSubCategories = [];
            $scope.BreadCrumbSubCategory = {};
          }
        }
      }

      if ($stateParams.categoryId) {
        HttpService.get(
          '/api/vendor/permissiongroupcategories/' +
            InputSanitizerService.sanitize($stateParams.categoryId),
        ).then(function (result) {
          $scope.permissionGroupCategory = result;
          parseBreadcrumbCategories();
        });
        // $scope.permissionGroupCategory = PermissionGroupCategoryModel.get($stateParams.categoryId)
      }

      // if ($stateParams.permissionGroupCategoryId) {
      //     $scope.permissionGroupCategory = permissionGroupCategories;
      //     if (permissionGroupCategories.SubCategories) {
      //         $scope.permissionGroups = _(permissionGroupCategories.SubCategories).flatMap(function (o) {
      //             var permissionGroups = o.PermissionGroups;
      //             _.forEach(permissionGroups, function (permissionGroup) {
      //                 permissionGroup.SubCategory = _.omit(o, 'PermissionGroups');
      //             });
      //             return permissionGroups;
      //         }).uniqBy('id').value();
      //     } else {
      //         var permissionGroups = permissionGroupCategories.PermissionGroups;
      //         _.forEach(permissionGroups, function (permissionGroup) {
      //             permissionGroup.SubCategory = _.omit(permissionGroupCategories, 'PermissionGroups');
      //         });
      //         $scope.permissionGroups = permissionGroups;
      //     }
      // } else {
      //     $scope.permissionGroups = _(permissionGroupCategories).flatMap('SubCategories').flatMap(function (o) {
      //         var permissionGroups = o.PermissionGroups;
      //         _.forEach(permissionGroups, function (permissionGroup) {
      //             permissionGroup.SubCategory = _.omit(o, 'PermissionGroups');
      //         });
      //         return permissionGroups;
      //     }).uniqBy('id').value();
      // }

      $scope.nameFilter = function (item) {
        return (
          !$scope.filter.name ||
          item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1 ||
          (item.description &&
            item.description.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1)
        );
      };

      $scope.priceFilter = function (item) {
        var selected = _.filter($scope.filter.price, { selected: true });
        return (
          _.isEmpty(selected) ||
          _.find(selected, function (priceRange) {
            return (
              (priceRange.min < item.realPrice && priceRange.max >= item.realPrice) ||
              (priceRange.min == priceRange.max && priceRange.min == item.realPrice)
            );
          })
        );
      };

      $scope.createdAtFilter = function (item) {
        var selected = _.filter($scope.filter.createdAt, { selected: true });
        return (
          _.isEmpty(selected) ||
          _.find(selected, function (dayRange) {
            return $moment().subtract(dayRange.days, 'day').isBefore($moment(item.createdAt));
          })
        );
      };

      $scope.vendorFilter = function (item) {
        var selected = _.filter($scope.filter.vendors, { selected: true });
        return (
          _.isEmpty(selected) ||
          _.find(selected, function (vendor) {
            return vendor.id == item.Vendor.id;
          })
        );
      };

      $scope.typeFilter = function (item) {
        var selected = _.filter($scope.filter.type, { selected: true });
        return (
          _.isEmpty(selected) ||
          _.find(selected, function (type) {
            return type.code == item.type;
          })
        );
      };

      $scope.userRoleFilter = function (item) {
        var selected = _.filter($scope.filter.userRoles, { selected: true });
        if (_.isEmpty(selected)) {
          return true;
        } else {
          var res = false;
          _.forEach(selected, function (o) {
            if (_.includes(item.userRoles, o.code)) {
              res = true;
              return false;
            } else if (_.isEmpty(item.userRoles) && o.code === '') {
              res = true;
              return false;
            }
          });
          return res;
        }
      };

      $scope.subscriptionFilter = function (item) {
        return true;
        // if (!$scope.filter.currentUserProductId) {
        //     return true;
        // }
        // return !item.isProfessional || serverConfigSettings.professionalProductId === $scope.filter.currentUserProductId;
      };

      $scope.assetTypeFilter = function (item) {
        var selected = _.filter($scope.filter.assetType, { selected: true });
        if (_.isEmpty(selected)) {
          return true;
        } else {
          var res = false;
          _.forEach(selected, function (o) {
            if (item.assetCounts[o.code] > 0) {
              res = true;
              return false;
            }
          });
          return res;
        }
      };
    },
  )

  .controller(
    'MarketplaceMyPermissionGroupsController',
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $http,
      $translate,
      _,
      CurrentUser,
      HttpService,
      PermissionGroupCategoryModel,
      company,
      permissionGroups,
      permissionGroupCategories,
    ) {
      //$scope.permissionGroups = permissionGroups;
      $scope.currentUser = CurrentUser.getUser();
      $scope.company = company;
      //console.log(company);
      $scope.isVendor = CurrentUser.isVendor;
    },
  )
  .controller(
    'VendorPermissionGroupCategoryEditController',
    function ($scope, $modalInstance, _, permissiongroupcategory) {
      $scope.permissionGroupCategory = permissiongroupcategory;

      $scope.ok = function () {
        $modalInstance.close($scope.permissionGroupCategory);
      };

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };
    },
  )
  .controller(
    'VendorPermissionGroupCategoryAdminController',
    function (
      $scope,
      $rootScope,
      $modal,
      $q,
      _,
      DialogService,
      HttpService,
      PermissionGroupCategoryModel,
      permissionGroupCategories,
    ) {
      $scope.itemTree = permissionGroupCategories;
      _.forEach(permissionGroupCategories, function (permissionGroupCategory) {
        permissionGroupCategory.children = permissionGroupCategory.SubCategories;
      });

      function refreshCategoryTree() {
        var oldItemtree = $scope.itemTree;
        $scope.itemTree = [];
        var deferred = $q.defer();

        HttpService.get('/api/vendor/permissiongroupcategorytree')
          .then(function (_categories) {
            $scope.itemTree = _categories;

            _.forEach(_categories, function (_category) {
              _category.children = _category.SubCategories;
            });

            deferred.resolve();
          })
          .catch(function (error) {
            console.error(error);
            deferred.reject();
          });
        deferred.resolve();
      }

      $scope.create = function (parent) {
        $scope.messages = [];
        $scope.errors = [];
        var modalInstance = $modal.open({
          templateUrl: '/templates/admin/permissiongroupcategory-edit-modal.html',
          controller: 'VendorPermissionGroupCategoryEditController',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            permissiongroupcategory: function () {
              return { name: '', description: '', CategoryId: parent ? parent.id : null };
            },
          },
        });

        modalInstance.result.then(
          function (data) {
            var _permissionGroupCategory = new PermissionGroupCategoryModel(data);
            _permissionGroupCategory.$save(
              function (result) {
                refreshCategoryTree();
                $rootScope.$broadcast('dataLoadingFinished');
              },
              function (error) {
                $scope.errors = [error];
                $rootScope.$broadcast('dataLoadingFinished');
              },
            );
          },
          function () {},
        );
      };

      $scope.edit = function (item) {
        var modalInstance = $modal.open({
          templateUrl: '/templates/admin/permissiongroupcategory-edit-modal.html',
          controller: 'VendorPermissionGroupCategoryEditController',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            permissiongroupcategory: function () {
              return angular.copy(item);
            },
          },
        });

        modalInstance.result.then(
          function (editItem) {
            new PermissionGroupCategoryModel(editItem).$save();
            item.name = editItem.name;
            item.description = editItem.description;
          },
          function () {},
        );
      };

      $scope.delete = function (item) {
        new PermissionGroupCategoryModel(item).$delete(function () {
          refreshCategoryTree();
        });
      };

      function reorderCategory(item, parent) {
        _.forEach(item, function (child, index) {
          if (child.orderId !== index + 1) {
            child.orderChanged = true;
            child.orderId = index + 1;
          }
          var newCategoryId = parent && parent.id ? parent.id : null;
          if (child.CategoryId !== newCategoryId) {
            child.orderChanged = true;
            child.CategoryId = newCategoryId;
          }
          if (child.children) {
            reorderCategory(child.children, child);
          }
        });
      }

      function saveReorder() {
        var deferred = $q.defer();
        var categories = $scope.itemTree;

        PermissionGroupCategoryModel.reorder(
          _(categories)
            .flatMap('children')
            .union(categories)
            .filter({ orderChanged: true })
            .value(),
        ).$promise.then(
          function () {
            _.forEach(categories, function (category) {
              category.orderChanged = false;
            });
            deferred.resolve();
          },
          function () {
            deferred.reject();
          },
        );
        return deferred.promise;
      }

      $scope.dragstartCallback = function (item, parentItem) {
        $scope.dragging = true;
        $scope.dragItem = item;
      };
      $scope.dragendCallback = function () {
        $scope.dragging = false;
        $scope.dragItem = null;
      };
      $scope.dropCallback = function (srcList, srcIndex, targetList, targetIndex) {
        // Copy the item from source to target.
        targetList.splice(targetIndex, 0, srcList[srcIndex]);
        // Remove the item from the source, possibly correcting the index first.
        // We must do this immediately, otherwise ng-repeat complains about duplicates.
        if (srcList == targetList && targetIndex <= srcIndex) {
          srcIndex++;
        }
        srcList.splice(srcIndex, 1);
        reorderCategory($scope.itemTree);
        $rootScope.$broadcast('dataLoadingStarted');
        saveReorder().then(
          function () {
            $rootScope.$broadcast('dataLoadingFinished');
          },
          function (error) {
            DialogService.error(error);
            $rootScope.$broadcast('dataLoadingFinished');
          },
        );
        // By returning true from dnd-drop we signalize we already inserted the item.
        return true;
      };
    },
  )

  .controller('VendorsController', function ($scope, _, CurrentUser, HttpService, vendors) {
    $scope.vendors = vendors;
    $scope.isVendor = CurrentUser.isVendor;

    // $scope.changeMarketplaceSubscription = function (vendor) {
    //     if (vendor.Member) {
    //         HttpService.delete('/api/academy/vendors/' + vendor.id + '/subscription').then(function () {
    //             vendor.Member = null;
    //             // AssetManager.resetAssets();
    //         });
    //     } else {
    //         HttpService.put('/api/academy/vendors/' + vendor.id + '/subscription').then(function () {
    //             vendor.Member = CurrentUser.getUser().Company;
    //             // AssetManager.resetAssets();
    //         });
    //     }
    // };
  })

  .controller('VendorController', function ($scope, _, CurrentUser, company) {
    $scope.company = company;
    $scope.currentUser = CurrentUser.getUser();

    $scope.isVendor = CurrentUser.isVendor;
    $scope.isSubscribed = function (permissionGroup) {
      return _.includes($scope.currentUser.Company.PermissionGroups, permissionGroup.id);
    };

    // $scope.limit = 3;
    // $scope.assetList = {};

    // $scope.changeMarketplaceSubscription = function (vendor) {
    //     if (vendor.Member) {
    //         HttpService.delete('/api/academy/vendors/' + vendor.id + '/subscription').then(function () {
    //             vendor.Member = null;
    //             // AssetManager.resetAssets();
    //         });
    //     } else {
    //         HttpService.put('/api/academy/vendors/' + vendor.id + '/subscription').then(function () {
    //             vendor.Member = CurrentUser.getUser().Company;
    //             // AssetManager.resetAssets();
    //         });
    //     }
    // };

    // $scope.generateAssetType = function (asset) {
    //     if (!$scope.assetList[asset.type]) {
    //         $scope.assetList[asset.type] = {
    //             id: asset.type,
    //             type: asset.type,
    //             name: 'academy.menu.' + asset.type,
    //             limit: $scope.limit,
    //             children: [],
    //             count: 0
    //         };
    //     }
    //     if ($scope.assetList[asset.type].children.length < $scope.assetList[asset.type].limit) {
    //         asset.typeIcon = AssetService.getAssetTypeIcon(asset);
    //         $scope.assetList[asset.type].children.push(asset);
    //     }
    // };

    // $scope.generateAssetList = function (results) {
    //     _.forEach($scope.assetList, function (type) {
    //         if (type.children.length) {
    //             type.children = [];
    //         }
    //         if (type.count) {
    //             type.count = 0;
    //         }
    //     });
    //     _.forEach(results, function (asset) {
    //         if (asset.type === 'course') {
    //             // users go here if they are not members of the given vendor, we don't have to calculate the prerequisites - performance saving
    //             asset.prerequisitesDone = false;
    //         }
    //         $scope.generateAssetType(asset);
    //         $scope.assetList[asset.type].count++;
    //     });
    // };

    // $scope.showMore = function (type) {
    //     $scope.assetList[type].limit += 5;
    //     $scope.generateAssetList(company.Assets, $scope.limit);
    // };

    // $scope.generateAssetList(company.Assets);
  })

  .service(
    'MarketPlacePaymentService',
    function (
      $rootScope,
      $state,
      $timeout,
      $translate,
      $http,
      CurrentUser,
      _,
      Auth,
      DialogService,
      HttpService,
      InputSanitizerService,
    ) {
      var confirmPayment = function (paymentResult, message, confirmation, currentUser) {
        if (paymentResult && paymentResult.paymentIntentClientSecret) {
          var stripe;
          if (paymentResult.stripeAccountId) {
            stripe = new Stripe(params.stripe_pk, { stripeAccount: paymentResult.stripeAccountId });
          } else {
            stripe = new Stripe(params.stripe_pk);
          }
          return stripe
            .confirmCardPayment(paymentResult.paymentIntentClientSecret)
            .then(function (confirmationResult) {
              if (
                confirmationResult.paymentIntent &&
                confirmationResult.paymentIntent.status === 'succeeded'
              ) {
                return HttpService.post(
                  '/api/payment/paymentintents/confirm',
                  confirmationResult.paymentIntent,
                ).then(function () {
                  $rootScope.$broadcast('dataLoadingFinished');
                  $timeout(function () {
                    Auth.refreshToken(function () {
                      $state.reload();
                    });
                  }, 500);
                  DialogService.message(message, confirmation, 'lg');
                });
              } else {
                // in case of error we will delete the payment intent on our side
                return HttpService.delete(
                  '/api/payment/paymentintents/' + paymentResult.paymentIntentId,
                ).then(function () {
                  $rootScope.$broadcast('dataLoadingFinished');
                  _.remove(currentUser.Company.Charges, {
                    paymentIntentId: paymentResult.paymentIntentId,
                  });
                });
              }
            })
            .catch(function (error) {
              $rootScope.$broadcast('dataLoadingFinished');
              DialogService.error(error);
            });
        } else {
          $rootScope.$broadcast('dataLoadingFinished');
          $timeout(function () {
            Auth.refreshToken(function () {
              $state.reload();
            });
          }, 500);
          DialogService.message(message, confirmation, 'lg');
          return Promise.resolve();
        }
      };

      var subscribe = function (permissionGroupId, successMessage, confirmation, couponCode) {
        $rootScope.$broadcast('dataLoadingStarted');
        return HttpService.put(
          '/api/vendor/permissiongroups/' +
            InputSanitizerService.sanitize(permissionGroupId) +
            '/company/' +
            InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
          { couponCode: couponCode },
        )
          .then(function (paymentResult) {
            return confirmPayment(paymentResult, successMessage, confirmation);
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
            return Promise.reject(error);
          });
      };

      return {
        confirmPayment: confirmPayment,
        subscribe: subscribe,
      };
    },
  )

  .controller(
    'MarketplacePermissionGroupSubscribeController',
    function (
      $modal,
      $rootScope,
      $scope,
      $state,
      $timeout,
      $translate,
      _,
      Auth,
      CurrentUser,
      DialogService,
      HttpService,
      MarketPlacePaymentService,
      InputSanitizerService,
    ) {
      $scope.currentUser = CurrentUser.getUser();
      $rootScope.$on('login-changed-event', function () {
        $scope.currentUser = CurrentUser.getUser();
      });
      if (!$scope.buttons) {
        $scope.buttons = ['getBack', 'get', 'buy', 'remove'];
      }
      $scope.isButton = function (buttonType) {
        return _.includes($scope.buttons, buttonType);
      };

      $scope.isSubscribed = function () {
        return _.includes($scope.currentUser.Company.PermissionGroups, $scope.permissionGroup.id);
      };

      $scope.isFree = function () {
        return !$scope.permissionGroup.realPrice;
      };

      $scope.canBuy = function () {
        return true;
        // return !$scope.permissionGroup.isProfessional || CurrentUser.hasProfessionalPlan();
      };

      $scope.isPurchased = function () {
        var charge = _.find($scope.currentUser.Company.Charges, {
          PermissionGroupId: $scope.permissionGroup.id,
        });
        return charge && (!charge.paymentIntent || charge.paymentIntent.status == 'succeeded');
      };

      var unsubscribe = function () {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.delete(
          '/api/vendor/permissiongroups/' +
            $scope.permissionGroup.id +
            '/company/' +
            $scope.currentUser.Company.id,
        )
          .then(function () {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.message(
              $scope.permissionGroup.stripePlan
                ? 'message.marketplace.permissionGroupUnsubscribeNonFreeSuccess'
                : 'message.marketplace.permissionGroupUnsubscribeFreeSuccess',
              function () {
                Auth.refreshToken(function () {
                  $state.go('msp.permissiongroups');
                });
              },
            );
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
          });
      };

      var openEditCard = function (ok, cancel) {
        $rootScope.$broadcast('dataLoadingFinished');
        var modalInstance = $modal.open({
          templateUrl: '/templates/admin/company-payment-card.html',
          controller: 'CompanyPaymentCardEditController',
          backdrop: 'static',
          resolve: {
            customer: function () {
              return $scope.customer;
            },
            company: function () {
              return $scope.currentUser.Company;
            },
          },
        });

        modalInstance.result.then(ok, cancel);
      };

      var openBuy = function (ok, cancel) {
        var modalInstance = $modal.open({
          templateUrl: '/templates/vendor/marketplace-permissiongroup-buy.html',
          controller: function (
            $modalInstance,
            $scope,
            $state,
            $translate,
            _,
            HttpService,
            UserEventService,
            customer,
            permissionGroup,
          ) {
            UserEventService.event('marketplacePermissionGroupBuyOpen', {
              permissionGroupId: permissionGroup.id,
              permissionGroupName: permissionGroup.name,
              vendorId: permissionGroup.Vendor.id,
              vendorName: permissionGroup.Vendor.name,
            });
            $scope.permissionGroup = permissionGroup;
            $scope.customer = customer;
            $scope.params = {
              discount: 0,
            };

            $scope.ok = function (cb) {
              if (!$scope.params.discount && $scope.params.couponCode) {
                $scope.applyCoupon(function (error) {
                  if (cb) {
                    cb();
                  }
                  if (!error) {
                    $modalInstance.close($scope.params);
                  }
                });
              } else {
                $modalInstance.close($scope.params);
              }
            };

            $scope.cancel = function () {
              $modalInstance.dismiss();
              UserEventService.event('marketplacePermissionGroupBuyCancel', {
                permissionGroupId: permissionGroup.id,
                permissionGroupName: permissionGroup.name,
                vendorId: permissionGroup.Vendor.id,
                vendorName: permissionGroup.Vendor.name,
              });
            };

            $scope.goPayment = function () {
              $state.go('msp.payment');
              $modalInstance.dismiss();
            };

            $scope.applyCoupon = function (cb) {
              var sendCouponEvent = function (error) {
                UserEventService.event('marketplacePermissionGroupBuyCoupon', {
                  permissionGroupId: permissionGroup.id,
                  permissionGroupName: permissionGroup.name,
                  vendorId: permissionGroup.Vendor.id,
                  vendorName: permissionGroup.Vendor.name,
                  coupon: $scope.params.couponCode,
                  status: error ? 'error' : 'success',
                  discount: !error ? $scope.params.discount : undefined,
                  error: error,
                });
              };

              HttpService.get(
                '/api/payment/coupons/' +
                  $scope.params.couponCode +
                  '?vendorId=' +
                  $scope.permissionGroup.VendorId,
              )
                .then(function (coupon) {
                  if (coupon && (coupon.duration === 'once' || $scope.permissionGroup.stripePlan)) {
                    if (coupon.amount) {
                      $scope.params.discount = Math.min(
                        $scope.permissionGroup.realPrice,
                        _.round(coupon.amount / 100, 2),
                      );
                    } else {
                      $scope.params.discount = Math.min(
                        $scope.permissionGroup.realPrice,
                        _.round(($scope.permissionGroup.realPrice * coupon.percent) / 100, 2),
                      );
                    }
                    if (cb) {
                      cb();
                    }
                    sendCouponEvent();
                  } else {
                    var error = $translate.instant('error.payment.coupon', {
                      couponCode: $scope.params.couponCode,
                    });
                    DialogService.error(error);
                    if (cb) {
                      cb(error);
                    }
                    sendCouponEvent(error);
                  }
                })
                .catch(function (error) {
                  DialogService.error(error);
                  if (cb) {
                    cb(error);
                  }
                  sendCouponEvent(error);
                });
            };
          },
          backdrop: 'static',
          resolve: {
            customer: function () {
              return $scope.customer;
            },
            permissionGroup: function () {
              return $scope.permissionGroup;
            },
          },
        });

        modalInstance.result.then(ok, cancel);
      };

      $scope.subscribeToPermissionGroup = function () {
        var charge = _.find($scope.currentUser.Company.Charges, {
          PermissionGroupId: $scope.permissionGroup.id,
        });

        if (
          $scope.isFree() ||
          (charge && (!charge.paymentIntent || charge.paymentIntent.status === 'succeeded'))
        ) {
          // if it is free or we already purchased it, so there is a CompanyCharge object with a successful payment
          MarketPlacePaymentService.subscribe(
            $scope.permissionGroup.id,
            $scope.permissionGroup.nextSteps,
            'message.marketplace.permissionGroupSubscriptionSuccess',
          );
        } else if (
          !$scope.isFree() &&
          charge &&
          charge.paymentIntent &&
          charge.paymentIntent.status !== 'succeeded'
        ) {
          // if we started a payment but it is not finished, try to confirm it
          MarketPlacePaymentService.confirmPayment(
            {
              paymentIntentId: charge.paymentIntentId,
              paymentIntentClientSecret: charge.paymentIntent.client_secret,
              stripeAccountId: charge.stripeAccountId,
            },
            $scope.permissionGroup.nextSteps,
            'message.marketplace.permissionGroupSubscriptionSuccess',
            $scope.currentUser,
          );
        } else {
          // buy

          $rootScope.$broadcast('dataLoadingStarted');
          HttpService.get(
            '/api/companies/' +
              InputSanitizerService.sanitize($scope.currentUser.Company.id) +
              '/payment/customer',
          )
            .then(function (customer) {
              $scope.customer = customer;
              $rootScope.$broadcast('dataLoadingFinished');
              if (!$scope.customer || !$scope.customer.card) {
                openEditCard(function () {
                  openBuy(function (params) {
                    MarketPlacePaymentService.subscribe(
                      $scope.permissionGroup.id,
                      $translate.instant('label.marketplace.buy.confirmation', {
                        permissionGroup: $scope.permissionGroup,
                        customer: $scope.customer,
                        statementDescriptor:
                          _.get($scope.permissionGroup.stripePlan, 'statementDescriptor') ||
                          $translate.instant(
                            'label.marketplace.buy.confirmation.statementDescriptor',
                          ),
                      }) +
                        ($scope.permissionGroup.nextSteps
                          ? '<hr/>' + $scope.permissionGroup.nextSteps
                          : ''),
                      'message.marketplace.permissionGroupSubscriptionSuccess',
                      params.discount ? params.couponCode : undefined,
                    );
                  });
                });
              } else {
                openBuy(function (params) {
                  MarketPlacePaymentService.subscribe(
                    $scope.permissionGroup.id,
                    $translate.instant('label.marketplace.buy.confirmation', {
                      permissionGroup: $scope.permissionGroup,
                      customer: $scope.customer,
                      statementDescriptor:
                        _.get($scope.permissionGroup.stripePlan, 'statementDescriptor') ||
                        $translate.instant(
                          'label.marketplace.buy.confirmation.statementDescriptor',
                        ),
                    }) +
                      ($scope.permissionGroup.nextSteps
                        ? '<hr/>' + $scope.permissionGroup.nextSteps
                        : ''),
                    'message.marketplace.permissionGroupSubscriptionSuccess',
                    params.discount ? params.couponCode : undefined,
                  );
                });
              }
            })
            .catch(function (error) {
              $rootScope.$broadcast('dataLoadingFinished');
              DialogService.error(error);
            });
        }
      };

      $scope.unsubscribeToPermissionGroup = function () {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.get(
          '/api/marketplace/permissiongroups/' + $scope.permissionGroup.id + '/myassets',
        )
          .then(function (permissionGroup) {
            $rootScope.$broadcast('dataLoadingFinished');
            var message = ''; //$translate.instant('warning.marketplace.solutionSetRemove') + '<br><br>';
            message +=
              $translate.instant('label.vendor.permissionGroupView.services', {
                number: permissionGroup.services.length,
              }) + '<br>';
            if (permissionGroup.services.length) {
              message += '<blockquote>';
              _.forEach(permissionGroup.services, function (item) {
                message += item.name + '<br>';
              });
              message += '</blockquote><br>';
            }
            message +=
              $translate.instant('label.vendor.permissionGroupView.serviceBundles', {
                number: permissionGroup.serviceBundles.length,
              }) + '<br>';
            if (permissionGroup.serviceBundles.length) {
              message += '<blockquote>';
              _.forEach(permissionGroup.serviceBundles, function (item) {
                message += item.name + '<br>';
              });
              message += '</blockquote><br>';
            }
            message +=
              $translate.instant('label.vendor.permissionGroupView.marketing', {
                number: permissionGroup.emailsequences.length + permissionGroup.graders.length,
              }) + '<br>';
            if (permissionGroup.emailsequences.length || permissionGroup.graders.length) {
              message += '<blockquote>';
              _.forEach(permissionGroup.emailsequences, function (item) {
                message += item.name + '<br>';
              });
              _.forEach(permissionGroup.graders, function (item) {
                message += item.name + '<br>';
              });
              message += '</blockquote><br>';
            }
            message +=
              $translate.instant('label.vendor.permissionGroupView.reports', {
                number: permissionGroup.meetingTemplates.length,
              }) + '<br>';
            if (permissionGroup.meetingTemplates.length) {
              message += '<blockquote>';
              _.forEach(permissionGroup.meetingTemplates, function (item) {
                message += item.name + '<br>';
              });
              message += '</blockquote><br>';
            }
            message +=
              $translate.instant('label.vendor.permissionGroupView.needs', {
                number: permissionGroup.surveys.length,
              }) + '<br>';
            if (permissionGroup.surveys.length) {
              message += '<blockquote>';
              _.forEach(permissionGroup.surveys, function (item) {
                message += item.name + '<br>';
              });
              message += '</blockquote><br>';
            }
            var modalInstance = $modal.open({
              template:
                '<modal-form header-class="bg-danger" buttons="buttons" oncancel="cancel" form-title="warning.marketplace.solutionSetRemoveConfirm">\n' +
                '    <span ng-bind-html="message"></span>' +
                '</modal-form>',
              backdrop: 'static',
              controller: function ($modalInstance, $scope, message) {
                $scope.message = message;
                $scope.buttons = [
                  {
                    color: 'danger',
                    label: 'button.marketplace.remove',
                    click: function () {
                      $modalInstance.close(true);
                    },
                  },
                ];
                $scope.cancel = function () {
                  $modalInstance.dismiss();
                };
              },
              resolve: {
                message: function () {
                  return message;
                },
              },
            });

            modalInstance.result.then(function () {
              unsubscribe();
            });
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
          });
      };
    },
  )

  .controller(
    'VendorMembersController',
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $http,
      $modal,
      $timeout,
      $translate,
      _,
      HttpService,
      PermissionGroupModel,
      CurrentUser,
      permissiongroups,
      companies,
      InputSanitizerService,
    ) {
      $scope.currentUser = CurrentUser.getUser(); //.Company.id;
      $scope.permissionGroups = permissiongroups;
      $scope.companies = companies.members;
      $scope.pagination = {
        allMember: companies.count || 0,
        itemsPerPage: 50,
        maxSize: 10,
        currentPage: 1,
      };
      // $scope.groupClients = groupClients;
      $scope.filter = {
        name: '',
        permissionGroups: [],
      };

      $scope.refresh = function () {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.get(
          '/api/vendor/members?limit=' +
            $scope.pagination.itemsPerPage +
            '&page=' +
            $scope.pagination.currentPage +
            '&search=' +
            $scope.filter.name +
            '&permissionGroupIds=' +
            _.join($scope.filter.permissionGroups, ','),
        )
          .then(function (result) {
            $rootScope.$broadcast('dataLoadingFinished');
            $scope.companies = result.members;
            $scope.pagination.allMember = result.count;
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
          });
      };

      $scope.loadNextPage = function () {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.get(
          '/api/vendor/members?limit=' +
            $scope.pagination.itemsPerPage +
            '&page=' +
            $scope.pagination.currentPage +
            '&search=' +
            $scope.filter.name +
            '&permissionGroupIds=' +
            _.join($scope.filter.permissionGroups, ','),
        )
          .then(function (result) {
            $rootScope.$broadcast('dataLoadingFinished');
            $scope.companies = _.concat($scope.companies, result.members);
            $scope.pagination.allMember = result.count;
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
          });
      };

      $scope.$watch('filter.name', function (newVal, oldVal) {
        if ($scope.filter.name.length >= 3) {
          if ($scope.filter.timeout) {
            $timeout.cancel($scope.filter.timeout);
          }
          $scope.filter.timeout = $timeout(function () {
            $scope.refresh();
          }, 1000);
        }
      });

      $scope.getCompanyPopover = function (company) {
        if (company) {
          var result =
            company.name +
            '<br><br>' +
            '<b>' +
            $translate.instant('label.companyDomain') +
            '</b>: ' +
            (company.domain || '') +
            '<br>' +
            '<b>' +
            $translate.instant('label.description') +
            '</b>: ' +
            (company.description || '') +
            '<br>' +
            '<b>' +
            $translate.instant('label.address') +
            '</b>: ' +
            (company.address || '') +
            '<br>' +
            '<b>' +
            $translate.instant('label.city') +
            '</b>: ' +
            (company.city || '') +
            '<br>' +
            '<b>' +
            $translate.instant('label.state') +
            '</b>: ' +
            (company.state || '') +
            '<br>' +
            '<b>' +
            $translate.instant('label.zip') +
            '</b>: ' +
            (company.zip || '');
          return result;
        } else {
          return '';
        }
      };

      function checkCompanyIdInGroup(companyId, groups) {
        var found = true;
        _.forEach(groups, function (val) {
          var group = _.find($scope.permissionGroups, { id: val });
          if (_.indexOf(group.Companies, companyId) !== -1) {
            found = false;
            return false;
          }
        });
        return found;
      }

      $scope.$watch('filter.permissionGroups', function () {
        $scope.refresh();
      });

      // $scope.companyFilter = function (company) {
      //     return ($scope.filter.disabled || !company.disabled) &&
      //         ($scope.filter.permissionGroups.length === 0 ||
      //             !checkCompanyIdInGroup(company.id, $scope.filter.permissionGroups))/* &&
      //         (!$scope.filter.name ||
      //             (company.name && company.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1) ||
      //             (company.domain && company.domain.indexOf($scope.filter.name.toLowerCase()) > -1))*/;
      // };

      $scope.create = function () {
        var modalInstance = $modal.open({
          templateUrl: '/templates/vendor/member-invite.html',
          controller: 'MemberInviteController',
          backdrop: 'static',
          resolve: {
            permissiongroups: function () {
              return $scope.permissionGroups;
            },
            users: function (CurrentUser, HttpService) {
              return HttpService.get(
                '/api/admin/users?companyId=' +
                  InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
              );
            },
          },
          //size: 'lg',
        });

        modalInstance.result.then(
          function () {
            $state.transitionTo(
              'tools.members',
              {},
              {
                reload: true,
                inherit: false,
                notify: true,
              },
            );
          },
          function () {},
        );
      };

      $scope.delete = function (company) {
        $http.delete('/api/companies/' + company.id + '/vendors').success(function () {
          _.remove($scope.companies, function (item) {
            return item.id === company.id;
          });
        });
      };

      $scope.permissionGroupHasCompany = function (group, company) {
        return _.indexOf(group.Companies, company.id) !== -1;
      };

      $scope.hasCompanyGroups = function () {
        return false; //!_.isEmpty($scope.groupClients);
      };

      // $scope.openGroupClientEdit = function (company) {
      //     var modalInstance = $modal.open({
      //         template: '<modal-form form-title="title.academy.manageCompanies.setGroupClient" title-values="{company: company.name}" onok="ok" oncancel="cancel">' +
      //             '<abm-form-group class="no-margin-top">' +
      //             '<abm-ui-select model="selected.client" values="groupClients" allow-clear="true" group-by="getOwnerName"></abm-ui-select>' +
      //             '</abm-form-group>' +
      //             '</modal-form>',
      //         controller: function ($scope, $modalInstance, HttpService, company, groupClients) {
      //             $scope.groupClients = groupClients;
      //             $scope.company = company;
      //             $scope.selected = {client: $scope.company.GroupClient || _.find(groupClients, {name: $scope.company.name})};
      //             $scope.ok = function (cb) {
      //                 HttpService.put('/api/vendor/groupclients/' + $scope.company.id + '/' + ($scope.selected.client ? $scope.selected.client.id : 'null'))
      //                     .then(function () {
      //                         $scope.company.GroupClient = $scope.selected.client;
      //                         $modalInstance.close();
      //                     })
      //                     .catch(function (error) {
      //                         cb();
      //                         $scope.errors = [error];
      //                     })
      //             };
      //             $scope.cancel = function () {
      //                 $modalInstance.dismiss();
      //             }
      //             $scope.getOwnerName = function (client) {
      //                 return client.Owner.name;
      //             }
      //         },
      //         backdrop: 'static',
      //         resolve: {
      //             company: function () {
      //                 return company;
      //             },
      //             groupClients: function () {
      //                 return groupClients;
      //             }
      //         }
      //     });
      //     // modalInstance.result.then(function () {
      //     // }, function () {
      //     // });
      // };

      $scope.companyHasPermissionGroups = function (company) {
        return _.some($scope.permissionGroups, function (group) {
          return $scope.permissionGroupHasCompany(group, company);
        });
      };

      $scope.addRemoveCompanyToGroup = function (permissionGroup, company) {
        if (!$scope.permissionGroupHasCompany(permissionGroup, company)) {
          PermissionGroupModel.addCompany({
            permissionGroupId: permissionGroup.id,
            companyId: company.id,
          }).$promise.then(function () {
            permissionGroup.Companies.push(company.id);
          });
        } else {
          PermissionGroupModel.removeCompany({
            permissionGroupId: permissionGroup.id,
            companyId: company.id,
          }).$promise.then(function () {
            permissionGroup.Companies = _.filter(permissionGroup.Companies, function (val) {
              return val !== company.id;
            });
          });
        }
      };

      $scope.getAPIKeys = function () {
        return _.map($scope.companies, function (company) {
          return {
            name: company.name,
            key: company.ukey,
          };
        });
      };
    },
  )

  .controller(
    'VendorMemberController',
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $http,
      $modal,
      $timeout,
      $translate,
      _,
      DialogService,
      HttpService,
      PermissionGroupModel,
      CurrentUser,
      company,
      courses,
      colleagues,
      InputSanitizerService,
    ) {
      $scope.options = {};
      $scope.company = company;
      $scope.colleagues = colleagues;
      $scope.currentUser = CurrentUser.getUser();

      if (CurrentUser.hasModule('vendor')) {
        var companyCoachId = InputSanitizerService.sanitize(
          _.get(
            _.find($scope.company.Vendors, {
              id: InputSanitizerService.sanitize($scope.currentUser.Company.id),
            }),
            'CompanyVendor.CoachId',
          ),
        );
        $scope.company.Coach = _.find(colleagues, { id: companyCoachId });
      }

      $scope.myUsers = _.filter($scope.company.Users, function (user) {
        return user.Coach && user.Coach.id == $scope.currentUser.id;
      });
      $scope.users = _.filter($scope.company.Users, function (user) {
        return !user.Coach || user.Coach.id != $scope.currentUser.id;
      });
      $scope.courses = courses;
      $scope.filter = { filter: { hasCheckpoint: false } };

      var chartData = function (value, label, color) {
        return {
          data: {
            labels: [$translate.instant(label)],
            datasets: [
              {
                data: [value, 100 - value],
                backgroundColor: [color || '#e7e7e7', '#EEEEEE'],
              },
            ],
          },
          options: {
            legend: false,
            tooltips: false,
            width: 120,
            height: 120,
            responsive: true,
            animation: false,
            cutoutPercentage: 85,
          },
        };
      };

      $scope.refresh = function () {
        _.forEach($scope.myUsers, function (user) {
          user.unfinishedCourses = _.orderBy(
            _.filter(user.Courses, function (course) {
              return course.progress < 100;
            }),
            ['UserAcademyAsset.orderId', 'name'],
          );
          user.finishedCourses = _.orderBy(
            _.filter(user.Courses, function (course) {
              return course.progress >= 100;
            }),
            ['UserAcademyAsset.orderId', 'name'],
          );

          user.progress = {
            overall:
              $scope.courses.length > 0
                ? Math.round((user.finishedCourses.length / $scope.courses.length) * 100)
                : 100,
            roadmap:
              user.Courses.length > 0
                ? Math.round((user.finishedCourses.length / user.Courses.length) * 100)
                : 100,
          };

          user.charts = {
            overall: chartData(user.progress.overall, 'overall', '#62A6DB'),
            roadmap: chartData(user.progress.roadmap, 'roadmap', '#62A6DB'),
          };

          user.checkpointCourses = _.filter(user.Courses, { hasCheckpoint: true });
        });
      };

      $scope.refresh();

      $scope.openUserCourse = function (user, course) {
        $scope.selectedUser = user;
        $scope.selectedCourse = course;
        var modalInstance = $modal.open({
          templateUrl: '/templates/academy/coaching-course-assets.html',
          controller: 'AcademyUserCourseAssetsModalController',
          // size: 'lg',
          backdrop: 'static',
          resolve: {
            course: function () {
              return course;
            },
            user: function () {
              return user;
            },
            quizResults: function () {
              return HttpService.get('/api/academy/quizzes/results/' + user.id);
            },
          },
        });

        modalInstance.result.then(
          function () {},
          function () {
            var courseAssets = _($scope.selectedCourse.sections)
              .flatten()
              .map('attachedAssets')
              .flatten()
              .uniq()
              .value();
            $scope.selectedCourse.progress =
              courseAssets.length > 0
                ? Math.round(
                    (_.filter(courseAssets, 'finishedAt').length / courseAssets.length) * 100,
                  )
                : 100;
            course.hasCheckpoint = !!_.find(courseAssets, function (asset) {
              return (
                !asset.finishedAt && asset.UserAcademyAsset && asset.UserAcademyAsset.checkpoint
              );
            });

            $scope.refresh();
          },
        );
      };

      $scope.chooseCoach = function () {
        if (CurrentUser.can('setCoach')) {
          $scope.options.coachSelect = true;
        }
      };

      $scope.changeCoach = function () {
        if ($scope.company.Coach) {
          HttpService.post(
            '/api/vendor/members/' + $scope.company.id + '/coaches/' + $scope.company.Coach.id,
          )
            .then(function () {
              $scope.options.coachSelect = false;
            })
            .catch(function (error) {
              DialogService.error(error);
              // $scope.options.coachSelect = false;
            });
        } else {
          HttpService.delete('/api/vendor/members/' + $scope.company.id + '/coaches')
            .then(function () {
              $scope.options.coachSelect = false;
            })
            .catch(function (error) {
              DialogService.error(error);
              // $scope.options.coachSelect = false;
            });
        }
      };
    },
  )

  .controller(
    'MemberInviteController',
    function (
      $scope,
      $rootScope,
      $modalInstance,
      $q,
      _,
      CurrentUser,
      HttpService,
      PermissionGroupModel,
      EMAIL_REGEX,
      permissiongroups,
      users,
      InputSanitizerService,
    ) {
      $scope.user = { email: '', CoachId: CurrentUser.getUser().id };
      $scope.userExists = false;
      $scope.companyExists = false;
      $scope.emailChecked = false;
      $scope.timeout = undefined;
      $scope.permissionGroups = permissiongroups;
      $scope.companyUsers = _.map(users, function (user) {
        return {
          id: user.id,
          name: user.firstName + ' ' + user.lastName,
        };
      });
      $scope.$watch('user.email', function () {
        $scope.emailChecked = false;
        $scope.userExists = false;
        $scope.companyExists = false;
        if (!$scope.timeout) {
          $scope.timeout = setTimeout(function () {
            $scope.getUser();
          }, 1000);
        }
      });

      $scope.getEmailDomain = function () {
        return $scope.user.email
          ? $scope.user.email.substring($scope.user.email.indexOf('@') + 1).toLowerCase()
          : undefined;
      };

      $scope.userInDifferentCompany = function () {
        if ($scope.user.Companies) {
          var domain = $scope.getEmailDomain();
          var result = true;
          $scope.user.Companies.forEach(function (company) {
            if (company.domain === domain) {
              result = false;
            }
          });
          return result;
        } else {
          return false;
        }
      };

      $scope.getUser = function () {
        $scope.timeout = undefined;
        $scope.messages = [];
        if (!$scope.user.id && $scope.user.email && EMAIL_REGEX.test($scope.user.email)) {
          HttpService.get(
            '/api/fraud/email/' + InputSanitizerService.sanitize($scope.user.email),
          ).then(
            function () {
              $scope.errors = [];
              var domain = $scope.getEmailDomain();
              HttpService.get(
                '/api/search/companies/' + InputSanitizerService.sanitize(domain),
              ).then(
                function (companies) {
                  if (companies && companies.length > 0) {
                    $scope.company = companies[0];
                    $scope.companyExists = true;
                    // $scope.messages.push({
                    //     code: 'message.companyExists',
                    //     translateValues: {name: $scope.company.name, domain: $scope.company.domain}
                    // });
                    _.forEach($scope.permissionGroups, function (permissionGroup) {
                      permissionGroup.$$toAdd =
                        _.indexOf(permissionGroup.Companies, $scope.company.id) !== -1;
                    });
                  } else {
                    $scope.companyExists = false;
                    $scope.company = undefined;
                  }
                  $scope.emailChecked = true;
                },
                function () {
                  $scope.company = undefined;
                  $scope.emailChecked = true;
                },
              );

              HttpService.get(
                '/api/admin/users/' + InputSanitizerService.sanitize($scope.user.email),
              ).then(function (_user) {
                if (_user) {
                  $scope.user.Companies = _user.Companies;
                  $scope.user.firstName = _user.firstName;
                  $scope.user.lastName = _user.lastName;
                  $scope.userExists = true;
                  // $scope.messages.push('message.userExists')
                  $scope.emailChecked = true;
                } else {
                  delete $scope.user.Companies;
                  delete $scope.user.firstName;
                  delete $scope.user.lastName;
                  $scope.userExists = false;
                  $scope.emailChecked = true;
                }
              });
            },
            function (error) {
              $scope.errors = [error];
              $scope.emailChecked = false;
            },
          );
        }
      };

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };

      $scope.save = function (cb) {
        HttpService.post('/api/invite', {
          email: $scope.user.email,
          firstName: $scope.user.firstName,
          lastName: $scope.user.lastName,
          companyName: $scope.user.companyName,
          roles: $scope.user.roles,
          CoachId: $scope.user.CoachId,
        }).then(
          function (result) {
            var promises = [];
            _.forEach($scope.permissionGroups, function (permissionGroup) {
              if (permissionGroup.$$toAdd === true) {
                promises.push(
                  PermissionGroupModel.addCompany({
                    permissionGroupId: permissionGroup.id,
                    companyId: result.company.id,
                  }).$promise,
                );
              } else if (permissionGroup.$$toRemove === true) {
                promises.push(
                  PermissionGroupModel.removeCompany({
                    permissionGroupId: permissionGroup.id,
                    companyId: result.company.id,
                  }).$promise,
                );
              }
            });
            $q.all(promises)
              .then(function () {
                $modalInstance.close(result);
              })
              .catch(function (error) {
                $scope.errors = [error];
                cb();
              });
          },
          function (error) {
            $scope.errors = [error];
            cb();
          },
        );
      };

      $scope.groupHasUser = function (group) {
        return group.$$toAdd;
      };

      $scope.addRemoveUserToGroup = function (permissionGroup) {
        if (!$scope.groupHasUser(permissionGroup)) {
          permissionGroup.$$toAdd = true;
          permissionGroup.$$toRemove = false;
        } else {
          permissionGroup.$$toAdd = false;
          permissionGroup.$$toRemove = true;
        }
      };
    },
  );
