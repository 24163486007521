angular
  .module('vcio-toolkit')
  .directive('svgCanvas', function ($translate, InputSanitizerService) {
    return {
      restrict: 'E',
      template: '<canvas width="{{width}}" height="{{height}}"></canvas>',
      scope: {
        source: '=src',
        data: '=data',
        width: '=width',
        height: '=height',
      },
      /**
       * data format:
       * [{
       *   value: "",
       *   color: "rgb(0, 0, 0)",
       *   fontSize: 14,
       *   fontWeight: "normal",
       *   textAlign: "left",
       *   x: ,
       *   y: ,
       *   maxWidth: 100,
       *   lineHeight: 20,
       *   nowrap: false
       *   }]
       */
      link: function ($scope, $rootScope, element, attrs) {
        var write = function (context, text, x, y, maxWidth, lineHeight) {
          var words = typeof text == 'string' ? text.split(' ') : [text];
          var line = '';

          for (var n = 0; n < words.length; n++) {
            var testLine = line + words[n] + ' ';
            var metrics = context.measureText(testLine);
            var testWidth = metrics.width;
            if (testWidth > maxWidth && n > 0) {
              context.fillText(line, x, y);
              line = words[n] + ' ';
              y += lineHeight;
            } else {
              line = testLine;
            }
          }
          context.fillText(line, x, y, maxWidth);
        };

        var draw = function (data) {
          var ctx = element.find('canvas')[0].getContext('2d');
          var img = new Image();
          img.onload = function () {
            //var aspectratio = img.width / img.height
            var resizeratio = Math.min($scope.width / img.width, $scope.height / img.height);
            var _width = img.width * resizeratio;
            var _height = img.height * resizeratio;

            ctx.drawImage(img, 0, 0, _width, _height);
            if (data) {
              data.forEach(function (text) {
                var fontSize = text.fontSize ? text.fontSize : '14';
                var lineHeight = text.lineHeight ? text.lineHeight : fontSize * 1.2;
                ctx.font =
                  (text.fontWeight ? text.fontWeight : 'normal') + ' ' + fontSize + 'px Helvetica';
                ctx.fillStyle = text.color ? text.color : 'black';
                ctx.textAlign = text.textAlign ? text.textAlign : 'center';
                if (text.nowrap) {
                  ctx.fillText(
                    typeof text.value == 'string' ? $translate.instant(text.value) : text.value,
                    text.x,
                    text.y,
                    text.maxWidth ? text.maxWidth : 2000,
                  );
                } else {
                  write(
                    ctx,
                    typeof text.value == 'string' ? $translate.instant(text.value) : text.value,
                    text.x,
                    text.y,
                    text.maxWidth ? text.maxWidth : 2000,
                    lineHeight,
                  );
                }
              });
            }
          };
          img.src = InputSanitizerService.sanitize($scope.source);
        };

        draw($scope.data);

        $scope.$watch(
          'data[1].value',
          function (newValue, oldValue) {
            draw($scope.data);
          },
          true,
        );
      },
    };
  })

  .directive('backImg', function () {
    return function (scope, element, attrs) {
      attrs.$observe('backImg', function (value) {
        element.css({
          'background-image': 'url(' + value + ')',
          'background-position': 'center center',
          'background-size': 'cover',
        });
      });
    };
  });
