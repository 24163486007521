angular
  .module('vcio-toolkit')
  .controller(
    'EmailLogController',
    function ($scope, $rootScope, $location, $http, $timeout, _, CurrentUser) {
      $scope.currentUser = CurrentUser.getUser();
      $scope.filter = {
        limit: 50,
        offset: 0,
      };
      //$scope.filterTimeout;

      $scope.refresh = function () {
        $rootScope.$broadcast('dataLoadingStarted');
        $http
          .get(
            '/api/email/log?' +
              ($scope.filter.limit ? 'limit=' + $scope.filter.limit + '&' : '') +
              ($scope.filter.offset ? 'offset=' + $scope.filter.offset + '&' : '') +
              ($scope.filter.from ? 'from=' + $scope.filter.from + '&' : '') +
              ($scope.filter.to ? 'to=' + $scope.filter.to + '&' : '') +
              ($scope.filter.subject ? 'subject=' + $scope.filter.subject + '&' : '') +
              ($scope.filter.company ? 'company=true&' : ''),
          )
          .success(function (result) {
            $scope.log = result.rows;
            $scope.filter.totalItems = result.count;
            $rootScope.$broadcast('dataLoadingFinished');
          });
      };

      var filterTimeout;
      var tempFilter = {};
      var clearTimeout = function () {
        if (filterTimeout) {
          $timeout.cancel(filterTimeout);
        }
      };
      var createTimeout = function () {
        return $timeout(function () {
          for (var key in tempFilter) {
            if (tempFilter.hasOwnProperty(key)) {
              $scope.filter[key] = tempFilter[key];
            }
          }
          // $scope.filter.to = tempFilter.to;
          // $scope.filter.subject = tempFilter.subject;
          $scope.refresh();
        }, 500);
      };
      $scope.$watch('filter.currentPage', function (value) {
        clearTimeout();
        tempFilter.offset = (value - 1) * $scope.filter.limit;
        filterTimeout = createTimeout();
      });
      $scope.$watch('filter.from', function (value) {
        clearTimeout();
        tempFilter.from = value;
        filterTimeout = createTimeout();
      });
      $scope.$watch('filter.to', function (value) {
        clearTimeout();
        tempFilter.to = value;
        filterTimeout = createTimeout();
      });

      $scope.$watch('filter.subject', function (value) {
        clearTimeout();
        tempFilter.subject = value;
        filterTimeout = createTimeout();
      });

      $scope.$watch('filter.company', function (value) {
        clearTimeout();
        tempFilter.company = value;
        filterTimeout = createTimeout();
      });

      $scope.getStatus = function (rec) {
        if (rec.clickedAt) {
          return 'clicked';
        } else if (rec.openedAt) {
          return 'opened';
        } else if (rec.failedAt) {
          return 'failed';
        } else if (rec.deliveredAt) {
          return 'delivered';
        } else if (rec.rejectedAt) {
          return 'rejected';
        } else if (rec.acceptedAt) {
          return 'accepted';
        } else if (rec.sentAt || rec.status == 'sent') {
          return 'sent';
        } else {
          return '';
        }
      };

      $scope.refresh();
    },
  )

  .controller(
    'EmailSettingsController',
    function ($scope, $rootScope, $location, $http, $modal, $timeout, _, CurrentUser) {
      $scope.domainInfo = {};
      $scope.company = CurrentUser.getUser().Company;

      $scope.refresh = function () {
        $scope.refreshing = true;
        $http.get('/api/email/domain').success(function (domainInfo) {
          $scope.domainInfo = domainInfo || {};
          $scope.domainInfo.emailTracking =
            domainInfo.emailTracking === undefined ? true : domainInfo.emailTracking;
          $scope.refreshing = false;
        });
      };

      $scope.refresh();

      $scope.generate = function () {
        $scope.refreshing = true;
        $http.post('/api/email/domain').success(function (domainInfo) {
          $scope.domainInfo = domainInfo;
          $scope.refreshing = false;
        });
      };

      $scope.openChangeDomain = function () {
        var modalInstance = $modal.open({
          template:
            '<modal-form form-title="title.company.emailDomainChange" onok="ok" oncancel="cancel"><div class="alert alert-warning" translate="label.company.emailDomainWarning"></div><input-text model="domainInfo.domain" label="label.company.emailDomain"></input-text></modal-form>',
          controller: function ($scope, $modalInstance, $http, CurrentUser, domainInfo) {
            $scope.domainInfo = domainInfo;
            $scope.ok = function (cb) {
              $http
                .post('/api/email/domain', { emailDomain: $scope.domainInfo.domain })
                .success(function (result) {
                  $modalInstance.close();
                })
                .error(function (error) {
                  $scope.errors = [error];
                  cb();
                });
            };
            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
          backdrop: 'static',
          resolve: {
            domainInfo: function () {
              return $scope.domainInfo;
            },
          },
        });

        modalInstance.result.then(
          function (page) {
            $scope.refresh();
          },
          function () {},
        );
      };

      $scope.enableTrackingSwitch = function () {
        var cname = _.find($scope.domainInfo.records, { record_type: 'CNAME' });
        return cname && cname.valid;
      };

      $scope.$watch('domainInfo.emailTracking', function (newValue, oldValue) {
        if ((oldValue === true || oldValue === false) && newValue != oldValue) {
          $http
            .put('/api/companies/' + CurrentUser.getUser().Company.id + '/settings/emailTracking', {
              value: $scope.domainInfo.emailTracking,
            })
            .success(function (result) {
              $scope.errors = [];
              $scope.messages = ['message.saved'];
              $timeout(function () {
                $scope.messages = [];
              }, 3000);
            })
            .error(function (error) {
              $scope.errors = [error];
              console.error(error);
            });
        }
      });
    },
  );
