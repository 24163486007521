angular
  .module('vcio-toolkit')

  .service('OverviewITCQChartService', function () {
    var ranges = [
      { min: 0, max: 40, name: '0-40', length: 40 },
      { min: 40, max: 60, name: '40-60', length: 20 },
      { min: 60, max: 80, name: '60-80', length: 20 },
      { min: 80, max: 100, name: '80-100', length: 20 },
    ];

    // var round = function (num, decimals) {
    //     return Math.round(num * (10 * decimals)) / (10 * decimals)
    // };

    var createDoughnutChartData = function (targetScore, score) {
      return {
        data: {
          labels: ['Current', 'Targeted', ''],
          datasets: [
            {
              data: [score, targetScore - score, 100 - targetScore], //TODO check if targeted score is less than the user score
              backgroundColor: ['#62A6DB', '#59BF90', '#EEEEEE'],
              hoverBackgroundColor: ['#62A6DB', '#59BF90', '#fff'],
              hoverBorderWidth: false,
            },
          ],
        },
        options: {
          width: 120,
          height: 120,
          responsive: true,
          animation: false,
          tooltips: false,
          legend: false,
        },
      };
    };

    var createDimensionData = function (dimension) {
      dimension.doughnutChartData = createDoughnutChartData(dimension.targetScore, dimension.score);
      dimension.hint = 'dimension.' + dimension.code + '.hint';
      // if (dimension.blocks) {
      //     _.forOwn(dimension.blocks, function (block) {
      //         block.doughnutChartData = createDoughnutChartData(block.targetScore, block.score);
      //     })
      // }
    };

    // var createBarChartData = function (scores, targetScores, labels) {
    //     var _scores = [];
    //     var _labels = [];
    //     scores.forEach(function (score) {
    //         _scores.push(round(score, 2));
    //         _labels.push(' ');
    //     });
    //     var _targetScores = [];
    //     targetScores.forEach(function (score) {
    //         _targetScores.push(round(score, 2))
    //     });
    //
    //     return {
    //         data: {
    //             labels: labels,
    //             datasets: [
    //                 {
    //                     fillColor: "rgba(42,171,225,0.5)",
    //                     strokeColor: "rgba(42,171,225,0.8)",
    //                     highlightFill: "rgba(42,171,225,0.75)",
    //                     highlightStroke: "rgba(42,171,225,1)",
    //                     data: _scores
    //                 },
    //                 {
    //                     fillColor: "rgba(140,197,64,0.5)",
    //                     strokeColor: "rgba(140,197,64,0.8)",
    //                     highlightFill: "rgba(140,197,64,0.75)",
    //                     highlightStroke: "rgba(140,197,64,1)",
    //                     data: _targetScores
    //                 }
    //             ]
    //         }, options: {
    //             width: 120,
    //             height: 120,
    //             responsive: true,
    //             animation: false,
    //             //showScale: false,
    //             scaleFontSize: 9,
    //             tooltipTemplate: "<%if (label){%><%=label%>: <%}%><%= Math.round(value *100) / 100 %>",
    //             multiTooltipTemplate: "<%= Math.round(value *100) / 100 %>"
    //         }
    //     };
    // };
    //
    // var createBenchmarkScoresObj = function (dimensionType, dimensionTypeCode, results) {
    //     //var bscores = [dimensionType.score]
    //     //var btargetScores = [dimensionType.targetScore]
    //     //var blabels = ['Current']
    //     var bscores = []
    //     var btargetScores = []
    //     var blabels = []
    //     results.forEach(function (result) {
    //         var _dimensionType = result[dimensionTypeCode]
    //         if (_dimensionType) {
    //             bscores.push(_dimensionType.score)
    //             btargetScores.push(_dimensionType.targetScore)
    //         }
    //         blabels.push(result.name)
    //     })
    //     dimensionType.benchmarkChartData = createBarChartData(bscores, btargetScores, blabels)
    // }
    //
    // var createBenchmarkScoresDimension = function (dimension, results) {
    //     //var bscores = [dimension.score]
    //     //var btargetScores = [dimension.targetScore]
    //     //var blabels = ['Current']
    //     var bscores = []
    //     var btargetScores = []
    //     var blabels = []
    //     results.forEach(function (result) {
    //         var _dimension = result.dimensions[dimension.code];
    //         if (_dimension) {
    //             bscores.push(_dimension.score)
    //             btargetScores.push(_dimension.targetScore)
    //         }
    //         blabels.push(result.name)
    //     })
    //     dimension.benchmarkChartData = createBarChartData(bscores, btargetScores, blabels)
    // }
    //
    // var createBenchmarkScoresBlock = function (dimensionCode, block, results) {
    //     var bscores = []
    //     var btargetScores = []
    //     var blabels = []
    //     results.forEach(function (result) {
    //         var _dimension = result.dimensions[dimensionCode];
    //         if (_dimension) {
    //             var _block = _dimension.blocks[block.type]
    //             if (_block) {
    //                 bscores.push(_block.score)
    //                 btargetScores.push(_block.targetScore)
    //                 blabels.push(result.name)
    //             }
    //         }
    //     })
    //     block.benchmarkChartData = createBarChartData(bscores, btargetScores, blabels)
    // }

    var createChartData = function (scores) {
      //scores.doughnutChartData = createDoughnutChartData(scores.targetScore, scores.score);
      scores.execution.doughnutChartData = createDoughnutChartData(
        scores.execution.targetScore,
        scores.execution.score,
      );
      scores.management.doughnutChartData = createDoughnutChartData(
        scores.management.targetScore,
        scores.management.score,
      );

      scores.dimensions.forEach(function (dimension) {
        createDimensionData(dimension);
      });
      scores.blocks.forEach(function (block) {
        block.doughnutChartData = createDoughnutChartData(block.targetScore, block.score);
      });

      scores.execution.name = 'execution';
      scores.management.name = 'management';

      scores.doughnutChartData = {
        data: {
          labels: ['Current', 'Targeted'],
          datasets: [
            {
              data: [scores.score, Math.round((scores.targetScore - scores.score) * 100) / 100],
              backgroundColor: ['#62A6DB', '#59BF90'],
              hoverBackgroundColor: ['#62A6DB', '#59BF90'],
              hoverBorderWidth: false,
            },
          ],
        },
        options: {
          width: 120,
          height: 120,
          responsive: true,
          animation: false,
          legend: false,
        },
      };

      ranges.forEach(function (range) {
        if (scores.targetScore < range.max) {
          // we have to show the range
          if (scores.targetScore >= range.min && scores.targetScore < range.max) {
            // the score is in this range
            scores.doughnutChartData.data.datasets[0].data.push(
              Math.round((range.max - scores.targetScore) * 100) / 100,
            );
          } else {
            // show the entire range
            scores.doughnutChartData.data.datasets[0].data.push(range.max - range.min);
          }
          scores.doughnutChartData.data.labels.push(range.name);
          scores.doughnutChartData.data.datasets[0].backgroundColor.push('#EEEEEE');
          scores.doughnutChartData.data.datasets[0].hoverBackgroundColor.push('#EEEEEE');
        }
      });
    };
    return {
      ranges: ranges,
      createChartData: createChartData,
      // createBenchmarkScoresObj: createBenchmarkScoresObj,
      // createBenchmarkScoresDimension: createBenchmarkScoresDimension,
      // createBenchmarkScoresBlock: createBenchmarkScoresBlock
    };
  })

  .controller('OverviewITCQCanvasController', function ($scope) {})

  .controller('OverviewITCQBlocksController', function ($scope) {
    $scope.dimensionTypes = ['execution', 'management'];
  })

  .controller(
    'OverviewITCQController',
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $http,
      $modal,
      $q,
      $window,
      _,
      $translate,
      CurrentUser,
      LocalStorage,
      OverviewITCQChartService,
    ) {
      $scope.loading = true;
      //$scope.surveyResult = surveyResult;
      $scope.actionPlan = [];
      $scope.showBenchmark = false;
      $scope.expanded = {
        total: true,
        overview: true,
        analyze: true,
        actionplan: true,
        ongoing: true,
      };
      $scope.postits = {};
      $scope.selected = {};
      $scope.blocks = [];
      $scope._ = _;

      // $scope.surveyResult.blocks.forEach(function (block) {
      //     var _block = _.find(surveyResult.Survey.blocks, {id: block.id});
      //     if (_block) {
      //         block.name = _block.name;
      //         block.question = _block.question;
      //         block.statements.forEach(function (statement) {
      //             var _statement = _.find(_block.statements, {id: statement.id});
      //             if (_statement) {
      //                 statement.name = _statement.name;
      //             }
      //         })
      //     }
      // })

      $scope.surveyResult.dimensions.forEach(function (dimension) {
        var d = _.find($scope.surveyResult.Survey.dimensions, { code: dimension.code });
        dimension.name = d ? d.name : dimension.code;
      });

      $scope.options.showProjects = LocalStorage.get('showProjects') != 'false';
      $scope.options.showServices = LocalStorage.get('showServices') != 'false';
      $scope.$watch('options.showProjects', function (value, oldValue) {
        if (value !== undefined && oldValue !== undefined) {
          LocalStorage.set('showProjects', value);
        }
      });
      $scope.$watch('options.showServices', function (value, oldValue) {
        if (value !== undefined && oldValue !== undefined) {
          LocalStorage.set('showServices', value);
        }
      });

      $scope.$watch('selected.block', function (newValue) {
        if (newValue) {
          $scope.selected.dimension = _.find($scope.surveyResult.dimensions, {
            code: $scope.selected.block.dimension,
          });
          // $scope.selected.dimension = $scope.surveyResult.dimensions[_.findKey($scope.surveyResult.dimensions, function (dimension) {
          //     return _.findKey(dimension.blocks, {code: $scope.selected.block.code});
          // })];
        } else {
          $scope.selected.dimension = undefined;
        }
      });

      $scope.getDimensionArray = function (type) {
        // $scope.surveyResult.dimensions.forEach(function (dimension) {
        // })

        return _.map(
          _.filter($scope.surveyResult.dimensions, { type: type }),
          function (dimension) {
            var _dimension = _.find($scope.surveyResult.Survey.dimensions, {
              code: dimension.code,
            });
            if (_dimension) {
              dimension.name = _dimension.name;
              dimension.description = _dimension.description;
              dimension.hint = _dimension.hint;
              dimension.dimensionText = [
                {
                  value: _dimension.name,
                  color: 'white',
                  fontSize: 14,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  x: 56,
                  y: 120,
                  maxWidth: 112,
                  nowrap: true,
                },
                {
                  value: dimension.score,
                  color: 'white',
                  fontSize: 22,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  x: 62,
                  y: 150,
                },
              ];
            }
            return dimension;
          },
        );
      };

      $scope.dimensionFilter = function (dimension, block) {
        return block.dimension == dimension.code;
      };

      var addPostit = function (type, service) {
        if (!_.includes($scope.postits[service.dimension][type], service.goal)) {
          $scope.postits[service.dimension][type].push(service.goal);
        }
      };

      $scope.benchmarkScores = [];
      $scope.benchmarkFilters = [
        { name: 'Global' },
        //{name: 'msp', msp: CurrentUser.getUser().Company.id}
      ];

      $scope.ranges = OverviewITCQChartService.ranges;

      $scope.refresh = function (surveyResult) {
        // $scope.surveyResult = surveyResult;
        // var serviceIds = [];
        //
        $scope.surveyResult.onetimeServices = surveyResult.onetimeServices;
        $scope.surveyResult.ongoingServices = surveyResult.ongoingServices;
        $scope.surveyResult.score = surveyResult.score;
        $scope.surveyResult.targetScore = surveyResult.targetScore;
        $scope.surveyResult.management.score = surveyResult.management.score;
        $scope.surveyResult.management.targetScore = surveyResult.management.targetScore;
        $scope.surveyResult.execution.score = surveyResult.execution.score;
        $scope.surveyResult.execution.targetScore = surveyResult.execution.targetScore;
        $scope.surveyResult.blocks = surveyResult.blocks;
        $scope.surveyResult.dimensions.forEach(function (dimension) {
          var _dimension = _.find(surveyResult.dimensions, { code: dimension.code });
          if (_dimension) {
            dimension.score = _dimension.score;
            dimension.targetScore = _dimension.targetScore;
          }
        });

        // $scope.surveyResult.blocks.forEach(function (block) {
        //     var _block = _.find(surveyResult.blocks, {id: block.id});
        //     if (_block) {
        //         block.score = _block.score;
        //         block.targetScore = _block.targetScore;
        //         block.statements.forEach(function (statement) {
        //             var _statement = _.find(_block, {id: statement.id});
        //             if (_statement) {
        //                 _statement.highlighted = statement.highlighted;
        //                 _statement.score = statement.score;
        //                 _statement.targetScore = statement.targetScore;
        //             }
        //         })
        //     }
        // })

        $scope.blocks = $scope.surveyResult.blocks;
        // $scope.surveyResult.blocks.forEach(function (block) {
        //     var _block = _.find($scope.surveyResult.Survey.blocks, {id: block.id});
        //     if (_block) {
        //         block.statements.forEach(function (statement) {
        //             var _statement = _.find(_block.statements, {id: statement.id});
        //             if (_statement) {
        //                 statement.onetimeService = _statement.onetimeService;
        //                 statement.ongoingService = _statement.ongoingService;
        //
        //                 if (statement.onetimeService) {
        //                     serviceIds = _.union(serviceIds, [statement.onetimeService]);
        //                 }
        //                 if (statement.ongoingService) {
        //                     serviceIds = _.union(serviceIds, [statement.ongoingService]);
        //                 }
        //             }
        //         })
        //         $scope.blocks.push(block);
        //     }
        // })

        $scope.getService = function (id) {
          return $scope.services[id];
        };

        $scope.service = function (id) {
          return $scope.services[id];
        };

        $scope.blocks = _.orderBy($scope.blocks, 'orderIdAnalyze');

        if (!$scope.selected.block) {
          $scope.selected.block = $scope.blocks[0];
        } else {
          $scope.selected.block = _.find($scope.blocks, { id: $scope.selected.block.id });
        }

        OverviewITCQChartService.createChartData($scope.surveyResult);

        $scope.postits = {
          continuous: { onetime: [], ongoing: [] },
          competent: { onetime: [], ongoing: [] },
          charge: { onetime: [], ongoing: [] },
          costeffective: { onetime: [], ongoing: [] },
          controlled: { onetime: [], ongoing: [] },
          clear: { onetime: [], ongoing: [] },
          conscious: { onetime: [], ongoing: [] },
        };

        $scope.surveyResult.onetimeServices.forEach(function (service) {
          addPostit('onetime', service);
        });
        $scope.surveyResult.ongoingServices.forEach(function (service) {
          addPostit('ongoing', service);
        });
        $scope.$broadcast('overviewRefreshed');
      };

      // $scope.reload = function () {
      //     $scope.loading = true;
      //     $rootScope.$broadcast('dataLoadingStarted');
      //     $q.all([$http.get('/api/surveyresults/' + $stateParams.surveyResultId + '/scores'),
      //         $http.get('/api/actionplans/' + $stateParams.surveyResultId)]).then(
      //         function (_results) {
      //             $scope.refresh(_results[0].data, _results[1].data);
      //             $scope.loading = false;
      //             $rootScope.$broadcast('dataLoadingFinished', {surveyResult: $scope.surveyResult});
      //         })
      // }

      $scope.progressStyleLength = function (length) {
        return { width: length + '%' };
      };

      // $scope.getDimensionData = function (type, code) {
      //     return _.find($scope.scores[type].dimensions, {code: code});
      // }

      $scope.inRange = function (range) {
        return (
          $scope.surveyResult.score >= range.min &&
          $scope.surveyResult.score < range.max + (range.max == 100 ? 1 : 0)
        );
      };

      $scope.isShowBenchmark = function () {
        return $scope.showBenchmark;
      };

      $scope.changeShowBenchmark = function () {
        $scope.refreshBenchmark();
        $scope.showBenchmark = !$scope.showBenchmark;
      };

      $scope.getPopoverContent = function (block) {
        var result = '<ul class="fa-ul">';
        _.forEach(_.sortBy(block.statements, 'orderId'), function (statement) {
          // var dBlock = _.find($scope.surveyResult.Survey.blocks, {id: block.id});
          // var _statement = dBlock ? _.find(dBlock.statements, {id: statement.id}) || {} : {};
          var icon =
            'fa-' +
            (statement.checked ? 'check-' : '') +
            'square' +
            (!!statement.checked == !!statement.checkedByUser ? '-o' : '');
          var style = statement.highlighted
            ? statement.checked
              ? 'checked-highlighted'
              : 'highlighted'
            : statement.checked
            ? 'checked'
            : '';
          result +=
            '<li class="' +
            style +
            '"><i class="fa-li fa ' +
            icon +
            '"></i>' +
            statement.name +
            '</li>';
        });
        result += '</ul>';
        if (block.hint && block.hint != 'null') {
          result += '<div class="popover-footer">' + block.hint + '</div>';
        }
        return result;
      };

      $scope.refreshBenchmark = function () {
        // var calls = []
        // $scope.benchmarkFilters.forEach(function (filter) {
        //     calls.push($http.post('/api/benchmark/filter', {filters: filter}));
        // })
        //
        // $q.all(calls).then(function (_results) {
        //     var results = [];
        //     _results.forEach(function (_result) {
        //         results.push(_result.data);
        //     })
        //
        //     results.push(_.extend($scope.surveyResult, {
        //             name: 'Current',
        //             createdAt: $scope.surveyResult.createdAt
        //         })
        //     );
        //
        //     results.sort(function (result1, result2) {
        //         if (result1.createdAt) {
        //             if (result2.createdAt) {
        //                 return result1.createdAt > result2.createdAt ? 1 : result1.createdAt < result2.createdAt ? -1 : 0;
        //             } else {
        //                 return -1;
        //             }
        //         } else {
        //             if (result2.createdAt) {
        //                 return 1;
        //             } else {
        //                 return result1.name > result2.name ? 1 : result1.name < result2.name ? -1 : 0;
        //             }
        //         }
        //     })
        //
        //     // $scope.scores.dimensions.forEach(function (dimension) {
        //     _.forEach($scope.surveyResult.dimensions, function (dimension) {
        //         OverviewITCQChartService.createBenchmarkScoresDimension(dimension, results)
        //     })
        //     _.forEach($scope.surveyResult.blocks, function (block) {
        //         OverviewITCQChartService.createBenchmarkScoresBlock(dimension.code, block, results)
        //     })
        //     OverviewITCQChartService.createBenchmarkScoresObj($scope.surveyResult.execution, 'execution', results);
        //     OverviewITCQChartService.createBenchmarkScoresObj($scope.surveyResult.management, 'management', results);
        // })
      };

      // $scope.openBenchmarkSettings = function () {
      //     var modalInstance = $modal.open({
      //         templateUrl: '/templates/sales/overview/itcq/overview-benchmark-settings.html',
      //         controller: 'OverviewITCQBenchmarkSettingsController',
      //         backdrop: 'static',
      //         size: 'lg',
      //         resolve: {
      //             benchmarkFilters: function () {
      //                 return $scope.benchmarkFilters;
      //             },
      //             currentSurveyResult: function () {
      //                 return $scope.surveyResult;
      //             }
      //         }
      //     });
      //
      //     modalInstance.result.then(function (benchmarks) {
      //         $scope.benchmarkFilters = benchmarks;
      //         $scope.refreshBenchmark();
      //         $scope.showBenchmark = true
      //     }, function () {
      //     });
      // };

      $scope.selectBlock = function (block) {
        $scope.selected.block = block;
      };

      $scope.selectFirstBlock = function (dimension) {
        var blocks = _.filter($scope.surveyResult.blocks, { dimension: dimension.code });
        if (blocks.length > 0) {
          $scope.selected.block = blocks[0];
        }
      };

      $scope.hasBlocks = function (dimension) {
        return (
          dimension &&
          _.filter($scope.surveyResult.blocks, { dimension: dimension.code }).length > 0
        );
      };

      $scope.getServicePopover = function (service) {
        if (service) {
          var d = { academy: 'success', marketing: 'danger', sales: 'primary', vcio: 'warning' };
          var result = service.description + '<div class="popover-footer"><div class="row"> ';
          _.forOwn(d, function (color, key) {
            result += '<div class="col-xs-3"><small>';
            service.serviceQuality[key].forEach(function () {
              result += '<i class="fa fa-star text-' + color + '"></i>';
            });
            result += '</small></div>';
          });
          return result + '</div></div>';
        } else {
          return '';
        }
      };

      // $scope.reload();
      $scope.refresh($scope.surveyResult);
    },
  )

  // .controller('OverviewITCQBenchmarkSettingsController', function ($scope, $modalInstance, $http, $filter, _, benchmarkFilters, CurrentUser, currentSurveyResult) {
  //     //var maxItems = 4;
  //     $scope.currentCompany = CurrentUser.getUser().Company;
  //     $scope.benchmarkFilters = angular.copy(benchmarkFilters);
  //     $scope.industries = [];
  //     $scope.regions = [];
  //     $scope.filter = {msp: $scope.currentCompany.id};
  //
  //     $scope.previousSurveyResults = [];
  //     $http.get('/api/surveyresults/?companyId=' + currentSurveyResult.ClientId)
  //         .success(function (results) {
  //             $scope.previousSurveyResults = _.filter(results, function (item) {
  //                 return item.id != currentSurveyResult.id && (item.status == 'evaluated' || item.status == 'archived')
  //             });
  //         });
  //
  //     $scope.surveyResultName = function (item) {
  //         return item.title + (item.CreatedBy ? " by " + item.CreatedBy.firstName + ' ' + item.CreatedBy.lastName : '') + " at " + $filter('date')(item.createdAt, 'shortDate');
  //     };
  //
  //     //$scope.selectedPreviousSurveyResult = null;
  //     //$scope.setSelectedPreviousSurveyResult = function (selected) {
  //     //    $scope.selectedPreviousSurveyResult = selected;
  //     //}
  //     $scope.addPrevious = function () {
  //         if ($scope.filter.previousId) {
  //             var selectedPrevious = _.find($scope.previousSurveyResults, {id: parseInt($scope.filter.previousId)});
  //             $scope.filter = {
  //                 previousId: selectedPrevious.id,
  //                 name: $filter('date')(selectedPrevious.createdAt, 'shortDate'),
  //                 title: selectedPrevious.title,
  //                 createdBy: selectedPrevious.CreatedBy.firstName + " " + selectedPrevious.CreatedBy.lastName,
  //                 createdAt: selectedPrevious.createdAt
  //             };
  //             $scope.add();
  //         }
  //     };
  //
  //     $scope.add = function () {
  //         if ($scope.benchmarkFilters.length < 4) {
  //             if (!_.find($scope.benchmarkFilters, function (item) {
  //                     return _.isEqual(item, $scope.filter)
  //                 })) {
  //                 if (!$scope.filter.createdAt) {
  //                     $scope.filter.createdAt = new Date();
  //                 }
  //                 $scope.benchmarkFilters.push($scope.filter);
  //                 $scope.filter = {msp: CurrentUser.getUser().Company.id}
  //             } else {
  //                 window.alert('You can add a filter only once.')
  //             }
  //         } else {
  //             window.alert('You can compare to maximum 4 scores.')
  //         }
  //     };
  //
  //     $scope.remove = function (itemid) {
  //         $scope.benchmarkFilters.splice(itemid, 1)
  //     };
  //
  //     $scope.changeMSPcheckbox = function () {
  //         if ($scope.filter.msp) {
  //             delete $scope.filter.msp;
  //         } else {
  //             $scope.filter.msp = CurrentUser.getUser().Company.id;
  //         }
  //     };
  //
  //     $scope.ok = function () {
  //         $modalInstance.close($scope.benchmarkFilters);
  //     };
  //
  //     $scope.cancel = function () {
  //         $modalInstance.dismiss('cancel');
  //     };
  // })

  .directive('overviewDimension', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        data: '=',
      },
      controller: function ($scope) {
        $scope.postits = $scope.$parent.postits;
      },
      templateUrl: '/templates/sales/overview/itcq/overview-dimension.html',
    };
  })

  .directive('overviewDimensionType', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        data: '=',
      },
      controller: function ($scope) {},
      templateUrl: '/templates/sales/overview/itcq/overview-dimensiontype.html',
    };
  })

  .controller('overview-continuous-controller', function ($scope, $rootScope, _) {
    //$scope.scoreChartPercentage = '25%';
    // $scope.data = _.find($scope.$parent.surveyResult.dimensions, {code: 'continuous'})
    $scope.data = _.find($scope.$parent.surveyResult.dimensions, { code: 'continuous' });
    $scope.$on('overviewRefreshed', function () {
      $scope.data = _.find($scope.$parent.surveyResult.dimensions, { code: 'continuous' });
    });
  })

  .controller('overview-competent-controller', function ($scope, $rootScope, _) {
    //$scope.scoreChartPercentage = '25%';
    $scope.data = $scope.data = _.find($scope.$parent.surveyResult.dimensions, {
      code: 'competent',
    });
    $scope.$on('overviewRefreshed', function () {
      $scope.data = $scope.data = _.find($scope.$parent.surveyResult.dimensions, {
        code: 'competent',
      });
    });
  })

  .controller('overview-charge-controller', function ($scope, $rootScope, _) {
    //$scope.scoreChartPercentage = '25%';
    $scope.data = $scope.data = _.find($scope.$parent.surveyResult.dimensions, { code: 'charge' });
    $scope.$on('overviewRefreshed', function () {
      $scope.data = $scope.data = _.find($scope.$parent.surveyResult.dimensions, {
        code: 'charge',
      });
    });
  })

  .controller('overview-costeffective-controller', function ($scope, $rootScope, _) {
    //$scope.scoreChartPercentage = '25%';
    $scope.data = $scope.data = _.find($scope.$parent.surveyResult.dimensions, {
      code: 'costeffective',
    });
    $scope.$on('overviewRefreshed', function () {
      $scope.data = $scope.data = _.find($scope.$parent.surveyResult.dimensions, {
        code: 'costeffective',
      });
    });
  })

  .controller('overview-controlled-controller', function ($scope, $rootScope, _) {
    //$scope.scoreChartPercentage = '25%';
    $scope.data = $scope.data = _.find($scope.$parent.surveyResult.dimensions, {
      code: 'controlled',
    });
    $scope.$on('overviewRefreshed', function () {
      $scope.data = $scope.data = _.find($scope.$parent.surveyResult.dimensions, {
        code: 'controlled',
      });
    });
  })

  .controller('overview-clear-controller', function ($scope, $rootScope, _) {
    //$scope.scoreChartPercentage = '25%';
    $scope.data = $scope.data = _.find($scope.$parent.surveyResult.dimensions, { code: 'clear' });
    $scope.$on('overviewRefreshed', function () {
      $scope.data = $scope.data = _.find($scope.$parent.surveyResult.dimensions, { code: 'clear' });
    });
  })

  .controller('overview-conscious-controller', function ($scope, $rootScope, _) {
    //$scope.scoreChartPercentage = '25%';
    $scope.data = $scope.data = _.find($scope.$parent.surveyResult.dimensions, {
      code: 'conscious',
    });
    $scope.$on('overviewRefreshed', function () {
      $scope.data = $scope.data = _.find($scope.$parent.surveyResult.dimensions, {
        code: 'conscious',
      });
    });
  })

  .controller('overview-execution-controller', function ($scope) {
    //$scope.scoreChartPercentage = '10%';
    $scope.data = $scope.$parent.surveyResult.execution;
    $scope.$on('overviewRefreshed', function () {
      $scope.data = $scope.$parent.surveyResult.execution;
    });
  })

  .controller('overview-management-controller', function ($scope) {
    //$scope.scoreChartPercentage = '10%';
    $scope.data = $scope.$parent.surveyResult.management;
    $scope.$on('overviewRefreshed', function () {
      $scope.data = $scope.$parent.surveyResult.management;
    });
  })

  .controller(
    'OverviewITCQAnalyzeController',
    function (
      $scope,
      $rootScope,
      HttpService,
      $translate,
      $moment,
      _,
      DialogService,
      InputSanitizerService,
    ) {
      // $scope.block = $scope.selected.block;
      $scope.dimensions = $scope.surveyResult.dimensions;

      $scope.indexOfSelectedBlock = function () {
        return _.indexOf($scope.blocks, $scope.selected.block);
      };

      $scope.next = function () {
        if ($scope.indexOfSelectedBlock() < $scope.blocks.length - 1) {
          $scope.selected.block = $scope.blocks[$scope.indexOfSelectedBlock() + 1];

          if (
            $scope.indexOfSelectedBlock() === $scope.blocks.length - 1 &&
            $scope.surveyResult.status !== 'evaluated'
          ) {
            HttpService.put(
              '/api/surveyresults/' + $scope.surveyResult.id + '/status/evaluated',
            ).then(
              function () {
                $scope.surveyResult.status = 'evaluated';
              },
              function (error) {
                console.error(error);
              },
            );
          }
        }
      };

      $scope.previous = function () {
        if ($scope.indexOfSelectedBlock() > 0) {
          $scope.selected.block = $scope.blocks[$scope.indexOfSelectedBlock() - 1];
        }
      };

      $scope.rowStyle = function (statement) {
        if (statement.highlighted) {
          if (statement.checked) {
            return 'checked-highlighted';
          } else {
            return 'highlighted';
          }
        } else {
          if (statement.checked) {
            return 'checked';
          }
        }
      };

      $scope.statementCheckboxStyle = function (statement) {
        // return "fa-" + (statement.checked ? "check-" : "") + "square" + ((!!statement.checked) === (!!statement.checkedByUser) ? "-o" : "")
        if ($scope.selected.block.answerType == 'single') {
          if (statement.checked) {
            return 'fa-dot-circle-o';
          } else {
            return 'fa-circle-o';
          }
        } else {
          if (statement.checked) {
            return 'fa-check-square-o';
          } else {
            return 'fa-square-o';
          }
        }
      };

      $scope.statementCheckboxChange = function (statement) {
        statement.checked = !statement.checked;

        if ($scope.selected.block.answerType == 'single') {
          if (statement.checked) {
            $scope.selected.block.statements.forEach(function (other) {
              if (statement.id != other.id && other.checked) {
                other.checked = false;
              }
            });
          }
        }

        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.put(
          '/api/surveyresults/' +
            InputSanitizerService.sanitize($scope.surveyResult.id) +
            '/responses/' +
            InputSanitizerService.sanitize($scope.selected.block.id),
          $scope.selected.block,
        )
          .then(function (result) {
            $rootScope.$broadcast('dataLoadingFinished');
            $scope.refresh(result);
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
          });
      };

      $scope.saveComment = function () {
        HttpService.put(
          '/api/surveyresults/' +
            InputSanitizerService.sanitize($scope.surveyResult.id) +
            '/blocks/' +
            InputSanitizerService.sanitize($scope.selected.block.orderIdQuestion) +
            '/comments',
          {
            analyzeComment: $scope.selected.block.analyzeComment,
          },
        );
      };

      $scope.showAddService = function (statement, type) {
        return (
          statement[type + 'Service'] &&
          !_.find($scope.surveyResult[type + 'Services'], { id: statement[type + 'Service'] })
        );
      };

      $scope.showDeleteService = function (statement, type) {
        return (
          statement[type + 'Service'] &&
          _.find($scope.surveyResult[type + 'Services'], { id: statement[type + 'Service'] })
        );
      };

      $scope.addService = function (statement, type) {
        if ($scope.showAddService(statement, type)) {
          var block = _.find($scope.surveyResult.blocks, function (block) {
            return _.includes(block.statements, statement);
          });
          HttpService.post(
            '/api/surveyresults/' +
              InputSanitizerService.sanitize($scope.surveyResult.id) +
              '/services/' +
              InputSanitizerService.sanitize(type) +
              '/' +
              InputSanitizerService.sanitize(statement[type + 'Service']),
            { dimension: block.dimension },
          ).then(
            function (result) {
              $scope.refresh(result);
            },
            function (error) {
              if (error && error.status === 400) {
                DialogService.error('error.overview.serviceNotFound');
              }
            },
          );
        }
      };

      $scope.deleteService = function (statement, type) {
        if ($scope.showDeleteService(statement, type)) {
          $rootScope.$broadcast('dataLoadingStarted');
          HttpService.delete(
            '/api/surveyresults/' +
              InputSanitizerService.sanitize($scope.surveyResult.id) +
              '/services/' +
              InputSanitizerService.sanitize(type) +
              '/' +
              InputSanitizerService.sanitize(statement[type + 'Service']),
          )
            .then(function (result) {
              $rootScope.$broadcast('dataLoadingFinished');
              $scope.refresh(result);
            })
            .catch(function (error) {
              $rootScope.$broadcast('dataLoadingFinished');
            });
        }
      };
    },
  )

  .controller(
    'OverviewITCQOnetimeServicesController',
    function ($scope, $rootScope, $state, $modal, $http, $moment, _, InputSanitizerService) {
      var now = $moment();
      $scope.quarters = [];
      for (var i = 0; i < 6; i++) {
        $scope.quarters[i] = {
          id: parseInt(now.format('YYYYQ')),
          name: now.format('YYYY@Q').replace('@', 'Q'),
          value: parseInt(now.format('YYYYQ')),
        };
        now.add(1, 'Q');
      }

      // $scope.typeFilter = function (item) {
      //     return item.SubprojectId;
      // }

      $scope.serviceOrder = function (item) {
        var dimension = _.find($scope.surveyResult.dimensions, { code: item.dimension });
        return (
          (dimension ? dimension.orderId : 0) +
          ($scope.services[item.id] ? $scope.services[item.id].name : item.id)
        );
      };

      $scope.inQuarter = function (item, q) {
        return _.indexOf(item.quarters, q.value) > -1;
      };

      $scope.progressQuarter = function (item, q) {
        if (_.indexOf(item.quarters, q.value) == -1) {
          return { width: '20%', backgroundColor: '#e8e8e8' };
        } else {
          return { width: '20%', backgroundColor: 'rgb(93, 147, 170)' };
        }
      };

      $scope.getServiceHours = function (service) {
        return service
          ? _.sumBy(service.todoLists, function (todoList) {
              return _.sumBy(todoList.todo, function (task) {
                return task.hours || 0;
              });
            })
          : 0;
      };

      $scope.editItem = function (item) {
        $scope.selectedItem = item;
        var modalInstance = $modal.open({
          templateUrl: '/templates/sales/overview/itcq/onetime-edit.html',
          controller: function ($scope, $modalInstance, surveyResult, item, quarters) {
            $scope.surveyResult = surveyResult;
            $scope.item = item;
            $scope.quarters = quarters;
            $scope.item.quarters.forEach(function (q) {
              if (!_.find($scope.quarters, { value: q })) {
                $scope.quarters.unshift({
                  name: $moment(q, 'YYYYQ').format('YYYY@Q').replace('@', 'Q'),
                  value: q,
                });
              }
            });
            item._quarters = _.map(item.quarters, function (q) {
              return _.extend(_.find($scope.quarters, { value: q }), { ticked: true });
            });

            $scope.ok = function (cb) {
              item.quarters = _.map(item._quarters, 'value');
              $http
                .put(
                  '/api/surveyresults/' +
                    InputSanitizerService.sanitize($scope.surveyResult.id) +
                    '/services/onetime/' +
                    InputSanitizerService.sanitize(item.id),
                  { service: item },
                )
                .success(function () {
                  $modalInstance.close(item);
                })
                .error(function (error) {
                  cb();
                  console.error(error);
                });
            };
            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
          backdrop: 'static',
          resolve: {
            item: function () {
              return angular.copy($scope.selectedItem);
            },
            quarters: function () {
              return angular.copy($scope.quarters);
            },
            surveyResult: function () {
              return $scope.surveyResult;
            },
          },
        });

        modalInstance.result.then(
          function (result) {
            $scope.selectedItem.price = result.price;
            $scope.selectedItem.quarters = result.quarters;
          },
          function () {},
        );
      };

      $scope.deleteItem = function (item) {
        $http
          .delete(
            '/api/surveyresults/' +
              InputSanitizerService.sanitize($scope.surveyResult.id) +
              '/services/onetime/' +
              InputSanitizerService.sanitize(item.id),
          )
          .success(function (result) {
            $scope.refresh(result);
          });
      };

      $scope.addItem = function (service) {
        var modalInstance = $modal.open({
          templateUrl: '/templates/sales/service-add.html',
          controller: 'OverviewITCQServiceAddController',
          backdrop: 'static',
          resolve: {
            surveyResult: function () {
              return $scope.surveyResult;
            },
            type: function () {
              return 'onetime';
            },
            services: function () {
              return _.filter($scope.services, function (item) {
                return (
                  (item.type === 'onetime' || item.type === 'any') &&
                  !_.find($scope.surveyResult.onetimeServices, { id: item.id })
                );
              });
            },
            service: function () {
              return service;
            },
          },
        });

        modalInstance.result.then(
          function (result) {
            $scope.refresh(result.surveyResult);
            if (result.createdService) {
              _.find($scope.serviceGroups, {
                id: result.createdService.ServiceGroupId,
              }).Services.push(result.createdService);
            }
          },
          function () {},
        );
      };

      $scope.createContract = function () {
        var contract = {
          type: 'onetime',
          name: $scope.surveyResult.Survey.name,
          defaults: { hourlyRate: 0, monthlyPrice: 0, pricing: 'labour' },
          Services: {},
          SurveyResultId: $scope.surveyResult.id,
        };

        $http.get('/api/service/services?body=true').success(function (services) {
          var _services = {};
          services.forEach(function (service) {
            _services[service.id] = service;
          });
          $scope.surveyResult.onetimeServices.forEach(function (service) {
            var _service = _services[service.id];
            if (_service) {
              contract.Services[_service.id] = {
                hours: _.sumBy(_service.todoLists, function (todoList) {
                  return _.sumBy(todoList.todo, function (task) {
                    return task.hours || 0;
                  });
                }),
                hourlyRate: contract.defaults.hourlyRate,
                todoLists: _service.todoLists,
              };
            }
          });

          $http
            .post('/api/vcio/contracts' + '?clientId=' + $scope.surveyResult.ClientId, {
              contract: contract,
            })
            .success(function (result) {
              $state.go('vcio.contractEdit', { contractId: result.id });
            })
            .error(function (error) {
              $scope.errors = [error];
            });
        });
      };
    },
  )

  .controller(
    'OverviewITCQServiceAddController',
    function (
      $scope,
      $rootScope,
      $modalInstance,
      $modal,
      $http,
      _,
      surveyResult,
      services,
      type,
      service,
      InputSanitizerService,
    ) {
      $scope.surveyResult = surveyResult;
      $scope.services = services;
      $scope.item = { serviceId: service ? service.id : undefined };
      $scope.type = type;
      $scope.dimensions = _.map($scope.surveyResult.dimensions, function (dimension) {
        var d = _.find(surveyResult.Survey.dimensions, { code: dimension.code });
        return { id: dimension.code, name: d ? d.name : dimension.code };
      });

      $scope.createService = function () {
        var modalScope = $rootScope.$new();
        modalScope.modalInstance = $modal.open({
          templateUrl: '/templates/academy/manage/service-edit.html',
          controller: 'AcademyServiceEditController',
          backdrop: 'static',
          size: 'lg',
          scope: modalScope,
          resolve: {
            service: function () {
              return null;
            },
            copy: function () {
              return false;
            },
            template: function () {
              return false;
            },
          },
        });

        modalScope.modalInstance.result.then(
          function (service) {
            $scope.createdService = service;
            $scope.services.push(service);
            $scope.item.serviceId = service.id;
          },
          function () {},
        );
      };

      $scope.save = function (cb) {
        if (
          !_.find($scope.surveyResult[$scope.type + 'Services'], {
            id: parseInt($scope.item.serviceId),
          })
        ) {
          $http
            .post(
              '/api/surveyresults/' +
                InputSanitizerService.sanitize($scope.surveyResult.id) +
                '/services/' +
                InputSanitizerService.sanitize($scope.type) +
                '/' +
                InputSanitizerService.sanitize($scope.item.serviceId),
              { dimension: $scope.item.dimensionCode },
            )
            .success(function (result) {
              $modalInstance.close({ surveyResult: result, createdService: $scope.createdService });
            })
            .error(function (error) {
              cb();
              console.error(error);
            });
        } else {
          cb();
        }
      };

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };
    },
  )

  .controller(
    'OverviewITCQOngoingServicesController',
    function (
      $scope,
      $rootScope,
      $state,
      $modal,
      $http,
      _,
      LocalStorage,
      DialogService,
      InputSanitizerService,
    ) {
      $scope.filter = { service: '', selected: true, selectionType: 'BH' };
      $scope.selectionTypes = ['H', 'B', 'BH', 'A'];
      $http.get('/api/service/servicebundles?owner=true').success(function (bundles) {
        var visibleServiceBundleIds;
        if (LocalStorage.get('visibleServiceBundleIds')) {
          visibleServiceBundleIds = JSON.parse(LocalStorage.get('visibleServiceBundleIds'));
        }
        $scope.serviceBundles = bundles;
        $scope.serviceBundles.forEach(function (bundle) {
          bundle.show = visibleServiceBundleIds
            ? _.includes(visibleServiceBundleIds, bundle.id)
            : true;
        });
      });

      $http.get('/api/service/servicegroups?services=true').success(function (groups) {
        $scope.serviceGroups = groups;
        $scope.serviceGroups.forEach(function (sg) {
          sg.expanded = true;
        });
      });

      $scope.getSelected = function (service) {
        return _.find($scope.surveyResult.ongoingServices, { id: service.id });
      };

      $scope.isInBundle = function (service) {
        return _.find($scope.serviceBundles, function (bundle) {
          return bundle.show && bundle.Services[service.id] && bundle.Services[service.id].cycle;
        });
      };

      $scope.selectedFilter = function (service) {
        return (
          $scope.filter.selectionType === 'A' ||
          ($scope.filter.selectionType === 'B' && $scope.isInBundle(service)) ||
          ($scope.filter.selectionType === 'BH' &&
            ($scope.isInBundle(service) || $scope.getSelected(service))) ||
          ($scope.filter.selectionType === 'H' && $scope.getSelected(service))
        );
        // return !$scope.filter.selected || $scope.getSelected(service);
      };

      $scope.nameFilter = function (service) {
        return (
          !$scope.filter.service ||
          service.name.toLowerCase().indexOf($scope.filter.service.toLowerCase()) > -1
        );
      };

      $scope.notEmptyFilter = function (serviceGroup) {
        return (
          _.filter(serviceGroup.Services, function (service) {
            return $scope.selectedFilter(service) && $scope.nameFilter(service);
          }).length > 0
        );
      };

      $scope.expandAll = function () {
        $scope.serviceGroups.forEach(function (group) {
          group.expanded = true;
        });
      };

      $scope.collapseAll = function () {
        $scope.serviceGroups.forEach(function (group) {
          group.expanded = false;
        });
      };

      $scope.showColumnSettings = function () {
        var modalInstance = $modal.open({
          template:
            '<modal-form onok="ok" oncancel="cancel">' +
            '<div class="alert alert-primary" ng-hide="serviceBundles.length" translate="label.vcio.noServiceBundles"></div>' +
            '<div class="row" ng-repeat="bundle in serviceBundles"><div class="col-md-12">' +
            '<input-checkbox model="bundle.show" label="{{bundle.name}}"></input-checkbox>' +
            '</div></div></modal-form>',
          controller: function ($scope, $modalInstance, serviceBundles) {
            $scope.serviceBundles = serviceBundles;
            $scope.ok = function () {
              $modalInstance.close($scope.serviceBundles);
            };
            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
          backdrop: 'static',
          resolve: {
            serviceBundles: function () {
              return _.map($scope.serviceBundles, function (item) {
                return { id: item.id, name: item.name, show: item.show };
              });
            },
          },
        });

        modalInstance.result.then(
          function (result) {
            LocalStorage.set(
              'visibleServiceBundleIds',
              JSON.stringify(_.map(_.filter(result, { show: true }), 'id')),
            );
            result.forEach(function (bundle) {
              _.find($scope.serviceBundles, { id: bundle.id }).show = bundle.show;
            });
          },
          function () {},
        );
      };

      $scope.createContract = function (bundle) {
        var contract = {
          type: 'ongoing',
          name: $scope.surveyResult.Survey.name + ' - ' + bundle.name,
          comment: bundle.comment,
          defaults: bundle.defaults,
          Services: bundle.Services,
          SurveyResultId: $scope.surveyResult.id,
        };

        $http
          .post('/api/vcio/contracts' + '?clientId=' + $scope.surveyResult.ClientId, {
            contract: contract,
          })
          .success(function (result) {
            $state.go('vcio.contractEdit', { contractId: result.id });
          })
          .error(function (error) {
            $scope.errors = [error];
          });
      };

      $scope.addItem = function (service) {
        var modalInstance = $modal.open({
          templateUrl: '/templates/sales/service-add.html',
          controller: 'OverviewITCQServiceAddController',
          backdrop: 'static',
          resolve: {
            surveyResult: function () {
              return $scope.surveyResult;
            },
            type: function () {
              return 'ongoing';
            },
            services: function () {
              return _.filter($scope.services, function (item) {
                return (
                  (item.type == 'ongoing' || item.type == 'any') &&
                  !_.find($scope.surveyResult.ongoingServices, { id: item.id })
                );
              });
            },
            service: function () {
              return service;
            },
          },
        });

        modalInstance.result.then(
          function (result) {
            //console.log(result.surveyResult);
            //return true;
            $scope.refresh(result.surveyResult);
            if (result.createdService) {
              _.find($scope.serviceGroups, {
                id: parseInt(result.createdService.ServiceGroupId),
              }).Services.push(result.createdService);
            }
          },
          function () {},
        );
      };

      $scope.addMultipleItems = function (bundle) {
        var serviceMap = {};
        var services = [];
        _.forEach($scope.surveyResult.blocks, function (block) {
          if (block.statements) {
            var dimension = block.dimension;
            _.forEach(block.statements, function (statement) {
              if (!serviceMap[statement.onetimeService]) {
                serviceMap[statement.onetimeService] = {
                  id: statement.onetimeService,
                  dimension: dimension,
                  type: 'project',
                };
              }
              if (!serviceMap[statement.ongoingService]) {
                serviceMap[statement.ongoingService] = {
                  id: statement.ongoingService,
                  dimension: dimension,
                  type: 'ongoing',
                };
              }
            });
          }
        });
        _.forEach(bundle.Services, function (service, serviceId) {
          if (serviceMap[serviceId]) {
            services.push(serviceMap[serviceId]);
          }
        });
        if (services.length) {
          $http
            .post(
              '/api/surveyresults/' +
                InputSanitizerService.sanitize($scope.surveyResult.id) +
                '/services/addAllFrom/' +
                InputSanitizerService.sanitize(bundle.id),
              { services: services },
            )
            .then(function (result) {
              $scope.refresh(result.data);
              DialogService.message('label.servicesAdded');
            })
            .catch(function (error) {
              console.error(error);
            });
        } else {
          //TODO: Do something if there are no matching services in the bundle !
          DialogService.message('label.noServicesToAdd');
        }
      };

      $scope.deleteItem = function (item) {
        $http
          .delete(
            '/api/surveyresults/' +
              InputSanitizerService.sanitize($scope.surveyResult.id) +
              '/services/ongoing/' +
              InputSanitizerService.sanitize(item.id),
          )
          .success(function (result) {
            $scope.refresh(result);
          });
      };
    },
  );
