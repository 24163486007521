angular
  .module('vcio-toolkit')

  .controller('ClientsTabController', function ($scope, $state) {
    $scope.isActive = function (stateName) {
      return $state.current.name === stateName;
    };
  })

  .controller(
    'ClientsController',
    function (
      $modal,
      $moment,
      $rootScope,
      $scope,
      $state,
      $translate,
      _,
      CesClientPlaybookService,
      CesService,
      CurrentUser,
      DialogService,
      HttpService,
      SidebarFilterService,
      UploadService,
      Utils,
      clients,
      clientSegments,
      activities,
      users,
      InputSanitizerService,
    ) {
      $scope.cesActivities = activities;
      $scope.touchPointFilter = {};
      $scope.clientSegments = clientSegments;

      $scope.changeLocation = function (id) {
        window.location.href =
          '/new-frontend?path=clients/' + InputSanitizerService.sanitize(id) + '/home?token=' + InputSanitizerService.sanitize(CurrentUser.getToken());
      };

      $scope.clients = _.map(clients, function (client) {
        client.score = CesService.calculate(
          _.filter($scope.cesActivities, function (activity) {
            return (
              activity.ClientId == client.id &&
              activity.day >= $moment().subtract(6, 'month').format('YYYYMMDD') &&
              activity.day <= $moment().format('YYYYMMDD')
            );
          }),
        ).actualAvg[12];

        if (client.ClientSegment && client.ClientSegment.complianceScore) {
          client.compliant =
            client.score >= client.ClientSegment.complianceScore
              ? 1
              : client.score >= client.ClientSegment.complianceScore * 0.75
              ? 0
              : -1;
        }

        client.changeLocation = function () {
          $scope.changeLocation(client.id);
        };

        return client;
      });
      $scope.newClient = {};
      $scope.users = _.map(users, function (user) {
        return _.extend({ name: user.firstName + ' ' + user.lastName }, user);
      });
      _.forEach($scope.clients, function (client) {
        if (client.AccountManagerId) {
          client.AccountManager = _.find($scope.users, { id: client.AccountManagerId });
        }
        client.$$hasContactSeniority = false;

        _.forEach(client.Contacts, function (contact) {
          if (contact.Seniorities && contact.Seniorities.length) {
            client.$$hasContactSeniority = true;
          }
        });
      });

      $scope.isClientTouchpointInTouchPointTypes = function (client) {
        var statusIds = _($scope.filter.touchPointStatuses)
          .filter({ selected: true })
          .map('id')
          .value();
        if (!statusIds || statusIds.length < 1) {
          return true;
        }
        return statusIds.includes(client.touchpointType);
      };

      $scope.filter = SidebarFilterService.get('clients').setLists({
        segment: _($scope.clientSegments)
          .sortBy('name')
          .concat([
            {
              id: -1,
              name: 'label.clientSegment.without',
            },
          ])
          .map(function (segment, i) {
            return _.extend(segment, { orderId: i });
          })
          .value(),
        user: _(users)
          .map(function (user) {
            return {
              id: user.id,
              name: user.firstName + ' ' + user.lastName,
            };
          })
          .sortBy('name')
          .map(function (user, i) {
            return _.extend(user, { orderId: i });
          })
          .concat([
            {
              id: -1,
              name: 'label.client.ces.sidebar.withoutAccountManager',
              orderId: users.length,
            },
          ])
          .value(),
      }).filter;

      $scope.chartCollapsed = false;
      $scope.toggleChart = function () {
        $scope.chartCollapsed = !$scope.chartCollapsed;
      };

      $scope.activityFilter = function (activity) {
        var types = _($scope.filter.type).filter({ selected: true }).map('code').value();
        var users = _($scope.filter.user).filter({ selected: true }).map('id').value();
        var segments = _($scope.filter.segment).filter({ selected: true }).map('id').value();
        return (
          (_.isEmpty(types) || _.includes(types, activity.type)) &&
          (_.isEmpty(users) || _.includes(users, activity.Client.AccountManagerId)) &&
          (_.isEmpty(segments) || _.includes(segments, activity.Client.ClientSegmentId))
        );
      };

      $scope.clientFilter = function (client) {
        var users = _($scope.filter.user).filter({ selected: true }).map('id').value();
        var withoutUser = _.find($scope.filter.user, { id: -1, selected: true });
        var segments = _($scope.filter.segment).filter({ selected: true }).map('id').value();
        var withoutSegment = _.find($scope.filter.segment, { id: -1, selected: true });
        return (
          (_.isEmpty($scope.filter) ||
            _.lowerCase(client.name).indexOf(_.lowerCase($scope.filter.name)) > -1) &&
          (_.isEmpty(users) ||
            _.includes(users, client.AccountManagerId) ||
            (withoutUser && !client.AccountManagerId)) &&
          (_.isEmpty(segments) ||
            _.includes(segments, client.ClientSegmentId) ||
            (withoutSegment && !client.ClientSegmentId)) &&
          $scope.isClientTouchpointInTouchPointTypes(client)
        );
      };

      $scope.segmentFilter = function (segment) {
        var segments = _($scope.filter.segment).filter({ selected: true }).map('id').value();
        return _.isEmpty(segments) || _.includes(segments, segment.id);
      };

      $scope.complianceFilter = function (client) {
        var compliance = _($scope.filter.compliance).filter({ selected: true }).map('id').value();
        return (
          _.isEmpty($scope.filter) ||
          _.isEmpty($scope.filter.compliance) ||
          compliance.length < 1 ||
          _.includes(compliance, client.compliant)
        );
      };

      $scope.parseChartData = function (to, from) {
        var clientSegmentNumbers = {};
        var usedClientSegments = [];
        var filteredSegments = _.filter($scope.clientSegments, $scope.segmentFilter);
        var filteredClients = _.filter(
          _.filter($scope.clients, $scope.clientFilter),
          $scope.complianceFilter,
        );
        var results = { activities: [], clientSegments: [] };

        _.forEach(filteredClients, function (client) {
          if (_.find(filteredSegments, ['id', client.ClientSegmentId])) {
            if (client.ClientSegmentId && !clientSegmentNumbers[client.ClientSegmentId]) {
              clientSegmentNumbers[client.ClientSegmentId] = 1;
              usedClientSegments.push(
                _.find($scope.clientSegments, ['id', client.ClientSegmentId]),
              );
              client.$$ClientSegmentName =
                $translate.instant('label.clientSegment') +
                ': ' +
                _.find($scope.clientSegments, ['id', client.ClientSegmentId]).name;
            } else if (client.ClientSegmentId) {
              clientSegmentNumbers[client.ClientSegmentId]++;
              client.$$ClientSegmentName =
                $translate.instant('label.clientSegment') +
                ': ' +
                _.find($scope.clientSegments, ['id', client.ClientSegmentId]).name;
            }
          }
        });
        if (usedClientSegments.length > 0) {
          results.segment = { id: 1, complianceScore: 0 };
          _.forEach(usedClientSegments, function (segment) {
            results.segment.complianceScore +=
              segment.complianceScore * clientSegmentNumbers[segment.id];
          });
          results.segment.complianceScore =
            results.segment.complianceScore / filteredClients.length;
        }
        results.activities = _.filter($scope.cesActivities, $scope.activityFilter);
        return results;
      };

      $scope.$watch(
        'filter',
        function (newVal, oldVal) {
          var data = $scope.parseChartData(/*toDay, fromDay*/);
          $scope.chart = CesService.createChartData(data.activities, data.segment, {
            clientCount: _.filter($scope.clients, $scope.clientFilter).length,
          });
        },
        true,
      );

      $scope.openClientLogo = function (client) {
        UploadService.open('clients', client).then(function (result) {
          client.logoUrl =
            '/images/clients/' +
            InputSanitizerService.sanitize(client.id) +
            '?' +
            new Date().getTime();
        });
      };

      $scope.save = function (newClient, cb) {
        var req;
        if (newClient.id) {
          req = HttpService.put('/api/clients', { client: _.omit(newClient, ['logoId']) });
        } else {
          req = HttpService.post('/api/clients', { client: _.omit(newClient, ['logoId']) });
        }
        $rootScope.$broadcast('dataLoadingStarted');
        req.then(
          function (result) {
            if (newClient.id) {
              newClient.isEdit = false;
            } else {
              $scope.clients.push(
                _.extend(result, {
                  AccountManager: _.find($scope.users, { id: newClient.AccountManagerId }),
                  changeLocation: function () {
                    $scope.changeLocation(result.id);
                  },
                }),
              );
              $scope.newClient = {};
            }
            // https://trello.com/c/jimSaDnG
            // if (!newClient.id) {
            //     $scope.filter.name = newClient.name;
            // }
            $rootScope.$broadcast('dataLoadingFinished');
            if (cb) {
              cb();
            }
          },
          function (error) {
            if (cb) {
              cb();
            }
            $rootScope.$broadcast('dataLoadingFinished');
            // if (error.code === "error.company.subscription") {
            //     DialogService.warning(error, 'label.payment.upgradeToPremium');
            // } else {
            DialogService.error(error);
            // }
            console.error(error);
          },
        );
      };

      $scope.removeVariable = function (key) {
        delete $scope.newClient.var[key];
      };

      $scope.cancel = function (newClient) {
        if (newClient.id) {
          newClient.isEdit = false;
          _.forEach(newClient.oldValues, function (v, k) {
            newClient[k] = v;
          });
          newClient.logoUrl =
            '/images/clients/' +
            InputSanitizerService.sanitize(newClient.id) +
            '?' +
            new Date().getTime();
        } else {
          $scope.newClient = {};
        }
      };

      $scope.nameFilter = function (item) {
        return (
          !$scope.filter.name ||
          (item.name && item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1) ||
          (item.description &&
            item.description.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1)
        );
      };

      $scope.editClient = function (client) {
        client.oldValues = _.cloneDeep(client);
        client.isEdit = true;
      };

      $scope.removeClient = function (client) {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.delete('/api/clients/' + InputSanitizerService.sanitize(client.id))
          .then(function () {
            _.remove($scope.clients, function (item) {
              return item.id === client.id;
            });
            $rootScope.$broadcast('dataLoadingFinished');
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
          });
      };

      $scope.applySegmentRoadmap = function (client) {
        CesClientPlaybookService.applySegmentRoadmap(client, function (result) {
          if (result === 'error' || result !== null) {
            $state.reload();
          }
        });
      };

      $scope.openImportClients = function () {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.get(
          '/api/companies/' + InputSanitizerService.sanitize(CurrentUser.getUser().Company.id),
        )
          .then(function (company) {
            $rootScope.$broadcast('dataLoadingFinished');
            if (!company.keys.autotask && !company.keys.connectwise) {
              DialogService.confirm('error.noIntegration.import', function () {
                $state.go('msp.integrations');
              });
            } else {
              $modal
                .open({
                  template:
                    '<modal-form form-title="label.import.company.title" onok="ok" oncancel="cancel">' +
                    '<input-text model="data.search" label="label.import.company.search"></input-text>' +
                    '</modal-form>',
                  controller: function ($scope, $modalInstance) {
                    $scope.data = {};
                    $scope.ok = function () {
                      $modalInstance.close($scope.data.search);
                    };

                    $scope.cancel = function () {
                      $modalInstance.dismiss();
                    };
                  },
                  backdrop: 'static',
                })
                .result.then(function (result) {
                  $state.go('msp.import', { search: result });
                });
            }
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
          });
      };

      $scope.getTouchPointStatusIcon = function (touchpointType) {
        var touchpointTypeMap = {
          Missed: {
            icon: 'text-left absolute-left fa fa-exclamation fa-5x',
            style: {
              color: 'red',
              'margin-right': '20px',
              'margin-left': '15px',
            },
          },
          Done: {
            icon: 'text-left absolute-left fa fa-check fa-5x',
            style: {
              color: '#59BF90',
              'margin-right': '20px',
              'margin-left': '15px',
            },
          },
          None: {
            icon: null,
            style: {
              'margin-right': '20px',
              'margin-left': '15px',
            },
          },
          Upcomming: {
            icon: null,
            style: {
              'margin-right': '20px',
              'margin-left': '15px',
            },
          },
        };

        return touchpointTypeMap[touchpointType];
      };
    },
  )

  .controller(
    'ClientHeaderController',
    function (
      $rootScope,
      $scope,
      $state,
      $stateParams,
      _,
      CurrentUser,
      DialogService,
      HttpService,
      UploadService,
      UserEventService,
      InputSanitizerService,
    ) {
      $scope.currentUser = $scope.currentUser || CurrentUser.getUser();
      $scope.options = {};
      $scope.hasModule = CurrentUser.hasModule;
      $scope.segmentFrequency = {};
      $scope.segmentFrequency.types = [
        { id: 1, name: 'Annual' },
        { id: 2, name: 'Quarterly' },
        { id: 3, name: 'Monthly' },
        { id: 4, name: 'None' },
      ];
      $scope.segmentFrequency.selected = undefined;

      $scope.generateNewFrontendUrls = function () {
        $scope.newDashboardUrl =
          '/new-frontend?path=clients/' +
          $scope.client.id +
          '/overview?token=' +
          CurrentUser.getToken();
        $scope.newAssetsUrl =
          '/new-frontend?path=clients/' +
          $scope.client.id +
          '/clientassets?token=' +
          CurrentUser.getToken();
        $scope.newBudgetUrl =
          '/new-frontend?path=clients/' +
          $scope.client.id +
          '/budget-dashboard?token=' +
          CurrentUser.getToken();
        $scope.newProjectRoadmapUrl =
          '/new-frontend?path=clients/' +
          $scope.client.id +
          '/project-roadmap?token=' +
          CurrentUser.getToken();
        $scope.newHomeScreenUrl =
          '/new-frontend?path=clients/' +
          $scope.client.id +
          '/home?token=' +
          CurrentUser.getToken();
        $scope.newClientListUrl = '/new-frontend?path=clients?token=' + CurrentUser.getToken();
        $scope.newClientSmartScoresUrl = '/new-frontend?path=clients/' + $scope.client.id + '/smart-scores?token=' + CurrentUser.getToken();
      };

      $scope.generateNewFrontendUrls();
      $scope.$on('companyChanged', $scope.generateNewFrontendUrls);

      $scope.getSegmentFrequency = function (clientSegment) {
        var defaultFrequency = { id: 4, name: 'None' };
        var frequencyTypeMapping = {
          'A - VIP': 'Monthly',
          'B - Mid Size': 'Quarterly',
          'C - Small': 'Annual',
          'D - Micro': 'None',
        };

        var frequencyName = frequencyTypeMapping[clientSegment.name];
        if (frequencyName) {
          return $scope.segmentFrequency.types.find(function (type) {
            return type.name === frequencyName;
          });
        }
        return defaultFrequency;
      };

      HttpService.get('/api/segments').then(function (clientSegments) {
        $scope.clientSegments = _.orderBy(clientSegments, 'name');
      });

      HttpService.get(
        '/api/admin/users?companyId=' +
          InputSanitizerService.sanitize($scope.currentUser.Company.id),
      ).then(function (users) {
        $scope.users = _(users)
          .map(function (user) {
            return _.extend({ name: user.firstName + ' ' + user.lastName }, user);
          })
          .orderBy('name')
          .value();
        if ($scope.client && $scope.client.AccountManagerId) {
          $scope.client.AccountManager = _.find($scope.users, {
            id: $scope.client.AccountManagerId,
          });
        }
      });

      $scope.chooseAccountManager = function () {
        $scope.options.accountManagerSelect = true;
      };

      $scope.changeAccountManager = function () {
        if (!$scope.client.AccountManagerId && $scope.client.AccountManager) {
          UserEventService.event('clientAccountManagerAdded', { clientId: $scope.client.id });
        }
        $scope.client.AccountManagerId = $scope.client.AccountManager
          ? $scope.client.AccountManager.id
          : null;
        HttpService.put('/api/clients', { client: $scope.client })
          .then(function () {
            $scope.options.accountManagerSelect = false;
          })
          .catch(function (error) {
            DialogService.error(error);
            $scope.options.accountManagerSelect = false;
          });
      };

      $scope.isDeliveryActive = function () {
        return $state.current.name === 'clients.delivery';
      };

      $scope.isActive = function (name) {
        return (
          (name === 'marketing' &&
            ($state.current.name === 'clients.emails' ||
              $state.current.name === 'clients.graders')) ||
          (name === 'delivery' && $state.current.name === 'clients.delivery') ||
          (name === 'reporting' && $state.current.name === 'clients.surveyresults')
        );
      };

      $scope.openClientLogo = function () {
        UploadService.open('clients', $scope.client).then(function () {
          $scope.client.logoUrl =
            '/images/clients/' +
            InputSanitizerService.sanitize($scope.client.id) +
            '?' +
            new Date().getTime();
        });
      };

      $scope.save = function (newClient, cb) {
        $scope.segmentFrequency.selected = undefined;

        var req;
        newClient.AccountManagerId = newClient.AccountManager ? newClient.AccountManager.id : null;
        if (newClient.id) {
          req = HttpService.put('/api/clients', { client: newClient });
        } else {
          req = HttpService.post('/api/clients', { client: newClient });
        }
        $rootScope.$broadcast('dataLoadingStarted');
        req
          .then(function (result) {
            if (newClient.id) {
              newClient.isEdit = false;
            } else {
              $scope.clients.push(result);
              $scope.newClient = {};
            }
            $scope.client.ClientSegment = _.find($scope.clientSegments, {
              id: newClient.ClientSegmentId,
            });
            /*
                                if ($scope.chart) {
                                    refreshChart();
                                }
                */
            $rootScope.$broadcast('dataLoadingFinished');
            if (cb) {
              cb();
            }
          })
          .catch(function (error) {
            if (cb) {
              cb();
            }
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
            console.error(error);
          });
      };

      $scope.cancel = function (newClient) {
        if (newClient.id) {
          newClient.isEdit = false;
          _.forEach(newClient.oldValues, function (v, k) {
            newClient[k] = v;
          });
        } else {
          $scope.newClient = {};
        }
        $scope.segmentFrequency.selected = undefined;
      };

      $scope.editClient = function (client) {
        client.oldValues = _.cloneDeep(client);
        client.isEdit = true;
        $scope.segmentFrequency.selected = $scope.getSegmentFrequency(client.ClientSegment);
      };
    },
  )

  .controller(
    'ClientOverviewController',
    function (
      $rootScope,
      $scope,
      CurrentUser,
      DialogService,
      HttpService,
      UploadService,
      client /*, clientSegments*/,
    ) {
      $scope.client = client;
      // $scope.clientSegments = clientSegments;
      $scope.currentUser = CurrentUser.getUser();
      $scope.hasModule = CurrentUser.hasModule;
    },
  )

  .controller(
    'ClientContactsController',
    function (
      $rootScope,
      $scope,
      $modal,
      _,
      CurrentUser,
      DialogService,
      HttpService,
      client,
      /*clientSegments, */ contactSeniorities,
      InputSanitizerService,
    ) {
      $scope.client = client;
      // $scope.clientSegments = clientSegments;
      $scope.filter = {};
      $scope.newContact = {};
      $scope.contactRollbackData = {};
      $scope.hasModule = CurrentUser.hasModule;
      $scope.contactVariables = CurrentUser.getUser().Company.settings.contactVariables;

      $scope.contactSeniorities = contactSeniorities;

      $scope.isExternalClient = function (system) {
        return _.get($scope.client, 'externalIds.' + system);
      };

      $scope.importContacts = function (system) {
        var modalInstance = $modal.open({
          templateUrl: '/templates/integration/import-modal.html',
          controller: 'ClientContactsImportController',
          backdrop: 'static',
          resolve: {
            companyContacts: function (HttpService) {
              return HttpService.get(
                '/api/vcio/' +
                  InputSanitizerService.sanitize(system) +
                  '/companies?contacts=true&id=' +
                  InputSanitizerService.sanitize(_.get($scope.client, 'externalIds.' + system)),
              );
            },
            client: function () {
              return $scope.client;
            },
            contactSeniorities: function (HttpService) {
              return HttpService.get('/api/admin/ces/contactseniorities');
            },
          },
        });

        modalInstance.result.then(
          function () {
            HttpService.get(
              '/api/clients/' + InputSanitizerService.sanitize($scope.client.id),
            ).then(function (result) {
              $scope.client = result;
            });
          },
          function () {},
        );
      };

      $scope.getSeniorityStringForContact = function (contact) {
        var seniorityNames = [];
        if (contact.Seniorities) {
          _.forEach(contact.seniorityIds, function (v) {
            const name = _.find($scope.contactSeniorities, { id: v }).name;
            if (name) {
              seniorityNames.push(name);
            }
          });
        }
        return seniorityNames.join(', ');
      };

      $scope.editContact = function (contact) {
        contact.oldValues = _.cloneDeep(contact);
        contact.isEdit = true;
      };

      $scope.save = function (contact, cb) {
        var req;
        contact.status = 'customer';
        contact.ClientId = $scope.client.id;
        if (contact.id) {
          req = HttpService.put('/api/contacts', { contact: contact });
        } else {
          req = HttpService.post('/api/contacts', { contact: contact });
        }
        $rootScope.$broadcast('dataLoadingStarted');
        req.then(
          function (result) {
            if (contact.id) {
              contact.isEdit = false;
              delete contact.oldValues;
            } else {
              $scope.client.Contacts.push(result);
              $scope.newContact = {};
            }
            $rootScope.$broadcast('dataLoadingFinished');
            if (cb) {
              cb();
            }
          },
          function (error) {
            if (cb) {
              cb();
            }
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
            console.error(error);
          },
        );
      };

      $scope.cancel = function (contact) {
        if (contact.id) {
          contact.isEdit = false;
          _.forEach(contact.oldValues, function (v, k) {
            contact[k] = v;
          });
          delete contact.oldValues;
        } else {
          $scope.newContact = {};
        }
      };

      $scope.nameFilter = function (item) {
        return (
          !$scope.filter.name ||
          (item.name && item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1) ||
          (item.description &&
            item.description.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1)
        );
      };

      $scope.removeContact = function (contact) {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.delete('/api/contacts/' + contact.id)
          .then(function () {
            _.remove($scope.client.Contacts, function (item) {
              return item.id === contact.id;
            });
            $rootScope.$broadcast('dataLoadingFinished');
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
          });
      };
    },
  )

  .controller(
    'ContactEditController',
    function (
      $scope,
      $rootScope,
      $state,
      $modal,
      $modalInstance,
      $http,
      _,
      CurrentUser,
      HttpService,
      contact,
      client,
      contactSeniorities,
    ) {
      $scope.newContact = contact || {};
      $scope.newContact.ClientId = contact.Client
        ? contact.Client.id
        : contact.ClientId || (client ? client.id : undefined);
      // $scope.needClientSelector = ($state.current.name === 'marketing.contacts' || $state.current.name === 'marketing.emailcampaign');
      $scope.errors = [];
      $scope.contactVariables = CurrentUser.getUser().Company.settings.contactVariables;
      $scope.isModal = $modalInstance;
      $scope.contactSeniorities = contactSeniorities;

      $scope.$watch('newContact.email', function () {
        $scope.newContact.error = undefined;
      });

      $scope.ok = function (cb) {
        HttpService[$scope.newContact.id ? 'put' : 'post']('/api/contacts', {
          contact: $scope.newContact,
        })
          .then(function (result) {
            if ($modalInstance) {
              $modalInstance.close(result);
            }
          })
          .catch(function (error) {
            $scope.errors = [error];
            $scope.newContact.error = error;
            cb();
          });
      };

      $scope.cancel = function () {
        if ($modalInstance) {
          $modalInstance.dismiss();
        }
      };

      // $scope.openCreateClient = function () {
      //     var modalScope = $rootScope.$new();
      //     modalScope.modalInstance = $modal.open({
      //         templateUrl: '/templates/admin/client-edit.html',
      //         controller: 'ClientEditController',
      //         scope: modalScope,
      //         backdrop: 'static',
      //         resolve: {
      //             client: function () {
      //                 return $scope.contact.Client;
      //             }
      //         }
      //     });
      //     modalScope.modalInstance.result.then(function (client) {
      //         $scope.contact.Client = client;
      //         $scope.contact.ClientId = client.id;
      //         $scope.clients.push(client);
      //     }, function () {
      //     });
      // }
    },
  )

  .controller(
    'ClientContactsImportController',
    function (
      $rootScope,
      $scope,
      $modalInstance,
      $q,
      _,
      HttpService,
      client,
      companyContacts,
      contactSeniorities,
    ) {
      $scope.client = client;
      $scope.externalClient = companyContacts[0];
      $scope.contactSeniorities = contactSeniorities;

      $scope.selectAllContacts = function (client) {
        client.selectAllContacts = !client.selectAllContacts;
        _.forEach(client.contacts, function (contact) {
          contact.selected = client.selectAllContacts;
        });
      };

      $scope.getCheckbox = function (item) {
        return item.selected ? 'fa-check-square-o' : 'fa-square-o';
      };

      $scope.selectChanged = function (item) {
        item.selected = !item.selected;
      };

      $scope.validate = function () {
        var result = true;
        $scope.externalClient.contacts.forEach(function (contact) {
          if (contact.selected) {
            if (!contact.firstName) {
              contact.firstName = '';
              result = false;
            }
            if (!contact.lastName) {
              contact.lastName = '';
              result = false;
            }
            if (!contact.email) {
              contact.email = '';
              result = false;
            }
          }
        });
        return result;
      };

      var saveContact = function (externalContact, clientId) {
        var contact = _.find($scope.client.Contacts, {
          email: externalContact.email.toLowerCase(),
        });
        if (contact) {
          return HttpService.put('/api/contacts', {
            contact: {
              id: contact.id,
              firstName: externalContact.firstName,
              lastName: externalContact.lastName,
              phone: externalContact.phone,
              seniorityIds: externalContact.seniorityIds,
            },
          });
        } else {
          return HttpService.post('/api/contacts', {
            contact: {
              firstName: externalContact.firstName,
              lastName: externalContact.lastName,
              email: externalContact.email.toLowerCase(),
              phone: externalContact.phone,
              seniorityIds: externalContact.seniorityIds,
              ClientId: clientId,
              status: 'customer',
            },
          });
        }
      };

      $scope.save = function () {
        if ($scope.validate()) {
          var promises = [];
          $scope.externalClient.contacts.forEach(function (contact) {
            if (contact.selected) {
              promises.push(saveContact(contact, $scope.client.id));
            }
          });
          $rootScope.$broadcast('dataLoadingStarted');
          $q.all(promises).then(
            function () {
              $rootScope.$broadcast('dataLoadingFinished');
              $modalInstance.close();
            },
            function () {
              $rootScope.$broadcast('dataLoadingFinished');
              $modalInstance.dismiss();
            },
          );
        } else {
          $scope.errors = ['error.validation'];
        }
      };

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };
    },
  )
  .controller(
    'ClientEmailsController',
    function (
      $modal,
      $rootScope,
      $scope,
      $state,
      _,
      CurrentUser,
      DialogService,
      HttpService,
      client,
      /*clientSegments, */ emailSequences,
      InputSanitizerService,
    ) {
      $scope.client = client;
      // $scope.clientSegments = clientSegments;
      $scope.filter = {};
      $scope.newEmail = {};
      $scope.contacts = [];
      $scope.emailSequences = emailSequences;
      $scope.hasModule = CurrentUser.hasModule;

      _.forEach(client.Contacts, function (contact) {
        $scope.contacts.push({
          id: contact.id,
          name: contact.firstName + ' ' + contact.lastName + ' <' + contact.email + '>',
        });
      });

      $scope.refresh = function () {
        HttpService.get(
          '/api/clients/' + InputSanitizerService.sanitize($scope.client.id) + '/emailsequences',
        )
          .then(function (result) {
            $scope.emailSequences = result;
            $rootScope.$broadcast('dataLoadingFinished');
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
          });
      };
      $scope.save = function (cb) {
        if ($scope.newEmail.emailSequence) {
          $rootScope.$broadcast('dataLoadingStarted');
          HttpService.put(
            '/api/emailsequences/' +
              InputSanitizerService.sanitize($scope.newEmail.emailSequence.id) +
              '/contacts',
            { contactIds: $scope.newEmail.contacts },
          ).then(
            function () {
              $scope.refresh();
              $scope.newEmail = {};
              $rootScope.$broadcast('dataLoadingFinished');
              if (cb) {
                cb();
              }
            },
            function (error) {
              if (cb) {
                cb();
              }
              $rootScope.$broadcast('dataLoadingFinished');
              DialogService.error(error);
              console.error(error);
            },
          );
        }
      };

      $scope.cancel = function () {
        $scope.newEmail = {};
      };

      $scope.nameFilter = function (item) {
        return (
          !$scope.filter.name ||
          (item.name && item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1)
        );
      };

      $scope.hasContacts = function (sequence) {
        return !_.isEmpty(sequence.Contacts);
      };

      $scope.removeContact = function (sequence, contact) {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.delete(
          '/api/emailsequences/' +
            InputSanitizerService.sanitize(sequence.id) +
            '/contacts/' +
            InputSanitizerService.sanitize(contact.id),
        )
          .then(function () {
            $scope.refresh();
            $rootScope.$broadcast('dataLoadingFinished');
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
          });
      };

      $scope.openAddEmailSequence = function () {
        var modalInstance = $modal.open({
          templateUrl: '/templates/marketing/email-sequence-add-directly.html',
          controller: 'EmailSequenceAddController',
          backdrop: 'static',
          resolve: {
            emailsequences: function (HttpService) {
              return HttpService.get('/api/emailsequences?template=true');
            },
          },
        });

        modalInstance.result.then(
          function (emailSequence) {
            //refresh modified data
            $scope.emailSequences.push(emailSequence);
            $scope.emailSequences = _.sortBy($scope.emailSequences, 'name');
            $scope.newEmail.emailSequence = emailSequence;
          },
          function () {},
        );
      };
    },
  )
  .controller(
    'ClientEmailContactsController',
    function (
      $rootScope,
      $scope,
      $stateParams,
      _,
      CurrentUser,
      DialogService,
      HttpService,
      emailSequence,
      client /*, clientSegments*/,
      InputSanitizerService,
    ) {
      $scope.client = client;
      // $scope.clientSegments = clientSegments;
      $scope.emailSequence = emailSequence;
      $scope.newContact = {};
      $scope.contacts = [];
      $scope.hasModule = CurrentUser.hasModule;

      _.forEach(client.Contacts, function (contact) {
        $scope.contacts.push({
          id: contact.id,
          name: contact.firstName + ' ' + contact.lastName + ' <' + contact.email + '>',
        });
      });

      $scope.refresh = function () {
        HttpService.get(
          '/api/clients/' +
            InputSanitizerService.sanitize($stateParams.clientId) +
            '/emailsequences/' +
            InputSanitizerService.sanitize($stateParams.sequenceId),
        )
          .then(function (result) {
            $scope.emailSequence = result;
            $rootScope.$broadcast('dataLoadingFinished');
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
          });
      };

      $scope.addContact = function (cb) {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.put(
          '/api/emailsequences/' +
            InputSanitizerService.sanitize($scope.emailSequence.id) +
            '/contacts',
          { contactIds: [$scope.newContact.contact.id] },
        ).then(
          function () {
            $scope.refresh();
            $scope.newContact = {};
            $rootScope.$broadcast('dataLoadingFinished');
            if (cb) {
              cb();
            }
          },
          function (error) {
            if (cb) {
              cb();
            }
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
            console.error(error);
          },
        );
      };

      $scope.cancel = function () {
        $scope.newContact = {};
      };

      $scope.removeContact = function (contact) {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.delete(
          '/api/emailsequences/' + $scope.emailSequence.id + '/contacts/' + contact.id,
        )
          .then(function () {
            $scope.refresh();
            $rootScope.$broadcast('dataLoadingFinished');
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
          });
      };
    },
  )
  .controller(
    'ClientGradersController',
    function (
      $modal,
      $rootScope,
      $scope,
      $state,
      _,
      CurrentUser,
      DialogService,
      HttpService,
      client,
      /*clientSegments, */ graders,
    ) {
      $scope.client = client;
      // $scope.clientSegments = clientSegments;
      $scope.graders = graders;
      $scope.hasModule = CurrentUser.hasModule;

      _.forEach($scope.graders, function (grader) {
        var total = 0;
        var score = 0;
        grader.Grader.dimensions.forEach(function (dimension) {
          dimension.blocks = _.sortBy(dimension.blocks, function (block) {
            return block.orderId;
          });
          dimension.blocks.forEach(function (block) {
            total = total + _.sumBy(block.statements, 'score');
            var dim = grader.responses[dimension.orderId];
            if (dim) {
              var selected = dim[block.orderId] ? dim[block.orderId][0] : undefined;
              score += selected ? selected.score : 0;
              block.statements.forEach(function (statement) {
                statement.selected = selected && selected.id == statement.orderId;
              });
            }
          });
        });

        var value = _.round((score / total) * 100);
        grader.chart = {
          data: {
            datasets: [
              {
                data: [value, 100 - value],
                backgroundColor: ['#59BF90', '#efefef'],
                hoverBackgroundColor: ['#59BF90', '#efefef'],
              },
            ],
          },
          options: {
            width: 120,
            height: 120,
            responsive: true,
            animation: false,
            tooltips: false,
            legend: false,
            cutoutPercentage: 85,
          },
        };
      });

      $scope.deleteGraderResponse = function (graderResponse) {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.delete('/api/graders/responses/' + graderResponse.id)
          .then(function () {
            _.remove($scope.graders, function (item) {
              return item.id === graderResponse.id;
            });
            $rootScope.$broadcast('dataLoadingFinished');
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
          });
      };

      $scope.openSendGraderReport = function (graderResponse) {
        var modalInstance = $modal.open({
          templateUrl: '/templates/marketing/grader-report-modal.html',
          controller: 'GraderSendReportController',
          backdrop: 'static',
          resolve: {
            graderResponse: function () {
              return graderResponse;
            },
          },
        });

        modalInstance.result.then(
          function (result) {
            $rootScope.$broadcast('dataLoadingStarted');
            HttpService.post('/api/grader-report/' + graderResponse.uuid + '/resend', result).then(
              function () {
                $rootScope.$broadcast('dataLoadingFinished');
                DialogService.message('message.grader.graderReportSent');
              },
              function (response) {
                $rootScope.$broadcast('dataLoadingFinished');
                DialogService.error(response.data);
              },
            );
          },
          function () {},
        );
      };
    },
  )
  .controller(
    'ClientLeadsController',
    function (
      $rootScope,
      $scope,
      $stateParams,
      $location,
      $modal,
      $q,
      _,
      CurrentUser,
      DialogService,
      HttpService,
      contacts,
      leads /*, clientSegments*/,
    ) {
      $scope.filter = {};
      $scope.leads = [];
      $scope.clients = [];
      // $scope.clientSegments = clientSegments;
      $scope.hasModule = CurrentUser.hasModule;

      $scope.editClient = function (client) {
        client.oldValues = _.cloneDeep(client);
        client.isEdit = true;
      };

      function parseDomainInformation(contact) {
        if (!_.isObject(contact)) {
          return false;
        }
        if (contact.email) {
          contact.domain = contact.email.split('@')[1];
        } else {
          contact.domain = 'unknown';
        }
      }

      function parseContactClient(contact) {
        parseDomainInformation(contact);
        var client = _.find($scope.clients, { domain: contact.domain });
        if (!client) {
          $scope.clients.push(_.extend(contact.Client, { domain: contact.domain }));
        }
      }

      function parseContactLead(contact) {
        parseDomainInformation(contact);
        var name = _(contact.firstName).split(' ');
        contact.firstName = name.head();
        contact.lastName = name.tail().join(' ');
        var domain = _.find($scope.leads, { domain: contact.domain });
        if (!domain) {
          var client = _.find($scope.clients, { domain: contact.domain });
          if (!client) {
            $scope.leads.push({
              name: contact.company || contact.domain,
              domain: contact.domain,
              Contacts: [contact],
            });
          } else {
            $scope.leads.push(
              _.extend(client, {
                Contacts: [contact],
              }),
            );
          }
        } else {
          domain.Contacts.push(contact);
        }
      }

      $scope.parseData = function (contacts, leads) {
        $scope.leads = [];
        $scope.clients = [];
        _.forEach(contacts, function (contact) {
          parseContactClient(contact);
        });
        _.forEach(leads, function (lead) {
          parseContactLead(lead);
        });
      };
      $scope.parseData(contacts, leads);

      $scope.save = function (newClient) {
        var req;
        if (newClient.id) {
          req = HttpService.put('/api/clients', { client: newClient });
        } else {
          req = HttpService.post('/api/clients', { client: newClient });
        }
        $rootScope.$broadcast('dataLoadingStarted');
        req.then(
          function () {
            _.remove($scope.leads, newClient);
            $rootScope.$broadcast('dataLoadingFinished');
          },
          function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
            console.error(error);
          },
        );
      };
      $scope.cancel = function (newClient) {
        newClient.isEdit = false;
        _.forEach(newClient.oldValues, function (v, k) {
          newClient[k] = v;
        });
      };
      $scope.nameFilter = function (item) {
        return (
          !$scope.filter.name ||
          (item.name && item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1) ||
          (item.domain && item.domain.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1)
        );
      };
    },
  )
  .controller(
    'ClientLeadContactsController',
    function (
      $rootScope,
      $scope,
      $state,
      $stateParams,
      _,
      CurrentUser,
      DialogService,
      HttpService,
      contacts,
      leads,
    ) {
      $scope.hasModule = CurrentUser.hasModule;

      function parseDomainInformation(contact) {
        if (!_.isObject(contact)) {
          return false;
        }
        if (contact.email) {
          contact.domain = contact.email.split('@')[1];
        } else {
          contact.domain = 'unknown';
        }
      }

      function parseContactClient(contact) {
        parseDomainInformation(contact);
        var client = _.find($scope.clients, { domain: contact.domain });
        if (!client) {
          $scope.clients.push(_.extend(contact.Client, { domain: contact.domain }));
        }
      }

      $scope.clients = [];
      _.forEach(contacts, function (contact) {
        parseContactClient(contact);
      });

      $scope.client = _.find($scope.clients, { domain: $stateParams.emailDomain }) || {
        name: $stateParams.emailDomain,
        domain: $stateParams.emailDomain,
      };
      $scope.filter = {};
      $scope.leadContacts = _.filter(leads, function (lead) {
        var domain = lead.email.split('@');
        return domain[1] && domain[1] === $stateParams.emailDomain;
      });
      if ($scope.leadContacts[0]) {
        $scope.client.name = $scope.leadContacts[0].company || $scope.client.name;
      }

      $scope.editContact = function (contact) {
        $scope.oldValues = _.cloneDeep(contact);
        contact.isEdit = true;
      };

      $scope.editClient = function (client) {
        $scope.oldValues = _.cloneDeep(client);
        client.isEdit = true;
      };

      $scope.save = function (newContact, cb) {
        var req;
        newContact.status = 'customer';
        newContact.ClientId = $scope.client.id;
        if (newContact.id) {
          req = HttpService.put('/api/contacts', { contact: newContact });
        } else {
          req = HttpService.post('/api/contacts', { contact: newContact });
        }
        $rootScope.$broadcast('dataLoadingStarted');
        req.then(
          function (result) {
            newContact.isEdit = false;
            if (!newContact.id) {
              $scope.client.Contacts.push(result);
            } else {
              _.remove($scope.leadContacts, function (item) {
                return item.id === result.id;
              });
              if ($scope.leadContacts.length === 0) {
                $state.go('clients.leads');
              }
            }
            $rootScope.$broadcast('dataLoadingFinished');
            if (cb) {
              cb();
            }
          },
          function (error) {
            if (cb) {
              cb();
            }
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
            console.error(error);
          },
        );
      };

      $scope.saveClient = function (newClient, cb) {
        var req;
        if (newClient.id) {
          req = HttpService.put('/api/clients', { client: newClient });
        } else {
          req = HttpService.post('/api/clients', { client: newClient });
        }
        $rootScope.$broadcast('dataLoadingStarted');
        req.then(
          function (result) {
            newClient.isEdit = false;
            if (!newClient.id) {
              $scope.clients.push(result);
              $scope.newClient = {};
              $scope.filter.name = newClient.name;
            }
            $state.go('clients.leads');
            $rootScope.$broadcast('dataLoadingFinished');
            $state.go('clients.leads');
            if (cb) {
              cb();
            }
          },
          function (error) {
            if (cb) {
              cb();
            }
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
            console.error(error);
          },
        );
      };

      $scope.cancel = function (newContact) {
        if (newContact.id) {
          newContact.isEdit = false;
          _.forEach(newContact.oldValues, function (v, k) {
            newContact[k] = v;
          });
        } else {
          $scope.newContact = {};
        }
      };

      $scope.cancelClient = function (newClient) {
        if (newClient.id) {
          newClient.isEdit = false;
          _.forEach(newClient.oldValues, function (v, k) {
            newClient[k] = v;
          });
        } else {
          $scope.newClient = {};
          newClient.isEdit = false;
        }
      };

      $scope.nameFilter = function (item) {
        return (
          !$scope.filter.name ||
          (item.name && item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1) ||
          (item.description &&
            item.description.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1)
        );
      };

      $scope.removeContact = function (contact) {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.delete('/api/contacts/' + contact.id)
          .then(function () {
            _.remove($scope.leadContacts, function (item) {
              return item.id === contact.id;
            });
            $rootScope.$broadcast('dataLoadingFinished');
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
          });
      };
    },
  )

  // .controller('ClientProjectsLinkProjectController', function ($scope, $rootScope, $stateParams, $http, _, CurrentUser, DialogService, IntegrationService) {
  //     $scope.linkProject = {};
  //     $scope.hasModule = CurrentUser.hasModule;
  //
  //     IntegrationService.get('/projects')
  //         .then(function (projects) {
  //             $scope.projectsWithoutClient = _.filter(projects, function (project) {
  //                 return !project.clientId;
  //             });
  //         });
  //
  //     $scope.ok = function () {
  //         if ($scope.linkProject.project) {
  //             IntegrationService.post('/clients/' + $scope.client.id + '/projects', {
  //                 project: $scope.linkProject.project
  //             })
  //                 .then(function () {
  //                     $scope.linkProject = {expanded: false};
  //                 }).catch(function (error) {
  //                 DialogService.error(error);
  //             })
  //         }
  //     };
  //
  //     $scope.cancel = function () {
  //         $scope.linkProject = {expanded: false};
  //     }
  // })

  .factory('ClientProjectsService', function ($moment, _, $rootScope, ProjectPriceService) {
    return {
      generateColums: function (options) {
        var coltime;
        return _.map(_.range(options.colNum || 5), function (i) {
          coltime = $moment(options.start).add(i, options.timeScale);
          return {
            id: coltime.format(options.timeScales[options.timeScale].idFormat),
            title: coltime.format(options.timeScales[options.timeScale].titleFormat),
          };
        });
      },
      calculateTable: function (options) {
        options = options || {};
        var table = { 0: { sum: undefined } };
        _(options.clientProjects)
          .filter(_.overEvery(options.filters))
          .forEach(function (clientProject) {
            var clientProjectMonths =
              options.timeScale == 'M'
                ? clientProject.months
                : _.map(clientProject.months, function (month) {
                    return $moment(month, 'YYYYMM')
                      .startOf(options.timeScale)
                      .format(options.timeScales[options.timeScale].idFormat);
                  });

            const formattedClientProjectPrices =
              options.valueType === 'prices'
                ? ProjectPriceService.formatClientProjectPrices(clientProject[options.valueType])
                : clientProject[options.valueType];

            table[clientProject.id] = { sum: undefined };
            table[clientProject.id].sum = _(formattedClientProjectPrices).toArray().sum();

            _(options.timeColumns).forEach(function (column) {
              table[clientProject.id][column.id] = _.includes(clientProjectMonths, column.id)
                ? null
                : undefined;

              _(formattedClientProjectPrices)
                .map(function (value, month) {
                  return { month: month, value: value };
                })
                .filter(function (item) {
                  return (
                    $moment(item.month, 'YYYYMM')
                      .startOf(options.timeScale)
                      .format(options.timeScales[options.timeScale].idFormat) == column.id
                  );
                })
                .forEach(function (item) {
                  table[clientProject.id][column.id] =
                    (table[clientProject.id][column.id] || 0) + item.value;
                  table[0][column.id] = (table[0][column.id] || 0) + item.value;
                  table[0].sum = (table[0].sum || 0) + item.value;
                });
            });
          });
        return table;
      },
    };
  })

  .controller(
    'ClientsMeetingsController',
    function (
      $rootScope,
      $scope,
      $translate,
      _,
      DialogService,
      HttpService,
      MeetingService,
      SidebarFilterService,
      clients,
      meetingTemplates,
      meetings,
      users,
    ) {
      $scope.MeetingService = MeetingService;
      $scope.clients = clients;
      $scope.meetings = _.map(meetings, function (meeting) {
        meeting.score = meeting.score || 0;
        meeting.targetScore = meeting.targetScore || 0;
        return meeting;
      });
      $scope.selected = {};

      $scope.filter = SidebarFilterService.get('clientmeetings').setLists({
        meetingTemplates: _.sortBy(meetingTemplates, 'name'),
        clients: _.sortBy(clients, 'name'),
        users: _(users)
          .map(function (user) {
            return _.extend(user, { name: user.firstName + ' ' + user.lastName });
          })
          .sortBy('name')
          .value(),
      }).filter;

      // $scope.userFilter = function (meeting) {
      //     var responsibles = _.filter($scope.filter.users, {selected: true});
      //     return _.isEmpty(responsibles) || _.find(responsibles, {id: meeting.CreatedById || meeting.CreatedBy.id})
      // }
      //
      // $scope.clientFilter = function (meeting) {
      //     var clients = _.filter($scope.filter.clients, {selected: true});
      //     return _.isEmpty(clients) || _.find(clients, {id: meeting.ClientId || meeting.Client.id})
      // }
      //
      // $scope.templateFilter = function (meeting) {
      //     return !$scope.getSelectedTemplate() || $scope.getSelectedTemplate().id === (meeting.TemplateId || meeting.Template.id)
      // }

      $scope.meetingFilter = function (meeting) {
        var responsibles = _.filter($scope.filter.users, { selected: true });
        var clients = _.filter($scope.filter.clients, { selected: true });
        var meetingTemplates = _.filter($scope.filter.meetingTemplates, { selected: true });
        return (
          (_.isEmpty(responsibles) ||
            _.find(responsibles, { id: meeting.Client.AccountManagerId })) &&
          (_.isEmpty(clients) || _.find(clients, { id: meeting.ClientId || meeting.Client.id })) &&
          (_.isEmpty(meetingTemplates) ||
            _.find(meetingTemplates, { id: meeting.TemplateId || meeting.Template.id }))
        );
      };

      $scope.getSelectedTemplate = function () {
        return _.find($scope.filter.meetingTemplates, { selected: true });
      };

      // $scope.getSelectedData = function () {
      $scope.$watch(
        'filter',
        function () {
          var selectedTemplate = $scope.getSelectedTemplate();
          if (selectedTemplate) {
            var responsibles = _.filter($scope.filter.users, { selected: true });
            var clients = _.filter($scope.filter.clients, { selected: true });
            HttpService.get(
              '/api/meeting/templates/' +
                selectedTemplate.id +
                '/average?' +
                (!_.isEmpty(responsibles)
                  ? '&responsibles=' + _(responsibles).map('id').join(',')
                  : '') +
                (!_.isEmpty(clients) ? '&clients=' + _(clients).map('id').join(',') : ''),
            ).then(function (result) {
              // HttpService.get('/api/meeting/templates/' + selectedTemplate.id).then(function (selectedTemplate) {
              //     var filteredMeetings = _.filter($scope.meetings, $scope.meetingFilter)
              //     var avgFilteredMeetings = _.filter(filteredMeetings, function (meeting) {
              //         return meeting.score > 0 && meeting.targetScore > 0;
              //     });

              $scope.selected = {
                template: result.template,
                meetingsCount: result.count,
                score: result.avgScore, //Math.round(_.meanBy(avgFilteredMeetings, 'score')),
                targetScore: result.avgTargetScore, //Math.round(_.meanBy(avgFilteredMeetings, 'targetScore')),
                clients: result.clients,
                // clients: _.filter($scope.clients, function (client) {
                //     return _(filteredMeetings).map('Client.id').includes(client.id)
                // }),
                ranges: result.ranges,
              };
            });
          } else {
            $scope.selected = undefined;
          }
        },
        true,
      );

      $scope.orderByFilter = function () {
        return _.find($scope.filter.order, { selected: true });
      };
    },
  )

  .controller(
    'ClientsProjectsController',
    function (
      $modal,
      $moment,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $translate,
      $window,
      _,
      Auth,
      ClientProjectsService,
      CompanyService,
      CurrentUser,
      DialogService,
      HttpService,
      SidebarFilterService,
      clientProjects,
      projects,
      users,
      projectStatuses,
      clients,
    ) {
      $scope.clientProjects = clientProjects || [];
      $scope.showAllProjects = true;
      $scope.currency = CurrentUser.getUser().Company.currency;
      $scope.projects = _.filter(projects, function (project) {
        return !_.find(clientProjects, { ServiceId: project.id });
      });
      $scope.projects.unshift({
        name: $translate.instant('label.report.actionplan.createProject'),
      });
      $scope.filter = SidebarFilterService.get('clientprojects').setLists({
        clients: clients,
        projects: projects,
        users: _(users)
          .map(function (user) {
            return {
              id: user.id,
              name: user.firstName + ' ' + user.lastName,
            };
          })
          .sortBy('name')
          .map(function (user, i) {
            return _.extend(user, { orderId: i });
          })
          .concat([
            {
              id: -1,
              name: 'label.clients.projects.withoutResponsible',
              orderId: users.length,
            },
          ])
          .value(),
        projectStatus: _.map(projectStatuses, function (status, index) {
          return {
            id: index,
            orderId: index,
            status: status.code,
            name: status.name || $translate.instant('projectStatuses.' + status.code),
          };
        }),
      }).filter;
      $scope.users = users;
      $scope.valueType = 'prices';
      $scope.table = {};

      $scope.timeScales = {
        M: { idFormat: 'YYYYMM', titleFormat: 'YYYY-M', sumMonths: 1 },
        Q: { idFormat: 'YYYYQ', titleFormat: 'YYYY[Q]Q', sumMonths: 3 },
        Y: { idFormat: 'YYYY', titleFormat: 'YYYY', sumMonths: 12 },
      };
      var startColumn = $moment().startOf($scope.timeScale);
      $scope.timeScale = Auth.getCompanySettingsValue('projects.timeScale', 'Q');
      $scope.changeTimescale = function (t) {
        if ($scope.timeScale !== t) {
          $scope.timeScale = t;
          Auth.setCompanySettingsValue('projects.timeScale', $scope.timeScale);
        }
      };

      $scope.projectStatuses = projectStatuses;
      CompanyService.getProjectStatuses().then(function (statuses) {
        $scope.projectStatuses = statuses;
      });

      $scope.projectFilter = function (item) {
        var statuses = _.filter($scope.filter.projectStatus, { selected: true });
        var responsibles = _.filter($scope.filter.users, { selected: true });
        var clients = _.filter($scope.filter.clients, { selected: true });
        var projects = _.filter($scope.filter.projects, { selected: true });
        return (
          (_.isEmpty(statuses) ||
            _.find(statuses, function (status) {
              return status.status == item.status || (status.status == 'none' && !item.status);
            })) &&
          (_.isEmpty(responsibles) ||
            _.find(responsibles, function (user) {
              return user.id == item.ResponsibleId || (user.id == -1 && !item.ResponsibleId);
            })) &&
          (_.isEmpty(clients) ||
            _.find(clients, function (client) {
              return client.id == item.Client.id;
            })) &&
          (_.isEmpty(projects) ||
            _.find(projects, function (project) {
              return project.id == item.ServiceId;
            }))
        );
      };

      $scope.projectOrder = function (clientProject) {
        var orderField = _.get(
          _.find($scope.filter.order, { selected: true }),
          'orderField',
          'name',
        );
        return _.get(clientProject, orderField);
      };

      var generateColums = function (start) {
        return ClientProjectsService.generateColums({
          start: start,
          timeScale: $scope.timeScale || _.get($scope.widget, 'data.timeScale') || 'Q',
          timeScales: $scope.timeScales,
          widget: $scope.widget,
        });
      };

      $scope.timeColumns = generateColums(startColumn);

      $scope.$watch('timeScale', function (newValue, oldValue) {
        $scope.timeColumns = generateColums(startColumn);
        calculateTable();
      });

      var calculateTable = function () {
        $scope.table = ClientProjectsService.calculateTable({
          clientProjects: $scope.clientProjects,
          filters: [$scope.projectFilter],
          timeColumns: $scope.timeColumns,
          timeScales: $scope.timeScales,
          timeScale: $scope.timeScale,
          valueType: $scope.valueType,
        });
      };

      $scope.changeValueType = function () {
        $scope.valueType = $scope.valueType == 'prices' ? 'resourceHours' : 'prices';
        calculateTable();
      };

      $scope.getTypeLabel = function () {
        return $scope.valueType == 'prices'
          ? $scope.currency
            ? $translate.instant('currency.' + $scope.currency)
            : ''
          : 'h';
      };

      $scope.isBudgetCell = function (clientProject, column) {
        return _.get($scope.table, clientProject.id + '.' + column.id) !== undefined;
      };

      $scope.getCellValue = function (clientProject, column) {
        return _.get($scope.table, clientProject.id + '.' + column.id);
      };

      $scope.getColumnSum = function (column) {
        return _.get($scope.table, '0.' + column.id);
      };

      $scope.getProjectSum = function (clientProject) {
        return _.get($scope.table, clientProject.id + '.sum');
      };

      $scope.prevTime = function () {
        startColumn.subtract(1, $scope.timeScale);
        $scope.timeColumns = generateColums(startColumn);
        calculateTable();
      };

      $scope.nextTime = function () {
        startColumn.add(1, $scope.timeScale);
        $scope.timeColumns = generateColums(startColumn);
        calculateTable();
      };

      $scope.getProjectStatusIcon = function (status) {
        const projectStatus = $scope.statuses
          ? $scope.statuses.find(function (x) {
              return x.code === status;
            })
          : null;
        return projectStatus
          ? projectStatus.icon
            ? projectStatus.icon
            : projectStatus.defaultIcon
          : 'fa-fw fa-lightbulb-o';
      };

      CompanyService.getProjectStatuses().then(function (statuses) {
        $scope.statuses = statuses;
      });

      $scope.changeProjectStatus = function (clientProject, status) {
        if (!$scope.isSnapshot) {
          clientProject.status = status.code;
          HttpService.put('/api/clients/' + clientProject.ClientId + '/projects', clientProject);
        }
      };

      $scope.setProjectResponsible = function (clientProject, responsible) {
        if (!$scope.isSnapshot) {
          clientProject.ResponsibleId = responsible ? responsible.id : null; //use null to delete the id
          clientProject.Responsible = responsible;
          HttpService.put('/api/clients/' + clientProject.ClientId + '/projects', clientProject);
        }
      };
    },
  )

  .controller(
    'ClientProjectsController',
    function (
      $modal,
      $moment,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $translate,
      $window,
      _,
      Auth,
      CompanyService,
      CurrentUser,
      DialogService,
      HttpService,
      UserEventService,
      client,
      clientProjects,
      projects,
      users,
      company,
      proposals,
    ) {
      $scope.proposals = proposals;
      $scope.client = client;
      $scope.clientProjects = clientProjects || [];
      $scope.showAllProjects = true;
      $scope.currency = CurrentUser.getUser().Company.currency;
      $scope.projects = _.filter(projects, function (project) {
        return !_.find(clientProjects, { ServiceId: project.id });
      });
      $scope.projects.unshift({
        name: $translate.instant('label.report.actionplan.createProject'),
      });

      $scope.timeScale = Auth.getCompanySettingsValue('projects.timeScale', 'Q');
      $scope.changeTimescale = function (t) {
        if ($scope.timeScale !== t) {
          $scope.timeScale = t;
          Auth.setCompanySettingsValue('projects.timeScale', $scope.timeScale);
        }
      };

      const cw = company.keys.connectwise;

      $scope.canSyncConnectwise =
        CurrentUser.can('integrationProjects') &&
        client.externalIds &&
        client.externalIds.connectwise &&
        cw &&
        !_.isEmpty(cw);
      $scope.navigateToSyncPage = function () {
        $state.go('clients.syncprojects', { clientId: client.id });
      };

      $scope.getConnectwisePopoverTitle = function (connectwise) {
        if (connectwise) {
          return connectwise.project
            ? connectwise.project.name
            : connectwise.opportunity
            ? connectwise.opportunity.name
            : '';
        }
      };

      $scope.getConnectwisePopover = function (connectwise) {
        if (connectwise) {
          const status = connectwise.project
            ? connectwise.project.status
            : connectwise.opportunity
            ? connectwise.opportunity.status
            : false;
          const type = connectwise.project
            ? 'project'
            : connectwise.opportunity
            ? 'opportunity'
            : '';
          const clientStatus = _.find($scope.projectStatuses, { code: status });

          if (clientStatus) {
            const translatedStatus =
              clientStatus.name || $translate.instant('projectStatuses.' + status);
            return (
              $translate.instant('label.connectwise.' + type) +
              '<br>' +
              $translate.instant('label.status') +
              ': ' +
              translatedStatus
            );
          }
        }
      };

      $scope.getConnectwiseUrl = function (connectwise) {
        if (!connectwise) {
          return;
        }

        return connectwise.project
          ? connectwise.project.appUrl
          : connectwise.opportunity
          ? connectwise.opportunity.appUrl
          : '';
      };

      CompanyService.getProjectStatuses().then(function (statuses) {
        $scope.projectStatuses = statuses;
      });

      UserEventService.event('clientProjectsOpened', { system: $scope.system });
    },
  )

  .controller(
    'ClientProjectsWidgetController',
    function (
      $modal,
      $moment,
      $rootScope,
      $scope,
      $stateParams,
      $translate,
      $window,
      _,
      ClientProjectsService,
      CompanyService,
      CurrentUser,
      DialogService,
      HttpService,
      MeetingService,
      InputSanitizerService,
      Utils,
    ) {
      $scope.editCell = {};
      $scope.selected = {};
      $scope.valueType = 'prices';
      $scope.disableReorder = !$scope.showAllProjects || !$scope.client || $scope.isSnapshot;
      $scope.filter = {};
      $scope.table = {};

      $scope.sharebox = {};
      $scope.sharebox.url = '';
      $scope.sharebox.showUrlBox = false;
      $scope.sharebox.clientUrl = '';

      $scope.setShareUrl = function (url, doShowBox) {
        const clientId = $scope.isMeeting ? $scope.meeting.Client.id : $scope.client.id;
        $scope.sharebox.url = url;
        $scope.sharebox.showUrlBox = doShowBox;
        $scope.sharebox.clientUrl = '/#/clients/' + clientId + '/meetings';
      };

      $scope.$on('hideBox', function () {
        $scope.sharebox.showUrlBox = false;
      });

      if (!$scope.client) {
        HttpService.get('/api/clients/' + $scope.meeting.Client.id + '/clientproposals').then(
          function (clientproposals) {
            $scope.proposals = clientproposals;
          },
        );
      }

      $scope.timeScales = {
        M: { idFormat: 'YYYYMM', titleFormat: 'YYYY-M', sumMonths: 1 },
        Q: { idFormat: 'YYYYQ', titleFormat: 'YYYY[Q]Q', sumMonths: 3 },
        Y: { idFormat: 'YYYY', titleFormat: 'YYYY', sumMonths: 12 },
      };
      var startColumn = $moment().startOf($scope.timeScale);

      if (!$scope.users && CurrentUser.getUser()) {
        HttpService.get(
          '/api/companies/' +
            InputSanitizerService.sanitize(CurrentUser.getUser().Company.id) +
            '/users',
        ).then(function (users) {
          $scope.users = users;
        });
      }

      $scope.$watch('showAllProjects', function () {
        $scope.disableReorder = !$scope.showAllProjects || !$scope.client || $scope.isSnapshot;
      });

      $scope.changeValueType = function () {
        $scope.valueType = $scope.valueType == 'prices' ? 'resourceHours' : 'prices';
        calculateTable();
      };

      $scope.$watch('clientProjects', function () {
        calculateTable();
      });

      $scope.getTypeLabel = function () {
        return $scope.valueType == 'prices'
          ? $scope.currency
            ? $translate.instant('currency.' + $scope.currency)
            : ''
          : 'h';
      };

      $scope.meetingFilter = function (clientProject) {
        return (
          $scope.showAllProjects || MeetingService.isMeetingProject(clientProject, $scope.meeting)
        );
      };

      $scope.projectOrder =
        $scope.projectOrder ||
        function (clientProject) {
          return clientProject.orderId;
        };

      var generateColums = function (start) {
        return ClientProjectsService.generateColums({
          start: start,
          timeScale: $scope.timeScale || _.get($scope.widget, 'data.timeScale') || 'Q',
          timeScales: $scope.timeScales,
          widget: $scope.widget,
        });
      };

      $scope.$watch('timeScale', function (newValue, oldValue) {
        $scope.timeColumns = generateColums(startColumn);
        calculateTable();
      });

      // This workaround is exist to reload the project list on the widget
      $scope.$on('reloadProjectData', function (event, project) {
        var filteredProjects = $scope.clientProjects.filter(function (clientProject) {
          return clientProject.id !== project.id;
        });
        filteredProjects.push(project);
        $scope.clientProjects = filteredProjects;
      });

      var calculateTable = function () {
        $scope.table = ClientProjectsService.calculateTable({
          clientProjects: $scope.clientProjects,
          filters: [$scope.meetingFilter, $scope.projectFilter],
          timeColumns: $scope.timeColumns,
          timeScales: $scope.timeScales,
          timeScale: $scope.timeScale,
          valueType: $scope.valueType,
        });
      };

      $scope.isBudgetCell = function (clientProject, column) {
        return _.get($scope.table, clientProject.id + '.' + column.id) !== undefined;
      };

      $scope.getCellValue = function (clientProject, column) {
        return _.get($scope.table, clientProject.id + '.' + column.id);
      };

      $scope.getColumnSum = function (column) {
        return _.get($scope.table, '0.' + column.id);
      };

      $scope.getProjectSum = function (clientProject) {
        return _.get($scope.table, clientProject.id + '.sum');
      };

      $scope.isEditCell = function (clientProject, column) {
        return (
          $scope.editCell.clientProject &&
          $scope.editCell.column &&
          $scope.editCell.clientProject.id === clientProject.id &&
          $scope.editCell.column.id === column.id
        );
      };

      $scope.setEditCell = function (clientProject, column) {
        if (!$scope.isSnapshot) {
          if (
            $scope.editCell &&
            $scope.editCell.value !== undefined &&
            $scope.editCell.value !==
              $scope.getCellValue($scope.editCell.clientProject, $scope.editCell.column)
          ) {
            var tableCellValue = $scope.getCellValue(
              $scope.editCell.clientProject,
              $scope.editCell.column,
            );
            $scope.saveBudgetColumn($scope.editCell.clientProject, $scope.editCell.column);
          }

          if (clientProject && column) {
            $scope.editCell = {
              value: $scope.getCellValue(clientProject, column),
              clientProject: clientProject,
              column: column,
            };
          } else {
            $scope.editCell = {};
          }
        }
      };

      $scope.prevTime = function () {
        startColumn.subtract(1, $scope.timeScale);
        $scope.timeColumns = generateColums(startColumn);
        calculateTable();
      };

      $scope.nextTime = function () {
        startColumn.add(1, $scope.timeScale);
        $scope.timeColumns = generateColums(startColumn);
        calculateTable();
      };

      var forTimeScale = function (column, iteratee) {
        var sumMonths = $scope.timeScales[$scope.timeScale].sumMonths;
        var startMonthId = parseInt(
          $moment(column.id, $scope.timeScales[$scope.timeScale].idFormat).format('YYYYMM'),
        );

        for (var i = 0; i < sumMonths; i++) {
          iteratee(startMonthId + i, i === sumMonths - 1);
        }
      };

      const generateMonthsByCell = function (column) {
        const sumMonths = $scope.timeScales[$scope.timeScale].sumMonths;
        const startMonthId = parseInt(
          $moment(column.id, $scope.timeScales[$scope.timeScale].idFormat).format('YYYYMM'),
        );
        const months = [];
        for (var i = 0; i < sumMonths; i++) {
          months.push(startMonthId + i);
        }
        return months;
      };

      const calculateAssetSumCost = function (assets, generatedMonths) {
        return (assets || []).reduce(function (acc, price) {
          if (_.isNil(price) || _.isNil(price.months)) {
            return acc;
          }

          _.forEach(Object.entries(price.months), function (entry) {
            const key = entry[0];
            const value = entry[1];
            if (generatedMonths.includes(+key)) {
              acc += value;
            }
          });

          return acc;
        }, 0);
      };

      $scope.saveBudgetColumn = function (clientProject, column) {
        const generatedMonths = generateMonthsByCell(column);

        if (!$scope.isSnapshot) {
          // store the amount in the given month or split the Q or Y amount into months
          const newPriceValue = $scope.editCell.value;

          // error handling
          clientProject[$scope.valueType] = clientProject[$scope.valueType] || {};
          if (
            !clientProject[$scope.valueType].others ||
            !clientProject[$scope.valueType].others.length
          ) {
            clientProject[$scope.valueType].others = [{ name: 'Project price', months: {} }];
          }

          const assetSumCost = calculateAssetSumCost(
            clientProject[$scope.valueType].assets,
            generatedMonths,
          );

          if (newPriceValue < assetSumCost) {
            Utils.showToastMessage(
              'Cost must be greater than ' +
                $translate.instant('currency.' + $scope.currency) +
                assetSumCost +
                ' because of the asset related costs.',
              { className: 'danger' },
            );
            return;
          }

          var modifiedPriceValue = newPriceValue - assetSumCost;
          var monthlyPrice = _.round(modifiedPriceValue / generatedMonths.length);

          _.forEach(generatedMonths, function (month, i) {
            const others = clientProject[$scope.valueType].others || [];
            if (i === generatedMonths.length - 1) {
              monthlyPrice += modifiedPriceValue - monthlyPrice * generatedMonths.length;
            }
            var verticalPrice = _.round(monthlyPrice / others.length);
            _.forEach(others, function (otherCostEntry, j) {
              otherCostEntry.months[month] = verticalPrice;
              if (j === others.length - 1) {
                otherCostEntry.months[month] += monthlyPrice - verticalPrice * others.length;
              }
            });
          });

          HttpService.put('/api/clients/' + clientProject.ClientId + '/projects', clientProject);
          calculateTable();
        }
      };

      $scope.addBudgetMonth = function (clientProject, column) {
        if (!$scope.isSnapshot) {
          // $window.location.href =
          //   '/new-frontend?path=clients/' +
          //   clientProject.ClientId +
          //   '/project-roadmap%3Ftoken=' + CurrentUser.getToken() +
          //   '%26projectId=' + clientProject.id;
          if (!$scope.isBudgetCell(clientProject, column)) {
            forTimeScale(column, function (monthId) {
              if (!_.includes(clientProject.months, monthId + '')) {
                clientProject.months = clientProject.months || [];
                clientProject.months.push(monthId + '');
              }
            });

            HttpService.put('/api/clients/' + clientProject.ClientId + '/projects', clientProject);
            calculateTable();
          }
        }
      };

      $scope.removeBudgetMonth = function (clientProject, column) {
        if (!$scope.isSnapshot) {
          if ($scope.isBudgetCell(clientProject, column)) {
            forTimeScale(column, function (monthId) {
              if (clientProject[$scope.valueType]) {
                const assetSumCost = calculateAssetSumCost(clientProject[$scope.valueType].assets, [
                  monthId,
                ]);

                if (assetSumCost) {
                  Utils.showToastMessage($translate.instant('label.client.removeCost.assetCost'), {
                    className: 'danger',
                  });

                  if (assetSumCost === $scope.editCell.value) {
                    return;
                  }

                  $scope.editCell.value = assetSumCost;
                }

                if (!assetSumCost) {
                  clientProject.months = clientProject.months.filter(function (month) {
                    return month !== monthId + '';
                  });
                }

                clientProject[$scope.valueType].others.forEach(function (other) {
                  if (!_.isNil(other.months[monthId])) {
                    delete other.months[monthId];
                  }
                });

                if ($scope.editCell && !assetSumCost) {
                  $scope.editCell.value = undefined;
                }
              }
            });
            HttpService.put('/api/clients/' + clientProject.ClientId + '/projects', clientProject);

            calculateTable();
          }
        }
      };

      var openClientProjectEdit = function (clientProject) {
        return $modal.open({
          templateUrl: '/templates/client/client-project-edit.html',
          backdrop: 'static',
          size: 'lg',
          controller: function (
            $scope,
            $modalInstance,
            HttpService,
            clientProject,
            projectTemplates,
            InputSanitizerService,
          ) {
            $scope.projectTemplates = projectTemplates;
            $scope.clientProject = clientProject || {};
            $scope.clientProject.deliverable = $scope.clientProject.deliverable || {};

            $scope.ok = function (cb) {
              HttpService[$scope.clientProject.id ? 'put' : 'post'](
                '/api/clients/' +
                  InputSanitizerService.sanitize($scope.clientProject.ClientId) +
                  '/projects',
                $scope.clientProject,
              )
                .then(function (result) {
                  $modalInstance.close(result);
                })
                .catch(function (error) {
                  cb();
                  $scope.errors = [error];
                });
            };

            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
          resolve: {
            clientProject: function () {
              return _.cloneDeep(clientProject);
            },
            projectTemplates: function (HttpService) {
              return HttpService.get('/api/service/services/templates?type=onetime');
            },
          },
        });
      };

      $scope.addProject = function (project) {
        if (!$scope.isSnapshot && $scope.client) {
          if (project && project.id) {
            HttpService.post(
              '/api/clients/' + InputSanitizerService.sanitize($scope.client.id) + '/projects',
              {
                ServiceId: project.id,
                name: project.name,
                deliverable: project.deliverable,
                description: project.description,
              },
            ).then(function (result) {
              $scope.clientProjects.push(result);
              calculateTable();
              // if ($scope.filterProjects) {
              //     $scope.filterProjects()
              // }
            });
            $scope.selected.project = undefined;
          } else {
            openClientProjectEdit({ ClientId: $scope.client.id }).result.then(function (
              createdClientProject,
            ) {
              $scope.clientProjects.push(createdClientProject);
              calculateTable();
              // if ($scope.filterProjects) {
              //     $scope.filterProjects()
              // }
              $scope.selected.project = undefined;
            });
          }
        }
      };

      $scope.removeProject = function (clientProject) {
        if (!$scope.isSnapshot && clientProject) {
          DialogService.confirm('label.clients.projects.removeConfirmationClient', function () {
            HttpService.delete('/api/clients/' + $scope.client.id + '/projects/' + clientProject.id)
              .then(function () {
                _.remove($scope.clientProjects, { id: clientProject.id });
                // if ($scope.filterProjects) {
                //     $scope.filterProjects();
                // }
                calculateTable();
              })
              .catch(function (error) {
                DialogService.error(error);
              });
          });
        }
      };

      $scope.editClientProject = function (clientProject) {
        if (!$scope.isSnapshot && clientProject) {
          openClientProjectEdit(clientProject).result.then(function (result) {
            _.extend(clientProject, _.pick(result, ['name', 'description', 'deliverable']));
          });
        }
      };

      $scope.reorderProjects = function () {
        if (!$scope.isSnapshot && $scope.client) {
          _.forEach($scope.clientProjects, function (clientProject, index) {
            clientProject.orderId = index + 1;
          });
          HttpService.put(
            '/api/clients/' +
              InputSanitizerService.sanitize($scope.client.id) +
              '/projects/reorder',
            _.map($scope.clientProjects, _.partialRight(_.pick, ['id', 'orderId'])),
          ).catch(function (error) {
            DialogService.error(error);
          });
        }
      };

      $scope.getProjectStatusIcon = function (status) {
        const projectStatus = _.find($scope.statuses || $scope.projectStatuses, { code: status });
        return projectStatus
          ? projectStatus.icon
            ? projectStatus.icon
            : projectStatus.defaultIcon
          : 'fa-fw fa-lightbulb-o';
      };

      $scope.getProjectStatusLanguageKey = function (status) {
        const statusLanguageKey = 'projectStatuses.' + status;
        const projectStatus = _.find($scope.statuses || $scope.projectStatuses, { code: status });
        return projectStatus && projectStatus.name
          ? projectStatus.name
          : $translate.instant(statusLanguageKey);
      };

      CompanyService.getProjectStatuses().then(function (statuses) {
        $scope.statuses = statuses;
      });

      $scope.changeProjectStatus = function (clientProject, status) {
        if (!$scope.isSnapshot) {
          clientProject.status = status.code;
          HttpService.put('/api/clients/' + clientProject.ClientId + '/projects', clientProject);
        }
      };

      $scope.setProjectResponsible = function (clientProject, responsible) {
        if (!$scope.isSnapshot) {
          clientProject.ResponsibleId = responsible ? responsible.id : null; //use null to delete the id
          clientProject.Responsible = responsible;
          HttpService.put('/api/clients/' + clientProject.ClientId + '/projects', clientProject);
        }
      };
    },
  )

  .controller(
    'ClientAssetsController',
    function (
      $modal,
      $moment,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $translate,
      $window,
      _,
      Auth,
      CompanyService,
      CurrentUser,
      DialogService,
      HttpService,
      UserEventService,
      client,
      clientProjects,
      projects,
      users,
      company,
      assets,
    ) {
      $scope.client = client;
      $scope.assets = assets;
      $scope.assets.currency = CurrentUser.getUser().Company.currency;
      $scope.assets.clientId = client.id;
      $scope.clientProjects = clientProjects;
    },
  )

  .controller(
    'ClientAssetTypesController',
    function (
      $modal,
      $moment,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $translate,
      $window,
      _,
      Auth,
      CurrentUser,
      DialogService,
      HttpService,
      Utils,
    ) {
      $scope.currentUser = CurrentUser.getUser();
      $scope.currency = $scope.currentUser.Company.currency;
      $scope.assetTypeField = { typeName: '', defaultCost: 0, isEdit: false };
      $scope.clientAssetTypes;

      if (!$scope.clientAssetTypes) {
        HttpService.get('/api/clientassettypes')
          .then(function (clientAssetTypes) {
            $scope.clientAssetTypes = clientAssetTypes;
          })
          .catch(function (error) {
            $scope.clientAssetTypes = [];
            DialogService.error(error);
          });
      }

      $scope.getButtonStyle = function (typeHasConnection) {
        return typeHasConnection
          ? 'btn btn-xs btn-default types-button'
          : 'btn btn-xs btn-danger types-button';
      };

      $scope.updateAssetType = function (assetType) {
        HttpService.put('/api/clientassettypes/' + assetType.id, {
          defaultCost: assetType.defaultCost !== '' ? assetType.defaultCost : 0,
        }).catch(function (error) {
          DialogService.error(error);
        });
      };

      $scope.addAssetType = function () {
        if (!$scope.assetTypeField.typeName) {
          $scope.reset();
          return;
        }

        HttpService.post('/api/clientassettypes', {
          typeName: $scope.assetTypeField.typeName,
          defaultCost:
            $scope.assetTypeField.defaultCost !== ''
              ? $scope.assetTypeField.defaultCost
              : undefined,
        })
          .then(function (createdAssetType) {
            $scope.clientAssetTypes.push(createdAssetType);
            Utils.showToastMessage(
              { code: 'label.client.addAssetType.success' },
              { className: 'success' },
            );
            $scope.reset();
          })
          .catch(function (error) {
            DialogService.error(error);
          });
      };

      $scope.removeAssetType = function (assetType) {
        HttpService.delete('/api/clientassettypes/' + assetType.id)
          .then(function (assetTypeId) {
            var assetTypeIdx = $scope.clientAssetTypes.findIndex(function (type) {
              return Number(type.id) === Number(assetTypeId.id);
            });
            $scope.clientAssetTypes.splice(assetTypeIdx, 1);
            Utils.showToastMessage(
              { code: 'label.client.removeAssetType.success' },
              { className: 'success' },
            );
            $scope.reset();
          })
          .catch(function (error) {
            DialogService.error(error);
          });
      };

      $scope.reset = function () {
        $scope.assetTypeField = { typeName: '' };
      };
    },
  )

  .controller(
    'ClientAssetsWidgetController',
    function (
      $modal,
      $scope,
      CurrentUser,
      HttpService,
      DialogService,
      $moment,
      $rootScope,
      $stateParams,
      $translate,
      $window,
      _,
      ClientProjectsService,
      CompanyService,
      MeetingService,
      Utils,
      InputSanitizerService,
    ) {
      $scope.selected = {};
      $scope.assetFilter = {};

      var clientId = $scope.client ? $scope.client.id : $scope.meeting.ClientId;
      $scope.getProjects = function () {
        return HttpService.get('/api/clients/' + clientId + '/projects');
      };

      $scope.getProjects().then(function (clientProjects) {
        $scope.clientProjects = clientProjects;
        $scope.clientAssetProjects = angular.copy($scope.clientProjects);
        $scope.clientAssetProjects.push({
          name: $translate.instant('label.report.actionplan.createProject'),
        });
      });

      $scope.getAssetTypesFromAssets = function (assets) {
        if (!assets) {
          return [];
        }

        var assetTypes = assets.reduce(function (acc, asset) {
          if (asset.Type && asset.Type.typeName) {
            acc.add(asset.Type.typeName);
          }
          return acc;
        }, new Set());

        return Array.from(assetTypes).map(function (type, index) {
          return { id: index, name: type };
        });
      };

      var ProjectLinkTypes = {
        PROJECT_LINKS: 'Project links',
        NONE: 'None',
        ONE_OR_MORE: 'One or more',
      };

      $scope.getProjectLinkTypes = function () {
        return [
          { id: 1, name: ProjectLinkTypes.PROJECT_LINKS },
          { id: 2, name: ProjectLinkTypes.NONE },
          { id: 3, name: ProjectLinkTypes.ONE_OR_MORE },
        ];
      };

      $scope.expandAssetData = function () {
        $scope.assetFilter.isAllSelected = false;
        $scope.assetFilter.searchedValue = '';
        $scope.assetFilter.assetTypes = $scope.getAssetTypesFromAssets($scope.assets);
        $scope.assetFilter.selectedTypes = [];
        $scope.assetFilter.warrantyExpireEnd = null;
        $scope.assetFilter.projectLinkTypes = $scope.getProjectLinkTypes();
        $scope.assetFilter.selectedProjectLinkType = $scope.assetFilter.projectLinkTypes[0];
      };

      $scope.currency = CurrentUser.getUser().Company.currency;
      $scope.assignedAssetsToProject = [];
      $scope.expandAssetData();

      $scope.$watch('assets', $scope.expandAssetData);

      $scope.$watchGroup(
        [
          'assets.searchedValue',
          'assets.selectedTypes',
          'assets.warrantyExpireEnd',
          'assets.selectedProjectLinkType',
        ],
        function () {
          if ($scope.assetFilter.isAllSelected) {
            $scope.selectAll();
          }
        },
      );

      $scope.loadAssetData = function () {
        if (CurrentUser.isAuthenticated()) {
          $scope.getProjects().then(function (clientProjects) {
            $scope.clientProjects = clientProjects;
          });
          HttpService.get(
            '/api/clients/' + InputSanitizerService.sanitize($scope.client.id) + '/clientassets',
          ).then(function (assets) {
            $scope.assets = assets;
            $scope.assets.expanded = false;
            $scope.expandAssetData();
          });
        }
      };

      $scope.redirectFromAsset = function (asset) {
        if (asset.ClientProjects.length > 1) {
          var ids = asset.ClientProjects.map(function (clientProject) {
            return clientProject.id;
          });

          var projectList = $scope.clientProjects.filter(function (project) {
            return ids.includes(project.id);
          });

          openProjecListPopup(projectList, $scope.openClientProjectEdit);
        } else {
          var editableProject = $scope.clientProjects.filter(function (project) {
            return project.id === asset.ClientProjects[0].id;
          });

          $scope.openClientProjectEdit(editableProject[0], editableProject[0].ClientAssets);
        }
      };

      var openProjecListPopup = function (projects, cb) {
        var modalInstance = $modal.open({
          template:
            '<modal-form onok="ok" oncancel="cancel" form-title="assetWidget.assetAttachedProjectsList.title">' +
            '<div>' +
            '<div class="alert alert-info" translate="assetWidget.assetAttachedProjectsList.alert"></div>' +
            '<ul>' +
            '<li ng-repeat="project in projects">' +
            '<a ng-click="openEdit(project)">{{project.name}}</a>' +
            '</li>' +
            '</ul>' +
            '</div>' +
            '</modal-form>',

          controller: function ($modalInstance, $scope, projects) {
            $scope.options = { templates: [] };
            $scope.projects = projects;
            $scope.cb = cb;

            $scope.openEdit = function (project) {
              $scope.cb(project, project.ClientAssets);
            };

            $scope.ok = function () {
              $modalInstance.dismiss();
            };
            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
          resolve: {
            projects: function () {
              return _.cloneDeep(projects);
            },
            cb: function () {
              return cb;
            },
          },
        });
      };

      $scope.cancelCsvUpload = function () {
        $scope.assets.expanded = false;
        document.getElementById('assetCsvFile').value = '';
      };

      $scope.$on('reloadAssetData', function () {
        $scope.loadAssetData();
      });

      var stringifyResultArray = function (arr, resultArrayPropName) {
        if (!arr[0][resultArrayPropName]) {
          return '';
        }
        return arr.reduce(function (acc, item) {
          return acc + '<li> ' + item[resultArrayPropName] + ' </li>';
        }, '');
      };

      $scope.uploadFile = function () {
        var assetCsv = document.getElementById('assetCsvFile').files[0];

        if (!assetCsv) {
          return;
        }

        var assetCsvFormData = new FormData();
        assetCsvFormData.append('file', assetCsv);

        HttpService.post(
          '/api/clients/' + $scope.client.id + '/clientassets/upload-csv',
          assetCsvFormData,
          { 'Content-Type': undefined },
        )
          .then(function (importResult) {
            const csvImport = {
              uploads: {
                count: importResult.uploadedAssets ? importResult.uploadedAssets.length : 0,
              },
              duplicates: {
                count: importResult.updatedAssets ? importResult.updatedAssets.length : 0,
                ids:
                  importResult.updatedAssets && importResult.updatedAssets.length
                    ? stringifyResultArray(importResult.updatedAssets, 'externalId')
                    : '',
              },
              errors: {
                count: importResult.failedAssets ? importResult.failedAssets.length : 0,
                messages:
                  importResult.failedAssets && importResult.failedAssets.length
                    ? stringifyResultArray(importResult.failedAssets, 'error')
                    : '',
              },
            };
            DialogService.message(
              {
                code: 'dialog.assetWidget.CsvUploaded',
                translateValues: { csvImport: csvImport },
              },
              $translate.instant('title.assetWidget.CsvUploaded'),
            );

            document.getElementById('assetCsvFile').value = '';
            $scope.assetFilter.assetTypes = $scope.getAssetTypesFromAssets($scope.assets);
            $rootScope.$broadcast('reloadAssetData');
          })
          .catch(function (error) {
            var message = error.message || '';
            Utils.showToastMessage(
              {
                code: 'assetWidget.CsvUploadFail',
                translateValues: { error: message },
              },
              { className: 'danger' },
            );
          });
      };

      $scope.addAsset = function (asset) {
        if (!$scope.isSnapshot && $scope.client) {
          openClientAssetEdit({}, $scope.currency).result.then(function (createdAsset) {
            $scope.assets.push(createdAsset);
            $scope.assetFilter.assetTypes = $scope.getAssetTypesFromAssets($scope.assets);
          });
        }
      };

      $scope.assignAssetToProject = function (asset) {
        if (asset.isChecked) {
          $scope.assignedAssetsToProject.push(asset);
        } else {
          var toDel = $scope.assignedAssetsToProject.indexOf(asset);
          $scope.assignedAssetsToProject.splice(toDel, 1);
        }

        if (!$scope.assignedAssetsToProject.length) {
          $scope.assetFilter.isAllSelected = false;
        }
      };

      $scope.reassignAssets = function () {
        $scope.assignedAssetsToProject = [];
        $scope.assets.forEach(function (asset) {
          asset.isChecked = false;
        });
      };

      $scope.editClientAsset = function (asset) {
        if (asset) {
          openClientAssetEdit(asset, $scope.currency).result.then(function (result) {
            _.extend(
              asset,
              _.pick(result, [
                'name',
                'description',
                'type',
                'warrantyExpire',
                'cost',
                'locationName',
                'manufacturer',
                'modelNumber',
                'os',
                'age',
                'purchaseDate',
                'replaceDate',
                'processorModel',
                'memory',
                'storage',
              ]),
            );
            $scope.assetFilter.assetTypes = $scope.getAssetTypesFromAssets($scope.assets);
          });
        }
      };

      var openClientAssetEdit = function (asset, currency) {
        return $modal.open({
          templateUrl: '/templates/client/client-asset-edit.html',
          backdrop: 'static',
          size: 'M',
          controller: function (
            $scope,
            $modalInstance,
            HttpService,
            client,
            isSnapshot,
            assetTypes,
          ) {
            $scope.asset = asset || {};
            $scope.assetTypes = assetTypes;
            $scope.isSnapshot = isSnapshot;
            $scope.client = client;
            $scope.asset.details = $scope.asset.details || '';
            $scope.asset.ClientId = $scope.client.id;
            $scope.asset.currency = currency;

            $scope.ok = function (cb) {
              HttpService[$scope.asset.id ? 'put' : 'post'](
                '/api/clients/' + $scope.client.id + '/clientassets',
                $scope.asset,
              )
                .then(function (result) {
                  if (result.purchaseDate) {
                    result['age'] = moment().diff(result.purchaseDate, 'years', true).toFixed(2);
                  }
                  $modalInstance.close(result);
                })
                .catch(function (error) {
                  cb();
                  $scope.errors = [error];
                });
            };

            $scope.cancel = function () {
              $modalInstance.dismiss();
            };

            $scope.setType = function (type) {
              $scope.asset.TypeId = type.id;
              $scope.asset.cost = type.defaultCost;
            };
          },
          resolve: {
            clientAsset: function () {
              return _.cloneDeep(asset);
            },
            client: function () {
              return $scope.client;
            },
            isSnapshot: function () {
              return $scope.isSnapshot;
            },
            assetTypes: function () {
              return HttpService.get('/api/clientassettypes');
            },
          },
        });
      };

      $scope.openClientProjectEdit = function (clientProject, assignedAssets) {
        return $modal.open({
          templateUrl: '/templates/client/client-project-edit.html',
          backdrop: 'static',
          size: 'lg',
          controller: function (
            $scope,
            $modalInstance,
            HttpService,
            clientProject,
            assignedAssets,
            projectTemplates,
          ) {
            $scope.projectTemplates = projectTemplates;
            $scope.clientProject = clientProject || {};
            $scope.clientProject.deliverable = $scope.clientProject.deliverable || {};

            if (!_.isEmpty(assignedAssets) && !clientProject.ClientAssets) {
              // Set name
              $scope.clientProject.name = 'Upgrade: ' + assignedAssets[0].name;

              // Set deliverable
              $scope.clientProject.deliverable.description = '<ol>';
              _.forEach(assignedAssets, function (asset) {
                var deliverableDescription = asset.serialNumber ? ' - ' + asset.serialNumber : '';
                $scope.clientProject.deliverable.description +=
                  '<li>' + asset.name + deliverableDescription + '</li>';
              });
              $scope.clientProject.deliverable.description += '</ol>';
            }

            $scope.ok = function (cb) {
              HttpService[$scope.clientProject.id ? 'put' : 'post'](
                '/api/clients/' + $scope.clientProject.ClientId + '/projects',
                $scope.clientProject,
              )
                .then(function (result) {
                  $modalInstance.close(result);
                  $rootScope.$broadcast('reloadAssetData');
                })
                .catch(function (error) {
                  cb();
                  $scope.errors = [error];
                });
            };

            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
          resolve: {
            clientProject: function () {
              return _.cloneDeep(clientProject);
            },
            assignedAssets: function () {
              return _.cloneDeep(assignedAssets);
            },
            projectTemplates: function (HttpService) {
              return HttpService.get('/api/service/services/templates?type=onetime');
            },
          },
        });
      };

      $scope.attachToProject = function (project) {
        if (!$scope.isSnapshot && $scope.client) {
          if (project && project.id) {
            var assetListString = [];
            $scope.assignedAssetsToProject.map(function (asset) {
              assetListString.push('<li>' + asset.name + '</li>');
            });
            DialogService.confirm(
              $translate.instant('assetWidget.assetAttachConfirm', {
                projectName: project.name,
                assets: assetListString.toString().replaceAll(',', ''),
              }),
              'assetWidget.assetAttachConfirm.title',
              function () {
                HttpService.post(
                  '/api/clients/' +
                    InputSanitizerService.sanitize($scope.client.id) +
                    '/projects/' +
                    InputSanitizerService.sanitize(project.id) +
                    '/clientassetprojects',
                  {
                    assetIds: _.map($scope.assignedAssetsToProject, function (asset) {
                      return asset.id;
                    }),
                  },
                ).then(function (result) {
                  Utils.showToastMessage(
                    {
                      code: 'assetWidget.assetAttached',
                      translateValues: { projectName: project.name },
                    },
                    { className: 'success' },
                  );
                  $rootScope.$broadcast('reloadAssetData');
                });
                $scope.selected.project = undefined;
                $scope.reassignAssets();
              },
              function () {
                $scope.selected.project = undefined;
              },
            );
          } else {
            $scope
              .openClientProjectEdit({ ClientId: $scope.client.id }, $scope.assignedAssetsToProject)
              .result.then(function (createdClientProject) {
                HttpService.post(
                  '/api/clients/' +
                    InputSanitizerService.sanitize($scope.client.id) +
                    '/projects/' +
                    InputSanitizerService.sanitize(createdClientProject.id) +
                    '/clientassetprojects',
                  {
                    assetIds: _.map($scope.assignedAssetsToProject, function (asset) {
                      return asset.id;
                    }),
                  },
                ).then(function (result) {
                  $rootScope.$broadcast('reloadAssetData');
                });

                $scope.clientProjects.push(createdClientProject);
                $scope.clientAssetProjects.splice(
                  $scope.clientAssetProjects.length - 1,
                  0,
                  createdClientProject,
                );
                $scope.selected.project = undefined;
                $scope.reassignAssets();
              });
          }
        }
      };

      $scope.removeAsset = function (asset) {
        if (!$scope.isSnapshot && asset) {
          DialogService.confirm('label.clients.assets.removeConfirmationClient', function () {
            HttpService.delete('/api/clients/' + asset.ClientId + '/clientassets/' + asset.id)
              .then(function () {
                _.remove($scope.assets, { id: asset.id });
                $scope.assetFilter.assetTypes = $scope.getAssetTypesFromAssets($scope.assets);
              })
              .catch(function (error) {
                DialogService.error(error);
              });
          });
        }
      };

      $scope.hasSearchedNameOrSerial = function (asset) {
        if (!$scope.assetFilter.searchedValue) {
          return true;
        }

        var searched = $scope.assetFilter.searchedValue.toLowerCase();
        var isNameMatch = asset.name.toLowerCase().includes(searched);
        var isSerialMatch = asset.serialNumber
          ? asset.serialNumber.toLowerCase().includes(searched)
          : false;

        return isNameMatch || isSerialMatch;
      };

      $scope.hasSearchedAssetType = function (asset) {
        if (!$scope.assetFilter.selectedTypes.length) {
          return true;
        }

        const assetIndex = $scope.assetFilter.selectedTypes.findIndex(function (type) {
          return asset.Type.typeName === type.name;
        });
        return assetIndex !== -1;
      };

      $scope.isAssetInDateRange = function (asset) {
        if (!$scope.assetFilter.warrantyExpireEnd) {
          return true;
        }

        if (!asset.warrantyExpire) {
          return false;
        }

        var warrantyExpire = $moment(asset.warrantyExpire);
        var expireEnd = $moment($scope.assetFilter.warrantyExpireEnd);

        return warrantyExpire.isSame(expireEnd) || warrantyExpire.isBefore(expireEnd);
      };

      $scope.isAssetInProjectLinkTypes = function (asset) {
        if ($scope.assetFilter.selectedProjectLinkType.name === ProjectLinkTypes.PROJECT_LINKS) {
          return true;
        }
        if ($scope.assetFilter.selectedProjectLinkType.name === ProjectLinkTypes.NONE) {
          return !asset.ClientProjects.length;
        }
        if ($scope.assetFilter.selectedProjectLinkType.name === ProjectLinkTypes.ONE_OR_MORE) {
          return asset.ClientProjects.length;
        }
      };

      $scope.filterAssets = function (asset) {
        return (
          $scope.hasSearchedNameOrSerial(asset) &&
          $scope.hasSearchedAssetType(asset) &&
          $scope.isAssetInDateRange(asset) &&
          $scope.isAssetInProjectLinkTypes(asset)
        );
      };

      $scope.selectAll = function () {
        // Always clear list to prevent duplications
        $scope.assignedAssetsToProject = [];

        var filteredAssets = $scope.assets.filter($scope.filterAssets);
        filteredAssets.forEach(function (asset) {
          if ($scope.assetFilter.isAllSelected) {
            asset.isChecked = true;
            $scope.assignedAssetsToProject.push(asset);
          } else {
            asset.isChecked = false;
          }
        });
      };
    },
  )

  .controller(
    'ClientProposalsController',
    function (
      $modal,
      $scope,
      CurrentUser,
      HttpService,
      DialogService,
      $moment,
      $rootScope,
      $stateParams,
      $translate,
      $window,
      _,
      ClientProjectsService,
      CompanyService,
      DialogService,
      MeetingService,
      Utils,
      proposals,
      client,
      clientProjects,
    ) {
      $scope.client = client;
      $scope.proposals = proposals;
      $scope.currentUser = $scope.currentUser || CurrentUser.getUser();
      $scope.filter = {};
      $scope.newProposal = { expanded: false };
      $scope.clientProjects = clientProjects;
      $scope.isEdit = false;

      $scope.$on('reloadProposalData', function () {
        $scope.loadProposalData();
      });

      $scope.loadProposalData = function () {
        if (CurrentUser.isAuthenticated()) {
          HttpService.get('/api/clients/' + $scope.client.id + '/clientproposals').then(function (
            proposals,
          ) {
            $scope.proposals = proposals;
          });
        }
      };

      $scope.hasAccess = function (status, action) {
        var accessMap = {
          draft: ['edit', 'share', 'remove'],
          pending: ['share', 'edit', 'remove'],
          approved: ['share'],
          rejected: ['share', 'remove'],
        };
        return accessMap[status].includes(action);
      };

      $scope.openEditProposal = function (proposal) {
        $scope.proposal.isEdit = true;
      };

      $scope.getStatusIcon = function (status) {
        var statusIconMap = {
          draft: { class: 'fa fa-edit fa-2x', style: { color: 'black' } },
          pending: { class: 'fa fa-clock-o fa-2x', style: { color: '#F7BE08' } },
          approved: { class: 'fa fa-check-square-o fa-2x', style: { color: '#59BF90' } },
          rejected: { class: 'fa fa-times-circle-o fa-2x', style: { color: '#F6303D' } },
        };
        return statusIconMap[status];
      };

      $scope.nameFilter = function (item) {
        return (
          !$scope.filter.name ||
          (item.name && item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1)
        );
      };

      $scope.proposalsOrder = function (proposal) {
        return $moment(proposal.updatedAt).diff($moment(), 'hours');
      };

      $scope.removeProposal = function (proposal) {
        if (!$scope.isSnapshot && proposal) {
          DialogService.confirm('label.clients.proposal.removeConfirmationClient', function () {
            HttpService.delete(
              '/api/clients/' + proposal.ClientId + '/clientproposals/' + proposal.id,
            )
              .then(function () {
                $rootScope.$broadcast('reloadProposalData');
              })
              .catch(function (error) {
                DialogService.error(error);
              });
          });
        }
      };

      $scope.openShareModal = function (proposal) {
        $modal.open({
          template:
            '<modal-form oncancel="close" label-cancel="button.close" form-title="{{title}}">' +
            '<p class="alert alert-info" translate="label.client.share.hint"></p>' +
            '<div class="abm-form"><div class="input-group">' +
            '<input type="text" ng-model="url" class="form-control padding-1x-left" readonly>' +
            '<span class="input-group-addon" translate-title="button.copyToClipboard"><i class="fa fa-copy" ng-click="copyToken()"></i></span>' +
            '<span class="input-group-addon no-padding-left" ng-if="generateNewUrl" translate-title="label.meeting.share.generateNewUrl"><i class="fa fa-refresh" ng-click="generateNewUrl()"></i></span>' +
            '<span class="input-group-addon no-padding-left" translate-title="button.openInNewTab"><a href="{{url}}" target="_blank" style="color: inherit"><i class="fa fa-external-link"></i></a></span>' +
            '</div></div>' +
            '</modal-form>',
          backdrop: 'static',
          size: 'lg',
          controller: function ($scope, $modalInstance, Utils, url, title, currency) {
            $scope.url = url;
            $scope.title = title;
            $scope.currency = currency;

            $scope.copyToken = function () {
              Utils.copyToClipboard($scope.url);
              MeetingService.showToastMessage(
                'success',
                $translate.instant('meetingWidget.linkCopied'),
              );
            };

            $scope.generateNewUrl = function () {
              HttpService.post(
                '/api/clients/' + proposal.ClientId + '/clientproposals/' + proposal.id + '/share',
              )
                .then(function (sharedProposal) {
                  $scope.url =
                    window.location.origin +
                    '/shareproposal?token=' +
                    sharedProposal.token +
                    '&currency=' +
                    $scope.currency;
                  MeetingService.showToastMessage(
                    'success',
                    $translate.instant('label.meeting.share.generateNewUrl.success'),
                  );
                })
                .catch(function (error) {
                  Utils.showToastMessage(error.code, { className: 'danger' });
                  $modalInstance.close();
                });
            };

            $scope.close = function () {
              $modalInstance.close();
            };
          },
          resolve: {
            url: function () {
              return (
                window.location.origin +
                '/shareproposal?token=' +
                proposal.token +
                '&currency=' +
                $scope.currentUser.Company.currency
              );
            },
            title: function () {
              return $translate.instant('label.client.share.title', {
                proposalName: proposal.name,
              });
            },
            currency: function () {
              return $scope.currentUser.Company.currency;
            },
          },
        });
      };

      $scope.shareProposal = function (proposal) {
        if (!proposal.token) {
          HttpService.post(
            '/api/clients/' + proposal.ClientId + '/clientproposals/' + proposal.id + '/share',
          )
            .then(function (sharedProposal) {
              $scope.openShareModal(sharedProposal);
            })
            .catch(function (error) {
              DialogService.error(error);
            });
        } else {
          $scope.openShareModal(proposal);
        }
      };

      $scope.getSharedProposalUrl = function (token) {
        return (
          window.location.origin +
          '/shareproposal?token=' +
          token +
          '&currency=' +
          $scope.currentUser.Company.currency
        );
      };
    },
  )

  .controller(
    'ClientProposalEditController',
    function (
      $modal,
      $scope,
      CurrentUser,
      HttpService,
      DialogService,
      $moment,
      $rootScope,
      $stateParams,
      $translate,
      $window,
      _,
      ClientProjectsService,
      CompanyService,
      DialogService,
      MeetingService,
      Utils,
      ProjectPriceService,
    ) {
      $scope.state = {};
      $scope.selected = {};
      $scope.currency = CurrentUser.getUser().Company.currency;
      $scope.proposal = $scope.proposal || {};
      $scope.proposal.note = $scope.proposal.note || '';

      $scope.sumPrice = 0;
      $scope.discount = Math.round(Number($scope.proposal.discount) || 0);
      $scope.proposal.discount = Math.round(Number($scope.discount));
      $scope.state.legalIsOpen = false;
      $scope.proposalProjects = [];
      $scope.addibleProjects = $scope.clientProjects;

      $scope.$on('reloadProposalData', function () {
        if ($scope.$parent.loadProposalData) {
          $scope.$parent.loadProposalData();
        }
      });

      $scope.proposalProjectMap = $scope.proposals.map(function (proposal) {
        return {
          proposalId: proposal.id,
          proposalName: proposal.name,
          projectIds: proposal.ClientProjects.map(function (project) {
            return project.id;
          }),
        };
      });

      $scope.checkForPreviousAttaches = function (projectId) {
        var previousAttaches = $scope.proposalProjectMap.filter(function (item) {
          return item.projectIds.includes(projectId);
        });
        return previousAttaches.reduce(function (acc, attachItem) {
          return acc.toString() + '<li> ' + attachItem.proposalName + ' </li>';
        }, []);
      };

      $scope.showDialog = function (project, proposalsString) {
        DialogService.confirm(
          $translate.instant('label.clients.proposal.projectAttachConfirm', {
            projectName: project.name,
            proposalList: proposalsString,
          }),
          'label.clients.proposal.projectAttachConfirm.title',
          function () {
            attachToProposal(project);
          },
          function () {
            $scope.selected.project = undefined;
          },
        );
      };

      $scope.getProjectCost = function (project) {
        return project.prices
          ? Object.values(ProjectPriceService.formatClientProjectPrices(project.prices)).reduce(
              function (acc, price) {
                return Number(acc) + Number(price);
              },
              0,
            )
          : 0;
      };

      if ($scope.proposal.id) {
        $scope.proposalProjectIds = $scope.proposal.ClientProjects.map(function (project) {
          return project.id;
        });
        $scope.proposalProjectIds.forEach(function (id) {
          $scope.proposalProjects.push(
            $scope.clientProjects.filter(function (project) {
              return project.id === id;
            })[0],
          );
        });
        $scope.addibleProjects = $scope.clientProjects.filter(function (project) {
          return !$scope.proposalProjectIds.includes(project.id);
        });
        $scope.proposalProjects.forEach(function (project) {
          $scope.sumPrice = $scope.sumPrice + Number($scope.getProjectCost(project));
        });

        // Save original values for the Cancel event
        $scope.originalProposal = angular.copy($scope.proposal);
        $scope.originalProposalProjects = angular.copy($scope.proposalProjects);
        $scope.originalAddibleProjects = angular.copy($scope.addibleProjects);
        $scope.originalSumPrice = angular.copy($scope.sumPrice);
      }

      $scope.state.isProjectSelectValid = !!$scope.proposalProjects.length;

      $scope.handleProposalAttach = function (project) {
        var previouslyAttachedProposals = $scope.checkForPreviousAttaches(project.id);
        previouslyAttachedProposals.length
          ? $scope.showDialog(project, previouslyAttachedProposals)
          : attachToProposal(project);
      };

      var attachToProposal = function (project) {
        $scope.proposalProjects.push(project);
        $scope.addibleProjects = $scope.addibleProjects.filter(function (addibleProject) {
          return addibleProject.id !== project.id;
        });
        var projectCost = $scope.getProjectCost(project);
        $scope.sumPrice = Number($scope.sumPrice) + Number(projectCost);
        $scope.proposal.proposalPrice = $scope.sumPrice;

        Utils.showToastMessage(
          {
            code: 'label.clients.proposal.projectAttached',
            translateValues: { projectName: project.name },
          },
          { className: 'success' },
        );

        $scope.selected.project = undefined;
        $scope.state.isProjectSelectValid = !!$scope.proposalProjects.length;
      };

      $scope.removeProposalProject = function (project) {
        $scope.proposalProjects = $scope.proposalProjects.filter(function (proposalProject) {
          return proposalProject.id !== project.id;
        });
        $scope.addibleProjects.push(project);
        $scope.sumPrice = Number($scope.sumPrice) - Number($scope.getProjectCost(project));
        $scope.state.isProjectSelectValid = !!$scope.proposalProjects.length;
        $scope.proposal.proposalPrice = $scope.sumPrice;
      };

      $scope.save = function (isShare) {
        $rootScope.$broadcast('hideBox');
        if (!$scope.proposal.id) {
          $scope.proposal.status = 'draft';
        }

        $scope.proposal.ClientProjects = $scope.proposalProjects.map(function (project) {
          return { id: project.id, status: project.status };
        });

        $scope.proposal.discount = Math.round(Number($scope.proposal.discount));

        HttpService[$scope.proposal.id ? 'put' : 'post'](
          '/api/clients/' + $scope.client.id + '/clientproposals',
          $scope.proposal,
        )
          .then(function (result) {
            $rootScope.$broadcast('reloadProposalData');
            $scope.newProposal.expanded = false;
            $scope.proposal.isEdit = false;

            if (isShare) {
              $scope.shareProposal(result);
            } else {
              $scope.generateNewUrl(result, true);
            }
            $scope.closeModal();
          })
          .catch(function (error) {
            $scope.errors = [error];
          });
      };

      $scope.closeModal = function () {
        if ($scope.proposalId) {
          Object.assign($scope.proposal, $scope.originalProposal);
          Object.assign($scope.proposalProjects, $scope.originalProposalProjects);
          Object.assign($scope.addibleProjects, $scope.originalAddibleProjects);

          $scope.state.legalIsOpen = false;
          $scope.proposal.note = $scope.proposal.note || '';
          $scope.sumPrice = $scope.originalSumPrice;
          return ($scope.proposal.isEdit = false);
        }

        $scope.proposal = {};
        $scope.proposalProjects.forEach(function (project) {
          $scope.addibleProjects.push(project);
        });
        $scope.proposalProjects = [];
        $scope.sumPrice = 0;
        $scope.state.legalIsOpen = false;
        $scope.proposal.note = '';
        $scope.state.isProjectSelectValid = !!$scope.proposalProjects.length;

        return ($scope.newProposal.expanded = false);
      };

      $scope.getPriceStyle = function (proposal) {
        if (proposal.proposalPrice && $scope.sumPrice !== proposal.proposalPrice) {
          if ($scope.sumPrice > proposal.proposalPrice) {
            return { color: 'red' };
          }
          return { 'background-color': 'lemonchiffon' };
        }
        return { color: 'black' };
      };

      $scope.editClientProject = function (clientProject) {
        if (clientProject) {
          openClientProjectEdit(clientProject).result.then(function (result) {
            _.extend(clientProject, _.pick(result, ['name', 'description', 'deliverable']));
          });
        }
      };

      var openClientProjectEdit = function (clientProject) {
        return $modal.open({
          templateUrl: '/templates/client/client-project-edit.html',
          backdrop: 'static',
          size: 'lg',
          controller: function (
            $scope,
            $modalInstance,
            HttpService,
            clientProject,
            projectTemplates,
          ) {
            $scope.projectTemplates = projectTemplates;
            $scope.clientProject = clientProject || {};
            $scope.clientProject.deliverable = $scope.clientProject.deliverable || {};

            $scope.ok = function (cb) {
              HttpService[$scope.clientProject.id ? 'put' : 'post'](
                '/api/clients/' + $scope.clientProject.ClientId + '/projects',
                $scope.clientProject,
              )
                .then(function (result) {
                  $modalInstance.close(result);
                })
                .catch(function (error) {
                  cb();
                  $scope.errors = [error];
                });
            };

            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
          resolve: {
            clientProject: function () {
              return _.cloneDeep(clientProject);
            },
            projectTemplates: function (HttpService) {
              return HttpService.get('/api/service/services/templates?type=onetime');
            },
          },
        });
      };

      $scope.generateNewUrl = function (result, isSimpleSave) {
        const source = isSimpleSave ? result : proposal;
        HttpService.post(
          '/api/clients/' + source.ClientId + '/clientproposals/' + source.id + '/share',
        )
          .then(function (sharedProposal) {
            $scope.url =
              window.location.origin +
              '/shareproposal?token=' +
              sharedProposal.token +
              '&currency=' +
              $scope.currency;

            if (!isSimpleSave) {
              MeetingService.showToastMessage(
                'success',
                $translate.instant('label.meeting.share.generateNewUrl.success'),
              );
            }

            if ($scope.$parent.setShareUrl) {
              $scope.$parent.setShareUrl($scope.url, true);
            }
          })
          .catch(function (error) {
            DialogService.error(error);
          });
      };

      $scope.openShareModal = function (proposal) {
        $modal.open({
          template:
            '<modal-form oncancel="close" label-cancel="button.close" form-title="{{title}}">' +
            '<p class="alert alert-info" translate="label.client.share.hint"></p>' +
            '<div class="abm-form"><div class="input-group">' +
            '<input type="text" ng-model="url" class="form-control padding-1x-left" readonly>' +
            '<span class="input-group-addon" translate-title="button.copyToClipboard"><i class="fa fa-copy" ng-click="copyToken()"></i></span>' +
            '<span class="input-group-addon no-padding-left" ng-if="generateNewUrl" translate-title="label.meeting.share.generateNewUrl"><i class="fa fa-refresh" ng-click="generateNewUrl()"></i></span>' +
            '<span class="input-group-addon no-padding-left" translate-title="button.openInNewTab"><a href="{{url}}" target="_blank" style="color: inherit"><i class="fa fa-external-link"></i></a></span>' +
            '</div></div>' +
            '</modal-form>',
          backdrop: 'static',
          size: 'lg',
          controller: function ($scope, $modalInstance, Utils, url, title, currency, urlGenerator) {
            $scope.url = url;
            $scope.title = title;
            $scope.currency = currency;
            $scope.urlGenerator = urlGenerator;

            $scope.copyToken = function () {
              Utils.copyToClipboard($scope.url);
              MeetingService.showToastMessage(
                'success',
                $translate.instant('meetingWidget.linkCopied'),
              );
            };
            $scope.generateNewUrl = $scope.urlGenerator;

            $scope.close = function () {
              $modalInstance.close();
            };
          },
          resolve: {
            url: function () {
              return (
                window.location.origin +
                '/shareproposal?token=' +
                proposal.token +
                '&currency=' +
                $scope.currency
              );
            },
            title: function () {
              return $translate.instant('label.client.share.title', {
                proposalName: proposal.name,
              });
            },
            currency: function () {
              return $scope.currency;
            },
            urlGenerator: function () {
              return $scope.generateNewUrl;
            },
          },
        });
      };

      $scope.shareProposal = function (proposal) {
        if (!proposal.token) {
          HttpService.post(
            '/api/clients/' + proposal.ClientId + '/clientproposals/' + proposal.id + '/share',
          )
            .then(function (sharedProposal) {
              $scope.openShareModal(sharedProposal);
            })
            .catch(function (error) {
              DialogService.error(error);
            });
        } else {
          $scope.openShareModal(proposal);
        }
      };
    },
  )

  .controller(
    'ClientDeliveryController',
    function (
      $modal,
      $q,
      $rootScope,
      $scope,
      $stateParams,
      $translate,
      $window,
      _,
      CurrentUser,
      DialogService,
      HttpService,
      IntegrationService,
      UserEventService,
      client,
      clientProjects,
      InputSanitizerService,
    ) {
      $scope.client = client;
      $scope.clientProjects = clientProjects;
      $scope.projects = []; //projects;
      $scope.system = $stateParams.system;
      $scope.systemName = $translate.instant('label.clients.delivery.' + $scope.system);

      $scope.filter = {};
      $scope.newProject = { expanded: false };
      $scope.linkProject = { expanded: false };
      $scope.billingMethods = _.map(
        ['ActualRates', 'FixedFee', 'NotToExceed', 'OverrideRate'],
        function (item) {
          return { id: item, name: item };
        },
      );
      $scope.boards = [];
      $scope.hasModule = CurrentUser.hasModule;
      const integrations = $scope.client.externalIds
        ? _.keys(_.pickBy($scope.client.externalIds, _.negate(_.isNull)))
        : null;

      $scope.isLinkedTo = _.isEmpty(integrations) ? null : integrations[0];
      $scope.isLinkedToDifferentIntegration = $scope.isLinkedTo
        ? $scope.system !== $scope.isLinkedTo
        : false;

      $scope.isLinked = function () {
        return $scope.system === 'basecamp' || $scope.client.externalIds[$scope.system];
      };

      $scope.$watch('newProject.expanded', function (newValue) {
        if (newValue && _.isEmpty($scope.boards) && $scope.system === 'connectwise') {
          HttpService.get('/api/vcio/connectwise/boards/project').then(function (boards) {
            $scope.boards = boards;
          });
        }
      });

      $scope.nameFilter = function (item) {
        return (
          !$scope.filter.name ||
          item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1
        );
      };

      $scope.refresh = function () {
        if ($scope.isLinked()) {
          $scope.client.externalClient = {};

          if ($scope.system === 'halo') {
            if ($scope.client.externalIds[$scope.system]) {
              IntegrationService.get(
                '/companies/' +
                  InputSanitizerService.sanitize($scope.client.externalIds[$scope.system]),
              )
                .then(
                  function (result) {
                    $scope.client.externalClient = result;
                  },
                  function (error) {
                    if (error) {
                      if (error.status === 404) {
                        delete $scope.client.externalClient;
                      } else {
                        console.error(error);
                        DialogService.error(error);
                      }
                    }
                  },
                )
                .catch(function (error) {
                  DialogService.error(error);
                });
            }
          } else {
            $q.all([
              IntegrationService.get(
                '/clients/' + InputSanitizerService.sanitize($scope.client.id) + '/projects',
              ),
              IntegrationService.get(
                '/clients/' + InputSanitizerService.sanitize($scope.client.id) + '/opportunities',
              ),
            ])
              .then(function (result) {
                $scope.projects = _(result[0])
                  .map(function (project) {
                    return _.extend(project, {
                      $$type: 'project',
                      clientProject: _.find($scope.clientProjects, function (clientProject) {
                        return (
                          _.get(clientProject.integrations, 'connectwise.projectId') === project.id
                        );
                      }),
                    });
                  })
                  .union(
                    _.map(result[1], function (opportunity) {
                      return _.extend(opportunity, {
                        $$type: 'opportunity',
                        clientProject: _.find($scope.clientProjects, function (clientProject) {
                          return (
                            _.get(clientProject.integrations, 'connectwise.opportunityId') ===
                            opportunity.id
                          );
                        }),
                      });
                    }),
                  )
                  .value();

                if ($scope.client.externalIds[$scope.system]) {
                  IntegrationService.get(
                    '/companies/' +
                      InputSanitizerService.sanitize($scope.client.externalIds[$scope.system]),
                  ).then(
                    function (result) {
                      $scope.client.externalClient = result;
                    },
                    function (error) {
                      if (error) {
                        if (error.status === 404) {
                          delete $scope.client.externalClient;
                        } else {
                          console.error(error);
                          DialogService.error(error);
                        }
                      }
                    },
                  );
                }
                _.forEach($scope.projects, function (project) {
                  project.admins = _.map(
                    _.filter(project.accesses, {
                      is_client: false,
                      admin: false,
                    }),
                    function (v) {
                      return { name: v.name, email: v.email_address };
                    },
                  );
                  project.clients = _.map(
                    _.filter(project.accesses, {
                      is_client: true,
                      admin: false,
                    }),
                    function (v) {
                      return { name: v.name, email: v.email_address };
                    },
                  );
                  var value = _.round(
                    ((project.actualHours || 0) / (project.estimatedHours || 1)) * 100,
                  );
                  project.chart = {
                    data: {
                      datasets: [
                        {
                          data: [value, 100 - value],
                          backgroundColor: ['#59BF90', '#efefef'],
                          hoverBackgroundColor: ['#59BF90', '#efefef'],
                        },
                      ],
                    },
                    options: {
                      width: 120,
                      height: 120,
                      responsive: true,
                      animation: false,
                      tooltips: false,
                      legend: false,
                      cutoutPercentage: 85,
                    },
                  };
                });
              })
              .catch(function (error) {
                DialogService.error(error);
              });
          }
        }
      };

      $scope.refresh();

      $scope.save = function () {
        if ($scope.isLinked()) {
          IntegrationService.post(
            '/clients/' + InputSanitizerService.sanitize($scope.client.id) + '/projects',
            {
              project: _.extend($scope.newProject, { clientId: $scope.client.id }),
            },
          )
            .then(function () {
              $scope.newProject = { expanded: false };
              $scope.refresh();
            })
            .catch(function (error) {
              DialogService.error(error);
            });
        } else {
          DialogService.error('errors.externalClientNotConnected');
        }
      };

      $scope.cancel = function () {
        $scope.newProject = { expanded: false };
      };

      $scope.sendProject = function (project) {
        var modalInstance = $modal.open({
          templateUrl: '/templates/client/send-to-project.html',
          controller: 'ClientSendToProjectController',
          backdrop: 'static',
          //size: 'lg',
          resolve: {
            clientId: function () {
              return $scope.client.id;
            },
            project: function () {
              return project;
            },
            system: function () {
              return $scope.system;
            },
            contracts: function (HttpService) {
              return HttpService.get('/api/vcio/contracts?clientId=' + client.id);
            },
            services: function (HttpService) {
              return HttpService.get('/api/service/services');
            },
          },
        });

        modalInstance.result.then(
          function () {},
          function () {},
        );
      };

      $scope.importProject = function (externalProject) {
        var modalInstance = $modal.open({
          templateUrl: '/templates/client/client-project-edit.html',
          controller: function ($modalInstance, $sce, $scope, academyProjects, client) {
            $scope.externalProject = externalProject;
            $scope.academyProjects = academyProjects;
            $scope.client = client;
            const integrations = { connectwise: {} };
            integrations.connectwise[externalProject.$$type + 'Id'] = externalProject.id;
            $scope.clientProject = {
              name: externalProject.name,
              description: externalProject.description,
              status: externalProject.mspStatus,
              deliverable: {},
              integrations: integrations,
            };

            $scope.serviceSelected = function (selectedService) {
              if (selectedService) {
                $scope.clientProject.description = selectedService.description;
                $scope.clientProject.deliverable = selectedService.deliverable;
              } else {
                $scope.clientProject.description = '';
                $scope.clientProject.deliverable = '';
              }
              $scope.clientProject.$$description = $sce.trustAsHtml(
                $scope.clientProject.description,
              );
              $scope.clientProject.deliverable.$$description = $sce.trustAsHtml(
                $scope.clientProject.deliverable.description,
              );
            };

            $scope.ok = function (cb) {
              HttpService.post(
                '/api/clients/' + $scope.client.id + '/projects',
                $scope.clientProject,
              )
                .then(function (result) {
                  cb();
                  $scope.externalProject.clientProject = result;
                  $modalInstance.close(result);
                  UserEventService.event('clientDeliveryProjectImported', {
                    system: $scope.system,
                  });
                })
                .catch(function (error) {
                  cb();
                  $scope.errors = [error];
                });
            };

            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
          backdrop: 'static',
          //size: 'lg',
          resolve: {
            academyProjects: function () {
              return HttpService.get('/api/service/services?type=onetime');
            },
            client: function () {
              return $scope.client;
            },
          },
        });
      };

      $scope.linkProject = function (externalProject) {
        var modalInstance = $modal.open({
          template:
            '<modal-form onok="ok" oncancel="cancel" form-title="label.clients.projects.import.linkProject">' +
            '<div class="abm-form">' +
            '<abm-form-group><abm-ui-select model="selected.clientProject" required="true" placeholder="label.clients.projects.import.selectService" values="clientProjects"></abm-ui-select></abm-form-group>' +
            '</div>' +
            '</modal-form>',
          controller: function ($modalInstance, $scope, client, clientProjects) {
            $scope.externalProject = externalProject;
            $scope.clientProjects = _.filter(clientProjects, function (project) {
              return (
                !_.get(project, 'integrations.connectwise.projectId') &&
                !_.get(project, 'integrations.connectwise.opportunityId')
              );
            });
            $scope.client = client;
            const integrations = { connectwise: {} };
            integrations.connectwise[externalProject.$$type + 'Id'] = externalProject.id;
            $scope.selected = {};

            $scope.ok = function (cb) {
              $scope.selected.clientProject.integrations = integrations;
              HttpService.put(
                '/api/clients/' + $scope.client.id + '/projects',
                $scope.selected.clientProject,
              )
                .then(function (result) {
                  cb();
                  $scope.externalProject.clientProject = result;
                  $modalInstance.close(result);
                  UserEventService.event('clientDeliveryProjectLinked', { system: $scope.system });
                })
                .catch(function (error) {
                  cb();
                  $scope.errors = [error];
                });
            };

            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
          backdrop: 'static',
          resolve: {
            clientProjects: function () {
              return $scope.clientProjects;
            },
            client: function () {
              return $scope.client;
            },
          },
        });
      };

      $scope.removeExternalClientLink = function () {
        if ($scope.isLinked()) {
          delete $scope.client.externalIds[$scope.system];
          IntegrationService.delete('/companies/undefined/link/' + $scope.client.id).then(
            function () {
              delete $scope.client.externalClient;
              $scope.projects = [];
              // $scope.refresh();
            },
          );
          window.location.reload();
        }
      };

      $scope.linkExternalClient = function () {
        var modalInstance = $modal.open({
          templateUrl: '/templates/vcio/external-client-select.html',
          controller: function ($scope, $modalInstance, model, IntegrationService, system) {
            $scope.model = model;
            $scope.system = system;
            $scope.systemName = $translate.instant('label.clients.delivery.' + $scope.system);
            $scope.values = [];
            $scope.filter = {};
            $scope.ok = function (cb) {
              if ($scope.selected) {
                $modalInstance.close($scope.selected);
              } else {
                cb();
              }
            };

            $scope.cancel = function () {
              $modalInstance.dismiss();
            };

            $scope.select = function (item) {
              if ($scope.selected == item) {
                $scope.selected = undefined;
              } else {
                $scope.selected = item;
              }
            };

            $scope.nameFilter = function (item) {
              return (
                !$scope.filter.name ||
                item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1
              );
            };

            $scope.search = function () {
              $scope.errors = [];
              $scope.messages = [];
              $scope.values = [];
              const name = $scope.filter.name ? $scope.filter.name : '';
              IntegrationService.get('/companies?name=' + name).then(
                function (result) {
                  $scope.values = result;
                  if (!$scope.values || $scope.values.length === 0) {
                    $scope.messages = ['message.noItems'];
                  }
                },
                function (error) {
                  if (error) {
                    $scope.errors = [error];
                    console.error(error);
                  } else {
                    $modalInstance.dismiss();
                  }
                },
              );
            };
          },
          backdrop: 'static',
          resolve: {
            model: function () {
              return $scope.client.externalIds[$scope.system];
            },
            system: function () {
              return $scope.system;
            },
          },
        });
        modalInstance.result.then(
          function (result) {
            $scope.client.externalIds[$scope.system] = result ? result.id : undefined;
            IntegrationService.put(
              '/companies/' + (result ? result.id : undefined) + '/link/' + $scope.client.id,
            ).then(function () {
              $scope.refresh();
              UserEventService.event('clientDeliveryClientLinked', { system: $scope.system });
            });
          },
          function () {},
        );
      };

      UserEventService.event('clientDeliveryOpened', { system: $scope.system });
    },
  )
  .controller(
    'ClientSendToProjectController',
    function (
      $scope,
      $rootScope,
      $stateParams,
      $http,
      $modalInstance,
      $translate,
      _,
      HttpService,
      VcioService,
      clientId,
      project,
      system,
      contracts,
      services,
      InputSanitizerService,
    ) {
      $scope.project = project;
      $scope.system = system;
      $scope.systemName = $translate.instant('label.clients.delivery.' + $scope.system);
      $scope.types = [
        { id: 'contract', name: 'Contract' },
        { id: 'service', name: 'Service' },
      ];
      $scope.serviceCycles = _.map(
        ['na', 'annual', 'quarterly', 'monthly', 'weekly'],
        function (item) {
          return {
            id: item,
            name: $translate.instant('label.academy.serviceCycle.' + item),
          };
        },
      );
      $scope.contracts = contracts;
      $scope.services = services;

      $scope.selected = {};

      VcioService.setSystem(system);

      $scope.ok = function (cb) {
        if ($scope.selected.type) {
          $rootScope.$broadcast('dataLoadingStarted');
          if ($scope.selected.type === 'contract' && $scope.selected.contract) {
            VcioService.post(
              '/clients/' +
                InputSanitizerService.sanitize(clientId) +
                '/projects/' +
                InputSanitizerService.sanitize(project.id) +
                '/contract/' +
                InputSanitizerService.sanitize($scope.selected.contract.id),
              {
                cycles: $scope.selected.cycles,
              },
            )
              .then(function (result) {
                $scope.messages = [
                  {
                    code: 'message.vcio.deliveryItemsExported',
                    translateValues: { url: result },
                  },
                ];
                $scope.ok = undefined;
                $rootScope.$broadcast('dataLoadingFinished');
                cb();
              })
              .catch(function (error) {
                $scope.errors = [error];
                $rootScope.$broadcast('dataLoadingFinished');
                cb();
              });
          } else if ($scope.selected.type === 'service' && $scope.selected.service) {
            VcioService.post(
              '/clients/' +
                InputSanitizerService.sanitize(clientId) +
                '/projects/' +
                InputSanitizerService.sanitize(project.id) +
                '/service/' +
                InputSanitizerService.sanitize($scope.selected.service.id),
              {
                cycles: $scope.selected.cycles,
              },
            )
              .then(function (result) {
                $scope.messages = [
                  {
                    code: 'message.vcio.deliveryItemsExported',
                    translateValues: { url: result },
                  },
                ];
                $scope.ok = undefined;
                $rootScope.$broadcast('dataLoadingFinished');
                cb();
              })
              .catch(function (error) {
                $scope.errors = [error];
                $rootScope.$broadcast('dataLoadingFinished');
                cb();
              });
          }
        } else {
          cb();
        }
      };

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };
    },
  )

  .controller(
    'ClientContractsController',
    function (
      $modal,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $translate,
      $window,
      _,
      CurrentUser,
      DialogService,
      HttpService,
      UserEventService,
      VcioService,
      client,
      /*clientSegments, */ contracts,
      serviceBundles,
      InputSanitizerService,
    ) {
      $scope.client = client;
      // $scope.clientSegments = clientSegments;
      $scope.currentUser = $scope.currentUser || CurrentUser.getUser();
      $scope.hasModule = CurrentUser.hasModule;
      $scope.contracts = _.map(contracts, function (contract) {
        VcioService.calculate(contract);
        return contract;
      });
      $scope.serviceBundles = serviceBundles;
      $scope.contractTypes = _.map(['ongoing', 'onetime'], function (item) {
        return { id: item, name: $translate.instant('label.vcio.contractType.' + item) };
      });

      $scope.filter = {};
      $scope.newContract = { expanded: false };

      $scope.openContractToProject = function (contract, system) {
        VcioService.setSystem(system);
        VcioService.get(
          '/clients/' + InputSanitizerService.sanitize($scope.client.id) + '/projects',
        )
          .then(function (projects) {
            $scope.client.Projects = projects;
            $rootScope.$broadcast('dataLoadingFinished');
            var modalInstance = $modal.open({
              templateUrl: '/templates/vcio/contract-to-project.html',
              controller: 'VcioContractToProjectController',
              backdrop: 'static',
              resolve: {
                contract: function () {
                  return angular.copy(contract);
                },
                client: function () {
                  return $scope.client;
                },
              },
            });

            modalInstance.result.then(
              function () {},
              function () {},
            );
          })
          .catch(function () {
            $rootScope.$broadcast('dataLoadingFinished');
          });
      };
      $scope.gotoPayment = function () {
        $state.go('msp.payment');
      };

      $scope.save = function (newContract, cb) {
        var req;
        if (newContract.id) {
          req = HttpService.put('/api/vcio/contracts', { contract: newContract });
        } else {
          if (!_.isArray(newContract.serviceBundles)) {
            newContract.serviceBundles = [newContract.serviceBundles];
          }
          newContract.Services = {};
          newContract.ServiceBundles = [];
          newContract.comment = '';
          newContract.defaults = '';
          if (newContract.type === 'ongoing' && newContract.serviceBundles) {
            newContract.comment = newContract.serviceBundles[0].comment;
            newContract.defaults = newContract.serviceBundles[0].defaults;
            _.forEach(newContract.serviceBundles, function (bundle) {
              _.extend(newContract.Services, bundle.Services);
            });
            newContract.ServiceBundles = _.map(newContract.serviceBundles, 'id');
          }
          req = HttpService.post(
            '/api/vcio/contracts?clientId=' + InputSanitizerService.sanitize($scope.client.id),
            { contract: newContract },
          );
        }
        $rootScope.$broadcast('dataLoadingStarted');
        req.then(
          function (result) {
            if (newContract.id) {
              newContract.isEdit = false;
            } else {
              VcioService.calculate(result);
              $scope.contracts.push(result);
              $scope.newContract = {};
            }
            $rootScope.$broadcast('dataLoadingFinished');
            if (cb) {
              cb();
            }
          },
          function (error) {
            if (cb) {
              cb();
            }
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
            console.error(error);
          },
        );
      };

      $scope.cancel = function (newContract) {
        if (newContract.id) {
          newContract.isEdit = false;
          _.forEach(newContract.oldValues, function (v, k) {
            newContract[k] = v;
          });
        } else {
          $scope.newContract = {};
        }
      };

      $scope.nameFilter = function (item) {
        return (
          !$scope.filter.name ||
          (item.name && item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1)
        );
      };

      $scope.editContract = function (contract) {
        contract.oldValues = _.cloneDeep(contract);
        contract.isEdit = true;
      };

      $scope.removeContract = function (contract) {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.delete('/api/vcio/contracts/' + contract.id).then(function () {
          _.remove($scope.contracts, function (item) {
            return item.id === contract.id;
          });
          $rootScope.$broadcast('dataLoadingFinished');
        });
      };

      UserEventService.event('clientContractsOpened', { clientId: $scope.client.id });
    },
  )

  .controller(
    'ClientSyncProjectsController',
    function (
      $modal,
      $rootScope,
      $scope,
      $stateParams,
      $translate,
      ngToast,
      CompanyService,
      DialogService,
      HttpService,
      UserEventService,
      connectwiseCompany,
      connectwiseUsers,
      projectBoards,
      clientProjects,
      projectStatuses,
      InputSanitizerService,
    ) {
      const left = document.getElementById('table').offsetWidth / 4 - 150;
      const right = (document.getElementById('table').offsetWidth / 4) * 3 - 150;
      $scope.customTooltipStyle =
        '#table .local-hover~.popover { left: ' +
        left +
        'px !important; } #table .remote-hover~.popover { left: ' +
        right +
        'px !important; }';

      $scope.client = clientProjects.client;
      $scope.projectStatuses = projectStatuses;
      $scope.connectwise = {};

      /*
       * "recommendation": opportunity open (default)
       * "planned": opportunity open (default)
       * "approved": opportunity closed won
       * "inProgress": project open
       * "finished": project closed
       * "issue": n/a
       * "none": n/a (<- opportunity closed lost)
       */

      const initProjects = function (clientProjects) {
        $scope.clientProjects = _(clientProjects.clientProjects)
          .filter(function (p) {
            return _.includes(['planned', 'approved', 'inProgress', 'finished', 'none'], p.status);
          })
          .map(function (project) {
            if (project.connectwise) {
              project.selected =
                project.statusOrder > project.connectwise.statusOrder ? 'local' : 'remote';
            }
            return _.assign(project, {
              linkType: _.includes(['inProgress', 'finished'], project.status)
                ? 'project'
                : 'opportunity',
            });
          })
          .value();
        $scope.connectwise.projects = _.filter(
          clientProjects.connectwiseProjects,
          function (connectwiseProject) {
            return !_.find($scope.clientProjects, function (project) {
              return _.get(project, 'integrations.connectwise.projectId') === connectwiseProject.id;
            });
          },
        );
        $scope.connectwise.opportunities = _.filter(
          clientProjects.opportunities,
          function (connectwiseProject) {
            return !_.find($scope.clientProjects, function (project) {
              return (
                _.get(project, 'integrations.connectwise.opportunityId') === connectwiseProject.id
              );
            });
          },
        );
      };

      initProjects(clientProjects);

      // const initProjects = function (projects) {
      //     $scope.clientProjects = projects.clientProjects.map(function (project) {
      //         const selectList = []
      //
      //         if (project.status === 'inProgress' || project.status === 'finished') {
      //             // selectList.push({id: -2, name: $translate.instant('label.connectwise.createProject')})
      //
      //             if (project.connectwise && project.connectwise.opportunity) {
      //                 project.needsData = true
      //             }
      //         } else {
      //             // selectList.push({id: -1, name: $translate.instant('label.connectwise.createOpportunity')})
      //
      //             projects.opportunities.forEach(function (opportunity) {
      //                 selectList.push({
      //                     id: selectList.length,
      //                     name: opportunity.name,
      //                     type: 'opportunity',
      //                     status: opportunity.status,
      //                     _id: opportunity.id
      //                 })
      //             });
      //
      //             if (project.connectwise && project.connectwise.project) {
      //                 project.cantSelectLocal = true
      //             }
      //         }
      //
      //         projects.connectwiseProjects.forEach(function (project) {
      //             selectList.push({
      //                 id: selectList.length,
      //                 name: project.name,
      //                 type: 'project',
      //                 status: project.status,
      //                 _id: project.id
      //             })
      //         });
      //
      //         if (!project.needsData && project.connectwise) {
      //             project.selected = project.statusOrder > project.connectwise.statusOrder ? 'local' : 'remote'
      //         }
      //
      //         return Object.assign(project, {
      //             connectwiseProjects: selectList
      //         })
      //     })
      //
      //     for (var i = 0; i < $scope.clientProjects.length; i++) {
      //         $scope.$watch('clientProjects[' + i + ']', function (newVal, oldVal) {
      //             if (!newVal.link && oldVal.link) {
      //                 const changed = $scope.clientProjects.find(function (project) {
      //                     return project.id === oldVal.id
      //                 })
      //                 changed.remoteStatus = ''
      //
      //                 // if (oldVal.link.id !== -1 && oldVal.link.id !== -2) {
      //                 addCwToLists(oldVal.link)
      //                 // }
      //             }
      //
      //             if (newVal.link && oldVal.link && newVal.link.id !== oldVal.link.id) {
      //                 addCwToLists(oldVal.link)
      //             }
      //         }, true);
      //     }
      // }

      // CompanyService.getProjectStatuses().then(function (statuses) {
      //     $scope.projectStatuses = statuses;
      // }).then(function () {
      //     initProjects(clientProjects)
      // })
      $scope.createOpportunityModal = function (clientProject) {
        const modalInstance = $modal.open({
          templateUrl: '/templates/connectwise/create-opportunity-modal.html',
          controller: function ($scope, $modalInstance) {
            $scope.res = {
              name: clientProject.name,
            };

            $scope.members = connectwiseUsers.map(function (member) {
              return {
                id: member.id,
                name: member.firstName + (member.lastName ? ' ' + member.lastName : ''),
              };
            });
            $scope.contacts = connectwiseCompany.contacts.map(function (x, i) {
              return {
                id: i,
                _id: x.id,
                name: x.fullName,
              };
            });

            $scope.ok = function () {
              $modalInstance.close({
                name: $scope.res.name,
                primarySalesRep: $scope.res.salesRepresentative.id,
                contact: $scope.res.contact._id,
              });
            };

            $scope.cancel = function () {
              $modalInstance.close();
            };
          },
        });

        modalInstance.result.then(function (result) {
          if (result) {
            clientProject.selected = 'local';
            clientProject.remoteStatus = clientProject.status;
            clientProject.link = {
              name: result.name,
              primarySalesRep: result.primarySalesRep,
              contact: result.contact,
              type: 'opportunity',
              newOpportunity: true,
            };
          } else {
            clientProject.remoteStatus = '';
            clientProject.selected = '';
            clientProject.link = null;
          }
        });
      };

      $scope.createProjectModal = function (clientProject) {
        const modalInstance = $modal.open({
          templateUrl: '/templates/connectwise/create-project-modal.html',
          controller: function ($scope, $modalInstance) {
            $scope.res = {
              name: clientProject.name,
            };

            $scope.boards = projectBoards;

            $scope.ok = function () {
              $modalInstance.close({
                name: $scope.res.name,
                boardId: $scope.res.board.id,
                start: $scope.res.start,
                end: $scope.res.end,
              });
            };

            $scope.cancel = function () {
              $modalInstance.close();
            };
          },
        });

        modalInstance.result.then(function (result) {
          if (result) {
            clientProject.selected = 'local';

            if (
              clientProject.linkType === 'project' &&
              clientProject.connectwise &&
              clientProject.connectwise.opportunity /*clientProject.needsData*/
            ) {
              clientProject.connectwise.opportunity.start = result.start;
              clientProject.connectwise.opportunity.end = result.end;
              clientProject.connectwise.opportunity.boardId = result.boardId;
            } else {
              clientProject.remoteStatus = clientProject.status;
              clientProject.link = {
                name: result.name,
                boardId: result.boardId,
                start: result.start,
                end: result.end,
                type: 'project',
                newProject: true,
              };
            }
          } else {
            clientProject.remoteStatus = '';
            clientProject.selected = '';
            clientProject.link = null;
          }
        });
      };

      $scope.connectwiseFilter = function (connectwise) {
        return !_($scope.clientProjects).flatMap('link').flatMap('id').includes(connectwise.id);
      };

      $scope.onLinkSelected = function (clientProject, connectwiseProject, type) {
        if (connectwiseProject) {
          clientProject.remoteStatus = connectwiseProject.status;
          clientProject.selected = '';
          // _.set(clientProject, 'integrations.connectwise.' + type + 'Id', connectwiseProject.id)

          if (
            clientProject.link &&
            (clientProject.link.newProject || clientProject.link.newOpportunity)
          ) {
            clientProject.selected = 'local';
          }

          clientProject.cantSelectLocal =
            connectwiseProject.type === 'project' &&
            !(clientProject.status === 'inProgress' || clientProject.status === 'finished');
        } else {
          clientProject.remoteStatus = undefined;
        }
        // removeFromList(cw)
        // }
      };

      // const removeFromList = function (connectwise) {
      //     $scope.clientProjects.forEach(function (project) {
      //         project.connectwiseProjects = project.connectwiseProjects.filter(function (cw) {
      //             return cw._id !== connectwise._id /*|| cw.id === -1 || cw.id === -2*/
      //         })
      //     })
      // }

      // const addCwToLists = function (connectwise) {
      //     $scope.clientProjects.forEach(function (project) {
      //         project.connectwiseProjects.push(connectwise)
      //     })
      // }

      const itemSelected = function (item, data) {
        if ((!item.connectwise || !item.connectwise.statusMismatch) && !item.link) {
          return;
        }

        if (item.link && (item.link.newProject || item.link.newOpportunity)) {
          return;
        }

        item.selected = item.selected === data ? null : data;
      };

      $scope.localSelected = function (clientProject) {
        $scope.mouseenterLocal(clientProject);
        if (!clientProject.status || clientProject.cantSelectLocal) {
          return;
        }

        if (
          clientProject.linkType === 'project' &&
          clientProject.connectwise.opportunity /*item.needsData*/
        ) {
          $scope.createProjectModal(clientProject);
        }

        itemSelected(clientProject, 'local');
      };

      $scope.remoteSelected = function (clientProject) {
        $scope.mouseenterRemote(clientProject);
        itemSelected(clientProject, 'remote');
      };

      $scope.syncStatuses = function () {
        const request = {
          remoteSelected: $scope.clientProjects.filter(function (p) {
            return p.selected === 'remote' && !p.link;
          }),
          localSelected: $scope.clientProjects.filter(function (p) {
            return p.selected === 'local' && !p.link;
          }),
          linked: $scope.clientProjects.filter(function (p) {
            return p.link;
          }),
        };

        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.post(
          '/api/clients/' +
            InputSanitizerService.sanitize($stateParams.clientId) +
            '/projects/integration/sync',
          request,
        )
          .then(function () {
            return HttpService.get(
              '/api/clients/' +
                InputSanitizerService.sanitize($stateParams.clientId) +
                '/projects/integration',
            ).then(function (result) {
              initProjects(result);
              $scope.showToastMessage(
                'success',
                $translate.instant('label.connectwise.success'),
                10000,
              );
            });
          })
          .catch(function (error) {
            DialogService.error(error);
            // $scope.showToastMessage('danger', $translate.instant('label.connectwise.error') + ':<br/><ul><li>' + error.translateValues.messages.join('</li><li>') + '</li></ul>', 10000)
          })
          .finally(function () {
            $rootScope.$broadcast('dataLoadingFinished');
            UserEventService.event('clientProjectCWSynced', { clientId: $stateParams.clientId });
          });
      };

      $scope.showToastMessage = function (className, message, duration) {
        ngToast.create({
          className: className || 'success',
          content: message,
          dismissButton: true,
          timeout: duration || 10000,
        });
      };

      $scope.translateStatus = function (status) {
        const clientStatus = _.find($scope.projectStatuses, function (p) {
          return p.code === status;
        });

        if (!clientStatus) {
          return;
        }

        return clientStatus.name || $translate.instant('projectStatuses.' + status);
      };

      $scope.getRowPopover = (clientProject) => {
        if (clientProject.$$hoverRemote) {
          if (
            clientProject.link &&
            (clientProject.link.newProject || clientProject.link.newOpportunity)
          ) {
            return $translate.instant('label.connectwise.cantChangeStatus');
          } else {
            if (clientProject.selected === 'remote') {
              return $translate.instant('label.connectwise.localWillBeUpdated');
            } else {
              return $translate.instant('label.connectwise.selectToUpdateLocal');
            }
          }
        } else {
          if (clientProject.cantSelectLocal) {
            return $translate.instant('label.connectwise.cantSelectLocal');
          } else {
            if (clientProject.selected === 'local') {
              return $translate.instant('label.connectwise.remoteWillBeUpdated');
            } else {
              return $translate.instant('label.connectwise.selectToUpdateRemote');
            }
          }
        }

        // return $translate.instant(!clientProject.connectwise && !clientProject.connectwise.newProject && !clientProject.link.newOpportunity ? '' :
        //     clientProject.$$hoverRemote ?
        //         ((clientProject.link.newProject || clientProject.link.newOpportunity) ? 'label.connectwise.cantChangeStatus' :
        //             (clientProject.selected === 'remote' ? 'label.connectwise.localWillBeUpdated' : 'label.connectwise.selectToUpdateLocal'))
        //         : (clientProject.cantSelectLocal ? 'label.connectwise.cantSelectLocal' :
        //         (clientProject.selected === 'local' ? 'label.connectwise.remoteWillBeUpdated' : 'label.connectwise.selectToUpdateRemote')))
      };

      $scope.mouseenterLocal = function (item) {
        item.$$hoverLocal = true;
        item.$$hoverLocalTooltip = true;
        item.$$hoverRemote = false;
        item.$$hoverRemoteTooltip = false;
      };

      $scope.mouseenterRemote = function (item) {
        item.$$hoverRemote = true;
        item.$$hoverRemoteTooltip = true;
        item.$$hoverLocal = false;
        item.$$hoverLocalTooltip = false;
      };

      $scope.mouseleaveLocal = function (item) {
        item.$$hoverLocal = false;
        setTimeout(function () {
          item.$$hoverLocalTooltip = false;
        }, 500);
      };

      $scope.mouseleaveRemote = function (item) {
        item.$$hoverRemote = false;
        setTimeout(function () {
          item.$$hoverRemoteTooltip = false;
        }, 500);
      };

      UserEventService.event('clientProjectCWSyncOpened', { clientId: $stateParams.clientId });
    },
  );
