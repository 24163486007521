angular.module('vcio-toolkit').service('actionPlanService', function ($rootScope, $http, _) {
  var self = {};

  self.refresh = function (resultId, cb) {
    $http
      .get('/api/actionplans/' + resultId)
      .success(function (result) {
        cb(result);
      })
      .error(function (error) {
        console.error(error);
      });
  };

  self.addActionPlanItemToList = function (actionPlan, action) {
    if (action.Subproject) {
      var project = action.Subproject.project;
      if (!_.has(actionPlan.projects, project)) {
        actionPlan.projects[project] = {
          code: project,
          dimension: action.Statement.Block.dimension,
          values: [],
        };
      }
      if (!self.inActionPlan(action.SubprojectId)) {
        actionPlan.projects[project].values.push(action);
      }
    } else if (action.Deliverable) {
      var service = action.Deliverable.service;
      if (!_.has(actionPlan.services, service)) {
        actionPlan.services[service] = {
          code: service,
          dimension: action.Statement.Block.dimension,
          values: [],
        };
      }
      if (!self.inActionPlan(action.DeliverableId)) {
        actionPlan.services[service].values.push(action);
      }
    }
  };

  self.inActionPlan = function (actionPlan, id) {
    var projects = actionPlan.projects;
    for (var key in projects) {
      if (projects.hasOwnProperty(key)) {
        if (_.find(projects[key].values, { SubprojectId: id })) {
          return true;
        }
      }
    }
    var services = actionPlan.services;
    for (var key in services) {
      if (services.hasOwnProperty(key)) {
        if (_.find(services[key].values, { DeliverableId: id })) {
          return true;
        }
      }
    }
    return false;
  };

  return self;
});
