angular
  .module('vcio-toolkit')

  .service('MeetingTemplateService', function (_, CurrentUser) {
    return {
      copyTemplate: function (meetingTemplate, isTemplate) {
        if (meetingTemplate.template && !isTemplate && !meetingTemplate.TemplateId) {
          meetingTemplate.Template = _.cloneDeep(meetingTemplate);
          meetingTemplate.TemplateId = meetingTemplate.id;
        } else if (isTemplate) {
          delete meetingTemplate.TemplateId;
        }
        delete meetingTemplate.id;
        meetingTemplate.Owner = CurrentUser.getUser().Company;
        meetingTemplate.OwnerId = CurrentUser.getUser().Company.id;
        meetingTemplate.template = isTemplate;
      },
    };
  })

  .controller(
    'MeetingTemplatesController',
    function (
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $translate,
      $q,
      $modal,
      $window,
      _,
      CurrentUser,
      DialogService,
      HttpService,
      PermissionGroupModel,
      meetingTemplates,
    ) {
      $scope.filter = {
        name: '',
        all: false,
        permissionGroups: [],
        type: [
          {
            id: 'client',
            name: 'label.meeting.type.client',
            selected: true,
          },
          {
            id: 'private',
            name: 'label.meeting.type.private',
          },
        ],
      };
      if ($stateParams.permissionGroupId) {
        $scope.filter.permissionGroups = _.map(
          $stateParams.permissionGroupId.split(','),
          function (val) {
            return parseInt(val);
          },
        );
      }

      $scope.meetingTemplates = meetingTemplates;
      $scope.isTemplates = $stateParams.vendor === 'true';
      $scope.newMeetingTemplate = {};

      $scope.myPermissionGroups = [];
      PermissionGroupModel.querySubscribed().$promise.then(function (result) {
        var listPermissionGroups = _($scope.meetingTemplates)
          .flatMap(function (item) {
            return _.get(item, 'Template.PermissionGroups') || _.get(item, 'PermissionGroups', []);
          })
          .map('id')
          .uniq()
          .value();
        $scope.myPermissionGroups = _.filter(result, function (item) {
          return _.includes(listPermissionGroups, item.id);
        });
      });

      $scope.clickPermissionGroupFilter = function (permissionGroupId) {
        $state.transitionTo(
          'meeting.templates',
          {
            vendor: $stateParams.vendor,
            permissionGroupId: permissionGroupId.join(),
          },
          { notify: false },
        );
      };

      $scope.save = function (newMeetingTemplate) {
        if (newMeetingTemplate.templateId) {
          $state.go('meeting.template', {
            meetingTemplateId: newMeetingTemplate.templateId,
            vendor: false,
            copy: true,
          });
        }
      };

      $scope.cancel = function () {
        $scope.newMeetingTemplate.expanded = false;
      };

      $scope.canEdit = function (item) {
        return item.OwnerId == CurrentUser.getUser().Company.id;
      };

      $scope.editMeetingTemplate = function (meetingTemplate) {
        if ($scope.canEdit(meetingTemplate)) {
          $state.go('meeting.template', {
            meetingTemplateId: meetingTemplate.id,
            copy: false,
            vendor: meetingTemplate.template,
          });
          // if (survey.template) {
          //     $state.go('sales.surveytemplate', {surveyId: survey.id, type: survey.type});
          // } else {
          //     $state.go('sales.survey', {surveyId: survey.id, type: survey.type});
          // }
        }
      };

      $scope.copyMeetingTemplate = function (meetingTemplate) {
        // if ($scope.isTemplates) {
        //     $state.go('sales.surveytemplatecopy', {surveyId: survey.id});
        // } else {
        //     $state.go('sales.surveycopy', {surveyId: survey.id, type: $scope.type});
        // }
        $state.go('meeting.template', {
          meetingTemplateId: meetingTemplate.id,
          copy: true,
          vendor: $scope.isTemplates,
        });
      };

      $scope.createMeetingTemplate = function (meetingTemplate) {
        if ($scope.isTemplates) {
          $state.go('meeting.template', { meetingTemplateId: 'new', vendor: true });
        } else {
          $rootScope.$broadcast('dataLoadingStarted');
          HttpService.get(
            '/api/meeting/templates?vendor=true&type=' +
              _.find($scope.filter.type, { selected: true }).id,
          )
            .then(function (vendorMeetingTemplates) {
              $scope.vendorMeetingTemplates = vendorMeetingTemplates;
              $scope.newMeetingTemplate = { expanded: true };
              $rootScope.$broadcast('dataLoadingFinished');
            })
            .catch(function (error) {
              $rootScope.$broadcast('dataLoadingFinished');
              DialogService.error(error);
            });
        }
      };

      $scope.deleteMeetingTemplate = function (meetingTemplate) {
        HttpService.delete('/api/meeting/templates/' + meetingTemplate.id).then(function () {
          _.remove($scope.meetingTemplates, { id: meetingTemplate.id });
        });
      };

      $scope.nameFilter = function (item) {
        return (
          $scope.filter.name === '' ||
          item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1 ||
          (item.Owner &&
            item.Owner.name &&
            item.Owner.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1)
        );
      };

      $scope.permissionGroupFilter = function (item) {
        return (
          _.isEmpty($scope.filter.permissionGroups) ||
          (item.Template &&
            _.find(item.Template.PermissionGroups, function (permissionGroup) {
              return _.includes($scope.filter.permissionGroups, permissionGroup.id);
            })) ||
          _.find(item.PermissionGroups, function (permissionGroup) {
            return _.includes($scope.filter.permissionGroups, permissionGroup.id);
          })
        );
      };

      $scope.typeFilter = function (item) {
        return item.type === _.find($scope.filter.type, { selected: true }).id;
      };

      $scope.downloadCsvExport = function (meetingTemplate) {
        var modalInstance = $modal.open({
          templateUrl: '/templates/meeting/meeting-export-modal.html',
          controller: 'MeetingExportModalController',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            selected: function () {
              return [];
            },
            meetings: function () {
              return HttpService.get(
                '/api/meeting/meetings?meetingTemplateId=' + meetingTemplate.id,
              );
            },
            group: function () {
              return 'Client.name';
            },
            filterValue: function () {
              return '';
            },
          },
        });
      };
    },
  )

  .controller(
    'MeetingExportModalController',
    function (
      $modalInstance,
      $translate,
      $scope,
      $rootScope,
      $window,
      _,
      CurrentUser,
      selected,
      meetings,
      group,
      filterValue,
      InputSanitizerService,
    ) {
      $scope.selected = {
        meetings: selected,
        scoreType: 'current',
      };

      $scope.group = group;
      $scope.values = $scope.group ? _.groupBy(meetings, $scope.group) : _.sortBy(meetings, 'name');
      $scope.filter = { name: filterValue };
      // $scope.scoreType = 'current';
      $scope.scoreTypes = [
        { id: 'current', name: $translate.instant('reports.currentScore') },
        {
          id: 'target',
          name: $translate.instant('reports.targetScore'),
        },
      ];
      $scope.okEnabled = function () {
        return $scope.selected.scoreType && !_.isEmpty($scope.selected.meetings);
      };

      $scope.ok =
        $scope.funcOk ||
        function () {
          $window.open(
            'https://' +
              $window.location.hostname +
              '/api/meeting/export/compare.csv?scoreType=' +
              InputSanitizerService.sanitize($scope.selected.scoreType) +
              '&meetingIds=' +
              InputSanitizerService.sanitize(_.join(_.map($scope.selected.meetings, 'id'), ',')) +
              '&token=' +
              CurrentUser.getToken(),
          );
          $modalInstance.close();
        };

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };

      $scope.groupFilter = function (item, values) {
        return (
          !$scope.filter.name ||
          _.includes(_.toLower(item), _.toLower($scope.filter.name)) ||
          _.filter(values, function (o) {
            return _.includes(_.toLower(o.name), _.toLower($scope.filter.name));
          }).length > 0
        );
      };
      $scope.itemFilter = function (item) {
        return (
          !$scope.filter.name ||
          _.includes(_.toLower(item.name), _.toLower($scope.filter.name)) ||
          _.includes(_.toLower(item.Client.name), _.toLower($scope.filter.name))
        );
      };

      $scope.isSelected = function (item) {
        return !!_.find($scope.selected.meetings, { id: item.id });
      };

      $scope.select = function (item) {
        if (!$scope.isSelected(item)) {
          $scope.selected.meetings.push(item);
        } else {
          _.remove($scope.selected.meetings, item);
        }
      };
    },
  )

  .controller(
    'MeetingTemplateEditController',
    function (
      $http,
      $q,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $timeout,
      $translate,
      _,
      CurrentUser,
      DialogService,
      HttpService,
      MeetingService,
      MeetingTemplateService,
      UploadService,
      Utils,
      uuid,
      meetingTemplate,
      services,
      InputSanitizerService,
      serviceTemplates,
      meetingWidgets,
      /*projects,*/ taskTemplates,
    ) {
      $scope.isTemplate = $stateParams.vendor === 'true';
      $scope.meetingTemplate = meetingTemplate || {
        template: $scope.isTemplate,
        widgets: [],
      };
      $scope.meetingTemplate.sections = $scope.meetingTemplate.sections || [];
      if (!_.find($scope.meetingTemplate.sections, { uuid: 'default' })) {
        $scope.meetingTemplate.sections.push({
          uuid: 'default',
          name: $translate.instant('label.meeting.sections.widgetsWithoutSection'),
          widgets: _.difference(
            _.map($scope.meetingTemplate.widgets, 'uuid'),
            _.flatMap($scope.meetingTemplate.sections, 'widgets'),
          ),
        });
      }

      $scope.copy = $stateParams.copy === 'true';
      $scope.wasTemplate = $scope.meetingTemplate.template;
      $scope.expanded = {};

      if ($scope.meetingTemplate.id) {
        if ($scope.meetingTemplate.PreviewImageId) {
          $scope.previewUrl =
            '/images/surveypreviews/' + $scope.meetingTemplate.id + '?' + new Date().getTime();
        }
      }
      $scope.openPreview = function (survey) {
        UploadService.open('surveypreviews', $scope.meetingTemplate).then(function () {
          $scope.previewUrl =
            '/images/surveypreviews/' +
            InputSanitizerService.sanitize($scope.meetingTemplate.id) +
            '?' +
            new Date().getTime();
        });
      };

      if ($scope.copy) {
        MeetingTemplateService.copyTemplate($scope.meetingTemplate, $scope.isTemplate);
      }

      // $scope.projects = _.orderBy(projects, 'name')
      $scope.taskTemplates = _.orderBy(taskTemplates, 'name');

      // $scope.servicesObj = {
      //     onetimeServiceTemplates: [],
      //     ongoingServiceTemplates: [],
      //     serviceTemplates: [],
      //     onetimeServices: [],
      //     ongoingServices: [],
      //     services: []
      // };
      // $scope.servicesObj.onetimeServiceTemplates = _.filter(serviceTemplates, function (s) {
      //     return /*s.published && */s.type !== 'ongoing'
      // });
      // $scope.servicesObj.ongoingServiceTemplates = _.filter(serviceTemplates, function (s) {
      //     return /*s.published && */s.type !== 'onetime'
      // });
      // $scope.servicesObj.serviceTemplates = serviceTemplates;
      // $scope.servicesObj.onetimeServices = $scope.isTemplate ? $scope.servicesObj.onetimeServiceTemplates : _.filter(services, function (s) {
      //     return s.type !== 'ongoing'
      // });
      // $scope.servicesObj.ongoingServices = $scope.isTemplate ? $scope.servicesObj.ongoingServiceTemplates : _.filter(services, function (s) {
      //     return s.type !== 'onetime'
      // });
      // $scope.servicesObj.services = $scope.isTemplate ? $scope.servicesObj.serviceTemplates : services;
      //
      // $scope.onetimeServices = _.orderBy($scope.servicesObj.onetimeServices, 'name');
      // $scope.ongoingServices = _.orderBy($scope.servicesObj.ongoingServices, 'name');
      //
      // $scope.services = _.orderBy($scope.servicesObj.services, 'name');

      $scope.serviceTemplates = serviceTemplates;
      $scope.services = _.orderBy($scope.isTemplate ? serviceTemplates : services, 'name');

      $scope.close = function () {
        $state.go('meeting.templates', { vendor: $scope.wasTemplate && !$scope.copy });
      };

      $scope.widgetSelector = {};
      $scope.addWidgetSelectors = [];
      $scope.widgetList = meetingWidgets; //'header', 'categories', 'details', 'bundlereview', 'pricecalculator', 'actionplan', 'projects', 'freetext', 'overviewcompare'];

      $scope.filterWidgetList = function (item) {
        var unlimitedWidgets = ['categories', 'freetext', 'scorecard'];
        var userWidgets = ['mpdonepager', 'personalroadmap'];
        var clientWidgets = ['actionplan', 'scorecompare'];
        var widgetCount = _.filter($scope.meetingTemplate.widgets, { type: item }).length;
        return (
          _.includes(unlimitedWidgets, item) ||
          widgetCount <= 0 ||
          ($scope.meetingTemplate.type === 'private' && _.includes(userWidgets, item)) ||
          ($scope.meetingTemplate.type === 'client' && _.includes(clientWidgets, item))
        );
      };

      var oldSurvey = _.cloneDeep($scope.meetingTemplate);

      $scope.getWidgetControllerName = function (widget) {
        return 'Meeting' + _.capitalize(_.get(widget, 'type')) + 'EditController';
      };

      $scope.generateAvailableWidgets = function () {
        $scope.availableWidgets = $scope.meetingTemplate.widgets
          .map(function (w) {
            return {
              name: w.name || $translate.instant('meetingWidget.' + w.type),
              type: w.type,
              id: w.uuid,
            };
          })
          .filter(function (w) {
            return w.type !== 'mpdonepager';
          });
        $scope.$broadcast('availableWidgetRefresh', $scope.availableWidgets);
      };
      $scope.generateAvailableWidgets();

      $scope.addWidget = function (type, index, section) {
        if (!$scope.meetingTemplate.widgets) {
          $scope.meetingTemplate.widgets = [];
        }
        const widget = {
          uuid: uuid.v4(),
          name: $translate.instant('meetingWidget.' + type),
          type: type,
          data: {},
        };
        $scope.meetingTemplate.widgets.splice(index, 0, widget);
        section.widgets.splice(index, 0, widget.uuid);
        _($scope.meetingTemplate.sections)
          .flatMap('widgets')
          .forEach(function (widgetId, index) {
            const widget = _.find($scope.meetingTemplate.widgets, { uuid: widgetId });
            widget.orderId = index + 1;
          });
        $scope.generateAvailableWidgets();
      };
      $scope.removeWidget = function (widget, section) {
        _.remove($scope.meetingTemplate.widgets, { uuid: widget.uuid });
        _.pull(section.widgets, widget.uuid);
      };
      $scope.editSectionName = function (section) {
        section.$$name = section.name;
        section.$$isEdit = true;
        setTimeout(function () {
          document.getElementById('sectionNameInput-' + section.uuid).focus();
        }, 0);
      };
      $scope.saveSectionEdit = function (section) {
        section.$$isEdit = false;
        section.name = section.$$name;
      };
      $scope.cancelSectionEdit = function (section) {
        section.$$isEdit = false;
        section.$$name = section.name;
      };

      $scope.changeWidgetShowHide = function (widget) {
        widget.collapsed = !widget.collapsed;
      };

      $scope.changeSectionShowHide = function (section) {
        section.collapsed = !section.collapsed;
      };

      $scope.changeAllWidgetShowHide = function (show, section) {
        _.forEach($scope.meetingTemplate.widgets, function (widget) {
          if (!section || _.includes(section.widgets, widget.uuid)) {
            widget.collapsed = !show;
          }
        });
      };

      $scope.scrollToElement = function (elementId) {
        document.getElementById(elementId).scrollIntoView();
      };

      $scope.save = function (cb) {
        var detailsWidget = _.find($scope.meetingTemplate.widgets, { type: 'details' });
        if (detailsWidget) {
          _.forEach(detailsWidget.data.dimensions, function (dimension) {
            _.forEach(dimension.blocks, function (block) {
              _.forEach(block.statements, function (statement, index) {
                statement.orderId = index + 1;
                // statement.serviceId = statement.$$service ? statement.$$service.id : undefined;
                if (!statement.statementType) {
                  statement.serviceId = null;
                  statement.taskTemplateId = null;
                  statement.solutionSetId = null;
                  statement.courseId = null;
                }
                delete statement.service; // this is only for deleting the garbage which was there previously
              });
            });
          });
        }
        // Check Scorecard widget questions
        var invalidScorecard = _($scope.meetingTemplate.widgets)
          .filter({ type: 'scorecard' })
          .find(function (scoreCardWidget) {
            var result = false;
            if (!scoreCardWidget.data.questions || !scoreCardWidget.data.questions.length) {
              result = true;
            } else {
              _.forEach(scoreCardWidget.data.questions, function (question) {
                if (!question.name || !question.name.trim()) {
                  result = true;
                }
              });
            }
            return result;
          });

        function showError(error) {
          if (
            error &&
            (error.includes('PayloadTooLargeError') || error.includes('Payload Too Large'))
          ) {
            var msg = $translate.instant('error.payloadTooLarge');
            // $scope.errors = [msg];
            Utils.showToastMessage(msg, { className: 'danger' });
          } else {
            // $scope.errors = [error];
            Utils.showToastMessage(error, { className: 'danger' });
          }
        }

        if (invalidScorecard) {
          Utils.showToastMessage(
            {
              code: 'meetingWidget.scoreCardQuestionNumberError',
              translateValues: { name: invalidScorecard.name },
            },
            { className: 'danger' },
          );
          cb();
        } else {
          if ($scope.meetingTemplate.id) {
            HttpService.put(
              '/api/meeting/templates',
              _.omit($scope.meetingTemplate, ['Owner', 'PermissionGroups', 'Template']),
            ).then(
              function (result) {
                // _.extend($scope.meetingTemplate, result);
                oldSurvey = _.cloneDeep($scope.meetingTemplate);
                $scope.messages = ['message.saved'];
                Utils.showToastMessage({ code: 'message.saved' });
                cb();
              },
              function (error) {
                showError(error);
                cb();
              },
            );
          } else {
            HttpService.post(
              '/api/meeting/templates',
              _.omit($scope.meetingTemplate, ['Owner', 'PermissionGroups', 'Template']),
            ).then(
              function (result) {
                if (result) {
                  oldSurvey = _.cloneDeep($scope.meetingTemplate);
                  $state.go('meeting.template', {
                    meetingTemplateId: result.id,
                    vendor: $stateParams.vendor,
                    copy: false,
                  });
                  Utils.showToastMessage({ code: 'message.saved' });
                }
              },
              function (error) {
                showError(error);
                cb();
              },
            );
          }
        }
      };

      $scope.checkFormIsDirty = function () {
        return !angular.equals(oldSurvey, $scope.meetingTemplate);
      };

      $scope.toggleType = function () {
        $scope.meetingTemplate.type =
          $scope.meetingTemplate.type === 'private' ? 'client' : 'private';
      };

      $scope.getWidgetByUUID = function (widgetId) {
        return _.find($scope.meetingTemplate.widgets, { uuid: widgetId });
      };
    },
  )

  // .controller('ReportGeneralEditBlockController', function ($scope, _, block) {
  //     $scope.block = block;
  //     $scope.statementTypes = ['text', 'number', 'currency', 'percentage', 'textWithNumber'];
  //
  //     $scope.changeNoneOfTheAbove = function (block, statement) {
  //         block.statements.forEach(function (_statement) {
  //             if (_statement.id == statement.id) {
  //                 _statement.noneOfTheAbove = !_statement.noneOfTheAbove;
  //             } else if (_statement.noneOfTheAbove) {
  //                 _statement.noneOfTheAbove = false;
  //             }
  //         })
  //     };
  //
  //     $scope.hideStatement = function (block, statement) {
  //         statement.hidden = true;
  //     };
  //     $scope.unhideStatement = function (block, statement) {
  //         statement.hidden = false;
  //     };
  //
  //     $scope.calcSums = function () {
  //         if (!$scope.block.type || $scope.block.type === 'score') {
  //             $scope.block.$$sum = _.sumBy($scope.block.statements, 'score');
  //         } else {
  //             delete $scope.block.$$sum;
  //         }
  //     };
  // })

  .service('MeetingService', function ($modal, $translate, ngToast, _) {
    var ranges = [
      { min: 0, max: 20, name: '0-20', length: 20, color: '#ea413d', class: 'red' },
      { min: 20, max: 40, name: '20-40', length: 20, color: '#ed742f', class: 'orange' },
      { min: 40, max: 60, name: '40-60', length: 20, color: '#f5be41', class: 'yellow' },
      { min: 60, max: 80, name: '60-80', length: 20, color: '#a8be4b', class: 'green' },
      { min: 80, max: 100, name: '80-100', length: 20, color: '#59bf90', class: 'darkgreen' },
    ];

    //Planned (fa-circle-o), On Track (fa-circle-play), At Risk (fa-circle-exclamation),
    // Off Track (fa-circle-times), Completed (fa-circle-check), Cancelled (fa-circle-minu), No Status

    const goalStatuses = {
      planned: { code: 'planned', name: 'Planned', icon: 'fa-circle-o' },
      ontrack: { code: 'ontrack', name: 'On Track', icon: 'fa-play-circle' },
      risk: { code: 'risk', name: 'At Risk', icon: 'fa-exclamation-circle' },
      offtrack: { code: 'offtrack', name: 'Off Track', icon: 'fa-times-circle' },
      completed: { code: 'completed', name: 'Completed', icon: 'fa-check-circle' },
      cancelled: { code: 'cancelled', name: 'Cancelled', icon: 'fa-minus-circle' },
      none: { code: 'none', name: 'No Status', icon: 'fa-question text-muted' },
    };

    const showToastMessage = function (className, message, duration) {
      ngToast.create({
        className: className || 'success',
        content: message,
        dismissButton: true,
        timeout: duration || 10000,
      });
    };

    const getGoalStatus = function (goal) {
      if (goal.$$course && goal.$$course.finishedAt) {
        return goalStatuses.completed;
      } else if (goal.$$course && !goal.$$course.finishedAt && goal.$$course.assignedAt) {
        return goalStatuses.planned;
      } else if (goal.$$course && !goal.$$course.assignedAt) {
        return goalStatuses.none;
      } else {
        return goalStatuses[goal.status || 'none'];
      }
    };

    var getWidget = function (meeting, type, uuid) {
      if (meeting) {
        var filtered = _.filter(meeting.widgets, { type: type });
        if (_.size(filtered) === 1) {
          return filtered[0];
        } else if (_.size(filtered) > 1) {
          return _.find(filtered, { uuid: uuid });
        } else {
          return undefined;
        }
      } else {
        return undefined;
      }
    };

    return {
      // generateSections: generateSections,
      getSurveyWidgetControllerName: function (widget) {
        return 'Meeting' + _.capitalize(_.get(widget, 'type')) + 'Controller';
      },

      // refresh: function ($scope, meeting) {
      //     // $scope.meeting = meeting;
      //     $scope.$broadcast('meetingRefresh');
      // },
      getWidget: getWidget,
      isMeetingProject: function (clientProject, meeting) {
        return (
          clientProject.ServiceId &&
          meeting &&
          _(_.get(getWidget(meeting, 'details'), 'data', {}).dimensions)
            .flatMap('blocks')
            .flatMap('statements')
            .find(function (statement) {
              return statement.serviceId === clientProject.ServiceId && statement.target === true;
            })
        );
      },

      getProgressStyleColor: function (value, meeting) {
        var _ranges = _.get(getWidget(meeting, 'header'), 'data.ranges') || ranges;
        var color = _ranges[0].color;
        if (!_ranges[0].min) {
          _.forEach(_ranges, function (range, index) {
            range.min = (index * 100) / _ranges.length;
            range.max = ((index + 1) * 100) / _ranges.length;
          });
        }
        _.forEach(_ranges, function (range) {
          if (value > range.min && value <= range.max) {
            color = range.color;
            return false;
          }
        });
        return color;
      },

      goalStatuses: goalStatuses,

      getGoalStatusIcon: function (goal) {
        return getGoalStatus(goal).icon;
      },

      getGoalStatus: function (goal) {
        return getGoalStatus(goal);
      },

      showToastMessage: showToastMessage,

      shareLink: function (url, options) {
        options = options || {};

        $modal.open({
          template:
            '<modal-form oncancel="close" label-cancel="button.close" form-title="{{title}}">' +
            '<p class="alert alert-info" ng-if="generateNewUrl" translate="label.meeting.share.generateNewUrl.hint"></p>' +
            '<div class="abm-form"><div class="input-group">' +
            '  <input type="text" ng-model="url" class="form-control padding-1x-left" readonly>' +
            '  <span class="input-group-addon" translate-title="button.copyToClipboard"><i class="fa fa-copy" ng-click="copyToken()"></i></span>' +
            '  <span class="input-group-addon no-padding-left" ng-if="generateNewUrl" translate-title="label.meeting.share.generateNewUrl"><i class="fa fa-refresh" ng-click="generateNewUrl()"></i></span>' +
            '  <span class="input-group-addon no-padding-left" translate-title="button.openInNewTab"><a href="{{url}}" target="_blank" style="color: inherit"><i class="fa fa-external-link"></i></a></span>' +
            '</div></div>' +
            // '<div class="row"><div class="col-xs-12"><input ng-model="url" disabled></div></div>' +
            '</modal-form>',
          backdrop: 'static',
          size: 'lg',
          controller: function ($scope, $modalInstance, Utils, url, generateNewUrl, title) {
            $scope.url = url;
            $scope.title = title;
            if (generateNewUrl) {
              $scope.generateNewUrl = function () {
                generateNewUrl(function (url) {
                  $scope.url = url;
                  showToastMessage(
                    'success',
                    $translate.instant('label.meeting.share.generateNewUrl.success'),
                  );
                });
              };
            }

            $scope.copyToken = function () {
              Utils.copyToClipboard($scope.url);
              showToastMessage('success', $translate.instant('meetingWidget.linkCopied'));
            };

            $scope.close = function () {
              $modalInstance.close();
            };
          },
          resolve: {
            url: function () {
              return url;
            },
            generateNewUrl: function () {
              return options.generateNewUrl;
            },
            title: function () {
              return options.title;
            },
          },
        });
      },
    };
  })

  .controller(
    'MeetingsController',
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $http,
      $modal,
      $moment,
      $window,
      $translate,
      _,
      LocalStorage,
      CurrentUser,
      DialogService,
      HttpService,
      surveyResultService,
      meetingTemplates,
      meetings,
      client,
    ) {
      $scope.meetings = meetings;
      $scope.selectedClient = client;
      $scope.client = client;
      $scope.meetingTemplates = meetingTemplates;

      $scope.newMeeting = {};

      _.forEach($scope.meetings, function (meeting) {
        if (_.find(meeting.widgets, { type: 'details' })) {
          meeting.doughnutChartData = {
            data: {
              labels: ['Current', 'Targeted', ''],
              datasets: [
                {
                  data: [
                    meeting.score,
                    meeting.targetScore - meeting.score,
                    Math.max(0, 100 - meeting.targetScore - meeting.score),
                  ],
                  backgroundColor: ['#62A6DB', '#59BF90', '#efefef'],
                  hoverBackgroundColor: ['#62A6DB', '#59BF90', '#efefef'],
                },
              ],
            },
            options: {
              width: 120,
              height: 120,
              responsive: true,
              animation: false,
              tooltips: false,
              legend: false,
              cutoutPercentage: 85,
            },
          };
        }
      });

      $scope.$watch('newMeeting.Template', function () {
        if (!$scope.newMeeting.name && $scope.newMeeting.Template) {
          $scope.newMeeting.name = $scope.newMeeting.Template.name;
        }
        if ($scope.newMeeting.Template && $scope.newMeeting.Template.quarterly) {
          $scope.newMeeting.mpdDataQuarter = $moment().subtract(1, 'Q').format('YYYY[Q]Q');
        } else {
          $scope.newMeeting.mpdDataQuarter = undefined;
        }
      });

      // $scope.$on('ConnectWiseScreenChanged', function (event, screenObject) {
      //     if (screenObject && screenObject.screen === 'company') {
      //         $scope.filter.externalId = screenObject.id;
      //         $scope.selectedClient = _.find($scope.clients, function (client) {
      //             return _.get(client, 'externalIds.connectwise') + '' == $scope.filter.externalId + '';
      //         });
      //         $state.$current.data.pageTitle = $scope.selectedClient ? $scope.selectedClient.name : 'sales.menu.companies';
      //         $rootScope.$broadcast('dataLoadingFinished');
      //     }
      // });

      $scope.canEditMeeting = function (meeting) {
        return !$scope.user || meeting.Template.Owner.id == CurrentUser.getUser().Company.id;
      };

      $scope.editMeeting = function (meeting) {
        if ($scope.canEditMeeting(meeting)) {
          $scope.oldValues = _.cloneDeep(meeting);
          meeting.isEdit = true;
        }
      };

      $scope.save = function (meeting, cb) {
        var req;
        if (meeting.id) {
          req = HttpService.put('/api/meeting/meetings/', meeting);
        } else {
          meeting.ClientId = $scope.client ? $scope.client.id : undefined;
          if ($scope.user) {
            meeting.UserId = $scope.user.id;
            meeting.OwnerId = meeting.OwnerId || $scope.user.Company.id;
          }
          req = HttpService.post(
            '/api/meeting/templates/' + meeting.Template.id + '/meetings',
            meeting,
          );
        }
        $rootScope.$broadcast('dataLoadingStarted');
        req.then(
          function (result) {
            if (meeting.id) {
              meeting.isEdit = false;
            } else {
              $scope.meetings.push(result);
              $scope.newMeeting = {};
            }
            $rootScope.$broadcast('dataLoadingFinished');
            if (cb) {
              cb();
            }
          },
          function (error) {
            if (cb) {
              cb();
            }
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
            console.error(error);
          },
        );
      };

      $scope.cancel = function (meeting) {
        if (meeting.id) {
          meeting.isEdit = false;
          _.forEach(meeting.oldValues, function (v, k) {
            meeting[k] = v;
          });
        } else {
          $scope.newMeeting = {};
        }
      };

      $scope.delete = function (meeting) {
        if ($scope.canEditMeeting(meeting)) {
          HttpService.delete('/api/meeting/meetings/' + meeting.id)
            .then(function () {
              _.remove($scope.meetings, { id: meeting.id });
            })
            .catch(function (error) {
              console.error(error);
            });
        }
      };

      $scope.meetingOrder = function (meeting) {
        return $moment(meeting.createdAt).diff($moment(), 'hours');
      };

      $scope.openAddMeetingTemplate = function () {
        var modalInstance = $modal.open({
          template:
            '<modal-form onok="ok" oncancel="cancel">\n' +
            '    <p translate="label.meetings.addToCatalogue.hint"></p>\n' +
            '    <input-select model="selected.meetingTemplate" values="meetingTemplates"></input-select>\n' +
            '</modal-form>',
          controller: function (
            $scope,
            $modalInstance,
            _,
            CurrentUser,
            DialogService,
            HttpService,
            MeetingTemplateService,
            meetingTemplates,
          ) {
            $scope.meetingTemplates = meetingTemplates;
            $scope.selected = {};
            $scope.cancel = function () {
              $modalInstance.dismiss();
            };

            $scope.ok = function (cb) {
              HttpService.get('/api/meeting/templates/' + $scope.selected.meetingTemplate.id).then(
                function (selectedMeetingTemplate) {
                  MeetingTemplateService.copyTemplate(selectedMeetingTemplate, false);
                  HttpService.post(
                    '/api/meeting/templates',
                    _.omit(selectedMeetingTemplate, ['Owner', 'PermissionGroups', 'Template']),
                  )
                    .then(function (result) {
                      DialogService.message('label.meetings.addToCatalogue.success', function () {
                        $modalInstance.close(result);
                      });
                      cb();
                    })
                    .catch(function (error) {
                      $scope.errors = [error];
                      cb();
                    });
                },
              );
            };
          },
          backdrop: 'static',
          resolve: {
            meetingTemplates: function ($stateParams, HttpService) {
              return HttpService.get(
                '/api/meeting/templates?vendor=true' + (!$scope.client ? '&type=private' : ''),
              );
            },
          },
        });

        modalInstance.result.then(
          function (meetingTemplate) {
            //refresh modified data
            $scope.meetingTemplates.push(meetingTemplate);
            $scope.newMeeting.Template = meetingTemplate;
          },
          function () {},
        );
      };
    },
  )

  .controller(
    'MeetingOverviewController',
    function (
      $location,
      $scope,
      $sce,
      $rootScope,
      $state,
      InputSanitizerService,
      $stateParams,
      $http,
      $modal,
      $moment,
      $window,
      _,
      FILENAME_REGEX,
      $translate,
      CurrentUser,
      DialogService,
      HttpService,
      LocalStorage,
      MeetingChartService,
      MeetingService,
      ModalFormService,
      UserEventService,
      uuid,
      _,
      meeting,
      services,
      cesActivities,
      meetingWidgets,
      taskTemplates,
      ngToast,
    ) {
      $scope.selected = {};
      $scope.taskTemplates = [
        {
          id: -1,
          name: $translate.instant('label.tasks.newTask'),
        },
      ].concat(taskTemplates);

      $scope.widgetSelector = {};
      $scope.addWidgetSelectors = [];
      $scope.widgetList = meetingWidgets; //['header', 'categories', 'details', 'bundlereview', 'pricecalculator', 'actionplan', 'projects', 'freetext'];
      $scope.meeting = meeting;
      $scope.currentUser = CurrentUser.getUser();

      $location.search('type', meeting.Template.type);

      $scope.meeting.sections = $scope.meeting.sections || [];
      var defaultSection = _.find($scope.meeting.sections, { uuid: 'default' });
      if (!defaultSection) {
        defaultSection = {
          uuid: 'default',
          name: $translate.instant('label.meeting.sections.widgetsWithoutSection'),
        };
        $scope.meeting.sections.push(defaultSection);
      }
      defaultSection.widgets = _.difference(
        _.map($scope.meeting.widgets, 'uuid'),
        _.flatMap(_.reject($scope.meeting.sections, { uuid: 'default' }), 'widgets'),
      );
      _.forEach($scope.meeting.sections, function (section) {
        section.widgets = _.filter(section.widgets, function (item) {
          return _.find($scope.meeting.widgets, { uuid: item });
        });
      });

      // TODO permission
      // if ($scope.meeting.widgets) {
      //     $scope.meeting.widgets = $scope.meeting.widgets.filter(function (w) {
      //         if (w.type === 'mpdonepager') {
      //             return CurrentUser.can('useMeetingWidgetMPDOnePager')
      //         }
      //         return true
      //     })
      // }
      $scope.services = _.map(services, function (service) {
        if (service.description) {
          service.$$descriptionTrustedHtml = $sce.trustAsHtml(service.description);
        }
        return service;
      });
      $scope.MeetingService = MeetingService;
      $scope.cesActivities = cesActivities;
      $scope.isSnapshot = $state.current.name === 'meeting.snapshot';
      if ($scope.isSnapshot && $scope.meeting.Parent) {
        $scope.meeting.Snapshots = $scope.meeting.Parent.Snapshots;
        // $scope.meeting.Snapshots = _.reject($scope.meeting.Parent.Snapshots, {id: $scope.meeting.id});
        // $scope.meeting.Snapshots.push(_.pick($scope.meeting.Parent, ['id', 'name', 'createdAt', 'CreatedBy']))
      }

      if ($scope.meeting.Client && !$scope.isSnapshot) {
        HttpService.get('/api/clients/' + $scope.meeting.Client.id + '/projects').then(function (
          clientProjects,
        ) {
          $scope.clientProjects = clientProjects;
        });
      } else if ($scope.isSnapshot) {
        $scope.clientProjects = _.get(
          _.find($scope.meeting.widgets, { type: 'actionplan' }),
          'data.clientProjects',
          {},
        );
      }

      $scope.meetingOwnersPermissionGroupIds = [];

      $scope.$on('projectExported', function () {
        HttpService.get('/api/clients/' + $scope.meeting.Client.id + '/projects').then(function (
          clientProjects,
        ) {
          $scope.clientProjects = clientProjects;
        });
      });

      // Private Meeting
      //   - If the meeting has a User
      //   - Between the Vendor and the MSP
      //   - The Owner of the meeting is the MSP
      //   - The UserId is the id of the MSP's user
      if ($scope.meeting.UserId) {
        // Get the courses and solution sets for the statements
        const detailsWidget = _.find($scope.meeting.widgets, { type: 'details' });
        if (detailsWidget) {
          _.forEach(detailsWidget.data.dimensions, function (dimension) {
            _.forEach(dimension.blocks, function (block) {
              _.forEach(block.statements, function (statement) {
                if (statement.solutionSetId) {
                  HttpService.get('/api/vendor/permissiongroups/' + statement.solutionSetId).then(
                    function (solutionSet) {
                      statement.$$solutionSet = solutionSet;
                    },
                  );
                } else if (statement.courseId) {
                  HttpService.get('/api/academy/assets/' + statement.courseId).then(function (
                    course,
                  ) {
                    statement.$$course = course;
                  });
                }
              });
            });
          });
        }

        // Get the owned solution sets from the meeting's user company
        HttpService.get('/api/companies/' + $scope.meeting.OwnerId + '?permissionGroups=true').then(
          function (company) {
            $scope.meetingOwnersPermissionGroupIds = _.map(company.PermissionGroups, 'id');
          },
        );
      }

      $scope.snapshotOrder = function (item) {
        return $filter('date')(item.createdAt, 'mediumDate');
      };

      $scope.goEmbeddedBack = function () {
        $state.go('embedded.surveyresults');
      };

      $scope.filterWidgetList = function (item) {
        var unlimitedWidgets = ['categories', 'freetext', 'scorecard'];
        var userWidgets = ['mpdonepager', 'personalroadmap'];
        var clientWidgets = ['actionplan'];
        var widgetCount = _.filter($scope.meeting.widgets, { type: item }).length;

        if (item === 'scorecard' || item === 'details') {
          return false;
        } else {
          return (
            (_.includes(unlimitedWidgets, item) || widgetCount <= 0) &&
            (($scope.meeting.Template.type === 'private' && _.includes(userWidgets, item)) ||
              ($scope.meeting.Template.type === 'client' && _.includes(clientWidgets, item)) ||
              (!_.includes(userWidgets, item) && !_.includes(clientWidgets, item))) &&
            (item !== 'scorecardsummary' ||
              !!_.find($scope.meeting.widgets, { type: 'scorecard' })) &&
            (!(item === 'categories' || item === 'overview') ||
              !!_.find($scope.meeting.widgets, { type: 'details' }))
          );
        }
      };

      $scope.addWidget = function (type, index, section) {
        $rootScope.$broadcast('dataLoadingStarted');
        if (!$scope.meeting.widgets) {
          $scope.meeting.widgets = [];
        }
        const widget = {
          uuid: uuid.v4(),
          name: $translate.instant('meetingWidget.' + type),
          type: type,
          data: {},
        };
        // $scope.meeting.widgets.push(widget);
        $scope.meeting.widgets.splice(index, 0, widget);
        section.widgets.splice(index, 0, widget.uuid);

        // sort saves the new widget into the right place
        $scope.saveWidgets(widget, function () {
          $scope.sortWidgets(function () {
            $rootScope.$broadcast('dataLoadingFinished');
          });
        });
      };

      $scope.getWidgetByUUID = function (widgetId) {
        return _.find($scope.meeting.widgets, { uuid: widgetId });
      };

      $scope.removeWidget = function (widget, section) {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.delete('/api/meeting/meetings/' + $scope.meeting.id + '/widgets/' + widget.uuid)
          .then(function () {
            _.remove($scope.meeting.widgets, { uuid: widget.uuid });
            _.pull(section.widgets, widget.uuid);
            $scope.sortWidgets();
            $scope.$broadcast('widgetDeleted');
            $rootScope.$broadcast('dataLoadingFinished');
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
          });
      };
      $scope.editWidgetName = function (widget) {
        widget.$$name = widget.name;
        widget.$$isEdit = true;
      };
      $scope.saveWidgetEdit = function (widget) {
        widget.$$isEdit = false;
        widget.name = widget.$$name;
        $scope.sortWidgets();
      };
      $scope.cancelWidgetEdit = function (widget) {
        widget.$$isEdit = false;
        widget.$$name = widget.name;
      };
      $scope.editSectionName = function (section) {
        section.$$name = section.name;
        section.$$isEdit = true;
        setTimeout(function () {
          document.getElementById('sectionNameInput-' + section.uuid).focus();
        }, 0);
      };
      $scope.saveSectionEdit = function (section) {
        section.$$isEdit = false;
        section.name = section.$$name;
        $scope.sortWidgets();
      };
      $scope.cancelSectionEdit = function (section) {
        section.$$isEdit = false;
        section.$$name = section.name;
      };
      // $scope.moveWidgetUp = function (section, widget) {
      //     const index = section.widgets.indexOf(widget.uuid);
      //     if (index === 0) {
      //         return;
      //     }
      //     const temp = section.widgets[index];
      //     section.widgets[index] = section.widgets[index - 1];
      //     section.widgets[index - 1] = temp;
      //     $scope.sortWidgets();
      // };
      // $scope.moveWidgetDown = function (section, widget) {
      //     const index = section.widgets.indexOf(widget.uuid);
      //     if (index === section.widgets.length - 1) {
      //         return;
      //     }
      //     const temp = section.widgets[index];
      //     section.widgets[index] = section.widgets[index + 1];
      //     section.widgets[index + 1] = temp;
      //     $scope.sortWidgets();
      // };

      $scope.addSection = function (name, cb) {
        if (!name) {
          return;
        }

        $rootScope.$broadcast('dataLoadingStarted');
        if (!$scope.meeting.sections) {
          $scope.meeting.sections = [];
        }
        const section = {
          uuid: uuid.v4(),
          name: name,
          widgets: [],
        };
        $scope.meeting.sections.unshift(section);
      };

      $scope.getWidgetControllerName = function (widget) {
        return 'Meeting' + _.capitalize(_.get(widget, 'type')) + 'Controller';
      };

      $scope.changeAllWidgetShowHide = function (show, section) {
        _.forEach($scope.meeting.widgets, function (widget) {
          if (!section || _.includes(section.widgets, widget.uuid)) {
            widget.collapsed = !show;
          }
        });
        if (!$scope.isSnapshot) {
          $scope.sortWidgets();
        }
      };

      $scope.changeWidgetShowHide = function (widget) {
        widget.collapsed = !widget.collapsed;
        if (!$scope.isSnapshot) {
          $scope.sortWidgets();
        }
      };

      $scope.changeSectionShowHide = function (section) {
        section.collapsed = !section.collapsed;
        if (!$scope.isSnapshot) {
          $scope.sortWidgets();
        }
      };

      $scope.saveWidgets = function (widgets, cb) {
        if (!$scope.isSnapshot) {
          if (!$scope.savePromise) {
            $scope.isSavingWidgets = true;
            $scope.savePromise = HttpService.put(
              '/api/meeting/meetings/' + $scope.meeting.id + '/widgets',
              widgets,
            )
              .then(function (result) {
                $scope.isSavingWidgets = false;
                $scope.$broadcast('meetingRefresh', result);
                if (cb) {
                  cb();
                }
              })
              .catch(function (error) {
                $scope.isSavingWidgets = false;
                DialogService.error(error);
                if (cb) {
                  cb();
                }
              });
          } else {
            $scope.savePromise = $scope.savePromise.finally(function () {
              $scope.isSavingWidgets = true;
              return HttpService.put(
                '/api/meeting/meetings/' + $scope.meeting.id + '/widgets',
                widgets,
              )
                .then(function (result) {
                  $scope.isSavingWidgets = false;
                  $scope.$broadcast('meetingRefresh', result);
                  if (cb) {
                    cb();
                  }
                })
                .catch(function (error) {
                  $scope.isSavingWidgets = false;
                  DialogService.error(error);
                  if (cb) {
                    cb();
                  }
                });
            });
          }
        } else {
          DialogService.error('error.meetings.saveSnapshot');
          if (cb) {
            cb();
          }
        }
      };

      $scope.$on('meetingRefresh', function (event, meeting) {
        if ($scope.meeting.Client) {
          HttpService.get('/api/clients/' + $scope.meeting.Client.id + '/projects').then(function (
            clientProjects,
          ) {
            $scope.clientProjects = clientProjects;
          });
        }
      });

      $scope.sortWidgets = function (cb) {
        if (!$scope.isSnapshot) {
          _($scope.meeting.sections)
            .flatMap('widgets')
            .forEach(function (widgetId, index) {
              const widget = _.find($scope.meeting.widgets, { uuid: widgetId });
              widget.orderId = index + 1;
            });

          const sections = _.map(
            $scope.meeting.sections,
            _.partialRight(_.pick, ['uuid', 'name', 'collapsed', 'widgets']),
          );
          const widgets = _.map(
            $scope.meeting.widgets,
            _.partialRight(_.pick, ['uuid', 'name', 'collapsed']),
          );
          HttpService.post('/api/meeting/meetings/' + $scope.meeting.id + '/widgets', {
            sections: sections,
            widgets: widgets,
          })
            .then(function (data) {
              if (cb) {
                cb(null, data);
              }
            })
            .catch(function (error) {
              DialogService.error(error);
              if (cb) {
                cb(error);
              }
            });
        } else {
          DialogService.error('error.meetings.saveSnapshot');
          if (cb) {
            cb(new Error('error.meetings.saveSnapshot'));
          }
        }
      };

      $scope.createSnapshot = function () {
        var modalInstance = $modal.open({
          template:
            '<modal-form form-title="label.meetings.createSnapshot" onok="ok" oncancel="cancel"><div class="abm-form">' +
            '<abm-form-group class="no-margin-top h2"><input type="text" class="ryc-h2" ng-model="snapshot.name" abm-form-control label="label.meetings.name" required></abm-form-group>' +
            '<abm-form-group class="no-margin-top" ng-if="contacts">' +
            '    <abm-checkbox model="snapshot.createCesActivity" label="label.meetings.createSnapshot.createCesActivity" full-size="true"></abm-checkbox>' +
            '</abm-form-group>' +
            '<div class="row"><div class="col-xs-6">' +
            '    <abm-form-group class="no-margin-top h6" ng-if="snapshot.createCesActivity">\n' +
            '        <abm-ui-select model="snapshot.CesActivity.contactIds" required="true" type="multi-id" placeholder="label.client.ces.selectContacts" values="contacts"></abm-ui-select>\n' +
            '    </abm-form-group></div><div class="col-xs-6">' +
            '    <abm-form-group class="no-margin-top h6" ng-if="snapshot.createCesActivity">\n' +
            '        <abm-date model="snapshot.CesActivity.day" required="true" max-date="today" format="MMM dd. yyyy" placeholder="label.client.ces.selectDate"></abm-date>\n' +
            '    </abm-form-group>\n\n' +
            '</div></div></modal-form>',
          controller: function (
            $scope,
            $state,
            $rootScope,
            $modalInstance,
            $moment,
            $filter,
            _,
            HttpService,
            meeting,
            contacts,
          ) {
            $scope.meeting = meeting;
            $scope.contacts = contacts;
            $scope.today = $moment().startOf('day').toDate();
            $scope.snapshot = {
              name: $filter('date')(new Date(), 'mediumDate'),
              CesActivity: { day: $scope.today, ClientId: $scope.meeting.ClientId },
            };

            $scope.ok = function (cb) {
              $rootScope.$broadcast('dataLoadingStarted');
              HttpService.post(
                '/api/meeting/meetings/' + $scope.meeting.id + '/snapshots',
                $scope.snapshot,
              )
                .then(function (result) {
                  $rootScope.$broadcast('dataLoadingFinished');
                  cb();
                  $modalInstance.close();
                  DialogService.message('label.meetings.snapshotCreated');
                  $state.go('meeting.snapshot', {
                    meetingId: $scope.meeting.id,
                    snapshotId: result.id,
                  });
                })
                .catch(function (error) {
                  $rootScope.$broadcast('dataLoadingFinished');
                  cb();
                  DialogService.error(error);
                });
            };

            $scope.downloadButton = {
              label: 'button.ok',
              function: $scope.ok,
            };

            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
          backdrop: 'static',
          resolve: {
            meeting: function () {
              return $scope.meeting;
            },
            contacts: function () {
              return $scope.meeting.ClientId
                ? HttpService.get(
                    '/api/contacts?clientId=' +
                      InputSanitizerService.sanitize($scope.meeting.ClientId),
                  ).then(function (contacts) {
                    return _.map(contacts, function (contact) {
                      return _.extend(contact, {
                        name: contact.firstName + ' ' + contact.lastName,
                      });
                    });
                  })
                : undefined;
            },
          },
        });
      };

      $scope.deleteSnapshot = function () {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.delete(
          '/api/meeting/meetings/' +
            InputSanitizerService.sanitize($scope.meeting.ParentId) +
            '/snapshots/' +
            InputSanitizerService.sanitize($scope.meeting.id),
        )
          .then(function (result) {
            $rootScope.$broadcast('dataLoadingFinished');
            $state.go('meeting.overview', { meetingId: $scope.meeting.ParentId });
            DialogService.message('label.meetings.snapshotDeleted');
          })
          .catch(function (error) {
            DialogService.error(error);
            $rootScope.$broadcast('dataLoadingFinished');
          });
      };

      $scope.scrollToElement = function (elementId) {
        document.getElementById(elementId).scrollIntoView();
      };

      $scope.getWidget = function (type, uuid) {
        return MeetingService.getWidget($scope.meeting, type, uuid);
      };

      $scope.getWidgetData = function (type, uuid) {
        return _.get($scope.getWidget(type, uuid), 'data', {});
      };

      $scope.getRecommendation = function (statement) {
        if (statement.statementType === 'task' && statement.taskTemplateId) {
          return (
            _.find($scope.tasks, { TaskTemplateId: statement.taskTemplateId }) ||
            _.find($scope.taskTemplates, { id: statement.taskTemplateId })
          );
        } else if (statement.statementType === 'solutionSet' && statement.solutionSetId) {
          return statement.$$solutionSet;
        } else if (statement.statementType === 'course' && statement.courseId) {
          return statement.$$course;
        } else if (
          (statement.statementType === 'onetime' ||
            statement.statementType === 'ongoing' ||
            !statement.statementType) &&
          statement.serviceId
        ) {
          // this should be the default case with older meetings: if ((statement.statementType === 'onetime' || statement.statementType === 'ongoing') && statement.serviceId) {
          return _.find($scope.services, { id: statement.serviceId });
        }
      };

      var printUrl =
        'https://' +
        $window.location.hostname +
        ($window.location.port ? ':' + $window.location.port : '') +
        '/api/meeting/meetings/' +
        ($scope.meeting.ParentId
          ? $scope.meeting.ParentId + '/snapshots/' + $scope.meeting.id
          : $scope.meeting.id) +
        '/print/';

      $scope.printWidget = function (widget, options) {
        HttpService.get(
          '/api/auth/downloadtoken/' + InputSanitizerService.sanitize($scope.meeting.id),
        ).then(function (downloadToken) {
          $window.open(
            printUrl +
              widget.uuid +
              '?token=' +
              downloadToken +
              (options.landscape ? '&landscape=true' : ''),
          );
        });
      };

      $scope.printReport = function () {
        //if (FILENAME_REGEX.test($scope.meeting.name)) {
        var modalInstance = $modal.open({
          template:
            '<modal-form form-title="label.meeting.print" onok="ok" label-ok="button.download" oncancel="cancel">\n' +
            '</modal-form>\n',
          controller: function (
            $scope,
            $state,
            $stateParams,
            $http,
            $modalInstance,
            $filter,
            $window,
            $translate,
            _,
            CurrentUser,
            HttpService,
            meeting,
          ) {
            $scope.meeting = meeting;

            $scope.ok = function (cb) {
              HttpService.get(
                '/api/auth/downloadtoken/' + InputSanitizerService.sanitize($scope.meeting.id),
              ).then(function (downloadToken) {
                $window.open(printUrl + '?token=' + downloadToken);
                cb();
              });
            };

            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
          backdrop: 'static',
          resolve: {
            meeting: function () {
              return $scope.meeting;
            },
          },
        });
        //} else {
        //    DialogService.error('error.meetingFilename')
        //}
      };

      $scope.shareReport = function () {
        function generateNewUrl(cb) {
          DialogService.confirm('label.meeting.share.generateNewUrl.confirm', function () {
            HttpService.put(
              '/api/meeting/meetings/' +
                InputSanitizerService.sanitize($scope.meeting.id) +
                '/uuid',
              { ParentId: $scope.meeting.ParentId },
            ).then(function (uuid, error) {
              if (!error) {
                $scope.meeting.uuid = uuid;
                cb(getLink(uuid));
              } else {
                DialogService.error(error);
              }
            });
          });
        }

        function getLink(uuid) {
          var url = window.location.origin + '/reports/' + uuid;
          var widgets = [];
          _($scope.meeting.sections)
            .reject({ collapsed: true })
            .flatMap('widgets')
            .forEach(function (widgetId, index) {
              var widget = _.find($scope.meeting.widgets, { uuid: widgetId });
              if (widget && !widget.collapsed) {
                widgets.push(widget.uuid);
              }
            });

          if (widgets.length > 0) {
            url += '?w=' + _.join(widgets, ',');
          }
          return url;
        }

        const title = $translate.instant('label.meeting.share.title', {
          meetingName: $scope.meeting.name,
        });
        UserEventService.event('meetingShared', {
          meetingId: $scope.meeting.id,
          modelId: $scope.meeting.TemplateId,
          modelName: 'MeetingTemplate',
          clientId: $scope.meeting.ClientId,
        });
        MeetingService.shareLink(getLink($scope.meeting.uuid), {
          title: title,
          generateNewUrl: generateNewUrl,
        });
      };

      $scope.sendToVcio = function (system, exportType) {
        UserEventService.event('auditTicketExportButton', {
          meetingId: $scope.meeting.id,
          modelId: $scope.meeting.TemplateId,
          modelName: 'MeetingTemplate',
        });
        if (system === 'connectwise' && !_.get($scope.meeting.Client, 'externalIds.connectwise')) {
          DialogService.error(
            $translate.instant('message.externalSystems.connectwise.noClientLinked', {
              clientId: $scope.meeting.Client.id,
            }),
          );
        } else {
          var modalInstance = $modal.open({
            // templateUrl: '/templates/vcio/service-to-vcio.html',
            template:
              exportType === 'projectExports'
                ? '<modal-form form-title="{{title}}" title-values="{system: systemName}" onok="ok" oncancel="cancel" label-cancel="button.close">' +
                  '  <div ng-repeat="project in projects" class="pading-1x-top">' +
                  '    <i ng-show="project.isExportedInMail && isEmailExport" class="fa fa-envelope"></i>' +
                  '    <span ng-hide="project.isExportedInMail && isEmailExport" style="padding-left: 1.75rem;"></span>' +
                  '    <input type="checkbox" id="{{ticket.id}}" ng-model="project.isChecked" class="margin-1x-right">' +
                  '    <label for="{{project.id}}">{{project.name}}</label>' +
                  '  </div>' +
                  '</modal-form>'
                : '<modal-form form-title="{{title}}" title-values="{system: systemName}" onok="ok" oncancel="cancel" label-cancel="button.close">' +
                  '  <input-select ng-if="boards" values="boards" model="selected.board" label="label.integration.serviceBoard"></input-select>' +
                  '  <input-select ng-if="users" values="users" model="selected.assignee" label="label.integration.assignee"></input-select>' +
                  '  <div ng-repeat="ticket in tickets" class="pading-1x-top">' +
                  '    <a ng-show="ticket.externalTicketId && !isEmailExport" href="{{ticket.fullPath}}" target="_blank" class="fa fa-link"></a>' +
                  '    <i ng-show="ticket.isExportedInMail && isEmailExport" class="fa fa-envelope"></i>' +
                  '    <span ng-hide="(ticket.externalTicketId && !isEmailExport)|| (ticket.isExportedInMail && isEmailExport)" style="padding-left: 1.75rem;"></span>' +
                  '    <input type="checkbox" id="{{ticket.id}}" ng-hide="ticket.externalTicketId && !isEmailExport" ng-model="ticket.isChecked" class="margin-1x-right">' +
                  '    <input type="checkbox" id="{{ticket.id}}" ng-hide=" isEmailExport || !ticket.externalTicketId" checked disabled class="margin-1x-right">' +
                  '    <label for="{{ticket.id}}">{{ticket.title}}</label>' +
                  '  </div>' +
                  '</modal-form>',
            controller: function (
              $scope,
              $rootScope,
              $stateParams,
              $http,
              $modalInstance,
              $translate,
              _,
              HttpService,
              UserEventService,
              VcioService,
              meeting,
              tasks,
              system,
              exportType,
              clientProjects,
            ) {
              $scope.exportTypeMap = {
                ticketExports: {
                  title: 'label.overview.exportTasksByEmail',
                  email: _.get(meeting, 'Owner.settings.emailIntegration.ticketExportEmail'),
                  eventName: 'ticketExported',
                  itemArray: 'tickets',
                },
                projectExports: {
                  title: 'label.overview.exportProjectsByEmail',
                  email: _.get(meeting, 'Owner.settings.emailIntegration.projectExportEmail'),
                  eventName: 'projectExported',
                  itemArray: 'projects',
                },
              };
              $scope.title =
                system === 'email'
                  ? $scope.exportTypeMap[exportType].title
                  : 'label.overview.exportTasks';
              $scope.meeting = meeting;
              $scope.tasks = tasks;
              $scope.system = system;
              $scope.isEmailExport = system === 'email';
              $scope.systemName = $translate.instant('label.externalSystem.' + system);
              $scope.selected = {};
              $scope.exportType = exportType;
              $scope.projects = clientProjects;

              VcioService.setSystem(system);

              if ($scope.system === 'connectwise') {
                // $scope.billingMethods = ['ActualRates', 'FixedFee', 'NotToExceed', 'OverrideRate'];
                VcioService.get('/boards/service').then(function (result) {
                  $scope.boards = result;
                });
              } else if ($scope.system === 'autotask') {
                $scope.selected.board = { id: 0 };
                VcioService.get('/users').then(function (users) {
                  $scope.users = _(users)
                    .filter('DefaultServiceDeskRoleID')
                    .map(function (user) {
                      user.name = user.FirstName + ' ' + user.LastName;
                      return user;
                    })
                    .value();
                });
              }

              $scope.tickets = [];

              // scoring details block tickets
              _.forEach(
                _.get(_.find($scope.meeting.widgets, { type: 'details' }), 'data', {}).dimensions,
                function (dimension) {
                  _.forEach(dimension.blocks, function (block) {
                    $scope.tickets = _.concat(
                      $scope.tickets,
                      _.map(block.tickets, function (ticket) {
                        return _.extend(_.clone(ticket), {
                          title: dimension.name + ' - ' + block.name + ' - ' + ticket.title,
                          name: ticket.title,
                          blockId: block.id,
                          meetingId: $scope.meeting.id,
                        });
                      }),
                    );
                  });
                },
              );

              // service bundle review tickets
              _.forEach(
                _.get(_.find($scope.meeting.widgets, { type: 'bundlereview' }), 'data', {})
                  .serviceBundles,
                function (serviceBundle) {
                  $scope.tickets = _.concat(
                    $scope.tickets,
                    _.map(serviceBundle.tickets, function (ticket) {
                      return _.extend(_.clone(ticket), {
                        title: serviceBundle.name + ' - ' + ticket.title,
                        name: ticket.title,
                        serviceBundleId: serviceBundle.id,
                        meetingId: $scope.meeting.id,
                      });
                    }),
                  );
                },
              );

              // tickets from the task widget if exists
              var taskWidget = _.find($scope.meeting.widgets, { type: 'tasks' });
              if (taskWidget) {
                $scope.tickets = _.concat(
                  $scope.tickets,
                  _.map($scope.tasks, function (task) {
                    return _.extend(
                      _.pick(task, [
                        'id',
                        'name',
                        'hours',
                        'description',
                        'externalTicketId',
                        'fullPath',
                        'deadline',
                        'widgetId',
                        'isExportedInMail',
                      ]),
                      {
                        title: taskWidget.name + ' - ' + task.name,
                      },
                    );
                  }),
                );
              }

              $scope.ok = function (cb) {
                if ($scope.selected.board) {
                  $rootScope.$broadcast('dataLoadingStarted');
                  VcioService.post(
                    '/clients/' +
                      InputSanitizerService.sanitize($scope.meeting.ClientId) +
                      '/serviceboards/' +
                      InputSanitizerService.sanitize($scope.selected.board.id) +
                      '/tickets',
                    {
                      tickets: $scope.tickets.filter(function (ticket) {
                        return ticket.isChecked;
                      }),
                      externalUser: $scope.selected.assignee,
                      meetingId: $scope.meeting.id,
                    },
                  )
                    .then(function (result) {
                      // Set updated tickets on scope
                      var updatedTickets = $scope.tasks.map(function (task) {
                        var updateTicketIndex = result.tickets.findIndex(function (resTicket) {
                          return resTicket.id === task.id;
                        });

                        if (updateTicketIndex !== -1) {
                          return Object.assign({}, task, {
                            externalTicketId: result.tickets[updateTicketIndex].externalTicketId,
                            fullPath: result.tickets[updateTicketIndex].fullPath,
                          });
                        }
                        return task;
                      });

                      $scope.messages = [
                        {
                          code: 'message.overview.ticketsExported',
                          translateValues: {
                            url: '', // Result was empty originally, therefore I put an empty string here.
                            board: $scope.selected.board.name,
                            assignee: $scope.selected.assignee
                              ? $scope.selected.assignee.name + "'s"
                              : 'your',
                          },
                        },
                      ];
                      $scope.ok = undefined;
                      $rootScope.$broadcast('dataLoadingFinished');
                      cb();
                      UserEventService.event('auditTicketExported', {
                        surveyResultId: $scope.meeting.id,
                      });
                      $modalInstance.close({
                        tasks: _.filter(updatedTickets, function (ticket) {
                          return !ticket.meetingId;
                        }),
                        meetingWidgets: result.meetingWidgets,
                      });
                    })
                    .catch(function (error) {
                      if (error.code == 'error.notFound' && error.entity == 'client') {
                        error = $translate.instant('error.systemIntegrationNotSet', {
                          clientName: $scope.meeting.Client.name,
                          systemName: $scope.systemName,
                        });
                      }
                      $scope.errors = [error];
                      $rootScope.$broadcast('dataLoadingFinished');
                      cb();
                    });
                } else if ($scope.system === 'email') {
                  VcioService.post(
                    '/clients/' +
                      InputSanitizerService.sanitize($scope.meeting.ClientId) +
                      '/emailsync/' +
                      InputSanitizerService.sanitize($scope.exportType),
                    {
                      exportedItems: $scope[
                        $scope.exportTypeMap[$scope.exportType].itemArray
                      ].filter(function (item) {
                        return item.isChecked;
                      }),
                      meetingData: {
                        meetingId: $scope.meeting.id,
                        clientName: $scope.meeting.Client.name,
                        meetingName: $scope.meeting.name,
                        meetingURL: $window.location.href,
                        exportEmail: $scope.exportTypeMap[$scope.exportType].email,
                      },
                    },
                  )
                    .then(function (message) {
                      $rootScope.$broadcast($scope.exportTypeMap[$scope.exportType].eventName);
                      DialogService.message(
                        {
                          code: 'succes.externalSystems.email.exportTicket',
                          translateValues: { message: message.message },
                        },
                        $translate.instant('succes.externalSystems.email.exportTicket.title'),
                      );
                    })
                    .catch(function (error) {
                      DialogService.error(
                        {
                          code: 'error.externalSystems.email.exportTicket',
                          translateValues: {
                            clientName: error.clientName,
                            errorMessage: error.translateValues
                              ? error.translateValues.message
                              : error,
                          },
                        },
                        {
                          code: 'error.externalSystems.email.exportTicket.title',
                        },
                      );
                    });
                  $modalInstance.close();
                  cb();
                } else {
                  cb();
                }
              };

              $scope.cancel = function () {
                $modalInstance.dismiss();
              };
            },
            backdrop: 'static',
            //size: 'lg',
            resolve: {
              meeting: function () {
                return $scope.meeting;
              },
              tasks: function () {
                return $scope.tasks;
              },
              system: function () {
                return system;
              },
              exportType: function () {
                return exportType;
              },
              clientProjects: function () {
                return $scope.clientProjects;
              },
            },
          });

          if (system !== 'email') {
            modalInstance.result.then(
              function (result) {
                $scope.tasks = result.tasks;
                $scope.meeting.widgets = result.meetingWidgets;
              },
              function () {},
            );
          }
        }
      };

      $scope.createCesActivity = function () {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.get('/api/admin/ces/interactiontypes')
          .then(function (interactionTypes) {
            $rootScope.$broadcast('dataLoadingFinished');

            ModalFormService.open({
              templateUrl: "'/templates/client/ces-activity-edit.html'",
              controller: 'CesActivityEditController',
              ok: 'save',
              cancel: 'cancel',
              close: function (result) {
                $scope.cesActivities.push(result);
              },
              title: 'label.clients.createEngagementActivity',
              scope: {
                newCesActivity: {
                  type: 'actual',
                  name: $scope.meeting.name,
                  InteractionTypeId: _.get(_.find(interactionTypes, { type: 'report' }), 'id'),
                  InstanceId: $scope.meeting.ParentId || $scope.meeting.id,
                  date: new Date(),
                  // contactIds: [$scope.surveyResult.ContactId],
                  template: meeting.Template.Template,
                  // PlanId: data.planActivityId
                },
                client: $scope.meeting.Client,
                hide: {
                  type: true,
                  interactionType: true,
                  instance: true,
                },
              },
            });
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
          });
      };

      $scope.isMeetingProject = function (clientProject) {
        return MeetingService.isMeetingProject(clientProject, $scope.meeting);
      };

      $scope.createContractActionPlan = function () {
        var contract = {
          type: 'onetime',
          name: $scope.meeting.name,
          defaults: { hourlyRate: 0, monthlyPrice: 0, pricing: 'labour' },
          Services: {},
          MeetingId: $scope.meeting.id,
        };

        $http.get('/api/service/services?body=true').success(function (services) {
          var _services = {};
          services.forEach(function (service) {
            _services[service.id] = service;
          });
          _($scope.clientProjects)
            .filter($scope.isMeetingProject)
            .forEach(function (clientProject) {
              // _.forEach(_.get(_.find($scope.meeting.widgets, {type: 'details'}), 'data', {}).services, function (service) {
              var _service = _services[clientProject.ServiceId];
              if (_service && _service.type !== 'ongoing') {
                contract.Services[_service.id] = {
                  hours: _.sumBy(_service.todoLists, function (todoList) {
                    return _.sumBy(todoList.todo, function (task) {
                      return task.hours || 0;
                    });
                  }),
                  hourlyRate: contract.defaults.hourlyRate,
                  todoLists: _service.todoLists,
                  name: clientProject.name,
                  description: clientProject.description,
                  deliverable: clientProject.deliveralble,
                };
              }
            });

          $http
            .post('/api/vcio/contracts' + '?clientId=' + $scope.meeting.ClientId, {
              contract: contract,
            })
            .success(function (result) {
              $state.go('vcio.contractEdit', { contractId: result.id });
            })
            .error(function (error) {
              $scope.errors = [error];
            });
        });
      };
      $scope.updateFromTemplate = function () {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.get(
          '/api/meeting/templates/' +
            InputSanitizerService.sanitize($scope.meeting.Template.id) +
            '?deleted=true',
        )
          .then(function (template) {
            $rootScope.$broadcast('dataLoadingFinished');
            $scope.template = template;
            var modalInstance = $modal.open({
              template:
                '<modal-form onok="ok" oncancel="cancel" form-title="label.meeting.updateFromTemplate.title" title-values="{template: meeting.Template.name}">' +
                '<p translate="label.meeting.updateFromTemplate.text"></p>' +
                '<abm-form-group class="no-margin-top">' +
                '<abm-checkbox model="update.sections" label="label.meeting.updateFromTemplate.sections"></abm-checkbox>' +
                '<p><p/>' +
                '<abm-checkbox model="item.value" label="{{item.label}}" ng-repeat="item in update.widgets"></abm-checkbox>' +
                '</abm-form-group>' +
                '</modal-form>',
              controller: function (
                $scope,
                $modalInstance,
                $translate,
                _,
                DialogService,
                meeting,
                template,
              ) {
                $scope.meeting = meeting;
                $scope.template = template;
                $scope.update = {};
                var updateItem = function (widget, type) {
                  return {
                    value: false,
                    uuid: widget.uuid,
                    orderId: widget.orderId,
                    label: $translate.instant('label.meeting.updateFromTemplate.' + type, {
                      name: widget.name || $translate.instant('meetingWidget.' + widget.type),
                    }),
                    type: type,
                  };
                };
                $scope.update.widgets = _.map($scope.meeting.widgets, function (widget) {
                  return updateItem(
                    widget,
                    _.find($scope.template.widgets, { uuid: widget.uuid }) ? 'update' : 'delete',
                  );
                });

                _.forEach($scope.template.widgets, function (widget) {
                  if (!_.find($scope.meeting.widgets, { uuid: widget.uuid })) {
                    $scope.update.widgets.push(updateItem(widget, 'create'));
                  }
                });

                $scope.update.widgets = _.sortBy($scope.update.widgets, 'orderId');

                $scope.ok = function (cb) {
                  HttpService.post(
                    '/api/meeting/meetings/' + $scope.meeting.id + '/widgets/update',
                    {
                      widgets: _.filter($scope.update.widgets, { value: true }),
                      sections: $scope.update.sections,
                    },
                  )
                    .then(function (result) {
                      DialogService.message('label.meeting.updateFromTemplate.success');
                      $modalInstance.close(result);
                    })
                    .catch(function (error) {
                      DialogService.error(error);
                    });
                  cb();
                };

                $scope.cancel = function () {
                  $modalInstance.dismiss();
                };
              },
              backdrop: 'static',
              size: 'lg',
              resolve: {
                meeting: function () {
                  return $scope.meeting;
                },
                template: function (HttpService) {
                  return template;
                },
              },
            });

            modalInstance.result.then(
              function (result) {
                $state.reload();
                // $scope.$broadcast('meetingRefresh', result);
              },
              function () {},
            );
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
          });
      };

      $scope.$on('ticketExported', function () {
        HttpService.get(
          '/api/meeting/meetings/' + InputSanitizerService.sanitize($scope.meeting.id),
        ).then(function (meeting) {
          $scope.meeting = meeting;
        });
        $scope.getTasks();
      });

      $scope.getTasks = function () {
        if ($scope.isSnapshot) {
          const taskWidget = _.find($scope.meeting.widgets, { type: 'tasks' });
          $scope.tasks = _.get(taskWidget, 'data.tasks', null);
        } else {
          HttpService.get(
            '/api/tasks?meetingId=' + InputSanitizerService.sanitize($stateParams.meetingId),
          ).then(function (result) {
            $scope.tasks = result ? _.orderBy(result, 'rownum') : [];
          });
        }
      };
      $scope.getTasks();

      $scope.addTaskFromWidget = function (widgetId) {
        if ($scope.selected.widgetTaskTemplate) {
          const taskTemplate = _.cloneDeep($scope.selected.widgetTaskTemplate);
          $scope.selected = {};

          if (taskTemplate.id === -1) {
            $scope.newTaskModal(widgetId);
          } else {
            const task = {
              name: taskTemplate.name,
              description: taskTemplate.description,
              hours: taskTemplate.hours,
              TaskTemplateId: taskTemplate.id,
              widgetId: widgetId,
            };

            $scope.createTask(task);
          }
        }
      };

      $scope.createTask = function (task) {
        if (!$scope.isSnapshot) {
          task.MeetingId = $stateParams.meetingId;
          const maxRownum = _.max(
            $scope.tasks.map(function (task) {
              return task.rownum;
            }),
          );
          task.rownum = maxRownum ? maxRownum + 1 : 0;

          HttpService.post('/api/tasks', { task: task }).then(function () {
            $scope.getTasks();
            $scope.reorderTasks();
          });
        }
      };

      $scope.getWidgetTasks = function (widgetId) {
        return _.filter($scope.tasks, function (task) {
          return task.widgetId === widgetId;
        });
      };

      $scope.showToastMessage = MeetingService.showToastMessage;

      $scope.deleteTask = function (task) {
        if (!$scope.isSnapshot && task) {
          HttpService.delete('/api/tasks/' + task.id).then(function () {
            $scope.getTasks();
            $rootScope.$broadcast('reloadMPDOnepager');
          });
        }
      };

      $scope.newTaskModal = function (widgetId) {
        var modalInstance = $modal.open($scope.getTaskEditModal());

        modalInstance.result.then(function (task) {
          if (task.deadline) {
            task.deadline = $moment(task.deadline).format('YYYY-MM-DD');
          }

          task.widgetId = widgetId;
          $scope.createTask(task);
        });
      };

      $scope.updateTaskModal = function (task) {
        var modalInstance = $modal.open($scope.getTaskEditModal(task));

        modalInstance.result.then(function (task) {
          if (task.deadline) {
            task.deadline = $moment(task.deadline).startOf('day');
          }

          $scope.updateTask(task);
        });
      };

      $scope.getTaskEditModal = function (task) {
        return {
          templateUrl: '/templates/tasks/task-edit.html',
          controller: function ($scope, $modalInstance, task) {
            $scope.edit = _.cloneDeep(task);
            $scope.today = new Date();

            $scope.save = function () {
              if ($scope.validForm()) {
                $modalInstance.close($scope.edit);
              }
            };

            $scope.cancel = function () {
              $modalInstance.dismiss();
            };

            $scope.validForm = function () {
              return $scope.edit.name;
            };
          },
          resolve: {
            task: function () {
              return task || {};
            },
          },
          backdrop: 'static',
        };
      };

      $scope.updateTask = function (task) {
        if (!$scope.isSnapshot) {
          HttpService.put('/api/tasks/' + task.id, { task: task }).then(function () {
            $scope.getTasks();
          });
        }
      };

      $scope.reorderTasks = function () {
        if (!$scope.isSnapshot) {
          setTimeout(function () {
            const tasks = $scope.tasks.map(function (task, index) {
              task.rownum = index;
              return task;
            });

            HttpService.post('/api/tasks/updateMany', { tasks: tasks }).then(function () {
              $scope.getTasks();
              $rootScope.$broadcast('reloadMPDOnepager');
            });
          }, 0);
        }
      };
    },
  )

  .controller('MeetingPrintController', function ($scope, $rootScope, _, MeetingService) {
    $scope.MeetingService = MeetingService;
    $scope.isPrint = true;

    $scope.getWidgetControllerName = function (widget) {
      return 'Meeting' + _.capitalize(_.get(widget, 'type')) + 'Controller';
    };

    $scope.getWidget = function (type, uuid) {
      var filtered = _.filter($scope.meeting.widgets, { type: type });
      if (_.size(filtered) === 1) {
        return filtered[0];
      } else if (_.size(filtered) > 1) {
        return _.find(filtered, { uuid: uuid });
      } else {
        return undefined;
      }
    };

    $scope.getWidgetData = function (type, uuid) {
      return _.get($scope.getWidget(type, uuid), 'data', {});
    };

    $scope.getService = function (id) {
      return _.find($scope.services, { id: id });
    };

    $scope.scrollToElement = function (elementId) {
      document.getElementById(elementId).scrollIntoView();
    };

    $scope.getRecommendation = function (statement) {
      if (statement.statementType === 'task' && statement.taskTemplateId) {
        return (
          _.find($scope.tasks, { TaskTemplateId: statement.taskTemplateId }) ||
          _.find($scope.taskTemplates, { id: statement.taskTemplateId })
        );
      } else if (statement.statementType === 'solutionSet' && statement.solutionSetId) {
        return statement.$$solutionSet;
      } else if (statement.statementType === 'course' && statement.courseId) {
        return statement.$$course;
      } else if (
        (statement.statementType === 'onetime' ||
          statement.statementType === 'ongoing' ||
          !statement.statementType) &&
        statement.serviceId
      ) {
        // this should be the default case with older meetings: if ((statement.statementType === 'onetime' || statement.statementType === 'ongoing') && statement.serviceId) {
        return _.find($scope.services, { id: statement.serviceId });
      }
    };
  })

  .controller('MeetingCategoriesEditController', function ($scope, widget) {})
  .controller('MeetingActionplanEditController', function ($scope, widget) {})
  .controller('MeetingProjectsEditController', function ($scope, widget) {})
  .controller('MeetingPersonalroadmapEditController', function ($scope, widget) {})
  .controller('MeetingTasksEditController', function ($scope, widget) {})
  .controller('MeetingCategoriesController', function ($scope, _, widget) {
    $scope.widget = widget;
    $scope.detailsWidget = $scope.getWidget('details');
  })
  .controller(
    'MeetingActionplanController',
    function (
      $http,
      $modal,
      $moment,
      $rootScope,
      $scope,
      $state,
      $translate,
      _,
      DialogService,
      HttpService,
      ModalSelector,
      widget,
      CurrentUser,
      CompanyService,
    ) {
      $scope.widget = widget;
      $scope.widget.data = $scope.widget.data || {};
      $scope.client = $scope.meeting.Client;
      $scope.currency = $scope.meeting.Owner.currency;
      $scope.detailsWidgetData = _.get(
        _.find($scope.meeting.widgets, { type: 'details' }),
        'data',
        {},
      );
      widget.data.showAllProjects =
        widget.data.showAllProjects === undefined ? true : widget.data.showAllProjects;
      $scope.showAllProjects = widget.data.showAllProjects;
      $scope.projects = _.filter($scope.services, function (service) {
        return (service.type === 'any' || service.type === 'onetime') && !service.deletedAt;
      });
      $scope.projects.unshift({
        name: $translate.instant('label.report.actionplan.createProject'),
      });
      $scope.changeShowAllProjects = function () {
        $scope.showAllProjects = !$scope.showAllProjects;
        widget.data.showAllProjects = $scope.showAllProjects;
        if (!$scope.isSnapshot) {
          $scope.saveWidgets($scope.widget);
        }
      };

      $scope.timeScale = $scope.widget.data.timeScale || 'Q';
      $scope.changeTimescale = function (t) {
        if ($scope.timeScale !== undefined && $scope.timeScale !== t) {
          $scope.timeScale = t;
          $scope.widget.data.timeScale = t;
          if (!$scope.isSnapshot) {
            $scope.saveWidgets($scope.widget);
          }
        }
      };

      // $scope.filterProjects = function () {
      //     $scope.projects = _($scope.services).filter({
      //         type: 'onetime',
      //         deletedAt: null
      //     }).map(function (service) {
      //         service.disabled = _.find($scope.clientProjects, function (clientProject) {
      //             return clientProject.ServiceId === service.id;
      //         }) || _($scope.detailsWidgetData.dimensions).flatMap('blocks').flatMap('statements').flatMap('serviceId').includes(service.id);
      //         return service;
      //     }).value();
      //     $scope.projects.unshift({name: $translate.instant('label.report.actionplan.createProject')})
      // }
      // $scope.filterProjects();

      const companyId = _.get($scope.currentUser, 'Company.id');
      if (companyId) {
        CompanyService.getProjectStatuses(companyId).then(function (projectStatuses) {
          if (projectStatuses) {
            $scope.projectStatuses = projectStatuses;
          }
        });
      }

      // $scope.isSurveyProject = function (clientProject) {
      //     return _($scope.detailsWidgetData.dimensions).flatMap('blocks').flatMap('statements').flatMap('serviceId').includes(clientProject.ServiceId)
      // }
      //
      // $scope.isSelectedSurveyProject = function (clientProject) {
      //     return _($scope.detailsWidgetData.dimensions).flatMap('blocks').flatMap('statements').find(function (statement) {
      //         return statement.serviceId === clientProject.ServiceId && statement.target === true
      //     })
      // }

      $scope.showClientProjectDetails = function (clientProject) {
        return $modal.open({
          templateUrl: '/templates/client/client-project-show.html',
          backdrop: 'static',
          size: 'lg',
          controller: function ($scope, $modalInstance) {
            $scope.clientProject = clientProject || {};
            $scope.clientProject.deliverable = $scope.clientProject.deliverable || {};

            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
        });
      };
    },
  )

  .controller(
    'MeetingProjectsController',
    function ($sce, $scope, $rootScope, _, MeetingService, widget, ProjectPriceService) {
      $scope.actionplanWidgetData = _.get(
        _.find($scope.meeting.widgets, { type: 'actionplan' }),
        'data',
        {},
      );

      $scope.meetingFilter = function (clientProject) {
        return (
          $scope.actionplanWidgetData.showAllProjects ||
          MeetingService.isMeetingProject(clientProject, $scope.meeting)
        );
      };

      // $scope.$watchCollection("clientProjects", function () {
      //     _($scope.clientProjects).forEach(function (clientProject) {
      //         clientProject.$$descriptionTrustedHtml = $sce.trustAsHtml(clientProject.description);
      //         clientProject.$$deliverableTrustedHtml = $sce.trustAsHtml(clientProject.deliverable);
      //     });
      // })

      $scope.budgetSum = function () {
        var sum = 0;
        _($scope.clientProjects)
          .filter($scope.meetingFilter)
          .forEach(function (clientProject) {
            _.forEach(
              ProjectPriceService.formatClientProjectPrices(clientProject.prices),
              function (item, c) {
                if (_.indexOf(clientProject.months, c) > -1) {
                  sum += item;
                }
              },
            );
          });
        return sum;
      };
    },
  )

  .controller(
    'MeetingPersonalroadmapController',
    function (
      $moment,
      $rootScope,
      $scope,
      $modal,
      $filter,
      $translate,
      uuid,
      _,
      AcademyService,
      DialogService,
      HttpService,
      MeetingService,
      widget,
      CurrentUser,
      InputSanitizerService,
    ) {
      $scope.widget = widget;
      $scope.personalRoadmap = $scope.isSnapshot
        ? $scope.widget.data.personalRoadmap
        : _.get($scope.meeting.User, 'personalRoadmap', []);
      $scope.editCell = {};
      $scope.newGoal = {};
      $scope.filter = {};
      $scope.goalStatuses = MeetingService.goalStatuses;
      $scope.getGoalStatusIcon = MeetingService.getGoalStatusIcon;
      $scope.getGoalStatus = MeetingService.getGoalStatus;
      $scope.isOwner = $scope.currentUser && $scope.meeting.UserId === $scope.currentUser.id;

      function getCourseForGoal(goal) {
        if (goal.courseId) {
          HttpService.get(
            '/api/academy/assets/' + InputSanitizerService.sanitize(goal.courseId),
          ).then(function (course) {
            goal.$$course = course;
          });
        }
      }

      function getSolutionSetForGoal(goal) {
        if (goal.solutionSetId) {
          HttpService.get(
            '/api/vendor/permissiongroups/' + InputSanitizerService.sanitize(goal.solutionSetId),
          ).then(function (solutionSet) {
            goal.$$solutionSet = solutionSet;
          });
        }
      }

      $scope.$watch('personalRoadmap.length', function () {
        // This will get the course or the solution set when a new item was added to the roadmap or when the items were reordered using the drag n drop feature
        _.forEach($scope.personalRoadmap, function (item) {
          if (item.courseId && !item.$$course) {
            getCourseForGoal(item);
          } else if (item.solutionSetId && !item.$$solutionSet) {
            getSolutionSetForGoal(item);
          }
        });
      });

      var startColumn = $moment().startOf('Q').subtract(2, 'Q');
      var timeScale = 'Q';

      var generateColums = function (start) {
        var coltime;
        return _.map(_.range(5), function (i) {
          coltime = $moment(start).add(i, timeScale);
          return {
            id: coltime.format('YYYYQ'),
            title: coltime.format('YYYY[Q]Q'),
          };
        });
      };

      $scope.timeColumns = generateColums(startColumn);

      $scope.setEditCell = function (userGoal, column) {
        if (!$scope.isSnapshot) {
          $scope.editCell.userGoal = userGoal;
          $scope.editCell.column = column;
        }
      };

      $scope.isEditCell = function (userGoal, column) {
        return $scope.editCell.userGoal == userGoal && $scope.editCell.column == column;
      };

      $scope.isBudgetCell = function (userGoal, column) {
        if (userGoal && column) {
          return _.includes(userGoal.quarters, column.id);
        } else {
          return false;
        }
      };

      $scope.prevTime = function () {
        startColumn.subtract(1, timeScale);
        $scope.timeColumns = generateColums(startColumn);
      };

      $scope.nextTime = function () {
        startColumn.add(1, timeScale);
        $scope.timeColumns = generateColums(startColumn);
      };

      $scope.savePersonalRoadmap = function () {
        if (!$scope.isSnapshot) {
          if ($scope.meeting.User) {
            const payload = $scope.personalRoadmap.map(function (roadmap) {
              return {
                id: roadmap.id,
                name: roadmap.name,
                status: roadmap.status,
                quarters: roadmap.quarters,
                meetingId: roadmap.meetingId,
                solutionSetId: roadmap.solutionSetId,
                courseId: roadmap.courseId,
              };
            });

            HttpService.post('/api/academy/users/' + $scope.meeting.User.id + '/roadmap', payload)
              .then(function () {
                $rootScope.$broadcast('reloadMPDOnepager');
              })
              .catch(function (error) {
                DialogService.error(error);
              });
          }
        } else {
          DialogService.error('error.meetings.saveSnapshot');
        }
      };

      $scope.addBudgetMonth = function (userGoal, column) {
        if (!$scope.isBudgetCell(userGoal, column)) {
          if (userGoal.courseId && userGoal.quarters.length === 0) {
            HttpService.post(
              '/api/academy/courses/' + userGoal.courseId + '/assign/' + $scope.meeting.User.id,
            ).then(function () {
              // This will update the course's status on the personal roadmap widget
              getCourseForGoal(userGoal);
            });
          }

          userGoal.quarters.push(column.id);
          $scope.savePersonalRoadmap();
        }
      };

      $scope.removeBudgetMonth = function (userGoal, column) {
        if ($scope.isBudgetCell(userGoal, column)) {
          userGoal.quarters.splice(_.indexOf(userGoal.quarters, column.id), 1);
          $scope.savePersonalRoadmap();

          if (userGoal.courseId && userGoal.quarters.length === 0) {
            HttpService.delete(
              '/api/academy/courses/' + userGoal.courseId + '/assign/' + $scope.meeting.User.id,
            ).then(function (course) {
              // This will update the course's status on the personal roadmap widget
              getCourseForGoal(userGoal);
            });
          }
        }
      };

      $scope.addGoal = function () {
        if ($scope.newGoal.name && !_.find($scope.personalRoadmap, { name: $scope.newGoal.name })) {
          $scope.personalRoadmap.push({
            id: uuid.v4(),
            name: $scope.newGoal.name,
            quarters: [],
            meetingId: $scope.meeting.id,
          });
          $scope.newGoal.name = '';
          $scope.savePersonalRoadmap();
        }
      };

      $scope.removeGoal = function (userGoal) {
        if (userGoal) {
          _.remove($scope.personalRoadmap, { id: userGoal.id });
          $scope.savePersonalRoadmap();

          if (userGoal.courseId) {
            HttpService.delete(
              '/api/academy/courses/' + userGoal.courseId + '/assign/' + $scope.meeting.User.id,
            );
          }
        }
      };

      $scope.changeGoalStatus = function (goal, status) {
        goal.status = status.code;
        $scope.savePersonalRoadmap();
      };

      $scope.editUserGoal = function (userGoal) {
        if (!$scope.isSnapshot) {
          var save = false;
          if (userGoal) {
            _($scope.personalRoadmap)
              .filter({ $$isEdit: true })
              .forEach(function (otherUserGoal) {
                otherUserGoal.$$isEdit = false;
                save = true;
              });
            userGoal.$$isEdit = true;
            setTimeout(function () {
              document.getElementById('personalRoadmapGoalNameInput').focus();
            }, 0);
          } else {
            _($scope.personalRoadmap)
              .filter({ $$isEdit: true })
              .forEach(function (userGoal) {
                userGoal.$$isEdit = false;
                save = true;
              });
          }
          if (save) {
            $scope.savePersonalRoadmap();
          }
        }
      };

      $scope.generateCoursePopover = AcademyService.generateCoursePopover;

      $scope.generateSolutionSetPopover = function (solutionSet) {
        if (!solutionSet) {
          return '';
        }

        var popoverText =
          '<div class="row row-eq-height">' +
          '<div class="col-xs-4 vertical-center">' +
          '<img class="img-responsive" src="/images/permissiongroups/' +
          solutionSet.id +
          '">' +
          '</div>' +
          '<div class="col-xs-8">' +
          '<h2>' +
          solutionSet.name +
          '</h2>' +
          '<h5>' +
          solutionSet.subtitle +
          '</h5>';

        if (solutionSet.marketplace) {
          if (!solutionSet.price || solutionSet.discountPrice === 0) {
            popoverText +=
              '<h4 style="display: inline-block;">' +
              $translate.instant('label.vendor.permissionGroup.free') +
              '</h4>';
          } else if (solutionSet.discountPrice > 0) {
            popoverText +=
              '<h4 style="display: inline-block; text-decoration: line-through;color:#a3a3a3">' +
              $filter('formatPrice')(solutionSet.discountPrice, 2) +
              '</h4>' +
              '<h4 style="display: inline-block;">' +
              $filter('formatPrice')(solutionSet.price, 2) +
              '</h4>';
          } else if (!solutionSet.discountPrice) {
            popoverText +=
              '<h4 style="display: inline-block;">' +
              $filter('formatPrice')(solutionSet.price, 2) +
              '</h4>';
          }
        } else {
          popoverText +=
            '<h4 style="display: inline-block;">' +
            $translate.instant('label.marketplace.private') +
            '</h4>';
        }

        popoverText +=
          '</div>' +
          '</div>' +
          '<div class="row margin-5x-top">' +
          '<div class="col-xs-4"></div>' +
          '<div class="col-xs-4 vertical-center">' +
          '<img class="img-responsive" src="/images/companies/' +
          solutionSet.VendorId +
          '">' +
          '</div>' +
          '<div class="col-xs-4"></div>' +
          '</div>';

        return popoverText;
      };

      $scope.openCourseDetailsModal = function (goal) {
        $modal.open({
          templateUrl: '/templates/academy/coaching-course-assets.html',
          controller: 'AcademyUserCourseAssetsModalController',
          backdrop: 'static',
          resolve: {
            course: function () {
              return goal.$$course;
            },
            user: function () {
              return $scope.meeting.User;
            },
            quizResults: function () {
              return HttpService.get(
                '/api/academy/quizzes/results/' +
                  InputSanitizerService.sanitize($scope.meeting.User.id),
              );
            },
          },
        });
      };

      $scope.isSubscribedToSolutionSet = function (permissionGroupId) {
        return $scope.meetingOwnersPermissionGroupIds.includes(permissionGroupId);
      };

      $scope.isOnCurrentMeeting = function (roadmapItem) {
        if ($scope.isSnapshot) {
          return roadmapItem.meetingId === $scope.meeting.ParentId || !roadmapItem.meetingId;
        } else {
          return roadmapItem.meetingId === $scope.meeting.id || !roadmapItem.meetingId;
        }
      };

      $scope.personalRoadmapFilter = function (roadmapItem) {
        if ($scope.showAllGoals && $scope.isOwner) {
          return true;
        } else {
          return $scope.isOnCurrentMeeting(roadmapItem);
        }
      };

      $scope.changeShowAllGoals = function () {
        $scope.showAllGoals = !$scope.showAllGoals;
        $scope.checkHideShowAllGoals();
      };

      $scope.checkHideShowAllGoals = function () {
        if (
          _.size($scope.personalRoadmap) ===
          _($scope.personalRoadmap).filter($scope.isOnCurrentMeeting).size()
        ) {
          $scope.hideShowAllGoals = true;
        }
      };

      $scope.disableReorder = function () {
        return $scope.isSnapshot || (!$scope.showAllGoals && !$scope.hideShowAllGoals);
      };

      $scope.checkHideShowAllGoals();
    },
  )

  .controller(
    'MeetingDetailsEditController',
    function (
      $rootScope,
      $modal,
      $scope,
      $translate,
      uuid,
      _,
      DialogService,
      HttpService,
      UploadService,
      widget,
    ) {
      $scope.widget = widget;
      if (!$scope.widget.data) {
        $scope.widget.data = {};
      }
      $scope.edit = {};
      $scope.solutionSets = [];
      $scope.courses = [];
      $scope.onetimeServices = _.filter($scope.services, function (service) {
        return service.type == 'onetime' || service.type == 'any';
      });
      $scope.ongoingServices = _.filter($scope.services, function (service) {
        return service.type == 'ongoing' || service.type == 'any';
      });

      $scope.collapsed = {
        welcomeText: false,
        goodbyeText: false,
        reportTranslations: false,
      };

      if (!$scope.widget.data.reportTranslations) {
        $scope.widget.data.reportTranslations = [
          { label: 'Current state', value: '' },
          { label: 'Future state', value: '' },
          { label: 'Statement', value: '' },
          { label: 'Recommendations', value: '' },
          { label: 'Note', value: '' },
          { label: 'Tickets', value: '' },
        ];
      }

      $scope.changeCollapse = function (propName) {
        $scope.collapsed[propName] = !$scope.collapsed[propName];
      };

      var findService = function (search) {
        return _.find($scope.services, search) || _.find($scope.serviceTemplates, search);
      };

      $scope.setService = function (statement) {
        if (statement.serviceId) {
          var service = findService({ id: statement.serviceId });
          if (!$scope.isTemplate && service && service.template) {
            service = findService({ TemplateId: statement.serviceId });
            if (service) {
              statement.serviceId = service.id;
            }
          }
        }
      };

      HttpService.get('/api/vendor/permissiongroups').then(function (solutionSets) {
        HttpService.get('/api/academy/assets?type=course').then(function (courses) {
          $scope.solutionSets = solutionSets;
          $scope.courses = courses;

          $scope.widget.data.dimensions = _.orderBy($scope.widget.data.dimensions, 'orderId');
          $scope.widget.data.dimensions.forEach(function (dimension, index) {
            dimension.orderId = index + 1;
            dimension.blocks = _.orderBy(dimension.blocks, 'orderId');
            _.forEach(dimension.blocks, function (block, index) {
              block.orderId = index + 1;
              block.statements.forEach(function (statement) {
                $scope.setService(statement);
                $scope.setStatementType(statement);
                statement.type = statement.type || 'number';
              });
              return block;
            });
            if (dimension.imageId) {
              dimension.imageUrl =
                '/images/surveydimensions/' + dimension.imageId + '?' + new Date().getTime();
            }
          });
        });
      });

      $scope.getStatementTypes = function () {
        if ($scope.meetingTemplate.type === 'client') {
          return [
            { name: $translate.instant('label.statementTypes.service'), id: 'ongoing' },
            { name: $translate.instant('label.statementTypes.project'), id: 'onetime' },
            { name: $translate.instant('label.statementTypes.task'), id: 'task' },
          ];
        } else if ($scope.meetingTemplate.type === 'private') {
          return [
            { name: $translate.instant('label.statementTypes.solutionSet'), id: 'solutionSet' },
            { name: $translate.instant('label.statementTypes.course'), id: 'course' },
          ];
        }
      };
      $scope.statementTypes = $scope.getStatementTypes();

      $scope.setStatementType = function (statement) {
        if (statement.serviceId && !statement.statementType) {
          const service = findService({ id: statement.serviceId });
          if (service) {
            statement.statementType = service.type;
          }
        }
      };

      $scope.clearRecommendations = function (statement) {
        statement.$$service = null;
        statement.serviceId = undefined;
        statement.taskTemplateId = undefined;
        statement.solutionSetId = undefined;
        statement.courseId = undefined;
      };

      $scope.deleteBlock = function (dimension, block) {
        _.remove(dimension.blocks, { id: block.id });
      };

      $scope.changeBlockAnswerType = function (block) {
        if (block.answerType === 'single') {
          block.answerType = 'multiple';
        } else {
          block.answerType = 'single';
        }
      };

      $scope.changeService = function (service) {};

      $scope.addBlock = function (dimension, newBlock) {
        if (newBlock && newBlock.name) {
          var blocks = _($scope.meetingTemplate.dimensions)
            .flatMap('blocks')
            .orderBy('orderId')
            .value();
          var newBlockObj = {
            name: newBlock.name,
            id: uuid.v4(),
            type: 'score',
            answerType: 'multiple',
            orderId: _.size(dimension.blocks) + 1,
            dimension: dimension.id,
            statements: [],
            $$expanded: true,
          };
          dimension.blocks.push(newBlockObj);
          newBlock.name = '';
        }
      };

      $scope.statementInserted = function (block, statement) {
        // if (_(block.statements).reject(statement).find({id: statement.id})) {
        //     statement.id = _(block.statements).map('id').max() + 1
        // }
        $scope.setService(statement);
        $scope.setStatementType(statement);
      };

      $scope.addStatement = function (block, newStatement) {
        if (newStatement && newStatement.name) {
          var statements = _.orderBy(block.statements, 'orderId');
          block.statements.push({
            name: newStatement && newStatement.name ? newStatement.name : '',
            id: uuid.v4(), //(_(block.statements).map('id').max() + 1) || 1,
            orderId: statements.length > 0 ? statements[statements.length - 1].orderId + 1 : 1,
            type: 'number',
          });
          newStatement.name = '';
        }
      };
      $scope.deleteStatement = function (block, statement) {
        _.remove(block.statements, { id: statement.id });
      };

      $scope.addDimension = function (newDimension) {
        if (!$scope.widget.data.dimensions) {
          $scope.widget.data.dimensions = [];
        }
        $scope.widget.data.dimensions.push({
          id: uuid.v4(),
          name: newDimension && newDimension.name ? newDimension.name : '',
          blocks: [],
          orderId: _.size($scope.widget.data.dimensions) + 1,
        });
        newDimension.name = '';
      };

      $scope.deleteDimension = function (dimension) {
        _.remove($scope.widget.data.dimensions, { id: dimension.id });
      };

      $scope.deleteService = function (statement) {
        delete statement.$$service;
      };

      $scope.openDimensionLogo = function (dimension) {
        var data = {
          dimension: dimension,
          id: dimension.imageId ? dimension.imageId : 0,
          maxWidth: 2048,
          maxHeight: 2048,
        };
        UploadService.openImage('surveydimensions', data).then(function (res) {
          //console.log(res);
          dimension.imageId = res.id;
          dimension.imageUrl = '/images/surveydimensions/' + res.id + '?' + new Date().getTime();
          $scope.save(function () {});
        });
      };

      $scope.deleteDimensionLogo = function (dimension) {
        dimension.imageId = '';
        delete dimension.imageUrl;
      };

      $scope.dimensionExpandToggle = function (dimension) {
        dimension.$$expanded = !dimension.$$expanded;
      };
      $scope.blockExpandToggle = function (block) {
        block.$$expanded = !block.$$expanded;
      };
      $scope.blockHiddenToggle = function (block) {
        block.hidden = !block.hidden;
      };

      $scope.moveDimensionUp = function (dimension) {
        if (dimension.orderId > 1) {
          var prevDimension = _.find($scope.widget.data.dimensions, {
            orderId: dimension.orderId - 1,
          });
          dimension.orderId--;
          prevDimension.orderId++;
        }
      };
      $scope.moveDimensionDown = function (dimension) {
        if (dimension.orderId < $scope.widget.data.dimensions.length) {
          var nextDimension = _.find($scope.widget.data.dimensions, {
            orderId: dimension.orderId + 1,
          });
          dimension.orderId++;
          nextDimension.orderId--;
        }
      };
      $scope.moveBlockUp = function (block, dimension) {
        if (block.orderId > 1) {
          var prevBlock = _.find(dimension.blocks, { orderId: block.orderId - 1 });
          block.orderId--;
          prevBlock.orderId++;
        }
      };
      $scope.moveBlockDown = function (block, dimension) {
        if (block.orderId < dimension.blocks.length) {
          var nextBlock = _.find(dimension.blocks, { orderId: block.orderId + 1 });
          block.orderId++;
          nextBlock.orderId--;
        }
      };
      $scope.moveBlock = function (block, dimension) {
        const modalInstance = $modal.open({
          template:
            '<modal-form onok="ok" oncancel="cancel" form-title="label.survey.block.move.title" title-values="{block: block.name}">' +
            '<div class="abm-form">\n' +
            '  <abm-form-group class="no-margin h6">\n' +
            '    <abm-ui-select model="selected.dimension" values="dimensions" placeholder="label.survey.block.move.dimension" allow-clear="false"></abm-ui-select>\n' +
            '    <abm-ui-select model="selected.position" values="positions" type="id" placeholder="label.survey.block.move.position" allow-clear="false"></abm-ui-select>\n' +
            '  </abm-form-group>' +
            '</div>' +
            '</modal-form>',
          controller: function ($scope, $modalInstance, block, dimension, dimensions) {
            $scope.selected = {};
            $scope.positions = [];
            $scope.block = block;
            $scope.dimension = dimension;
            // $scope.dimensions = _.reject(dimensions, {id: dimension.id});
            $scope.dimensions = _.map(dimensions, function (dimension) {
              if (dimension.id === $scope.dimension.id) {
                // dimension.name += ' (current)';
                $scope.selected.dimension = dimension;
              }
              return dimension;
            });

            $scope.ok = function () {
              if (
                $scope.selected.dimension &&
                $scope.selected.position !== undefined &&
                ($scope.selected.dimension.id !== $scope.dimension.id ||
                  $scope.selected.position !== $scope.block.orderId)
              ) {
                _.remove($scope.dimension.blocks, $scope.block);
                $scope.dimension.blocks.forEach(function (other) {
                  if (other.id !== $scope.block.id && other.orderId >= $scope.block.orderId) {
                    other.orderId--;
                  }
                });
                $scope.selected.dimension.blocks.splice($scope.selected.position, 0, $scope.block);
                $scope.block.orderId = _.min([
                  $scope.selected.position + 1,
                  _.size($scope.selected.dimension.blocks),
                ]);
                $scope.selected.dimension.blocks.forEach(function (other) {
                  if (other.id !== $scope.block.id && other.orderId >= $scope.block.orderId) {
                    other.orderId++;
                  }
                });
              }
              $modalInstance.close($scope.model);
            };

            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
            $scope.$watch('selected.dimension', function () {
              if ($scope.selected.dimension) {
                $scope.positions = _(_.size($scope.selected.dimension.blocks) + 1)
                  .times()
                  .map(function (index) {
                    if (
                      $scope.selected.dimension.id === $scope.dimension.id &&
                      $scope.block.orderId === index + 1
                    ) {
                      $scope.selected.position = index;
                    }
                    return { id: index, name: index + 1 };
                  });
              }
            });
          },
          backdrop: 'static',
          // size: 'lg',
          resolve: {
            block: function () {
              return block;
            },
            dimension: function () {
              return dimension;
            },
            dimensions: function () {
              return $scope.widget.data.dimensions;
            },
          },
        });
      };

      function addServices(selectedTemplates) {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.post('/api/service/services/templates', { templateIds: selectedTemplates })
          .then(function (services) {
            $rootScope.$broadcast('dataLoadingFinished');
            $scope.services = _.orderBy($scope.services.concat(services), 'name');

            _($scope.widget.data.dimensions)
              .flatMap('blocks')
              .flatMap('statements')
              .forEach(function (statement) {
                $scope.setService(statement);
              });

            $scope.save(function () {
              DialogService.message($translate.instant('message.survey.serviceAdded'));
            });
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
          });
      }

      $scope.addServiceToCatalogue = function (service) {
        addServices([service.id]);
      };

      $scope.editNote = function (statement, isInternal) {
        const modalInstance = $modal.open({
          templateUrl: '/templates/modal-richtext.html',
          controller: function (
            $scope,
            $modalInstance,
            model,
            label,
            labelOk,
            labelCancel,
            unsafeSanitizer,
          ) {
            $scope.model = isInternal ? statement.internalNote : statement.hint;
            $scope.label = label;
            $scope.labelOk = labelOk ? labelOk : 'button.ok';
            $scope.labelCancel = labelCancel ? labelCancel : 'button.cancel';
            $scope.unsafeSanitizer = unsafeSanitizer ? true : false;
            $scope.ok = function () {
              $modalInstance.close($scope.model);
            };

            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
          backdrop: 'static',
          size: 'lg',
          resolve: {
            model: function () {
              return $scope.model;
            },
            label: function () {
              return $scope.label;
            },
            labelOk: function () {
              return $scope.labelOk;
            },
            labelCancel: function () {
              return $scope.labelCancel;
            },
            unsafeSanitizer: function () {
              return $scope.unsafeSanitizer;
            },
          },
        });

        modalInstance.result.then(function (note) {
          isInternal ? (statement.internalNote = note) : (statement.hint = note);
        });
      };
    },
  )

  .controller(
    'MeetingDetailsController',
    function (
      $rootScope,
      $sce,
      $scope,
      $translate,
      HttpService,
      _,
      widget,
      $modal,
      Utils,
      uuid,
      MeetingService,
      InputSanitizerService,
    ) {
      $scope.widget = widget;
      if (!$scope.widget.data) {
        $scope.widget.data = { text: '' };
      }
      $scope.getGoalStatusIcon = MeetingService.getGoalStatusIcon;
      $scope.getGoalStatus = MeetingService.getGoalStatus;
      $scope.personalRoadmap = $scope.isSnapshot
        ? _.get(_.find($scope.meeting.widgets, { type: 'personalroadmap' }), 'data.personalRoadmap')
        : _.get($scope.meeting.User, 'personalRoadmap', []);
      $scope.reportTranslations = {};

      if ($scope.widget.data.reportTranslations) {
        $scope.widget.data.reportTranslations.forEach(function (translation) {
          var translationKey = translation.label.replace(/\s/g, '').toLowerCase();
          $scope.reportTranslations[translationKey] = !!translation.value
            ? translation.value
            : undefined;
        });
      }

      // fixing the bug where service templates remained in the MSP Template
      // https://trello.com/c/dlGPF4QN
      _.forEach($scope.widget.data.dimensions, function (dimension) {
        _.forEach(dimension.blocks, function (block) {
          // block.$$trustedHtmlComment = $sce.trustAsHtml(block.analyzeComment);
          // block.$$isEditComment = false;

          _.forEach(block.statements, function (statement) {
            if (!_.find($scope.services, { id: statement.serviceId }) && statement.serviceId) {
              var service =
                _.find($scope.services, {
                  TemplateId: statement.serviceId,
                  deletedAt: null,
                }) || _.find($scope.services, { TemplateId: statement.serviceId });
              if (service) {
                statement.serviceId = service.id;
              }
            }
          });
        });
      });
      _.forEach($scope.widget.data.services, function (_service) {
        if (!_.find($scope.services, { id: _service.id })) {
          var service =
            _.find($scope.services, {
              TemplateId: _service.id,
              deletedAt: null,
            }) || _.find($scope.services, { TemplateId: _service.id });
          if (service) {
            _service.id = service.id;
          }
        }
      });
      $scope.widget.data.services = _.uniqBy($scope.widget.data.services, 'id');
      // fix end

      $scope.newTicket = {};
      $scope.$on('meetingRefresh', function (event, meeting) {
        var _detailsWidget = _.find(meeting.widgets, { type: 'details' });
        if (_detailsWidget) {
          var _detailsWidgetData = _detailsWidget.data;
          $scope.widget.version = _detailsWidget.version;
          $scope.widget.data.services = _detailsWidgetData.services;
          $scope.widget.data.score = _detailsWidgetData.score;
          $scope.widget.data.targetScore = _detailsWidgetData.targetScore;
          _.forEach($scope.widget.data.dimensions, function (dimension) {
            var _dimension = _.find(_detailsWidgetData.dimensions, { id: dimension.id });
            if (_dimension) {
              dimension.score = _dimension.score;
              dimension.targetScore = _dimension.targetScore;
            }

            _.forEach(dimension.blocks, function (block) {
              var _block = _.find(_dimension.blocks, { id: block.id });
              if (_block) {
                block.score = _block.score;
                block.targetScore = _block.targetScore;
                _.forEach(block.statements, function (statement) {
                  var _statement = _.find(_block.statements, { id: statement.id });
                  if (_statement) {
                    statement.current = _statement.current;
                    statement.target = _statement.target;
                  }
                });
              }
            });
          });

          _.forEach($scope.widget.data.services, function (service) {
            service.$$hoverQuarterInput = {};
          });
          $scope.meeting.score = meeting.score;
          $scope.meeting.targetScore = meeting.targetScore;
          // $scope.widget.data.$$initialized = true;
          // OverviewStack2ChartService.setRangesData($scope.widget.data.ranges);
        }
        $rootScope.$broadcast('meetingDetailsRefreshed');
      });

      $scope.statementCheckboxStyle = function (statement, block, type) {
        if (!_.has(statement, type)) {
          statement[type] = false;
        }
        var result = '';
        if (block.answerType === 'single') {
          if (statement[type]) {
            result = 'fa-dot-circle-o';
          } else {
            result = 'fa-circle-o';
          }
        } else {
          if (statement[type]) {
            result = 'fa-check-square-o';
          } else {
            result = 'fa-square-o';
          }
        }
        if (type == 'target' && statement.current && block.answerType === 'multiple') {
          result = 'fa-check-square-o disabled';
        }

        return result;
      };

      $scope.addClientProjectByStatement = function (statement, service) {
        // var meetingPath = $scope.widget.uuid + '/' + dimension.id + '/' + block.id + '/' + statement.id;
        const actionPlanWidget = $scope.getWidget('actionplan');
        if (statement.target) {
          // statement is checked, project should be added to the roadmap manually by the blue plus,
          var clientProject = {
            name: service.name,
            description: service.description || '',
            deliverable: service.deliverable,
            ServiceId: statement.serviceId,
            // MeetingId: $scope.meeting.id,
            // meetingPath: meetingPath
          };
          HttpService.post(
            '/api/clients/' + $scope.meeting.ClientId + '/projects',
            clientProject,
          ).then(function (result) {
            $scope.saveWidgets(actionPlanWidget);
            // var existingClientProject = _.find($scope.clientProjects, {ServiceId: statement.serviceId});
            // if (!existingClientProject) {
            //     $scope.clientProjects.push(result);
            // } else {
            //     existingClientProject.ClientProjectMeetings = result.ClientProjectMeetings
            // }
          });

          $scope.showToastMessage(
            'success',
            $translate.instant('label.projects.created', {
              projectName: $scope.getRecommendation(statement).name,
              widgetName: actionPlanWidget.name,
            }),
            10000,
          );
        }
      };

      $scope.getStatusIconFromPersonalRoadmap = function (statement) {
        var query;
        if (statement.solutionSetId) {
          query = { solutionSetId: statement.solutionSetId };
        } else if (statement.courseId) {
          query = { courseId: statement.courseId };
        }
        const roadmapItem = _.find($scope.personalRoadmap, query);
        return roadmapItem ? 'fa fa-fw ' + $scope.getGoalStatusIcon(roadmapItem) : null;
      };

      $scope.savePersonalRoadmap = function () {
        if (!$scope.isSnapshot) {
          if ($scope.meeting.User) {
            const payload = $scope.personalRoadmap;

            HttpService.post('/api/academy/users/' + $scope.meeting.User.id + '/roadmap', payload)
              .then(function () {
                $rootScope.$broadcast('reloadMPDOnepager');
              })
              .catch(function (error) {
                DialogService.error(error);
              });
          }
        } else {
          DialogService.error('error.meetings.saveSnapshot');
        }
      };

      $scope.addRecommendationToPersonalRoadmap = function (statement) {
        const recommendation = $scope.getRecommendation(statement);
        const roadmapItem = {
          id: uuid.v4(),
          name: recommendation.name,
          meetingId: $scope.meeting.id,
          quarters: [],
        };

        if (statement.solutionSetId) {
          roadmapItem.solutionSetId = recommendation.id;
        } else if (statement.courseId) {
          roadmapItem.courseId = recommendation.id;
        }

        $scope.personalRoadmap.push(roadmapItem);
        $scope.savePersonalRoadmap();

        $scope.showToastMessage(
          'success',
          $translate.instant('label.meeting.widgets.personalRoadmap.itemAddedMessage', {
            itemName: $scope.getRecommendation(statement).name,
            widgetName: _.find($scope.meeting.widgets, { type: 'personalroadmap' }).name,
          }),
          10000,
        );
      };

      $scope.statementCheckboxChange = function (statement, block, type, dimension) {
        if (!$scope.isSnapshot) {
          statement[type] = !statement[type];
          var _statement = _.find(block.statements, { id: statement.id });
          // var statementsToRemoveClientProject = [];
          if (block.answerType === 'single') {
            if (_statement.target) {
              block.statements.forEach(function (other) {
                if (_statement.id != other.id && other.target) {
                  other.target = false;
                  // statementsToRemoveClientProject.push(other);
                }
              });
            }
            if (_statement.current) {
              block.statements.forEach(function (other) {
                if (_statement.id != other.id && other.current) {
                  other.current = false;
                }
              });
            }
          }

          $scope.saveWidgets($scope.widget, function () {
            // // don't add project automatically but link it when it is already there
            // if (statement.target) {
            //     if (_.find($scope.clientProjects, {ServiceId: statement.serviceId})) {
            //         $scope.addClientProjectByStatement(statement, block, dimension)
            //     }
            // } else {
            //     statementsToRemoveClientProject.push(statement)
            // }
            // if ($scope.meeting.Client) {
            //     _.forEach(statementsToRemoveClientProject, function (statement) {
            //         var clientProject = _.find($scope.clientProjects, {ServiceId: statement.serviceId});
            //         if (clientProject) {
            //             HttpService.delete('/api/clients/' + $scope.meeting.Client.id + '/projects/' + clientProject.id + '/' + $scope.meeting.id + '/' + $scope.widget.uuid + '%2F' + dimension.id + '%2F' + block.id + '%2F' + statement.id)
            //         }
            //     })
            // }
          });
          // if (statement.serviceId && _.get($scope.getService(statement.serviceId), 'type') !== 'ongoing') {
          //     $scope.addRemoveClientProjectByStatement(statement, block, _.find($scope.widget.data.dimensions, function (dimension) {
          //         return _.find(dimension.blocks, {id: block.id})
          //     }))
          // }
        }
      };

      var getProjectStatusIcon = function (status) {
        // TODO refactor with ClientsController
        return {
          planned: 'fa-calendar',
          approved: 'fa-calendar-check-o',
          inProgress: 'fa-clock-o',
          finished: 'fa-check',
          recommendation: 'fa-lightbulb-o',
          issue: 'fa-exclamation-triangle',
          none: '',
        }[status || 'recommendation'];
      };

      $scope.getClientProjectStatusIcon = function (serviceId) {
        var clientProject = _.find($scope.clientProjects, { ServiceId: serviceId });
        if (clientProject) {
          return getProjectStatusIcon(_.get(clientProject, 'status'));
          // } else if (serviceId) {
          //     return "fa-plus text-primary"
        } else {
          return '';
        }
      };

      $scope.remove = function (ticket, block) {
        _.remove(block.tickets, ticket);
        $scope.saveWidgets($scope.widget);
      };

      $scope.addTicket = function (ticketTitle, block) {
        if (ticketTitle) {
          block.tickets = block.tickets || [];
          block.tickets.push({ title: ticketTitle, $$expanded: true });
          $scope.newTicket = {};
          $scope.saveWidgets($scope.widget);
        }
      };

      $scope.blockHiddenToggle = function (block) {
        block.hidden = !block.hidden;
        $scope.saveWidgets($scope.widget);
      };

      $scope.blockHiddenDimension = function (dimension) {
        dimension.hidden = !dimension.hidden;
        $scope.saveWidgets($scope.widget);
      };

      // const copyScoreDetailsUrl = function (token) {
      //     const link = window.location.origin + '/fillreport?survey=' + $scope.meeting.uuid + '_' + token
      //     Utils.copyToClipboard(link)
      //     $scope.showToastMessage('success', $translate.instant('meetingWidget.linkCopied'))
      // }

      $scope.shareLink = function () {
        function generateNewUrl(cb) {
          HttpService.get(
            '/api/meeting/meetings/' +
              InputSanitizerService.sanitize($scope.meeting.id) +
              '/widgets/' +
              InputSanitizerService.sanitize($scope.widget.uuid) +
              '/token',
          ).then(function (result) {
            $scope.widget.token = result.token;
            cb(
              window.location.origin +
                '/fillreport?survey=' +
                $scope.meeting.uuid +
                '_' +
                $scope.widget.token,
            );
          });
        }

        const title = $translate.instant('label.meeting.share.title', {
          meetingName: $scope.meeting.name,
          widgetName: $scope.widget.name,
        });
        if ($scope.widget.token) {
          MeetingService.shareLink(
            window.location.origin +
              '/fillreport?survey=' +
              $scope.meeting.uuid +
              '_' +
              $scope.widget.token,
            {
              title: title,
              generateNewUrl: generateNewUrl,
            },
          );
        } else {
          generateNewUrl(function (url) {
            MeetingService.shareLink(url, { title: title });
          });
        }
      };

      $scope.save = function () {
        $scope.saveWidgets($scope.widget);
      };

      // $scope.saveBlockComment = function (block) {
      //     block.$$isEditComment = false;
      //     var u1 = block.$$trustedHtmlComment ? block.$$trustedHtmlComment.$$unwrapTrustedValue() : '';
      //     var u2 = $sce.trustAsHtml(block.analyzeComment) ? $sce.trustAsHtml(block.analyzeComment).$$unwrapTrustedValue() : '';
      //     if (u1 !== u2) {
      //         $scope.saveWidgets($scope.widget);
      //         block.$$trustedHtmlComment = $sce.trustAsHtml(block.analyzeComment);
      //     }
      // };
    },
  )

  .controller('MeetingHeaderEditController', function ($scope, widget) {
    $scope.widget = widget;
    $scope.widget.data = $scope.widget.data || {};
    $scope.widget.data.ranges = $scope.widget.data.ranges || [
      { name: 'Chaotic', color: '#ea413d' },
      { name: 'Ad-Hoc', color: '#ed742f' },
      { name: 'Standard', color: '#f5be41' },
      { name: 'Optimized', color: '#a8be4b' },
      { name: 'Agile', color: '#59bf90' },
    ];
  })

  .controller(
    'MeetingHeaderController',
    function (
      $rootScope,
      $scope,
      $q,
      _,
      FILENAME_REGEX,
      CurrentUser,
      DialogService,
      HttpService,
      widget,
    ) {
      $scope.widget = widget;
      $scope.widget.data.snapshots = $scope.widget.data.snapshots || [];
      $scope.selected = {};

      $scope.editMeetingName = function () {
        $scope.editName = true;
      };

      $scope.saveMeetingName = function () {
        if (FILENAME_REGEX.test($scope.meeting.name)) {
          var body = { id: $scope.meeting.id, name: $scope.meeting.name };
          if ($scope.meeting.ParentId) {
            body.ParentId = $scope.meeting.ParentId;
          }
          HttpService.put('/api/meeting/meetings', body)
            .then(function () {
              $scope.editName = false;
            })
            .catch(function (error) {
              DialogService.error(error);
            });
        } else {
          DialogService.error('error.invalidData');
        }
      };

      $scope.addSnapshot = function () {
        if ($scope.selected.snapshot && $scope.selected.snapshot.id) {
          $scope.widget.data.snapshots.push($scope.selected.snapshot.id);
          $scope.saveWidgets($scope.widget);
          $scope.selected.snapshot = undefined;
        }
      };

      $scope.removeSnapshot = function (snapshot) {
        if (snapshot) {
          $scope.widget.data.snapshots.splice(
            _.indexOf($scope.widget.data.snapshots, snapshot.id),
            1,
          );
        }
        $scope.saveWidgets($scope.widget);
      };

      $scope.snapshotListFilter = function (snapshot) {
        return !_.includes($scope.widget.data.snapshots, snapshot.id);
      };

      $scope.snapshotFilter = function (snapshot) {
        return _.includes($scope.widget.data.snapshots, snapshot.id);
      };

      $scope.getScore = function (snapshot) {
        return _.get(_.find(snapshot.widgets, { type: 'details' }), 'data.score');
      };

      $scope.getTargetScore = function (snapshot) {
        return _.get(_.find(snapshot.widgets, { type: 'details' }), 'data.score');
      };
    },
  )

  .controller('MeetingFreetextEditController', function ($scope, widget) {
    $scope.widget = widget;
  })

  .controller('MeetingFreetextController', function ($sce, $scope, _, widget) {
    $scope.widget = widget;
    $scope.widget.data.$$trustedHtml = $sce.trustAsHtml($scope.widget.data.text);
    $scope.widget.$$isEditing = false;
    // $scope.$watch('widget.data.text', function (newValue, oldValue) {
    //     if (newValue !== oldValue){
    //         $scope.save()
    //     }
    // });

    $scope.save = function () {
      $scope.widget.$$isEditing = false;
      var u1 = $scope.widget.data.$$trustedHtml
        ? $scope.widget.data.$$trustedHtml.$$unwrapTrustedValue()
        : '';
      var u2 = $sce.trustAsHtml($scope.widget.data.text)
        ? $sce.trustAsHtml($scope.widget.data.text).$$unwrapTrustedValue()
        : '';
      if (u1 !== u2) {
        $scope.saveWidgets($scope.widget);
        $scope.widget.data.$$trustedHtml = $sce.trustAsHtml($scope.widget.data.text);
      }
    };
    $scope.clickEditor = function () {
      $scope.widget.$$isEditing = true;
    };
  })

  // Service Reviews
  .controller('MeetingBundlereviewEditController', function ($scope, widget) {})
  .controller(
    'MeetingBundlereviewController',
    function ($scope, _, AcademyService, HttpService, ModalSelector, widget) {
      $scope.widget = widget;
      if (!$scope.widget.data.serviceBundles) {
        $scope.widget.data.serviceBundles = [];
      } else {
        _.forOwn($scope.widget.data.serviceBundles, function (bundle) {
          bundle.Services = _.sortBy(bundle.Services, ['name', 'id']);
        });
      }
      $scope.selectToAddTicket = {};
      $scope.allServiceBundles = [];

      $scope.save = function () {
        $scope.saveWidgets($scope.widget);
      };

      if (!$scope.serviceBundles) {
        HttpService.get('/api/service/servicebundles?owner=true').then(function (bundles) {
          $scope.allServiceBundles = _.map(bundles, function (bundle) {
            bundle.Services = _(bundle.Services)
              .map(function (service, id) {
                return _.extend(_.find($scope.services, { id: parseInt(id) }), service);
                // }).filter(function (service) {
                //     var s = _.find($scope.services, {id: service.id});
                //     return s && !s.deletedAt
              })
              .keyBy('id')
              .value();
            bundle.Services = _.sortBy(bundle.Services, ['name', 'id']);

            return bundle;
          });
        });
      } else {
        $scope.allServiceBundles = _.map($scope.serviceBundles, function (bundle) {
          bundle.Services = _(bundle.Services)
            .map(function (service, id) {
              return _.extend(_.find($scope.services, { id: parseInt(id) }), service);
              // }).filter(function (service) {
              //     var s = _.find($scope.services, {id: service.id});
              //     return s && !s.deletedAt
            })
            .keyBy('id')
            .value();
          bundle.Services = _.sortBy(bundle.Services, ['name', 'id']);

          return bundle;
        });
      }
      $scope.rates = [
        { color: '#fc2125', name: 'Red' },
        //{color: '#fd8208', name: 'Orange'},
        { color: '#fec309', name: 'Yellow' },
        { color: '#56d72b', name: 'Green' },
        //{color: '#1d9bf6', name: 'Blue'},
        //{color: '#7b7b7e', name: 'Grey'}
      ];

      $scope.selectServiceRate = function (service, rate) {
        if (service.rate && service.rate == rate) {
          service.rate = null;
        } else {
          service.rate = rate;
        }
        $scope.save();
      };

      $scope.addBundleToWidget = function () {
        ModalSelector.show(
          null,
          _.filter($scope.allServiceBundles, function (bundle) {
            return !_.find($scope.widget.data.serviceBundles, { id: bundle.id });
          }),
          function (bundle) {
            if (bundle) {
              $scope.widget.data.serviceBundles.push(bundle);
              $scope.save();
            }
          },
          true,
        );
      };
      $scope.removeBundleFromWidget = function (bundle) {
        _.remove($scope.widget.data.serviceBundles, function (item) {
          return item.id == bundle.id;
        });
        $scope.save();
      };

      $scope.ticketExpandToggle = function (ticket) {
        ticket.$$expanded = !ticket.$$expanded;
      };

      $scope.addTicket = function (ticketTitle, bundle) {
        if (ticketTitle) {
          bundle.tickets = bundle.tickets || [];
          bundle.tickets.push({ title: ticketTitle, $$expanded: true });
          $scope.selectToAddTicket = {};
          $scope.save();
        }
      };

      $scope.remove = function (ticket, bundle) {
        AcademyService.remove(ticket, bundle.tickets);
        $scope.save();
      };

      $scope.moveBundleUp = function (bundle) {
        var i = _.indexOf($scope.widget.data.serviceBundles, bundle);
        $scope.widget.data.serviceBundles.splice(i - 1, 0, bundle);
        $scope.widget.data.serviceBundles.splice(i + 1, 1);
        $scope.save();
      };

      $scope.moveBundleDown = function (bundle) {
        var i = _.indexOf($scope.widget.data.serviceBundles, bundle);
        $scope.widget.data.serviceBundles.splice(i + 2, 0, bundle);
        $scope.widget.data.serviceBundles.splice(i, 1);
        $scope.save();
      };
    },
  )

  // Service Selector
  .controller('MeetingPricecalculatorEditController', function ($scope, widget) {})

  .controller(
    'MeetingPricecalculatorController',
    function (
      $scope,
      $rootScope,
      $state,
      _,
      AcademyService,
      CurrentUser,
      HttpService,
      ModalSelector,
      widget,
      InputSanitizerService,
    ) {
      $scope.headerWidget = $scope.getWidget('header');
      $scope.detailsWidgetData = _.get(
        _.find($scope.meeting.widgets, { type: 'details' }),
        'data',
        {},
      );
      // $scope.actionplanWidgetData = _.get(_.find($scope.meeting.widgets, {type: 'actionplan'}), 'data', {});
      $scope.widget = widget;
      $scope.widget.data.serviceBundles =
        _($scope.widget.data.serviceBundles)
          .sortBy('orderId')
          .map(function (item, index) {
            return _.extend(item, { orderId: index });
          })
          .value() || [];
      $scope.widget.expanded = {
        servicesNotSelected: { show: true },
        servicesCurrent: { show: true },
        servicesFuture: { show: true },
      };
      $scope.recommended = {
        currentScore: $scope.meeting.score,
        recommendedScore: $scope.meeting.targetScore,
      };

      $scope.selectToAddTicket = {};
      $scope.allServiceBundles = [];
      if (!$scope.serviceBundles) {
        HttpService.get('/api/service/servicebundles?owner=true').then(function (bundles) {
          $scope.allServiceBundles = _.map(bundles, function (bundle) {
            bundle.Services = _(bundle.Services)
              .map(function (service, id) {
                return _.extend(_.find($scope.services, { id: parseInt(id) }), service);
              })
              .keyBy('id')
              .value();
            return bundle;
          });
          calculateRecommended();
        });
      } else {
        $scope.allServiceBundles = _.map($scope.serviceBundles, function (bundle) {
          bundle.Services = _(bundle.Services)
            .map(function (service, id) {
              return _.extend(_.find($scope.services, { id: parseInt(id) }), service);
            })
            .keyBy('id')
            .value();

          return bundle;
        });
        calculateRecommended();
      }

      $scope._services = _($scope.services)
        .filter(function (service) {
          return (
            (service.type !== 'onetime' &&
              _($scope.detailsWidgetData.dimensions)
                .flatMap('blocks')
                .flatMap('statements')
                .flatMap('serviceId')
                .includes(service.id)) ||
            _($scope.widget.data.serviceBundles)
              .flatMap('Services')
              .flatMap(function (services) {
                return _.keys(services);
              })
              .includes(service.id + '')
          );
        })
        .value();

      var isFutureService = function (service) {
        return _.find(/*_.reject(*/ $scope.detailsWidgetData.services /*, {type: 'onetime'})*/, {
          id: service.id,
        });
      };

      var isCurrentService = function (service) {
        return !!_($scope.detailsWidgetData.dimensions)
          .flatMap('blocks')
          .flatMap('statements')
          .filter(function (statement) {
            return !statement.target && statement.current;
          })
          .map('serviceId')
          .filter(undefined)
          .uniq()
          .includes(service.id);
      };

      var isNotSelectedService = function (service) {
        return (
          (_($scope.widget.data.serviceBundles)
            .flatMap('Services')
            .flatMap(function (services) {
              return _.keys(services);
            })
            .includes(service.id + '') ||
            _($scope.detailsWidgetData.dimensions)
              .flatMap('blocks')
              .flatMap('statements')
              .flatMap('serviceId')
              .includes(service.id)) &&
          !isCurrentService(service) &&
          !isFutureService(service)
        );
      };

      $scope.reindex = function (array) {
        _.forEach(array, function (item, index) {
          item.orderId = index + 1;
        });
      };

      $scope.onServiceMove = function (index, list) {
        $scope[list].splice(index, 1);
        $scope.reindex($scope[list]);
        $scope.widget.data[list] = _.map($scope[list], _.partialRight(_.pick, ['id', 'orderId']));
        $scope.saveWidgets($scope.widget);
      };

      function calculateServices() {
        $scope.futureServices = _($scope._services)
          .filter(isFutureService)
          .sortBy('orderId')
          .value();
        $scope.currentServices = _($scope._services)
          .filter(isCurrentService)
          .sortBy('orderId')
          .value();
        $scope.notSelectedServices = _($scope._services)
          .filter(isNotSelectedService)
          .sortBy('orderId')
          .value();
        $scope.reindex($scope.futureServices);
        $scope.reindex($scope.currentServices);
        $scope.reindex($scope.notSelectedServices);
        $scope.widget.data.futureServices = _.map(
          $scope.futureServices,
          _.partialRight(_.pick, ['id', 'orderId']),
        );
        $scope.widget.data.currentServices = _.map(
          $scope.currentServices,
          _.partialRight(_.pick, ['id', 'orderId']),
        );
        $scope.widget.data.notSelectedServices = _.map(
          $scope.notSelectedServices,
          _.partialRight(_.pick, ['id', 'orderId']),
        );
        // $scope.saveWidgets($scope.widget);
      }

      var mapService = function (list) {
        return _($scope._services)
          .map(function (_service) {
            var service = _.find(list, { id: _service.id });
            if (service) {
              return _.extend(_service, { orderId: service.orderId });
            } else {
              return null;
            }
          })
          .filter(null)
          .orderBy('orderId')
          .value();
      };
      if ($scope.widget.data.futureServices) {
        $scope.futureServices = mapService($scope.widget.data.futureServices);
        $scope.currentServices = mapService($scope.widget.data.currentServices);
        $scope.notSelectedServices = mapService($scope.widget.data.notSelectedServices);
      } else {
        calculateServices();
      }

      $scope.$on('meetingRefresh', function (event, meeting) {
        $scope.futureServices = mapService($scope.widget.data.futureServices);
        $scope.currentServices = mapService($scope.widget.data.currentServices);
        $scope.notSelectedServices = mapService($scope.widget.data.notSelectedServices);
      });

      $scope.calculatePrice = function (bundle) {
        if (bundle) {
          if (bundle.defaults.pricing == 'unit') {
            return bundle.defaults.unitPrice * bundle.units;
          } else {
            return bundle.defaults.monthlyPrice;
          }
        } else {
          return undefined;
        }
      };

      $scope.addBundleToWidget = function () {
        ModalSelector.show(
          null,
          _.filter($scope.allServiceBundles, function (bundle) {
            return !_.find($scope.widget.data.serviceBundles, { id: bundle.id });
          }),
          function (bundle) {
            if (bundle) {
              $scope.widget.data.serviceBundles.push(
                _.extend(bundle, { orderId: $scope.widget.data.serviceBundles.length }),
              );
              calculateServices();
              $scope.saveWidgets($scope.widget);
            }
          },
          true,
        );
      };

      $scope.removeBundleFromWidget = function (bundle) {
        _.remove($scope.widget.data.serviceBundles, { id: bundle.id });
        calculateServices();
        $scope.saveWidgets($scope.widget);
      };

      function calculateRecommended() {
        var recommendedMeeting = _.cloneDeep($scope.meeting);
        var recommendedDetailsWidgetData = _.get(
          _.find(recommendedMeeting.widgets, { type: 'details' }),
          'data',
        );

        recommendedDetailsWidgetData.services = _.union(
          recommendedDetailsWidgetData.services,
          _($scope.widget.data.serviceBundles)
            .filter({ recommended: true })
            .flatMap('Services')
            .flatMap(function (services) {
              return _.values(services);
            })
            .map('id')
            .filter(undefined)
            .map(function (serviceId) {
              return { id: serviceId };
            })
            .value(),
        );

        _.forEach(recommendedDetailsWidgetData.dimensions, function (dimension) {
          _.forEach(dimension.blocks, function (block) {
            _.forEach(block.statements, function (statement) {
              if (_.find(recommendedDetailsWidgetData.services, { id: statement.serviceId })) {
                statement.target = true;
              }
            });
          });
        });

        _.forEach($scope.detailsWidgetData.dimensions, function (dimension) {
          _.forEach(dimension.blocks, function (block) {
            _.forEach(block.statements, function (statement) {
              statement.$$recommended = false;
              if (
                _($scope.widget.data.serviceBundles)
                  .filter({ recommended: true })
                  .flatMap('Services')
                  .flatMap(function (services) {
                    return _.values(services);
                  })
                  .map('id')
                  .filter(undefined)
                  .includes(statement.serviceId)
              ) {
                statement.$$recommended = true;
              }
            });
          });
        });

        //FIXME calculate recommended
        HttpService.post('/api/meeting/meetings/calculate', recommendedMeeting).then(function (
          result,
        ) {
          $scope.widget.data.recommended = {
            currentScore: result.score,
            recommendedScore: result.targetScore,
          };
        });
      }

      $scope.recommendBundle = function (bundle) {
        bundle.recommended = !bundle.recommended;
        calculateRecommended();
      };

      // calculateServices();

      $scope.$on('meetingDetailsRefreshed', function (values) {
        calculateServices();
        calculateRecommended();
      });

      $scope.shiftBundleLeft = function (bundle) {
        if (bundle.orderId > 0) {
          _.forEach($scope.widget.data.serviceBundles, function (_bundle) {
            if (_bundle.orderId == bundle.orderId - 1) {
              _bundle.orderId = bundle.orderId;
            }
          });
          bundle.orderId = bundle.orderId - 1;
        }
        $scope.saveWidgets($scope.widget);
      };

      $scope.shiftBundleRight = function (bundle) {
        if (bundle.orderId < $scope.widget.data.serviceBundles.length - 1) {
          _.forEach($scope.widget.data.serviceBundles, function (_bundle) {
            if (_bundle.orderId == bundle.orderId + 1) {
              _bundle.orderId = bundle.orderId;
            }
          });
          bundle.orderId = bundle.orderId + 1;
        }
        $scope.saveWidgets($scope.widget);
      };

      $scope.createContract = function () {
        if (!_.isEmpty($scope.widget.data.serviceBundles)) {
          var services = {};
          _.forEach($scope.widget.data.serviceBundles, function (bundle) {
            services = _.extend(services, $scope.allServiceBundles[bundle.id].Services);
          });

          var contract = {
            type: 'ongoing',
            name: $scope.meeting.Template.name,
            // comment: bundle.comment,
            defaults: $scope.widget.data.serviceBundles[0].defaults,
            Services: services,
            SurveyResultId: $scope.meeting.id,
          };

          HttpService.post(
            '/api/vcio/contracts' +
              '?clientId=' +
              InputSanitizerService.sanitize($scope.meeting.ClientId),
            {
              contract: contract,
            },
          )
            .then(function (result) {
              $state.go('vcio.contractEdit', { contractId: result.id });
            })
            .catch(function (error) {
              $scope.errors = [error];
            });
        }
      };
    },
  )

  .controller(
    'MeetingMpdonepagerController',
    function (
      $scope,
      $rootScope,
      CurrentUser,
      DialogService,
      HttpService,
      MeetingService,
      widget,
      meetingId,
      parentId,
    ) {
      $scope.widget = widget;
      $scope.editedCell = null;
      $scope.meetingId = meetingId;
      $scope.parentId = parentId;
      // $scope.onepager = {} for share and print initialized from MeetingPringController
      $scope.loadMpdData = function () {
        if (CurrentUser.isAuthenticated()) {
          HttpService.get(
            '/api/overview/' +
              $scope.meetingId +
              '/mpd' +
              ($scope.parentId ? '?parentId=' + $scope.parentId : ''),
          ).then(function (response) {
            $scope.onepager = response;
          });
        }
      };
      $scope.loadMpdData();
      $scope.$on('meetingRefresh', function () {
        $scope.loadMpdData();
      });
      $scope.$on('reloadMPDOnepager', function () {
        $scope.loadMpdData();
      });

      $scope.getFinancialsLocalizationKey = function (rowName) {
        return 'financialSummary.' + rowName;
      };

      $scope.getGoalStatusIcon = MeetingService.getGoalStatusIcon;
      $scope.getGoalStatus = MeetingService.getGoalStatus;

      $scope.getChartPopover = function (chart) {
        if (_.isEmpty(chart.questions)) {
          return '';
        }
        var result = '<table>';
        _.forEach(chart.questions, function (question) {
          result +=
            '<tr><td>' +
            question.name +
            '</td><td>' +
            _.defaultTo(question.average, '') +
            '</td></tr>';
        });
        return result + '</table>';
      };
    },
  )

  .controller(
    'MeetingMpdonepagerEditController',
    function ($scope, $translate, widget, availableWidgets) {
      $scope.widget = widget;
      $scope.availableWidgets = availableWidgets || [];
      if (
        !$scope.widget.data ||
        !$scope.widget.data.sections ||
        !$scope.widget.data.sections.length
      ) {
        $scope.widget.data = { sections: [] };
      }
      const defaultSectionsData = [
        {
          id: 'personal_business_goals',
          type: 'main',
          name: '',
          withWidget: false,
          subSections: [
            {
              id: 'curr_quarter_goals',
              type: 'sub',
              name: '',
              withWidget: true,
              widget: null,
            },
            {
              id: 'prev_quarter_goals',
              type: 'sub',
              name: '',
              withWidget: true,
              widget: null,
            },
            {
              id: 'what_went_well',
              type: 'sub',
              name: '',
              withWidget: true,
              widget: null,
            },
            {
              id: 'what_could_have_gone_better',
              type: 'sub',
              name: '',
              withWidget: true,
              widget: null,
            },
          ],
        },
        {
          id: 'strategy_operations_finance',
          type: 'main',
          name: '',
          withWidget: false,
          subSections: [
            {
              id: 'financial_targets',
              type: 'sub',
              name: '',
              withWidget: true,
              widget: null,
            },
            {
              id: 'financial_summary',
              type: 'sub',
              name: '',
              withWidget: false,
              widget: null,
            },
          ],
        },
        {
          id: 'health_assessment_scorecards',
          type: 'main',
          name: '',
          withWidget: false,
          subSections: [
            {
              id: 'life_work',
              type: 'sub',
              name: '',
              withWidget: true,
              widget: null,
            },
            {
              id: 'family_spouse',
              type: 'sub',
              name: '',
              withWidget: true,
              widget: null,
            },
            {
              id: 'leadership',
              type: 'sub',
              name: '',
              withWidget: true,
              widget: null,
            },
            {
              id: 'legacy',
              type: 'sub',
              name: '',
              withWidget: true,
              widget: null,
            },
          ],
        },
        {
          id: 'blockers_plans',
          type: 'main',
          name: '',
          withWidget: false,
          subSections: [
            {
              id: 'blockers_annual_targets',
              type: 'sub',
              name: '',
              withWidget: true,
              widget: null,
            },
            {
              id: 'primary_challenges',
              type: 'sub',
              name: '',
              withWidget: true,
              widget: null,
            },
            {
              id: 'one_big_thing',
              type: 'sub',
              name: '',
              withWidget: true,
              widget: null,
            },
            {
              id: 'my_next_steps',
              type: 'sub',
              name: '',
              withWidget: false,
            },
            {
              id: 'business_plan',
              type: 'sub',
              name: '',
              withWidget: true,
              widget: null,
            },
            {
              id: 'leadership_plan',
              type: 'sub',
              name: '',
              withWidget: true,
              widget: null,
            },
            {
              id: 'life_plan',
              type: 'sub',
              name: '',
              withWidget: true,
              widget: null,
            },
            {
              id: 'legacy_plan_business',
              type: 'sub',
              name: '',
              withWidget: true,
              widget: null,
            },
            {
              id: 'legacy_plan_personal',
              type: 'sub',
              name: '',
              withWidget: true,
              widget: null,
            },
            {
              id: 'thank_yous',
              type: 'sub',
              name: '',
              withWidget: true,
              widget: null,
            },
          ],
        },
      ];
      $scope.widget.data.sections = _.unionBy(
        $scope.widget.data.sections,
        defaultSectionsData,
        'id',
      );

      $scope.getSectionLabelKey = function (label) {
        return $translate.instant('meetingWidget.' + _.camelCase(label));
      };
      $scope.$on('availableWidgetRefresh', function (event, newWidgets) {
        $scope.availableWidgets = newWidgets || [];
      });
    },
  )

  .controller(
    'MeetingScorecardController',
    function (
      $filter,
      $modal,
      $scope,
      $rootScope,
      $translate,
      _,
      HttpService,
      MeetingService,
      widget,
      meetingId,
      InputSanitizerService,
    ) {
      $scope.widget.data.showAdditionalDetails = false;
      $scope.widget = widget;
      $scope.maxResponses = InputSanitizerService.sanitize(
        _.get($scope.widget.data, 'maxResponses'),
      );

      $scope.actual = $scope.widget.data.questions[0].values
        ? $scope.widget.data.questions[0].values.length
        : 0;

      $scope.translatedResponseNumber = $translate.instant(
        'meetingWidget.scoreCard.containingAnswers',
        {
          responses: $scope.actual,
          maxResponses: $scope.maxResponses,
        },
      );

      $scope.fillers = $scope.widget.data.questions[0].values;
      $scope.filteredFillers = $scope.fillers
        ? $scope.fillers.filter(function (value) {
            return value.email !== 'n/a';
          })
        : [];

      $scope.sliderOption = {
        floor: 0,
        ceil: 10,
        disabled: $scope.maxResponses > 0 || $scope.isSnapshot || $scope.isPrint,
      };

      $scope.setupData = function () {
        $scope.widget.modifyAnswersData = {};
        _.forEach($scope.widget.data.questions, function (question) {
          _.forEach(question.values, function (answer) {
            const createdAt = (answer.createdAt ? answer.createdAt : Date.now())
              .toString()
              .substring(0, 11);
            if ($scope.widget.modifyAnswersData[createdAt]) {
              $scope.widget.modifyAnswersData[createdAt].push({
                id: question.id,
                name: question.name,
                value: answer.value,
              });
            } else {
              $scope.widget.modifyAnswersData[createdAt] = [
                { id: question.id, name: question.name, value: answer.value },
              ];
            }
          });
        });
        if ($scope.maxResponses > 0) {
          $scope.sliderOption.step = 0.01;
          $scope.sliderOption.precision = 2;
          $scope.widget.data.questions = $scope.widget.data.questions.map(function (question) {
            return _.extend(question, {
              visibleValue:
                question.average ||
                (_.isEmpty(question.values) ? 0 : _.meanBy(question.values, 'value')),
            });
          });
        } else {
          _.forEach($scope.widget.data.questions, function (question) {
            question.visibleValue = !_.isEmpty(question.values) ? question.values[0].value : 0;
          });
        }
      };
      $scope.setupData();

      $scope.sliderOption.onEnd = function () {
        _.forEach($scope.widget.data.questions, function (question) {
          question.value = question.visibleValue;
          if (_.isEmpty(question.values)) {
            question.values = [{}];
          }
          question.values[0].value = question.visibleValue;
        });

        return HttpService.post(
          '/api/scorecard/answer/' +
            InputSanitizerService.sanitize(meetingId) +
            '/' +
            InputSanitizerService.sanitize($scope.widget.uuid),
          $scope.widget.data.questions,
        )
          .then(function (res) {
            $scope.widget.data.average = res.widget.data.average;

            $scope.showToastMessage(
              'success',
              $translate.instant('meetingWidget.scoreCard.saveSucceded'),
            );
          })
          .then(function () {
            $rootScope.$broadcast('reloadMPDOnepager');
          })
          .catch(function () {
            $scope.showToastMessage(
              'danger',
              $translate.instant('meetingWidget.scoreCard.saveFailed'),
            );
          });
      };

      $scope.shareLink = function () {
        MeetingService.shareLink(
          window.location.origin +
            '/fillscorecard?survey=' +
            $scope.meeting.uuid +
            '_' +
            widget.uuid,
          {
            title: $translate.instant('label.meeting.share.title', {
              meetingName: $scope.meeting.name,
              widgetName: $scope.widget.name,
            }),
          },
        );
      };

      $scope.editScoreCardAnswers = function () {
        var modalInstance = $modal.open({
          templateUrl: '/templates/meeting/widget-scorecard/edit-scorecard-answers.html',
          controller: 'EditScoreCardAnswersController',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            meetingId: function () {
              return meetingId;
            },
            widgetData: function () {
              return $scope.widget;
            },
            fillersData: function () {
              return $scope.fillers;
            },
          },
        });

        modalInstance.result.then(
          function (newAnswers) {
            if (newAnswers) {
              // refersh data
              const newWidget = _.find(newAnswers.widgets, {
                type: 'scorecard',
                uuid: $scope.widget.uuid,
              });
              if (newWidget) {
                $scope.widget.data.questions = newWidget.data.questions;
                $scope.setupData();
                $rootScope.$broadcast('reloadMPDOnepager');
              }
            }
          },
          function () {},
        );
      };

      $scope.editScorecardName = function (widget) {
        widget.data.$$editingName = true;
        widget.data.$$name = widget.data.name;

        setTimeout(function () {
          document.getElementById('scorecardNameInput').focus();
        }, 0);
      };

      $scope.saveScorecardName = function (widget, event) {
        if (event) {
          event.preventDefault();
        }
        if ($scope.validScorecardName(widget)) {
          widget.data.$$editingName = false;
          widget.data.name = widget.data.$$name;
          $scope.saveWidgets(widget);
        }
      };

      $scope.cancelScorecardName = function (widget) {
        widget.data.$$editingName = false;
      };

      $scope.validScorecardName = function (widget) {
        if (widget.data.$$name) {
          return true;
        }

        return false;
      };
    },
  )
  .controller(
    'MeetingScorecardEditController',
    function ($modal, $scope, $translate, _, widget, uuid) {
      $scope.widget = widget;

      if (!$scope.widget || !$scope.widget.data || !$scope.widget.data.questions) {
        $scope.widget.data = {
          name: '',
          title: '',
          showAdditionalDetails: true,
          maxResponses: 0,
          welcomeText: '',
          goodbyeText: '',
          questions: [],
          fillerData: {
            fillerName: '',
            fillerEmail: '',
            fillerNote: '',
          },
        };
      }
      $scope.newQuestion = {
        name: '',
      };

      $scope.changeShowAdditionalDetails = function () {
        $scope.showAdditionalDetails = !$scope.showAdditionalDetails;
        widget.data.showAdditionalDetails = $scope.showAdditionalDetails;
      };

      $scope.addQuestion = function () {
        if ($scope.newQuestion.name && $scope.newQuestion.name.trim()) {
          const newQuestionObj = {
            name: $scope.newQuestion.name.trim(),
            id: uuid.v4(),
          };
          $scope.widget.data.questions.push(newQuestionObj);
          $scope.newQuestion.name = '';
        }
      };

      $scope.deleteQuestionConfirm = function (id) {
        $scope.confirm($translate('confirm.question'), function () {
          $scope.widget.data.questions = $scope.widget.data.questions.filter(function (question) {
            return question.id !== id;
          });
        });
      };

      $scope.confirm = function (message, _ok, _cancel) {
        var modalInstance = $modal.open({
          templateUrl: '/templates/modal-confirm.html',
          controller: function ($scope, $modalInstance, message) {
            $scope.message = message;
            $scope.ok = function () {
              $modalInstance.close(true);
            };

            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
          resolve: {
            message: function () {
              return message;
            },
          },
        });

        modalInstance.result.then(function () {
          _ok();
        });
      };
    },
  )

  .controller(
    'EditScoreCardAnswersController',
    function (
      $scope,
      $rootScope,
      $modalInstance,
      _,
      HttpService,
      DialogService,
      meetingId,
      widgetData,
      fillersData,
      InputSanitizerService,
    ) {
      $scope.showAnswerList = false;
      $scope.questionsAnswered = true;
      $scope.widgetData = angular.copy(widgetData);
      $scope.fillersData = angular.copy(fillersData);
      $scope.widgetData.data.showAdditionalDetails = true;

      $scope.updateStatus = function () {
        $scope.questionsAnswered = true;
        _.forEach($scope.widgetData.modifyAnswersData, function (questions) {
          _.forEach(questions, function (q) {
            if (!q.value) {
              $scope.questionsAnswered = false;
            }
          });
        });
      };

      $scope.escapeQuoteMarks = function (text) { 
        const modifiedText = text.replace(/'/g, "&apos;").replace(/"/g, "&quot;");
        return modifiedText;
      }

      $scope.sliderOption = {
        floor: 0,
        ceil: 10,
        onEnd: $scope.updateStatus,
      };

      $scope.delete = function (ts) {
        delete $scope.widgetData.modifyAnswersData[ts];
      };

      $scope.ok = function () {
        if ($scope.questionsAnswered) {
          HttpService.post(
            '/api/scorecard/modify-answer/' +
              InputSanitizerService.sanitize(meetingId) +
              '/' +
              InputSanitizerService.sanitize($scope.widgetData.uuid),
            $scope.widgetData.modifyAnswersData,
          ).then(
            function (modified) {
              $modalInstance.close(modified);
            },
            function (error) {
              DialogService.error(error);
            },
          );
        }
      };

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };
      setTimeout(function () {
        $scope.showAnswerList = true;
      }, 0);
    },
  )

  .controller(
    'MeetingTaskTemplatesController',
    function ($scope, $translate, $modal, HttpService, CurrentUser) {
      $scope.editTemplate = null;
      $scope.newTemplateExpended = false;

      $scope.getTaskTemplates = function () {
        return HttpService.get('/api/tasktemplates').then(function (taskTemplates) {
          $scope.taskTemplates = taskTemplates;
        });
      };
      $scope.getTaskTemplates();

      $scope.updateTaskTemplate = function () {
        return HttpService.put('/api/tasktemplates');
      };

      $scope.editTaskTemplate = function (taskTemplate) {
        if (!$scope.editTemplate) {
          $scope.editTemplate = _.cloneDeep(taskTemplate);
          taskTemplate.$$editing = true;
        }
      };

      $scope.deleteTaskTemplate = function (taskTemplate) {
        $scope.confirm($translate('confirm.question'), function () {
          HttpService.delete('/api/tasktemplates/' + taskTemplate.id).then(
            setTimeout(function () {
              $scope.getTaskTemplates();
            }, 100),
          );
        });
      };

      $scope.newTaskTemplate = function () {
        if (!$scope.editTemplate) {
          $scope.newTemplateExpanded = true;
          $scope.editTemplate = {};
        }
      };

      $scope.cancelEdit = function (taskTemplate) {
        if (taskTemplate) {
          taskTemplate.$$editing = false;
        } else {
          $scope.newTemplateExpanded = false;
        }
        $scope.editTemplate = null;
      };

      $scope.saveEdit = function (taskTemplate) {
        if (!$scope.validForm()) {
          return;
        }
        $scope.confirm($translate('confirm.question'), function () {
          if (taskTemplate) {
            HttpService.put('/api/tasktemplates/' + taskTemplate.id, $scope.editTemplate).then(
              function () {
                $scope.taskTemplates = $scope.getTaskTemplates();
                $scope.editTemplate = null;
                taskTemplate.$$editing = false;
              },
            );
          } else {
            $scope.editTemplate.CompanyId = $scope.currentUser.Company.id;

            HttpService.post('/api/tasktemplates/', { taskTemplate: $scope.editTemplate }).then(
              function () {
                $scope.taskTemplates = $scope.getTaskTemplates();
                $scope.editTemplate = null;
                $scope.newTemplateExpanded = false;
              },
            );
          }
        });
      };

      $scope.validForm = function () {
        if (
          $scope.editTemplate.name &&
          $scope.editTemplate.description &&
          $scope.editTemplate.hours
        ) {
          return true;
        }

        return false;
      };

      $scope.confirm = function (message, _ok, _cancel) {
        var modalInstance = $modal.open({
          templateUrl: '/templates/modal-confirm.html',
          controller: function ($scope, $modalInstance, message) {
            $scope.message = message;
            $scope.ok = function () {
              $modalInstance.close(true);
            };

            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
          resolve: {
            message: function () {
              return message;
            },
          },
        });

        modalInstance.result.then(function () {
          _ok();
        });
      };
    },
  )

  .service('MeetingChartService', function (_) {
    var ranges = [
      { min: 0, max: 20, name: '0-20', length: 20, color: '#ea413d', class: 'red' },
      { min: 20, max: 40, name: '20-40', length: 20, color: '#ed742f', class: 'orange' },
      { min: 40, max: 60, name: '40-60', length: 20, color: '#f5be41', class: 'yellow' },
      { min: 60, max: 80, name: '60-80', length: 20, color: '#a8be4b', class: 'green' },
      { min: 80, max: 100, name: '80-100', length: 20, color: '#59bf90', class: 'darkgreen' },
    ];

    // var round = function (num, decimals) {
    //     return Math.round(num * (10 * decimals)) / (10 * decimals)
    // };

    var createHorizontalChartData = function (targetScore, score) {
      return {
        data: {
          //TODO check if targeted score is less than the user score
          labels: [''],
          datasets: [
            {
              label: 'Current',
              data: [score],
              backgroundColor: '#62A6DB',
              hoverBackgroundColor: '#62A6DB',
            },
            {
              label: 'Targeted',
              data: [targetScore - score],
              backgroundColor: '#59BF90',
              hoverBackgroundColor: '#59BF90',
            },
            {
              data: [100 - targetScore],
              backgroundColor: '#EEEEEE',
              hoverBackgroundColor: 'white',
            },
          ],
        },
        options: {
          scales: {
            xAxes: [{ stacked: true }],
            yAxes: [{ stacked: true }],
          },
          responsive: true,
          animation: false,
          showTooltips: false,
        },
      };
    };

    var createDimensionData = function (dimension) {
      dimension.horizontalChartData = createHorizontalChartData(
        dimension.targetScore,
        dimension.score,
      );
      dimension.hint = 'dimension.' + dimension.id + '.hint';
      // if (dimension.blocks) {
      //     _.forOwn(dimension.blocks, function (block) {
      //         block.horizontalChartData = createHorizontalChartData(block.targetScore, block.score);
      //     })
      // }
    };

    var setRangesData = function (surveyRanges) {
      if (surveyRanges) {
        _.forEach(ranges, function (range, index) {
          if (surveyRanges[index] && surveyRanges[index].color) {
            range.color = surveyRanges[index].color;
          }
        });
      }
    };

    var createChartData = function (scores) {
      scores.horizontalChartData = createHorizontalChartData(scores.targetScore, scores.score);
      // scores.execution.horizontalChartData = createHorizontalChartData(scores.execution.targetScore, scores.execution.score);
      // scores.management.horizontalChartData = createHorizontalChartData(scores.management.targetScore, scores.management.score);

      scores.dimensions.forEach(function (dimension) {
        createDimensionData(dimension);
      });
      scores.blocks.forEach(function (block) {
        block.horizontalChartData = createHorizontalChartData(block.targetScore, block.score);
      });
    };
    return {
      ranges: ranges,
      setRangesData: setRangesData,
      createChartData: createChartData,
    };
  })

  .controller(
    'MeetingTasksController',
    function ($sce, $scope, $translate, $stateParams, HttpService, widget) {
      $scope.selected = {};
      $scope.widget = widget;
      $scope.widget.data = $scope.widget.data || {};

      $scope.$on('meetingRefresh', function (event, meeting) {
        const detailsWidget = _.find(meeting.widgets, { type: 'details' });
        const tasksWidgetName = $scope.widget.name;

        _.forEach(detailsWidget.data.dimensions, function (dimension) {
          _.forEach(dimension.blocks, function (block) {
            _.forEach(block.statements, function (statement) {
              if (statement.taskTemplateId) {
                const existingTask = _.find($scope.tasks, function (task) {
                  return (
                    /*task.dimensionId === dimension.id &&*/ task.blockId === block.id &&
                    task.statementId === statement.id
                  );
                });

                if (!existingTask && statement.target) {
                  $scope.addTaskFromStatement(statement, block, dimension);

                  const message = $translate.instant('label.tasks.created', {
                    taskName: $scope.getRecommendation(statement).name,
                    widgetName: tasksWidgetName,
                  });
                  $scope.showToastMessage('success', message, 10000);
                } else if (existingTask && !statement.target) {
                  $scope.deleteTask(existingTask);

                  const message = $translate.instant('label.tasks.deleted', {
                    taskName: existingTask.name,
                    widgetName: tasksWidgetName,
                  });
                  $scope.showToastMessage('danger', message, 10000);
                }
              }
            });
          });
        });
      });

      $scope.addTaskFromTemplate = function (taskTemplate) {
        if (taskTemplate) {
          const task = {
            name: taskTemplate.name,
            description: taskTemplate.description,
            hours: taskTemplate.hours,
            TaskTemplateId: taskTemplate.id,
          };

          $scope.createTask(task);
        }
      };

      $scope.addTaskFromStatement = function (statement, block, dimension) {
        HttpService.get('/api/tasktemplates/' + statement.taskTemplateId).then(function (
          taskTemplate,
        ) {
          const task = {
            name: taskTemplate.name,
            description: taskTemplate.description,
            hours: taskTemplate.hours,
            MeetingId: $stateParams.meetingId,
            TaskTemplateId: taskTemplate.id,
            blockId: block.id,
            dimensionId: dimension.id,
            statementId: statement.id,
            statementPath: dimension.name + ' / ' + block.name + ' / ' + statement.name,
          };

          $scope.createTask(task);
        });
      };

      $scope.onTaskTemplateSelected = function (taskTemplate) {
        $scope.selected = {};

        if (taskTemplate.id === -1) {
          $scope.newTaskModal();
        } else {
          $scope.addTaskFromTemplate(taskTemplate);
        }
      };

      $scope.getTaskPopoverContent = function (task) {
        return $sce.trustAsHtml(
          '<p>' +
            (task.description || '') +
            '</p>' +
            '<div class="popover-footer">' +
            $translate.instant('label.hours', { value: task.hours || 0 }, 'messageformat') +
            '</div>',
        );
      };

      $scope.getWidgetName = function (widgetId) {
        if (widgetId) {
          return _.find($scope.meeting.widgets, { uuid: widgetId }).name;
        } else {
          return null;
        }
      };

      $scope.doneTask = function (task) {
        if (!$scope.isSnapshot) {
          task.done = !task.done;
          $scope.updateTask(task);
        }
      };
    },
  )

  .controller('MeetingScorecardsummaryEditController', function () {})
  .controller('MeetingScorecardsummaryController', function ($scope) {
    const refreshWidgets = function () {
      $scope.scorecards = $scope.meeting.widgets
        .filter(function (widgets) {
          return widgets.type === 'scorecard';
        })
        .map(function (widget) {
          return widget.data;
        });
    };

    refreshWidgets();

    $scope.$on('widgetDeleted', function () {
      refreshWidgets();
    });
  })

  .controller('MeetingScorecompareEditController', function () {})
  .controller(
    'MeetingScorecompareController',
    function ($filter, $q, $rootScope, $scope, _, CurrentUser, DialogService, HttpService, widget) {
      $scope.widget = widget;
      $scope.widget.data.meetingIds = $scope.widget.data.meetingIds || [];
      $scope.selected = {};
      $scope.headerWidget = $scope.getWidget('header');

      var createScoreData = function (meeting) {
        return _.extend(_.pick(meeting, ['id', 'name', 'createdAt', 'score', 'targetScore']), {
          ranges: _.get(meeting.widgets, '[0].data.ranges'),
        });
      };

      if (CurrentUser.isAuthenticated()) {
        HttpService.get(
          '/api/meeting/meetings?clientId=' + $scope.meeting.ClientId + '&widgetType=header',
        ).then(function (meetings) {
          $scope.clientMeetings = meetings;
          $scope.scores = _($scope.widget.data.meetingIds)
            .filter(function (meetingId) {
              return _.find($scope.clientMeetings, { id: meetingId });
            })
            .map(function (meetingId) {
              return createScoreData(_.find($scope.clientMeetings, { id: meetingId }));
            })
            .value();
        });
      } else {
        $scope.scores = _.map($scope.widget.data.meetingIds, function (meetingId) {
          return createScoreData(_.find($scope.clientMeetings, { id: meetingId }));
        });
      }

      $scope.addScore = function () {
        if ($scope.selected.meeting) {
          $scope.scores.push(createScoreData($scope.selected.meeting));
          $scope.widget.data.meetingIds.push($scope.selected.meeting.id);
          $scope.saveWidgets($scope.widget);
          $scope.selected.meeting = undefined;
          UserEventService.event('meetingWidgetScorecompareScoreAdded', {
            meetingId: $scope.meeting.id,
            modelId: $scope.meeting.TemplateId,
            modelName: 'MeetingTemplate',
          });
        }
      };

      $scope.removeScore = function (scoreData) {
        if (scoreData) {
          $scope.widget.data.meetingIds.splice(
            _.indexOf($scope.widget.data.meetingIds, scoreData.id),
            1,
          );
        }
        $scope.saveWidgets($scope.widget);
      };

      $scope.meetingListFilter = function (meeting) {
        return !_.includes($scope.widget.data.meetingIds, meeting.id);
      };

      $scope.scoreFilter = function (scoreData) {
        return _.includes($scope.widget.data.meetingIds, scoreData.id);
      };
    },
  )

  .controller('MeetingAssetsEditController', function ($scope, widget) {})
  .controller(
    'MeetingAssetsController',
    function (
      $http,
      $modal,
      $moment,
      $rootScope,
      $scope,
      $state,
      $translate,
      _,
      DialogService,
      HttpService,
      ModalSelector,
      widget,
      CurrentUser,
      CompanyService,
    ) {
      $scope.client = $scope.meeting.Client;

      if ($scope.meeting.Client && !$scope.isSnapshot && !$scope.isPrint) {
        HttpService.get('/api/clients/' + $scope.meeting.Client.id + '/clientassets').then(
          function (assets) {
            $scope.assets = assets;
          },
        );
      } else if ($scope.isSnapshot) {
        $scope.assets = _.get(
          _.find($scope.meeting.widgets, { type: 'assets' }),
          'data.clientAssets',
          {},
        );
      }

      $scope.showClientAsset = function (asset) {
        if (asset) {
          openClientAsset(asset, $scope.assets.clientId, $scope.meeting.Owner.currency).result.then(
            function (result) {
              _.extend(
                asset,
                _.pick(result, ['name', 'description', 'type', 'warrantyExpire', 'cost']),
              );
            },
          );
        }
      };

      var openClientAsset = function (asset, clientId, currency) {
        return $modal.open({
          templateUrl: '/templates/client/client-asset-show.html',
          backdrop: 'static',
          size: 'lg',
          controller: function ($scope, $modalInstance) {
            $scope.asset = asset || {};
            $scope.asset.details = $scope.asset.details || '';
            $scope.asset.ClientId = clientId;
            $scope.asset.warrantyExpire = asset.warrantyExpire
              ? new Date(asset.warrantyExpire)
              : null;

            $scope.asset.currency = currency;

            $scope.close = function () {
              $modalInstance.dismiss();
            };
          },
          resolve: {
            clientProject: function () {
              return _.cloneDeep(asset);
            },
          },
        });
      };
    },
  );
