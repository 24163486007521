angular.module('vcio-toolkit').factory('UploadService', function ($rootScope, $modal) {
  var uploadImageController = function (
    $scope,
    $state,
    $http,
    $modalInstance,
    Upload,
    $timeout,
    _,
    data,
  ) {
    var obj = data.obj;
    if (data.type === 'trainers') {
      $scope.title = 'label.trainerPhoto';
      $scope.titleValues = { name: obj.firstName + ' ' + obj.lastName };
    } else if (data.type === 'users') {
      $scope.title = 'button.uploadProfilePhoto';
    } else {
      $scope.title = 'title.logo';
      $scope.titleValues = { name: obj.name };
    }
    $scope.file2upload = null;
    $scope.imageUrl = '/images/' + data.type + '/' + obj.id + '?' + new Date().getTime();
    $scope.maxWidth = obj.maxWidth || 600;
    $scope.maxHeight = obj.maxHeight || 600;
    //$scope.maxSize = obj.maxSize || 512;
    var uploadUrl = obj.uploadUrl || '/api/images/' + data.type + '/' + obj.id;

    var checkImage = function (file, cb) {
      var img = new Image();
      img.src = file.dataUrl;
      if (img.width > $scope.maxWidth || img.height > $scope.maxHeight) {
        cb(
          'Too large image: ' +
            img.width +
            'x' +
            img.height +
            ' (max: ' +
            $scope.maxWidth +
            'x' +
            $scope.maxHeight +
            ')',
        );
        // } else if (file.size > $scope.maxSize * 1024) {
        //     file.$error = 'maxSize';
        //     cb(getErrorMessage(file));
      } else if (
        !(
          file.type === 'image/png' ||
          file.type === 'image/jpg' ||
          file.type === 'image/jpeg' ||
          file.type === 'image/gif'
        )
      ) {
        file.$error = 'pattern';
        cb(getErrorMessage(file));
      } else {
        cb();
      }
    };

    var getErrorMessage = function (file) {
      if (file.$error === 'maxWidth' || file.$error === 'maxHeight') {
        return (
          'Too large image: ' +
          img.width +
          'x' +
          img.height +
          ' (max: ' +
          $scope.maxWidth +
          'x' +
          $scope.maxHeight +
          ')'
        );
      } else if (file.$error === 'maxSize') {
        return (
          'Too large image: ' +
          Math.round((file.size / 1024) * 100) / 100 +
          ' kB (max: ' +
          $scope.maxSize +
          ' kB)'
        );
      } else if (file.$error === 'pattern') {
        return { code: 'error.invalidFileType', fields: ['jpg, png, gif'] };
      } else {
        return file.$error;
      }
    };

    $scope.preview = function (file, invalidFile) {
      // console.log(file, invalidFile);
      $scope.messages = [];
      if (file) {
        $timeout(function () {
          var fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = function (e) {
            $timeout(function () {
              $scope.errors = [];
              file.dataUrl = e.target.result;
              checkImage(file, function (error) {
                if (!error) {
                  $scope.file2upload = file;
                  $scope.messages = [];
                } else {
                  file.dataUrl = undefined;
                  $scope.errors = [error];
                }
              });
            });
          };
        });
      } else if (invalidFile.length > 0) {
        $scope.errors = [getErrorMessage(invalidFile[0])];
      }
    };

    var uploadLogo = function (file, cb) {
      $scope.errors = [];
      checkImage(file, function (error) {
        if (error) {
          $scope.errors = [error];
          cb(null, error);
        } else {
          Upload.upload({
            url: uploadUrl,
            method: 'POST',
            withCredentials: true,
            data: { file: file },
          })
            .progress(function (evt) {})
            .success(function (res) {
              $scope.messages = ['message.fileUploaded'];
              cb(res);
            })
            .error(function (error) {
              if (error && error.status == 400) {
                cb(null, { code: 'error.invalidFileType', fields: ['jpg, png'] });
              } else {
                cb(null, error);
              }
            });
        }
      });
    };

    $scope.upload = function (cb) {
      if ($scope.file2upload) {
        uploadLogo($scope.file2upload, function (data, error) {
          if (!error) {
            //$scope.imageUrl = '/images/' + data.type + '/' + data.id + '?' + new Date().getTime();
            if ($modalInstance) {
              $modalInstance.close(data);
            } else {
              cb(null, data);
            }
          }
          cb(error);
        });
      } else {
        cb();
      }
    };

    $scope.cancel = function () {
      if ($modalInstance) {
        $modalInstance.dismiss();
      }
    };
  };

  var uploadFileController = function ($scope, $http, $modalInstance, Upload, $timeout, _, data) {
    $scope.myFiles = [];
    var obj = data.obj;
    $scope.maxSize = obj.maxSize || 10;

    var checkFile = function (file, cb) {
      if (file.size > 1024 * 1024 * $scope.maxSize) {
        cb(
          'Too large file: ' +
            Math.round((file.size / 1024) * 100) / 100 +
            ' kB (max: ' +
            $scope.maxSize +
            ' mB)',
        );
      } else {
        cb();
      }
    };

    var upload = function (file, cb) {
      $scope.errors = [];
      checkFile(file, function (error) {
        if (error) {
          $scope.errors = [error];
        } else {
          Upload.upload({
            url: '/api/files/' + data.type + '/' + (data.obj.id || 'new'),
            method: 'POST',
            withCredentials: true,
            data: { file: file },
          })
            .progress(function (evt) {
              //console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '% file :' + evt.config.file.name);
              $scope.uploadPercentage = parseInt((100.0 * evt.loaded) / evt.total);
            })
            .success(function (data, status, headers, config) {
              //console.log('file ' + config.file.name + 'is uploaded successfully. Response: ' + data);
              $scope.messages = ['message.fileUploaded'];
              cb(_.extend(data, { filename: config.data.file.name }));
            })
            .error(function (error) {
              cb(null, error);
            });
        }
      });
    };

    $scope.ok = function (cb) {
      if ($scope.file2upload) {
        upload($scope.file2upload, function (data, error) {
          if (!error) {
            $modalInstance.close(data);
          }
          cb(error);
        });
      } else {
        cb();
      }
    };

    $scope.cancel = function () {
      $modalInstance.dismiss();
    };

    $scope.preview = function (files) {
      var file = _.get(files, 0);
      if (file) {
        $timeout(function () {
          var fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = function (e) {
            $timeout(function () {
              $scope.errors = [];
              file.dataUrl = e.target.result;
              $scope.file2upload = file;
            });
          };
        });
      }
    };
  };

  var openImage = function (type, obj) {
    var modalScope = $rootScope.$new();
    modalScope.modalInstance = $modal.open({
      templateUrl: '/templates/image-upload.html',
      controller: uploadImageController,
      scope: modalScope,
      backdrop: 'static',
      size: 'lg',
      resolve: {
        data: function () {
          return {
            obj: angular.copy(obj),
            type: type,
          };
        },
      },
    });

    return modalScope.modalInstance.result;
  };

  var openFile = function (type, obj) {
    var modalScope = $rootScope.$new();
    modalScope.modalInstance = $modal.open({
      templateUrl: '/templates/admin/library-upload.html',
      controller: uploadFileController,
      scope: modalScope,
      backdrop: 'static',
      resolve: {
        data: function () {
          return {
            obj: angular.copy(obj),
            type: type,
          };
        },
      },
    });

    return modalScope.modalInstance.result;
  };

  return {
    uploadImageController: uploadImageController,
    openImage: openImage,
    openFile: openFile,
    open: openImage,
  };
});
