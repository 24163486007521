angular
  .module('vcio-toolkit')

  // .controller('VcioNavController', function ($scope, $rootScope, $state, $translate, _, CurrentUser, VcioService, LocalStorage) {
  //     $scope.isCollapsed = true;
  //     $scope.currentUser = CurrentUser.getUser();
  //     $scope.systems = ['basecamp'/*, 'connectwise'*/, 'autotask'];
  //     if ($scope.currentUser.Company.keys.connectwise) {
  //         $scope.systems.push('connectwise');
  //     } else if (LocalStorage.get('selectedSystemId') === 'connectwise') {
  //         LocalStorage.delete('selectedSystemId');
  //     }
  //
  //     $rootScope.$on('login-changed-event', function () {
  //         $scope.currentUser = CurrentUser.getUser();
  //         if ($scope.currentUser.Company.keys.connectwise) {
  //             if (!_.includes($scope.systems, 'connectwise')) {
  //                 $scope.systems.push('connectwise');
  //             }
  //         } else {
  //             _.pull($scope.systems, 'connectwise');
  //         }
  //     });
  //
  //     $scope.showSystems = function () {
  //         return $state.includes('vcio.dashboard') && !($rootScope.connectWiseScreen && $rootScope.connectWiseScreen.id);
  //     };
  //
  //     $scope.$on('ConnectWiseScreenChanged', function () {
  //         VcioService.setSystem('connectwise');
  //     });
  //
  //     // if (params.embedded) {
  //     //     VcioService.setSystem('connectwise');
  //     // } else {
  //     //     VcioService.setSystem(LocalStorage.get('selectedSystemId') || 'basecamp');
  //     // }
  //
  //     $scope.can = function (right) {
  //         return CurrentUser.can(right);
  //     };
  //
  //     $scope.changeUser = VcioService.changeUser;
  //     $scope.changeSystem = VcioService.setSystem;
  //     $scope.getSystem = VcioService.getSystem;
  //     $scope.getUsername = VcioService.getUsername;
  //
  //     $scope.getTitle = function () {
  //         return $state.$current.data && $state.$current.data.pageTitle ? $translate.instant($state.$current.data.pageTitle) : '';
  //     }
  // })

  .service(
    'VcioService',
    function ($http, $rootScope, $modal, $q, _, HttpService, LocalStorage, CurrentUser) {
      var system = null;

      function getSystem() {
        return system || LocalStorage.get('selectedSystemId') || 'basecamp';
      }

      function callWithCredentials(method, url, data) {
        var deferred = $q.defer();
        data = data || {};
        if (url && url.indexOf('/') !== 0) {
          url = '/' + url;
        }
        if (url) {
          $rootScope.$broadcast('dataLoadingStarted');
          HttpService[method]('/api/vcio/' + getSystem() + url, data)
            .then(function (result) {
              $rootScope.$broadcast('dataLoadingFinished');
              deferred.resolve(result);
            })
            .catch(function (error) {
              $rootScope.$broadcast('dataLoadingFinished');
              handleAutotaskError(error);
              deferred.reject(error);
            });
        } else {
          deferred.resolve(true);
        }

        return deferred.promise;
      }

      function handleAutotaskError(error) {
        // if (error.code === 'error.autotask.invalid_username_or_password' || error.code === 'error.autotask.invalid_username_format__username_must_be_of_the_format_user@domain') {
        if (_.isObject(error)) {
          if (error.code && _.isObject(error.code)) {
            error = error.code;
          }
        }
      }

      function isUnitPrice(contract) {
        return contract.defaults.pricing === 'unit';
      }

      return {
        setSystem: function (_system) {
          var old = system;
          /*if ((_system === 'connectwise' && !CurrentUser.getUser().Company.keys.connectwise)) {
                    _system = 'basecamp';
                } else*/
          system = _system;
          if (_system) {
            LocalStorage.set('selectedSystemId', _system);
          } else {
            LocalStorage.delete('selectedSystemId');
          }
          if (old != _system) {
            $rootScope.$broadcast('vcioSystemChanged');
          }
        },

        getSystem: getSystem,

        isBasecamp: function () {
          return getSystem() === 'basecamp';
        },

        isConnectwise: function () {
          return getSystem() === 'connectwise';
        },

        isAutotask: function () {
          return getSystem() === 'autotask';
        },

        get: function (url) {
          return callWithCredentials('get', url);
        },

        post: function (url, data) {
          return callWithCredentials('post', url, data);
        },

        put: function (url, data) {
          return callWithCredentials('put', url, data);
        },

        delete: function (url) {
          return callWithCredentials('delete', url);
        },

        // getUsername: function () {
        //     var loginData = CurrentUser.Company.keys.autotask;
        //     return loginData ? loginData.username : '';
        // },

        changeUser: function () {
          $rootScope.$broadcast('vcioSystemChanged');
        },

        isUnitPrice: isUnitPrice,

        calculate: function (contract) {
          if (isUnitPrice(contract)) {
            if (!contract.defaults.units) {
              contract.defaults.units = 1;
            }
          } else {
            contract.calculated = {
              annual: { hours: 0, price: 0 },
              quarterly: { hours: 0, price: 0 },
              monthly: { hours: 0, price: 0 },
              weekly: { hours: 0, price: 0 },
              total: { hours: 0, price: 0 },
            };
            for (var serviceId in contract.Services) {
              if (
                contract.Services.hasOwnProperty(serviceId) &&
                contract.Services[serviceId].cycle !== 'na'
              ) {
                var contractService = contract.Services[serviceId];
                if (contractService.cycle) {
                  contract.Services[serviceId].price =
                    contractService.hours * contractService.hourlyRate;
                  contract.calculated[contractService.cycle].hours += contractService.hours;
                  contract.calculated[contractService.cycle].price +=
                    contractService.hours * contractService.hourlyRate;
                } else if (contractService.hours) {
                  contract.Services[serviceId].price =
                    contractService.hours * contractService.hourlyRate;
                  contract.calculated.total.hours += contractService.hours;
                  contract.calculated.total.price +=
                    contractService.hours * contractService.hourlyRate;
                }
              }
            }

            contract.calculated.total.hours +=
              contract.calculated.annual.hours / 12 +
              (contract.calculated.quarterly.hours * 3) / 12 +
              (contract.calculated.monthly.hours * 8) / 12 +
              (contract.calculated.weekly.hours * 24) / 12;
            contract.calculated.total.price +=
              contract.calculated.annual.price / 12 +
              (contract.calculated.quarterly.price * 3) / 12 +
              (contract.calculated.monthly.price * 8) / 12 +
              (contract.calculated.weekly.price * 24) / 12;
          }
        },
      };
    },
  )

  // .controller('VcioDashboardController', function ($scope, $rootScope, $state, $location, $http, $window, $modal, $moment, $translate, _, CurrentUser, DialogService, HttpService, LocalStorage, ModalSelector, VcioService, UploadService, clients) {
  //     $scope.system = VcioService.getSystem();
  //     $scope.systemName = $translate.instant('label.clients.delivery.' + $scope.system);
  //     $scope.currentUser = CurrentUser.getUser();
  //     $scope.expanded = {projects: true, contracts: true};
  //     $scope.clients = clients;
  //     $scope.externalClients = clients.externalIds;
  //     $scope.selectedClient = undefined;
  //     $scope.todos = [];
  //     $scope.filter = {
  //         dueFrom: new Date(),
  //         dueTo: $moment().add(7, 'days').toDate(),
  //         contact: '{}',
  //         onlyAssignedTasks: false,
  //         externalId: $rootScope.connectWiseScreen ? $rootScope.connectWiseScreen.id : ''
  //     };
  //
  //     $scope.findClientByExternalId = function () {
  //         if ($scope.filter.externalId) {
  //             $scope.selectedClient = _.find($scope.clients, function (client) {
  //                 return _.get(client, 'externalIds.connectwise') + '' == $scope.filter.externalId + '';
  //             });
  //             if (!$scope.selectedClient) {
  //                 $state.go('msp.importClient', {externalId: $scope.filter.externalId});
  //             }
  //         } else if ($location.search().clientId) {
  //             $scope.selectedClient = _.find($scope.clients, {id: parseInt($location.search().clientId)});
  //         } else if (LocalStorage.get('clientIdFilter')) {
  //             $scope.selectedClient = _.find($scope.clients, {id: parseInt(LocalStorage.get('clientIdFilter'))});
  //         }
  //     };
  //
  //     $scope.isBasecamp = VcioService.isBasecamp;
  //     $scope.isConnectwise = VcioService.isConnectwise;
  //     $scope.isAutotask = VcioService.isAutotask;
  //
  //     $scope.$watch('selectedClient', function (value) {
  //         if (value) {
  //             if ($scope.isBasecamp()) {
  //                 $scope.setTodos();
  //             } else {
  //                 var externalId = _.get(value, 'externalIds.' + VcioService.getSystem());
  //                 if (externalId) {
  //                     VcioService.get('/companies', {id: externalId})
  //                         .then(function (result) {
  //                             $scope.setTodos();
  //                             $scope.externalClients = result;
  //                         }, function (error) {
  //                             if (error) {
  //                                 console.error(error);
  //                                 DialogService.error(error);
  //                             }
  //                         })
  //                 }
  //             }
  //         }
  //     });
  //
  //     $scope.$on('ConnectWiseScreenChanged', function (event, screenObject) {
  //         if (screenObject && screenObject.screen == 'company') {
  //             $scope.filter.externalId = screenObject.id;
  //             $scope.findClientByExternalId();
  //
  //             // $scope.selectedClient = _.find($scope.clients, function (client) {
  //             //     return _.get(client, 'externalIds.connectwise') + '' == $scope.filter.externalId + '';
  //             // })
  //             // $rootScope.$broadcast('dataLoadingFinished');
  //         }
  //     });
  //
  //     if ($scope.isBasecamp()) {
  //         $scope.externalContacts = _.filter($scope.contacts, function (contact) {
  //             return contact.externalId;
  //         }) || [];
  //         $scope.externalContacts.unshift({firstName: ' ', lastName: ' '});
  //         $scope.findClientByExternalId();
  //         // } else if ($scope.isAutotask()) {
  //     } else {
  //         VcioService.get('/users')
  //             .then(function (users) {
  //                 $scope.externalContacts = users || [];
  //                 $scope.externalContacts.unshift({firstName: ' ', lastName: ' '});
  //                 $scope.findClientByExternalId();
  //             });
  //         // } else {
  //         //     HttpService.get('/api/vcio/' + (LocalStorage.get('selectedSystemId')) + '/users')
  //         //         .then(function (users) {
  //         //             $scope.externalContacts = users || [];
  //         //             $scope.externalContacts.unshift({firstName: ' ', lastName: ' '});
  //         //         });
  //         //     $scope.findClientByExternalId();
  //     }
  //
  //     $scope.setTodos = function () {
  //         if ($scope.selectedClient) {
  //             if (!$scope.selectedClient.Projects) {
  //                 VcioService.get('/clients/' + $scope.selectedClient.id + '/projects')
  //                     .then(function (projects) {
  //                         if ($scope.selectedClient) {
  //                             if (!$scope.selectedClient.Projects) {
  //                                 $scope.selectedClient.Projects = [];
  //                             }
  //                             $scope.selectedClient.Projects = _.map(projects, function (project) {
  //                                 project.todolists = [];
  //                                 project.todos.forEach(function (todo) {
  //                                     if (todo.todolist) {
  //                                         var todolist = _.find(project.todolists, {id: todo.todolist.id});
  //                                         if (!todolist) {
  //                                             todolist = {id: todo.todolist.id, name: todo.todolist.name, todos: []};
  //                                             project.todolists.push(todolist);
  //                                         }
  //                                         todolist.todos.push(todo);
  //                                     }
  //                                 });
  //                                 delete project.todos;
  //                                 return project;
  //                             });
  //                         }
  //                         $rootScope.$broadcast('dataLoadingFinished');
  //                     })
  //             } else {
  //                 $scope.todos = [];
  //                 $scope.selectedClient.Projects.forEach(function (project) {
  //                     $scope.todos = _.union($scope.todos, _.filter(project.todos, function (todo) {
  //                         return (!$scope.filter.onlyAssignedTasks || todo.due_at || todo.due_on || todo.assignee);
  //                     }));
  //                 });
  //
  //                 $scope.todos.sort(function (a, b) {
  //                     return a.todolist.name.localeCompare(b.todolist.name);
  //                 });
  //                 $rootScope.$broadcast('dataLoadingFinished');
  //             }
  //         } else {
  //             $scope.todos = [];
  //         }
  //     };
  //
  //     $scope.getExternalContacts = function (cb) {
  //         //var cwId = _.get($scope.selectedClient, 'externalIds.connectwise');
  //         VcioService.get('/users')
  //             .then(function (externalContacts) {
  //                 $scope.externalContacts = externalContacts;
  //                 if (cb) {
  //                     cb()
  //                 }
  //             }, function (error) {
  //                 console.error(error);
  //                 if (cb) {
  //                     cb()
  //                 }
  //             })
  //     };
  //
  //     $scope.refresh = function () {
  //         $rootScope.$broadcast('dataLoadingStarted');
  //         $scope.filter.onlyAssignedTasks = false;
  //         $scope.clients = [];
  //         $scope.externalContacts = [];
  //
  //         HttpService.get('/api/clients?contracts=true')
  //             .then(function (clients) {
  //                 $scope.clients = clients;
  //                 $scope.findClientByExternalId();
  //                 $rootScope.$broadcast('dataLoadingFinished');
  //                 if ($scope.selectedClient) {
  //                     $scope.getExternalContacts();
  //                 }
  //             })
  //             .catch(function () {
  //                 $rootScope.$broadcast('dataLoadingFinished');
  //             });
  //     };
  //
  //     $rootScope.$on('vcioSystemChanged', function () {
  //         $scope.selectedClient = undefined;
  //         $scope.findClientByExternalId();
  //         $scope.refresh();
  //     });
  //
  //     //if (LocalStorage.get('clientIdFilter')) {
  //     //    $scope.selectedClient = _.find($scope.clients, {id: parseInt(LocalStorage.get('clientIdFilter'))});
  //     //    if (!$scope.selectedClient) {
  //     //        LocalStorage.delete('clientIdFilter');
  //     //    }
  //     //}
  //
  //     var currentContact = _.find($scope.externalContacts, {email: $scope.currentUser.email});
  //     if (currentContact) {
  //         $scope.filter.contact = JSON.stringify(currentContact);
  //     }
  //
  //     $scope.getContactName = function (item) {
  //         return item ? ((item.firstName || item.name || '') + ' ' + (item.lastName || '') + (item.email ? ' <' + item.email + '>' : '')) : '';
  //     };
  //
  //     $scope.nameFilter = function (item) {
  //         return !$scope.filter.name ||
  //             (item.name && item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1) ||
  //             (item.projects && _.find(item.projects, function (project) {
  //                 return project.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1
  //             }))
  //     };
  //
  //     $scope.clientIdFilter = function (item) {
  //         return (!$scope.filter.externalId && !$scope.selectedClient) || $scope.selectedClient == item;
  //     };
  //
  //     $scope.contactClientFilter = function (contact) {
  //         return !$scope.selectedClient || !contact.ClientId || $scope.selectedClient.id == contact.ClientId || !contact.externalId;
  //     };
  //
  //     $scope.taskFilter = function (todo) {
  //         var filter = function (todo) {
  //             return (!$scope.selectedProject || $scope.selectedProject.id == todo.ProjectId) &&
  //                 (!$scope.filter.searchDueDates ||
  //                     (todo.due_on && (!$scope.filter.dueFrom || !$moment($scope.filter.dueFrom).isAfter(todo.due_on, 'days')) &&
  //                         (!$scope.filter.dueTo || !$moment($scope.filter.dueTo).isBefore(todo.due_on, 'days'))));
  //         };
  //
  //         if (!$scope.filter.onlyAssignedTasks) {
  //             return filter(todo);
  //         } else if (!todo.assignee) {
  //             return false;
  //         } else {
  //             var contact = $scope.getFilterContact();
  //             return (!contact || !contact.externalId || (todo.assignee.id == contact.externalId)) && filter(todo);
  //         }
  //
  //     };
  //
  //     $scope.todolistFilter = function (todolist) {
  //         return todolist ? _.find(todolist.todos, $scope.taskFilter) : false;
  //     };
  //
  //     $scope.projectFilter = function (project) {
  //         return (!$scope.selectedProject || $scope.selectedProject.id == project.id) &&
  //             _.find(project.todolists, $scope.todolistFilter);
  //     };
  //
  //     // $scope.taskClientFilter = function (todo) {
  //     //     return !$scope.selectedClient || $scope.selectedClient.id == todo.ClientId;
  //     // }
  //     //
  //     // $scope.taskProjectFilter = function (todo) {
  //     //     return !$scope.selectedProject || $scope.selectedProject.id == todo.ProjectId;
  //     // }
  //     //
  //     // $scope.taskDateFilter = function (item) {
  //     //     return !$scope.filter.searchDueDates ||
  //     //         (item.due_on && (!$scope.filter.dueFrom || !$moment($scope.filter.dueFrom).isAfter(item.due_on, 'days')) &&
  //     //         (!$scope.filter.dueTo || !$moment($scope.filter.dueTo).isBefore(item.due_on, 'days')))
  //     // }
  //
  //     $scope.getFilterContact = function () {
  //         return $scope.filter.contact ? JSON.parse($scope.filter.contact) : undefined
  //     };
  //
  //     // $scope.taskContactFilter = function (item) {
  //     //     if (!$scope.filter.onlyAssignedTasks) {
  //     //         return true;
  //     //     } else if (!item.assignee) {
  //     //         return false;
  //     //     } else {
  //     //         var contact = $scope.getFilterContact();
  //     //         return (!contact || !contact.externalId || (item.assignee.id == contact.externalId))
  //     //     }
  //     // }
  //
  //     $scope.getTaskColor = function (todo) {
  //         if ($moment().endOf("day").isAfter(todo.due_on, 'days')) {
  //             return 'text-danger';
  //         } else if (todo.assignee) {
  //             return 'text-success';
  //         } else {
  //             return '';
  //         }
  //     };
  //
  //     $scope.deleteClient = function (client) {
  //         HttpService.delete('/api/clients/' + client.id)
  //             .then(function () {
  //                 _.remove($scope.clients, function (item) {
  //                     return item.id == client.id
  //                 });
  //             })
  //     };
  //
  //     $scope.openEditClient = function (client) {
  //         if (!client) {
  //             client = {}
  //         }
  //         var modalScope = $rootScope.$new();
  //         modalScope.modalInstance = $modal.open({
  //             templateUrl: '/templates/admin/client-edit.html',
  //             controller: 'ClientEditController',
  //             scope: modalScope,
  //             backdrop: 'static',
  //             //size: 'lg',
  //             resolve: {
  //                 client: function () {
  //                     return angular.copy(client)
  //                 }
  //             }
  //         });
  //
  //         modalScope.modalInstance.result.then(function (client) {
  //             var editedClient = _.find($scope.clients, {id: client.id});
  //             if (!editedClient) {
  //                 $scope.clients.push(client);
  //                 if (!client.Contacts) {
  //                     client.Contacts = [];
  //                 }
  //                 if (!client.Projects) {
  //                     client.Projects = [];
  //                 }
  //                 if (!client.Contracts) {
  //                     client.Contracts = [];
  //                 }
  //                 // $scope.clients = _.sortBy($scope.clients, 'name');
  //                 $scope.selectedClient = client;
  //             } else {
  //                 angular.copy(client, editedClient);
  //             }
  //
  //         }, function () {
  //
  //         });
  //
  //         //if (LocalStorage.get('clientNameFilter')) {
  //         //    $scope.filter.clientName = LocalStorage.get('clientNameFilter');
  //         //    $scope.selectClient(_.find($scope.clients, {name: $scope.filter.clientName}))
  //         //}
  //
  //     };
  //
  //     $scope.openClientLogo = function (client) {
  //         UploadService.open('clients', client).then(function () {
  //             client.logoUrl = '/images/clients/' + client.id + '?' + new Date().getTime();
  //         });
  //     };
  //
  //     $scope.clientOperations = [
  //         {operation: $scope.openEditClient, label: 'button.edit', icon: 'edit'},
  //         {operation: $scope.openClientLogo, label: 'button.logo', icon: 'image'},
  //         {operation: $scope.deleteClient, label: 'button.delete', icon: 'times-circle', confirmation: true}
  //     ];
  //
  //     $scope.createContact = function () {
  //         $scope.openEditContact({status: 'customer'});
  //     };
  //
  //     $scope.openEditContact = function (contact) {
  //         if ($scope.canEditContact(contact)) {
  //
  //             var modalInstance = $modal.open({
  //                 templateUrl: '/templates/client/contact-edit.html',
  //                 controller: 'ContactEditController',
  //                 backdrop: 'static',
  //                 resolve: {
  //                     contact: function () {
  //                         return angular.copy(contact);
  //                     },
  //                     client: function () {
  //                         return $scope.selectedClient;
  //                     }
  //                 }
  //             });
  //
  //             modalInstance.result.then(function (contact) {
  //                 var editedContact = _.find($scope.selectedClient.Contacts, {id: contact.id});
  //                 if (!editedContact) {
  //                     $scope.selectedClient.Contacts.push(contact);
  //                 } else {
  //                     angular.copy(contact, editedContact);
  //                 }
  //
  //             }, function () {
  //
  //             });
  //         }
  //     };
  //
  //     $scope.createContract = function () {
  //         $scope.openEditContract();
  //     };
  //
  //     $scope.printContract = function (contract) {
  //         $window.open($state.href('vcio.contract', {
  //             clientId: $scope.selectedClient.id,
  //             contractId: contract.id
  //         }), '_blank');
  //     };
  //
  //     $scope.openEditContract = function (contract) {
  //         if (contract) {
  //             $state.go('vcio.contractEdit', {contractId: contract.id});
  //         } else {
  //             $state.go('vcio.contractCreate', {clientId: $scope.selectedClient.id});
  //         }
  //         // $scope.selectedContract = contract;
  //         //
  //         // var modalInstance = $modal.open({
  //         //     templateUrl: '/templates/vcio/contract-edit.html',
  //         //     controller: 'VcioContractEditController',
  //         //     backdrop: 'static',
  //         //     size: 'lg',
  //         //     resolve: {
  //         //         contract: function () {
  //         //             return angular.copy(contract);
  //         //         },
  //         //         client: function () {
  //         //             return $scope.selectedClient;
  //         //         },
  //         //         serviceGroups: function (HttpService) {
  //         //             return HttpService.get('/api/service/servicegroups');
  //         //         }
  //         //     }
  //         // });
  //         //
  //         // modalInstance.result.then(function (contract) {
  //         //     $scope.selectedContract.name = contract.name;
  //         //     $scope.selectedContract.comment = contract.comment;
  //         //     $scope.selectedContract.preamble = contract.preamble;
  //         //     $scope.selectedContract.defaults = contract.defaults;
  //         //     $scope.selectedContract.Services = contract.Services;
  //         // }, function () {
  //         // });
  //     };
  //
  //     $scope.deleteContract = function (contract) {
  //         if ($scope.selectedClient) {
  //             HttpService.delete('/api/vcio/contracts/' + contract.id)
  //                 .then(function (deleted) {
  //                     _.remove($scope.selectedClient.Contracts, {id: parseInt(deleted.id)});
  //                 })
  //         }
  //     };
  //
  //
  //     $scope.openContractToProject = function (contract) {
  //         $scope.selectedContract = contract;
  //
  //         var modalInstance = $modal.open({
  //             templateUrl: '/templates/vcio/contract-to-project.html',
  //             controller: 'VcioContractToProjectController',
  //             backdrop: 'static',
  //             resolve: {
  //                 contract: function () {
  //                     return angular.copy(contract);
  //                 },
  //                 client: function () {
  //                     return $scope.selectedClient;
  //                 }
  //             }
  //         });
  //
  //         modalInstance.result.then(function () {
  //         }, function () {
  //         });
  //     };
  //     $scope.canArchiveProject = function () {
  //         return VcioService.getSystem() == 'basecamp';
  //     };
  //     $scope.canAssignProject = function () {
  //         return VcioService.getSystem() == 'basecamp';
  //         //return !_.find(project.accesses, function (access) {
  //         //    return (access.email_address != 'admin@vciotoolkit.com' && access.email_address != $scope.currentUser.email);
  //         //})
  //     };
  //     $scope.canOpenProject = function () {
  //         return true; //VcioService.getSystem() == 'basecamp';
  //     };
  //
  //     $scope.canCreateProject = function (client) {
  //         return VcioService.isBasecamp() || client.externalIds[VcioService.getSystem()];
  //     };
  //
  //     $scope.assignProject = function (project) {
  //         //HttpService.post('/api/vcio/projects/' + project.id + '/accesses', {email: 'stoiacs@eseinformatika.hu'})
  //         //.then(function(result){
  //         //        //_.remove($scope.clients.Projects, {id: project.id});
  //         //        // add to the new...
  //         //})
  //         var modalInstance = $modal.open({
  //             template: '<modal-form form-title="label.vcio.assignProject" title-values="{project: project.name}" onok="ok" oncancel="cancel"><input-listvalues model="selected.email" label="label.contact" values="contacts" keyname="email" exp="getContactName" required="true"></input-listvalues></modal-form>',
  //             controller: function ($scope, $http, $modalInstance, project, client, contacts) {
  //                 $scope.getContactName = function (contact) {
  //                     return contact ? (contact.firstName + ' ' + contact.lastName + (contact.email ? ' <' + contact.email + '>' : '')) : '';
  //                 };
  //                 $scope.contacts = _.filter(contacts, function (item) {
  //                     return !item.ClientId || item.ClientId == client.id;
  //                 });
  //                 $scope.selected = {};
  //                 $scope.ok = function (cb) {
  //                     VcioService.post('/clients/' + client.id + '/projects/' + project.id + '/accesses', {email: $scope.selected.email})
  //                         .then(function (accesses) {
  //                             cb();
  //                             $modalInstance.close({email: $scope.selected.email, accesses: accesses});
  //                         })
  //                         .catch(function (error) {
  //                             cb();
  //                             $scope.errors = [error];
  //                         })
  //                 };
  //                 $scope.cancel = function () {
  //                     $modalInstance.dismiss();
  //                 }
  //             },
  //             backdrop: 'static',
  //             resolve: {
  //                 project: function () {
  //                     return project;
  //                 },
  //                 client: function () {
  //                     return $scope.selectedClient;
  //                 },
  //                 contacts: function () {
  //                     return $scope.externalContacts;
  //                 }
  //             }
  //         });
  //
  //         modalInstance.result.then(function (data) {
  //             //_.remove($scope.currentClient.Projects, {id: project.id});
  //             //var client = _.find($scope.clients, function (client) {
  //             //    return _.find(client.Contacts, {email: data.email});
  //             //})
  //             //if ($scope.selectedClient) {
  //             //    project.accesses = data.accesses;
  //             //    $scope.selectedClient.Projects.push(project);
  //             //}
  //         }, function () {
  //         });
  //     };
  //
  //     $scope.canEditContact = function (contact) {
  //         return contact.id || !contact.email;
  //     };
  //
  //     $scope.deleteContact = function (contact) {
  //         HttpService.delete('/api/contacts/' + contact.id)
  //             .then(function () {
  //                 var client = _.find($scope.clients, {id: contact.ClientId});
  //                 _.remove(client.Contacts, {id: contact.id});
  //             })
  //     };
  //
  //     $scope.archiveProject = function (project) {
  //         VcioService.delete('/clients/' + $scope.selectedClient.id + '/projects/' + project.id)
  //             .then(function () {
  //                 var client = _.find($scope.clients, function (item) {
  //                     return _.find(item.Projects, {id: project.id});
  //                 });
  //                 _.remove(client.Projects, {id: project.id});
  //             })
  //     };
  //
  //     //$scope.canPublishProject = function(project){
  //     //    return project.draft;
  //     //}
  //     //
  //     //$scope.publishProject = function (project) {
  //     //    HttpService.post('/api/vcio/projects/' + project.id + '/publish')
  //     //        .then(function (result) {
  //     //            project.draft = false;
  //     //        })
  //     //}
  //
  //     $scope.createProject = function () {
  //         var modalInstance = $modal.open({
  //             templateUrl: '/templates/vcio/project-create.html',
  //             controller: 'VcioProjectCreateController',
  //             resolve: {
  //                 externalContacts: function () {
  //                     return $scope.externalContacts;
  //                 },
  //                 selectedClient: function () {
  //                     return $scope.selectedClient;
  //                 }
  //             }
  //         });
  //
  //         modalInstance.result.then(function (result) {
  //
  //             //var client = _.find($scope.clients, function (client) {
  //             //    return _.find(client.Contacts, {email: $scope.currentUser.email})
  //             //})
  //             //if (client && !_.find(client.Projects, {id: result.id})) {
  //             //    client.Projects.push(result);
  //             //}
  //             //$scope.selectedClient = client;
  //             $scope.selectedClient.Projects.unshift(result);
  //             result.ClientId = $scope.selectedClient.id;
  //             $scope.selectedProject = result;
  //             //$scope.otherProjects.push(result);
  //         }, function () {
  //         });
  //
  //     };
  //
  //     $scope.linkProject = function () {
  //         var modalInstance = $modal.open({
  //             templateUrl: '/templates/vcio/project-link.html',
  //             controller: 'VcioProjectLinkController',
  //             resolve: {
  //                 selectedClient: function () {
  //                     return $scope.selectedClient;
  //                 }
  //             }
  //         });
  //
  //         modalInstance.result.then(function (result) {
  //             if (!_.find($scope.selectedClient.Projects, {id: result.id})) {
  //                 $scope.selectedClient.Projects.push(result);
  //             }
  //             $scope.selectedProject = result;
  //         }, function () {
  //         });
  //
  //     };
  //
  //     $scope.getExternalClient = function (client) {
  //         var _id = _.get(client, 'externalIds.' + VcioService.getSystem());
  //         if (_id) {
  //             return _.find($scope.externalClients, {id: parseInt(_id)}) || _.find($scope.externalClients, {id: _id});
  //         } else {
  //             return null;
  //         }
  //     };
  //
  //     $scope.removeExternalClientLink = function (client) {
  //         delete client.externalIds[VcioService.getSystem()];
  //         VcioService.delete('/companies/undefined/link/' + client.id)
  //             .then(function () {
  //                 $scope.refresh();
  //             });
  //     };
  //
  //     $scope.linkExternalClient = function (client) {
  //         var modalInstance = $modal.open({
  //             templateUrl: '/templates/vcio/external-client-select.html',
  //             controller: function ($scope, $modalInstance, $translatte, model, VcioService) {
  //                 $scope.systemName = $translate.instant('label.clients.delivery.' + system);
  //                 $scope.model = model;
  //                 $scope.values = [];
  //                 $scope.filter = {};
  //                 $scope.ok = function (cb) {
  //                     if ($scope.selected) {
  //                         $modalInstance.close($scope.selected);
  //                     } else {
  //                         cb();
  //                     }
  //                 };
  //
  //                 $scope.cancel = function () {
  //                     $modalInstance.dismiss();
  //                 };
  //
  //                 $scope.select = function (item) {
  //                     if ($scope.selected == item) {
  //                         $scope.selected = undefined;
  //                     } else {
  //                         $scope.selected = item;
  //                     }
  //                 };
  //
  //                 $scope.nameFilter = function (item) {
  //                     return !$scope.filter.name || item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1;
  //                 };
  //
  //                 $scope.search = function () {
  //                     $scope.errors = [];
  //                     $scope.messages = [];
  //                     $scope.values = [];
  //                     VcioService.get('/companies?name=' + $scope.filter.name)
  //                         .then(function (result) {
  //                             $scope.values = result;
  //                             if (!$scope.values || $scope.values.length === 0) {
  //                                 $scope.messages = ['message.noItems'];
  //                             }
  //                         }, function (error) {
  //                             if (error) {
  //                                 $scope.errors = [error];
  //                                 console.error(error);
  //                             } else {
  //                                 $modalInstance.dismiss();
  //                             }
  //                         });
  //                 }
  //             },
  //             backdrop: 'static',
  //             resolve: {
  //                 model: function () {
  //                     return client.externalIds[VcioService.getSystem()];
  //                     // },
  //                     // values: function () {
  //                     //     return $scope.externalClients;
  //                 }
  //             }
  //         });
  //         modalInstance.result.then(function (result) {
  //             client.externalIds[VcioService.getSystem()] = result ? result.id : undefined;
  //             VcioService.put('/companies/' + (result ? result.id : undefined) + '/link/' + client.id)
  //                 .then(function () {
  //                     $scope.refresh();
  //                 });
  //         }, function () {
  //         });
  //     };
  //
  //     // $scope.linkClient = function () {
  //     //     var modalInstance = $modal.open({
  //     //         templateUrl: '/templates/integration/link-client.html',
  //     //         controller: 'IntegrationConnectwiseLinkClientController',
  //     //         backdrop: 'static',
  //     //         resolve: {
  //     //             externalId: function () {
  //     //                 return $scope.filter.externalId;
  //     //             },
  //     //             clients: function () {
  //     //                 return _.filter($scope.clients, function (client) {
  //     //                     return !client.externalIds.connectwise;
  //     //                 });
  //     //             }
  //     //         }
  //     //     });
  //     //
  //     //     modalInstance.result.then(function (result) {
  //     //         var client = _.find($scope.clients, {id: result.id});
  //     //         if (client) {
  //     //             client.externalIds.connectwise = $scope.filter.externalId;
  //     //             HttpService.put('/api/vcio/connectwise/companies/' + $scope.filter.externalId + '/link/' + result.id)
  //     //                 .then(function () {
  //     //                     $scope.refresh();
  //     //                 });
  //     //         }
  //     //     }, function () {
  //     //     });
  //     // }
  //
  //     $scope.removeClientSelection = function () {
  //         //LocalStorage.delete('clientNameFilter');
  //         //LocalStorage.delete('clientIdFilter');
  //         $scope.selectedClient = undefined;
  //         $scope.selectedProject = undefined;
  //         LocalStorage.delete('clientIdFilter');
  //         //$scope.$broadcast('client-list-selection-removed');
  //     };
  //
  //     $scope.selectProject = function (project) {
  //         if ($scope.selectedProject && $scope.selectedProject == project) {
  //             $scope.selectedProject = undefined;
  //         } else {
  //             $scope.selectedProject = project;
  //         }
  //     };
  //
  //     $scope.selectClient = function (client) {
  //         if (!$scope.filter.externalId) {
  //             if (!client) {
  //                 var filtered = _.filter($scope.clients, function (item) {
  //                     return $scope.nameFilter(item)
  //                 });
  //                 if (filtered.length == 1) {
  //                     $scope.selectClient(filtered[0]);
  //                 }
  //             } else {
  //                 if ($scope.selectedClient == client) {
  //                     $scope.filter.clientName = '';
  //                     $scope.removeClientSelection();
  //                     $scope.filter.contact = undefined;
  //                 } else {
  //                     $scope.selectedClient = client;
  //                     $scope.filter.contact = undefined;
  //                     LocalStorage.set('clientIdFilter', client.id);
  //                     $scope.getExternalContacts();
  //                     //$scope.filter.clientName = client.name;
  //                     //LocalStorage.set('clientIdFilter', client.id)
  //                 }
  //             }
  //         }
  //     };
  //
  //     $scope.openTask = function (todo) {
  //         if ($rootScope.connectWiseScreen) {
  //             $rootScope.connectWiseClientApi.post({
  //                 "request": "openScreen",
  //                 "args": {"newTab": false, "screen": "ticket", "id": todo.id + ""}
  //             });
  //         } else {
  //             $window.open(todo.app_url, 'RYC-' + VcioService.getSystem());
  //         }
  //     };
  //
  //     $scope.openProject = function (project) {
  //         // if ($rootScope.connectWiseScreen) {
  //         //     $rootScope.connectWiseClientApi.post({
  //         //         "request": "openScreen",
  //         //         "args": {"newTab": false, "screen": "project", "id": project.id + ""}
  //         //     });
  //         // } else {
  //         $window.open(project.app_url, 'RYC-' + VcioService.getSystem());
  //         // }
  //     };
  //
  //     $scope.contactOperations = [
  //         {operation: $scope.openEditContact, label: 'button.edit', icon: 'edit', condition: $scope.canEditContact},
  //         {
  //             operation: $scope.deleteContact,
  //             label: 'button.delete',
  //             icon: 'times-circle',
  //             confirmation: true,
  //             condition: $scope.canEditContact
  //         }
  //     ];
  //
  //     $scope.projectOperations = [
  //         {
  //             operation: $scope.archiveProject,
  //             label: 'button.archive',
  //             icon: 'archive',
  //             confirmation: true,
  //             condition: $scope.canArchiveProject
  //         },
  //         {operation: $scope.assignProject, label: 'button.assign', icon: 'user', condition: $scope.canAssignProject},
  //         {
  //             operation: $scope.openProject,
  //             label: 'button.vcio.openProject',
  //             icon: 'external-link',
  //             condition: $scope.canOpenProject
  //         }
  //     ];
  //
  //     $scope.contractOperations = [
  //         {operation: $scope.openEditContract, label: 'button.edit', icon: 'edit'},
  //         {operation: $scope.deleteContract, label: 'button.delete', icon: 'minus-square-o', confirmation: true},
  //         {
  //             operation: $scope.openContractToProject,
  //             label: 'button.vcio.contractToBasecamp',
  //             icon: 'edit',
  //             condition: function () {
  //                 return $scope.isBasecamp();
  //             }
  //         },
  //         {
  //             operation: $scope.openContractToProject,
  //             label: 'button.vcio.contractToConnectwise',
  //             icon: 'edit',
  //             condition: function () {
  //                 return $scope.isConnectwise() && $scope.getExternalClient($scope.selectedClient);
  //             }
  //         },
  //         {
  //             operation: $scope.openContractToProject,
  //             label: 'button.vcio.contractToAutotask',
  //             icon: 'edit',
  //             condition: function () {
  //                 return $scope.isAutotask() && $scope.getExternalClient($scope.selectedClient);
  //             }
  //         },
  //         {operation: $scope.printContract, label: 'button.print', icon: 'print'}
  //     ];
  //
  //     $scope.getProjectPopover = function (project) {
  //         var result = '';
  //         if (_.isArray(project.accesses)) {
  //             project.accesses.forEach(function (access) {
  //                 if (access.email_address != 'admin@vciotoolkit.com' && access.email_address != $scope.currentUser.email) {
  //                     result += '<p>' + access.name + '<br>&lt;' + access.email_address + '&gt;</p>'
  //                 }
  //             })
  //         }
  //
  //         return result;
  //     }
  // })

  // .controller('VcioProjectController', function ($scope, $stateParams, $http) {
  //
  //     HttpService.get('/api/vcio/projects/' + $stateParams.projectId)
  //         .then(function (project) {
  //             $scope.project = project;
  //
  //         })
  // })
  //
  .controller(
    'VcioContractController',
    function (
      $http,
      $modal,
      $scope,
      $state,
      $rootScope,
      $window,
      _,
      CurrentUser,
      HttpService,
      VcioService,
      contract,
      InputSanitizerService,
    ) {
      $scope.currentUser = $scope.currentUser || CurrentUser.getUser();
      $scope.hasModule = CurrentUser.hasModule;
      $scope.contract = contract;
      $scope.services = [];
      // $scope.client = client;
      $scope.currentCompany = CurrentUser.getUser().Company;
      HttpService.get(
        '/api/clients/' + InputSanitizerService.sanitize($scope.contract.ClientId),
      ).then(function (client) {
        $scope.client = client;
      });

      //console.log($scope.contract.Services);

      _.forEach(contract.Services, function (service) {
        if (service.name) {
          $scope.services.push(service);
        }
      });
      //console.log($scope.services);
      /*serviceGroups.forEach(function (group) {
            group.Services.forEach(function (service) {
                if ($scope.contract.Services.hasOwnProperty(service.id)) {
                    $scope.services.push(service);
                    service.bundle = $scope.contract.Services[service.id];
                }
            })
        });*/

      $scope.edit = function () {
        $state.go('vcio.contractEdit', { contractId: $scope.contract.id });
      };
      $scope.canEdit = function () {
        return $scope.contract.Client.OwnerId === $scope.currentCompany.id;
      };

      $scope.sendToVcio = function (system) {
        VcioService.setSystem(system);

        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.get('/api/vcio/' + system + '/clients/' + $scope.client.id + '/projects')
          .then(function (projects) {
            $scope.client.Projects = projects;
            $rootScope.$broadcast('dataLoadingFinished');
            var modalInstance = $modal.open({
              templateUrl: '/templates/vcio/contract-to-project.html',
              controller: 'VcioContractToProjectController',
              backdrop: 'static',
              resolve: {
                contract: function () {
                  return angular.copy($scope.contract);
                },
                client: function () {
                  return $scope.client;
                },
              },
            });

            modalInstance.result.then(
              function () {},
              function () {},
            );
          })
          .catch(function () {
            $rootScope.$broadcast('dataLoadingFinished');
          });
      };

      $scope.print = function () {
        $window.print();
      };
    },
  )

  .controller(
    'VcioContractEditController',
    function (
      $scope,
      $rootScope,
      $state,
      $translate,
      $http,
      $modal,
      $location,
      _,
      HttpService,
      VcioService,
      contract,
      serviceGroups,
      client,
      InputSanitizerService,
    ) {
      $scope.filter = {};
      $scope.client = client;
      $scope.contract = contract || { defaults: {}, Services: {} };
      $scope.serviceGroups = serviceGroups;
      $scope.serviceCycles = ['na', 'annual', 'quarterly', 'monthly', 'weekly'];
      $scope.contract.defaults = $scope.contract.defaults || {};
      if (!$scope.contract.defaults.pricing) {
        $scope.contract.defaults.pricing = 'labour';
        $scope.contract.defaults.monthlyPrice = 0;
      }

      $scope.getContractType = function () {
        return $scope.contract.type
          ? $translate.instant('label.vcio.contractType.' + $scope.contract.type)
          : '';
      };

      if (!client) {
        HttpService.get(
          '/api/clients/' + InputSanitizerService.sanitize($scope.contract.ClientId),
        ).then(function (client) {
          $scope.client = client;
        });
      }

      $scope.isUnitPrice = function () {
        return VcioService.isUnitPrice($scope.contract);
      };

      $scope.showCalculation = function () {
        return $scope.contract.defaults.pricing == 'labour' && $scope.contract.type == 'ongoing';
      };

      $scope.filterServicesByContractType = function () {
        $scope.isOngoing = $scope.contract.type == 'ongoing';
        $scope.serviceGroups.forEach(function (serviceGroup) {
          serviceGroup.Services = _.filter(serviceGroup.Services, function (service) {
            return service.type == $scope.contract.type || service.type == 'any';
          });
        });
        $scope.serviceGroups.forEach(function (serviceGroup) {
          serviceGroup.Services.forEach(function (service) {
            service.contractService = $scope.contract.Services[service.id];
          });
        });
      };

      $scope.calculate = function () {
        VcioService.calculate($scope.contract);
      };

      $scope.$watch(
        'contract.Services',
        function () {
          $scope.calculate();
        },
        true,
      );

      // $scope.$watch('contract.defaults.units', function (newValue) {
      //     if ($scope.isUnitPrice && newValue) {
      //         $scope.contract.defaults.monthlyPrice = newValue * $scope.contract.defaults.unitPrice;
      //     }
      // });

      if (!$scope.contract.type) {
        if ($location.search().type) {
          $scope.contract.type = $location.search().type;
        } else {
          var modalInstance = $modal.open({
            // template: '<modal-form form-title="title.vcio.createContract" onok="ok" oncancel="cancel">' +
            // '<input-values label="label.vcio.contractType" model="contract.type" values="[\'ongoing\', \'onetime\']" listcode="label.vcio.contractType" required="true"></input-values>' +
            // '<input-select label="label.vcio.contractEdit.serviceBundle" model="contract.bundle" values="serviceBundles" keyname="id" valuename="name" ng-show="contract.type==\'ongoing\'"></input-select>' +
            // '<input-values label="label.vcio.serviceBundleEdit.pricing" model="contract.defaults.pricing" values="[\'labour\', \'unit\']" listcode="label.vcio.serviceBundleEdit.pricing" required="true" ng-if="!contract.bundle"></input-values>' +
            // '</modal-form>',
            templateUrl: '/templates/vcio/contract-create.html',
            controller: function ($scope, $modalInstance, $state, contract, client) {
              $scope.contract = contract;
              $scope.client = client;
              HttpService.get('/api/service/servicebundles?owner=true').then(function (
                serviceBundles,
              ) {
                $scope.serviceBundles = serviceBundles;
              });

              $scope.ok = function () {
                if ($scope.contract.type) {
                  if ($scope.contract.bundle) {
                    $scope.contract.name = $scope.contract.bundle.name;
                    $scope.contract.comment = $scope.contract.bundle.comment;
                    $scope.contract.defaults = $scope.contract.bundle.defaults;
                    $scope.contract.Services = $scope.contract.bundle.Services;
                  }

                  $modalInstance.close();
                }
              };

              $scope.cancel = function () {
                $modalInstance.dismiss();
                if (
                  $rootScope.previousState &&
                  $rootScope.previousState.name &&
                  $state.current.name != $rootScope.previousState.name
                ) {
                  $state.go($rootScope.previousState.name, $rootScope.previousState.params);
                } else {
                  $state.go('vcio.dashboard');
                }
              };
            },
            backdrop: 'static',
            resolve: {
              contract: function () {
                return $scope.contract;
              },
              client: function () {
                return $scope.client;
              },
            },
          });

          modalInstance.result.then(
            function () {
              $scope.filterServicesByContractType();
              $scope.calculate();
            },
            function () {},
          );
        }
      } else {
        $scope.filterServicesByContractType();
        $scope.calculate();
      }

      $scope.serviceFilter = function (service) {
        return (
          !$scope.filter.service ||
          service.name.toLowerCase().indexOf($scope.filter.service.toLowerCase()) > -1
        );
      };

      $scope.serviceGroupFilter = function (item) {
        return !$scope.filter.service || _.find(item.Services, $scope.serviceFilter);
      };

      $scope.hasTasks = function (service) {
        return (
          service.contractService.todoLists &&
          service.contractService.todoLists.length > 0 &&
          _.sumBy(service.contractService.todoLists, function (todoList) {
            return todoList.todo.length;
          }) > 0
        );
      };

      $scope.openTasks = function (service) {
        if ($scope.hasTasks(service)) {
          var modalInstance = $modal.open({
            templateUrl: '/templates/vcio/contract-service-edit.html',
            controller: function ($scope, $modalInstance, $state, _, service) {
              $scope.service = service;

              $scope.ok = function () {
                service.contractService.hours = _.sumBy(
                  service.contractService.todoLists,
                  function (todoList) {
                    return _.sumBy(todoList.todo, function (task) {
                      return task.hours || 0;
                    });
                  },
                );
                $modalInstance.close();
              };

              $scope.cancel = function () {
                $modalInstance.dismiss();
              };
            },
            backdrop: 'static',
            resolve: {
              service: function () {
                return service;
              },
            },
          });

          modalInstance.result.then(
            function () {},
            function () {},
          );
        }
      };

      $scope.expandAll = function () {
        $scope.serviceGroups.forEach(function (group) {
          group.expanded = true;
        });
      };

      $scope.collapseAll = function () {
        $scope.serviceGroups.forEach(function (group) {
          group.expanded = false;
        });
      };

      $scope.goWorkspace = function () {
        $state.go('vcio.dashboard', { clientId: $scope.client.id });
      };

      $scope.goBack = function () {
        if (
          $rootScope.previousState &&
          $rootScope.previousState.name &&
          $state.current.name != $rootScope.previousState.name
        ) {
          $state.go($rootScope.previousState.name, $rootScope.previousState.params);
        } else {
          $state.go('vcio.dashboard');
        }
      };

      $scope.addService = function (service) {
        if ($scope.contract.type == 'ongoing') {
          $scope.contract.Services[service.id] = {
            cycle: service.cycle,
            hours: service.hoursToComplete,
            hourlyRate: $scope.contract.defaults.hourlyRate,
          };
        } else {
          $scope.contract.Services[service.id] = {
            hours: _.sumBy(service.todoLists, function (todoList) {
              return _.sumBy(todoList.todo, function (task) {
                return task.hours || 0;
              });
            }),
            hourlyRate: $scope.contract.defaults.hourlyRate,
            todoLists: service.todoLists,
          };
        }
        service.contractService = $scope.contract.Services[service.id];
      };

      $scope.removeService = function (service) {
        delete $scope.contract.Services[service.id];
        delete service.contractService;
      };

      function saveContract(cb) {
        var req;
        if ($scope.contract.id) {
          req = HttpService.put('/api/vcio/contracts', { contract: $scope.contract });
        } else {
          req = HttpService.post(
            '/api/vcio/contracts' + '?clientId=' + InputSanitizerService.sanitize($scope.client.id),
            { contract: $scope.contract },
          );
        }
        req
          .then(function (result) {
            if (!$scope.contract.id) {
              cb();
              $state.go('vcio.contractEdit', { contractId: result.id });
            } else {
              $scope.messages = ['message.saved'];
              cb();
            }
          })
          .catch(function (error) {
            console.error(error);
            $scope.errors = [error];
            cb();
          });
      }

      $scope.save = function (cb) {
        saveContract(cb);
      };

      $scope.massHourlyRateApply = function () {
        _.forEach($scope.contract.Services, function (service) {
          service.hourlyRate = $scope.contract.defaults.hourlyRate;
        });
      };
    },
  )

  // .controller('VcioContractOngoingEditController', function ($scope, $http, $modal, _) {
  //
  //     $scope.filter = {};
  //
  //     $scope.createBundleService = function (service) {
  //         $scope.contract.Services[service.id] = {
  //             cycle: service.cycle,
  //             hours: service.hoursToComplete,
  //             hourlyRate: $scope.contract.defaults.hourlyRate
  //         };
  //         return $scope.contract.Services[service.id];
  //     }
  //
  //     $scope.removeBundleService = function (service) {
  //         delete $scope.contract.Services[service.id];
  //         delete service.bundleService;
  //     }
  //
  //     $scope.calculate = function () {
  //         $scope.contract.calculated = {
  //             annual: {hours: 0, price: 0},
  //             quarterly: {hours: 0, price: 0},
  //             monthly: {hours: 0, price: 0},
  //             weekly: {hours: 0, price: 0},
  //             total: {hours: 0, price: 0}
  //         };
  //         for (var serviceId in $scope.contract.Services) {
  //             if ($scope.contract.Services.hasOwnProperty(serviceId) && $scope.contract.Services[serviceId].cycle) {
  //                 var bundleService = $scope.contract.Services[serviceId];
  //                 $scope.contract.calculated[bundleService.cycle].hours += bundleService.hours;
  //                 $scope.contract.calculated[bundleService.cycle].price += bundleService.hours * bundleService.hourlyRate;
  //             }
  //         }
  //
  //         $scope.contract.calculated.total.hours = $scope.contract.calculated.annual.hours / 12 + $scope.contract.calculated.quarterly.hours * 3 / 12 + $scope.contract.calculated.monthly.hours * 8 / 12 + $scope.contract.calculated.weekly.hours * 24 / 12;
  //         $scope.contract.calculated.total.price = $scope.contract.calculated.annual.price / 12 + $scope.contract.calculated.quarterly.price * 3 / 12 + $scope.contract.calculated.monthly.price * 8 / 12 + $scope.contract.calculated.weekly.price * 24 / 12;
  //     };
  //
  //     $scope.calculate();
  // })
  //
  .controller(
    'VcioProjectsController',
    function ($scope, $stateParams, $http, $modal, _, CurrentUser, HttpService, projects) {
      $scope.currentUser = CurrentUser.getUser();
      $scope.filter = {};
      $scope.clients = projects.clients;
      //$scope.otherProjects = projects.otherProjects;

      $scope.nameFilter = function (item) {
        return (
          !$scope.filter.name ||
          (item.name && item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1) ||
          (item.projects &&
            _.find(item.projects, function (project) {
              return project.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1;
            }))
        );
      };
    },
  )

  .controller(
    'VcioProjectLinkController',
    function (
      $scope,
      $rootScope,
      $stateParams,
      $http,
      $modalInstance,
      _,
      HttpService,
      LocalStorage,
      VcioService,
      selectedClient,
      InputSanitizerService,
    ) {
      $scope.selectedClient = selectedClient;
      $scope.selected = {};
      VcioService.get('/projects').then(function (projects) {
        $scope.projectsWithoutClient = _.filter(projects, function (project) {
          return !project.clientId;
        });
      });
      $scope.ok = function (cb) {
        if ($scope.selected.project) {
          var project = _.find($scope.projectsWithoutClient, {
            id: parseInt($scope.selected.project),
          });
          if (project) {
            VcioService.post(
              '/clients/' + InputSanitizerService.sanitize($scope.selectedClient.id) + '/projects',
              {
                project: {
                  id: project.id,
                  name: project.name,
                  description: project.description,
                  clientId: $scope.selectedClient.id,
                },
              },
            )
              .then(function (result) {
                $modalInstance.close(result);
              })
              .catch(function (error) {
                $scope.errors = [error];
                cb();
              });
          } else {
            cb();
          }
        } else {
          cb();
        }
      };

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };
    },
  )

  .controller(
    'VcioProjectCreateController',
    function (
      $scope,
      $rootScope,
      $stateParams,
      $http,
      $modalInstance,
      _,
      HttpService,
      LocalStorage,
      VcioService,
      externalContacts,
      selectedClient,
      InputSanitizerService,
    ) {
      $scope.useTemplate = false;
      $scope.selectedClient = selectedClient;
      $scope.externalContacts = externalContacts;
      $scope.newProject = {};
      $scope.system = VcioService.getSystem();

      if ($scope.system == 'connectwise') {
        $scope.billingMethods = ['ActualRates', 'FixedFee', 'NotToExceed', 'OverrideRate'];
        $scope.boards = [];
        HttpService.get('/api/vcio/connectwise/boards/project').then(function (boards) {
          $scope.boards = boards;
        });
      }

      $scope.getContactName = function (item) {
        return item
          ? (item.firstName || item.name || '') +
              ' ' +
              (item.lastName || '') +
              (item.email ? ' <' + item.email + '>' : '')
          : '';
      };

      // HttpService.get('/api/vcio/' + (LocalStorage.get('selectedSystemId') || 'basecamp') + '/myprojects')
      //     .then(function (projects) {
      //         $scope.projectsWithoutClient = projects;
      //     })
      //
      // HttpService.get('/api/vcio/templates')
      //     .then(function (templates) {
      //         $scope.templates = templates;
      //     })
      //
      //$scope.$watch('newProject.name', function (newValue, oldValue) {
      //    if ($scope.newProject.templateUsedWarning && oldValue){
      //        $scope.newProject.templateUsedWarning = false;
      //    }
      //})

      $scope.ok = function (cb) {
        if (
          VcioService.isBasecamp() ||
          $scope.selectedClient.externalIds[VcioService.getSystem()]
        ) {
          VcioService.post(
            '/clients/' + InputSanitizerService.sanitize($scope.selectedClient.id) + '/projects',
            {
              project: _.extend($scope.newProject, { clientId: $scope.selectedClient.id }),
            },
          )
            .then(function (result) {
              $modalInstance.close(result);
            })
            .catch(function (error) {
              $scope.errors = [error];
              cb();
            });
        } else {
          $scope.errors = ['errors.externalClientNotConnected'];
          cb();
        }
      };

      $scope.cancel = function () {
        if ($scope.selectedTemplate) {
          $scope.selectedTemplate = undefined;
        } else {
          $modalInstance.dismiss();
        }
      };
    },
  )

  .controller(
    'VcioContractToProjectController',
    function (
      $scope,
      $rootScope,
      $stateParams,
      $http,
      $modalInstance,
      $translate,
      _,
      HttpService,
      LocalStorage,
      VcioService,
      contract,
      client,
      InputSanitizerService,
    ) {
      $scope.filter = {};
      $scope.contract = contract;
      $scope.client = client;
      $scope.projects = client.Projects || [];
      $scope.serviceCycles = _.map(
        ['na', 'annual', 'quarterly', 'monthly', 'weekly'],
        function (item) {
          return {
            id: item,
            name: $translate.instant('label.academy.serviceCycle.' + item),
          };
        },
      );
      $scope.selected = {};

      if ($scope.projects.length == 1) {
        $scope.selected.project = $scope.projects[0];
      }

      $scope.isBasecamp = VcioService.isBasecamp;

      $scope.ok = function (cb) {
        if ($scope.selected.project) {
          var cycles = _.map($scope.selected.cycles, 'id');

          VcioService.post(
            '/clients/' +
              InputSanitizerService.sanitize($scope.client.id) +
              '/projects/' +
              InputSanitizerService.sanitize($scope.selected.project.id) +
              '/contract/' +
              InputSanitizerService.sanitize($scope.contract.id),
            {
              cycles: cycles,
              addServiceUrl: $scope.filter.addServiceUrl,
            },
          )
            .then(function (result) {
              $scope.messages = [
                {
                  code: 'message.vcio.deliveryItemsExported',
                  translateValues: { url: result },
                },
              ];
              $scope.ok = undefined;
              cb();
            })
            .catch(function (error) {
              $scope.errors = [error];
              cb();
            });
        } else {
          $scope.errors = ['error.noProjectSelected'];
          cb();
        }
      };

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };

      $scope.selectProject = function (project) {
        $scope.selectedProject = project;
      };

      $scope.projectFilter = function (item) {
        return (
          !$scope.filter.name ||
          item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1
        );
      };
    },
  )

  .controller(
    'VcioAssetToVcioController',
    function (
      $http,
      $modalInstance,
      $rootScope,
      $scope,
      $stateParams,
      $translate,
      _,
      CurrentUser,
      HttpService,
      VcioService,
      asset,
      system,
    ) {
      $scope.asset = asset;
      $scope.system = system;
      $scope.systemName = $translate.instant('label.externalSystem.' + system);

      $scope.sendOption = 'link';
      // $scope.externalContacts = [];
      $scope.selected = {};

      VcioService.setSystem(system);

      VcioService.get('/projects')
        .then(function (result) {
          // VcioService.get('/users').then(function (externalContacts) {
          //     $scope.externalContacts = externalContacts;
          // });
          $scope.projects = result;
          if ($scope.projects && $scope.projects.length > 0) {
            var vendorProject = _.find($scope.projects, { name: $scope.asset.Vendor.name });
            if (vendorProject) {
              $scope.selected.project = vendorProject;
            } else {
              $scope.selected.project = $scope.projects[0];
              $scope.sendOption = 'create';
              $scope.selected.name = $scope.asset.Vendor.name;
            }
          } else {
            $scope.sendOption = 'create';
            $scope.selected.name = $scope.asset.Vendor.name;
          }
        })
        .catch(function (error) {
          if (!error) {
            $modalInstance.dismiss();
          }
        });

      if ($scope.system === 'connectwise') {
        $scope.billingMethods = ['ActualRates', 'FixedFee', 'NotToExceed', 'OverrideRate'];
        HttpService.get('/api/vcio/connectwise/boards/project').then(function (boards) {
          $scope.boards = boards;
        });
      }

      $scope.ok = function (cb) {
        if ($scope.selected.project || $scope.sendOption === 'create') {
          $rootScope.$broadcast('dataLoadingStarted');
          if ($scope.sendOption === 'link') {
            VcioService.post(
              '/projects/' + $scope.selected.project.id + '/assets/' + $scope.asset.id,
            ).then(
              function (created) {
                $scope.messages = [
                  {
                    code: 'message.vcio.processItemsExported',
                    translateValues: { url: created.app_url || created.url },
                  },
                ];
                $scope.ok = undefined;
                $rootScope.$broadcast('dataLoadingFinished');
                cb();
              },
              function (error) {
                $scope.errors = [error];
                $rootScope.$broadcast('dataLoadingFinished');
                cb();
              },
            );
          } else if ($scope.sendOption === 'create') {
            VcioService.post('/clients/0/projects', {
              project: {
                name: $scope.selected.name,
                boardId: $scope.selected.boardId,
                billingMethod: $scope.selected.billingMethod,
                clientId: $scope.selected.clientId,
                OwnerId: $scope.selected.OwnerId,
                projectStart: $scope.selected.start,
                projectEnd: $scope.selected.end,
              },
            })
              .then(function (created) {
                VcioService.post('/projects/' + created.id + '/assets/' + $scope.asset.id)
                  .then(function () {
                    $scope.messages = [
                      {
                        code: 'message.vcio.processItemsExported',
                        translateValues: { url: created.app_url || created.url },
                      },
                    ];
                    $scope.ok = undefined;
                    $rootScope.$broadcast('dataLoadingFinished');
                    cb();
                  })
                  .catch(function (error) {
                    $scope.errors = [error];
                    $rootScope.$broadcast('dataLoadingFinished');
                    cb();
                  });
              })
              .catch(function (error) {
                $scope.errors = [error];
                $rootScope.$broadcast('dataLoadingFinished');
                cb();
              });
          }
        } else {
          cb();
        }
      };

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };

      $scope.setOption = function (option) {
        $scope.sendOption = option;
      };

      $scope.getContactName = function (item) {
        return item
          ? (item.firstName || item.name || '') +
              ' ' +
              (item.lastName || '') +
              (item.email ? ' <' + item.email + '>' : '')
          : '';
      };
    },
  )

  .controller(
    'VcioServiceToVcioController',
    function (
      $http,
      $modalInstance,
      $rootScope,
      $scope,
      $stateParams,
      $translate,
      _,
      HttpService,
      VcioService,
      service,
      system,
    ) {
      $scope.service = service;
      $scope.system = system;
      $scope.systemName = $translate.instant('label.externalSystem.' + system);

      $scope.sendOption = 'link';
      $scope.externalContacts = [];
      $scope.selected = {};
      $scope.clients = [];

      VcioService.setSystem(system);

      HttpService.get('/api/clients').then(function (clients) {
        $scope.clients = clients;
      });

      $scope.getProjects = function () {
        VcioService.get('/clients/' + $scope.selected.clientId + '/projects')
          .then(function (result) {
            // VcioService.get('/users').then(function (externalContacts) {
            //     $scope.externalContacts = externalContacts;
            // });
            $scope.projects = result;
            if ($scope.projects && $scope.projects.length > 0) {
              $scope.selected.project = $scope.projects[0];
              $scope.sendOption = 'link';
            } else {
              $scope.sendOption = 'create';
            }
            $rootScope.$broadcast('dataLoadingFinished');
          })
          .catch(function (error) {
            if (!error) {
              $modalInstance.dismiss();
            }
          });
      };

      $scope.$watch('selected.clientId', function (value) {
        if (value) {
          $scope.getProjects();
        }
      });

      if ($scope.system === 'connectwise') {
        $scope.billingMethods = ['ActualRates', 'FixedFee', 'NotToExceed', 'OverrideRate'];
        HttpService.get('/api/vcio/connectwise/boards/project').then(function (boards) {
          $scope.boards = boards;
        });
      }

      $scope.ok = function (cb) {
        if ($scope.selected.project || $scope.sendOption === 'create') {
          $rootScope.$broadcast('dataLoadingStarted');
          if ($scope.sendOption === 'link') {
            VcioService.post(
              '/clients/' +
                $scope.selected.clientId +
                '/projects/' +
                $scope.selected.project.id +
                '/service/' +
                $scope.service.id,
            ).then(
              function (result) {
                $scope.messages = [
                  {
                    code: 'message.vcio.deliveryItemsExported',
                    translateValues: { url: result },
                  },
                ];
                $scope.ok = undefined;
                $rootScope.$broadcast('dataLoadingFinished');
                cb();
              },
              function (error) {
                $scope.errors = [error];
                $rootScope.$broadcast('dataLoadingFinished');
                cb();
              },
            );
          } else if ($scope.sendOption === 'create') {
            VcioService.post('/clients/' + $scope.selected.clientId + '/projects', {
              project: {
                name: $scope.selected.name,
                boardId: $scope.selected.boardId,
                billingMethod: $scope.selected.billingMethod,
                clientId: $scope.selected.clientId,
                OwnerId: $scope.selected.OwnerId,
                projectStart: $scope.selected.start,
                projectEnd: $scope.selected.end,
              },
            })
              .then(function (created) {
                VcioService.post(
                  '/clients/' +
                    $scope.selected.clientId +
                    '/projects/' +
                    created.id +
                    '/service/' +
                    $scope.service.id,
                )
                  .then(function (result) {
                    $scope.messages = [
                      {
                        code: 'message.vcio.deliveryItemsExported',
                        translateValues: { url: result },
                      },
                    ];
                    $scope.ok = undefined;
                    $rootScope.$broadcast('dataLoadingFinished');
                    cb();
                  })
                  .catch(function (error) {
                    $scope.errors = [error];
                    $rootScope.$broadcast('dataLoadingFinished');
                    cb();
                  });
              })
              .catch(function (error) {
                $scope.errors = [error];
                $rootScope.$broadcast('dataLoadingFinished');
                cb();
              });
          }
        } else {
          cb();
        }
      };

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };

      $scope.setOption = function (option) {
        $scope.sendOption = option;
      };

      $scope.getContactName = function (item) {
        return item
          ? (item.firstName || item.name || '') +
              ' ' +
              (item.lastName || '') +
              (item.email ? ' <' + item.email + '>' : '')
          : '';
      };

      // $scope.selectProject = function (project) {
      //     $scope.selectedProject = project;
      // }
      //
      // $scope.projectFilter = function (item) {
      //     return !$scope.filter.name ||
      //         item.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1 ||
      //         item.client.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1;
      // }
    },
  )

  .controller(
    'VcioServiceBundleController',
    function (
      $scope,
      $rootScope,
      $sce,
      $state,
      $http,
      $translate,
      $window,
      _,
      CurrentUser,
      AcademyService,
      HttpService,
      bundle,
      services,
      serviceTemplates,
    ) {
      $scope.bundle = bundle;
      $scope.courses = []; //courses;
      $scope.bundleServices = _.map($scope.bundle.Services, function (_service, serviceId) {
        return (
          _.find(services, { id: parseInt(serviceId) }) ||
          _.find(serviceTemplates, { id: parseInt(serviceId) })
        );
      });
      $scope.currency = 'currency.' + $scope.bundle.Owner.currency;

      if ($scope.bundle.steps && $scope.courses.length > 0) {
        if ($scope.bundle) {
          $scope.bundle.courses = [];
          $scope.bundle.steps.forEach(function (step) {
            if (step.ExampleImageId) {
              step.exampleUrl = '/images/' + step.ExampleImageId;
            }
            if (step.CourseId && !_.find($scope.bundle.courses, { id: parseInt(step.CourseId) })) {
              step.course = _.find($scope.courses, { id: parseInt(step.CourseId) });
              $scope.bundle.courses.push(step.course);
            }
          });
        }
      }

      if ($scope.bundle.blueprint) {
        $scope.bundle.blueprintUrl = $sce.trustAsResourceUrl(
          'https://www.lucidchart.com/documents/embeddedchart/' + $scope.bundle.blueprint,
        );
      }
      if ($scope.bundle.valueProposition) {
        $scope.bundle.valuePropositionUrl = $sce.trustAsResourceUrl(
          'https://www.lucidchart.com/documents/embeddedchart/' + $scope.bundle.valueProposition,
        );
      }

      // $scope.suggestImprovement = function () {
      //     if (typeof window.Intercom != 'undefined') {
      //         window.Intercom('showNewMessage', $translate.instant('message.suggestImprovementServiceBundle', {serviceBundleName: bundle.name}));
      //     }
      // };

      AcademyService.embedWistia($scope.bundle.video, $scope.bundle.playlist);

      $scope.canEdit = function () {
        return (
          $scope.bundle.OwnerId == CurrentUser.getUser().Company.id &&
          CurrentUser.can('editServiceBundle')
        );
      };

      $scope.edit = function () {
        $state.go('service.servicebundleEdit', { serviceBundleId: $scope.bundle.id });
      };

      $scope.print = function () {
        $window.open(
          $state.href('service.servicebundlePrint', {
            serviceBundleId: $scope.bundle.id,
          }),
          '_blank',
        );
      };
    },
  )

  .controller(
    'VcioServiceBundleEditController',
    function (
      $scope,
      $rootScope,
      $state,
      $translate,
      $http,
      $window,
      InputSanitizerService,
      $modal,
      _,
      CurrentUser,
      AcademyService,
      DialogService,
      HttpService,
      /*CourseModel, ProjectModel, */ bundle,
      services,
      serviceTemplates,
      copy,
      template,
    ) {
      $scope.currentUser = CurrentUser.getUser();
      $scope.bundle = bundle || {
        Owner: CurrentUser.getUser().Company,
        defaults: {},
        template: template,
        published: false,
      };
      $scope.services = _.keyBy(services.concat(serviceTemplates), 'id');
      $scope.bundle.Services = _.pickBy($scope.bundle.Services, function (bundle, serviceId) {
        return $scope.services[serviceId];
      });

      $scope.getBundleServices = function () {
        return _.map($scope.bundle.Services, function (_service, serviceId) {
          return _.find($scope.services, { id: parseInt(serviceId) }); // || _.find($scope.services, {TemplateId: parseInt(serviceId)});
        });
      };

      var isTemplate = function (serviceId) {
        return $scope.services[serviceId] && $scope.services[serviceId].template;
      };

      var canAddToCatalogue = function (serviceId) {
        return isTemplate(serviceId) && !$scope.bundle.template;
      };

      function refreshServices(services) {
        _.keys($scope.bundle.Services).forEach(function (serviceId) {
          if (isTemplate(serviceId)) {
            var service = _.find(services, { TemplateId: parseInt(serviceId) });
            if (service) {
              $scope.bundle.Services[service.id] = _.cloneDeep($scope.bundle.Services[serviceId]);
              delete $scope.bundle.Services[serviceId];
            }
          }
        });
      }

      if (copy) {
        $scope.bundle.originalId = $scope.bundle.id; //needed for event logging
        if ($scope.bundle.template && !template) {
          $scope.bundle.originalTemplate = $scope.bundle.template;
          $scope.TemplateId = $scope.bundle.id;
        } //needed for event logging
        delete $scope.bundle.id;
        $scope.bundle.Owner = CurrentUser.getUser().Company;
        $scope.bundle.template = !!($scope.bundle.template && template);
        $scope.bundle.name = bundle.name;
      }
      if (!$scope.bundle.template && !template) {
        refreshServices(services);
      }

      $scope.currentUser = CurrentUser.getUser();
      $scope.isVendor = function () {
        return CurrentUser.hasModule('vendor');
      };
      $scope.expanded = { services: true };
      $scope.marketingAssets = [];
      $scope.newAsset = {};
      $scope.selected = {};

      if (!$scope.bundle.steps) {
        $scope.bundle.steps = [];
      }
      $scope.bundle.steps.forEach(function (step) {
        step.exampleUrl =
          '/images/vcio/servicebundles/' +
          $scope.bundle.id +
          '/examples/' +
          step.orderId +
          '?' +
          new Date().getTime();
      });

      if (!$scope.bundle.defaults.pricing) {
        if ($scope.bundle.defaults.monthlyPrice) {
          $scope.bundle.defaults.pricing = 'labour';
        } else {
          $scope.bundle.defaults.pricing = 'unit';
        }
      }
      var oldBundle = _.cloneDeep($scope.bundle);

      $scope.isUnitPrice = function () {
        return $scope.bundle.defaults.pricing == 'unit';
      };

      $scope.courses = []; //CourseModel.query();
      $scope.projects = []; //ProjectModel.query();
      HttpService.get('/api/graders?type=template').then(function (result) {
        $scope.marketingAssets.graders = _.map(result, function (item) {
          return { id: item.id, name: item.name };
        });
      });
      HttpService.get('/api/emailsequences?template=true').then(function (result) {
        $scope.marketingAssets.emailSequences = _.map(result, function (item) {
          return { id: item.id, name: item.name };
        });
      });
      HttpService.get(
        '/api/companies/' +
          InputSanitizerService.sanitize($scope.currentUser.Company.id) +
          '/files',
      ).then(function (result) {
        $scope.marketingAssets.ebooks = _.map(result, function (item) {
          return { id: item.id, name: item.name };
        });
      });

      $scope.removeAsset = function (asset, list) {
        _.remove(list, function (item) {
          return item == asset;
        });
      };

      $scope.addStep = function () {
        if (!$scope.bundle.steps) {
          $scope.bundle.steps = [];
        }
        $scope.bundle.steps.push({
          orderId: $scope.bundle.steps.length + 1,
          todo: [],
        });
      };

      $scope.addTask = function (step) {
        if (!step.todo) {
          step.todo = [];
        }
        step.todo.push({
          text: '',
          orderId: step.todo.length + 1,
        });
      };

      $scope.moveUp = AcademyService.moveUp;

      $scope.moveDown = AcademyService.moveDown;

      $scope.remove = AcademyService.remove;

      $scope.addAsset = function (type) {
        var modalInstance = $modal.open({
          templateUrl: '/templates/modal-select.html',
          controller: 'ModalSelectController',
          backdrop: 'static',
          resolve: {
            model: function () {
              return $scope.newAsset[type];
            },
            values: function () {
              return $scope.marketingAssets[type];
            },
            required: function () {
              return true;
            },
          },
        });

        modalInstance.result.then(
          function (asset) {
            if (asset) {
              if (!$scope.bundle.marketingAssets) {
                $scope.bundle.marketingAssets = {};
              }
              if (!$scope.bundle.marketingAssets[type]) {
                $scope.bundle.marketingAssets[type] = [];
              }
              if (
                asset &&
                asset.id &&
                !_.find($scope.bundle.marketingAssets[type], { id: asset.id })
              ) {
                $scope.bundle.marketingAssets[type].push(asset);
              }
              $scope.newAsset[type] = undefined;
            }
          },
          function () {},
        );
      };

      function saveBundle(cb) {
        $scope.bundle.CourseId = $scope.bundle.CourseId || null; // undefined is not saved at db side
        $scope.bundle.ProjectId = $scope.bundle.ProjectId || null;
        var req;
        if ($scope.bundle.id) {
          req = HttpService.post(
            '/api/service/servicebundles/' + InputSanitizerService.sanitize($scope.bundle.id),
            { serviceBundle: $scope.bundle },
          );
        } else {
          req = HttpService.put('/api/service/servicebundles', { serviceBundle: $scope.bundle });
        }
        req
          .then(function (result) {
            if (!$scope.bundle.id) {
              $state.go('service.servicebundleEdit', { serviceBundleId: result.id });
            } else {
              $scope.bundle = result;
              oldBundle = _.cloneDeep($scope.bundle);
              $scope.messages = ['message.saved'];
              cb();
            }
          })
          .catch(function (error) {
            console.error(error);
            $scope.errors = [error];
            cb();
          });
      }

      $scope.save = function (cb) {
        if ($scope.bundle.defaults.pricing == 'labour') {
          $scope.bundle.defaults.unitType = undefined;
          $scope.bundle.defaults.unitPrice = undefined;
        } else {
          $scope.bundle.defaults.monthlyPrice = undefined;
          $scope.bundle.defaults.hourlyRate = undefined;
        }

        if (
          oldBundle &&
          $scope.bundle.defaults.pricing == 'labour' &&
          $scope.bundle.defaults.hourlyRate > 0 &&
          $scope.bundle.defaults.hourlyRate != oldBundle.defaults.hourlyRate
        ) {
          DialogService.confirm(
            'label.vcio.serviceBundleEdit.massHourlyRateConfirmMessage',
            function () {
              _.forEach($scope.bundle.Services, function (service) {
                if (service.hourlyRate > 0) {
                  service.hourlyRate = $scope.bundle.defaults.hourlyRate;
                }
              });
              saveBundle(cb);
            },
            function () {
              saveBundle(cb);
            },
          );
        } else {
          saveBundle(cb);
        }
      };

      $scope.cancel = function () {
        // if ($rootScope.previousState && $rootScope.previousState.name
        //     && $state.current.name != $rootScope.previousState.name) {
        //     $state.go($rootScope.previousState.name, $rootScope.previousState.params);
        // } else {
        if (template || $scope.bundle.template) {
          $state.go('service.servicebundletemplates');
        } else {
          $state.go('service.servicebundles');
        }
        // }
      };

      $scope.showAddAllButton = function () {
        return !$scope.bundle.template && !!_.find(_.keys($scope.bundle.Services), isTemplate);
      };

      $scope.removeService = function (serviceId) {
        delete $scope.bundle.Services[serviceId];
      };

      $scope.editService = function (serviceId) {
        var modalInstance = $modal.open({
          templateUrl: '/templates/vcio/service-bundle-service-edit.html',
          controller: 'VcioServiceBundleServiceEditController',
          backdrop: 'static',
          resolve: {
            bundleService: function () {
              return angular.copy($scope.bundle.Services[serviceId]);
            },
            bundle: function () {
              return $scope.bundle;
            },
            service: function () {
              return $scope.services[serviceId];
            },
            autoSave: function () {
              return false;
            },
          },
        });

        modalInstance.result.then(
          function () {},
          function () {},
        );
      };

      $scope.addService = function () {
        var modalInstance = $modal.open({
          template:
            '<modal-form form-title="label.serviceBundleEdit.selectService" onok="ok" oncancel="cancel"><input-select model="selected.service" values="services" required="true"></input-select></modal-form>',
          controller: function ($modalInstance, $scope, services) {
            $scope.services = services;
            $scope.selected = {};
            $scope.ok = function () {
              $modalInstance.close($scope.selected.service);
            };
            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
          backdrop: 'static',
          resolve: {
            services: function () {
              return _.sortBy(
                _.filter(_.toArray($scope.services), { template: $scope.bundle.template }),
                'name',
              );
            },
          },
        });

        modalInstance.result.then(
          function (service) {
            $scope.editService(service.id);
          },
          function () {},
        );
      };

      $scope.addAllToCatalogue = function (ids) {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.post('/api/service/services/templates', {
          templateIds: ids || _.keys($scope.bundle.Services),
        })
          .then(function (result) {
            var services = result;
            _.extend($scope.services, _.keyBy(services, 'id'));
            refreshServices(services);

            if ($scope.bundle.id) {
              saveBundle(function () {
                $rootScope.$broadcast('dataLoadingFinished');
              });
            } else {
              $rootScope.$broadcast('dataLoadingFinished');
            }
          })
          .catch(function (error) {
            DialogService.error(error);
          });
      };

      $scope.addToCatalogue = function (serviceId) {
        $scope.addAllToCatalogue([serviceId]);
      };

      $scope.serviceOperations = [
        {
          operation: $scope.editService,
          label: 'button.edit',
          icon: 'edit',
        },
        {
          operation: $scope.removeService,
          label: 'button.remove',
          icon: 'remove',
          confirmation: true,
        },
        {
          operation: $scope.addToCatalogue,
          label: 'button.services.addToCatalogue',
          icon: 'plus',
          condition: canAddToCatalogue,
        },
      ];
    },
  )

  .controller(
    'VcioServiceBundlePrintController',
    function ($scope, _, services, serviceTemplates, bundle) {
      $scope.bundle = bundle;
      //var _services = services.concat(serviceTemplates);

      $scope.services = [];
      for (var serviceId in $scope.bundle.Services) {
        if ($scope.bundle.Services.hasOwnProperty(serviceId)) {
          var value = _.find(services, { id: parseInt(serviceId) });
          if (value) {
            $scope.services.push(_.extend($scope.bundle.Services[serviceId], { value: value }));
          }
        }
      }
    },
  )

  .controller(
    'VcioServiceBundlesController',
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $http,
      $window,
      $modal,
      $q,
      _,
      CallToActionModel,
      CurrentUser,
      AcademyService,
      HttpService,
      PermissionGroupModel,
      serviceBundles,
      services,
      serviceTemplates,
    ) {
      $scope.myPermissionGroups = [];
      $scope.can = CurrentUser.can;
      PermissionGroupModel.querySubscribed().$promise.then(function (result) {
        var listPermissionGroups = _(serviceBundles)
          .flatMap(function (item) {
            return _.get(item, 'Template.PermissionGroups') || _.get(item, 'PermissionGroups', []);
          })
          .map('id')
          .uniq()
          .value();
        $scope.myPermissionGroups = _.filter(result, function (item) {
          return _.includes(listPermissionGroups, item.id);
        });
      });
      $scope.userHasNoServices = false;
      $scope.type =
        $state.$current.name === 'service.servicebundletemplates' ? 'templates' : 'bundles';
      $scope.isTemplates = $scope.type === 'templates';
      $scope.serviceBundles = serviceBundles;
      $scope.bundleTemplates = [];
      if ($scope.serviceBundles.length === 0 && $scope.type === 'bundles') {
        $scope.userHasNoServices = true;
      }
      $scope.currentUser = CurrentUser.getUser();
      $scope.isVendor = CurrentUser.isVendor;
      $scope.filter = { selected: false };
      $scope.templates = $state.current.name === 'service.servicebundletemplates';
      $scope.services = _.keyBy(services.concat(serviceTemplates), 'id');
      $scope.filter = { name: '', all: false, permissionGroups: [] };
      if ($stateParams.permissionGroupId) {
        $scope.filter.permissionGroups = _.map(
          $stateParams.permissionGroupId.split(','),
          function (val) {
            return parseInt(val);
          },
        );
      }

      $scope.newBundle = {};

      $scope.clickPermissionGroupFilter = function (permissionGroupId) {
        $state.transitionTo(
          'service.servicebundlesByPermissionGroup',
          { permissionGroupId: permissionGroupId.join() },
          { notify: false },
        );
      };

      $scope.vendorFilter = AcademyService.vendorFilter;
      $scope.nameFilter = function (bundle) {
        return (
          !$scope.filter.name ||
          bundle.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1
        );
      };
      $scope.companyFilter = function (bundle) {
        return !$scope.filter.onlyMyCompany || bundle.OwnerId == $scope.currentUser.Company.id;
      };
      $scope.permissionGroupFilter = function (item) {
        return (
          _.isEmpty($scope.filter.permissionGroups) ||
          (item.Template &&
            _.find(item.Template.PermissionGroups, function (permissionGroup) {
              return _.includes($scope.filter.permissionGroups, permissionGroup.id);
            })) ||
          _.find(item.PermissionGroups, function (permissionGroup) {
            return _.includes($scope.filter.permissionGroups, permissionGroup.id);
          })
        );
      };

      $scope.selectBundle = function (bundle) {
        bundle.selected = !bundle.selected;
        if ($scope.filter.selected) {
          $scope.filter.selected = false;
        }
      };
      $scope.selectAll = function (deselect) {
        $scope.filter.selected = deselect ? false : !$scope.filter.selected;
        $scope.serviceBundles.forEach(function (bundle) {
          if (bundle.OwnerId == $scope.currentUser.Company.id) {
            bundle.selected = $scope.filter.selected && $scope.nameFilter(bundle);
          }
        });
      };

      $scope.$watch('filter.name', function () {
        $scope.selectAll(true);
      });

      $scope.save = function (newBundle, cb) {
        if (newBundle.template) {
          $state.go('service.servicebundleCopy', { serviceBundleId: newBundle.template });
        }
      };

      $scope.cancel = function (newBundle) {
        $scope.newBundle = {};
      };

      $scope.createBundle = function () {
        if ($scope.type === 'templates') {
          $state.go('service.servicebundletemplateEdit', { serviceBundleId: 'new' });
        } else {
          $rootScope.$broadcast('dataLoadingStarted');
          HttpService.get('/api/service/servicebundles/templates').then(
            function (bundleTemplates) {
              $scope.bundleTemplates = bundleTemplates;
              $scope.newBundle = { expanded: true };
              $rootScope.$broadcast('dataLoadingFinished');
            },
            function (error) {
              $rootScope.$broadcast('dataLoadingFinished');
              DialogService.error(error);
            },
          );
        }
      };

      $scope.copyBundle = function (bundle) {
        if ($scope.type === 'templates') {
          $state.go('service.servicebundletemplateCopy', { serviceBundleId: bundle.id });
        } else {
          $state.go('service.servicebundleCopy', { serviceBundleId: bundle.id });
        }
      };

      $scope.addCatBundle = function (bundle) {
        $state.go('service.servicebundleCopy', { serviceBundleId: bundle.id });
      };

      $scope.canCopyBundle = function (bundle) {
        return (
          (!bundle || !bundle.OwnerId || CurrentUser.getUser().Company.id == bundle.OwnerId) &&
          CurrentUser.can('editServiceBundle')
        );
      };
      $scope.canAddCatBundle = function () {
        return $scope.type === 'templates' && CurrentUser.can('editServiceBundle');
      };
      $scope.canEditBundle = function (bundle) {
        return (
          bundle.OwnerId == $scope.currentUser.Company.id && CurrentUser.can('editServiceBundle')
        );
      };
      $scope.canBuildBundle = function () {
        return !$scope.templates && CurrentUser.can('editServiceBundle');
      };

      $scope.editBundle = function (bundle) {
        $state.go('service.servicebundleEdit', { serviceBundleId: bundle.id });
      };

      $scope.buildBundle = function (bundle) {
        $state.go('service.servicebundlebuilder', { serviceBundleIds: [bundle.id] });
      };

      $scope.buildBundles = function () {
        var selected = _.filter($scope.serviceBundles, { selected: true });
        if (selected.length > 0) {
          $state.go('service.servicebundlebuilder', { serviceBundleIds: _.map(selected, 'id') });
        } else {
          $state.go('service.servicebundlebuilder');
        }
      };

      $scope.deleteBundle = function (bundle) {
        HttpService.delete('/api/service/servicebundles/' + bundle.id)
          .then(function () {
            _.remove($scope.serviceBundles, { id: bundle.id });
          })
          .catch(function (error) {
            $scope.errors = [error];
          });
      };

      $scope.printBundle = function (bundle) {
        $window.open(
          $state.href('service.servicebundlePrint', {
            serviceBundleId: bundle.id,
          }),
          '_blank',
        );
      };

      $scope.shareBundle = function (bundle) {
        var modalInstance = $modal.open({
          templateUrl: '/templates/vcio/service-bundle-share.html',
          controller: 'VcioServiceBundleShareController',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            callToAction: function (CallToActionModel) {
              return CallToActionModel.getByType({ type: 'servicebundle', objectId: bundle.id })
                .$promise;
            },
            bundleId: function () {
              return bundle.id;
            },
          },
        });
      };

      // $scope.bundleOperations = [{
      //     operation: $scope.editBundle,
      //     label: 'button.edit',
      //     icon: 'edit',
      //     condition: $scope.canEditBundle
      // }, {
      //     operation: $scope.deleteBundle,
      //     label: 'button.delete',
      //     icon: 'minus-square-o',
      //     condition: $scope.canEditBundle,
      //     confirmation: true
      // }, {
      //     operation: $scope.copyBundle,
      //     label: 'button.copy',
      //     icon: 'copy',
      //     condition: $scope.canCopyBundle
      // }, {
      //     operation: $scope.addCatBundle,
      //     label: 'button.vcio.addToCatalogue',
      //     icon: 'copy',
      //     condition: $scope.canAddCatBundle
      // }, {operation: $scope.printBundle, label: 'button.print', icon: 'print'},
      //     {operation: $scope.shareBundle, label: 'button.share', icon: 'share-alt'}];

      $scope.getCheckbox = function (bundle) {
        if ($scope.canEditBundle(bundle)) {
          return bundle.selected ? 'fa-check-square-o' : 'fa-square-o';
        } else {
          return '';
        }
      };
    },
  )

  .controller(
    'VcioServiceBundleShareController',
    function (
      $http,
      $modalInstance,
      $scope,
      uuid,
      _,
      CallToActionModel,
      CurrentUser,
      callToAction,
      bundleId,
    ) {
      $http.post(
        '/api/admin/users/' + CurrentUser.getUser().id + '/timeline/vcioServicebundleShared',
        { id: bundleId },
      );

      $scope.callToAction = callToAction.uuid
        ? callToAction
        : {
            uuid: uuid.v4(),
            type: 'servicebundle',
            objectId: bundleId,
          };
      $scope.currentView = 'button';
      $scope.sizes = ['S', 'M', 'L'];
      $scope.buttonStyle = {};
      $scope.callToAction.design = $scope.callToAction.design || {};
      if (!$scope.callToAction.design.button) {
        $scope.callToAction.design.button = {
          //width: '100%',
          fontSize: 'M',
          textColor: '#333333',
          backgroundColor: '#e7e7e7',
          borderColor: '#e7e7e7',
          text: 'View bundle...',
          fontFamily: 'Arial',
        };
      }
      // if (!$scope.callToAction.id) {
      //     $scope.callToAction.type = 'servicebundle';
      //     $scope.callToAction.objectId = bundleId;
      //     var _callToAction = new CallToActionModel($scope.callToAction);
      //     _callToAction.$save(function (result) {
      //         $scope.callToAction.id = result.id;
      //     }, function (error) {
      //         $scope.errors = [error];
      //     });
      // }
      $scope.callToActionUrl =
        'https://' + apphostname + '/js/insertcta.js?ctaId=' + $scope.callToAction.uuid;
      $scope.callToActionElementId = 'ryc-insert-script-' + $scope.callToAction.uuid;
      $scope.callToActioniFrameUrl =
        'https://' + apphostname + '/showservicebundle?bundle=' + bundleId;

      $scope.$watch(
        'callToAction.design',
        function () {
          $scope.buttonStyle.color = $scope.callToAction.design.button.textColor;
          $scope.buttonStyle.backgroundColor = $scope.callToAction.design.button.backgroundColor;
          $scope.buttonStyle.border = '1px solid ' + $scope.callToAction.design.button.borderColor;
          $scope.buttonStyle.borderRadius =
            ($scope.callToAction.design.button.borderRadius || '0') + 'px';
          $scope.buttonStyle.fontFamily = $scope.callToAction.design.button.fontFamily;
          $scope.buttonStyle.fontSize = '15px';
          if ($scope.callToAction.design.button.fontSize === 'S') {
            $scope.buttonStyle.fontSize = '10px';
          } else if ($scope.callToAction.design.button.fontSize === 'L') {
            $scope.buttonStyle.fontSize = '24px';
          }
          if ($scope.callToAction.design.button.fullWidth) {
            $scope.buttonStyle.width = '100%';
          } else {
            delete $scope.buttonStyle.width;
          }
        },
        true,
      );

      $scope.save = function (cb) {
        var _callToAction = new CallToActionModel($scope.callToAction);
        if (_callToAction.id) {
          _callToAction.$update(
            function (result) {
              $modalInstance.close(result);
            },
            function (error) {
              $scope.errors = [error];
              cb();
            },
          );
        } else {
          _callToAction.$save(
            function (result) {
              $modalInstance.close(result);
            },
            function (error) {
              $scope.errors = [error];
              cb();
            },
          );
        }
      };

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };

      $scope.setView = function (view) {
        $scope.currentView = view;
      };
    },
  )

  .controller(
    'VcioServiceBundleBuilderController',
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $http,
      $window,
      $modal,
      $q,
      $timeout,
      $translate,
      _,
      AcademyService,
      CurrentUser,
      DialogService,
      HttpService,
      LocalStorage,
      serviceBundles,
      serviceGroups,
    ) {
      // if ($stateParams.serviceBundleIds && $stateParams.serviceBundleIds.length > 0) {
      //     $scope.serviceBundles = _.filter(serviceBundles, function (item) {
      //         return _.includes($stateParams.serviceBundleIds.split(','), item.id + '');
      //     });
      // } else {
      $scope.userHasNoServices = false;
      $scope.allServiceBundles = _(serviceBundles).sortBy('name').value();
      $scope.serviceBundles = [];
      $scope.bundleTemplates = [];
      $scope.newBundle = {};
      $scope.can = CurrentUser.can;
      if (LocalStorage.get('serviceBundleBuilderBundles')) {
        var ids = LocalStorage.getObject('serviceBundleBuilderBundles');
        _.forEach(ids, function (bundleId) {
          var bundle = _.find($scope.allServiceBundles, { id: bundleId });
          if (bundle) {
            bundle.active = true;
            $scope.serviceBundles.push(bundle);
          }
        });
      }
      if ($scope.allServiceBundles.length === 0) {
        $scope.userHasNoServices = true;
        $rootScope.$broadcast('dataLoadingStarted');
        $state.go('service.servicebundletemplates');
      }
      $scope.currentUser = CurrentUser.getUser();
      $scope.isVendor = CurrentUser.isVendor;

      // if (!$stateParams.serviceBundleIds || $stateParams.serviceBundleIds.length === 0) {
      //     _.forEach($scope.serviceBundles, function (bundle, i) {
      //         bundle.orderId = i + 1;
      //     })
      // }

      $scope.serviceGroups = serviceGroups;
      $scope.services = {};
      $scope.serviceCycles = ['', 'na', 'annual', 'quarterly', 'monthly', 'weekly'];
      $scope.filter = {};

      function reindexServiceOrGroup(arrData) {
        _.forEach(arrData, function (item, index) {
          item.orderId = index + 1;
        });
      }

      $scope.serviceGroups = _.orderBy($scope.serviceGroups, 'orderId');
      reindexServiceOrGroup($scope.serviceGroups);
      _.forOwn($scope.serviceGroups, function (serviceGroup) {
        serviceGroup.Services = _.orderBy(serviceGroup.Services, 'orderId');
        reindexServiceOrGroup(serviceGroup.Services);
      });

      function saveServiceGroupOrder() {
        var serviceGroupsPost = [];
        _.forEach($scope.serviceGroups, function (serviceGroup) {
          serviceGroupsPost.push({
            id: serviceGroup.id,
            orderId: serviceGroup.orderId,
          });
        });
        HttpService.post('/api/service/servicegroups/reorder', {
          serviceGroupIds: serviceGroupsPost,
        });
      }

      function saveServiceOrder(group) {
        var servicesPost = [];
        _.forEach(group.Services, function (service) {
          servicesPost.push({
            id: service.id,
            orderId: service.orderId,
          });
        });
        HttpService.post('/api/service/services/reorder', { serviceIds: servicesPost });
      }

      function saveService(service, cb) {
        var req;
        $rootScope.$broadcast('dataLoadingStarted');
        if (service.id) {
          req = HttpService.put('/api/service/services', { service: service });
        } else {
          req = HttpService.post('/api/service/services', { service: service });
        }
        req.then(
          function (result) {
            $rootScope.$broadcast('dataLoadingFinished');
            if (!service.id) {
              service.id = result.id;
            }
            if (cb) {
              cb(null);
            }
          },
          function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            console.error(error);
            DialogService.error(error);
            if (cb) {
              cb(error);
            }
          },
        );
      }

      var storeServiceGroupsExpanded = {};
      var restoreServiceGroupsExpanded = function () {
        $scope.serviceGroups.forEach(function (serviceGroup) {
          serviceGroup.expanded = storeServiceGroupsExpanded[serviceGroup.id];
        });
      };
      var serviceDragged = false;
      var serviceGroupDragged = null;
      $scope.serviceClick = function (service) {
        if (serviceDragged) {
          serviceDragged = false;
        } else {
          $state.go('service.service', { serviceId: service.id });
        }
      };
      $scope.onDropServiceGroupSuccess = function (dragData, dropData) {
        var newServiceGroup = dropData;
        newServiceGroup.Services = newServiceGroup.Services || [];
        if (dragData.Services) {
          var serviceGroup = dragData;
          if (serviceGroup.orderId != newServiceGroup.orderId) {
            $scope.serviceGroups.splice(
              newServiceGroup.orderId - 1,
              0,
              $scope.serviceGroups.splice(serviceGroup.orderId - 1, 1)[0],
            );
            reindexServiceOrGroup($scope.serviceGroups);
            saveServiceGroupOrder();
          }
        } else {
          var service = dragData;
          var oldServiceGroup = _.find($scope.serviceGroups, { id: service.ServiceGroupId });
          if (service.ServiceGroupId !== newServiceGroup.id) {
            service.ServiceGroupId = newServiceGroup.id;
            oldServiceGroup.Services = _.filter(oldServiceGroup.Services, function (oldService) {
              return oldService.id != service.id;
            });
            newServiceGroup.Services.push(service);
            saveService(service);
          }
        }
      };
      $scope.onDropServiceSuccess = function (dragData, dropData) {
        var service = dragData;
        var newService = dropData;
        if (
          service.ServiceGroupId === newService.ServiceGroupId &&
          service.orderId != newService.orderId
        ) {
          var oldServiceGroup = _.find($scope.serviceGroups, { id: service.ServiceGroupId });
          oldServiceGroup.Services.splice(
            newService.orderId - 1,
            0,
            oldServiceGroup.Services.splice(service.orderId - 1, 1)[0],
          );
          reindexServiceOrGroup(oldServiceGroup.Services);
          saveServiceOrder(oldServiceGroup);
        }
      };
      $scope.onDragServiceStart = function (service) {
        if (!serviceDragged && !serviceGroupDragged) {
          serviceDragged = _.extend(_.clone(service), { id: -1 });
          $scope.serviceGroups.forEach(function (serviceGroup) {
            storeServiceGroupsExpanded[serviceGroup.id] = serviceGroup.expanded;
            serviceGroup.expanded = true;
            if (serviceGroup.id == serviceDragged.ServiceGroupId) {
              serviceGroup.Services.push(serviceDragged);
            }
          });
        }
      };
      $scope.onDragServiceGroupStart = function (serviceGroup) {
        if (!serviceDragged && !serviceGroupDragged) {
          $scope.serviceGroups.forEach(function (_serviceGroup) {
            storeServiceGroupsExpanded[_serviceGroup.id] = _serviceGroup.expanded;
          });
          serviceGroup.expanded = false;
          serviceGroupDragged = _.extend(_.clone(serviceGroup), { id: -1 });
          $scope.serviceGroups.push(serviceGroupDragged);
        }
      };
      $scope.onDragStop = function () {
        restoreServiceGroupsExpanded();
        if (serviceGroupDragged) {
          serviceGroupDragged = null;
          $scope.serviceGroups = _.filter($scope.serviceGroups, function (serviceGroup) {
            return serviceGroup.id != -1;
          });
        } else if (serviceDragged) {
          var serviceGroup = _.find($scope.serviceGroups, { id: serviceDragged.ServiceGroupId });
          serviceDragged = false;
          if (serviceGroup) {
            serviceGroup.Services = _.filter(serviceGroup.Services, function (service) {
              return service.id != -1;
            });
          }
        }
      };
      var hoverTimeout;
      var lastHoverServiceGroupId = null;
      $scope.onDragHover = function (dragData, dropData) {
        if (dragData.Services) {
          if (serviceGroupDragged && lastHoverServiceGroupId !== dropData.id && dropData !== -1) {
            $timeout.cancel(hoverTimeout);
            hoverTimeout = $timeout(function () {
              if (dropData.orderId < serviceGroupDragged.orderId) {
                console.log(1, dropData);
                serviceGroupDragged.orderId = dropData.orderId - 1;
              } else {
                console.log(2, dropData);
                serviceGroupDragged.orderId = dropData.orderId;
              }
            }, 1);
          }
          lastHoverServiceGroupId = dropData.id;
        } else if (
          serviceDragged &&
          dragData.ServiceGroupId == dropData.ServiceGroupId &&
          dropData !== -1
        ) {
          $timeout.cancel(hoverTimeout);
          hoverTimeout = $timeout(function () {
            if (dropData.orderId < serviceDragged.orderId) {
              serviceDragged.orderId = dropData.orderId - 1;
            } else {
              serviceDragged.orderId = dropData.orderId;
            }
          }, 1);
        }
      };
      $scope.canDropServiceGroup = function (dropData) {
        return (
          CurrentUser.can('editServiceBundle') &&
          (serviceGroupDragged || serviceDragged.ServiceGroupId != dropData.Services)
        );
      };

      $scope.canEditServiceGroup = function (serviceGroup) {
        return (
          CurrentUser.can('editServiceBundle') &&
          (!serviceGroup ||
            !serviceGroup.VendorId ||
            CurrentUser.getUser().Company.id == serviceGroup.VendorId)
        );
      };
      $scope.canEditService = function (service) {
        return (
          !service ||
          !service.VendorId ||
          (CurrentUser.can('editService') &&
            CurrentUser.getUser().Company.id == service.VendorId &&
            !service.template)
        );
      };

      // $scope.canReorder = !$stateParams.serviceBundleIds;
      $scope.createBundle = function () {
        HttpService.get('/api/service/servicebundles/templates').then(
          function (bundleTemplates) {
            $scope.bundleTemplates = bundleTemplates;
            $scope.newBundle = { expanded: true };
          },
          function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
          },
        );
      };

      $scope.saveNewBundle = function (newBundle, cb) {
        if (newBundle.template) {
          $state.go('service.servicebundleCopy', { serviceBundleId: newBundle.template });
        } else {
          $state.go('service.servicebundleEdit', { serviceBundleId: 'new' });
        }
      };

      $scope.getServicePopover = AcademyService.getServicePopover;

      $scope.toggleBundleToTable = function (bundle) {
        if (bundle.active) {
          _.remove($scope.serviceBundles, { id: bundle.id });
          LocalStorage.setObject('serviceBundleBuilderBundles', _.map($scope.serviceBundles, 'id'));
          bundle.active = false;
        } else if ($scope.serviceBundles.length < 5) {
          $scope.serviceBundles.push(bundle);
          $scope.calculatePrices();
          bundle.active = true;
          LocalStorage.setObject('serviceBundleBuilderBundles', _.map($scope.serviceBundles, 'id'));
        }
      };

      $scope.showCalculation = function (bundle) {
        //console.log(bundle.defaults.pricing);
        return bundle.defaults.pricing != 'unit';
      };

      $scope.calculatePrices = function () {
        $scope.serviceBundles.forEach(function (bundle) {
          if ($scope.showCalculation(bundle)) {
            bundle.calculated = {
              annual: { hours: 0, price: 0 },
              quarterly: { hours: 0, price: 0 },
              monthly: { hours: 0, price: 0 },
              weekly: { hours: 0, price: 0 },
              total: { hours: 0, price: 0 },
            };
            for (var serviceId in bundle.Services) {
              if ($scope.services.hasOwnProperty(serviceId)) {
                var bundleService = bundle.Services[serviceId];
                if (
                  bundleService &&
                  bundleService.hours &&
                  bundleService.hourlyRate &&
                  bundleService.cycle != 'na'
                ) {
                  bundle.calculated[bundleService.cycle].hours += bundleService.hours;
                  bundle.calculated[bundleService.cycle].price +=
                    bundleService.hours * bundleService.hourlyRate;
                }
              }
            }
            //bundle.calculated.annual.hours = bundle.calculated.annual.hours / 12;
            //bundle.calculated.annual.price = bundle.calculated.annual.price / 12;
            //
            //bundle.calculated.quarterly.hours = bundle.calculated.quarterly.hours * 3 / 12;
            //bundle.calculated.quarterly.price = bundle.calculated.quarterly.price * 3 / 12;
            //
            //bundle.calculated.monthly.hours = bundle.calculated.monthly.hours * 8 / 12;
            //bundle.calculated.monthly.price = bundle.calculated.monthly.price * 8 / 12;
            //
            //bundle.calculated.weekly.hours = bundle.calculated.weekly.hours * 24 / 12;
            //bundle.calculated.weekly.price = bundle.calculated.weekly.price * 24 / 12;

            bundle.calculated.total.hours =
              bundle.calculated.annual.hours / 12 +
              (bundle.calculated.quarterly.hours * 3) / 12 +
              (bundle.calculated.monthly.hours * 8) / 12 +
              (bundle.calculated.weekly.hours * 24) / 12;
            bundle.calculated.total.price =
              bundle.calculated.annual.price / 12 +
              (bundle.calculated.quarterly.price * 3) / 12 +
              (bundle.calculated.monthly.price * 8) / 12 +
              (bundle.calculated.weekly.price * 24) / 12;
          } else {
            bundle.units = 1;
          }
        });
      };

      function refresh() {
        $scope.serviceGroups.forEach(function (group) {
          group.Services.forEach(function (service) {
            $scope.services[service.id] = service;
            $scope.serviceBundles.forEach(function (bundle, index) {
              if (!bundle.orderId) {
                bundle.orderId = index;
              }
            });
          });
        });
        $scope.calculatePrices();
      }

      refresh();

      $scope.serviceFilter = function (service) {
        return (
          !$scope.filter.service ||
          service.name.toLowerCase().indexOf($scope.filter.service.toLowerCase()) > -1
        );
      };

      $scope.serviceGroupFilter = function (item) {
        return !$scope.filter.service || _.find(item.Services, $scope.serviceFilter);
      };

      $scope.expandAll = function () {
        $scope.serviceGroups.forEach(function (group) {
          group.expanded = true;
        });
        $scope.expanded = true;
      };

      $scope.collapseAll = function () {
        $scope.serviceGroups.forEach(function (group) {
          group.expanded = false;
        });
        $scope.expanded = false;
      };

      $scope.revert = function () {
        HttpService.get('/api/service/servicegroups').then(function (serviceGroups) {
          $scope.serviceGroups = serviceGroups;
          HttpService.get('/api/service/servicebundles').then(function (bundles) {
            $scope.serviceBundles = bundles;
            refresh();
          });
        });
      };

      $scope.canCopyBundle = function (bundle) {
        return (
          (!bundle || !bundle.OwnerId || CurrentUser.getUser().Company.id == bundle.OwnerId) &&
          CurrentUser.can('editServiceBundle')
        );
      };
      $scope.canEditBundle = function (bundle) {
        return (
          bundle.OwnerId == $scope.currentUser.Company.id && CurrentUser.can('editServiceBundle')
        );
      };

      $scope.editBundle = function (bundle) {
        $state.go('service.servicebundleEdit', { serviceBundleId: bundle.id });
      };
      $scope.deleteBundle = function (bundle) {
        HttpService.delete('/api/service/servicebundles/' + bundle.id)
          .then(function () {
            _.remove($scope.allServiceBundles, { id: bundle.id });
            $scope.toggleBundleToTable(bundle);
          })
          .catch(function (error) {
            $scope.errors = [error];
          });
      };
      $scope.printBundle = function (bundle) {
        $window.open(
          $state.href('service.servicebundlePrint', {
            serviceBundleId: bundle.id,
          }),
          '_blank',
        );
      };
      $scope.shareBundle = function (bundle) {
        var modalInstance = $modal.open({
          templateUrl: '/templates/vcio/service-bundle-share.html',
          controller: 'VcioServiceBundleShareController',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            callToAction: function (CallToActionModel) {
              return CallToActionModel.getByType({ type: 'servicebundle', objectId: bundle.id })
                .$promise;
            },
            bundleId: function () {
              return bundle.id;
            },
          },
        });
      };
      $scope.copyBundle = function (bundle) {
        $state.go('service.servicebundleCopy', { serviceBundleId: bundle.id });
      };

      //$scope.save = function (cb) {
      //    HttpService.post('/api/service/servicebundles', {serviceBundles: $scope.serviceBundles})
      //        .then(function (result) {
      //            $scope.messages = ['message.saved'];
      //            cb();
      //        }).catch(function (error) {
      //            $scope.errors = [error];
      //            cb();
      //        })
      //
      //}
      //
      // $scope.addBundle = function () {
      //     $scope.editBundle({orderId: $scope.serviceBundles.length + 1, Services: {}});
      // }
      //
      // $scope.createServiceBundle = function () {
      //     $state.go('vcio.servicebundle', {serviceBundleId: 'new'});
      // }
      //
      // $scope.createServiceBundleFromTemplate = function () {
      //     $scope.selectedTemplate = undefined;
      //     HttpService.get('/api/service/servicebundles/templates')
      //         .then(function (templates) {
      //             var modalInstance = $modal.open({
      //                 templateUrl: '/templates/modal-select.html',
      //                 controller: 'ModalSelectController',
      //                 backdrop: 'static',
      //                 resolve: {
      //                     model: function () {
      //                         return $scope.selectedTemplate;
      //                     },
      //                     values: function () {
      //                         return templates;
      //                     }
      //                 }
      //             });
      //
      //             modalInstance.result.then(function (template) {
      //                 if (template) {
      //                     $state.go('vcio.servicebundleCopy', {serviceBundleId: template.id});
      //                 }
      //             }, function () {
      //             });
      //         })
      // }

      $scope.saveBundle = function (bundle) {
        HttpService.post('/api/service/servicebundles/' + bundle.id, {
          serviceBundle: bundle,
        }).then(function (result) {});
      };

      // $scope.removeBundle = function (bundle) {
      //     HttpService.delete('/api/service/servicebundles/' + bundle.id)
      //         .then(function () {
      //             _.remove($scope.serviceBundles, {id: bundle.id});
      //         }).catch(function (error) {
      //         $scope.errors = [error];
      //     })
      // }
      //
      // $scope.moveLeft = function (item, list) {
      //     if (item.orderId > 1) {
      //         var prev = _.find(list, {orderId: (item.orderId - 1)});
      //         item.orderId -= 1.5;
      //         prev.orderId++;
      //         item.orderId += 0.5;
      //         $scope.saveBundle(item);
      //         $scope.saveBundle(prev);
      //     }
      // };
      //
      // $scope.moveRight = function (item, list) {
      //     if (item.orderId < list.length) {
      //         var next = _.find(list, {orderId: (item.orderId + 1)});
      //         item.orderId += 1.5;
      //         next.orderId--;
      //         item.orderId -= 0.5;
      //         $scope.saveBundle(item);
      //         $scope.saveBundle(next);
      //     }
      // };
      //
      //$scope.getCellClass = function (bundle, service) {
      //    return bundle.Services[service.id].changed ? "alert-warning" : {};
      //}

      $scope.openEditService = function (bundle, service) {
        $scope.selectedBundleService = bundle.Services[service.id];
        var modalInstance = $modal.open({
          templateUrl: '/templates/vcio/service-bundle-service-edit.html',
          controller: 'VcioServiceBundleServiceEditController',
          backdrop: 'static',
          resolve: {
            bundleService: function () {
              return angular.copy($scope.selectedBundleService);
            },
            bundle: function () {
              return bundle;
            },
            service: function () {
              return service;
            },
            autoSave: function () {
              return true;
            },
          },
        });

        modalInstance.result.then(
          function () {
            $scope.calculatePrices();
          },
          function () {},
        );
      };

      $scope.getUnitTypeLetter = function (cycle) {
        return $translate
          .instant('label.academy.serviceCycle.' + cycle)
          .substring(0, 1)
          .toUpperCase();
      };
    },
  )

  .controller(
    'VcioServiceBundleServiceEditController',
    function (
      $scope,
      $rootScope,
      $state,
      $modalInstance,
      $http,
      _,
      CurrentUser,
      HttpService,
      bundle,
      service,
      bundleService,
      autoSave,
      InputSanitizerService,
    ) {
      $scope.bundle = bundle;
      $scope.autoSave = autoSave;
      $scope.bundleService = bundleService || {
        cycle: service.cycle,
        // unitType: service.unitType,
        // unitPrice: service.unitPrice
        hourlyRate: bundle.defaults.hourlyRate,
        hours: service.hoursToComplete,
      };
      if (!$scope.bundleService.unitType) {
        $scope.bundleService.unitType = service.unitType;
        $scope.bundleService.unitPrice = service.unitPrice;
      }
      $scope.serviceCycles = ['', 'na', 'annual', 'quarterly', 'monthly', 'weekly'];

      var saveBundle = function () {
        if ($scope.autoSave) {
          HttpService.post(
            '/api/service/servicebundles/' + InputSanitizerService.sanitize($scope.bundle.id),
            { serviceBundle: $scope.bundle },
          )
            .then(function () {
              $modalInstance.close($scope.bundleService);
            })
            .catch(function (error) {
              $scope.errors = [error];
            });
        } else {
          $modalInstance.close($scope.bundleService);
        }
      };

      $scope.remove = function () {
        $scope.bundle.Services[service.id] = undefined;
        saveBundle();
      };

      $scope.ok = function () {
        if ($scope.bundleService.cycle) {
          $scope.bundle.Services[service.id] = $scope.bundleService;
        } else {
          $scope.bundle.Services[service.id] = undefined;
        }
        saveBundle();
      };

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };

      $scope.showCalculation = function () {
        return bundle.defaults.pricing != 'unit';
      };
    },
  );
