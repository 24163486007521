'use strict';

angular
  .module('vcio-toolkit')

  .directive('chartDoughnutSimple', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        value: '=',
        label: '@',
        color: '@',
      },
      template:
        '<div  max-width="100" width="100"><canvas tc-chartjs-doughnut chart="" chart-legend="" chart-options="chartData.options" chart-data="chartData.data"></canvas></div>',
      // template: '<div  max-width="100" width="100"><canvas tc-chartjs-doughnut chart="" chart-legend="" chart-options="chartData.options" chart-data="chartData.data">{{value}}</canvas></div>',

      controller: function ($scope, $translate) {
        var colors = {
          success: '#59BF90',
          info: '#5bc0de',
          warning: '#F7BE08',
          danger: '#F6303D',
          primary: '#62A6DB',
        };

        var chartData = {
          labels: ['data', ''],
          data: {
            datasets: [
              {
                data: [45, 100 - 45],
                //data: [$scop.value, 100 - $scope.value],
                backgroundColor: ['#F7BE08', '#EEEEEE'],
                hoverBackgroundColor: ['#F7BE08', '#FFFFFF'],
              },
            ],
          },
          options: {
            // width: $scope.width || 120,
            // height: $scope.width || 120,
            // responsive: true,
            // animation: true,
            // tooltips: false,
            // cutoutPercentage: 85,
            // legend: false
          },
        };
      },
    };
  })

  .directive('rycProgressChart', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        name: '@',
        valueLower: '=',
        valueHigher: '=',
        colorLower: '@',
        // colorHigher: '@',
        labelLower: '@',
        labelLowerTranslated: '=?',
        labelHigher: '@',
        labelHigherTranslated: '=?',
        size: '@',
        tooltipLower: '@',
        tooltipLowerTranslated: '=?',
        tooltipHigher: '@',
        tooltipHigherTranslated: '=?',
        ranges: '=?',
      },
      template:
        '<div>\n' +
        '    <div class="row" ng-if="labelLower || labelHigher">\n' +
        '        <div class="col-xs-4" ng-if="name"></div>\n' +
        '        <div ng-class="{\'col-xs-6\': !name, \'col-xs-4\': name}" class="h2 text-default">' +
        '            <span ng-if="!labelLowerTranslated" translate="{{labelLower}}" title="{{labelLowerHint}}"></span>' +
        '            <span ng-if="labelLowerTranslated" title="{{labelLowerTranslated}}">{{labelLowerTranslated}}</span>' +
        '        </div>\n' +
        '        <div ng-class="{\'col-xs-6\': !name, \'col-xs-4\': name}"class="h2 text-default text-right">' +
        '            <div ng-if="!labelHigherTranslated" translate="{{labelHigher}}" title="{{labelHigherHint}}"></div>' +
        '            <div ng-if="labelHigherTranslated" title="{{labelHigherTranslated}}">{{labelHigherTranslated}}</div>' +
        '        </div>\n' +
        '    </div>\n' +
        '    <div class="row row-eq-height">\n' +
        '        <div class="col-xs-4 vertical-center" ng-if="name">{{name}}</div>\n' +
        "        <div ng-class=\"{'col-xs-12': !name, 'col-xs-8': name}\">\n" +
        '            <progress class="ryc-progress-chart no-margin-bottom" ng-class="{\'ryc-progress-chart-lg\': size == \'lg\'}">\n' +
        "                <h2 class=\"text-default\" ng-if=\"valueLower == 0 && valueHigher == 0\" ng-style=\"{ 'margin-top': (size === 'lg' ? '6px' : '0')}\">0</h2>\n" +
        '                <abm-bar value="valueLower" color="{{colorLower}}" ng-if="valueLower" tooltip="{{getPopoverText(true, 5)}}" >\n' +
        '                    <span ng-show="valueLower > 5" class="h2">{{valueLower}}</span>\n' +
        '                </abm-bar>\n' +
        '                <abm-bar value="valueHigher - valueLower" type="white" ng-if="valueHigher - valueLower > 0" tooltip="{{getPopoverText(false, 5)}}" >\n' +
        '                    <span ng-show="valueHigher - valueLower > 5" class="h2">{{valueHigher}}</span>\n' +
        '                </abm-bar>\n' +
        '                <div class="progress-bar-markers">\n' +
        '                    <div ng-repeat="i in [1,2,3,4,5]"></div>\n' +
        '                </div>\n' +
        '            </progress>\n' +
        '            <div class="row" ng-if="ranges">\n' +
        '                <div class="col-xs-2 text-center h4 text-default no-margin-top"\n' +
        '                  ng-repeat="range in ranges track by $index"\n' +
        '                  style="width: {{100 / ranges.length}}%; min-width: {{100 / ranges.length}}%; max-width: {{100 / ranges.length}}%;">\n' +
        '                  {{range.name}}\n' +
        '                </div>\n' +
        '            </div>\n' +
        '        </div>\n' +
        '    </div>\n' +
        '</div>\n',

      controller: function ($scope, $translate) {
        $scope.labelHigherHint = $translate.instant($scope.labelHigher + '.hint');
        if ($scope.labelHigherHint == $scope.labelHigher + '.hint') {
          $scope.labelHigherHint = undefined;
        }
        $scope.labelLowerHint = $translate.instant($scope.labelLower + '.hint');
        if ($scope.labelLowerHint == $scope.labelLower + '.hint') {
          $scope.labelLowerHint = undefined;
        }

        $scope.getPopoverText = function (current, limit) {
          if (current && $scope.valueLower <= limit) {
            var tooltipLowerFirstHalf = !$scope.tooltipLowerTranslated
              ? $translate.instant($scope.labelLower || $scope.tooltipLower)
              : $scope.tooltipLowerTranslated;

            return tooltipLowerFirstHalf + ': ' + $scope.valueLower;
          }

          if (!current && $scope.valueHigher - $scope.valueLower <= limit) {
            var tooltipHigherFirstHalf = !$scope.tooltipHigherTranslated
              ? $translate.instant($scope.labelHigher || $scope.tooltipHigher)
              : $scope.tooltipHigherTranslated;

            return tooltipHigherFirstHalf + ': ' + $scope.valueHigher;
          }
        };

        // $scope.getMobilePopoverText = function () {
        //     if ($scope.valueLower <= 10 || $scope.valueHigher - $scope.valueLower <= 10) {
        //         return $translate.instant($scope.labelLower || $scope.tooltipLower) + ': ' + $scope.valueLower + '<br/>' + $translate.instant($scope.labelHigher || $scope.tooltipHigher) + ': ' + $scope.valueHigher
        //     }
        // }
      },
    };
  })
  .directive('doughnutChart', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        score: '@',
        name: '@',
        size: '@',
        color: '@',
      },
      template:
        '<div class="scorecard-chart">' +
        '<div class="{{getChartClass(score)}} {{size}}">' +
        '<span>{{percentNumber}}%</span>' +
        '<div class="slice">' +
        '<div class="bar"></div>' +
        '<div class="fill"></div>' +
        '</div>' +
        '</div>' +
        '<div class="height-2-line">{{shortenName(name)}}</div>' +
        '</div>',
      controller: function ($scope) {
        $scope.getChartClass = function (percent) {
          $scope.percentNumber = Math.round(Number.parseFloat(percent));
          var classes = ['c100', 'p' + $scope.percentNumber];
          if ($scope.color) {
            classes.push($scope.color);
          } else {
            if (percent >= 75) {
              classes.push('green');
            } else if (percent >= 25) {
              classes.push('yellow');
            } else {
              classes.push('red');
            }
          }

          return classes.join(' ');
        };

        $scope.shortenName = function (text, maxTextLength) {
          if (!text) {
            return;
          }

          maxTextLength = maxTextLength || 64;

          var ret = text;
          if (ret.length > maxTextLength) {
            ret = ret.substr(0, maxTextLength - 3) + '...';
          }
          return ret;
        };
      },
    };
  });

// .directive('chart', function () {
//     var baseWidth = 100;
//     var baseHeight = 100;
//
//     return {
//         restrict: 'E',
//         template: '<div style="width: 100%; display: table">' +
//         '   <div style="width: 66%; overflow: hidden; display: table-cell">' +
//         '       <div style="position: relative; width: 100%;">' +
//         '           <div style="padding-bottom: 100%;">' +
//         '           </div>' +
//         '           <div style="position: absolute; top: 0; bottom: 0; left: 0; right: 0;">' +
//         '               <canvas></canvas>' +
//         '           </div>' +
//         '       </div>' +
//         '   </div>' +
//         '   <div style="width: 34%; display: table-cell">' +
//         '   </div>' +
//         '</div>',
//         scope: {
//             chartObject: "=value"
//         },
//         link: function (scope, element, attrs) {
//             var canvas = element.find('canvas')[0],
//                 context = canvas.getContext('2d'),
//                 chart;
//
//             var options = {
//                 type: attrs.type || "Doughnut",
//                 width: attrs.width || baseWidth,
//                 height: attrs.height || baseHeight
//             };
//             canvas.width = Math.min(canvas.parentNode.clientWidth, canvas.parentNode.clientHeight)
//             canvas.height = Math.min(canvas.parentNode.clientWidth, canvas.parentNode.clientHeight)
//             chart = new Chart(context);
//
//             scope.$watch(function () {
//                 return element.attr('type');
//             }, function (value) {
//                 if (!value) return;
//                 options.type = value;
//                 var chartType = options.type;
//                 chart[chartType](scope.chartObject.data, scope.chartObject.options);
//             });
//
//             //Update when charts data changes
//             scope.$watch(function () {
//                 return scope.chartObject;
//             }, function (value) {
//                 if (!value) return;
//                 var chartType = options.type;
//                 chart[chartType](scope.chartObject.data, scope.chartObject.options);
//             });
//         }
//     }
// })

// .directive('chartprogress', function () {
//     return {
//         restrict: 'E',
//         template: '<div class="progress progress-striped">' +
//         '<div class="progress-bar progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"' +
//         'style="width: {{data.targetScore}}%">{{data.targetScore}}%</div></div>' +
//         '<div class="progress progress-striped">' +
//         '<div class="progress-bar progress-bar-success" role="progressbar" aria-valuemin="0" aria-valuemax="100"' +
//         'style="width: {{data.score}}% ">{{data.score}}%</div></div>',
//         scope: {
//             data: "=data"
//         }
//     }
// })
