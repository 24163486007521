'use strict';

angular
  .module('vcio-toolkit')

  .controller('MeetingNavigator', function ($scope, uuid, _, MeetingService, meeting) {
    $scope.meeting = meeting;

    $scope.sideNav = {
      open: false,
      pin: false,
    };
    $scope.pinSideNav = function () {
      $scope.sideNav.pin = !$scope.sideNav.pin;
    };
    $scope.openSideNav = function () {
      $scope.sideNav.open = true;
    };
    $scope.closeSideNav = function () {
      $scope.sideNav.open = $scope.sideNav.pin && $scope.sideNav.open;
    };
    $scope.newSection = {
      name: '',
      $$collapsed: true,
    };
    $scope.toggleAddSection = function () {
      $scope.newSection.$$collapsed = !$scope.newSection.$$collapsed;
      setTimeout(function () {
        document.getElementById('addNewSectionNameInput').focus();
      }, 0);
    };
    $scope.cancelAddSection = function () {
      $scope.newSection.name = '';
      $scope.newSection.$$collapsed = true;
    };
    // $scope.addNewSection = function () {
    //     $scope.addSection({
    //         name: $scope.newSection.name, cb: function () {
    //             $scope.cancelAddSection()
    //         }
    //     })
    // };
    $scope.addSection = function () {
      if ($scope.newSection.name) {
        if (!$scope.meeting.sections) {
          $scope.meeting.sections = [];
        }
        var section = {
          uuid: uuid.v4(),
          name: $scope.newSection.name,
          widgets: [],
        };
        $scope.meeting.sections.splice($scope.meeting.sections.length - 1, 0, section);
        $scope.newSection = { $$collapsed: true };
        // $scope.sectionsHelper = MeetingService.generateSections($scope.meetingTemplate)
      }
    };

    $scope.dropCallback = function (srcList, srcIndex, targetList, targetIndex) {
      // Copy the item from source to target.
      targetList.splice(targetIndex, 0, srcList[srcIndex]);
      // Remove the item from the source, possibly correcting the index first.
      // We must do this immediately, otherwise ng-repeat complains about duplicates.
      if (srcList == targetList && targetIndex <= srcIndex) {
        srcIndex++;
      }
      srcList.splice(srcIndex, 1);
      $scope.saveChanges();
      return true;
    };
    $scope.removeSection = function (section) {
      if (section.widgets.length) {
        return;
      }
      _.remove($scope.meeting.sections, { uuid: section.uuid });
      $scope.saveChanges();
    };
    // $scope.removeWidget = function (widget, section) {
    //     _.remove($scope.meeting.widgets, {uuid: widget.uuid});
    //     var index = section.widgets.indexOf(widget.uuid);
    //     if (index >= 0) {
    //         section.widgets.splice(index, 1)
    //     }
    //     $scope.sectionsHelper = MeetingService.generateSections($scope.meeting);
    //     $scope.generateAvailableWidgets()
    // };
    $scope.saveChanges = function () {
      // $scope.sectionsHelper = MeetingService.generateSections($scope.meeting);
      if ($scope.sortWidgets) {
        $scope.sortWidgets();
      }
    };
  });
