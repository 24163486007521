angular
  .module('vcio-toolkit')
  .controller(
    'SurveyResultsController',
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $http,
      $modal,
      $moment,
      $window,
      $translate,
      _,
      LocalStorage,
      CurrentUser,
      DialogService,
      HttpService,
      surveyResultService,
      surveys,
      surveyResults,
      client /*, clientSegments*/,
    ) {
      $scope.surveyResults = surveyResults;
      $scope.selectedClient = client;
      $scope.client = client;
      // $scope.clientSegments = clientSegments;
      $scope.surveys = surveys;
      $scope.surveyType = { code: $stateParams.type, name: '' };
      if ($stateParams.type) {
        if ($stateParams.type === 'qbr') {
          $scope.surveyType.name = $translate.instant('label.overview.guided.quarterly');
        } else {
          $scope.surveyType.name = $translate.instant('surveyType.' + $stateParams.type);
        }
      } else {
        $scope.surveyType.name = $translate.instant('label.survey');
      }
      $scope.newSurvey = {};
      $scope.contacts = _.map($scope.client.Contacts, function (contact) {
        contact.name = contact.firstName + ' ' + contact.lastName + ' <' + contact.email + '>';
        return contact;
      });

      _.forEach($scope.surveyResults, function (surveyResult) {
        if (_.includes(['itcq', 'needs', 'stack', 'stack2'], surveyResult.Survey.type)) {
          surveyResult.doughnutChartData = {
            data: {
              labels: ['Current', 'Targeted', ''],
              datasets: [
                {
                  data: [
                    surveyResult.score,
                    surveyResult.targetScore - surveyResult.score,
                    Math.max(0, 100 - surveyResult.targetScore - surveyResult.score),
                  ],
                  backgroundColor: ['#62A6DB', '#59BF90', '#efefef'],
                  hoverBackgroundColor: ['#62A6DB', '#59BF90', '#efefef'],
                },
              ],
            },
            options: {
              width: 120,
              height: 120,
              responsive: true,
              animation: false,
              tooltips: false,
              legend: false,
              cutoutPercentage: 85,
            },
          };
        } else if (surveyResult.Survey.type === 'mpd') {
          surveyResult.doughnutChartData = {
            data: {
              labels: ['Completion', ''],
              datasets: [
                {
                  data: [surveyResult.completion, 100 - surveyResult.completion],
                  backgroundColor: ['#62A6DB', '#efefef'],
                  hoverBackgroundColor: ['#62A6DB', '#efefef'],
                },
              ],
            },
            options: {
              width: 120,
              height: 120,
              responsive: true,
              animation: false,
              tooltips: false,
              legend: false,
              cutoutPercentage: 85,
            },
          };
        }
        _.forEach(surveyResult.compare, function (compareSurveyResult) {
          compareSurveyResult.name = _.get(
            _.find($scope.surveyResults, { id: compareSurveyResult.id }),
            'title',
          );
        });
      });

      $scope.surveyResultOrder = function (surveyResult) {
        if (surveyResult.Survey.type === 'mpd') {
          return surveyResult.title + (surveyResult.Contact ? surveyResult.Contact.email : '');
        } else {
          return $moment(surveyResult.createdAt).diff($moment(), 'hours');
        }
      };

      $scope.$watch('newSurvey.Survey', function (value, old) {
        if (!old) {
          $scope.setTitle();
        }
      });

      if ($scope.surveys.length > 0) {
        $scope.newSurvey.title = $scope.surveys[0].name;
      }
      if (!$scope.newSurvey.Contact && $scope.contacts.length > 0) {
        $scope.newSurvey.Contact = $scope.contacts[0];
      }
      $scope.setTitle = function () {
        if ($scope.newSurvey.Survey) {
          $scope.newSurvey.title = $scope.newSurvey.Survey.name;
        }
      };

      $scope.refresh = function () {
        surveyResultService
          .getSurveyResultsWithScore($stateParams.clientId, $stateParams.type)
          .then(function (surveyResults) {
            $scope.surveyResults = surveyResults;
          });
      };

      $scope.$on('ConnectWiseScreenChanged', function (event, screenObject) {
        if (screenObject && screenObject.screen === 'company') {
          $scope.filter.externalId = screenObject.id;
          $scope.selectedClient = _.find($scope.clients, function (client) {
            return _.get(client, 'externalIds.connectwise') + '' == $scope.filter.externalId + '';
          });
          $state.$current.data.pageTitle = $scope.selectedClient
            ? $scope.selectedClient.name
            : 'sales.menu.companies';
          $rootScope.$broadcast('dataLoadingFinished');
          // if ($scope.selectedClient) {
          //     $scope.filter.clientId = $scope.selectedClient.id;
          // }
        }
      });

      $scope.shareSurvey = function (surveyResult) {
        $modal.open({
          templateUrl: '/templates/msp/survey-share.html',
          controller: function ($scope, $modalInstance, $http, surveyResult, CurrentUser) {
            $scope.surveyResult = surveyResult;
            $scope.url = surveyResult.Survey.LandingPage.url;
            $scope.roles = surveyResult.roles;
            if ($scope.url.indexOf('?') > -1) {
              $scope.url += '&survey=' + surveyResult.uuid;
            } else {
              $scope.url += '?survey=' + surveyResult.uuid;
            }

            $http.post(
              '/api/admin/users/' + CurrentUser.getUser().id + '/timeline/surveyResultLinkCopied',
              { surveyResultId: surveyResult.id },
            );

            $scope.ok = function () {
              $modalInstance.dismiss();
            };
          },
          backdrop: 'static',
          size: 'lg',
          resolve: {
            surveyResult: function () {
              return surveyResult;
            },
          },
        });
      };

      $scope.sendSurvey = function (surveyResult) {
        // $state.go('sales.invite', {surveyResultId: surveyResult.id});
        var modalInstance = $modal.open({
          templateUrl: '/templates/msp/survey-invite.html',
          controller: 'InviteSurveyController',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            surveyResult: function () {
              return surveyResult;
            },
            senders: function (HttpService) {
              return HttpService.get('/api/admin/users');
            },
          },
        });

        modalInstance.result.then(function () {
          $scope.refresh();
        });
      };

      $scope.editSurvey = function (surveyResult) {
        $scope.oldValues = _.cloneDeep(surveyResult);
        surveyResult.isEdit = true;
      };

      $scope.modalConfirm = function (message, data, _ok, _cancel) {
        var modalInstance = $modal.open({
          templateUrl: '/templates/modal-confirm.html',
          controller: function ($scope, $modalInstance, message) {
            $scope.message = message;
            $scope.ok = function () {
              $modalInstance.close(true);
            };

            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
          resolve: {
            message: function () {
              return message;
            },
          },
        });

        modalInstance.result.then(
          function () {
            _ok(data);
          },
          function () {
            if (_cancel) {
              _cancel(data);
            }
          },
        );
      };

      $scope.save = function (editedSurvey, cb) {
        var req;
        if (editedSurvey.id) {
          req = HttpService.put('/api/surveyresults/' + editedSurvey.id, {
            attributes: editedSurvey,
          });
        } else {
          editedSurvey.ClientId = $scope.client.id;
          req = HttpService.post('/api/surveyresults', {
            surveyResult: _.omit(editedSurvey, ['survey']),
          });
        }
        $rootScope.$broadcast('dataLoadingStarted');
        req.then(
          function (result) {
            if (editedSurvey.id) {
              editedSurvey.isEdit = false;
            } else {
              result.Contact = editedSurvey.Contact;
              $scope.surveyResults.push(result);
              $scope.newSurvey = {};
            }
            $rootScope.$broadcast('dataLoadingFinished');
            if (cb) {
              cb();
            }
          },
          function (error) {
            if (cb) {
              cb();
            }
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
            console.error(error);
          },
        );
      };

      $scope.cancel = function (newSurvey) {
        if (newSurvey.id) {
          newSurvey.isEdit = false;
          _.forEach(newSurvey.oldValues, function (v, k) {
            newSurvey[k] = v;
          });
        } else {
          $scope.newSurvey = {};
        }
      };

      $scope.archive = function (surveyResult) {
        $http
          .put('/api/surveyresults/' + surveyResult.id + '/status/archived')
          .success(function () {
            $scope.refresh();
          })
          .error(function (error) {
            console.error(error);
          });
      };

      $scope.delete = function (surveyResult) {
        $http
          .delete('/api/surveyresults/' + surveyResult.id)
          .success(function () {
            _.remove($scope.surveyResults, function (item) {
              return item.id == surveyResult.id;
            });
          })
          .error(function (error) {
            console.error(error);
          });
      };

      $scope.finish = function (surveyResult) {
        $http
          .put('/api/surveyresults/' + surveyResult.id + '/status/finished')
          .success(function () {
            $scope.refresh();
          })
          .error(function (error) {
            console.error(error);
          });
      };

      // $scope.rename = function (surveyResult) {
      //     var modalInstance = $modal.open({
      //         template: '<modal-form form-title="label.surveyResult.rename.title" title-values="{title: title}" onok="save" oncancel="cancel">' +
      //         '<input-text model="attributes.title" label="label.surveyResult.title" required="true"></input-text>' +
      //         '</modal-form>',
      //         controller: function ($scope, $modalInstance, _, HttpService, attributes, title) {
      //             $scope.attributes = attributes;
      //             $scope.title = title;
      //             $scope.save = function (cb) {
      //                 HttpService.put('/api/surveyresults/' + $scope.attributes.id, {attributes: attributes})
      //                     .then(function () {
      //                         $modalInstance.close(attributes);
      //                     })
      //                     .catch(function (error) {
      //                         $scope.errors = [error];
      //                         cb();
      //                     })
      //             };
      //
      //             $scope.cancel = function () {
      //                 $modalInstance.dismiss();
      //             }
      //         },
      //         resolve: {
      //             attributes: function () {
      //                 return _.pick(surveyResult, ['id', 'title', 'Contact']);
      //             },
      //             title: function () {
      //                 return surveyResult.title;
      //             }
      //         }
      //     });
      //
      //     modalInstance.result.then(function (attributes) {
      //         surveyResult.title = attributes.title;
      //     }, function () {
      //     });
      //
      // };

      $scope.reopen = function (surveyResult) {
        $http
          .put('/api/surveyresults/' + surveyResult.id + '/status/started')
          .success(function () {
            $scope.refresh();
            DialogService.message('message.surveyResultReopened');
          })
          .error(function (error) {
            console.error(error);
          });
      };

      function openFillSurvey(surveyResult, role) {
        if (rycFillContainer && rycFillContainer.parentNode) {
          rycFillContainer.parentNode.removeChild(rycFillContainer);
          rycFillContainer = undefined;
        }

        var width = Math.min(window.innerWidth, 810);
        var height = Math.min(window.window.innerHeight, 700);
        rycFillContainer = document.createElement('div');
        rycFillContainer.setAttribute('id', 'ryc-fill-container');
        rycFillContainer.setAttribute('class', 'rycfill-container');

        var i = document.createElement('iframe');
        i.setAttribute('id', 'ryc-fill-frame');
        i.setAttribute(
          'style',
          'width: ' + width + 'px;height: ' + height + 'px;pointer-events: "all"',
        );
        i.setAttribute('class', 'rycfill-iframe rycfill-box-shadow');
        i.setAttribute('scrolling', 'no');
        i.setAttribute('frameborder', '0');
        var uuid = surveyResult.uuid;
        if (role && role.uuid) {
          uuid = uuid + '_' + role.uuid;
        }
        i.setAttribute('src', '/fillsurvey?survey=' + uuid);
        rycFillContainer.appendChild(i);

        window.onresize = function () {
          width = Math.min(window.innerWidth, 810);
          height = Math.min(window.window.innerHeight, 700);
          i.setAttribute(
            'style',
            'width: ' + width + 'px;height: ' + height + 'px;pointer-events: "all"',
          );
        };

        document.body.appendChild(rycFillContainer);

        window.addEventListener(
          'message',
          function (e) {
            if (e.data == 'close-ryc-fill-container') {
              if (rycFillContainer && rycFillContainer.parentNode) {
                rycFillContainer.parentNode.removeChild(rycFillContainer);
                rycFillContainer = undefined;
                $scope.refresh();
              }
            }
          },
          false,
        );
      }

      var rycFillContainer;

      // $scope.do = function (operation, surveyResult) {
      //     switch (operation) {
      //         case 'analyze':
      //             $state.go('sales.analyze', {
      //                 surveyResultId: surveyResult.id,
      //                 dimension: 'continuous',
      //                 blockOrderId: 1
      //             });
      //             break;
      //         case 'overview':
      //             $state.go('sales.overview', {surveyResultId: surveyResult.id, type: $scope.surveyType.code});
      //             break;
      //         case 'sendmail':
      //             //$state.go('sales.sendmail', {surveyResultId: surveyResult.id});
      //             $scope.sendSurvey(surveyResult);
      //             break;
      //         case 'share':
      //             $scope.shareSurvey(surveyResult);
      //             break;
      //         case 'fill':
      //             if (surveyResult.roles.length) {
      //                 var modalInstance = $modal.open({
      //                     templateUrl: '/templates/sales/survey/roles.html',
      //                     controller: function ($scope, $modalInstance, surveyResult) {
      //                         $scope.roles = _.filter(surveyResult.roles, function (role) {
      //                             return !role.finishedAt;
      //                         });
      //
      //                         $scope.ok = function (role) {
      //                             $modalInstance.close(role);
      //                         };
      //                         $scope.cancel = function () {
      //                             $modalInstance.dismiss();
      //                         }
      //                     },
      //                     backdrop: 'static',
      //                     size: 'lg',
      //                     resolve: {
      //                         surveyResult: function () {
      //                             return surveyResult;
      //                         }
      //                     }
      //                 });
      //
      //                 modalInstance.result.then(function (role) {
      //                     openFillSurvey(surveyResult, role);
      //                 }, function () {
      //
      //                 })
      //             } else {
      //                 openFillSurvey(surveyResult);
      //             }
      //             break;
      //         case 'finish':
      //             $scope.modalConfirm('confirm.finishSurvey', surveyResult, $scope.finish);
      //             break;
      //         case 'delete':
      //             $scope.modalConfirm($translate.instant('confirm.deleteSurvey', {contact: surveyResult.Contact.firstName + ' ' + surveyResult.Contact.lastName}), surveyResult, $scope.delete);
      //             break;
      //         case 'rename':
      //             $scope.rename(surveyResult);
      //             break;
      //         case 'reopen':
      //             $scope.reopen(surveyResult);
      //             break;
      //         case 'archive':
      //             $scope.modalConfirm('confirm.archiveSurvey', surveyResult, $scope.archive);
      //             break;
      //         case 'csv':
      //             $window.open('/api/surveyresults/' + surveyResult.id + '/results.csv?token=' + CurrentUser.getToken(), '_blank');
      //             break;
      //     }
      // };

      $scope.openAddSurvey = function () {
        var modalInstance = $modal.open({
          templateUrl: '/templates/sales/survey-add-directly.html',
          controller: 'SurveyAddController',
          backdrop: 'static',
          resolve: {
            surveys: function ($stateParams, $http) {
              return $http.get('/api/surveytemplates?type=' + $stateParams.type);
            },
          },
        });

        modalInstance.result.then(
          function (survey) {
            //refresh modified data
            $scope.surveys.push(survey);
            $scope.surveys = _.sortBy($scope.surveys, 'name');
            $scope.newSurvey.Survey = survey;
          },
          function () {},
        );
      };

      $scope.openAddContact = function () {
        var modalInstance = $modal.open({
          templateUrl: '/templates/client/contact-edit.html',
          controller: 'ContactEditController',
          backdrop: 'static',
          resolve: {
            contact: function () {
              return { status: 'customer' };
            },
            client: function () {
              return $scope.client;
            },
            contactSeniorities: function (HttpService) {
              return HttpService.get('/api/admin/ces/contactseniorities');
            },
          },
        });

        modalInstance.result.then(
          function (contact) {
            //refresh modified data
            contact.name = contact.firstName + ' ' + contact.lastName + ' <' + contact.email + '>';
            $scope.contacts.push(contact);
            $scope.newSurvey.Contact = contact;
          },
          function () {},
        );
      };

      $scope.showShareSurvey = function (surveyResult) {
        return (
          $scope.surveyType.code !== 'stack2' &&
          (surveyResult.status === 'draft' ||
            surveyResult.status === 'sent' ||
            surveyResult.status === 'started')
        );
      };

      // $scope.linkClient = function () {
      //     var client = {};
      //     var modalInstance = $modal.open({
      //         templateUrl: '/templates/integration/link-client.html',
      //         controller: 'IntegrationConnectwiseLinkClientController',
      //         backdrop: 'static',
      //         resolve: {
      //             externalId: function () {
      //                 return $scope.filter.externalId;
      //             },
      //             values: function () {
      //                 return _.filter($scope.clients, function (client) {
      //                     return !client.externalIds.connectwise;
      //                 });
      //             }
      //         }
      //     });
      //
      //     modalInstance.result.then(function (result) {
      //         var client = _.find($scope.clients, {id: result.id});
      //         if (client) {
      //             client.externalIds.connectwise = $scope.filter.externalId;
      //             $http.put('/api/vcio/connectwise/companies/' + $scope.filter.externalId + '/link/' + result.id)
      //                 .success(function () {
      //                     $scope.refresh();
      //                 });
      //         }
      //     }, function () {
      //     });
      //
      // };
      //
      // $scope.surveyResultOperations = function (surveyResult) {
      //     switch (surveyResult.status) {
      //         case 'draft':
      //             return ['overview', /*'sendmail', */'share', /*'fill', */'delete', 'rename'];
      //         case 'sent':
      //             return ['overview', /*'sendmail', */'share', /*'fill', */'delete', 'rename'];
      //         case 'started':
      //             return ['overview', /*'sendmail', */'share', /*'fill', */'delete', /*'reopen', */'rename'];
      //         case 'finished':
      //             return ['overview', 'csv', 'archive', /*'reopen', */'rename'];
      //         case 'evaluated':
      //             return ['overview', 'csv', 'archive', /*'reopen', */'rename'];
      //         case 'archived':
      //             return ['overview', 'delete', 'rename'];
      //     }
      // }
    },
  );

// .controller('SurveyResultTemplatesController', function ($scope, $state, $http, $modal, _, CurrentUser, DialogService, templates, surveys) {
//     $scope.templates = templates;
//     $scope.surveys = _.filter(surveys, function (o) {
//         return o.roles.length == 0;
//     });
//
//     $scope.refresh = function () {
//         $http.get('/api/surveyresulttemplates')
//             .success(function (templates) {
//                 $scope.templates = templates;
//             })
//     };
//
//     $scope.delete = function (template) {
//         $http.delete('/api/surveyresulttemplates/' + template.id)
//             .success(function () {
//                 $scope.refresh();
//             });
//
//     };
//
//     $scope.edit = function (template) {
//         var modalInstance = $modal.open({
//             template: '<modal-form onok="ok" oncancel="cancel">' +
//             '<input-text model="template.title" label="label.name" required="true"></input-text>' +
//             '<input-select-id model="template.SurveyId" label="launch.survey" values="surveys" required="true"></input-select-id>' +
//             '<modal-richtext model="template.welcome" label="launch.welcome"></modal-richtext>' +
//             '</modal-form>',
//             controller: function ($scope, $http, $modalInstance, template, surveys) {
//                 $scope.template = template || {};
//                 $scope.surveys = _.filter(surveys, function (o) {
//                     return o.roles.length == 0;
//                 });
//                 $scope.cancel = function () {
//                     $modalInstance.dismiss();
//                 };
//                 $scope.ok = function (cb) {
//                     $scope.errors = [];
//                     $http[$scope.template.id ? 'put' : 'post']('/api/surveyresulttemplates', {template: $scope.template})
//                         .success(function (result) {
//                             cb();
//                             $modalInstance.close(result);
//                         })
//                         .error(function (error) {
//                             $scope.errors = [error];
//                             cb();
//                         })
//                 }
//             },
//             backdrop: 'static',
//             resolve: {
//                 template: function () {
//                     return template;
//                 },
//                 surveys: function () {
//                     return $scope.surveys;
//                 },
//                 landingPages: function () {
//                     return $scope.landingPages;
//                 }
//             }
//         });
//
//         modalInstance.result.then(function () {
//             $scope.refresh();
//         }, function () {
//         });
//     };
//
//     $scope.showScript = function (template) {
//         var message = '<pre rows="4">&lt;script type="text/javascript" id="ryc-insert-script" src="https://' + apphostname + '/js/insertfill-' + template.uuid + '.js' + '"&gt;&lt;/script&gt;</pre>';
//         DialogService.message(message, 'surveyShare.shareScript', 'lg');
//     };
//
//     $scope.operations = [{operation: $scope.edit, label: 'button.edit', icon: 'edit'},
//         {operation: $scope.delete, label: 'button.delete', icon: 'remove', confirmation: true},
//         {operation: $scope.showScript, label: 'button.share', icon: 'share'}]
//
// });
