'use strict';

angular
  .module('vcio-toolkit')
  .factory('abmConfig', function () {
    var showErrors = true;
    var errorMap = {};
    return {
      toggleErrorState: function () {
        showErrors = !showErrors;
      },
      getErrorState: function () {
        return showErrors;
      },
      getErrors: function (error) {
        return errorMap;
      },
      setErrors: function (error) {
        if (error && error instanceof Object) angular.extend(errorMap, error);
      },
    };
  })
  .factory('isChar', function () {
    return function _isChar(evt) {
      if (typeof evt.which == 'undefined') {
        return true;
      } else if (typeof evt.which == 'number' && evt.which > 0) {
        return (
          !evt.ctrlKey &&
          !evt.metaKey &&
          !evt.altKey &&
          evt.which != 8 && // backspace
          evt.which != 9 && // tab
          evt.which != 13 && // enter
          evt.which != 16 && // shift
          evt.which != 17 && // ctrl
          evt.which != 20 && // caps lock
          evt.which != 27 // escape
        );
      }
      return false;
    };
  })
  .directive('abmLabel', function ($compile) {
    return {
      /*templateUrl: '/templates/label.html',
             replace: true,*/
      require: '^abmFormGroup',
      priority: 1001,
      terminal: true,
      scope: {
        type: '@',
      },
      link: function (scope, element, attrs, formGroupCtrl) {
        if (!formGroupCtrl)
          // if used outside a form group for some reason, do nothing
          return false;
        if (attrs.type === 'no-float') {
          element.addClass('sr-only');
          formGroupCtrl.hiddenLabel = true;
          formGroupCtrl.hiddenLabelPhrase = attrs.translate;
        } else {
          var className = 'label-' + (attrs.type || 'static'); // TBD see if this can be an array
          if (!element.hasClass('control-label'))
            // TBD checkif the condition is unnecessary
            element.addClass('control-label');
          formGroupCtrl.addClass(className);
        }

        element.removeAttr('abm-label');
        element.removeAttr('data-abm-label');

        $compile(element)(scope.$parent);
      },
    };
  })
  .directive('abmInputLabel', [
    function () {
      return {
        restrict: 'E',
        replace: true,
        template:
          '<label ng-show="label" class="control-label" popover-placement="top" uib-popover="{{hint}}" popover-trigger="mouseenter">{{label | translate:values}} <i class="fa fa-question-circle text-success" ng-show="hint"></i></label>',
        scope: {
          label: '@',
          labelValues: '=?',
        },
        controller: function ($scope, $translate) {
          $scope.hint = $translate.instant($scope.label + '.hint');
          if ($scope.hint == $scope.label + '.hint') {
            $scope.hint = undefined;
          }
          if ($scope.labelValues) {
            $scope.values = JSON.stringify($scope.labelValues);
          } else {
            $scope.values = '{}';
          }
        },
      };
    },
  ])
  .filter('abmUiSelectSearch', function (_) {
    return function (values, searchFunc, searchText) {
      return _.filter(values, function (value) {
        return searchFunc(value, searchText);
      });
    };
  })
  .directive('abmUiSelect', function ($compile, $translate, InputSanitizerService) {
    return {
      restrict: 'E',
      require: '^abmFormGroup',
      template:
        '<ui-select ng-if="!isMultipleSupported()" ng-model="$parent.$parent.model" on-select="onSelect($item, $model, parent)" ng-disabled="disabled" ng-required="{{required}}"' +
        '    popover-placement="top" uib-popover="{{hint}}" popover-trigger="mouseenter">' +
        '    <ui-select-match allow-clear="{{allowClear}}" placeholder="{{placeholder}}">' +
        '      {{$select.selected[nameKey]}}' +
        '    </ui-select-match>' +
        '    <ui-select-choices repeat="(_expVal(item)) as item in (values | filter:itemFilter | orderBy: orderBy | abmUiSelectSearch:searchFunc:$select.search) track by item[idKey]" ng-class="{hide: $select.search.length < searchMin }"' +
        '        group-by="groupBy" group-filter="groupFilter" ui-disable-choice="item.disabled">' +
        '      <div ng-if="imageKey" back-img="{{item[imageKey]}}" style="display: inline-block; width: 25px; height: 25px; vertical-align: middle;" class="img-circle margin-1x-right"></div>' +
        '      <span ng-bind="item[nameKey]"></span>' +
        '    </ui-select-choices>' +
        '  </ui-select>' +
        '<ui-select ng-if="isMultipleSupported()" ng-model="$parent.$parent.model" on-select="onSelect($item, $model, parent)" ng-disabled="disabled" ng-required="{{required}}" multiple' +
        '    popover-placement="top" uib-popover="{{hint}}" popover-trigger="mouseenter">' +
        '    <ui-select-match allow-clear="{{allowClear}}" placeholder="{{placeholder}}">' +
        '      {{$item.name}}' +
        '    </ui-select-match>' +
        '    <ui-select-choices repeat="(_expVal(item)) as item in (values | filter:itemFilter | orderBy: orderBy | abmUiSelectSearch:searchFunc:$select.search) track by item[idKey]" ng-class="{hide: $select.search.length < searchMin }"' +
        '        group-by="groupBy" group-filter="groupFilter" ui-disable-choice="item.disabled">' +
        '      <div ng-if="imageKey" back-img="{{item[imageKey]}}" style="display: inline-block; width: 25px; height: 25px; vertical-align: middle;" class="img-circle margin-1x-right"></div>' +
        '      <span ng-bind="item[nameKey]"></span>' +
        '    </ui-select-choices>' +
        '  </ui-select>',
      scope: {
        parent: '=?',
        model: '=',
        values: '=?',
        list: '@?',
        required: '=',
        searchMin: '=?',
        onSelect: '=?',
        allowClear: '@?',
        type: '@', //id, multi-id, multi, object, array
        disabled: '=?',
        idKey: '@',
        nameKey: '@',
        imageKey: '@',
        orderBy: '@',
        groupBy: '=?',
        groupFilter: '=?',
        filter: '=?',
        _placeholder: '@?placeholder',
        search: '=?',
        isValid: '=?', // do not use with required at the same time
      },
      controller: function ($scope, $rootScope, _, HttpService) {
        if (!$scope.searchMin) {
          $scope.searchMin = 0;
        }
        if (!$scope.allowClear) {
          $scope.allowClear = 'true';
        }
        if (!$scope.onSelect) {
          $scope.onSelect = function () {};
        }
        if ($scope._placeholder) {
          $scope.placeholder = $translate.instant($scope._placeholder);
          $scope.hint = $translate.instant($scope._placeholder + '.hint');
          if ($scope.hint == $scope._placeholder + '.hint') $scope.hint = $scope.placeholder;
        }
        if ($scope._hint) {
        }
        $scope.isMultipleSupported = function () {
          return $scope.type === 'multi' || $scope.type === 'multi-id';
        };
        $scope.idKey = $scope.idKey || 'id';
        $scope.nameKey = $scope.nameKey || 'name';
        $scope.itemFilter =
          $scope.filter ||
          function (o) {
            return true;
          };
        $scope._expVal = function (item) {
          if ($scope.type === 'id' || $scope.type === 'multi-id' || $scope.type === 'array') {
            return item[$scope.idKey];
          } else {
            return item;
          }
        };
        $scope.searchFunc =
          $scope.search ||
          function (item, searchText) {
            return _.includes(_.toLower(item[$scope.nameKey]), _.toLower(searchText));
          };
        $scope.validate = function (input) {
          if ($scope.isValid !== undefined) {
            return !$scope.isValid;
          } else if (
            $scope.required &&
            ($scope.model === undefined ||
              $scope.model === '' ||
              $scope.model === null ||
              (_.isArray($scope.model) && $scope.model.length === 0))
          ) {
            return true;
          } else {
            return ($scope.required && !input) === true;
          }
        };
        if (!$scope.values) {
          if ($scope.list) {
            HttpService.get('/api/lists/' + InputSanitizerService.sanitize($scope.list)).then(
              function (result) {
                var values = [];
                _.forEach(result, function (r) {
                  values.push({
                    id: r,
                    name: $translate.instant($scope.list + '.' + r),
                  });
                });
                $scope.values = values;
              },
            );
          }
        } else if ($scope.type === 'array' && _.isArray($scope.values)) {
          $scope.values = _.map($scope.values, function (value) {
            if (_.isString(value)) {
              return { id: value, name: value };
            } else {
              return value;
            }
          });
        } else if ($scope.list && $scope.values.length && _.isString($scope.values[0])) {
          $scope.values = _.map($scope.values, function (value) {
            return { id: value, name: $translate.instant($scope.list + '.' + value) };
          });
        }
      },
      link: function ($scope, $element, attr, ctrl) {
        var formGroup = ctrl;
        formGroup.registerControl($scope);
        // $element.on('focus', function () {
        //     formGroup.toggleFocus(true);
        // }).on('blur', function () {
        //     formGroup.toggleFocus(false);
        //     //formGroup.toggleEmpty(!$element.val());
        //     //formGroup.toggleError(input.$invalid);
        // });

        $scope.$watch('model', function (input) {
          formGroup.toggleError($scope.validate(input));
        });

        // To override default validation control
        $scope.$watch('isValid', function (input) {
          formGroup.toggleError($scope.validate(input));
        });
      },
    };
  })
  .directive('abmModalRichtext', function ($modal, $translate) {
    return {
      restrict: 'E',
      scope: {
        label: '@',
        _placeholder: '@placeholder',
        model: '=',
        labelOk: '@',
        labelCancel: '@',
        unsafeSanitizer: '=',
      },
      replace: true,
      template:
        '<div>' +
        '    <span class="form-control" ng-click="open()" ng-class="{\'text-muted\': !model}">{{(model ? model : placeholder)| shorten:35}}</span>' +
        '    <span class="fa fa-file-text-o form-control-feedback text-muted" style="top: 3px; right: 3px;"></span>' +
        '</div>',
      link: function ($scope, $element, $attrs) {
        $scope.placeholder = $translate.instant($scope._placeholder || 'label.editClick');
        $scope.open = function () {
          var modalInstance = $modal.open({
            templateUrl: '/templates/modal-richtext.html',
            controller: function (
              $scope,
              $modalInstance,
              model,
              label,
              labelOk,
              labelCancel,
              unsafeSanitizer,
            ) {
              $scope.model = model;
              $scope.label = label;
              $scope.labelOk = labelOk ? labelOk : 'button.ok';
              $scope.labelCancel = labelCancel ? labelCancel : 'button.cancel';
              $scope.unsafeSanitizer = unsafeSanitizer ? true : false;
              $scope.ok = function () {
                $modalInstance.close($scope.model);
              };

              $scope.cancel = function () {
                $modalInstance.dismiss();
              };
            },
            backdrop: 'static',
            size: 'lg',
            resolve: {
              model: function () {
                return $scope.model;
              },
              label: function () {
                return $scope.label;
              },
              labelOk: function () {
                return $scope.labelOk;
              },
              labelCancel: function () {
                return $scope.labelCancel;
              },
              unsafeSanitizer: function () {
                return $scope.unsafeSanitizer;
              },
            },
          });
          modalInstance.result.then(
            function (result) {
              $scope.model = result;
            },
            function () {},
          );
        };
      },
      controller: function ($scope, $http) {
        // $scope.hint = $translate.instant($scope.label + '.hint');
        // if ($scope.hint == $scope.label + '.hint') $scope.hint = undefined;
      },
    };
  })
  .controller(
    'ModalMultiSelectController',
    function ($scope, $modalInstance, selected, values, group) {
      $scope.selected = selected;
      $scope.group = group;
      $scope.values = $scope.group ? _.groupBy(values, $scope.group) : values;
      $scope.filter = {};
      $scope.ok =
        $scope.funcOk ||
        function () {
          $modalInstance.close($scope.selected);
        };

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };

      $scope.groupFilter = function (item) {
        return !$scope.filter.name || _.includes(_.toLower(item), _.toLower($scope.filter.name));
      };

      $scope.isSelected = function (item) {
        return !!_.find($scope.selected, { id: item.id });
      };

      $scope.select = function (item) {
        if (!$scope.isSelected(item)) {
          $scope.selected.push(item);
        } else {
          _.remove($scope.selected, item);
        }
      };
    },
  )
  .directive('abmModalSelect', function ($modal, _) {
    return {
      restrict: 'E',
      scope: {
        label: '@',
        model: '=',
        values: '=',
        labelOk: '@',
        labelCancel: '@',
        funcOk: '=?',
        group: '@?',
      },
      replace: true,
      template:
        '<div><span class="btn btn-default" translate="{{label}}" ng-click="open()"></span></div>',
      link: function ($scope, $element, $attrs) {
        $scope.open = function () {
          var modalInstance = $modal.open({
            templateUrl: '/templates/modal-multiselect.html',
            controller: 'ModalMultiSelectController',
            backdrop: 'static',
            size: 'lg',
            resolve: {
              selected: function () {
                return _.filter($scope.values, function (value) {
                  return _.find($scope.model, { id: value.id });
                });
              },
              values: function () {
                return $scope.values;
              },
              group: function () {
                return $scope.group;
              },
            },
          });
          modalInstance.result.then(
            function (result) {
              $scope.model = result;
            },
            function () {},
          );
        };
      },
      controller: function ($scope, $http) {
        // $scope.hint = $translate.instant($scope.label + '.hint');
        // if ($scope.hint == $scope.label + '.hint') $scope.hint = undefined;
      },
    };
  })
  .directive('abmFormGroup', [
    'abmConfig',
    function (abmConfig) {
      return {
        scope: {
          classList: '=',
          label: '@',
          placeholder: '@',
          errorMessages: '=',
          errorMessagesInclude: '@',
        },
        replace: true,
        transclude: true,
        template:
          '<div class="form-group" ng-class="{{classList}}" ng-form="fg_{{$id}}">' +
          '<abm-transclude-slot></abm-transclude-slot>' +
          '<div ng-show="showErrors" ng-messages="this[\'fg_\'+$id].$error" ng-messages-multiple>' +
          '<div ng-if="errorMessagesInclude" ng-messages-include="{{errorMessagesInclude}}"></div>' +
          '<div ng-repeat="(key,value) in errorMessageMap">' +
          '<!-- use ng-message-exp for a message whose key is given by an expression -->' +
          '<div ng-message-exp="key" class="help-block">{{value}}</div>' +
          '</div></div></div>',
        controller: [
          '$scope',
          '$element',
          function ($scope, $element) {
            $scope.errorMessageMap = {};
            $scope.hiddenLabel = false;
            $scope.hiddenLabelPhrase;

            function updateErrorMessages() {
              $scope.showErrors = abmConfig.getErrorState() && $scope.errorMessages !== false;
              if ($scope.showErrors) {
                var globalErrors = abmConfig.getErrors() || {};
                if ($scope.errorMessages instanceof Object)
                  angular.extend($scope.errorMessageMap, globalErrors, $scope.errorMessages);
                else angular.extend($scope.errorMessageMap, globalErrors);
              }
            }

            $scope.$watch('errorMessages', updateErrorMessages);

            this.toggleFocus = function (state) {
              $element.toggleClass('is-focused', state);
            };
            this.toggleEmpty = function (state) {
              $element.toggleClass('is-empty', state);
            };
            this.toggleError = function (state) {
              $element.toggleClass('has-error', state);
            };
            this.registerControl = function (scope) {
              $scope.formControl = scope;
            };
            this.addClass = function (className) {
              $element.addClass(className);
            };
          },
        ],
        link: {
          pre: function ($scope, element, attrs, ctrl, transclude) {},
          post: function (scope, element, attrs, ctrl, transclude) {
            /*if (transclude.isSlotFilled('label')) {
                     var label = transclude(angular.noop, null, 'label');
                     element.find('label').replaceWith(label);
                     }
                     if (transclude.isSlotFilled('input')) {
                     var input = transclude(function (clone, scope) {
                     element.find('input').replaceWith(clone);
                     }, null, 'input');
                     }*/

            transclude(function (clone, scope) {
              var label = clone.find('label');
              var formControl = clone.find('.form-control'); // TBD maybe defone an array of valid types
              label.attr('for', 'form-control-' + scope.$id);
              formControl.attr('id', 'form-control-' + scope.$id);
              element.find('abm-transclude-slot').replaceWith(clone);
            }, null);
          },
        },
      };
    },
  ])
  .directive('abmFormControl', function (isChar, $compile, $translate) {
    return {
      scope: {
        label: '@',
        abmPlaceholder: '@',
      },
      require: ['ngModel', '^abmFormGroup'],
      priority: 1000,
      compile: function () {
        return function ($scope, $element, attrs, ctrls) {
          var input = ctrls[0];
          var formGroup = ctrls[1];
          var hintKey;
          if (formGroup.hiddenLabel && formGroup.hiddenLabelPhrase) {
            hintKey = formGroup.hiddenLabelPhrase + '.hint';
          } else if ($scope.label) {
            hintKey = $scope.label + '.hint';
          }
          if (hintKey) {
            var hintText = $translate.instant(hintKey);
            if (hintText === hintKey && $scope.label) {
              hintText = $translate.instant($scope.label);
            }
            if (hintText !== hintKey && hintText !== $scope.label) {
              $element.attr('popover-placement', 'top');
              $element.attr('uib-popover', hintText);
              $element.attr('popover-trigger', 'mouseenter');
            }
          }
          if ($scope.abmPlaceholder) {
            $element.attr('placeholder', $translate.instant($scope.abmPlaceholder));
          } else if (!$element.attr('placeholder')) {
            $element.attr('placeholder', $translate.instant($scope.label));
          }

          $scope.label = $translate.instant($scope.label);

          if (!$element.hasClass('form-control')) {
            $element.addClass('form-control');
          }
          if (!$element.attr('id')) {
            $element.attr('id', 'form-control-' + $scope.$id);
          }
          formGroup.registerControl($scope);
          $element
            .on('input paste', function (e) {
              if (isChar(e)) {
                formGroup.toggleEmpty(true);
              }
            })
            .on('focus', function () {
              formGroup.toggleFocus(true);
            })
            .on('blur', function () {
              formGroup.toggleFocus(false);
              formGroup.toggleEmpty(!$element.val());
              formGroup.toggleError(input.$invalid);
            });

          $scope.$watch(function () {
            return /*input.$dirty && */ input.$invalid;
          }, formGroup.toggleError);
          $scope.$watch(
            function () {
              return input.$modelValue;
            },
            function (newValue, oldValue) {
              formGroup.toggleEmpty(!newValue);
            },
          );

          $element.removeAttr('abm-form-control'); //remove the attribute to avoid indefinite loop
          $element.removeAttr('data-abm-form-control'); //also remove the same attribute with data- prefix in case users specify data-common-things in the html

          var ngTest = /ng-.+/;
          _.forEach(attrs.$attr, function (e) {
            if (ngTest.test(e) && e !== 'ng-model') {
              $element.removeAttr(e);
            }
          });

          $compile($element)($scope.$parent);
        };
      },
    };
  })
  .directive('abmForm', function ($translate) {
    return {
      restrict: 'E',
      transclude: true,
      replace: true,
      templateUrl: '/templates/edit-form.html',
      scope: {
        _title: '@formTitle',
        hideTitle: '=?hideTitle',
        hideButtons: '=?hideButtons',
        headerClass: '@',
        bodyClass: '@',
        _title_values: '=?titleValues',
        _ok: '=?onok',
        _cancel: '=?oncancel',
        labelOk: '@labelOk',
        labelCancel: '@labelCancel',
        disableOk: '=?',
        disableCancel: '=?',
        validate: '=?',
        buttons: '=?',
        messages: '=?',
        errors: '=?',
      },
      controller: function ($scope, $element, $attrs, _) {
        $scope.submitting = false;
        $scope.valid = true;
        $scope._buttons = [];
        if ($scope.buttons) {
          $scope._buttons = _.filter($scope.buttons, function (button) {
            return button.name != 'cancel' && button.name != 'ok';
          });
        }
        var btnok = _.find($scope.buttons, { name: 'ok' });
        if (!$scope._ok && btnok) {
          $scope._ok = btnok.click;
          $scope.labelOk = btnok.label;
        }
        var btncancel = _.find($scope.buttons, { name: 'cancel' });
        if (!$scope._cancel && btncancel) {
          $scope._cancel = btncancel.click;
          $scope.labelCancel = btncancel.label;
        }

        $scope.ok = function () {
          if (_.isArray($scope.errors)) $scope.errors.length = 0;
          if (_.isArray($scope.messages)) $scope.messages.length = 0;
          $scope.submitting = true;
          $scope._ok(function (error) {
            $scope.submitting = false;
            if (error) {
              $scope.errors = [error];
            }
          });
        };
        $scope.cancel = function () {
          $scope._cancel();
        };
        if (!$scope.labelOk) $scope.labelOk = 'button.ok';
        if (!$scope.labelCancel) $scope.labelCancel = 'button.cancel';
        $scope.title = $translate.instant($scope._title, $scope._title_values);
        $scope.getValues = function () {
          var result = '{';
          return result + '}';
        };

        $scope.getMessage = function (message) {
          if (message && typeof message === 'object') {
            return message.code;
          } else {
            return message;
          }
        };
        $scope.getMessageValues = function (message) {
          if (message && typeof message === 'object') {
            return message.translateValues;
          } else {
            return null;
          }
        };
      },
      link: function ($scope, $element, $attrs, $ctrl) {
        $scope.$watch(
          function () {
            return $element.controller('form').$valid;
          },
          function (validity) {
            if (!(undefined === validity)) {
              $scope.valid = validity;
            }
          },
        );
        if (!$attrs.hasOwnProperty('messages') && !$scope.messages) {
          $scope.$watch(
            function () {
              return $scope.$parent.messages;
            },
            function (value) {
              $scope.messages = value;
            },
          );
        }
        if (!$attrs.hasOwnProperty('errors') && !$scope.errors) {
          $scope.$watch(
            function () {
              return $scope.$parent.errors;
            },
            function (value) {
              $scope.errors = value;
            },
          );
        }
      },
    };
  })
  .directive('abmBar', function () {
    return {
      restrict: 'EA',
      replace: true,
      transclude: true,
      require: '^progress',
      scope: {
        value: '=',
        max: '=?',
        color: '@?',
        type: '@',
      },
      template:
        '<div class="progress-bar" ng-class="type && \'progress-bar-\' + type" ' +
        'role="progressbar" aria-valuenow="{{value}}" aria-valuemin="0" aria-valuemax="{{max}}" ' +
        'ng-style="{width: (percent < 100 ? percent : 100) + \'%\', \'background-color\': color}" aria-valuetext="{{percent | number:0}}%" ' +
        'ng-transclude></div>',
      link: function (scope, element, attrs, progressCtrl) {
        progressCtrl.addBar(scope, element);
      },
    };
  })
  .directive('abmSwitch', function ($translate) {
    return {
      restrict: 'E',
      replace: true,
      template:
        '<div class="row" >' +
        "<div ng-class=\"{'col-xs-12': !label, 'col-xs-2': label, 'text-success': model, 'text-muted': !model }\">" +
        '  <i ng-click="change()" ng-if="!disabled" class="fa margin-2x-top" ng-class="{\'fa-2x\': size != \'small\', \'fa-toggle-on\': model, \'fa-toggle-off\': !model}"></i>' +
        "  <i ng-if=\"disabled\" class=\"fa  margin-2x-top\" ng-class=\"{'fa-2x': size != 'small', 'fa-toggle-on': model, 'fa-toggle-off': !model}\"></i>" +
        '</div>' +
        '<div class="col-xs-10" ng-if="label"><input-label label="{{label}}" left="true"></input-label></div>' +
        '</div>',
      scope: {
        model: '=',
        label: '@',
        disabled: '=',
        readonly: '=',
        size: '@',
        onChange: '=?',
      },

      controller: function ($scope, $http) {
        $scope.hint = $translate.instant($scope.label + '.hint');
        if ($scope.hint == $scope.label + '.hint') {
          $scope.hint = undefined;
        }
        $scope.change = function () {
          if (!$scope.disabled && !$scope.readonly) {
            $scope.model = !$scope.model;
            if (_.isFunction($scope.onChange)) {
              $scope.onChange();
            }
          }
        };
      },

      link: function ($scope, $element, $attrs, ctrl) {},
    };
  })
  .directive('abmCheckbox', function ($translate) {
    return {
      restrict: 'E',
      scope: {
        label: '@label',
        labelValues: '=?',
        model: '=',
        disabled: '=',
        fullSize: '=',
      },
      controller: function ($scope) {
        $scope.change = function () {
          if (!$scope.disabled) {
            $scope.model = !$scope.model;
          }
        };
      },
      template:
        '<div class="row">' +
        '<div class="col-xs-3" ng-hide="fullSize"></div>' +
        "<div ng-class=\"{'col-xs-9': !fullSize, 'col-xs-12': fullSize}\">" +
        '  <div ng-click="change()" ng-class="{\'disabled\': disabled}">' +
        '    <i class="fa fa-fw" ng-class="{\'fa-check-square-o\': model, \'fa-square-o\': !model}"></i>' +
        // '    <label translate="{{label}}"></label>' +
        '    <input-label left="true" label="{{label}}" label-values="labelValues" skipControlClass="true"></input-label>' +
        '  </div>' +
        '</div></div>',
    };
  })
  .directive('abmDate', function (isChar, $translate) {
    return {
      restrict: 'E',
      require: ['^abmFormGroup'],
      scope: {
        label: '@label',
        required: '=',
        model: '=',
        dateOptions: '=',
        minDate: '=',
        maxDate: '=',
        format: '@',
        mode: '@',
        readOnly: '@?',
        placeholder: '@?',
        allowClear: '=?',
        hideIcon: '=?',
      },
      controller: function ($scope, $http) {
        $scope.opened = false;
        $scope.options = $scope.dateOptions || {};
      },
      link: function ($scope, $element, $attrs, ctrls) {
        var formGroup = ctrls[0];
        var inputEl = $element.find('input');

        formGroup.registerControl($scope);
        inputEl
          .on('input paste', function (e) {
            if (isChar(e)) {
              formGroup.toggleEmpty(true);
            }
          })
          .on('focus', function () {
            formGroup.toggleFocus(true);
          })
          .on('blur', function () {
            formGroup.toggleFocus(false);
            formGroup.toggleEmpty(!inputEl.val());
            //formGroup.toggleError(input.$invalid);
          });
        $scope.$watch(
          function () {
            return $scope.model;
          },
          function (newValue, oldValue) {
            formGroup.toggleEmpty(!newValue);
          },
        );

        $scope.open = function (event) {
          event.preventDefault();
          event.stopPropagation();
          $scope.opened = true;
        };
        $scope.clear = function (event) {
          event.preventDefault();
          event.stopPropagation();
          $scope.model = undefined;
        };
      },
      template:
        '<div class="input-group">' +
        '<input type="text" class="form-control" placeholder="{{placeholder| translate}}" ' +
        '       ng-readonly="true" ng-click="open($event)" ' +
        '       datepicker-popup="{{format}}" ng-model="model" datepicker-mode="mode" ' +
        '       is-open="opened" min-date="minDate" max-date="maxDate" datepicker-options="options" ' +
        '       date-disabled="disabled(date, mode)" ng-required="required" ' +
        '       close-text="{{\'button.close\' | translate}}" show-button-bar="false" init-date="model"/>' +
        '<input type="text" ng-show="false" class="form-control" placeholder="{{placeholder| translate}}" ' +
        '       ng-readonly="{{readOnly}}"' +
        '       datepicker-popup="{{format}}" ng-model="model" datepicker-mode="mode" ' +
        '       is-open="false" min-date="minDate" max-date="maxDate" datepicker-options="options" ' +
        '       date-disabled="disabled(date, mode)" ng-required="required" ' +
        '       close-text="{{\'button.close\' | translate}}" show-button-bar="false" init-date="model"/>' +
        '<span class="input-group-btn">' +
        '<button class="btn btn-sm btn-default" ng-click="clear($event)" ng-show="allowClear"><i class="fa fa-remove"></i></button>' +
        '<button ng-if="!hideIcon" class="btn btn-sm btn-default" ng-click="open($event)"><i class="fa fa-calendar"></i></button>' +
        '</span>' +
        '</div>',
    };
  });
