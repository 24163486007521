angular
  .module('vcio-toolkit')

  .controller('BlockCustomController', function ($scope, $rootScope, $location, $http) {
    $scope.modifyStatement = function (statement) {
      if (statement.type === 'percentage') {
        statement.min = 0;
        statement.max = 100;
      }
      if (statement.min !== undefined && statement.value < statement.min) {
        statement.value = statement.min;
      }
      if (statement.max !== undefined && statement.value > statement.max) {
        statement.value = statement.max;
      }
      $scope.block.modified = true;
      // $http.post('/api/surveyresults/' + $scope.surveyResultId + '/responses/' + $scope.block.orderIdQuestion + '/' + statement.id, {statement: statement})
      //     .error(function (error) {
      //         console.error(error);
      //     });
    };
  })

  .controller('BlockQuarterlytableController', function ($scope, $rootScope, $location, $http) {
    $scope.modifyStatement = function (statement) {
      $scope.block.modified = true;
      // $http.post('/api/surveyresults/' + $scope.surveyResultId + '/responses/' + $scope.block.orderIdQuestion + '/' + statement.id, {statement: statement})
      //     .error(function (error) {
      //         console.error(error);
      //     });
    };
  })

  .controller(
    'BlockScoreController',
    function ($scope, $rootScope, $location, $http, $sce, $translate, _) {
      $scope.commentFocus = false;

      $scope.$watch('block', function (block) {
        $scope.statements = block.statements;
      });

      $scope.changeResponse = function (statement) {
        statement.checkedByUser = !statement.checkedByUser;
        statement.checked = statement.checkedByUser;

        if ($scope.block.answerType === 'single') {
          if (statement.checked) {
            $scope.block.statements.forEach(function (other) {
              if (statement.id != other.id && other.checked) {
                other.checkedByUser = false;
                other.checked = false;
              }
            });
          }
        }
        $scope.modifyStatement(statement);
      };

      $scope.modifyStatement = function (statement) {
        $scope.block.modified = true;
        // if ($scope.surveyResultId) {
        //     $http.post('/api/surveyresults/' + $scope.surveyResultId + '/responses/' + $scope.block.orderIdQuestion + '/' + statement.id, {statement: statement})
        // }
      };

      $scope.getCheckboxClass = function (statement) {
        //{'fa-check-square-o': statement.checkedByUser, 'fa-square-o': !statement.checkedByUser}
        if ($scope.block.answerType == 'single') {
          if (statement.checkedByUser) {
            return 'fa-check-circle text-primary';
          } else {
            return 'fa-circle';
          }
        } else {
          if (statement.checkedByUser) {
            return 'fa-check-square text-primary';
          } else {
            return 'fa-square';
          }
        }
      };

      // $scope.saveComment = function (block) {
      //     if ($scope.surveyResultId) {
      //         $http.post('/api/surveyresults/' + $scope.surveyResultId + '/blocks/' + $scope.block.orderIdQuestion + '/comments', {
      //             responseComment: block.responseComment
      //         }).then(function (response) {
      //         }, function (response) {
      //             console.error(response.data);
      //         });
      //     }
      // };

      $scope.fillNoneOfTheAbove = function (block) {
        var noneOfTheAbove = _.find(block.statements, { noneOfTheAbove: true });
        if (noneOfTheAbove && !_.find(block.statements, { checkedByUser: true })) {
          noneOfTheAbove.checkedByUser = true;
          $scope.modifyStatement(noneOfTheAbove);
          block.responseComment =
            (block.responseComment ? block.responseComment + '\n' : '') +
            $translate.instant('label.survey.noneOfTheAboveAuto');
          $scope.saveComment(block);
        }
      };

      $scope.keydown = function ($event) {
        if (!$scope.commentFocus) {
          if ($event.code == 'ArrowRight' && $scope.nextAction) {
            if ($scope.block) {
              $scope.fillNoneOfTheAbove($scope.block);
            }
            $scope.nextAction();
          } else if ($event.code == 'ArrowLeft' && $scope.prevAction) {
            $scope.prevAction();
          }
        }
      };

      // $scope.showPrev = function () {
      //     //return !_.includes(['finished'], $scope.state) && $scope.prevAction;
      //     return $scope.prevAction;
      // };
      //
      // $scope.showNext = function () {
      //     return !_.includes(['roles'], $scope.state) && $scope.nextAction;
      // };
    },
  )

  .controller(
    'FillSurveyController',
    function (
      $scope,
      $interpolate,
      $rootScope,
      $location,
      $http,
      $sce,
      $timeout,
      $translate,
      uuid,
      _,
      CurrentUser,
    ) {
      $scope.loadingFinished = false;
      $scope.selected = {};

      $scope.stateFlow = {
        welcome: 'instructions',
        instructions: 'started',
      };

      $scope.nextActionTitle = 'button.next';
      var surveyUUID =
        decodeURIComponent(
          (new RegExp('[?|&]survey=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [
            ,
            '',
          ])[1].replace(/\+/g, '%20'),
        ) || null;
      $http.get('/api/findsurvey/' + surveyUUID).then(
        function (response) {
          $scope.loadingFinished = true;
          var found = response.data;
          /*if (bowser.msie){
                 $scope.setState('msie');
                 } else*/
          if (found) {
            // $scope.startTime = new Date();
            $scope.landingPage = found.LandingPage;
            $scope.questionPaging = {
              itemsPerPage: 1,
              currentPage: Math.min(found.blockOrderId, found.totalBlocks),
              maxSize: 5,
              totalItems: found.totalBlocks,
            };
            $scope.surveyType = found.type;
            $scope.surveyURL = found.url;

            $scope.setBlock = function () {
              $scope.saveBlock();
              $scope.blockTemplate = undefined;
              $scope.block = $scope.blocks[$scope.questionPaging.currentPage - 1];
              if ($scope.block) {
                $scope.start = new Date();
                $scope.block.responseId = $scope.responseId;
                $scope.statements = $scope.block.statements;
                $scope.statements.forEach(function (statement) {
                  if (!statement.type || $scope.block.type === 'quarterlytable') {
                    statement.type = $scope.block.type === 'quarterlytable' ? 'number' : 'text';
                  }
                  if (!statement.value) {
                    if (statement.type === 'textWithNumber') {
                      statement.value = { text: '', number: 0 };
                    } else if (
                      (statement.type === 'number' || statement.type === 'currency') &&
                      statement.min >= 0
                    ) {
                      statement.value = statement.min;
                    } else if (statement.type === 'percentage') {
                      statement.value = 0;
                    }
                  }
                });
                $scope.blockTemplate =
                  $scope.block.type === 'score'
                    ? '/templates/sales/survey/block-score.html'
                    : '/templates/sales/survey/block-custom.html';
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                var els = document.getElementsByClassName('content');
                for (var i = 0; i < els.length; i++) {
                  els[i].scrollTop = 0;
                }
                $timeout(function () {
                  $scope.$broadcast('rzSliderForceRender');
                }, 100);
                // this is for embedding the survey into the page, not for the 'popup' iframe
                // setTimeout(function () {
                //     window.parent.postMessage('change-ryc-fill-container-' + (document.getElementById('ryc-fill-content').scrollHeight + 55), '*');
                // }, 500);
              }
            };

            CurrentUser.setToken(found.token, true);
            CurrentUser.setUser(found.user, true);
            $scope.surveyResultId = found.surveyResultId;
            $scope.surveyName = found.surveyName;
            if (found.multiRole) {
              if (found.selectedRole) {
                $scope.selected.role = found.selectedRole;
                $scope.setState('welcome');
              } else {
                $scope.setState('notfound');
              }
            } else {
              $scope.setState('welcome');
            }
          } else {
            $scope.setState('notfound');
          }
        },
        function () {
          $scope.loadingFinished = true;
          $scope.setState('notfound');
        },
      );

      $scope.hiddenFilter = function (item) {
        return !item.hidden;
      };

      $scope.createSurveyResultTime = function () {
        // if ($scope && $scope.surveyResultId && $scope.block) {
        //     $http.post('/api/surveyresults/' + $scope.surveyResultId + '/times/' + $scope.block.orderIdQuestion,
        //         {
        //             start: $scope.startTime,
        //             end: new Date()
        //         })
        // }
      };

      $scope.$on('$destroy', function () {
        delete window.onbeforeunload;
        if (CurrentUser.getUser()) {
          $scope.createSurveyResultTime();
        }
      });

      window.onbeforeunload = function (event) {
        if (CurrentUser.getUser()) {
          $scope.createSurveyResultTime();
        }
      };

      $scope.finish = function (dontClose) {
        $scope.saveBlock();
        if (
          $scope.surveyResultId &&
          $scope.allQuestionAnswered($scope.allBlocks) &&
          $scope.questionPaging.currentPage == $scope.questionPaging.totalItems
        ) {
          $scope.createSurveyResultTime();
          $http
            .put(
              '/api/surveyresults/' +
                $scope.surveyResultId +
                '/status/filled?' +
                ($scope.selected.role ? '&role=' + $scope.selected.role.code : ''),
            )
            .then(function () {
              if (!dontClose) {
                $scope.close();
              }
            });
        }
      };

      $scope.close = function () {
        // $scope.saveBlock(function () {
        if (window.self !== window.top) {
          window.parent.postMessage('close-ryc-fill-container', '*');
        } else {
          window.close();
        }
        // });
      };

      // $scope.saveComment = function (block) {
      //     if ($scope.surveyResultId) {
      //         $http.post('/api/surveyresults/' + $scope.surveyResultId + '/blocks/' + $scope.block.orderIdQuestion + '/comments', {
      //             responseComment: block.responseComment
      //         }).then(function (response) {
      //         }, function (response) {
      //             console.error(response.data);
      //         });
      //     }
      // };
      //
      // $scope.finish = function () {
      //     if ($scope.surveyResultId) {
      //         $scope.createSurveyResultTime();
      //         $http.put('/api/surveyresults/' + $scope.surveyResultId + '/state/finished').then(function () {
      //             $scope.finished = true;
      //             $scope.close();
      //         }, function (response) {
      //             console.error(response.data);
      //         });
      //     }
      // }
      //
      // $scope.blockChecked = function (block) {
      //     var result = false;
      //     for (var j = 0; j < block.statements.length; j++) {
      //         if (block.statements[j].checkedByUser) {
      //             result = true;
      //         }
      //     }
      //     return result;
      // }

      $scope.allQuestionAnswered = function (blocks) {
        return (
          _.includes(['qbr', 'mpd'], $scope.surveyType) ||
          !_.find(blocks, function (block) {
            return !_.find(block.statements, { checkedByUser: true });
          })
        );
        // for (var i = 0; i < $scope.blocks.length; i++) {
        //     if (!$scope.blockChecked($scope.blocks[i])) return false;
        // }
        // return true;
      };

      $scope.showFinish = function () {
        return _.includes(['notfound', 'finished'], $scope.state) && !$scope.responseId;
      };

      $scope.setNextState = function () {
        $scope.setState($scope.stateFlow[$scope.state]);
      };

      $scope.saveBlock = function (cb) {
        if ($scope.block && /*!$scope.block.saved || */ $scope.block.modified) {
          $scope.block.end = new Date();
          $http
            .post(
              '/api/surveyresults/' + $scope.surveyResultId + '/responses/' + $scope.block.id,
              $scope.block,
            )
            .success(function () {
              if (cb) {
                cb();
              }
            })
            .error(function (error) {
              console.error(error);
              if (cb) {
                cb();
              }
            });

          // $scope.block.saved = true;
          $scope.block.modified = false;
        } else {
          if (cb) {
            cb();
          }
        }
      };

      $scope.setState = function (state) {
        $scope.state = state;
        $scope.nextAction = undefined;
        $scope.prevAction = undefined;
        switch ($scope.state) {
          case 'msie':
            $scope.message = $sce.trustAsHtml($translate.instant('error.msie'));
            $scope.template = '/templates/sales/survey/message.html';
            break;
          case 'notfound':
            $scope.message = $sce.trustAsHtml(
              '<p><strong>We cannot find the survey you are looking for.</strong></p><p>This can happen because the survey has been finished or you do not have right to fill it.</p><p>Please contact your contact person.</p>',
            );
            $scope.template = '/templates/sales/survey/message.html';
            break;
          case 'welcome':
            if ($scope.landingPage && $scope.landingPage.welcome) {
              $scope.nextAction = $scope.setNextState;
              $scope.nextActionTitle = 'button.next';
              $scope.message = $sce.trustAsHtml($scope.landingPage.welcome);
              $scope.template = '/templates/sales/survey/message.html';
            } else {
              $scope.setNextState();
            }
            break;
          case 'instructions':
            if ($scope.landingPage && $scope.landingPage.instructions) {
              $scope.prevAction = function () {
                $scope.setState('welcome');
              };
              $scope.nextAction = $scope.setNextState;
              $scope.message = $sce.trustAsHtml($scope.landingPage.instructions);
              $scope.nextActionTitle = 'button.next';
              $scope.template = '/templates/sales/survey/message.html';
            } else {
              $scope.setNextState();
            }
            break;
          case 'started':
            $scope.nextAction = function () {
              if ($scope.questionPaging.currentPage == $scope.questionPaging.totalItems) {
                $scope.saveBlock();
                if ($scope.allQuestionAnswered($scope.blocks)) {
                  $scope.setState('finished');
                }
              } else {
                $scope.questionPaging.currentPage++;
                $scope.setBlock();
              }
            };

            $scope.prevAction = function () {
              if ($scope.questionPaging.currentPage > 1) {
                $scope.questionPaging.currentPage--;
                $scope.setBlock();
              } else {
                $scope.setState('welcome');
              }
            };

            $http
              .get(
                '/api/surveyresults/' +
                  $scope.surveyResultId +
                  '/blocks?' +
                  ($scope.selected.role ? '&role=' + $scope.selected.role.code : ''),
              )
              .then(
                function (response) {
                  $scope.blocks = response.data;

                  if ($scope.selected.role && $scope.selected.role.multiresponse) {
                    $scope.responseId = uuid.v4();
                  }

                  var firstUnfinishedBlock = _.find($scope.blocks, function (block) {
                    return !_.find(block.statements, { checkedByUser: true });
                  });
                  if (firstUnfinishedBlock) {
                    $scope.questionPaging.currentPage =
                      _.indexOf($scope.blocks, firstUnfinishedBlock) + 1;
                  } else {
                    $scope.questionPaging.currentPage = $scope.blocks.length;
                  }

                  $scope.questionPaging.totalItems = $scope.blocks.length;
                  $scope.questionPaging.currentPage = Math.min(
                    $scope.questionPaging.currentPage,
                    $scope.questionPaging.totalItems,
                  );

                  $scope.template = '/templates/sales/survey/block.html';
                  $scope.setBlock();
                },
                function () {
                  $scope.setState('notfound');
                },
              );

            $scope.nextActionTitle = 'button.next';
            break;
          case 'finished':
            $scope.nextAction = undefined;
            // $scope.prevAction = function () {
            //     $scope.setState('started')
            // }
            if ($scope.landingPage && $scope.landingPage.thankyou) {
              $scope.message = $sce.trustAsHtml(
                $interpolate($scope.landingPage.thankyou)({ url: $scope.surveyURL }),
              );
            }
            $scope.template = '/templates/sales/survey/message.html';
            if ($scope.responseId) {
              $scope.finish(true);
            }
            break;
        }
      };
    },
  );
