angular
  .module('vcio-toolkit')
  .directive('navbarMain', function () {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      controller: 'NavbarController',
      templateUrl: '/templates/navbar.html',
      scope: {
        hideNavbar: '=',
        embedded: '=',
      },
    };
  })

  .directive('navbar', function ($rootScope, $timeout, $location) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        template: '@',
      },
      template: '<div ng-include="template"></div>',
      link: function (scope, element) {
        $rootScope.$on(
          '$stateChangeSuccess',
          function (event, toState, toParams, fromState, fromParams) {
            var template = '';
            if (toState.data && toState.data.navbar) {
              template = toState.data.navbar;
            }
            $timeout(function () {
              scope.template = template;
            });
          },
        );
      },
    };
  })

  .directive('navButton', function ($translate) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        labelAfter: '@labelAfter',
        labelBefore: '@labelBefore',
        icon: '@icon',
        action: '=action',
        model: '=model',
      },
      controller: function ($scope) {
        $scope.htmlid = ($scope.labelBefore || '') + ($scope.labelAfter || '');
      },
      template:
        '<li ng-class="{\'active\': model}"><a ng-click="action()" id="{{htmlid}}">{{labelBefore | translate}} <i class="fa fa-{{icon}}"></i> {{labelAfter | translate}}</a></li>',
    };
  })

  .directive('navPopup', function ($rootScope, $modal, _) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        labelAfter: '@labelAfter',
        labelBefore: '@labelBefore',
        icon: '@icon',
        action: '=action',
        model: '=model',
        templateUrl: '@',
        controller: '@',
        resolve: '=',
        refresh: '=',
        size: '@',
      },

      controller: function ($scope) {
        $scope.htmlid = ($scope.labelBefore || '') + ($scope.labelAfter || '');
        if (!$scope.size) {
          $scope.size = 'md';
        }

        $scope.open = function () {
          var modalScope = $rootScope.$new();
          modalScope.modalInstance = $modal.open({
            templateUrl: $scope.templateUrl,
            controller: $scope.controller,
            scope: modalScope,
            backdrop: 'static',
            resolve: $scope.resolve,
          });

          modalScope.modalInstance.result.then(
            function (result) {
              if ($scope.refresh) {
                $scope.refresh(result);
              }
            },
            function () {},
          );
        };
      },
      template:
        '<li ng-class="{\'active\': model}"><a ng-click="open()" id="{{htmlid}}">{{labelBefore | translate}} <i class="fa fa-{{icon}}"></i> {{labelAfter | translate}}</a></li>',
    };
  })

  .directive('navLink', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        labelAfter: '@labelAfter',
        labelBefore: '@labelBefore',
        labelUnder: '@labelUnder',
        navbarMenuIcon: '=navbarMenuIcon',
        target: '@target',
        icon: '@icon',
        href: '@href',
        model: '=model',
      },
      controller: function ($scope) {
        if (!$scope.target) {
          $scope.target = '_self';
        }
        $scope.htmlid = ($scope.labelBefore || '') + ($scope.labelAfter || '');
        $scope.show = function () {
          return !!$scope.href;
        };
      },
      template:
        '<li ng-class="{\'active\': model}" class="nav-link"><a ng-href="{{href}}" target="{{target}}" id="{{htmlid}}" ng-class="{\'text-center\': labelUnder, \'no-padding-bottom\': navbarMenuIcon}"">{{labelBefore | translate}} <i class="fa fa-{{icon}}" ng-class="{\'fa-2x\': navbarMenuIcon}"></i> {{labelAfter | translate}}<br ng-if="labelUnder"><small>{{labelUnder |translate}}</small></a></li>',
    };
  })

  .directive('navSref', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        labelAfter: '@',
        labelBefore: '@',
        icon: '@',
        sref: '@',
        model: '=',
      },
      controller: function ($scope) {
        $scope.htmlid = ($scope.labelBefore || '') + ($scope.labelAfter || '');
        $scope.show = function () {
          return !!$scope.sref;
        };
      },
      template:
        '<li ng-class="{\'active\': model}" class="nav-link"><a ui-sref="{{sref}}" target="{{target}}" id="{{htmlid}}" ng-class="{\'text-center\': labelUnder, \'no-padding-bottom\': navbarMenuIcon}"">{{labelBefore | translate}} <i class="fa fa-{{icon}}" ng-class="{\'fa-2x\': navbarMenuIcon}"></i> {{labelAfter | translate}}<br ng-if="labelUnder"><small>{{labelUnder |translate}}</small></a></li>',
    };
  })

  .directive('navState', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        labelAfter: '@',
        labelBefore: '@',
        icon: '@',
        state: '@',
        stateParams: '=?',
        model: '=',
      },
      controller: function ($scope, $state) {
        $scope.htmlid = ($scope.labelBefore || '') + ($scope.labelAfter || '');
        $scope.goState = function () {
          $state.go($scope.state, $scope.stateParams);
        };
      },
      template:
        '<li ng-class="{\'active\': model}"><a ng-click="goState()" id="{{htmlid}}">{{labelBefore | translate}} <i class="fa fa-{{icon}}"></i> {{labelAfter | translate}}</a></li>',
    };
  })

  .directive('navSwitch', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        labelAfter: '@',
        labelBefore: '@',
        model: '=',
        icon: '@',
        disabled: '=',
      },
      controller: function ($scope, _) {
        if ($scope.icon) {
          $scope.iconOn = (_.startsWith($scope.icon, 'fa-') ? '' : 'fa-') + $scope.icon;
        } else {
          $scope.iconOn = 'fa-toggle-on';
        }
        if ($scope.icon) {
          $scope.iconOff = (_.startsWith($scope.icon, 'fa-') ? '' : 'fa-') + $scope.icon;
        } else {
          $scope.iconOff = 'fa-toggle-off';
        }
        $scope.iconClass = function () {
          return $scope.model ? $scope.iconOn : $scope.iconOff;
        };
        $scope.click = function () {
          if (!$scope.disabled) {
            $scope.model = !$scope.model;
          }
        };
      },
      template:
        '<li ng-class="{\'active\': model}" ><a ng-click="click()">{{labelBefore | translate}} <i class="fa" ng-class="iconClass()"></i> {{labelAfter | translate}}</a></li>',
    };
  })

  .directive('scrollPosition', function ($window) {
    return function (scope, element, attrs) {
      angular.element($window).bind('scroll', function () {
        if (this.pageYOffset >= 35) {
          scope.boolChangeClass = true;
        } else {
          scope.boolChangeClass = false;
        }
        scope.$apply();
      });
    };
  })

  .directive('appcuesWidget', function () {
    return {
      restrict: 'E',
      template:
        '<a ng-if="showWidget">' +
        '  <span id="appcues-widget" class="text-center no-padding-top">' +
        '    <div class="appcues-widget-icon"><i class="fa fa-bell" style="font-size: 13px !important;"></i></div>' +
        '  </span>' +
        '</a>',
      replace: true,
      controller: function ($scope, $window) {
        $scope.showWidget = $window.AppcuesWidget && $window.Appcues;
        if ($scope.showWidget) {
          var widget = AppcuesWidget(Appcues.user());
          widget.init('#appcues-widget', {
            position: 'left',
            header: 'Notifications',
            footer: serverConfigSettings.appcuesFooter || '',
          });
        }
      },
    };
  });

//    .directive('scrollPosition', function ($window) {
//        return {
//            scope: {
//                scroll: '=scrollPosition'
//            },
//            link: function ($scope, $element, $attrs) {
//                var windowEl = angular.element($window);
//                var handler = function () {
//                    $scope.scroll = windowEl.scrollTop();
//                }
//                windowEl.on('scroll', $scope.$apply.bind($scope, handler));
//                handler();
//            }
//        };
//    });
