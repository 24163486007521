angular
  .module('vcio-toolkit')
  .controller('AdminController', function ($scope) {
    $scope.viewTab = 'users';

    $scope.menuItems = [
      { name: 'users', label: 'title.users', icon: 'users' },
      { name: 'rights', label: 'title.rights', icon: 'unlock' },
    ];
  })

  .controller(
    'AdminTasksController',
    function ($modal, $rootScope, $scope, _, DialogService, HttpService) {
      // var downloadLog = function (result, name) {
      //     var anchor = angular.element('<a/>').css({display: 'none'});
      //     angular.element(document.body).append(anchor); // Attach to document
      //     anchor.attr({
      //         href: 'data:text/csv;charset=utf-8,' + encodeURI(result),
      //         target: '_blank',
      //         download: name + '.csv'
      //     })[0].click();
      //     anchor.remove();
      // };

      // $scope.generateHTGMPDs = function () {
      //     var modalInstance = $modal.open({
      //         template: '<modal-form onok="ok" oncancel="cancel">' +
      //             '<input-text model="options.year" label="label.sysadmin.HTGMPD.year" required="true"></input-text>' +
      //             '<input-text model="options.quarter" label="label.sysadmin.HTGMPD.quarter"required="true"></input-text>' +
      //             '<input-text model="options.meetingTemplateId" label="label.sysadmin.HTGMPD.templateId"required="true"></input-text>' +
      //             '<input-textarea model="options.emails" label="label.sysadmin.HTGMPD.emails"required="true"></input-textarea>' +
      //             '</modal-form>',
      //         controller: function ($modalInstance, $moment, $scope, $translate, _, HttpService) {
      //             $scope.options = {};
      //
      //             $scope.ok = function (cb) {
      //
      //                 HttpService.post('/api/system/tasks/generate-evolve-mpds', $scope.options)
      //                     .then(function (result) {
      //                         $modalInstance.close();
      //                         DialogService.message($translate.instant('label.sysadmin.HTGMPD.created'));
      //                     })
      //                     .catch(function (error) {
      //                         cb();
      //                         DialogService.error(error);
      //                     })
      //             }
      //             $scope.cancel = function () {
      //                 $modalInstance.dismiss();
      //             }
      //         },
      //         backdrop: 'static'
      //     });
      // }

      $scope.generateHTGMPDSnapshots = function () {
        var modalInstance = $modal.open({
          template:
            '<modal-form onok="ok" oncancel="cancel" form-title="label.sysadmin.HTGMPDSnapshots.title">' +
            '<input-text model="options.year" placeholder="{{options.pYear}}" label="label.sysadmin.HTGMPDSnapshots.year" required="true"></input-text>' +
            '<input-text model="options.quarter" placeholder="{{options.pQuarter}}" label="label.sysadmin.HTGMPDSnapshots.quarter"required="true" uib-popover="Data quarter of the snapshots." popover-placement="top" popover-trigger="mouseenter"></input-text>' +
            '<input-select label="label.sysadmin.HTGMPDSnapshots.templateId" model="options.meetingTemplate" values="options.templates" required="true" type="id"></input-select>' +
            '<div class="alert alert-info" translate="label.sysadmin.HTGMPDSnapshots.alert"></div>' +
            '</modal-form>',
          controller: function (
            $modalInstance,
            $moment,
            $scope,
            $translate,
            _,
            DialogService,
            HttpService,
          ) {
            $scope.options = { templates: [] };

            $scope.options.pYear = moment().subtract(2, 'quarter').format('YYYY');
            $scope.options.pQuarter = 'Q' + moment().subtract(2, 'quarter').format('Q');

            HttpService.get('/api/system/tasks/get-quarterly-meeting-templates')
              .then(function (templates) {
                $scope.options.templates = templates;
                $scope.options.meetingTemplate = _.get(templates, 0);
              })
              .catch(function (error) {
                DialogService.error(error);
              });

            $scope.ok = function (cb) {
              HttpService.post('/api/system/tasks/generate-quarterly-snapshots', {
                year: $scope.options.year,
                quarter: $scope.options.quarter,
                meetingTemplateId: $scope.options.meetingTemplate.id,
              })
                .then(function (result) {
                  $modalInstance.close();
                  DialogService.message(
                    $translate.instant('label.sysadmin.HTGMPDSnapshots.created'),
                  );
                })
                .catch(function (error) {
                  cb();
                  DialogService.error(error);
                });
            };
            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
          backdrop: 'static',
        });
      };

      $scope.updateMeetingsFromTemplate = function () {
        var modalInstance = $modal.open({
          template:
            '<modal-form onok="ok" oncancel="cancel">' +
            '<input-text model="options.meetingTemplateId" label="label.sysadmin.HTGMPDSnapshots.templateId"required="true" ng-if="!template"></input-text>' +
            '<div class="alert alert-info" ng-if="!template">Evolve MPD: 3357</div>' +
            '<abm-form-group class="no-margin-top padding-3x-left" ng-if="template">' +
            '  <abm-checkbox model="update.sections" label="label.meeting.updateFromTemplate.sections"></abm-checkbox>' +
            '  <p><p/>' +
            '  <div class="row">' +
            '    <div class="col-xs-1">create</div>' +
            '    <div class="col-xs-1">update</div>' +
            '    <div class="col-xs-10">widget</div>' +
            '  </div>' +
            '  <div class="row" ng-repeat="item in update.widgets">' +
            '    <div class="col-xs-1"><abm-checkbox model="item.create"></abm-checkbox></div>' +
            '    <div class="col-xs-1"><abm-checkbox model="item.update"></abm-checkbox></div>' +
            '    <div class="col-xs-10"><label>{{item.label}}</label></div>' +
            '  </div>' +
            '</abm-form-group>' +
            '</modal-form>',
          backdrop: 'static',
          size: 'lg',
          controller: function ($modalInstance, $moment, $scope, $translate, _, HttpService) {
            $scope.options = { labelOk: $translate.instant('button.next') };

            $scope.ok = function (cb) {
              if (!$scope.template) {
                $rootScope.$broadcast('dataLoadingStarted');
                HttpService.get(
                  '/api/system/tasks/get-meeting-template/' + $scope.options.meetingTemplateId,
                )
                  .then(function (template) {
                    $rootScope.$broadcast('dataLoadingFinished');
                    $scope.template = template;
                    $scope.update = { widgets: [] };

                    var updateItem = function (widget) {
                      return {
                        uuid: widget.uuid,
                        orderId: widget.orderId,
                        label: widget.name || $translate.instant('meetingWidget.' + widget.type),
                        create: false,
                        update: false,
                      };
                    };
                    // $scope.update.widgets = _.map($scope.meeting.widgets, function (widget) {
                    //     return updateItem(widget, _.find($scope.template.widgets, {uuid: widget.uuid}) ? 'update' : 'delete')
                    // });

                    _.forEach($scope.template.widgets, function (widget) {
                      // if (!_.find($scope.meeting.widgets, {uuid: widget.uuid})) {
                      $scope.update.widgets.push(updateItem(widget));
                      // }
                    });

                    $scope.update.widgets = _.sortBy($scope.update.widgets, 'orderId');
                    cb();
                  })
                  .catch(function (error) {
                    $rootScope.$broadcast('dataLoadingFinished');
                    DialogService.error(error);
                    cb();
                  });
              } else {
                HttpService.post(
                  '/api/system/tasks/update-meetings-from-template/' +
                    $scope.options.meetingTemplateId,
                  {
                    widgets: _.filter($scope.update.widgets, function (widget) {
                      return widget.create || widget.update;
                    }),
                    sections: $scope.update.sections,
                  },
                )
                  .then(function (result) {
                    DialogService.message('label.meeting.updateFromTemplate.success');
                    $modalInstance.close(result);
                    cb();
                  })
                  .catch(function (error) {
                    DialogService.error(error);
                    cb();
                  });
              }
            };
            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
        });
      };

      $scope.callHTGSLIImport = function () {
        var modalInstance = $modal.open({
          template:
            '<modal-form onok="ok" oncancel="cancel">' +
            '<input-text model="options.username" label="login.username"></input-text>' +
            '<input-text model="options.password" label="login.password"></input-text>' +
            '<input-text model="options.year" label="label.sysadmin.HTGSLI.year"></input-text>' +
            '<input-text model="options.quarter" label="label.sysadmin.HTGSLI.quarter"></input-text>' +
            '</modal-form>',
          controller: function ($scope, HttpService, $modalInstance) {
            $scope.options = {};

            $scope.ok = function (cb) {
              HttpService.post('/api/system/tasks/htg-sli-import', $scope.options)
                .then(function (result) {
                  $modalInstance.close();
                  DialogService.message('label.sysadmin.HTGSLI.success');
                })
                .catch(function (error) {
                  cb();
                  DialogService.error(error);
                });
            };
            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
          backdrop: 'static',
        });
      };

      // $scope.openHTGMove = function () {
      //     var modalInstance = $modal.open({
      //         template: '<modal-form onok="ok" oncancel="cancel">' +
      //             '<input-text model="options.email" label="label.sysadmin.HTGMove.email"></input-text>' +
      //             '<input-text model="options.fromGroupName" label="label.sysadmin.HTGMove.fromGroupName"></input-text>' +
      //             '<input-text model="options.toGroupName" label="label.sysadmin.HTGMove.toGroupName"></input-text>' +
      //             '</modal-form>',
      //         controller: function ($scope, HttpService, $modalInstance) {
      //             $scope.options = {};
      //
      //             $scope.ok = function (cb) {
      //                 HttpService.post('/api/system/tasks/htg-move', $scope.options)
      //                     .then(function (result) {
      //                         $modalInstance.close();
      //                         DialogService.message({
      //                             code: 'label.sysadmin.HTGMove.success',
      //                             translateValues: result
      //                         });
      //                     })
      //                     .catch(function (error) {
      //                         cb();
      //                         DialogService.error(error);
      //                     })
      //             }
      //             $scope.cancel = function () {
      //                 $modalInstance.dismiss();
      //             }
      //         },
      //         backdrop: 'static'
      //     });
      // }

      $scope.identifyAllUser = function () {
        var modalInstance = $modal.open({
          template:
            '<modal-form onok="ok" oncancel="cancel">' +
            '<input-text model="options.userIds" label="label.sysadmin.identify.userIds"></input-text>' +
            '</modal-form>',
          backdrop: 'static',
          size: 'lg',
          controller: function ($modalInstance, $moment, $scope, $translate, _, HttpService) {
            $scope.options = {};

            $scope.ok = function (cb) {
              HttpService.post('/api/system/tasks/identify', { userIds: $scope.options.userIds })
                .then(function (result) {
                  cb();
                  DialogService.message('label.sysadmin.identify.success');
                })
                .catch(function (error) {
                  cb();
                  DialogService.error(error);
                });
            };
            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
        });
      };

      $scope.permissionGroupCopy = {};
      $scope.copyPermissionGroup = function () {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.post('/api/system/tasks/copypermissiongroup', {
          permissionGroupId: $scope.permissionGroupCopy.permissionGroupId,
          vendorId: $scope.permissionGroupCopy.vendorId,
        })
          .then(function (result) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.message('label.sysadmin.copyPermissionGroup.success');
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
          });
      };

      $scope.$watch('usersToImport', function () {
        if (_.isArray($scope.usersToImport)) {
          $modal.open({
            template:
              '<modal-form onok="ok" oncancel="cancel">' +
              '<p translate="label.sysadmin.importUsers.message"></p>' +
              '<table>' +
              '  <tr ng-repeat="item in usersToImport track by $index">' +
              '    <td>{{item.Company.name}}</td>' +
              '    <td>{{item.Company.address}}</td>' +
              '    <td>{{item.Company.city}}</td>' +
              '    <td>{{item.Company.state}}</td>' +
              '    <td>{{item.Company.zip}}</td>' +
              '    <td>{{item.Company.country}}</td>' +
              '    <td>{{item.firstName}}</td>' +
              '    <td>{{item.lastName}}</td>' +
              '    <td>{{item.email}}</td>' +
              '    <td>{{item.Company.vendor}}</td>' +
              '  </tr> ' +
              '</table>' +
              '</modal-form>',
            controller: function ($scope, HttpService, $modalInstance, usersToImport) {
              $scope.usersToImport = _.map(usersToImport, function (item) {
                return {
                  email: _.toLower(item.email),
                  firstName: item.firstName,
                  lastName: item.lastName,
                  Company: {
                    name: item.name,
                    address: item.address,
                    city: item.city,
                    state: item.state,
                    zip: item.zip,
                    country: item.country,
                    vendor: item.vendor,
                  },
                };
              });

              $scope.ok = function (cb) {
                HttpService.post('/api/system/tasks/importusers', $scope.usersToImport)
                  .then(function (result) {
                    $modalInstance.close();
                    // DialogService.message('', 'label.sysadmin.importUsers.success');
                    $modal.open({
                      template:
                        '<modal-form oncancel="close" label-cancel="button.close">' +
                        '<p translate="label.sysadmin.importUsers.success"></p>' +
                        '<table>' +
                        '  <tr>' +
                        '    <th>Company name</th>' +
                        '    <th>email</th>' +
                        '    <th></th>' +
                        '  </tr> ' +
                        '  <tr ng-repeat="item in importedUsers track by $index" ng-class="{\'text-danger\': !item.created && !item.existing, \'text-warning\': item.isDeleted}">' +
                        '    <td>{{item.Company.name}}</td>' +
                        '    <td>{{item.email}}</td>' +
                        '    <td>{{item.isDeleted ? "user is deleted" : (item.invalid ? "invalid data" : "")}}</td>' +
                        '  </tr> ' +
                        '</table>' +
                        '</modal-form>',
                      controller: function ($scope, HttpService, $modalInstance, importedUsers) {
                        $scope.importedUsers = importedUsers;
                        $scope.cancel = function () {
                          $modalInstance.dismiss();
                        };
                      },
                      backdrop: 'static',
                      size: 'lg',
                      resolve: {
                        importedUsers: function () {
                          return result;
                        },
                      },
                    });
                  })
                  .catch(function (error) {
                    cb();
                    DialogService.error(error);
                  });
              };
              $scope.cancel = function () {
                $modalInstance.dismiss();
              };
            },
            backdrop: 'static',
            size: 'lg',
            resolve: {
              usersToImport: function () {
                return $scope.usersToImport;
              },
            },
          });
        }
      });

      $scope.cloneStripeCustomer = function () {
        var modalInstance = $modal.open({
          template:
            '<modal-form onok="ok" oncancel="cancel">' +
            '<input-text model="options.customerId" label="label.sysadmin.cloneStripeCustomer.customerId"></input-text>' +
            '<input-text model="options.connectedAccountId" label="label.sysadmin.cloneStripeCustomer.connectedAccountId"></input-text>' +
            '<input-text model="options.subscription.planId" label="label.sysadmin.cloneStripeCustomer.planId"></input-text>' +
            '<input-date model="options.subscription.trialEnd" label="label.sysadmin.cloneStripeCustomer.trialEnd"></input-date>' +
            '<input-text model="options.subscription.feePercent" label="label.sysadmin.cloneStripeCustomer.feePercent"></input-text>' +
            '</modal-form>',
          controller: function ($scope, HttpService, $modalInstance) {
            $scope.options = {};

            $scope.ok = function (cb) {
              HttpService.post('/api/system/tasks/clonestripecustomer', $scope.options)
                .then(function (result) {
                  $modalInstance.close();
                  DialogService.message('label.sysadmin.cloneStripeCustomer.success');
                })
                .catch(function (error) {
                  cb();
                  DialogService.error(error);
                });
            };
            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
          backdrop: 'static',
        });
      };
    },
  )

  // .controller('ContactsController', function ($scope, $stateParams, $http, $modal, $location, $q, _) {
  //     $scope.contacts = [];
  //     $scope.filter = {name: '', status: $location.search().status};
  //     //$scope.init = function (status) {
  //     //    $scope.filter.status = status;
  //     //    $scope.refresh();
  //     //};
  //
  //     $scope.refresh = function () {
  //         $scope.refreshing = true;
  //         $q.all([$http.get('/api/contacts'), $http.get('/api/leads')]).then(function (_results) {
  //             $scope.contacts = [];
  //             _results.forEach(function (_result) {
  //                 $scope.contacts = _.union($scope.contacts, _result.data);
  //                 //addSeries(_result.data);
  //             });
  //             $scope.refreshing = false;
  //         })
  //     };
  //    $scope.refresh();
  //
  //     $scope.source = function (contact) {
  //         var result = [];
  //
  //         if (contact.LeadMagnetHit) {
  //             result.push('lead magnet');
  //         }
  //         if (contact.GraderResponse) {
  //             result.push('grader');
  //         }
  //         return result;
  //};
  //
  //     $scope.openEditContact = function (contact) {
  //
  //         var modalInstance = $modal.open({
  //             templateUrl: '/templates/client/contact-edit.html',
  //             controller: 'ContactEditController',
  //             backdrop: 'static',
  //             resolve: {
  //                 contact: function () {
  //                     return _.extend(angular.copy(contact), {status: 'customer'});
  //                 },
  //                 client: function () {
  //                     return contact.Client;
  //                 }
  //             }
  //         });
  //
  //         modalInstance.result.then(function () {
  //             $scope.refresh();
  //         }, function () {
  //         });
  //     };
  //             })

  .controller('EmailTemplatesController', function ($scope, $http, $modal) {
    $scope.emailTemplates = [];

    $scope.refresh = function () {
      $http.get('/api/emailtemplates').success(function (result) {
        $scope.emailTemplates = result;
      });
    };

    $scope.refresh();

    $scope.copy = function (template) {
      $scope.edit({
        name: template.name,
        language: template.language,
        from: template.from,
        bcc: template.bcc,
        subject: template.subject,
        body: template.body,
        css: template.css,
      });
    };

    $scope.edit = function (template) {
      var modalInstance = $modal.open({
        templateUrl: '/templates/admin/email-template-edit.html',
        controller: 'EmailTemplateEditController',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          emailTemplate: function () {
            return angular.copy(template || { language: 'en' });
          },
        },
      });

      modalInstance.result.then(
        function () {
          $scope.refresh();
        },
        function () {},
      );
    };
  })

  .controller(
    'EmailTemplateEditController',
    function (
      $http,
      $modal,
      $modalInstance,
      $scope,
      _,
      CurrentUser,
      DialogService,
      HttpService,
      emailTemplate,
    ) {
      $scope.select = { tab: 'edit' };
      $scope.emailTemplate = emailTemplate;
      $scope.companies = [];
      $scope.options = {};
      if (
        $scope.emailTemplate.name === 'weeklyReport' ||
        $scope.emailTemplate.name === 'monthlyReport'
      ) {
        HttpService.get('/api/admin/users?all=true').then(function (users) {
          $scope.users = users;
        });
      }

      $scope.ok = function () {
        if ($scope.emailTemplate.id) {
          // modify
          $http
            .put('/api/emailtemplates', { emailTemplate: $scope.emailTemplate })
            .success(function (result) {
              $modalInstance.close(result);
            })
            .error(function (error) {
              $scope.errors.push(error);
            });
        } else {
          //create
          $http
            .post('/api/emailtemplates', { emailTemplate: $scope.emailTemplate })
            .success(function (result) {
              $modalInstance.close(result);
            })
            .error(function (error) {
              $scope.errors.push(error);
            });
        }
      };

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };

      $scope.preview = function () {
        var doc = document.getElementById('sample-iframe').contentWindow.document;
        doc.open();
        doc.write('loading...');
        doc.close();
        HttpService.post('/email/sample', {
          body: $scope.emailTemplate.body,
          templateName: $scope.emailTemplate.name,
          userId: $scope.options.user ? $scope.options.user.id : undefined,
          companyId: $scope.options.company ? $scope.options.company.id : undefined,
        })
          .then(function (value) {
            var doc = document.getElementById('sample-iframe').contentWindow.document;
            doc.open();
            doc.write(value);
            doc.close();
          })
          .catch(function (error) {
            DialogService.error(error);
          });
      };

      $scope.$watch('select.tab', function () {
        if ($scope.select.tab == 'preview') {
          $scope.preview();
        }
      });

      $scope.$watch('options.user', function () {
        $scope.options.company =
          $scope.options.user && !_.isEmpty($scope.options.user.Companies)
            ? $scope.options.user.Companies[0]
            : undefined;
        if ($scope.options.user && $scope.options.company) {
          $scope.preview();
        }
      });

      $scope.sendEmail = function () {
        if ($scope.options.user && $scope.options.company) {
          HttpService.post('/api/email/send', {
            templateName: $scope.emailTemplate.name,
            userId: $scope.options.user ? $scope.options.user.id : undefined,
            companyId: $scope.options.company ? $scope.options.company.id : undefined,
          })
            .then(function () {
              DialogService.message({
                code: 'message.email.testSent',
                translateValues: { email: $scope.options.user.email },
              });
            })
            .catch(function (error) {
              DialogService.error(error);
            });
        }
      };

      $scope.openRichEdit = function () {
        var modalInstance = $modal.open({
          template:
            '<modal-form onok="ok" oncancel="cancel">' +
            '<div class="alert alert-danger">Be careful, Froala can screw up the mustache logic!</div>' +
            '<textarea ng-model="options.value" froala></textarea>' +
            '</modal-form>',
          controller: function ($scope, $modalInstance, value) {
            $scope.options = { value: value };
            $scope.ok = function () {
              $modalInstance.close($scope.options.value);
            };
            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
          backdrop: 'static',
          size: 'lg',
          resolve: {
            value: function () {
              return _.cloneDeep($scope.emailTemplate.body);
            },
          },
        });
        modalInstance.result.then(function (result) {
          $scope.emailTemplate.body = result;
        });
      };
    },
  )

  .controller('RightsController', function ($scope, $http, _, rights, roles, modules) {
    $scope.rights = rights;
    $scope.modules = modules;
    $scope.roles = roles;

    $scope.columns = [];
    _.forEach(modules, function (module) {
      _.forEach(roles, function (role) {
        $scope.columns.push({ module: module, role: role });
      });
    });
  });
