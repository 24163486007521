angular
  .module('vcio-toolkit')

  .factory('PermissionGroupCategoryModel', function ($resource) {
    var resource = $resource(
      '/api/vendor/permissiongroupcategories/:permissionGroupCategoryId',
      { permissionGroupCategoryId: '@id' },
      {
        create: {
          method: 'POST',
          transformRequest: function (data) {
            return angular.toJson({ permissionGroupCategory: data });
          },
        },
        delete: {
          method: 'DELETE',
          transformRequest: function (data) {
            return angular.toJson({ permissionGroupCategory: data });
          },
        },
        update: {
          url: '/api/vendor/permissiongroupcategories',
          method: 'PUT',
          transformRequest: function (data) {
            return angular.toJson({ permissionGroupCategory: data });
          },
        },
        reorder: {
          url: '/api/vendor/permissiongroupcategories/reorder',
          isArray: true,
          method: 'POST',
          transformRequest: function (data) {
            var permissionGroupCategoryIds = [];
            _.forEach(data, function (item) {
              permissionGroupCategoryIds.push({
                id: item.id,
                orderId: item.orderId,
                CategoryId: item.CategoryId,
              });
            });
            return angular.toJson({ permissionGroupCategoryIds: permissionGroupCategoryIds });
          },
        },
      },
    );

    resource.prototype.$save = function (cb, cbError) {
      if (!this.id) {
        return this.$create(cb, cbError);
      } else {
        return this.$update(cb, cbError);
      }
    };

    return resource;
  });
