angular
  .module('vcio-toolkit')

  // .service('AcademyAssetService', function (_, AssetManager) {
  //     return {
  //         attachableAssetGroupBy: function (item, useTypePrefix) {
  //             useTypePrefix = useTypePrefix !== false;
  //             var category;
  //             var subcategory;
  //             if (item && item.SubCategoryId) {
  //                 subcategory = AssetManager.getCategoryById(item.SubCategoryId);
  //                 category = subcategory ? AssetManager.getCategoryById(subcategory.CategoryId) : undefined;
  //             }
  //             if (category && subcategory) {
  //                 if (useTypePrefix) {
  //                     return item.typeName + ' / ' + category.name + ' / ' + subcategory.name;
  //                 } else {
  //                     return category.name + ' / ' + subcategory.name;
  //                 }
  //             } else {
  //                 return item && useTypePrefix ? item.typeName : '';
  //             }
  //         },
  //         parseAssetSections: function (course) {
  //             // _.forEach(course.sections, function (section) {
  //             //     _.forEach(section.attachedAssets, function (item) {
  //             //         _.extend(item, AssetManager.getAssetById(item.id));
  //             //     });
  //             //     _.remove(section.attachedAssets, function (item) {
  //             //         return !item.name;
  //             //     });
  //             //     if (section.quiz) {
  //             //         section.quiz = _.cloneDeep(AssetManager.getAssetById(section.quiz.id));
  //             //     }
  //             // });
  //             // _.remove(course.sections, function (item) {
  //             //     return item.attachedAssets && item.attachedAssets.length === 0 && !item.quiz;
  //             // })
  //         }
  //     };
  // })

  .controller(
    'AcademyManageAssetsController',
    function ($scope, $rootScope, $state, $http, _, CurrentUser) {
      $scope.createAsset = function () {
        $scope.messages = [];
        $scope.errors = [];
        $state.go('academy.assetAdd');
      };

      $scope.save = function (asset, cb) {
        $scope.messages = [];
        $scope.errors = [];
        $http.put('/api/academy/assets', { asset: asset }).then(
          function () {
            $scope.messages = ['message.saved'];
            cb();
          },
          function (error) {
            $scope.errors = [error.data];
            cb();
          },
        );
      };

      $scope.revert = function (asset) {
        $scope.messages = [];
        $scope.errors = [];
        $http.get('/api/academy/assets/' + asset.id).then(function (response) {
          asset = response.data;
        });
      };
    },
  )

  .controller(
    'AcademyAssetEditController',
    function (
      $modal,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $http,
      $timeout,
      $translate,
      _,
      AcademyService,
      AssetModel,
      AssetService,
      CurrentUser,
      UploadService,
      assets,
      asset,
      isCopy,
      trainers,
      uuid,
    ) {
      $scope.currentUser = CurrentUser.getUser();
      $scope.isCopy = isCopy || false;
      $scope.Trainers = [];
      $scope.isFieldShown = AssetService.isFieldShown;
      $scope.asset = asset;
      $scope.isCourseEdit = $state.current.name === 'academy.courseAssetEdit';
      $scope.assetList = assets;
      $scope.expanded = {
        sections: true,
        todoLists: true,
      };
      $scope.asset.attachedAssetsObj = _.groupBy(asset.relatedAssets, 'type');
      if (!$scope.asset.id) {
        $scope.isNew = true;
        $scope.asset = {
          type: $stateParams.assetType,
          knowledgebaseType: $stateParams.assetType === 'knowledgebase' ? 'article' : undefined,
          published: true,
          SubCategoryId: parseInt($stateParams.assetCategory),
          VendorId: $scope.currentUser.Company.id,
        };
        $scope.asset.TrainerId = $scope.isFieldShown($scope.asset, 'trainer')
          ? $scope.currentUser.id
          : undefined;
      }
      if ($scope.isCopy) {
        delete $scope.asset.id;
        delete $scope.asset.CreatedById;
        delete $scope.asset.UpdatedById;
        $scope.asset.TrainerId = null;
      }

      _.forEach(trainers, function (trainer) {
        $scope.Trainers.push({
          id: trainer.id,
          name: trainer.firstName + ' ' + trainer.lastName,
          logoUrl: '/images/users/' + trainer.id + '?' + new Date().getTime(),
        });
      });
      if (
        $scope.asset.SubCategoryId &&
        $scope.asset.SubCategory &&
        $scope.asset.SubCategory.Category
      ) {
        $scope.asset.Category = $scope.asset.SubCategory.Category;
      }
      $scope.asset.typeName = $translate.instant('label.academy.assetTypes.' + $scope.asset.type);
      $scope.asset.prerequisites = $scope.asset.prerequisites || [];
      var oldAsset = _.cloneDeep($scope.asset);
      $scope.messages = [];
      $scope.errors = [];
      //console.log($scope.asset.type);
      if ($scope.asset.type === 'roadmap') {
        $scope.attachableArticles = _.orderBy(
          _.filter($scope.assetList, { type: 'course' }),
          'name',
        );
      } else {
        $scope.attachableArticles = _.orderBy(
          _.filter($scope.assetList, { type: 'article' }),
          'name',
        );
      }
      $scope.selectToAttachAsset = {};
      _.forEach($scope.asset.sections, function (section) {
        section.attachedAssets = _($scope.asset.AttachedAssets)
          .filter(function (asset) {
            return asset.AcademyAssetAttachedAsset.SectionId == section.id;
          })
          .sortBy(function (asset) {
            return asset.AcademyAssetAttachedAsset.orderId;
          })
          .value();
      });

      $scope.knowledgebaseTypes = AssetService.knowledgebaseTypes;

      $scope.revert = function (asset) {
        $scope.messages = [];
        $scope.errors = [];
        $http.get('/api/academy/assets/' + asset.id).then(function (response) {
          asset = response.data;
        });
      };

      $scope.ok = function (cb) {
        //this can be removed later, just here to fix missing section ids on test
        _.forEach($scope.asset.sections, function (section) {
          if (!section.id) {
            section.id = uuid.v4();
          }
        });

        // we do not have to send the section asset details to save bandwith
        var assetToSave = _.cloneDeep($scope.asset);
        _.forEach(assetToSave.sections, function (section) {
          section.attachedAssets = _.map(section.attachedAssets, function (section) {
            return _.pick(section, 'id');
          });
        });

        AssetService.save(assetToSave)
          .then(function (result) {
            oldAsset = _.cloneDeep($scope.asset);
            if (!$scope.asset.id) {
              if (cb) {
                cb();
              }
              $scope.isCopy = false;
              isCopy = false;
              $state.go('academy.assetEdit', { assetId: result.id });
            } else {
              if (cb) {
                cb();
              }
              $scope.messages = ['message.saved'];
            }
          })
          .catch(function (error) {
            console.error(error);
            $scope.errors = [error];
            if (cb) {
              cb();
            }
          });
      };

      $scope.cancel = function () {
        // $state.go('academy.asset', {assetId: $scope.asset.id});
        if ($scope.isCourseEdit) {
          $state.go('academy.courseAsset', $stateParams);
        } else {
          AssetService.goState(asset);
        }
      };

      function youtube_parser(url) {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        var match = url.match(regExp);
        return match && match[7].length == 11 ? match[7] : false;
      }

      $scope.changeVideo = function (event) {
        var val = event.clipboardData.getData('Text');
        if ($scope.asset.videoSource === 'youtube') {
          var parseUrlCode = youtube_parser(val);
          if (parseUrlCode) {
            $timeout(function () {
              $scope.asset.video = parseUrlCode;
            }, 100);
          }
        }
      };

      $scope.addTodoList = function (todoListName) {
        if (todoListName) {
          if (!$scope.asset.todoLists) {
            $scope.asset.todoLists = [];
          }
          $scope.asset.todoLists.push({
            name: todoListName,
            todo: [],
          });
          $scope.selectToAttachAsset = {};
        }
      };

      $scope.addTask = function (todoText, step) {
        if (todoText) {
          if (!step.todo) {
            step.todo = [];
          }
          step.todo.push({
            text: todoText,
          });
          $scope.selectToAttachAsset = {};
        }
      };

      $scope.addSection = function (sectionName) {
        if (sectionName) {
          if (!$scope.asset.sections || !$scope.asset.sections.length) {
            $scope.asset.sections = [];
          }
          var section = {
            name: sectionName,
            id: uuid.v4(),
            attachedAssets: [],
          };
          $scope.asset.sections.push(section);
          $scope.selectToAttachAsset = {};
        }
      };

      $scope.createQuiz = function (section) {
        var newQuiz = {
          name: section.name + ' - quiz',
          subtitle: '',
          overview: '',
          type: 'quiz',
          quizType: 'quiz',
          published: false,
          TrainerId: $scope.currentUser.id,
          typeName: '',
          quiz: { questions: [] },
        };
        AssetService.save(newQuiz, $scope.assetList).then(
          function (result, error) {
            if (!error) {
              if (result) {
                $scope.attachAsset(result, section.attachedAssets);
                $scope.ok();
              }
            } else {
              console.error(error);
            }
          },
          function (error) {
            console.error(error);
          },
        );
      };

      $scope.editAsset = function (asset, section) {
        $rootScope.$broadcast('dataLoadingStarted');
        $scope.ok(); //function () {
        if (asset.type != 'quiz') {
          $state.go('academy.courseAssetEdit', {
            courseId: $scope.asset.id,
            sectionId: section.id,
            assetId: asset.id,
          });
        } else {
          $state.go('academy.quizEdit', {
            courseId: $scope.asset.id,
            sectionId: section.id,
            assetId: asset.id,
          });
        }
        // });
      };

      function closeAllTodoTasks() {
        if ($scope.asset.todoLists && $scope.asset.todoLists.length) {
          _.forEach($scope.asset.todoLists, function (ti) {
            if (ti.todo && ti.todo.length) {
              _.forEach(ti.todo, function (t) {
                t.$$expanded = false;
              });
            }
          });
        }
      }

      $scope.todoTaskExpandToggle = function (task) {
        if (!task.$$expanded) {
          closeAllTodoTasks();
        }
        task.$$expanded = !task.$$expanded;
      };

      $scope.remove = AcademyService.remove;

      $scope.openUpload = function () {
        UploadService.openFile('assets', $scope.asset).then(
          function (data) {
            $scope.asset.FileId = data.id;
            $scope.asset.fileUrl = data.url;
            $scope.asset.filename = data.filename;
            $scope.asset.File = data;
          },
          function () {},
        );
      };

      $scope.createAsset = function (list) {
        if (!list) {
          list = [];
        }
        var modalInstance = $modal.open({
          templateUrl: '/templates/academy/manage/attached-asset-create-modal.html',
          controller: function ($scope, $modalInstance, $translate, _, AssetService, CurrentUser) {
            var currentUser = CurrentUser.getUser();
            $scope.knowledgebaseTypes = AssetService.knowledgebaseTypes;
            $scope.newAsset = {
              name: '',
              subtitle: '',
              overview: '',
              type: 'knowledgebase',
              knowledgebaseType: '',
              published: false,
              TrainerId: currentUser.id,
              typeName: '',
            };

            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
            $scope.ok = function () {
              $modalInstance.close($scope.newAsset);
              $scope.ok = undefined;
            };
            $scope.changeAssetType = function (type) {
              $scope.newAsset.knowledgebaseType = type;
              $scope.newAsset.typeName = $translate.instant('label.academy.assetTypes.' + type);
            };
          },
          backdrop: 'static',
        });

        modalInstance.result.then(
          function (asset) {
            if (asset) {
              $rootScope.$broadcast('dataLoadingStarted');
              AssetService.save(asset, $scope.assetList).then(
                function (result, error) {
                  $rootScope.$broadcast('dataLoadingFinished');
                  if (!error) {
                    if (result) {
                      $scope.attachAsset(result, list);
                    }
                  } else {
                    console.error(error);
                  }
                },
                function (error) {
                  $rootScope.$broadcast('dataLoadingFinished');
                  console.error(error);
                },
              );
            }
            $scope.selectToAttachAsset = {};
          },
          function () {
            $scope.selectToAttachAsset = {};
          },
        );
      };

      $scope.attachAsset = function (asset, list) {
        if (!list) {
          list = [];
        }
        if (asset) {
          list.push(asset);
        }
        $scope.selectToAttachAsset = {};
      };

      $scope.removeAttachedAsset = function (asset, list) {
        if ($scope.asset.type === 'course') {
          new AssetModel(asset).$delete();
          // AssetManager.remove(asset);
        }
        AcademyService.remove(asset, list);
      };

      $scope.unlinkAttachedAsset = function (asset, list) {
        AcademyService.remove(asset, list);
      };

      $scope.removeRelatedAssets = function (item2remove, list) {
        _.remove(list, function (item) {
          return item.id === item2remove.id;
        });
      };

      $scope.checkFormIsDirty = function () {
        return !angular.equals(oldAsset, $scope.asset);
      };

      $scope.attachableCourseFilter = function (o) {
        if (o.type !== 'course') {
          return false;
        } else if ($scope.asset.type === 'roadmap') {
          return (
            !_.find(_.flatten(_.map($scope.asset.sections, 'attachedCourses')), { id: o.id }) &&
            o.id !== $scope.asset.id
          );
        } else {
          return !_.find($scope.asset.relatedAssets, { id: o.id }) && o.id !== $scope.asset.id;
        }
      };

      $scope.attachableAssetFilter = function (o) {
        if ($scope.asset.type === 'roadmap') {
          return (
            o.type === 'course' &&
            o.id !== $scope.asset.id &&
            !_.find(_.flatten(_.map($scope.asset.sections, 'attachedAssets')), { id: o.id })
          );
        } else if ($scope.asset.type === 'course') {
          return (
            (o.type === 'training' || o.type === 'resource') &&
            o.id !== $scope.asset.id &&
            !_.find(_.flatten(_.map($scope.asset.sections, 'attachedAssets')), { id: o.id })
          );
        } else {
          return o.id !== $scope.asset.id && !_.find($scope.asset.relatedAssets, { id: o.id });
        }
      };
      $scope.attachableAssetGroupBy = AssetService.attachableAssetGroupBy;
      $scope.attachableAssetGroupOrder = function (groups) {
        return _.sortBy(
          _.map(groups, function (group) {
            group.items = _.sortBy(group.items, 'orderId');
            return group;
          }),
          'name',
        );
      };

      $scope.hasMissingPermissions = function (item) {
        var result;
        if ($scope.asset.SubCategory && item) {
          if (item.SubCategory && item.SubCategory.PermissionGroups) {
            result = _.find($scope.asset.SubCategory.PermissionGroups, function (group) {
              return !_.find(item.SubCategory.PermissionGroups, { id: group.id });
            });
            // } else if (item.type !== 'knowledgebase' && !item.SubCategory) {
            //     result = true;
          }
        }
        if (result) {
          $scope.warnings = _.uniq(
            _.union($scope.warnings, ['warning.assetEdit.attachedAssetNotVisible']),
          );
        }
        return result;
      };

      $scope.getMissingPermissions = function (item) {
        var result;
        if ($scope.asset.SubCategory) {
          var missingPermissions = [];
          var _asset = item; //AssetManager.getAssetById(item.id);
          if (_asset && _asset.SubCategory && _asset.SubCategory.PermissionGroups) {
            _.forEach($scope.asset.SubCategory.PermissionGroups, function (group) {
              if (!_.find(_asset.SubCategory.PermissionGroups, { id: group.id })) {
                missingPermissions.push(group);
              }
            });
            if (missingPermissions.length) {
              result = $translate.instant(
                'label.academy.assetEdit.attachedAssetMissingPermissions',
              );
              _.forEach(missingPermissions, function (group) {
                result += '<br>' + group.name;
              });
            }
          }
        }
        return result;
      };

      $scope.prerequisitesFilter = function (o) {
        return (
          o.type === 'course' &&
          !_.find($scope.asset.prerequisites, { id: o.id }) &&
          o.id !== $scope.asset.id
        );
      };

      if (AssetService.isFieldShown($scope.asset, 'relatedAssets') && $scope.asset.SubCategoryId) {
        $scope.$watchCollection('asset.relatedAssets', function (val) {
          if (!val) {
            $scope.asset.relatedAssets = [];
          }
          $scope.relatedAssets = [];
          _.forEach($scope.asset.relatedAssets, function (item) {
            var data = _.find($scope.assetList, { id: item.id });
            if (data) {
              $scope.relatedAssets.push(data);
            }
          });
          $scope.relatedAssetsObj = _.groupBy($scope.relatedAssets, 'type');
        });
      }
    },
  )

  .controller(
    'AcademyQuizEditController',
    function (
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $http,
      $timeout,
      $translate,
      _,
      AcademyService,
      AssetService,
      CurrentUser,
      quiz,
      trainers,
    ) {
      $scope.currentUser = CurrentUser.getUser();
      $scope.Trainers = [];
      $scope.quiz = quiz;
      $scope.quiz.quiz = $scope.quiz.quiz || { questions: [] };
      $scope.isCourseEdit = $state.current.name === 'academy.courseQuizEdit';
      $scope.messages = [];
      $scope.errors = [];
      $scope.listValues = 'quizQuestionTypes';
      $scope.quiz.typeName = $translate.instant('label.academy.assetTypes.' + $scope.quiz.type);
      if (!$scope.quiz.quiz.passPercent && $scope.quiz.quiz.passPercent !== 0) {
        $scope.quiz.quiz.passPercent = 80;
      }
      if (!$scope.quiz.quizType) {
        $scope.quiz.quizType = 'quiz';
      }
      var oldAsset = _.cloneDeep($scope.quiz);

      $scope.quizTypes = ['quiz', 'checkpoint', 'survey'];

      _.forEach(trainers, function (trainer) {
        $scope.Trainers.push({
          id: trainer.id,
          name: trainer.firstName + ' ' + trainer.lastName,
          logoUrl: '/images/users/' + trainer.id + '?' + new Date().getTime(),
        });
      });
      $scope.selectToAttachQuiz = {};
      $scope.select = {};

      $scope.ok = function (cb) {
        AssetService.save($scope.quiz)
          .then(function (result) {
            oldAsset = _.cloneDeep($scope.quiz);
            if (!$scope.quiz.id) {
              cb();
              $state.go('academy.quizEdit', { quizId: result.id });
            } else {
              cb();
              $scope.messages = ['message.saved'];
            }
          })
          .catch(function (error) {
            console.error(error);
            $scope.errors = [error];
            cb();
          });
      };

      $scope.cancel = function () {
        if ($scope.isCourseEdit) {
          $state.go('academy.assetEdit', { assetId: $stateParams.courseId });
        } else {
          if (
            $rootScope.previousState &&
            $rootScope.previousState.name &&
            $rootScope.previousState.name != $state.$current.name
          ) {
            $state.go($rootScope.previousState.name, $rootScope.previousState.params);
          } else {
            $state.go('academy.assetcategoriesByType', { type: 'course' });
          }
        }
      };

      $scope.addAnswer = function (answerText, question) {
        if (answerText) {
          if (!question.answers) {
            question.answers = [];
          }
          question.answers.push({
            value: question.type === 'number' ? parseInt(answerText) : answerText,
            correct: false,
            difference: 0,
          });
          $scope.selectToAttachQuiz = {};
        }
      };

      $scope.addQuestion = function (type) {
        if (!$scope.quiz.quiz.questions) {
          $scope.quiz.quiz.questions = [];
        }
        $scope.quiz.quiz.questions.push({
          question: '',
          type: type,
          hint: '',
          answers: [],
          $$expanded: false,
        });
        $timeout(function () {
          $scope.select = {};
        }, 100);
      };

      $scope.remove = AcademyService.remove;

      $scope.setCorrect = function (question, answer) {
        _.forEach(question.answers, function (o) {
          o.correct = o === answer;
        });
        //answer.
      };

      $scope.changeQuizType = function (type) {
        if (type === 'checkpoint') {
          $scope.quiz.quiz.passPercent = 100;
          $scope.quiz.quiz.questions = [];
        } else if (type === 'survey') {
          $scope.quiz.quiz.passPercent = 0;
        } else if (type === 'quiz') {
          $scope.quiz.quiz.passPercent = 80;
        }
      };
    },
  )

  .controller(
    'AcademyAssetController',
    function (
      $modal,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $translate,
      $http,
      _,
      AcademyService,
      AssetService,
      DialogService,
      CurrentUser,
      HttpService,
      asset,
      quizResults,
      InputSanitizerService,
    ) {
      $scope.asset = asset;
      $scope.quizResult =
        (quizResults[asset.id] &&
          _.extend(quizResults[asset.id].quizResult, {
            passed: !!quizResults[asset.id].finishedAt,
          })) ||
        asset.quiz;
      if ($scope.quizResult) {
        $scope.asset.quiz = _.merge($scope.asset.quiz, $scope.quizResult);
        if ($scope.quizResult.passed) {
          $scope.asset.$$quizSubmitted = true;
          $scope.asset.finishedAt = quizResults[asset.id].finishedAt;
        }
      }
      $scope.isCourseView =
        $state.current.name === 'academy.courseAsset' ||
        $state.current.name === 'academy.roadmapCourseAsset';
      $scope.currentUser = CurrentUser.getUser();
      $scope.hasModule = CurrentUser.hasModule;
      $scope.can = CurrentUser.can;
      $scope.isFieldShown = AssetService.isFieldShown;
      if ($scope.asset.Vendor) {
        $scope.asset.vendorFooter = $scope.asset.Vendor.vendorFooter;
      }
      $scope.relatedAssets = [];
      _.forEach($scope.asset.todoLists, function (todoList) {
        todoList.totalHours = _.round(_.sumBy(todoList.todo, 'hours'), 1);
        todoList.$$expanded = true;
      });

      $scope.isVendor = function () {
        return CurrentUser.hasModule('vendor');
      };

      $scope.markAsCompleted = function (cb) {
        if (AssetService.isFieldShown($scope.asset, 'markAsCompleted')) {
          AssetService.finish($scope.asset).then(
            function () {
              cb();
            },
            function (error) {
              console.error(error);
              DialogService.error(error);
            },
          );
        } else {
          cb();
        }
      };

      $scope.getNextButtonText = function () {
        if (!$scope.isFieldShown($scope.asset, 'markAsCompleted')) {
          if ($scope.asset.nextAsset) {
            return $translate.instant('label.academy.viewAsset.buttonNext');
          } else {
            return $translate.instant('label.academy.viewAsset.buttonBack');
          }
        } else {
          if ($scope.asset.finishedAt) {
            if ($scope.asset.nextAsset) {
              return $translate.instant('label.academy.viewAsset.buttonNext');
            } else {
              return $translate.instant('label.academy.viewAsset.buttonBack');
            }
          } else {
            if ($scope.asset.nextAsset) {
              return $translate.instant('label.academy.viewAsset.buttonCompleteAndNext');
            } else {
              return $translate.instant('label.academy.viewAsset.buttonCompleteAndBack');
            }
          }
        }
      };

      $scope.goNext = function () {
        if (!$scope.asset.finishedAt) {
          $scope.markAsCompleted(function () {
            if ($scope.asset.nextAsset) {
              $scope.goAsset($scope.asset.nextAsset);
            } else {
              $scope.goCourse($scope.asset.Course);
            }
          });
        } else {
          if ($scope.asset.nextAsset) {
            $scope.goAsset($scope.asset.nextAsset);
          } else {
            $scope.goCourse($scope.asset.Course);
          }
        }
      };

      $scope.edit = function () {
        if ($scope.asset.type === 'quiz') {
          $state.go('academy.quizEdit', { assetId: $scope.asset.id });
        } else if ($scope.isCourseView) {
          $state.go('academy.courseAssetEdit', $stateParams);
        } else {
          $state.go('academy.assetEdit', { assetId: $scope.asset.id });
        }
      };
      $scope.canEdit = function () {
        return (
          $scope.asset.VendorId === $scope.currentUser.Company.id && $scope.can('manageAcademy')
        );
      };
      $scope.canAssign = function () {
        return !$scope.isCourseView && $scope.asset.type === 'course';
      };
      $scope.can = function (right) {
        return CurrentUser.can(right);
      };
      $scope.sendToVcio = function (system) {
        if (
          system === 'connectwise' &&
          !_.get($scope.currentUser.Company.settings, 'myCompany.connectwise.id')
        ) {
          DialogService.error(
            $translate.instant('message.externalSystems.connectwise.noDefaultClient'),
          );
        } else {
          var modalInstance = $modal.open({
            templateUrl: '/templates/vcio/asset-to-vcio.html',
            controller: 'VcioAssetToVcioController',
            backdrop: 'static',
            //size: 'lg',
            resolve: {
              asset: function () {
                return $scope.asset;
              },
              system: function () {
                return system;
              },
            },
          });

          modalInstance.result.then(
            function () {},
            function () {},
          );
        }
      };

      $scope.getAssetLink = function (asset) {
        if ($state.current.name === 'academy.courseAsset') {
          var sectionId = asset.sectionId ? asset.sectionId : $stateParams.sectionId;
          // if (asset.type == 'quiz') {
          //     return 'academy.courseQuiz({courseId: ' + $stateParams.courseId + ', sectionId: "' + sectionId + '", assetId: ' + asset.id + '})';
          // } else {
          return (
            'academy.courseAsset({courseId: ' +
            $stateParams.courseId +
            ', sectionId: "' +
            sectionId +
            '", assetId: ' +
            asset.id +
            '})'
          );
          // }
        } else {
          return 'academy.asset({assetId: ' + asset.id + '})';
        }
      };
      $scope.goAsset = function (asset) {
        if ($state.current.name === 'academy.courseAsset') {
          var sectionId = asset.sectionId ? asset.sectionId : $stateParams.sectionId;
          // if (asset.type == 'quiz') {
          //     $state.go('academy.courseQuiz', {
          //         courseId: $stateParams.courseId,
          //         sectionId: sectionId,
          //         assetId: asset.id
          //     });
          // } else {
          $state.go('academy.courseAsset', {
            courseId: $stateParams.courseId,
            sectionId: sectionId,
            assetId: asset.id,
          });
          // }
        } else if ($state.current.name === 'academy.roadmapCourseAsset') {
          var sectionId = asset.sectionId ? asset.sectionId : $stateParams.sectionId;
          // if (asset.type == 'quiz') {
          //     $state.go('academy.courseQuiz', {
          //         courseId: $stateParams.courseId,
          //         sectionId: sectionId,
          //         assetId: asset.id
          //     });
          // } else {
          // url: '/academy/roadmaps/:roadmapId/:sectionId/courses/:courseId/:courseSectionId/assets/:assetId',

          $state.go('academy.roadmapCourseAsset', {
            roadmapId: $stateParams.roadmapId,
            sectionId: $stateParams.sectionId,
            courseId: $stateParams.courseId,
            courseSectionId: sectionId,
            assetId: asset.id,
          });
          // }
        } else {
          $state.go('academy.asset', { assetId: asset.id });
        }
      };

      $scope.isSectionDisabled = function (_section) {
        if (
          CurrentUser.hasModule('vendor') &&
          $scope.asset.VendorId == $scope.currentUser.Company.id
        ) {
          return false;
        } else {
          var currentSectionIndex = _.indexOf($scope.asset.sections, _section);
          var prevSections = _.filter($scope.asset.sections, function (section) {
            return _.indexOf($scope.asset.sections, section) < currentSectionIndex;
          });

          return _.find(prevSections, function (section) {
            return (
              !_(section.attachedAssets).filter({ type: 'quiz' }).isEmpty() &&
              !_(section.attachedAssets).filter({ type: 'quiz' }).every('finishedAt')
            ); //(section.quiz && !section.quiz.finishedAt)
          });
        }
      };

      $scope.goAssetLink = function (section, courseAsset) {
        // if (!$scope.isSectionDisabled(section)) {
        if ($scope.asset.type === 'roadmap') {
          return (
            'academy.roadmapCourse({ roadmapId: ' +
            $scope.asset.id +
            ", sectionId: '" +
            section.id +
            "', courseId: " +
            courseAsset.id +
            ' })'
          );
        } else if ($state.current.name === 'academy.roadmapCourse') {
          return (
            'academy.roadmapCourseAsset({ roadmapId: ' +
            $stateParams.roadmapId +
            ", sectionId: '" +
            $stateParams.sectionId +
            "', courseId: " +
            $scope.asset.id +
            ", courseSectionId: '" +
            section.id +
            "', assetId: " +
            courseAsset.id +
            ' })'
          );
        } else {
          return (
            'academy.courseAsset({ courseId: ' +
            $scope.asset.id +
            ", sectionId: '" +
            section.id +
            "', assetId: " +
            courseAsset.id +
            ' })'
          );
        }
        // }
      };
      // $scope.goQuizLink = function (section, courseQuiz) {
      //     return 'academy.courseQuiz({ courseId: ' + $scope.asset.id + ', sectionId: "' + section.id + '", assetId: ' + courseQuiz.id + '})';
      // };
      $scope.goCourse = function (course) {
        if ($state.current.name === 'academy.courseAsset') {
          $state.go('academy.course', {
            courseId: course.id,
          });
        } else if ($state.current.name === 'academy.roadmapCourseAsset') {
          $state.go('academy.roadmapCourse', {
            roadmapId: $stateParams.roadmapId,
            sectionId: $stateParams.sectionId,
            courseId: $stateParams.courseId,
          });
        }
      };
      $scope.assignToUsers = function () {
        var modalInstance = $modal.open({
          templateUrl: '/templates/academy/course-to-users.html',
          controller: 'AcademyCourseToUsersController',
          backdrop: 'static',
          resolve: {
            users: function () {
              return HttpService.get('/api/academy/users');
            },
            course: function () {
              return $scope.asset;
            },
          },
        });
      };
      $scope.markQuestionSuccess = function () {
        _.forEach($scope.asset.quiz.questions, function (question) {
          if ($scope.asset.quiz.passPercent === 0) {
            if (question.type === 'multiSelect' || question.type === 'singleSelect') {
              var checked = false;
              _.forEach(question.answers, function (answer) {
                if (answer.value && answer.checked) {
                  checked = true;
                }
              });
              if (checked) {
                question.$$answerIsCorrect = true;
                question.$$answerIsFailed = false;
              } else {
                question.$$answerIsCorrect = false;
                question.$$answerIsFailed = true;
              }
            } else {
              if (question.userAnswer) {
                question.$$answerIsCorrect = true;
                question.$$answerIsFailed = false;
              } else {
                question.$$answerIsCorrect = false;
                question.$$answerIsFailed = true;
              }
            }
          } else {
            var correctAnswerNumbers = 0;
            var userCorrectAnswers = 0;
            var userFailedAnswers = 0;
            _.forEach(question.answers, function (answer) {
              if (question.type === 'singleSelect' || question.type === 'multiSelect') {
                if (answer.checked && answer.correct) {
                  userCorrectAnswers++;
                  correctAnswerNumbers++;
                } else if (answer.checked && !answer.correct) {
                  userFailedAnswers++;
                } else if (!answer.checked && answer.correct) {
                  userFailedAnswers++;
                  correctAnswerNumbers++;
                }
              } else if (question.type === 'openText') {
                if (question.userAnswer == answer.correct) {
                  userCorrectAnswers++;
                  correctAnswerNumbers++;
                } else {
                  correctAnswerNumbers++;
                }
              } else {
                if (question.userAnswer == answer.value) {
                  userCorrectAnswers++;
                  correctAnswerNumbers++;
                } else {
                  correctAnswerNumbers++;
                }
              }
            });
            if (
              correctAnswerNumbers > 0 &&
              correctAnswerNumbers === userCorrectAnswers &&
              userFailedAnswers === 0
            ) {
              question.$$answerIsCorrect = true;
              question.$$answerIsFailed = false;
            } else {
              question.$$answerIsCorrect = false;
              question.$$answerIsFailed = true;
            }
            //console.log(question.$$answerIsCorrect, question.$$answerIsFailed);
          }
        });
      };

      $scope.saveQuizResults = function () {
        $scope.asset.$$quizSubmitted = true;
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.put(
          '/api/academy/courses/' +
            InputSanitizerService.sanitize($stateParams.courseId) +
            '/' +
            InputSanitizerService.sanitize($stateParams.sectionId) +
            '/' +
            InputSanitizerService.sanitize($scope.asset.id) +
            '/finish',
          { quiz: $scope.asset },
        ).then(
          function (userAcademyAssetResult) {
            $rootScope.$broadcast('dataLoadingFinished');
            $scope.quizResult.passed = userAcademyAssetResult.quizResult.passed;
            $scope.quizResult.notified = userAcademyAssetResult.quizResult.notified;
            $scope.asset.quiz.notified = $scope.quizResult.notified;
            // $scope.updateQuizStatus(results.score, results.maxScore, results.passPercent);
            $scope.markQuestionSuccess();
            if ($scope.quizResult.passed) {
              $scope.asset.quiz.passed = $scope.quizResult.passed;
              $scope.asset.finishedAt = userAcademyAssetResult.finishedAt;
            }
          },
          function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
          },
        );
      };
      $scope.closeQuiz = function () {
        if ($scope.asset.nextAsset && $scope.asset.finishedAt) {
          $scope.goAsset($scope.asset.nextAsset);
        } else {
          $scope.goCourse($scope.asset.Course);
        }
      };
      $scope.setAnswer = function (question, answer) {
        _.forEach(question.answers, function (o) {
          o.checked = o === answer;
        });
      };
      // $scope.updateQuizStatus = function (score, maxScore, passPercent) {
      //     if (passPercent === null) {
      //         passPercent = 0;
      //     }
      //     //console.log(score, maxScore, passPercent);
      //     if (score === 0) {
      //         $scope.asset.$$quizPassed = false;
      //         $scope.asset.$$quizFailed = true;
      //     } else if (score === maxScore && score > 0) {
      //         $scope.asset.$$quizFailed = false;
      //         $scope.asset.$$quizPassed = true;
      //     } else if ((score / maxScore) * 100 >= passPercent) {
      //         $scope.asset.$$quizFailed = false;
      //         $scope.asset.$$quizPassed = true;
      //     } else {
      //         $scope.asset.$$quizPassed = false;
      //         $scope.asset.$$quizFailed = true;
      //     }
      // };
      // $scope.isQuizSuccessful = function (asset) {
      //     if ($scope.quizzes[asset.id]) {
      //         var results = $scope.quizzes[asset.id].quizResult;
      //         //console.log(results);
      //         results.passingScore = Math.floor(results.maxScore * (results.passPercent / 100));
      //         return results.score >= results.passingScore;
      //     } else {
      //         return false;
      //     }
      // };
    },
  )

  .controller(
    'AcademyAssetCategoryController',
    function (
      $scope,
      $state,
      $stateParams,
      $http,
      $translate,
      _,
      AcademyService,
      AssetService,
      CurrentUser,
      category,
    ) {
      $scope.category = category;
      $scope.category.assetCount = _(category.SubCategories).flatMap('Assets').size();

      $scope.isDisabled = function (asset) {
        return (
          !CurrentUser.hasModule('vendor') &&
          !$scope.disableFilter &&
          asset.type === 'course' &&
          !asset.prerequisitesDone
        );
      };

      // $scope.goAsset = function (asset) {
      //     if (!$scope.isDisabled(asset)) {
      //         AssetService.goState(asset);
      //     }
      // };

      $scope.generatePrerequisiteTooltip = function (asset) {
        if (asset.prerequisites && !asset.prerequisitesDone) {
          return AssetService.generatePrerequisitesTooltip(asset);
        }
      };

      $scope.getAssetLink = AssetService.getAssetLink;
    },
  )

  .controller(
    'AcademyAssetCategoriesController',
    function (
      $scope,
      $state,
      $stateParams,
      $http,
      $translate,
      _,
      AcademyService,
      AssetService,
      CurrentUser,
      categories,
    ) {
      $scope.currentUser = CurrentUser.getUser();
      $scope.type = $stateParams.type;
      $scope.categoriesByVendors = [];

      // $scope.isDisabled = function (asset) {
      //     return !CurrentUser.hasModule('vendor') && asset.type === 'course' && !asset.prerequisiteDone;
      // };

      _.forEach(categories, function (category) {
        category.assetCount = _(category.SubCategories).flatMap('Assets').size();
        category.trainers = _(category.SubCategories)
          .flatMap('Assets')
          .flatMap('Trainer')
          .uniqBy('id')
          .value();

        var vendor = _.find($scope.categoriesByVendors, { id: category.VendorId || 0 });
        if (!vendor) {
          vendor = category.Vendor
            ? { id: category.Vendor.id, name: category.Vendor.name }
            : { id: 0 };
          vendor.categories = [];
          vendor.assetCount = 0;
          $scope.categoriesByVendors.push(vendor);
        }
        if (!vendor.categories) {
          vendor.categories = [];
        }
        vendor.assetCount += category.assetCount;
        vendor.categories.push(category);
      });
    },
  )

  .controller(
    'AcademyAssetSearchController',
    function (
      $scope,
      $state,
      $stateParams,
      $http,
      $translate,
      _,
      AcademyService,
      AssetService,
      CurrentUser,
      results,
    ) {
      // $scope.disableFilter = CurrentUser.hasModule('vendor');
      $scope.searchString = $stateParams.searchString;
      $scope.results = results;
      $scope.limit = 3;
      $scope.assetList = {};
      $scope.search = {};

      // $scope.goAsset = function (asset) {
      //     AssetService.goState(asset);
      // };

      $scope.getAssetLink = AssetService.getAssetLink;

      $scope.generateAssetType = function (asset) {
        if (!$scope.assetList[asset.type]) {
          $scope.assetList[asset.type] = {
            id: asset.type,
            type: asset.type,
            name: 'academy.menu.' + asset.type,
            limit: $scope.limit,
            children: [],
            count: 0,
          };
        }
        if ($scope.assetList[asset.type].children.length < $scope.assetList[asset.type].limit) {
          asset.typeIcon = AssetService.getAssetTypeIcon(asset);
          $scope.assetList[asset.type].children.push(asset);
        }
      };

      $scope.generateAssetList = function () {
        _.forEach($scope.assetList, function (type) {
          if (type.children.length) {
            type.children = [];
          }
          if (type.count) {
            type.count = 0;
          }
        });
        _.forEach($scope.results, function (asset) {
          $scope.generateAssetType(asset);
          $scope.assetList[asset.type].count++;
        });
      };

      $scope.showMore = function (type) {
        $scope.assetList[type].limit += 5;
        $scope.generateAssetList();
      };

      $scope.openAssetSearch = function () {
        $state.go('academy.searchResults', $scope.search);
      };

      $scope.generatePrerequisiteTooltip = function (asset) {
        if (!_.isEmpty(asset.prerequisites) && !asset.prerequisitesDone) {
          return AssetService.generatePrerequisitesTooltip(asset);
        }
        return '';
      };

      $scope.generateAssetLink = function (asset) {
        if (!$scope.isDisabled(asset)) {
          if (asset.type === 'roadmap') {
            return 'academy.roadmap({roadmapId: ' + asset.id + '})';
          } else {
            return 'academy.asset({assetId: ' + asset.id + '})';
          }
        }
        return '';
      };

      $scope.generateAssetList();
    },
  );
