angular
  .module('vcio-toolkit')

  .controller(
    'UserProfileController',
    function (
      $libphonenumber,
      $modal,
      $rootScope,
      $scope,
      $translate,
      _,
      EMAIL_REGEX,
      CurrentUser,
      DialogService,
      HttpService,
      UploadService,
      user,
      permissionGroupCategories,
      InputSanitizerService,
    ) {
      $scope.user = user || {};
      $scope.password = {};
      $scope.currentUser = CurrentUser.getUser();
      $scope.selectedCompany = undefined;
      $scope.logoUrl = '/images/users/' + $scope.user.id + '?' + new Date().getTime();
      $scope.permissionGroupCategories = _.flatMap(permissionGroupCategories, function (category) {
        return _.map(category.SubCategories, function (subcategory) {
          return _.extend(subcategory, { Category: _.omit(category, 'SubCategories') });
        });
      });
      $scope.user.itcqCompares = [
        { id: 1, name: 'Global' },
        { id: 2, name: 'Local' },
        { id: 3, name: 'Industry' },
      ];
      var currentUserDefaultItcqScore = _.get(
        $scope.currentUser.config,
        'defaultItcqCompare.' + InputSanitizerService.sanitize($scope.currentUser.Company.id),
      );
      $scope.user.defaultItcqCompare =
        _.find($scope.user.itcqCompares, function (score) {
          return score.name.toLowerCase() === currentUserDefaultItcqScore;
        }) || $scope.user.itcqCompares[0];

      $scope.user.DefaultCompany = _.find($scope.user.Companies, function (company) {
        return company.UserCompanyRole.default === true;
      });

      $scope.setDefaultCompany = function () {
        $scope.user.Companies.forEach(function (company) {
          company.UserCompanyRole.default =
            $scope.user.DefaultCompany && $scope.user.DefaultCompany.id === company.id;
        });
      };

      $scope.setDefaultItcqComapre = function () {
        CurrentUser.setConfigValue(
          'defaultItcqCompare.' + $scope.currentUser.Company.id,
          $scope.user.defaultItcqCompare.name.toLowerCase(),
        );
      };

      $scope.original = _.cloneDeep($scope.user);

      $scope.checkEmail = function (cb) {
        if ($scope.original.email !== $scope.user.email) {
          if (EMAIL_REGEX.test($scope.user.email)) {
            $scope.searching = true;
            HttpService.get('/api/admin/users/' + InputSanitizerService.sanitize($scope.user.email))
              .then(function (_user) {
                $scope.profileErrors = [];
                $scope.profileMessages = [];
                if (_user && _user.id !== $scope.user.id) {
                  $scope.profileErrors = ['error.userEdit.emailExists'];
                }
                $scope.searching = false;
                if (cb) {
                  cb();
                }
              })
              .catch(function (error) {
                $scope.searching = false;
                $scope.profileMessages = [];
                $scope.profileErrors = [error];
                if (cb) {
                  cb();
                }
              });
          } else {
            $scope.profileErrors = ['login.invalidEmail'];
          }
        } else {
          if (cb) {
            cb();
          }
        }
      };

      $scope.emailChanged = function () {
        $scope.checkEmail();
      };

      $scope.save = function (cb) {
        $scope.checkEmail(function () {
          var attributes = [
            'id',
            'firstName',
            'lastName',
            'phone',
            'position',
            'champion',
            'Companies',
            'roles',
            'permissionGroupCategories',
          ];
          if ($scope.original.email !== $scope.user.email && $scope.user.email) {
            attributes.push('email');
          }
          HttpService.put('/api/admin/users', { user: _.pick($scope.user, attributes) })
            .then(function (user) {
              var currentUser = CurrentUser.getUser();
              currentUser.firstName = user.firstName;
              currentUser.lastName = user.lastName;
              currentUser.email = user.email;
              currentUser.roles = user.roles;
              currentUser.permissionGroupCategories = user.permissionGroupCategories;
              CurrentUser.setUser(currentUser);
              cb();
            })
            .catch(function (error) {
              cb();
              $scope.profileErrors = [error];
            });
        });
      };

      $scope.openUserLogo = function () {
        UploadService.open('users', $scope.user).then(function () {
          $scope.logoUrl =
            '/images/users/' +
            InputSanitizerService.sanitize($scope.user.id) +
            '?' +
            new Date().getTime();
        });
      };

      $scope.permissionGroupCategoryGroupBy = function (subcategory) {
        return subcategory.Category.name;
      };

      $scope.changePassword = function () {
        if (!$scope.password.newPassword) {
          DialogService.error('error.password.missing');
        } else if ($scope.password.newPassword !== $scope.password.newPassword2) {
          DialogService.error('error.password.match');
        } else {
          $rootScope.$broadcast('dataLoadingStarted');
          HttpService.put('/api/password', {
            email: $scope.user.email,
            oldPassword: $scope.password.oldPassword,
            newPassword: $scope.password.newPassword,
            newPassword2: $scope.password.newPassword2,
          }).then(
            function () {
              $rootScope.$broadcast('dataLoadingFinished');
              DialogService.message('message.passwordChangedInternal');
              $scope.password.oldPassword = '';
              $scope.password.newPassword = '';
              $scope.password.newPassword2 = '';
            },
            function (error) {
              $rootScope.$broadcast('dataLoadingFinished');
              DialogService.error(error);
            },
          );
        }
      };

      $scope.TFASettings = function () {
        $modal.open({
          templateUrl: '/templates/admin/user-profile-tfa.html',
          controller: 'UserProfileTFAController',
          backdrop: 'static',
          resolve: {
            user: function () {
              return $scope.user;
            },
          },
        });
      };
      $scope.emailReports = {
        weekly: _.get(
          $scope.currentUser.config,
          'emailReports.' +
            InputSanitizerService.sanitize($scope.currentUser.Company.id) +
            '.weekly',
        ),
        monthly: _.get(
          $scope.currentUser.config,
          'emailReports.' +
            InputSanitizerService.sanitize($scope.currentUser.Company.id) +
            '.monthly',
        ),
      };
      // if (!_.get($scope.currentUser.config, 'emailReports.' + $scope.currentUser.Company.id)){
      //     $scope.currentUser.config.emailReports[$scope.currentUser.Company.id] = $scope.currentUser.config.emailReports[$scope.currentUser.Company.id] || {};
      // }
      $scope.changeWeeklyEmailReport = function () {
        $scope.emailReports.weekly = !$scope.emailReports.weekly;
        CurrentUser.setConfigValue(
          'emailReports.' + $scope.currentUser.Company.id + '.weekly',
          $scope.emailReports.weekly,
        );
      };

      $scope.changeMonthlyEmailReport = function () {
        $scope.emailReports.monthly = !$scope.emailReports.monthly;
        CurrentUser.setConfigValue(
          'emailReports.' + $scope.currentUser.Company.id + '.monthly',
          $scope.emailReports.monthly,
        );
      };
    },
  )

  .controller(
    'UserProfileTFAController',
    function (
      $libphonenumber,
      $modalInstance,
      $rootScope,
      $scope,
      $state,
      $q,
      _,
      DialogService,
      HttpService,
      user,
    ) {
      $scope.user = user;
      $scope.tfa = {};

      try {
        var parsedPhoneNumber = $libphonenumber($scope.user.phone);
        $scope.user.phoneCountryCode = parsedPhoneNumber.countryCallingCode;
        $scope.user.phoneNumber = parsedPhoneNumber.nationalNumber;
      } catch (e) {}

      $scope.close = function () {
        $modalInstance.dismiss();
      };

      $scope.$watch('user.phoneCountryCode', function () {
        $scope.tfa.tfaUserId = undefined;
      });

      $scope.$watch('user.phoneNumber', function () {
        $scope.tfa.tfaUserId = undefined;
      });

      $scope.registerTFA = function () {
        try {
          $rootScope.$broadcast('dataLoadingStarted');
          $scope.user.phone = '+' + $scope.user.phoneCountryCode + $scope.user.phoneNumber;
          HttpService.put('/api/admin/users', {
            user: { id: $scope.user.id, phone: $scope.user.phone },
          }).then(function () {
            HttpService.post('/api/auth/tfa/enable', {
              email: $scope.user.email,
              phone: $scope.user.phoneNumber,
              countryCode: $scope.user.phoneCountryCode,
            })
              .then(function (result) {
                $scope.tfa.tfaUserId = result.id;
                $rootScope.$broadcast('dataLoadingFinished');
                DialogService.message('label.userProfile.tfa.enableRegisterSuccess');
              })
              .catch(function (error) {
                $rootScope.$broadcast('dataLoadingFinished');
                DialogService.error(error);
              });
          });
        } catch (e) {
          $rootScope.$broadcast('dataLoadingFinished');
          $scope.errors = [e.message || e];
        }
      };

      $scope.enableTFA = function () {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.post('/api/auth/tfa/verify', {
          tfaToken: $scope.tfa.token,
          tfaUserId: $scope.tfa.tfaUserId,
        })
          .then(function () {
            HttpService.put('/api/admin/users', {
              user: { id: $scope.user.id, tfaUserId: $scope.tfa.tfaUserId },
            }).then(function () {
              $scope.user.tfaUserId = $scope.tfa.tfaUserId;
              $rootScope.$broadcast('dataLoadingFinished');
              DialogService.message({
                code: 'label.userProfile.tfa.enableSuccess',
                translateValues: { phone: $scope.user.phone },
              });
              $modalInstance.close();
            });
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
          });
      };

      $scope.disableTFA = function () {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.post('/api/auth/tfa/disable', { tfaToken: $scope.tfa.token })
          .then(function () {
            $rootScope.$broadcast('dataLoadingFinished');
            $scope.user.tfaUserId = undefined;
            DialogService.message('label.userProfile.tfa.disableSuccess');
            $modalInstance.dismiss();
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
          });
      };

      $scope.sendTFA = function (enableOK) {
        $rootScope.$broadcast('dataLoadingStarted');
        HttpService.post('/api/auth/tfa/send')
          .then(function () {
            $scope.tfa.smsSent = true;
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.message('label.userProfile.tfa.smsSent');
          })
          .catch(function (error) {
            $rootScope.$broadcast('dataLoadingFinished');
            DialogService.error(error);
          });
      };

      if ($scope.user.tfaUserId) {
        HttpService.get('/api/auth/tfa/qrcode')
          .then(function (result) {
            $scope.tfa.qrURL = result.url + '?' + Date.now();
          })
          .catch(function (error) {
            DialogService.error(error);
          });
      }

      $scope.refreshTFAQRCode = function () {
        HttpService.get('/api/auth/tfa/qrcode?force=true')
          .then(function (result) {
            $scope.tfa.qrURL = result.url + '?' + Date.now();
          })
          .catch(function (error) {
            DialogService.error(error);
          });
      };
    },
  )

  .controller(
    'UserEditController',
    function (
      $scope,
      $rootScope,
      $state,
      $modal,
      $modalInstance,
      _,
      CurrentUser,
      EMAIL_REGEX,
      HttpService,
      UploadService,
      user,
      company,
      InputSanitizerService,
    ) {
      $scope.currentUser = CurrentUser.getUser();
      $scope.errors = [];
      $scope.selectedCompany = company;
      $scope.user = user || {};
      $scope.user.DefaultCompany = _.find($scope.user.Companies, function (company) {
        return company.UserCompanyRole.default === true;
      });

      $scope.original = _.cloneDeep($scope.user);
      if ($scope.user.id) {
        $scope.logoUrl = '/images/users/' + $scope.user.id + '?' + new Date().getTime();
      }

      $scope.checkEmail = function (cb) {
        if (EMAIL_REGEX.test($scope.user.email)) {
          $scope.searching = true;
          HttpService.get('/api/admin/users/' + InputSanitizerService.sanitize($scope.user.email))
            .then(function (_user) {
              $scope.errors = [];
              $scope.messages = [];
              if (_user) {
                if ($scope.selectedCompany) {
                  $scope.user.firstName = _user.firstName;
                  $scope.user.lastName = _user.lastName;
                  $scope.user.phone = _user.phone;
                  $scope.user.position = _user.position;
                  $scope.user.id = _user.id;
                  $scope.messages = ['message.userExists'];
                  // $scope.addSelectedCompany();
                  $scope.existingUserId = _user.id;
                } else {
                  if (_user.id !== $scope.user.id) {
                    $scope.errors = ['error.userEdit.emailExists'];
                    $scope.existingUserId = _user.id;
                  }
                }
              } else {
                // $scope.user.id = _user.id;
                $scope.existingUserId = undefined;
              }
              $scope.searching = false;
              if (cb) {
                cb();
              }
            })
            .catch(function (error) {
              $scope.searching = false;
              $scope.messages = [];
              // if (error.status != 404) {
              $scope.errors = [error];
              if (cb) {
                cb();
              }
            });
        }
      };

      $scope.emailChanged = function () {
        $scope.checkEmail();
      };

      $scope.openUserLogo = function () {
        var user = $scope.user;
        var userId = InputSanitizerService.sanitize(user.id);
        user.uploadUrl = '/api/images/users/' + userId + '?userid=' + userId;
        UploadService.open('users', user).then(function () {
          $scope.logoUrl = '/images/users/' + userId + '?' + new Date().getTime();
        });
      };

      $scope.ok = function (cb) {
        $scope.checkEmail(function () {
          if ($scope.existingUserId && $scope.selectedCompany) {
            HttpService.post(
              '/api/admin/users/' +
                $scope.existingUserId +
                '/companies/' +
                $scope.selectedCompany.id,
            )
              .then(function () {
                $modalInstance.close();
                cb();
              })
              .catch(function (error) {
                $scope.errors.push(error);
                cb();
              });
          } else if (!$scope.existingUserId) {
            var attributes = [
              'id',
              'firstName',
              'lastName',
              'phone',
              'position',
              'email',
              'champion',
              'Companies',
              'roles',
            ];
            if ($scope.original.email !== $scope.user.email && $scope.user.email) {
              attributes.push('email');
            }
            if (!$scope.user.id) {
              $scope.user.Companies = [
                _.extend($scope.selectedCompany, { UserCompanyRole: { AuthRoleCode: 'manager' } }),
              ];
            }
            ($scope.user.id ? HttpService.put : HttpService.post)('/api/admin/users', {
              user: _.pick($scope.user, attributes),
            })
              .then(function (user) {
                $modalInstance.close(user);
              })
              .catch(function (error) {
                cb();
                $scope.errors.push(error);
              });
          }
        });
      };

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };
    },
  )

  // .controller('UserCompaniesEditController', function ($scope, $state, $modal, $modalInstance, $http, _, user, company, CurrentUser, EMAIL_REGEX, HttpService) {
  //     $scope.user = user || {};
  //     $scope.errors = [];
  //     $scope.currentUser = CurrentUser.getUser();
  //     $scope.showRoles = $scope.currentUser.hasModule('system');
  //     $scope.selectedCompany = company;
  //     $scope.addSelectedCompany = function () {
  //         if (!user.Companies) {
  //             user.Companies = [];
  //         }
  //         if (company && !_.find(user.Companies, function (_company) {
  //             return _company.id === company.id
  //         })) {
  //             user.Companies.unshift(_.extend($scope.selectedCompany, {UserCompanyRole: {AuthRoleCode: 'manager'}}));
  //         }
  //     };
  //     $scope.selected = {
  //         company: _.find($scope.user.Companies, function (company) {
  //             return company.UserCompanyRole.default;
  //         })
  //     };
  //     $scope.addSelectedCompany();
  //
  //     $scope.roles = [];
  //     $http.get('/api/lists/role')
  //         .success(function (roles) {
  //             $scope.roles = roles;
  //         });
  //
  //     // $scope.setDefaultCompany = function () {
  //     //     $scope.user.Companies.forEach(function (company) {
  //     //         company.UserCompanyRole.default = $scope.selected.company && $scope.selected.company.id == company.id;
  //     //     })
  //     // }
  //
  //     $scope.ok = function (cb) {
  //         var attributes = ['id', 'firstName', 'lastName', 'phone', 'position', 'email', 'champion'];
  //         if ($scope.original.email !== $scope.user.email) {
  //             attributes.push('email');
  //         }
  //         ($scope.user.id ? HttpService.put : HttpService.post)('/api/admin/users', {user: _.pick($scope.user, 'Companies')})
  //             .then(function (result) {
  //                 $modalInstance.close(result);
  //             })
  //             .catch(function (error) {
  //                 cb();
  //                 $scope.errors.push(error);
  //             })
  //     };
  //
  //     $scope.cancel = function () {
  //         $modalInstance.dismiss();
  //     };
  //
  //     // $scope.openChangePassword = function (user) {
  //     //     $scope.selectedUser = user;
  //     //
  //     //     var modalInstance = $modal.open({
  //     //         templateUrl: '/templates/admin/password-change.html',
  //     //         controller: 'ChangePasswordController',
  //     //         backdrop: 'static',
  //     //         resolve: {
  //     //             user: function () {
  //     //                 return user;
  //     //             }
  //     //         }
  //     //     });
  //     //
  //     //     modalInstance.result.then(function (message) {
  //     //         $scope.messages = [message]
  //     //
  //     //     }, function () {
  //     //
  //     //     });
  //     // };
  //     //
  //     // $scope.removeCompany = function (company) {
  //     //     _.remove($scope.user.Companies, function (_company) {
  //     //         return _company.id == company.id;
  //     //     })
  //     // }
  // })

  .controller(
    'UserRecommendationsController',
    function ($scope, $modalInstance, _, CurrentUser, DialogService, HttpService, userRoles) {
      $scope.userRoles = userRoles;
      $scope.currentUser = CurrentUser.getUser();
      $scope.selected = {};

      $scope.doneDisabled = function () {
        return _($scope.selected).pickBy().keys().isEmpty();
      };

      $scope.done = function () {
        HttpService.put('/api/admin/users', {
          user: {
            id: $scope.currentUser.id,
            roles: _($scope.selected).pickBy().keys().value(),
          },
        })
          .then(function () {
            $modalInstance.close();
          })
          .catch(function (error) {
            $modalInstance.close();
            DialogService.error(error);
          });
      };
    },
  );
