angular
  .module('vcio-toolkit')

  .controller(
    'CompanyAdminController',
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $modal,
      $http,
      $translate,
      _,
      CurrentUser,
      CompanyService,
      DialogService,
      HttpService,
      InputSanitizerService,
    ) {
      $scope.filter = { name: '', modules: [], plan: false };
      $scope.companies = [];
      $scope.owners = [];
      $scope.modules = [];
      $http.get('/api/lists/module').success(function (result) {
        $scope.modules = _.map(result, function (value) {
          return { id: value, name: value };
        });
      });

      $scope.refresh = function () {
        $rootScope.$broadcast('dataLoadingStarted');
        $http
          .get(
            '/api/search/companies' +
              ($scope.filter.name
                ? '?name=' + InputSanitizerService.sanitize($scope.filter.name)
                : ''),
          )
          .success(function (companies) {
            $scope.companies = companies;
            $rootScope.$broadcast('dataLoadingFinished');
          });
      };

      $scope.companyFilter = function (company) {
        return (
          ($scope.filter.modules.length === 0 ||
            _.intersection(company.modules, $scope.filter.modules).length ==
              $scope.filter.modules.length) &&
          (!$scope.filter.plan || company.subscription)
        );
      };

      $scope.openEditCompany = function (company) {
        if (!company) {
          company = {};
        }
        $scope.selectedCompany = company;
        CompanyService.openEditCompany(
          company,
          function (company) {
            //refresh modified data
            angular.copy(company, $scope.selectedCompany);
            $scope.filter.name = company.name;
            $scope.refresh();
          },
          function () {},
        );
      };

      $scope.openAddUser = function (company) {
        var modalInstance = $modal.open({
          templateUrl: '/templates/admin/user-edit.html',
          controller: 'UserEditController',
          backdrop: 'static',
          resolve: {
            user: function () {
              return undefined;
            },
            company: function () {
              return company;
            },
          },
        });

        modalInstance.result.then(
          function (user) {},
          function () {},
        );
      };

      $scope.openEditModules = function (company) {
        $scope.selectedCompany = company;
        var modalInstance = $modal.open({
          templateUrl: '/templates/admin/company-modules.html',
          controller: 'CompanyModulesController',
          backdrop: 'static',
          resolve: {
            company: function () {
              return company;
            },
          },
        });

        modalInstance.result.then(
          function (modules) {
            $scope.selectedCompany.modules = modules;
          },
          function () {},
        );
      };

      // $scope.openEditOffers = function (company) {
      //     $scope.selectedCompany = company;
      //     var modalInstance = $modal.open({
      //         templateUrl: '/templates/admin/company-payment-offers.html',
      //         controller: 'CompanyPaymentOffersController',
      //         backdrop: 'static',
      //         resolve: {
      //             company: function () {
      //                 return company;
      //             }
      //         }
      //     });
      //
      //     modalInstance.result.then(function (modules) {
      //         $scope.selectedCompany.modules = modules;
      //     }, function () {
      //     });
      // };

      $scope.canDelete = function (company) {
        return CurrentUser.can('modifyAnyCompany');
      };

      $scope.deleteCompany = function (company) {
        $http
          .delete('/api/companies/' + InputSanitizerService.sanitize(company.id))
          .success(function () {
            _.remove($scope.companies, function (item) {
              return item.id == company.id;
            });
          });
      };

      $scope.openUsers = function (company) {
        $state.go('admin.companyUsers', { companyId: company.id });
      };

      $scope.showEvents = function (company) {
        $state.go('admin.companyEventsReport', { companyId: company.id });
      };

      $scope.updateSubscription = function (company) {
        HttpService.post(
          '/api/payment/updateSubscription/' +
            InputSanitizerService.sanitize(company.stripeCustomerId),
        )
          .then(function (company) {
            company.subscription = company.subscription;
            DialogService.message('message.success');
          })
          .catch(function (error) {
            DialogService.message(error);
          });
      };

      $scope.identifyUsersToAnalytics = function (company) {
        HttpService.get('/api/companies/' + InputSanitizerService.sanitize(company.id) + '/users')
          .then(function (users) {
            HttpService.post('/api/system/tasks/identify', { userIds: _.map(users, 'id') }).then(
              function () {
                DialogService.message('label.companies.identifyUsersToAnalytics.success');
              },
            );
          })
          .catch(function (error) {
            DialogService.message(error);
          });
      };

      $scope.openSendEmail = function () {
        var modalInstance = $modal.open({
          templateUrl: '/templates/admin/email-send.html',
          controller: 'CompaniesEmailController',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            companies: function () {
              return _.filter($scope.companies, $scope.companyFilter);
            },
          },
        });

        modalInstance.result.then(
          function (user) {},
          function () {},
        );
      };

      $scope.editStripeCommissionPercentage = function (company) {
        var modalInstance = $modal.open({
          template:
            '<modal-form form-title="label.payment.stripe.commissionPercentage" onok="ok" oncancel="cancel">' +
            '<input-text type="number" model="company.settings.stripe.$$commissionPercentage" icon="percent"></input-text>' +
            '<h6 translate="label.payment.stripe.commissionPercentageDefault" translate-values="{default: 20}"></h6>' +
            '</modal-form>',
          controller: function ($modalInstance, $scope, HttpService, company) {
            $scope.company = company;
            $scope.company.settings.stripe = $scope.company.settings.stripe || {};
            $scope.company.settings.stripe.$$commissionPercentage =
              $scope.company.settings.stripe.commissionPercentage;
            $scope.defaultCommissionPercentage = serverConfigSettings.stripeCommissionPercentage;

            $scope.ok = function (cb) {
              HttpService.put(
                '/api/companies/' +
                  InputSanitizerService.sanitize(company.id) +
                  '/settings/stripe.commissionPercentage',
                { value: $scope.company.settings.stripe.$$commissionPercentage },
              )
                .then(function () {
                  $scope.company.settings.stripe.commissionPercentage =
                    $scope.company.settings.stripe.$$commissionPercentage;
                  $modalInstance.close();
                })
                .catch(function (error) {
                  cb();
                  $scope.errors = [error];
                });
            };

            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
          },
          backdrop: 'static',
          resolve: {
            company: function () {
              return company;
            },
          },
        });
      };

      $scope.companyOperations = [
        { operation: $scope.openEditCompany, label: 'button.edit', icon: 'edit' },
        { operation: $scope.openUsers, label: 'button.users', icon: 'users' },
        { operation: $scope.openAddUser, label: 'button.addUser', icon: 'user-plus' },
        {
          operation: $scope.deleteCompany,
          label: 'button.delete',
          icon: 'times-circle',
          confirmation: true,
          condition: $scope.canDelete,
        },
        { operation: $scope.openEditModules, label: 'label.modules', icon: 'cubes' },
        { operation: $scope.showEvents, label: 'label.companies.showEvents', icon: 'list' },
        {
          operation: $scope.updateSubscription,
          label: 'label.companies.updateSubscription',
          icon: 'refresh',
        },
        {
          operation: $scope.identifyUsersToAnalytics,
          label: 'label.companies.identifyUsersToAnalytics',
          icon: 'cloud-upload',
        },
        {
          operation: $scope.editStripeCommissionPercentage,
          label: 'label.payment.stripe.commissionPercentage',
          icon: 'percent',
        },
      ];
    },
  )

  .controller(
    'CompaniesEmailController',
    function (
      $scope,
      $rootScope,
      $modalInstance,
      $sce,
      _,
      CurrentUser,
      DialogService,
      HttpService,
      companies,
      InputSanitizerService,
    ) {
      $scope.email = {};
      $scope.select = { allStatus: true, tab: 'companies' };
      $scope.companies = companies;
      $scope.user = CurrentUser.getUser();

      $scope.preview = function () {
        HttpService.post('/email/sample', { body: $scope.email.body }).then(function (value) {
          // firstName: user.firstName,
          //     lastName: user.lastName,
          //     email: user.email,
          //     company: user.Company.name
          value = value
            .replace(/%recipient.firstName%/g, $scope.user.firstName)
            .replace(/%recipient.lastName%/g, $scope.user.lastName)
            .replace(/%recipient.email%/g, $scope.user.email)
            .replace(/%recipient.company%/g, $scope.user.Company.name);
          var doc = document.getElementById('sample-iframe').contentWindow.document;
          doc.open();
          doc.write(InputSanitizerService.sanitize(value));
          doc.close();
        });
      };

      // $scope.buttons = [{label: 'button.preview', click: $scope.preview, color: 'primary'}]

      $scope.$watch('select.tab', function () {
        if ($scope.select.tab == 'preview') {
          $scope.preview();
        }
      });

      $scope.send = function (cb) {
        var companies = _.filter($scope.companies, { $$selected: true });
        if (companies) {
          HttpService.post('/api/email/send', {
            email: $scope.email,
            companyIds: _.map(companies, 'id'),
          })
            .then(function (value) {
              cb();
              DialogService.message('message.success');
            })
            .catch(function (error) {
              cb();
              $scope.errors = [error];
              // DialogService.error(error);
            });
        }
      };
      $scope.close = function () {
        $modalInstance.dismiss();
      };

      $scope.$watch('select.allStatus', function () {
        _.forEach($scope.companies, function (company) {
          company.$$selected = $scope.select.allStatus;
        });
      });
    },
  )

  .controller('CompanyModulesController', function ($scope, $http, $modalInstance, _, company) {
    $scope.modules = [];
    $scope.company = company;
    $scope.companyModules = {};

    $http.get('/api/lists/module').success(function (result) {
      $scope.modules = result;
      $scope.modules.forEach(function (module) {
        $scope.companyModules[module] = _.includes($scope.company.modules, module) ? true : false;
      });
    });

    $scope.save = function (cb) {
      var modules = [];
      for (var module in $scope.companyModules) {
        if ($scope.companyModules.hasOwnProperty(module) && $scope.companyModules[module]) {
          modules.push(module);
        }
      }

      $http
        .post('/api/companies/' + $scope.company.id + '/modules', {
          modules: modules,
        })
        .success(function (company) {
          $modalInstance.close(company.modules);
        })
        .error(function (error) {
          $scope.errors = [error];
          cb();
        });
    };

    $scope.cancel = function () {
      $modalInstance.dismiss();
    };
  })

  .controller(
    'UserTimelineController',
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $modal,
      $http,
      $translate,
      _,
      userId,
      InputSanitizerService,
    ) {
      $scope.messageLimit = 25;
      $scope.events = [];
      $scope.userId = userId;

      $http.get('/api/lists/events').success(function (events) {
        $scope.events = events;
        $scope.events.unshift('');
      });

      $scope.downloadEvents = function () {
        var eventList = $scope.events.join('%0A');
        var a = document.createElement('a');
        a.href = 'data:attachment/csv,' + InputSanitizerService.sanitize(eventList);
        a.target = '_blank';
        a.download = 'events.txt';
        document.body.appendChild(a);
        a.click();
      };

      $scope.refresh = function () {
        $http
          .get(
            '/api/admin/users/' +
              $scope.userId +
              '/timeline?limit=' +
              $scope.messageLimit +
              ($scope.filter.event ? '&event=' + $scope.filter.event : ''),
          )
          .success(function (timeline) {
            $scope.selectedUser.timeline = timeline;
          });
      };

      $scope.getMoreTimeline = function () {
        $http
          .get(
            '/api/admin/users/' +
              $scope.userId +
              '/timeline?limit=' +
              $scope.messageLimit +
              '&offset=' +
              $scope.selectedUser.timeline.length +
              ($scope.filter.event ? '&event=' + $scope.filter.event : ''),
          )
          .success(function (timeline) {
            timeline.forEach(function (event) {
              $scope.selectedUser.timeline.push(event);
            });
          });
      };

      $scope.getEventParam = function (event) {
        var result = '';
        var i = 0;
        for (var key in event.args) {
          if (event.args.hasOwnProperty(key)) {
            result += (i > 0 ? ', ' : '') + key + ': ' + event.args[key];
          }
          i++;
        }
        return result;
      };

      $scope.$watch('filter.event', function () {
        if ($scope.userId) {
          $scope.refresh();
        }
      });

      $scope.$on('userSelected', function (event, args) {
        $scope.userId = args.userId;
        $scope.refresh();
      });
    },
  )

  .controller(
    'UserAdminController',
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $modal,
      $http,
      $translate,
      $location,
      _,
      DialogService,
      HttpService,
    ) {
      $scope.filter = { name: '' };
      $scope.users = [];
      $scope.companies = [];
      $scope.companiesExpanded = false;
      $scope.timelineExpanded = true;
      $scope.filter.companyId = $stateParams.companyId;

      $scope.refresh = function () {
        $rootScope.$broadcast('dataLoadingStarted');
        $http
          .get(
            '/api/admin/users?all=true&disabled=' +
              $scope.filter.disabled +
              ($scope.filter.companyId ? '&companyId=' + $scope.filter.companyId : '') +
              '&search=' +
              $scope.filter.name,
          )
          .success(function (users) {
            $scope.users = users;
            $rootScope.$broadcast('dataLoadingFinished');
          });
      };

      if ($scope.filter.companyId) {
        $scope.refresh();
      }

      $scope.clickCompanies = function () {
        $scope.companiesExpanded = !$scope.companiesExpanded;
      };

      $scope.clickTimeline = function () {
        $scope.timelineExpanded = !$scope.timelineExpanded;
      };

      $scope.userNameFilter = function (user) {
        return (
          !$scope.filter.name ||
          _.find(user.Companies, function (company) {
            return (
              company.name &&
              company.name.toLowerCase().indexOf($scope.filter.name.toLowerCase()) > -1
            );
          }) ||
          (user.firstName + user.lastName + user.email)
            .toLowerCase()
            .indexOf($scope.filter.name.toLowerCase()) > -1
        );
      };

      $scope.selectUser = function (user) {
        if ($scope.selectedUser == user) {
          $scope.selectedUser = undefined;
        } else {
          $scope.selectedUser = user;
          //$scope.getUserTimeline();
          $scope.$broadcast('userSelected', { userId: user.id });
        }
      };

      $scope.openEditUser = function (user) {
        if (!user) {
          user = {};
        }
        $scope.selectedUser = user;

        var modalInstance = $modal.open({
          templateUrl: '/templates/admin/user-edit.html',
          controller: 'UserEditController',
          backdrop: 'static',
          resolve: {
            user: function () {
              return angular.copy(user);
            },
            company: function () {
              return $scope.selectedCompany;
            },
          },
        });

        modalInstance.result.then(
          function (user) {
            //refresh modified data
            if ($scope.selectedUser.id == user.id) {
              angular.copy(user, $scope.selectedUser);
            } else {
              var _user = _.find($scope.users, { id: user.id });
              if (_user) {
                angular.copy(user, _user);
              } else {
                $scope.users.push(user);
              }
            }
          },
          function () {},
        );
      };

      $scope.openChangePassword = function (user) {
        $scope.selectedUser = user;

        var modalInstance = $modal.open({
          templateUrl: '/templates/admin/password-change.html',
          controller: function ($scope, $modalInstance, CurrentUser, HttpService, user) {
            $scope.password = {};
            $scope.user = user;

            $scope.ok = function (enableOk) {
              HttpService.put('/api/password', {
                email: $scope.user.email,
                oldPassword: $scope.password.oldPassword,
                newPassword: $scope.password.newPassword,
                newPassword2: $scope.password.newPassword2,
              }).then(
                function () {
                  $scope.messages = ['message.passwordChangedInternal'];
                  if (enableOk) {
                    enableOk();
                  }
                  $scope.ok = null;
                  $scope.labelCancel = 'button.close';
                },
                function (error) {
                  $scope.errors = [error];
                  if (enableOk) {
                    enableOk();
                  }
                },
              );
            };

            $scope.close = function () {
              $modalInstance.dismiss('cancel');
            };
          },
          backdrop: 'static',
          resolve: {
            user: function () {
              return user;
            },
          },
        });
      };

      $scope.canShowSignupLink = function (user) {
        return user.signupTicket;
      };

      // $scope.resendSignupEmail = function (user) {
      //     $http.put('/api/password/reset', {email: user.email})
      //         .success(function () {
      //             $scope.messages = ['success'];
      //         }).error(function (error) {
      //         $scope.errors = [error];
      //     })
      // };

      $scope.showLink = function (user, type) {
        var modalInstance = $modal.open({
          template:
            '<modal-form header-class="bg-success" onok="ok" oncancel="cancel"><p translate="label.sendSignupLinkText"></p><textarea class="form-control code" rows="4">{{_message}}</textarea></modal-form>',
          backdrop: 'static',
          size: 'lg',
          controller: function ($scope, $modalInstance) {
            $scope.ok = function () {
              $modalInstance.close(true);
            };
            if (type == 'signup') {
              $scope._message = 'https://' + $location.host() + '/signup/' + user.signupTicket;
            } else {
              $scope._message =
                'https://' + $location.host() + '/password/forgot/' + user.signupTicket;
            }
          },
        });

        modalInstance.result.then(
          function () {},
          function () {},
        );
      };

      $scope.showPasswordLink = function (user) {
        $scope.showLink(user, 'password');
      };

      $scope.showSignupLink = function (user) {
        $scope.showLink(user, 'signup');
      };

      $scope.userOperations = [
        {
          operation: $scope.openEditUser,
          label: 'button.edit',
          icon: 'edit',
        },
        {
          operation: $scope.openChangePassword,
          label: 'button.changePassword',
          icon: 'key',
        },
        {
          operation: $scope.showSignupLink,
          label: 'button.showSignupLink',
          icon: 'link',
          condition: $scope.canShowSignupLink,
        },
        {
          operation: $scope.showPasswordLink,
          label: 'button.showPasswordLink',
          icon: 'link',
          condition: $scope.canShowSignupLink,
        },
      ];

      $scope.deleteRole = function (company) {
        HttpService.delete(
          '/api/admin/users/' + $scope.selectedUser.id + '/companies/' + company.id,
        )
          .then(function () {
            _.remove($scope.selectedUser.Companies, { id: company.id });
          })
          .catch(function (error) {
            DialogService.message(error);
          });
      };

      $scope.changeWeeklyEmailReport = function (company) {
        // if (!_.get($scope.selectedUser.config, 'emailReports')){
        //     CurrentUser.setConfigValue('emailReports', {})
        // }
        // CurrentUser.setConfigValue('emailReports.' + $scope.currentUser.Company.id + '.weekly', $scope.emailReports.weekly);
        var name = 'emailReports.' + company.id + '.weekly';
        var value = !_.get($scope.selectedUser.config, name);
        $scope.selectedUser.config = $scope.selectedUser.config || {};
        _.set($scope.selectedUser.config, name, value);
        HttpService.put('/api/admin/users/' + $scope.selectedUser.id + '/config/' + name, {
          value: value,
        });
      };

      $scope.disableTfa = function (user) {
        HttpService.put('/api/admin/users', {
          user: { id: user.id, tfaUserId: null, tfaQrCodeUrl: null },
        })
          .then(function () {
            user.tfaUserId = undefined;
            user.tfaQrCodeUrl = undefined;
          })
          .catch(function (error) {
            DialogService.message(error);
          });
      };
    },
  )

  .controller(
    'ColleaguesController',
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $modal,
      $http,
      $translate,
      $location,
      _,
      CurrentUser,
      DialogService,
      company,
    ) {
      $scope.users = [];
      $scope.company = company;
      $scope.newEmail = { value: '' };

      $scope.refresh = function () {
        $http
          .get('/api/admin/users?disabled=true&companyId=' + $scope.company.id)
          .success(function (users) {
            $scope.users = users;
          });
      };

      $scope.refresh();

      $scope.addTeamMember = function () {
        var modalInstance = $modal.open({
          templateUrl: '/templates/admin/colleagues-add.html',
          controller: 'AddColleagueController',
          backdrop: 'static',
          resolve: {
            user: function () {
              return {};
            },
            company: function () {
              return $scope.company;
            },
          },
        });

        modalInstance.result.then(
          function (user) {
            $scope.refresh();
          },
          function () {},
        );
      };

      $scope.deleteTeamMember = function (user) {
        $http
          .delete('/api/teammembers/' + user.id)
          .success(function () {
            DialogService.message('message.teamMemberDeleted');
            $scope.refresh();
          })
          .error(function (error) {
            DialogService.message(error);
          });
      };

      $scope.userOperations = [
        { operation: $scope.deleteTeamMember, label: 'button.delete', icon: 'times' },
      ];
    },
  )

  .controller(
    'AddColleagueController',
    function (
      $scope,
      $state,
      $modal,
      $modalInstance,
      $http,
      _,
      user,
      company,
      CurrentUser,
      EMAIL_REGEX,
      DialogService,
    ) {
      $scope.user = {};
      $scope.errors = [];
      $scope.company = company;

      $scope.getUser = function () {
        if (!$scope.user.id && $scope.user.email && EMAIL_REGEX.test($scope.user.email)) {
          $http
            .get('/api/admin/users/' + $scope.user.email)
            .success(function (_user) {
              if (_user) {
                $scope.user.id = _user.id;
                $scope.user.firstName = _user.firstName;
                $scope.user.lastName = _user.lastName;
                $scope.messages = ['message.userExists'];
              }
            })
            .error(function (error) {
              $scope.messages = [];
            });
        }
      };

      $scope.$watch('user.email', function (o) {
        $scope.getUser();
      });

      $scope.ok = function (cb) {
        $scope.errors = [];
        var emailParts = $scope.user.email.split('@');
        if (emailParts.length != 2) {
          $scope.errors.push({ name: 'InvalidEmail', code: 'login.invalidEmail' });
          cb();
          // } else if(emailParts[1] != $scope.company.domain) {
          //     $scope.errors.push({'name': 'InvalidDomain', 'code': 'error.company.email', translateValues: {'domain':$scope.company.domain}});
          //     cb();
        } else {
          $http
            .post('/api/teammembers', { user: $scope.user })
            .success(function (result) {
              $modalInstance.close(result);
              DialogService.message('message.teamMemberCreated');
              cb();
            })
            .error(function (error) {
              cb();
              $scope.errors.push(error);
              console.error(error);
            });
        }
      };

      $scope.cancel = function () {
        $modalInstance.dismiss();
      };
    },
  );

// .controller('CompanyPaymentOffersController', function ($scope, $state, $http, $modal, $modalInstance, $location, _, company) {
//
//     $scope.company = company;
//
//     $scope.refresh = function () {
//         $http.get('/api/companies/' + $scope.company.id + '/payment/offers/')
//             .success(function (offers) {
//                 $scope.offers = offers;
//             })
//     };
//
//     $scope.delete = function (offer) {
//         $http.delete('/api/companies/' + $scope.company.id + '/payment/offers/' + offer.id)
//             .success(function () {
//                 _.remove($scope.offers, {id: offer.id});
//             })
//     };
//
//     $scope.edit = function (offer) {
//         var modalInstance = $modal.open({
//             templateUrl: '/templates/admin/company-payment-offer-edit.html',
//             controller: 'CompanyPaymentOfferEditController',
//             backdrop: 'static',
//             resolve: {
//                 offer: function () {
//                     return angular.copy(offer)
//                 },
//                 company: function () {
//                     return $scope.company;
//                 }
//             }
//         });
//
//         modalInstance.result.then(function (offer) {
//             $scope.refresh();
//         }, function () {
//         });
//     };
//
//     $scope.share = function (offer) {
//         var modalInstance = $modal.open({
//             template: '<modal-form form-title="label.sendOfferLink" header-class="bg-success" onok="ok" oncancel="cancel"><textarea class="form-control code" rows="4">{{_message}}</textarea></modal-form>',
//             backdrop: 'static',
//             size: 'lg',
//             controller: function ($scope, $modalInstance) {
//                 $scope.ok = function () {
//                     $modalInstance.close(true);
//                 };
//                 $scope._message = 'https://' + $location.host() + '/#/mycompany/payment?offer=' + offer.id;
//             }
//         });
//
//         modalInstance.result.then(function () {
//         }, function () {
//         });
//     };
//
//     $scope.offerOperations = [
//         {operation: $scope.edit, label: 'button.edit', icon: 'edit'},
//         {operation: $scope.delete, label: 'button.delete', icon: 'minus-square-o', confirmation: true},
//         {operation: $scope.share, label: 'button.share', icon: 'share'}
//     ];
//
//     $scope.close = function () {
//         $modalInstance.dismiss();
//     };
//
//     $scope.refresh();
// })
//
// .controller('CompanyPaymentOfferEditController', function ($scope, $state, $http, $modal, $location, $modalInstance, _, company, offer) {
//     $scope.offer = offer || {};
//     $scope.company = company;
//     $scope.offerTypes = ['immediate', 'nextinvoice'];
//
//     $scope.cancel = function () {
//         $modalInstance.dismiss();
//     };
//
//     $scope.save = function (cb) {
//         if ($scope.offer.id) {
//             $http.put('/api/companies/' + $scope.company.id + '/payment/offers/' + $scope.offer.id, {offer: $scope.offer})
//                 .success(function (result) {
//                     $modalInstance.close(result);
//                 }).error(function (error) {
//                 $scope.errors = [error];
//                 cb();
//             })
//         } else {
//             $http.post('/api/companies/' + $scope.company.id + '/payment/offers/', {offer: $scope.offer})
//                 .success(function (result) {
//                     $modalInstance.close(result);
//                 }).error(function (error) {
//                 $scope.errors = [error];
//                 cb();
//             })
//         }
//     }
// });
