/**
 * This service is to hold the
 *
 * ActionPlan item (action) has the following structure.
 * {
 *      "id": 4,
 *      "value": "IT Reporting Service",
 *      "priority": "medium",
 *      "createdAt": "2014-10-07T14:01:52.604Z",
 *      "updatedAt": "2014-10-07T14:01:52.604Z",
 *      "SurveyResultId": 563,
 *      "StatementId": 451,
 *      "SubprojectId": 386,
 *      "DeliverableId": null,
 *      // references if loaded:
 *      "Statement": {
 *          "id": 451,
 *          "Block": {
 *              "dimension": "clear"
 *          }
 *      },
 *      "Subproject": {
 *          "id": 386,
 *          "code": "L386",
 *          "project": "L295",
 *          "createdAt": "2014-09-29T14:08:20.505Z",
 *          "updatedAt": "2014-09-29T14:08:20.505Z"
 *      },
 *      "Deliverable": null
 *  }
 *
 *
 *
 *
 *
 */
angular.module('vcio-toolkit');
//     .service('analyzeService', function ($rootScope, $http, _) {
//
//         var self = {
//             events: {
//                 actionPlanChanged: 'actionPlanChanged',
//                 actionPlanItemRemoved: 'actionPlanItemRemoved',
//                 postitRemoved: 'postitRemoved',
//                 canvasDimensionSelected: 'canvasDimensionSelected'
//             }
//         }
//
//         self.modifyAction = function (action) {
//             $http.put('/api/actionplans', {actionPlanItem: action})
//                 .error(function (error) {
//                     console.error(error);
//                 });
//         }
//
//         self.deleteActionPlanItem = function (id, cb) {
//             $http.delete('/api/actionplans/' + id)
//                 .then(function () {
//                     cb()
//                 }, function (error) {
//                     console.error(error)
//                 })
//         }
//
//         self.createActionPlanItem = function (item, cb) {
//             $http.post('/api/actionplans', {actionPlanItem: item})
//                 .success(function (result) {
//                     cb(result)
//                 })
//                 .error(function (error) {
//                     console.error(error);
//                 });
//         }
//
//         self.saveAnalyzeComment = function (blockComment, cb) {
//             $http.put('/api/comments', {blockComment: blockComment})
//                 .success(function (result) {
//                     cb(result)
//                 })
//                 .error(function (error) {
//                     console.error(error);
//                 });
//
//         }
//
//         self.saveStatementResponse = function (response, cb) {
//             $http.put('/api/responses', {
//                     SurveyResultId: response.SurveyResultId,
//                     StatementId: response.StatementId,
//                     checked: response.checked,
//                     highlighted: response.highlighted
//                 })
//                 .success(function (result) {
//                     cb(result)
//                 })
//                 .error(function (error) {
//                     console.error(error);
//                 });
//         }
//
//
//         return self;
//     }
// )
